import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './collapsible.css';

@Component
export default class Collapsible extends TsxComponent<{
  isOpen?: boolean;
}> {
  @Prop()
  public isOpen?: boolean;

  public render() {
    return (
      <transition
        enter-class={styles.transitionSlideEnter}
        enter-to-class={styles.transitionSlideEnterTo}
        leave-class={styles.transitionSlideLeave}
        leave-to-class={styles.transitionSlideLeaveTo}
      >
        {this.isOpen && this.$slots.default}
      </transition>
    );
  }
}
