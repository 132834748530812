import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconPosition } from 'components/form/button/types';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { IconName } from 'components/icons/types';
import styles from './filter-popup.css';

interface Item {
  id: number | string;
  label: string;
  isChecked: boolean;
}
interface Props<TItem extends Item> {
  items: TItem[];
  hasSearch?: boolean;
  hasSelectAll?: boolean;
  searchString?: string;
  itemClass?: string;
}

interface ScopedSlots<TItem extends Item> {
  item: { item: TItem };
}
interface Events {
  onFilterChange: (
    payload: EventPayload<{
      id: number; }, HTMLButtonElement, InputEvent>) => void;
  onToggleAll: (
    payload: EventPayload<{
      isSelected: boolean; }, HTMLButtonElement, InputEvent>) => void;
  onSearchInput: (
    payload: EventPayload<{
      search: string; }, HTMLButtonElement, InputEvent>) => void;
  onClose: () => void;
}
@Component
class FilterPopup<TItem extends Item> extends TsxComponent<Props<TItem>,
  Events, ScopedSlots<TItem>> {
  @Prop()
  public searchString: string;

  @Prop()
  public items: TItem[];

  @Prop({ default: false })
  public hasSearch: boolean;

  @Prop({ default: false })
  public hasSelectAll: boolean;

  @Prop()
  public itemClass: string;

  private onChange(e: SyntheticEvent<HTMLInputElement, InputEvent>, id: number | string) {
    this.$emit('filterChange', createEventPayload(e, { id }));
  }

  private onSearchInput(e: SyntheticEvent<HTMLInputElement, InputEvent>) {
    this.$emit('searchInput', createEventPayload(e, { search: e.target.value }));
  }

  private get isAllSelected() {
    return this.items.length > 0 && this.items.every(it => it.isChecked);
  }

  private onToggleAll(e: SyntheticEvent<HTMLInputElement, InputEvent>) {
    this.$emit('toggleAll', createEventPayload(e, { isSelected: !this.isAllSelected }));
  }

  public render() {
    return (
      <div class={styles.filterPopup}>
        {this.$slots.header && (
          <header class={styles.filterPopupHeader}>
            <span>{this.$slots.header}</span>
            <Button
              class={styles.filterPopupCloseButton}
              iconPosition={IconPosition.ALONE}
              color={ButtonColor.SECONDARY}
              icon={IconName.CLEAR}
              onClick={() => this.$emit('close')}
              size={Size.SMALL}
              kind={ButtonKind.GHOST}
            />
          </header>
        )}
        {
          this.hasSearch && <label class={styles.filterPopupSearch}>
            <Icon class={styles.filterPopupSearchIcon} name={IconName.SEARCH} size={Size.SMALL}/>
            <input
              class={styles.filterPopupSearchInput}
              onInput={this.onSearchInput}
              placeholder={this.$t('table.filterPopup.search')}
              type="text"
              value={this.searchString}
            />
          </label>
        }
        <ul class={styles.filterPopupList}>
          {
            this.hasSelectAll && this.items.length > 0 && <li
              class={[styles.filterPopupListItem, this.itemClass]}
              key={'toggleAll'}>
              <label class={styles.filterPopupListItemContent}>
                <input
                  checked={this.isAllSelected}
                  class={styles.filterPopupListItemCheckbox}
                  onChange={this.onToggleAll}
                  type="checkbox"
                  value={-1}
                />
                {
                  <span>{this.$t('table.filterPopup.selectAll')}</span>
                }
              </label>
            </li>
          }
          {
            this.items.map((item, index) => (
              <li
                class={[styles.filterPopupListItem, this.itemClass]}
                key={item.label + index}
              >
                <label class={styles.filterPopupListItemContent}>
                  <input
                    checked={item.isChecked}
                    class={styles.filterPopupListItemCheckbox}
                    onChange={e => this.onChange(e, item.id)}
                    type="checkbox"
                    value={item.id}
                  />
                  {
                    this.$scopedSlots.item
                      ? this.$scopedSlots.item({ item })
                      : <span title={item.label}>{item.label}</span>
                  }
                </label>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }
}

export default FilterPopup;
