import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import InputToggle from 'components/form/input-toggle/InputToggle';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'src/utils/events';
import InputTextArea from 'components/form/input-text-area/InputTextArea';
import { GQLShiftplanPublishInput } from 'codegen/gql-types';
import Collapsible from 'components/collapsible/Collapsible';
import styles from './dialog-shiftplan-publish.css';

export interface Props {
  formState: GQLShiftplanPublishInput;
}

interface Events {
  onInput: <T extends keyof GQLShiftplanPublishInput>(
    payload: EventPayload<{ field: T; value: GQLShiftplanPublishInput[T] }>
  ) => void;
}

@Component
export default class DialogShiftplanPublish extends TsxComponent<Props, Events> {
  @Prop()
  public formState: Props['formState'];

  protected onInput<T extends keyof GQLShiftplanPublishInput>(
    field: T,
    value: GQLShiftplanPublishInput[T],
    e?: SyntheticEvent,
  ) {
    this.$emit(
      'input',
      createEventPayload(e as SyntheticEvent, { field, value }),
    );
  }

  public render() {
    return (
      <div class={styles.dialogShiftplanPublish}>
        <InputToggle
          checked={!!this.formState?.publishWithEmail}
          class={styles.dialogShiftplanPublishInputToggle}
          label={this.$t('publishShift.modal.labelNotifyEmployees')}
          name="publishWithEmail"
          onChange={e => this.onInput('publishWithEmail', e.target.checked, e)
          }
        />

        <Collapsible isOpen={!!this.formState?.publishWithEmail}>
          <div>
            <InputToggle
              checked={false}
              class={styles.dialogShiftplanPublishInputToggle}
              label={this.$t('publishShift.modal.labelWithMessage')}
              name="sendMessage"
              onChange={e => this.onInput('sendMessage', e.target.checked, e)}
            />

            <Collapsible isOpen={!!this.formState?.sendMessage}>
              <InputTextArea
                class={styles.dialogShiftplanPublishInputTextarea}
                label={this.$t('publishShift.modal.labelMessageToEmployees')}
                name="message"
                onChange={e => this.onInput('message', e.target.value, e)}
              />
            </Collapsible>

            <InputToggle
              checked={false}
              class={styles.dialogShiftplanPublishInputToggle}
              label={this.$t('publishShift.modal.labelOpenShiftsSummary')}
              name="sendOpenShifts"
              onChange={e => this.onInput('sendOpenShifts', e.target.checked, e)
              }
            />

            <InputToggle
              checked={false}
              class={styles.dialogShiftplanPublishInputToggle}
              label={this.$t('publishShift.modal.labelAssignedShiftsSummary')}
              name="sendAssignedShifts"
              onChange={e => this.onInput('sendAssignedShifts', e.target.checked, e)
              }
            />
          </div>
        </Collapsible>
      </div>
    );
  }
}
