import { Component, Prop } from 'vue-property-decorator';
import SpIcon from 'components/sp-icon/SpIcon';
import DialogConfirm from 'components/dialog-confirm/DialogConfirm';
import { Component as TsxComponent } from 'vue-tsx-support';
import Tooltip from 'components/tooltip/Tooltip';
import type { AssignEmploymentsToOpenShiftsFunction, FetchShiftplanFunction } from 'src/store/shiftplans/Store';
import { shiftplansNS } from 'src/store/shiftplans/Store';
import ShiftplanAction from 'store/shiftplans/Action';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'src/utils/store';
import { Action } from 'store/normalized-store';
import { Action as WatchStoreAction } from 'store/watch-store';
import { backgroundJobsNS } from 'src/store/background-jobs/Store';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { AlertKind } from 'components/alert/Alert';
import styles from './button-assign-employments-to-open-shifts.css';
import { shiftScheduleBackgroundJobsMapNS } from '../store/Store';
import type { SetJobFunction } from '../store/Store';
import ShiftScheduleAction from '../store/Action';
import { Route } from '../routes';

interface Props {
  shiftplanId: number;
}

@Component
class ButtonAssignEmploymentsToOpenShifts extends TsxComponent<Props> {
  @Prop()
  public shiftplanId: number;

  protected isConfirmDialogOpen = false;

  protected isSubmitting = false;

  @shiftplansNS.Action(ShiftplanAction.ASSIGN_EMPLOYMENTS_TO_OPEN_SHIFTS)
  protected assignEmploymentsToOpenShifts: AssignEmploymentsToOpenShiftsFunction;

  @shiftplansNS.Action(Action.FETCH)
  protected fetchShiftplan: FetchShiftplanFunction;

  @shiftScheduleBackgroundJobsMapNS.Action(ShiftScheduleAction.SET_JOB)
  protected setJobForShiftplan: SetJobFunction;

  @backgroundJobsNS.Action(WatchStoreAction.REFETCH)
  private backgroundJobsRefetch: () => Promise<void>;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  protected onOpenConfirmDialog() {
    this.isConfirmDialogOpen = true;
  }

  protected onCancelConfirmDialog() {
    this.isConfirmDialogOpen = false;
  }

  protected async onAssignEmploymentsToOpenShifts() {
    this.isSubmitting = true;
    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        shiftplanId: this.shiftplanId,
      },
      this.assignEmploymentsToOpenShifts,
      '',
    );
    this.isSubmitting = false;

    if (response.state !== StoreActionState.SUCCESS || !response.entityId) {
      return;
    }

    const shiftplanId = response.entityId;
    const { jobId } = response.meta;

    this.setJobForShiftplan({
      jobId,
      shiftplanId,
    });

    await Promise.all([
      this.backgroundJobsRefetch(),
      this.fetchShiftplan({ id: shiftplanId }),
    ]);

    this.showSnackbar({
      message: this.$t('shiftSchedule.assignEmploymentsToOpenShifts.successMessage'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });

    this.$router.push({
      name: Route.SHIFTPLAN,
      params: {
        shiftplanId: response.entityId.toString(),
      },
    });

    this.isConfirmDialogOpen = false;
  }

  public render() {
    return (
      <div>
        <Tooltip
          text={this.$t('shiftSchedule.assignEmploymentsToOpenShifts.buttonTooltip')}
          class={styles.buttonTooltip}
        >
          <button
            type="button"
            aria-label={this.$t('shiftSchedule.assignEmploymentsToOpenShifts.buttonTooltip')}
            class={styles.buttonButton}
            onClick={() => this.onOpenConfirmDialog()}>
            <SpIcon name="dashboard" class={styles.buttonButtonIcon}/>
          </button>
        </Tooltip>
        {
          this.isConfirmDialogOpen && <DialogConfirm
            isOpen={true}
            isSubmitting={this.isSubmitting}
            onCancel={this.onCancelConfirmDialog}
            onConfirm={this.onAssignEmploymentsToOpenShifts}
            title={this.$t('shiftSchedule.assignEmploymentsToOpenShifts.confirmDialogTitle')}
          >
            {this.$t('shiftSchedule.assignEmploymentsToOpenShifts.confirmDialogMessage')}
          </DialogConfirm>
        }
      </div>
    );
  }
}

export default ButtonAssignEmploymentsToOpenShifts;
