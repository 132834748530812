import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { EventPayload } from 'src/utils/events';
import SnackbarAction from 'src/components/snackbar/store/Action';
import { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { AlertKind } from 'components/alert/Alert';
import { Action } from 'src/store/normalized-store';
import { staffShiftsNS } from 'src/store/staff-shifts/Store';
import type {
  MoveStaffShiftFunction,
  CreateStaffShiftFunction,
  DeleteStaffShiftFunction,
} from 'src/store/staff-shifts/Store';
import { executeStoreActionWithFailureSnackbar, StoreActionResult, StoreActionState } from 'src/utils/store';
import DndKind from 'src/components/calendar-common/common/dnd/DndKind';
import type { ShowSnackbarFunction } from 'src/components/snackbar/store/Store';
import { snackbarNS } from 'src/components/snackbar/store/Store';
import DialogShiftConflicts from 'components/dialog-shift/DialogShiftConflicts';
import StaffShiftAction from 'src/store/staff-shifts/Action';
import DeleteBox from 'components/calendar-common/delete-box/DeleteBox';
import {
  DraggableSourcePayload,
  DropTargetPayload,
  ShiftDropTarget,
} from 'src/store/drag-and-drop/types';
import { calendarDataNS } from '../data/Store';
import type { LoadShiftsFunction } from '../data/Store';
import { LOAD_SHIFTS } from '../data/Actions';

interface Props {
  viewComponent: Vue,
}

@Component
class DragAndDropEventsContainer extends TsxComponent<Props> {
  private conflictsStaffShift: GQLShiftConflictsFragmentFragment[] = [];

  private target: DropTargetPayload | null;

  private source: DraggableSourcePayload | null;

  private ignoreConflicts = false;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @staffShiftsNS.Action(Action.CREATE)
  protected createStaffShiftAction: CreateStaffShiftFunction;

  @staffShiftsNS.Action(Action.DELETE)
  protected deleteStaffShiftAction: DeleteStaffShiftFunction;

  @staffShiftsNS.Action(StaffShiftAction.MOVE)
  protected moveStaffShiftAction: MoveStaffShiftFunction;

  @calendarDataNS.Action(LOAD_SHIFTS)
  private loadShiftsAction: LoadShiftsFunction;

  @Prop()
  public viewComponent: Props['viewComponent'];

  protected get conflicts() {
    return this.conflictsStaffShift;
  }

  private async refetchCalendarShifts(params: {
    shiftIds?: number[],
    connectedGroupId?: number;
  }) {
    this.loadShiftsAction(params);
  }

  private async tryCreateStaffShift(
    params: Parameters<CreateStaffShiftFunction>[0],
    shiftId: number,
  ): Promise<StoreActionResult> {
    const result = await executeStoreActionWithFailureSnackbar(
      this,
      params,
      this.createStaffShiftAction,
      '',
    );

    if (result.state === StoreActionState.SUCCESS) {
      this.showSnackbar({
        message: this.$t('shifts.employees.shiftAssigned'),
        timeout: 5000,
        kind: AlertKind.SUCCESS,
      });
      this.refetchCalendarShifts({ shiftIds: [shiftId] });
    }

    if (result.state === StoreActionState.CONFLICT) {
      this.conflictsStaffShift = result.conflicts;
    }

    return result;
  }

  private async tryDeleteStaffShift(
    params: Parameters<DeleteStaffShiftFunction>[0],
    shiftId: number,
  ): Promise<StoreActionResult> {
    const result = await executeStoreActionWithFailureSnackbar(
      this,
      params,
      this.deleteStaffShiftAction,
      '',
    );

    if (result.state === StoreActionState.SUCCESS) {
      this.showSnackbar({
        message: this.$t('shifts.employees.shiftUnassigned'),
        timeout: 5000,
        kind: AlertKind.SUCCESS,
      });
      this.refetchCalendarShifts({ shiftIds: [shiftId] });
    }

    return result;
  }

  private async tryMoveStaffShift(
    params: Parameters<MoveStaffShiftFunction>[0],
    shiftId: number,
  ): Promise<StoreActionResult> {
    const result = await executeStoreActionWithFailureSnackbar(
      this,
      params,
      this.moveStaffShiftAction,
      '',
    );

    if (result.state === StoreActionState.SUCCESS) {
      this.showSnackbar({
        message: this.$t('shifts.employees.shiftAssigned'),
        timeout: 5000,
        kind: AlertKind.SUCCESS,
      });
      this.refetchCalendarShifts({
        shiftIds: [
          shiftId, params.sourceShiftId,
        ],
      });
    }

    if (result.state === StoreActionState.CONFLICT) {
      this.conflictsStaffShift = result.conflicts;
    }

    return result;
  }

  private async handleShiftDrop(source: DraggableSourcePayload, target: ShiftDropTarget) {
    switch (source.kind) {
      case DndKind.EMPLOYMENT: {
        this.tryCreateStaffShift(
          {
            staffShift: {
              shiftId: target.id,
              employmentId: source.id,
              ignoreConflicts: this.ignoreConflicts,
              assignToConnected: false,
            },

          },
          target.id,
        );
        break;
      }

      case DndKind.STAFF_SHIFT:
        this.tryMoveStaffShift(
          {
            employmentId: source.employmentId,
            shiftId: target.id,
            sourceShiftId: source.shiftId,
            ignoreConflicts: this.ignoreConflicts,
          },
          target.id,
        );
        break;
      default:
    }
  }

  private async handleDeleteBoxDrop(source: DraggableSourcePayload) {
    switch (source.kind) {
      case DndKind.STAFF_SHIFT:
        this.tryDeleteStaffShift(
          {
            id: source.id,
            unassignFromConnected: false,
          },
          source.shiftId,
        );
        break;
      default:
    }
  }

  /*
    handles drop action based on source(dragged element)
    and target(dropped element)
  */
  private async handleDrop() {
    if (!this.source || !this.target) {
      return;
    }

    switch (this.target.kind) {
      case DndKind.SHIFT: {
        this.handleShiftDrop(this.source, this.target);
        break;
      }
      case DndKind.DELETE_BOX: {
        this.handleDeleteBoxDrop(this.source);
        break;
      }
      default:
    }
  }

  private async onDrop({ payload: { source, target } }: EventPayload<{
    source: DraggableSourcePayload,
    target: DropTargetPayload,
  }>) {
    this.target = target;
    this.source = source;
    this.ignoreConflicts = false;

    this.handleDrop();
  }

  private onIgnoreConflicts() {
    this.conflictsStaffShift = [];
    this.ignoreConflicts = true;
    this.handleDrop();
  }

  private onCancelConflicts() {
    this.conflictsStaffShift = [];
    this.target = null;
    this.source = null;
    this.ignoreConflicts = false;
  }

  public render() {
    // JSX requires components to be in CamelCase
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ViewComponent: any = this.viewComponent;
    return (<div>
      <ViewComponent onDrop={this.onDrop}/>
      <DialogShiftConflicts
        isOpen={this.conflicts.length > 0}
        conflicts={this.conflicts}
        onCancel={this.onCancelConflicts}
        onIgnore={this.onIgnoreConflicts}
      />
      <DeleteBox onDrop={this.onDrop} />
    </div>);
  }
}

export default DragAndDropEventsContainer;
