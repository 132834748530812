import { GQLPaygradeLevel } from 'codegen/gql-types';
import { Pay } from 'components/dialog-shift/paygrades/Section';
import RootStoreState from 'store/RootStoreState';
import { Action as StoreAction } from 'store/normalized-store';
import { StoreActionResult, StoreActionState } from 'utils/store';
import { filterUndefined } from 'utils/utils';
import Vue from 'vue';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import Action from './Action';
import Mutation from './Mutation';
import { mergeSourceWithTargetTree } from './utils';

export const sectionPayNS = namespace('evaluationsForm/sectionPay');

export const sectionPayEmploymentNS = namespace('evaluationsForm/sectionPayEmployment');

export interface StoreState {
  createIdCounter: number;
  isDirty: boolean;
  tree: Pay[];
}

const getSectionPayStore = (
  isStakeholder?: boolean,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    createIdCounter: Number.MIN_SAFE_INTEGER,
    isDirty: false,
    tree: [],
  },
  mutations: {
    [Mutation.SET_TREE]: (state, tree: Pay[]) => {
      state.tree = tree;
    },
    [Mutation.SET_IS_DIRTY]: (state, isDirty: boolean) => {
      state.isDirty = isDirty;
    },
    [Mutation.REPLACE_PARENT_NODE]: (state, { index, node }: { index: number; node: Pay }) => {
      Vue.set(state.tree, index, node);
    },
    [Mutation.INSERT_PARENT_NODE]: (state, node: Pay) => {
      state.tree = [...state.tree, node];
    },
    [Mutation.SET_CREATE_ID_COUNTER]: (state, number: number) => {
      state.createIdCounter = number;
    },
  },
  actions: {
    [Action.SET_TREE]: ({ commit }, tree: Pay[]) => {
      commit(Mutation.SET_TREE, tree);
      commit(Mutation.SET_IS_DIRTY, false);
    },
    [Action.ADD_PAY]: ({ commit, state }, payload?: Pay) => {
      const pay: Pay = {
        id: state.createIdCounter,
        children: [],
        isCreated: true,
        level: payload?.id
          ? GQLPaygradeLevel.PAYGRADE
          : GQLPaygradeLevel.SHIFT,
        parentId: payload?.id,
        total: 0,
        typeId: 0,
        value: 0,
      };

      if (payload?.id) {
        const parentIndex = state.tree.findIndex(o => o.id === payload.id);

        const updated = { ...state.tree[parentIndex] };
        updated.children = [...updated.children, pay];

        commit(Mutation.REPLACE_PARENT_NODE, { index: parentIndex, node: updated });
      } else {
        commit(Mutation.INSERT_PARENT_NODE, pay);
      }

      commit(Mutation.SET_CREATE_ID_COUNTER, state.createIdCounter + 1);
    },
    [Action.UPDATE_PAY]: ({ commit, state }, payload: Pay) => {
      const parentIndex = state.tree.findIndex(o => o.id === (payload.parentId || payload.id));

      if (parentIndex < 0) {
        return;
      }

      const updated = { ...state.tree[parentIndex] };

      if (!payload.parentId) {
        updated.value = +(payload.value || 0);
        updated.typeId = +payload.typeId;
        updated.isUpdated = !updated.isCreated;
      } else {
        updated.children = mergeSourceWithTargetTree([{
          ...payload,
          value: +(payload.value || 0),
          typeId: +payload.typeId,
          isUpdated: !payload.isCreated,
        }], updated.children);
      }

      commit(Mutation.REPLACE_PARENT_NODE, { index: parentIndex, node: updated });
    },
    [Action.UPDATE_TOTAL]: ({ commit, state }, payload: Pay) => {
      const parentIndex = state.tree.findIndex(o => o.id === payload.id);

      if (parentIndex < 0) {
        return;
      }

      const updated = { ...state.tree[parentIndex] };
      updated.total = +(payload.total || 0);
      updated.children = mergeSourceWithTargetTree(payload.children, updated.children);

      commit(Mutation.REPLACE_PARENT_NODE, { index: parentIndex, node: updated });
    },
    [Action.REMOVE_PAY]: ({ commit, state }, payload: Pay) => {
      const parentIndex = state.tree.findIndex(o => o.id === (payload.parentId || payload.id));

      if (parentIndex < 0) {
        return;
      }

      const updated = { ...state.tree[parentIndex] };

      if (!payload.parentId) {
        updated.isDeleted = true;
      } else {
        updated.children = mergeSourceWithTargetTree([{
          ...payload,
          isDeleted: true,
        }], updated.children);
      }

      commit(Mutation.REPLACE_PARENT_NODE, { index: parentIndex, node: updated });
    },
    async [Action.DISPATCH_REMOVE](
      { commit, dispatch, state },
      evaluationId: number,
    ): Promise<StoreActionResult> {
      const toRemove = state.tree
        .filter(parent => (
          (parent.isDeleted && !parent.isCreated)
            || parent.children.some(chld => chld.isDeleted && !chld.isCreated)
        ))
        .flatMap(parent => (
          parent.isDeleted
            ? parent
            : parent.children.filter(chld => chld.isDeleted && !chld.isCreated)
        ));

      if (!toRemove.length) {
        return { state: StoreActionState.SUCCESS };
      }

      const results: StoreActionResult[] = await Promise.all(
        toRemove.map(pay => dispatch(
          isStakeholder
            ? `evaluationPayments/${StoreAction.DELETE}`
            : `employmentEvaluationPayments/${StoreAction.DELETE}`,
          {
            id: pay.id,
            staffShiftId: evaluationId,
            paymentId: pay.id,
            parentPaymentId: pay.parentId || null,
          },
          { root: true },
        )),
      );

      commit(Mutation.SET_IS_DIRTY, true);

      return results.some(result => result.state !== StoreActionState.SUCCESS)
        ? { state: StoreActionState.ERROR }
        : { state: StoreActionState.SUCCESS };
    },
    async [Action.DISPATCH_UPDATE](
      { commit, dispatch, state },
      evaluationId: number,
    ): Promise<StoreActionResult> {
      const toUpdate = state.tree
        .filter(parent => !parent.isCreated && !parent.isDeleted)
        .flatMap(parent => [
          parent.isUpdated ? parent : undefined,
          ...parent.children.filter(chld => chld.isUpdated && !chld.isCreated && !chld.isDeleted),
        ].filter(filterUndefined));

      if (!toUpdate.length) {
        return { state: StoreActionState.SUCCESS };
      }

      const results: StoreActionResult[] = await Promise.all(
        toUpdate.map(pay => dispatch(
          isStakeholder
            ? `evaluationPayments/${StoreAction.UPDATE}`
            : `employmentEvaluationPayments/${StoreAction.UPDATE}`,
          {
            staffShiftId: evaluationId,
            payment: {
              parentPaymentId: pay.parentId || null,
              value: pay.value || 0,
            },
            paymentId: pay.id,
          },
          { root: true },
        )),
      );

      commit(Mutation.SET_IS_DIRTY, true);

      return results.some(result => result.state !== StoreActionState.SUCCESS)
        ? { state: StoreActionState.ERROR }
        : { state: StoreActionState.SUCCESS };
    },
    async [Action.DISPATCH_CREATE](
      { commit, dispatch, state },
      evaluationId: number,
    ): Promise<StoreActionResult> {
      const toCreate = state.tree.filter(parent => (
        (parent.isCreated && !parent.isDeleted)
          || parent.children.some(chld => chld.isCreated && !chld.isDeleted)
      ));

      if (!toCreate.length) {
        return { state: StoreActionState.SUCCESS };
      }

      const createDeep = async (pay: Pay[], parentId?: number): Promise<StoreActionResult[]> => (
        (await Promise.all(pay.map(async (p) => {
          let newParentPaymentId = p.id;
          let result: StoreActionResult | undefined;

          if (p.isCreated) {
            result = await dispatch(
              isStakeholder
                ? `evaluationPayments/${StoreAction.CREATE}`
                : `employmentEvaluationPayments/${StoreAction.CREATE}`,
              {
                staffShiftId: evaluationId,
                payment: {
                  parentPaymentId: parentId || null,
                  paygradeTypeId: p.typeId,
                  value: p.value || 0,
                },
              },
              { root: true },
            );

            if (result?.state === StoreActionState.SUCCESS) {
              newParentPaymentId = result.entityId || Number.NaN;
            }
          }

          return [
            result || [],
            ...(await createDeep(p.children, newParentPaymentId)),
          ].flatMap<StoreActionResult>(o => o);
        }))).flatMap(o => o)
      );

      const results = await createDeep(toCreate);

      commit(Mutation.SET_IS_DIRTY, true);

      return results.some(result => result.state !== StoreActionState.SUCCESS)
        ? { state: StoreActionState.ERROR }
        : { state: StoreActionState.SUCCESS };
    },
  },
});

export default getSectionPayStore;
