import { GQLOrderDirection, GQLShiftEmploymentSort } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { createEventPayload, EventPayload } from 'src/utils/events';
import RadioButton from './RadioButton';

import styles from './sorting.css';

@Component
export default class Money extends TsxComponent<{
  criterion: GQLShiftEmploymentSort;
  name: string;
  order: GQLOrderDirection;
}, {
  onClick: (payload: EventPayload<GQLShiftEmploymentSort>) => void;
}> {
  @Prop()
  public criterion: GQLShiftEmploymentSort;

  @Prop()
  public name: string;

  @Prop({ default: GQLOrderDirection.ASC })
  public order: GQLOrderDirection;

  protected onClick(e) {
    this.$emit('click', createEventPayload(e, e.target.value));
  }

  public render() {
    return (
      <div class={styles.sorting}>
        <RadioButton
          checked={this.criterion === GQLShiftEmploymentSort.EARNEDAMOUNT}
          icon="earned-amount"
          label={this.$t('shifts.employees.sorting.money.labelEarned')}
          name={this.name}
          onClick={this.onClick}
          order={this.order}
          value={GQLShiftEmploymentSort.EARNEDAMOUNT}
        />

        <RadioButton
          checked={this.criterion === GQLShiftEmploymentSort.MAXIMUMMONEY}
          icon="maximum-money"
          label={this.$t('shifts.employees.sorting.money.labelMaximum')}
          name={this.name}
          onClick={this.onClick}
          order={this.order}
          value={GQLShiftEmploymentSort.MAXIMUMMONEY}
        />

        <RadioButton
          checked={this.criterion === GQLShiftEmploymentSort.REMAININGMONEY}
          icon="remaining-money"
          label={this.$t('shifts.employees.sorting.money.labelRemaining')}
          name={this.name}
          onClick={this.onClick}
          order={this.order}
          value={GQLShiftEmploymentSort.REMAININGMONEY}
        />
      </div>
    );
  }
}
