import EmploymentWithAvatar from 'components/employment-with-avatar/EmploymentWithAvatar';
import type { Employment } from 'components/employments/types';
import { Shift } from 'src/store/shifts/Store';
import { getDateInTimeZone, getDateTimeFormatter } from 'src/utils/date-related';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './row.css';

interface Props {
  employment?: Employment;
  isManagerView?: boolean;
  isOffer?: boolean;
  isPending?: boolean;
  isSubmitting?: boolean;
  request: Shift['requests'][0];
}

interface Events {
  onCancelRequest: (payload: EventPayload<void>) => void;
  onConfirmRequest: (payload: EventPayload<void>) => void;
  onDeleteOffer: (payload: EventPayload<void>) => void;
}

@Component
export default class Row extends TsxComponent<Props, Events> {
  protected get formatDate() {
    return getDateTimeFormatter(this.$i18n.i18next.language);
  }

  @Prop()
  public employment?: Props['employment'];

  @Prop()
  public isManagerView?: Props['isManagerView'];

  @Prop()
  public isOffer?: Props['isOffer'];

  @Prop()
  public isPending?: Props['isPending'];

  @Prop()
  public isSubmitting?: Props['isSubmitting'];

  @Prop()
  public request: Props['request'];

  protected get isAcceptOfferButtonShown() {
    return !this.isManagerView && !this.isPending;
  }

  protected get isConfirmRequestButtonShown() {
    return this.isManagerView && !this.isOffer;
  }

  public render() {
    return (
      <div class={styles.row}>
        <EmploymentWithAvatar
          employment={this.employment || this.request.employment}
          size={this.isOffer ? Size.MEDIUM : Size.SMALL}
          withStaffNumber={true}
        />

        <div class={styles.rowInformation}>
          {
            this.isOffer && this.isManagerView && this.formatDate(
              getDateInTimeZone(new Date(this.request.createdAt), this.$timeZone.value),
            )
          }

          {
            this.isOffer && this.isManagerView && (
              <Button
                color={ButtonColor.ERROR}
                disabled={this.isSubmitting}
                onClick={e => this.$emit('deleteOffer', createEventPayload(e, undefined))}
                size={Size.SMALL}
                kind={ButtonKind.GHOST}
              >
                {this.$t('shifts.replaceRequests.buttonDeleteOffer')}
              </Button>
            )
          }

          {
            ((!this.isOffer && this.isManagerView) || this.isPending) && (
              <Button
                color={ButtonColor.ERROR}
                disabled={this.isSubmitting}
                onClick={e => this.$emit('cancelRequest', createEventPayload(e, undefined))}
                size={Size.SMALL}
                kind={ButtonKind.STROKE}
              >
                {this.$t('shifts.replaceRequests.buttonCancelRequest')}
              </Button>
            )
          }

          {
            (this.isConfirmRequestButtonShown || this.isAcceptOfferButtonShown) && (
              <Button
                color={ButtonColor.SUCCESS}
                disabled={this.isSubmitting}
                onClick={e => this.$emit('confirmRequest', createEventPayload(e, undefined))}
                size={Size.SMALL}
                kind={ButtonKind.GHOST}
              >
                {this.$t('shifts.replaceRequests.buttonConfirmRequest')}
              </Button>
            )
          }
        </div>
      </div>
    );
  }
}
