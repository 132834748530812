import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { EventPayload } from 'src/utils/events';
import { ShiftPreset, shiftPresetsNS } from 'src/store/shift-presets/Store';
import type { GetById } from 'src/utils/store';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import InputPillSelect from 'components/form/input-pill-select/InputPillSelect';
import styles from './section-shift-rotations.css';
import type { AvailableShiftRotationGroup } from '../types';

export interface Props {
  selectableShiftRotationGroups: AvailableShiftRotationGroup[];
  selectedShiftRotationGroupIds: number[];
  shiftPresetId: number;
  isDisabled?: boolean;
}
interface Events {
  onChange: (
    e: EventPayload<string[], HTMLButtonElement | HTMLInputElement, MouseEvent | InputEvent>
  ) => void;
}

@Component
class SectionShiftRotations extends TSXComponent<Props, Events> {
  @Prop()
  public selectableShiftRotationGroups: Props['selectableShiftRotationGroups'];

  @Prop()
  public shiftPresetId: Props['shiftPresetId'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public selectedShiftRotationGroupIds: Props['selectedShiftRotationGroupIds'];

  @shiftPresetsNS.Getter('getById')
  protected getShiftPresetById: GetById<ShiftPreset>;

  private get color() {
    if (!this.shiftPresetId) {
      return undefined;
    }

    return this.getShiftPresetById(this.shiftPresetId)?.color;
  }

  private get placeholder() {
    return this.$t(
      this.isDisabled
        ? 'shifts.dialog.placeholderNoTags'
        : 'shifts.dialog.placeholderAddTags',
    );
  }

  private get options() {
    return this.selectableShiftRotationGroups.map(
      ({ id, name, shiftRotation }) => ({
        value: id.toString(),
        label: `${name} (${shiftRotation.name})`,
        color: this.color,
      }),
    );
  }

  protected onChange(
    e: EventPayload<string[], HTMLButtonElement | HTMLInputElement, MouseEvent | InputEvent>,
  ) {
    this.$emit('change', e);
  }

  public render() {
    return (
      <FormSection heading={this.$t('shifts.dialog.headingShiftRotationGroups')}>
        <InputPillSelect
          label={this.$t('shifts.dialog.labelShiftRotationGroups')}
          class={styles.sectionShiftRotationsRowFullWidth}
          isDisabled={this.isDisabled}
          onChange={this.onChange}
          options={this.options}
          placeholder={this.placeholder}
          value={this.selectedShiftRotationGroupIds.map(id => id.toString())}
        />
      </FormSection>
    );
  }
}

export default SectionShiftRotations;
