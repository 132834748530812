import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Screen from './Screen';

@Component
export default class ScreenError extends TsxComponent<{}> {
  public render() {
    return (
      <Screen
        visual="🤔"
        title={this.$t('screens.error.title')}
        message={this.$t('screens.error.message')}
      />
    );
  }
}
