import SpIcon from 'components/sp-icon/SpIcon';
import DateItem from 'components/calendar-common/common/DateItem';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import gridCellStyles from 'components/calendar-common/grid/grid-cell/grid-cell.css';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SpecialDates from '../special-dates/SpecialDates.jsx';
import styles from './special-dates-container.css';

@Component
class SpecialDatesContainer extends Vue {
  @Prop()
  public dates!: DateItem[];

  @Prop({ default: false })
  public showLabelText!: boolean;

  @Getter('calendar/notes/specialDates')
  protected specialDates: object;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  public render() {
    return (this.filters.showSpecialDates && <div class={gridStyles.gridTableSubgrid}>
      <GridRow dates={this.dates}
        cellClass={[gridCellStyles.gridCellNoGap, gridStyles.gridTableContentCellStretch]}
        scopedSlots={{
          cell: scope => (<SpecialDates
            specialDates={this.specialDates[scope.dateItem.dateKey]}/>),
        }}>
        <GridCell isHeader={true}
          class={[gridStyles.gridTableLabelCell, styles.specialDatesContainerLabelText]}
          slot="label">
          {this.showLabelText
            ? (<span>
              {this.$t('calendar.specialDates.title')}
            </span>)
            : <SpIcon name="event"/>}
        </GridCell>
      </GridRow>
    </div>);
  }
}

export default SpecialDatesContainer as any;
