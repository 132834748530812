import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import Tooltip from 'components/tooltip/Tooltip';
import { getRandomString } from 'utils/random';
import Indicator, { AvailabilityState } from '../../availability/Indicator';

import styles from './checkbox-availability.css';

@Component
export default class CheckboxAvailability extends TsxComponent<
Partial<HTMLInputElement> & {
  id?: string;
  label: string;
  name: string;
  state: AvailabilityState;
  value?: string;
}, EventsOn<HTMLInputElement>> {
  @Prop({ default: () => `inp-${getRandomString()}` })
  protected id: string;

  @Prop()
  public label: string;

  @Prop()
  public name: string;

  @Prop()
  public state: AvailabilityState;

  @Prop()
  public value?: string;

  public render() {
    return (
      <div class={styles.checkboxAvailability}>
        <input
          {...{
            attrs: { ...this.$attrs },
            on: { ...this.$listeners },
          }}
          class={styles.checkboxAvailabilityInput}
          id={this.id}
          name={this.name}
          type="checkbox"
          value={this.value}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label class={styles.checkboxAvailabilityLabel} for={this.id}>
          <Tooltip text={this.label}>
            <Indicator state={this.state} class={styles.checkboxAvailabilityIndicator}>
              {this.label}
            </Indicator>
          </Tooltip>
        </label>
      </div>
    );
  }
}
