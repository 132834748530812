import type { GQLCalendarAggregation } from 'codegen/gql-types';
import DefaultActions from 'components/calendar-common/DefaultActions';
import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import RootStoreState from 'src/store/RootStoreState';
import Mutations from './Mutations';

export const calendarAbsencesAggregationsNS = namespace('calendarAbsences/aggregations');

export type CalendarAggregation = Pick<GQLCalendarAggregation, 'absent' | 'demand' | 'working'>;
export interface StoreState {
  items: GQLCalendarAggregation[];
}

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    [Mutations.SET_AGGREGATIONS](state, items) {
      state.items = items;
    },
  },
  actions: {
    [DefaultActions.SET]({ commit }, items) {
      commit(Mutations.SET_AGGREGATIONS, items);
    },
  },
  getters: {
    aggregationsByDates(state, rootState, getters, rootGetters) {
      return rootGetters['calendarAbsences/common/dateKeys'].reduce((acc, date) => {
        acc[date] = state.items
          .find(item => item.date === date) || {
          demand: 0,
          working: 0,
          absent: 0,
        };
        return acc;
      }, {});
    },
  },
});
