/* eslint-disable import/prefer-default-export */
import type { RotationGroupUnion } from './rotation-groups/types';

/* eslint-disable indent */
export const sortEmploymentsRotationGroupByStartsAtAndName = ({
  reverseName = false,
  reverseStartsAt = false,
} = {}) => (x: RotationGroupUnion, y: RotationGroupUnion) => {
  if (x.startsAt && y.startsAt) {
    const xStartsAt = new Date(x.startsAt).valueOf();
    const yStartsAt = new Date(y.startsAt).valueOf();

    if (xStartsAt !== yStartsAt) {
      return reverseStartsAt ? yStartsAt - xStartsAt : xStartsAt - yStartsAt;
    }
  }

  if (x.__typename === 'EmploymentsRotationGroup' && y.__typename === 'EmploymentsRotationGroup') {
    const xShiftRotationName = x.shiftRotationGroup.shiftRotation.name;
    const yShiftRotationName = y.shiftRotationGroup.shiftRotation.name;

    return reverseName
      ? yShiftRotationName.localeCompare(xShiftRotationName)
      : xShiftRotationName.localeCompare(yShiftRotationName);
  }

  return x.id - y.id;
};
/* eslint-enable indent */
