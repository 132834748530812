import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import InputText from 'components/form/input-text/InputText';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import ActionButtonWrapper from '../action-button-wrapper/ActionButtonWrapper';
import { Route } from '../routes';
import StepWrapper from '../step-wrapper/StepWrapper';
import Action from '../store/Action';
import { rotationWizardNS, StoreState } from '../store/Store';
import styles from './general-setup.css';

const FORM_ID = 'general-setup';

@Component
export default class GeneralSetup extends TsxComponent<{ isEdit: boolean }> {
  public $refs: {
    anchorDateRef: Vue;
    buttonRef: HTMLButtonElement;
    formRef: HTMLFormElement;
    rotationGroupsLengthRef: Vue;
    rotationIntervalRef: Vue;
  };

  @Prop()
  protected isEdit: boolean;

  @rotationWizardNS.Action(Action.ON_INPUT)
  protected onInput: (payload: { name: string; value: number | string }) => void;

  @rotationWizardNS.Action(Action.GENERATE_ROTATION_GROUPS)
  protected generateRotationGroups: ({
    rotationInterval,
    groupsLength,
    defaultGroupNamePrefix,
  }: {
    rotationInterval: number;
    groupsLength: number;
    defaultGroupNamePrefix: string;
  }) => void;

  @rotationWizardNS.State
  protected rotationName: StoreState['rotationName'];

  @rotationWizardNS.State
  protected rotationGroupsLength: StoreState['rotationGroupsLength'];

  @rotationWizardNS.State
  protected rotationInterval: StoreState['rotationInterval'];

  @rotationWizardNS.State
  protected anchorDate: StoreState['anchorDate'];

  @rotationWizardNS.State
  protected rotationGroups: StoreState['rotationGroups'];

  @rotationWizardNS.Getter
  protected isGeneralSetupValid: boolean;

  @rotationWizardNS.Getter
  protected existingShiftRotationGroupsLength: number;

  protected onSubmit(e) {
    this.$router.push({ name: this.isEdit ? Route.EDIT_STEP_2 : Route.STEP_2 });
    e.preventDefault();
  }

  protected confirmRotationGroupsReset() {
    // do not show prompt on edit
    if (this.isEdit) {
      return true;
    }

    if (this.rotationGroups.some(group => group.days.some(Boolean))) {
      // eslint-disable-next-line no-alert
      return window.confirm(
        this.$t('rotationWizard.generalSetup.confirmRotationSetupReset'),
      );
    }

    return true;
  }

  @Watch('rotationGroupsLength')
  @Watch('rotationInterval')
  @Watch('anchorDate')
  protected onRotationGroupsChange() {
    this.generateRotationGroups({
      groupsLength: this.rotationGroupsLength,
      rotationInterval: this.rotationInterval,
      defaultGroupNamePrefix: this.$t('rotationWizard.rotationPatternSetup.defaultGroupNamePrefix'),
    });
  }

  public render() {
    return (
      <StepWrapper heading={this.$t('rotationWizard.generalSetup.heading')}>
        <form class={styles.generalSetupForm} onSubmit={this.onSubmit} id={FORM_ID}>
          <InputText
            class={styles.generalSetupFormInput}
            id="rotationName"
            label={this.$t('rotationWizard.generalSetup.inputRotationName')}
            name="rotationName"
            onInput={e => this.onInput(e.target)}
            placeholder={this.$t('rotationWizard.generalSetup.inputRotationNamePlaceholder')}
            required={true}
            type="text"
            value={this.rotationName}
          />

          <InputText
            class={styles.generalSetupFormInput}
            id="rotationGroupsLength"
            isStepperShown={true}
            label={this.$t('rotationWizard.generalSetup.inputRotationGroupsLength')}
            max="9999"
            min={this.isEdit
              ? this.existingShiftRotationGroupsLength.toString()
              : '1'}
            name="rotationGroupsLength"
            onInput={(e) => {
              if (this.confirmRotationGroupsReset()) {
                this.onInput({
                  name: e.target.name,
                  value: parseInt(e.target.value, 10),
                });
              } else {
                // need to force update to retain value
                this.$refs.rotationGroupsLengthRef.$forceUpdate();
              }
            }}
            placeholder="0"
            ref="rotationGroupsLengthRef"
            required={true}
            type="number"
            value={this.rotationGroupsLength?.toString()}
          />

          <InputText
            class={styles.generalSetupFormInput}
            id="rotationInterval"
            isStepperShown={true}
            disabled={this.isEdit}
            label={this.$t('rotationWizard.generalSetup.inputRotationInterval')}
            max="9999"
            min="1"
            name="rotationInterval"
            onKeydown={this.confirmRotationGroupsReset}
            onInput={(e) => {
              if (this.confirmRotationGroupsReset()) {
                this.onInput({
                  name: e.target.name,
                  value: parseInt(e.target.value, 10),
                });
              } else {
                // need to force update to retain value
                this.$refs.rotationIntervalRef.$forceUpdate();
              }
            }}
            placeholder="0"
            ref="rotationInterval"
            required={true}
            type="number"
            value={this.rotationInterval?.toString()}
          />

          <InputText
            class={styles.generalSetupFormInput}
            id="anchorDate"
            disabled={this.isEdit}
            label={this.$t('rotationWizard.generalSetup.inputAnchorDate')}
            name="anchorDate"
            onInput={(e) => {
              if (this.confirmRotationGroupsReset()) {
                this.onInput(e.target);
              } else {
                // need to force update to retain value
                this.$refs.anchorDateRef.$forceUpdate();
              }
            }}
            // HACK: Require specific date format for Safari which doesn't support input type="date"
            // we can only safely parse ISO date in every browser
            pattern="[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}"
            placeholder={this.$t('rotationWizard.generalSetup.inputDatePlaceholder')}
            ref="anchorDateRef"
            required={true}
            type="date"
            value={this.anchorDate?.substr(0, 10)}
          />
        </form>

        <ActionButtonWrapper slot="footer">
          <Button
            form={FORM_ID}
            kind={ButtonKind.FILL}
            color={ButtonColor.PRIMARY}
            disabled={!this.isGeneralSetupValid}
            size={Size.LARGE}
            icon={IconName.ARROW_NEXT}
            iconPosition={IconPosition.AFTER}
          >
            {this.$t('rotationWizard.generalSetup.buttonRotationSetup')}
          </Button>
        </ActionButtonWrapper>
      </StepWrapper>
    );
  }
}
