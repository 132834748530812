import { GQLRequestType } from 'codegen/gql-types';
import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { SentryTag } from 'services/logger/SentryTransport';
import { hasAlreadyStarted } from 'src/utils/date-related';
import Action from 'store/shifts/Action';
import { shiftsNS } from 'store/shifts/Store';
import type {
  CreateJoinRequestFunction,
  DeleteRequestFunction,
  Shift,
} from 'store/shifts/Store';
import { StoreActionState } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './join-shift.css';
import DialogShiftConflicts from '../DialogShiftConflicts';

interface Props {
  shift?: Shift;
}

interface Events {
  onRefetchShift: () => void;
}

@Component
export default class JoinShift extends TsxComponent<Props, Events> {
  protected conflicts: GQLShiftConflictsFragmentFragment[] = [];

  protected isDeleteConfirmDialogOpen = false;

  protected isSubmitting = false;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @shiftsNS.Action(Action.CREATE_JOIN_REQUEST)
  protected createJoinRequest: CreateJoinRequestFunction;

  @shiftsNS.Action(Action.DELETE_REQUEST)
  protected deleteRequest: DeleteRequestFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public shift?: Props['shift'];

  protected get hasShiftAlreadyStarted() {
    return hasAlreadyStarted(this.shift);
  }

  protected get isCurrentlyAssigned() {
    if (!this.shift || !Array.isArray(this.shift.staffShifts)) {
      return false;
    }

    return this.shift.staffShifts.some(staffShift => (
      staffShift.employment && staffShift.employment.id === this.currentEmployment?.id
    ));
  }

  protected get ownRequest() {
    const { currentEmployment } = this;

    if (!this.shift || !currentEmployment) {
      return undefined;
    }

    return this.shift.requests.find(request => (
      request.type === GQLRequestType.STAFFREQUEST
        && request.employment
        && request.employment.id === currentEmployment.id
    ));
  }

  protected async onCancelJoinRequest() {
    this.isDeleteConfirmDialogOpen = true;
  }

  protected onCancelJoinRequestCancel() {
    this.isDeleteConfirmDialogOpen = false;
  }

  protected async onCancelJoinRequestConfirm() {
    if (!this.shift || !this.ownRequest) {
      return undefined;
    }

    this.isSubmitting = true;

    const response = await this.deleteRequest({
      id: this.ownRequest.id,
      shiftId: this.shift.id,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, JoinShift.name]],
        message: JSON.stringify(response),
      });

      this.isDeleteConfirmDialogOpen = false;
      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return false;
    }

    this.$emit('refetchShift');

    this.isDeleteConfirmDialogOpen = false;
    this.isSubmitting = false;

    return this.showSnackbar({
      message: this.$t('shifts.joinShift.requestCanceled'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected async onCreateJoinRequest(_, ignoreConflicts?: boolean) {
    if (!this.shift) {
      return undefined;
    }

    this.isSubmitting = true;

    const response = await this.createJoinRequest({
      shiftId: this.shift.id,
      ignoreConflicts: !!ignoreConflicts,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, JoinShift.name]],
        message: JSON.stringify(response),
      });

      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return undefined;
    }

    if (response.state === StoreActionState.CONFLICT) {
      this.isSubmitting = false;
      this.conflicts = response.conflicts;

      return undefined;
    }

    this.$emit('refetchShift');

    this.conflicts = [];
    this.isSubmitting = false;

    return this.showSnackbar({
      message: this.$t('shifts.joinShift.requestCreated'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected get messageAutoAcceptState(): string | undefined {
    if (!this.ownRequest || !this.currentCompany?.autoAcceptShiftRequestEnabled) {
      return undefined;
    }

    return !this.ownRequest.automationCheckedAt
      ? this.$t('shifts.joinShift.messagePendingAutoAcceptancePending')
      : this.$t('shifts.joinShift.messageManagerConflictResolutionRequired');
  }

  public beforeRouteEnter(_to, _from, next) {
    next((vm: this) => {
      const isJoinShiftPossible = vm.currentEmployment?.isEmployee
        && !vm.shift?.canManage
        && !vm.hasShiftAlreadyStarted
        && !vm.isCurrentlyAssigned;

      if (!isJoinShiftPossible) {
        const { matched } = vm.$route;
        // FAQ: last element is the route itself, so the one before is the route's parent
        const routeParent = matched[matched.length - 2] || matched[0];
        vm.$router.push({ name: routeParent.name, replace: true });
      }
    });
  }

  public render() {
    if (this.ownRequest) {
      return (
        <div class={styles.joinShift}>
          <p class={styles.joinShiftMessage}>
            {this.$t('shifts.joinShift.messageCancel')}
          </p>

          {
            this.messageAutoAcceptState && (
              <p class={styles.joinShiftMessageState}>
                {this.messageAutoAcceptState}
              </p>
            )
          }

          <Button
            class={styles.joinShiftButton}
            color={ButtonColor.ERROR}
            disabled={this.isSubmitting}
            onClick={this.onCancelJoinRequest}
            size={Size.SMALL}
            kind={ButtonKind.GHOST}
          >
            {this.$t('shifts.joinShift.buttonCancel')}
          </Button>

          <DialogConfirmDelete
            isOpen={this.isDeleteConfirmDialogOpen}
            isSubmitting={this.isSubmitting}
            onCancel={this.onCancelJoinRequestCancel}
            onConfirm={this.onCancelJoinRequestConfirm}
            title={this.$t('shifts.joinShift.titleConfirmCancel')}
          >
            {this.$t('shifts.joinShift.messageConfirmCancel')}
          </DialogConfirmDelete>
        </div>
      );
    }

    return (
      <div class={styles.joinShift}>
        <p class={styles.joinShiftMessage}>
          {this.$t('shifts.joinShift.messageCreate')}
        </p>

        <Button
          class={styles.joinShiftButton}
          color={ButtonColor.SUCCESS}
          disabled={this.isSubmitting}
          onClick={this.onCreateJoinRequest}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
        >
          {this.$t('shifts.joinShift.buttonCreate')}
        </Button>

        <DialogShiftConflicts
          isSelf={true}
          isOpen={!!this.conflicts.length}
          conflicts={this.conflicts}
          onCancel={() => { this.conflicts = []; }}
          onIgnore={e => this.onCreateJoinRequest(e, true)}
        />
      </div>
    );
  }
}
