import { GQLAbsenceState } from 'codegen/gql-types';
import FilterPopup from 'components/table/filter-popup/FilterPopup';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class FilterPopupState extends TsxComponent<{
  close: () => void;
  selected: string[] | null;
}, {
  onFilterChange: string[];
}> {
  @Emit('filterChange')
  public onFilterChange({ payload }) {
    if (this.selected && this.selected.includes(payload.id)) {
      return this.selected.filter(o => o !== payload.id);
    }

    return [...(this.selected || []), payload.id];
  }

  @Prop()
  public close: () => void;

  @Prop()
  public selected: string[] | null;

  public render() {
    return (
      <FilterPopup
        items={
          Object.values(GQLAbsenceState).map(val => ({
            id: val,
            isChecked: !!this.selected && this.selected.indexOf(val) !== -1,
            label: this.$t(`absence.state.${val}`),
          }))
        }
        onFilterChange={this.onFilterChange}
        onClose={this.close}
      />
    );
  }
}
