import { getUpdatedItems, StoreItem } from 'components/calendar-common/common/store/Helpers';
import DefaultActions from 'components/calendar-common/DefaultActions';
import { getSecondaryColor } from 'components/calendar-common/helpers/colors/Colors';
import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { CalendarNamespace } from '../Enums';
import { TOGGLE_POSITION } from './Actions';
import Mutations from './Mutations';

export const positionsNS = namespace('calendar/positions');
export const calendarPrintPositionsNS = namespace(`${CalendarNamespace.PRINT}/positions`);
export const monthPositionsNS = namespace('calendar/positions/monthPositions');

export interface StoreState {
  items: StoreItem[];
}

export interface ModuleState extends StoreState {
  monthPositions: {
    openPositionIds: number[];
  };
}

export const store = (parentNamespace: CalendarNamespace): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    [Mutations.SET_POSITIONS](state, items) {
      state.items = items;
    },
    [Mutations.UPDATE_POSITIONS](state, items) {
      state.items = getUpdatedItems(state.items, items);
    },
  },
  actions: {
    [DefaultActions.SET]({ commit }, items) {
      commit(Mutations.SET_POSITIONS, items);
    },
    [DefaultActions.UPDATE]({ commit }, items) {
      commit(Mutations.UPDATE_POSITIONS, items);
    },
  },
  getters: {
    positions(state) {
      return state.items
        .map(({ position, id, sort }) => ({
          ...position,
          sort,
          locationsPositionId: id,
          secondaryColor: getSecondaryColor(position.color),
        }))
        .sort((positionA, positionB) => positionA.sort - positionB.sort);
    },
    filteredPositions(state, getters, rootState, rootGetters) {
      const locationsPositionIds = rootGetters[`${parentNamespace}/filters/locationsPositionIds`];

      return getters.positions
        .filter(it => locationsPositionIds.length === 0 || locationsPositionIds.includes(
          it.locationsPositionId,
        ));
    },
    positionIds(state, getters) {
      return getters.filteredPositions.map(position => position.locationsPositionId);
    },
  },
  modules: {
    monthPositions: {
      namespaced: true,
      state: {
        openPositionIds: [],
      },
      mutations: {
        [Mutations.OPEN_POSITION](state, positionId) {
          state.openPositionIds = [...state.openPositionIds, positionId];
        },
        [Mutations.CLOSE_POSITION](state, positionId) {
          state.openPositionIds = state.openPositionIds.filter(it => it !== positionId);
        },
      },
      actions: {
        [TOGGLE_POSITION]({ commit, state }, positionId) {
          if (state.openPositionIds.includes(positionId)) {
            commit(Mutations.CLOSE_POSITION, positionId);
          } else {
            commit(Mutations.OPEN_POSITION, positionId);
          }
        },
      },
    },
  },
});
