import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { difference } from 'lodash';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import { employmentEvaluationsNS } from 'store/employment-evaluations/Store';
import type { CreateEmploymentEvaluationFunction } from 'store/employment-evaluations/Store';
import { evaluationTagsNS } from 'store/employment-evaluation-tags/Store';
import type { CreateEvaluationTagFunction, DeleteEvaluationTagFunction } from 'store/employment-evaluation-tags/Store';
import { evaluationsNS, GQLEvaluation } from 'store/evaluations/Store';
import { Action } from 'store/normalized-store';
import { Tag } from 'store/tags/Store';
import { isStartBeforeEnd } from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import { executeStoreActionWithFailureSnackbar } from 'utils/store';
import { filterUndefined } from 'utils/utils';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Form, { FormState, Slot } from './Form';
import SectionBreaksContainerEmployment from '../section-breaks/SectionBreaksContainerEmployment';
import SectionBreaksAction from '../section-breaks/store/Action';
import { sectionBreaksEmploymentNS } from '../section-breaks/store/Store';
import type { Break, IsValidFunction } from '../section-breaks/store/Store';
import SectionPayContainerEmployment from '../section-pay/SectionPayContainerEmployment';
import SectionPayAction from '../section-pay/store/Action';
import { sectionPayEmploymentNS } from '../section-pay/store/Store';
import { EvaluationContext } from '../types';
import type { Evaluation } from '../types';

interface Props {
  evaluation: Evaluation;
  isBringShiftsEnabled?: boolean;
  isDisabled?: boolean;
  isEmploymentEvaluationPresent?: boolean;
  staffShiftsTags: Tag[];
}
@Component
export default class FormContainerEmployment extends TsxComponent<Props,
{
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] } >,
  ) => void;
  onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}> {
  protected formState: FormState = {} as FormState;

  protected isSubmitting = false;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @employmentEvaluationsNS.Action(Action.CREATE)
  protected createEmploymentEvaluation: CreateEmploymentEvaluationFunction;

  @evaluationsNS.Getter
  protected hasEvaluationPayShowRight: (evaluation: GQLEvaluation) => boolean;

  @evaluationsNS.Getter
  protected hasEvaluationPayManageRight: (evaluation: GQLEvaluation) => boolean;

  @evaluationTagsNS.Action(Action.CREATE)
  protected createEvaluationTag: CreateEvaluationTagFunction;

  @evaluationTagsNS.Action(Action.DELETE)
  protected deleteEvaluationTag: DeleteEvaluationTagFunction;

  @sectionPayEmploymentNS.Action(SectionPayAction.DISPATCH_REMOVE)
  protected dispatchRemovePayments: (evaluationId: number) => Promise<void>;

  @sectionPayEmploymentNS.Action(SectionPayAction.DISPATCH_UPDATE)
  protected dispatchUpdatePayments: (evaluationId: number) => Promise<void>;

  @sectionPayEmploymentNS.Action(SectionPayAction.DISPATCH_CREATE)
  protected dispatchCreatePayments: (evaluationId: number) => Promise<void>;

  @sectionBreaksEmploymentNS.Action(SectionBreaksAction.DISPATCH_REMOVE)
  protected dispatchRemoveBreaks: (evaluationId: number) => Promise<void>;

  @sectionBreaksEmploymentNS.Action(SectionBreaksAction.DISPATCH_UPDATE)
  protected dispatchUpdateBreaks: (evaluationId: number) => Promise<void>;

  @sectionBreaksEmploymentNS.Action(SectionBreaksAction.DISPATCH_CREATE)
  protected dispatchCreateBreaks: (evaluationId: number) => Promise<void>;

  @sectionBreaksEmploymentNS.Getter('isValid')
  protected isBreaksValid: IsValidFunction;

  @sectionBreaksEmploymentNS.State('breaks')
  protected breaks: Break[];

  @Prop()
  public evaluation: Props['evaluation'];

  @Prop()
  public isBringShiftsEnabled?: boolean;

  @Prop()
  public isDisabled?: boolean;

  @Prop()
  public isEmploymentEvaluationPresent?: boolean;

  @Prop()
  public staffShiftsTags: Tag[];

  @Watch('evaluation', { immediate: true })
  protected onEvaluationChange() {
    this.formState = {
      startsAt: new Date(this.evaluation.startsAt),
      endsAt: new Date(this.evaluation.endsAt),
      note: this.evaluation.note,
      unpaidBreak: this.evaluation.untimedBreak ?? this.evaluation.break,
      tags: this.isEmploymentEvaluationPresent
        ? this.evaluation.evaluationTags?.map(({ tag }) => tag.id.toString()) || []
        : this.evaluation.staffShiftsTags.map(({ tag }) => tag.id.toString()),
    };
  }

  protected onInput<T extends keyof FormState>(
    { payload: { field, value } }: EventPayload<{ field: T; value: FormState[T] } >,
  ) {
    this.formState[field] = value;
  }

  protected async updatePayments() {
    await this.dispatchRemovePayments(this.evaluation.staffShiftId);
    await this.dispatchUpdatePayments(this.evaluation.staffShiftId);
    await this.dispatchCreatePayments(this.evaluation.staffShiftId);
  }

  protected async updateBreaks() {
    await this.dispatchRemoveBreaks(this.evaluation.staffShiftId);
    await this.dispatchUpdateBreaks(this.evaluation.staffShiftId);
    await this.dispatchCreateBreaks(this.evaluation.staffShiftId);
  }

  protected async updateTags(tagsUpdated: number[]) {
    const tagsExisting = this.isEmploymentEvaluationPresent
      ? this.evaluation.evaluationTags?.map(o => o.tag.id) || []
      : [];

    const toRemove = difference(tagsExisting, tagsUpdated)
      .map(tagId => this.evaluation.evaluationTags?.find(o => o.tag.id === tagId))
      .filter(filterUndefined);
    const toCreate = difference(tagsUpdated, tagsExisting);

    await Promise.all([
      ...toRemove.map(evaluationTag => executeStoreActionWithFailureSnackbar(
        this,
        {
          id: evaluationTag.id,
          evaluationId: this.evaluation.id,
          evaluationTagId: evaluationTag.id,
        },
        this.deleteEvaluationTag,
        '',
      )),
      ...toCreate.map(tagId => executeStoreActionWithFailureSnackbar(
        this,
        { evaluationId: this.evaluation.id, tagId },
        this.createEvaluationTag,
        '',
      )),
    ]);
  }

  protected async onSubmit({ event }: EventPayload<void>) {
    event.preventDefault();

    this.isSubmitting = true;

    const { tags, unpaidBreak, ...evaluation } = this.formState;

    await this.createEmploymentEvaluation({
      staffShiftId: this.evaluation.staffShiftId,
      employmentEvaluation: deepTransformDates({
        ...evaluation,
        break: null,
        untimedBreak: unpaidBreak,
      }),
    });

    await Promise.all([
      this.updatePayments(),
      this.updateBreaks(),
      this.updateTags(tags.map(id => Number.parseInt(id, 10))),
    ]);

    this.isSubmitting = false;

    this.$emit('submit', createEventPayload<void>(event, undefined));
  }

  protected get isSectionPayShown() {
    return !!this.evaluation.payments.length && (
      this.hasEvaluationPayShowRight(this.evaluation)
        || this.hasEvaluationPayManageRight(this.evaluation)
        || this.isSelfEvaluation
    );
  }

  protected get isSelfEvaluation() {
    return this.evaluation.employment.id === this.currentEmploymentId;
  }

  protected get isValid() {
    return this.formState.unpaidBreak !== undefined
      && !Number.isNaN(this.formState.unpaidBreak)
      && isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt, true)
      && this.isBreaksValid(this.formState.startsAt, this.formState.endsAt, this.$timeZone.value);
  }

  public render() {
    return (
      <Form
        context={EvaluationContext.EMPLOYMENT}
        evaluation={this.evaluation}
        formState={this.formState}
        isBringShiftsEnabled={this.isBringShiftsEnabled}
        isDisabled={this.isDisabled}
        isSubmitting={this.isSubmitting}
        isValid={this.isValid}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
        tags={this.staffShiftsTags}
      >
        <SectionBreaksContainerEmployment
          evaluationBreaks={this.evaluation.breaks}
          evaluationEndsAt={this.formState.endsAt}
          evaluationStartsAt={this.formState.startsAt}
          isDisabled={this.isDisabled || this.isSubmitting}
          isEmploymentEvaluationPresent={this.isEmploymentEvaluationPresent}
          onUnpaidBreakChange={
            ({ event, payload: { value } }) => this.onInput({
              event,
              payload: { field: 'unpaidBreak', value },
            })
          }
          slot={Slot.SECTION_BREAKS}
          unpaidBreak={this.formState.unpaidBreak}
        />

        {
          this.isSectionPayShown && (
            <SectionPayContainerEmployment
              breaks={this.breaks}
              evaluation={this.evaluation}
              isEmploymentEvaluationPresent={this.isEmploymentEvaluationPresent}
              formState={this.formState}
              isDisabled={
                this.isDisabled || this.isSubmitting || !this.currentCompany?.editShiftPayments
              }
              slot={Slot.SECTION_PAY}
            />
          )
        }
      </Form>
    );
  }
}
