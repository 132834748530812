import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Size } from 'components/types';
import Icon from 'components/icons/Icon';
import styles from './breadcrumbs.css';
import { IconName } from '../icons/types';

export interface Props {
  size?: Size.SMALL | Size.LARGE;
}

@Component
export default class Breadcrumbs extends TsxComponent<Props> {
  @Prop({ default: Size.SMALL })
  public size: Props['size'];

  public render() {
    return (
      <nav aria-label="Breadcrumb">
        <ol class={styles.breadcrumbsList}>
          {
            this.$slots.default?.flatMap((item, index, slots) => (
              index === slots.length - 1
                ? <li class={{
                  [styles.breadcrumbsListItem]: true,
                  [styles.breadcrumbsListItemLarge]: this.size === Size.LARGE,
                  [styles.breadcrumbsListItemSelected]: true,
                }} key={`${index}#${item.text}`}>{item}</li>
                : [
                  <li class={{
                    [styles.breadcrumbsListItem]: true,
                    [styles.breadcrumbsListItemLarge]: this.size === Size.LARGE,
                  }}
                  key={`${index}#${item.text}`}>{item}</li>,
                  <li aria-hidden="true" class={styles.breadcrumbsListItemIcon} key={`${index}#${item.text}#chevron`}>
                    <Icon name={IconName.ARROW_NEXT}
                      size={this.size === Size.SMALL ? Size.SMALL : Size.LARGE} />
                  </li>,
                ]
            ))
          }
        </ol>
      </nav>
    );
  }
}
