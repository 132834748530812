import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import InputTextArea from 'components/form/input-text-area/InputTextArea';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'src/utils/events';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import type { FormState } from '../types';

import styles from './section-notes.css';

export interface Props {
  isDisabled?: boolean;
  positionNote?: string;
  managerNote?: string;
  isManagerNoteVisible?: boolean;
  publicNote?: string;
}

interface Events {
  onChange: <T extends keyof FormState>(e: EventPayload<{ field: T; value: FormState[T] }>) => void;
}

@Component
class SectionNotes extends TSXComponent<Props, Events> {
  @Prop()
  public positionNote: Props['positionNote'];

  @Prop()
  public managerNote: Props['managerNote'];

  @Prop()
  public isManagerNoteVisible: Props['isManagerNoteVisible'];

  @Prop()
  public publicNote: Props['publicNote'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  protected onInput(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.$emit('change', createEventPayload(
      e,
      {
        field: e.target.name,
        value: e.target.value,
      },
    ));
  }

  public render() {
    return (
      <FormSection heading={this.$t('shifts.dialog.headingNote')}>
        {this.positionNote && (
          <InputTextArea
            // Can onĺy be changed when editing the position
            disabled
            name="positionNote"
            class={[styles.sectionNotesRow, styles.sectionNotesRowFullWidth]}
            label={this.$t('shifts.dialog.labelPositionNote')}
            value={this.positionNote}
          />
        )}

        { !!this.isManagerNoteVisible && (
          <InputTextArea
            disabled={this.isDisabled}
            name="managerNote"
            class={[styles.sectionNotesRow, styles.sectionNotesRowFullWidth]}
            label={this.$t('shifts.dialog.labelManagerNote')}
            value={this.managerNote}
            onInput={this.onInput}
          />
        )}

        <InputTextArea
          disabled={this.isDisabled}
          class={[styles.sectionNotesRow, styles.sectionNotesRowFullWidth]}
          label={this.$t('shifts.dialog.labelPublicNote')}
          value={this.publicNote}
          name="publicNote"
          onInput={this.onInput}
        />
      </FormSection>
    );
  }
}

export default SectionNotes;
