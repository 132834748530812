import InputSelect from 'components/form/input-select/InputSelect';
import { ShiftRotation } from 'src/store/shift-rotations/Store';
import { EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

interface Props {
  rotations: ShiftRotation[];
  rotationId?: string;
}

@Component
export default class SectionRotations extends TsxComponent<Props, {
  onChange: (payload: EventPayload<string, HTMLButtonElement>) => void;
}> {
  @Prop()
  protected readonly rotations: Props['rotations'];

  @Prop()
  protected readonly rotationId: Props['rotationId'];

  protected onChange(payload: EventPayload<string | number, HTMLInputElement>) {
    this.$emit('change', payload);
  }

  public render() {
    return (
      <InputSelect
        label={this.$t('createShiftplan.modal.labelRotation')}
        name="rotationId"
        onChange={this.onChange}
        options={this.rotations.map(item => ({
          label: item.name,
          value: item.id.toString(),
        }))}
        placeholder={this.$t('createShiftplan.modal.placeholderRotation')}
        required={true}
        value={this.rotationId}
      />
    );
  }
}
