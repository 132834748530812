import HeaderCell from 'components/table/header-cell/HeaderCell';
import { LoadingState, SetFilterPayload, SetSortPayload } from 'components/table/store/Store';
import { SortDirection } from 'components/table/types';
import type { TableColumn, TableColumns } from 'components/table/types';
import Table, { TableSlot } from 'components/table/Table';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import tableStyles from 'components/table/table.css';
import Subheader, { Slot } from 'components/subheader/Subheader';
import FilterButton from 'components/table/filter-button/FilterButton';
import Action from 'components/table/store/Action';
import { employmentsNS } from 'components/employments/store/Store';
import type { FetchAllEmploymentsFunction } from 'components/employments/store/Store';
import { Action as StoreAction } from 'store/normalized-store';
import { absenceReasonsNS } from 'store/absence-reasons/Store';
import type { FetchAllFunction as FetchAllAbsencesFunction } from 'store/absence-reasons/Store';
import type { GQLAbsencesQuery } from 'codegen/gql-types';
import { addYears } from 'date-fns';
import {
  endOf,
  getDurationStringInTimeZone,
  startOf,
  Unit,
} from 'src/utils/date-related';
import { SelectedTimeframe } from 'components/datepicker/types';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import Button from 'components/form/button/Button';
import CellActions from './cell-actions/CellActions';
import CellAttachment from './cell-attachment/CellAttachment';
import CellDuration from './cell-duration/CellDuration';
import CellEmployment from './cell-employment/CellEmployment';
import CellPaid from './cell-paid/CellPaid';
import CellReason from './cell-reason/CellReason';
import CellState from './cell-state/CellState';
import CellTimeframe from './cell-timeframe/CellTimeframe';
import DropdownExport from './dropdown-export/DropdownExport';
import FilterPopupReason from './filter-popup-reason/FilterPopupReason';
import FilterPopupState from './filter-popup-state/FilterPopupState';
import FilterPopupTrueFalse from './filter-popup-true-false/FilterPopupTrueFalse';
import { absencesTableNS } from './store/Store';
import type { Filters } from './store/Store';
import { TimeframeFilterKind } from './types';
import type { Absence } from './types';
import styles from './absences.css';
import FilterPopupTimeframe from './filter-popup-timeframe/FilterPopupTimeframe';
import Summary from './summary/Summary';
import FilterPopupEmployments from './filter-popup-employments/FilterPopupEmployments';
import FilterPopupLocation from './filter-popup-location/FilterPopupLocation';
import FilterPopupEmploymentStatus from './filter-popup-employment-status/FilterPopupEmploymentStatus';
import { Route } from './routes';

@Component
export default class Absences extends TsxComponent<{}> {
  @absenceReasonsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAbsenceReasons: FetchAllAbsencesFunction;

  @employmentsNS.Action(StoreAction.FETCH_ALL)
  protected fetchEmployments: FetchAllEmploymentsFunction;

  @absencesTableNS.State
  private data: GQLAbsencesQuery['absences']['items'];

  @absencesTableNS.State
  private page: number;

  @absencesTableNS.State
  private perPage: number;

  @absencesTableNS.State
  private count: number;

  @absencesTableNS.State
  private loadingState: LoadingState;

  @absencesTableNS.State
  private filters: Filters;

  @absencesTableNS.State
  private sort: { key: 'startsAt'; direction: SortDirection };

  @absencesTableNS.Action(Action.SUBSCRIBE)
  private subscribe: () => Promise<void>;

  @absencesTableNS.Action(Action.UNSUBSCRIBE)
  private unsubscribe: () => Promise<void>;

  @absencesTableNS.Action(Action.SET_FILTER)
  private setFilter: (
    payload: SetFilterPayload<Filters, keyof Filters>
  ) => Promise<void>;

  @absencesTableNS.Action(Action.SET_PAGE)
  private setPage: (page: number) => Promise<void>;

  @absencesTableNS.Action(Action.SET_SORT)
  private setSort: (payload: SetSortPayload<Absence>) => Promise<void>;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  protected get timeframeLabel() {
    return getDurationStringInTimeZone(
      this.$timeZone.value,
      this.$i18n.i18next.language,
      this.filters.startsAt,
      this.filters.endsAt,
    );
  }

  protected get fields() {
    const fields: TableColumns<Absence, Filters> = [{
      label: this.$t('absence.table.headerStaffNumber'),
      width: '10%',
    },
    {
      label: this.$t('absence.table.headerEmployee'),
      width: '18%',
      isStretch: true,
      filter: {
        key: 'employmentIds',
        component: FilterPopupEmployments,
        isActive: this.filters.employmentIds && this.filters.employmentIds.length > 0,
      },
    },
    {
      rowIdKey: 'startsAt',
      label: this.timeframeLabel,
      width: '18%',
      isSortable: true,
      filter: {
        key: 'startsAt',
        icon: 'calendar',
        component: FilterPopupTimeframe,
      },
    },
    {
      label: this.$t('absence.table.headerDays'),
      width: '8%',
    },
    {
      label: this.$t('absence.table.headerPaid'),
      filter: {
        key: 'paid',
        component: FilterPopupTrueFalse,
        translationPrefix: 'absence.paid',
        isActive: this.filters.paid !== undefined && this.filters.paid !== null,
      },
      width: '8%',
    },
    {
      label: this.$t('absence.table.headerReason'),
      width: '10%',
      filter: {
        key: 'absenceReasonIds',
        component: FilterPopupReason,
        isActive: this.filters.absenceReasonIds && this.filters.absenceReasonIds.length > 0,
      },
    }];

    if (this.currentCompany?.isAbsenceAttachmentsAllowed) {
      fields.push({
        label: this.$t('absence.table.headerAttachment'),
        width: '8%',
        filter: {
          key: 'withAttachment',
          component: FilterPopupTrueFalse,
          translationPrefix: 'absence.attachment',
          isActive: this.filters.withAttachment !== undefined
            && this.filters.withAttachment !== null,
        },
      });
    }

    fields.push(
      {
        label: this.$t('absence.table.headerStatus'),
        width: '12%',
        filter: {
          key: 'states',
          component: FilterPopupState,
          isActive: this.filters.states && this.filters.states.length > 0,
        },
      },
      {
        label: '',
        width: '4%',
      },
    );

    return fields;
  }

  protected get pagination() {
    return {
      page: this.page,
      perPage: this.perPage,
      count: this.count,
    };
  }

  protected getFilterPopupSlot(field: TableColumn<Absence, Filters>) {
    return ({ close }: { close: () => void }) => {
      if (!field.filter) {
        return undefined;
      }

      const { key, component: FilterComponent } = field.filter;
      // special case for timeframe filter
      if (key === 'startsAt') {
        return (
          <FilterPopupTimeframe
            onFilterChange={(value: SelectedTimeframe
            & { timeframeKind: TimeframeFilterKind.CUSTOM }
            | { timeframeKind: TimeframeFilterKind.CURRENT_YEAR
            | TimeframeFilterKind.NEXT_YEAR
            | TimeframeFilterKind.PREVIOUS_YEAR;
            }) => {
              const date = new Date();
              let startDate;
              let endDate;
              switch (value.timeframeKind) {
                case TimeframeFilterKind.CUSTOM:
                  startDate = value.startsAt;
                  endDate = value.endsAt;
                  break;
                case TimeframeFilterKind.CURRENT_YEAR:
                  startDate = startOf(date, Unit.YEAR, this.$timeZone.value);
                  endDate = endOf(date, Unit.YEAR, this.$timeZone.value);
                  break;
                case TimeframeFilterKind.PREVIOUS_YEAR: {
                  const previousYearDate = addYears(date, -1);
                  startDate = startOf(previousYearDate, Unit.YEAR, this.$timeZone.value);
                  endDate = endOf(previousYearDate, Unit.YEAR, this.$timeZone.value);
                  break;
                }
                case TimeframeFilterKind.NEXT_YEAR: {
                  const nextYearDate = addYears(date, 1);
                  startDate = startOf(nextYearDate, Unit.YEAR, this.$timeZone.value);
                  endDate = endOf(nextYearDate, Unit.YEAR, this.$timeZone.value);
                  break;
                }
                default:
                  return;
              }
              // TODO: use array signature introduced in other PR
              this.setFilter({ key: 'startsAt', value: startDate });
              this.setFilter({ key: 'endsAt', value: endDate });
              this.setFilter({ key: 'timeframeKind', value: value.timeframeKind });
            }}
            timeframe={{
              startsAt: this.filters.startsAt,
              endsAt: this.filters.endsAt,
            }}
            timeframeKind={this.filters.timeframeKind}
          />
        );
      }

      return (
        <FilterComponent
          close={close}
          onFilterChange={(value: Filters[typeof key]) => this.setFilter({ key, value })}
          selected={
            this.filters[key] === undefined
              ? null
              : this.filters[key]
          }
          translationPrefix={field.filter.translationPrefix}
        />
      );
    };
  }

  public mounted() {
    this.subscribe();
    this.fetchAbsenceReasons();
    this.fetchEmployments();
  }

  public beforeDestroy() {
    this.unsubscribe();
  }

  public render() {
    return (
      <div>
        <Subheader title={this.$t('absence.title')}>
          <template slot={Slot.RIGHT}>
            <DropdownExport class={styles.headerDropdown} />
            <Button
              onClick={() => this.$router.push({ name: Route.DIALOG_NEW })}
              size={Size.SMALL}
            >
              {this.$t('absence.header.buttonCreateAbsence')}
            </Button>
          </template>
        </Subheader>

        <Summary />

        <Table
          class={styles.absencesTable}
          loadingState={this.loadingState}
          rows={this.data}
          rowIdKey="id"
          pagination={this.pagination}
          fields={this.fields}
          onPageChange={({ payload }) => this.setPage(payload.page)}
          scopedSlots={{
            header: ({ fields }) => fields.map((field) => {
              const sort = field.isSortable
                ? {
                  direction: field.rowIdKey === this.sort?.key
                    ? this.sort?.direction
                    : SortDirection.UNDEFINED,
                }
                : undefined;

              return (
                <HeaderCell
                  filter={field.filter}
                  isStretch={field.isStretch}
                  onSortDirectionChange={({ payload }) => (
                    field.rowIdKey && this.setSort({
                      key: field.rowIdKey,
                      direction: payload.direction,
                    })
                  )}
                  scopedSlots={{
                    popup: this.getFilterPopupSlot(field),
                  }}
                  sort={sort}
                  style={{ width: field.width }}
                >
                  {field.label}
                </HeaderCell>
              );
            }),
            row: ({ rowData }) => [
              <td class={tableStyles.tableBodyCell}>{rowData.employment.staffNumber}</td>,
              <CellEmployment employment={rowData.employment} />,
              <CellTimeframe
                startsAt={new Date(rowData.startsAt)}
                endsAt={new Date(rowData.endsAt)}
              />,
              <CellDuration number={rowData.days} />,
              <CellPaid paid={rowData.paid} />,
              <CellReason reason={rowData.absenceReason} />,
              this.currentCompany?.isAbsenceAttachmentsAllowed && (
                <CellAttachment absence={rowData} />
              ),
              <CellState absence={rowData} />,
              <CellActions
                absence={rowData}
                onEditClick={({ payload }) => (
                  this.$router.push({ name: Route.DIALOG_EDIT, params: { id: payload.toString() } })
                )}
              />,
            ],
          }}
        >
          <template slot={TableSlot.AUX_CONTROLS}>
            <FilterButton
              icon={IconName.LOCATION}
              isActive={this.filters.locationIds && this.filters.locationIds.length > 0}
              size={Size.MEDIUM}
              scopedSlots={{
                popup: this.getFilterPopupSlot({
                  label: 'Location',
                  filter: {
                    component: FilterPopupLocation,
                    key: 'locationIds',
                  },
                }),
              }}
              title={this.$t('absence.locations')}
            />

            <FilterButton
              icon={IconName.FILTER}
              isActive={
                this.filters.employmentStatuses && this.filters.employmentStatuses.length > 0
              }
              size={Size.MEDIUM}
              scopedSlots={{
                popup: this.getFilterPopupSlot({
                  label: 'Location',
                  filter: {
                    component: FilterPopupEmploymentStatus,
                    key: 'employmentStatuses',
                  },
                }),
              }}
              title={this.$t('absence.employmentStatus.title')}
            />
          </template>
        </Table>

        <router-view />
      </div>
    );
  }
}
