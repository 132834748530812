import { getUpdatedItems } from 'components/calendar-common/common/store/Helpers';
import DefaultActions from 'components/calendar-common/DefaultActions';
import Employment, { EmploymentConstructorProps } from 'components/calendar-common/employments/Employment';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { locationPositionFilter } from 'components/calendar-common/employments/utils';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import { sortByLastName } from 'src/utils/utils';
import Mutations from './Mutations';

export const calendarPrintEmploymentsNS = namespace(`${CalendarNamespace.PRINT}/employments`);

export interface StoreState {
  items: EmploymentConstructorProps[];
}

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    [Mutations.SET_EMPLOYMENTS](state, items) {
      state.items = items;
    },
    [Mutations.UPDATE_EMPLOYMENTS](state, items) {
      state.items = getUpdatedItems(state.items, items);
    },
  },
  actions: {
    [DefaultActions.SET]({ commit }, items) {
      commit(Mutations.SET_EMPLOYMENTS, items);
    },
    [DefaultActions.UPDATE]({ commit }, items) {
      commit(Mutations.UPDATE_EMPLOYMENTS, items);
    },
  },
  getters: {
    employments(state, getters, rootState, rootGetters) {
      const shiftsEmployments: Record<string, number[]> = rootGetters[`${CalendarNamespace.PRINT}/shifts/shiftsEmployments`] || {};
      const employments = state.items
        .map((employment): EmploymentConstructorProps => ({
          ...employment,
          presentLocationsPositionIds: Array.from(shiftsEmployments[employment.id] || []),
        }))
        .sort(sortByLastName);
      return employments.map(it => new Employment(it));
    },
    filteredEmployments(state, getters, rootState, rootGetters) {
      if (!rootState.auth.currentCompany || !rootState.auth.currentEmploymentId) {
        return [];
      }

      const locationsPositionIds = rootGetters['calendarPrint/filters/locationsPositionIds'];

      return getters.employments
        .filter(locationPositionFilter(locationsPositionIds));
    },
    employmentsByPositions(state, getters, rootState, rootGetters) {
      return rootGetters[`${CalendarNamespace.PRINT}/positions/filteredPositions`].reduce(
        (acc, it) => {
          acc[it.locationsPositionId] = getters.filteredEmployments
            .filter(employment => employment.presentLocationsPositionIds
              .includes(it.locationsPositionId));
          return acc;
        },
        {},
      );
    },
  },
});
