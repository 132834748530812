import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import SpIcon from 'components/sp-icon/SpIcon';
import Tooltip from 'components/tooltip/Tooltip';
import { getRandomString } from 'utils/random';

import styles from './checkbox-general.css';

@Component
export default class CheckboxGeneral extends TsxComponent<
Partial<HTMLInputElement> & {
  icon: string;
  id?: string;
  label: string;
  name: string;
  value?: string;
}, EventsOn<HTMLInputElement>> {
  @Prop()
  public icon: string;

  @Prop({ default: () => `inp-${getRandomString()}` })
  protected id: string;

  @Prop()
  public label: string;

  @Prop()
  public name: string;

  @Prop()
  public value?: string;

  public render() {
    return (
      <div class={styles.checkboxGeneral}>
        <input
          {...{
            attrs: { ...this.$attrs },
            on: { ...this.$listeners },
          }}
          class={styles.checkboxGeneralInput}
          id={this.id}
          name={this.name}
          type="checkbox"
          value={this.value}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label class={styles.checkboxGeneralLabel} for={this.id}>
          <Tooltip class={styles.checkboxGeneralTooltip} text={this.label}>
            <SpIcon class={styles.checkboxGeneralIcon} name={this.icon} label={this.label} />
            <span class={styles.checkboxGeneralOther}>{this.$slots.default}</span>
          </Tooltip>
        </label>
      </div>
    );
  }
}
