import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEmploymentEvaluationPaymentMutation,
  GQLCreateEmploymentEvaluationPaymentMutationVariables,
  GQLUpdateEmploymentEvaluationPaymentMutation,
  GQLUpdateEmploymentEvaluationPaymentMutationVariables,
  GQLDeleteEmploymentEvaluationPaymentMutation,
  GQLDeleteEmploymentEvaluationPaymentMutationVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateEmploymentEvaluationPaymentGql from './queries/CreateEmploymentEvaluationPayment.gql';
import DeleteEmploymentEvaluationPaymentGql from './queries/DeleteEmploymentEvaluationPayment.gql';
import UpdateEmploymentEvaluationPaymentGql from './queries/UpdateEmploymentEvaluationPayment.gql';
import { Payment } from '../evaluation-payments/Store';

export const employmentEvaluationPaymentsNS = namespace('employmentEvaluationPayments');

export type StoreState = ById<Payment>;

export type CreateEmploymentEvaluationPaymentFunction = (
  payload: Omit<GQLCreateEmploymentEvaluationPaymentMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteEmploymentEvaluationPaymentFunction = (
  payload: Omit<GQLDeleteEmploymentEvaluationPaymentMutationVariables & { id: number }, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateEmploymentEvaluationPaymentFunction = (
  payload: Omit<GQLUpdateEmploymentEvaluationPaymentMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getEmploymentEvaluationPaymentsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
    getters: {
      getByStaffShiftId: (state: StoreState) => (staffShiftId: number) => (
        Object.values<Payment>(state.byId).filter(item => item.staffShiftId === staffShiftId)
      ),
    },
  });

  const create: ActionProvider<
  GQLCreateEmploymentEvaluationPaymentMutation,
  GQLCreateEmploymentEvaluationPaymentMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEmploymentEvaluationPaymentFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: CreateEmploymentEvaluationPaymentGql,
      resultKey: 'createEmploymentEvaluationPayment',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    });
  };

  const remove: ActionProvider<
  GQLDeleteEmploymentEvaluationPaymentMutation,
  GQLDeleteEmploymentEvaluationPaymentMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteEmploymentEvaluationPaymentFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: DeleteEmploymentEvaluationPaymentGql,
      resultKey: 'deleteEmploymentEvaluationPayment',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    });
  };

  const update: ActionProvider<
  GQLUpdateEmploymentEvaluationPaymentMutation,
  GQLUpdateEmploymentEvaluationPaymentMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateEmploymentEvaluationPaymentFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: UpdateEmploymentEvaluationPaymentGql,
      resultKey: 'updateEmploymentEvaluationPayment',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    });
  };

  return createNormalizedStore<Payment, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getEmploymentEvaluationPaymentsStore;
