import { differenceInDays } from 'date-fns';
import { ShiftRotation } from 'src/store/shift-rotations/Store';
import {
  CalendarAbsencesScopedSettings, LocationScopedSettings, ShiftplanScopedSettings, UiSettings,
} from 'store/ui-settings/Store';
import {
  PrintViewKind, SlotDisplayStyle, TimeframeKind, ViewKind,
} from '../../calendar-common/Enums';
import { FiltersInput } from '../../calendar-common/filters/Store';
import type { SelectedTimeframe } from '../../datepicker/types';

const buildSharedSettings = (
  props: Required<Omit<UiSettings['shared'], 'timeframeCustomDuration'>>
  & { timeframe: SelectedTimeframe },
) => {
  const settings: UiSettings['shared'] = {
    viewKind: props.viewKind,
    printViewKind: props.printViewKind,
    filters: props.filters,
    timeframeKind: props.timeframeKind,
    slotDisplayStyle: props.slotDisplayStyle,
    isShiftplanFiltersSidebarCollapsed: props.isShiftplanFiltersSidebarCollapsed,
  };

  if (props.timeframeKind === TimeframeKind.FREE) {
    settings.timeframeCustomDuration = differenceInDays(
      props.timeframe.endsAt,
      props.timeframe.startsAt,
    );
  }

  return settings;
};

const buildShiftplanSettings = (
  props: ShiftplanScopedSettings & { timeframeKind: TimeframeKind },
) => {
  const settings: ShiftplanScopedSettings = {
    shiftRotationGroupIds: props.shiftRotationGroupIds,
  };

  if (props.timeframeKind === TimeframeKind.FREE) {
    settings.timeframeCustom = props.timeframe;
  } else {
    settings.timeframe = props.timeframe;
  }

  return settings;
};

const buildLocationSettings = (
  props: LocationScopedSettings,
) => {
  const settings: LocationScopedSettings = {
    tagIds: props.tagIds,
    locationsPositionIds: props.locationsPositionIds,
    shiftPresetIds: props.shiftPresetIds,
  };

  return settings;
};

const buildCalendarAbsencesSettings = (
  props: CalendarAbsencesScopedSettings,
) => {
  const settings: CalendarAbsencesScopedSettings = {
    // absences timeframe is always updated
    // to match currently displayed timeframe
    timeframe: props.timeframe,
    shiftRotationGroupIds: props.shiftRotationGroupIds,
  };

  return settings;
};

// eslint-disable-next-line import/prefer-default-export
export const buildCalendarSettings = (options: {
  shiftplanId?: number;
  locationid?: number;
  currentState: UiSettings;
  props: {
    viewKind: ViewKind;
    printViewKind: PrintViewKind;
    filters: FiltersInput;
    timeframeKind: TimeframeKind;
    slotDisplayStyle: SlotDisplayStyle;
    timeframe: SelectedTimeframe;
    shiftRotationGroupIds: number[] | null;
    tagIds: number[];
    locationsPositionIds: number[];
    shiftPresetIds: number[];
    isShiftplanFiltersSidebarCollapsed: boolean;
  };
}) => {
  const settings: UiSettings = {
    shared: {
      ...options.currentState.shared,
      ...buildSharedSettings(options.props),
    },
    calendarAbsencesScoped: {
      ...options.currentState.calendarAbsencesScoped,
      ...buildCalendarAbsencesSettings(options.props),
    },
    shiftplanScoped: { ...options.currentState.shiftplanScoped },
    locationScoped: { ...options.currentState.locationScoped },
  };

  if (options.shiftplanId) {
    settings.shiftplanScoped[options.shiftplanId] = {
      ...settings.shiftplanScoped[options.shiftplanId],
      ...buildShiftplanSettings(
        options.props,
      ),
    };
  }

  if (options.locationid) {
    settings.locationScoped[options.locationid] = {
      ...settings.locationScoped[options.locationid],
      ...buildLocationSettings(options.props),
    };
  }

  return settings;
};

export const getApplicableShiftRotationGroupIds = (
  ids: number[],
  shiftRotations?: ShiftRotation[],
) => {
  const availableRotationGroupIds = shiftRotations?.flatMap(
    shiftRotation => shiftRotation.shiftRotationGroups.map(({ id }) => id),
  );

  const availableRotationGroupIdsSet = new Set(availableRotationGroupIds);

  return ids?.filter(id => availableRotationGroupIdsSet.has(id));
};
