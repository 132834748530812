import Vue from 'vue';
import styles from './special-dates.css';

export default Vue.component('SpecialDates', {
  props: {
    specialDates: {
      default() {
        return [];
      },
      type: Array,
    },
  },
  // inject: ['bootstrapHovers'],
  mounted() {
    // this.bootstrapHovers.tooltip(this.$el);
  },
  updated() {
    // this.bootstrapHovers.tooltip(this.$el);
  },
  render() {
    return ((this.specialDates.length > 0) && <div class={styles.specialDates}>
      {this.specialDates.map(it => <div class={styles.specialDate}
        data-tooltip
        data-html="true"
        title={it.name}>
        {it.name}
      </div>)}
    </div>);
  },
});
