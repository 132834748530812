import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import Button from 'components/form/button/Button';
import Action from 'components/table/store/Action';
import { SetFilterPayload, SetSortPayload } from 'components/table/store/Store';
import { Action as StoreAction } from 'store/normalized-store';
import type { FetchAllShiftPresetsFunction } from 'store/shift-presets/Store';
import { shiftPresetsNS } from 'store/shift-presets/Store';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'src/utils/url';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './employment-assignment.css';
import {
  employmentAssignmentNS, EmploymentsTableData, Filters,
  StoreState as EmploymentAssignmentStoreState,
} from './store/Store';
import Sidebar from './sidebar/Sidebar';
import EmploymentsTable, { Sort } from './employments-table/EmploymentsTable';
import type { RotationGroup } from '../store/types';
import ActionButtonWrapper from '../action-button-wrapper/ActionButtonWrapper';
import { rotationWizardNS } from '../store/Store';
import RotationWizardAction from '../store/Action';
import { Route } from '../routes';

@Component
export default class EmploymentAssignment extends TsxComponent<{ isEdit: boolean }> {
  @Prop()
  protected isEdit: boolean;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @employmentAssignmentNS.State
  private rotationGroups: EmploymentAssignmentStoreState['rotationGroups'];

  @employmentAssignmentNS.State
  private data: EmploymentAssignmentStoreState['data'];

  @employmentAssignmentNS.State
  private page: EmploymentAssignmentStoreState['page'];

  @employmentAssignmentNS.State
  private perPage: EmploymentAssignmentStoreState['page'];

  @employmentAssignmentNS.State
  private loadingState: EmploymentAssignmentStoreState['loadingState'];

  @employmentAssignmentNS.State
  private count: EmploymentAssignmentStoreState['count'];

  @employmentAssignmentNS.State
  private filters: EmploymentAssignmentStoreState['filters'];

  @employmentAssignmentNS.State
  private selection: EmploymentAssignmentStoreState['selection'];

  @rotationWizardNS.State
  private rotationId: number | null;

  @employmentAssignmentNS.State
  private sort: EmploymentAssignmentStoreState['sort'];

  @employmentAssignmentNS.Action(Action.SUBSCRIBE)
  private subscribe: () => Promise<void>;

  @employmentAssignmentNS.Action(Action.UNSUBSCRIBE)
  private unsubscribe: () => Promise<void>;

  @employmentAssignmentNS.Action(Action.SET_SORT)
  private setSort: (
    payload: SetSortPayload<EmploymentsTableData>
  ) => Promise<void>;

  @employmentAssignmentNS.Action(Action.SET_FILTER)
  private setFilter: (
    payload: SetFilterPayload<Filters, keyof Filters>
  ) => Promise<void>;

  @employmentAssignmentNS.Action(Action.SET_PAGE)
  private setPage: (page: number) => Promise<void>;

  @employmentAssignmentNS.Action(Action.SET_SELECTION)
  private setSelection: (ids: number[]) => void;

  @shiftPresetsNS.Action(StoreAction.FETCH_ALL)
  protected fetchShiftPresets: FetchAllShiftPresetsFunction;

  @rotationWizardNS.Action(RotationWizardAction.RESET)
  protected resetStore: () => void;

  private get pagination() {
    return {
      page: this.page,
      perPage: this.perPage,
      count: this.count,
    };
  }

  private get rotationGroupsOptions() {
    return this
      .rotationGroups
      .filter((group): group is Required<RotationGroup> => group.id !== undefined)
      .map(group => ({
        id: group.id,
        label: group.name,
        isChecked: (this.filters.shiftRotationGroupIds || []).includes(group.id),
      }));
  }

  public async mounted() {
    if (!this.rotationId) {
      this.$router.replace({ name: Route.ROOT });
      return;
    }
    await this.fetchShiftPresets({});
    this.subscribe();
  }

  public beforeDestroy() {
    this.unsubscribe();
  }

  private onFinishClick() {
    this.resetStore();

    const companyProfileUrl = getUrlWithApiPrefix(getUrlWithCompanyPrefix('', this.currentCompany));
    window.location.assign(companyProfileUrl);
  }

  public render() {
    return (
      <div class={styles.employmentAssignment}>
        <Sidebar class={styles.employmentAssignmentSidebar} />
        <div class={styles.employmentAssignmentMain}>
          <h2 class={styles.employmentAssignmentTitle}>
            {this.$t('rotationWizard.employmentAssignment.title')}
          </h2>
          <EmploymentsTable rows={this.data}
            filters={this.filters}
            pagination={this.pagination}
            selectedRowIds={this.selection}
            sort={this.sort as Sort}
            loadingState={this.loadingState}
            rotationGroups={this.rotationGroupsOptions}
            onSetPage={(payload) => {
              this.setPage(payload.page);
              this.setSelection([]);
            }}
            onSetSort={payload => this.setSort(payload)}
            onSetFilter={(payload) => {
              this.setFilter(payload);
            }}
            onSetSelection={(payload) => {
              this.setSelection(payload.ids);
            }}
          />
          <ActionButtonWrapper>
            <Button
              color={ButtonColor.PRIMARY}
              size={Size.LARGE}
              kind={ButtonKind.FILL}
              type="submit"
              onClick={this.onFinishClick}
            >
              {this.$t('rotationWizard.employmentAssignment.buttonFinishWizard')}
            </Button>
          </ActionButtonWrapper>
        </div>
      </div>);
  }
}
