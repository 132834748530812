import Tabs from 'components/tabs/Tabs';
import { EventPayload } from 'utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Dialog, { Slot } from 'components/dialog/Dialog';
import { redirectToNamedParent } from 'src/utils/route';
import { shiftplanNotificationsWatchNS, StoreState } from 'store/shiftplan-notifications/Store';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './dialog-shiftplan-notifications.css';
import { Route } from './routes';

interface Props {
  shiftplanId: number;
}

interface Events {
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}

@Component
export default class DialogShiftplanNotifications extends TsxComponent<Props, Events> {
  @Prop()
  public shiftplanId: Props['shiftplanId'];

  @shiftplanNotificationsWatchNS.State('data')
  protected notificationsData: StoreState['watch']['data'];

  private isSubmitting = false;

  protected onCloseClick() {
    if (this.isSubmitting) {
      return;
    }
    redirectToNamedParent(this);
  }

  protected onSubmitStateChange(state: boolean) {
    this.isSubmitting = state;
  }

  protected get formId() {
    return `form-${this.$route?.name}`;
  }

  protected get pendingNotifications() {
    return this.notificationsData?.shiftplanNotifications?.count;
  }

  protected get tabsList() {
    return [{
      id: Route.NOTIFICATION,
      label: this.$t('messageEmployees.modal.labelNotificationTab'),
      notificationCount: this.pendingNotifications,
      class: styles.dialogShiftplanNotificationsTab,
    },
    {
      id: Route.MESSAGE,
      label: this.$t('messageEmployees.modal.labelMessageTab'),
    }];
  }

  public render() {
    return (
      <Dialog
        isOpen={true}
        onCloseClick={this.onCloseClick}
        title={this.$t('messageEmployees.modal.title')}
      >
        <div
          class={styles.dialogShiftplanNotificationsInner}
        >
          {
            <Tabs
              list={this.tabsList}
              onSelect={(e) => {
                if (!this.isSubmitting) {
                  this.$router.push({ name: e.payload.id });
                }
              }}
              selected={this.$route?.name}
              passthrough={true}
            >
              <router-view
                onSubmitStateChange={this.onSubmitStateChange}
              />
            </Tabs>
          }
        </div>

        <Button
          color={ButtonColor.SECONDARY}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
          slot={Slot.BUTTONS_RIGHT}
          aria-label="close-button"
        >
          {this.$t('general.buttonCancel')}
        </Button>

        <Button
          color={ButtonColor.SUCCESS}
          size={Size.SMALL}
          type="submit"
          disabled={this.isSubmitting}
          form={this.formId}
          slot={Slot.BUTTONS_RIGHT}
        >
          {this.$t('messageEmployees.modal.buttonSend')}
        </Button>
      </Dialog>
    );
  }
}
