import DateItem from 'components/calendar-common/common/DateItem';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import { DayNote, dayNotesNS } from 'store/day-notes/Store';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import SpIcon from 'components/sp-icon/SpIcon';
import DayNotesCompact from '../day-notes-compact/DayNotesCompact.jsx';
import styles from './day-notes-compact-container.css';

@Component
class DayNotesCompactContainer extends Vue {
  @Prop()
  public dates!: DateItem[];

  @Prop()
  public showLabelText!: string;

  @dayNotesNS.Getter('byDate')
  private notes: Record<string, DayNote[]>;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  public render() {
    return (this.filters.showDayNotes && <GridRow
      cellClass={gridStyles.gridTableContentCellStretch}
      dates={this.dates}
      scopedSlots={{
        cell: ({ dateItem }: GridRowScope) => <DayNotesCompact notes={this.notes[dateItem.dateKey]}
          date={dateItem}/>,
      }}>
      <GridCell class={gridStyles.gridTableLabelCell}
        slot="label"
        isHeader={true}>
        {this.showLabelText
          ? (<span class={styles.dayNotesCompactContainerLabelText}>
            {this.$t('calendar.dayNotes.title')}
          </span>)
          : <SpIcon name="note" class={styles.dayNotesCompactContainerIcon}/>}
      </GridCell>
    </GridRow>);
  }
}

export default DayNotesCompactContainer as any;
