import {
  GQLAbsenceConflictsFragmentFragment,
  GQLAbsenceState,
} from 'codegen/gql-types';
import { authNS } from 'components/auth/store/Store';
import type { HasAnyRightFunction, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { employmentsNS } from 'components/employments/store/Store';
import type { FetchAllEmploymentsFunction } from 'components/employments/store/Store';
import type { Employment } from 'components/employments/types';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { SentryTag } from 'services/logger/SentryTransport';
import { RestError, RestResponse } from 'services/rest-client/RestClient';
import { Action as StoreAction } from 'store/normalized-store';
import { AbsenceReason, absenceReasonsNS } from 'store/absence-reasons/Store';
import type { FetchAllFunction as FetchAllAbsencesFunction } from 'store/absence-reasons/Store';
import {
  MINUTES_IN_HOUR,
  FALLBACK_WORKING_HOURS_IN_DAY,
  Unit,
  endOf,
  startOf,
} from 'src/utils/date-related';
import { EventPayload } from 'src/utils/events';
import { getFirstErrorFromResponse, StoreActionState } from 'src/utils/store';
import type { GetById } from 'src/utils/store';
import { formatNumberWithOneFractionMin } from 'src/utils/utils';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import { AlertKind } from 'components/alert/Alert';
import DialogConflicts from '../dialog-conflicts/DialogConflicts';
import Action from '../store/Action';
import { absencesNS, absencesTableNS } from '../store/Store';
import type {
  CreateAbsenceFunction,
  DeleteAbsenceFunction,
  FetchAbsenceFunction,
  ModuleState,
  UpdateAbsenceFunction,
} from '../store/Store';
import type { Absence, NewAbsenceInitialState } from '../types';
import DialogAbsence, { FormState, CollisionOverrideOption } from './DialogAbsence';

export const getInitialFormState = (timeZone: string): FormState => ({
  attachment: undefined,
  daysDuration: '0',
  hoursDuration: '0.0',
  hoursDurationNumber: Number.NaN,
  employmentId: '',
  endsAt: endOf(new Date(), Unit.DAY, timeZone),
  isFullDay: true,
  isPaid: true,
  reasonId: '',
  startsAt: startOf(new Date(), Unit.DAY, timeZone),
  state: GQLAbsenceState.NEW,
  notes: '',
});

interface Props {
  absenceId?: number;
  initialState?: NewAbsenceInitialState;
}

interface Events {
  onCloseClick: () => void;
  onCreateAbsenceSuccess: (absenceId: number) => void;
  onUpdateAbsenceSuccess: (absenceId: number) => void;
  onDeleteAbsenceSuccess: (absenceId: number) => void;
}

@Component
export default class DialogAbsenceContainer extends TsxComponent<Props, Events> {
  protected absence: Absence | null = null;

  // formState will be replaced on mount, so timeZone does not really matter
  protected formState = getInitialFormState('UTC');

  protected conflicts: GQLAbsenceConflictsFragmentFragment['conflicts'] = [];

  protected canManage?: boolean = false;

  protected isDeleting = false;

  protected isDeleteConfirmDialogOpen = false;

  protected isSubmitting = false;

  protected lastStoreActionState = StoreActionState.SUCCESS;

  @absencesNS.Action(Action.UPLOAD_ATTACHMENT)
  protected uploadAttachment: (payload: {
    absenceId: number;
    file: File;
  }) => Promise<RestError | RestResponse<Record<string, unknown>>>;

  @absencesNS.Action(Action.CREATE)
  protected createAbsence: CreateAbsenceFunction;

  @absencesNS.Action(Action.UPDATE)
  protected updateAbsence: UpdateAbsenceFunction;

  @absencesNS.Action(Action.DELETE)
  protected deleteAbsence: DeleteAbsenceFunction;

  @absencesNS.Action(Action.FETCH)
  protected fetch: FetchAbsenceFunction;

  @absencesNS.Getter('getById')
  protected getAbsenceById: GetById<Absence>;

  @absenceReasonsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAbsenceReasons: FetchAllAbsencesFunction;

  @absenceReasonsNS.Getter('items')
  protected reasons: AbsenceReason[];

  @absenceReasonsNS.Getter('getById')
  protected getAbsenceReasonById: GetById<AbsenceReason>;

  @authNS.Getter
  protected hasAnyRight: HasAnyRightFunction;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @employmentsNS.Action(StoreAction.FETCH_ALL)
  protected fetchEmployments: FetchAllEmploymentsFunction;

  @employmentsNS.Getter('items')
  protected employments: Employment[];

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @absencesTableNS.State(state => state.data)
  protected absences: ModuleState['table']['data'];

  @Prop()
  public absenceId: Props['absenceId'];

  @Prop()
  public initialState: Props['initialState'];

  protected get isAttachmentUploadShown() {
    return !this.absence?.file
      && !!this.currentCompany?.isAbsenceAttachmentsAllowed
      && !!this.reasons.find(
        reason => reason.id.toString() === this.formState.reasonId,
      )?.isAbsenceAttachmentsAllowed;
  }

  protected get isManager() {
    return this.hasAnyRight('absences_manage', 'super_admin');
  }

  protected get isUpdate() {
    return !!this.absenceId;
  }

  protected get reasonsReferencedOrNotDeleted() {
    return this.reasons.filter(reason => (
      !reason.deletedAt || this.formState.reasonId === reason.id.toString()
    ));
  }

  protected get selectedAbsenceReason() {
    return this.getAbsenceReasonById(Number.parseInt(this.formState.reasonId, 10));
  }

  protected get selectedAbsenceEmployment() {
    return this.employments.find(o => o.id.toString() === this.formState.employmentId);
  }

  protected get states() {
    const states = Object.values(GQLAbsenceState);

    if (this.isUpdate) {
      return states;
    }

    // remove "refused" state (not needed for absence creation, only editing)
    return states.filter(state => state !== GQLAbsenceState.REFUSED);
  }

  @Watch('formState.hoursDurationNumber')
  protected onHoursDurationNumberUpdate(num: number) {
    // special case: hoursDurationNumber was manually set when populating formState
    // with existing absence
    if (num === Number.NEGATIVE_INFINITY) {
      return;
    }

    const absenceHoursPerDay = this.currentEmployment?.vacationHours
      || FALLBACK_WORKING_HOURS_IN_DAY;

    const hoursDuration = Number.isNaN(num)
      ? Number.parseInt(this.formState.daysDuration, 10) * absenceHoursPerDay
      : num;

    this.formState.hoursDuration = formatNumberWithOneFractionMin(hoursDuration);
  }

  @Watch('isAttachmentUploadShown')
  protected onIsAttachmentUploadShownUpdate(isAttachmentUploadShown: boolean) {
    if (!isAttachmentUploadShown) {
      this.formState.attachment = undefined;
    }
  }

  protected onCloseClick() {
    this.$emit('closeClick');
  }

  protected onDeleteClick() {
    this.isDeleteConfirmDialogOpen = true;
  }

  protected async onDeleteCancel() {
    this.isDeleteConfirmDialogOpen = false;
  }

  protected async onDeleteConfirm() {
    if (!this.absenceId || !this.absence) {
      this.isDeleteConfirmDialogOpen = false;
      return false;
    }

    this.isDeleting = true;

    const response = await this.deleteAbsence({ id: this.absenceId });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, DialogAbsenceContainer.name]],
        message: JSON.stringify(response),
      });

      this.isDeleting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return false;
    }

    this.onCloseClick();
    this.$emit('deleteAbsenceSuccess', this.absenceId);

    this.isDeleting = false;
    this.isDeleteConfirmDialogOpen = false;

    return this.showSnackbar({
      message: this.$t('absence.modal.deleted'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit({
    event,
    payload: { forceCollision, forceCollisionAndRemove } = {},
  }: EventPayload<CollisionOverrideOption, HTMLElement, UIEvent>) {
    event.preventDefault();

    const absence = {
      absenceReasonId: Number.parseInt(this.formState.reasonId, 10),
      days: Number.parseFloat(this.formState.daysDuration),
      employmentId: Number.parseInt(this.formState.employmentId, 10),
      endsAt: this.formState.endsAt,
      isFullDay: this.formState.isFullDay,
      notes: this.formState.notes || null,
      paid: this.formState.isPaid,
      startsAt: this.formState.startsAt,
      state: this.formState.state as GQLAbsenceState,
    };

    this.isSubmitting = true;

    const response = this.isUpdate && this.absence
      ? await this.updateAbsence({
        absence,
        forceCollision,
        forceCollisionAndRemove,
        id: this.absence.id,
      })
      : await this.createAbsence({
        absence,
        forceCollision,
        forceCollisionAndRemove,
      });

    this.isSubmitting = false;

    if (
      response.state === StoreActionState.ERROR
        || response.state === StoreActionState.NOT_FOUND
    ) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, DialogAbsenceContainer.name]],
        message: JSON.stringify(response),
      });

      const error = getFirstErrorFromResponse(
        'error' in response && typeof response.error !== 'string'
          ? response.error
          : undefined,
      );

      const message = error
        ? this.$t(`tags.modal.error.${error.key}`, { ...error.val })
        : this.$t('general.error.unknown');

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });

      return false;
    }

    if (response.state === StoreActionState.CONFLICT) {
      this.isSubmitting = false;
      this.conflicts = response.conflicts;
      this.canManage = response.canManage;

      return undefined;
    }

    const uploadResponse = await this.tryUploadAttachment(response.entityId);

    this.isSubmitting = false;
    this.clearConflicts();

    if (uploadResponse) {
      this.$emit(this.isUpdate ? 'updateAbsenceSuccess' : 'createAbsenceSuccess', response.entityId);

      this.onCloseClick();

      return this.showSnackbar({
        message: this.isUpdate ? this.$t('absence.modal.updated') : this.$t('absence.modal.created'),
        kind: AlertKind.SUCCESS,
        timeout: 5000,
      });
    }

    return this.showSnackbar({
      message: this.$t('absence.modal.error.uploadFailed'),
      kind: AlertKind.ERROR,
      timeout: 5000,
    });
  }

  protected async tryUploadAttachment(absenceId?: number) {
    if (!absenceId) {
      return false;
    }

    if (!(this.formState.attachment instanceof File)) {
      return true;
    }

    const response = await this.uploadAttachment({
      absenceId,
      file: this.formState.attachment,
    });

    return response.isSuccessful;
  }

  protected clearConflicts() {
    this.conflicts = [];
    this.canManage = false;
  }

  protected async populateFormState() {
    if (!this.absenceId) {
      this.$logError({
        tags: [[SentryTag.COMPONENT, DialogAbsenceContainer.name]],
        error: new Error('PopulateFormState called, but id is not passed'),
      });
      return;
    }

    this.lastStoreActionState = StoreActionState.PENDING;

    const response = await this.fetch({ id: this.absenceId });

    this.lastStoreActionState = response.state;

    if (this.lastStoreActionState !== StoreActionState.SUCCESS) {
      return;
    }

    this.absence = this.getAbsenceById(this.absenceId) || null;

    if (this.absence) {
      this.formState = {
        attachment: undefined,
        employmentId: this.absence.employment.id.toString(),
        endsAt: new Date(this.absence.endsAt),
        startsAt: new Date(this.absence.startsAt),
        state: this.absence.state,
        reasonId: this.absence.absenceReason.id.toString(),
        isFullDay: this.absence.isFullDay,
        isPaid: this.absence.paid,
        notes: this.absence.notes || '',
        daysDuration: this.absence.days.toString(),
        hoursDuration: formatNumberWithOneFractionMin(
          this.absence.vacationMinutes / MINUTES_IN_HOUR,
        ),
        hoursDurationNumber: Number.NEGATIVE_INFINITY,
      };

      this.formState.existingAttachment = this.absence.file
        ? {
          name: this.absence.fileName || '',
          url: this.absence.file,
        } : undefined;
    }
  }

  public mounted() {
    this.fetchAbsenceReasons();
    this.fetchEmployments();

    if (!this.isUpdate) {
      this.formState = getInitialFormState(this.$timeZone.value);
      this.formState.employmentId = this.isManager
        ? ''
        : this.currentEmployment?.id.toString() || '';
      this.formState = { ...this.formState, ...this.initialState };
      this.lastStoreActionState = StoreActionState.SUCCESS;
      return;
    }

    // TODO: We might actually want to check if the absence is already in the store and not refetch
    // it in this case or refetch it silently in the background.
    this.populateFormState();
  }

  public render() {
    if (this.conflicts.length > 0 && this.selectedAbsenceReason && this.selectedAbsenceEmployment) {
      return (
        <DialogConflicts
          canManage={this.canManage}
          absence={{
            absenceReason: this.selectedAbsenceReason,
            employment: this.selectedAbsenceEmployment,
            startsAt: this.formState.startsAt,
          }}
          conflicts={this.conflicts}
          isCurrentEmployment={
            this.formState.employmentId === (this.currentEmployment?.id || -1).toString()
          }
          isOpen={true}
          isSubmitting={this.isSubmitting}
          onCancelClick={() => this.clearConflicts()}
          onApproveAndIgnoreClick={event => this.onSubmit({
            event,
            payload: { forceCollision: true },
          })}
          onApproveAndRemoveClick={event => this.onSubmit({
            event,
            payload: { forceCollisionAndRemove: true },
          })}
        />
      );
    }

    if (this.isDeleteConfirmDialogOpen) {
      return (
        <DialogConfirmDelete
          isOpen={true}
          isSubmitting={this.isDeleting}
          onCancel={this.onDeleteCancel}
          onConfirm={this.onDeleteConfirm}
          title={this.$t('absence.modal.titleDelete')}
        >
          {this.$t('absence.modal.messageDelete')}
        </DialogConfirmDelete>
      );
    }

    return (
      <DialogAbsence
        employments={this.employments}
        formState={this.formState}
        hasError={
          [StoreActionState.ERROR, StoreActionState.NOT_FOUND].includes(this.lastStoreActionState)
        }
        isAttachmentUploadShown={this.isAttachmentUploadShown}
        isLoading={this.lastStoreActionState === StoreActionState.PENDING}
        isManager={this.isManager}
        isOpen={true}
        isSubmitting={this.isSubmitting}
        isUpdate={this.isUpdate}
        onCloseClick={this.onCloseClick}
        onDeleteClick={this.onDeleteClick}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
        reasons={this.reasonsReferencedOrNotDeleted}
        states={this.states}
      />
    );
  }
}
