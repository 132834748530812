import { EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { shiftplanNotificationsNS } from 'src/store/shiftplan-notifications/Store';
import type { CreateShiftplanNotificationFunction } from 'src/store/shiftplan-notifications/Store';
import ShiftplanMessageActionsAction from 'src/store/shiftplan-notifications/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'src/utils/store';
import { AlertKind } from 'components/alert/Alert';
import { redirectToNamedParent } from 'src/utils/route';
import { GQLShiftplanCreateNotificationInput, GQLShiftplanNotificationType } from 'codegen/gql-types';
import Notification from './Notification';

export const getInitialFormState = (): GQLShiftplanCreateNotificationInput => ({
  message: '',
  notificationType: GQLShiftplanNotificationType.UPDATES,
});

@Component
export default class NotificationContainer extends TsxComponent<{
  shiftplanId: number;
}, {
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onSubmitStateChange: (state: boolean) => void;
}> {
  @Prop()
  public shiftplanId: number;

  @shiftplanNotificationsNS.Action(ShiftplanMessageActionsAction.CREATE_SHIFTPLAN_NOTIFICATION)
  protected createShiftplaNotification: CreateShiftplanNotificationFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  protected formState = getInitialFormState();

  protected async onCreateNotification({
    message, notificationType,
  }: GQLShiftplanCreateNotificationInput) {
    this.$emit('submitStateChange', true);
    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        input: {
          notificationType,
          message: message || null,
        },
        id: this.shiftplanId,
      },
      this.createShiftplaNotification,
      '',
    );

    this.$emit('submitStateChange', false);

    if (response.state === StoreActionState.ERROR) {
      return;
    }

    redirectToNamedParent(this);

    this.showSnackbar({
      message: this.$t('messageEmployees.notification.notificationSent'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onFormInput({ payload }) {
    this.formState[payload.field] = payload.value;
  }

  public render() {
    return (
      <Notification
        formState={this.formState}
        onSubmitForm={e => this.onCreateNotification(e.payload.formState)}
        onFormInput={this.onFormInput}
      />
    );
  }
}
