import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import CheckboxGeneral from './CheckboxGeneral';

import styles from './filtering.css';

export enum FilterGeneral {
  WITH_JOIN_REQUESTS = 'withJoinRequests',
  WITHOUT_ABSENCE_CONFLICTS = 'withoutAbsenceConflicts',
  WITHOUT_SHIFT_CONFLICTS = 'withoutShiftConflicts',
}

@Component
export default class General extends TsxComponent<{
  selected: Record<FilterGeneral, boolean>;
  joinRequestLength?: number;
}, {
  onChange: EventPayload<FilterGeneral, HTMLInputElement>;
}> {
  @Prop()
  public selected: Record<FilterGeneral, boolean>;

  @Prop()
  public joinRequestLength?: number;

  protected onChange(e: SyntheticEvent<HTMLInputElement, Event>) {
    this.$emit('change', createEventPayload(e, e.target.value));
  }

  public render() {
    return (
      <section class={styles.filtering}>
        <CheckboxGeneral
          checked={this.selected[FilterGeneral.WITHOUT_SHIFT_CONFLICTS]}
          class={styles.filteringCheckbox}
          icon="event"
          label={this.$t('shifts.employees.filter.labelWithoutShiftConflicts')}
          name={FilterGeneral.WITHOUT_SHIFT_CONFLICTS}
          onChange={this.onChange}
        />

        <CheckboxGeneral
          checked={this.selected[FilterGeneral.WITHOUT_ABSENCE_CONFLICTS]}
          class={styles.filteringCheckbox}
          icon="absence"
          label={this.$t('shifts.employees.filter.labelWithoutAbsenceConflicts')}
          name={FilterGeneral.WITHOUT_ABSENCE_CONFLICTS}
          onChange={this.onChange}
        />

        <CheckboxGeneral
          checked={this.selected[FilterGeneral.WITH_JOIN_REQUESTS]}
          class={styles.filteringCheckbox}
          icon="application"
          label={this.$t('shifts.employees.filter.labelWithRequests')}
          name={FilterGeneral.WITH_JOIN_REQUESTS}
          onChange={this.onChange}
        >
          {this.joinRequestLength}
        </CheckboxGeneral>
      </section>
    );
  }
}
