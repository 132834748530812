import { CalendarNamespace } from 'components/calendar-common/Enums';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HighlightableEventTypes } from '../Store';
import styles from './highlightable-event.css';

interface HighlightState {
  id: number[] | null;
  type: HighlightableEventTypes | null;
}

export interface HighlightableEventProps {
  calendarNamespace?: CalendarNamespace;
}

@Component
class HighlightableEvent extends Vue {
  @Prop({ default: CalendarNamespace.CALENDAR })
  public calendarNamespace: CalendarNamespace;

  protected get highlightState(): HighlightState {
    return this.$store.state[this.calendarNamespace].highlights;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  protected isHighlighted(type: HighlightableEventTypes | null, id: number[] | null): boolean {
    throw new Error('Not implemented');
  }

  protected get highlightClass(): string {
    const { type, id } = this.highlightState;
    if (type !== null && id !== null) {
      return this.isHighlighted(type, id)
        ? styles.highlightableEventActiveHighlighted
        : styles.highlightableEventActive;
    }
    return '';
  }
}

export default HighlightableEvent;
