import FilterPopup from 'components/table/filter-popup/FilterPopup';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { filterFalsy, sortBySortOrder } from 'src/utils/utils';

@Component
export default class FilterPopupLocation extends TsxComponent<{
  close: () => void;
  selected: number[] | null;
}, {
  onFilterChange: number[];
}> {
  protected searchString = '';

  @Emit('filterChange')
  public onFilterChange({ payload }) {
    if (Array.isArray(payload.id) || payload.id === null) {
      return payload.id;
    }

    if (this.selected && this.selected.includes(payload.id)) {
      return this.selected.filter(o => o !== payload.id);
    }

    return [...(this.selected || []), payload.id];
  }

  public onSearchInput({ payload }) {
    this.searchString = payload.search;
  }

  @Emit('filterChange')
  public onToggleAll({ payload }) {
    if (payload.isSelected) {
      return this.locations.map(o => o.id);
    }
    return null;
  }

  @Prop()
  public close: () => void;

  @Prop()
  public selected: number[] | null;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  protected get locations() {
    const lowerCaseString = this.searchString.toLocaleLowerCase();

    return (this.currentCompany?.locations || [])
      .filter(filterFalsy)
      .filter(o => o.name.toLocaleLowerCase().includes(lowerCaseString))
      .sort(sortBySortOrder())
      .map(o => ({
        id: o.id,
        isChecked: !!this.selected && this.selected.indexOf(o.id) !== -1,
        label: o.name,
      }));
  }

  public render() {
    return (
      <FilterPopup
        hasSearch={true}
        hasSelectAll={true}
        items={this.locations}
        onClose={this.close}
        onFilterChange={this.onFilterChange}
        onSearchInput={this.onSearchInput}
        onToggleAll={this.onToggleAll}
        searchString={this.searchString}
      >
        <template slot="header">{this.$t('absence.locations')}</template>
      </FilterPopup>
    );
  }
}
