import Dropdown from 'components/dropdown/Dropdown';
import { snakeCase } from 'lodash';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { SortDirection } from 'components/table/types';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'utils/url';
import { hasMinLength } from 'utils/utils';
import { DropdownItemKind } from 'components/dropdown/types';
import { absencesTableNS } from '../store/Store';
import type { Filters } from '../store/Store';

@Component
export default class DropdownExport extends TsxComponent<{}> {
  @absencesTableNS.State
  private page: number;

  @absencesTableNS.State
  private filters: Filters;

  @absencesTableNS.State
  private sort: { key: 'startsAt'; direction: SortDirection };

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  protected get filterQueryString() {
    const usp = new URLSearchParams();

    if (hasMinLength(this.filters.employmentStatuses, 1)) {
      this.filters.employmentStatuses.forEach(id => usp.append('search[emp_status][]', id.toString()));
    }

    if (hasMinLength(this.filters.employmentIds, 1)) {
      this.filters.employmentIds.forEach(id => usp.append('search[employment_id][]', id.toString()));
    }

    if (hasMinLength(this.filters.locationIds, 1)) {
      this.filters.locationIds.forEach(id => usp.append('search[location_id][]', id.toString()));
    }

    if (this.filters.startsAt) {
      usp.append('search[start_date]', this.filters.startsAt.toISOString());
    }

    if (this.filters.endsAt) {
      usp.append('search[end_date]', this.filters.endsAt.toISOString());
    }

    // FIXME: API implicitly assumes per_page is 10. No parameter to override this behavior.
    usp.append('search[page]', this.page.toString());

    if (typeof this.filters.paid === 'boolean') {
      usp.append('search[paid][]', this.filters.paid ? 'paid' : 'unpaid');
    }

    if (typeof this.filters.withAttachment === 'boolean') {
      usp.append(
        'search[attachment][]',
        this.filters.withAttachment ? 'with_attachment' : 'without_attachment',
      );
    }

    if (hasMinLength(this.filters.absenceReasonIds, 1)) {
      this.filters.absenceReasonIds.forEach(id => usp.append('search[reason_id][]', id.toString()));
    }

    usp.append('search[sort]', snakeCase(this.sort.key));
    usp.append(
      'search[order]',
      this.sort.direction === SortDirection.ASC ? 'ascending' : 'descending',
    );

    if (hasMinLength(this.filters.states, 1)) {
      this.filters.states.forEach(state => usp.append('search[state][]', state));
    }

    return usp.toString();
  }

  protected getExportBaseUrl(extension: string) {
    return getUrlWithApiPrefix(getUrlWithCompanyPrefix(`/absences.${extension}`, this.currentCompany));
  }

  public render() {
    return (
      <Dropdown
        id="export-dropdown"
        items={[{
          label: 'XLS',
          type: DropdownItemKind.LINK,
          href: [this.getExportBaseUrl('xls'), this.filterQueryString].join('?'),
        }, {
          label: 'CSV',
          type: DropdownItemKind.LINK,
          href: [this.getExportBaseUrl('csv'), this.filterQueryString].join('?'),
        }]}
      >
        {this.$t('absence.header.buttonExport')}
      </Dropdown>
    );
  }
}
