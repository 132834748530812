import DateItem from 'components/calendar-common/common/DateItem';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import SpIcon from 'components/sp-icon/SpIcon';
import { DayNote, dayNotesNS } from 'store/day-notes/Store';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import Actions from '../Action';
import DayNotes from '../day-notes/DayNotes';
import { notesNS } from '../Store';
import styles from './day-notes-container.css';

@Component
class DayNotesContainer extends Vue {
  @Prop({ default: false })
  public showLabelText !: boolean;

  @Prop()
  public dates !: DateItem[];

  @calendarFiltersNS.Getter('filters')
  private filters: FiltersDictionary;

  @notesNS.State(state => state.isOpen)
  private isOpen: boolean;

  @notesNS.Getter('hasHiddenNotes')
  private hasHiddenNotes: boolean;

  @dayNotesNS.Getter('byDate')
  private notes: Record<string, DayNote[]>;

  @notesNS.Action(Actions.OPEN_DAY_NOTES)
  private openDayNotes;

  @notesNS.Action(Actions.CLOSE_DAY_NOTES)
  private closeDayNotes;

  private toggleIsOpen() {
    if (this.isOpen) {
      this.closeDayNotes();
    } else {
      this.openDayNotes();
    }
  }

  public render() {
    return (
      this.filters.showDayNotes && <GridRow
        dates={this.dates}
        cellClass={gridStyles.gridTableContentCellStretch}
        scopedSlots={{
          cell: ({ dateItem }: GridRowScope) => <DayNotes
            notes={this.notes[dateItem.dateKey]}
            date={dateItem}/>,
        }}>
        <GridCell slot="label"
          class={gridStyles.gridTableLabelCell}
          isHeader={true}>
          <button
            disabled={!this.hasHiddenNotes}
            class={[styles.dayNotesContainerToggle, {
              [styles.dayNotesContainerToggleWithText]: this.showLabelText,
            }]}
            onClick={() => this.toggleIsOpen()}>
            {
              <span class={styles.dayNotesContainerLabelText}>
                {this.showLabelText
                  ? this.$t('calendar.dayNotes.title') : <SpIcon name="note"/>}
              </span>
            }
            {this.hasHiddenNotes
            && <SpIcon
              name={this.isOpen ? 'chevron-up' : 'chevron-down'}
            />}
          </button>
        </GridCell>
      </GridRow>);
  }
}

export default DayNotesContainer as any;
