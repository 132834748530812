import Badge, { BadgePreset } from 'components/badge/Badge';
import type { ShiftAvailableEmployment } from 'store/shift-available-employments/Store';
import { MSECS_IN_HOUR, MSECS_IN_MINUTE, getHourMinuteDurationString } from 'utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Size } from 'components/types';
import Popover from 'components/popover/Popover';
import PopoverContent from './PopoverContent';
import styles from './hour-balance.css';

@Component
export default class HourBalance extends TsxComponent<{
  employment: ShiftAvailableEmployment;
}> {
  @Prop()
  public employment: ShiftAvailableEmployment;

  protected get worked() {
    return getHourMinuteDurationString((this.employment.workedMinutes || 0) * MSECS_IN_MINUTE);
  }

  protected get scheduled() {
    return getHourMinuteDurationString((this.employment.scheduledMinutes || 0) * MSECS_IN_MINUTE);
  }

  protected get contracted() {
    return getHourMinuteDurationString((this.employment.contractMinutes || 0) * MSECS_IN_MINUTE);
  }

  protected get hourAccountBalance() {
    return getHourMinuteDurationString(
      (this.employment.hourAccountHours || 0) * MSECS_IN_HOUR,
      true,
    );
  }

  public render() {
    return (
      <Popover
        class={styles.hourBalance}
        content={<PopoverContent employment={this.employment} />}
      >
        {this.worked}&thinsp;h/{this.scheduled}&thinsp;h ({this.contracted}&thinsp;h)

        {
          this.employment.employment.hourEnabled && (
            <Badge
              preset={BadgePreset.NEUTRAL}
              size={Size.XSMALL}
              class={styles.hourBalanceBadge}
            >
              {this.hourAccountBalance}
            </Badge>
          )
        }
      </Popover>
    );
  }
}
