import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import { employmentsNS } from 'components/employments/store/Store';
import type { Employment } from 'components/employments/types';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { SentryTag } from 'services/logger/SentryTransport';
import ShiftAction from 'store/shifts/Action';
import { shiftsNS } from 'store/shifts/Store';
import type { DeleteRequestFunction, Shift } from 'store/shifts/Store';
import { StoreActionState } from 'utils/store';
import type { GetById } from 'utils/store';
import { createEventPayload, EventPayload } from 'utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Offerer from '../offerer/Offerer';
import Row from '../row/Row';

import styles from './requester-list.css';

@Component
export default class RequesterList extends TsxComponent<{
  isSubmitting?: boolean;
  shift: Shift;
  requests: Shift['requests'][0][];
}, {
  onCancelRequest: (payload: EventPayload<{ employmentId: number; requestId: number }>) => void;
  onConfirmRequest: (payload: EventPayload<{ employmentId: number; requestId: number }>) => void;
  onRefetchShift: () => void;
}> {
  protected toDeleteRequestId = Number.NaN;

  protected isDeleting = false;

  @employmentsNS.Getter('getById')
  protected getEmploymentById: GetById<Employment>;

  @shiftsNS.Action(ShiftAction.DELETE_REQUEST)
  protected deleteRequest: DeleteRequestFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public requests: Shift['requests'][0][];

  @Prop()
  public shift: Shift;

  @Prop()
  public isSubmitting?: boolean;

  protected onDeleteOffer({ payload }: EventPayload<{ requestId: number }>) {
    this.toDeleteRequestId = payload.requestId;
  }

  protected async onDeleteOfferConfirm() {
    this.isDeleting = true;

    const response = await this.deleteRequest({
      id: this.toDeleteRequestId,
      shiftId: this.shift?.id || 0,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, RequesterList.name]],
        message: JSON.stringify(response),
      });

      this.isDeleting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return false;
    }

    this.$emit('refetchShift');

    this.isDeleting = false;
    this.toDeleteRequestId = Number.NaN;

    return this.showSnackbar({
      message: this.$t('shifts.replaceRequests.offerDeleted'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected async onDeleteOfferCancel() {
    this.toDeleteRequestId = Number.NaN;
  }

  public render() {
    return (
      <div class={styles.requesterList}>
        {
          this.requests.map(request => [
            <Offerer
              class={styles.requesterListOfferer}
              isManagerView={true}
              isPending={false}
              isSubmitting={this.isSubmitting}
              onDeleteOffer={({ event }) => this.onDeleteOffer({
                event,
                payload: { requestId: request.id },
              })}
              request={request}
            />,

            !!request.acceptedBy?.length && (
              <section class={styles.requesterListRequester}>
                <div class={styles.requesterListRequesterTitle}>
                  {this.$t('shifts.replaceRequests.titleRequestsBy')}
                </div>

                {
                  request.acceptedBy.map((employmentId) => {
                    const employment = employmentId && this.getEmploymentById(employmentId);

                    return employment && (
                      <Row
                        employment={employment}
                        request={request}
                        isManagerView={true}
                        isSubmitting={this.isSubmitting}
                        onCancelRequest={({ event }) => this.$emit('cancelRequest', createEventPayload(
                          event,
                          { employmentId, requestId: request.id },
                        ))}
                        onConfirmRequest={({ event }) => this.$emit('confirmRequest', createEventPayload(
                          event,
                          { employmentId, requestId: request.id },
                        ))}
                      />
                    );
                  })
                }
              </section>
            ),
          ])
        }

        <DialogConfirmDelete
          isOpen={!Number.isNaN(this.toDeleteRequestId)}
          isSubmitting={this.isDeleting}
          onCancel={this.onDeleteOfferCancel}
          onConfirm={this.onDeleteOfferConfirm}
          title={this.$t('shifts.replaceRequests.offerTitleDelete')}
        >
          {this.$t('shifts.replaceRequests.offerMessageDelete')}
        </DialogConfirmDelete>
      </div>
    );
  }
}
