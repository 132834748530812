/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import type { RotationDayPreset } from 'components/rotation-wizard/store/types';
import { DAYS_IN_WEEK, getLocalizedWeekday } from 'src/utils/date-related';
import {
  Component,
  Emit,
  Prop,
} from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import DayCell from '../day-cell/DayCell';

import styles from './weeks-grid.css';

interface Props {
  firstDay: Date;
  weeksLength: number;
  days: RotationDayPreset[];
  isDisabled?: boolean;
}

interface Events {
  onPresetSelect: (
    e: SyntheticEvent<HTMLDivElement, MouseEvent>,
    dayIndex: number,
    preset: RotationDayPreset,
  ) => void;
}

@Component
class WeeksGrid extends TSXComponent<Props, Events> {
  @Prop()
  public firstDay: Props['firstDay'];

  @Prop()
  public weeksLength: Props['weeksLength'];

  @Prop()
  public days: Props['days'];

  @Prop({ default: false })
  public isDisabled: Props['isDisabled'];

  @Emit('presetSelect')
  public onPresetSelect(
    e: SyntheticEvent<HTMLDivElement, MouseEvent>,
    dayIndex: number,
    preset: RotationDayPreset,
  ) {}

  public render() {
    return (
      <div class={styles.weeksGrid}>
        <div class={styles.weeksGridHeader} />
        {
          // leave first cell empty
          Array.from({ length: DAYS_IN_WEEK }, (_, index) => {
            const curDate = new Date(this.firstDay);
            curDate.setDate(this.firstDay.getDate() + index);

            return (
              <div class={styles.weeksGridHeader}>
                {getLocalizedWeekday(curDate, this.$i18n.i18next.language)}
              </div>
            );
          })
        }
        {Array.from({ length: this.weeksLength }, (_row, weekIndex) => {
          // last week can be partial
          const weekLength = weekIndex === this.weeksLength - 1
            ? DAYS_IN_WEEK - (this.weeksLength * DAYS_IN_WEEK - this.days.length)
            : DAYS_IN_WEEK;
          return [
            <div></div>,
            Array.from({ length: weekLength }, (_, dayIndex) => (
              <DayCell
                key={(weekIndex * 7) + dayIndex}
                isDisabled={this.isDisabled}
                preset={this.days[(weekIndex * 7) + dayIndex]}
                onPresetSelect={(e, preset) => (
                  this.onPresetSelect(e, (weekIndex * 7) + dayIndex, preset)
                )}
              />)),
          ];
        })}
      </div>
    );
  }
}

export default WeeksGrid;
