import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { getDurationString, toLocaleDateString } from 'utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class OutsideWorkPeriod extends TsxComponent<{
  conflict: GQLShiftConflictsFragmentFragment;
}> {
  @Prop()
  public conflict: GQLShiftConflictsFragmentFragment;

  public render() {
    if (this.conflict.__typename !== 'OutsideWorkPeriodShiftConflict') {
      return null;
    }

    return (
      <span>
        {
          this.conflict.startsAt && this.conflict.endsAt && getDurationString(
            this.$i18n.i18next.language,
            new Date(this.conflict.startsAt),
            new Date(this.conflict.endsAt),
          )
        }
        {
          this.conflict.startsAt && !this.conflict.endsAt && this.$t(
            'conflicts.outsideWorkPeriod.from',
            {
              when: toLocaleDateString(
                new Date(this.conflict.startsAt),
                this.$i18n.i18next.language,
                this.$timeZone.value,
              ),
            },
          )
        }
        {
          !this.conflict.startsAt && this.conflict.endsAt && this.$t(
            'conflicts.outsideWorkPeriod.until',
            {
              when: toLocaleDateString(
                new Date(this.conflict.endsAt),
                this.$i18n.i18next.language,
                this.$timeZone.value,
              ),
            },
          )
        }
      </span>
    );
  }
}
