import Employment, { EmploymentConstructorProps } from 'components/calendar-common/employments/Employment';

type StaffShiftParams = EmploymentConstructorProps & {
  staffShiftId: number;
};

class StaffShift extends Employment {
  public staffShiftId: number;

  public constructor(data: StaffShiftParams) {
    super(data);
    this.staffShiftId = data.staffShiftId;
  }
}

export default StaffShift;
