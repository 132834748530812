import SpIcon from 'components/sp-icon/SpIcon';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Tooltip from 'components/tooltip/Tooltip';
import styles from './radio-button-group.css';

export interface RadioButtonGroupOption {
  label: string;
  value: string;
  icon: string;
}

interface Props {
  options: RadioButtonGroupOption[];
  value: string;
}

interface Events {
  onChange: EventPayload<{ value: string }, HTMLButtonElement, MouseEvent>;
}

@Component
export default class RadioButtonGroup extends TsxComponent<Props, Events> {
  @Prop()
  public options: RadioButtonGroupOption[];

  @Prop()
  public value: string;

  private onClick(event: SyntheticEvent<HTMLButtonElement, MouseEvent>, value: string) {
    this.$emit('change', createEventPayload(event, { value }));
  }

  public render() {
    return (
      <div class={styles.radioButtonGroup} role="radiogroup">
        {this.options.map((option) => {
          const isSelected = option.value === this.value;

          return (
            <Tooltip
              class={styles.radioButtonGroupTooltip}
              text={
                <span
                  class={styles.radioButtonGroupTooltipText}
                  domPropsInnerHTML={option.label}
                />
              }
            >
              <button
                role="radio"
                aria-checked={isSelected}
                aria-label={option.label}
                class={{
                  [styles.radioButtonGroupButton]: true,
                  [styles.radioButtonGroupButtonSelected]: isSelected,
                }}
                onClick={e => this.onClick(e, option.value)}
              >
                <SpIcon name={option.icon} class={styles.radioButtonGroupButtonIcon} />
              </button>
            </Tooltip>
          );
        })}
      </div>
    );
  }
}
