import { Key } from 'src/utils/keyboard';
import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './dropdown.css';

export interface DropdownMenuItem {
  class?: string;
  href?: string;
  label: string | 'ruler';
  onClick?: () => void;
}

export type DropdownItem = DropdownMenuItem | 'ruler' | undefined;

@Component
export default class DropdownLegacy extends TsxComponent<{
  id?: string;
  items: DropdownItem[];
}> {
  public $refs: {
    buttonRef: HTMLButtonElement;
    dropdownRef: HTMLUListElement;
    itemsRef: HTMLAnchorElement[];
  };

  protected isExpanded = false;

  @Prop({ default: () => `dropdown-${getRandomString()}` })
  public id: string;

  @Prop()
  public items: DropdownItem[];

  protected onButtonClick() {
    this.isExpanded = !this.isExpanded;
  }

  protected async onButtonKeyDown(e: KeyboardEvent) {
    if (e.key === Key.ENTER || e.key === Key.SPACE || e.key === Key.ARROW_DOWN) {
      this.isExpanded = true;
      await this.$nextTick();
      const [firstItem] = this.$refs.itemsRef;
      firstItem.focus();
    } else if (e.key === Key.ARROW_UP) {
      this.isExpanded = true;
      await this.$nextTick();
      const [lastItem] = this.$refs.itemsRef.slice(-1);
      lastItem.focus();
    }
  }

  protected onMenuItemKeyDown(e: KeyboardEvent) {
    if (e.key === Key.ENTER) {
      this.isExpanded = false;
      this.$refs.buttonRef.focus();
      return;
    }

    if (e.key === Key.ESCAPE) {
      return;
    }

    const index = this.$refs.itemsRef.findIndex(o => o === e.target);

    if (index < 0) {
      return;
    }

    let newIndex = index;

    if (e.key === Key.ARROW_DOWN) {
      newIndex = ((index + 1) + this.$refs.itemsRef.length) % this.$refs.itemsRef.length;
    } else if (e.key === Key.ARROW_UP) {
      newIndex = ((index - 1) + this.$refs.itemsRef.length) % this.$refs.itemsRef.length;
    }

    this.$refs.itemsRef[newIndex].focus();
  }

  public render() {
    return (
      <div class={styles.dropdown} ref="dropdownRef">
        {this.isExpanded && (
          <OutsideClickHandler
            insideRef={this.$refs.dropdownRef}
            onOutsideClick={(e) => {
              this.isExpanded = false;

              if (e instanceof KeyboardEvent) {
                this.$refs.buttonRef.focus();
              }
            }}
          />
        )}
        <button
          aria-controls={`${this.id}-menu`}
          aria-expanded={this.isExpanded}
          aria-haspopup="true"
          class={styles.dropdownButton}
          id={`${this.id}-button`}
          onClick={this.onButtonClick}
          onKeydown={this.onButtonKeyDown}
          ref="buttonRef"
          type="button"
        >
          {this.$slots.default}
        </button>
        <ul
          aria-labelledby={`${this.id}-button`}
          class={[styles.dropdownMenu, !this.isExpanded && styles.dropdownMenuHidden]}
          id={`${this.id}-menu`}
          role="menu"
          tabindex={-1}
        >
          {this.items
            .filter((item): item is NonNullable<DropdownItem> => !!item)
            .map((item, index) => (
              item === 'ruler'
                ? (
                  <li
                    class={styles.dropdownMenuItemSeparator}
                    key={item + index}
                    role="separator"
                  />
                ) : (
                  <li
                    key={item.label + index}
                    role="none"
                  >
                    <a
                      class={[styles.dropdownMenuItem, item.class]}
                      href={item.href}
                      onClick={item.onClick || (() => undefined)}
                      onKeydown={this.onMenuItemKeyDown}
                      ref="itemsRef"
                      refInFor={true}
                      role="menuitem"
                    >
                      {item.label}
                    </a>
                  </li>
                )
            ))
          }
        </ul>
      </div>
    );
  }
}
