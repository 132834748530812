import { getUpdatedItems } from 'components/calendar-common/common/store/Helpers';
import DefaultActions from 'components/calendar-common/DefaultActions';
import Employment, { EmploymentConstructorProps } from 'components/calendar-common/employments/Employment';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { sortByLastName } from 'src/utils/utils';
import { SET_EMPLOYMENT_SEARCH_QUERY } from './Actions';
import Mutations from './Mutations';
import { CalendarNamespace } from '../Enums';
import { FiltersDictionary } from '../filters/Store';
import { locationPositionFilter, myShiftsFilter, shiftRotationGroupFilter } from './utils';

export const calendarEmploymentsNS = namespace('calendar/employments');
export const calendarEmploymentsPositionsMonthViewNS = namespace('calendar/employments/positionsMonthView');
export const calendarAbsencesEmploymentsNS = namespace('calendarAbsences/employments');

export interface StoreState {
  searchQuery: string;
  items: EmploymentConstructorProps[];
}

export const store = (
  parentNamespace: CalendarNamespace.CALENDAR |
  CalendarNamespace.ABSENCES,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    searchQuery: '',
    items: [],
  },
  mutations: {
    [Mutations.SET_EMPLOYMENTS](state, items) {
      state.items = items;
    },
    [Mutations.UPDATE_EMPLOYMENTS](state, items) {
      state.items = getUpdatedItems(state.items, items);
    },
    [Mutations.SET_SEARCH_QUERY](state, searchQuery) {
      state.searchQuery = searchQuery;
    },
  },
  actions: {
    [DefaultActions.SET]({ commit }, items) {
      commit(Mutations.SET_EMPLOYMENTS, items);
    },
    [DefaultActions.UPDATE]({ commit }, items) {
      commit(Mutations.UPDATE_EMPLOYMENTS, items);
    },
    [SET_EMPLOYMENT_SEARCH_QUERY]({ commit }, value = '') {
      commit(Mutations.SET_SEARCH_QUERY, value);
    },
  },
  getters: {
    employments(state, getters, rootState, rootGetters) {
      const shiftsEmployments: Record<string, number[]> = rootGetters[`${parentNamespace}/shifts/shiftsEmployments`] || {};
      const employments = state.items
        .map((employment): EmploymentConstructorProps => ({
          ...employment,
          presentLocationsPositionIds: Array.from(shiftsEmployments[employment.id] || []),
        }))
        .sort(sortByLastName);
      return employments.map(it => new Employment(it));
    },
    filteredEmployments(state, getters, rootState, rootGetters) {
      if (!rootState.auth.currentEmploymentId || !rootState.auth.currentCompany) {
        return [];
      }

      const locationsPositionIds = rootGetters[`${parentNamespace}/filters/locationsPositionIds`];
      const shiftRotationGroupIds = rootGetters[`${parentNamespace}/filters/shiftRotationGroupIds`];

      const { shiftRotationEnabled: isShiftRotationsEnabled } = rootState.auth.currentCompany;

      const { showOnlyMineShifts } = rootGetters[`${parentNamespace}/filters/filters`];

      return getters.employments
        .filter(myShiftsFilter(showOnlyMineShifts, rootState.auth.currentEmploymentId))
        /** filter employments with all positions in ignored positions
          * we need to check presentLocationsPositions here as well
          * so historical data is displayed correctly.
          */
        .filter(locationPositionFilter(locationsPositionIds))
        .filter(
          shiftRotationGroupFilter(
            shiftRotationGroupIds,
            isShiftRotationsEnabled || false,
            rootGetters[`${parentNamespace}/shifts/filteredShifts`],
          ),
        );
    },
    employmentsByPositions(state, getters, rootState, rootGetters) {
      return rootGetters[`${parentNamespace}/positions/filteredPositions`].reduce(
        (acc, it) => {
          acc[it.locationsPositionId] = getters.filteredEmployments
            .filter(employment => employment.presentLocationsPositionIds
              .includes(it.locationsPositionId));
          return acc;
        },
        {},
      );
    },
  },
  modules: {
    positionsMonthView: {
      namespaced: true,
      getters: {
        filteredEmployments(state, getters, rootState, rootGetters) {
          if (!rootState.auth.currentEmploymentId || !rootState.auth.currentCompany) {
            return [];
          }

          const locationsPositionIds = rootGetters[`${parentNamespace}/filters/locationsPositionIds`];
          const shiftRotationGroupIds = rootGetters[`${parentNamespace}/filters/shiftRotationGroupIds`];
          const filters: FiltersDictionary = rootGetters[`${parentNamespace}/filters/filters`];
          const { shiftRotationEnabled: isShiftRotationsEnabled } = rootState.auth.currentCompany;

          return rootGetters[`${parentNamespace}/employments/employments`]
            .filter(
              myShiftsFilter(
                filters.showOnlyMineShifts,
                rootState.auth.currentEmploymentId,
              ),
            )
            /** filter employments with all positions in ignored positions
              * we need to check presentLocationsPositions here as well
              * so historical data is displayed correctly.
              */
            .filter(locationPositionFilter(locationsPositionIds))
            .filter(
              shiftRotationGroupFilter(
                shiftRotationGroupIds,
                isShiftRotationsEnabled || false,
                rootGetters[`${parentNamespace}/shifts/filteredShifts`],
                true,
              ),
            );
        },
        employmentsByPositions(state, getters, rootState, rootGetters) {
          return rootGetters[`${parentNamespace}/positions/filteredPositions`].reduce(
            (acc, it) => {
              acc[it.locationsPositionId] = getters.filteredEmployments
                .filter(employment => employment.presentLocationsPositionIds
                  .includes(it.locationsPositionId));
              return acc;
            },
            {},
          );
        },
      },
    },
  },
});
