import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './subnavigation.css';

@Component
export default class Subnavigation extends TsxComponent<{}> {
  public render() {
    return (
      <nav class={styles.subnavigation}>
        <ul class={styles.subnavigationList}>
          {
            this.$slots.default?.map((item, index) => (
              <li class={styles.subnavigationListItem} key={`${index}#${item.text}`}>
                {item}
              </li>
            ))
          }
        </ul>
      </nav>
    );
  }
}
