enum Action {
  APPLY_ROTATION = 'applyRotation',
  CREATE_SHIFTPLAN_FROM_SHIFT_ROTATION = 'createShiftplanFromShiftRotation',
  ASSIGN_EMPLOYMENTS_TO_OPEN_SHIFTS = 'assignEmploymentsToOpenShifts',
  PUBLISH = 'publish',
  COPY_SHIFTPLAN = 'copyShiftplan',
  IMPORT_SHIFTPLAN = 'importShiftplan'
}

export default Action;
