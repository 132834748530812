import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { getRandomString } from 'src/utils/random';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { filterFalsy } from 'src/utils/utils';
import { Key } from 'src/utils/keyboard';
import Badge, { BadgePreset } from 'components/badge/Badge';
import SelectPanel, { Option } from 'components/select-panel/SelectPanel';
import { Size } from 'components/types';
import Icon from 'components/icons/Icon';
import { IconName } from 'components/icons/types';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import styles from './input-pill-select.css';

export interface Props {
  id?: string;
  options: Option<string>[];
  value: string[];
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  isValid?: boolean;
  isError?: boolean;
  name?: string;
  hintText?: string;
}

interface Events {
  onChange: (e: (
    EventPayload<
    string[],
    HTMLButtonElement
    | HTMLInputElement,
    MouseEvent
    | InputEvent
    >
  )) => void;
}

@Component
export default class InputPillSelect extends TSXComponent<Props & Partial<Pick<HTMLInputElement, 'required'>>, Events> {
  public $refs: {
    input: HTMLInputElement;
    container: HTMLDivElement;
    list: Vue;
  };

  private isExpanded = false;

  public isPanelFocused = false;

  public suffixIcon?: IconName;

  private searchString = '';

  @Prop({ default: () => `input-pill-select-${getRandomString()}` })
  protected id: string;

  @Prop()
  public options: Props['options'];

  @Prop()
  public label: Props['label'];

  @Prop()
  public placeholder: Props['placeholder'];

  @Prop({ default: [] })
  public value: NonNullable<Props['value']>;

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public name: Props['name'];

  @Prop()
  public isValid: Props['isValid'];

  @Prop()
  public isError: Props['isError'];

  @Prop()
  public hintText: Props['hintText'];

  private get selectedOptions() {
    if (this.value && this.value.length) {
      return this.value.map((selectedValue: string) => this.options.find(
        (option: Option<string>) => option.value === selectedValue,
      )).filter(filterFalsy);
    }
    return [];
  }

  private get filteredOptions() {
    const trimmedLowerCaseSearchString = this.searchString.trim().toLowerCase();

    if (trimmedLowerCaseSearchString.length === 0) {
      return this.options;
    }

    return this.options.filter(({ label }) => (
      label.trim().toLowerCase().includes(trimmedLowerCaseSearchString)
    ));
  }

  private get isInvalid(): boolean {
    return this.isError
      || (this.$attrs.required !== undefined
        && (this.value === undefined || this.value.length === 0));
  }

  private onChange(
    e: (
      SyntheticEvent<
      HTMLElement
      | HTMLInputElement,
      MouseEvent
      | InputEvent
      >
    ),
    payload: string[],
  ) {
    this.close();
    this.searchString = '';
    this.$emit('change', createEventPayload(e, payload));
  }

  private onKeyup(e: SyntheticEvent<HTMLInputElement, KeyboardEvent>) {
    if (
      this.isDisabled
      || e.key === Key.ENTER
      || e.key === Key.TAB
      || e.key === Key.SHIFT
      || e.key === Key.CAPS_LOCK
    ) {
      return;
    }
    if (e.key === Key.ESCAPE) {
      this.close();
      return;
    }
    if (e.key === Key.ARROW_DOWN || e.key === Key.ARROW_UP) {
      this.isPanelFocused = true;
    }
    if (e.target.value === '' && e.key === Key.BACKSPACE) {
      this.onChange(
        e,
        this.value.filter((value: string, index) => index === this.value.length - 1),
      );
    }

    this.searchString = e.target.value;
    this.open();
  }

  private onKeydown(e) {
    // Prevent submit while selecting
    if (e.key === Key.ENTER && this.isExpanded) {
      e.preventDefault();
    }
  }

  private onDeletePillClick(
    e: SyntheticEvent<HTMLButtonElement, MouseEvent>,
    valueToDelete: string | number,
  ) {
    e.preventDefault();
    e.stopPropagation();
    this.$refs.input.blur();
    this.close();
    this.onChange(
      e,
      this.value.filter((value: string) => value !== valueToDelete),
    );
  }

  private onOutsideClick(e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>) {
    e.stopPropagation();
    this.close();
  }

  private open() {
    if (this.isDisabled) {
      return;
    }
    this.$refs?.input?.focus();
    this.isExpanded = true;
  }

  private onSelect(e: EventPayload<string | number, HTMLElement, KeyboardEvent | MouseEvent>) {
    if (this.value.includes(e.payload as string)) {
      this.onChange(
        e.event,
        this.value.filter((value: string) => value !== e.payload as string),
      );
    } else {
      this.onChange(
        e.event,
        [...this.value, e.payload as string],
      );
    }
    this.$refs.input.blur();
    this.close();
  }

  private close() {
    this.isExpanded = false;
    this.isPanelFocused = false;
  }

  public render() {
    return (
      <div>
        {this.label && (
          <label
            class={styles.inputPillSelectLabel}
            for={this.id}
          >
            {this.label}
          </label>
        )}
        <div
          // the required props are there, just not in this element, but the way proposed by w3c
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          class={{
            [styles.inputPillSelect]: true,
            [styles.inputPillSelectDisabled]: this.isDisabled,
          }}
          ref="container"
          aria-expanded={this.isExpanded}
          aria-owns={`${this.id}-listbox`}
          aria-haspopup="listbox"
          id={`${this.id}-combobox`}
          tabIndex={0}
          onFocus={this.open}
        >
          {this.isExpanded && <OutsideClickHandler
            insideRef={() => this.$refs.container}
            onOutsideClick={this.onOutsideClick}
          />}

          <div class={{
            [styles.inputPillSelectInner]: true,
            [styles.inputPillSelectInnerSuccess]: this.isValid,
            [styles.inputPillSelectInnerError]: this.isInvalid,
            [styles.inputPillSelectInnerActive]: this.isExpanded,

          }}>
            <div class={{
              [styles.inputPillSelectValuesAndSearch]: true,
            }}>
              {this.selectedOptions.length > 0 && (
                this.selectedOptions.map(option => (
                  <Badge
                    size={Size.SMALL}
                    class={styles.inputPillSelectBadge}
                    preset={BadgePreset.INFO}
                    isClosable={!this.isDisabled}
                    onCloseClick={e => this.onDeletePillClick(e, option.value)}
                  >
                    {option?.label}
                  </Badge>
                ))
              )}

              <input
                id={this.id}
                disabled={this.isDisabled}
                type="text"
                ref="input"
                value={this.searchString}
                class={styles.inputPillSelectInput}
                onKeydown={this.onKeydown}
                onKeyup={this.onKeyup}
                onFocus={this.open}
                aria-controls={`${this.id}-listbox`}
                placeholder={this.selectedOptions.length === 0 ? this.placeholder : ''}
              />
            </div>
            <div class={styles.inputPillSelectSuffixWrapper}>
              <Icon
                aria-hidden="true"
                class={{
                  [styles.inputPillSelectChevron]: true,
                  [styles.inputPillSelectChevronMenuHidden]: this.isExpanded,
                }}
                name={IconName.CHEVRON_DOWN}
                size={Size.SMALL}
              />
              {(this.isInvalid || this.isValid) && (
                <Icon
                  aria-hidden="true"
                  name={this.isValid ? IconName.CHECK_CIRCLE : IconName.REPORT_PROBLEM}
                  size={Size.SMALL}
                  ref="iconRef"
                  class={{
                    [styles.inputPillSelectSuffixIcon]: true,
                    [styles.inputPillSelectSuffixIconError]: this.isInvalid,
                    [styles.inputPillSelectSuffixIconSuccess]: this.isValid,
                  }}
                />
              )}
            </div>

          </div>
          {!this.isDisabled && this.isExpanded && (
            <SelectPanel
              id={`${this.id}-listbox`}
              value={this.value}
              onSelect={e => this.onSelect(e)}
              options={this.filteredOptions}
              isPanelFocused={this.isPanelFocused}
              ref="list"
            />
          )}
        </div>
        <span class={{
          [styles.inputPillSelectHintText]: true,
          [styles.inputPillSelectHintTextError]: this.isInvalid,
          [styles.inputPillSelectHintTextSuccess]: this.isValid,
        }}
        >{this.hintText}</span>
      </div>
    );
  }
}
