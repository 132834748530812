import { Component, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import Loader from 'components/loader/Loader';
import Pagination from 'components/pagination/Pagination';
import SpIcon from 'components/sp-icon/SpIcon';
import Action from 'components/table/store/Action';
import { LoadingState, SetFilterPayload } from 'components/table/store/Store';
import { EventPayload } from 'src/utils/events';
import DndKind from 'components/calendar-common/common/dnd/DndKind';
import { dragAndDropNS } from 'src/store/drag-and-drop/store';
import type { SetItemAction } from 'src/store/drag-and-drop/store';
import DragAndDropAction from 'src/store/drag-and-drop/Action';
import { DraggableEmploymentPayload } from 'src/store/drag-and-drop/types';
import FilterBox, { Slot } from '../filter-box/FilterBox';
import EmploymentItem from './employment-item/EmploymentItem';
import { employmentFilterNS, Filter, StoreState } from './store/Store';
import styles from './filter-box-employment.css';
import {
  shiftRotationGroupsFilterNS,
  StoreState as ShiftRotationFilterBoxStoreState,
} from '../filter-box-shift-rotation-group/store/Store';

@Component
export default class FilterBoxEmployment extends TsxComponent<{}> {
  protected isOpen = false;

  protected isSearchExpanded = false;

  protected timeout = Number.NaN;

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @shiftRotationGroupsFilterNS.State('selection')
  protected selectedShiftRotationGroups: ShiftRotationFilterBoxStoreState['selection'];

  @employmentFilterNS.Action(Action.SET_FILTER)
  private setFilter: (
    payload: SetFilterPayload<Filter, keyof Filter>
  ) => Promise<void>;

  @employmentFilterNS.Action(Action.SET_PAGE)
  protected setPage: (page: number) => Promise<void>;

  @employmentFilterNS.Action(Action.SET_SELECTION)
  protected setSelection: (id: number[]) => void;

  @employmentFilterNS.Action(Action.SUBSCRIBE)
  protected subscribe: () => Promise<void>;

  @employmentFilterNS.Action(Action.UNSUBSCRIBE)
  protected unsubscribe: () => Promise<void>;

  @employmentFilterNS.Action(Action.REFETCH)
  protected refetch: () => Promise<void>;

  @employmentFilterNS.State
  protected count: StoreState['count'];

  @employmentFilterNS.State(state => state.data)
  protected employments: StoreState['data'];

  @employmentFilterNS.State
  protected loadingState: StoreState['loadingState'];

  @employmentFilterNS.State
  protected page: StoreState['page'];

  @employmentFilterNS.State
  protected perPage: StoreState['perPage'];

  @employmentFilterNS.State
  protected selection: StoreState['selection'];

  @dragAndDropNS.Action(DragAndDropAction.SET_ITEM)
  protected setDraggableItem: SetItemAction;

  @dragAndDropNS.Action(DragAndDropAction.CLEAR_ITEM)
  protected clearDndItem;

  @Watch('selectedShiftRotationGroups')
  protected onShiftRotationGroupsChange() {
    if (this.isOpen) {
      this.refetch();
    }
  }

  protected get pages() {
    return Math.ceil(this.count / this.perPage);
  }

  protected onItemSelect(
    e: SyntheticEvent<HTMLElement, MouseEvent | KeyboardEvent>,
    employmentId: number,
  ) {
    this.setSelection(
      this.selection.includes(employmentId)
        ? []
        : [employmentId],
    );

    e.preventDefault();
  }

  protected onOpenClose({ payload: state }: EventPayload<boolean>) {
    this.isOpen = state;

    if (state) {
      this.subscribe();
    } else {
      this.unsubscribe();
    }
  }

  protected onPageChange({ payload }: EventPayload<number>) {
    this.setPage(payload);
    this.setSelection([]);
  }

  protected onSearchInput(e: SyntheticEvent<HTMLInputElement, KeyboardEvent>) {
    window.clearTimeout(this.timeout);

    this.timeout = window.setTimeout(() => {
      this.setFilter({ key: 'search', value: e.target.value });
      this.setSelection([]);
    }, 500);
  }

  private onDragStart(employment: StoreState['data'][0]) {
    const payload: DraggableEmploymentPayload = {
      kind: DndKind.EMPLOYMENT,
      id: employment.id,
      activeLocationsPositionIds: employment
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .locationsPositions?.filter(it => it && it.id).map(it => it!.id) || [],
      isDeleted: employment.deletedAt !== null,
    };

    this.setDraggableItem(payload);
  }

  private onDragEnd() {
    this.clearDndItem();
  }

  public beforeDestroy() {
    this.unsubscribe();
  }

  public render() {
    return (
      <FilterBox isOpen={this.isOpen} onOpenCloseClick={this.onOpenClose}>
        <template slot={Slot.TITLE}>{this.$t('shiftSchedule.filter.employment.title')}</template>

        <div class={styles.filterBoxEmploymentTopBar}>
          <button
            aria-expanded={this.isSearchExpanded}
            aria-controls="employment-search"
            class={{
              [styles.filterBoxEmploymentButton]: true,
              [styles.filterBoxEmploymentButtonActive]: this.isSearchExpanded,
            }}
            id="employment-search-button"
            onClick={() => { this.isSearchExpanded = !this.isSearchExpanded; }}
          >
            <SpIcon class={styles.filterBoxEmploymentIcon} name="search" />
            {this.$t('shiftSchedule.filter.employment.buttonSearch')}
          </button>

          {/* sorting employments is not supported by API */}
        </div>

        <div
          aria-labelledby="employment-search-button"
          class={{
            [styles.filterBoxEmploymentSearch]: true,
            [styles.filterBoxEmploymentSearchExpanded]: this.isSearchExpanded,
          }}
          id="employment-search"
        >
          <label class={styles.filterBoxEmploymentLabel}>
            <input
              class={styles.filterBoxEmploymentInput}
              onInput={this.onSearchInput}
              type="search"
            />
            <SpIcon class={styles.filterBoxEmploymentLabelIcon} name="search" />
          </label>
        </div>

        {this.loadingState === LoadingState.LOADING && (
          <Loader />
        )}

        {this.loadingState === LoadingState.IDLE && (
          <ul class={styles.filterBoxEmploymentList} role="radiogroup">
            {(this.employments || []).map(employment => (
              <EmploymentItem
                class={styles.filterBoxEmploymentDraggable}
                employment={employment}
                isSelected={this.selection.includes(employment.id)}
                key={employment.id}
                onSelect={({ event, payload }) => this.onItemSelect(event, payload)}
                onDragStart={() => this.onDragStart(employment)}
                onDragEnd={this.onDragEnd}
              />
            ))}
          </ul>
        )}

        {this.pages > 1 && this.loadingState === LoadingState.IDLE && (
          <Pagination
            class={styles.filterBoxEmploymentPagination}
            isLongGapOmitted={true}
            selected={this.page}
            length={this.pages}
            onClick={this.onPageChange}
          />
        )}
      </FilterBox>

    );
  }
}
