import type { GQLShiftPreset } from 'codegen/gql-types';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { SET_SELECTED_SHIFT_PRESET_IDS } from './Actions';
import Mutations from './Mutations';

export const shiftPresetsNS = namespace('shiftPresets');

export type ShiftPreset = GQLShiftPreset;

export interface StoreState {
  selectedShiftPresetIds: number[];
}

export const store = (selectedShiftPresetIds: number[] = []): Module<StoreState, any> => ({
  namespaced: true,
  state: {
    selectedShiftPresetIds,
  },
  mutations: {
    [Mutations.SET_SELECTED_SHIFT_PRESET_IDS](state, shiftPresetIds) {
      state.selectedShiftPresetIds = shiftPresetIds;
    },
  },
  actions: {
    [SET_SELECTED_SHIFT_PRESET_IDS]({ commit }, itemIds) {
      commit(Mutations.SET_SELECTED_SHIFT_PRESET_IDS, itemIds);
    },
  },
});
