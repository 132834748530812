import { FiltersMap, StoreState as CalendarFilterStoreState } from 'components/calendar-common/filters/Store';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Action as FilterStoreAction } from 'store/filter-store';
import TableStoreAction from 'components/table/store/Action';
import { SetFilterPayload } from 'components/table/store/Store';
import type { SetSelectionFunction } from 'components/table/store/Store';
import ButtonResetFilters from '../button-reset-filters/ButtonResetFilters';
import { locationsPositionsFilterNS, StoreState as LocationsPositionsFilterStoreState } from '../filter-box-locations-position/store/Store';
import { quickFilterNS, StoreState as QuickFilterFilterStoreState } from '../filter-box-quick/store/Store';
import QuickFilterAction from '../filter-box-quick/store/Action';
import { employmentFilterNS } from '../filter-box-employment/store/Store';
import type { Filter } from '../filter-box-employment/store/Store';
import { shiftScheduleNS } from '../store/Store';
import { shiftRotationGroupsFilterNS } from '../filter-box-shift-rotation-group/store/Store';

@Component
export default class CalendarAbsencesResetFiltersContainer extends TsxComponent<{}> {
  @shiftScheduleNS.Getter
  protected absencePlanningQuickFilters: FiltersMap[];

  @quickFilterNS.State('filters')
  protected quickFilters: QuickFilterFilterStoreState['filters'];

  @quickFilterNS.Action(QuickFilterAction.SET_FILTERS)
  protected setQuickFilters: (quickFilters: FiltersMap[]) => Promise<void>;

  @shiftRotationGroupsFilterNS.State('selection')
  public shiftRotationGroupIds: CalendarFilterStoreState['selectedShiftRotationGroupIds'];

  @shiftRotationGroupsFilterNS.Action(FilterStoreAction.SET_SELECTION)
  protected setShiftRotationGroupsSelection: (tagIds: number[]) => Promise<void>;

  @locationsPositionsFilterNS.State('selection')
  protected locationsPositionIds: LocationsPositionsFilterStoreState['selection'];

  @locationsPositionsFilterNS.Action(FilterStoreAction.SET_SELECTION)
  protected setLocationsPositionsSelection: (
    locationsPositionIds: number[]
  ) => Promise<void>;

  @employmentFilterNS.State('filters')
  protected employmentFilters: Filter;

  @employmentFilterNS.State('selection')
  protected employmentIds: number[];

  @employmentFilterNS.Action(TableStoreAction.SET_FILTER)
  private setEmploymentsFilter: (
    payload: SetFilterPayload<Filter, keyof Filter>
  ) => Promise<void>;

  @employmentFilterNS.Action(TableStoreAction.SET_SELECTION)
  private setEmploymentsSelection: SetSelectionFunction;

  private get selectedFiltersCount() {
    return this.quickFilters
      .filter(filter => this.absencePlanningQuickFilters.includes(filter)).length
      + this.locationsPositionIds.length
      + (this.shiftRotationGroupIds ? this.shiftRotationGroupIds.length : 0)
      + (this.employmentFilters.search
        && this.employmentFilters.search?.length ? 1 : 0)
      + this.employmentIds.length;
  }

  private onClick() {
    this.setQuickFilters([]);
    this.setLocationsPositionsSelection([]);
    this.setShiftRotationGroupsSelection([]);
    // employments filter needs to be reset as well
    this.setEmploymentsFilter({ key: 'search', value: '' });
    this.setEmploymentsSelection([]);
  }

  public render() {
    return (
      <ButtonResetFilters
        onClick={() => this.onClick()}
        selectedFiltersCount={this.selectedFiltersCount}
      />
    );
  }
}
