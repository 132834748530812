
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShiftAssignmentGroupFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShiftAssignmentGroup"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"deletedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"assignmentGroupId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"shiftId"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":137}};
    doc.loc.source = {"body":"fragment ShiftAssignmentGroupFragment on ShiftAssignmentGroup {\n  id\n  createdAt\n  updatedAt\n  deletedAt\n  assignmentGroupId\n  shiftId\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
