import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLAssignmentGroupsQuery,
  GQLAssignmentGroupsQueryVariables,
  GQLCreateAssignmentGroupMutation,
  GQLCreateAssignmentGroupMutationVariables,
  GQLAssignmentGroupCreateInput,
  GQLUpdateAssignmentGroupMutation,
  GQLUpdateAssignmentGroupMutationVariables,
  GQLAssignmentGroupUpdateInput,
  GQLAssignmentGroupFragmentFragment,
  GQLFetchAssignmentGroupQueryVariables,
  GQLFetchAssignmentGroupQuery,
  GQLRemoveAssignmentGroupMutation,
  GQLRemoveAssignmentGroupMutationVariables,
  GQLUnassignEmploymentFromAssignmentGroupMutationVariables,
  GQLUnassignEmploymentFromAssignmentGroupMutation,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { StoreActionResult, PayloadParameter, StoreActionState } from 'utils/store';
import AssignmentGroupsGql from './queries/AssignmentGroups.gql';
import CreateAssignmentGroupGql from './queries/CreateAssignmentGroup.gql';
import UpdateAssignmentGroupGql from './queries/UpdateAssignmentGroup.gql';
import FetchAssignmentGroupGql from './queries/FetchAssignmentGroup.gql';
import RemoveAssignmentGroupGql from './queries/RemoveAssignmentGroup.gql';
import UnassignEmploymentFromAssignmentGroup from './queries/UnassignEmploymentFromAssignmentGroup.gql';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
  handleUnexpectedResult,
  isSuccessResult,
} from '../normalized-store';
import AssignmentGroupAction from './Action';

export const assignmentGroupsNS = namespace('assignmentGroups');

export type AssignmentGroup = GQLAssignmentGroupFragmentFragment;

export type StoreState = ById<AssignmentGroup>;

export type CreateAssignmentGroupFunction = (
  payload: { assignmentGroup: GQLAssignmentGroupCreateInput; shiftplanId: number },
) => Promise<StoreActionResult>;

export type UpdateAssignmentGroupFunction = (
  payload: { assignmentGroup: GQLAssignmentGroupUpdateInput; id: number },
) => Promise<StoreActionResult>;

export type FetchAllAssignmentGroupsFunction = (
  payload: Omit<GQLAssignmentGroupsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchAssignmentGroupFunction = (
  payload: Omit<GQLFetchAssignmentGroupQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type RemoveAssignmentGroupFunction = (
  payload: Omit<GQLRemoveAssignmentGroupMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UnassignEmploymentFromAssignmentGroupFunction = (
  payload: Omit<GQLUnassignEmploymentFromAssignmentGroupMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

export type GetByShiftplanId = (shiftplanId: number) => AssignmentGroup[];

const getAssignmentGroupsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
    getters: {
      getByShiftplanId: (state: StoreState) => (shiftplanId: number) => (
        Object.values<AssignmentGroup>(state.byId)
          .filter(item => item.shiftplan.id === shiftplanId)
          .sort((x, y) => x.name.localeCompare(y.name))
      ),
    },
    actions: {
      async [AssignmentGroupAction.UNASSIGN_EMPLOYMENT](
        { rootState },
        payload: PayloadParameter<UnassignEmploymentFromAssignmentGroupFunction>,
      ): Promise<StoreActionResult> {
        if (!rootState.auth.currentCompanyId) {
          return { state: StoreActionState.ERROR };
        }
        const variables: GQLUnassignEmploymentFromAssignmentGroupMutationVariables = {
          ...payload,
          companyId: rootState.auth.currentCompanyId,
        };

        /* eslint-disable @typescript-eslint/indent */
        const result = await graphqlClient
          .mutate<
            GQLUnassignEmploymentFromAssignmentGroupMutation,
            GQLUnassignEmploymentFromAssignmentGroupMutationVariables
          >({
            mutation: UnassignEmploymentFromAssignmentGroup,
            variables,
          });
        /* eslint-enable @typescript-eslint/indent */

        if (!isSuccessResult(result, 'unassignEmploymentFromAssignmentGroup')) {
          return handleUnexpectedResult(AssignmentGroupAction.UNASSIGN_EMPLOYMENT as any, logger);
        }

        return {
          state: StoreActionState.SUCCESS,
        };
      },
    },
  });

  const create: ActionProvider<
  GQLCreateAssignmentGroupMutation,
  GQLCreateAssignmentGroupMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateAssignmentGroupFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: CreateAssignmentGroupGql,
      resultKey: 'createAssignmentGroup',
      variables: {
        companyId: rootState.auth.currentCompanyId,
        ...payload,
      },
    });
  };

  const update: ActionProvider<
  GQLUpdateAssignmentGroupMutation,
  GQLUpdateAssignmentGroupMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateAssignmentGroupFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: UpdateAssignmentGroupGql,
      resultKey: 'updateAssignmentGroup',
      variables: {
        companyId: rootState.auth.currentCompanyId,
        ...payload,
      },
    });
  };

  const fetch: ActionProvider<
  GQLFetchAssignmentGroupQuery,
  GQLFetchAssignmentGroupQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAssignmentGroupFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: FetchAssignmentGroupGql,
      resultKey: 'assignmentGroups',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  const remove: ActionProvider<
  GQLRemoveAssignmentGroupMutation,
  GQLRemoveAssignmentGroupMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<RemoveAssignmentGroupFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: RemoveAssignmentGroupGql,
      resultKey: 'removeAssignmentGroup',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  const fetchAll: ActionProvider<
  GQLAssignmentGroupsQuery,
  GQLAssignmentGroupsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllAssignmentGroupsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: AssignmentGroupsGql,
      resultKey: 'assignmentGroups',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  return createNormalizedStore<AssignmentGroup, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.UPDATE]: update,
      [Action.FETCH]: fetch,
      [Action.DELETE]: remove,
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getAssignmentGroupsStore;
