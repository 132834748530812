import { store as absences, StoreState as AbsencesStoreState } from 'components/calendar/absences/Store';
import { store as agenda, StoreState as AgendaStoreState } from 'components/calendar/agenda/Store';
import {
  store as common,
  StoreState as CommonStoreState,
} from 'components/calendar/common/Store';
import { store as data, StoreState as DataStoreState } from 'components/calendar/data/Store';
import { store as employments, StoreState as EmploymentsStoreState } from 'components/calendar-common/employments/Store';
import {
  store as filters,
  StoreState as FiltersStoreState,
} from 'components/calendar-common/filters/Store';
import { store as highlights, StoreState as HighlightsStoreState } from 'components/calendar-common/highlights/Store';
import { store as notes, StoreState as NotesStoreState } from 'components/calendar-common/notes/Store';
import { store as positions, ModuleState as PositionsModuleState } from 'components/calendar-common/positions/Store';
import { store as shifts, StoreState as ShiftsStoreState } from 'components/calendar-common/shifts/store/Store';
import { store as tags, StoreState as TagsStoreState } from 'components/calendar-common/tags/Store';
import { store as pagination, StoreState as PaginationStoreState } from 'components/calendar-common/common/pagination-mixin/Store';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { CalendarNamespace } from 'components/calendar-common/Enums';

export interface ModuleState {
  notes: NotesStoreState;
  agenda: AgendaStoreState;
  highlights: HighlightsStoreState;
  shifts: ShiftsStoreState;
  absences: AbsencesStoreState;
  filters: FiltersStoreState;
  positions: PositionsModuleState;
  employments: EmploymentsStoreState;
  common: CommonStoreState;
  tags: TagsStoreState;
  data: DataStoreState;
  pagination: PaginationStoreState;
}

const getCalendarStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  applicationLogger: ApplicationLogger,
) => ({
  namespaced: true,
  modules: {
    agenda,
    highlights: highlights(CalendarNamespace.CALENDAR),
    shifts: shifts(graphqlClient),
    absences: absences(),
    filters: filters(CalendarNamespace.CALENDAR),
    positions: positions(CalendarNamespace.CALENDAR),
    employments: employments(CalendarNamespace.CALENDAR),
    common: common(),
    notes: notes(CalendarNamespace.CALENDAR),
    tags: tags(),
    data: data(graphqlClient, applicationLogger),
    pagination: pagination(),
  },
});

export default getCalendarStore;
