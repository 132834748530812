import {
  createStore,
  DropActionTypes,
  EventEditTypes,
  onDropCallback,
  StoreState as SplittableEventStoreState,
} from 'components/calendar-common/common/time-grid-events/StoreFactory';
import Shift from 'components/calendar-common/shifts/Shift';
import { namespace } from 'vuex-class';
import ShiftPart from './ShiftPart';

export const agendaNS = namespace('calendar/agenda');

export const AGENDA_GRID_STEP = 30;

const onDrop: onDropCallback = (shift, eventEditType, dropAction, dispatch) => {
  const startDate = shift.getStartDateTime();
  const endDate = shift.getEndDateTime();
  switch (eventEditType) {
    case EventEditTypes.RESIZE_EVENT:
    case EventEditTypes.DRAG_EVENT:
      if (dropAction === DropActionTypes.DEFAULT) {
        dispatch(
          'calendar/shifts/updateRequest',
          {
            id: shift.id,
            params: {
              /* eslint-disable camelcase */
              starts_at_date: startDate.format('DD.MM.YYYY'),
              ends_at_date: endDate.format('DD.MM.YYYY'),
              starts_at_time: startDate.format('HH:mm'),
              ends_at_time: endDate.format('HH:mm'),
              enforce_break_warning: 'true',
              /* eslint-enable camelcase */
            },
          },
          {
            root: true,
          },
        );
      }
      // TODO: add delete drop box in d&d ticket
      // window.DeleteDropBox.hide();
      break;
    default:
  }
};
export type StoreState = SplittableEventStoreState<Shift>;
export const store = createStore<Shift>('calendar/shifts/filteredShifts', 'calendar/common/dates', ShiftPart, onDrop);
