import { isSameDay, isWithinInterval } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DAYS_IN_WEEK, getDateInTimeZone } from 'src/utils/date-related';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SelectedTimeframe, SelectionMode } from '../types';
import datePickerStyles from '../datepicker.css';
import styles from './day.css';

interface Props {
  currentSelection: Date[];
  currentHoveredDates: SelectedTimeframe | null;
  day: Date;
  minDate: Date;
  maxDate: Date;
  dayIndex: number;
  selectionMode: SelectionMode;
  timeZone: string;
  disabledDates: Date[];
}

interface Events {
  onDayClick: EventPayload<Date, HTMLButtonElement, MouseEvent>;
  onMouseEnter: EventPayload<Date, HTMLButtonElement, MouseEvent>;
  onMouseLeave: EventPayload<null, HTMLButtonElement, MouseEvent>;
}

@Component
export default class Day extends TsxComponent<Props, Events> {
  @Prop()
  public currentSelection: Props['currentSelection'];

  @Prop()
  public currentHoveredDates: Props['currentHoveredDates'];

  @Prop()
  public day: Props['day'];

  @Prop()
  public minDate: Props['minDate'];

  @Prop()
  public maxDate: Props['maxDate'];

  @Prop()
  public dayIndex: Props['dayIndex'];

  @Prop()
  public selectionMode: Props['selectionMode'];

  @Prop()
  public timeZone: Props['timeZone'];

  @Prop()
  public disabledDates: Props['disabledDates'];

  private get utcDay() {
    return zonedTimeToUtc(this.day, this.timeZone);
  }

  private get selectionStartsAtInTimeZone() {
    return this.currentSelection.length > 0
      ? getDateInTimeZone(this.currentSelection[0], this.timeZone)
      : undefined;
  }

  private get selectionEndsAtInTimeZone() {
    return this.currentSelection.length > 0
      ? getDateInTimeZone(this.currentSelection[this.currentSelection.length - 1], this.timeZone)
      : undefined;
  }

  private get isSelectionBoundary() {
    return this.selectionMode === SelectionMode.INTERVAL
      && this.selectionStartsAtInTimeZone
      && this.selectionEndsAtInTimeZone
      && (isSameDay(this.day, this.selectionStartsAtInTimeZone)
        || isSameDay(this.day, this.selectionEndsAtInTimeZone));
  }

  private get isIntervalSelectionStartDay() {
    return this.selectionMode === SelectionMode.INTERVAL
      && this.selectionStartsAtInTimeZone
      && isSameDay(this.selectionStartsAtInTimeZone, this.day);
  }

  private get isIntervalSelectionEndDay() {
    return this.selectionMode === SelectionMode.INTERVAL
      && this.selectionEndsAtInTimeZone
      && isSameDay(this.selectionEndsAtInTimeZone, this.day);
  }

  private get isWithinMinMax() {
    return isWithinInterval(this.utcDay, {
      start: this.minDate,
      end: this.maxDate,
    });
  }

  private get isInDisabledDates() {
    return !!this.disabledDates
      .find(date => isSameDay(getDateInTimeZone(date, this.timeZone), this.day));
  }

  private get isWithinIntervalSelection() {
    return this.selectionStartsAtInTimeZone
    && this.selectionEndsAtInTimeZone
    && this.selectionMode === SelectionMode.INTERVAL
    && isWithinInterval(this.day, {
      start: this.selectionStartsAtInTimeZone,
      end: this.selectionEndsAtInTimeZone,
    });
  }

  private get isPreSelectionStartDay() {
    return this.currentHoveredDates
      && isSameDay(this.currentHoveredDates.startsAt, this.day);
  }

  private get isPreSelectionEndDay() {
    return this.currentHoveredDates
      && isSameDay(this.currentHoveredDates.endsAt, this.day);
  }

  private get isWithinHovered() {
    return this.currentHoveredDates
      && isWithinInterval(this.day, {
        start: this.currentHoveredDates.startsAt,
        end: this.currentHoveredDates.endsAt,
      });
  }

  private get isSingleSelected() {
    return this.selectionMode !== SelectionMode.INTERVAL
    && this.currentSelection
      .find(it => it.valueOf() === this.utcDay.valueOf());
  }

  public render() {
    return (
      <button
        onClick={e => this.$emit('dayClick', createEventPayload(e, this.utcDay))}
        class={{
          [datePickerStyles.datepickerGridCell]: true,
          [styles.day]: true,
          [styles.dayIntervalSelected]: this.isWithinIntervalSelection,
          [styles.daySingleSelected]: this.isSingleSelected,
          [styles.dayFirstColumn]: this.dayIndex === 0,
          [styles.dayLastColumn]: this.dayIndex === DAYS_IN_WEEK - 1,
          [styles.dayIntervalSelectionBoundaryStart]: this.isIntervalSelectionStartDay,
          [styles.dayIntervalSelectionBoundaryEnd]: this.isIntervalSelectionEndDay,
          [styles.dayPreSelectionIntervalHovered]: this.isWithinHovered,
          [styles.dayPreSelectionIntervalHoveredStart]:
            this.selectionMode === SelectionMode.INTERVAL && this.isPreSelectionStartDay,
          [styles.dayPreSelectionIntervalHoveredEnd]:
            this.selectionMode === SelectionMode.INTERVAL && this.isPreSelectionEndDay,
        }}
        disabled={!this.isWithinMinMax || this.isInDisabledDates}
        type="button"
        onMouseenter={(e) => { this.$emit('mouseEnter', createEventPayload(e, this.utcDay)); }}
        onMouseleave={() => { this.$emit('mouseLeave'); }}
      >
        <span
          class={{
            [styles.dayLabel]: true,
            [styles.dayLabelSingleSelected]: this.isSingleSelected,
            [styles.dayLabelIntervalSelectionBoundary]: this.isSelectionBoundary,
          }}
        >
          {this.day.getDate()}
        </span>
      </button>
    );
  }
}
