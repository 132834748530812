enum Action {
  INIT_CALENDAR = 'initCalendar',
  // prop setters
  SET_TIMEFRAME = 'setTimeframe',
  SET_VIEW_KIND = 'setViewKind',
  SET_PRINT_VIEW_KIND = 'setPrintViewKind',
  SET_SLOT_DISPLAY_STYLE = 'setSlotDisplayStyle',
  SET_JOB = 'setJob',
  REMOVE_JOB = 'removeJob',
}

export default Action;
