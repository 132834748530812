import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { getDurationStringInTimeZone } from 'utils/date-related';
import { filterFalsy } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './collisions.css';

@Component
export default class ShiftsCollision extends TsxComponent<{
  conflict: GQLShiftConflictsFragmentFragment;
}> {
  @Prop()
  public conflict: GQLShiftConflictsFragmentFragment;

  public render() {
    if (this.conflict.__typename !== 'ShiftsCollisionShiftConflict' || !this.conflict.shifts) {
      return null;
    }

    return (
      <span>
        <ul class={styles.collisions}>
          {
            this.conflict.shifts
              .filter(filterFalsy)
              .map(shift => (
                <li class={styles.collisionsItem}>
                  {
                    getDurationStringInTimeZone(
                      this.$timeZone.value,
                      this.$i18n.i18next.language,
                      new Date(shift.startsAt),
                      new Date(shift.endsAt),
                      true,
                    )
                  }
                </li>
              ))
          }
        </ul>
      </span>
    );
  }
}
