import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLAcceptReplaceOfferMutation,
  GQLAcceptReplaceOfferMutationVariables,
  GQLCreateShiftBreakMutation,
  GQLCreateShiftBreakMutationVariables,
  GQLAddTagToShiftMutation,
  GQLAddTagToShiftMutationVariables,
  GQLConfirmReplaceRequestMutation,
  GQLConfirmReplaceRequestMutationVariables,
  GQLConnectShiftsMutation,
  GQLDisconnectShiftMutation,
  GQLDisconnectShiftMutationVariables,
  GQLConnectShiftsMutationVariables,
  GQLCreateJoinRequestMutation,
  GQLCreateJoinRequestMutationVariables,
  GQLCreateLeaveRequestMutation,
  GQLCreateLeaveRequestMutationVariables,
  GQLCreateReplaceRequestMutation,
  GQLCreateReplaceRequestMutationVariables,
  GQLCreateShiftMutation,
  GQLCreateShiftMutationVariables,
  GQLDeleteRequestMutation,
  GQLDeleteRequestMutationVariables,
  GQLFetchShiftQuery,
  GQLFetchShiftQueryVariables,
  GQLReplaceRequestState,
  GQLShiftCreateInput,
  GQLShiftUpdateInput,
  GQLUpdateShiftMutation,
  GQLShiftFragmentFragment,
  GQLWithdrawAcceptedReplaceRequestMutation,
  GQLWithdrawAcceptedReplaceRequestMutationVariables,
  GQLFetchAllShiftsQuery,
  GQLFetchAllShiftsQueryVariables,
  GQLDeleteShiftMutationVariables,
  GQLDeleteShiftMutation,
  GQLUpdateShiftMutationVariables,
  GQLCopyShiftMutation,
  GQLCopyShiftMutationVariables,
  GQLAssignEmployeeToShiftRotationGroupMutationVariables,
  GQLAssignEmployeeToShiftRotationGroupMutation,
} from 'codegen/gql-types';
import type { HasAnyLocationRightFunction, HasAnyLocationsPositionRightFunction, HasAnyRightFunction } from 'components/auth/store/Store';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
  handleUnexpectedResult,
  isSuccessResult,
  Mutation,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult, StoreActionState } from 'utils/store';
import ShiftAction from './Action';
import AcceptReplaceOfferGql from './queries/AcceptReplaceOffer.gql';
import ConfirmReplaceRequestGql from './queries/ConfirmReplaceRequest.gql';
import CreateJoinRequestGql from './queries/CreateJoinRequest.gql';
import CreateLeaveRequestGql from './queries/CreateLeaveRequest.gql';
import CreateReplaceRequestGql from './queries/CreateReplaceRequest.gql';
import DeleteRequestGql from './queries/DeleteRequest.gql';
import FetchShiftGql from './queries/FetchShift.gql';
import FetchAllShiftsGql from './queries/FetchAllShifts.gql';
import WithdrawAcceptedReplaceRequestGql from './queries/WithdrawAcceptedReplaceRequest.gql';
import CreateShiftGql from './queries/CreateShift.gql';
import DeleteShiftGql from './queries/DeleteShift.gql';
import UpdateShiftGql from './queries/UpdateShift.gql';
import ConnectShiftsGql from './queries/ConnectShifts.gql';
import DisconnectShiftGql from './queries/DisconnectShift.gql';
import CopyShiftGql from './queries/CopyShift.gql';
import AddTagToShiftGql from './queries/AddTagToShift.gql';
import CreateShiftBreakGql from './queries/CreateShiftBreak.gql';
import AssignEmployeeToShiftRotationGroupGql from './queries/AssignEmployeeToShiftRotationGroup.gql';

export const shiftsNS = namespace('shifts');

export type StoreState = ById<Shift>;

export type Shift = GQLShiftFragmentFragment;

export type AcceptReplaceOfferFunction = (
  payload: Omit<GQLAcceptReplaceOfferMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type ConfirmReplaceRequestFunction = (
  payload: Omit<GQLConfirmReplaceRequestMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateJoinRequestFunction = (
  payload: Omit<GQLCreateJoinRequestMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateLeaveRequestFunction = (
  payload: Omit<GQLCreateLeaveRequestMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateReplaceRequestFunction = (
  payload: Omit<GQLCreateReplaceRequestMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteRequestFunction = (
  payload: Omit<GQLDeleteRequestMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchShiftFunction = (
  payload: Omit<GQLFetchShiftQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateShiftFunction = (
  payload: { shift: Omit<GQLShiftCreateInput, 'companyId'> },
) => Promise<StoreActionResult>;

export type DeleteShiftFunction = (
  payload: Omit<GQLDeleteShiftMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateShiftFunction = (
  payload: { shiftId: number; shift: Omit<GQLShiftUpdateInput, 'companyId'> },
) => Promise<StoreActionResult>;

export type FetchAllShiftsFunction = (
  payload: Omit<GQLFetchAllShiftsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type WithdrawAcceptedReplaceRequestFunction = (
  payload: Omit<GQLWithdrawAcceptedReplaceRequestMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CanManageFunction = (
  shiftLocationId: number, shiftLocationsPositionId: number,
) => boolean;

export type ConnectShiftsFunction = (
  payload: Omit<GQLConnectShiftsMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DisconnectShiftFunction = (
  payload: Omit<GQLDisconnectShiftMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CopyShiftFunction = (
  payload: Omit<GQLCopyShiftMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type AddTagToShiftFunction = (
  payload: Omit<GQLAddTagToShiftMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateShiftBreakFunction = (
  payload: Omit<GQLCreateShiftBreakMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type AssignEmployeeToShiftRotationGroupFunction = (
  payload: Omit<GQLAssignEmployeeToShiftRotationGroupMutationVariables, 'companyId'>
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getShiftsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      canManageStaff: (state, getters, rootState, rootGetters) => (
        (locationId: number, locationsPositionId: number) => {
          const isSuperAdmin = rootGetters['auth/isSuperAdmin'];
          const hasAnyRight: HasAnyRightFunction = rootGetters['auth/hasAnyRight'];
          const hasAnyLocationRight: HasAnyLocationRightFunction = rootGetters['auth/hasAnyLocationRight'];
          const hasAnyLocationsPositionRight: HasAnyLocationsPositionRightFunction = rootGetters['auth/hasAnyLocationsPositionRight'];

          return isSuperAdmin
            || hasAnyRight('payments_manage_all', 'payments_show_all', 'shifts_manage_all', 'shifts_show_all')
            || hasAnyLocationRight(locationId, 'payment_manage_right', 'payment_show_right', 'shift_manage_right', 'shift_show_right')
            || hasAnyLocationsPositionRight(locationsPositionId, 'payment_manage_right', 'payment_show_right', 'shift_manage_right', 'shift_show_right');
        }
      ),
      canManagePaygrade: (state, getters, rootState, rootGetters) => (
        (locationId: number, locationsPositionId: number) => {
          const isSuperAdmin = rootGetters['auth/isSuperAdmin'];
          const hasAnyRight: HasAnyRightFunction = rootGetters['auth/hasAnyRight'];
          const hasAnyLocationRight: HasAnyLocationRightFunction = rootGetters['auth/hasAnyLocationRight'];
          const hasAnyLocationsPositionRight: HasAnyLocationsPositionRightFunction = rootGetters['auth/hasAnyLocationsPositionRight'];

          return isSuperAdmin
            || hasAnyRight('payments_show_all', 'payments_manage_all')
            || hasAnyLocationRight(locationId, 'payment_show_right', 'payment_manage_right')
            || hasAnyLocationsPositionRight(locationsPositionId, 'payment_show_right', 'payment_manage_right');
        }
      ),
      canManageShift: (state, getters, rootState, rootGetters) => (
        (locationId: number, locationsPositionId: number) => {
          const isSuperAdmin = rootGetters['auth/isSuperAdmin'];
          const isStakeholder = rootGetters['auth/isStakeholder'];
          const hasAnyRight: HasAnyRightFunction = rootGetters['auth/hasAnyRight'];
          const hasAnyLocationRight: HasAnyLocationRightFunction = rootGetters['auth/hasAnyLocationRight'];
          const hasAnyLocationsPositionRight: HasAnyLocationsPositionRightFunction = rootGetters['auth/hasAnyLocationsPositionRight'];

          return isSuperAdmin
            || (isStakeholder && (
              hasAnyRight('payments_manage_all', 'shifts_manage_all')
              || hasAnyLocationRight(locationId, 'payment_manage_right', 'shift_manage_right')
              || hasAnyLocationsPositionRight(locationsPositionId, 'payment_manage_right', 'shift_manage_right')
            )
            );
        }
      ),
      getByShiftplanId: (state: StoreState) => (shiftplanId: number) => (
        // FAQ: implicitly uses sorting by ID which is also what the API returns
        Object.values(state.byId).filter(item => item.shiftplan.id === shiftplanId)
      ),
    },
    actions: {
      async [ShiftAction.ACCEPT_REPLACE_OFFER](
        { rootState },
        payload: PayloadParameter<AcceptReplaceOfferFunction>,
      ): ReturnType<AcceptReplaceOfferFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLAcceptReplaceOfferMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<GQLAcceptReplaceOfferMutation, GQLAcceptReplaceOfferMutationVariables>({
              mutation: AcceptReplaceOfferGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
          }

          const { acceptReplaceOffer } = result.data || {};

          if (!acceptReplaceOffer) {
            return handleUnexpectedResult(ShiftAction.ACCEPT_REPLACE_OFFER as any, logger);
          }

          const hasId = 'id' in acceptReplaceOffer && acceptReplaceOffer.id;
          const isAutoConfirmed = 'state' in acceptReplaceOffer && acceptReplaceOffer.state === GQLReplaceRequestState.AUTO_CONFIRMED;

          if (hasId || isAutoConfirmed) {
            return { state: StoreActionState.SUCCESS };
          }

          if ('conflicts' in acceptReplaceOffer) {
            return {
              state: StoreActionState.CONFLICT,
              conflicts: acceptReplaceOffer.conflicts,
            };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.CONFIRM_REPLACE_REQUEST](
        { rootState },
        payload: PayloadParameter<ConfirmReplaceRequestFunction>,
      ): ReturnType<ConfirmReplaceRequestFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLConfirmReplaceRequestMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<GQLConfirmReplaceRequestMutation, GQLConfirmReplaceRequestMutationVariables>({
              mutation: ConfirmReplaceRequestGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
          }

          const { confirmReplaceRequest } = result.data || {};

          if (!confirmReplaceRequest) {
            return handleUnexpectedResult(ShiftAction.CONFIRM_REPLACE_REQUEST as any, logger);
          }

          const hasId = 'id' in confirmReplaceRequest && confirmReplaceRequest.id;
          const isAutoConfirmed = 'state' in confirmReplaceRequest && confirmReplaceRequest.state === GQLReplaceRequestState.AUTO_CONFIRMED;

          if (hasId || isAutoConfirmed) {
            return { state: StoreActionState.SUCCESS };
          }

          if ('conflicts' in confirmReplaceRequest) {
            return {
              state: StoreActionState.CONFLICT,
              conflicts: confirmReplaceRequest.conflicts,
            };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.CREATE_JOIN_REQUEST](
        { rootState },
        payload: PayloadParameter<CreateJoinRequestFunction>,
      ): ReturnType<CreateJoinRequestFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLCreateJoinRequestMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<GQLCreateJoinRequestMutation, GQLCreateJoinRequestMutationVariables>({
              mutation: CreateJoinRequestGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
          }

          const { createJoinRequest } = result.data || {};

          if (!createJoinRequest) {
            return handleUnexpectedResult(ShiftAction.CREATE_JOIN_REQUEST as any, logger);
          }

          if (createJoinRequest.success) {
            return { state: StoreActionState.SUCCESS };
          }

          if ('conflicts' in createJoinRequest) {
            return {
              state: StoreActionState.CONFLICT,
              conflicts: createJoinRequest.conflicts,
            };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.CREATE_LEAVE_REQUEST](
        { rootState },
        payload: PayloadParameter<CreateLeaveRequestFunction>,
      ): ReturnType<CreateLeaveRequestFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLCreateLeaveRequestMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<GQLCreateLeaveRequestMutation, GQLCreateLeaveRequestMutationVariables>({
              mutation: CreateLeaveRequestGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
          }

          const { createLeaveRequest } = result.data || {};

          if (!createLeaveRequest) {
            return handleUnexpectedResult(ShiftAction.CREATE_LEAVE_REQUEST as any, logger);
          }

          if (createLeaveRequest.success) {
            return { state: StoreActionState.SUCCESS };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.CREATE_REPLACE_REQUEST](
        { rootState },
        payload: PayloadParameter<CreateReplaceRequestFunction>,
      ): ReturnType<CreateReplaceRequestFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLCreateReplaceRequestMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<GQLCreateReplaceRequestMutation, GQLCreateReplaceRequestMutationVariables>({
              mutation: CreateReplaceRequestGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
          }

          const { createReplaceRequest } = result.data || {};

          if (!createReplaceRequest) {
            return handleUnexpectedResult(ShiftAction.CREATE_REPLACE_REQUEST as any, logger);
          }

          const hasId = 'id' in createReplaceRequest && createReplaceRequest.id;

          if (hasId) {
            return { state: StoreActionState.SUCCESS };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.DELETE_REQUEST](
        { rootState },
        payload: PayloadParameter<DeleteRequestFunction>,
      ): ReturnType<DeleteRequestFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLDeleteRequestMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<GQLDeleteRequestMutation, GQLDeleteRequestMutationVariables>({
              mutation: DeleteRequestGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
          }

          if (!isSuccessResult(result, 'deleteRequest')) {
            return handleUnexpectedResult(ShiftAction.DELETE_REQUEST as any, logger);
          }

          return {
            state: result.data?.deleteRequest?.success
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
            error: result.data?.deleteRequest?.error || undefined,
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.WITHDRAW_ACCEPTED_REPLACE_REQUEST](
        { rootState },
        payload: PayloadParameter<WithdrawAcceptedReplaceRequestFunction>,
      ): ReturnType<WithdrawAcceptedReplaceRequestFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLWithdrawAcceptedReplaceRequestMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<
              GQLWithdrawAcceptedReplaceRequestMutation,
              GQLWithdrawAcceptedReplaceRequestMutationVariables
            >({
              mutation: WithdrawAcceptedReplaceRequestGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
          }

          return {
            state: result.data?.withdrawAcceptedReplaceRequest?.id
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.CONNECT_SHIFTS](
        { rootState },
        payload: PayloadParameter<ConnectShiftsFunction>,
      ): ReturnType<ConnectShiftsFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }
          const variables: GQLConnectShiftsMutationVariables = {
            ...payload,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<
              GQLConnectShiftsMutation,
              GQLConnectShiftsMutationVariables
            >({
              mutation: ConnectShiftsGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          return {
            state: result.data?.connectShifts.success
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.DISCONNECT_SHIFT](
        { rootState },
        payload: PayloadParameter<DisconnectShiftFunction>,
      ): ReturnType<DisconnectShiftFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }
          const variables: GQLDisconnectShiftMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          const result = await graphqlClient
            .mutate<
          GQLDisconnectShiftMutation,
          GQLDisconnectShiftMutationVariables
          >({
            mutation: DisconnectShiftGql,
            variables,
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          return {
            state: result.data?.disconnectShift.success
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.ADD_TAG_TO_SHIFT](
        { rootState },
        payload: PayloadParameter<AddTagToShiftFunction>,
      ): ReturnType<AddTagToShiftFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLAddTagToShiftMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<
              GQLAddTagToShiftMutation,
              GQLAddTagToShiftMutationVariables
            >({
              mutation: AddTagToShiftGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          return {
            state: result.data?.addTagToShift.id
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.CREATE_SHIFT_BREAK](
        { rootState },
        payload: PayloadParameter<CreateShiftBreakFunction>,
      ): ReturnType<CreateShiftBreakFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLCreateShiftBreakMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<
              GQLCreateShiftBreakMutation,
              GQLCreateShiftBreakMutationVariables
            >({
              mutation: CreateShiftBreakGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          return {
            state: result.data?.createShiftBreak.id
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.COPY_SHIFT](
        { rootState, commit },
        payload: PayloadParameter<CopyShiftFunction>,
      ): ReturnType<ConnectShiftsFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }
          const variables: GQLCopyShiftMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient
            .mutate<
              GQLCopyShiftMutation,
              GQLCopyShiftMutationVariables
            >({
              mutation: CopyShiftGql,
              variables,
            });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          if (!!result.data && 'conflicts' in result.data.copyShift) {
            return {
              state: StoreActionState.CONFLICT,
              conflicts: result.data.copyShift.conflicts,
            };
          }

          commit(Mutation.SET_ITEM, result.data!.copyShift);

          if (!!result.data && 'id' in result.data.copyShift) {
            return {
              state: StoreActionState.SUCCESS,
              entityId: result.data.copyShift.id,
            };
          }

          return { state: StoreActionState.ERROR };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },

      async [ShiftAction.ASSIGN_EMPLOYEE_TO_SHIFT_ROTATION_GROUP](
        { rootState },
        payload: PayloadParameter<AssignEmployeeToShiftRotationGroupFunction>,
      ): ReturnType<AssignEmployeeToShiftRotationGroupFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLAssignEmployeeToShiftRotationGroupMutationVariables = {
            companyId: rootState.auth.currentCompanyId,
            ...payload,
          };

          const result = await graphqlClient
            .mutate<
          GQLAssignEmployeeToShiftRotationGroupMutation,
          GQLAssignEmployeeToShiftRotationGroupMutationVariables
          >({
            mutation: AssignEmployeeToShiftRotationGroupGql,
            variables,
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          return {
            state: result.data?.assignEmployeeToShiftRotationGroup.jobId
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
    },
  };

  const fetch: ActionProvider<GQLFetchShiftQuery, GQLFetchShiftQueryVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchShiftFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: FetchShiftGql,
      resultKey: 'shifts',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  const create: ActionProvider<GQLCreateShiftMutation, GQLCreateShiftMutationVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateShiftFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = {
      shift: {
        ...payload.shift,
        companyId: rootState.auth.currentCompanyId,
      },
    };

    return ({
      resultKey: 'createShift',
      query: CreateShiftGql,
      variables,
      useBatching: true,
    });
  };

  const remove: ActionProvider<GQLDeleteShiftMutation, GQLDeleteShiftMutationVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteShiftFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = {
      ...payload,
      companyId: rootState.auth.currentCompanyId,
    };

    return ({
      resultKey: 'deleteShift',
      query: DeleteShiftGql,
      variables,
      useBatching: true,
    });
  };

  const update: ActionProvider<GQLUpdateShiftMutation, GQLUpdateShiftMutationVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateShiftFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = {
      id: payload.shiftId,
      companyId: rootState.auth.currentCompanyId,
      shift: {
        ...payload.shift,
        companyId: rootState.auth.currentCompanyId,
      },
    };

    return ({
      query: UpdateShiftGql,
      resultKey: 'updateShift',
      variables,
    });
  };

  const fetchAll: ActionProvider<GQLFetchAllShiftsQuery, GQLFetchAllShiftsQueryVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllShiftsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: FetchAllShiftsGql,
      resultKey: 'shifts',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  return createNormalizedStore<Shift, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH]: fetch,
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.UPDATE]: update,
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getShiftsStore;
