
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShiftRotationFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShiftRotation"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rotationInterval"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"startsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"anchorDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"shiftRotationGroups"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShiftRotationGroupFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":222}};
    doc.loc.source = {"body":"#import \"./ShiftRotationGroupFragment.gql\"\n\nfragment ShiftRotationFragment on ShiftRotation {\n  id\n  rotationInterval\n  startsAt\n  endsAt\n  anchorDate\n  name\n  shiftRotationGroups {\n    ...ShiftRotationGroupFragment\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./ShiftRotationGroupFragment.gql").definitions));


      module.exports = doc;
    
