import { GQLOrderDirection, GQLShiftEmploymentSort } from 'codegen/gql-types';
import SpIcon from 'components/sp-icon/SpIcon';
import Tabs, { Tab } from 'components/tabs/Tabs';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Chrono from './Chrono';
import Money from './Money';

import styles from './sorting.css';

@Component
export default class Sorting extends TsxComponent<{
  criterion: GQLShiftEmploymentSort;
  name?: string;
  isBringShiftsShown?: boolean;
  isChronoOpen?: boolean;
  isHourAccountShown?: boolean;
  isMoneyOpen?: boolean;
  isMoneyShown?: boolean;
  order: GQLOrderDirection;
}, {
  onChange: EventPayload<GQLShiftEmploymentSort>;
  onTabSelect: EventPayload<string>;
}> {
  @Prop()
  public criterion: GQLShiftEmploymentSort;

  @Prop()
  public isBringShiftsShown?: boolean;

  @Prop()
  public isChronoOpen?: boolean;

  @Prop()
  public isHourAccountShown?: boolean;

  @Prop()
  public isMoneyOpen?: boolean;

  @Prop()
  public isMoneyShown?: boolean;

  @Prop({ default: () => `sorting-${getRandomString()}` })
  public name: string;

  @Prop({ default: GQLOrderDirection.ASC })
  public order: GQLOrderDirection;

  protected onChange({ event, payload }: EventPayload<GQLShiftEmploymentSort>) {
    this.$emit('change', createEventPayload(event, payload));
  }

  protected onTabSelect({ event, payload }: EventPayload<Tab>) {
    this.$emit('tabSelect', createEventPayload(event, payload.id));
  }

  public render() {
    return (
      <section>
        <Tabs
          class={styles.sortingTabs}
          list={[
            {
              id: 'chrono',
              label: (
                <span class={styles.sortingTabLabel}>
                  <SpIcon name="clock" />
                  {this.$t('shifts.employees.sorting.chrono.label')}
                </span>
              ),
            }, {
              id: 'money',
              label: (
                <span class={styles.sortingTabLabel}>
                  <SpIcon name="cash-usd" />
                  {this.$t('shifts.employees.sorting.money.label')}
                </span>
              ),
            },
          ]}
          selected={this.isMoneyOpen ? 'money' : 'chrono'}
          onSelect={this.onTabSelect}
        >
          <Chrono
            criterion={this.criterion}
            name={this.name}
            isBringShiftsShown={this.isBringShiftsShown}
            isHourAccountShown={this.isHourAccountShown}
            order={this.order}
            onClick={this.onChange}
          />

          {
            this.isMoneyShown && (
              <Money
                criterion={this.criterion}
                name={this.name}
                order={this.order}
                onClick={this.onChange}
              />
            )
          }
        </Tabs>
      </section>
    );
  }
}
