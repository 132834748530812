import Absence from 'components/calendar-common/absences/Absence';
import AbsenceDay from 'components/calendar-common/absences/absence-day/AbsenceDay';
import DateItem from 'components/calendar-common/common/DateItem';
import DndParams from 'components/calendar-common/grid/DndParams';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import GridMixin from 'components/calendar-common/grid/GridMixin';
import {
  GRID_COLUMN_MIN_WIDTH_DAY,
  GRID_LABEL_COLUMN_SMALL_WIDTH,
  GRID_SUMMARY_COLUMN_WIDTH,
} from 'components/calendar-common/grid/GridVariables';
import DayNotesContainer from 'components/calendar-common/notes/day-notes-container/DayNotesContainer';
import SpecialDatesContainer from 'components/calendar-common/notes/special-dates-container/SpecialDatesContainer';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftDay from 'components/calendar-common/shifts/shift-day/ShiftDay';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { shiftsNS } from 'components/calendar-common/shifts/store/Store';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { Component, Mixins } from 'vue-property-decorator';
import { absencesNS } from 'components/calendar/absences/Store';
import HeaderDays from 'components/calendar/grid/header-days/HeaderDays';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';

@Component
class ListDays extends Mixins(ShiftViewActionsMixin, GridMixin) {
  @calendarCommonNS.Getter
  protected dates!: DateItem[];

  @shiftsNS.Getter('shiftsByDates')
  private shifts!: Record<string, Shift[]>;

  @absencesNS.Getter('absencesByDatesUnsorted')
  private absences!: Record<string, Absence[]>;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  protected get weekGrid() {
    return `${GRID_LABEL_COLUMN_SMALL_WIDTH} repeat(${
      this.dates.length}, minmax(${GRID_COLUMN_MIN_WIDTH_DAY}, 1fr)) ${
      this.filters.showSummary
        ? GRID_SUMMARY_COLUMN_WIDTH : ''}`;
  }

  public render() {
    return (<div class={gridStyles.gridTable}
      style={this.gridStyle}>
      <HeaderDays
        shiftsByDates={this.shifts}
        dates={this.dates}
        onClick={(e, { dateItem }) => this.onShiftCellClick(dateItem)}
      >
        <template slot="label">
          {this.getWeekNumber(this.dates[0])}
        </template>
      </HeaderDays>
      <SpecialDatesContainer dates={this.dates}/>
      <DayNotesContainer dates={this.dates}/>
      <GridRow
        cellClass={gridStyles.gridTableContentCellPaddingBottom}
        isLastRow={true}
        dates={this.dates}
        dndParams={ { ...DndParams, isDroppable: true } }
        onCellClick={dateItem => this.onShiftCellClick(dateItem)}
        scopedSlots= {{
          cell: scope => [
            this.absences[scope.dateItem.dateKey]
              .map(absence => <AbsenceDay absence={absence} key={absence.uniqId}/>),
            this.shifts[scope.dateItem.dateKey]
              .map(shift => <ShiftDay
                shift={shift}
                key={shift.id}
                onDrop={(...args) => this.$emit('drop', ...args) }
              />)],
        }}>
        <GridCell slot="label">
        </GridCell>
      </GridRow>
    </div>);
  }
}

export default ListDays as any;
