import DateItem from 'components/calendar-common/common/DateItem';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import CalendarSummaryContainer from 'components/calendar/summary/SummaryContainer';
import Shift from 'components/calendar-common/shifts/Shift';
import GridHeaderMonth from 'components/calendar-common/grid/grid-header-month/GridHeaderMonth';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import gridHeaderMonthStyles from 'components/calendar-common/grid/grid-header-month/grid-header-month.css';

@Component
class HeaderMonth extends Vue {
  @Prop({
    default: () => [],
  })
  public dates!: DateItem[];

  @Prop()
  public shiftsByDates: Record<string, Shift[]>;

  @Prop({
    default: '',
  })
  public cellClass!: string;

  @Prop({
    default: true,
  })
  public isSticky!: boolean;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  public render() {
    return (<GridHeaderMonth dates={this.dates}
      cellClass={this.cellClass}
      isSticky={this.isSticky}
      onClick={(e, scope) => this.$emit('click', e, scope)}>
      <template slot="label">
        {this.$slots.label}
      </template>
      <template slot="total">
        {
          this.filters.showSummary && <GridCell
            isHeader={true}
            class={gridHeaderMonthStyles.gridHeaderMonthTotal}
            style={{ gridColumn: `${this.dates.length + 2} / ${this.dates.length + 3}` }}>
            {this.$t('calendar.summary.total')}
          </GridCell>
        }
      </template>
      {this.filters.showSummary && <CalendarSummaryContainer
        dates={this.dates}
        shiftsByDates={this.shiftsByDates}/>}
    </GridHeaderMonth>);
  }
}

export default HeaderMonth as any;
