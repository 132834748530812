import Icon from 'components/icons/Icon';
import { Component, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';

import styles from './router-link-icon.css';

// TODO: use this a generic Size enum once we migrate to the new design
export enum Size {
  XXSMALL = 'xxsmall',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

const sizeMap: Record<Size, string> = {
  [Size.XXSMALL]: styles.routerLinkIconXxsmall,
  [Size.XSMALL]: styles.routerLinkIconXsmall,
  [Size.SMALL]: styles.routerLinkIconSmall,
  [Size.MEDIUM]: styles.routerLinkIconMedium,
  [Size.LARGE]: styles.routerLinkIconLarge,
  [Size.XLARGE]: styles.routerLinkIconXlarge,
};

interface Props {
  icon: IconName;
  label: string;
  location: RawLocation;
  size: Size;
}

@Component
export default class RouterLinkIcon extends TsxComponent<Props> {
  @Prop()
  public icon: Props['icon'];

  @Prop()
  public label: Props['label'];

  @Prop()
  public location: Props['location'];

  @Prop()
  public size: Props['size'];

  public render() {
    return (
      <router-link class={[styles.routerLinkIcon, sizeMap[this.size]]} to={this.location}>
        <Icon
          aria-label={this.label}
          name={this.icon}
          title={this.label}
        />
      </router-link>
    );
  }
}
