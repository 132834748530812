import { Filters, ModuleState, rotationGroupsTableNS } from 'components/employments/rotation-groups/store/Store';
import { employmentsNS } from 'components/employments/store/Store';
import type { FetchEmploymentFunction } from 'components/employments/store/Store';
import { Employment } from 'components/employments/types';
import InputText from 'components/form/input-text/InputText';
import ScreenError from 'components/screen/ScreenError';
import ScreenNotFound from 'components/screen/ScreenNotFound';
import Spinner from 'components/spinner/Spinner';
import TableAction from 'components/table/store/Action';
import type { SetFilterPayload } from 'components/table/store/Store';
import { Size } from 'components/types';
import { Action as StoreAction } from 'store/normalized-store';
import { getItemsOverlappingInterval, isStartBeforeEnd } from 'utils/date-related';
import { StoreActionState } from 'utils/store';
import type { GetById } from 'utils/store';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import Icon from 'components/icons/Icon';
import Avatar from 'components/avatar/Avatar';
import Alert, { AlertKind } from 'components/alert/Alert';
import { IconName } from 'components/icons/types';
import ActionButtonWrapper from '../action-button-wrapper/ActionButtonWrapper';
import { Route } from '../routes';
import StepWrapper, { Slot as StepWrapperSlot } from '../step-wrapper/StepWrapper';
import Action from '../store/Action';
import { rotationWizardNS, StoreState } from '../store/Store';
import styles from './general-setup.css';

const FORM_ID = 'general-setup-individual';

@Component
export default class GeneralSetupIndividual extends TsxComponent<{
  employmentId: number;
}> {
  public $refs: {
    endsAtRef: Vue;
    rotationIntervalRef: Vue;
    startsAtRef: Vue;
  };

  protected employment: Employment | null = null;

  protected lastStoreActionState = StoreActionState.PENDING;

  @employmentsNS.Action(StoreAction.FETCH)
  protected fetchEmployment: FetchEmploymentFunction;

  @employmentsNS.Getter
  protected getById: GetById<Employment>;

  @rotationGroupsTableNS.Action(TableAction.SET_FILTER)
  private setFilter: (
    payload: SetFilterPayload<Filters, keyof Filters>
  ) => Promise<void>;

  @rotationGroupsTableNS.Action(TableAction.SUBSCRIBE)
  private rotationGroupsSubscribe: () => Promise<void>;

  @rotationGroupsTableNS.Action(TableAction.UNSUBSCRIBE)
  private rotationGroupsUnsubscribe: () => Promise<void>;

  @rotationGroupsTableNS.State(state => state.data)
  private existingRotationGroups: ModuleState['table']['data'];

  @rotationWizardNS.Action(Action.ON_INPUT)
  protected onInput: (payload: { name: string; value: number | string }) => void;

  @rotationWizardNS.Action(Action.GENERATE_ROTATION_GROUPS)
  protected generateRotationGroups: (
    payload: { rotationInterval: number; groupsLength: number },
  ) => void;

  @rotationWizardNS.State
  protected endsAt: StoreState['endsAt'];

  @rotationWizardNS.State
  protected rotationGroups: StoreState['rotationGroups'];

  @rotationWizardNS.State
  protected rotationInterval: StoreState['rotationInterval'];

  @rotationWizardNS.State
  protected startsAt: StoreState['startsAt'];

  @rotationWizardNS.Getter
  protected isGeneralSetupIndividualValid: boolean;

  @Prop()
  public employmentId: number;

  protected get isSubmitEnabled() {
    return this.lastStoreActionState === StoreActionState.SUCCESS
      && this.isGeneralSetupIndividualValid
      && this.overlappingAssignments.length === 0;
  }

  protected get isStartsAtBeforeEndsAt() {
    if (!this.startsAt || !this.endsAt) {
      return true;
    }

    return isStartBeforeEnd(new Date(this.startsAt), new Date(this.endsAt));
  }

  protected get overlappingAssignments() {
    if (!this.isStartsAtBeforeEndsAt) {
      return [];
    }

    return getItemsOverlappingInterval(
      this.existingRotationGroups,
      this.startsAt,
      this.endsAt,
      this.$timeZone.value,
    );
  }

  protected onSubmit(e) {
    this.$router.push({
      name: Route.INDIVIDUAL_STEP_2,
      params: { id: this.employment?.id.toString() || '0' },
    });
    e.preventDefault();
  }

  protected confirmRotationGroupsReset() {
    if (this.rotationGroups.some(group => group.days.some(Boolean))) {
      // eslint-disable-next-line no-alert
      return window.confirm(
        this.$t('rotationWizard.generalSetup.confirmRotationSetupReset'),
      );
    }

    return true;
  }

  @Watch('endsAt')
  @Watch('rotationInterval')
  @Watch('startsAt')
  protected onRotationGroupsChange() {
    this.generateRotationGroups({
      groupsLength: 1,
      rotationInterval: this.rotationInterval,
    });
  }

  protected async populateEmployment() {
    this.lastStoreActionState = StoreActionState.PENDING;

    const response = await this.fetchEmployment({
      employmentId: this.employmentId,
    });

    this.lastStoreActionState = response.state;

    if (this.lastStoreActionState !== StoreActionState.SUCCESS) {
      return;
    }

    this.employment = this.getById(this.employmentId) || null;

    if (this.employment) {
      this.setFilter({
        key: 'employmentIds',
        value: [this.employmentId],
      });

      this.rotationGroupsSubscribe();
    }
  }

  public mounted() {
    this.populateEmployment();
  }

  public beforeDestroy() {
    this.rotationGroupsUnsubscribe();
  }

  public render() {
    if (this.lastStoreActionState === StoreActionState.NOT_FOUND) {
      return <ScreenNotFound />;
    }

    if (this.lastStoreActionState === StoreActionState.ERROR) {
      return <ScreenError />;
    }

    return (
      <StepWrapper heading={this.$t('rotationWizard.generalSetup.heading')}>
        <form class={styles.generalSetupForm} onSubmit={this.onSubmit} id={FORM_ID}>
          <div class={styles.generalSetupStaticInput}>
            <div class={styles.generalSetupStaticInputLabel}>
              {this.$t('rotationWizard.generalSetup.inputEmployee')}
            </div>
            <div class={styles.generalSetupStaticInputText}>
              {
                this.lastStoreActionState === StoreActionState.PENDING
                  ? <Spinner class={styles.generalSetupSpinner} />
                  : ([
                    <Avatar
                      class={styles.generalSetupAvatar}
                      size={Size.SMALL}
                      firstName={this.employment?.firstName ?? ''}
                      lastName={this.employment?.lastName ?? ''}
                      imageUrl={this.employment?.pictureData?.pictureSmall ?? undefined}
                    />,
                    `${this.employment?.firstName} ${this.employment?.lastName}`,
                  ])
              }
            </div>
          </div>

          <InputText
            class={styles.generalSetupFormInput}
            id="startsAt"
            isValid={this.isStartsAtBeforeEndsAt}
            label={this.$t('rotationWizard.generalSetup.inputStartsAt')}
            name="startsAt"
            onInput={(e) => {
              if (this.confirmRotationGroupsReset()) {
                this.onInput(e.target);
                this.onInput({ name: 'anchorDate', value: e.target.value });
              } else {
                // need to force update to retain value
                this.$refs.startsAtRef.$forceUpdate();
              }
            }}
            // HACK: Require specific date format for Safari which doesn't support input type="date"
            // we can only safely parse ISO date in every browser
            pattern="[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}"
            placeholder={this.$t('rotationWizard.generalSetup.inputDatePlaceholder')}
            ref="startsAtRef"
            required={true}
            type="date"
            value={this.startsAt?.substr(0, 10)}
          />

          <InputText
            class={styles.generalSetupFormInput}
            error={
              this.isStartsAtBeforeEndsAt
                ? undefined
                : this.$t('rotationWizard.error.individualShiftRotation.endsAt.greaterThanStartsAt')
            }
            id="endsAt"
            label={this.$t('rotationWizard.generalSetup.inputEndsAt')}
            name="endsAt"
            onInput={(e) => {
              if (this.confirmRotationGroupsReset()) {
                this.onInput(e.target);
              } else {
                // need to force update to retain value
                this.$refs.endsAtRef.$forceUpdate();
              }
            }}
            // HACK: Require specific date format for Safari which doesn't support input type="date"
            // we can only safely parse ISO date in every browser
            pattern="[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}"
            placeholder={this.$t('rotationWizard.generalSetup.inputDatePlaceholder')}
            ref="endsAtRef"
            required={true}
            type="date"
            value={this.endsAt?.substr(0, 10)}
          />

          <InputText
            class={styles.generalSetupFormInput}
            id="rotationInterval"
            isStepperShown={true}
            label={this.$t('rotationWizard.generalSetup.inputRotationInterval')}
            max="9999"
            min="1"
            name="rotationInterval"
            onKeydown={this.confirmRotationGroupsReset}
            onInput={(e) => {
              if (this.confirmRotationGroupsReset()) {
                this.onInput({
                  name: e.target.name,
                  value: parseInt(e.target.value, 10),
                });
              } else {
                // need to force update to retain value
                this.$refs.rotationIntervalRef.$forceUpdate();
              }
            }}
            placeholder="0"
            ref="rotationInterval"
            required={true}
            type="number"
            value={this.rotationInterval?.toString()}
          />

          {
            this.overlappingAssignments.length > 0 && (
              <Alert
                kind={AlertKind.ERROR}
                title={this.$t('rotationWizard.error.individualShiftRotation.conflict.title')}
              >
                {this.$t('rotationWizard.error.individualShiftRotation.conflict.message')}

                <ul class={styles.generalSetupConflictList}>
                  {
                    this.overlappingAssignments
                      .map(assignment => (
                        <li class={styles.generalSetupConflictListItem}>
                          {
                            assignment.__typename === 'EmploymentsRotationGroup'
                              ? assignment.shiftRotationGroup.shiftRotation.name
                              : this.$t('rotationWizard.error.individualShiftRotation.conflict.individual')
                          }
                        </li>
                      ))
                  }
                </ul>
              </Alert>
            )
          }
        </form>

        <ActionButtonWrapper slot={StepWrapperSlot.FOOTER}>
          <Button
            kind={ButtonKind.FILL}
            color={ButtonColor.PRIMARY}
            disabled={!this.isSubmitEnabled}
            size={Size.LARGE}
            form={FORM_ID}
          >
            {this.$t('rotationWizard.generalSetup.buttonRotationSetup')}
            <Icon
              name={IconName.ARROW_NEXT}
              size={Size.SMALL}
              class={styles.generalSetupButtonIcon}
            />
          </Button>
        </ActionButtonWrapper>
      </StepWrapper>
    );
  }
}
