import Absence from 'components/calendar-common/absences/Absence';
import AbsenceMonth from 'components/calendar-common/absences/absence-month/AbsenceMonth';
import DateItem from 'components/calendar-common/common/DateItem';
import Observable from 'components/calendar-common/common/observable/Observable';
import PaginationMixin from 'components/calendar-common/common/pagination-mixin/PaginationMixin';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import GridMixin, { GridType } from 'components/calendar-common/grid/GridMixin';
import DayNotesCompactContainer from
  'components/calendar-common/notes/day-notes-compact-container/DayNotesCompactContainer';
import SpecialDatesCompactContainer from
  'components/calendar-common/notes/special-dates-compact-container/SpecialDatesCompactContainer';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftMonth from 'components/calendar-common/shifts/shift-month/ShiftMonth';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { Component, Mixins } from 'vue-property-decorator';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import ShiftPresetMonth from 'components/calendar-common/shift-presets/shift-preset-month/ShiftPresetMonth';
import { ShiftPreset, shiftPresetsNS } from 'components/calendar-common/shift-presets/Store';
import HeaderMonth from 'components/calendar/grid/header-month/HeaderMonth';
import { shiftsEmployeeViewNS } from 'components/calendar-common/shifts/store/Store';
import Employment from 'components/calendar-common/employments/Employment';
import { absencesNS } from 'components/calendar/absences/Store';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { calendarEmploymentsNS } from 'components/calendar-common/employments/Store';
import Row from '../row/Row';
import OpenShifts from '../OpenShifts';

@Component({
  directives: {
    observable: Observable,
  },
})
class Month extends Mixins(GridMixin, ShiftViewActionsMixin, PaginationMixin) {
  private openEmploymentIds: number[] = [];

  protected gridType = GridType.MONTH;

  @shiftsEmployeeViewNS.Getter('shiftsByEmployments')
  private shifts: Record<number, Record<string, Shift[]>>;

  @shiftsEmployeeViewNS.Getter('shiftsByDates')
  private shiftsByDates: Record<number, Record<string, Shift[]>>;

  @shiftsEmployeeViewNS.Getter('openShifts')
  private openShifts: Record<string, Shift[]>;

  @absencesNS.Getter('absencesByEmployments')
  private absences: Record<number, Record<string, Absence[]>>;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  @calendarCommonNS.Getter('dates')
  protected dates: DateItem[];

  @calendarEmploymentsNS.Getter('filteredEmployments')
  private employments: Employment[];

  @shiftPresetsNS.Getter('getByIdWithFallback')
  private getShiftPresetById: (id: number) => ShiftPreset;

  protected get paginatedItems() {
    return this.employments;
  }

  private get rowScopedSlots() {
    return {
      shift: ({ shift, employment }) => <ShiftMonth
        onDrop={(...args) => this.$emit('drop', ...args)}
        shift={shift}
        employmentId={employment.id}
        key={shift.id}/>,
      absence: ({ absence }) => <AbsenceMonth
        key={absence.uniqId}
        absence={absence}/>,
      rotationShiftPreset: ({ shiftPresetId }) => this
        .filters.showShiftRotation && <ShiftPresetMonth
        shiftPreset={this.getShiftPresetById(shiftPresetId)}/>,
    };
  }

  private onToggleEmployment(employmentId: number) {
    if (this.openEmploymentIds.includes(employmentId)) {
      this.openEmploymentIds = this.openEmploymentIds
        .filter(it => it !== employmentId);
    } else {
      this.openEmploymentIds = [...this.openEmploymentIds, employmentId];
    }
  }

  public render() {
    return (<div
      class={gridStyles.gridTable}
      style={this.gridStyle}>
      <HeaderMonth
        dates={this.dates}
        onClick={(e, { dateItem }) => this.onShiftCellClick(dateItem)}
        shiftsByDates={this.shiftsByDates}
      >
        <span slot="label">{this.$t('calendar.titleEmployment')}</span>
      </HeaderMonth>
      <SpecialDatesCompactContainer dates={this.dates} showLabelText={true}/>
      <DayNotesCompactContainer dates={this.dates} showLabelText={true}/>
      <OpenShifts
        showSummary={this.filters.showSummary}
        dates={this.dates}
        shifts={this.openShifts}
        scopedSlots= {this.rowScopedSlots}
      />
      {
        this.visibleItems.map((employment, index) => {
          const shifts = this.shifts[employment.id];
          const absences = this.absences[employment.id];
          const isLastRow = (this.employments.length - 1) === index;

          return (
            <Row
              showSummary={this.filters.showSummary}
              isVisible={this.visibleIds.includes(employment.id)}
              isOpen={this.openEmploymentIds.includes(employment.id)}
              onToggleEmployment={this.onToggleEmployment}
              data-id={employment.id}
              v-observable={this.observer}
              employment={employment}
              shifts={shifts}
              absences={absences}
              dates={this.dates}
              isLastRow={isLastRow}
              scopedSlots= {this.rowScopedSlots}
            />
          );
        })
      }
      {
        this.renderShowMore()
      }
    </div>);
  }
}

export default Month as any;
