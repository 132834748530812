import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { toLocaleDateString } from 'src/utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './conflicts.css';

@Component
export default class OutsideWorkPeriodShiftConflict extends TsxComponent<{
  conflict: Extract<GQLShiftConflictsFragmentFragment, { __typename: 'OutsideWorkPeriodShiftConflict' }>;
}> {
  @Prop()
  public conflict: Extract<GQLShiftConflictsFragmentFragment, { __typename: 'OutsideWorkPeriodShiftConflict' }>;

  public render() {
    return (
      <section>
        <h3 class={styles.conflictsItemTitle}>
          {this.$t('conflicts.outsideWorkPeriod.title')}
        </h3>
        <p class={styles.conflictsItemText}>
          {
            this.$t(
              'conflicts.outsideWorkPeriod.text',
              {
                exitDate: this.conflict.endsAt
                  ? toLocaleDateString(
                    new Date(this.conflict.endsAt),
                    this.$i18n.i18next.language,
                    this.$timeZone.value,
                  ) : '',
              },
            )
          }
        </p>
      </section>
    );
  }
}
