import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { Slot } from 'components/dialog/Dialog';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import FormDialog from 'components/form/form-dialog/FormDialog';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import Alert, { AlertKind } from 'components/alert/Alert';
import { backgroundJobsNS } from 'store/background-jobs/Store';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type { FetchAllLocationsPositionsFunction, LocationsPosition } from 'store/locations-positions/Store';
import { Action } from 'store/normalized-store';
import { shiftRotationsNS } from 'store/shift-rotations/Store';
import type { FetchAllShiftRotationsFunction, ShiftRotation } from 'store/shift-rotations/Store';
import { shiftplansNS } from 'store/shiftplans/Store';
import type { ApplyRotationFunction } from 'store/shiftplans/Store';
import ShiftplanAction from 'store/shiftplans/Action';
import { Action as WatchStoreAction } from 'store/watch-store';
import { filterFalsy, sortBySortOrder } from 'utils/utils';
import { redirectToParentIf } from 'utils/route';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'utils/store';
import type { GetMultipleById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import SectionPositions from '../dialog-create-shiftplan/section-positions/SectionPositions';
import SectionRotations from '../dialog-create-shiftplan/SectionRotations';
import ShiftScheduleAction from '../store/Action';
import { shiftScheduleBackgroundJobsMapNS } from '../store/Store';
import type { SetJobFunction } from '../store/Store';
import styles from './dialog-apply-rotation.css';

// required to make browser form validation work
const FORM_ID = 'dialog-apply-rotation';

interface FormState {
  rotationId: string;
  positions: Record<number, {
    isDisabled?: boolean;
    workers: number;
  }>;
}

interface Props{
  shiftplanId: number;
}

@Component
export default class DialogApplyRotation extends TsxComponent<Props> {
  protected hasError = false;

  protected isSubmitting = false;

  protected isLoading = false;

  protected formState: FormState = {
    rotationId: '',
    positions: {},
  };

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @backgroundJobsNS.Action(WatchStoreAction.REFETCH)
  private backgroundJobsRefetch: () => Promise<void>;

  @locationsPositionsNS.Action(Action.FETCH_ALL)
  protected fetchLocationsPositionsAll: FetchAllLocationsPositionsFunction;

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;

  @shiftRotationsNS.Action(Action.FETCH_ALL)
  protected fetchRotationsAll: FetchAllShiftRotationsFunction;

  @shiftRotationsNS.Getter('ordered')
  protected shiftRotations: ShiftRotation[];

  @shiftScheduleBackgroundJobsMapNS.Action(ShiftScheduleAction.SET_JOB)
  protected setJobForShiftplan: SetJobFunction;

  @shiftplansNS.Action(ShiftplanAction.APPLY_ROTATION)
  protected applyRotation: ApplyRotationFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public shiftplanId: Props['shiftplanId'];

  protected get isValid() {
    const positions = Object.values(this.formState.positions);

    return !!this.formState.rotationId
      && positions.length > 0
      && positions.every(position => position.workers > 0);
  }

  protected get locationsPositions() {
    return this.getLocationsPositionsByLocationAndPositionId(this.currentLocationId)
      .filter(({ position }) => !!position)
      .sort(sortBySortOrder());
  }

  protected get positions() {
    return this.locationsPositions.map(o => o.position).filter(filterFalsy);
  }

  protected onCloseClick() {
    redirectToParentIf(() => !this.isSubmitting)(this);
  }

  protected async onSubmit(e) {
    e.preventDefault();

    this.isSubmitting = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        id: this.shiftplanId,
        input: {
          shiftRotationId: Number.parseInt(this.formState.rotationId, 10),
          workersPerPositionsList: Object.entries(this.formState.positions)
            .filter(([, item]) => !item.isDisabled)
            .map(([id, item]) => ({
              id: Number.parseInt(id, 10),
              workers: item.workers,
            })),
        },
      },
      this.applyRotation,
      // TODO: Currently unused as the API endpoint does not use the new error format
      'applyRotation.modal.error',
    );

    this.isSubmitting = false;

    if (response.state !== StoreActionState.SUCCESS || !response.entityId) {
      return false;
    }

    const shiftplanId = response.entityId;
    const { jobId } = response.meta;

    this.setJobForShiftplan({
      jobId,
      shiftplanId,
    });

    await this.backgroundJobsRefetch();

    this.showSnackbar({
      message: this.$t('shiftSchedule.snackbarBackgroundProcessing'),
      kind: AlertKind.SUCCESS,
      timeout: 20000,
    });

    return this.onCloseClick();
  }

  public async initialPopulate() {
    this.isLoading = true;

    const responses = await Promise.all([
      executeStoreActionWithFailureSnackbar(
        this,
        {},
        this.fetchRotationsAll,
        'applyRotation.modal.error',
      ),
      executeStoreActionWithFailureSnackbar(
        this,
        {
          locationIds: [this.currentLocationId || Number.NaN],
          positionIds: null,
        },
        this.fetchLocationsPositionsAll,
        'applyRotation.modal.error',
      ),
    ]);

    this.isLoading = false;

    if (responses.some(response => response.state !== StoreActionState.SUCCESS)) {
      this.hasError = true;
    }

    this.formState.positions = this.positions.reduce((prev, cur) => {
      prev[cur.id] = { workers: 1 };
      return prev;
    }, {});
  }

  public mounted() {
    this.initialPopulate();
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        error={this.hasError}
        isLoading={this.isLoading}
        isOpen={true}
        onCloseClick={this.onCloseClick}
        title={this.$t('applyRotation.modal.title')}
      >
        <FormDialog id={FORM_ID} onSubmit={this.onSubmit}>
          {
            this.shiftRotations.length === 0
              ? (
                <Alert
                  class={styles.dialogApplyRotationAlert}
                  kind={AlertKind.ERROR}
                  title={this.$t('applyRotation.modal.errorNoRotation.title')}
                >
                  {this.$t('applyRotation.modal.errorNoRotation.message')}
                </Alert>
              ) : (
                <SectionRotations
                  onChange={({ payload }) => { this.formState.rotationId = payload; }}
                  rotations={this.shiftRotations}
                  rotationId={this.formState.rotationId}
                />
              )
          }

          <SectionPositions
            selection={this.formState.positions}
            positions={this.positions}
            onChange={({ payload }) => { this.formState.positions = payload; }}
          />
        </FormDialog>

        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isSubmitting}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.GHOST}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        {
          !this.hasError && (
            <Button
              disabled={!this.isValid || this.isSubmitting || this.isLoading}
              form={FORM_ID}
              size={Size.SMALL}
              slot={Slot.BUTTONS_RIGHT}
              type="submit"
            >
              {this.$t('applyRotation.modal.buttonApply')}
            </Button>
          )
        }
      </DialogWithSpinnerAndError>
    );
  }
}
