import { getDateKey } from 'components/calendar-common/common/DateItem';
import Employment from 'components/calendar-common/employments/Employment';
import { AbsenceStates } from './Store';

class Absence {
  public id: number;

  public note: string;

  public employment: Employment;

  public reason: { hasLocalization: boolean; name: string };

  public state: AbsenceStates;

  public date: string;

  public isManageable: boolean;

  public uniqId: string;

  public constructor({
    id,
    canManage,
    employment,
    reason,
    state,
    date,
    notes,
  }) {
    this.id = id;
    this.isManageable = canManage;
    this.employment = employment;
    this.reason = reason;
    this.state = state;
    // allways 1 day, so don't care about endsAt
    this.date = getDateKey(date);
    this.note = notes;
    this.uniqId = `${id}-${employment.id}`;
  }
}

export default Absence;
