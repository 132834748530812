import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLAbsenceDurationQuery,
  GQLAbsenceDurationQueryVariables,
  GQLAbsenceInput,
  GQLAbsencesQuery,
  GQLAbsencesQueryVariables,
  GQLAbsenceState,
  GQLApproveAbsenceMutation,
  GQLApproveAbsenceMutationVariables,
  GQLCreateAbsenceMutation,
  GQLCreateAbsenceMutationVariables,
  GQLDeclineAbsenceMutation,
  GQLDeclineAbsenceMutationVariables,
  GQLDeleteAbsenceMutation,
  GQLDeleteAbsenceMutationVariables,
  GQLEmploymentStatus,
  GQLFetchAbsenceQuery,
  GQLFetchAbsenceQueryVariables,
  GQLUpdateAbsenceMutation,
  GQLUpdateAbsenceMutationVariables,
} from 'codegen/gql-types';
import TableAction from 'components/table/store/Action';
import getTableStore, { StoreState as TableStoreState } from 'components/table/store/Store';
import { SortDirection } from 'components/table/types';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { SentryTag } from 'services/logger/SentryTransport';
import RestClient from 'services/rest-client/RestClient';
import { PayloadParameter, StoreActionResult, StoreActionState } from 'utils/store';
import { endOfYear, startOfYear } from 'date-fns';
import AbsencesGql from '../queries/Absences.gql';
import AbsenceDurationGql from '../queries/AbsenceDuration.gql';
import ApproveAbsenceGql from '../queries/ApproveAbsence.gql';
import CreateAbsenceGql from '../queries/CreateAbsence.gql';
import DeclineAbsenceGql from '../queries/DeclineAbsence.gql';
import DeleteAbsenceGql from '../queries/DeleteAbsence.gql';
import FetchAbsenceGql from '../queries/FetchAbsence.gql';
import UpdateAbsenceGql from '../queries/UpdateAbsence.gql';
import Action from './Action';
import Mutation from './Mutation';
import { TimeframeFilterKind } from '../types';
import type { Absence } from '../types';
import getAbsencesSummaryStore, { StoreState as SummaryStoreState } from '../summary/store/Store';

export const absencesNS = namespace('absences');
export const absencesTableNS = namespace('absences/table');

export interface StoreState {
  absencesById: Record<number, Absence>;
}

export interface ModuleState extends StoreState {
  summary: SummaryStoreState;
  table: TableStoreState<Absence, Filters>;
}

export interface Filters {
  absenceReasonIds?: number[];
  employmentIds?: number[];
  employmentStatuses: GQLEmploymentStatus[];
  endsAt: Date;
  startsAt: Date;
  paid?: boolean;
  states?: GQLAbsenceState[];
  withAttachment?: boolean;
  locationIds: number[];
  timeframeKind: TimeframeFilterKind;
}

export type CreateAbsenceFunction = (
  payload: {
    absence: Omit<GQLAbsenceInput, 'companyId' | 'endsAt' | 'startsAt'> & { endsAt: Date; startsAt: Date };
    forceCollision?: boolean;
    forceCollisionAndRemove?: boolean;
  },
) => Promise<StoreActionResult>;

export type DeleteAbsenceFunction = (
  payload: GQLDeleteAbsenceMutationVariables,
) => Promise<StoreActionResult>;

export type FetchAbsenceFunction = (
  payload: Omit<GQLFetchAbsenceQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateAbsenceFunction = (
  payload: {
    id: number;
    absence: Omit<GQLAbsenceInput, 'companyId' | 'endsAt' | 'startsAt'> & { endsAt: Date; startsAt: Date };
    forceCollision?: boolean;
    forceCollisionAndRemove?: boolean;
  },
) => Promise<StoreActionResult>;

const getAbsencesStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  restClient: RestClient,
  logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    absencesById: {},
  },
  mutations: {
    [Mutation.REMOVE_ABSENCE](state, id: number) {
      delete state.absencesById[id];
    },
    [Mutation.SET_ABSENCE](state, absence: Absence) {
      state.absencesById[absence.id] = absence;
    },
  },
  getters: {
    getById: state => (id: number) => state.absencesById[id],
  },
  actions: {
    [Action.APPROVE]: async (
      { rootState, dispatch },
      payload: {
        absenceId: number;
        forceCollision?: boolean;
        forceCollisionAndRemove?: boolean;
      },
    ) => {
      if (!rootState.auth.currentCompanyId) {
        return undefined;
      }

      const result = await graphqlClient.mutate<
      GQLApproveAbsenceMutation, GQLApproveAbsenceMutationVariables>({
        mutation: ApproveAbsenceGql,
        variables: {
          forceCollision: !!payload.forceCollision,
          forceCollisionAndRemove: !!payload.forceCollisionAndRemove,
          absenceId: payload.absenceId,
          companyId: rootState.auth.currentCompanyId,
        },
      });

      if (result.errors?.length) {
        return result.errors[0].extensions?.response;
      }

      await dispatch(`table/${TableAction.REFETCH}`);

      return result.data?.approveAbsence;
    },
    [Action.DECLINE]: async (
      { dispatch, rootState },
      payload: { absenceId: number; refuseMessage: string },
    ) => {
      try {
        if (!rootState.auth.currentCompanyId) {
          return undefined;
        }

        const result = await graphqlClient.mutate<
        GQLDeclineAbsenceMutation, GQLDeclineAbsenceMutationVariables>({
          mutation: DeclineAbsenceGql,
          variables: {
            absenceId: payload.absenceId,
            refuseMessage: payload.refuseMessage || null,
            companyId: rootState.auth.currentCompanyId,
          },
        });

        await dispatch(`table/${TableAction.REFETCH}`);

        if (result.errors?.length) {
          return result.errors[0].extensions?.response;
        }

        return true;
      } catch (ex) {
        logger.instance.error(ex);
      }

      await dispatch(`table/${TableAction.REFETCH}`);

      return false;
    },
    async [Action.GET_ABSENCE_DURATION]({ rootState }, payload: {
      days?: number;
      startsAt: Date;
      employmentId: number;
      endsAt: Date;
      reasonId: number;
    }) {
      if (!rootState.auth.currentCompanyId) {
        return undefined;
      }

      const variables = {
        companyId: rootState.auth.currentCompanyId,
        days: typeof payload.days !== 'number' ? null : payload.days,
        employmentId: payload.employmentId,
        endsAt: payload.endsAt,
        reasonId: payload.reasonId,
        startsAt: payload.startsAt,
      };

      try {
        /* eslint-disable @typescript-eslint/indent */
        const result = await graphqlClient
          .query<GQLAbsenceDurationQuery, GQLAbsenceDurationQueryVariables>({
            query: AbsenceDurationGql,
            variables: deepTransformDates(variables),
          });
        /* eslint-enable @typescript-eslint/indent */

        if (result.errors?.length) {
          return null;
        }

        return {
          days: result.data.absenceDuration?.absenceDays,
          hours: result.data.absenceDuration?.absenceHours,
        };
      } catch {
        return null;
      }
    },
    async [Action.CREATE](
      { commit, rootState },
      payload: PayloadParameter<CreateAbsenceFunction>,
    ): ReturnType<CreateAbsenceFunction> {
      try {
        if (!rootState.auth.currentCompanyId) {
          return { state: StoreActionState.ERROR };
        }

        const variables = {
          absence: {
            ...payload.absence,
            companyId: rootState.auth.currentCompanyId,
          },
          forceCollision: payload.forceCollision,
          forceCollisionAndRemove: payload.forceCollisionAndRemove,
        };

        /* eslint-disable @typescript-eslint/indent */
        const result = await graphqlClient
          .mutate<GQLCreateAbsenceMutation, GQLCreateAbsenceMutationVariables>({
            mutation: CreateAbsenceGql,
            variables: deepTransformDates(variables),
          });
        /* eslint-enable @typescript-eslint/indent */

        if (result.errors?.length) {
          return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
        }

        if (!result.data) {
          logger.instance.error({
            message: 'Unexpected empty result data',
            tags: [[SentryTag.ACTION, Action.CREATE]],
          });

          return { state: StoreActionState.ERROR };
        }

        const { createAbsence } = result.data;

        if (!createAbsence) {
          // unknown error, should never happen (erroneous optional GQL return type)
          logger.instance.error({
            message: 'Unexpected missing createAbsence in result data',
            tags: [[SentryTag.ACTION, Action.CREATE]],
          });

          return { state: StoreActionState.ERROR };
        }

        if ('success' in createAbsence) {
          return {
            state: StoreActionState.CONFLICT,
            canManage: createAbsence.canManage,
            conflicts: createAbsence.conflicts,
          };
        }

        commit(Mutation.SET_ABSENCE, createAbsence);

        return { state: StoreActionState.SUCCESS, entityId: createAbsence.id };
      } catch (e) {
        logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    },
    async [Action.UPLOAD_ATTACHMENT](
      _,
      payload: { absenceId: number; file: File },
    ) {
      return restClient.postFormData(`/absences/${payload.absenceId}/attachment`, {
        file: payload.file,
      });
    },
    async [Action.UPDATE](
      { commit, rootState },
      payload: PayloadParameter<UpdateAbsenceFunction>,
    ): ReturnType<UpdateAbsenceFunction> {
      try {
        if (!rootState.auth.currentCompanyId) {
          return { state: StoreActionState.ERROR };
        }

        const variables = {
          id: payload.id,
          absence: {
            ...payload.absence,
            companyId: rootState.auth.currentCompanyId,
          },
          forceCollision: payload.forceCollision,
          forceCollisionAndRemove: payload.forceCollisionAndRemove,
        };

        /* eslint-disable @typescript-eslint/indent */
        const result = await graphqlClient
          .mutate<GQLUpdateAbsenceMutation, GQLUpdateAbsenceMutationVariables>({
            mutation: UpdateAbsenceGql,
            variables: deepTransformDates(variables),
          });
        /* eslint-enable @typescript-eslint/indent */

        if (result.errors?.length) {
          return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
        }

        if (!result.data) {
          logger.instance.error({
            message: 'Unexpected empty result data',
            tags: [[SentryTag.ACTION, Action.UPDATE]],
          });

          return { state: StoreActionState.ERROR };
        }

        const { updateAbsence } = result.data;

        if (!updateAbsence) {
          // unknown error, should never happen (erroneous optional GQL return type)
          logger.instance.error({
            message: 'Unexpected missing updateAbsence in result data',
            tags: [[SentryTag.ACTION, Action.UPDATE]],
          });

          return { state: StoreActionState.ERROR };
        }

        if ('success' in updateAbsence) {
          return {
            state: StoreActionState.CONFLICT,
            canManage: updateAbsence.canManage,
            conflicts: updateAbsence.conflicts,
          };
        }

        commit(Mutation.SET_ABSENCE, updateAbsence);

        return { state: StoreActionState.SUCCESS, entityId: updateAbsence.id };
      } catch (e) {
        logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    },
    async [Action.DELETE](
      { commit, rootState },
      payload: PayloadParameter<DeleteAbsenceFunction>,
    ): ReturnType<DeleteAbsenceFunction> {
      try {
        if (!rootState.auth.currentCompanyId) {
          return { state: StoreActionState.ERROR };
        }

        const variables: GQLDeleteAbsenceMutationVariables = {
          id: payload.id,
        };

        /* eslint-disable @typescript-eslint/indent */
        const result = await graphqlClient
          .mutate<GQLDeleteAbsenceMutation, GQLDeleteAbsenceMutationVariables>({
            mutation: DeleteAbsenceGql,
            variables,
          });
        /* eslint-enable @typescript-eslint/indent */

        if (result.errors?.length) {
          return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
        }

        if (!result.data) {
          logger.instance.error({
            message: 'Unexpected empty result data',
            tags: [[SentryTag.ACTION, Action.DELETE]],
          });

          return { state: StoreActionState.ERROR };
        }

        const { deleteAbsence } = result.data;

        if (!deleteAbsence) {
          // unknown error, should never happen (erroneous optional GQL return type)
          logger.instance.error({
            message: 'Unexpected missing deleteAbsence in result data',
            tags: [[SentryTag.ACTION, Action.DELETE]],
          });

          return { state: StoreActionState.ERROR };
        }

        commit(Mutation.REMOVE_ABSENCE, payload.id);

        return {
          state: deleteAbsence.success
            ? StoreActionState.SUCCESS
            : StoreActionState.ERROR,
          error: deleteAbsence.error || undefined,
        };
      } catch (e) {
        logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    },
    async [Action.FETCH](
      { commit, rootState },
      payload: PayloadParameter<FetchAbsenceFunction>,
    ): ReturnType<FetchAbsenceFunction> {
      if (!rootState.auth.currentCompanyId) {
        return { state: StoreActionState.ERROR };
      }

      const variables: GQLFetchAbsenceQueryVariables = {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      };

      /* eslint-disable @typescript-eslint/indent */
      const result = await graphqlClient
        .query<GQLFetchAbsenceQuery, GQLFetchAbsenceQueryVariables>({
          query: FetchAbsenceGql,
          variables,
        });
      /* eslint-enable @typescript-eslint/indent */

      if (result.errors?.length) {
        logger.instance.error({
          message: JSON.stringify(result.errors),
          tags: [[SentryTag.ACTION, Action.FETCH]],
        });

        return { state: StoreActionState.ERROR };
      }

      if (
        !Array.isArray(result.data?.absences.items) || !result.data.absences.items.length
      ) {
        return { state: StoreActionState.NOT_FOUND };
      }

      commit(Mutation.SET_ABSENCE, result.data?.absences.items[0]);

      return { state: StoreActionState.SUCCESS };
    },
  },
  modules: {
    table: getTableStore<Absence, Filters, GQLAbsencesQuery, GQLAbsencesQueryVariables, {}>(
      graphqlClient,
      {
        query: AbsencesGql,
        getVariables: rootState => ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          companyId: rootState.auth.currentCompanyId!,
        }),
        transformResponse: (response) => {
          const { absences: { items: data, pagination: { count } } } = response.data;

          return {
            data,
            count,
          };
        },
        initialState: {
          perPage: 10,
          sort: {
            direction: SortDirection.DESC,
            key: 'startsAt',
          },
          filters: {
            timeframeKind: TimeframeFilterKind.CURRENT_YEAR,
            // FIXME: need to find a way to pass the company time zone here
            // eslint-disable-next-line no-restricted-syntax
            startsAt: startOfYear(new Date()),
            // FIXME: need to find a way to pass the company time zone here
            // eslint-disable-next-line no-restricted-syntax
            endsAt: endOfYear(new Date()),
          },
        },
      },
    ),
    summary: getAbsencesSummaryStore(graphqlClient, logger),
  },
});

export default getAbsencesStore;
