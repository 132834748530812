import { GQLShiftplanState } from 'codegen/gql-types';
import Dropdown from 'components/dropdown/Dropdown';
import type { Shiftplan } from 'store/shiftplans/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { DropdownItem, DropdownItemKind } from 'components/dropdown/types';
import { Route } from '../routes';
import styles from './shiftplan-selector.css';

@Component
export default class ShiftplanSelector extends TsxComponent<{
  selected?: Shiftplan;
  shiftplans: Shiftplan[];
}> {
  @Prop()
  public selected?: Shiftplan;

  @Prop()
  public shiftplans: Shiftplan[];

  protected get items(): DropdownItem[] {
    return this.shiftplans.map((shiftplan) => {
      const isUnpublished = shiftplan.state === GQLShiftplanState.UNPUBLISHED;
      const isExpired = shiftplan.state === GQLShiftplanState.PUBLISHED
        && new Date(shiftplan.endsAt).valueOf() < Date.now();
      const isSelected = shiftplan.id === this.selected?.id;

      return {
        type: DropdownItemKind.ROUTER_LINK,
        label: shiftplan.name,
        // for print view navigate to calendarPrintSchedule
        route: this.$route.name === Route.PRINT_SHIFTPLAN
          ? Route.PRINT_SHIFTPLAN
          : Route.SHIFTPLAN,
        params: { shiftplanId: shiftplan.id.toString() },
        class: {
          [styles.shiftplanSelectorShiftplan]: true,
          [styles.shiftplanSelectorShiftplanUnpublished]: isUnpublished,
          [styles.shiftplanSelectorShiftplanExpired]: isExpired,
          [styles.shiftplanSelectorShiftplanSelected]: isSelected,
        },
      };
    });
  }

  public render() {
    return (
      <div class={styles.shiftplanSelector}>
        <Dropdown
          id="shiftplan-selector"
          items={this.items}
        >
          {this.selected
            ? this.selected.name
            : this.$t('shiftSchedule.shiftplanSelector.notSelected')}
        </Dropdown>
      </div>
    );
  }
}
