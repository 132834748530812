const addToObserve = (el, binding) => {
  const observer: IntersectionObserver = binding.value;
  if (observer) {
    const checkElementInRecords = observer.takeRecords()
      .find(it => it.target === el);

    if (checkElementInRecords === undefined) {
      observer.observe(el);
    }
  }
};
const observable = {
  inserted: addToObserve,
  update: addToObserve,
  unbind: (el, binding) => {
    const observer: IntersectionObserver = binding.value;
    if (observer) {
      observer.unobserve(el);
    }
  },
};

export default observable;
