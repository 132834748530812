import Absence from 'components/calendar-common/absences/Absence';
import DateItem from 'components/calendar-common/common/DateItem';
import Employee from 'components/calendar-common/employees/employee/Employee';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { calendarAbsencesFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import Employment from 'components/calendar-common/employments/Employment';
import styles from 'components/calendar-common/employments/employments.css';
import SummaryCell from 'components/calendar-common/summary/SummaryCell';
import { Route } from 'components/shift-schedule/routes';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Mode } from '../../../calendar-common/Enums';
import { calendarAbsencesCommonNS } from '../../common/store/Store';

@Component
class EmploymentsRow extends Vue {
  @Prop()
  public isVisible: boolean;

  @Prop()
  public dates!: DateItem[];

  @Prop()
  public absences!: Record<string, Absence[]>;

  @Prop()
  public employment!: Employment;

  @Prop({ default: false })
  public isLastRow!: boolean;

  @calendarAbsencesCommonNS.Getter
  protected mode: Mode;

  @calendarAbsencesFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  protected get canManageEvents() {
    return this.mode === Mode.STAKEHOLDER;
  }

  public onAbsenceCellClick(dateItem: DateItem, employmentId: number) {
    if (this.canManageEvents && dateItem.isWithinShiftplan) {
      this.$router.push({
        name: Route.ABSENCES_CREATE_ABSENCE,
        params: {
          initialState: {
            employmentId: employmentId.toString(),
            startsAt: zonedTimeToUtc(dateItem.date.toDate(), this.$timeZone.value),
            endsAt: zonedTimeToUtc(dateItem.date.toDate(), this.$timeZone.value),
            // any is needed because typing for vue-router is not correct here
          } as any,
        },
      });
    }
  }

  private get employmentRotationPattern() {
    return this.employment.getShiftPresetIdsForTimeframe(this.dates);
  }

  private onEmploymentAbsenceCellClick(dateItem: DateItem) {
    if (!this.employment.isDeleted()) {
      this.onAbsenceCellClick(dateItem, this.employment.id);
    }
  }

  public render() {
    const absences = { ...this.absences };

    return (
      <GridRow
        isVisible={this.isVisible}
        cellClass={gridStyles.gridTableContentCellPaddingBottom}
        isLastRow={this.isLastRow}
        dates={this.dates}
        onCellClick={dateItem => this.onEmploymentAbsenceCellClick(dateItem)}
        scopedSlots= {{
          cell: ({ dateItem }: GridRowScope) => {
            const shiftPresetId = this.employmentRotationPattern[dateItem.dateKey];

            const shouldShowSetPreset = this.employmentRotationPattern[dateItem.dateKey] !== null;
            return [
              this.filters.showShiftRotation
           && shouldShowSetPreset
           && this.$scopedSlots.rotationShiftPreset
            && this.$scopedSlots.rotationShiftPreset({
              shiftPresetId,
            }),
              absences[dateItem.dateKey]
                .map(absence => this.$scopedSlots.absence
              && this.$scopedSlots.absence({ absence })),
            ].filter(Boolean);
          },
        }}>
        <GridCell slot="label"
          isHeader={true}
          class={styles.employeeCell}>
          <Employee employee={this.employment} isLabel={true} />
        </GridCell>
        {this.filters.showSummary && <SummaryCell slot="total"/>}
      </GridRow>);
  }
}

export default EmploymentsRow as any;
