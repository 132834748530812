import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Attachment, { Slot } from 'components/attachment/Attachment';
import { createEventPayload, EventPayload } from 'src/utils/events';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconPosition } from 'components/form/button/types';
import { IconName } from 'components/icons/types';
import styles from './attachment-upload.css';

const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MiB file size limit
const ALLOWED_FILE_TYPES = ['image/jpeg', 'application/pdf'];

@Component
export default class AttachmentUpload extends TsxComponent<{
  file?: File;
}, {
  onChange: EventPayload<{ value: File }>;
}> {
  private isInvalid = false;

  private previewUrl?: string = undefined;

  @Prop()
  public file?: File;

  @Watch('file', { immediate: true })
  protected onAttachmentChange(file?: File) {
    if (this.previewUrl) {
      URL.revokeObjectURL(this.previewUrl);
      this.previewUrl = undefined;
    }

    if (file && file.type.startsWith('image')) {
      this.previewUrl = URL.createObjectURL(file);
    }
  }

  private onFileChange(e: SyntheticEvent<HTMLInputElement, UIEvent>) {
    this.isInvalid = false;

    const { target } = e;
    const file = target.files?.[0];

    if (!file || file.size > FILE_SIZE_LIMIT || !ALLOWED_FILE_TYPES.includes(file.type)) {
      this.isInvalid = true;
      return;
    }

    this.$emit('change', createEventPayload(e, { value: file }));
  }

  private clearAttachment(e) {
    this.isInvalid = false;
    this.$emit('change', createEventPayload(e, { value: undefined }));
  }

  protected get name() {
    if (this.isInvalid) {
      return (
        <span
          class={styles.attachmentUploadError}
          domPropsInnerHTML={this.$t('attachment.error')}
        />
      );
    }

    return this.file ? this.file.name : this.$t('attachment.details');
  }

  public beforeDestroy() {
    if (this.previewUrl) {
      URL.revokeObjectURL(this.previewUrl);
    }
  }

  public render() {
    return (
      <Attachment
        isPreviewShown={!!this.file}
        name={this.name}
        url={this.previewUrl}
      >
        {
          !this.file && (
            <label
              class={{
                [styles.attachmentUpload]: true,
                [styles.attachmentUploadInvalid]: this.isInvalid,
              }}
              slot={Slot.PREVIEW}
            >
              <Icon
                class={styles.attachmentUploadIcon}
                name={IconName.UPLOAD}
                size={Size.MEDIUM}
              />
              <span class={styles.attachmentUploadText}>
                {this.$t('attachment.text')}
              </span>
              <input
                accept={ALLOWED_FILE_TYPES.join(',')}
                class={styles.attachmentUploadInput}
                onChange={this.onFileChange}
                type="file"
              />
            </label>
          )
        }

        {
          this.file && (
            <Button
              class={styles.attachmentUploadClearButton}
              color={ButtonColor.ERROR}
              icon={IconName.DELETE}
              onClick={this.clearAttachment}
              slot={Slot.PREVIEW}
              kind={ButtonKind.GHOST}
              iconPosition={IconPosition.ALONE}
              title={this.$t('attachment.clear')}
            />
          )
        }
      </Attachment>
    );
  }
}
