import {
  GQLShiftConflictsFragmentFragment,
  GQLTagFragmentFragment,
} from 'codegen/gql-types';
import EmploymentWithAvatar from 'components/employment-with-avatar/EmploymentWithAvatar';
import { Employment } from 'components/employments/types';
import Spinner from 'components/spinner/Spinner';
import { LocationsPosition } from 'store/locations-positions/Store';
import { Shiftplan } from 'store/shiftplans/Store';
import { endOf, startOf, Unit } from 'utils/date-related';
import { EventPayload } from 'utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { authNS, StoreState } from 'components/auth/store/Store';
import { ShiftPreset } from 'src/store/shift-presets/Store';
import { Qualification } from 'src/store/qualifications/Store';
import DialogConfirm from 'components/dialog-confirm/DialogConfirm';
import Alert, { AlertKind } from 'components/alert/Alert';
import SectionGeneral, { CUSTOM_PRESET_ID } from './section-general/SectionGeneral';
import SectionBreaks from './section-breaks/SectionBreaks';
import SectionTags from './section-tags/SectionTags';
import SectionShiftRotations from './section-shift-rotations/SectionShiftRotations';
import SectionSettings from './section-settings/SectionSettings';
import SectionNotes from './section-notes/SectionNotes';
import SectionShiftEvaluationTags from './section-shift-evaluation-tags/SectionShiftEvaluationTags';
import SectionQualifications from './section-qualifications/SectionQualifications';
import { AvailableShiftRotationGroup, FormState } from './types';
import DialogShiftConflicts from '../DialogShiftConflicts';
import styles from './details.css';
import DialogShiftConnected from '../dialog-shift-connected/DialogShiftConnected';

enum CancelConflictsEventKind {
  SHIFT = 'cancelConflictsShift',
  STAFF_SHIFT = 'cancelConflictsStaffShift',
  COPIED_SHIFT = 'cancelConflictsCopiedShift',
}

enum IgnoreConflictsEventKind {
  SHIFT = 'ignoreConflictsShift',
  STAFF_SHIFT = 'ignoreConflictsStaffShift',
  COPIED_SHIFT = 'ignoreConflictsCopiedShift',
}

export interface Props {
  employment?: Employment;
  formId: string;
  formState: FormState;
  isDisabled?: boolean;
  isEmploymentLoading?: boolean;
  isManagerNoteVisible?: boolean;
  isUpdate: boolean;
  isValidated?: boolean;
  isDialogAssignConnectedOpen: boolean;
  isDisconnectConfirmDialogOpen: boolean;
  positionNote?: string;
  shiftplan: Pick<Shiftplan, 'startsAt' | 'endsAt' | 'id' | 'locationId'>;
  selectableLocationsPositions: LocationsPosition[];
  selectableShiftPresets: ShiftPreset[];
  selectableShiftEvaluationTags: GQLTagFragmentFragment[];
  selectableShiftRotationGroups: AvailableShiftRotationGroup[];
  conflictsShift: GQLShiftConflictsFragmentFragment[];
  conflictsStaffShift: GQLShiftConflictsFragmentFragment[];
  conflictsCopiedShift: GQLShiftConflictsFragmentFragment[];
  selectableShiftTags: GQLTagFragmentFragment[];
  selectableQualifications: Qualification[];
}

interface Events {
  onChange: <T extends keyof FormState>(e: { field: T; value: FormState[T] }) => void;
  onSubmit: (ignoreConflicts?: boolean) => void;
  onCancelConflictsShift: (payload: EventPayload<void>) => void;
  onCancelConflictsStaffShift: (payload: EventPayload<void>) => void;
  onCancelUpdateConnected: (payload: EventPayload<void>) => void;
  onCancelShiftDisconnect: (payload: EventPayload<void>) => void;
  onCancelConflictsCopiedShift: (payload: EventPayload<void>) => void;
  onIgnoreConflictsStaffShift: (payload: EventPayload<void>) => void;
  onIgnoreConflictsCopiedShift: (payload: EventPayload<void>) => void;
  onIgnoreConflictsShift: (payload: EventPayload<void>) => void;
  onConfirmShiftConnected: (payload: EventPayload<boolean>) => void;
  onConfirmShiftDisconnect: (payload: EventPayload<boolean>) => void;
  onConnectedShiftChanged: <T extends keyof FormState>(
    e: { field: T; value: FormState[T] }
  ) => void;
}

@Component
export default class Details extends TsxComponent<Props, Events> {
  @Prop()
  public selectableLocationsPositions: Props['selectableLocationsPositions'];

  @Prop()
  public selectableShiftTags: Props['selectableShiftTags'];

  @Prop()
  public selectableQualifications: Props['selectableQualifications'];

  @Prop()
  public selectableShiftEvaluationTags: Props['selectableShiftEvaluationTags'];

  @Prop()
  public selectableShiftRotationGroups: Props['selectableShiftRotationGroups'];

  @Prop()
  public selectableShiftPresets: Props['selectableShiftPresets'];

  @Prop()
  public employment: Props['employment'];

  @Prop()
  public formId: Props['formId'];

  @Prop()
  public formState: Props['formState'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public isUpdate: Props['isUpdate'];

  @Prop()
  public isDialogAssignConnectedOpen: Props['isDialogAssignConnectedOpen'];

  @Prop()
  public isDisconnectConfirmDialogOpen: Props['isDisconnectConfirmDialogOpen'];

  @Prop()
  public isEmploymentLoading: Props['isEmploymentLoading'];

  @Prop()
  public isManagerNoteVisible: Props['isManagerNoteVisible'];

  @Prop()
  public isValidated: Props['isValidated'];

  @Prop()
  public positionNote: Props['positionNote'];

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public conflictsShift: Props['conflictsShift'];

  @Prop()
  public conflictsStaffShift: Props['conflictsStaffShift'];

  @Prop()
  public conflictsCopiedShift: Props['conflictsCopiedShift'];

  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  private get isTagsSectionShown() {
    return this.selectableShiftTags.length > 0;
  }

  private get isQualificationsSectionShown() {
    return this.currentCompany?.canUseQualifications
      && this.selectableQualifications.length > 0;
  }

  private get isShiftRotationsSectionShown() {
    return (
      this.currentCompany?.shiftRotationEnabled
      && this.currentCompany?.canUseShiftPresets
      && !!this.formState.shiftPresetId
      && (this.selectableShiftRotationGroups.length || 0) > 0
    );
  }

  private get isShiftEvaluationTagsSectionShown() {
    return this.selectableShiftEvaluationTags.length > 0;
  }

  private get shiftplanStartsAt() {
    return startOf(new Date(this.shiftplan.startsAt), Unit.DAY, this.$timeZone.value);
  }

  private get shiftplanEndsAt() {
    return endOf(new Date(this.shiftplan.endsAt), Unit.DAY, this.$timeZone.value);
  }

  private get conflicts() {
    if (this.conflictsShift.length) {
      return this.conflictsShift;
    }

    if (this.conflictsStaffShift.length) {
      return this.conflictsStaffShift;
    }

    return this.conflictsCopiedShift;
  }

  private onIgnore(payload: EventPayload<void>) {
    if (this.conflictsShift.length) {
      return this.$emit('ignoreConflictsShift');
    }

    if (this.conflictsStaffShift.length) {
      return this.$emit(IgnoreConflictsEventKind.STAFF_SHIFT, payload);
    }

    return this.$emit(IgnoreConflictsEventKind.COPIED_SHIFT, payload);
  }

  private onCancelConflicts(payload: EventPayload<void>) {
    let eventName: CancelConflictsEventKind;

    if (this.conflictsShift.length) {
      eventName = CancelConflictsEventKind.SHIFT;
    } else if (this.conflictsStaffShift.length) {
      eventName = CancelConflictsEventKind.STAFF_SHIFT;
    } else {
      eventName = CancelConflictsEventKind.COPIED_SHIFT;
    }
    // FAQ: shift conflicts take precedence over staffShift conflicts
    this.$emit(
      eventName,
      payload,
    );
  }

  private onChange<T extends keyof FormState>(field: T, value: FormState[T]) {
    this.$emit('change', { field, value });
  }

  private onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    e.preventDefault();

    this.$emit('submit');
  }

  protected onConnectedShiftsButtonClick(payload: boolean) {
    this.$emit('confirmShiftConnected', { payload });
  }

  public render() {
    return (
      <div>

        <form
          class={styles.details}
          onSubmit={this.onSubmit}
          id={this.formId}
        >
          {
            (this.employment || this.isEmploymentLoading) && (
              <Alert
                class={styles.detailsAlert}
                kind={AlertKind.INFO}
                title={this.$t('shifts.dialog.alertAutomaticAssignment.title')}
              >
                <div class={styles.detailsMessage}>
                  {this.$t('shifts.dialog.alertAutomaticAssignment.message')}
                </div>

                {
                  this.isEmploymentLoading
                    ? <Spinner class={styles.detailsEmploymentSpinner} />
                    : (
                      <EmploymentWithAvatar
                        class={styles.detailsEmployment}
                        employment={this.employment}
                        withStaffNumber={true}
                      />
                    )
                }
              </Alert>
            )
          }

          <SectionGeneral
            selectableLocationsPositions={this.selectableLocationsPositions}
            selectableShiftPresets={this.selectableShiftPresets}
            endsAt={this.formState.endsAt}
            isDisabled={this.isDisabled}
            isValidated={this.isValidated}
            locationsPositionId={this.formState.locationsPositionId}
            onChange={({ payload }) => this.onChange(payload.field, payload.value)}
            shiftplanEndsAt={this.shiftplanEndsAt}
            shiftplanStartsAt={this.shiftplanStartsAt}
            shiftPresetId={this.formState.shiftPresetId}
            startsAt={this.formState.startsAt}
            workersCount={this.formState.workers}
          />

          <SectionBreaks
            additionalShiftBreaks={this.formState.shiftBreaks || []}
            isDisabled={this.isDisabled}
            isUnpaidBreakDisabled={
              !!this.formState.shiftPresetId
            && this.formState.shiftPresetId !== CUSTOM_PRESET_ID
            }
            onChange={({ payload }) => this.onChange(payload.field, payload.value)}
            shiftEndsAt={this.formState.endsAt}
            shiftStartsAt={this.formState.startsAt}
            unpaidBreak={this.formState.unpaidBreak}
          />

          {this.isQualificationsSectionShown && (
            <SectionQualifications
              onChange={({ payload }) => this.onChange(payload.field, payload.value)}
              selectableQualifications={this.selectableQualifications}
              selectedQualifications={this.formState.shiftQualifications}
              workersCount={this.formState.workers}
              isDisabled={this.isDisabled}
            />
          )}

          {this.isTagsSectionShown && (
            <SectionTags
              availableTags={this.selectableShiftTags}
              isDisabled={this.isDisabled}
              selectedTagIds={this.formState.tagIds}
              onChange={
                ({ payload }) => this.onChange('tagIds', payload.map(id => Number.parseInt(id, 10)))
              }
            />
          )}

          {this.isShiftEvaluationTagsSectionShown && (
            <SectionShiftEvaluationTags
              availableTags={this.selectableShiftEvaluationTags}
              isDisabled={this.isDisabled}
              selectedTagIds={this.formState.shiftEvaluationTagIds}
              onChange={
                ({ payload }) => this.onChange(
                  'shiftEvaluationTagIds',
                  payload.map(id => Number.parseInt(id, 10)),
                )
              }
            />
          )}

          {this.isShiftRotationsSectionShown && (
            <SectionShiftRotations
            // check for shiftPresetId already happens in isShiftRotationsShown check
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              shiftPresetId={this.formState.shiftPresetId!}
              isDisabled={this.isDisabled}
              selectableShiftRotationGroups={this.selectableShiftRotationGroups || []}
              selectedShiftRotationGroupIds={this.formState.shiftRotationGroupIds || []}
              onChange={
                ({ payload }) => this.onChange(
                  'shiftRotationGroupIds',
                  payload.map(id => Number.parseInt(id, 10)),
                )
              }
            />
          )}

          <SectionSettings
            autoAcceptSetting={this.formState.autoAccept}
            canEvaluate={this.formState.canEvaluate}
            isDisabled={this.isDisabled}
            isStandBy={this.formState.isStandBy}
            isUpdate={this.isUpdate}
            repeatDates={this.formState.repeatDates}
            shiftplan={this.shiftplan}
            shiftStartsAt={this.formState.startsAt}
            connectedGroupId={this.formState.connectedGroupId}
            isConnectedShift={this.formState.isConnectedShift}
            onConnectedShiftChanged={
              ({ payload }) => this.$emit(
                'connectedShiftChanged',
                {
                  field: payload.field,
                  value: payload.value,
                },
              )
            }
            onChange={({ payload }) => this.onChange(payload.field, payload.value)}
          />

          <SectionNotes
            isDisabled={this.isDisabled}
            onChange={({ payload }) => this.onChange(payload.field, payload.value)}
            positionNote={this.positionNote || undefined}
            publicNote={this.formState.publicNote || undefined}
            managerNote={this.formState.managerNote || undefined}
            isManagerNoteVisible={this.isManagerNoteVisible}
          />
        </form>

        <DialogShiftConflicts
          isOpen={this.conflicts.length > 0}
          conflicts={this.conflicts}
          onCancel={this.onCancelConflicts}
          onIgnore={this.onIgnore}
        />

        <DialogShiftConnected
          isOpen={this.isDialogAssignConnectedOpen}
          onConfirmSingleShift={() => this.onConnectedShiftsButtonClick(false)}
          onConfirmConnectedShifts={() => this.onConnectedShiftsButtonClick(true)}
        />

        <DialogConfirm
          isOpen={this.isDisconnectConfirmDialogOpen}
          onCancel={() => this.$emit('cancelShiftDisconnect')}
          onConfirm={() => this.$emit('confirmShiftDisconnect')}
          title={this.$t('shifts.modalDisconnectShifts.title')}
        >
          {this.$t('shifts.modalDisconnectShifts.message')}
        </DialogConfirm>
      </div>
    );
  }
}
