import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLEmploymentsRotationGroupsQuery,
  GQLEmploymentsRotationGroupsQueryVariables,
  GQLShiftRotationsQueryVariables,
  GQLShiftRotationsQuery,
  GQLCreateEmploymentsRotationGroupMutation,
  GQLCreateEmploymentsRotationGroupMutationVariables,
  GQLUpdateEmploymentsRotationGroupMutation,
  GQLUpdateEmploymentsRotationGroupMutationVariables,
  GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy,
} from 'codegen/gql-types';
import getTableStore, { StoreState as TableStoreState } from 'components/table/store/Store';
import CreateEmploymentsRotationGroupGql from 'components/rotation-wizard/queries/CreateEmploymentsRotationGroup.gql';
import UpdateEmploymentsRotationGroupGql from 'components/rotation-wizard/queries/UpdateEmploymentsRotationGroup.gql';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import EmploymentsRotationGroupsGql from '../queries/EmploymentsRotationGroups.gql';
import Action from './Action';
import ShiftRotationsGql from '../queries/ShiftRotations.gql';
import type { RotationGroupUnion } from '../types';

export const rotationGroupsNS = namespace('rotationGroups');
export const rotationGroupsTableNS = namespace('rotationGroups/table');

// TEMP till endpoint is fixed
export interface Filters {
  employmentIds: number[];
}

export interface ModuleState {
  table: TableStoreState<RotationGroupUnion, Filters>;
}

export interface CreateEmploymentsRotationGroupPayload {
  employmentId: number;
  shiftRotationGroupId: number;
  startsAt: Date | null;
  endsAt: Date | null;
}

export interface UpdateEmploymentsRotationGroupPayload {
  id: number;
  startsAt: Date | null;
  endsAt: Date | null;
  employmentId: number;
  unassignmentStrategy: GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy | undefined;
}

const getRotationGroupsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
): Module<void, RootStoreState> => ({
  namespaced: true,
  modules: {
    /* eslint-disable @typescript-eslint/indent */
    table: getTableStore<
      RotationGroupUnion,
      Filters,
      GQLEmploymentsRotationGroupsQuery,
      GQLEmploymentsRotationGroupsQueryVariables,
      void
    >(
    /* eslint-enable @typescript-eslint/indent */
      graphqlClient,
      {
        query: EmploymentsRotationGroupsGql,
        getVariables: rootState => ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          companyId: rootState.auth.currentCompanyId!,
        }),
        transformResponse: (response) => {
          const { employmentsRotationGroups: { items: employmentsRotationGroups } } = response.data;

          return {
            data: employmentsRotationGroups,
            count: employmentsRotationGroups.length,
          };
        },
      },
    ),
  },
  actions: {
    async [Action.FETCH_SHIFT_ROTATIONS]({ rootState }) {
      if (!rootState.auth.currentCompanyId) {
        return undefined;
      }

      const response = await graphqlClient.query<
      GQLShiftRotationsQuery, GQLShiftRotationsQueryVariables>(
        {
          query: ShiftRotationsGql,
          variables: {
            companyId: rootState.auth.currentCompanyId,
          },
        },
      );

      return response.data;
    },
    async [Action.CREATE_EMPLOYMENT_ROTATION_GROUP](
      { rootState },
      payload: CreateEmploymentsRotationGroupPayload,
    ) {
      if (!rootState.auth.currentCompanyId) {
        return undefined;
      }
      const result = await graphqlClient.mutate<
      GQLCreateEmploymentsRotationGroupMutation,
      GQLCreateEmploymentsRotationGroupMutationVariables>(
        {
          mutation: CreateEmploymentsRotationGroupGql,
          variables: {
            employmentsRotationGroup: {
              companyId: rootState.auth.currentCompanyId,
              employmentId: payload.employmentId,
              shiftRotationGroupId: payload.shiftRotationGroupId,
              startsAt: deepTransformDates(payload.startsAt),
              endsAt: deepTransformDates(payload.endsAt),
            },
          },
        },
      );

      if (result.errors?.length) {
        return result.errors[0].extensions?.response;
      }

      return result.data?.createEmploymentsRotationGroup.id;
    },
    async [Action.UPDATE_EMPLOYMENT_ROTATION_GROUP](
      { rootState },
      payload: UpdateEmploymentsRotationGroupPayload,
    ) {
      if (!rootState.auth.currentCompanyId) {
        return undefined;
      }

      const result = await graphqlClient.mutate<
      GQLUpdateEmploymentsRotationGroupMutation,
      GQLUpdateEmploymentsRotationGroupMutationVariables
      >(
        {
          mutation: UpdateEmploymentsRotationGroupGql,
          variables: {
            id: payload.id,
            employmentsRotationGroup: {
              companyId: rootState.auth.currentCompanyId,
              startsAt: deepTransformDates(payload.startsAt),
              endsAt: deepTransformDates(payload.endsAt),
            },
            unassignmentStrategy: payload.unassignmentStrategy
              ? payload.unassignmentStrategy : null,
          },
        },
      );

      if (result.errors?.length) {
        return result.errors[0].extensions?.response;
      }

      return result.data?.updateEmploymentsRotationGroup.id;
    },
  },
});

export default getRotationGroupsStore;
