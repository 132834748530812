import { Slot } from 'components/dialog/Dialog';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { GQLShiftplanImportInput } from 'codegen/gql-types';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { LocationsPosition, locationsPositionsNS } from 'store/locations-positions/Store';
import type { FetchShiftplanFunction, ImportShiftplanFunction } from 'src/store/shiftplans/Store';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { Shiftplan, shiftplansNS } from 'src/store/shiftplans/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import { redirectToParentIf } from 'src/utils/route';
import FormDialog from 'components/form/form-dialog/FormDialog';
import ShiftplanAction from 'store/shiftplans/Action';
import CopyShiftplan, { TargetShiftplanKind } from 'components/shift-schedule/dialog-create-shiftplan/copy-shiftplan/CopyShiftplan';
import { filterFalsy, sortBySortOrder } from 'src/utils/utils';
import { executeStoreActionWithFailureSnackbar, StoreActionResult, StoreActionState } from 'src/utils/store';
import type { GetMultipleById } from 'src/utils/store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import type { SetJobFunction } from 'components/shift-schedule/store/Store';
import { shiftScheduleBackgroundJobsMapNS } from 'components/shift-schedule/store/Store';
import { Action as WatchStoreAction } from 'store/watch-store';
import { backgroundJobsNS } from 'src/store/background-jobs/Store';
import { Action } from 'store/normalized-store';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import ShiftScheduleAction from '../shift-schedule/store/Action';
import { ButtonColor, ButtonKind } from '../form/base-button/types';
import { Route } from '../shift-schedule/routes';

export type FormState = Omit<GQLShiftplanImportInput, 'importTimeframeStartsAt' | 'importTimeframeEndsAt'> &
{
  sourceTimeframeStartsAt: string | null,
  sourceTimeframeEndsAt: string | null,
};

export interface Props {
  shiftplanId: number
}

const FORM_ID = 'dialog-import-shiftplan';

@Component
export default class DialogShiftplanImportContainer extends TsxComponent<Props, {}> {
  protected isSubmitting = false;

  protected formState: FormState = {
    copyFullShiftplan: true,
    copyEmployees: false,
    withoutConflicts: false,
    startsAt: '',
    endsAt: '',
    positionIds: [],
    sourceShiftplanId: NaN,
    sourceTimeframeStartsAt: '',
    sourceTimeframeEndsAt: '',
    targetShiftplanId: 0,
  };

  @Prop()
  public readonly shiftplanId: Props['shiftplanId'];

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @shiftplansNS.Action(ShiftplanAction.IMPORT_SHIFTPLAN)
  protected importShiftplan: ImportShiftplanFunction;

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;

  @shiftplansNS.Getter('getByLocationId')
  protected getShiftplansByLocationId: GetMultipleById<Shiftplan>;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @shiftScheduleBackgroundJobsMapNS.Action(ShiftScheduleAction.SET_JOB)
  protected setJobForShiftplan: SetJobFunction;

  @backgroundJobsNS.Action(WatchStoreAction.REFETCH)
  private backgroundJobsRefetch: () => Promise<void>;

  @shiftplansNS.Action(Action.FETCH)
  protected fetchShiftplan: FetchShiftplanFunction;

  @Watch('formState.sourceShiftplanId')
  protected setDateOffset() {
    const shiftplan = this.shiftplans.find(s => s.id === this.formState.sourceShiftplanId);
    this.formState.sourceTimeframeStartsAt = shiftplan?.startsAt || '';
    this.formState.sourceTimeframeEndsAt = shiftplan?.endsAt || '';
    this.formState.startsAt = this.targetShiftplan?.startsAt || '';
  }

  @Watch('formState.startsAt')
  protected updateEndsAt() {
    this.formState.endsAt = this.targetShiftplan?.endsAt || '';
  }

  protected get targetShiftplan() {
    return this.shiftplans.find(s => s.id === this.formState.targetShiftplanId);
  }

  protected get positions() {
    return this.locationsPositions.map(o => o.position).filter(filterFalsy);
  }

  protected get shiftplans() {
    return this.getShiftplansByLocationId(this.currentLocationId || Number.NaN);
  }

  protected get locationsPositions() {
    return this.getLocationsPositionsByLocationAndPositionId(this.currentLocationId)
      .filter(({ position }) => !!position)
      .sort(sortBySortOrder());
  }

  protected onCloseClick() {
    redirectToParentIf(() => !this.isSubmitting)(this);
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected get isValid() {
    if (!Number(this.formState.sourceShiftplanId)) {
      return false;
    }
    if (!this.formState.copyFullShiftplan && this.formState.positionIds?.length === 0) {
      return false;
    }
    return true;
  }

  protected async onSubmit(event) {
    event.preventDefault();

    if (!this.isValid) {
      return;
    }

    this.isSubmitting = true;

    const {
      sourceTimeframeEndsAt,
      sourceTimeframeStartsAt,
      ...otherParams
    } = this.formState;

    const response: StoreActionResult = await executeStoreActionWithFailureSnackbar(
      this,
      {
        importShiftplanParams: {
          ...otherParams,
          importTimeframeEndsAt: sourceTimeframeEndsAt,
          importTimeframeStartsAt: sourceTimeframeStartsAt,
        },
      },
      this.importShiftplan,
      '',
    );
    this.isSubmitting = false;

    if (response.state !== StoreActionState.SUCCESS || !response.entityId) {
      return;
    }

    const shiftplanId = response.entityId;
    const { jobId } = response.meta;

    this.setJobForShiftplan({
      jobId,
      shiftplanId,
    });

    await Promise.all([
      this.backgroundJobsRefetch(),
      this.fetchShiftplan({ id: shiftplanId }),
    ]);

    this.showSnackbar({
      message: this.$t('importShiftSchedule.modal.snackSuccess'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });

    this.$router.push({
      name: Route.SHIFTPLAN,
      params: {
        shiftplanId: response.entityId.toString(),
      },
    });
  }

  protected initialPopulate() {
    this.formState.targetShiftplanId = this.shiftplanId;
  }

  public mounted() {
    this.initialPopulate();
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        isOpen={true}
        isLoading={this.isSubmitting}
        onCloseClick={this.onCloseClick}
        isClosingPrevented={this.isSubmitting}
        title={this.$t('importShiftSchedule.modal.title')}
      >
        <FormDialog
          id={FORM_ID}
          onSubmit={this.onSubmit}
        >
          <CopyShiftplan<FormState>
            formId={FORM_ID}
            formState={this.formState}
            positions={this.positions}
            shiftplans={this.shiftplans}
            onSubmit={this.onSubmit}
            onInput={this.onInput}
            targetShiftplanKind={TargetShiftplanKind.EXISTING}
          />
        </FormDialog>

        <Button
          disabled={this.isSubmitting}
          color={ButtonColor.SECONDARY}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
          slot={Slot.BUTTONS_LEFT}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        <Button
          disabled={this.isSubmitting || !this.isValid}
          size={Size.SMALL}
          form={FORM_ID}
          color={ButtonColor.PRIMARY}
          type="submit"
          slot={Slot.BUTTONS_RIGHT}
        >
          {this.$t('importShiftSchedule.modal.buttonImport')}
        </Button>
      </DialogWithSpinnerAndError>
    );
  }
}
