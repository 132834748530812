import { TFunction } from 'i18next';
import type { RouteConfig, Route as RouteType } from 'vue-router';
import absencesRoutes from 'components/absences/routes';
import DashboardRedirect from 'components/dashboard-redirect/DashboardRedirect';
import MainView from 'components/main-view/MainView';
import getRotationWizardRoutes from 'components/rotation-wizard/routes';
import employmentsRoutes from 'components/employments/routes';
import Testing from 'components/Testing';
import shiftScheduleRoutes from 'components/shift-schedule/routes';
import availalabilityAggregationRoutes from 'components/availability-aggregations/routes';
import DialogShiftplanContainer from 'components/dialog-shiftplan/DialogShiftplanContainer';
import EvaluationContainer from 'components/evaluation/EvaluationContainer';
import DialogAssignmentGroupContainer from 'components/dialog-assignment-group/DialogAssignmentGroupContainer';

const fallbackRoute: RouteConfig = {
  path: '*',
  // TODO: enter route name here
  redirect: { name: '' },
};

const publicRoutes: RouteConfig[] = [
  // TODO: Some day, add login route here
];

const privateRoutes: RouteConfig[] = [
  ...getRotationWizardRoutes(),
  {
    name: 'mainView',
    path: '/',
    component: MainView,
    meta: {
      title: (t: TFunction) => t('general.pageTitle'),
    },
    children: [
      ...absencesRoutes,
      ...employmentsRoutes,
      ...shiftScheduleRoutes,
      ...availalabilityAggregationRoutes,
      {
        path: '*',
        component: DashboardRedirect,
      },
    ],
  },
];

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-unused-expressions
  privateRoutes[privateRoutes.length - 1].children?.unshift(
    {
      path: 'testing',
      name: 'testing',
      component: Testing,
      children: [
        {
          component: DialogShiftplanContainer,
          name: 'shiftplans',
          path: 'shiftplans/:id(\\d+)',
          props: (route: RouteType) => ({
            shiftplanId: Number.parseInt(route.params.id, 10),
          }),
        },
        {
          component: DialogAssignmentGroupContainer,
          name: 'assignmentGroups',
          path: 'shiftplan/:shiftplanId(\\d+)/assignment-groups/:assignmentGroupId(\\d+)?',
          props: (route: RouteType) => ({
            shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
            assignmentGroupId: route.params.assignmentGroupId
              && Number.parseInt(route.params.assignmentGroupId, 10),
          }),
        },
      ],
    },
    {
      path: 'evaluation-form',
      component: EvaluationContainer,
      props: () => ({
        staffShiftId: 944627,
      }),
    },
  );
}

export const publicRouteNames = publicRoutes.map(it => it.name);

export default () => [
  ...publicRoutes,
  ...privateRoutes,
  fallbackRoute,
];
