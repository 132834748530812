import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import GraphqlClient from 'services/graphql-client/GraphqlClientFactory';
import RestClient from 'services/rest-client/RestClient';
import RootStoreState from 'src/store/RootStoreState';
import {
  GQLGetCompaniesQuery,
  GQLGetCompaniesQueryVariables,
  GQLGetCurrentEmploymentQuery,
  GQLGetCurrentEmploymentQueryVariables,
  GQLGetMyRightsQuery,
  GQLGetMyRightsQueryVariables,
  GQLEmployment,
  GQLRight,
  GQLCompany,
} from 'codegen/gql-types';
import Action from './Action';
import Mutation from './Mutation';
import CompaniesGql from '../queries/Companies.gql';
import CurrentEmploymentGql from '../queries/CurrentEmployment.gql';
import MyRightsGql from '../queries/MyRights.gql';

export const authNS = namespace('auth');

export interface StoreState {
  token?: string;
  email?: string;
  currentCompany?: GQLCompany;
  currentCompanyId?: number;
  currentEmployment?: GQLGetCurrentEmploymentQuery['myEmployments'][0];
  currentEmploymentId?: number;
  currentLocationId?: number;
  currentUserId?: number;
  language?: string;
  // TODO: GQLRight is technically the wrong type
  // Correct one would be GQLGetMyRightsQuery['myRights'][0], which doesn't work atm
  // as the result array could be null
  rights: GQLRight[];
  employments?: {
    id: number;
    companyId: number;
  }[];
  companies?: {
    id: number;
    name: string;
    canUseShiftPresets?: boolean;
    isTagsAllowed?: boolean;
    autoAcceptShiftRequestEnabled?: boolean;
    shiftRotationEnabled?: boolean;
  }[];
}

export type HasAnyRightFunction = (...names: string[]) => boolean;

export type HasAnyLocationRightFunction = (
  locationId: number | undefined, ...names: string[]
) => boolean;

export type HasAnyCurrentLocationRightFunction = (...names: string[]) => boolean;

export type HasAnyLocationsPositionRightFunction = (
  locationsPositionId: number | undefined,
  ...names: string[]
) => boolean;

export type HasAnyOfLocationsPositionsRightFunction = (
  locationsPositionIds: number[] | undefined,
  ...names: string[]
) => boolean;

const getInitialState = (): StoreState => ({
  // FAQ: Do not include token or email here as this will be called when switching the company.
  // If it is included here, the user will otherwise be logged out.
  currentCompany: undefined,
  currentCompanyId: undefined,
  currentEmployment: undefined,
  currentEmploymentId: undefined,
  currentLocationId: undefined,
  currentUserId: undefined,
  rights: [],
});

const getAuthStore = (
  graphqlClient: GraphqlClient,
  restClient: RestClient,
): Module<StoreState, RootStoreState> => {
  const store: Module<StoreState, RootStoreState> = {
    namespaced: true,
    state: {
      ...getInitialState(),
      email: undefined,
      token: undefined,
    },
    mutations: {
      [Mutation.SET_AUTHENTICATION_TOKEN](state: StoreState, token: string) {
        state.token = token;
      },
      [Mutation.SET_EMAIL](state: StoreState, email: string) {
        state.email = email;
      },
      [Mutation.SET_CURRENT_COMPANY](state: StoreState, company: GQLCompany) {
        state.currentCompany = company;
      },
      [Mutation.SET_CURRENT_COMPANY_ID](state: StoreState, companyId: number) {
        state.currentCompanyId = companyId;
      },
      [Mutation.SET_COMPANIES](state: StoreState, companies: []) {
        state.companies = companies;
      },
      [Mutation.SET_CURRENT_EMPLOYMENT](state: StoreState, employment: GQLEmployment) {
        state.currentEmployment = employment;
      },
      [Mutation.SET_CURRENT_EMPLOYMENT_ID](state: StoreState, employmentId: number) {
        state.currentEmploymentId = employmentId;
      },
      [Mutation.SET_RIGHTS](state: StoreState, rights: []) {
        state.rights = rights;
      },
    },
    actions: {
      async [Action.TRY_LOGIN_FROM_LOCAL_STORAGE]({ dispatch, state }) {
        if (state.email && state.token) {
          dispatch(Action.SET_REQUEST_CREDENTIALS, { email: state.email, token: state.token });
          dispatch(Action.GET_USER_DATA);
          return true;
        }

        return false;
      },
      async [Action.GET_USER_DATA]({ dispatch }) {
        await Promise.all([
          dispatch(Action.GET_COMPANIES),
          dispatch(Action.GET_CURRENT_EMPLOYMENT),
          dispatch(Action.GET_RIGHTS),
        ]);
      },
      async [Action.GET_COMPANIES]({ commit, state }) {
        const { query } = graphqlClient.getClient();

        const result = await query<GQLGetCompaniesQuery, GQLGetCompaniesQueryVariables>({
          query: CompaniesGql,
        });

        const { companies: { items: companies } } = result.data;

        commit(Mutation.SET_COMPANIES, companies);

        const currentCompany = state.currentCompanyId
          ? companies.find(company => company.id === state.currentCompanyId)
          : companies[0];

        if (currentCompany) {
          commit(Mutation.SET_CURRENT_COMPANY, currentCompany);
          commit(Mutation.SET_CURRENT_COMPANY_ID, currentCompany.id);
        }

        return true;
      },
      async [Action.GET_CURRENT_EMPLOYMENT]({ commit, state }) {
        if (!state.currentCompanyId) {
          return false;
        }

        const { query } = graphqlClient.getClient();

        const result = await query<
        GQLGetCurrentEmploymentQuery,
        GQLGetCurrentEmploymentQueryVariables
        >(
          {
            query: CurrentEmploymentGql,
            variables: {
              companyId: state.currentCompanyId,
            },
          },
        );

        if (Array.isArray(result.data?.myEmployments) && result.data.myEmployments.length > 0) {
          const [firstEmployment] = result.data.myEmployments;
          const employment = {
            ...firstEmployment,
            userImage: firstEmployment.pictureData?.pictureSmall,
          };

          commit(Mutation.SET_CURRENT_EMPLOYMENT, employment);
          commit(Mutation.SET_CURRENT_EMPLOYMENT_ID, employment.id);
        }

        return true;
      },
      async [Action.GET_RIGHTS]({ commit, state }) {
        if (!state.currentCompanyId) {
          return false;
        }

        const { query } = graphqlClient.getClient();

        const result = await query<GQLGetMyRightsQuery, GQLGetMyRightsQueryVariables>({
          query: MyRightsGql,
          variables: {
            companyId: state.currentCompanyId,
          },
        });

        commit(Mutation.SET_RIGHTS, result.data.myRights);

        return true;
      },
      [Action.SET_REQUEST_CREDENTIALS](
        { commit },
        { email, token }: { email?: string; token?: string },
      ) {
        commit(Mutation.SET_AUTHENTICATION_TOKEN, token);
        commit(Mutation.SET_EMAIL, email);

        graphqlClient.setEmail(email);
        graphqlClient.setToken(token);

        restClient.setEmail(email);
        restClient.setToken(token);
      },
    },
    getters: {
      hasAnyRight: ({ rights }: StoreState) => (...names: string[]) => (
        names.some(right => rights.findIndex(o => o.name === right) !== -1)
      ),
      hasEveryRight: ({ rights }: StoreState) => (...names: string[]) => (
        names.every(right => rights.findIndex(o => o.name === right) !== -1)
      ),
      hasAnyCurrentLocationRight: (
        { currentLocationId },
        { hasAnyLocationRight },
      ) => (...names: string[]) => hasAnyLocationRight(currentLocationId, ...names),
      hasAnyLocationRight: ({ rights }) => (locationId: number | undefined, ...names: string[]) => {
        if (!locationId) {
          return false;
        }

        return names.some(right => (
          rights.findIndex(o => o.name === `location_${locationId}_${right}`) !== -1
        ));
      },
      hasAnyLocationsPositionRight: ({ rights }) => (
        locationsPositionId: number | undefined,
        ...names: string[]
      ) => {
        if (!locationsPositionId) {
          return false;
        }

        const locationsPositionRights = rights.filter(right => right.contextType === 'LocationsPosition' && right.contextId && locationsPositionId === right.contextId);
        if (!locationsPositionRights) {
          return false;
        }

        return names.some(right => (
          locationsPositionRights.findIndex(o => o.name === `locationsposition_${locationsPositionId}_${right}`) !== -1
        ));
      },
      hasAnyOfLocationsPositionsRight: ({ rights }) => (
        locationsPositionIds: number[] | undefined,
        ...names: string[]
      ) => {
        if (!locationsPositionIds || !locationsPositionIds.length) {
          return false;
        }

        // only fetch rights belonging to LocationsPosition
        // and only for passed locationsPositionIds
        const locationsPositionRights = rights.filter(right => right.contextType === 'LocationsPosition' && right.contextId && locationsPositionIds.includes(right.contextId));

        if (!locationsPositionRights.length) {
          return false;
        }
        return names.some(right => (
          locationsPositionRights.findIndex(o => o.name?.match(right)) !== -1
        ));
      },
      isAuthorized(state) {
        return state.token !== undefined;
      },
      isSuperAdmin: ({ rights }: StoreState) => (
        !!rights.find(right => right.name === 'super_admin')
      ),
      isStakeholder: ({ currentEmployment }: StoreState) => (
        !!currentEmployment?.isStakeholder
      ),
      viewableLocationPositions: ({ rights }) => {
        const locationsPositionViewRightRegex = /locationsposition_(\d*)_shift_show_right/g;

        return rights
          .filter(
            ({ name, contextType }) => (
              name
              && contextType === 'LocationsPosition'
              && name.match(locationsPositionViewRightRegex)
            ),
          )
          .map(({ contextId }) => contextId);
      },
      manageableLocationPositions: ({ rights }) => {
        const locationsPositionManageRightRegex = /locationsposition_(\d*)_shift_manage_right/g;

        return rights
          .filter(
            ({ name, contextType }) => (
              name
              && contextType === 'LocationsPosition'
              && name.match(locationsPositionManageRightRegex)
            ),
          )
          .map(({ contextId }) => contextId);
      },
    },
  };

  return store;
};

export default getAuthStore;
