import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';
import Avatar from 'components/avatar/Avatar';
import { Size } from 'components/types';
import { AvailabilityAggregation } from '../../types';
import styles from './cell-employment.css';

export interface Props {
  employment: AvailabilityAggregation['employment'];
}

@Component
export default class CellEmployment extends TsxComponent<Props> {
  @Prop()
  public employment: Props['employment'];

  public render() {
    return (
      <td
        class={[tableStyles.tableBodyCell, styles.cellEmployment]}
        title={`${this.employment.firstName} ${this.employment.lastName}`}
      >
        <Avatar
          class={styles.cellEmploymentAvatar}
          size={Size.MEDIUM}
          imageUrl={this.employment.pictureData?.pictureSmall}
          firstName={this.employment.firstName}
          lastName={this.employment.lastName}
        />

        <span class={styles.cellEmploymentName}>
          {`${this.employment.firstName} ${this.employment.lastName}`}
        </span>

        <span class={styles.cellEmploymentStaffNumber}>
          {this.employment.staffNumber}
        </span>
      </td>
    );
  }
}
