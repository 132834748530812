import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLShiftPaygradesQuery,
  GQLShiftPaygradesQueryVariables,
  GQLShiftPaygradeFragmentFragment,
  GQLCreateShiftPaygradeMutation,
  GQLCreateShiftPaygradeMutationVariables,
  GQLUpdateShiftPaygradeMutation,
  GQLUpdateShiftPaygradeMutationVariables,
  GQLDeleteShiftPaygradeMutation,
  GQLDeleteShiftPaygradeMutationVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateShiftPaygradeGql from './queries/CreateShiftPaygrade.gql';
import DeleteShiftPaygradeGql from './queries/DeleteShiftPaygrade.gql';
import UpdateShiftPaygradeGql from './queries/UpdateShiftPaygrade.gql';
import ShiftPaygradesGql from './queries/ShiftPaygrades.gql';

export const shiftPaygradesNS = namespace('shiftPaygrades');

export type StoreState = ById<ShiftPaygrade>;

export type ShiftPaygrade = GQLShiftPaygradeFragmentFragment;

export type CreateShiftPaygradeFunction = (
  payload: Omit<GQLCreateShiftPaygradeMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteShiftPaygradeFunction = (
  payload: Omit<GQLDeleteShiftPaygradeMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchAllShiftPaygradesFunction = (
  payload: Omit<GQLShiftPaygradesQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateShiftPaygradeFunction = (
  payload: Omit<GQLUpdateShiftPaygradeMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getShiftPaygradesStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
    getters: {
      getByShiftId: (state: StoreState) => (shiftId: number) => (
        Object.values<ShiftPaygrade>(state.byId).filter(item => item.shiftId === shiftId)
      ),
    },
  });

  const create: ActionProvider<
  GQLCreateShiftPaygradeMutation,
  GQLCreateShiftPaygradeMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateShiftPaygradeFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: CreateShiftPaygradeGql,
      resultKey: 'createShiftPaygrade',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    });
  };

  const remove: ActionProvider<
  GQLDeleteShiftPaygradeMutation,
  GQLDeleteShiftPaygradeMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteShiftPaygradeFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: DeleteShiftPaygradeGql,
      resultKey: 'removeShiftPaygrade',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    });
  };

  const update: ActionProvider<
  GQLUpdateShiftPaygradeMutation,
  GQLUpdateShiftPaygradeMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateShiftPaygradeFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: UpdateShiftPaygradeGql,
      resultKey: 'updateShiftPaygrade',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    });
  };

  const fetchAll: ActionProvider<GQLShiftPaygradesQuery, GQLShiftPaygradesQueryVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllShiftPaygradesFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: ShiftPaygradesGql,
      resultKey: 'shiftPaygrades',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  return createNormalizedStore<ShiftPaygrade, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.FETCH_ALL]: fetchAll,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getShiftPaygradesStore;
