import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Icon from 'components/icons/Icon';
import { hexToRgba } from 'src/utils/color';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import styles from './badge.css';

export enum BadgePreset {
  ERROR = 'error',
  INFO = 'info',
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
  PRIMARY = 'primary',
}

export interface BadgeProps {
  icon?: IconName;
  size?: Size.XSMALL | Size.SMALL | Size.MEDIUM;
  preset?: BadgePreset;
  hasDot?: boolean;
  isClosable?: boolean;
  customColor?: string;
}

export interface BadgeEvents {
  onCloseClick: (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
}

@Component
export default class Badge extends TsxComponent<BadgeProps, BadgeEvents> {
  @Prop()
  public icon: BadgeProps['icon'];

  @Prop({ default: Size.MEDIUM })
  public size: NonNullable<BadgeProps['size']>;

  @Prop({ default: BadgePreset.NEUTRAL })
  public preset: NonNullable<BadgeProps['preset']>;

  @Prop({ default: false })
  public hasDot: NonNullable<BadgeProps['hasDot']>;

  @Prop({ default: false })
  public isClosable: NonNullable<BadgeProps['isClosable']>;

  @Prop()
  public customColor: BadgeProps['customColor'];

  public render() {
    return (
      <div
        style={
          this.customColor
            ? {
              '--text-color': this.customColor,
              '--badge-color': hexToRgba(this.customColor, 0.2),
              '--close-background': hexToRgba(this.customColor, 0.4),
              '--close-active-color': 'white',
            }
            : null
        }
        class={{
          [styles.badge]: true,
          [styles.badgePresetError]: this.preset === BadgePreset.ERROR,
          [styles.badgePresetInfo]: this.preset === BadgePreset.INFO,
          [styles.badgePresetPrimary]: this.preset === BadgePreset.PRIMARY,
          [styles.badgePresetSuccess]: this.preset === BadgePreset.SUCCESS,
          [styles.badgePresetWarning]: this.preset === BadgePreset.WARNING,
          [styles.badgeSizeExtraSmall]: this.size === Size.XSMALL,
          [styles.badgeSizeSmall]: this.size === Size.SMALL,
          [styles.badgeSizeMedium]: this.size === Size.MEDIUM,
        }}
      >
        {this.hasDot ? <span class={styles.badgeDot}></span> : null}
        {this.icon ? (
          <Icon name={this.icon} class={styles.badgeIcon} size={this.size} />
        ) : null}
        <span class={styles.badgeLabel}>{this.$slots.default}</span>
        {this.isClosable ? (
          // TODO: use a replace it with a proper IconButton as soon we have one
          <button
            aria-label="close"
            type="button"
            class={styles.badgeClose}
            onClick={(event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
              this.$emit('closeClick', event);
            }}
          >
            <Icon name={IconName.CLEAR} size={this.size} />
          </button>
        ) : null}
      </div>
    );
  }
}
