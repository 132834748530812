import { GQLRequestType } from 'codegen/gql-types';
import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { employmentsNS } from 'components/employments/store/Store';
import type { FetchAllEmploymentsFunction } from 'components/employments/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { SentryTag } from 'services/logger/SentryTransport';
import { Action } from 'store/normalized-store';
import ShiftAction from 'store/shifts/Action';
import { shiftsNS } from 'store/shifts/Store';
import type {
  AcceptReplaceOfferFunction,
  ConfirmReplaceRequestFunction,
  Shift,
  WithdrawAcceptedReplaceRequestFunction,
} from 'store/shifts/Store';
import { EventPayload } from 'utils/events';
import { StoreActionState } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import CancelOffer from './cancel-offer/CancelOffer';
import CreateOffer from './create-offer/CreateOffer';
import OffererList from './offerer-list/OffererList';
import DialogShiftConflicts from '../DialogShiftConflicts';
import RequesterList from './requester-list/RequesterList';

import styles from './replace-requests.css';

interface ConflictsWithRetryInformation {
  conflicts: GQLShiftConflictsFragmentFragment[];
  employmentId: number;
  isConfirm: boolean;
  requestId: number;
}

@Component
export default class ReplaceRequests extends TsxComponent<{
  shift?: Shift;
}, {
  onRefetchShift: () => void;
}> {
  protected isSubmitting = false;

  protected conflictsWithRetryInfo: ConflictsWithRetryInformation | null = null;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.Getter
  protected isSuperAdmin: boolean;

  @shiftsNS.Action(ShiftAction.ACCEPT_REPLACE_OFFER)
  protected acceptReplaceOffer: AcceptReplaceOfferFunction;

  @shiftsNS.Action(ShiftAction.CONFIRM_REPLACE_REQUEST)
  protected confirmReplaceRequest: ConfirmReplaceRequestFunction;

  @shiftsNS.Action(ShiftAction.WITHDRAW_ACCEPTED_REPLACE_REQUEST)
  protected withdrawAcceptedReplaceRequest: WithdrawAcceptedReplaceRequestFunction;

  @employmentsNS.Action(Action.FETCH_ALL)
  protected fetchAll: FetchAllEmploymentsFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public shift?: Shift;

  protected get requests() {
    return this.shift?.requests
      .filter(request => request.type === GQLRequestType.SWAPREQUEST) || [];
  }

  protected async onCancelRequest(
    { payload }: EventPayload<{ employmentId: number; requestId: number }>,
  ) {
    this.isSubmitting = true;

    const response = await this.withdrawAcceptedReplaceRequest({
      acceptedBy: payload.employmentId,
      id: payload.requestId,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, ReplaceRequests.name]],
        message: JSON.stringify(response),
      });

      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return false;
    }

    this.$emit('refetchShift');

    this.isSubmitting = false;

    return this.showSnackbar({
      message: this.$t('shifts.replaceRequests.requestCanceled'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected async onAcceptOfferOrConfirmRequestIgnore(e: EventPayload<void>) {
    if (!this.conflictsWithRetryInfo) {
      return undefined;
    }

    return this.onAcceptOfferOrConfirmRequest(
      {
        event: e.event,
        payload: {
          employmentId: this.conflictsWithRetryInfo.employmentId,
          requestId: this.conflictsWithRetryInfo.requestId,
        },
      },
      this.conflictsWithRetryInfo.isConfirm,
      true,
    );
  }

  protected async onAcceptOfferOrConfirmRequest(
    { payload }: EventPayload<{ employmentId: number; requestId: number }>,
    isConfirm: boolean,
    ignoreConflicts?: boolean,
  ) {
    this.isSubmitting = true;

    const response = isConfirm
      ? await this.confirmReplaceRequest({
        employmentId: payload.employmentId,
        id: payload.requestId,
        ignoreConflicts: !!ignoreConflicts,
      })
      : await this.acceptReplaceOffer({
        ignoreConflicts: !!ignoreConflicts,
        requestId: payload.requestId,
        shiftId: 0, // FIXME: unnecessary parameter in GQL server
      });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, ReplaceRequests.name]],
        message: JSON.stringify(response),
      });

      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return undefined;
    }

    if (response.state === StoreActionState.CONFLICT) {
      this.isSubmitting = false;
      this.conflictsWithRetryInfo = {
        isConfirm,
        conflicts: response.conflicts,
        employmentId: payload.employmentId,
        requestId: payload.requestId,
      };

      return undefined;
    }

    this.$emit('refetchShift');

    this.conflictsWithRetryInfo = null;
    this.isSubmitting = false;

    return this.showSnackbar({
      message: this.$t('shifts.replaceRequests.requestConfirmed'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected get isCurrentlyAssigned() {
    if (!this.shift || !Array.isArray(this.shift.staffShifts)) {
      return false;
    }

    return this.shift.staffShifts.some(staffShift => (
      staffShift.employment && staffShift.employment.id === this.currentEmployment?.id
    ));
  }

  protected get ownPendingOffer() {
    const { currentEmployment } = this;

    if (!this.shift || !currentEmployment) {
      return undefined;
    }

    return this.shift.requests.find(request => (
      request.employment && request.employment.id === currentEmployment.id
    ));
  }

  protected get isOffererListShown() {
    return !this.currentEmployment?.isStakeholder
      && !!this.currentEmployment?.isEmployee
      && !this.isCurrentlyAssigned
      && !!this.requests.length;
  }

  public mounted() {
    this.fetchAll();
  }

  public render() {
    // It may happen that employment is loaded after the shift itself. Show nothing in this case.
    // Will be resolved once we pull the login into sppt_web
    if (!this.currentEmployment) {
      return null;
    }

    if (this.isOffererListShown) {
      return (
        <OffererList
          isSubmitting={this.isSubmitting}
          onAcceptOffer={e => this.onAcceptOfferOrConfirmRequest(e, false)}
          onCancelRequest={this.onCancelRequest}
          requests={this.requests}
        />
      );
    }

    if (this.currentCompany?.shiftSwapEnabled && this.isCurrentlyAssigned) {
      return this.ownPendingOffer
        ? (
          <CancelOffer
            offer={this.ownPendingOffer}
            onRefetchShift={() => this.$emit('refetchShift')}
            shift={this.shift}
          />
        ) : (
          <CreateOffer
            onRefetchShift={() => this.$emit('refetchShift')}
            shift={this.shift}
          />
        );
    }

    return (
      <div class={styles.replaceRequests}>
        <DialogShiftConflicts
          isOpen={!!this.conflictsWithRetryInfo?.conflicts.length}
          isSelf={this.currentEmployment.id === this.conflictsWithRetryInfo?.employmentId}
          conflicts={this.conflictsWithRetryInfo?.conflicts || []}
          onCancel={() => { this.conflictsWithRetryInfo = null; }}
          onIgnore={this.onAcceptOfferOrConfirmRequestIgnore}
        />

        {
          !this.shift || !this.requests.length
            ? (
              <p class={styles.replaceRequestsNone}>
                {this.$t('shifts.replaceRequests.none')}
              </p>
            ) : (
              <RequesterList
                isSubmitting={this.isSubmitting}
                requests={this.requests}
                shift={this.shift}
                onCancelRequest={this.onCancelRequest}
                onConfirmRequest={e => this.onAcceptOfferOrConfirmRequest(e, true)}
                onRefetchShift={() => this.$emit('refetchShift')}
              />
            )
        }
      </div>
    );
  }
}
