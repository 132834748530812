import type { SelectedTimeframe } from 'components/datepicker/types';
import { getCurrentDateInTimeZone } from 'src/utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import DatepickerInterval from 'components/datepicker/DatepickerInterval';
import { TimeframeFilterKind } from '../types';
import styles from './filter-popup-timeframe.css';

@Component
export default class FilterPopupTimeframe extends TsxComponent<{
  timeframe: SelectedTimeframe;
  timeframeKind: TimeframeFilterKind;
}, {
  onFilterChange: SelectedTimeframe
  & { timeframeKind: TimeframeFilterKind.CUSTOM }
  | {
    timeframeKind: TimeframeFilterKind.CURRENT_YEAR
    | TimeframeFilterKind.NEXT_YEAR
    | TimeframeFilterKind.PREVIOUS_YEAR;
  };
}> {
  @Prop()
  public timeframe: SelectedTimeframe;

  @Prop()
  public timeframeKind: TimeframeFilterKind;

  public onFilterChange({ payload }) {
    this.$emit('filterChange', {
      timeframeKind: this.timeframeKind,
      ...payload,
    });
  }

  private get currentYear() {
    return getCurrentDateInTimeZone(this.$timeZone.value).getFullYear();
  }

  private get previousYear() {
    return this.currentYear - 1;
  }

  private get nextYear() {
    return this.currentYear + 1;
  }

  private onTimeframeKindChange(
    e: SyntheticEvent<HTMLInputElement, InputEvent>,
    value: TimeframeFilterKind,
  ) {
    this.onFilterChange({
      payload: {
        timeframeKind: value,
        ...this.timeframe,
      },
    });
  }

  public render() {
    return (
      <div class={styles.filterPopupTimeframe}>
        <label class={styles.filterPopupTimeframeRadioWrapper}>
          <input type="radio" value={TimeframeFilterKind.PREVIOUS_YEAR}
            name="absences-filter-popup-timeframe"
            class={styles.filterPopupTimeframeRadioInput}
            checked={this.timeframeKind === TimeframeFilterKind.PREVIOUS_YEAR}
            onChange={e => this.onTimeframeKindChange(e, TimeframeFilterKind.PREVIOUS_YEAR)}/>
          {this.previousYear}
        </label>
        <label class={styles.filterPopupTimeframeRadioWrapper}>
          <input type="radio"
            name="absences-filter-popup-timeframe"
            value={TimeframeFilterKind.CURRENT_YEAR}
            checked={this.timeframeKind === TimeframeFilterKind.CURRENT_YEAR}
            class={styles.filterPopupTimeframeRadioInput}
            onChange={e => this.onTimeframeKindChange(e, TimeframeFilterKind.CURRENT_YEAR)}/>
          {this.currentYear}
        </label>
        <label class={styles.filterPopupTimeframeRadioWrapper}>
          <input type="radio"
            name="absences-filter-popup-timeframe"
            value={TimeframeFilterKind.NEXT_YEAR}
            checked={this.timeframeKind === TimeframeFilterKind.NEXT_YEAR}
            class={styles.filterPopupTimeframeRadioInput}
            onChange={e => this.onTimeframeKindChange(e, TimeframeFilterKind.NEXT_YEAR)}/>
          {this.nextYear}
        </label>
        <label class={styles.filterPopupTimeframeRadioWrapper}>
          <input type="radio"
            name="absences-filter-popup-timeframe"
            value={TimeframeFilterKind.CUSTOM}
            checked={this.timeframeKind === TimeframeFilterKind.CUSTOM}
            class={styles.filterPopupTimeframeRadioInput}
            onChange={e => this.onTimeframeKindChange(e, TimeframeFilterKind.CUSTOM)}/>
          {this.$t('absence.timeframe.custom')}
        </label>
        {
          this.timeframeKind === TimeframeFilterKind.CUSTOM
          && <DatepickerInterval
            selection={this.timeframe}
            onChange={this.onFilterChange}
            timeZone={this.$timeZone.value}
          />
        }
      </div>
    );
  }
}
