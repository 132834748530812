import DateItem, { DateKey } from 'components/calendar-common/common/DateItem';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import type { GQLCalendarAggregation } from 'codegen/gql-types';
import Summary from 'components/calendar-common/summary/Summary';
import { calendarAbsencesAggregationsNS } from './Store';

@Component
class SummaryContainer extends Vue {
  @Prop()
  public dates: DateItem[];

  @calendarAbsencesAggregationsNS.Getter
  public aggregationsByDates: Record<DateKey, GQLCalendarAggregation>;

  public render() {
    return (<Summary
      dates={this.dates}
      aggregationsByDates={this.aggregationsByDates}/>);
  }
}

export default SummaryContainer as any;
