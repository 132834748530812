import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLPaymentsCalculationQueryVariables,
  GQLPaymentsCalculationQuery,
  GQLPaymentsCalculationFragmentFragment,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  handleUnexpectedResult,
  isSuccessResult,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult, StoreActionState } from 'utils/store';
import PaymentsCalculationGql from './queries/PaymentsCalculation.gql';
import Action from './Action';

export const paymentsCalculationNS = namespace('paymentsCalculation');

export type StoreState = void;

export type PaymentsCalculation = GQLPaymentsCalculationFragmentFragment;

export type PaymentsCalculationActionResult = StoreActionResult & {
  result?: PaymentsCalculation;
}

export type FetchPaymentsCalculationFunction = (
  payload: Omit<GQLPaymentsCalculationQueryVariables, 'companyId'>,
) => Promise<PaymentsCalculationActionResult>;

const getPaymentsCalculationStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    actions: {
      [Action.FETCH_PAYMENTS_CALCULATION]: async (
        { rootState },
        payload: PayloadParameter<FetchPaymentsCalculationFunction>,
      ): ReturnType<FetchPaymentsCalculationFunction> => {
        const resultKey = 'paymentsCalculation';

        try {
          if (!rootState.auth.currentCompanyId) {
            throw new TypeError('currentCompanyId not provided');
          }

          const result = await graphqlClient.query<
          GQLPaymentsCalculationQuery,
          GQLPaymentsCalculationQueryVariables
          >({
            query: PaymentsCalculationGql,
            variables: {
              companyId: rootState.auth.currentCompanyId,
              ...payload,
            },
          });

          if (!isSuccessResult(result, resultKey)) {
            return handleUnexpectedResult(Action.FETCH_PAYMENTS_CALCULATION as any, logger);
          }

          return { state: StoreActionState.SUCCESS, result: result.data.paymentsCalculation };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
    },
  };

  return store;
};

export default getPaymentsCalculationStore;
