import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { getHourMinuteDurationString, MSECS_IN_MINUTE } from 'utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class MinimumRest extends TsxComponent<{
  conflict: GQLShiftConflictsFragmentFragment;
}> {
  @Prop()
  public conflict: GQLShiftConflictsFragmentFragment;

  public render() {
    if (this.conflict.__typename !== 'MinimumRestShiftConflict') {
      return null;
    }

    return (
      <span>
        {
          getHourMinuteDurationString(this.conflict.minimumRestMinutes * MSECS_IN_MINUTE)
        }&thinsp;h
      </span>
    );
  }
}
