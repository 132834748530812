import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import {
  authNS,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import ScreenLoading from 'components/screen/ScreenLoading';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type { FetchAllLocationsPositionsFunction, LocationsPosition } from 'store/locations-positions/Store';
import { Action as StoreAction } from 'store/normalized-store';
import { shiftplansNS } from 'store/shiftplans/Store';
import type { FetchAllShiftplansFunction, Shiftplan } from 'store/shiftplans/Store';
import { getDefaultShiftplan } from 'utils/shift-schedule';
import { executeStoreActionWithFailureSnackbar } from 'utils/store';
import type { GetById, GetMultipleById } from 'utils/store';
import { uiSettingsNS } from 'src/store/ui-settings/Store';
import type { StoreState as UiSettingsStoreState } from 'store/ui-settings/Store';
import { Route } from './routes';
import PrintShiftSchedule from './PrintShiftSchedule';
import UiSettingsContainer, { SettingsScope } from './ui-settings-container/UiSettingsContainer';
import styles from './container.css';
import { shiftScheduleNS } from './store/Store';
import { FiltersMap } from '../calendar-common/filters/Store';

@Component
export default class PrintShiftScheduleContainer extends TsxComponent<{
  shiftplanId: number;
}> {
  protected isLoading = true;

  protected isShiftplanFiltersSidebarCollapsed = false;

  @Prop()
  public shiftplanId: number;

  @authNS.State
  public currentLocationId: AuthStoreState['currentLocationId'];

  @locationsPositionsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAllLocationsPositions: FetchAllLocationsPositionsFunction;

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;

  @shiftplansNS.Action(StoreAction.FETCH_ALL)
  protected fetchAll: FetchAllShiftplansFunction;

  @shiftplansNS.Getter('getById')
  protected getShiftplanById: GetById<Shiftplan>;

  @shiftplansNS.Getter('getByLocationId')
  protected getShiftplansByLocationId: GetMultipleById<Shiftplan>;

  @shiftScheduleNS.Getter
  protected printShiftScheduleQuickFilters: FiltersMap[];

  @uiSettingsNS.State('shared')
  protected uiSharedSettings: UiSettingsStoreState['shared'];

  protected get orderedLocationsPositions() {
    return this.getLocationsPositionsByLocationAndPositionId(this.currentLocationId)
      .filter(({ position }) => !!position)
      .sort((x, y) => x.position!.name.localeCompare(y.position!.name));
  }

  protected get shiftplans() {
    return this.getShiftplansByLocationId(this.currentLocationId || Number.NaN);
  }

  protected get shiftplan() {
    return this.getShiftplanById(this.shiftplanId);
  }

  protected redirectToDefaultShiftplanIfRequired() {
    if (this.shiftplan) {
      return;
    }

    if (!this.shiftplans.length) {
      this.$router.push({ name: Route.ROOT });
      return;
    }

    const shiftplan = getDefaultShiftplan(this.shiftplans, this.$timeZone.value);

    if (shiftplan) {
      this.$router.push({
        name: Route.PRINT_SHIFTPLAN,
        params: { shiftplanId: shiftplan.id.toString() },
      });
    }
  }

  protected async populate() {
    this.isLoading = true;

    await executeStoreActionWithFailureSnackbar(
      this,
      { locationId: this.currentLocationId || Number.NaN },
      this.fetchAll,
      '',
    );

    if (!this.orderedLocationsPositions.length) {
      await executeStoreActionWithFailureSnackbar(
        this,
        {
          locationIds: [this.currentLocationId || Number.NaN],
          positionIds: null,
        },
        this.fetchAllLocationsPositions,
        '',
      );
    }

    this.isLoading = false;
  }

  public async mounted() {
    await this.populate();
    this.redirectToDefaultShiftplanIfRequired();
  }

  public render() {
    if (this.isLoading) {
      return (
        <ScreenLoading />
      );
    }

    return (
      <div class={styles.containerWrapper}>
        <UiSettingsContainer
          scope={SettingsScope.CALENDAR_PRINT}
          shiftplan={this.shiftplan}
          isShiftplanFiltersSidebarCollapsed={this.isShiftplanFiltersSidebarCollapsed}
        />
        <PrintShiftSchedule
          printShiftScheduleQuickFilters={this.printShiftScheduleQuickFilters}
          shiftplan={this.shiftplan}
          shiftplans={this.shiftplans}
          onFilterSidebarToggle={() => {
            this.isShiftplanFiltersSidebarCollapsed = !this.isShiftplanFiltersSidebarCollapsed;
          }}
          isShiftplanFiltersSidebarCollapsed={
            !!this.uiSharedSettings.isShiftplanFiltersSidebarCollapsed
          }
        />
      </div>
    );
  }
}
