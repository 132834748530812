import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './screen.css';

@Component
export default class Screen extends TsxComponent<{
  message?: string;
  title?: string;
  visual?: JSX.Element | string;
}> {
  @Prop()
  public message?: string;

  @Prop()
  public title?: string;

  @Prop()
  public visual?: JSX.Element | string;

  public render() {
    return (
      <div class={styles.screen}>
        {
          this.visual && (
            <span aria-hidden="true" class={styles.screenVisual}>{this.visual}</span>
          )
        }
        {
          this.title && (
            <h1 class={styles.screenTitle}>
              {this.title}
            </h1>
          )
        }
        {
          this.message && (
            <p
              class={styles.screenMessage}
              domPropsInnerHTML={this.message}
            />
          )
        }
      </div>
    );
  }
}
