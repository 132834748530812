/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Dialog, { Slot } from 'components/dialog/Dialog';
import Spinner from 'components/spinner/Spinner';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './dialog.css';

@Component
export default class DialogWithSpinnerAndError extends TsxComponent <{
  error?: boolean | string;
  id?: string;
  isLoading?: boolean;
  isClosingPrevented?: boolean;
  isOpen?: boolean;
  title?: string;
}, {
  onCloseClick: (e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>) => void;
}> {
  @Prop()
  public error?: boolean | string;

  @Prop()
  public id: string;

  @Prop({ default: false })
  public isOpen: boolean;

  @Prop()
  public isLoading?: boolean;

  @Prop()
  public isClosingPrevented?: boolean;

  @Prop()
  public title?: string;

  protected onCloseClick() {
    this.$emit('closeClick');
  }

  public render() {
    return (
      <Dialog
        id={this.id}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        isCloseButtonDisabled={this.isClosingPrevented}
        title={this.title}
      >
        {
          this.isLoading && (
            <Spinner class={styles.dialogSpinner} />
          )
        }

        {
          this.error && (
            <div class={styles.dialogError}>
              {
                typeof this.error === 'string'
                  ? this.error
                  : this.$t('screens.error.title')
              }
            </div>
          )
        }

        {!this.isLoading && !this.error && this.$slots.default}

        <template slot={Slot.BUTTONS_LEFT}>
          {this.$slots[Slot.BUTTONS_LEFT]}
        </template>

        <template slot={Slot.BUTTONS_RIGHT}>
          {this.$slots[Slot.BUTTONS_RIGHT]}
        </template>
      </Dialog>
    );
  }
}
