import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLEmploymentsUiSetting,
  GQLMutationUpdateEmploymentsUiSettingArgs,
  GQLGetUiSettingQuery,
  GQLGetUiSettingQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { StoreActionResult, StoreActionState } from 'utils/store';
import {
  PrintViewKind, SlotDisplayStyle, TimeframeKind, ViewKind,
} from 'components/calendar-common/Enums';
import { FiltersInput } from 'components/calendar-common/filters/Store';
import type { SelectedTimeframe } from 'components/datepicker/types';
import { Module } from 'vuex';
import UpdateEmploymentUiSettingGQL from './queries/UpdateEmploymentsUiSetting.gql';
import GetUiSettingGQL from './queries/GetUiSetting.gql';
import Action from './Action';
import Mutation from './Mutation';
import RootStoreState from '../RootStoreState';
import { isSuccessResult, handleUnexpectedResult } from '../normalized-store';

export const uiSettingsNS = namespace('uiSettings');

export interface SharedSettings {
  timeframeKind?: TimeframeKind;
  viewKind?: ViewKind;
  printViewKind?: PrintViewKind;
  slotDisplayStyle?: SlotDisplayStyle;
  filters?: FiltersInput;
  timeframeCustomDuration?: number;
  isShiftplanFiltersSidebarCollapsed?: boolean;
}

export interface ShiftplanScopedSettings {
  shiftRotationGroupIds: number[] | null;
  timeframeCustom?: SelectedTimeframe;
  timeframe?: SelectedTimeframe;
}

export interface LocationScopedSettings {
  locationsPositionIds: number[];
  tagIds: number[];
  shiftPresetIds: number[];
}

export interface CalendarAbsencesScopedSettings {
  timeframe?: SelectedTimeframe;
  shiftRotationGroupIds?: number[] | null;
}

export interface UiSettings {
  shared: SharedSettings;
  calendarAbsencesScoped: CalendarAbsencesScopedSettings;
  shiftplanScoped: Record<number, ShiftplanScopedSettings>;
  locationScoped: Record<number, LocationScopedSettings>;
}

export type StoreState = UiSettings & { employmentId: number | null };
const getUiSettingsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    shared: {},
    calendarAbsencesScoped: {},
    shiftplanScoped: {},
    locationScoped: {},
    employmentId: null,
  },
  actions: {
    [Action.SAVE_SETTINGS]: async (
      { rootState, state },
    ): Promise<StoreActionResult> => {
      const { currentCompanyId, currentEmploymentId } = rootState.auth;
      if (!currentCompanyId || !currentEmploymentId) {
        return {
          state: StoreActionState.ERROR,
        };
      }

      const {
        shared,
        calendarAbsencesScoped,
        shiftplanScoped,
        locationScoped,
      } = state;
      const result = await graphqlClient.mutate<
      GQLEmploymentsUiSetting, GQLMutationUpdateEmploymentsUiSettingArgs
      >({
        mutation: UpdateEmploymentUiSettingGQL,
        variables: {
          employmentId: currentEmploymentId,
          settings: JSON.stringify({
            shared,
            locationScoped,
            shiftplanScoped,
            calendarAbsencesScoped,
          }),
          companyId: currentCompanyId,
          version: '2.0.0',
        },
      });

      if (!isSuccessResult(result, 'updateEmploymentsUiSetting')) {
        return handleUnexpectedResult(Action.SAVE_SETTINGS as any, logger);
      }

      return {
        state: StoreActionState.SUCCESS,
      };
    },
    [Action.UPDATE_SETTINGS_STATE]: async (
      { commit },
      setting: UiSettings,
    ): Promise<StoreActionResult> => {
      commit(Mutation.SET_SETTINGS, setting);
      return {
        state: StoreActionState.SUCCESS,
      };
    },
    [Action.GET_SETTINGS]: async (
      { commit, rootState },
    ): Promise<StoreActionResult> => {
      const { currentEmploymentId, currentCompanyId } = rootState.auth;

      if (!currentCompanyId || !currentEmploymentId) {
        return {
          state: StoreActionState.ERROR,
        };
      }

      try {
        const result = await graphqlClient.query<
        GQLGetUiSettingQuery, GQLGetUiSettingQueryVariables
        >({
          query: GetUiSettingGQL,
          variables: { employmentId: currentEmploymentId, companyId: currentCompanyId },
        });

        if (!isSuccessResult(result, 'employmentsUiSetting')) {
          return handleUnexpectedResult(Action.GET_SETTINGS as any, logger);
        }

        const { version, settings } = result.data.employmentsUiSetting;
        if (version === '2.0.0') {
          commit(Mutation.SET_SETTINGS, settings);
          commit(
            Mutation.SET_EMPLOYMENT_ID,
            currentEmploymentId,
          );
          return {
            state: StoreActionState.SUCCESS,
          };
        }

        return {
          state: StoreActionState.NOT_FOUND,
        };
      } catch (ex) {
        logger.instance.error(ex);

        return {
          state: StoreActionState.ERROR,
        };
      }
    },
  },
  mutations: {
    [Mutation.SET_SETTINGS](state, {
      shared = {},
      shiftplanScoped = {},
      locationScoped = {},
      calendarAbsencesScoped = {},
    }: UiSettings) {
      state.shared = shared;
      state.shiftplanScoped = shiftplanScoped;
      state.locationScoped = locationScoped;
      state.calendarAbsencesScoped = calendarAbsencesScoped;
    },
    [Mutation.SET_EMPLOYMENT_ID](state, employmentId: number) {
      state.employmentId = employmentId;
    },
  },
});

export default getUiSettingsStore;
