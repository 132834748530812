import {
  addMonths, eachDayOfInterval, endOfMonth,
  endOfWeek, isSameDay, isWithinInterval,
  startOfWeek, startOfToday, isAfter,
  startOfMonth,
} from 'date-fns';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import {
  DAYS_IN_WEEK,
  getDateInTimeZone,
  getDurationString,
  getLocalizedWeekdays,
  LOCALE_TIMEZONE,
  MAX_DATE,
  MIN_DATE,
  eachDayOfTimeframe,
} from 'src/utils/date-related';
import { chunk, isEqual, xorWith } from 'lodash';
import { createEventPayload, EventPayload } from 'src/utils/events';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import { IconPosition } from 'components/form/button/types';
import { SelectedTimeframe, SelectionMode, SelectionState } from './types';
import Day from './day/Day';
import styles from './datepicker.css';

interface Props {
  max?: Date;
  min?: Date;
  selection: Date[];
  selectionMode?: SelectionMode;
  timeZone?: string;
  hoveredDates?: SelectedTimeframe | null;
  // used to disable specific dates on datepicker
  disabledDates?: Date[];
}

interface Events {
  onChange: EventPayload<Date[], HTMLButtonElement, MouseEvent>;
  onIntervalSingleSelection: EventPayload<Date[], HTMLButtonElement, MouseEvent>;
  onMouseEnter: EventPayload<
  { day: Date; selectionState: SelectionState; currentSelection: Date[] },
  HTMLButtonElement, MouseEvent>;
  onMouseLeave: EventPayload<
  void,
  HTMLButtonElement, MouseEvent>;
  onWindowResize: void;
}

@Component
export default class Datepicker extends TsxComponent<Props, Events> {
  // first day of active month
  // FIXME: check if eslint override can be removed when refactoring Datepicker
  // eslint-disable-next-line no-restricted-syntax
  private firstOfMonth: Date = startOfMonth(startOfToday());

  private selectionState: SelectionState = SelectionState.NOT_SELECTED;

  /*
    it is storing correct moment of times,
    so conversion is needed when comparing with values
    that are used for UI representation
  */
  private currentSelection: Date[] = [];

  @Prop()
  public max: Props['max'];

  @Prop()
  public min: Props['min'];

  @Prop()
  public selection: Props['selection'];

  @Prop({ default: SelectionMode.INTERVAL })
  public selectionMode: NonNullable<Props['selectionMode']>; // default value makes it always provided

  @Prop()
  public timeZone?: Props['timeZone'];

  @Prop({
    default() {
      return [];
    },
  })
  public disabledDates: NonNullable<Props['disabledDates']>;

  @Prop({
    default() {
      return null;
    },
  })
  public hoveredDates: Exclude<Props['hoveredDates'], undefined>; // can't use NonNullable as null is allowed

  @Watch('selection', { immediate: true })
  public onSelectionChange() {
    if (this.selectionMode === SelectionMode.INTERVAL) {
      this.selectionState = this.selection.length
        ? SelectionState.INTERVAL : SelectionState.NOT_SELECTED;
    }
    this.currentSelection = this.selection;
  }

  /* reset selection if disabledDates changes
   there are too many edge cases to handle it
   for every case otherwise (especially for interval selection)
  */
  @Watch('disabledDates', { deep: true })
  public onDisabledDatesChange(oldValue, newValue) {
    // Vue can't detect changes with arrays of dates? runs into infinite loop without explicit check
    if (xorWith(oldValue, newValue, isEqual).length !== 0) {
      this.currentSelection = [];
      this.selectionState = SelectionState.NOT_SELECTED;
      this.$emit('change', { payload: [] });
    }
  }

  private get maxDate() {
    return this.max
      ? getDateInTimeZone(this.max, this.timeZoneValue)
      : MAX_DATE;
  }

  private get minDate() {
    return this.min
      ? getDateInTimeZone(this.min, this.timeZoneValue)
      : MIN_DATE;
  }

  private get orderedSelection(): Date[] {
    return [...this.currentSelection]
      .sort((date1, date2) => date1.valueOf() - date2.valueOf());
  }

  private get selectionStartsAt(): Date | undefined {
    return this.orderedSelection.length > 0
      ? this.orderedSelection[0]
      : undefined;
  }

  private get selectionEndsAt(): Date | undefined {
    return this.orderedSelection.length > 0
      ? this.orderedSelection[this.orderedSelection.length - 1]
      : undefined;
  }

  private get firstDate() {
    // eslint-disable-next-line no-restricted-syntax
    return startOfWeek(this.firstOfMonth, {
      weekStartsOn: 1,
    });
  }

  private get lastDate() {
    // eslint-disable-next-line no-restricted-syntax
    return endOfWeek(endOfMonth(this.firstOfMonth), {
      weekStartsOn: 1,
    });
  }

  // These are used to display calendar days and are in user(company) timezone
  private get dates() {
    return eachDayOfInterval({
      start: this.firstDate,
      end: this.lastDate,
    });
  }

  private get timeZoneValue() {
    return this.timeZone || LOCALE_TIMEZONE;
  }

  private get prevMonth() {
    return addMonths(this.firstOfMonth, -1);
  }

  private get nextMonth() {
    return addMonths(this.firstOfMonth, 1);
  }

  private setFirstOfMonth(firstOfMonth: Date) {
    this.firstOfMonth = firstOfMonth;
  }

  private handleIntervalModeClick(e: SyntheticEvent<HTMLButtonElement, MouseEvent>, day: Date) {
    switch (this.selectionState) {
      case SelectionState.SINGLE_SELECTION: {
        const [originalSelection] = this.currentSelection;
        // do nothing if day matches active single selection
        if (isSameDay(day, originalSelection)) {
          return;
        }
        const timeframe: SelectedTimeframe = isAfter(originalSelection, day)
          ? {
            startsAt: day,
            endsAt: originalSelection,
          }
          : {
            startsAt: originalSelection,
            endsAt: day,
          };
        // reverse startsAt and endsAt if needed
        this.currentSelection = eachDayOfTimeframe(timeframe, this.timeZoneValue);

        this.selectionState = SelectionState.INTERVAL;

        this.$emit('change', createEventPayload(e, this.orderedSelection));

        break;
      }
      case SelectionState.INTERVAL:
      case SelectionState.NOT_SELECTED:
        this.selectionState = SelectionState.SINGLE_SELECTION;
        this.currentSelection = [day];
        this.$emit('intervalSingleSelection', createEventPayload(e, this.orderedSelection));
        break;
      default:
      // should never happen
    }
  }

  private handleSingleModeClick(e: SyntheticEvent<HTMLButtonElement, MouseEvent>, day: Date) {
    this.selectionState = SelectionState.SINGLE_SELECTION;
    this.currentSelection = [day];

    this.$emit('change', createEventPayload(e, this.orderedSelection));
  }

  private handleMultipleModeClick(e: SyntheticEvent<HTMLButtonElement, MouseEvent>, day: Date) {
    this.selectionState = SelectionState.SINGLE_SELECTION;

    // filter selected element if it's present
    const newSelection = this.currentSelection
      .filter(it => it.valueOf() !== day.valueOf());

    // if nothing was filtered - add new element to selection
    if (newSelection.length === this.currentSelection.length) {
      this.currentSelection = [...this.currentSelection, day];
    } else {
      this.currentSelection = newSelection;
    }

    this.$emit('change', createEventPayload(e, this.orderedSelection));
  }

  /*
    - Logic for selection is as follows:
    1. If state is NOT_SELECTED - goto SINGLE_SELECTION
    2. If state is SINGLE_SELECTION
      * If day doesn't match day from selection goto INTERVAL
      ** If day matches day from selection - do nothing
    3. If state is INTERVAL - goto SINGLE_SELECTION
  */
  private onDayClick(e: SyntheticEvent<HTMLButtonElement, MouseEvent>, day: Date) {
    switch (this.selectionMode) {
      case SelectionMode.INTERVAL:
        this.handleIntervalModeClick(e, day);
        break;
      case SelectionMode.MULTIPLE:
        this.handleMultipleModeClick(e, day);
        break;
      default:
        this.handleSingleModeClick(e, day);
    }
  }

  private onWindowResize() {
    this.$emit('windowResize');
  }

  public mounted() {
    // https://shyftplan.atlassian.net/wiki/spaces/DEV/pages/2082930779/Working+with+Time+Zones#It%E2%80%99s-About-Time-(Shifting)
    // eslint-disable-next-line no-restricted-syntax
    this.firstOfMonth = startOfMonth(getDateInTimeZone(this.selectionStartsAt
      || this.min
      || new Date(), this.timeZoneValue));
    window.addEventListener('resize', this.onWindowResize, { passive: true });
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  public render() {
    const locale = this.$i18n.i18next.language;
    const weekdays = getLocalizedWeekdays(locale, {
      weekday: 'narrow',
    });
    const weeks = chunk(this.dates, DAYS_IN_WEEK);

    return (
      <div class={styles.datepicker}>
        <header class={styles.datepickerHeader}>
          <Button
            color={ButtonColor.SECONDARY}
            /*
             * dates here are in company timezone
             * we want to check if at least one day is in allowed interval
             */
            disabled={!isWithinInterval(
              // eslint-disable-next-line no-restricted-syntax
              endOfMonth(this.prevMonth),
              { start: this.minDate, end: this.maxDate },
            )}
            icon={IconName.ARROW_BACK}
            aria-label={this.$t('datepicker.labelPreviousMonth')}
            onClick={() => this.setFirstOfMonth(this.prevMonth)}
            size={Size.MEDIUM}
            iconPosition={IconPosition.ALONE}
            kind={ButtonKind.GHOST}
          />
          <span class={styles.datepickerMonthLabel}>
            {
              // FIXME: check if eslint override can be removed when refactoring Datepicker
              // eslint-disable-next-line no-restricted-syntax
              this.firstOfMonth.toLocaleDateString(locale, {
                month: 'long',
                year: 'numeric',
              })
            }
          </span>
          <Button
            color={ButtonColor.SECONDARY}
            disabled={!isWithinInterval(this.nextMonth, { start: this.minDate, end: this.maxDate })}
            icon={IconName.ARROW_NEXT}
            aria-label={this.$t('datepicker.labelNextMonth')}
            onClick={() => this.setFirstOfMonth(this.nextMonth)}
            size={Size.MEDIUM}
            iconPosition={IconPosition.ALONE}
            kind={ButtonKind.GHOST}
          />
        </header>
        <div class={styles.datepickerGrid}>
          {
            weekdays.map(weekday => (
              <div class={[styles.datepickerGridCell, styles.datepickerGridCellHeader]}>
                {weekday}
              </div>
            ))
          }

          {
            weeks.map(week => week.map((day, index) => <Day
              currentSelection={this.orderedSelection}
              currentHoveredDates={this.hoveredDates}
              day={day}
              minDate={this.minDate}
              maxDate={this.maxDate}
              disabledDates={this.disabledDates}
              dayIndex={index}
              selectionMode={this.selectionMode}
              timeZone={this.timeZoneValue}
              onDayClick={({ event, payload }) => { this.onDayClick(event, payload); }}
              onMouseEnter={({ event, payload }) => {
                this.$emit('mouseEnter', createEventPayload(
                  event,
                  {
                    day: payload,
                    selectionState: this.selectionState,
                    currentSelection: this.currentSelection,
                  },
                ));
              }}
              onMouseLeave={(eventPayload) => {
                this.$emit('mouseLeave', eventPayload);
              }}
            />))
          }
        </div>

        {
          this.selectionMode === SelectionMode.INTERVAL && (
            <div class={styles.datepickerInterval}>
              {
                this.selectionStartsAt
                && this.selectionEndsAt
                && getDurationString(
                  this.$i18n.i18next.language,
                  getDateInTimeZone(this.selectionStartsAt, this.timeZoneValue),
                  getDateInTimeZone(this.selectionEndsAt, this.timeZoneValue),
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}
