import { Component, Vue } from 'vue-property-decorator';
import { calendarPrintCommonNS, StoreState } from 'components/calendar-print/common/Store';
import { PrintViewKind } from 'components/calendar-common/Enums';
import EmploymentsMonth from './employments/employments-month/EmploymentsMonth';
import EmploymentsWeek from './employments/employments-week/EmploymentsWeek';
import ListDay from './list-day/ListDay';
import PositionsMonth from './positions/positions-month/PositionsMonth';
import PositionsWeek from './positions/positions-week/PositionsWeek';
import styles from './print-root.css';

const componentsMap: Record<PrintViewKind, any> = {
  [PrintViewKind.LIST]: ListDay,
  [PrintViewKind.POSITIONS_WEEK]: PositionsWeek,
  [PrintViewKind.POSITIONS_MONTH]: PositionsMonth,
  [PrintViewKind.EMPLOYMENTS_WEEK]: EmploymentsWeek,
  [PrintViewKind.EMPLOYMENTS_MONTH]: EmploymentsMonth,
};
@Component
class PrintRoot extends Vue {
  @calendarPrintCommonNS.State
  public printViewKind: PrintViewKind;

  @calendarPrintCommonNS.State((state: StoreState) => state.shiftplan?.name)
  public shiftplanName: string;

  public render() {
    // tslint:disable-next-line:variable-name
    const PrintComponent = componentsMap[this.printViewKind];
    return (<div class={styles.print} id="print-root">
      <header class={styles.printHeader}>
        <span class={styles.printHeaderRange}>
          {this.shiftplanName}
        </span>
      </header>
      <PrintComponent />
    </div>);
  }
}

export default PrintRoot as any;
