import Spinner from 'components/spinner/Spinner';
import Action from 'components/table/store/Action';
import { LoadingState, SetFilterPayload } from 'components/table/store/Store';
import type { GetById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Card from 'components/card/Card';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import Button from 'components/form/button/Button';
import { IconPosition } from 'components/form/button/types';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { sortEmploymentsRotationGroupByStartsAtAndName } from '../utils';
import RotationGroupCard from '../rotation-group-card/RotationGroupCard';
import { Filters, ModuleState, rotationGroupsTableNS } from './store/Store';
import { Route } from '../routes';
import { employmentsNS } from '../store/Store';
import type { Employment } from '../types';
import styles from './rotation-groups.css';

@Component
export default class RotationGroups extends TsxComponent<{}> {
  protected employment: Employment | null = null;

  @employmentsNS.Getter
  protected getById: GetById<Employment>;

  @rotationGroupsTableNS.Action(Action.SET_FILTER)
  private setFilter: (
    payload: SetFilterPayload<Filters, keyof Filters>
  ) => Promise<void>;

  @rotationGroupsTableNS.Action(Action.SUBSCRIBE)
  private subscribe: () => Promise<void>;

  @rotationGroupsTableNS.Action(Action.UNSUBSCRIBE)
  private unsubscribe: () => Promise<void>;

  @rotationGroupsTableNS.State(state => state.data)
  private rotationGroups: ModuleState['table']['data'];

  @rotationGroupsTableNS.State
  private loadingState: ModuleState['table']['loadingState'];

  @Prop()
  public employmentId: number;

  protected get rotationGroupsSorted() {
    const sortFn = sortEmploymentsRotationGroupByStartsAtAndName({ reverseStartsAt: true });
    return [...this.rotationGroups].sort((x, y) => sortFn(x, y));
  }

  public mounted() {
    this.employment = this.getById(this.employmentId) || null;

    if (this.employment) {
      this.setFilter({
        key: 'employmentIds',
        value: [this.employment.id],
      });

      this.subscribe();
    }
  }

  public beforeDestroy() {
    this.unsubscribe();
  }

  public render() {
    return (
      <div class={styles.rotationGroups}>
        <h2 class={styles.rotationGroupsTitle}>
          {this.$t('employments.rotationGroups.title')}
        </h2>

        <div class={styles.rotationGroupsCards}>
          <div class={styles.rotationGroupsButtonWrapper}>
            <Card class={styles.rotationGroupsCard}>
              <router-link class={styles.rotationGroupsButton}
                to={{ name: Route.CREATE_ASSIGNMENT }}>
                <Button
                  class={styles.rotationGroupsButtonIcon}
                  color={ButtonColor.SECONDARY}
                  iconPosition={IconPosition.ALONE}
                  icon={IconName.PLUS}
                  size={Size.LARGE}
                  kind={ButtonKind.GHOST}
                />
                {this.$t('employments.rotationGroups.buttonAssignRotationGroup')}
              </router-link>
            </Card>

            {/* Deliberately hidden in SP-416 */}
            {/* <Card class={styles.rotationGroupsCard}>
              <router-link
                class={styles.rotationGroupsButton}
                to={{
                  name: RotationWizardRoute.INDIVIDUAL_ROOT,
                  params: { id: this.employment?.id.toString() || '0' },
                }}
              >
                <Icon
                  class={styles.rotationGroupsButtonIcon}
                  name="plus"
                  size={Size.LARGE}
                />
                {this.$t('employments.rotationGroups.buttonCreateEmployeeRotation')}
              </router-link>
            </Card> */}
          </div>

          {
            this.rotationGroupsSorted.map(group => (
              <RotationGroupCard
                employment={this.employment || undefined}
                employmentsRotationGroup={group}
                key={group.id}
              />
            ))
          }

          {
            this.loadingState !== LoadingState.IDLE && (
              <Spinner class={styles.rotationGroupsSpinner} />
            )
          }
        </div>
        <router-view/>
      </div>
    );
  }
}
