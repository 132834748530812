import { RouteConfig, Route as RouteType } from 'vue-router';
import DialogShiftplanNotifications from './DialogShiftplanNotifications';
import MessageContainer from './message/MessageContainer';
import NotificationContainer from './notification/NotificationContainer';

export enum Route {
  NOTIFICATION = 'shiftplanNotification',
  MESSAGE = 'shiftplanMessage',
}

const routes: RouteConfig[] = [
  {
    component: DialogShiftplanNotifications,
    path: 'notifications-message',
    props: (route: RouteType) => ({
      shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
    }),
    children: [
      {
        name: Route.NOTIFICATION,
        component: NotificationContainer,
        path: 'notifications',
        props: (route: RouteType) => ({
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        }),
      },
      {
        name: Route.MESSAGE,
        component: MessageContainer,
        path: 'message',
        props: (route: RouteType) => ({
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        }),
      },
    ],
  },
];

export default routes;
