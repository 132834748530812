import type { GQLAbsenceCollisionFragment, GQLShiftCollisionFragment } from 'codegen/gql-types';
import { getDurationStringInTimeZone } from 'utils/date-related';
import { filterFalsy } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './collisions.css';

@Component
export default class Collisions extends TsxComponent<{
  shifts: (GQLShiftCollisionFragment | null)[] | null;
  absences: (GQLAbsenceCollisionFragment | null)[] | null;
}> {
  @Prop()
  public shifts: (GQLShiftCollisionFragment | null)[] | null;

  @Prop()
  public absences: (GQLAbsenceCollisionFragment | null)[] | null;

  public render() {
    if (!this.shifts?.length && !this.absences?.length) {
      return null;
    }

    return (
      <div>
        {!!this.shifts?.length && (
          <section class={styles.collisionsSection}>
            <h3 class={styles.collisionsTitle}>{this.$t('conflicts.collisions.shifts')}</h3>

            <ul class={styles.collisionsList}>
              {this.shifts.filter(filterFalsy).map(shift => (
                <li class={styles.collisionsListItem} key={shift.id}>
                  {
                    getDurationStringInTimeZone(
                      this.$timeZone.value,
                      this.$i18n.i18next.language,
                      new Date(shift.startsAt),
                      new Date(shift.endsAt),
                      true,
                    )
                  }
                  {' '}
                  ({
                    shift.locationsPosition.position?.name
                  }, {
                    shift.locationsPosition.location?.name
                  })
                </li>
              ))}
            </ul>
          </section>
        )}

        {!!this.absences?.length && (
          <section class={styles.collisionsSection}>
            <h3 class={styles.collisionsTitle}>{this.$t('conflicts.collisions.absences')}</h3>

            <ul class={styles.collisionsList}>
              {this.absences.filter(filterFalsy).map(absence => (
                <li class={styles.collisionsListItem} key={absence.id}>
                  {
                    getDurationStringInTimeZone(
                      this.$timeZone.value,
                      this.$i18n.i18next.language,
                      new Date(absence.startsAt),
                      new Date(absence.endsAt),
                    )
                  }
                  {' '}
                  ({
                    absence.absenceReason.hasLocalization
                      ? this.$t(`absence.reason.${absence.absenceReason.name}`)
                      : absence.absenceReason.name
                  })
                </li>
              ))}
            </ul>
          </section>
        )}
      </div>
    );
  }
}
