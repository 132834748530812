import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import styles from './link-button.css';

export enum LinkButtonColor {
  OUTER_SPACE = 'outer-space',
  SHYFTPLAN_GREEN = 'shyftplan-green',
}

export type Props = {
  to?: string;
  isExternal: true;
  title?: string;
  color?: LinkButtonColor;
} | {
  to?: string | Location;
  isExternal?: false;
  title?: string;
  color?: LinkButtonColor;
}

@Component
class LinkButton extends TSXComponent<Props> {
  @Prop({ default: LinkButtonColor.SHYFTPLAN_GREEN })
  public color: LinkButtonColor;

  @Prop()
  public to: Props['to'];

  @Prop()
  public isExternal: Props['isExternal'];

  @Prop()
  public title: Props['title'];

  public render() {
    return this.isExternal ? (
      <a
        href={this.to}
        title={this.title}
        class={[
          styles.linkButton,
          this.color === LinkButtonColor.OUTER_SPACE && styles.linkButtonOuterSpace,
          this.color === LinkButtonColor.SHYFTPLAN_GREEN && styles.linkButtonShyftplanGreen,
        ]}
      >
        {this.$slots.default}
      </a>
    ) : (
      <router-link
        title={this.title}
        to={this.to}
        class={[
          styles.linkButton,
          this.color === LinkButtonColor.OUTER_SPACE && styles.linkButtonOuterSpace,
          this.color === LinkButtonColor.SHYFTPLAN_GREEN && styles.linkButtonShyftplanGreen,
        ]}
      >
        {this.$slots.default}
      </router-link>
    );
  }
}

export default LinkButton;
