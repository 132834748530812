export enum UpdatedItemKind {
  NEW = 'new',
  CHANGED = 'changed',
}

export interface StoreItem {
  id: number;
  [key: string]: any;
}

// eslint-disable-next-line arrow-parens
export const deleteItems = <T extends StoreItem>(existingItems: T[], deletedIds: number[] = []) => {
  if (deletedIds.length === 0) {
    return existingItems;
  }
  return existingItems.filter(it => !deletedIds.includes(it.id));
};

export const getUpdatedItems = <T extends StoreItem>(
  // eslint-disable-next-line arrow-parens
  existingItems: T[],
  updatedItems: T[],
): T[] => {
  const unchangedItems = deleteItems(
    existingItems,
    updatedItems.map(it => it.id),
  );

  return [...unchangedItems, ...updatedItems];
};
