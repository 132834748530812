import {
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { tagsNS } from 'store/tags/Store';
import type { FetchAllTagsFunction, Tag } from 'store/tags/Store';
import { shiftEvaluationTagsNS } from 'store/shift-evaluation-tags/Store';
import type {
  CreateShiftEvaluationTagFunction,
  ShiftEvaluationTagsFunction,
} from 'store/shift-evaluation-tags/Store';
import { authNS } from 'components/auth/store/Store';
import type { StoreState as AuthStoreState } from 'components/auth/store/Store';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type {
  FetchAllLocationsPositionsFunction,
  LocationsPosition,
} from 'store/locations-positions/Store';
import { ShiftPreset, shiftPresetsNS } from 'store/shift-presets/Store';
import type { FetchAllShiftPresetsFunction } from 'store/shift-presets/Store';
import { shiftsNS } from 'store/shifts/Store';
import type {
  CanManageFunction,
  CreateShiftBreakFunction,
  AddTagToShiftFunction,
  CreateShiftFunction,
  UpdateShiftFunction,
  Shift,
  DeleteShiftFunction,
  CopyShiftFunction,
  DisconnectShiftFunction,
} from 'store/shifts/Store';
import { Action } from 'store/normalized-store';
import { Shiftplan } from 'store/shiftplans/Store';
import { staffShiftsNS } from 'store/staff-shifts/Store';
import type { CreateStaffShiftFunction } from 'store/staff-shifts/Store';
import { GQLShiftConflictsFragmentFragment, GQLShiftPreset, GQLTagContext } from 'codegen/gql-types';
import { employmentsNS } from 'components/employments/store/Store';
import type { FetchEmploymentFunction } from 'components/employments/store/Store';
import type { Employment } from 'components/employments/types';
import { confirmLeaveIf } from 'utils/route';
import {
  executeStoreActionWithFailureSnackbar,
  StoreActionState,
} from 'utils/store';
import type {
  GetById,
  GetMultipleById,
} from 'utils/store';
import { sortBySortOrder } from 'utils/utils';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { shiftRotationsNS } from 'src/store/shift-rotations/Store';
import type { FetchAllShiftRotationsFunction, ShiftRotation } from 'src/store/shift-rotations/Store';
import ShiftAction from 'store/shifts/Action';
import { formatISO } from 'date-fns';
import type { FetchAllQualificationsFunction } from 'src/store/qualifications/Store';
import { Qualification, qualificationsNS } from 'src/store/qualifications/Store';
import { EventPayload } from 'src/utils/events';
import type { AvailableShiftRotationGroup, FormState } from './types';
import {
  filteredShiftRotationGroupByShiftPreset,
  getFormStateByShift,
  getInitialFormState,
  getNewFormState,
  transformFormStateToCreateInput,
  transformFormStateToUpdateInput,
  validateBreak,
} from './utils';
import Details from './Details';

export interface Props {
  employmentId?: number;
  endsAt?: Date;
  formId: string;
  locationsPositionId?: number;
  shift?: Shift;
  shiftplan: Pick<Shiftplan, 'startsAt' | 'endsAt' | 'id' | 'locationId'>;
  startsAt?: Date;
}

export interface Events {
  onSubmitStateChange: (state: boolean) => void;
  onCloseClick: (e: SyntheticEvent<HTMLElement, MouseEvent>) => void;
  onCreateShiftSuccess: (shiftIds: number[]) => void;
  onUpdateShiftSuccess: (shiftIds: number[]) => void;
}
/*
 * Create shift requests flow:
  states:
 *  1. sumbit
 *  2. tryCreateStaffShift
 *  3. tryCloneShift
 *  4. deleteShift
 * states have an ignoreConflicts param (false | true)
 *  1(false) -> { success } -> 2(false)
 *  1(false) -> { conflict: ignored } -> 1(true)
 *  1(false) -> { conflict: canceled } -> end
 *  1(true)  -> { success } -> 2(false)
 *  2(false) -> { success } -> 3(false)
 *  2(false) -> { conflict: ignored } -> 2(true)
 *  2(false) -> { conflict: canceled } -> 4
 *  2(true)  -> { success } -> 3(false)
 *  3(false) -> { success } -> 3(false)
 *  3(false) -> { conflict: ignored } -> 3(true) (for same date)
 *  3(false) -> { conflict: canceled } -> 3(false) (for next date)
 *  3(true) -> { success } -> 3(false) (for next date)
 *  3(false) -> { no dates to repeat} -> end
 *  4 -> { any result } -> end
 *
 * TODO: Would probably make sense to have a propper state machine here
 */
@Component
class DetailsContainer extends TSXComponent<Props, Events> {
  private formState = getInitialFormState();

  private isDirty = false;

  private isEmploymentLoading = false;

  private isValidated = false;

  private conflictsShift: GQLShiftConflictsFragmentFragment[] = [];

  private conflictsStaffShift: GQLShiftConflictsFragmentFragment[] = [];

  private conflictsCopiedShift: GQLShiftConflictsFragmentFragment[] = [];

  protected isDialogAssignConnectedOpen = false;

  protected isDisconnectConfirmDialogOpen = false;

  protected updateConnected = false;

  private newShiftId = Number.NaN;

  private copiedShiftsQueue: Date[] = [];

  private newShiftIds: number[] = [];

  private ignoreShiftConflicts = false;

  // #region Props
  @Prop()
  public formId: Props['formId'];

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public shift: Props['shift'];

  @Prop()
  public startsAt: Props['startsAt'];

  @Prop()
  public endsAt: Props['endsAt'];

  @Prop()
  public employmentId: Props['employmentId'];

  @Prop()
  public locationsPositionId: Props['locationsPositionId'];
  // #endregion Props

  // #region Auth Store
  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];
  // #endregion Auth Store

  // #region Employments Store
  @employmentsNS.Action(Action.FETCH)
  protected fetchEmployment: FetchEmploymentFunction;

  @employmentsNS.Getter('getById')
  protected getEmploymentById: GetById<Employment>;
  // #endregion Employments Store

  // #region Tags Store
  @tagsNS.Getter('getByLocationId')
  protected getShiftTagsByLocationId: GetMultipleById<Tag>;

  @tagsNS.Action(Action.FETCH_ALL)
  protected fetchAllTags: FetchAllTagsFunction;

  protected get shiftTags() {
    return this.getShiftTagsByLocationId(this.shiftplan.locationId, GQLTagContext.SHIFT_TAG);
  }
  // #endregion Tags Store

  // #region Qualifications Store
  @qualificationsNS.Getter('items')
  protected qualifications: Qualification[];

  @qualificationsNS.Action(Action.FETCH_ALL)
  protected fetchAllQualifications: FetchAllQualificationsFunction;
  // #endregion Tags Store

  // #region Shift Evaluation Tags Store
  @shiftEvaluationTagsNS.Getter('ordered')
  protected shiftEvaluationTags: Tag[];

  @shiftEvaluationTagsNS.Action(Action.CREATE)
  protected createShiftEvaluationTag: CreateShiftEvaluationTagFunction;

  @shiftEvaluationTagsNS.Action(Action.FETCH_ALL)
  protected fetchAllShiftEvaluationTags: ShiftEvaluationTagsFunction;
  // #endregion Shift Evaluation Tags STore

  // #region Shifts Store
  @shiftsNS.Action(Action.CREATE)
  protected createShift: CreateShiftFunction;

  @shiftsNS.Action(Action.DELETE)
  protected deleteShift: DeleteShiftFunction;

  @shiftsNS.Action(Action.UPDATE)
  protected updateShift: UpdateShiftFunction;

  @shiftsNS.Action(ShiftAction.COPY_SHIFT)
  protected copyShift: CopyShiftFunction;

  @shiftsNS.Action(ShiftAction.DISCONNECT_SHIFT)
  protected disconnectShift: DisconnectShiftFunction;

  @shiftsNS.Action
  protected addTagToShift: AddTagToShiftFunction;

  @shiftsNS.Action
  protected createShiftBreak: CreateShiftBreakFunction;

  @shiftsNS.Getter
  protected canManageShift: CanManageFunction;
  // #endregion Shifts Store

  // #region StaffShifts Store
  @staffShiftsNS.Action(Action.CREATE)
  protected createStaffShift: CreateStaffShiftFunction;
  // #endregion StaffShifts Store

  // #region Locations Positions Store
  @locationsPositionsNS.Getter('getById')
  protected getLocationsPositionById: GetById<LocationsPosition>;

  @locationsPositionsNS.Getter('items')
  protected locationsPositions: LocationsPosition[];

  @locationsPositionsNS.Action(Action.FETCH_ALL)
  protected fetchAllLocationsPositions: FetchAllLocationsPositionsFunction;
  // #endregion Locations Positions Store

  // #region Shift Rotations Store
  @shiftRotationsNS.Getter('items')
  protected availableShiftRotations: ShiftRotation[];

  @shiftRotationsNS.Action(Action.FETCH_ALL)
  protected fetchAllShiftRotations: FetchAllShiftRotationsFunction;
  // #endregion Shift Rotations Store

  // #region Shift Presets Store
  @shiftPresetsNS.Getter('items')
  protected availableShiftPresets: ShiftPreset[];

  @shiftPresetsNS.Getter('getById')
  protected getShiftPresetById: GetById<GQLShiftPreset>;

  @shiftPresetsNS.Action(Action.FETCH_ALL)
  protected fetchAllShiftPresets: FetchAllShiftPresetsFunction;
  // #endregion Shift Presets Store

  // #endregion Store Bindings

  // #region Watchers
  @Watch('currentCompany', { immediate: true })
  @Watch('currentLocationId', { immediate: true })
  public async onCurrentCompanyChange() {
    executeStoreActionWithFailureSnackbar(
      this,
      {
        locationIds: this.currentLocationId ? [this.currentLocationId] : null,
        positionIds: null,
      },
      this.fetchAllLocationsPositions,
      'shifts.dialog.error',
      'shifts.dialog.error.genericLoadLocationsPositions',
    );

    if (this.currentCompany?.canUseShiftPresets) {
      executeStoreActionWithFailureSnackbar(
        this,
        {},
        this.fetchAllShiftPresets,
        'shifts.dialog.error',
        'shifts.dialog.error.genericLoadShiftPresets',
      );
    }

    if (this.currentCompany?.canUseShiftPresets
      && this.currentCompany?.shiftRotationEnabled) {
      executeStoreActionWithFailureSnackbar(
        this,
        {},
        this.fetchAllShiftRotations,
        'shifts.dialog.error',
        'shifts.dialog.error.genericLoadShiftRotations',
      );
    }

    if (this.currentCompany?.isTagsAllowed) {
      executeStoreActionWithFailureSnackbar(
        this,
        {
          context: [GQLTagContext.SHIFT_TAG],
          locationIds: this.shiftplan.locationId ? [this.shiftplan.locationId] : [],
        },
        this.fetchAllTags,
        'shifts.dialog.error',
        'shifts.dialog.error.genericLoadTags',
      );

      executeStoreActionWithFailureSnackbar(
        this,
        {},
        this.fetchAllShiftEvaluationTags,
        'shifts.dialog.error',
        'shifts.dialog.error.genericLoadTags',
      );
    }

    if (this.currentCompany?.canUseQualifications) {
      executeStoreActionWithFailureSnackbar(
        this,
        {},
        this.fetchAllQualifications,
        'shifts.dialog.error',
        'shifts.dialog.error.genericLoadQualifications',
      );
    }
  }
  // #endregion Watchers

  // #region Getters
  private get selectableLocationsPositions() {
    const locationsPositions = Array.isArray(this.employment?.locationsPositionIds)
      ? this.locationsPositions.filter(o => this.employment?.locationsPositionIds?.includes(o.id))
      : this.locationsPositions;

    return [...locationsPositions].sort(sortBySortOrder());
  }

  private get selectableShiftTags() {
    return !this.currentCompany?.isTagsAllowed
      ? []
      : this.shiftTags.filter(tag => tag.context === GQLTagContext.SHIFT_TAG);
  }

  private get selectableQualifications(): Qualification[] {
    return !this.currentCompany?.canUseQualifications
      ? []
      : this.qualifications;
  }

  private get selectableShiftEvaluationTags() {
    return !this.currentCompany?.isTagsAllowed
      ? []
      : this.shiftEvaluationTags;
  }

  private get selectableShiftPresets() {
    return !this.currentCompany?.canUseShiftPresets
      ? []
      : this.availableShiftPresets;
  }

  private get selectableShiftRotationGroups(): AvailableShiftRotationGroup[] {
    if (
      !this.shift?.id
      || !this.currentCompany?.shiftRotationEnabled
      || !this.currentCompany?.canUseShiftPresets
      || !this.formState.shiftPresetId
      || !this.availableShiftRotations
    ) {
      return [];
    }

    const availableGroups = filteredShiftRotationGroupByShiftPreset(
      this.formState.startsAt,
      this.availableShiftRotations,
      this.formState.shiftPresetId,
      this.$timeZone.value,
    );

    return availableGroups.map(group => ({
      id: Number(group.shiftRotationGroupId),
      name: group.shiftRotationGroupName,
      shiftRotation: {
        name: group.shiftRotationName,
        id: group.shiftRotationId,
      },
    }));
  }

  private get locationsPosition() {
    return this.getLocationsPositionById(this.formState.locationsPositionId);
  }

  private get employment() {
    return this.getEmploymentById(this.employmentId);
  }

  private get isManagingShiftsAllowed(): boolean {
    return this.canManageShift(
      this.shift?.locationsPosition.location?.id || Number.NaN,
      this.shift?.locationsPosition.id || Number.NaN,
    );
  }

  private get isManagerNoteVisible() {
    return !!this.shift?.id && this.isManagingShiftsAllowed;
  }

  private get isShiftBreaksValid() {
    return this.formState.shiftBreaks.every(breakToValidate => validateBreak({
      breakToValidate: {
        startsAt: new Date(breakToValidate.startsAt),
        endsAt: new Date(breakToValidate.endsAt),
        id: breakToValidate.id,
      },
      shiftStartsAt: this.formState.startsAt,
      shiftEndsAt: this.formState.endsAt,
      allBreaks: this.formState.shiftBreaks.map(shiftBreak => ({
        ...shiftBreak,
        startsAt: new Date(shiftBreak.startsAt),
        endsAt: new Date(shiftBreak.endsAt),
      })),
      timeZone: this.$timeZone.value,
    }));
  }

  private get isQualificationsValid() {
    return !this.formState.shiftQualifications?.some(qualification => (!qualification.count
      || qualification.count > this.formState.workers));
  }

  private get isLocationsPositionIdValid() {
    return this.formState.locationsPositionId !== null
      && !Number.isNaN(this.formState.locationsPositionId)
      && this.selectableLocationsPositions.some(o => o.id === this.formState.locationsPositionId);
  }

  private get isWorkersCountValid() {
    return (
      !Number.isNaN(this.formState.workers)
      && this.formState.workers > 0
    );
  }

  private get isValid() {
    const result = (
      this.isShiftBreaksValid
      && this.isLocationsPositionIdValid
      && this.isWorkersCountValid
      && this.isQualificationsValid
    );

    this.isValidated = !result;

    return result;
  }

  private get isShiftRotationGroupIncluded() {
    return this.currentCompany?.shiftRotationEnabled
      && this.formState.shiftRotationGroupIds?.length;
  }
  // #endregion Getters

  // #region Event Handlers
  protected async onCancelConflictsStaffShift() {
    this.conflictsStaffShift = [];

    this.$emit('submitStateChange', true);

    await executeStoreActionWithFailureSnackbar(
      this,
      {
        id: this.newShiftId,
        // this is used as a rollback, so we can send flag as true always
        deleteParams: {
          deleteConnected: true,
        },
      },
      this.deleteShift,
      'shifts.dialog.error',
    );

    this.$emit('submitStateChange', false);

    this.newShiftId = Number.NaN;
  }

  protected onIgnoreConflictsStaffShift() {
    this.conflictsCopiedShift = [];
    this.tryCreateStaffShift(true);
  }

  protected onCancelConflictsCopiedShift() {
    this.conflictsCopiedShift = [];
    // move to next date in shifts queue skipping current date
    this.copiedShiftsQueue = [...this.copiedShiftsQueue.slice(1)];
    this.tryCopyShift(false);
  }

  protected onIgnoreConflictsCopiedShift() {
    this.conflictsCopiedShift = [];
    this.tryCopyShift(true);
  }

  protected onChange<T extends keyof FormState>({ field, value }: {
    field: T; value: FormState[T];
  }) {
    this.formState[field] = value;
    this.isDirty = true;
  }

  private onSubmit(ignoreAllConflicts = false) {
    if (!this.isValid) {
      return undefined;
    }

    this.conflictsShift = [];

    const { locationsPositionId } = this.formState;

    // this is sorted out by validate already, but has to be checked for type safety
    if (!locationsPositionId) {
      return undefined;
    }

    this.ignoreShiftConflicts = ignoreAllConflicts;

    if (this.shift?.id) {
      this.tryUpdateWithConnectedShift(locationsPositionId);
    } else {
      this.$emit('submitStateChange', true);
      this.tryCreateShift(locationsPositionId);
    }

    return undefined;
  }

  private onIgnoreConflictsShift() {
    this.conflictsShift = [];

    const { locationsPositionId } = this.formState;

    // this is sorted out by validate already, but has to be checked for type safety
    if (!locationsPositionId) {
      return undefined;
    }

    this.ignoreShiftConflicts = true;
    this.$emit('submitStateChange', true);

    if (this.shift?.id) {
      this.tryUpdateShift(locationsPositionId);
    } else {
      this.tryCreateShift(locationsPositionId);
    }

    return undefined;
  }

  protected onCancelUpdateConnected() {
    this.isDialogAssignConnectedOpen = false;
  }

  protected onCancelShiftDisconnect() {
    this.onChange({ field: 'isConnectedShift', value: true });
    this.isDisconnectConfirmDialogOpen = false;
  }

  protected onConfirmShiftDisconnect() {
    this.isDisconnectConfirmDialogOpen = false;
  }

  private onConnectedShiftChanged(payload) {
    // if shift is part of connected group
    // we ignore the connection
    if (this.isShiftRotationGroupIncluded) {
      return;
    }
    if (this.shift?.id) {
      this.isDisconnectConfirmDialogOpen = true;
    }

    this.onChange(payload);
  }

  private onCancelConflictsShift() {
    this.conflictsShift = [];
    this.updateConnected = false;
  }

  protected onConfirmShiftConnected({ payload }: EventPayload<boolean>) {
    const { locationsPositionId } = this.formState;

    // this is sorted out by validate already, but has to be checked for type safety
    if (!locationsPositionId) {
      return undefined;
    }

    this.updateConnected = this.isShiftRotationGroupIncluded ? false : payload;

    this.isDisconnectConfirmDialogOpen = false;
    this.isDialogAssignConnectedOpen = false;

    this.tryUpdateShift(locationsPositionId);

    return undefined;
  }

  // #endregion Event Handlers

  private async tryCreateShift(locationsPositionId) {
    const shift = transformFormStateToCreateInput({
      ...this.formState,
      locationsPositionId,
    });

    const result = await executeStoreActionWithFailureSnackbar(
      this,
      {
        shift: {
          ...shift,
          startsAt: shift.startsAt,
          endsAt: shift.endsAt,
          ignoreConflicts: this.ignoreShiftConflicts,
        },
      },
      this.createShift,
      'shifts.dialog.error',
      'general.error.unknown',
    );

    this.$emit('submitStateChange', false);

    if (result.state === StoreActionState.SUCCESS && result.entityId) {
      this.newShiftId = result.entityId;
      this.copiedShiftsQueue = [...this.formState.repeatDates];
      this.newShiftIds.push(result.entityId);
      return this.tryCreateStaffShift(false);
    }

    if (result.state === StoreActionState.CONFLICT) {
      this.conflictsShift = result.conflicts;
    }

    return undefined;
  }

  protected async tryCopyShift(ignoreConflicts = false) {
    // when inside copying loop we keep submit disabled until queue is empty
    if (!this.copiedShiftsQueue.length) {
      this.isDirty = false;
      this.$emit('createShiftSuccess', this.newShiftIds);
      this.$emit('submitStateChange', false);
      this.$emit('closeClick');
      return;
    }

    this.$emit('submitStateChange', true);

    const [date, ...nextDates] = this.copiedShiftsQueue;

    const result = await executeStoreActionWithFailureSnackbar(
      this,
      {
        copyParams: {
          sourceShiftId: this.newShiftId,
          date: formatISO(date),
          connect: this.formState.isConnectedShift,
          ignoreConflicts,
        },
      },
      this.copyShift,
      'shifts.dialog.error',
      'general.error.unknown',
    );

    if (result.state === StoreActionState.CONFLICT) {
      this.conflictsCopiedShift = result.conflicts;
      return;
    }

    if (result.state === StoreActionState.SUCCESS && result.entityId) {
      this.newShiftIds.push(result.entityId);
    }

    // remove first date from the queue
    // on errors we still want to continue with other shifts
    this.copiedShiftsQueue = nextDates;
    this.tryCopyShift();
  }

  private async tryCreateStaffShift(ignoreConflicts = false) {
    if (!this.employment) {
      this.tryCopyShift(false);
      return;
    }

    this.$emit('submitStateChange', true);

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        staffShift: {
          ignoreConflicts,
          assignToConnected: false,
          employmentId: this.employment.id,
          shiftId: this.newShiftId,
        },
      },
      this.createStaffShift,
      'shifts.dialog.error',
    );

    this.$emit('submitStateChange', false);

    if (response.state === StoreActionState.CONFLICT) {
      this.conflictsStaffShift = response.conflicts;
      return;
    }
    this.conflictsStaffShift = [];
    this.isDirty = false;
    this.tryCopyShift(false);
  }

  private async tryUpdateWithConnectedShift(locationsPositionId) {
    // Updating connected shifts
    // update to other connected shift should show
    // 1. shift is part of any connected group
    // 2. shift is not part of rotation group
    // 3. user has not manually clicked to disconnect shift (isConnectedShift is false)
    if (
      !this.isShiftRotationGroupIncluded
      && !!this.formState.connectedGroupId
      && this.formState.isConnectedShift
    ) {
      this.isDialogAssignConnectedOpen = true;
      return;
    }

    this.tryUpdateShift(locationsPositionId);
  }

  private async tryUpdateShift(locationsPositionId) {
    const shift = transformFormStateToUpdateInput({
      ...this.formState,
      locationsPositionId,
      updateConnected: this.updateConnected,
    });

    this.$emit('submitStateChange', true);

    const result = await executeStoreActionWithFailureSnackbar(
      this,
      {
        shiftId: this.shift?.id || 0,
        shift: {
          ...shift,
          startsAt: shift.startsAt,
          endsAt: shift.endsAt,
          ignoreConflicts: this.ignoreShiftConflicts,
        },
      },
      this.updateShift,
      'shifts.dialog.error',
      'general.error.unknown',
    );

    this.$emit('submitStateChange', false);
    if (result.state === StoreActionState.CONFLICT) {
      this.conflictsShift = result.conflicts;
      return;
    }

    this.isDirty = false;
    if (result.state === StoreActionState.SUCCESS) {
      this.$emit('updateShiftSuccess', {
        shiftId: this.shift?.id,
        withConnected: this.updateConnected,
        connectedGroupId: this.shift?.connectedGroupId,
      });

      // disconnect shift
      // 1. when shift is part of connected group
      // 2. when toggle for connected group is switched off
      // 3. when shift is not part of rotation group
      const disconnect = !!this.formState.connectedGroupId && !this.formState.isConnectedShift;
      if (disconnect && !this.isShiftRotationGroupIncluded) {
        const disconnectResult = await executeStoreActionWithFailureSnackbar(
          this,
          { shiftId: this.shift?.id || 0 },
          this.disconnectShift,
          'shifts.dialog.error',
          'general.error.unknown',
        );

        if (disconnectResult.state === StoreActionState.ERROR) {
          return;
        }
      }

      this.populateShift();
      this.$emit('closeClick');
    }
  }

  protected async populateShift() {
    if (this.shift) {
      this.formState = getFormStateByShift(this.shift, this.shiftplan.id);
      return;
    }

    this.formState = getNewFormState({
      shiftplan: this.shiftplan,
      timeZone: this.$timeZone.value,
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      locationsPositionId: this.locationsPositionId,
    });

    if (this.employmentId) {
      this.isEmploymentLoading = true;

      await this.fetchEmployment({ employmentId: this.employmentId });

      this.isEmploymentLoading = false;
    }

    this.isDirty = false;
  }
  // #endregion Component Methods

  public beforeRouteLeave(_to, _from, next) {
    confirmLeaveIf.call(this, () => this.isDirty, next);
  }

  public mounted() {
    this.populateShift();
  }

  public render() {
    return (
      <Details
        conflictsShift={this.conflictsShift}
        conflictsStaffShift={this.conflictsStaffShift}
        conflictsCopiedShift={this.conflictsCopiedShift}
        employment={this.employment}
        formId={this.formId}
        formState={this.formState}
        isDisabled={!this.isManagingShiftsAllowed || this.isEmploymentLoading}
        isEmploymentLoading={this.isEmploymentLoading}
        isManagerNoteVisible={this.isManagerNoteVisible}
        isUpdate={!!this.shift?.id}
        isValidated={this.isValidated}
        isDialogAssignConnectedOpen={this.isDialogAssignConnectedOpen}
        isDisconnectConfirmDialogOpen={this.isDisconnectConfirmDialogOpen}
        positionNote={this.locationsPosition?.position?.note || undefined}
        selectableLocationsPositions={this.selectableLocationsPositions}
        selectableShiftEvaluationTags={this.selectableShiftEvaluationTags}
        selectableShiftRotationGroups={this.selectableShiftRotationGroups}
        selectableShiftPresets={this.selectableShiftPresets}
        selectableShiftTags={this.selectableShiftTags}
        shiftplan={this.shiftplan}
        selectableQualifications={this.selectableQualifications}
        /* events */
        onCancelConflictsShift={this.onCancelConflictsShift}
        onCancelConflictsStaffShift={this.onCancelConflictsStaffShift}
        onCancelConflictsCopiedShift={this.onCancelConflictsCopiedShift}
        onCancelUpdateConnected={this.onCancelUpdateConnected}
        onCancelShiftDisconnect={this.onCancelShiftDisconnect}
        onConfirmShiftConnected={this.onConfirmShiftConnected}
        onConfirmShiftDisconnect={this.onConfirmShiftDisconnect}
        onConnectedShiftChanged={this.onConnectedShiftChanged}
        onIgnoreConflictsStaffShift={this.onIgnoreConflictsStaffShift}
        onIgnoreConflictsCopiedShift={this.onIgnoreConflictsCopiedShift}
        onIgnoreConflictsShift={this.onIgnoreConflictsShift}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default DetailsContainer;
