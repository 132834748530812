import InputButtonSelect from 'components/form/input-button-select/InputButtonSelect';
import { EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './section-create-kind.css';

export enum CreateShiftplanKind {
  NEW = 'new',
  COPY = 'copy',
  ROTATION = 'rotation',
}

interface Props {
  createShiftplanKind: CreateShiftplanKind;
  isShiftRotationEnabled?: boolean;
}

@Component
export default class SectionCreateKind extends TsxComponent<Props, {
  onChange: (payload: EventPayload<CreateShiftplanKind, HTMLInputElement>) => void;
}> {
  @Prop()
  public createShiftplanKind: Props['createShiftplanKind'];

  @Prop()
  public isShiftRotationEnabled: Props['isShiftRotationEnabled'];

  protected get options() {
    const options = [
      {
        label: this.$t('createShiftplan.modal.buttonNew'),
        value: CreateShiftplanKind.NEW,
      },
      {
        label: this.$t('createShiftplan.modal.buttonCopy'),
        value: CreateShiftplanKind.COPY,
      },
    ];

    if (this.isShiftRotationEnabled) {
      options.push({
        label: this.$t('createShiftplan.modal.buttonRotation'),
        value: CreateShiftplanKind.ROTATION,
      });
    }

    return options;
  }

  protected onChange(payload: EventPayload<CreateShiftplanKind>) {
    this.$emit('change', payload);
  }

  public render() {
    return (
      <section class={styles.sectionCreateKind}>
        <div class={styles.sectionCreateKindLabel}>
          {this.$t('createShiftplan.modal.labelCreateKind')}
        </div>
        <InputButtonSelect
          name="createShiftplanKind"
          options={this.options}
          value={this.createShiftplanKind}
          onChange={this.onChange}
        />
      </section>
    );
  }
}
