import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

export const TOOLTIP_PORTAL_NAME = 'tooltip';

@Component
class TooltipPortalTarget extends TsxComponent<{}> {
  public render() {
    return (
      <portal-target name={TOOLTIP_PORTAL_NAME} multiple>
      </portal-target>
    );
  }
}

export default TooltipPortalTarget;
