import { StoreState as FilterStoreState, createFilterStore } from 'store/filter-store';
import { namespace } from 'vuex-class';

export const tagsFilterNS = namespace('shiftSchedule/tagsFilter');

export type StoreState = FilterStoreState;

const getStore = () => createFilterStore({ namespaced: true });

export default getStore;
