import Absence from 'components/calendar-common/absences/Absence';
import AbsenceDay from 'components/calendar-common/absences/absence-day/AbsenceDay';
import { absencesNS } from 'components/calendar/absences/Store';
import DateItem from 'components/calendar-common/common/DateItem';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { calendarCommonNS } from 'components/calendar/common/Store';
import SpIcon from 'components/sp-icon/SpIcon';
import { AbsenceIcons, AbsenceStates } from '../Store';

@Component
class AbsencesRow extends Vue {
  @Prop()
  public absenceState: AbsenceStates;

  @calendarCommonNS.Getter
  private dates: DateItem[];

  @calendarFiltersNS.Getter('filters')
  private filters: FiltersDictionary;

  @absencesNS.Getter('absencesByDates')
  private absences!: Record<number, { new: Absence[]; accepted: Absence[] }>;

  private get isVisible() {
    return this.absenceState === AbsenceStates.NEW
      ? this.filters.showNewAbsences : this.filters.showAcceptedAbsences;
  }

  private get absenceIcon() {
    return AbsenceIcons[this.absenceState];
  }

  public render() {
    return this.isVisible && (<GridRow
      dates={this.dates}
      scopedSlots= {{
        cell: (scope: GridRowScope) => this.absences[scope.dateItem.dateKey]
            && this.absences[scope.dateItem.dateKey][this.absenceState].map(
              (absence: Absence) => <AbsenceDay absence={absence} key={absence.uniqId}/>,
            ),
      }}>
      <GridCell slot="label"
        class={gridStyles.gridTableLabelCell}
        isHeader={true}>
        {this.$scopedSlots.label
          ? this.$scopedSlots.label({ icon: this.absenceIcon })
          : <SpIcon name={this.absenceIcon}/>}
      </GridCell>
    </GridRow>);
  }
}

export default AbsencesRow as any;
