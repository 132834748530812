import Pagination from 'components/pagination/Pagination';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import SpIcon from 'components/sp-icon/SpIcon';
import CheckboxList from '../filter-box/checkbox-list/CheckboxList';

import styles from './checkbox-list-with-paging-and-filtering.css';

interface Props<T extends {id: number | string}> {
  items: T[];
  selection: T['id'][];
  perPage: number;
  filterKey: keyof T;
  throttleTimeout?: number;
}

interface Events {
  onSelect: EventPayload<(number | string)[], HTMLInputElement, KeyboardEvent | MouseEvent>;
}

interface ScopedSlots<T> {
  item: {
    item: T;
    isSelected: boolean;
    onChange: (
      e: EventPayload<boolean, HTMLInputElement, KeyboardEvent | MouseEvent>,
    ) => void;
  };
}

@Component
class CheckboxListWithPagingAndFiltering<T extends {id: number | string}>
  extends TsxComponent<Props<T>, Events, ScopedSlots<T>> {
  private page = 1;

  private searchString = '';

  @Prop()
  public perPage: Props<T>['perPage'];

  @Prop()
  public filterKey: Props<T>['filterKey'];

  @Prop()
  public items: Props<T>['items'];

  @Prop()
  public selection: Props<T>['selection'];

  @Prop({ default: 0 })
  public throttleTimeout: number;

  private onSelect(
    e: EventPayload<T['id'][], HTMLInputElement, KeyboardEvent | MouseEvent>,
  ) {
    this.$emit('select', e);
  }

  private onSelectAll(e: SyntheticEvent<HTMLInputElement, MouseEvent | KeyboardEvent>) {
    const filtered = this.itemsFiltered.map(({ id }) => id);

    const payload = e.target.checked
      ? [...new Set([...this.selection, ...filtered])]
      : this.selection.filter(id => !filtered.includes(id));

    this.$emit('select', createEventPayload(e, payload));
  }

  protected onPageChange({ payload }: EventPayload<number>) {
    this.page = payload;
  }

  private get isEveryFilteredSelected() {
    return this.itemsFiltered.every(({ id }) => this.selection.includes(id));
  }

  private get itemsFiltered() {
    if (this.searchString.length === 0) {
      return this.items;
    }

    const searchStringLowercased = this.searchString.toLocaleLowerCase();

    return this.items.filter(
      ({ [this.filterKey]: name }) => (
        (name as unknown as string)
          .toLocaleLowerCase()
          .includes(searchStringLowercased)
      ),
    );
  }

  private get itemsFilteredPaginated() {
    const startIndex = (this.page - 1) * this.perPage;
    const endIndex = this.page * this.perPage;

    return this.itemsFiltered.slice(startIndex, endIndex);
  }

  private get pages() {
    return Math.ceil(this.itemsFiltered.length / this.perPage);
  }

  public render() {
    return (
      <div class={styles.checkboxListWithPagingAndFiltering}>
        <div class={styles.checkboxListWithPagingAndFilteringTopBar}>
          <label class={styles.checkboxListWithPagingAndFilteringSelectAll}>
            <input
              checked={this.isEveryFilteredSelected ? 'checked' : undefined}
              onChange={e => this.onSelectAll(e)}
              type="checkbox"
            />

            {this.selection.length}/{this.itemsFiltered.length}
          </label>

          <div class={styles.checkboxListWithPagingAndFilteringSearchContainer}>
            <SpIcon class={styles.checkboxListWithPagingAndFilteringIcon} name="search" />

            <input
              class={styles.checkboxListWithPagingAndFilteringSearchInput}
              onInput={(e: SyntheticEvent<HTMLInputElement, KeyboardEvent>) => {
                this.page = 1;
                this.searchString = e.target.value;
              }}
              placeholder={this.$t('shiftSchedule.filter.tags.buttonSearch')}
              type="search"
              value={this.searchString}
            />
          </div>
        </div>

        <CheckboxList
          items={this.itemsFilteredPaginated}
          onChange={this.onSelect}
          scopedSlots={{ item: this.$scopedSlots.item }}
          selection={this.selection}
          throttleTimeout={this.throttleTimeout}
        />

        {this.pages > 1 && (
          <Pagination
            class={styles.checkboxListWithPagingAndFilteringPagination}
            length={this.pages}
            onClick={this.onPageChange}
            selected={this.page}
          />
        )}
      </div>
    );
  }
}

export default CheckboxListWithPagingAndFiltering;
