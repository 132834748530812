import Dialog, { Slot } from 'components/dialog/Dialog';
import FormDialog from 'components/form/form-dialog/FormDialog';
import SnackbarAction from 'components/snackbar/store/Action';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { redirectToNamedParent } from 'src/utils/route';
import { GQLShiftplanPublishInput } from 'codegen/gql-types';
import ShiftPlanAction from 'store/shiftplans/Action';
import { shiftplansNS } from 'src/store/shiftplans/Store';
import type {
  PublishShiftplanFunction,
  FetchShiftplanFunction,
} from 'src/store/shiftplans/Store';
import {
  executeStoreActionWithFailureSnackbar,
  StoreActionResult,
  StoreActionState,
} from 'src/utils/store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { snackbarNS } from 'components/snackbar/store/Store';
import { Action } from 'store/normalized-store';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { AlertKind } from 'components/alert/Alert';
import Publish from './DialogShiftplanPublish';
import { ButtonColor, ButtonKind } from '../form/base-button/types';

export interface Props {
  shiftplanId: number;
}

export const getInitialFormState = (): GQLShiftplanPublishInput => ({
  publishWithEmail: false,
  sendMessage: false,
  message: '',
  sendAssignedShifts: false,
  sendOpenShifts: false,
});

@Component
export default class DialogShiftplanPublishContainer extends TsxComponent<
Props,
{}
> {
  protected isSubmitting = false;

  protected formId = 'dialog-shitplan-publish';

  protected formState = getInitialFormState();

  @Prop()
  protected shiftplanId: Props['shiftplanId'];

  @shiftplansNS.Action(ShiftPlanAction.PUBLISH)
  protected publishShiftplan: PublishShiftplanFunction;

  @shiftplansNS.Action(Action.FETCH)
  protected fetchShiftplan: FetchShiftplanFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  protected onCloseClick() {
    return !this.isSubmitting && redirectToNamedParent(this);
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit(event) {
    event.preventDefault();
    this.isSubmitting = true;
    const response: StoreActionResult = await executeStoreActionWithFailureSnackbar(
      this,
      {
        id: this.shiftplanId,
        input: this.formState,
      },
      this.publishShiftplan,
      '',
    );
    this.isSubmitting = false;
    if (response.state !== StoreActionState.SUCCESS) {
      return;
    }
    this.showSnackbar({
      message: this.$t('publishShift.modal.published'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
    this.onCloseClick();
    this.fetchShiftplan({ id: this.shiftplanId });
  }

  public render() {
    return (
      <Dialog
        isOpen={true}
        onCloseClick={this.onCloseClick}
        isCloseButtonDisabled={this.isSubmitting}
        title={this.$t('publishShift.modal.title')}
      >
        <FormDialog id={this.formId} onSubmit={this.onSubmit}>
          <Publish formState={this.formState} onInput={this.onInput} />
        </FormDialog>

        <Button
          disabled={this.isSubmitting}
          color={ButtonColor.SECONDARY}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
          slot={Slot.BUTTONS_LEFT}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        <Button
          disabled={this.isSubmitting}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
          form={this.formId}
          color={ButtonColor.PRIMARY}
          type="submit"
          slot={Slot.BUTTONS_RIGHT}
        >
          {this.$t('publishShift.modal.buttonPublish')}
        </Button>
      </Dialog>
    );
  }
}
