import AbsencesRow from 'components/calendar-common/absences/absences-row/AbsencesRow';
import { AbsenceStates } from 'components/calendar-common/absences/Store';
import DateItem from 'components/calendar-common/common/DateItem';
import Observable from 'components/calendar-common/common/observable/Observable';
import PaginationMixin from 'components/calendar-common/common/pagination-mixin/PaginationMixin';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import GridMixin, { GridType } from 'components/calendar-common/grid/GridMixin';
import DayNotesContainer from 'components/calendar-common/notes/day-notes-container/DayNotesContainer';
import SpecialDatesContainer from 'components/calendar-common/notes/special-dates-container/SpecialDatesContainer';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { shiftsNS } from 'components/calendar-common/shifts/store/Store';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { Component, Mixins } from 'vue-property-decorator';
import { Position } from 'components/calendar-common/positions/Position';
import { positionsNS } from 'components/calendar-common/positions/Store';
import HeaderDays from 'components/calendar/grid/header-days/HeaderDays';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import Row from './Row';

@Component({
  directives: {
    observable: Observable,
  },
})
class PositionsDays extends Mixins(ShiftViewActionsMixin, GridMixin, PaginationMixin) {
  protected gridType: GridType = GridType.WEEK;

  @calendarCommonNS.Getter('dates')
  public dates: DateItem[];

  @shiftsNS.Getter('shiftsByDates')
  public shiftsByDates: Record<string, Shift[]>;

  @shiftsNS.Getter('shiftsByPositions')
  public shifts: Record<number, Record<string, Shift[]>>;

  @positionsNS.Getter('filteredPositions')
  public positions: Position[];

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  protected get paginatedItems() {
    return this.positions;
  }

  public render() {
    const shifts = { ...this.shifts };
    return (
      <div class={gridStyles.gridTable}
        style={this.gridStyle}>
        <HeaderDays
          shiftsByDates={this.shiftsByDates}
          dates={this.dates}
          onClick={(e, { dateItem }) => this.onShiftCellClick(dateItem)}>
          {this.$t('calendar.titlePosition')}
        </HeaderDays>
        <SpecialDatesContainer showLabelText={true} dates={this.dates}/>
        <DayNotesContainer showLabelText={true} dates={this.dates}/>
        <AbsencesRow absenceState={AbsenceStates.NEW}
          scopedSlots={{
            label: () => this.$t('absence.state.new'),
          }}/>
        <AbsencesRow absenceState={AbsenceStates.ACCEPTED}
          scopedSlots={{
            label: () => this.$t('absence.state.accepted'),
          }}
        />
        {
          this.visibleItems.map((position, index) => {
            const isLastRow = (this.positions.length - 1) === index;
            const positionShifts = shifts[position.locationsPositionId];
            return <Row
              onDrop={(...args) => this.$emit('drop', ...args)}
              isVisible={this.visibleIds.includes(position.locationsPositionId)}
              data-id={position.locationsPositionId}
              v-observable={this.observer}
              isLastRow={isLastRow}
              position={position}
              dates={this.dates}
              shiftsByDates={positionShifts}
              showSummary={this.filters.showSummary}/>;
          })}
        {this.renderShowMore()}
      </div>);
  }
}

export default PositionsDays as any;
