import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { Shiftplan } from 'src/store/shiftplans/Store';
import { EventPayload } from 'utils/events';
import styles from './controls.css';
import TimeframeControl from '../timeframe-control/TimeframeControl';
import { TimeframeKind } from '../../calendar-common/Enums';
import { SelectedTimeframe } from '../../datepicker/types';

interface Props {
  timeframeKind: TimeframeKind;
  timeframe: SelectedTimeframe;
  shiftplan?: Shiftplan;
  isDatepickerOpen: boolean;
  isIntervalSelectPending?: boolean;
}

interface Events {
  onDatepickerCancel: EventPayload<void>;
  onDatepickerToggle: EventPayload<boolean>;
  onDatepickerChange: EventPayload<SelectedTimeframe, HTMLButtonElement, MouseEvent>;
}

export enum Slot {
  RIGHT_CONTROLS_GROUP = 'rightControlsGroup',
  LEFT_CONTROLS_GROUP = 'leftControlsGroup'
}

@Component
export default class Controls extends TsxComponent<Props, Events> {
  @Prop()
  private timeframe: Props['timeframe'];

  @Prop()
  private timeframeKind: Props['timeframeKind'];

  @Prop()
  private shiftplan: Props['shiftplan'];

  @Prop()
  private isDatepickerOpen: Props['isDatepickerOpen'];

  @Prop({ default: false })
  private isIntervalSelectPending: NonNullable<Props['isIntervalSelectPending']>;

  public render() {
    return (
      <div class={styles.controls}>
        <div class={styles.controlsGroup}>
          {this.$slots[Slot.LEFT_CONTROLS_GROUP]}
        </div>
        <TimeframeControl
          timeframe={this.timeframe}
          timeframeKind={this.timeframeKind}
          shiftplan={this.shiftplan}
          isIntervalSelectPending={this.isIntervalSelectPending}
          onDatepickerToggle={(eventPayload) => { this.$emit('datepickerToggle', eventPayload); }}
          onCancel={() => {
            this.$emit('datepickerCancel');
          }}
          onChange={(eventPayload) => {
            this.$emit('datepickerChange', eventPayload);
          }}
          isDatepickerOpen={this.isDatepickerOpen}/>

        <div class={styles.controlsGroup}>
          {this.$slots[Slot.RIGHT_CONTROLS_GROUP]}
        </div>
      </div>
    );
  }
}
