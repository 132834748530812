import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './loading-indicator.css';

@Component
export default class LoadingIndicator extends TsxComponent<{}> {
  public render() {
    return (
      <div
        aria-valuemin="0"
        aria-valuemax="100"
        class={styles.loadingIndicator}
        role="progressbar"
      />
    );
  }
}
