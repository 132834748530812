import { ObservableQuery } from 'apollo-client';
import DefaultActions from 'components/calendar-common/DefaultActions';
import { FiltersDictionary } from 'components/calendar-common/filters/Store';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import RootStoreState from 'src/store/RootStoreState';
import { GraphqlClient } from 'services/graphql-client/GraphqlClientFactory';
import {
  GQLCalendarDataQuery, GQLCalendarEventsQuery,
  GQLCalendarDataQueryVariables, GQLCalendarEventsQueryVariables,
  GQLCalendarShiftsQuery, GQLCalendarShiftsQueryVariables,
  GQLCalendarAbsencesQuery, GQLCalendarAbsencesQueryVariables,
  GQLCalendarEmploymentsQuery, GQLCalendarEmploymentsQueryVariables,
  GQLCalendarLocationsPositionsQuery, GQLCalendarLocationsPositionsQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { SentryTag } from 'services/logger/SentryTransport';
import CalendarAbsencesGql from 'components/calendar-common/data/queries/CalendarAbsences.gql';
import CalendarEmploymentsGql from 'components/calendar-common/data/queries/CalendarEmployments.gql';
import CalendarLocationsPositionsGql from 'components/calendar-common/data/queries/CalendarLocationsPositions.gql';
import CalendarShiftsGql from 'components/calendar-common/data/queries/CalendarShifts.gql';
import { max, min } from 'date-fns';
import CalendarEventsGql from './queries/CalendarEvents.gql';
import CalendarDataGql from './queries/CalendarData.gql';
import Mutations from './Mutations';
import * as Actions from './Actions';
import { CalendarNamespace, LoadState, Mode } from '../../calendar-common/Enums';
import NotesAction from '../../calendar-common/notes/Action';
import { deepTransformDates } from '../../../services/graphql-client/DatesTransformLink';
import { endOf, startOf, Unit } from '../../../utils/date-related';
import VueTimeZoneProvider from '../../../services/vue-time-zone-provider/VueTimeZoneProvider';

export const calendarDataNS = namespace('calendar/data');

export const UPDATE_THROTTLE_TIME = 1000;

interface RequestParams {
  shiftplanId: number;
}

export interface StoreState {
  loadState: LoadState;
  requestParams: RequestParams | null;
  dataQuery: ObservableQuery<GQLCalendarDataQuery, GQLCalendarDataQueryVariables> | null;
  eventsQueryRequestUid: string;
  dataQueryRequestUid: string;
}

const INVALIDATED_REQUEST_UID = NIL_UUID;

const getAllEmployments = (employments, shifts) => {
  const employmentsMap = new Map(employments.map(it => [it.id, it]));

  shifts.forEach(({ staffShifts }) => {
    staffShifts.forEach(({ employment }) => {
      if (employment !== null) {
        employmentsMap.set(employment.id, employment);
      }
    });
  });

  return [...employmentsMap.values()];
};

export type LoadShiftsFunction = (payload: {
  shiftIds?: number[],
  connectedGroupId?: number,
}) => Promise<void>;

export const store = (
  client: GraphqlClient,
  logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    loadState: LoadState.NOT_LOADED,
    requestParams: null,
    dataQuery: null,
    eventsQueryRequestUid: INVALIDATED_REQUEST_UID,
    dataQueryRequestUid: INVALIDATED_REQUEST_UID,
  },
  mutations: {
    [Mutations.SET_LOAD_STATE](state, loadState: LoadState) {
      state.loadState = loadState;
    },
    [Mutations.SET_DATA_QUERY](state, query) {
      state.dataQuery = query;
    },
    [Mutations.SET_EVENTS_QUERY_REQUEST_UID](state, uid: string) {
      state.eventsQueryRequestUid = uid;
    },
    [Mutations.SET_DATA_QUERY_REQUEST_UID](state, uid: string) {
      state.dataQueryRequestUid = uid;
    },
  },
  getters: {
    // for now we're limited by shiftplan bounds
    // so we don't need to load anything outside
    // shiftplan
    currentInterval(state, getters, rootState, rootGetters) {
      const { shiftplan } = rootState.calendar.common;
      const visibleInterval = rootGetters['calendar/common/currentInterval'];
      const { startsAt, endsAt } = shiftplan || visibleInterval;
      return {
        start: max([
          visibleInterval.start,
          startOf(new Date(startsAt), Unit.DAY, VueTimeZoneProvider.getTimeZone()),
        ]),
        end: min([
          visibleInterval.end,
          endOf(new Date(endsAt), Unit.DAY, VueTimeZoneProvider.getTimeZone()),
        ]),
      };
    },
    locationsPositionIds(state, getters, rootState, rootGetters) {
      const locationsPositionIds = rootGetters[
        `${CalendarNamespace.CALENDAR}/filters/locationsPositionIds`
      ];
      return !Array.isArray(locationsPositionIds) || locationsPositionIds.length === 0
        ? undefined
        : locationsPositionIds;
    },
    shiftPresetIds(state, getters, rootState) {
      const { selection } = rootState.shiftSchedule.shiftPresetsFilter;
      const selectionWithoutCustomPreset = selection.filter(id => id !== -1);

      return selectionWithoutCustomPreset.length === 0
        ? undefined : selectionWithoutCustomPreset;
    },
    tagIds(state, getters, rootState) {
      const { selection } = rootState.shiftSchedule.tagsFilter;
      return selection.length === 0
        ? undefined : selection;
    },
    employmentsSearchQuery(state, getters, rootState) {
      const { searchQuery } = rootState.calendar.employments;
      const trimmedQuery = searchQuery.trim();
      return trimmedQuery.length === 0 ? undefined : trimmedQuery;
    },
  },
  actions: {
    async [Actions.LOAD_DATA]({
      dispatch, commit, state, rootState, getters,
    }) {
      const { currentCompany } = rootState.auth;

      if (!currentCompany) {
        return;
      }
      //  You should already have current interval and shiftplan set
      // up in common store
      const {
        shiftplan,
      } = rootState.calendar.common;
      const {
        shiftRotationEnabled: isShiftRotationEnabled,
      } = currentCompany;
      const { employmentsSearchQuery } = getters;

      if (!shiftplan) {
        return;
      }

      const variables: GQLCalendarDataQueryVariables = {
        companyId: shiftplan.companyId,
        locationId: shiftplan.locationId,
        search: employmentsSearchQuery,
        shouldFetchShiftRotationGroupIds: !!isShiftRotationEnabled,
      };

      const requestUid: string = uuidv4();

      // mark ongoing requests as stale
      commit(Mutations.SET_EVENTS_QUERY_REQUEST_UID, INVALIDATED_REQUEST_UID);
      commit(Mutations.SET_DATA_QUERY_REQUEST_UID, requestUid);
      commit(Mutations.SET_LOAD_STATE, LoadState.LOADING);

      const result = await client.query<GQLCalendarDataQuery, GQLCalendarDataQueryVariables>(
        {
          variables,
          query: CalendarDataGql,
        },
      );

      // check if request is stale
      if (requestUid !== state.dataQueryRequestUid) {
        return;
      }
      const {
        employments: { items: employments },
        locationsPositions: { items: locationsPositions },
      } = result.data;
      dispatch(
        `calendar/employments/${DefaultActions.SET}`,
        employments,
        { root: true },
      );
      dispatch(`calendar/positions/${DefaultActions.SET}`, locationsPositions, { root: true });
      dispatch(Actions.LOAD_EVENTS);
    },

    async [Actions.LOAD_EVENTS](
      {
        dispatch,
        commit,
        rootState,
        rootGetters,
        getters,
        state,
      },
    ) {
      const { currentCompany, currentEmploymentId } = rootState.auth;

      if (!currentCompany || !currentEmploymentId) {
        return;
      }

      const {
        shiftplan,
      } = rootState.calendar.common;
      const {
        isTagsAllowed: isShiftTagsEnabled,
        assignmentGroupEnabled: isShiftAssignmentGroupsEnabled,
        shiftRotationEnabled: isShiftRotationsEnabled,
      } = currentCompany;

      const {
        currentInterval,
        employmentsSearchQuery,
        shiftPresetIds,
        tagIds,
        locationsPositionIds,
      } = getters;
      const employments = rootGetters['calendar/employments/employments'];
      const filters: FiltersDictionary = rootGetters['calendar/filters/filters'];
      const mode: Mode = rootGetters['calendar/common/mode'];
      const { start, end }: { start: Date; end: Date } = currentInterval;

      if (!shiftplan) {
        return;
      }

      const withOpenShiftsWithoutConflicts = filters
        .showShiftsWithoutConflicts && mode === Mode.EMPLOYEE;
      const isLocationsPositionsFilterDisabled = !Array.isArray(locationsPositionIds)
        || locationsPositionIds.length === 0;
      // fetch open shifts
      const withOpenShifts = ((employmentsSearchQuery !== undefined)
        && !isLocationsPositionsFilterDisabled);
      const employmentIds = employmentsSearchQuery
        ? employments.map(it => it.id)
        : undefined;
      // employment id is used to fetch shifts without conflicts for current employee
      const employmentId = withOpenShiftsWithoutConflicts
        ? currentEmploymentId : null;
      // if one of filters is empty array - we don't need to load any shifts at all
      const withShifts = (
        // check that any employment is not filtered away
        !Array.isArray(employmentIds) || employmentIds.length > 0
      )
        // fetch shifts if withoutConflicts filter is not set
        // or if withoutConflicts filter is set together with myShifts
        && (
          !withOpenShiftsWithoutConflicts
          || (withOpenShiftsWithoutConflicts && filters.showOnlyMineShifts)
        );

      const variables: GQLCalendarEventsQueryVariables = deepTransformDates({
        shiftPresetIds,
        tagIds,
        withoutShiftPresets: filters.showShiftsWithoutPreset,
        withoutTags: filters.showShiftsWithoutTags,
        companyId: shiftplan.companyId,
        locationId: shiftplan.locationId,
        shiftplanId: shiftplan.id,
        startsAt: start,
        endsAt: end,
        locationsPositionIds,
        // without conflicts
        withOpenShifts: withOpenShifts || withOpenShiftsWithoutConflicts,
        withoutConflicts: withOpenShiftsWithoutConflicts,
        withShifts,
        withAbsences: filters.showAcceptedAbsences || filters.showNewAbsences,
        employmentId,
        withSpecialDates: filters.showSpecialDates,
        employmentIds,
        shouldFetchShiftRotationGroupIds: !!isShiftRotationsEnabled,
        shouldFetchShiftTags: isShiftTagsEnabled,
        shouldFetchShiftAssignmentGroups: !!isShiftAssignmentGroupsEnabled,
      });

      const requestUid: string = uuidv4();
      commit(Mutations.SET_EVENTS_QUERY_REQUEST_UID, requestUid);
      commit(Mutations.SET_LOAD_STATE, LoadState.LOADING);
      const result = await client.query<GQLCalendarEventsQuery, GQLCalendarEventsQueryVariables>(
        {
          variables,
          query: CalendarEventsGql,
        },
      );

      // check if request is stale
      if (requestUid !== state.eventsQueryRequestUid) {
        return;
      }

      const {
        shifts: { items: shifts = [] } = {},
        absences: { items: absences = [] } = {},
        openShifts: { items: openShifts = [] } = {},
        specialDates: { items: specialDates = [] } = {},
      } = result.data;
      // TODO: fix graphql types to be stricter
      const getMapTuple = <T>(item: T): [number, T] => [(item as any).id, item];
      const mergedShifts: GQLCalendarEventsQuery['shifts']['items'] = [...shifts || [], ...openShifts || []];
      const allShifts = [...new Map(mergedShifts.map(getMapTuple)).values()];
      dispatch(`calendar/absences/${DefaultActions.SET}`, absences, { root: true });
      dispatch(`calendar/shifts/${DefaultActions.SET}`, allShifts, { root: true });
      dispatch(`calendar/notes/${NotesAction.SET_SPECIAL_DATES}`, specialDates, { root: true });

      /*
        for old shiftplans employments can change, as some
        employments used in the past can be unassigned from current positions
      */
      dispatch(
        `calendar/employments/${DefaultActions.SET}`,
        getAllEmployments(rootState.calendar.employments.items, allShifts),
        { root: true },
      );
      // wait for render cycle to complete before removing placeholder
      Vue.nextTick(() => {
        commit(Mutations.SET_LOAD_STATE, LoadState.LOADED);
      });
    },

    async [Actions.LOAD_SHIFTS](
      {
        dispatch, rootState,
      },
      {
        shiftIds,
        connectedGroupId,
      }: Parameters<LoadShiftsFunction>[0],
    ) {
      const { currentCompany } = rootState.auth;

      if (!currentCompany) {
        return;
      }

      const {
        isTagsAllowed: isShiftTagsEnabled,
        assignmentGroupEnabled: isShiftAssignmentGroupsEnabled,
      } = currentCompany;

      const {
        shiftplan,
      } = rootState.calendar.common;

      if (!shiftplan) {
        return;
      }

      try {
        const response = await client.query<
        GQLCalendarShiftsQuery, GQLCalendarShiftsQueryVariables>(
          {
            variables: {
              companyId: shiftplan.companyId,
              shiftplanId: shiftplan.id,
              locationId: shiftplan.locationId,
              ids: shiftIds || null,
              connectedGroupId: connectedGroupId || null,
              shouldFetchShiftRotationGroupIds: false,
              shouldFetchShiftTags: isShiftTagsEnabled,
              shouldFetchShiftAssignmentGroups: isShiftAssignmentGroupsEnabled,
            },
            query: CalendarShiftsGql,
          },
        );
        const {
          shifts: { items: shifts },
        } = response.data;
        dispatch(`calendar/shifts/${DefaultActions.UPDATE}`, shifts, { root: true });
      } catch (e) {
        logger.instance.error({
          message: 'failed to update shifts',
          tags: [[SentryTag.ACTION, Actions.LOAD_SHIFTS]],
          error: e,
        });
      }
    },

    async [Actions.LOAD_LOCATIONSPOSITIONS](
      { dispatch, rootState },
      locationsPositionsIds: number[],
    ) {
      const { shiftplan } = rootState.calendar.common;
      if (!shiftplan) {
        return;
      }
      try {
        const response = await client
          .query<GQLCalendarLocationsPositionsQuery,
        GQLCalendarLocationsPositionsQueryVariables>(
          {
            variables: {
              companyId: shiftplan.companyId,
              locationId: shiftplan.locationId,
              shiftplanId: shiftplan.id,
              ids: locationsPositionsIds,
            },
            query: CalendarLocationsPositionsGql,
          },
        );
        const {
          locationsPositions: { items: locationsPositions },
        } = response.data;
        dispatch(
          `calendar/positions/${DefaultActions.UPDATE}`,
          locationsPositions,
          { root: true },
        );
      } catch (e) {
        logger.instance.error({
          message: 'failed to locations positions',
          tags: [[SentryTag.ACTION, Actions.LOAD_LOCATIONSPOSITIONS]],
          error: e,
        });
      }
    },

    async [Actions.LOAD_EMPLOYMENTS](
      { dispatch, rootState },
      employmentsIds: number[],
    ) {
      const { shiftplan } = rootState.calendar.common;
      if (!shiftplan) {
        return;
      }
      try {
        const response = await client.query<GQLCalendarEmploymentsQuery,
        GQLCalendarEmploymentsQueryVariables>(
          {
            variables: {
              companyId: shiftplan.companyId,
              locationId: shiftplan.locationId,
              ids: employmentsIds,
              // TODO: add shift rotations support
              shouldFetchShiftRotationGroupIds: false,
            },
            query: CalendarEmploymentsGql,
          },
        );
        const {
          employments: { items: employments },
        } = response.data;
        dispatch(
          `calendar/employments/${DefaultActions.UPDATE}`,
          employments,
          { root: true },
        );
      } catch (e) {
        logger.instance.error({
          message: 'failed to update employments',
          tags: [[SentryTag.ACTION, Actions.LOAD_EMPLOYMENTS]],
          error: e,
        });
      }
    },

    async [Actions.LOAD_ABSENCES](
      { dispatch, rootState },
      absenceIds: number[],
    ) {
      const { shiftplan } = rootState.calendar.common;
      if (!shiftplan) {
        return;
      }
      try {
        const response = await client.query<GQLCalendarAbsencesQuery,
        GQLCalendarAbsencesQueryVariables>(
          {
            variables: {
              companyId: shiftplan.companyId,
              locationId: shiftplan.locationId,
              ids: absenceIds,
              // TODO: add shift rotations support
              shouldFetchShiftRotationGroupIds: false,
            },
            query: CalendarAbsencesGql,
          },
        );
        const {
          absences: { items: absences },
        } = response.data;
        dispatch(
          `calendar/absences/${DefaultActions.UPDATE}`,
          absences,
          { root: true },
        );
      } catch (e) {
        logger.instance.error({
          message: 'failed to update absences',
          tags: [[SentryTag.ACTION, Actions.LOAD_ABSENCES]],
          error: e,
        });
      }
    },
  },
});
