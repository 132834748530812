import type { GQLEmploymentEvaluationTagFragmentFragment } from 'codegen/gql-types';
import { GQLEvaluation } from 'store/evaluations/Store';

export interface Evaluation extends GQLEvaluation {
  note: string;
  totalPayment: number;
  evaluationTags?: GQLEmploymentEvaluationTagFragmentFragment[];
}

export enum EvaluationContext {
  COMPANY,
  EMPLOYMENT,
}
