import { GQLPaygradeLevel } from 'codegen/gql-types';
import FormDialog from 'components/form/form-dialog/FormDialog';
import { AlertKind } from 'components/alert/Alert';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { Action } from 'store/normalized-store';
import { PaygradeType, paygradeTypesNS } from 'store/paygrade-types/Store';
import type { FetchAllPaygradeTypesFunction } from 'store/paygrade-types/Store';
import { shiftPaygradesNS } from 'src/store/shift-paygrades/Store';
import type {
  CreateShiftPaygradeFunction,
  DeleteShiftPaygradeFunction,
  FetchAllShiftPaygradesFunction,
  ShiftPaygrade,
  UpdateShiftPaygradeFunction,
} from 'src/store/shift-paygrades/Store';
import type { Shift } from 'store/shifts/Store';
import { hasAlreadyStarted } from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import {
  executeStoreActionWithFailureSnackbar,
  StoreActionState,
} from 'utils/store';
import type {
  GetMultipleById,
  StoreActionResult,
} from 'utils/store';
import { confirmLeaveIf, redirectToParentIf } from 'utils/route';
import { uniqBy } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { Option } from 'components/select-panel/SelectPanel';
import PaygradesPlaceholder from './PaygradesPlaceholder';
import Section, { Pay } from './Section';
import {
  hasParentPaygrade,
  hasPaymentManageRight,
  hasPaymentViewRight,
  isBonusPaygrade,
  isShiftPaygrade,
  transformToPay,
} from './utils';
import styles from './paygrades.css';

interface Props {
  shift?: Shift;
}

interface Events {
  onRefetchShift: () => void;
}

@Component
export default class Paygrades extends TsxComponent<Props, Events> {
  protected toCreateIdCounter = Number.MIN_SAFE_INTEGER;

  protected isLoading = false;

  protected toCreate: Pay[] = [];

  protected toRemove: Pay[] = [];

  protected toUpdate: Pay[] = [];

  @paygradeTypesNS.Action(Action.FETCH_ALL)
  protected fetchAllPaygradeTypes: FetchAllPaygradeTypesFunction;

  @paygradeTypesNS.Getter('bonuses')
  protected typesBonus: PaygradeType[];

  @paygradeTypesNS.Getter('regular')
  protected typesRegular: PaygradeType[];

  @shiftPaygradesNS.Action(Action.CREATE)
  protected createShiftPaygrade: CreateShiftPaygradeFunction;

  @shiftPaygradesNS.Action(Action.DELETE)
  protected deleteShiftPaygrade: DeleteShiftPaygradeFunction;

  @shiftPaygradesNS.Action(Action.UPDATE)
  protected updateShiftPaygrade: UpdateShiftPaygradeFunction;

  @shiftPaygradesNS.Action(Action.FETCH_ALL)
  protected fetchAllShiftPaygrades: FetchAllShiftPaygradesFunction;

  @shiftPaygradesNS.Getter('getByShiftId')
  protected getPaygradesByShiftId: GetMultipleById<ShiftPaygrade>;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public shift?: Shift;

  protected get hasShiftAlreadyStarted() {
    return hasAlreadyStarted(this.shift);
  }

  protected get hasPaymentManageRight() {
    const shiftLocationsPositionId = this.shift?.locationsPosition.id || 0;
    const shiftLocationId = this.shift?.locationsPosition?.location?.id || 0;

    return hasPaymentManageRight({ shiftLocationsPositionId, shiftLocationId, store: this.$store });
  }

  protected get hasPaymentViewRight() {
    const shiftLocationsPositionId = this.shift?.locationsPosition.id || 0;
    const shiftLocationId = this.shift?.locationsPosition?.location?.id || 0;

    return hasPaymentViewRight({ shiftLocationsPositionId, shiftLocationId, store: this.$store });
  }

  protected get isPaygradeAddible() {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return this.typesRegular.length !== this.inherited?.length + this.individual?.length;
  }

  protected get paygrades() {
    if (this.shift) {
      const toRemove = new Set(this.toRemove.map(({ id }) => id));

      return this.getPaygradesByShiftId(this.shift.id)
        .filter(({ id }) => !toRemove.has(id))
        .map(transformToPay) || [];
    }

    return [];
  }

  protected get inherited(): Pay[] {
    return this.paygrades.filter(o => !isShiftPaygrade(o) && !isBonusPaygrade(o));
  }

  protected get individual() {
    return uniqBy([
      ...this.paygrades.filter(isShiftPaygrade),
      ...this.toUpdate.filter(o => !hasParentPaygrade(o)),
      ...this.toCreate.filter(o => !hasParentPaygrade(o)),
    ], 'id', true);
  }

  protected get bonuses() {
    return uniqBy([
      ...this.paygrades.filter(isBonusPaygrade),
      ...this.toUpdate.filter(hasParentPaygrade),
      ...this.toCreate.filter(hasParentPaygrade),
    ], 'id', true);
  }

  protected get optionsBonuses(): Option<number>[] {
    return this.typesBonus.map(type => ({
      label: `${type.name}, ${this.$t(`shifts.paygrades.${type.payType}`)}`,
      value: type.id,
    }));
  }

  protected get optionsRegular(): Option<number>[] {
    // FAQ: inherited paygrade may be overridden in shift - only count individual paygrades as used
    const typesAlreadyUsed = new Set(this.individual.map(o => o.typeId));

    return this.typesRegular.map(type => ({
      isDisabled: typesAlreadyUsed.has(type.id),
      label: `${type.name}, ${this.$t(`shifts.paygrades.${type.payType}`)}`,
      value: type.id,
    }));
  }

  protected onAddPaygradeClick({ payload: parent }: EventPayload<Pay | undefined>) {
    this.toCreate.push({
      id: this.toCreateIdCounter,
      level: parent?.id ? GQLPaygradeLevel.PAYGRADE : GQLPaygradeLevel.SHIFT,
      parentId: parent?.id,
      typeId: 0,
      value: 0,
      children: [],
    });

    this.toCreateIdCounter += 1;
  }

  protected onChange({ payload }: EventPayload<Pay>) {
    const isUpdate = payload.id > 0;

    let paygrade = isUpdate
      ? this.toUpdate.find(({ id }) => id === payload.id)
      : this.toCreate.find(({ id }) => id === payload.id);

    if (!isUpdate && !paygrade) {
      // something must have gone wrong... stop here
      return;
    }

    if (!paygrade) {
      paygrade = { ...payload };
      this.toUpdate.push(paygrade);
    }

    paygrade.value = +(payload.value || 0);
    paygrade.typeId = +payload.typeId;
  }

  protected onRemovePaygradeClick({ payload: paygrade }: EventPayload<Pay>) {
    const isUpdate = paygrade.id > 0;

    if (!isUpdate) {
      this.toCreate = this.toCreate.filter(({ id }) => id !== paygrade.id);
      return;
    }

    this.toRemove.push(paygrade);

    // FAQ: exclude from toUpdate the paygrade which was just removed and any bonus paygrade of
    // that parent. We don't need to update a bonus paygrade if the parent will be removed anyway
    this.toUpdate = this.toUpdate.filter(o => (
      o.id !== paygrade.id && o.parentId !== paygrade.id
    ));

    // FAQ: exclude from toRemove any bonus paygrade of the paygrade which was just removed
    // If the parent is removed, the API automatically removes any bonus paygrade of that parent
    this.toRemove = this.toRemove.filter(o => o.parentId !== paygrade.id);
  }

  protected async executeRemove(shiftId: number) {
    const results: StoreActionResult[] = await Promise.all(
      this.toRemove.map(({ id }) => executeStoreActionWithFailureSnackbar(
        this,
        { shiftId, id },
        this.deleteShiftPaygrade,
        'shifts.paygrades.error',
      )),
    );

    // keep failed
    this.toRemove = this.toRemove.filter((_, index) => (
      results[index].state !== StoreActionState.SUCCESS
    ));
  }

  protected async executeUpdate(shiftId: number) {
    const results: StoreActionResult[] = await Promise.all(
      this.toUpdate.map(paygrade => executeStoreActionWithFailureSnackbar(
        this,
        {
          shiftId,
          id: paygrade.id,
          paygrade: {
            parentPaygradeId: paygrade.parentId || null,
            paygradeTypeId: paygrade.typeId,
            value: paygrade.value || 0,
          },
        },
        this.updateShiftPaygrade,
        'shifts.paygrades.error',
      )),
    );

    // keep failed
    this.toUpdate = this.toUpdate.filter((_, index) => (
      results[index].state !== StoreActionState.SUCCESS
    ));
  }

  protected async executeCreateParent(shiftId: number) {
    const toCreateParent = this.toCreate.filter(paygrade => !paygrade.parentId);

    const results: StoreActionResult[] = await Promise.all(
      toCreateParent.map(paygrade => executeStoreActionWithFailureSnackbar(
        this,
        {
          shiftId,
          paygrade: {
            parentPaygradeId: paygrade.parentId || null,
            paygradeTypeId: +paygrade.typeId,
            value: paygrade.value || 0,
          },
        },
        this.createShiftPaygrade,
        'shifts.paygrades.error',
      )),
    );

    return results.reduce((prev, response, index) => {
      if (response.state === StoreActionState.SUCCESS && response.entityId) {
        prev.set(toCreateParent[index].id, response.entityId);
      }

      return prev;
    }, new Map() as Map<number, number>);
  }

  protected async executeCreateBonus(
    shiftId: number,
    tmpToCreatedParentId: Map<number, number>,
  ) {
    const toCreateBonus = this.toCreate.filter(({ parentId }) => (
      // include only bonuses for which we have an actual parent ID (existing or just created)
      parentId && (parentId > 0 || tmpToCreatedParentId.has(parentId))
    ));

    const results: StoreActionResult[] = await Promise.all(
      toCreateBonus.map(bonus => executeStoreActionWithFailureSnackbar(
        this,
        {
          shiftId,
          paygrade: {
            parentPaygradeId: bonus.parentId && bonus.parentId > 0
              ? bonus.parentId
              : tmpToCreatedParentId.get(bonus.parentId || 0) || 0,
            paygradeTypeId: +bonus.typeId,
            value: bonus.value || 0,
          },
        },
        this.createShiftPaygrade,
        'shifts.paygrades.error',
      )),
    );

    return new Set(toCreateBonus.filter((_, index) => (
      results[index].state !== StoreActionState.SUCCESS
    )).map(({ id }) => id));
  }

  protected async onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    e.preventDefault();

    if (!this.shift) {
      return;
    }

    const { id: shiftId } = this.shift;

    this.$emit('submitStateChange', true);

    await this.executeRemove(shiftId);
    await this.executeUpdate(shiftId);

    const tmpToCreatedParentId = await this.executeCreateParent(shiftId);

    const createBonusFailed = await this.executeCreateBonus(shiftId, tmpToCreatedParentId);

    // keep failed
    this.toCreate = this.toCreate.filter(({ id }) => (
      createBonusFailed.has(id) || !tmpToCreatedParentId.has(id)
    ));

    this.$emit('submitStateChange', false);

    const isAllSuccessful = !this.toRemove.length && !this.toUpdate.length && !this.toCreate.length;

    if (isAllSuccessful) {
      this.showSnackbar({
        message: this.$t('shifts.paygrades.saved'),
        kind: AlertKind.SUCCESS,
        timeout: 5000,
      });
    }

    this.initialPopulate();
  }

  public beforeRouteEnter(_to, _from, next) {
    next(
      redirectToParentIf((vm: this) => (
        vm.hasShiftAlreadyStarted || (!vm.hasPaymentViewRight && !vm.hasPaymentManageRight)
      )),
    );
  }

  public beforeRouteLeave(_to, _from, next) {
    confirmLeaveIf.call(this, () => (
      !!this.toCreate.length || !!this.toRemove.length || !!this.toUpdate.length
    ), next);
  }

  public async initialPopulate() {
    if (!this.shift) {
      return;
    }

    // Set isLoading only when performing the initial loading. Otherwise use SWR.
    this.isLoading = !this.paygrades.length && !this.typesRegular.length && !this.typesBonus.length;

    await Promise.all([
      executeStoreActionWithFailureSnackbar(
        this,
        { shiftId: this.shift.id },
        this.fetchAllShiftPaygrades,
        'shifts.paygrades.error',
      ),
      executeStoreActionWithFailureSnackbar(
        this,
        {},
        this.fetchAllPaygradeTypes,
        'shifts.paygrades.error',
      ),
    ]);

    this.isLoading = false;
  }

  public mounted() {
    this.initialPopulate();
  }

  public render() {
    if (this.isLoading) {
      return (
        <PaygradesPlaceholder />
      );
    }

    return (
      <FormDialog
        class={styles.paygrades}
        id={`form-${this.$route?.name}`}
        onSubmit={this.onSubmit}
      >
        {
          !this.typesRegular.length && !this.typesBonus.length && (
            <p class={styles.paygradesNone}>
              {this.$t('shifts.paygrades.noTypesExistent')}
            </p>
          )
        }

        {
          this.inherited.map(paygrade => (
            <Section
              class={styles.paygradesSection}
              isInherited={true}
              isRemoveDisabled={true}
              key={paygrade.id}
              optionsBonuses={this.optionsBonuses}
              optionsRegular={this.optionsRegular}
              paygrade={paygrade}
            />
          ))
        }

        {
          this.individual.map(paygrade => (
            <Section
              bonuses={this.bonuses.filter(o => o.parentId === paygrade.id)}
              class={styles.paygradesSection}
              key={paygrade.id}
              onAddBonusPaygrade={this.onAddPaygradeClick}
              onChange={this.onChange}
              onRemovePaygrade={this.onRemovePaygradeClick}
              optionsBonuses={this.optionsBonuses}
              optionsRegular={this.optionsRegular}
              paygrade={paygrade}
            />
          ))
        }

        {
          this.isPaygradeAddible && (
            <Button
              class={styles.paygradesButton}
              color={ButtonColor.SUCCESS}
              onClick={e => this.onAddPaygradeClick(createEventPayload(e, undefined))}
              size={Size.SMALL}
              kind={ButtonKind.STROKE}
            >
              {this.$t('shifts.paygrades.buttonAddPaygrade')}
            </Button>
          )
        }
      </FormDialog>
    );
  }
}
