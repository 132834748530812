import SpIcon from 'components/sp-icon/SpIcon';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './search.css';

@Component
export default class Search extends TsxComponent<{
  value: string;
}, {
  onInput: (payload: EventPayload<string>) => void;
}> {
  @Prop()
  public value: string;

  protected onInput(e: SyntheticEvent<HTMLInputElement, Event>) {
    this.$emit('input', createEventPayload(e, e.target.value));
  }

  public render() {
    return (
      <section class={styles.search}>
        <label class={styles.searchLabel}>
          <input
            class={styles.searchInput}
            onInput={this.onInput}
            type="search"
            value={this.value}
          />
          <SpIcon class={styles.searchLabelIcon} name="search" />
        </label>
      </section>
    );
  }
}
