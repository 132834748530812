import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { Action } from 'src/store/normalized-store';
import { Shiftplan, shiftplansNS } from 'src/store/shiftplans/Store';
import { shiftsNS } from 'src/store/shifts/Store';
import type {
  FetchShiftFunction,
  DeleteShiftFunction,
  Shift,
} from 'src/store/shifts/Store';
import { redirectToNamedParent } from 'src/utils/route';
import {
  executeStoreActionWithFailureSnackbar,
  StoreActionState,
} from 'utils/store';
import type { GetById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { OnDeleteShiftPayload, OnUpdateShiftPayload } from 'components/shift-schedule/calendar-dialog-shift-container/CalendarDialogShiftContainer';
import { AlertKind } from 'components/alert/Alert';
import { EventPayload } from 'src/utils/events';
import DialogShift from './DialogShift';

interface Props {
  shiftId?: number;
  shiftplanId: number;
}

interface Events {
  onCloseClick: () => void;
  onCreateShiftSuccess: (shiftIds: number[]) => void;
  onUpdateShiftSuccess: (payload: OnUpdateShiftPayload) => void;
  onDeleteShiftSuccess: (payload: OnDeleteShiftPayload) => void;
}

@Component
export default class DialogShiftContainer extends TsxComponent<Props, Events> {
  public $refs: {
    dialog: Vue;
  };

  protected shift: Shift | null = null;

  protected shiftplan: Shiftplan | null = null;

  private isDeleteConfirmDialogOpen = false;

  private isConnectedShiftsDeleteConfirmDialogOpen = false;

  private isDeleting = false;

  protected lastStoreActionState = StoreActionState.PENDING;

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.State
  protected currentCompanyId: AuthStoreState['currentCompanyId'];

  @shiftsNS.Action(Action.FETCH)
  protected fetchShift: FetchShiftFunction;

  @shiftsNS.Getter('getById')
  protected getShiftById: GetById<Shift>;

  @shiftsNS.Action(Action.DELETE)
  protected deleteShift: DeleteShiftFunction;

  @shiftplansNS.Getter('getById')
  protected getShiftplanById: GetById<Shiftplan>;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public shiftId: Props['shiftId'];

  @Prop()
  public shiftplanId: Props['shiftplanId'];

  protected get isUpdate() {
    return !!this.shiftId;
  }

  protected get isErrorState() {
    if (this.lastStoreActionState === StoreActionState.NOT_FOUND) {
      return this.$t('shifts.dialog.errorNotAvailable');
    }
    return this.lastStoreActionState === StoreActionState.ERROR;
  }

  protected onCloseClick() {
    redirectToNamedParent(this);
  }

  protected async tryShiftDelete(deleteConnected = false) {
    if (!this.shift?.id) {
      return false;
    }

    this.isDeleting = true;
    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        id: this.shift.id,
        deleteParams: { deleteConnected },
      },
      this.deleteShift,
      'general.error.unknown',
    );

    this.isDeleteConfirmDialogOpen = false;
    this.isDeleting = false;

    if (response.state === StoreActionState.ERROR) {
      return false;
    }

    this.$emit('deleteShiftSuccess', {
      shiftId: this.shift.id,
      withConnected: deleteConnected,
      connectedGroupId: this.shift.connectedGroupId,
    });
    this.onCloseClick();
    return this.showSnackbar({
      message: this.$t('shifts.modalDelete.deleted'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onConfirmDelete() {
    this.isDeleteConfirmDialogOpen = false;

    // show additional dialog to ask about connected shifts
    if (this.shift?.connectedGroupId) {
      this.isConnectedShiftsDeleteConfirmDialogOpen = true;
      return;
    }

    this.tryShiftDelete(false);
  }

  protected onConfirmShiftConnected({ payload }: EventPayload<boolean>) {
    this.isConnectedShiftsDeleteConfirmDialogOpen = false;

    this.tryShiftDelete(payload);
  }

  protected async populateShift(useBackgroundFetch = false, isInitialLoad = false) {
    if (!useBackgroundFetch) {
      this.lastStoreActionState = StoreActionState.PENDING;
    }

    if (this.shiftId) {
      const fetchShiftResponse = await executeStoreActionWithFailureSnackbar(
        this,
        {
          id: this.shiftId,
          isShiftEvaluationTagsIncluded: true,
        },
        this.fetchShift,
        'shifts.dialog.error',
        'shifts.dialog.error.genericLoadShift',
      );

      this.lastStoreActionState = fetchShiftResponse.state;

      if (this.lastStoreActionState === StoreActionState.NOT_FOUND) {
        this.$emit('deleteShiftSuccess', this.shiftId);
      }

      if (this.lastStoreActionState !== StoreActionState.SUCCESS) {
        return;
      }

      this.lastStoreActionState = fetchShiftResponse.state;
      this.shift = this.getShiftById(this.shiftId) || null;

      if (!isInitialLoad) {
        this.$emit('updateShiftSuccess', {
          shiftId: this.shiftId,
          withConnected: false,
          connectedGroupId: null,
        });
      }

      if (!this.shift) {
        return;
      }

      this.shiftplan = {
        ...this.shift.shiftplan,
        locationId: this.shift.locationsPosition.location?.id || null,
      };
    }
  }

  public async mounted() {
    if (this.isUpdate) {
      // TODO: We might actually want to check if the shiftplan is already in the store and not
      // refetch it in this case or refetch it silently in the background.
      await this.populateShift();
      return;
    }

    // FIXME: fetching the shiftplan here interferes with the getter for shiftplan in
    // CalendarContainer which will be executed unnecessarily
    this.shiftplan = this.getShiftplanById(this.shiftplanId) || null;
    this.lastStoreActionState = StoreActionState.SUCCESS;
  }

  public render() {
    return (
      <DialogShift
        currentEmployment={this.currentEmployment}
        hasError={this.isErrorState}
        isLoading={this.lastStoreActionState === StoreActionState.PENDING}
        isOpen={true}
        isUpdate={this.isUpdate}
        onCloseClick={this.onCloseClick}
        onRefetchShift={({ useBackgroundFetch }) => this.populateShift(useBackgroundFetch, false)}
        ref="dialog"
        shift={this.shift}
        shiftplan={this.shiftplan}
        isDeleteConfirmDialogOpen={this.isDeleteConfirmDialogOpen}
        isConnectedShiftsDeleteConfirmDialogOpen={this.isConnectedShiftsDeleteConfirmDialogOpen}
        isDeleting={this.isDeleting}
        onCancelDelete={() => { this.isDeleteConfirmDialogOpen = false; }}
        onConfirmDelete={this.onConfirmDelete}
        onConfirmShiftConnected={this.onConfirmShiftConnected}
        onDeleteClick={() => { this.isDeleteConfirmDialogOpen = true; }}
        onCreateShiftSuccess={(payload) => { this.$emit('createShiftSuccess', payload); }}
        onUpdateShiftSuccess={(payload) => { this.$emit('updateShiftSuccess', payload); }}
      />
    );
  }
}
