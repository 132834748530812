export const SENSITIVE_KEYS = [
  'password',
  'authtoken',
  'authemail',
  'email',
  'firstName',
  'lastName',
  'nameFirst',
  'nameLast',
  'first_name',
  'last_name',
  'name_first',
  'name_first',
];

export default function censorSensitiveProps(obj, keys = SENSITIVE_KEYS, censoredString = '***') {
  const result = { ...obj };

  Object.keys(obj).forEach((key) => {
    if (keys.includes(key)) {
      result[key] = censoredString;
    } else if (Object.keys(result[key]).some(subKey => keys.includes(subKey))) {
      result[key] = censorSensitiveProps(result[key], keys, censoredString);
    }
  });

  return result;
}
