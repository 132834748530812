import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import tableStyles from 'components/table/table.css';
import { getUrlWithApiPrefix } from 'src/utils/url';
import Tooltip from 'components/tooltip/Tooltip';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import type { Absence } from '../types';
import styles from './cell-attachment.css';
import TooltipUploadedAt from './TooltipUploadedAt';

@Component
export default class CellAttachment extends TsxComponent<{
  absence: Absence;
}> {
  @Prop()
  public absence: Absence;

  protected onClick(e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) {
    const target = e.currentTarget as HTMLAnchorElement;

    if (target.href) {
      window.open(target.href, target.target, 'location=off');
    }

    e.preventDefault();
  }

  public render() {
    return (
      <td class={[tableStyles.tableBodyCell, styles.cellAttachment]}>
        {this.absence.file && (
          <Tooltip
            class={styles.cellAttachmentTooltip}
            text={this.$t('absence.attachment.download')}
          >
            <a
              href={getUrlWithApiPrefix(this.absence.file)}
              target="preview"
              onClick={this.onClick} class={styles.cellAttachmentLink}
            >
              <Icon
                name={IconName.DOWNLOAD}
                size={Size.SMALL}
                title={this.$t('absence.attachment.download')}
              />
            </a>
          </Tooltip>
        )}

        {
          this.absence.fileUploadedAt && (
            <TooltipUploadedAt absence={this.absence} />
          )
        }
      </td>
    );
  }
}
