import { FiltersMap } from 'components/calendar-common/filters/Store';
import { Component, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { v4 as uuid } from 'uuid';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import InputCheckbox from 'components/form/input-checkbox/InputCheckbox';
import InputRadio from 'components/form/input-radio/InputRadio';
import ButtonSquare from 'components/shift-schedule/button-square/ButtonSquare';
import Button from 'components/form/button/Button';
import { IconPosition } from 'components/form/button/types';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import { quickFilterNS } from '../store/Store';
import ButtonToolbar, { ButtonColor as ButtonToolbarColor } from '../../button-toolbar/ButtonToolbar';
import styles from './filter-popup-quick.css';

/*
  This popup has a legacy design (for a calendar page, it should not be used outside it)
*/
@Component
class FilterPopupQuick extends TsxComponent<{}> {
  public $refs: {
    filterRef: HTMLElement;
  };

  private isOpen = false;

  private radioName = uuid();

  private radioButtons = [
    {
      value: FiltersMap.ALL_SHIFTS,
      label: 'shiftSchedule.filter.quick.allShifts',
    },
    {
      value: FiltersMap.MY_SHIFTS,
      label: 'shiftSchedule.filter.quick.myShifts',
    },
  ];

  private checkboxes = [
    {
      value: FiltersMap.SHIFTS_WITHOUT_CONFLICTS,
      label: 'shiftSchedule.filter.quick.shiftWithoutConflict',
    },
    {
      value: FiltersMap.ACCEPTED_ABSENCES,
      label: 'shiftSchedule.filter.quick.acceptedAbsence',
    },
    {
      value: FiltersMap.NEW_ABSENCES,
      label: 'shiftSchedule.filter.quick.newAbsence',
    },
    {
      value: FiltersMap.SPECIAL_DATES,
      label: 'shiftSchedule.filter.quick.specialDay',
    },
    {
      value: FiltersMap.DAY_NOTES,
      label: 'shiftSchedule.filter.quick.dayNote',
    },
  ];

  private selectedCheckboxes: FiltersMap[] = [];

  private radioValue: FiltersMap.ALL_SHIFTS | FiltersMap.MY_SHIFTS = FiltersMap.ALL_SHIFTS;

  @quickFilterNS.State
  private filters: FiltersMap[];

  @quickFilterNS.Action
  private setFilters: (name: FiltersMap[]) => void;

  private get toggleColor() {
    const isNoFilterSelected = this.filters.length < 2
      && this.filters.includes(FiltersMap.ALL_SHIFTS);

    return isNoFilterSelected && !this.isOpen
      ? ButtonToolbarColor.OUTER_SPACE
      : ButtonToolbarColor.SHYFTPLAN_GREEN;
  }

  private onCheckboxChange(e: SyntheticEvent<HTMLInputElement, Event>) {
    const { target } = e;
    this.selectedCheckboxes = target.checked
      ? [...this.selectedCheckboxes, target.value as FiltersMap]
      : this.selectedCheckboxes.filter(it => it !== target.value);
  }

  private onRadioChange(e: SyntheticEvent<HTMLInputElement, Event>) {
    this.radioValue = e.target.value as FiltersMap.ALL_SHIFTS | FiltersMap.MY_SHIFTS;
  }

  private onToggleClick() {
    this.isOpen = !this.isOpen;
  }

  private onApplyClick() {
    this.setFilters([...this.selectedCheckboxes, this.radioValue]);
    this.onClose();
  }

  private onClose() {
    this.isOpen = false;
  }

  // sync values with store
  @Watch('filters')
  protected onFiltersChange() {
    const possibleFilters = this.checkboxes.map(it => it.value);

    this.selectedCheckboxes = this.filters
      .filter(it => possibleFilters.includes(it));
    this.radioValue = this.filters.includes(FiltersMap.MY_SHIFTS)
      ? FiltersMap.MY_SHIFTS : FiltersMap.ALL_SHIFTS;
  }

  public mounted() {
    // if no filters set - set default value
    if (this.filters.length === 0) {
      this.setFilters([
        FiltersMap.MY_SHIFTS,
        FiltersMap.SHIFTS_WITHOUT_CONFLICTS,
      ]);
    }
    this.onFiltersChange();
  }

  public render() {
    return (
      <div class={styles.filterPopupQuickWrapper}
        ref={'filterRef'}>
        <ButtonToolbar
          aria-expanded={this.isOpen || 'false'}
          icon={this.isOpen ? 'clear' : 'filter'}
          color={this.toggleColor}
          onClick={this.onToggleClick}
          label={this.$t('shiftSchedule.filter.quick.toggle')}/>

        {
          this.isOpen
            && <div class={styles.filterPopupQuick} role="dialog">
              <OutsideClickHandler
                insideRef={this.$refs.filterRef}
                onOutsideClick={this.onClose}>
              </OutsideClickHandler>
              <header class={styles.filterPopupQuickHeader}>
                <span>{this.$t('shiftSchedule.filter.quick.title')}</span>
                <Button
                  class={styles.filterPopupQuickCloseButton}
                  color={ButtonColor.SECONDARY}
                  icon={IconName.CLEAR}
                  onClick={this.onClose}
                  size={Size.SMALL}
                  kind={ButtonKind.GHOST}
                  iconPosition={IconPosition.ALONE}
                />
              </header>
              <ul class={styles.filterPopupQuickList}>
                {
                  this.radioButtons.map(item => (
                    <li
                      class={[styles.filterPopupQuickListItem]}
                      key={item.value}
                    >
                      <InputRadio
                        value={item.value}
                        name={this.radioName}
                        label={this.$t(item.label)}
                        checked={item.value === this.radioValue}
                        onChange={e => this.onRadioChange(e)}
                      />
                    </li>
                  ))
                }
              </ul>
              <ul class={styles.filterPopupQuickList}>
                {
                  this.checkboxes.map(item => (
                    <li
                      class={[styles.filterPopupQuickListItem]}
                      key={item.value}
                    >
                      <InputCheckbox label={this.$t(item.label)}
                        name={item.label}
                        value={item.value}
                        checked={this.selectedCheckboxes.includes(item.value)}
                        onChange={e => this.onCheckboxChange(e)}/>
                    </li>
                  ))
                }
              </ul>
              <ButtonSquare
                type="button"
                class={styles.filterPopupQuickApplyButton}
                onClick={this.onApplyClick}>
                {this.$t('shiftSchedule.filter.quick.apply')}
              </ButtonSquare>
            </div>
        }
      </div>

    );
  }
}

export default FilterPopupQuick;
