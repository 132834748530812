import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { v4 as uuid } from 'uuid';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconPosition } from 'components/form/button/types';
import { IconName } from 'components/icons/types';
import styles from './accordion.css';

interface Props {
  id?: string;
  label?: string;
  isOpen?: boolean;
}

interface Events {
  onOpenCloseClick: (e: MouseEvent) => void;
  onKeyDown: (e: KeyboardEvent) => void;
}

export enum Slot {
  LID = 'lid',
  LID_CONTENT_OVERLAY = 'lidContentReplacement',
}

@Component
class Accordion extends TSXComponent<Props, Events> {
  public $refs: {
    buttonRef: HTMLButtonElement;
  };

  @Prop({ default: () => uuid() })
  public id: Props['id'];

  @Prop()
  public label: Props['label'];

  @Prop()
  public isOpen: Props['isOpen'];

  @Emit('openCloseClick')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onOpenCloseClick() { }

  @Emit('keyDown')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onKeyDown() { }

  public render() {
    const LID_ELEMENT_ID = `accordion-lid-${this.id}`;
    const LID_ARROW_ELEMENT_ID = `accordion-lid-arrow-${this.id}`;
    const CONTENT_ELEMENT_ID = `accordion-content-${this.id}`;

    return (
      <div class={styles.accordion}>
        <h2 class={styles.accordionLid}>
          <Button
            aria-controls={CONTENT_ELEMENT_ID}
            aria-expanded={this.isOpen}
            class={styles.accordionLidArrowButton}
            id={LID_ARROW_ELEMENT_ID}
            onClick={this.onOpenCloseClick}
            icon={this.isOpen ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
            size={Size.MEDIUM}
            kind={ButtonKind.GHOST}
            color={ButtonColor.SECONDARY}
            iconPosition={IconPosition.ALONE}
          />
          <transition name="accordion-header-transition"
            duration={200}>
            {this.$slots[Slot.LID_CONTENT_OVERLAY] && (
              <div class={styles.accordionLidContentOverlay} aria-modal>
                {this.$slots[Slot.LID_CONTENT_OVERLAY]}
              </div>
            )}
          </transition>
          <button
            aria-controls={CONTENT_ELEMENT_ID}
            aria-expanded={this.isOpen}
            class={styles.accordionLidButton}
            id={LID_ELEMENT_ID}
            onClick={this.onOpenCloseClick}
            onKeydown={this.onKeyDown}
            ref="buttonRef"
          >
            {this.label}
          </button>
          {this.$slots[Slot.LID]}
        </h2>

        <div
          aria-labelledby={LID_ELEMENT_ID}
          class={[styles.accordionContent, this.isOpen && styles.accordionContentOpen]}
          hidden={!this.isOpen}
          id={CONTENT_ELEMENT_ID}
          role="region"
        >
          {this.isOpen && this.$slots.default}
        </div>
      </div>
    );
  }
}

export default Accordion;
