import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Prompt, { Slot as PromptSlot } from 'components/prompt/Prompt';
import Button from 'components/form/button/Button';
import { ButtonKind, ButtonColor } from 'components/form/base-button/types';

@Component
export default class DialogConfirm extends TsxComponent<{
  isOpen?: boolean;
  isSubmitting?: boolean;
  title: string;
}, {
  onCancel: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onConfirm: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}> {
  @Prop()
  public isOpen?: boolean;

  @Prop()
  public isSubmitting?: boolean;

  @Prop()
  public title: string;

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        onCloseClick={e => this.$emit('cancel', createEventPayload(e, undefined))}
        title={this.title}
      >
        {this.$slots.default}

        <template slot={PromptSlot.ACTION_BUTTONS}>
          <Button type="button"
            color={ButtonColor.SECONDARY}
            kind={ButtonKind.STROKE}
            onClick={e => this.$emit('cancel', createEventPayload(e, undefined))}
            disabled={this.isSubmitting}
          >{this.$t('modal.buttonCancel')}</Button>
          <Button type="button"
            color={ButtonColor.PRIMARY}
            kind={ButtonKind.FILL}
            onClick={e => this.$emit('confirm', createEventPayload(e, undefined))}
            disabled={this.isSubmitting}>
            {this.$t('modal.buttonOkay')}
          </Button>
        </template>
      </Prompt>
    );
  }
}
