import FilterPopup from 'components/table/filter-popup/FilterPopup';
import { AbsenceReason, absenceReasonsNS } from 'src/store/absence-reasons/Store';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class FilterPopupReason extends TsxComponent<{
  close: () => void;
  selected: number[] | null;
}, {
  onFilterChange: number[];
}> {
  @Emit('filterChange')
  public onFilterChange({ payload }) {
    if (this.selected && this.selected.includes(payload.id)) {
      return this.selected.filter(o => o !== payload.id);
    }

    return [...(this.selected || []), payload.id];
  }

  @Prop()
  public close: () => void;

  @Prop()
  public selected: number[] | null;

  @absenceReasonsNS.Getter('items')
  protected reasons: AbsenceReason[];

  public render() {
    return (
      <FilterPopup
        items={this.reasons.map(o => ({
          id: o.id,
          isChecked: !!this.selected && this.selected.indexOf(o.id) !== -1,
          label: o.hasLocalization
            ? this.$t(`absence.reason.${o.name}`)
            : o.name,
        }))}
        onFilterChange={this.onFilterChange}
        onClose={this.close}
      />
    );
  }
}
