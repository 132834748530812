import { kebabCase } from 'lodash';
import type { GQLCompany } from 'codegen/gql-types';

export const getUrlWithApiPrefix = (...path: string[]) => (
  new URL(path.join('/'), process.env.API_ENDPOINT).toString()
);

export const getUrlWithCompanyPrefix = (url: string, company?: GQLCompany) => {
  const prefix = [
    undefined,
    'companies',
    [company?.id, kebabCase(company?.name)].join('-'),
  ].join('/');

  return [prefix, url].join('');
};

/**
 * Reducer to build URLSearchParams.
 * @param payload Hash of arguments to pass
 */
/* eslint-disable indent */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildPayload = (payload: Record<string, any>) => {
  const queryStringObj = Object.entries(payload).reduce((usp, [key, val]) => {
    if (Array.isArray(val)) {
      return val.reduce((prev, v) => {
        prev.append(key, v);
        return prev;
      }, usp);
    }

    if (val) {
      usp.append(key, val);
    }

    return usp;
  }, new URLSearchParams());

  return queryStringObj.toString();
};
