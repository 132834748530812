import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEmploymentEvaluationMutation,
  GQLCreateEmploymentEvaluationMutationVariables,
  GQLEmploymentEvaluationFragmentFragment,
  GQLEmploymentEvaluationQuery,
  GQLEmploymentEvaluationQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import EmploymentEvaluationGql from './queries/EmploymentEvaluation.gql';
import CreateEmploymentEvaluationGql from './queries/CreateEmploymentEvaluation.gql';

export const employmentEvaluationsNS = namespace('employmentEvaluations');

export type GQLEmploymentEvaluation = GQLEmploymentEvaluationFragmentFragment;

export type StoreState = ById<GQLEmploymentEvaluation>;

export type FetchEmploymentEvaluationFunction = (
  payload: Omit<GQLEmploymentEvaluationQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateEmploymentEvaluationFunction = (
  payload: Omit<GQLCreateEmploymentEvaluationMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getEmploymentEvaluationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
    getters: {
      getByStaffShiftId: (state: StoreState) => (staffShiftId: number) => (
        Object.values<GQLEmploymentEvaluation>(state.byId)
          .find(item => item.staffShiftId === staffShiftId)
      ),
    },
  });

  const fetch: ActionProvider<
  GQLEmploymentEvaluationQuery, GQLEmploymentEvaluationQueryVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchEmploymentEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: EmploymentEvaluationGql,
      resultKey: 'employmentEvaluation',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      failSilently: true,
    });
  };

  const create: ActionProvider<
  GQLCreateEmploymentEvaluationMutation,
  GQLCreateEmploymentEvaluationMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEmploymentEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: CreateEmploymentEvaluationGql,
      resultKey: 'createEmploymentEvaluation',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  return createNormalizedStore<GQLEmploymentEvaluation, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH]: fetch,
      [Action.CREATE]: create,
    },
    graphqlClient,
    logger,
  });
};

export default getEmploymentEvaluationsStore;
