import type { GQLDayNote } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import SpIcon from 'components/sp-icon/SpIcon';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { Route } from 'components/shift-schedule/routes';
import { dayNotesNS } from 'store/day-notes/Store';
import { notesNS } from '../Store';

import styles from './day-note.css';

@Component
export default class DayNote extends TsxComponent<{
  note: GQLDayNote;
}> {
  @calendarCommonNS.State
  protected shiftplan;

  @notesNS.Getter
  protected openedDayNoteId: number;

  @dayNotesNS.Getter('canManage')
  protected canManageNotes: boolean;

  @Prop()
  public note: GQLDayNote;

  protected showNote(e: MouseEvent, noteId) {
    e.stopPropagation();

    this.$router.push({
      name: Route.DAY_NOTE,
      params: {
        id: noteId.toString(),
        shiftplanId: this.shiftplan.id.toString(),
      },
    });
  }

  public render() {
    return (
      <button
        class={styles.dayNote}
        onClick={e => this.showNote(e, this.note.id)}
      >
        <span>
          {this.note.title}
        </span>
        {this.canManageNotes && <SpIcon name="edit"/>}
      </button>
    );
  }
}
