import { DroppableTypeConfig } from '@shyftplan/drag-and-drop';
import DndKind from 'components/calendar-common/common/dnd/DndKind';
import Action from 'components/calendar-common/shifts/store/Action';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftParamsHelper, { OPEN_SHIFT_EMPLOYMENT_ID } from 'components/calendar-common/shifts/shift-params-helper/ShiftParamsHelper';
import Employment from 'components/calendar-common/employments/Employment';
import { Store } from 'vuex';
import RootStoreState from 'src/store/RootStoreState';
import type { ShiftDraggable } from 'components/calendar-common/shifts/dnd/types';
import type { CellDroppable } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getConfig = (store: Store<RootStoreState>): DroppableTypeConfig => {
  const isEmploymentValid = (cellEmploymentId: number | undefined) => {
    // 0 is used to represent an empty employment
    if ((cellEmploymentId === undefined)
      || (cellEmploymentId === OPEN_SHIFT_EMPLOYMENT_ID)) {
      return true;
    }
    const employment: Employment = store.getters['calendar/employments/filteredEmployments']
      .find(it => it.id === cellEmploymentId);
    // should not happen
    if (!employment) {
      return false;
    }

    return !employment.isDeleted();
  };
  return {
    isActive: (draggableProps, droppableProps: CellDroppable): boolean => {
      const { data: droppableData } = droppableProps;
      const { data: draggableData, kind } = draggableProps;
      if (kind !== DndKind.SHIFT) {
        return false;
      }
      const shift: Shift = store.getters['calendar/shifts/shiftById'](draggableData.id);
      const shiftPosition = shift.position.locationsPositionId;
      const isSamePosition = droppableData.positionId === undefined
        || shiftPosition === droppableData.positionId;

      const startDate = shift.getStartDateTime();
      const isSameDate = startDate.isSame(droppableData.date, 'day');

      // if employment param is not passed then we should ignore this check
      const isSameEmployment = droppableData.employmentId === undefined
        || droppableData.employmentId === draggableData.employmentId;

      const isActiveEmployment = isEmploymentValid(droppableData.employmentId);

      const isPositionAllowed = droppableData.allowedPositionIds === undefined
        || droppableData.allowedPositionIds.includes(shiftPosition);

      return (!isSameDate || !isSamePosition || !isSameEmployment)
        && isPositionAllowed
        && isActiveEmployment;
    },
    onDrop: async (draggableProps: ShiftDraggable, droppableProps: CellDroppable) => {
      const { data: draggableData, kind } = draggableProps;
      if (kind !== DndKind.SHIFT) {
        return false;
      }
      const shiftId = draggableData.id;
      const shiftToUpdate: Shift = store.getters['calendar/shifts/shiftById'](shiftId);
      shiftToUpdate.employmentId = draggableData.employmentId;
      const slotData = {
        ...droppableProps.data,
      };
      const newParams = ShiftParamsHelper.getUpdatedParams(slotData, shiftToUpdate);
      // check if there are any changes
      if (Object.keys(newParams).length > 0) {
        return store.dispatch(`calendar/shifts/${Action.UPDATE_SHIFT}`, {
          id: shiftId,
          params: {
            ...newParams,
          },
        });
      }

      return false;
    },
  // TODO: fix dnd types
  } as any;
};
