import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { DocumentNode } from 'graphql';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { SentryTag } from 'services/logger/SentryTransport';
import { PayloadParameter, StoreActionResult, StoreActionState } from 'utils/store';
import { ActionContext, ActionTree, Module } from 'vuex';

export interface ById<T> {
  byId: Record<number, T>;
  intrinsicOrder: number[];
  total: number;
}

export interface WithId {
  id: number;
}

export enum Action {
  CLEAR = 'clear',
  CREATE = 'create',
  DELETE = 'delete',
  FETCH = 'fetch',
  FETCH_ALL = 'fetchAll',
  UPDATE = 'update',
}

export enum Mutation {
  CLEAR_ITEMS = 'clearItems',
  REMOVE_ITEM = 'removeItem',
  SET_ITEM = 'setItem',
  SET_ITEMS = 'setItems',
  SET_TOTAL = 'setTotal',
}

export interface ActionDefinition<Query, Variables> {
  query: DocumentNode;
  resultKey: keyof Query;
  variables: Variables;
  useBatching?: boolean;
  failSilently?: boolean;
}

export interface ActionDefinitionWithTransform<
  Entity, Query, Variables> extends ActionDefinition<Query, Variables> {
  transform: (input: any) => Entity;
}

/* eslint-disable indent */
export const isSuccessResult = <T extends {}>(
  result: FetchResult<T>,
  resultKey: string | number | symbol,
): result is FetchResult<T> & { data: T } => (
  !!result.data
    && resultKey in result.data
    && !!result.data[resultKey]
);
/* eslint-enable indent */

export const handleUnexpectedResult = (
  action: Action,
  logger: ApplicationLogger,
): StoreActionResult => {
  logger.instance.error({
    message: 'Unexpected query/mutation result: data missing or resultKey not found in data',
    tags: [[SentryTag.ACTION, action]],
  });

  return { state: StoreActionState.ERROR };
};

const isActionDefinitionWithTransform = <Entity, Query, Variables>(
  actionDefinition: ActionDefinition<Query, Variables>,
): actionDefinition is ActionDefinitionWithTransform<Entity, Query, Variables> => 'transform' in actionDefinition;

export type ActionProvider<
  Query = any,
  Variables = any,
  Entity = any,
  Context = ActionContext<any, any>,
> = <ActionFunction extends (...args: any) => any>(
  context: Context,
  payload: PayloadParameter<ActionFunction>,
) => ActionDefinition<Query, Variables> | ActionDefinitionWithTransform<Entity, Query, Variables>;

export function createNormalizedStore<Entity extends WithId, StoreState, RootStoreState>(config: {
  provide: {
    [key in Action]?: ActionProvider;
  };
  graphqlClient: ApolloClient<NormalizedCacheObject>;
  logger: ApplicationLogger;
  store: Module<StoreState, RootStoreState>;
}): Module<StoreState & ById<Entity>, RootStoreState> {
  const { state: otherState, namespaced } = config.store || {};

  const newState = {
    ...(otherState instanceof Function ? otherState() : otherState as StoreState),
    byId: {},
    intrinsicOrder: [],
    total: 0,
  };

  const newActions = {
    [Action.CLEAR]({ commit }) {
      commit(Mutation.CLEAR_ITEMS);
    },
  } as ActionTree<StoreState, RootStoreState>;

  const {
    [Action.CREATE]: createActionProvider,
    [Action.DELETE]: deleteActionProvider,
    [Action.FETCH]: fetchActionProvider,
    [Action.FETCH_ALL]: fetchAllActionProvider,
    [Action.UPDATE]: updateActionProvider,
  } = config.provide;

  if (fetchActionProvider) {
    newActions[Action.FETCH] = async (context, payload) => {
      try {
        const actionDefinition = fetchActionProvider(context, payload);
        const {
          variables,
          query,
          resultKey,
          useBatching,
          failSilently,
        } = actionDefinition;

        const result = await config.graphqlClient.query({
          query,
          variables,
          context: {
            useBatching,
          },
        });

        if (!isSuccessResult(result, resultKey) && !failSilently) {
          return handleUnexpectedResult(Action.FETCH, config.logger);
        }

        const isArrayResponse = !!result.data?.[resultKey]?.items
          && Array.isArray(result.data?.[resultKey]?.items);
        if (
          (
            // check for array response
            (isArrayResponse && !result.data[resultKey].items.length)
            // check for non-array response
            || (
              result.data?.[resultKey] === null
            ))
        ) {
          return { state: StoreActionState.NOT_FOUND };
        }

        let item = isArrayResponse
          ? result.data?.[resultKey].items[0]
          : result.data?.[resultKey];

        if (isActionDefinitionWithTransform(actionDefinition)) {
          item = actionDefinition.transform(item);
        }

        context.commit(Mutation.SET_ITEM, item);

        return { state: StoreActionState.SUCCESS };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  if (fetchAllActionProvider) {
    newActions[Action.FETCH_ALL] = async (context, payload) => {
      try {
        const actionDefinition = fetchAllActionProvider(context, payload);
        const {
          variables,
          query,
          resultKey,
          useBatching,
        } = actionDefinition;

        const result = await config.graphqlClient.query({
          query,
          variables,
          context: {
            useBatching,
          },
        });

        if (!isSuccessResult(result, resultKey)) {
          return handleUnexpectedResult(Action.FETCH, config.logger);
        }

        if (
          !Array.isArray(result.data?.[resultKey].items)
        ) {
          return { state: StoreActionState.NOT_FOUND };
        }

        let items = result.data?.[resultKey].items;
        if (isActionDefinitionWithTransform(actionDefinition)) {
          items = items.map(item => actionDefinition.transform(item));
        }

        context.commit(Mutation.SET_ITEMS, items);
        context.commit(
          Mutation.SET_TOTAL,
          result.data?.[resultKey].pagination?.count || items.length,
        );

        return { state: StoreActionState.SUCCESS };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  if (updateActionProvider) {
    newActions[Action.UPDATE] = async (context, payload) => {
      try {
        const {
          variables,
          query,
          resultKey,
          useBatching,
        } = updateActionProvider(context, payload);

        const result = await config.graphqlClient.mutate({
          mutation: query,
          variables,
          context: {
            useBatching,
          },
        });

        if (result.errors?.length) {
          return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
        }

        if (!isSuccessResult(result, resultKey)) {
          return handleUnexpectedResult(Action.UPDATE, config.logger);
        }

        // FIXME find a generic way to handle conflicts. Check feedback on:
        // https://github.com/shyftplan/sppt_web/pull/310#pullrequestreview-849468481
        if (!!result.data && 'conflicts' in result.data[resultKey]) {
          return {
            state: StoreActionState.CONFLICT,
            conflicts: result.data[resultKey].conflicts,
          };
        }

        context.commit(Mutation.SET_ITEM, result.data[resultKey]);

        return { state: StoreActionState.SUCCESS };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  if (deleteActionProvider) {
    newActions[Action.DELETE] = async (context, payload) => {
      try {
        const {
          variables,
          query,
          resultKey,
          useBatching,
        } = deleteActionProvider(context, payload);

        const result = await config.graphqlClient.mutate({
          mutation: query,
          variables,
          context: {
            useBatching,
          },
        });

        if (result.errors?.length) {
          return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
        }

        if (!isSuccessResult(result, resultKey)) {
          return handleUnexpectedResult(Action.DELETE, config.logger);
        }

        context.commit(Mutation.REMOVE_ITEM, payload.id);

        return {
          state: result.data[resultKey].success
            ? StoreActionState.SUCCESS
            : StoreActionState.ERROR,
          error: result.data[resultKey].error || undefined,
        };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  if (createActionProvider) {
    newActions[Action.CREATE] = async (context, payload) => {
      try {
        const actionDefinition = createActionProvider(context, payload);
        const {
          variables,
          query,
          resultKey,
          useBatching,
        } = actionDefinition;

        const result = await config.graphqlClient.mutate({
          mutation: query,
          variables,
          context: {
            useBatching,
          },
        });

        if (result.errors?.length) {
          return { state: StoreActionState.ERROR, error: result.errors[0].extensions?.response };
        }

        if (!isSuccessResult(result, resultKey)) {
          return handleUnexpectedResult(Action.CREATE, config.logger);
        }

        if (!!result.data && 'conflicts' in result.data[resultKey]) {
          return {
            state: StoreActionState.CONFLICT,
            conflicts: result.data[resultKey].conflicts,
          };
        }

        let item = result.data[resultKey];

        if (isActionDefinitionWithTransform(actionDefinition)) {
          item = actionDefinition.transform(item);
        }

        context.commit(Mutation.SET_ITEM, item);

        return { state: StoreActionState.SUCCESS, entityId: result.data[resultKey].id };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  return ({
    namespaced,
    state: newState,
    mutations: {
      ...config.store?.mutations,
      [Mutation.CLEAR_ITEMS](state) {
        state.byId = {};
        state.intrinsicOrder = [];
        state.total = 0;
      },
      [Mutation.SET_ITEM](state, item: Entity) {
        state.byId = {
          ...state.byId,
          [item.id]: item,
        };
      },
      [Mutation.SET_ITEMS](state, items: Entity[]) {
        state.byId = items.reduce((prev, item) => {
          prev[item.id] = item;
          return prev;
        }, { ...state.byId });

        state.intrinsicOrder = items.map(item => item.id);
      },
      [Mutation.REMOVE_ITEM](state, id: number) {
        const tmp = { ...state.byId };
        delete tmp[id];

        state.byId = tmp;
        state.intrinsicOrder = state.intrinsicOrder.filter(itemId => itemId !== id);
      },
      [Mutation.SET_TOTAL](state, total: number) {
        state.total = total;
      },
    },
    getters: {
      ...config.store?.getters,
      getById: state => (id: number) => state.byId[id],
      items: state => Object.values(state.byId),
      ordered: state => state.intrinsicOrder.map(id => state.byId[id]),
    },
    actions: {
      ...config.store?.actions,
      ...newActions,
    },
    modules: config.store?.modules,
  });
}
