import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { OVERLAY_PORTAL_NAME } from 'layouts/OverlayPortalTarget';
import Datepicker from 'components/datepicker/Datepicker';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import { TimeframeKind } from 'components/calendar-common/Enums';
import DatepickerInterval, { IntervalSelectionMode } from 'components/datepicker/DatepickerInterval';
import Monthpicker from 'components/monthpicker/Monthpicker';
import { SelectedTimeframe, SelectionMode } from 'components/datepicker/types';
import { getTimeframeFromDates } from 'src/utils/timeframe-helpers';
import { Size } from 'components/types';
import { ButtonColor } from 'components/form/base-button/types';
import Button from 'components/form/button/Button';
import styles from './controls-datepicker.css';

interface Props {
  isDatepickerOpen: boolean;
  max?: Date;
  min?: Date;
  selection: SelectedTimeframe;
  timeframeKind: TimeframeKind;
  timeframeLabel: string;
  timeZone?: string;
  isIntervalSelectPending: boolean;
}

interface Events {
  onCancel: EventPayload<void>;
  onChange: EventPayload<SelectedTimeframe, HTMLButtonElement, MouseEvent>;
  onDatepickerToggle: EventPayload<boolean>;
}

@Component
export default class ControlsDatepicker extends TsxComponent<Props, Events> {
  public $refs: {
    timeframeCalendarRef: HTMLElement;
    timeframeButtonRef: Vue;
  };

  private datepickerStyle: Partial<CSSStyleDeclaration> = {};

  @Prop()
  public selection: Props['selection'];

  @Prop()
  public timeframeKind: Props['timeframeKind'];

  @Prop()
  public timeframeLabel: Props['timeframeLabel'];

  @Prop()
  public timeZone?: Props['timeZone'];

  @Prop()
  public max: Props['max'];

  @Prop()
  public min: Props['min'];

  @Prop()
  public isDatepickerOpen: Props['isDatepickerOpen'];

  @Prop({ default: false })
  private isIntervalSelectPending: Props['isIntervalSelectPending'];

  @Watch('isDatepickerOpen')
  protected async onOpenStateChange() {
    if (!this.isDatepickerOpen) {
      this.datepickerStyle = {};
      return;
    }

    // FAQ: need because on first render after isDatepickerOpen = true,
    // timeframeCalendarRef is still unset
    await this.$nextTick();

    const { timeframeButtonRef, timeframeCalendarRef } = this.$refs;
    const datepickerWidth = timeframeCalendarRef.offsetWidth;

    const buttonRect = timeframeButtonRef.$el.getBoundingClientRect();
    const left = buttonRect.left - (datepickerWidth - buttonRect.width) / 2;

    this.datepickerStyle = {
      left: `${left}px`,
      top: `${buttonRect.bottom}px`,
    };
  }

  protected onCancel(e) {
    this.$emit('cancel', createEventPayload(e, undefined));
  }

  protected toggleDatepicker(e) {
    this.$emit('datepickerToggle', createEventPayload(e, !this.isDatepickerOpen));
  }

  protected renderDatepickerComponent() {
    // This is a case when Free mode triggers a datepicker range selection
    // It happens before mode is switched, so it should be handled separately
    if (this.isIntervalSelectPending) {
      return <DatepickerInterval
        max={this.max}
        min={this.min}
        onChange={({ payload }) => this.$emit('change', { payload })}
        onWindowResize={this.onCancel}
        selection={this.selection}
        intervalSelectionMode={IntervalSelectionMode.CUSTOM}
        timeZone={this.$timeZone.value}
      />;
    }

    switch (this.timeframeKind) {
      case TimeframeKind.MONTH:
        return <Monthpicker
          max={this.max}
          min={this.min}
          onChange={({ payload }) => this.$emit('change', { payload })}
          onWindowResize={this.onCancel}
          selection={this.selection}
          timeZone={this.$timeZone.value}
        />;
      case TimeframeKind.WEEK:
      case TimeframeKind.FREE:
        return <DatepickerInterval
          max={this.max}
          min={this.min}
          onChange={({ payload }) => this.$emit('change', { payload })}
          onWindowResize={this.onCancel}
          selection={this.selection}
          intervalSelectionMode={
            this.timeframeKind === TimeframeKind.FREE
              ? IntervalSelectionMode.CUSTOM
              : IntervalSelectionMode.WEEK
          }
          timeZone={this.$timeZone.value}
        />;
      case TimeframeKind.DAY:
        return <Datepicker
          max={this.max}
          min={this.min}
          onChange={({ payload }) => {
            const timeframe = getTimeframeFromDates(
              payload[0],
              payload[0],
              TimeframeKind.DAY,
              this.$timeZone.value,
            );
            this.$emit('change', {
            // convert it to SelectedTimeframe to match other emits
              payload: timeframe,
            });
          }}
          onWindowResize={this.onCancel}
          selection={[this.selection.startsAt]}
          selectionMode={SelectionMode.SINGLE}
          timeZone={this.$timeZone.value}
        />;
      default:
        return null;
    }
  }

  public render() {
    return (
      <div>
        <Button
          class={styles.controlsDatepicker}
          size={Size.SMALL}
          color={ButtonColor.SECONDARY}
          onClick={this.toggleDatepicker}
          ref="timeframeButtonRef"
        >
          <span class={styles.controlsDatepickerLabel}>
            {this.timeframeLabel}
          </span>
        </Button>

        <portal to={OVERLAY_PORTAL_NAME} disabled={!this.isDatepickerOpen}>
          {
            this.isDatepickerOpen && ([
              <OutsideClickHandler
                insideRef={() => this.$refs.timeframeCalendarRef}
                onOutsideClick={this.onCancel}
              />,
              <div
                class={styles.controlsDatepickerPopup}
                style={this.datepickerStyle}
                ref="timeframeCalendarRef"
              >
                {this.renderDatepickerComponent()}
              </div>,
            ])
          }
        </portal>
      </div>
    );
  }
}
