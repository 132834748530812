import { Component, Vue } from 'vue-property-decorator';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import styles from './pagination-mixin.css';
import { NEXT_PAGE } from './Actions';

export interface PaginationMixinProps {
  calendarNamespace?: CalendarNamespace;
}

@Component
export default class PaginationMixin extends Vue {
  protected visibleIds: number[] = [];

  protected observer: IntersectionObserver | null = null;

  private isButtonDisabled = false;

  // can be overwritten in parent components
  protected calendarNamespace = CalendarNamespace.CALENDAR;

  // #region storeMappings
  private get pageSize() {
    return this.$store.state[this.calendarNamespace].pagination.paginationPageSize;
  }

  private get page() {
    return this.$store.state[this.calendarNamespace].pagination.page;
  }

  private get isPaginationEnabled() {
    return this.$store.getters[`${this.calendarNamespace}/pagination/isPaginationEnabled`];
  }

  private nextPage() {
    this.$store.dispatch(`${this.calendarNamespace}/pagination/${NEXT_PAGE}`);
  }
  // endregion

  protected get paginatedItems(): any[] {
    throw new Error('not implemented');
  }

  public get visibleItems() {
    return this.isPaginationEnabled
      ? this.paginatedItems.slice(0, this.page * this.pageSize)
      : this.paginatedItems;
  }

  private get isAllVisible() {
    return this.paginatedItems.length === this.visibleItems.length;
  }

  private async onShowMoreClick() {
    /*
    prevent multiple clicks
    and show loading animation
    */
    this.isButtonDisabled = true;
    this.nextPage();
    await this.$nextTick();

    this.isButtonDisabled = false;
  }

  protected renderShowMore() {
    return this.isPaginationEnabled
      && !this.isAllVisible
      && (<section class={styles.paginationMixin}>
        <button
          disabled={this.isButtonDisabled}
          class={styles.paginationMixinButton}
          onClick={() => this.onShowMoreClick()}>
        show more
        </button>
      </section>);
  }

  private intersectionCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const id = parseInt((entry.target as HTMLElement).dataset.id!, 10);
      const isVisible = this.visibleIds.includes(id);
      if (entry.isIntersecting) {
        if (!isVisible) {
          this.visibleIds.push(id);
        }
      } else if (isVisible) {
        this.visibleIds.splice(this.visibleIds.indexOf(id), 1);
      }
    });
  }

  public mounted() {
    this.observer = new IntersectionObserver(
      entries => this.intersectionCallback(entries),
      {
        root: this.$el.parentElement,
        threshold: [0, 1],
      },
    );
    this.visibleIds.push(...this.paginatedItems.slice(10).map(it => it.id));
  }
}
