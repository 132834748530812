import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SelectedTimeframe } from 'components/datepicker/types';
import {
  Mode, SlotDisplayStyle, TimeframeKind, ViewKind,
} from 'components/calendar-common/Enums';
import { authNS } from 'components/auth/store/Store';
import type {
  HasAnyLocationRightFunction,
  HasAnyOfLocationsPositionsRightFunction,
  HasAnyRightFunction,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type { LocationsPosition } from 'store/locations-positions/Store';
import { shiftplanNotificationsWatchNS } from 'store/shiftplan-notifications/Store';
import type { StoreState } from 'store/shiftplan-notifications/Store';
import type { Shiftplan } from 'store/shiftplans/Store';
import { ShiftplanScopedSettings, uiSettingsNS } from 'store/ui-settings/Store';
import type { StoreState as UiSettingsStoreState } from 'store/ui-settings/Store';
import { calendarCommonNS } from 'components/calendar/common/Store';
import type { GetMultipleById } from 'utils/store';
import { sortBySortOrder } from 'src/utils/utils';
import { shiftScheduleNS } from '../store/Store';
import type { SetTimeframePayload } from '../store/Store';
import Action from '../store/Action';
import CalendarControls from './CalendarControls';

interface Props {
  shiftplan?: Shiftplan;
  settings?: ShiftplanScopedSettings;
}

interface Events {
  onFilterSidebarToggle: void;
}

@Component
export default class CalendarControlsContainer extends TsxComponent<Props, Events> {
  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  private settings: Props['settings'];

  @shiftScheduleNS.State
  private timeframeKind: TimeframeKind;

  @shiftScheduleNS.State
  private viewKind: ViewKind;

  @shiftScheduleNS.State
  private slotDisplayStyle: SlotDisplayStyle;

  @shiftScheduleNS.State
  private timeframe: SelectedTimeframe;

  @authNS.State
  public currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  public currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.State
  public currentLocationId: AuthStoreState['currentLocationId'];

  @authNS.Getter
  public hasAnyRight: HasAnyRightFunction;

  @authNS.Getter
  public hasAnyLocationRight: HasAnyLocationRightFunction;

  @authNS.Getter
  public hasAnyOfLocationsPositionsRight: HasAnyOfLocationsPositionsRightFunction;

  @authNS.Getter
  public hasAnyCurrentLocationRight: HasAnyRightFunction;

  @authNS.Getter
  public isSuperAdmin: boolean;

  @calendarCommonNS.Getter
  public mode: Mode;

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;

  @shiftScheduleNS.Action(Action.SET_SLOT_DISPLAY_STYLE)
  private setSlotDisplayStyle: (slotDisplayStyle: SlotDisplayStyle) => void;

  @shiftScheduleNS.Action(Action.SET_TIMEFRAME)
  private setTimeframe: (payload: SetTimeframePayload) => void;

  @shiftScheduleNS.Action(Action.SET_VIEW_KIND)
  private setViewKind: (viewKind: ViewKind) => void;

  @shiftplanNotificationsWatchNS.State('data')
  protected notificationsData: StoreState['watch']['data'];

  @uiSettingsNS.State('shared')
  protected uiSharedSettings: UiSettingsStoreState['shared'];

  protected get isEmployeeView() {
    return this.mode === Mode.EMPLOYEE;
  }

  protected get isAuthorisedToManageShiftplans(): boolean {
    const hasRight = this.hasAnyRight(
      'payments_manage_all',
      'shifts_manage_all',
    );
    const hasLocationRight = this.hasAnyLocationRight(
      this.currentLocationId,
      'payment_manage_right',
      'shift_manage_right',
    );

    return !!(this.isSuperAdmin || (
      this.currentEmployment?.isStakeholder && (hasRight || hasLocationRight))
    );
  }

  protected get isCalendarExportEnabled(): boolean {
    const hasExportCapability = this.timeframeKind === TimeframeKind.WEEK
      && [ViewKind.EMPLOYMENTS, ViewKind.POSITIONS].includes(this.viewKind);

    const locationsPositionIds = this.orderedLocationsPositions.map(({ id }) => id);
    const hasRights = this.isSuperAdmin
      || (
        !!this.currentEmployment?.isStakeholder
        && this.hasAnyOfLocationsPositionsRight(locationsPositionIds, 'shift_manage_right')
      );

    return hasExportCapability && hasRights;
  }

  protected get orderedLocationsPositions() {
    return this.getLocationsPositionsByLocationAndPositionId(this.shiftplan?.locationId)
      .filter(({ position }) => !!position)
      .sort(sortBySortOrder());
  }

  public render() {
    return (
      <CalendarControls
        isAuthorisedToManageShiftplans={this.isAuthorisedToManageShiftplans}
        isAuthorisedToFillShiftplans={!!this.currentCompany?.canFillShiftplans
          && this.isAuthorisedToManageShiftplans}
        isCalendarExportEnabled={this.isCalendarExportEnabled}
        isEmployeeView={this.isEmployeeView}
        onSlotDisplayStyleChange={({ payload }) => this.setSlotDisplayStyle(payload)}
        onTimeframeChange={({ payload }) => this.setTimeframe(payload)}
        onViewKindChange={({ payload }) => this.setViewKind(payload)}
        pendingNotifications={this.notificationsData?.shiftplanNotifications?.count}
        settings={this.settings}
        shiftplan={this.shiftplan}
        slotDisplayStyle={this.slotDisplayStyle}
        timeframe={this.timeframe}
        timeframeKind={this.timeframeKind}
        viewKind={this.viewKind}
        onFilterSidebarToggle={() => this.$emit('filterSidebarToggle')}
        isShiftplanFiltersSidebarCollapsed={
          !!this.uiSharedSettings.isShiftplanFiltersSidebarCollapsed
        }
      />
    );
  }
}
