import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLAbsencesQuery,
  GQLAbsencesQueryVariables,
  GQLEmploymentStatus,
} from 'codegen/gql-types';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { SentryTag } from 'services/logger/SentryTransport';
import Action from './Action';
import Mutation from './Mutation';
import AbsencesGql from '../../queries/Absences.gql';
import type { Absence } from '../../types';

export const absencesSummaryNS = namespace('absences/summary');

export interface StoreState {
  absences: Absence[];
}

const getAbsencesSummaryStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    absences: [],
  },
  getters: {
    filteredAbsenceReasons(state, getters, { absenceReasons: { byId } }) {
      return Object.values(byId).filter(
        (reason) => {
          const isDeleted = reason.deletedAt !== null;
          const isReferenced = () => state.absences.some(
            absence => absence.absenceReason.id === reason.id,
          );

          return !isDeleted || isReferenced();
        },
      );
    },
  },
  mutations: {
    [Mutation.SET_ABSENCES](state, payload) {
      state.absences = payload;
    },
  },
  actions: {
    async [Action.FETCH_SUMMARY_DATA](
      { commit, rootState },
      payload: {
        employmentId: number;
        employmentStatuses?: GQLEmploymentStatus[];
        endsAt?: Date;
        locationIds?: number[];
        startsAt?: Date;
        withAttachment?: boolean;
      },
    ) {
      if (!rootState.auth.currentCompanyId) {
        return false;
      }

      try {
        const variables = deepTransformDates({
          absenceReasonIds: null,
          companyId: rootState.auth.currentCompanyId,
          employmentIds: [payload.employmentId],
          employmentStatuses: payload.employmentStatuses || null,
          locationIds: payload.locationIds || null,
          endsAt: payload.endsAt || null,
          orderDir: null,
          orderKey: null,
          page: 1,
          paid: null,
          perPage: 10000,
          startsAt: payload.startsAt || null,
          states: null,
          withAttachment:
            typeof payload.withAttachment === 'boolean'
              ? payload.withAttachment
              : null,
        });

        /* eslint-disable @typescript-eslint/indent */
        const result = await graphqlClient
          .query<GQLAbsencesQuery, GQLAbsencesQueryVariables>({
            query: AbsencesGql,
            variables,
          });
        /* eslint-enable @typescript-eslint/indent */

        if (result.errors?.length) {
          logger.instance.error({
            message: JSON.stringify(result.errors),
            tags: [[SentryTag.ACTION, Action.FETCH_SUMMARY_DATA]],
          });

          return false;
        }

        commit(Mutation.SET_ABSENCES, result.data?.absences.items);

        return true;
      } catch (ex) {
        logger.instance.error(ex);
      }

      return false;
    },
  },
});

export default getAbsencesSummaryStore;
