import {
  addYears, isWithinInterval, startOfToday,
  eachMonthOfInterval, startOfYear, endOfYear, endOfMonth, startOfMonth, subMonths, subYears,
} from 'date-fns';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import {
  getDateInTimeZone,
  LOCALE_TIMEZONE,
  MAX_DATE,
  MIN_DATE,
} from 'src/utils/date-related';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { zonedTimeToUtc } from 'date-fns-tz';
import type { SelectedTimeframe } from 'components/datepicker/types';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import styles from './monthpicker.css';

interface Props {
  max?: Date;
  min?: Date;
  selection: SelectedTimeframe;
  timeZone?: string;
}
interface Events {
  onChange: EventPayload<SelectedTimeframe, HTMLButtonElement, MouseEvent>;
  onWindowResize: void;
}

@Component
export default class Monthpicker extends TsxComponent<Props, Events> {
  // first day of active month
  // FIXME: check if eslint override can be removed when refactoring Datepicker
  // eslint-disable-next-line no-restricted-syntax
  private startOfYear: Date = startOfYear(startOfToday());

  // assign object to enable reactivity
  private currentSelection: SelectedTimeframe = {
    startsAt: new Date(),
    endsAt: new Date(),
  };

  @Prop()
  public max: Props['max'];

  @Prop()
  public min: Props['min'];

  @Prop()
  public selection: Props['selection'];

  @Prop()
  public timeZone?: Props['timeZone'];

  @Watch('selection', { immediate: true })
  public onSelectionChange() {
    this.currentSelection = {
      startsAt: getDateInTimeZone(this.selection.startsAt, this.timeZoneValue),
      endsAt: getDateInTimeZone(this.selection.endsAt, this.timeZoneValue),
    };
  }

  private get maxDate() {
    return this.max
      ? getDateInTimeZone(this.max, this.timeZoneValue)
      : MAX_DATE;
  }

  private get minDate() {
    return this.min
      ? getDateInTimeZone(this.min, this.timeZoneValue)
      : MIN_DATE;
  }

  private get endOfYear() {
    // eslint-disable-next-line no-restricted-syntax
    return endOfYear(this.startOfYear);
  }

  protected get isPrevYearDisabled() {
    return this.minDate > subMonths(this.startOfYear, 1);
  }

  protected get isNextYearDisabled() {
    // eslint-disable-next-line no-restricted-syntax
    return this.startOfNextYear.valueOf() >= startOfMonth(this.maxDate).valueOf();
  }

  private get months() {
    return eachMonthOfInterval({
      start: this.startOfYear,
      end: this.endOfYear,
    }).map(month => ({
      // eslint-disable-next-line no-restricted-syntax
      name: month.toLocaleDateString(this.$i18n.i18next.language, { month: 'long' }),
      startOfMonth: month,
      // eslint-disable-next-line no-restricted-syntax
      endOfMonth: endOfMonth(month),
    }));
  }

  private get timeZoneValue() {
    return this.timeZone || LOCALE_TIMEZONE;
  }

  private get startOfNextYear() {
    return addYears(this.startOfYear, 1);
  }

  private setStartOfYear(date: Date) {
    this.startOfYear = date;
  }

  private onMonthClick(
    e: SyntheticEvent<HTMLButtonElement, MouseEvent>,
    startsAt: Date,
    endsAt: Date,
  ) {
    this.currentSelection = {
      startsAt,
      endsAt,
    };

    this.$emit('change', createEventPayload(e, {
      startsAt: zonedTimeToUtc(this.currentSelection.startsAt, this.timeZoneValue),
      endsAt: zonedTimeToUtc(this.currentSelection.endsAt, this.timeZoneValue),
    }));
  }

  private onWindowResize() {
    this.$emit('windowResize');
  }

  public mounted() {
    // https://shyftplan.atlassian.net/wiki/spaces/DEV/pages/2082930779/Working+with+Time+Zones#It%E2%80%99s-About-Time-(Shifting)
    // eslint-disable-next-line no-restricted-syntax
    this.startOfYear = startOfYear(this.currentSelection.startsAt);
    window.addEventListener('resize', this.onWindowResize, { passive: true });
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  public render() {
    const locale = this.$i18n.i18next.language;

    return (
      <div class={styles.monthpicker}>
        <header class={styles.monthpickerHeader}>
          <Button
            color={ButtonColor.SECONDARY}
            disabled={this.isPrevYearDisabled}
            icon={IconName.ARROW_BACK}
            aria-label={this.$t('monthpicker.labelPreviousYear')}
            onClick={() => this.setStartOfYear(subYears(this.startOfYear, 1))}
            size={Size.MEDIUM}
            kind={ButtonKind.GHOST}
            iconPosition={IconPosition.ALONE}
          />
          <span class={styles.monthpickerYearLabel}>
            {
              // FIXME: check if eslint override can be removed when refactoring Datepicker
              // eslint-disable-next-line no-restricted-syntax
              this.startOfYear.toLocaleDateString(locale, { year: 'numeric' })
            }
          </span>
          <Button
            color={ButtonColor.SECONDARY}
            disabled={this.isNextYearDisabled}
            icon={IconName.ARROW_NEXT}
            aria-label={this.$t('monthpicker.labelNextYear')}
            onClick={() => this.setStartOfYear(this.startOfNextYear)}
            size={Size.MEDIUM}
            kind={ButtonKind.GHOST}
            iconPosition={IconPosition.ALONE}
          />
        </header>

        <div class={styles.monthpickerGrid}>
          {
            this.months.map((month) => {
              const isWithinMinMax = isWithinInterval(month.startOfMonth, {
                start: this.minDate,
                end: this.maxDate,
              }) && isWithinInterval(month.endOfMonth, {
                start: this.minDate,
                end: this.maxDate,
              });

              const isWithinSelection = isWithinInterval(month.startOfMonth, {
                start: this.currentSelection.startsAt,
                end: this.currentSelection.endsAt,
              });

              return (
                <button
                  class={{
                    [styles.monthpickerMonth]: true,
                    [styles.monthpickerMonthSelected]: isWithinSelection,
                    [styles.monthpickerMonthDisabled]: !isWithinMinMax,
                  }}
                  disabled={!isWithinMinMax}
                  key={month.name}
                  onClick={e => this.onMonthClick(e, month.startOfMonth, month.endOfMonth)}
                  type="button"
                >
                  <span class={styles.monthpickerMonthLabel}>
                    {month.name}
                  </span>
                </button>
              );
            })
          }
        </div>
      </div>
    );
  }
}
