import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient, { ApolloQueryResult, ObservableQuery, OperationVariables } from 'apollo-client';
import { DocumentNode } from 'graphql';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { PayloadParameter } from 'utils/store';
import {
  ActionContext,
  ActionTree,
  Commit,
  Module,
} from 'vuex';
import { handleUnexpectedResult, isSuccessResult } from './normalized-store';

export const DEFAULT_POLLING_INTERVAL = 10000;

export interface WatchStoreState<T> {
  data?: T;
  subscription?: ZenObservable.Subscription;
  watchQuery?: ObservableQuery<T, OperationVariables>;
}

export enum Action {
  REFETCH = 'refetch',
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
}

export enum Mutation {
  SET_DATA = 'setData',
  SET_SUBSCRIPTION = 'setSubscription',
  SET_WATCH_QUERY = 'setWatchQuery',
}

export interface QueryDefinition<Query, Variables> {
  query: DocumentNode;
  resultKey: keyof Query;
  variables: Variables;
}

export interface QueryDefinitionWithTransform<
  T, Query, Variables> extends QueryDefinition<Query, Variables> {
  transform: (input: any) => T;
}

export type SubscribeAction<Payload> = (payload: Payload) => void;

export type RefetchAction<Payload> = (payload: Partial<Payload>) => void;

export type QueryProvider<
  Query = any,
  Variables = any,
  T = any,
  Context = ActionContext<any, any>,
> = <ActionFunction extends (...args: any) => any>(
  context: Context,
  payload: PayloadParameter<ActionFunction>,
) => QueryDefinition<Query, Variables> | QueryDefinitionWithTransform<T, Query, Variables>;

function createSubscription<Query, Variables, T>(
  commit: Commit,
  queryDefinition: QueryDefinition<Query, Variables>
  | QueryDefinitionWithTransform<T, Query, Variables>,
  watchQuery: WatchStoreState<T>['watchQuery'],
  logger: ApplicationLogger,
  transform?: QueryDefinitionWithTransform<T, Query, Variables>['transform'],
) {
  const onSubscription = (result: ApolloQueryResult<T>) => {
    const data = typeof transform === 'function' ? transform(result.data) : result.data;

    if (!isSuccessResult(result, queryDefinition.resultKey)) {
      handleUnexpectedResult('' as any, logger);
      return;
    }

    commit(Mutation.SET_DATA, data);
  };

  const subscription = watchQuery?.subscribe(onSubscription);

  commit(Mutation.SET_SUBSCRIPTION, subscription);
}

export function createWatchStore<T, StoreState, RootStoreState>(config: {
  query: QueryProvider;
  graphqlClient: ApolloClient<NormalizedCacheObject>;
  logger: ApplicationLogger;
  store: Module<StoreState, RootStoreState>;
  pollInterval?: number;
}): Module<StoreState & WatchStoreState<T>, RootStoreState> {
  const { state: otherState, namespaced } = config.store || {};

  const newState = {
    ...(otherState instanceof Function ? otherState() : otherState as StoreState),
    data: undefined,
    subscription: undefined,
    watchQuery: undefined,
  } as StoreState & WatchStoreState<T>;

  const newActions = {
    [Action.SUBSCRIBE](context, payload) {
      const queryDefinition = config.query(context, payload);
      const {
        query,
        variables,
      } = queryDefinition;

      const transform = 'transform' in queryDefinition
        ? queryDefinition.transform
        : undefined;

      if (context.state.watchQuery) {
        if (!context.state.subscription) {
          createSubscription(
            context.commit,
            queryDefinition,
            context.state.watchQuery,
            config.logger,
            transform,
          );
        }

        return;
      }

      const watchQuery = config.graphqlClient.watchQuery({
        query,
        variables,
        pollInterval: config.pollInterval || DEFAULT_POLLING_INTERVAL,
      });

      context.commit(Mutation.SET_WATCH_QUERY, watchQuery);
      createSubscription(context.commit, queryDefinition, watchQuery, config.logger, transform);
    },
    [Action.UNSUBSCRIBE](context) {
      if (!context.state.watchQuery || !context.state.subscription) {
        return;
      }

      context.state.subscription.unsubscribe();

      context.commit(Mutation.SET_SUBSCRIPTION, undefined);
    },
    async [Action.REFETCH]({ state }, payload) {
      if (!state.watchQuery || !state.subscription) {
        return;
      }

      state.watchQuery.stopPolling();
      await state.watchQuery.refetch(payload);
      state.watchQuery.startPolling(config.pollInterval || DEFAULT_POLLING_INTERVAL);
    },
  } as ActionTree<typeof newState, RootStoreState>;

  return {
    namespaced,
    state: newState,
    mutations: {
      ...config.store?.mutations,
      [Mutation.SET_DATA](state, data) {
        state.data = data;
      },
      [Mutation.SET_SUBSCRIPTION](state, subscription) {
        state.subscription = subscription;
      },
      [Mutation.SET_WATCH_QUERY](state, watchQuery) {
        state.watchQuery = watchQuery;
      },
    },
    getters: {
      ...config.store?.getters,
    },
    actions: {
      ...config.store?.actions,
      ...newActions,
    },
    modules: config.store?.modules,
  };
}
