import { positionsNS, Position } from 'store/positions/Store';
import FilterBox, { Slot as FilterBoxSlot } from 'components/filter-box/FilterBox';
import InputPillSelect from 'components/form/input-pill-select/InputPillSelect';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';

import { Option } from 'components/select-panel/SelectPanel';
import styles from './filter-positions.css';

export interface Props {
  value: number[];
}

interface Events {
  onChange: (value: number[]) => void;
  onReset: () => void;
}

@Component
class FilterPositions extends TSXComponent<Props, Events> {
  @Prop()
  public value: Props['value'];

  @positionsNS.Getter('items')
  private positions: Position[];

  private get positionOptions(): Option<string>[] {
    return this.positions.map(position => ({
      value: `${position.id}`,
      label: position.name,
      color: position.color,
    }));
  }

  private onReset() {
    this.$emit('reset');
  }

  private onChange(value) {
    this.$emit('change', value);
  }

  public render() {
    return (
      <FilterBox
        class={styles.filterPositions}
        isResetButtonShown={this.value.length > 0}
        resetButtonText={`${this.value.length}`}
        onResetClick={this.onReset}
      >
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('availabilityAggregations.filterPositions.header')}
        </template>

        <InputPillSelect
          class={styles.filterPositionsInput}
          placeholder={this.$t('availabilityAggregations.filterPositions.placeholder')}
          options={this.positionOptions}
          name="POSITIONS"
          value={this.value.map(item => `${item}`)}
          onChange={({ payload }) => this.onChange(payload.map(val => parseInt(val, 10)))}
        />
      </FilterBox>
    );
  }
}

export default FilterPositions;
