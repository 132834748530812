import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import SectionHeading from './section-heading/SectionHeading';

import styles from './form-section.css';

interface Props {
  context?: string;
  heading?: string;
  headingSummary?: string;
}

@Component
class FormSection extends TSXComponent<Props> {
  @Prop()
  public context: Props['context'];

  @Prop()
  public heading: Props['heading'];

  @Prop()
  public headingSummary: Props['headingSummary'];

  public render() {
    return (
      <section class={styles.formSection}>
        {
          this.heading && (
            <SectionHeading
              class={styles.formSectionHeading}
              context={this.context}
              summary={this.headingSummary}
            >
              {this.heading}
            </SectionHeading>
          )
        }

        <div class={styles.formSectionContent}>
          {this.$slots.default}
        </div>
      </section>
    );
  }
}

export default FormSection;
