import InputSelect from 'components/form/input-select/InputSelect';
import InputText from 'components/form/input-text/InputText';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import { IconPosition } from 'components/form/button/types';
import styles from './additional-qualification.css';
import { QualificationsSelectItem } from '../Qualifications';
import { ShiftQualificationInput } from '../../types';

export interface Props {
  qualification: ShiftQualificationInput;
  selectableQualifications: QualificationsSelectItem[];
  workersCount: number;
  isDisabled: boolean | undefined;
}

interface Events {
  onChange: <T extends 'qualificationId' | 'count'>(
    payload: EventPayload<
    { field: T; value: ShiftQualificationInput[T]; id: number },
    Element
    >
  ) => void;
  onDeleteQualificationClick: (
    payload: EventPayload<{ id: number }, Element>
  ) => void;
}

@Component
class AdditionalQualification extends TSXComponent<Props, Events> {
  @Prop()
  public qualification: Props['qualification'];

  @Prop()
  public selectableQualifications: Props['selectableQualifications'];

  @Prop()
  public workersCount: Props['workersCount'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  protected onInput(
    e: SyntheticEvent<Element>,
    qualificationId: number,
    field: string,
    value: string,
  ) {
    this.$emit(
      'change',
      createEventPayload(e, {
        id: qualificationId,
        value: parseInt(value, 10),
        field,
      }),
    );
  }

  private getErrorMessageForQualification(): string | undefined {
    if (this.qualification.count > this.workersCount) {
      return this.$t('shifts.qualifications.error.maxEmployees');
    }
    if (this.qualification.count === 0 || !this.qualification.count) {
      return this.$t('shifts.qualifications.error.minEmployeesRequired');
    }
    return undefined;
  }

  public render() {
    return (
      <div class={styles.additionalQualification}>
        <InputSelect
          isDisabled={this.isDisabled}
          class={styles.additionalQualificationSelect}
          label={this.$t('shifts.qualifications.labelSelectQualification')}
          name="QualificationId"
          onChange={(e) => {
            this.onInput(
              e.event,
              this.qualification.qualificationId,
              'qualificationId',
              e.payload.toString(),
            );
          }}
          options={this.selectableQualifications.map(qualification => ({
            ...qualification,
            label: qualification.name,
            value: qualification.id,
          }))}
          placeholder={this.$t(
            'shifts.qualifications.labelSelectQualification',
          )}
          required={true}
          value={this.qualification.qualificationId}
        />

        <InputText
          class={styles.additionalQualificationInput}
          name="employeeCount"
          label={this.$t('shifts.qualifications.labelRequiredEmployees')}
          type="number"
          min="1"
          disabled={this.isDisabled}
          max={`${this.workersCount}`}
          required={true}
          value={`${this.qualification.count}`}
          onInput={(e) => {
            this.onInput(
              e,
              this.qualification.qualificationId,
              'count',
              e.target.value,
            );
          }}
          error={this.getErrorMessageForQualification()}
        />

        { !this.isDisabled
          && <Button
            type="button"
            class={styles.additionalQualificationButtonDelete}
            color={ButtonColor.ERROR}
            icon={IconName.CLEAR}
            size={Size.MEDIUM}
            title={this.$t('shifts.qualifications.buttonDelete')}
            kind={ButtonKind.GHOST}
            iconPosition={IconPosition.ALONE}
            onClick={e => this.$emit(
              'deleteQualificationClick',
              createEventPayload(e, { id: this.qualification.qualificationId }),
            )
            }
          />
        }
      </div>
    );
  }
}

export default AdditionalQualification;
