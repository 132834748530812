import Collapsible from 'components/collapsible/Collapsible';
import { SelectedTimeframe } from 'components/datepicker/types';
import FormDialog from 'components/form/form-dialog/FormDialog';
import InputText from 'components/form/input-text/InputText';
import { LocationsPosition } from 'src/store/locations-positions/Store';
import { ShiftRotation } from 'src/store/shift-rotations/Store';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { TimeframeKind } from 'components/calendar-common/Enums';
import { getTimeframeFromDates } from 'utils/timeframe-helpers';
import Monthpicker from 'components/monthpicker/Monthpicker';
import DatepickerInterval, { IntervalSelectionMode } from 'components/datepicker/DatepickerInterval';
import { CreateShiftplanKind } from '../section-create-kind/SectionCreateKind';
import SectionPositions from '../section-positions/SectionPositions';
import SectionRotations from '../SectionRotations';
import SectionTimeFrameKind, { TimeFrameKind as ShiftplanTimeFrameKind } from '../section-time-frame-kind/SectionTimeFrameKind';

import styles from './create-shiftplan.css';

// required to make browser form validation work

export const timeFrameKindToTimeframeKindMap: Record<ShiftplanTimeFrameKind, TimeframeKind> = {
  [ShiftplanTimeFrameKind.WEEK]: TimeframeKind.WEEK,
  [ShiftplanTimeFrameKind.MONTH]: TimeframeKind.MONTH,
  [ShiftplanTimeFrameKind.CUSTOM]: TimeframeKind.FREE,
};

export interface FormState {
  createShiftplanKind: CreateShiftplanKind;
  endsAt: Date;
  name: string;
  positions: Record<number, {
    isDisabled?: boolean;
    workers: number;
  }>;
  rotationId: string;
  startsAt: Date;
  timeFrameKind: ShiftplanTimeFrameKind;
}

interface Props {
  formState: FormState;
  isShiftRotationEnabled?: boolean;
  positions: NonNullable<LocationsPosition['position']>[];
  shiftRotations: ShiftRotation[];
  formId: string;
  createShiftplanKind: CreateShiftplanKind
}

interface Events{
  onCloseClick: (payload: EventPayload<void>) => void;
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] }>,
  ) => void;
  onSubmit: (payload: EventPayload<void>) => void;
}

@Component
export default class CreateShiftplan extends TsxComponent<Props, Events> {
  @Prop()
  public readonly formState: Props['formState'];

  @Prop()
  public readonly isShiftRotationEnabled: Props['isShiftRotationEnabled'];

  @Prop()
  public readonly positions: Props['positions'];

  @Prop()
  public readonly shiftRotations: Props['shiftRotations'];

  @Prop()
  public readonly formId: Props['formId'];

  @Prop()
  public readonly createShiftplanKind: Props['createShiftplanKind'];

  protected get calendarSelectionMode(): IntervalSelectionMode {
    if (this.formState.timeFrameKind === ShiftplanTimeFrameKind.WEEK) {
      return IntervalSelectionMode.WEEK;
    }

    return IntervalSelectionMode.CUSTOM;
  }

  protected onCloseClick(e) {
    this.$emit('closeClick', createEventPayload(e, undefined));
  }

  protected onInput(e: SyntheticEvent<HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement
  | HTMLButtonElement>) {
    this.$emit('input', createEventPayload(e, { field: e.target.name, value: e.target.value }));
  }

  protected onShiftRotaitonChange({ event, payload }: EventPayload<string>) {
    this.$emit(
      'input',
      createEventPayload(event, { field: 'rotationId', value: payload }),
    );
  }

  protected onPositionsChange({ event, payload }: EventPayload<FormState['positions']>) {
    this.$emit('input', createEventPayload(event, { field: 'positions', value: payload }));
  }

  protected onSubmit(e: SyntheticEvent<HTMLFormElement>) {
    this.$emit('submit', createEventPayload(e, undefined));
  }

  protected onTimeFrameChange({ event, payload }: EventPayload<SelectedTimeframe>) {
    const { startsAt, endsAt } = getTimeframeFromDates(
      payload.startsAt,
      payload.endsAt,
      timeFrameKindToTimeframeKindMap[this.formState.timeFrameKind],
      this.$timeZone.value,
    );

    this.$emit('input', createEventPayload(event, { field: 'startsAt', value: startsAt }));
    this.$emit('input', createEventPayload(event, { field: 'endsAt', value: endsAt }));
  }

  public render() {
    return (
      <FormDialog
        id={this.formId}
        onSubmit={this.onSubmit}>

        <SectionTimeFrameKind
          onChange={({ event }) => { this.onInput(event); }}
          timeFrameKind={this.formState.timeFrameKind}
        />
        {
          this.formState.timeFrameKind === ShiftplanTimeFrameKind.MONTH
            ? <Monthpicker
              selection={{
                startsAt: this.formState.startsAt,
                endsAt: this.formState.endsAt,
              }}
              timeZone={this.$timeZone.value}
              class={styles.dialogCreateShiftplanDatepicker}
              onChange={this.onTimeFrameChange} />
            : <DatepickerInterval
              class={styles.dialogCreateShiftplanDatepicker}
              onChange={this.onTimeFrameChange}
              selection={{
                startsAt: this.formState.startsAt,
                endsAt: this.formState.endsAt,
              }}
              intervalSelectionMode={this.calendarSelectionMode}
              timeZone={this.$timeZone.value}
            />
        }
        <InputText
          label={this.$t('createShiftplan.modal.labelName')}
          name="name"
          onInput={this.onInput}
          required
          type="text"
          value={this.formState.name}
        />

        <Collapsible isOpen={this.createShiftplanKind === CreateShiftplanKind.ROTATION}>
          <SectionRotations
            rotations={this.shiftRotations}
            rotationId={this.formState.rotationId}
            onChange={this.onShiftRotaitonChange}
          />
        </Collapsible>

        <Collapsible isOpen={this.createShiftplanKind === CreateShiftplanKind.ROTATION}>
          <SectionPositions
            onChange={this.onPositionsChange}
            positions={this.positions}
            selection={this.formState.positions}
          />
        </Collapsible>
      </FormDialog>
    );
  }
}
