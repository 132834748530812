import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import SpIcon from 'components/sp-icon/SpIcon';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { Route } from 'components/shift-schedule/routes';
import { dayNotesNS } from 'store/day-notes/Store';
import DayNote from '../day-note/DayNote';
import styles from './day-notes.css';
import { VISIBLE_NOTES_COUNT, OpenStrategy, notesNS } from '../Store';
import DateItem from '../../common/DateItem';
import Action from '../Action';

// number of shown notes when notes are collapsed

@Component
export default class DayNotes extends TsxComponent<{
  notes: any[];
  date: DateItem;
  openStrategy?: OpenStrategy;
}> {
  // used when openStrategy is OpenStrategy.INDEPENDENT
  protected isOpen = false;

  @calendarCommonNS.State(state => state.shiftplan.id)
  protected shiftplanId: number;

  @notesNS.Action(Action.OPEN_DAY_NOTES)
  protected openAllDayNotes: Function;

  @notesNS.Action(Action.CLOSE_DAY_NOTES)
  protected closeAllDayNotes: Function;

  @dayNotesNS.Getter('canManage')
  private canManageNotes: boolean;

  @notesNS.State('isOpen')
  protected isAllOpen: boolean;

  @Prop({ default: () => [] })
  public notes: any[];

  @Prop({ default: () => ({}) })
  public date: DateItem;

  @Prop({ default: OpenStrategy.ALL })
  public openStrategy: OpenStrategy;

  protected get isNotesOpen() {
    return this.openStrategy === OpenStrategy.ALL ? this.isAllOpen : this.isOpen;
  }

  protected getNotes() {
    return this.notes.map(note => <DayNote note={note}/>);
  }

  protected getNotesPreview() {
    return this.notes.slice(0, VISIBLE_NOTES_COUNT).map(note => <DayNote note={note}/>);
  }

  protected openDayNotes() {
    if (this.openStrategy === OpenStrategy.ALL) {
      if (this.isNotesOpen) {
        this.closeAllDayNotes();
      } else {
        this.openAllDayNotes();
      }
    } else {
      this.isOpen = !this.isOpen;
    }
  }

  protected get labelShowMore() {
    const text = this.isNotesOpen
      ? `${this.$t('calendar.dayNotes.showLess')}`
      : `${this.notes.length - VISIBLE_NOTES_COUNT} ${this.$t('calendar.dayNotes.showMore')}`;
    const isVisible = (this.notes.length > VISIBLE_NOTES_COUNT);
    return isVisible && (
      <button
        onClick={() => this.openDayNotes()}
        class={[styles.dayNotesButton, styles.dayNotesMore, {
          [styles.dayNotesMoreIsEmpty]: this.notes.length === 0,
        }]}>
        <span class={styles.dayNotesButtonText}>
          <span>
            {text}
          </span>
          <SpIcon
            name={`chevron-${this.isNotesOpen ? 'up' : 'down'}`}
            class={styles.dayNotesButtonIcon}/>
        </span>
      </button>);
  }

  protected get buttonAddNote() {
    const isVisible = this.canManageNotes
    && this.date.isWithinShiftplan
    && (this.isNotesOpen || OpenStrategy.INDEPENDENT);
    return isVisible && (
      <button
        onClick={e => this.addNote(e)}
        class={[styles.dayNotesButton, styles.dayNotesAdd]}>
        <span class={styles.dayNotesButtonText}>
          <span>
            {this.$t('calendar.dayNotes.add')}
          </span>
          <SpIcon name="plus" class={styles.dayNotesButton}/>
        </span>
      </button>);
  }

  protected addNote(e) {
    e.stopPropagation();
    if (this.canManageNotes && this.date.isWithinShiftplan) {
      this.$router.push({
        name: Route.DAY_NOTE,
        params: {
          shiftplanId: this.shiftplanId.toString(),
        },
        query: {
          startsAt: this.date.dateKey,
        },
      });
    }
  }

  public render() {
    return (
      <div class={styles.dayNotesList}>
        {this.isNotesOpen ? this.getNotes() : this.getNotesPreview()}
        {this.labelShowMore}
        {this.buttonAddNote}
      </div>);
  }
}
