import Mutations from './Mutations';
import * as Actions from './Actions';

export interface StoreState {
  paginationPageSize: number;
  page: number;
}

const DEFAULT_CALENDAR_PAGINATION = 20;

export const store = () => ({
  namespaced: true,
  state: {
    paginationPageSize: DEFAULT_CALENDAR_PAGINATION,
    page: 1,
  },
  mutations: {
    [Mutations.SET_PAGE](state, page: number) {
      state.page = page;
    },
  },
  actions: {
    [Actions.NEXT_PAGE]({ commit, state }) {
      commit(Mutations.SET_PAGE, state.page + 1);
    },

    [Actions.RESET]({ commit }) {
      commit(Mutations.SET_PAGE, 1);
    },
  },
  getters: {
    isPaginationEnabled(state) {
      return typeof state.paginationPageSize === 'number' && state.paginationPageSize > 0;
    },
  },
});
