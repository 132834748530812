import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SelectedTimeframe } from 'components/datepicker/types';
import { TimeframeKind } from 'components/calendar-common/Enums';
import {
  endOf, getCurrentDateInTimeZone, startOf, toLocaleDateString, Unit,
} from 'utils/date-related';
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import type { Shiftplan } from 'src/store/shiftplans/Store';
import { createEventPayload, EventPayload } from 'utils/events';
import { getTimeframeFromDates } from 'utils/timeframe-helpers';
import { ButtonColor } from 'components/form/base-button/types';
import { Size } from 'components/types';
import Button from 'components/form/button/Button';
import ControlsDatepicker from '../controls-datepicker/ControlsDatepicker';
import styles from './timeframe-control.css';

interface Props {
  timeframeKind: TimeframeKind;
  timeframe: SelectedTimeframe;
  isDatepickerOpen: boolean;
  shiftplan?: Shiftplan;
  isIntervalSelectPending: boolean;
}

interface Events {
  onCancel: EventPayload<void>;
  onChange: EventPayload<SelectedTimeframe, HTMLButtonElement, MouseEvent>;
  onDatepickerToggle: EventPayload<boolean>;
}

@Component
export default class TimeframeControl extends TsxComponent<Props, Events> {
  @Prop()
  private shiftplan: Props['shiftplan'];

  @Prop()
  private isDatepickerOpen: Props['isDatepickerOpen'];

  @Prop()
  private timeframe: Props['timeframe'];

  @Prop()
  private timeframeKind: Props['timeframeKind'];

  @Prop({ default: false })
  private isIntervalSelectPending: Props['isIntervalSelectPending'];

  private get timeframeLabel() {
    const { startsAt, endsAt } = this.timeframe;
    const { language: locale } = this.$i18n.i18next;

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };

    // show year only if start and end dates are in different years
    const startsAtOptions: Intl.DateTimeFormatOptions = (
      startsAt.getFullYear() === endsAt.getFullYear()
    )
      ? { day: 'numeric', month: 'numeric' }
      : options;

    return `${toLocaleDateString(
      startsAt,
      locale,
      this.$timeZone.value,
      startsAtOptions,
    )} - ${toLocaleDateString(
      endsAt,
      locale,
      this.$timeZone.value,
      options,
    )}`;
  }

  private get maxDate() {
    if (!this.shiftplan) {
      return undefined;
    }

    return endOf(
      toDate(this.shiftplan.endsAt, { timeZone: this.$timeZone.value }),
      Unit.DAY,
      this.$timeZone.value,
    );
  }

  private get minDate() {
    if (!this.shiftplan) {
      return undefined;
    }

    return startOf(
      toDate(this.shiftplan.startsAt, { timeZone: this.$timeZone.value }),
      Unit.DAY,
      this.$timeZone.value,
    );
  }

  private onTodayClick(event) {
    const todayDate = zonedTimeToUtc(
      getCurrentDateInTimeZone(this.$timeZone.value),
      this.$timeZone.value,
    );
    const resultTimeframe = getTimeframeFromDates(
      todayDate,
      todayDate,
      this.timeframeKind,
      this.$timeZone.value,
    );

    this.$emit('change', createEventPayload(event, resultTimeframe));
  }

  public render() {
    return (
      <div class={styles.timeframeControl}>
        <Button
          class={styles.timeframeControlToday}
          color={ButtonColor.SECONDARY}
          onClick={this.onTodayClick}
          size={Size.SMALL}
        >
          <span>{this.$t('shiftSchedule.controls.today')}</span>
        </Button>
        <ControlsDatepicker
          isIntervalSelectPending={this.isIntervalSelectPending}
          timeframeKind={this.timeframeKind}
          selection={this.timeframe}
          timeframeLabel={this.timeframeLabel}
          max={this.maxDate}
          min={this.minDate}
          isDatepickerOpen={this.isDatepickerOpen}
          onChange={eventPayload => this.$emit('change', eventPayload)}
          onCancel={
            eventPayload => this.$emit('cancel', eventPayload)
          }
          onDatepickerToggle={
            eventPayload => this.$emit('datepickerToggle', eventPayload)
          }
          timeZone={this.$timeZone.value}
        />
      </div>
    );
  }
}
