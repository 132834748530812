import DateItem, { DateKey } from 'components/calendar-common/common/DateItem';
import DndParams from 'components/calendar-common/grid/DndParams';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import { Position } from 'components/calendar-common/positions/Position';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import SummaryCell, { SummaryCellColor } from 'components/calendar-common/summary/SummaryCell';
import {
  Component, Prop, Mixins,
} from 'vue-property-decorator';
import { TsxComponentAttrs } from 'vue-tsx-support';
import DaysShift from '../days-shift/DaysShift';
import styles from '../positions.css';

interface Props {
  isVisible: boolean;
  isLastRow: boolean;
  showSummary: boolean;
  position: Position;
  dates: DateItem[];
  shiftsByDates: Record<DateKey, Shift[]>;
}

interface Events {
  onDrop: any
}

@Component
class Row extends Mixins(ShiftViewActionsMixin) {
  protected _tsxattrs: TsxComponentAttrs<Props, Events>;

  @Prop()
  public isVisible: Props['isVisible'];

  @Prop()
  public isLastRow: Props['isLastRow'];

  @Prop()
  public showSummary: Props['showSummary'];

  @Prop()
  public position: Props['position'];

  @Prop()
  public dates: Props['dates'];

  @Prop()
  public shiftsByDates: Props['shiftsByDates'];

  // @Inject('bootstrapHovers')
  // private bootstrapHovers;

  private get cellScopedSlots() {
    return {
      cell: scope => this.shiftsByDates[scope.dateItem.dateKey]
        .map(shift => <DaysShift
          shift={shift} key={shift.id}
          onDrop={(...args) => this.$emit('drop', ...args)}/>),
    };
  }

  private get aggregationsTotal() {
    return Object.values(this.shiftsByDates)
      .flatMap(shifts => shifts.map(it => ({ demand: it.workers, working: it.staffShiftsLength })))
      .reduce((acc, it) => ({
        demand: acc.demand + it.demand,
        working: acc.working + it.working,
      }), {
        demand: 0,
        working: 0,
      });
  }

  private get aggregationsColor() {
    if (this.aggregationsTotal.working < this.aggregationsTotal.demand) {
      return SummaryCellColor.RED;
    }

    return this.aggregationsTotal.working === this.aggregationsTotal.demand
      ? SummaryCellColor.GREEN
      : SummaryCellColor.YELLOW;
  }

  public render() {
    return (<GridRow
      isVisible={this.isVisible}
      cellClass={gridStyles.gridTableContentCellPaddingBottom}
      isLastRow={this.isLastRow}
      dates={this.dates}
      dndParams={{
        ...DndParams,
        positionId: this.position.locationsPositionId,
      }}
      onCellClick={dateItem => this.onShiftCellClick(
        dateItem,
        undefined,
        this.position.locationsPositionId,
      )}
      scopedSlots= {this.cellScopedSlots}>

      <GridCell slot="label"
        isHeader={true}
        style={{ borderColor: this.position.color }}
        class={styles.positionCell}>
        <span class={styles.positionCellText}
          data-tooltip={true}
          title={this.position.name}>
          {this.position.name}
        </span>
      </GridCell>
      {
        this.showSummary
        && <SummaryCell slot="total"
          value={`${this.aggregationsTotal.working}/${this.aggregationsTotal.demand}`}
          color={this.aggregationsColor}/>
      }
    </GridRow>);
  }
}

export default Row;
