import { SelectedTimeframe } from 'components/datepicker/types';
import {
  addMonths,
  addWeeks,
  areIntervalsOverlapping,
  eachDayOfInterval,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatISO,
  getISOWeek,
  isBefore,
  isSameDay,
  isSameSecond,
  isWithinInterval,
  Locale,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfWeekYear,
  startOfYear,
} from 'date-fns';
import { toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const MINUTES_IN_HOUR = 60;
export const MSECS_IN_MINUTE = 60000;
export const MSECS_IN_HOUR = 3600000;
export const HOURS_IN_DAY = 24;
export const FALLBACK_WORKING_HOURS_IN_DAY = 8;
export const MSECS_IN_YEAR = 365 * HOURS_IN_DAY * MSECS_IN_HOUR;
export const MSECS_IN_TWO_WEEKS = 14 * HOURS_IN_DAY * MSECS_IN_HOUR;
export const MSECS_IN_DAY = 24 * MSECS_IN_HOUR;
export const MSECS_IN_TWO_DAYS = 48 * MSECS_IN_HOUR;
export const DAYS_IN_WEEK = 7;

export const MAX_DATE = new Date(8640000000000000);
export const MIN_DATE = new Date(-8640000000000000);

export const DATE_API_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxx";

export const LOCALE_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export enum Unit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

const startOfUnitFnMap = {
  [Unit.DAY]: startOfDay,
  [Unit.WEEK]: startOfWeek,
  [Unit.MONTH]: startOfMonth,
  [Unit.YEAR]: startOfYear,
};

const endOfUnitFnMap = {
  [Unit.DAY]: endOfDay,
  [Unit.WEEK]: endOfWeek,
  [Unit.MONTH]: endOfMonth,
  [Unit.YEAR]: endOfYear,
};

/**
 * @param {Date} date A date
 * @param {string} timeZone Time zone to convert date to
 * @returns An instance of `Date` shifted to current timeZone
 * calling valueof for such dates
 * do not return correct timestamp(valueOf() as they are assumed
 * to be in different time zone
 */
export function getDateInTimeZone(date: Date, timeZone: string) {
  return utcToZonedTime(date, timeZone);
}

/**
 * @param {string} timeZone Time zone to convert date to
 * @returns An instance of `Date` representing current time shifted to current timeZone
 * calling valueof for such dates
 * do not return correct timestamp(valueOf() as they are assumed
 * to be in different time zone)
 */
export function getCurrentDateInTimeZone(timeZone: string) {
  return getDateInTimeZone(new Date(), timeZone);
}

export const startOf = (date: Date, unit: Unit, timeZone: string, options?: {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
}) => (
  zonedTimeToUtc(startOfUnitFnMap[unit](utcToZonedTime(date, timeZone), options), timeZone)
);

export const endOf = (date: Date, unit: Unit, timeZone: string, options?: {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}) => (
  zonedTimeToUtc(endOfUnitFnMap[unit](utcToZonedTime(date, timeZone), options), timeZone)
);

/**
 * Format an instance of `Date` using a language sensitive representation in the specified
 * time zone.
 *
 * @param date An instance of `Date` to format
 * @param locale Locale to use for formatting. If omitted, use default locale of the JS runtime.
 * @param timeZone Time zone of the supplied date or time zone to convert to
 * @param options An object that contains one or more properties that specify comparison options.
 * @returns {string} Localized string in the specified time zone
 */
export const toLocaleString = (
  date: Date,
  locale: string | undefined,
  timeZone: string,
  options?: Intl.DateTimeFormatOptions,
) => (
  // FAQ: toLocaleDateString is intended here as the input date will be shifted beforehand
  // eslint-disable-next-line no-restricted-syntax
  getDateInTimeZone(date, timeZone).toLocaleString(locale, options)
);

/**
 * Format an instance of `Date` using a language sensitive representation of the date portion
 * in the specified time zone.
 *
 * @param date An instance of `Date` to format
 * @param locale Locale to use for formatting. If omitted, use default locale of the JS runtime.
 * @param timeZone Time zone of the supplied date or time zone to convert to
 * @param options An object that contains one or more properties that specify comparison options.
 * @returns {string} Localized date string in the specified time zone
 */
export const toLocaleDateString = (
  date: Date,
  locale: string | undefined,
  timeZone: string,
  options?: Intl.DateTimeFormatOptions,
) => (
  // FAQ: toLocaleDateString is intended here as the input date will be shifted beforehand
  // eslint-disable-next-line no-restricted-syntax
  getDateInTimeZone(date, timeZone).toLocaleDateString(locale, options)
);

/**
 * @param {string} str A date string which should be parseable by `new Date()`
 * @param {string} timeZone A time zone to parse date to
 * @returns An instance of `Date` for the parsed date string in company timeZone
 *
 * @throws `RangeError` is thrown if the input string is not parseable through `new Date()`
 */
export const parseDateString = (str: string, timeZone: string): Date => {
  const obj = parseISO(str);

  if (obj instanceof Date && !Number.isNaN(obj.valueOf())) {
    return getDateInTimeZone(obj, timeZone);
  }
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error('Unparseable date string given');
  }

  // return invalid date object and write a warning in non-production environments
  return new Date(NaN);
};

export const getDateTimeFormatter = (locale: string) => {
  const RESOLVED_OPTIONS = Intl.DateTimeFormat(locale).resolvedOptions();

  return new Intl.DateTimeFormat(
    locale,
    {
      day: RESOLVED_OPTIONS.day || '2-digit',
      hour: RESOLVED_OPTIONS.hour || '2-digit',
      minute: RESOLVED_OPTIONS.minute || '2-digit',
      month: RESOLVED_OPTIONS.month || '2-digit',
      year: RESOLVED_OPTIONS.year || 'numeric',
    },
  ).format;
};

export const getLocalizedWeekday = (date: Date, locale: string, options = {}) => (
  // FAQ: toLocaleDateString is intended here as it's intentionally left to the caller
  // to shift input dates beforehand if required
  // eslint-disable-next-line no-restricted-syntax
  date.toLocaleDateString(locale, { weekday: 'long', ...options })
);

export const getLocalizedMonthName = (date: Date, locale: string, options = {}) => (
  // FAQ: toLocaleDateString is intended here as it's intentionally left to the caller
  // to shift input dates beforehand if required
  // eslint-disable-next-line no-restricted-syntax
  date.toLocaleDateString(locale, { month: 'long', ...options })
);

export const sortByTime = (x: Date, y: Date) => {
  // FAQ: can safely use toLocaleTimeString as it will just be used for string comparison
  /* eslint-disable no-restricted-syntax */
  const timeX = x.toLocaleTimeString('en-US', { hour12: false });
  const timeY = y.toLocaleTimeString('en-US', { hour12: false });
  /* eslint-enable no-restricted-syntax */

  if (timeX < timeY) {
    return -1;
  }

  if (timeX > timeY) {
    return 1;
  }

  return 0;
};

export const getTimeString = (date: Date, timeZone = 'UTC'): string => {
  const isoString = formatISO(utcToZonedTime(date, timeZone));

  return isoString.substr(11, 5);
};

export const getDateString = (date: Date, timeZone = 'UTC'): string => {
  const isoString = formatISO(utcToZonedTime(date, timeZone));

  return isoString.substring(0, 10);
};

export const getLocalizedWeekdays = (locale: string, options = {}) => {
  // FAQ: startOfWeek can be used here as we only need the strings irrelevant of time zone
  // eslint-disable-next-line no-restricted-syntax
  const startOfWeekDate = startOfWeek(new Date(), {
    weekStartsOn: 1,
    ...options,
  });

  return Array.from({ length: 7 }, (_, index) => {
    const curDate = new Date(startOfWeekDate);

    curDate.setDate(startOfWeekDate.getDate() + index);

    return getLocalizedWeekday(curDate, locale, {
      weekday: 'narrow',
    });
  });
};

export const getMonthIntervals = (baseDate: Date, timeZone: string) => {
  const newYear = startOf(baseDate, Unit.YEAR, timeZone);
  return Array.from({ length: 12 }, (_, index) => {
    const monthBase = addMonths(newYear, index);

    const startsAt = startOf(monthBase, Unit.MONTH, timeZone);
    const endsAt = endOf(monthBase, Unit.MONTH, timeZone);

    return { startsAt, endsAt };
  });
};

export const getLocalizedMonthNames = (locale: string, options = {}) => {
  // FAQ: startOfWeek can be used here as we only need the strings irrelevant of time zone
  // eslint-disable-next-line no-restricted-syntax
  const startOfMonthDate = startOfYear(new Date());

  return Array.from({ length: 12 }, (_, index) => {
    const curDate = new Date(startOfMonthDate);

    curDate.setMonth(startOfMonthDate.getMonth() + index);

    return getLocalizedMonthName(curDate, locale, options);
  });
};

export const getDurationString = (
  locale: string,
  startsAt: Date,
  endsAt: Date,
  isTimeIncluded = false,
) => {
  if (isTimeIncluded) {
    const format = getDateTimeFormatter(locale);

    return `${format(startsAt)} – ${format(endsAt)}`;
  }

  // FAQ: toLocaleDateString is intended here as it's intentionally left to the caller
  // to shift input dates beforehand if required
  // eslint-disable-next-line no-restricted-syntax
  return `${startsAt.toLocaleDateString(locale)} – ${endsAt.toLocaleDateString(locale)}`;
};

export const getDurationStringInTimeZone = (
  timeZone: string,
  locale: string,
  startsAt: Date,
  endsAt: Date,
  isTimeIncluded = false,
) => getDurationString(
  locale,
  getDateInTimeZone(startsAt, timeZone),
  getDateInTimeZone(endsAt, timeZone),
  isTimeIncluded,
);

/**
 * Combine date and separate (optional) time string into one date object.
 * Note that if a time string is given, it will override a potentially existing
 * time component in the date string.
 *
 * @param {String} dateStr Any date string parseable by `Date`
 * @param {String} timeStr A time string in 24 hour format
 * @param {String} timeZone A time zone to parse date to
 * @returns {Date} Composite date object made up of dateStr and timeStr
 */
// eslint-disable-next-line default-param-last
export const getDateFromDateAndTimeString = (dateStr: string, timeStr = '00:00:00', timeZone?: string): Date => (
  toDate(`${dateStr.substr(0, 10)}T${timeStr}`, { timeZone })
);

interface Intervaled {
  startsAt: Date | string | null | undefined;
  endsAt: Date | string | null | undefined;
}

export function getItemsOverlappingInterval<T extends Intervaled>(
  items: T[],
  startsAt: Intervaled['startsAt'],
  endsAt: Intervaled['endsAt'],
  timeZone: string,
): T[] {
  if (!startsAt && !endsAt) {
    return [];
  }

  const startsAtDate = startsAt ? getDateInTimeZone(new Date(startsAt), timeZone) : MIN_DATE;
  const endsAtDate = endsAt ? getDateInTimeZone(new Date(endsAt), timeZone) : MAX_DATE;

  return items.filter((item) => {
    if (!item.startsAt && !item.endsAt) {
      return true;
    }

    const itemStartsAt = item.startsAt
      ? getDateInTimeZone(new Date(item.startsAt), timeZone) : MIN_DATE;
    const itemEndsAt = item.endsAt
      ? getDateInTimeZone(new Date(item.endsAt), timeZone) : MAX_DATE;

    return areIntervalsOverlapping(
      { start: startsAtDate, end: endsAtDate },
      { start: itemStartsAt, end: itemEndsAt },
    );
  });
}

export function getItemsCoveringInterval<T extends Intervaled>(
  items: T[],
  startsAt: Intervaled['startsAt'],
  endsAt: Intervaled['endsAt'],
  timeZone: string,
) {
  const startsAtDate = startsAt ? getDateInTimeZone(new Date(startsAt), timeZone) : MIN_DATE;
  const endsAtDate = endsAt ? getDateInTimeZone(new Date(endsAt), timeZone) : MAX_DATE;

  return items.filter((item) => {
    const interval = {
      start: item.startsAt ? getDateInTimeZone(new Date(item.startsAt), timeZone) : MIN_DATE,
      end: item.endsAt ? getDateInTimeZone(new Date(item.endsAt), timeZone) : MAX_DATE,
    };

    return isWithinInterval(startsAtDate, interval) && isWithinInterval(endsAtDate, interval);
  });
}

export const getHourMinuteDurationString = (milliseconds: number, isNegativeAllowed?: boolean) => {
  const positiveDuration = isNegativeAllowed ? Math.abs(milliseconds) : Math.max(0, milliseconds);
  const h = Math.floor(positiveDuration / MSECS_IN_HOUR);
  const m = Math.floor((positiveDuration % MSECS_IN_HOUR) / MSECS_IN_MINUTE);

  const sign = isNegativeAllowed && milliseconds < 0 ? '-' : '';

  return `${sign}${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
};

export const getISOWeekDurationString = (locale: string, weekNo: number, year: number) => {
  // FAQ: start in the middle of the year just to be safe
  const yearRef = new Date(year, 5);

  // FAQ: startOfWeekYear can be used here as time zone is irrelevant in this case
  // eslint-disable-next-line no-restricted-syntax
  const yearStart = startOfWeekYear(yearRef, {
    weekStartsOn: 1,
    firstWeekContainsDate: 4,
  });

  const isoWeek = getISOWeek(yearStart) !== 1 ? weekNo : weekNo - 1;
  const startsAt = addWeeks(yearStart, isoWeek);

  // FAQ: endOfISOWeek can be used here as time zone is irrelevant in this case
  // eslint-disable-next-line no-restricted-syntax
  const endsAt = endOfISOWeek(startsAt);

  return getDurationString(locale, startsAt, endsAt);
};

export function hasAlreadyStarted<T extends Intervaled>(item: T | null |
undefined) {
  if (!item || !item.startsAt) {
    return false;
  }

  return (new Date(item.startsAt)).valueOf() < (new Date()).valueOf();
}

export function isStartBeforeEnd(
  start: Date | undefined,
  end: Date | undefined,
  isSameAllowed = false,
) {
  if (!(start instanceof Date && end instanceof Date)) {
    return false;
  }

  return isBefore(start, end) || (isSameAllowed && isSameSecond(start, end));
}

export function getDurationSum<T extends { startsAt: Date | string; endsAt: Date | string }>(
  items: T[],
) {
  return items.reduce((sum, cur) => (
    sum + new Date(cur.endsAt).valueOf() - new Date(cur.startsAt).valueOf()
  ), 0);
}

export const isSameDayInTimeZone = (start: Date, end: Date, timeZone: string) => (
  isSameDay(
    getDateInTimeZone(start, timeZone),
    getDateInTimeZone(end, timeZone),
  )
);

export const isSameInterval = (intervalA: SelectedTimeframe, intervalB: SelectedTimeframe) => (
  intervalA.startsAt.getTime() === intervalB.startsAt.getTime()
    && intervalA.endsAt.getTime() === intervalB.endsAt.getTime()
);

export const eachDayOfTimeframe = (timeframe: SelectedTimeframe, timeZone: string) => {
  const { startsAt, endsAt } = timeframe;

  // we need to make sure that we are not including extra days
  return eachDayOfInterval({
    start: getDateInTimeZone(startsAt, timeZone),
    end: getDateInTimeZone(endsAt, timeZone),
    // convert dates back to "correct moment of time"
  }).map(it => zonedTimeToUtc(it, timeZone));
};
