export const DATE_EXPORT_FORMAT = 'yyyy-MM-dd';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildSpreadSheetInit = (email: string, token: string, querySearchParams: string) => ({
  method: 'POST',
  headers: {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Authorization: `Basic ${window.btoa(`${email}:${token}`)}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  credentials: 'include',
  body: querySearchParams,
});

export const downloadFile = async (
  filename: string,
  downloadFromUrl: string,
  init: {[key: string]: any },
) => {
  // create hidden anchor element and trigger click to download
  const anchorElement = document.createElement('a');
  anchorElement.download = filename;

  const response = await fetch(downloadFromUrl, init);
  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);
  anchorElement.href = objectUrl;
  anchorElement.click();
  URL.revokeObjectURL(objectUrl);
};
