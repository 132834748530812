import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import InputText from 'components/form/input-text/InputText';
import InputDateTime, { Kind } from 'components/form/input-date-time/InputDateTime';
import { ShiftPreset, shiftPresetsNS } from 'src/store/shift-presets/Store';
import InputCombobox from 'components/form/input-combobox/InputCombobox';
import { createEventPayload, EventPayload } from 'src/utils/events';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import {
  addHours,
  differenceInHours,
  formatISO,
  isAfter,
  addDays,
} from 'date-fns';
import { authNS, StoreState } from 'components/auth/store/Store';
import type { GetById } from 'src/utils/store';
import { LocationsPosition, locationsPositionsNS } from 'src/store/locations-positions/Store';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import { getDateFromDateAndTimeString } from 'src/utils/date-related';
import type { GQLShiftPreset } from 'codegen/gql-types';
import type { FormState } from '../types';
import styles from './section-general.css';

export const CUSTOM_PRESET_ID = 0;

export const CUSTOM_PRESET: Pick<
GQLShiftPreset,
'breakTime'
| 'startsAtTime'
| 'endsAtTime'
| 'shiftEvaluationTagIds'
| 'tagIds'
> = {
  breakTime: 0,
  startsAtTime: '09:00',
  endsAtTime: '17:00',
  shiftEvaluationTagIds: [],
  tagIds: [],
};

export interface Props {
  selectableLocationsPositions: LocationsPosition[];
  selectableShiftPresets: ShiftPreset[];
  startsAt: Date;
  endsAt: Date;
  workersCount: number;
  locationsPositionId: number | null;
  shiftPresetId: number | null;
  shiftplanStartsAt: Date;
  shiftplanEndsAt: Date;
  isDisabled?: boolean;
  isValidated?: boolean;
}

export interface Events {
  onChange: <T extends keyof FormState>(e: EventPayload<{ field: T; value: FormState[T] }>) => void;
}

@Component
class SectionGeneral extends TSXComponent<Props, Events> {
  @Prop()
  public selectableLocationsPositions: Props['selectableLocationsPositions'];

  @Prop()
  public selectableShiftPresets: Props['selectableShiftPresets'];

  @Prop()
  public startsAt: Props['startsAt'];

  @Prop()
  public endsAt: Props['endsAt'];

  @Prop()
  public shiftplanStartsAt: Props['shiftplanStartsAt'];

  @Prop()
  public shiftplanEndsAt: Props['shiftplanEndsAt'];

  @Prop()
  public workersCount: Props['workersCount'];

  @Prop()
  public locationsPositionId: Props['locationsPositionId'];

  @Prop()
  public shiftPresetId: Props['shiftPresetId'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public isValidated: Props['isValidated'];

  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  @shiftPresetsNS.Getter('getById')
  protected getShiftPresetById: GetById<GQLShiftPreset>;

  @locationsPositionsNS.Getter('getById')
  protected getLocationsPositionById: GetById<LocationsPosition>;

  private get shiftPreset() {
    return this.getShiftPresetById(this.shiftPresetId);
  }

  private get locationsPosition() {
    return this.getLocationsPositionById(this.locationsPositionId);
  }

  private onPresetChange(e: EventPayload<string, HTMLInputElement, UIEvent>) {
    const inputValue = parseInt(e.payload, 10);

    this.onInput('shiftPresetId', inputValue, e.event);
    const newPreset = this.getShiftPresetById(inputValue) || CUSTOM_PRESET;

    const startsAt = getDateFromDateAndTimeString(
      formatISO(this.startsAt),
      newPreset.startsAtTime,
      this.$timeZone.value,
    );

    // presets always stick to one day, therefore base endDate on startDate
    let endsAt = getDateFromDateAndTimeString(
      formatISO(startsAt),
      newPreset.endsAtTime,
      this.$timeZone.value,
    );

    // for special cases like presets ending at or lasting until after midnight
    if (isAfter(startsAt, endsAt)) {
      endsAt = addDays(endsAt, 1);
    }

    this.onInput('startsAt', startsAt, e.event);
    this.onInput('endsAt', endsAt, e.event);
    this.onInput('unpaidBreak', newPreset.breakTime, e.event);
    this.onInput('tagIds', newPreset.tagIds, e.event);
    this.onInput('shiftEvaluationTagIds', newPreset.shiftEvaluationTagIds, e.event);

    // For custom shift preset, remove shiftRotationGroupIds
    if (inputValue === CUSTOM_PRESET_ID) {
      this.onInput('shiftRotationGroupIds', [], e.event);
    }
  }

  private onStartsAtInput(
    value: FormState['startsAt'],
    e?: SyntheticEvent,
  ) {
    this.onInput('startsAt', value, e);

    if (isAfter(value, this.endsAt)) {
      this.onInput('endsAt', addHours(value, differenceInHours(this.endsAt, this.startsAt)));
    }
  }

  protected onInput<T extends keyof FormState>(field: T, value: FormState[T], e?: SyntheticEvent) {
    this.$emit('change', createEventPayload(e as SyntheticEvent, { field, value }));
  }

  public render() {
    return (
      <FormSection heading={this.$t('shifts.dialog.headingInformation')}>
        <InputCombobox
          isValid={!this.isValidated || !!this.locationsPosition }
          error={
            this.isValidated
            && !this.locationsPosition
            && this.$t('shifts.dialog.errorLocationPositionRequired')
          }
          placeholder={this.$t('shifts.dialog.placeholderPosition')}
          isDisabled={this.isDisabled}
          class={[styles.sectionGeneralRow, styles.sectionGeneralRowFullWidth]}
          label={this.$t('shifts.dialog.labelPosition')}
          value={this.locationsPosition?.id.toString()}
          onChange={ (e: EventPayload<string, HTMLInputElement, UIEvent>) => {
            this.onInput('locationsPositionId', parseInt(e.payload, 10), e.event);
          } }
          options={
            this.selectableLocationsPositions.map(locPos => ({
              value: locPos.id.toString(),
              label: locPos.position?.name || this.$t('shifts.dialog.fallbackPosition'),
              data: locPos,
            }))
          }
        />

        {
          this.currentCompany?.canUseShiftPresets
          && this.selectableShiftPresets.length > 0
          && (
            <InputCombobox
              placeholder={this.$t('shifts.dialog.placeholderShiftPreset')}
              isDisabled={this.isDisabled}
              label={this.$t('shifts.dialog.labelShiftPreset')}
              class={[styles.sectionGeneralRow, styles.sectionGeneralRowFullWidth]}
              value={this.shiftPresetId?.toString() || CUSTOM_PRESET_ID.toString()}
              style={{ color: this.shiftPreset?.color || 'unset' } as CSSStyleDeclaration}
              onChange={this.onPresetChange}
              options={[
                ...this.selectableShiftPresets.map(preset => ({
                  label: preset.shortName
                    ? `${preset.shortName}, ${preset.name}`
                    : preset.name,
                  value: preset.id.toString(),
                  style: { color: preset.color },
                })),
                {
                  value: CUSTOM_PRESET_ID.toString(),
                  label: this.$t('shifts.dialog.customShiftPreset'),
                },
              ]}
            />
          )
        }

        <InputDateTime
          class={[styles.sectionGeneralRow, styles.sectionGeneralRowSideBySide]}
          datepickerLabel={this.$t('shifts.dialog.labelStartsAtDate')}
          isDatepickerDisabled={this.isDisabled}
          isTimepickerDisabled={this.isDisabled || !!this.shiftPreset}
          kind={Kind.DATETIME}
          max={this.shiftplanEndsAt}
          min={this.shiftplanStartsAt}
          required
          timepickerLabel={this.$t('shifts.dialog.labelStartsAtTime')}
          timeZone={this.$timeZone.value}
          value={this.startsAt}
          onInput={ (e: EventPayload<{ value: Date }>) => (
            this.onStartsAtInput(e.payload.value, e.event)
          )}
        />

        <InputDateTime
          class={[styles.sectionGeneralRow, styles.sectionGeneralRowSideBySide]}
          datepickerLabel={this.$t('shifts.dialog.labelEndsAtDate')}
          isDatepickerDisabled={this.isDisabled}
          isTimepickerDisabled={this.isDisabled || !!this.shiftPreset}
          kind={Kind.DATETIME}
          max={this.shiftplanEndsAt}
          min={this.startsAt}
          required
          timeZone={this.$timeZone.value}
          timepickerLabel={this.$t('shifts.dialog.labelEndsAtTime')}
          value={this.endsAt}
          onInput={ (e: EventPayload<{ value: Date }>) => (
            this.onInput('endsAt', e.payload.value, e.event)
          )}
        />

        <InputText
          isValid={!this.isValidated || !Number.isNaN(this.workersCount)}
          error={
            this.isValidated
            && Number.isNaN(this.workersCount)
            && this.$t('shifts.dialog.errorNumberOfEmployeesRequired')
          }
          disabled={this.isDisabled}
          class={styles.sectionGeneralRow}
          value={`${this.workersCount}`}
          label={this.$t('shifts.dialog.labelNumberOfEmployees')}
          type="number"
          min="0"
          onInput={(e: SyntheticEvent<HTMLInputElement, Event>) => (
            this.onInput('workers', parseInt(e.target.value, 10), e)
          )}
        />
      </FormSection>
    );
  }
}

export default SectionGeneral;
