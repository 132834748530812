import { Component, Prop, Vue } from 'vue-property-decorator';
import SpIcon from '../../../sp-icon/SpIcon';
import styles from './toggle-cell.css';

@Component
class ToggleCell extends Vue {
  @Prop({ default: false })
  public disabled: boolean;

  @Prop({ default: false })
  public isOpen: boolean;

  @Prop({ default: '' })
  public title: string;

  public render() {
    return (<button class={styles.toggleCellToggle}
      disabled={this.disabled}
      onClick={() => this.$emit('toggle')}>
      <span class={styles.toggleCellToggleText}
        data-tooltip={true}
        title={this.title}>
        {this.$slots.default}
      </span>
      {!this.disabled
      && (<SpIcon
        name={`chevron-${this.isOpen ? 'up' : 'down'}`}
        class={styles.toggleCellToggleIcon}/>)
      }
    </button>);
  }
}

export default ToggleCell as any;
