/* eslint-disable import/prefer-default-export */

/**
 * Adapted from https://github.com/berlinmagic/EinsZweiDrei/blob/master/lib/dragonfly/image_magick/generators/square_text.rb
 * which was used in sppt
 */
export const getRandomPastelColor = () => (
  ['#', ...Array.from({ length: 3 }, () => (
    Math.floor(Math.random() * 92 + 152).toString(16).padStart(2, '0')
  ))].join('')
);

export const hexToRgba = (hexColor: string, alpha = 1) => {
  const hexWithoutHash = hexColor.substr(1);

  if (hexWithoutHash.length !== 6) {
    throw new Error('only hex values with the length of 6 are supported');
  }

  const r = parseInt(hexWithoutHash.slice(0, 2), 16);
  const g = parseInt(hexWithoutHash.slice(2, 4), 16);
  const b = parseInt(hexWithoutHash.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
