import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './indicator.css';

export enum AvailabilityState {
  AVAILABLE,
  PARTLY_AVAILABLE,
  UNAVAILABLE,
  PARTLY_UNAVAILABLE,
  PARTLY_BOTH,
  UNKNOWN,
}

const styleMapping: Record<AvailabilityState, string | undefined> = {
  [AvailabilityState.AVAILABLE]: styles.indicatorAvailable,
  [AvailabilityState.PARTLY_AVAILABLE]: styles.indicatorPartlyAvailable,
  [AvailabilityState.UNAVAILABLE]: styles.indicatorUnavailable,
  [AvailabilityState.PARTLY_UNAVAILABLE]: styles.indicatorPartlyUnavailable,
  [AvailabilityState.PARTLY_BOTH]: styles.indicatorPartlyBoth,
  [AvailabilityState.UNKNOWN]: undefined,
};

@Component
export default class Indicator extends TsxComponent<{
  state: AvailabilityState;
}> {
  @Prop()
  public state: AvailabilityState;

  public render() {
    return (
      <span class={[styles.indicator, styleMapping[this.state]]}>
        {this.$slots.default}
      </span>
    );
  }
}
