import RootStoreState from 'src/store/RootStoreState';
import Vue from 'vue';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import {
  GQLCreateEmploymentsRotationGroupMutation,
  GQLCreateIndividualShiftRotationMutation,
  GQLCreateShiftRotationGroupMutation,
  GQLCreateShiftRotationMutation,
  GQLDeleteEmploymentsRotationGroupMutation,
  GQLFetchShiftRotationQuery,
  GQLFetchShiftRotationQueryVariables,
  GQLMutationCreateEmploymentsRotationGroupArgs,
  GQLMutationCreateIndividualShiftRotationArgs,
  GQLMutationCreateShiftRotationArgs,
  GQLMutationCreateShiftRotationGroupArgs,
  GQLMutationDeleteEmploymentsRotationGroupArgs,
  GQLMutationUpdateShiftRotationArgs,
  GQLMutationUpdateShiftRotationGroupArgs,
  GQLShiftRotation,
  GQLUpdateShiftRotationGroupMutation,
  GQLUpdateShiftRotationMutation,
} from 'codegen/gql-types';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import { parse } from 'date-fns';
import Action from './Action';
import Mutation from './Mutation';
import { RESTING_DAY } from './types';
import type { RotationDayPreset, RotationGroupInput } from './types';
import CreateEmploymentsRotationGroupGql from '../queries/CreateEmploymentsRotationGroup.gql';
import CreateIndividualShiftRotationGql from '../queries/CreateIndividualShiftRotation.gql';
import CreateShiftRotationGql from '../queries/CreateShiftRotation.gql';
import UpdateShiftRotationGql from '../queries/UpdateShiftRotation.gql';
import CreateShiftRotationGroupGql from '../queries/CreateShiftRotationGroup.gql';
import UpdateShiftRotationGroupGql from '../queries/UpdateShiftRotationGroup.gql';
import DeleteEmploymentsRotationGroupGql from '../queries/DeleteEmploymentsRotationGroup.gql';
import FetchShiftRotationGql from '../queries/FetchShiftRotation.gql';
import getTableStore, { StoreState as EmploymentsTableStoreState } from '../employment-assignment/store/Store';

export const rotationWizardNS = namespace('rotationWizard');

export const transformRotationDayPreset = (day: RotationDayPreset) => (
  (!day || day === RESTING_DAY) ? 0 : day.id
);

export interface StoreState {
  rotationName: string;
  rotationId: number | null;
  rotationGroupsLength: number;
  rotationInterval: number;
  anchorDate: string;
  rotationGroups: RotationGroupInput[];
  startsAt: string | null;
  endsAt: string | null;
}

export interface ModuleState extends StoreState {
  employmentAssignment: EmploymentsTableStoreState;
}

const getInitialState = () => ({
  rotationName: '',
  rotationId: null,
  rotationGroupsLength: 0,
  rotationInterval: 0,
  anchorDate: '',
  rotationGroups: [],
  employmentsRotationsMap: [],
  startsAt: null,
  endsAt: null,
});

const getRotationWizardStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => {
  const store: Module<StoreState, RootStoreState> = {
    namespaced: true,
    state: getInitialState(),
    mutations: {
      [Mutation.SET_VALUE](state, payload) {
        state[payload.name] = payload.value;
      },

      [Mutation.SET_ROTATION_GROUPS](state, groups: RotationGroupInput[]) {
        state.rotationGroups = groups;
      },

      [Mutation.SET_ROTATION_GROUP_ID](
        state,
        { groupIndex, id }: { groupIndex: number; id: number },
      ) {
        state.rotationGroups[groupIndex].id = id;
      },
      [Mutation.SET_ROTATION](
        state,
        shiftRotation: GQLShiftRotation,
      ) {
        state.anchorDate = shiftRotation.anchorDate;
        state.rotationGroupsLength = shiftRotation.shiftRotationGroups.length;
        state.rotationName = shiftRotation.name;
        state.rotationId = shiftRotation.id;
        state.rotationInterval = shiftRotation.rotationInterval;
      },
      [Mutation.SET_ROTATION_ID](
        state,
        id: number,
      ) {
        state.rotationId = id;
      },

      [Mutation.SET_ROTATION_GROUP_NAME](
        state,
        { groupIndex, name }: { groupIndex: number; name: string },
      ) {
        state.rotationGroups[groupIndex].name = name;
      },

      [Mutation.SET_ROTATION_DAY](state, {
        groupIndex,
        dayIndex,
        preset,
      }:
      {
        groupIndex: number;
        dayIndex: number;
        preset: RotationDayPreset;
      }) {
        Vue.set(state.rotationGroups[groupIndex].days, dayIndex, preset);
      },
      // TODO: replace later if/when we include withReset?
      [Mutation.RESET](state, initialState) {
        Object.assign(state, initialState);
      },
    },
    getters: {
      isGeneralSetupValid: state => state.rotationName.length > 0
        && Number.isInteger(state.rotationGroupsLength)
        && state.rotationGroupsLength > 0
        && state.rotationInterval > 0
        && !!state.anchorDate
        && state.rotationGroups.length > 0,
      isGeneralSetupIndividualValid: state => state.rotationInterval > 0
        && !!state.startsAt
        && !!state.endsAt
        && state.rotationGroups.length > 0,
      isRotationPatternSetupValid: state => state.rotationGroups.length > 0
        && state.rotationGroups.every(
          // at least one day must have a preset != RESTING_DAY for every group
          group => group.days.some(day => day && day !== RESTING_DAY),
        ),
      isEmployeeAssignmentValid: () => false,
      rotationGroupNames(state) {
        return state.rotationGroups.map(it => it.name);
      },
      existingShiftRotationGroupsLength: state => state.rotationGroups
        .filter(it => it.id !== undefined).length,
    },
    actions: {
      [Action.SET_ROTATION_ID]({ commit }, rotationId) {
        commit(Mutation.SET_ROTATION_ID, rotationId);
      },
      [Action.ON_INPUT]({ commit }, payload) {
        commit(Mutation.SET_VALUE, payload);
      },

      async [Action.FETCH_ROTATION]({ rootState, commit }, shiftRotationId) {
        if (!rootState.auth.currentCompanyId) {
          return undefined;
        }

        let actionResult: number | undefined;

        const variables: GQLFetchShiftRotationQueryVariables = {
          companyId: rootState.auth.currentCompanyId,
          id: shiftRotationId,
        };

        const result = await graphqlClient.query<GQLFetchShiftRotationQuery,
        GQLFetchShiftRotationQueryVariables>({
          query: FetchShiftRotationGql,
          variables,
        });

        if (result.data) {
          const [shiftRotation] = result.data.shiftRotations.items;
          if (shiftRotation) {
            const { shiftRotationGroups } = shiftRotation;
            commit(Mutation.SET_ROTATION, shiftRotation);
            const transformedRotationGroups: RotationGroupInput[] = shiftRotationGroups
              .map(group => ({
                id: group.id,
                name: group.name,
                days: group.shiftPresetIds.map(presetId => (presetId === 0
                  ? RESTING_DAY
                  : group.shiftPresets.find(preset => presetId === preset.id)
                )),
              }));
            commit(Mutation.SET_ROTATION_GROUPS, transformedRotationGroups);
            actionResult = shiftRotation.id;
          }
        }

        return actionResult;
      },
      async [Action.CREATE_ROTATION]({ state, rootState, commit }) {
        if (!rootState.auth.currentCompanyId) {
          return undefined;
        }

        const variables: GQLMutationCreateShiftRotationArgs = {
          shiftRotation: {
            anchorDate: deepTransformDates(
              parse(
                state.anchorDate,
                'yyyy-MM-dd',
                // fallback if parsing failed
                new Date(state.anchorDate),
              ),
            ),
            companyId: rootState.auth.currentCompanyId,
            endsAt: null,
            name: state.rotationName,
            rotationInterval: state.rotationInterval,
            startsAt: null,
          },
        };

        const result = await graphqlClient.mutate<GQLCreateShiftRotationMutation,
        GQLMutationCreateShiftRotationArgs>({
          mutation: CreateShiftRotationGql,
          variables,
        });

        if (result.errors?.length) {
          return result.errors[0].extensions?.response;
        }

        commit(Mutation.SET_ROTATION_ID, result.data?.createShiftRotation.id);

        return result.data?.createShiftRotation.id;
      },

      async [Action.UPDATE_ROTATION]({ state, rootState, commit }) {
        if (!rootState.auth.currentCompanyId || !state.rotationId) {
          return undefined;
        }

        const variables: GQLMutationUpdateShiftRotationArgs = {
          id: state.rotationId,
          shiftRotation: {
            anchorDate: state.anchorDate,
            companyId: rootState.auth.currentCompanyId,
            endsAt: null,
            name: state.rotationName,
            rotationInterval: state.rotationInterval,
            startsAt: null,
          },
        };

        const result = await graphqlClient.mutate<GQLUpdateShiftRotationMutation,
        GQLMutationUpdateShiftRotationArgs>({
          mutation: UpdateShiftRotationGql,
          variables,
        });

        if (result.errors?.length) {
          return result.errors[0].extensions?.response;
        }

        commit(Mutation.SET_ROTATION_ID, result.data?.updateShiftRotation.id);

        return result.data?.updateShiftRotation.id;
      },

      async [Action.CREATE_ROTATION_GROUP]({ rootState }, payload: {
        sortPosition: number;
        shiftRotationId: number;
        group: RotationGroupInput;
      }) {
        if (!rootState.auth.currentCompanyId) {
          return undefined;
        }

        const variables: GQLMutationCreateShiftRotationGroupArgs = {
          shiftRotationGroup: {
            // false positive because currentCompanyId is checked before
            companyId: rootState.auth.currentCompanyId || 0,
            name: payload.group.name,
            shiftRotationId: payload.shiftRotationId,
            sortPosition: payload.sortPosition,
            shiftPresetIds: payload.group.days.map(transformRotationDayPreset),
          },
        };

        const result = await graphqlClient.mutate<GQLCreateShiftRotationGroupMutation,
        GQLMutationCreateShiftRotationGroupArgs>({
          mutation: CreateShiftRotationGroupGql,
          variables,
        });

        if (result.errors?.length) {
          return result.errors[0].extensions?.response;
        }

        return result.data?.createShiftRotationGroup.id;
      },

      async [Action.UPDATE_ROTATION_GROUP]({ rootState }, payload: {
        sortPosition: number;
        shiftRotationId: number;
        group: RotationGroupInput;
      }) {
        if (!rootState.auth.currentCompanyId || !payload.group.id) {
          return undefined;
        }

        const variables: GQLMutationUpdateShiftRotationGroupArgs = {
          id: payload.group.id,
          shiftRotationGroup: {
            companyId: rootState.auth.currentCompanyId,
            name: payload.group.name,
            shiftRotationId: payload.shiftRotationId,
            sortPosition: payload.sortPosition,
            shiftPresetIds: payload.group.days.map(transformRotationDayPreset),
          },
        };

        const result = await graphqlClient.mutate<GQLUpdateShiftRotationGroupMutation,
        GQLMutationUpdateShiftRotationGroupArgs>({
          mutation: UpdateShiftRotationGroupGql,
          variables,
        });

        if (result.errors?.length) {
          return result.errors[0].extensions?.response;
        }

        return result.data?.updateShiftRotationGroup.id;
      },

      async [Action.CREATE_ROTATION_GROUPS](
        {
          state,
          rootState,
          dispatch,
          commit,
        },
        shiftRotationId: number,
      ) {
        if (!rootState.auth.currentCompanyId) {
          return undefined;
        }

        const promises = state.rotationGroups.map(async (group, index) => {
          const response = await dispatch(
            Action.CREATE_ROTATION_GROUP,
            { sortPosition: index + 1, shiftRotationId, group },
          );

          if (typeof response === 'number') {
            commit(Mutation.SET_ROTATION_GROUP_ID, { groupIndex: index, id: response });
          }

          return response;
        });

        return Promise.all(promises);
      },

      async [Action.UPDATE_ROTATION_GROUPS](
        {
          state,
          rootState,
          dispatch,
          commit,
        },
        shiftRotationId: number,
      ) {
        if (!rootState.auth.currentCompanyId) {
          return undefined;
        }

        /*
          we have a mix of new and old groups so we want to call update or
          create. We call update for all groups even if they did not
          really change just to not care about sorting logic
        */
        const promises = state.rotationGroups.map(async (group, index) => {
          const response = await dispatch(
            group.id ? Action.UPDATE_ROTATION_GROUP : Action.CREATE_ROTATION_GROUP,
            { sortPosition: index + 1, shiftRotationId, group },
          );

          if (typeof response === 'number' && !group.id) {
            commit(Mutation.SET_ROTATION_GROUP_ID, { groupIndex: index, id: response });
          }

          return response;
        });

        return Promise.all(promises);
      },
      [Action.GENERATE_ROTATION_GROUPS](
        { commit, state, getters },
        payload: {
          rotationInterval: number;
          groupsLength: number;
          defaultGroupNamePrefix: string;
        },
      ) {
        // only take existing groups into account if rotation exists
        // this should not generally happen
        const existingGroupsLength = state.rotationId
          ? getters.existingShiftRotationGroupsLength
          : 0;
        const newRotationGroups = Array.from({
          length: Math.max(payload.groupsLength - existingGroupsLength, 0),
        }, (_, index) => ({
          name: `${payload.defaultGroupNamePrefix} ${existingGroupsLength + index + 1}`,
          days: Array.from({ length: payload.rotationInterval }),
        }));

        // if it's edit append new groups to the end of groups array
        // otherwise - override existing groups
        const allRotationGroups = state.rotationId
          ? [
            ...state.rotationGroups.filter(grp => grp.id !== undefined),
            ...newRotationGroups,
          ]
          : newRotationGroups;
        commit(Mutation.SET_ROTATION_GROUPS, allRotationGroups);
      },

      [Action.UPDATE_ROTATION_GROUP_NAME](
        { commit },
        { groupIndex, name }:
        {groupIndex: number; name: string},
      ) {
        commit(Mutation.SET_ROTATION_GROUP_NAME, { groupIndex, name });
      },
      [Action.MOVE_ROTATION_GROUP](
        { commit, state },
        { groupIndex, direction }: { groupIndex: number; direction: number },
      ) {
        const rotationGroups = [...state.rotationGroups];
        const newGroupIndex = groupIndex + direction;
        const movedGroup = rotationGroups[groupIndex];
        rotationGroups[groupIndex] = rotationGroups[newGroupIndex];
        rotationGroups[newGroupIndex] = movedGroup;
        commit(Mutation.SET_ROTATION_GROUPS, rotationGroups);
      },
      [Action.DELETE_ROTATION_GROUP](
        { commit, state },
        { groupIndex }: { groupIndex: number; direction: number },
      ) {
        const rotationGroups = [...state.rotationGroups];
        const [deletedGroup] = rotationGroups.splice(groupIndex, 1);
        if (deletedGroup.id) {
          logger.instance.error('Can\'t delete a group with id');
          return;
        }
        commit(Mutation.SET_ROTATION_GROUPS, rotationGroups);
        commit(Mutation.SET_VALUE, { name: 'rotationGroupsLength', value: rotationGroups.length });
      },
      [Action.UPDATE_ROTATION_DAY](
        { commit },
        {
          groupIndex,
          dayIndex,
          preset,
        }:
        {
          groupIndex: number;
          dayIndex: number;
          preset: RotationDayPreset;
        },
      ) {
        commit(Mutation.SET_ROTATION_DAY, {
          groupIndex,
          dayIndex,
          preset,
        });
      },

      [Action.SET_ROTATION_GROUPS]({ commit }, shiftRotationGroups: RotationGroupInput[]) {
        commit(Mutation.SET_ROTATION_GROUPS, shiftRotationGroups);
      },
      async [Action.ASSIGN_EMPLOYMENT]({ rootState }, payload: {
        employmentId: number;
        shiftRotationGroupId: number;
      }) {
        if (!rootState.auth.currentCompanyId) {
          return undefined;
        }

        const variables: GQLMutationCreateEmploymentsRotationGroupArgs = {
          employmentsRotationGroup: {
            companyId: rootState.auth.currentCompanyId || 0,
            employmentId: payload.employmentId,
            endsAt: null,
            shiftRotationGroupId: payload.shiftRotationGroupId,
            startsAt: null,
          },
        };

        const result = await graphqlClient.mutate<GQLCreateEmploymentsRotationGroupMutation,
        GQLMutationCreateEmploymentsRotationGroupArgs>({
          mutation: CreateEmploymentsRotationGroupGql,
          variables,
          context: {
            useBatching: true,
          },
        });

        if (result.errors?.length) {
          return result.errors[0].extensions?.response;
        }

        const employmentsRotationGroupId = result.data?.createEmploymentsRotationGroup.id;

        return employmentsRotationGroupId;
      },
      async [Action.UNASSIGN_EMPLOYMENT](
        { rootState },
        employmentsRotationGroupId: number,
      ) {
        if (!rootState.auth.currentCompanyId) {
          return undefined;
        }

        const variables: GQLMutationDeleteEmploymentsRotationGroupArgs = {
          id: employmentsRotationGroupId,
        };

        const result = await graphqlClient.mutate<GQLDeleteEmploymentsRotationGroupMutation,
        GQLMutationDeleteEmploymentsRotationGroupArgs>({
          mutation: DeleteEmploymentsRotationGroupGql,
          variables,
          context: {
            useBatching: true,
          },
        });

        if (result.errors?.length) {
          return result.errors[0].extensions?.response;
        }

        return result.data?.deleteEmploymentsRotationGroup;
      },
      // TODO: replace later if/when we include withReset?
      [Action.RESET]({ commit }) {
        commit(Mutation.RESET, getInitialState());
      },

      async [Action.CREATE_INDIVIDUAL_ROTATION]({ rootState, state }, payload: {
        employmentId: number;
      }) {
        if (!rootState.auth.currentCompanyId || !state.startsAt || !state.endsAt) {
          return undefined;
        }

        const variables: GQLMutationCreateIndividualShiftRotationArgs = {
          individualShiftRotation: {
            // false positive because currentCompanyId is checked before
            companyId: rootState.auth.currentCompanyId || 0,
            anchorDate: state.startsAt,
            employmentId: payload.employmentId,
            endsAt: state.endsAt,
            rotationInterval: state.rotationInterval,
            shiftPresetIds: state.rotationGroups[0].days.map(transformRotationDayPreset),
            startsAt: state.startsAt,
          },
        };

        const result = await graphqlClient.mutate<GQLCreateIndividualShiftRotationMutation,
        GQLMutationCreateIndividualShiftRotationArgs>({
          mutation: CreateIndividualShiftRotationGql,
          variables,
        });

        if (result.errors?.length) {
          return result.errors[0].extensions?.response;
        }

        return result.data?.createIndividualShiftRotation.id;
      },
    },
    modules: {
      employmentAssignment: getTableStore(graphqlClient, logger),
    },
  };

  return store;
};

export default getRotationWizardStore;
