import Icon from 'components/icons/Icon';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { IconName } from 'components/icons/types';
import styles from './filter-box.css';

export enum Slot {
  TITLE = 'title',
  ACTION_BUTTON = 'actionButton',
}

@Component
export default class FilterBox extends TsxComponent<{
  id?: string;
  isOpen?: boolean;
}, {
  onOpenCloseClick: (e: EventPayload<boolean>) => void;
  onResetClick: (e: EventPayload<void>) => void;
}> {
  @Prop({ default: () => `filter-box-${getRandomString()}` })
  public id: string;

  @Prop()
  public isOpen?: boolean;

  protected onOpenCloseClick(e: SyntheticEvent<HTMLButtonElement, MouseEvent>) {
    this.$emit('openCloseClick', createEventPayload(e, !this.isOpen));
  }

  protected onResetClick(e: SyntheticEvent<HTMLButtonElement, MouseEvent>) {
    this.$emit('resetClick', createEventPayload(e, undefined));
  }

  public render() {
    return (
      <aside class={styles.filterBox}>
        <header class={styles.filterBoxHeader}>
          <span class={styles.filterBoxLabel}>
            {this.$slots[Slot.TITLE]}
          </span>

          <div class={styles.filterBoxControls}>
            {/* Currently unused */}
            {/* <button
              class={styles.filterBoxButton}
              onClick={this.onResetClick}
              type="button"
            >
              <Icon
                class={styles.filterBoxIcon}
                name="update"
              />
            </button> */}

            {this.$slots[Slot.ACTION_BUTTON]}
            <button
              aria-controls={`${this.id}-body`}
              aria-expanded={this.isOpen}
              class={styles.filterBoxButton}
              id={`${this.id}-button`}
              onClick={this.onOpenCloseClick}
              title={
                this.isOpen
                  ? this.$t('shiftSchedule.filter.labelCollapse')
                  : this.$t('shiftSchedule.filter.labelExpand')
              }
              type="button"
            >
              <Icon
                aria-label={
                  this.isOpen
                    ? this.$t('shiftSchedule.filter.labelCollapse')
                    : this.$t('shiftSchedule.filter.labelExpand')
                }
                class={styles.filterBoxIcon}
                name={this.isOpen ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
              />
            </button>
          </div>
        </header>

        <transition
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {
            this.isOpen && (
              <div
                aria-labelledby={`${this.id}-button`}
                class={styles.filterBoxBody}
                id={`${this.id}-body`}
                role="region"
              >
                {this.$slots.default}
              </div>
            )
          }
        </transition>
      </aside>
    );
  }
}
