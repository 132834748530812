import Vue, { VueConstructor } from 'vue';

interface TimeZoneConfig {
  value: string;
}

declare module 'vue/types/vue' {
  interface Vue {
    $timeZone: TimeZoneConfig;
  }
}

class VueTimeZoneProvider {
  private static tz: Vue & TimeZoneConfig;

  public static setTimeZone(timeZone: string) {
    this.tz.$set(this.tz, 'value', timeZone);
  }

  public static getTimeZone(): TimeZoneConfig['value'] {
    return this.tz.value;
  }

  public static install(VueInstance: VueConstructor) {
    this.tz = new Vue({ data: { value: 'UTC' } });

    if (!Object.prototype.hasOwnProperty.call(VueInstance.prototype, '$timeZone')) {
      Object.defineProperty(VueInstance.prototype, '$timeZone', {
        get: () => this.tz.$data,
      });
    }
  }
}

export default VueTimeZoneProvider;
