import {
  GQLShiftFieldsFragment,
} from 'codegen/gql-types';
import { deleteItems, getUpdatedItems } from 'components/calendar-common/common/store/Helpers';
import DefaultActions from 'components/calendar-common/DefaultActions';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import {
  filterByLocationsPositions,
  getByDates,
  shiftsByEmployments,
  shiftsByPositions,
  sortShifts,
} from 'components/calendar-common/shifts/store/util';
import Shift from 'components/calendar-common/shifts/Shift';
import StaffShift from 'components/calendar-common/shifts/StaffShift';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import Mutation from './Mutation';

export const calendarPrintShiftsNS = namespace(`${CalendarNamespace.PRINT}/shifts`);

export interface StoreState {
  items: GQLShiftFieldsFragment[];
}

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    [Mutation.SET_SHIFTS](state, items) {
      state.items = items;
    },
    [Mutation.DELETE_SHIFTS](state, ids: number[]) {
      state.items = deleteItems(state.items, ids);
    },
    [Mutation.UPDATE_SHIFTS](state, items) {
      state.items = getUpdatedItems(state.items, items);
    },
  },
  actions: {
    [DefaultActions.SET]({ commit }, items) {
      commit(Mutation.SET_SHIFTS, items);
    },
    [DefaultActions.UPDATE]({ commit }, items) {
      commit(Mutation.UPDATE_SHIFTS, items);
    },
    [DefaultActions.DELETE]({ commit }, ids) {
      const idsArray = Array.isArray(ids) ? ids : [ids];
      commit(Mutation.DELETE_SHIFTS, idsArray);
    },
  },
  getters: {
    shiftsIds(state, getters) {
      return getters.shifts.map(it => it.id);
    },
    shiftsEmployments(state) {
      return state.items
        .reduce(
          (
            acc: Record<number, Set<number>>,
            { locationsPosition, staffShifts },
          ) => {
            (staffShifts || []).forEach((staffShift) => {
              if (staffShift && staffShift.employment) {
                if (acc[staffShift.employment.id]) {
                  acc[staffShift.employment.id].add(locationsPosition.id);
                } else {
                  acc[staffShift.employment.id] = new Set([locationsPosition.id]);
                }
              }
            });
            return acc;
          },
          {},
        );
    },
    shift(state, getters, rootState) {
      return (shiftData): Shift => {
        const isShiftPresetsEnabled = rootState.auth.currentCompany?.canUseShiftPresets || false;
        const {
          locationsPosition,
          staffShifts,
          shiftplan,
        } = shiftData;
        const visibleStaffShifts = staffShifts
          .filter(staffShift => staffShift.employment !== null);
        return new Shift({
          ...shiftData,
          isMyShift: visibleStaffShifts.find(
            staffShift => staffShift.employment
              .userId === rootState.auth.currentUserId,
          ) !== undefined,
          staffShifts: visibleStaffShifts
            .map(staffShift => new StaffShift({
              ...staffShift.employment,
              staffShiftId: staffShift.id,
            })),
          // tags are not used for print and therefore not fetched
          tags: [],
          position: {
            ...locationsPosition.position,
            locationsPositionId: locationsPosition.id,
          },
          color: locationsPosition.position.color,
          shiftplanId: shiftplan.id,
        }, isShiftPresetsEnabled);
      };
    },
    shifts(state, getters) {
      return state.items
        .map(it => getters.shift(it))
        .sort(sortShifts);
    },
    // all filters except employments filter
    filteredShifts(state, getters, rootState, rootGetters) {
      if (!rootState.auth.currentCompany) {
        return [];
      }
      const locationsPositionIds = rootGetters['calendarPrint/filters/locationsPositionIds'];
      const isLocationsPositionsFilterDisabled = !Array.isArray(locationsPositionIds)
        || locationsPositionIds.length === 0;

      return (
        getters.shifts
          .filter(filterByLocationsPositions(
            locationsPositionIds,
            isLocationsPositionsFilterDisabled,
          ))
      );
    },
    shiftsByDates(state, getters, rootState, rootGetters) {
      return getByDates(rootGetters[`${CalendarNamespace.PRINT}/common/dateKeys`], getters.filteredShifts);
    },
    shiftsByPositions(state, getters, rootState, rootGetters) {
      return shiftsByPositions(rootGetters[`${CalendarNamespace.PRINT}/positions/filteredPositions`], getters.shiftsByDates);
    },
    shiftsByEmployments(state, getters, rootState, rootGetters) {
      return shiftsByEmployments(
        rootGetters[`${CalendarNamespace.PRINT}/employments/filteredEmployments`],
        getters.shiftsByDates,
      );
    },
    shiftsByPositionsEmployments(state, getters, rootState, rootGetters) {
      return Object.entries(
        getters.shiftsByPositions as Record<number, Record<string, Shift[]>>,
      ).reduce(
        (acc, [positionId, items]) => {
          acc[positionId] = shiftsByEmployments(
            rootGetters[`${CalendarNamespace.PRINT}/employments/employmentsByPositions`][positionId] || [],
            items,
          );
          return acc;
        },
        {},
      );
    },
  },
});
