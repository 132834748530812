import TwoColumn from 'layouts/two-column/TwoColumn';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Mode, TimeframeKind } from 'components/calendar-common/Enums';
import { FiltersMap } from 'components/calendar-common/filters/Store';
import { ShiftRotation } from 'src/store/shift-rotations/Store';
import { getTimeframeFromDates } from 'utils/timeframe-helpers';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/form/button/Button';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import styles from './shift-schedule.css';
import FilterBoxEmployment from './filter-box-employment/FilterBoxEmployment';
import Header from './header/Header';
import FilterBoxQuick, { QUICK_FILTERS_MAP } from './filter-box-quick/FilterBoxQuick';
import FilterBoxLocationsPosition from './filter-box-locations-position/FilterBoxLocationsPosition';
import CalendarAbsencesContainer from './calendar-absences-container/CalendarAbsencesContainer';
import type { QuickFilterItem } from './filter-box-quick/types';
import Controls, { Slot } from './controls/Controls';
import { SelectedTimeframe } from '../datepicker/types';
import FilterBoxShiftRotationGroup from './filter-box-shift-rotation-group/FilterBoxShiftRotationGroup';
import CalendarAbsencesResetFiltersContainer from './calendar-absences-reset-filters-contailer/CalendarAbsencesResetFiltersContainer';
import { Shiftplan } from '../../store/shiftplans/Store';
import { createEventPayload, EventPayload } from '../../utils/events';
import calendarControlStyles from './calendar-controls/calendar-controls.css';

interface Props {
  shiftplans: Shiftplan[];
  timeframe: SelectedTimeframe;
  mode: Mode;
  isShiftRotationsEnabled: boolean;
  shiftRotations: ShiftRotation[];
  absencePlanningQuickFilters: FiltersMap[];
  isShiftplanFiltersSidebarCollapsed: boolean;
}

interface Events {
  onTimeframeChange: EventPayload<{
    timeframe: SelectedTimeframe;
  }, HTMLButtonElement, MouseEvent>;
  onFilterSidebarToggle: void;
}

@Component
export default class AbsencePlanning extends TsxComponent<Props, Events> {
  private isDatepickerOpen = false;

  @Prop()
  public mode: Props['mode'];

  @Prop()
  public shiftplans: Props['shiftplans'];

  @Prop()
  public timeframe: Props['timeframe'];

  @Prop()
  public shiftRotations: Props['shiftRotations'];

  @Prop()
  public isShiftRotationsEnabled: Props['isShiftRotationsEnabled'];

  @Prop()
  public absencePlanningQuickFilters: Props['absencePlanningQuickFilters'];

  @Prop()
  public isShiftplanFiltersSidebarCollapsed: Props['isShiftplanFiltersSidebarCollapsed'];

  private get isEmployeeView() {
    return this.mode === Mode.EMPLOYEE;
  }

  protected get quickFilterItems(): QuickFilterItem[] {
    return this.absencePlanningQuickFilters.map((filterKey) => {
      const filter = QUICK_FILTERS_MAP[filterKey];
      return {
        ...filter,
        id: filterKey,
        name: filterKey,
        label: this.$t(filter.label),
      };
    });
  }

  private onTimeframeUpdate(
    { event, payload: timeframe }: EventPayload<SelectedTimeframe, HTMLButtonElement, MouseEvent>,
  ) {
    const resultTimeframe = getTimeframeFromDates(
      timeframe.startsAt,
      timeframe.endsAt,
      TimeframeKind.MONTH,
      this.$timeZone.value,
    );

    this.$emit('timeframeChange', createEventPayload(event, {
      timeframe: resultTimeframe,
    }));
  }

  public render() {
    return (
      <div class={{
        [styles.shiftSchedule]: true,
        [styles.shiftScheduleEmployee]: this.isEmployeeView,
      }}>
        <Header isApplyRotationActionAllowed={false}
          shiftplans={this.shiftplans}
          title={this.$t('shiftSchedule.absencesPlanning.pageTitle')} />
        {
          (this.isEmployeeView)
            ? (
              <main slot="two" class={styles.shiftScheduleMain}>
                <Controls timeframeKind={TimeframeKind.MONTH}
                  timeframe={this.timeframe}
                  isDatepickerOpen={this.isDatepickerOpen}
                  onDatepickerCancel={() => {
                    this.isDatepickerOpen = false;
                  }}
                  onDatepickerChange={eventPayload => this.onTimeframeUpdate(eventPayload)}
                  onDatepickerToggle={({ payload }) => { this.isDatepickerOpen = payload; }} />
                <CalendarAbsencesContainer />
              </main>)
            : (
              <TwoColumn class={{
                [styles.shiftScheduleContainer]: true,
                [styles.shiftScheduleContainerSidebarCollapsed]:
                  this.isShiftplanFiltersSidebarCollapsed,
              }}>
                <div slot="one" class={styles.shiftScheduleSidebarWrapper}>
                  <div class={styles.shiftScheduleSidebar}>
                    <FilterBoxQuick items={this.quickFilterItems} />
                    <FilterBoxLocationsPosition />

                    {this.isShiftRotationsEnabled && (
                      this.shiftRotations?.map(shiftRotation => (
                        <FilterBoxShiftRotationGroup shiftRotation={shiftRotation} />
                      ))
                    )}

                    <FilterBoxEmployment />
                    <CalendarAbsencesResetFiltersContainer />
                  </div>
                </div>

                <main slot="two" class={styles.shiftScheduleMain}>
                  <Controls timeframeKind={TimeframeKind.MONTH}
                    timeframe={this.timeframe}
                    isDatepickerOpen={this.isDatepickerOpen}
                    onDatepickerCancel={() => {
                      this.isDatepickerOpen = false;
                    }}
                    onDatepickerChange={eventPayload => this.onTimeframeUpdate(eventPayload)}
                    onDatepickerToggle={({ payload }) => { this.isDatepickerOpen = payload; }}
                  >
                    <template slot={Slot.LEFT_CONTROLS_GROUP}>
                      {!this.isEmployeeView && (
                        <Tooltip
                          text={
                            <span
                              class={calendarControlStyles.calendarControlsTooltipText}>
                              {this.$t(
                                this.isShiftplanFiltersSidebarCollapsed
                                  ? 'calendar.controls.buttonFilterSidebarExpand'
                                  : 'calendar.controls.buttonFilterSidebarCollapse',
                              )}
                            </span>
                          }
                        >
                          <Button
                            aria-label={this.$t(
                              this.isShiftplanFiltersSidebarCollapsed
                                ? 'calendar.controls.buttonFilterSidebarExpand'
                                : 'calendar.controls.buttonFilterSidebarCollapse',
                            )}
                            icon={IconName.SIDEBAR_COLLAPSE}
                            iconPosition={IconPosition.ALONE}
                            onClick={() => this.$emit('filterSidebarToggle')}
                          />
                        </Tooltip>
                      )}
                    </template>
                  </Controls>
                  <CalendarAbsencesContainer class={styles.shiftScheduleCalendar} />
                </main>
              </TwoColumn>
            )
        }
        <router-view />
      </div>
    );
  }
}
