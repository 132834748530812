import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import BaseTooltip from './BaseTooltip';

export interface TooltipProps {
  text: string | JSX.Element | Vue;
}
@Component
export default class Tooltip extends TsxComponent<TooltipProps> {
  @Prop()
  public text: TooltipProps['text'];

  public render() {
    return <BaseTooltip text={this.text} >{this.$slots.default}</BaseTooltip>;
  }
}
