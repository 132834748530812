import { GQLPaygradeLevel } from 'codegen/gql-types';
import { ShiftPaygrade } from 'src/store/shift-paygrades/Store';
import RootStoreState from 'store/RootStoreState';
import { Store } from 'vuex';
import { Pay } from './Section';

export const isBonusPaygrade = ({ level }: Pay) => (
  level === GQLPaygradeLevel.PAYGRADE || level === GQLPaygradeLevel.MONTHLYPAYGRADE
);

export const isShiftPaygrade = ({ level }: Pay) => level === GQLPaygradeLevel.SHIFT;

export const transformToPay = (entity: ShiftPaygrade): Pay => ({
  id: entity.id,
  isLocked: true,
  level: entity.paygradeForType,
  parentId: entity.paygradeForType === GQLPaygradeLevel.PAYGRADE
    || entity.paygradeForType === GQLPaygradeLevel.MONTHLYPAYGRADE
    ? entity.paygradeForId
    : undefined,
  typeId: entity.paygradeType.id,
  value: entity.value,
  children: [],
});

export const hasParentPaygrade = ({ parentId }: Pay) => parentId !== undefined;

export const hasPaymentManageRight = ({
  shiftLocationsPositionId,
  shiftLocationId,
  store,
}: {
  shiftLocationsPositionId: number;
  shiftLocationId: number;
  store: Store<RootStoreState>;
}) => (
  store.getters['auth/hasAnyRight']('payments_manage_all')
    || store.getters['auth/hasAnyLocationRight'](shiftLocationId, 'payment_manage_right')
    || store.getters['auth/hasAnyLocationsPositionRight'](shiftLocationsPositionId, 'payment_manage_right')
);

export const hasPaymentViewRight = ({
  shiftLocationsPositionId,
  shiftLocationId,
  store,
}: {
  shiftLocationsPositionId: number;
  shiftLocationId: number;
  store: Store<RootStoreState>;
}) => (
  store.getters['auth/hasAnyRight']('payments_show_all')
    || store.getters['auth/hasAnyLocationRight'](shiftLocationId, 'payment_show_right')
    || store.getters['auth/hasAnyLocationsPositionRight'](shiftLocationsPositionId, 'payment_show_right')
);
