/* eslint-disable import/prefer-default-export */

import type { Shiftplan } from 'store/shiftplans/Store';
import { getDateInTimeZone, startOf, Unit } from './date-related';

export const getDefaultShiftplan = (shiftplans: Shiftplan[], timeZone: string) => {
  const today = startOf(new Date(), Unit.DAY, timeZone).valueOf();

  const firstOngoingShiftplan = shiftplans.find(shiftplan => (
    getDateInTimeZone(new Date(shiftplan.startsAt), timeZone).valueOf() <= today
      && getDateInTimeZone(new Date(shiftplan.endsAt), timeZone).valueOf() >= today
  ));

  if (firstOngoingShiftplan) {
    return firstOngoingShiftplan;
  }

  const firstFutureShiftplan = shiftplans.find(shiftplan => (
    getDateInTimeZone(new Date(shiftplan.startsAt), timeZone).valueOf() > today
  ));

  if (firstFutureShiftplan) {
    return firstFutureShiftplan;
  }

  const mostRecentPastShiftplan = [...shiftplans].reverse()
    .find(shiftplan => getDateInTimeZone(new Date(shiftplan.endsAt), timeZone).valueOf() < today);

  if (mostRecentPastShiftplan) {
    return mostRecentPastShiftplan;
  }

  return shiftplans.slice(-1)[0];
};
