import SpptDnd, { DROPPABLE_DATA_KEY } from '@shyftplan/drag-and-drop';
import DataAttrs from 'components/calendar-common/helpers/data-attrs/DataAttrs.js';
import DateItem from 'components/calendar-common/common/DateItem';
import DndKind from 'components/calendar-common/common/dnd/DndKind';
import { DndParams } from 'components/calendar-common/grid/DndParams';
import gridCellStyles from 'components/calendar-common/grid/grid-cell/grid-cell.css';
import styles from 'components/calendar-common/grid/grid-row/grid-row.css';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface GridRowScope {
  dateItem: DateItem;
  index: number;
  isLastRow: boolean;
}

@Component({
  directives: {
    'data-attrs': DataAttrs,
  },
})
class GridRow extends Vue {
  @Prop()
  public dates!: DateItem[];

  @Prop()
  public isHeader!: boolean;

  @Prop({ default: false })
  public isLastRow!: boolean;

  @Prop({ default: '' })
  public cellClass!: string;

  @Prop({ default: true })
  public isVisible: boolean;

  @Prop({
    default: () => null,
  })
  public dndParams!: DndParams | null;

  private onCellClick(event: MouseEvent | KeyboardEvent, dateItem: DateItem) {
    event.stopPropagation();
    this.$emit('cellClick', dateItem);
  }

  private getDateClasses(date: DateItem, isHeader: boolean, isLastRow: boolean) {
    return (isHeader
      ? [gridCellStyles.gridCellHeader]
      : {
        [gridCellStyles.gridCellOutsideShiftplan]: !date.isWithinShiftplan,
        [gridCellStyles.gridCellToday]: date.isToday,
        [gridCellStyles.gridCellSpecialDate]: date.isSpecialDate,
        [gridCellStyles.gridCellLastRow]: isLastRow
          && (date.isSpecialDate || date.isToday),
        [gridCellStyles.gridCellDroppable]: date.isWithinShiftplan,
      });
  }

  private renderCell(date: DateItem, index, isHeader, isLastRow) {
    return (<div
      class={[
        gridStyles.gridTableContentCell,
        this.cellClass,
        gridCellStyles.gridCell,
        this.getDateClasses(date, isHeader, isLastRow),
      ]}
      role="button"
      tabIndex={0}
      v-data-attrs={(this.dndParams !== null) && {
        [`${DROPPABLE_DATA_KEY}`]: JSON.stringify({
          data: {
            ...this.dndParams,
            date: date.date,
          },
        }),
      }}
      key={date.dateKey}
      onClick={e => this.onCellClick(e, date)}
      onKeypress={(e: KeyboardEvent) => e.key === 'enter' && this.onCellClick(e, date)}
      onMouseover={e => this.$emit('mouseover', e)}
      onFocus={e => this.$emit('focus', e)}
      onMousedown={e => this.$emit('mousedown', e)}>
      { this.$scopedSlots.cell && this.$scopedSlots.cell({ index, dateItem: date })}
    </div>);
  }

  private getCells() {
    return this.dates
      .map((dateItem, index) => this.renderCell(dateItem, index, this.isHeader, this.isLastRow));
  }

  private initDnd() {
    if (this.dndParams) {
      SpptDnd.getInstance().initDroppableContainer(
        this.$el as HTMLElement,
        [{
          kind: DndKind.CELL,
          selector: `.${gridCellStyles.gridCellDroppable}`,
        }],
      );
    }
  }

  public mounted() {
    this.initDnd();
  }

  public updated() {
    this.initDnd();
  }

  public render() {
    return (<div class={{
      [gridStyles.gridTableSubgrid]: true,
      [styles.gridRowWithOverlay]: !this.isVisible,
    }}>
      {this.$slots.label
      && <div class={styles.gridRowCellSticky}>
        {this.$slots.label}
      </div>
      }
      {this.getCells()}
      {
        this.$slots.total
      && <div class={[styles.gridRowCellTotal]}>
        {this.$slots.total}
      </div>}
      {!this.isVisible && <div class={styles.gridRowOverlay}/>}
    </div>);
  }
}

export default GridRow as any;
