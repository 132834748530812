import FilterBox, { Slot as FilterBoxSlot } from 'components/filter-box/FilterBox';
import InputPillSelect from 'components/form/input-pill-select/InputPillSelect';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { locationsNS, Location } from 'src/store/locations/Store';

import { Option } from 'components/select-panel/SelectPanel';
import styles from './filter-locations.css';

export interface Props {
  value: number[];
}

interface Events {
  onChange: (value: number[]) => void;
  onReset: () => void;
}

@Component
class FilterLocations extends TSXComponent<Props, Events> {
  @Prop()
  public value: Props['value'];

  @locationsNS.Getter('items')
  private locations: Location[];

  private get locationOptions(): Option<string>[] {
    return this.locations.map(location => ({
      value: `${location.id}`,
      label: location.name,
      color: '#1c1c1c', // cod-gray
      textColor: '#FFFFFF',
    }));
  }

  private onReset() {
    this.$emit('reset');
  }

  private onChange(value) {
    this.$emit('change', value);
  }

  public render() {
    return (
      <FilterBox
        class={styles.filterLocations}
        isResetButtonShown={this.value.length > 0}
        resetButtonText={`${this.value.length}`}
        onResetClick={this.onReset}
      >
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('availabilityAggregations.filterLocations.header')}
        </template>

        <InputPillSelect
          class={styles.filterLocationsInput}
          placeholder={this.$t('availabilityAggregations.filterLocations.placeholder')}
          options={this.locationOptions}
          name="LOCATIONS"
          value={this.value.map(item => `${item}`)}
          onChange={({ payload }) => this.onChange(payload.map(val => parseInt(val, 10)))}
        />
      </FilterBox>
    );
  }
}

export default FilterLocations;
