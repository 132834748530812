import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEmploymentEvaluationTagMutation,
  GQLCreateEmploymentEvaluationTagMutationVariables,
  GQLDeleteEmploymentEvaluationTagMutation,
  GQLDeleteEmploymentEvaluationTagMutationVariables,
  GQLEmploymentEvaluationTagFragmentFragment,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateEmploymentEvaluationTagGql from './queries/CreateEmploymentEvaluationTag.gql';
import DeleteEmploymentEvaluationTagGql from './queries/DeleteEmploymentEvaluationTag.gql';

export const evaluationTagsNS = namespace('employmentEvaluationTags');

export type StoreState = ById<EvaluationTag>;

export type EvaluationTag = GQLEmploymentEvaluationTagFragmentFragment;

export type CreateEvaluationTagFunction = (
  payload: Omit<GQLCreateEmploymentEvaluationTagMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteEvaluationTagFunction = (
  payload: Omit<GQLDeleteEmploymentEvaluationTagMutationVariables & { id: number }, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getEmploymentEvaluationTagsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
  });

  const create: ActionProvider<
  GQLCreateEmploymentEvaluationTagMutation,
  GQLCreateEmploymentEvaluationTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEvaluationTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: CreateEmploymentEvaluationTagGql,
      resultKey: 'createEmploymentEvaluationTag',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    });
  };

  const remove: ActionProvider<
  GQLDeleteEmploymentEvaluationTagMutation,
  GQLDeleteEmploymentEvaluationTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteEvaluationTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: DeleteEmploymentEvaluationTagGql,
      resultKey: 'deleteEmploymentEvaluationTag',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    });
  };

  return createNormalizedStore<EvaluationTag, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
    },
    graphqlClient,
    logger,
  });
};

export default getEmploymentEvaluationTagsStore;
