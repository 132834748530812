import { Route } from 'components/dialog-shift/routes';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import HighlightableShift from '../HighlightableShift';
import Shift from '../Shift';
import ShiftMixin from '../ShiftMixin';
import styles from './shift-day.css';

@Component
class ShiftDayBase extends Mixins(ShiftMixin, HighlightableShift) {
  protected maxDisplayedShifts = 3;

  @Prop()
  public shift!: Shift;

  @Prop({ default: true })
  public allowDrag: boolean;

  protected get shiftSlots(): string {
    return `${this.shift.staffShiftsLength}/${this.shift.workers}`;
  }

  protected get shiftTime(): string {
    return `${this.shift.getStartDateTime()
      .format('HH:mm')} - ${this.shift.getEndDateTime()
      .format('HH:mm')}`;
  }

  protected get isDraggable() {
    return this.shift.isManageable && this.allowDrag;
  }

  private onClick(e: MouseEvent) {
    e.stopPropagation();

    this.$router.push({
      name: Route.ROOT,
      params: { id: this.shift.id.toString(), shiftplanId: this.shift.shiftplanId.toString() },
    });
  }

  public render() {
    return (
      <button class={[
        styles.shiftDay,
        this.highlightClass,
        {
          [styles.shiftDayIsDropActive]: this.isDropActive,
          [styles.shiftDayIsDragOverActive]: this.isDragOverActive,
        }]}
      style={this.shift.style}
      draggable={this.isDraggable}
      onDrop={this.onDrop}
      onDragover={this.onDragOver}
      onDragenter={this.onDragEnter}
      onDragleave={this.onDragLeave}
      onClick={e => this.onClick(e)}>
        {this.$scopedSlots.default && this.$scopedSlots.default({})}
      </button>
    );
  }
}

export default ShiftDayBase as any;
