import type { ShiftAvailableEmployment } from 'store/shift-available-employments/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import Icon from 'components/icons/Icon';
import Popover from 'components/popover/Popover';
import styles from './indicator-conflict.css';
import PopoverContent from './PopoverContent';

@Component
export default class IndicatorConflict extends TsxComponent<{
  employment: ShiftAvailableEmployment;
}> {
  @Prop()
  public employment: ShiftAvailableEmployment;

  public render() {
    return (
      <Popover class={styles.indicatorConflict}
        content={<PopoverContent employment={this.employment} />}
      >
        <Icon
          class={styles.indicatorConflictIcon}
          name={IconName.REPORT_PROBLEM}
          size={Size.XSMALL}
        />
      </Popover>
    );
  }
}
