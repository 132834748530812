import DropdownLegacy from 'components/dropdown-legacy/DropdownLegacy';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS } from 'components/auth/store/Store';
import type {
  HasAnyLocationRightFunction,
  HasAnyRightFunction,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'utils/url';
import type { GQLLocation } from 'codegen/gql-types';
import SpIcon from 'components/sp-icon/SpIcon';
import { ROOT_ROUTE } from 'components/shift-schedule/routes';
import styles from './location-dropdown.css';

@Component
export default class LocationDropdown extends TsxComponent<{}> {
  @authNS.Getter
  protected hasAnyRight: HasAnyRightFunction;

  @authNS.Getter
  protected hasAnyLocationRight: HasAnyLocationRightFunction;

  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  protected isAssignedLocationInCurrentEmployment(location: GQLLocation | null) {
    return this.currentEmployment?.locationsPositions?.findIndex(lp => (
      lp?.location?.id === location?.id
    )) !== -1;
  }

  protected get locations() {
    const locations = this.currentCompany?.locations || [];

    if (this.isSuperAdmin) {
      return locations;
    }

    if (this.currentEmployment?.isEmployee) {
      return locations.filter(this.isAssignedLocationInCurrentEmployment);
    }

    if (this.currentEmployment?.isStakeholder) {
      return locations.filter(location => (
        this.hasAnyRight(
          'shifts_show_all',
          'shifts_manage_all',
          'payments_show_all',
          'payments_manage_all',
        )
        || this.hasAnyLocationRight(
          location?.id,
          'shift_show_right',
          'shift_manage_right',
          'payment_show_right',
          'payment_manage_right',
        )
        || this.isAssignedLocationInCurrentEmployment(location)
      ));
    }

    return [];
  }

  public render() {
    if (!this.$route.path.startsWith(ROOT_ROUTE.path) || this.locations.length < 2) {
      return null;
    }

    return (
      <DropdownLegacy
        id="location-dropdown"
        items={[
          ...this.locations.map(location => ({
            label: location?.name || '',
            href: getUrlWithApiPrefix(getUrlWithCompanyPrefix(`/app/set_current_location?id=${location?.id}`, this.currentCompany)),
          })),
          (this.isSuperAdmin || this.currentEmployment?.isStakeholder) ? 'ruler' : undefined,
          // TODO: Add create company modal once we have modal compatibility layer
          (this.isSuperAdmin || this.currentEmployment?.isStakeholder) ? {
            label: this.$t('header.createLocation'),
            href: '#',
          } : undefined,
        ]}
      >
        <SpIcon name="location" class={styles.locationDropdownIcon} />
        {this.currentCompany?.locations?.find(o => o?.id === this.currentLocationId)?.name}
      </DropdownLegacy>
    );
  }
}
