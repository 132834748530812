import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { getRandomString } from 'utils/random';
import DialogTitle from 'components/dialog/dialog-title/DialogTitle';
import { Size } from 'components/types';
import { OVERLAY_PORTAL_NAME } from 'layouts/OverlayPortalTarget';
import styles from './base-dialog.css';
import { Key } from '../../../utils/keyboard';

export enum Slot {
  FOOTER = 'footer',
}

export const DIALOG_SPACING_SIZES = [
  Size.SMALL,
  Size.MEDIUM,
] as const;

export type DialogSpacingSize = typeof DIALOG_SPACING_SIZES[number]

interface Events {
  onCloseClick: (e: SyntheticEvent<HTMLElement
  | HTMLButtonElement, KeyboardEvent
  | MouseEvent
  | UIEvent>) => void;
}

export interface Props {
  id?: string;
  isOpen?: boolean;
  title?: string;
  isCloseButtonDisabled?: boolean;
  isDialogNotClosable?: boolean;
  showTitleBodySeparator?: boolean;
  dialogSpacingSize?: DialogSpacingSize;
  dialogClasses?: string[]
}

@Component
export default class BaseDialog extends TsxComponent<Props, Events> {
  public $refs: {
    container: HTMLDivElement;
  };

  @Prop({ default: () => `dialog-${getRandomString()}` })
  public id: NonNullable<Props['id']>;

  @Prop({ default: false })
  public isOpen: NonNullable<Props['isOpen']>;

  @Prop({ default: false })
  public isCloseButtonDisabled: NonNullable<Props['isCloseButtonDisabled']>;

  @Prop({ default: false })
  public isDialogNotClosable: NonNullable<Props['isDialogNotClosable']>;

  @Prop({ default: false })
  public showTitleBodySeparator: NonNullable<Props['showTitleBodySeparator']>;

  @Prop()
  public title: Props['title'];

  @Prop({ default: Size.SMALL })
  public dialogSpacingSize: NonNullable<Props['dialogSpacingSize']>;

  @Prop({ default: () => [] })
  public dialogClasses: NonNullable<Props['dialogClasses']>;

  protected onCloseClick() {
    this.$emit('closeClick');
  }

  protected onClose() {
    const { container } = this.$refs;
    const isLastChildOfPortal = container === container.parentNode?.lastElementChild;

    // FAQ: This is based on the assumption that the last opened dialog is the last child of
    // the portal. So, only the last child should be closed on ESCAPE
    if (isLastChildOfPortal) {
      this.onCloseClick();
    }
  }

  protected onWindowEscape(e: KeyboardEvent) {
    if (e.key === Key.ESCAPE && !this.isDialogNotClosable) {
      this.onClose();
    }
  }

  @Watch('isOpen', { immediate: true })
  public onIsOpenUpdate(isOpen: boolean) {
    if (isOpen) {
      window.addEventListener('keydown', this.onWindowEscape, { capture: true });
    } else {
      window.removeEventListener('keydown', this.onWindowEscape, { capture: true });
    }
  }

  public beforeDestroy() {
    window.removeEventListener('keydown', this.onWindowEscape);
  }

  public render() {
    return (
      <portal to={OVERLAY_PORTAL_NAME} disabled={!this.isOpen}>
        <transition
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {this.isOpen && (
            <div
              aria-describedby={`${this.id}-body`}
              aria-labelledby={`${this.id}-label`}
              aria-modal="true"
              class={styles.baseDialogContainer}
              id={this.id}
              ref="container"
              role="alertdialog"
            >
              <div
                class={[styles.baseDialog, ...this.dialogClasses]}
              >
                <DialogTitle
                  dialogSpacingSize={this.dialogSpacingSize}
                  title={this.title}
                  isCloseButtonDisabled={this.isCloseButtonDisabled}
                  isDialogNotClosable={this.isDialogNotClosable}
                  onCloseClick={this.onCloseClick} />
                {this.showTitleBodySeparator
                    && <hr class={styles.baseDialogTitleBodySeparator} size="1"/>}
                <div id={`${this.id}-body`} class={styles.baseDialogBody}>
                  {this.$slots.default}
                </div>
                {this.$slots[Slot.FOOTER]}
              </div>
              <button class={styles.baseDialogBackdrop} onClick={this.onClose} tabindex={-1} />
            </div>
          )}
        </transition>
      </portal>
    );
  }
}
