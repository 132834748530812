
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EmploymentEvaluationFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EmploymentEvaluation"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"break"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"breaks"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EvaluationBreakFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"endsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"evaluationTags"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EmploymentEvaluationTagFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"note"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"payments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PaymentFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"startsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"staffShiftId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"untimedBreak"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":506}};
    doc.loc.source = {"body":"#import \"../../evaluation-payments/queries/PaymentFragment.gql\"\n#import \"../../evaluation-breaks/queries/EvaluationBreakFragment.gql\"\n#import \"../../employment-evaluation-tags/queries/EmploymentEvaluationTagFragment.gql\"\n\nfragment EmploymentEvaluationFragment on EmploymentEvaluation {\n  id\n  break\n  breaks {\n    ...EvaluationBreakFragment\n  }\n  endsAt\n  evaluationTags {\n    ...EmploymentEvaluationTagFragment\n  }\n  note\n  payments {\n    ...PaymentFragment\n  }\n  startsAt\n  staffShiftId\n  untimedBreak\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../../evaluation-payments/queries/PaymentFragment.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../../evaluation-breaks/queries/EvaluationBreakFragment.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../../employment-evaluation-tags/queries/EmploymentEvaluationTagFragment.gql").definitions));


      module.exports = doc;
    
