import FormDialog from 'components/form/form-dialog/FormDialog';
import Placeholder from 'components/placeholder/Placeholder';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './paygrades.css';

@Component
export default class PaygradesPlaceholder extends TsxComponent<{}> {
  public render() {
    return (
      <FormDialog class={styles.paygrades}>
        <Placeholder
          class={styles.paygradesPlaceholderSection}
          isShown={true}
        />

        <Placeholder
          class={styles.paygradesPlaceholderButton}
          isShown={true}
        />
      </FormDialog>
    );
  }
}
