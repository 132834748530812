import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import { IconName } from 'components/icons/types';
import Icon from 'components/icons/Icon';
import styles from './input-toggle.css';

export interface Props {
  id?: string;
  label: string;
  name: string;
  value?: string;
  checked: boolean;
  description?: string;
  iconUnchecked?: IconName;
  iconChecked?: IconName;
}
@Component({
  inheritAttrs: false,
})
export default class InputToggle extends TsxComponent<
Partial<HTMLInputElement> & Props, EventsOn<HTMLInputElement>> {
  @Prop({ default: () => `inp-${getRandomString()}` })
  protected id: Props['id'];

  @Prop()
  public label: Props['label'];

  @Prop()
  public checked: Props['checked'];

  @Prop()
  public description: Props['description'];

  @Prop()
  public name: Props['name'];

  @Prop()
  public value: Props['value'];

  @Prop()
  public iconUnchecked: Props['iconUnchecked'];

  @Prop()
  public iconChecked: Props['iconChecked'];

  public render() {
    return (
      <div class={{
        [styles.inputToggle]: true,
        [styles.inputToggleDisabled]: !!this.$attrs.disabled,
        [styles.inputToggleHasLabel]: !!this.label,
      }}>
        <label for={this.id} class={styles.inputToggleLabel} title={this.label}>
          <span>{this.label}</span>
          {this.description && (
            <div class={styles.inputToggleDescription}>{this.description}</div>
          )}
        </label>

        <input
          {...{
            attrs: { ...this.$attrs },
            on: { ...this.$listeners },
          }}
          class={styles.inputToggleInput}
          id={this.id}
          name={this.name}
          type="checkbox"
          value={this.value}
          checked={this.checked}
        />

        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label aria-hidden="true" for={this.id} class={styles.inputToggleSwitch}>
          {this.iconUnchecked
          && <Icon
            name={this.iconUnchecked}
            class={[styles.inputToggleIcon, styles.inputToggleIconUnchecked]} />}
          {this.iconChecked
          && <Icon
            name={this.iconChecked}
            class={[styles.inputToggleIcon, styles.inputToggleIconChecked]} />}
        </label>
      </div>
    );
  }
}
