import { authNS } from 'components/auth/store/Store';
import type { HasAnyRightFunction, StoreState } from 'components/auth/store/Store';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import ScreenError from 'components/screen/ScreenError';
import ScreenLoading from 'components/screen/ScreenLoading';
import ScreenNotFound from 'components/screen/ScreenNotFound';
import Subheader, { Slot as SubheaderSlot } from 'components/subheader/Subheader';
import Button from 'components/subnavigation/button/Button';
import Subnavigation from 'components/subnavigation/Subnavigation';
import SubView, { Slot } from 'components/sub-view/SubView';
import { Action } from 'store/normalized-store';
import { StoreActionState } from 'utils/store';
import type { GetById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import { Route } from '../routes';
import { employmentsNS } from '../store/Store';
import type { FetchEmploymentFunction } from '../store/Store';
import type { Employment } from '../types';

import styles from './single.css';

@Component
export default class Single extends TsxComponent<{
  employmentId: number;
}> {
  protected employment: Employment | null = null;

  protected lastStoreActionState = StoreActionState.PENDING;

  @Prop()
  public employmentId: number;

  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.Getter
  protected hasAnyRight: HasAnyRightFunction;

  @authNS.State
  protected currentEmployment: StoreState['currentEmployment'];

  @employmentsNS.Action(Action.FETCH)
  protected fetchEmployment: FetchEmploymentFunction;

  @employmentsNS.Getter
  protected getById: GetById<Employment>;

  protected async populateEmployment() {
    this.lastStoreActionState = StoreActionState.PENDING;

    const response = await this.fetchEmployment({
      employmentId: this.employmentId,
    });

    this.lastStoreActionState = response.state;

    if (this.lastStoreActionState !== StoreActionState.SUCCESS) {
      return;
    }

    this.employment = this.getById(this.employmentId) || null;
  }

  public mounted() {
    this.populateEmployment();
  }

  public render() {
    if (this.lastStoreActionState === StoreActionState.PENDING) {
      return <ScreenLoading />;
    }

    if (this.lastStoreActionState === StoreActionState.NOT_FOUND) {
      return <ScreenNotFound />;
    }

    if (this.lastStoreActionState === StoreActionState.ERROR) {
      return <ScreenError />;
    }

    return (
      <SubView class={styles.single}>
        <Subheader slot={Slot.SUBHEADER}>
          <Breadcrumbs slot={SubheaderSlot.TITLE}>
            <router-link class={styles.singleBreadcrumb} to={{ name: Route.ROOT }}>
              {this.$t('employments.title')}
            </router-link>
            <router-link
              class={[styles.singleBreadcrumb, styles.singleBreadcrumbActive]}
              to={{
                name: Route.SINGLE_EMPLOYMENT_ROOT,
                params: { id: this.employment?.id },
              }}
            >
              {this.employment?.firstName} {this.employment?.lastName}
            </router-link>
          </Breadcrumbs>
        </Subheader>

        <Subnavigation slot={Slot.SUBNAVIGATION}>
          <Button route={Route.ROTATION_GROUPS} icon={IconName.ARROW_NEXT}>
            {this.$t('employments.navigation.rotationGroups')}
          </Button>
        </Subnavigation>

        <router-view />
      </SubView>
    );
  }
}
