import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { formatCurrency } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class MaximumEarning extends TsxComponent<{
  conflict: GQLShiftConflictsFragmentFragment;
}> {
  @Prop()
  public conflict: GQLShiftConflictsFragmentFragment;

  public render() {
    if (this.conflict.__typename !== 'MaximumEarningShiftConflict') {
      return null;
    }

    return (
      <span>
        {
          formatCurrency(
            this.$i18n.i18next.language,
            this.conflict.totalPayment,
            this.conflict.currency,
          )
        }
        /
        {
          formatCurrency(
            this.$i18n.i18next.language,
            this.conflict.employmentMaxPayment,
            this.conflict.currency,
          )
        }
      </span>
    );
  }
}
