import { createEventPayload, EventPayload } from 'utils/events';
import { Component as TSXComponent } from 'vue-tsx-support';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { Size } from 'components/types';
import { Key } from 'utils/keyboard';
import Icon from 'components/icons/Icon';
import { IconName } from 'components/icons/types';
import styles from './select-panel.css';

export interface Option<T extends string | number> {
  label: string;
  value: T;
  isDisabled?: boolean;
}

interface Props<T extends string | number> {
  id: string;
  options: Option<T>[];
  value?: string | number | string[] | number[];
  isPanelFocused?: boolean;
}

interface Events {
  onSelect: EventPayload<string | number, HTMLElement, KeyboardEvent | MouseEvent>;
}

@Component
export default class SelectPanel<T extends string | number> extends TSXComponent<Props<T>, Events> {
  public $refs: {
    itemsRef: HTMLLIElement[];
    listRef: HTMLElement;
  };

  private parentPosition: DOMRect | null = null;

  @Prop()
  public id: Props<T>['id'];

  @Prop()
  public options: Props<T>['options'];

  @Prop()
  public value: Props<T>['value'];

  @Prop()
  public isPanelFocused: Props<T>['isPanelFocused'];

  @Watch('isPanelFocused')
  protected panelFocusChanged() {
    if (this.isPanelFocused) {
      this.$refs.itemsRef[0].focus();
    } else {
      this.$refs.itemsRef.forEach((ref: HTMLLIElement) => {
        ref.blur();
      });
    }
  }

  private onSelect(
    value: string | number,
    e: SyntheticEvent<HTMLElement, MouseEvent | KeyboardEvent>,
  ) {
    e.stopPropagation();
    this.$emit('select', createEventPayload(e, value));
  }

  private getNextIndex(event: SyntheticEvent<HTMLElement, KeyboardEvent>, index: number): number {
    let newIndex = index;
    if (event.key === Key.ARROW_DOWN) {
      newIndex = ((index + 1) + this.$refs.itemsRef.length) % this.$refs.itemsRef.length;
    } else if (event.key === Key.ARROW_UP) {
      newIndex = ((index - 1) + this.$refs.itemsRef.length) % this.$refs.itemsRef.length;
    }
    return newIndex;
  }

  private onKeyup(event: SyntheticEvent<HTMLElement, KeyboardEvent>, index: number) {
    if (event.key === Key.ENTER) {
      this.onSelect(
        this.options[index].value,
        event as SyntheticEvent<HTMLElement, KeyboardEvent>,
      );
    }
    let newIndex = this.getNextIndex(event, index);
    while (this.options[newIndex].isDisabled) {
      newIndex = this.getNextIndex(event, newIndex);
    }
    this.$refs.itemsRef[newIndex].focus();
  }

  private isSelected(option: Option<T>): boolean {
    if (this.value === undefined) {
      return false;
    }
    if (typeof this.value === 'string' || typeof this.value === 'number') {
      return option.value === this.value;
    }
    if (this.value.length) {
      if (typeof option.value === 'string') {
        return (this.value as string[]).includes(option.value);
      }
      if (typeof option.value === 'number') {
        return (this.value as number[]).includes(option.value);
      }
    }
    return false;
  }

  private updateParentPosition() {
    if (this.$refs.listRef) {
      this.parentPosition = (this.$refs.listRef.parentNode as HTMLElement).getBoundingClientRect();
    }
  }

  public async mounted() {
    window.addEventListener(
      'scroll',
      this.updateParentPosition,
      { capture: true },
    );
    window.addEventListener('resize', this.updateParentPosition);

    if (this.$refs?.itemsRef?.length && this.isPanelFocused) {
      this.$refs.itemsRef[0].focus();
    }

    await this.$nextTick();
    this.updateParentPosition();
  }

  public beforeDestroy() {
    window.removeEventListener(
      'scroll',
      this.updateParentPosition,
      { capture: true },
    );
    window.removeEventListener('resize', this.updateParentPosition);
  }

  public render() {
    return (
      <div ref={'listRef'}>
        <transition
          appear
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {this.options?.length
          && <ul
            aria-labelledby={`${this.id}-label`}
            role="listbox"
            id={`${this.id}-listbox`}
            style={{
              width: `${this.parentPosition?.width}px`,
              // we want position relative to the screen, as we are using position: fixed here
              top: `${this.parentPosition?.bottom}px`,
            }}
            class={styles.selectPanel}
          >
            {this.options.map((option, index) => (
              <li
                id={`${this.id}-option-${index}`}
                role="option"
                tabIndex={option.isDisabled ? -1 : 0}
                aria-selected={this.isSelected(option)}
                class={{
                  [styles.selectPanelMenuItem]: true,
                  [styles.selectPanelMenuItemDisabled]: option.isDisabled,
                }}
                ref="itemsRef"
                refInFor={true}
                onClick={(e) => {
                  if (!this.options[index].isDisabled) {
                    this.onSelect(
                      this.options[index].value,
                      e,
                    );
                  }
                }}
                onKeyup={e => this.onKeyup(e, index)}
              >
                <span class={styles.selectPanelMenuItemContent}>
                  {option.label}
                </span>
                {this.isSelected(option) && (
                  <Icon class={styles.selectPanelMenuItemCheckmark}
                    size={Size.SMALL} name={IconName.DONE}
                  />)
                }
              </li>
            ))}
          </ul>
          }
        </transition>
      </div>
    );
  }
}
