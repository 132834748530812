import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { GQLAutoAcceptRequestSetting } from 'codegen/gql-types';
import InputToggle from 'components/form/input-toggle/InputToggle';
import InputButtonSelect from 'components/form/input-button-select/InputButtonSelect';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'src/utils/events';
import type { Shiftplan } from 'src/store/shiftplans/Store';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import {
  endOf, getDateInTimeZone, startOf, Unit,
} from 'src/utils/date-related';
import Collapsible from 'components/collapsible/Collapsible';
import type { FormState } from '../types';
import InputRepeatDates from '../input-repeat-dates/InputRepeatDates';
import styles from './section-settings.css';

export interface Props {
  autoAcceptSetting: GQLAutoAcceptRequestSetting;
  canEvaluate: boolean | null;
  isDisabled?: boolean;
  isStandBy: boolean | null;
  isUpdate?: boolean;
  connectedGroupId?: number | null;
  isConnectedShift: boolean;
  repeatDates: Date[];
  shiftStartsAt: Date;
  shiftplan: Pick<Shiftplan, 'startsAt' | 'endsAt'>;
}

interface Events {
  onChange: <T extends keyof FormState>(e: EventPayload<{ field: T; value: FormState[T] }>) => void;
  onConnectedShiftChanged: <T extends keyof FormState>(
    e: EventPayload<{ field: T; value: FormState[T] }>
  ) => void;
}

@Component
class SectionSettings extends TSXComponent<Props, Events> {
  protected isRepeatedShift = false;

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public repeatDates: Date[];

  @Prop()
  public autoAcceptSetting: Props['autoAcceptSetting'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public isUpdate: Props['isUpdate'];

  @Prop()
  public isStandBy: Props['isStandBy'];

  @Prop()
  public canEvaluate: Props['canEvaluate'];

  @Prop()
  public connectedGroupId: Props['connectedGroupId'];

  @Prop()
  public isConnectedShift: Props['isConnectedShift'];

  @Prop()
  public shiftStartsAt: Props['shiftStartsAt'];

  protected get shiftplanStart() {
    return getDateInTimeZone(
      startOf(new Date(this.shiftplan.startsAt), Unit.DAY, this.$timeZone.value),
      this.$timeZone.value,
    );
  }

  protected get shiftplanEnd() {
    return getDateInTimeZone(
      endOf(new Date(this.shiftplan.endsAt), Unit.DAY, this.$timeZone.value),
      this.$timeZone.value,
    );
  }

  protected get isConnectedShiftToggleVisible() {
    return this.isRepeatedShift || !!this.connectedGroupId;
  }

  protected onRepeatShiftToggleChange(
    event: SyntheticEvent<HTMLInputElement, Event>,
    checked: boolean,
  ) {
    this.isRepeatedShift = checked;
    // unselect selected dates if checkbox is unselected
    if (!this.isRepeatedShift) {
      this.$emit('change', createEventPayload(
        event,
        { field: 'repeatDates', value: [] },
      ));
    }
  }

  protected onInput<T>(value: T, e: SyntheticEvent<HTMLInputElement>) {
    this.$emit('change', createEventPayload(
      e as SyntheticEvent,
      { field: e.target.name, value },
    ));
  }

  protected onConnectedShiftToggleChange<T>(value: T, e: SyntheticEvent<HTMLInputElement>) {
    this.$emit('connectedShiftChanged', createEventPayload(
      e as SyntheticEvent,
      { field: e.target.name, value },
    ));
  }

  public render() {
    return (
      <FormSection heading={this.$t('shifts.dialog.headingSettings')}>
        <InputToggle
          disabled={this.isDisabled}
          class={[styles.sectionSettingsRow, styles.sectionSettingsRowFullWidth]}
          label={this.$t('shifts.dialog.labelCanEvaluate')}
          name="canEvaluate"
          checked={!!this.canEvaluate}
          onChange={e => this.onInput(e.target.checked, e)}
        />

        <InputToggle
          disabled={this.isDisabled}
          class={[styles.sectionSettingsRow, styles.sectionSettingsRowFullWidth]}
          label={this.$t('shifts.dialog.labelStandByShift')}
          description={this.$t('shifts.dialog.tooltipStandbyShift')}
          name="isStandBy"
          checked={!!this.isStandBy}
          onChange={e => this.onInput(e.target.checked, e)}
        />

        <InputToggle
          disabled={this.isDisabled}
          class={[styles.sectionSettingsRow, styles.sectionSettingsRowFullWidth]}
          label={this.$t('shifts.dialog.labelAcceptJoinShiftRequestsAutomatically')}
          name="autoAccept"
          checked={this.autoAcceptSetting !== GQLAutoAcceptRequestSetting.DISABLED}
          onChange={e => this.onInput(
            e.target.checked
              ? GQLAutoAcceptRequestSetting.WARNING
              : GQLAutoAcceptRequestSetting.DISABLED,
            e,
          )}
        />

        {this.autoAcceptSetting !== GQLAutoAcceptRequestSetting.DISABLED && (
          <InputButtonSelect
            disabled={this.isDisabled}
            class={[styles.sectionSettingsRow, styles.sectionSettingsRowFullWidth]}
            options={[
              {
                label: this.$t('shifts.dialog.optionAutoAcceptRequestWarning'),
                value: GQLAutoAcceptRequestSetting.WARNING,
              },
              {
                label: this.$t('shifts.dialog.optionAutoAcceptRequestEnforced'),
                value: GQLAutoAcceptRequestSetting.ENFORCED,
              },
            ]}
            name="autoAccept"
            value={this.autoAcceptSetting}
            onChange={e => this.onInput(
              e.payload,
              e.event,
            )}
          />
        )}
        {!this.isUpdate
          && <InputToggle
            label={this.$t('shifts.dialog.labelRepeatShift')}
            name="repeatShift"
            disabled={this.isDisabled}
            checked={this.isRepeatedShift}
            onChange={e => this.onRepeatShiftToggleChange(e, e.target.checked)}/>
        }
        {
          !this.isUpdate
          && <Collapsible isOpen={this.isRepeatedShift}>
            <InputRepeatDates
              class={[styles.sectionSettingsRow, styles.sectionSettingsRowFullWidth]}
              min={this.shiftplanStart}
              max={this.shiftplanEnd}
              dates={this.repeatDates}
              disabledDates={[this.shiftStartsAt]}
              onChange={({ payload, event }) => this.$emit('change', createEventPayload(
                event as SyntheticEvent,
                { field: 'repeatDates', value: payload },
              ))}
              onConnectedShiftChange={
                eventPayload => this.onInput(
                  eventPayload.payload,
                  eventPayload.event,
                )}/>
          </Collapsible>
        }
        <Collapsible isOpen={this.isConnectedShiftToggleVisible}>
          <InputToggle
            label={this.$t(this.$t('shifts.dialog.labelConnectedShift'))}
            name="isConnectedShift"
            class={[styles.sectionSettingsRow, styles.sectionSettingsRowFullWidth]}
            checked={this.isConnectedShift}
            onChange={e => this.onConnectedShiftToggleChange(e.target.checked, e)} />
        </Collapsible>

      </FormSection>
    );
  }
}

export default SectionSettings;
