import { Mode } from 'components/calendar-common/Enums';
import { DATE_TIME_STRING_FORMAT, MINUTES_IN_HOUR } from 'components/calendar-common/helpers/intervals/Intervals.js';
import DateItem from 'components/calendar-common/common/DateItem';
import EventPart from 'components/calendar-common/common/time-grid-events/EventPart';
import { EventEditTypes } from 'components/calendar-common/common/time-grid-events/StoreFactory';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import GridMixin from 'components/calendar-common/grid/GridMixin';
import {
  GRID_COLUMN_MIN_WIDTH_DAY,
  GRID_LABEL_COLUMN_SMALL_WIDTH,
} from 'components/calendar-common/grid/GridVariables';
import TimeGrid, {
  DEFAULT_TIME_GRID_CELL_HEIGHT,
  DEFAULT_TIME_GRID_STEP,
  TimeGridScope,
} from 'components/calendar-common/grid/time-grid/TimeGrid';
import { Route } from 'components/dialog-shift/routes';
import { toDate } from 'date-fns-tz';
import moment, { Moment } from 'moment';
import { VueConstructor } from 'vue';
import {
  Component, Vue, Mixins,
} from 'vue-property-decorator';
import { Element } from 'vue-tsx-support/types/base';
import Shift from 'components/calendar-common/shifts/Shift';
import { SplitableEvent } from '../SplitableEvent';
import { calendarCommonNS } from '../../../../calendar/common/Store';

@Component
class TimeGridView extends Mixins(GridMixin) {
  @calendarCommonNS.Getter
  protected dates: DateItem[];

  @calendarCommonNS.Getter
  protected mode: Mode;

  protected timeGridStep: number = DEFAULT_TIME_GRID_STEP;

  protected gridCellHeight: number = DEFAULT_TIME_GRID_CELL_HEIGHT;

  protected eventComponent: VueConstructor<Vue>;

  protected editedEvent: SplitableEvent;

  protected eventEditType: EventEditTypes;

  protected eventParts: Record<string, EventPart[]>;

  protected isEditingEvent: boolean;

  protected get gridStyle() {
    return {
      gridTemplateColumns: `${GRID_LABEL_COLUMN_SMALL_WIDTH} repeat(${
        this.dates.length}, minmax(${
        GRID_COLUMN_MIN_WIDTH_DAY}, 1fr))`,
    };
  }

  private setTime(date: Moment, hours: number, minutes: number) {
    return date.clone().add({
      hours,
      minutes,
    });
  }

  private getDateFromMousePosition(date: Moment, mousePosition: number) {
    const minutes = Math.floor(mousePosition / this.gridCellHeight)
      * this.timeGridStep;
    return this.setTime(
      date,
      Math.floor(minutes / MINUTES_IN_HOUR),
      minutes % MINUTES_IN_HOUR,
    );
  }

  private onMouseDown(event: MouseEvent, dateItem: DateItem) {
    // checks for incorrect events
    if (this.isEditingEvent
        || (event.button !== 0)
        || !event.target
        || !this.canManageEvents
        || !dateItem.isWithinShiftplan) {
      return;
    }
    const mousePosition = event.offsetY;
    const startDate = this.getDateFromMousePosition(dateItem.date, mousePosition);
    const endDate = startDate.clone().add(
      // 3 cells feels best from UX perspective
      this.timeGridStep * 3,
      'minutes',
    ).format(DATE_TIME_STRING_FORMAT);
    this.startEventEdit({
      event: this.getEvent(startDate.format(DATE_TIME_STRING_FORMAT), endDate),
      editType: EventEditTypes.NEW_EVENT,
    });

    // FIXME: this is just a workaround because the current architecture does not allow to easily
    // hook into the event without major refactoring
    document.addEventListener('mouseup', () => {
      const startsAt = toDate(this.editedEvent.startsAt, { timeZone: this.$timeZone.value });
      const endsAt = toDate(this.editedEvent.endsAt, { timeZone: this.$timeZone.value });

      this.$router.push({
        name: Route.DIALOG_NEW,
        params: {
          shiftplanId: this.$route.params.shiftplanId,
        },
        query: {
          startsAt: startsAt.toISOString(),
          endsAt: endsAt.toISOString(),
        },
      });
    }, { capture: true, once: true });
  }

  private onMouseOver(event: MouseEvent, dateItem: DateItem) {
    // check for incorrect events
    if (!this.isEditingEvent || !dateItem.isWithinShiftplan) {
      return;
    }
    // do not trigger if same day as start
    // as we drag start of the shift
    switch (this.eventEditType) {
      case EventEditTypes.DRAG_EVENT: {
        const momentStart: Moment = moment(this.editedEvent.startsAt);
        if (momentStart.isSame(dateItem.date, 'day')
          && !this.editedEvent.isFixed) {
          return;
        }
        const cellDate = this.setTime(
          dateItem.date,
          momentStart.hour(),
          momentStart.minute(),
        );
        const newStartDate = this.editedEvent.isFixed
          ? this.getDateFromMousePosition(dateItem.date, event.offsetY)
          : cellDate;
        const newEndDate = newStartDate.clone()
          .add(this.editedEvent.getDurationMinutes(), 'minute');
        this.updateEditedEvent({
          endsAt: newEndDate.format(DATE_TIME_STRING_FORMAT),
          startsAt: newStartDate.format(DATE_TIME_STRING_FORMAT),
          isFixed: false,
        });
        break;
      }
      case EventEditTypes.RESIZE_EVENT:
      case EventEditTypes.NEW_EVENT: {
        const momentEnd = moment(this.editedEvent.endsAt);
        // do not trigger if same day as end
        // as we drag end of shift
        if (momentEnd.isSame(dateItem.date, 'day')
          && !this.editedEvent.isFixed) {
          return;
        }
        // add 30 minutes as cell time is
        // top of the cell and we want whole cell
        // to be covered on hover
        const newEndDate = this.getDateFromMousePosition(dateItem.date, event.offsetY)
          .add(this.timeGridStep, 'minutes')
          .format(DATE_TIME_STRING_FORMAT);
        this.updateEditedEvent({ endsAt: newEndDate, isFixed: false });
        break;
      }
      default:
    }
  }

  private renderColumn({ dateItem }: TimeGridScope) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const EventComponent = this.eventComponent as any;
    return this.eventParts[dateItem.dateKey]
      && this.eventParts[dateItem.dateKey].map((eventPart: EventPart) => <EventComponent
        eventPart={eventPart}
        key={eventPart.key}
        isEdited={this.isEditingEvent
          && (this.editedEvent.id === eventPart.id)
          && !eventPart.isEditedShiftCopy}/>);
  }

  public render() {
    return (
      <div class={[gridStyles.gridTable, this.viewClass]} style={this.gridStyle}>
        {this.renderHeader()}

        {this.renderSlots()}

        <TimeGrid
          showSummary={this.showSummary}
          onCellMouseover={(e, dateItem) => this.onMouseOver(e, dateItem)}
          onCellMousedown={(e, dateItem) => this.onMouseDown(e, dateItem)}
          dates={this.dates}
          isEdited={this.isEditingEvent}
          timeGridStep={this.timeGridStep}
          scopedSlots= {{
            column: (scope: TimeGridScope) => this.renderColumn(scope),
          }}>
        </TimeGrid>
      </div>
    );
  }

  protected get viewClass(): string | undefined {
    return undefined;
  }

  protected get canManageEvents() {
    return this.mode === Mode.STAKEHOLDER;
  }

  // overridden functions
  protected renderSlots(): any[] {
    return [];
  }

  protected renderHeader(): Element | null {
    return null;
  }

  protected get showSummary() {
    return false;
  }

  protected get shiftsByDates(): Record<string, Shift[]> {
    return {};
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  protected getEvent(startDate: string, endDate: string): SplitableEvent {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  protected startEventEdit(params: { event: SplitableEvent; editType: EventEditTypes}) {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  protected onHeaderClick(dateItem: DateItem) {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  protected updateEditedEvent(params: Partial<SplitableEvent>) {
    throw new Error('Not implemented');
  }
}

export default TimeGridView;
