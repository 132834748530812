import HighlightableEvent from 'components/calendar-common/highlights/highlightable-event/HighlightableEvent';
import { HighlightableEventTypes } from 'components/calendar-common/highlights/Store';
import { Component } from 'vue-property-decorator';
import Shift from './Shift';

@Component
class HighlightableShift extends HighlightableEvent {
  protected shift: Shift;

  public isHighlighted(type: HighlightableEventTypes, id: number[] | null) {
    if (!Array.isArray(id)) {
      return false;
    }

    switch (type) {
      case HighlightableEventTypes.Employment:
        return this.shift.employmentIds
          .some(employmentId => id.includes(employmentId));
      case HighlightableEventTypes.AssignmentGroup:
        return this.shift.assignmentGroupIds
          .some(assignmentGroupId => id.includes(assignmentGroupId));
      case HighlightableEventTypes.ShiftRotationGroup:
        return this.shift.shiftRotationGroupIds
          .some(shiftRotationGroupId => id.includes(shiftRotationGroupId));
      default:
        return false;
    }
  }
}

export default HighlightableShift;
