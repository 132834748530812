import { authNS } from 'components/auth/store/Store';
import type {
  HasAnyLocationRightFunction,
  HasAnyLocationsPositionRightFunction,
  HasAnyRightFunction,
  StoreState,
} from 'components/auth/store/Store';
import EmploymentWithAvatar from 'components/employment-with-avatar/EmploymentWithAvatar';
import { shiftsNS } from 'store/shifts/Store';
import type { CanManageFunction, Shift } from 'store/shifts/Store';
import { redirectToParentIf } from 'utils/route';
import { sortByLastName } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Size } from 'components/types';
import ManagerView from './manager-view/ManagerView';

import styles from './employees.css';

interface Props {
  shift?: Shift;
}

@Component
export default class Employees extends TsxComponent<Props> {
  @Prop()
  public shift?: Props['shift'];

  @authNS.Getter
  protected hasAnyRight: HasAnyRightFunction;

  @authNS.Getter
  protected hasAnyLocationRight: HasAnyLocationRightFunction;

  @authNS.Getter
  protected hasAnyLocationsPositionRight: HasAnyLocationsPositionRightFunction;

  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  @shiftsNS.Getter
  protected canManageStaff: CanManageFunction;

  protected get employments() {
    return (this.shift?.staffShifts || [])
      .flatMap(o => o.employment)
      .filter((o): o is NonNullable<typeof o> => !!o).sort(sortByLastName);
  }

  protected get isShiftManager() {
    if (this.shift?.canManage) {
      return true;
    }

    const shiftLocationId = this.shift?.locationsPosition?.location?.id || 0;
    const shiftLocationsPositionId = this.shift?.locationsPosition.id || 0;

    return this.canManageStaff(shiftLocationId, shiftLocationsPositionId);
  }

  public beforeRouteEnter(_to, _from, next) {
    next(redirectToParentIf((vm: this) => !vm.currentCompany?.viewPersonal && !vm.isShiftManager));
  }

  public render() {
    if (this.isShiftManager) {
      return (
        <ManagerView
          shift={this.shift}
          onRefetchShift={({ useBackgroundFetch }) => this.$emit('refetchShift', { useBackgroundFetch })}
        />
      );
    }

    return (
      <div class={styles.employees}>
        {
          this.employments.length
            ? this.employments.map(staffShift => (
              <EmploymentWithAvatar
                class={styles.employeesItem}
                employment={staffShift}
                key={staffShift.id}
                size={Size.MEDIUM}
                withStaffNumber={true}
              />
            )) : (
              <p class={styles.employeesNone}>
                {this.$t('shifts.employees.none')}
              </p>
            )
        }
      </div>
    );
  }
}
