import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import RotationWizardAction from 'components/rotation-wizard/store/Action';
import { rotationWizardNS, StoreState as RotationWizardStoreState } from 'components/rotation-wizard/store/Store';
import type {
  CreateRotationResponse,
  RotationGroup,
  SuccessOrResponse,
} from 'components/rotation-wizard/store/types';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import TableAction from 'components/table/store/Action';
import { SetFilterPayload } from 'components/table/store/Store';
import { SentryTag } from 'services/logger/SentryTransport';
import { getFirstErrorFromResponse, GQLResponseExtension } from 'utils/store';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import RotationGroupCard from '../../rotation-group-card/RotationGroupCard';
import { employmentAssignmentNS } from '../store/Store';
import type { EmploymentsTableData, Filters } from '../store/Store';

import styles from './sidebar.css';
import Action from '../store/Action';
import DialogOverlap from '../dialog-overlap/DialogOverlap';

const OVERLAP_ERROR_KEY = 'overlap';

@Component
export default class Sidebar extends TsxComponent<{}> {
  protected isSubmitting = false;

  protected overlappingEmployments: EmploymentsTableData[] = [];

  @employmentAssignmentNS.State
  private unassignedEmploymentsLength: number;

  @employmentAssignmentNS.State
  private selection: number[];

  @employmentAssignmentNS.State
  protected rotationGroups: RotationGroup[];

  @rotationWizardNS.State
  protected anchorDate: RotationWizardStoreState['anchorDate'];

  @employmentAssignmentNS.State('data')
  private employmentsTableRows: EmploymentsTableData[];

  @employmentAssignmentNS.State
  private filters: Filters;

  @employmentAssignmentNS.Action(TableAction.SET_FILTER)
  private setFilter: (payload: SetFilterPayload<Filters, keyof Filters>) => Promise<void>;

  @employmentAssignmentNS.Action(TableAction.SET_SELECTION)
  private setSelection: (id: number[]) => void;

  @employmentAssignmentNS.Action(TableAction.REFETCH)
  private fetchEmploymentsData: () => void;

  @employmentAssignmentNS.Action(Action.FETCH_SIDEBAR_DATA)
  private fetchSidebarData: () => void;

  @rotationWizardNS.Action(RotationWizardAction.ASSIGN_EMPLOYMENT)
  protected assignEmployment: (payload: {
    employmentId: number;
    shiftRotationGroupId: number;
  }) => Promise<CreateRotationResponse>;

  @rotationWizardNS.Action(RotationWizardAction.UNASSIGN_EMPLOYMENT)
  protected unassignEmployment: (employmentsRotationGroupId: number) => Promise<SuccessOrResponse>;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  protected getErrorMessage(errorResponse?: GQLResponseExtension) {
    if (errorResponse?.status === 403) {
      return { key: 'rotationWizard.error.forbidden' };
    }

    if (errorResponse?.status === 404) {
      return { key: 'rotationWizard.error.employmentAssignment.notFound' };
    }

    const error = getFirstErrorFromResponse(errorResponse);

    return error
      ? { key: `rotationWizard.error.employmentAssignment.${error.key}`, val: error.val }
      : { key: 'general.error.unknown' };
  }

  protected getOverlaps(errored: ({ key: string } | undefined)[]) {
    const overlaps = errored.map(error => !!error && error.key.endsWith(OVERLAP_ERROR_KEY));

    return overlaps
      .map((overlap, index) => (overlap ? this.selection[index] : Number.NaN))
      .filter(Boolean)
      .map(employmentId => this.employmentsById[employmentId]);
  }

  protected async onAssignSelectedClick(shiftRotationGroupId: number) {
    this.isSubmitting = true;

    const promises = this.selection.map(employmentId => this.assignEmployment({
      employmentId,
      shiftRotationGroupId,
    }));

    const responses = await Promise.all(promises);

    this.isSubmitting = false;

    const errored = responses.map(response => (
      typeof response !== 'number'
        ? this.getErrorMessage(response)
        : undefined
    ));

    const hasAnyOverlap = errored.some(error => error && error.key.endsWith(OVERLAP_ERROR_KEY));

    if (hasAnyOverlap) {
      this.overlappingEmployments = this.getOverlaps(errored);

      // FAQ: leave unsuccessful selected
      this.setSelection(this.selection.filter((_, index) => typeof responses[index] !== 'number'));

      return undefined;
    }

    const firstNonOverlapError = errored.find(
      error => error && !error.key.endsWith(OVERLAP_ERROR_KEY),
    );

    if (firstNonOverlapError) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, Sidebar.name]],
        message: JSON.stringify(responses),
      });

      // FAQ: leave unsuccessful selected
      this.setSelection(this.selection.filter((_, index) => typeof responses[index] !== 'number'));

      return this.showSnackbar({
        kind: AlertKind.ERROR,
        message: this.$t(...firstNonOverlapError.key, { ...firstNonOverlapError.val }),
        timeout: 5000,
      });
    }

    this.setSelection([]);

    this.fetchEmploymentsData();
    this.fetchSidebarData();

    return this.showSnackbar({
      kind: AlertKind.SUCCESS,
      message: this.$t('rotationWizard.saved'),
      timeout: 5000,
    });
  }

  protected async onUnassignSelectedClick() {
    this.isSubmitting = true;

    const promises = this.selection.map((id) => {
      const row = this.employmentsById[id];

      return row
        && row.shiftRotationGroup
        && this.unassignEmployment(
          row.shiftRotationGroup.employmentsShiftRotationGroupId,
        );
    });

    const responses = await Promise.all(promises);

    this.isSubmitting = false;

    const firstErrorResponse = responses.find(
      (response): response is GQLResponseExtension => !response || !('success' in response),
    );

    if (firstErrorResponse) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, Sidebar.name]],
        message: JSON.stringify(responses),
      });

      // FAQ: leave unsuccessful selected
      this.setSelection(this.selection.filter((_, idx) => {
        const response = responses[idx];
        return !response || !('success' in response) || !response.success;
      }));

      const firstError = this.getErrorMessage(firstErrorResponse);

      return this.showSnackbar({
        kind: AlertKind.ERROR,
        message: this.$t(firstError.key, { ...firstError.val }),
        timeout: 5000,
      });
    }

    this.setSelection([]);

    this.fetchEmploymentsData();
    this.fetchSidebarData();

    return this.showSnackbar({
      kind: AlertKind.SUCCESS,
      message: this.$t('rotationWizard.saved'),
      timeout: 5000,
    });
  }

  protected onRotationGroupFilterClick(shiftRotationGroupId: number) {
    if (this.filters.shiftRotationGroupIds.includes(shiftRotationGroupId)) {
      this.setFilter([{
        key: 'shiftRotationGroupIds',
        value: this.filters.shiftRotationGroupIds.filter(id => id !== shiftRotationGroupId),
      }]);

      return;
    }

    this.setFilter([{
      key: 'shiftRotationGroupIds',
      value: [shiftRotationGroupId],
    }, {
      key: 'notInShiftRotationGroupIds',
      value: [],
    }]);
  }

  protected onNotInRotationGroupFilterClick() {
    if (!this.filters.notInShiftRotationGroupIds.length) {
      this.setFilter([{
        key: 'notInShiftRotationGroupIds',
        value: this.rotationGroups.map(o => o.id),
      }, {
        key: 'shiftRotationGroupIds',
        value: [],
      }]);

      return;
    }

    this.setFilter([{
      key: 'notInShiftRotationGroupIds',
      value: [],
    }]);
  }

  protected get employmentsById() {
    return this.employmentsTableRows.reduce((prev, cur) => {
      prev[cur.id] = cur;
      return prev;
    }, {} as Record<number, EmploymentsTableData>);
  }

  protected get isAddSelectedShown() {
    return this.selection.length > 0
      && this.selection.every((id) => {
        const row = this.employmentsById[id];
        return row && row.shiftRotationGroup === null;
      });
  }

  protected get isUnassignSelectedShown() {
    return this.selection.length > 0
      && this.selection.every((id) => {
        const row = this.employmentsById[id];
        return row && row.shiftRotationGroup !== null;
      });
  }

  protected get assignedEmploymentsLength() {
    return this.rotationGroups.reduce((acc, current) => acc + current.employmentsLength, 0);
  }

  public mounted() {
    this.fetchSidebarData();
  }

  public render() {
    return (
      <aside class={styles.sidebar}>
        <DialogOverlap
          employments={this.overlappingEmployments}
          isOpen={this.overlappingEmployments.length > 0}
          onCloseClick={() => { this.overlappingEmployments = []; }}
        />

        <div class={styles.sidebarHeader}>
          <h3 class={styles.sidebarTitle}>
            {this.$t('rotationWizard.employmentAssignment.sidebarTitle')}
          </h3>

          <div class={styles.sidebarUnassigned}>
            {this.$t('rotationWizard.employmentAssignment.sidebarUnassigned')}: {this.unassignedEmploymentsLength}
            <Button
              class={styles.sidebarUnassignedFilterButton}
              color={
                this.filters.notInShiftRotationGroupIds.length > 0
                  ? ButtonColor.SUCCESS
                  : ButtonColor.SECONDARY
              }
              icon={IconName.FILTER}
              iconPosition={IconPosition.ALONE}
              onClick={this.onNotInRotationGroupFilterClick}
              size={Size.SMALL}
              kind={ButtonKind.GHOST}
            />
          </div>

          {this.isUnassignSelectedShown && (
            <Button
              class={styles.sidebarUnassignButton}
              color={ButtonColor.ERROR}
              onClick={this.onUnassignSelectedClick}
              size={Size.SMALL}
            >
              {this.$t('rotationWizard.employmentAssignment.buttonUnassignSelected')}
            </Button>
          )}
        </div>

        {
          this.rotationGroups.map((group, index) => (
            <RotationGroupCard
              anchorDate={new Date(this.anchorDate)}
              group={group}
              groupIndex={index}
              usersLength={group.employmentsLength}
              isAddSelectedShown={this.isAddSelectedShown}
              isFilterForGroupActive={this.filters.shiftRotationGroupIds.includes(group.id)}
              isUnassignSelectedShown={this.isUnassignSelectedShown}
              onAddSelectedClick={() => group.id && this.onAssignSelectedClick(group.id)}
              onFilterClick={() => this.onRotationGroupFilterClick(group.id)}
            />
          ))
        }
      </aside>
    );
  }
}
