import Employee from 'components/calendar-common/employees/employee/Employee';
import Tag from 'components/calendar-common/tags/tag/Tag';
import { SlotDisplayStyle } from 'components/calendar-common/Enums';
import SpIcon from 'components/sp-icon/SpIcon';
import RootStoreState from 'src/store/RootStoreState';
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import Shift from '../Shift';
import styles from './shift-day.css';

@Component
class ShiftDaySlots extends Vue {
  protected shift: Shift;

  @State((state: RootStoreState) => state.calendar.common.slotDisplayStyle)
  private slotDisplayStyle: SlotDisplayStyle;

  @State((state: RootStoreState) => state.auth.currentCompany?.isTagsAllowed || false)
  private isTagsEnabled: boolean;

  @State((state: RootStoreState) => state.auth.currentCompany?.canUseShiftPresets || false)
  public isShiftPresetsEnabled: boolean;

  private maxDisplayedShifts = 3;

  public get shiftSlots(): string {
    return `${this.shift.staffShiftsLength}/${this.shift.workers}`;
  }

  public get isMultiDay(): boolean {
    return this.shift.isMultiDay();
  }

  public get isStandBy(): boolean {
    return this.shift.isStandBy();
  }

  public get shiftTime(): string {
    return `${this.shift.getStartDateTime()
      .format('HH:mm')} - ${this.shift.getEndDateTime()
      .format('HH:mm')}`;
  }

  public get isFullnames() {
    return this.slotDisplayStyle === SlotDisplayStyle.FULLNAMES;
  }

  public mounted() {
    const widthOfShift = this.$el.clientWidth;
    const iconSize = 25;

    // // iconSize has to be reduced at end because
    // // we may need to show + sign which is shown full width.
    const spaceAvailableForIcons = widthOfShift - iconSize;

    // icons size is set to 25px in CSS, but as
    // we are only displaying half of icons, so
    // we can consider lower value for iconSize.
    // Mostly it is 25/2
    // iconSize = 18;

    this.maxDisplayedShifts = Math.max(Math.round(spaceAvailableForIcons / iconSize), 3);
  }

  public renderIcons() {
    return (<div class={styles.shiftDayIcons}>

      {this.shift.hasJoinRequests() && <div class={styles.shiftDayIcon}>
        <SpIcon name="application" />
        <span class={styles.shiftDayIconText}>
          {this.shift.joinRequests.length}
        </span>
      </div>}

      {this.shift.hasReplaceRequests() && <div class={styles.shiftDayIcon}>
        <SpIcon name="direct-swap" />
        <span class={styles.shiftDayIconText}>
          {this.shift.replaceRequests.length}
        </span>
      </div>}

      {this.shift.hasPendingEvaluations() && <div class={styles.shiftDayIcon}>
        <SpIcon name="evaluation" />
        <span class={styles.shiftDayIconText}>
          {this.shift.pendingEvaluationsLength}
        </span>
      </div>}

      {this.shift.hasLeaveRequests() && <div class={styles.shiftDayIcon}>
        <SpIcon name="swap" />
        <span class={styles.shiftDayIconText}>
          {this.shift.leaveRequests.length}
        </span>
      </div>}

      {this.shift.hasNote() && <div class={styles.shiftDayIcon}>
        <SpIcon name="note"
          data-tooltip="true"
          data-html="true"
          label={this.shift.composedNote}/>
      </div>}

    </div>);
  }

  public renderEmployees(shownEmploymentsCount: number = Number.POSITIVE_INFINITY) {
    const {
      workers: slotsCount,
      staffShifts,
      staffShiftsLength: fullSlotsCount,
      style,
    } = this.shift;
    // All icons have fixed positioning on them so they need
    // to be calculated so not to override other icons.
    // Calculations below are used to find starting position at
    // which employee icon for each slot to be displayed.
    // Example would be: if displayedEmployees is present, then
    // hiddenSlots should only be displayed after displaying all
    // employees within displayedEmployees.

    // =====================
    // Displayed Employees
    // Slots filled, and staff shift provided
    // =====================
    const maxDisplayedShifts = this.isFullnames
      ? shownEmploymentsCount
      : this.maxDisplayedShifts;
    const displayedEmployees = staffShifts.slice(0, maxDisplayedShifts);

    const totalDisplayedEmployees = displayedEmployees.length;
    // =====================
    // Hidden Slots
    // Slots filled, but no staff shift provided
    // =====================
    const hiddenSlotsCount = Math.max(
      Math.min(fullSlotsCount, maxDisplayedShifts) - staffShifts.length,
      0,
    );
    const hiddenSlots = new Array(hiddenSlotsCount).fill('');
    const totalHiddenSlots = hiddenSlots.length;

    // For icon positioning
    const hiddenSlotsMoveIndex = totalDisplayedEmployees;

    // =====================
    // Open Slots
    // Slots available (after all displayed and hidden slots)
    // =====================
    const openSlotsCount = Math.max(
      Math.min(slotsCount, maxDisplayedShifts) - staffShifts.length - hiddenSlotsCount,
      0,
    );
    const openSlots = new Array(openSlotsCount).fill('');

    // For icon positioning
    const totalOpenSlots = openSlots.length;
    const openSlotsMoveIndex = hiddenSlotsMoveIndex + totalHiddenSlots;

    // =====================
    // Extra Employee Count
    // Calculate how many employees will not fit
    // =====================
    const extraEmployeesCount = Math.max(fullSlotsCount - maxDisplayedShifts, 0);

    // for icon positioning
    const extraEmployeesCountMoveIndex = openSlotsMoveIndex + totalOpenSlots;

    return (this.slotDisplayStyle !== SlotDisplayStyle.COMPACT
    && <section class={{
      [styles.shiftDayEmployees]: true,
      [styles.shiftDayEmployeesFullnames]: this.slotDisplayStyle === SlotDisplayStyle.FULLNAMES,
    }}
    style={{
      borderColor: style.backgroundColor,
    }}
    onMousemove={e => e.stopPropagation()}>
      {displayedEmployees.map((employee, index) => <Employee
        slotDisplayStyle={this.slotDisplayStyle}
        borderColor={style.backgroundColor}
        employee={employee}
        eventId={this.shift.id}
        index={index}
        key={employee.id}
        isDraggable={this.shift.isManageable} />)}

      {hiddenSlots.map((hiddenSlot, index) => <Employee
        slotDisplayStyle={this.slotDisplayStyle}
        index={hiddenSlotsMoveIndex + index}
        borderColor={style.backgroundColor}
        isHidden={true}/>)}

      {openSlots.map((openSlot, index) => <Employee
        slotDisplayStyle={this.slotDisplayStyle}
        index={openSlotsMoveIndex + index}
        borderColor={style.backgroundColor}
        isOpen={true}/>)}

      {extraEmployeesCount > 0 && <Employee
        slotDisplayStyle={this.slotDisplayStyle}
        index={extraEmployeesCountMoveIndex}
        isExtra={true}
        borderColor={style.backgroundColor}
        value={extraEmployeesCount}/>}
    </section>);
  }

  public renderEmployeesChip() {
    return (<div class={[styles.shiftDayChipsChip, styles.shiftDayEmployeesChip, {
      [styles.shiftDayEmployeesChipOverassigned]: this.shift.isOverassigned,
      [styles.shiftDayEmployeesChipUnderassigned]:
        !this.shift.isFull && !this.shift.isOverassigned,
      [styles.shiftDayEmployeesChipFullLine]: this.isFullnames,
    }]}>
      <SpIcon name="person" />
      {this.shiftSlots}
    </div>);
  }

  public renderPositionName() {
    return (<div class={styles.shiftDayHeaderPosition}>
      {this.shift.position.name}
    </div>);
  }

  private renderTime() {
    return (<div class={styles.shiftDaySubheader}>
      <span>
        {this.shiftTime}
        {this.isMultiDay ? '+' : ''}
      </span>
      {this.isMultiDay && <SpIcon name="moon" class={styles.shiftDayMultidayIcon}/>}
      {this.isStandBy && <span>&nbsp;(S)</span>}
    </div>);
  }

  private renderPreset() {
    const { shiftPreset } = this.shift;
    const name = shiftPreset
      ? shiftPreset.name
      : this.$t('calendar.shiftPresetCustom');
    return (<div class={styles.shiftDaySubheader}>
      <span>
        {name}
      </span>
      {this.isStandBy && <span>&nbsp;(S)</span>}
    </div>);
  }

  public renderTimeSlot() {
    return this.isShiftPresetsEnabled && this.shift.shiftPreset
      ? this.renderPreset()
      : this.renderTime();
  }

  public renderTags() {
    const { tags, style } = this.shift;
    if (tags.length === 0 || !this.isTagsEnabled) {
      return [];
    }
    if (this.isFullnames) {
      return this.shift.tags.map(tag => <Tag
        name={tag.name}
        tooltip={tag.name}
        bgColor={tag.color || style.color}
        textColor={tag.secondaryColor} />);
    }
    const tooltip = this.shift.tags.map(tag => tag.name).join('<br/>');
    const name = (tags.length === 1)
      ? tags[0].name
      : `${this.shift.tags.length} ${this.$t('calendar.tags')}`;
    const color = (tags.length === 1)
      ? tags[0].color || style.color
      : style.color;
    const textColor = (tags.length === 1)
      ? tags[0].secondaryColor : style.backgroundColor;
    return <Tag bgColor={color}
      textColor={textColor}
      name={name}
      tooltip={tooltip} />;
  }
}

export default ShiftDaySlots;
