import type { GQLEmploymentFieldsFragment } from 'codegen/gql-types';
import moment, { Moment } from 'moment';
import DateItem, { DateKey } from '../common/DateItem';

export interface PictureData {
  pictureSmall: string | null;
}

export type EmploymentConstructorProps = GQLEmploymentFieldsFragment
& { presentLocationsPositionIds?: number[] };

class Employment {
  public firstName: string;

  public lastName: string;

  public id: number;

  public userId: number;

  public pictureData: PictureData;

  public deletedAt: Moment | null;

  /**
   * LocationsPosition ids that are present in current view
   * some of these might be previous locations of employment
   * and should be used only for display purposes
   *
   * @type {number[]}
   * @memberof Employment
   */
  public presentLocationsPositionIds: number[];

  /**
   * ShiftRotationGroup ids that that the employment is assigned to
   * @type {number[]}
   * @memberof Employment
   */
  public shiftRotationGroupIds: number[];

  /**
   * LocationsPosition ids that employee has currently access too
   *
   * @type {number[]}
   * @memberof Employment
   */
  public activeLocationsPositionIds: number[];

  public name: string;

  public image: string | null;

  /**
      * ShiftRotationGroup ids that that the employment is assigned to
      * @type {number[]}
      * @memberof Employment
      */
  public employmentsShiftRotationGroups: GQLEmploymentFieldsFragment['employmentsShiftRotationGroups'];

  public constructor({
    firstName,
    lastName,
    deletedAt,
    userId,
    id,
    pictureData,
    presentLocationsPositionIds = [],
    locationsPositionIds,
    employmentsShiftRotationGroups = [],
  }: EmploymentConstructorProps) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.deletedAt = deletedAt !== null ? moment(deletedAt) : null;
    this.id = id;
    this.userId = userId;
    this.pictureData = pictureData || { pictureSmall: null };
    this.presentLocationsPositionIds = presentLocationsPositionIds;
    this.activeLocationsPositionIds = locationsPositionIds || [];
    this.image = this.pictureData.pictureSmall;
    this.name = `${firstName} ${lastName}`;
    this.employmentsShiftRotationGroups = employmentsShiftRotationGroups;
    this.userId = userId;

    this.shiftRotationGroupIds = employmentsShiftRotationGroups
      .map(it => it.shiftRotationGroup.id);
    this.image = this.pictureData.pictureSmall;
    this.name = `${firstName} ${lastName}`;
  }

  public isDeleted() {
    return this.deletedAt !== null;
  }

  public getShiftPresetIdsForTimeframe(dateItems: DateItem[]): Record<DateKey, number | null> {
    const momentTimeframeStartsAt = dateItems[0].date;
    const momentTimeframeEndsAt = dateItems[dateItems.length - 1].date;
    const pattern: Record<DateKey, number | null> = Object
      .fromEntries(dateItems.map(it => [it.dateKey, null]));

    if (!this.employmentsShiftRotationGroups) {
      return pattern;
    }

    // filter assignments that are not in current timeframe
    const rotationGroupsInTimeframe = this.employmentsShiftRotationGroups
      .filter(({ startsAt, endsAt }) => !(
        (startsAt && momentTimeframeEndsAt.isBefore(startsAt))
        || (endsAt && momentTimeframeStartsAt.isAfter(endsAt))));

    rotationGroupsInTimeframe.forEach((rotationGroupAssignment) => {
      const { anchorDate, rotationInterval } = rotationGroupAssignment
        .shiftRotationGroup
        .shiftRotation;

      const { shiftPresetIds } = rotationGroupAssignment.shiftRotationGroup;
      const {
        endsAt,
        startsAt,
      } = rotationGroupAssignment;
      // set all dates to start or end of day
      const assignmentEndDate = moment(endsAt).endOf('day');
      const assignmentStartDate = moment(startsAt).startOf('day');
      const momentAnchorDate: Moment = moment(anchorDate).startOf('day');
      const rotationPadding = momentTimeframeStartsAt
        .diff(momentAnchorDate, 'days') % rotationInterval;

      dateItems.forEach((dateItem, index) => {
        if (
        // ignore dates outside current shiftplan
          !dateItem.isWithinShiftplan
          || (assignmentEndDate && dateItem.date.isAfter(assignmentEndDate, 'days'))
          || (assignmentStartDate && dateItem.date.isBefore(assignmentStartDate, 'days'))) {
          return;
        }

        if (pattern[dateItem.dateKey] !== null) {
          console.warn('there is an overlap?', index, pattern[index], rotationGroupAssignment);
        }

        const idx = (index + rotationPadding < 0
        // make sure that index is positive
          ? rotationInterval + index + rotationPadding
          : index + rotationPadding) % rotationInterval;

        pattern[dateItem.dateKey] = shiftPresetIds[idx];
      });
    });

    return pattern;
  }
}

export default Employment;
