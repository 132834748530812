import { ButtonColor, ButtonKind, ButtonSize } from 'components/form/base-button/types';
import { IconPosition } from 'components/form/button/types';
import { IconName } from 'components/icons/types';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import { Size } from 'components/types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import styles from './filter-button.css';

interface Props {
  icon: IconName;
  isActive?: boolean;
  size?: ButtonSize;
  title?: string;
}

interface ScopedSlots {
  popup: { close: () => void };
}

@Component
class FilterButton extends TsxComponent<Props, {}, ScopedSlots> {
  public $refs: {
    popup: HTMLDivElement;
    wrapper: HTMLDivElement;
  };

  private isFilterPopupOpen = false;

  private isRightAligned = false;

  @Prop()
  public icon: Props['icon'];

  @Prop()
  public isActive?: Props['isActive'];

  @Prop({ default: Size.SMALL })
  public size: Props['size'];

  @Prop()
  public title?: Props['title'];

  private async togglePopup() {
    this.isRightAligned = false;

    this.isFilterPopupOpen = !this.isFilterPopupOpen;

    if (this.isFilterPopupOpen) {
      await this.$nextTick();

      const { left, width } = this.$refs.popup.getBoundingClientRect();

      this.isRightAligned = left + width > window.innerWidth;
    }
  }

  public render() {
    return (
      // wrapper is needed to position popup
      <div class={styles.filterButton} title={this.title} ref="wrapper">
        <Button
          color={this.isActive ? ButtonColor.SUCCESS : ButtonColor.SECONDARY}
          icon={this.icon}
          onClick={this.togglePopup}
          size={this.size}
          kind={ButtonKind.GHOST}
          iconPosition={IconPosition.ALONE}
        />
        {
          this.isFilterPopupOpen && (
            <div
              class={{
                [styles.filterButtonPopup]: true,
                [styles.filterButtonPopupRightAlign]: this.isRightAligned,
              }}
              ref="popup"
            >
              <OutsideClickHandler
                insideRef={this.$refs.wrapper}
                onOutsideClick={this.togglePopup}
              />
              {this.$scopedSlots.popup({ close: () => this.togglePopup() })}
            </div>
          )
        }
      </div>
    );
  }
}

export default FilterButton;
