import { getDateKey } from 'components/calendar-common/common/DateItem';
import { DEFAULT_TIME_GRID_STEP } from 'components/calendar-common/grid/time-grid/TimeGrid';
import { Moment } from 'moment';
import { SplitableEvent } from './SplitableEvent';

class EventPart {
  protected timeGridStep = DEFAULT_TIME_GRID_STEP;

  public key: string;

  public event: SplitableEvent;

  public id: number;

  public start: Moment;

  public end: Moment;

  public indentCoeff: number;

  public isEditedShiftCopy: boolean;

  public constructor(
    event: SplitableEvent,
    id: number,
    start: Moment,
    end: Moment,
    indentCoeff = 0,
    isEditedShiftCopy = false,
  ) {
    this.event = event;
    this.id = id;
    this.start = start;
    this.end = end;
    this.indentCoeff = indentCoeff;
    this.isEditedShiftCopy = isEditedShiftCopy;
    // for edited shift we don't want key to change on shift end date change
    this.key = isEditedShiftCopy
      ? `${this.id}${getDateKey(this.getGridStartDate())}`
      : `${this.id}${this.event.startsAt}${this.event.endsAt}`;
  }

  public getGridStartDate() {
    const startDate = this.start.clone();
    const minutes = startDate.minutes();
    // floor minutes to nearest grid step
    const gridMinutes = Math.floor(minutes / this.timeGridStep) * this.timeGridStep;
    return startDate.startOf('hour').minutes(gridMinutes);
  }

  public duration() {
    return this.end.clone().diff(this.start, 'minutes');
  }

  public isLastPart() {
    // for shifts ending in 00:00 event part will end on 23:59 of previous day
    // that's why we need another check
    return this.end.isSame(this.event.endsAt, 'day')
      || this.end.clone().add(1, 'second').isSame(this.event.endsAt, 'second');
  }

  public getDurationMinutes() {
    throw new Error('not implemented');
  }
}

export default EventPart;
