import Shift from 'components/calendar-common/shifts/Shift';
import shiftStyles from 'components/calendar-common/shifts/shift-day-base/shift-day.css';
import ShiftDayBase from 'components/calendar-common/shifts/shift-day-base/ShiftDayBase';
import ShiftDaySlots from 'components/calendar-common/shifts/shift-day-base/ShiftDaySlots';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
class DaysShift extends Mixins(ShiftDaySlots) {
  @Prop()
  public shift!: Shift;

  @Prop()
  public employmentId: number;

  public render() {
    return (<ShiftDayBase
      onDrop={(...args) => this.$emit('drop', ...args)}
      shift={this.shift}
      employmentId={this.employmentId}>
      <div class={shiftStyles.shiftDayHorizontalGroup}>
        {this.renderTimeSlot()}
        {this.renderIcons()}
      </div>
      {<div class={shiftStyles.shiftDayHorizontalGroup}>
        {this.renderPositionName()}
      </div>}
      <div class={[
        shiftStyles.shiftDayHorizontalGroup,
        shiftStyles.shiftDayHorizontalGroupJustifyLeft, {
          [shiftStyles.shiftDayHorizontalGroupNowrap]: !this.isFullnames,
        }]}>
        {this.renderEmployeesChip()}
        {this.renderTags()}
      </div>
      {<div class={shiftStyles.shiftDayHorizontalGroup}>
        {this.renderEmployees()}
      </div>}
    </ShiftDayBase>);
  }
}

export default DaysShift as any;
