enum Action {
  ADD_BREAK = 'addBreak',
  DELETE_BREAK = 'deleteBreak',
  UPDATE_BREAK = 'updateBreak',
  SET_BREAKS = 'setBreaks',
  DISPATCH_CREATE = 'dispatchCreate',
  DISPATCH_UPDATE = 'dispatchUpdate',
  DISPATCH_REMOVE = 'dispatchRemove',
}

export default Action;
