import Transport from 'winston-transport';
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueSentryIntegration, RewriteFrames } from '@sentry/integrations';
import censorSensitiveProps from './censorSensitiveProps';
//
// Inherit from `winston-transport` so you can take advantage
// of the base functionality and `.exceptions.handle()`.
//

export interface SentryErrorPayload {
  tags?: [SentryTag, string][];
  error: Error;
}

export interface SentryMessagePayload {
  tags?: [SentryTag, string][];
  message: string;
}

export enum SentryTag {
  COMPONENT = 'app.component',
  ACTION = 'app.action',
  GRAPHQL = 'app.graphql',
}

class SentryTransport extends Transport {
  public constructor({ dsn, level }: { dsn: string; level: string }) {
    super({ level });
    Sentry.init({
      dsn,
      release: process.env.RELEASE,
      integrations: [
        new VueSentryIntegration({ Vue, attachProps: false, logErrors: true }),
        new RewriteFrames(),
      ],
      beforeSend(event) {
        return censorSensitiveProps(event);
      },
    });
  }

  public log(info, callback) {
    const { message: payload, level } = info;
    setImmediate(() => {
      this.emit('logged', info);
    });

    if (level === 'error') {
      // errors handling
      if (this.isSentryErrorPayload(payload)) {
        Sentry.captureException(payload.error, this.createSentryScope(payload));
      } else {
        Sentry.captureException(info);
      }
    } else if (this.isSentryMessagePayload(payload)) {
      // messages handling
      Sentry.captureMessage(payload.message, this.createSentryScope(payload, Sentry.Severity.Info));
    } else {
      Sentry.captureMessage(payload instanceof Object
        ? payload.message : info, Sentry.Severity.Info);
    }

    // Perform the writing to the remote service
    callback();
  }

  private isSentryErrorPayload(payload: any): payload is SentryErrorPayload {
    return payload instanceof Object && payload.error instanceof Error;
  }

  private isSentryMessagePayload(payload: any): payload is SentryMessagePayload {
    return payload instanceof Object && Object.prototype.hasOwnProperty.call(payload, 'message');
  }

  private createSentryScope(
    payload: SentryErrorPayload | SentryMessagePayload,
    level: Sentry.Severity = Sentry.Severity.Error,
  ) {
    const scope = new Sentry.Scope();
    scope.setLevel(level);
    (payload.tags || []).forEach(([key, value]) => {
      scope.setTag(key, value);
    });

    return scope;
  }
}

export default SentryTransport;
