import { Slot } from 'components/dialog/Dialog';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import FormDialog from 'components/form/form-dialog/FormDialog';
import InputDateTime, { Kind } from 'components/form/input-date-time/InputDateTime';
import InputTextArea from 'components/form/input-text-area/InputTextArea';
import InputText from 'components/form/input-text/InputText';
import { isStartBeforeEnd } from 'src/utils/date-related';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';

// required to make browser form validation work
const FORM_ID = `dialog-${getRandomString()}`;

export interface FormState {
  title: string;
  startsAt: Date;
  endsAt: Date;
  description: string;
}

interface Props {
  formState: FormState;
  hasError?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  isSubmitting?: boolean;
  isUpdate?: boolean;
}

interface Events{
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onDeleteClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] } >,
  ) => void;
  onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}

@Component
export default class DialogDayNote extends TsxComponent<Props, Events> {
  @Prop()
  public formState: Props['formState'];

  @Prop()
  public hasError?: Props['hasError'];

  @Prop()
  public isLoading?: Props['isLoading'];

  @Prop()
  public isOpen?: Props['isOpen'];

  @Prop()
  public isSubmitting?: Props['isSubmitting'];

  @Prop()
  public isUpdate?: Props['isUpdate'];

  protected get isTimespanValid() {
    return isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt, true);
  }

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    if (!this.isSubmitting) {
      this.$emit('closeClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
    }
  }

  protected onDeleteClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    if (!this.isSubmitting) {
      this.$emit('deleteClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
    }
  }

  protected onInput(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) {
    this.$emit('input', createEventPayload(e, { field: e.target.name, value: e.target.value }));
  }

  protected onDateInput(field: string, value: Date, e: SyntheticEvent) {
    this.$emit('input', createEventPayload(e, { field, value }));
  }

  protected onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    this.$emit('submit', createEventPayload<void>(e, undefined));
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        error={this.hasError}
        isLoading={this.isLoading}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        title={
          this.isUpdate
            ? this.$t('dayNotes.modal.titleUpdate')
            : this.$t('dayNotes.modal.titleCreate')
        }
      >
        <FormDialog id={FORM_ID} onSubmit={this.onSubmit}>
          <InputText
            label={this.$t('dayNotes.modal.labelTitle')}
            name="title"
            onInput={this.onInput}
            required={true}
            type="text"
            value={this.formState.title}
          />

          <InputDateTime
            kind={Kind.DATE}
            datepickerLabel={this.$t('dayNotes.modal.labelStartsAt')}
            name="startsAt"
            onInput={({ event, payload: { value } }) => this.onDateInput('startsAt', value, event)}
            required={true}
            value={this.formState.startsAt}
            isValid={(this.isTimespanValid)}
            timeZone={this.$timeZone.value}
          />

          <InputDateTime
            kind={Kind.DATE}
            datepickerLabel={this.$t('dayNotes.modal.labelEndsAt')}
            name="endsAt"
            onInput={({ event, payload: { value } }) => this.onDateInput('endsAt', value, event)}
            required={true}
            value={this.formState.endsAt}
            isValid={(this.isTimespanValid)}
            timeZone={this.$timeZone.value}
          />

          <InputTextArea
            label={this.$t('dayNotes.modal.labelDescription')}
            name="description"
            onChange={this.onInput}
            value={this.formState.description}
          />
        </FormDialog>

        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isSubmitting}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.GHOST}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        {
          !this.hasError && this.isUpdate && !this.isLoading && (
            <Button
              color={ButtonColor.ERROR}
              disabled={this.isSubmitting || this.isLoading}
              size={Size.SMALL}
              slot={Slot.BUTTONS_LEFT}
              type="button"
              kind={ButtonKind.GHOST}
              onClick={this.onDeleteClick}
            >
              {this.$t('dayNotes.modal.buttonDelete')}
            </Button>
          )
        }

        {
          !this.hasError && (
            <Button
              disabled={this.isSubmitting || this.isLoading || !this.isTimespanValid}
              form={FORM_ID}
              size={Size.SMALL}
              slot={Slot.BUTTONS_RIGHT}
              type="submit"
            >
              {
                this.isUpdate
                  ? this.$t('dayNotes.modal.buttonUpdate')
                  : this.$t('dayNotes.modal.buttonCreate')
              }
            </Button>
          )
        }
      </DialogWithSpinnerAndError>
    );
  }
}
