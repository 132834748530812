import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './placeholder.css';

@Component
export default class Placeholder extends TsxComponent<{
  isShown?: boolean;
}> {
  @Prop()
  public isShown?: boolean;

  public render() {
    return (
      this.isShown
        ? <div class={styles.placeholder} />
        : this.$slots.default
    );
  }
}
