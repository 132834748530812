import Absence from 'components/calendar-common/absences/Absence';
import DateItem from 'components/calendar-common/common/DateItem';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import Shift from 'components/calendar-common/shifts/Shift';
import { Component, Vue } from 'vue-property-decorator';
import styles from './list-day.css';
import { calendarPrintShiftsNS } from '../shifts/store/Store';
import { calendarPrintAbsencesNS } from '../absences/store/Store';
import { calendarPrintCommonNS, NoteType } from '../common/Store';
import { calendarPrintFiltersNS, StoreState as FiltersStoreState } from '../filters/store/Store';

@Component
export default class ListDay extends Vue {
  @calendarPrintCommonNS.Getter('dates')
  public dates!: DateItem[];

  @calendarPrintShiftsNS.Getter('shiftsByDates')
  public shiftsByDates!: Record<string, Shift[]>;

  @calendarPrintAbsencesNS.Getter('absencesByDates')
  public absences!:
  Record<string, { new: Absence[]; accepted: Absence[] }>;

  @calendarPrintFiltersNS.State((state: FiltersStoreState) => state.filters)
  public filters: FiltersStoreState['filters'];

  private get date(): DateItem {
    return this.dates[0];
  }

  private get shifts() {
    return this.shiftsByDates[this.date.dateKey].map((it: Shift) => ({
      startTime: it.getStartTime(),
      endTime: it.getEndTime(),
      time: `${it.getStartTime()} - ${it.getEndTime()}`,
      position: it.position.name,
      employeesCount: `${it.staffShifts.length} / ${it.workers}`,
      employees: it.staffShifts
        .filter(emp => emp.id !== undefined)
        .map(emp => emp.name).join(', '),
      [NoteType.NOTE]: it.note,
      [NoteType.POSITION_NOTE]: it.position.note,
    })).sort((a, b) => a.startTime.localeCompare(b.startTime));
  }

  private get shiftsKeys(): Record<string, string> {
    const defaultKeys = {
      time: 'duration',
      position: 'position',
      employeesCount: 'employees',
      employees: 'names',
    };
    return this.hasNotes ? { ...defaultKeys, notes: 'notes' } : { ...defaultKeys };
  }

  private get absencesColumns(): number {
    return +this.filters.showAcceptedAbsences + +this.filters.showNewAbsences;
  }

  private get newAbsences() {
    return this.absences[this.date.dateKey]
      ? this.absences[this.date.dateKey].new.map(this.mapAbsence)
      : [];
  }

  private get acceptedAbsences() {
    return this.absences[this.date.dateKey]
      ? this.absences[this.date.dateKey].accepted.map(this.mapAbsence)
      : [];
  }

  private get hasNotes() {
    return this.filters.showNotes || this.filters.showPositionNotes;
  }

  private mapAbsence(absence: Absence): string {
    return absence.employment.name;
  }

  private renderAbsencesBlock(absences, key: 'New' | 'Accepted') {
    return (<div class={styles.listDayAbsencesBlock}>
      <GridCell isHeader={true}
        class={gridStyles.gridTablePrintHeaderCell}>
        <span>
          {this.$t(`calendar.${key.toLowerCase()}Absence`)}
        </span>
      </GridCell>
      <GridCell class={gridStyles.gridTablePrintContentCell}>
        <span>
          {absences.length > 0
            ? absences.join('\n') : this.$t(`calendar.print.no${key}Absences`)}
        </span>
      </GridCell>
    </div>);
  }

  private getNotes(shift) {
    const notes = [
      this.filters.showNotes && shift[NoteType.NOTE],
      this.filters.showPositionNotes && shift[NoteType.POSITION_NOTE],
    ];
    return notes.filter(Boolean).join('\n');
  }

  public render() {
    return (
      <div class={[gridStyles.gridTable, gridStyles.gridTablePrint, styles.listDay]}>
        <GridCell isHeader={true} class={gridStyles.gridTablePrintHeaderCell}>
          <span>
            {this.date.date.format('DD MMMM YY')}
          </span>
        </GridCell>
        {(this.filters.showNewAbsences || this.filters.showAcceptedAbsences)
        && <div class={[gridStyles.gridTableSubgrid,
          styles.listDayAbsences, {
            [styles.listDayAbsencesOneColumn]: this.absencesColumns === 1,
          }]}>
          {this.filters.showNewAbsences
            && this.renderAbsencesBlock(this.newAbsences, 'New')}
          {this.filters.showAcceptedAbsences
            && this.renderAbsencesBlock(this.acceptedAbsences, 'Accepted')}
        </div>}
        <GridCell isHeader={true} class={[
          gridStyles.gridTableSubheaderCell,
          gridStyles.gridTablePrintContentCell,
        ]}>
          <span>
            {this.shifts.length} {this.$t('calendar.print.shifts')}
          </span>
        </GridCell>
        <div class={[gridStyles.gridTableSubgrid, styles.listDayShifts, {
          [styles.listDayShiftsNotes]: this.hasNotes,
        }]}>
          <div class={[gridStyles.gridTableSubgrid]}>
            {Object.entries(this.shiftsKeys).map(([, value]) => (
              <GridCell isHeader={true} class={gridStyles.gridTablePrintHeaderCell}>
                <span>
                  {this.$t(`calendar.print.${value}`)}
                </span>
              </GridCell>))}
          </div>
          <div class={gridStyles.gridTableSubgrid}>
            {this.shifts.map((shift) => {
              const columns = Object.entries(this.shiftsKeys)
                .map(([key]) => (<GridCell
                  class={gridStyles.gridTablePrintContentCell}>
                  <span>
                    {(key === 'notes')
                      ? this.getNotes(shift)
                      : shift[key]}
                  </span>
                </GridCell>));
              return columns;
            })}
          </div>
        </div>
      </div>
    );
  }
}
