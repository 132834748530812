import { GQLBackgroundJobState } from 'codegen/gql-types';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { executeStoreActionWithFailureSnackbar, StoreActionResult } from 'src/utils/store';
import type { GetById, GetMultipleById } from 'src/utils/store';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type { FetchAllLocationsPositionsFunction } from 'store/locations-positions/Store';
import { Action } from 'store/normalized-store';
import { Action as WatchStoreAction } from 'store/watch-store';
import { shiftplansNS } from 'store/shiftplans/Store';
import type { FetchAllShiftplansFunction, Shiftplan } from 'store/shiftplans/Store';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { shiftplanNotificationsWatchNS } from 'store/shiftplan-notifications/Store';
import type { SubscribeToShiftplanNotificationsFunction } from 'store/shiftplan-notifications/Store';
import { backgroundJobsNS } from 'store/background-jobs/Store';
import type { StoreState as BackgroundJobsStoreState } from 'store/background-jobs/Store';
import { uiSettingsNS } from 'store/ui-settings/Store';
import type { UiSettings, StoreState as UiSettingsStoreState } from 'store/ui-settings/Store';
import { shiftRotationsNS } from 'src/store/shift-rotations/Store';
import type { FetchAllShiftRotationsFunction, ShiftRotation } from 'src/store/shift-rotations/Store';
import type { FetchAllTagsFunction } from 'src/store/tags/Store';
import { tagsNS } from 'src/store/tags/Store';
import ShiftSchedule from './ShiftSchedule';
import ScreenLoading from '../screen/ScreenLoading';
import UiSettingsContainer, { SettingsScope } from './ui-settings-container/UiSettingsContainer';
import ShiftScheduleAction from './store/Action';
import { shiftScheduleBackgroundJobsMapNS } from './store/Store';
import type { BackgroundJobsMapStoreState, RemoveJobFunction } from './store/Store';
import styles from './container.css';

interface Props {
  shiftplanId?: number;
}

@Component
export default class ShiftScheduleContainer extends TsxComponent<Props> {
  protected isLoading = true;

  protected isShiftplanFiltersSidebarCollapsed = false;

  @Prop()
  public shiftplanId: Props['shiftplanId'];

  @authNS.State
  public currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  private currentLocationId: AuthStoreState['currentLocationId'];

  @backgroundJobsNS.Action(WatchStoreAction.SUBSCRIBE)
  private subscribeToBackgroundJobsUpdate: Function;

  @backgroundJobsNS.Action(WatchStoreAction.UNSUBSCRIBE)
  private unsubscribeFromBackgroundJobsUpdate: Function;

  @backgroundJobsNS.State('data')
  private backgroundJobs: BackgroundJobsStoreState['data'];

  @uiSettingsNS.State('shiftplanScoped')
  protected uiShiftplanScopedSettings: UiSettings['shiftplanScoped'];

  @locationsPositionsNS.Action(Action.FETCH_ALL)
  protected fetchAllLocationsPositions: FetchAllLocationsPositionsFunction;

  @tagsNS.Action(Action.FETCH_ALL)
  protected fetchAllTags: FetchAllTagsFunction;

  @shiftplansNS.Action(Action.FETCH_ALL)
  protected fetchAllShiftplans: FetchAllShiftplansFunction;

  @shiftRotationsNS.Action(Action.FETCH_ALL)
  protected fetchAllShiftRotations: FetchAllShiftRotationsFunction;

  @shiftplansNS.Getter('getById')
  protected getShiftplanById: GetById<Shiftplan>;

  @shiftplansNS.Getter('getByLocationId')
  protected getShiftplansByLocationId: GetMultipleById<Shiftplan>;

  @shiftplanNotificationsWatchNS.Action(WatchStoreAction.SUBSCRIBE)
  protected subscribeToShiftplanNotifications: SubscribeToShiftplanNotificationsFunction;

  @shiftplanNotificationsWatchNS.Action(WatchStoreAction.UNSUBSCRIBE)
  protected unsubscribeFromShiftplanNotifications: Function;

  @shiftplanNotificationsWatchNS.Action(WatchStoreAction.REFETCH)
  protected refetchShiftplanNotifications: Function;

  @shiftRotationsNS.Getter('ordered')
  protected shiftRotations: ShiftRotation[];

  @shiftScheduleBackgroundJobsMapNS.Action(ShiftScheduleAction.REMOVE_JOB)
  protected removeJobForShiftplan: RemoveJobFunction;

  @shiftScheduleBackgroundJobsMapNS.State('byShiftplanId')
  protected backgroundJobsByShiftplanId: BackgroundJobsMapStoreState['byShiftplanId'];

  @uiSettingsNS.State('shared')
  protected uiSharedSettings: UiSettingsStoreState['shared'];

  protected get shiftplans() {
    return this.getShiftplansByLocationId(this.currentLocationId || Number.NaN);
  }

  protected get shiftplan() {
    return this.getShiftplanById(this.shiftplanId);
  }

  @Watch('shiftplanId')
  @Watch('backgroundJobForShiftplan')
  protected onBackgroundJobForShiftplanUpdate() {
    if (
      !this.shiftplanId
      || !this.backgroundJobForShiftplan
      || this.backgroundJobForShiftplan.state !== GQLBackgroundJobState.FINISHED
    ) {
      return;
    }

    this.removeJobForShiftplan(this.shiftplanId);
  }

  @Watch('shiftplanId')
  protected async onShiftplanIdUpdate(value) {
    this.isLoading = true;

    await this.loadShiftplanData(value);

    this.isLoading = false;
  }

  private async loadShiftplanData(shiftplanId: number | undefined) {
    if (shiftplanId) {
      // subscribe will not do anything if we are already on it
      this.subscribeToShiftplanNotifications({ shiftplanId });
      this.refetchShiftplanNotifications({ shiftplanId });

      if (this.currentCompany?.shiftRotationEnabled) {
        await executeStoreActionWithFailureSnackbar(
          this,
          { shiftplanIds: [shiftplanId || Number.NaN] },
          this.fetchAllShiftRotations,
          '',
        );
      }
    } else {
      // remove existing subscribtion if it was present before
      this.unsubscribeFromShiftplanNotifications();
    }
  }

  protected get backgroundJobForShiftplan() {
    if (!this.shiftplanId) {
      return undefined;
    }

    const backgroundJobId = this.backgroundJobsByShiftplanId[this.shiftplanId];

    if (!backgroundJobId) {
      return undefined;
    }

    return this.backgroundJobs?.find(job => job.id === backgroundJobId);
  }

  protected get hasUnfinishedBackgroundJobForShiftplan() {
    return !!this.backgroundJobForShiftplan
      && this.backgroundJobForShiftplan.state !== GQLBackgroundJobState.FINISHED;
  }

  protected async populate() {
    this.isLoading = true;

    const storePromises = [
      executeStoreActionWithFailureSnackbar(
        this,
        { locationId: this.currentLocationId || Number.NaN },
        this.fetchAllShiftplans,
        '',
      ),

      executeStoreActionWithFailureSnackbar(
        this,
        {
          locationIds: [this.currentLocationId || Number.NaN],
          positionIds: null,
        },
        this.fetchAllLocationsPositions,
        '',
      ),

      this.loadShiftplanData(this.shiftplanId),
    ];

    if (this.currentCompany?.isTagsAllowed) {
      storePromises.push(executeStoreActionWithFailureSnackbar(
        this,
        {
          locationIds: [this.currentLocationId || Number.NaN],
          context: null,
        },
        this.fetchAllTags,
        '',
      ));
    }

    await Promise.all<StoreActionResult | void>(storePromises);

    this.isLoading = false;
  }

  public mounted() {
    this.populate();
    this.subscribeToBackgroundJobsUpdate({});
    if (this.shiftplanId) {
      this.subscribeToShiftplanNotifications({ shiftplanId: this.shiftplanId });
    }
  }

  public beforeDestroy() {
    this.unsubscribeFromBackgroundJobsUpdate();
    this.unsubscribeFromShiftplanNotifications();
  }

  public render() {
    if (this.isLoading) {
      return (
        <ScreenLoading />
      );
    }

    return (
      <div class={styles.containerWrapper}>
        <UiSettingsContainer
          scope={SettingsScope.CALENDAR}
          shiftplan={this.shiftplan}
          isShiftplanFiltersSidebarCollapsed={this.isShiftplanFiltersSidebarCollapsed}
        />

        <ShiftSchedule
          hasUnfinishedBackgroundJob={this.hasUnfinishedBackgroundJobForShiftplan}
          shiftplan={this.shiftplan}
          shiftplans={this.shiftplans}
          shiftRotations={this.shiftRotations}
          settings={this.uiShiftplanScopedSettings[this.shiftplan?.id || Number.NaN]}
          onFilterSidebarToggle={() => {
            this.isShiftplanFiltersSidebarCollapsed = !this.isShiftplanFiltersSidebarCollapsed;
          }}
          isShiftplanFiltersSidebarCollapsed={
            !!this.uiSharedSettings.isShiftplanFiltersSidebarCollapsed
          }
        />
      </div>
    );
  }
}
