import { Component, Prop, Vue } from 'vue-property-decorator';
import styles from './summary-cell.css';

export enum SummaryCellColor {
  GREY = 'grey',
  RED = 'red',
  GREEN = 'green',
  YELLOW = 'yellow',
}

@Component
class SummaryCell extends Vue {
  @Prop()
  public value: number;

  @Prop({ default: SummaryCellColor.GREY })
  public color: SummaryCellColor;

  public render() {
    return (
      <div
        class={{
          [styles.summaryCell]: true,
          // grey is default color and doesn't need an extra class
          [styles.summaryCellGreen]: this.color === SummaryCellColor.GREEN,
          [styles.summaryCellYellow]: this.color === SummaryCellColor.YELLOW,
          [styles.summaryCellRed]: this.color === SummaryCellColor.RED,
        }}>
        <span>
          {this.value}
        </span>
      </div>
    );
  }
}

export default SummaryCell as any;
