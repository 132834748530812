import SpptDnd from '@shyftplan/drag-and-drop';
import employeeStyles from 'components/calendar-common/employees/employee/employee.css';
import { getConfig as getCellTypeConfig } from 'components/calendar-common/grid/dnd/droppable-config';
import dndStyles from 'components/calendar-common/shifts/dnd/dnd.css';
import DndKind from './DndKind';

const initDnd = (element, store) => {
  SpptDnd.getInstance().registerDroppableType(DndKind.CELL, getCellTypeConfig(store));
  SpptDnd.getInstance().initDraggableContainer(element, [
    {
      selector: `.${dndStyles.shiftDraggable}`,
      kind: DndKind.SHIFT,
    },
    {
      selector: `.${employeeStyles.employeeContainerDraggable}`,
      kind: DndKind.STAFF_SHIFT,
    },
  ]);
  SpptDnd.getInstance().initDroppableContainer(
    element,
    [{
      kind: DndKind.SHIFT,
      selector: `.${dndStyles.shiftDroppable}`,
    }],
  );
};

export default initDnd;
