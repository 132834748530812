export enum TimeframeKind {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  FREE = 'free',
}

export enum ViewKind {
  AGENDA = 'agenda',
  LIST = 'list',
  POSITIONS = 'positions',
  EMPLOYMENTS = 'employments',
}

export enum SlotDisplayStyle {
  COMPACT = 'compact',
  DEFAULT = 'default',
  FULLNAMES = 'fullnames',
}

export enum Mode {
  EMPLOYEE = 'employee',
  STAKEHOLDER = 'stakeholder',
}

export enum ExportUrlPathName {
  EMPLOYMENTS = 'employment-week',
  POSITIONS = 'locations-position-week',
}

export enum CalendarNamespace {
  CALENDAR = 'calendar',
  ABSENCES = 'calendarAbsences',
  PRINT = 'calendarPrint',
}

export enum LoadState {
  NOT_LOADED,
  LOADING,
  LOADED,
  LOAD_ERROR,
}

export enum PrintViewKind {
  LIST = 'list',
  POSITIONS_WEEK = 'positionsWeek',
  EMPLOYMENTS_WEEK = 'employmentsWeek',
  POSITIONS_MONTH = 'positionsMonth',
  EMPLOYMENTS_MONTH = 'employmentsMonth',
}

export type PrintTimeframeKind = TimeframeKind.MONTH
| TimeframeKind.WEEK
| TimeframeKind.DAY;
