import { authNS } from 'components/auth/store/Store';
import type { HasAnyRightFunction, StoreState } from 'components/auth/store/Store';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Route as CalendarRoute } from 'components/shift-schedule/routes';
import { Route as AvailabilityAggregationsRoute } from 'components/availability-aggregations/routes';
import { IconName } from 'components/icons/types';
import Brand from 'components/brand/Brand';
import { BrandColor, BrandKind } from 'components/brand/types';
import LinkWithCompanyPrefix from 'components/link-with-company-prefix/LinkWithCompanyPrefix';
import NavigationButton from '../navigation-button/NavigationButton';
import SupportBanner from '../support-banner/SupportBanner';

import styles from './navigation.css';

@Component
export default class Navigation extends TsxComponent<{}> {
  protected isSupportBannerShown = false;

  @authNS.Getter
  protected hasAnyRight: HasAnyRightFunction;

  @authNS.Getter
  protected hasAnyCurrentLocationRight: (...name: string[]) => boolean;

  @authNS.Getter
  protected hasEveryRight: (...name: string[]) => boolean;

  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: StoreState['currentEmployment'];

  protected get isEmployee() {
    return !!this.currentEmployment?.isEmployee;
  }

  protected get isStakeholder() {
    return !!this.currentEmployment?.isStakeholder;
  }

  protected get isShiftplansShown() {
    return this.isSuperAdmin || this.currentEmployment?.locationsPositions?.length;
  }

  protected get isPositionsShown() {
    return (
      this.isSuperAdmin || (
        this.isStakeholder && (
          this.hasAnyRight('shifts_manage_all', 'payments_manage_all') || this.hasAnyCurrentLocationRight('shift_manage_right', 'payment_manage_right')
        )
      )
    );
  }

  protected get isUsersShown() {
    return (
      this.isSuperAdmin || (
        this.isStakeholder && this.hasAnyRight(
          'users_show',
          'users_show_managed_users',
          'users_manage',
          'users_manage_managed_users',
          'users_payment',
        )
      )
    );
  }

  protected get isAbsencesShown() {
    return (
      this.isSuperAdmin || this.isEmployee || (
        this.isStakeholder && this.hasAnyRight(
          'absences_show',
          'absences_show_managed_users',
          'absences_manage',
          'absences_manage_managed_users',
        )
      )
    );
  }

  protected get isHourAccountsShown() {
    return (
      this.isSuperAdmin || (
        this.isStakeholder && this.hasAnyRight(
          'hour_accounts_show',
          'hour_accounts_show_managed_users',
          'hour_accounts_manage',
          'hour_accounts_manage_managed_users',
        )
      )
    );
  }

  protected get isOwnHourAccountsShown() {
    return this.isEmployee
      && this.currentCompany?.isEmployeeAllowedToSeeOwnHourAccount
      && this.currentEmployment?.hourEnabled
      && !this.isHourAccountsShown;
  }

  protected get isEvaluationsShown() {
    return (
      this.isSuperAdmin || this.isEmployee || (
        this.isStakeholder && this.hasAnyRight(
          'shifts_show_all',
          'shifts_manage_all',
          'payments_show_all',
          'payments_manage_all',
        )
      )
    );
  }

  protected get isPayrollShown() {
    return (
      this.isSuperAdmin || (
        this.isStakeholder && (
          (this.hasAnyRight('billings_show') && this.hasAnyRight('payments_show_all', 'payments_manage_all'))
            || this.hasEveryRight('billings_manage', 'payments_manage_all')
        )
      )
    );
  }

  private get availabilitiesRoute() {
    if (
      this.isSuperAdmin
      || (
        this.isStakeholder
        && this.currentCompany?.canManageAvailabilities
        && this.hasAnyRight('users_show_managed_users')
      )
    ) {
      return { routeName: AvailabilityAggregationsRoute.ROOT };
    }

    return { href: '/availabilities' };
  }

  protected get isAvailabilitiesShown() {
    return (
      this.isSuperAdmin
      || this.isEmployee
      || (
        this.isStakeholder
        && this.currentCompany?.canManageAvailabilities
        && this.hasAnyRight('users_show_managed_users')
      )
    );
  }

  public render() {
    return (
      <nav class={styles.navigation}>
        <ul class={styles.navigationList}>
          <li class={styles.navigationListItem}>
            <LinkWithCompanyPrefix href="/app/dashboard">
              <Brand
                class={styles.navigationBrandIcon}
                color={BrandColor.WHITE}
                kind={BrandKind.ICON}
              />
              <Brand
                class={styles.navigationBrandFull}
                color={BrandColor.WHITE}
                kind={BrandKind.ICON_AND_TEXT}
              />
            </LinkWithCompanyPrefix>
          </li>

          <li class={styles.navigationListItem}>
            <NavigationButton
              href="/app/dashboard"
              icon={IconName.DASHBOARD}
              tooltip={this.$t('navigation.dashboard')}
            >
              {this.$t('navigation.dashboard')}
            </NavigationButton>
          </li>

          {this.isShiftplansShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                routeName={CalendarRoute.ROOT}
                icon={IconName.SHIFTPLAN}
                tooltip={this.$t('navigation.shiftplans')}
              >
                {this.$t('navigation.shiftplans')}
              </NavigationButton>
            </li>
          )}

          {this.isPositionsShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                href="/positions"
                icon={IconName.POSITION}
                tooltip={this.$t('navigation.positions')}
              >
                {this.$t('navigation.positions')}
              </NavigationButton>
            </li>
          )}

          {this.isUsersShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                href="/users"
                icon={IconName.PERSONAL}
                tooltip={this.$t('navigation.users')}
              >
                {this.$t('navigation.users')}
              </NavigationButton>
            </li>
          )}

          {this.isAbsencesShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                href="/absences"
                icon={IconName.ABSENCE}
                tooltip={this.$t('navigation.absences')}
              >
                {this.$t('navigation.absences')}
              </NavigationButton>
            </li>
          )}

          {this.isHourAccountsShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                href="/hour_accounts"
                icon={IconName.TIME}
                tooltip={this.$t('navigation.hourAccounts')}
              >
                {this.$t('navigation.hourAccounts')}
              </NavigationButton>
            </li>
          )}

          {this.isOwnHourAccountsShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                href="/hour_accounts"
                icon={IconName.TIME}
                tooltip={this.$t('navigation.hourAccounts')}
              >
                {this.$t('navigation.hourAccounts')}
              </NavigationButton>
            </li>
          )}

          {this.isEvaluationsShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                href="/evaluations"
                icon={IconName.EVALUATION}
                tooltip={this.$t('navigation.evaluations')}
              >
                {this.$t('navigation.evaluations')}
              </NavigationButton>
            </li>
          )}

          {this.isPayrollShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                href="/billings"
                icon={IconName.BILLING}
                tooltip={this.$t('navigation.payroll')}
              >
                {this.$t('navigation.payroll')}
              </NavigationButton>
            </li>
          )}

          {this.isAvailabilitiesShown && (
            <li class={styles.navigationListItem}>
              <NavigationButton
                {...{
                  props:
                  {
                    ...this.availabilitiesRoute,
                    icon: IconName.OCCUPIED,
                    tooltip: this.$t('navigation.availabilities'),
                  },
                }}
              >
                {this.$t('navigation.availabilities')}
              </NavigationButton>
            </li>
          )}
        </ul>

        <div class={[styles.navigationListItem, styles.navigationButtonSupportContainer]}>
          <NavigationButton
            href="#"
            icon={IconName.HELP}
            class={styles.navigationButtonSupport}
            isButton={true}
            onClick={(e) => {
              this.isSupportBannerShown = !this.isSupportBannerShown;
              e.stopPropagation();
            }}
          >
            {this.$t('navigation.support')}
          </NavigationButton>
        </div>

        <SupportBanner
          isOpen={this.isSupportBannerShown}
          onCloseClick={() => {
            this.isSupportBannerShown = false;
          }}
        />
      </nav>
    );
  }
}
