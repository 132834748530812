import Absence from 'components/calendar-common/absences/Absence';
import AbsenceMonth from 'components/calendar-common/absences/absence-month/AbsenceMonth';
import DateItem from 'components/calendar-common/common/DateItem';
import Observable from 'components/calendar-common/common/observable/Observable';
import PaginationMixin from 'components/calendar-common/common/pagination-mixin/PaginationMixin';
import GridHeaderMonth from 'components/calendar-common/grid/grid-header-month/GridHeaderMonth';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import GridMixin, { GridType } from 'components/calendar-common/grid/GridMixin';
import DayNotesCompactContainer from
  'components/calendar-common/notes/day-notes-compact-container/DayNotesCompactContainer';
import SpecialDatesCompactContainer from
  'components/calendar-common/notes/special-dates-compact-container/SpecialDatesCompactContainer';
import { Component, Mixins } from 'vue-property-decorator';
import { calendarAbsencesFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import ShiftPresetMonth from 'components/calendar-common/shift-presets/shift-preset-month/ShiftPresetMonth';
import { ShiftPreset, shiftPresetsNS } from 'components/calendar-common/shift-presets/Store';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import gridHeaderMonthStyles from 'components/calendar-common/grid/grid-header-month/grid-header-month.css';
import { calendarAbsencesEmploymentsNS } from 'components/calendar-common/employments/Store';
import Employment from 'components/calendar-common/employments/Employment';
import { calendarAbsencesAbsencesNS } from 'components/calendar-absences/absences/Store';
import { calendarAbsencesCommonNS } from 'components/calendar-absences/common/store/Store';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import Row from '../row/Row';
import SummaryContainer from '../../summary/SummaryContainer';
import styles from './month.css';

@Component({
  directives: {
    observable: Observable,
  },
})
class Month extends Mixins(GridMixin, PaginationMixin) {
  private openEmploymentIds: number[] = [];

  protected calendarNamespace = CalendarNamespace.ABSENCES;

  protected gridType = GridType.MONTH;

  @calendarAbsencesAbsencesNS.Getter('absencesByEmployments')
  private absences: Record<number, Record<string, Absence[]>>;

  @calendarAbsencesFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  @calendarAbsencesCommonNS.Getter('dates')
  protected dates: DateItem[];

  @calendarAbsencesEmploymentsNS.Getter('filteredEmployments')
  private employments: Employment[];

  @shiftPresetsNS.Getter('getByIdWithFallback')
  private getShiftPresetById: (id: number) => ShiftPreset;

  protected get paginatedItems() {
    return this.employments;
  }

  private get rowScopedSlots() {
    return {
      absence: ({ absence }) => <AbsenceMonth
        key={absence.uniqId}
        calendarNamespace={CalendarNamespace.ABSENCES}
        absence={absence}/>,
      rotationShiftPreset: ({ shiftPresetId }) => this
        .filters.showShiftRotation && <ShiftPresetMonth
        shiftPreset={this.getShiftPresetById(shiftPresetId)}/>,
    };
  }

  public render() {
    return (<div
      class={gridStyles.gridTable}
      style={this.gridStyle}>
      <GridHeaderMonth dates={this.dates}
        cellClass={styles.monthHeaderCell}>
        <span slot="label">{this.$t('calendar.titleEmployment')}</span>
        <template slot="total">
          {
            this.filters.showSummary && <GridCell
              isHeader={true}
              class={gridHeaderMonthStyles.gridHeaderMonthTotal}
              style={{ gridColumn: `${this.dates.length + 2} / ${this.dates.length + 3}` }}>
              {this.$t('calendar.summary.total')}
            </GridCell>
          }
        </template>
        {this.filters.showSummary
        && <SummaryContainer dates={this.dates}/>}
      </GridHeaderMonth>
      <SpecialDatesCompactContainer dates={this.dates} showLabelText={true}/>
      <DayNotesCompactContainer dates={this.dates} showLabelText={true}/>
      {
        this.visibleItems.map((employment, index) => {
          const absences = this.absences[employment.id];
          const isLastRow = (this.employments.length - 1) === index;
          return (<Row
            showSummary={this.filters.showSummary}
            isVisible={this.visibleIds.includes(employment.id)}
            isOpen={this.openEmploymentIds.includes(employment.id)}
            data-id={employment.id}
            v-observable={this.observer}
            employment={employment}
            // there are no shifts on this view
            shifts={[]}
            absences={absences}
            dates={this.dates}
            isLastRow={isLastRow}
            scopedSlots= {this.rowScopedSlots}/>);
        })
      }
      {
        this.renderShowMore()
      }
    </div>);
  }
}

export default Month;
