import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import DialogConfirm from 'components/dialog-confirm/DialogConfirm';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { Action } from 'store/normalized-store';
import { shiftplansNS } from 'store/shiftplans/Store';
import type {
  DeleteShiftplanFunction,
  FetchShiftplanFunction,
  Shiftplan,
  UpdateShiftplanFunction,
} from 'store/shiftplans/Store';
import { EventPayload } from 'utils/events';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'utils/store';
import type { GetById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { isBefore } from 'date-fns';
import { parseDateString, startOf, Unit } from 'src/utils/date-related';
import DialogShiftplan, { FormState } from './DialogShiftplan';

export const getInitialFormState = (): FormState => ({
  endsAt: new Date().toISOString(),
  name: '',
  startsAt: new Date().toISOString(),
  locked: false,
});

@Component
export default class DialogShiftplanContainer extends TsxComponent<{
  shiftplanId?: number;
}> {
  protected shiftplan: Shiftplan | null = null;

  protected formState = getInitialFormState();

  protected isDeleting = false;

  protected isDeleteConfirmDialogOpen = false;

  protected isUpdateConfirmDialogOpen = false;

  protected isSubmitting = false;

  protected lastStoreActionState = StoreActionState.SUCCESS;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @shiftplansNS.Action(Action.DELETE)
  protected deleteShiftplan: DeleteShiftplanFunction;

  @shiftplansNS.Action(Action.FETCH)
  protected fetch: FetchShiftplanFunction;

  @shiftplansNS.Action(Action.UPDATE)
  protected updateShiftplan: UpdateShiftplanFunction;

  @shiftplansNS.Getter
  protected getById: GetById<Shiftplan>;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @Prop()
  public shiftplanId: number;

  protected get isPeriodChanged() {
    return this.isUpdate && (
      this.shiftplan?.startsAt !== this.formState.startsAt
      || this.shiftplan?.endsAt !== this.formState.endsAt
    );
  }

  protected get isUpdate() {
    return !!this.shiftplanId;
  }

  protected get isLockShiftplanVisible(): boolean {
    return !!this.currentCompany?.canLockShiftplans
      && !!this.shiftplan?.endsAt
      && isBefore(
        parseDateString(this.shiftplan.endsAt, this.$timeZone.value),
        startOf(new Date(), Unit.DAY, this.$timeZone.value),
      );
  }

  protected onCloseClick() {
    const { matched } = this.$route;

    // FAQ: last element is the route itself, so the one before is the route's parent
    const routeParent = matched[matched.length - 2] || matched[0];

    this.$router.push(routeParent);
  }

  protected onDeleteClick() {
    this.isDeleteConfirmDialogOpen = true;
  }

  protected async onConfirmDialogCancel() {
    // close both to save some extra code
    this.isDeleteConfirmDialogOpen = false;
    this.isUpdateConfirmDialogOpen = false;
  }

  protected async onDeleteConfirm() {
    if (!this.shiftplanId || !this.shiftplan) {
      this.isDeleteConfirmDialogOpen = false;
      return false;
    }

    this.isDeleting = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      { id: this.shiftplan.id },
      this.deleteShiftplan,
      '',
    );

    this.isDeleting = false;
    if (response.state === StoreActionState.ERROR) {
      return false;
    }

    this.onCloseClick();

    this.isDeleteConfirmDialogOpen = false;
    this.showSnackbar({
      message: this.$t('shiftplans.modal.deleted'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
    return false;
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit(payload: EventPayload<void, HTMLElement, UIEvent>) {
    if (!this.isUpdate || !this.isPeriodChanged) {
      return this.submit(payload);
    }

    this.isUpdateConfirmDialogOpen = true;
    return undefined;
  }

  protected async submit({ event }: EventPayload<void, HTMLElement, UIEvent>) {
    event.preventDefault();

    if (!this.shiftplan) {
      return undefined;
    }

    const shiftplan = {
      endsAt: new Date(this.formState.endsAt),
      name: this.formState.name,
      startsAt: new Date(this.formState.startsAt),
      locked: this.formState.locked,
    };

    this.isSubmitting = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      { id: this.shiftplan.id, shiftplan },
      this.updateShiftplan,
      'shiftplans.modal.error',
    );

    this.isSubmitting = false;

    if (response.state === StoreActionState.SUCCESS) {
      this.onCloseClick();
      this.showSnackbar({
        message: this.isUpdate
          ? this.$t('shiftplans.modal.updated')
          : this.$t('shiftplans.modal.created'),
        kind: AlertKind.SUCCESS,
        timeout: 5000,
      });
    }

    return false;
  }

  protected async populateFormState() {
    this.lastStoreActionState = StoreActionState.PENDING;

    const response = await this.fetch({ id: this.shiftplanId });

    this.lastStoreActionState = response.state;

    if (this.lastStoreActionState !== StoreActionState.SUCCESS) {
      return;
    }

    this.shiftplan = this.getById(this.shiftplanId) || null;

    if (this.shiftplan) {
      this.formState = {
        endsAt: this.shiftplan.endsAt,
        name: this.shiftplan.name,
        startsAt: this.shiftplan.startsAt,
        locked: !!this.shiftplan.locked,
      };
    }
  }

  public mounted() {
    if (!this.isUpdate) {
      this.formState = getInitialFormState();
      this.lastStoreActionState = StoreActionState.SUCCESS;
      return;
    }

    // TODO: We might actually want to check if the shiftplan is already in the store and not
    // refetch it in this case or refetch it silently in the background.
    this.populateFormState();
  }

  public render() {
    if (this.isDeleteConfirmDialogOpen) {
      return (
        <DialogConfirmDelete
          isOpen={true}
          isSubmitting={this.isDeleting}
          onCancel={this.onConfirmDialogCancel}
          onConfirm={this.onDeleteConfirm}
          title={this.$t('shiftplans.modal.titleDeleteConfirm')}
        >
          {this.$t('shiftplans.modal.messageDeleteConfirm')}
        </DialogConfirmDelete>
      );
    }

    if (this.isUpdateConfirmDialogOpen) {
      return (
        <DialogConfirm
          isOpen={true}
          isSubmitting={this.isSubmitting}
          onCancel={this.onConfirmDialogCancel}
          onConfirm={this.submit}
          title={this.$t('shiftplans.modal.titleUpdateConfirm')}
        >
          {this.$t('shiftplans.modal.messageUpdateConfirm')}
        </DialogConfirm>
      );
    }

    return (
      <DialogShiftplan
        formState={this.formState}
        hasError={
          [StoreActionState.ERROR, StoreActionState.NOT_FOUND].includes(this.lastStoreActionState)
        }
        isLoading={this.lastStoreActionState === StoreActionState.PENDING}
        isOpen={true}
        isSubmitting={this.isSubmitting}
        isUpdate={this.isUpdate}
        onCloseClick={this.onCloseClick}
        onDeleteClick={this.onDeleteClick}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
        isLockShiftplanVisible={this.isLockShiftplanVisible}
        isShiftScheduleLocked={!!this.shiftplan?.locked}
      />
    );
  }
}
