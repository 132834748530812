import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { PrintViewKind } from 'components/calendar-common/Enums';
import type { PrintTimeframeKind } from 'components/calendar-common/Enums';
import SpIcon from 'components/sp-icon/SpIcon';
import JSPDF from 'jspdf';
import { format } from 'date-fns';
import Tooltip from 'components/tooltip/Tooltip';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { AlertKind } from 'components/alert/Alert';
import type { Shiftplan } from 'src/store/shiftplans/Store';
import { getTimeframeFromDates } from 'utils/timeframe-helpers';
import { IconName } from 'components/icons/types';
import Button from 'components/form/button/Button';
import { IconPosition } from 'components/form/button/types';
import RadioButtonGroup from '../radio-button-group/RadioButtonGroup';
import { PrintTypeIntervals, SetTimeframePayload, shiftScheduleNS } from '../store/Store';
import Action from '../store/Action';
import Controls, { Slot } from '../controls/Controls';
import NavLink from '../nav-link/NavLink';
import { Route } from '../routes';
import ButtonSquare from '../button-square/ButtonSquare';
import styles from './calendar-print-controls.css';
import { SentryTag } from '../../../services/logger/SentryTransport';
import type { SelectedTimeframe } from '../../datepicker/types';

const PrintViewKindIconMap: Record<PrintViewKind, string> = {
  [PrintViewKind.LIST]: 'day',
  [PrintViewKind.POSITIONS_WEEK]: 'week',
  [PrintViewKind.POSITIONS_MONTH]: 'month-position',
  [PrintViewKind.EMPLOYMENTS_WEEK]: 'week-employees',
  [PrintViewKind.EMPLOYMENTS_MONTH]: 'month-employees',
};

const PRINT_DATE_FORMAT = 'yyyyMMdd';

interface Props {
  shiftplan?: Shiftplan;
  isEmployeeView: boolean;
  isShiftplanFiltersSidebarCollapsed: boolean;
}

interface Events {
  onFilterSidebarToggle: void;
}

@Component
export default class CalendarPrintControls extends TsxComponent<Props, Events> {
  protected isPrintDisabled = false;

  private isDatepickerOpen = false;

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public isEmployeeView: Props['isEmployeeView'];

  @Prop()
  public isShiftplanFiltersSidebarCollapsed: Props['isShiftplanFiltersSidebarCollapsed'];

  @shiftScheduleNS.Getter
  private printTimeframeKind: PrintTimeframeKind;

  @shiftScheduleNS.State
  private printViewKind: PrintViewKind;

  @shiftScheduleNS.State
  private timeframe: SelectedTimeframe;

  @shiftScheduleNS.Action(Action.SET_PRINT_VIEW_KIND)
  private setPrintViewKind: (viewKind: PrintViewKind) => void;

  @shiftScheduleNS.Action(Action.SET_TIMEFRAME)
  private setTimeframe: (payload: SetTimeframePayload) => void;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  private onTimeframeUpdate({
    timeframe = this.timeframe,
  }: SetTimeframePayload) {
    const resultTimeframe = getTimeframeFromDates(
      timeframe.startsAt,
      timeframe.endsAt,
      this.printTimeframeKind,
      this.$timeZone.value,
    );

    this.setTimeframe({ timeframe: resultTimeframe });
  }

  public async onPrintClick() {
    this.isPrintDisabled = true;

    try {
      const doc = new JSPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [1600, 900],
      });
      const printRoot = document.getElementById('print-root');
      if (printRoot) {
        await doc.html(
          printRoot,
          {
            margin: 5,
            x: 0,
            y: 0,
            width: 1600,
            windowWidth: 1600,
          },
        );
        doc.save(`${this.printViewKind}_${format(this.timeframe.startsAt, PRINT_DATE_FORMAT)
        }_${format(this.timeframe.endsAt, PRINT_DATE_FORMAT)
        }`);
      } else {
        throw new Error('PrintRoot element is not found');
      }
    } catch (e) {
      this.showSnackbar({
        timeout: 5000,
        kind: AlertKind.ERROR,
        message: this.$t('calendar.print.error'),
      });

      this.$logError({
        tags: [[SentryTag.COMPONENT, CalendarPrintControls.name]],
        error: e,
      });
    } finally {
      this.isPrintDisabled = false;
    }
  }

  public render() {
    return (
      <Controls timeframeKind={this.printTimeframeKind}
        timeframe={this.timeframe}
        isDatepickerOpen={this.isDatepickerOpen}
        shiftplan={this.shiftplan}
        onDatepickerCancel={() => {
          this.isDatepickerOpen = false;
        }}
        onDatepickerChange={({ payload }) => {
          this.onTimeframeUpdate({
            timeframe: payload,
          });
        }}
        onDatepickerToggle={({ payload }) => { this.isDatepickerOpen = payload; }}>
        <template slot={Slot.LEFT_CONTROLS_GROUP}>
          {!this.isEmployeeView && (
            <Tooltip
              text={
                <span
                  class={styles.calendarPrintControlsTooltipText}>
                  {this.$t(
                    this.isShiftplanFiltersSidebarCollapsed
                      ? 'calendar.controls.buttonFilterSidebarExpand'
                      : 'calendar.controls.buttonFilterSidebarCollapse',
                  )}
                </span>
              }
            >
              <Button
                icon={IconName.SIDEBAR_COLLAPSE}
                iconPosition={IconPosition.ALONE}
                onClick={() => this.$emit('filterSidebarToggle')}
              />
            </Tooltip>
          )}
          {
            this.shiftplan
            && <Tooltip
              text={
                <span
                  class={styles.calendarPrintControlsTooltipText}>
                  {this.$t('calendar.print.controls.buttonHidePrintView')}
                </span>
              }
            >
              <NavLink to={{
                name: Route.SHIFTPLAN,
                params: {
                  shiftplanId: this.shiftplan?.id.toString(),
                },
              }}
              icon={IconName.PRINT}
              isPressed={true} />
            </Tooltip>
          }
          <Tooltip
            text={
              <span
                class={styles.calendarPrintControlsTooltipText}>
                {this.$t('calendar.print.controls.buttonPrint')}
              </span>
            }
          >
            <ButtonSquare onClick={this.onPrintClick}
              class={styles.calendarPrintControlsPrint}>
              <SpIcon name="download" />
            </ButtonSquare>
          </Tooltip>
        </template>
        <template slot={Slot.RIGHT_CONTROLS_GROUP}>
          <RadioButtonGroup
            options={Object.values(PrintViewKind).map(kind => ({
              label: this.$t(`calendar.print.viewKind.${kind}`),
              value: kind,
              icon: PrintViewKindIconMap[kind],
            }))}
            value={this.printViewKind}
            onChange={({ payload }) => {
              this.setPrintViewKind(payload.value as PrintViewKind);
              this.setTimeframe({
                timeframeKind: PrintTypeIntervals[payload.value],
              });
            }} />
        </template>
      </Controls>
    );
  }
}
