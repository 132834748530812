import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { FiltersMap } from 'components/calendar-common/filters/Store';
import Loader from 'components/loader/Loader';
import Action from 'components/table/store/Action';
import { shiftPresetsNS } from 'store/shift-presets/Store';
import type { FetchAllShiftPresetsFunction, ShiftPreset } from 'store/shift-presets/Store';
import type { SetSelectionFunction } from 'store/filter-store';
import { Action as StoreAction } from 'store/normalized-store';
import { EventPayload } from 'utils/events';
import FilterBox, { Slot as FilterBoxSlot } from '../filter-box/FilterBox';
import { StoreState as FilterStoreState, shiftPresetsFilterNS } from './store/Store';
import CheckboxListItem from '../filter-box/checkbox-list/item/Item';
import CheckboxListWithPagingAndFiltering from '../checkbox-list-with-paging-and-filtering/CheckboxListWithPagingAndFiltering';
import { quickFilterNS } from '../filter-box-quick/store/Store';
import QuickFilterAction from '../filter-box-quick/store/Action';
import { FILTER_PAGE_SIZE, FILTER_THROTTLE_TIMEOUT } from '../constants';

@Component
export default class FilterBoxShiftPreset extends TsxComponent<{}> {
  protected isLoading = false;

  protected isOpen = false;

  @quickFilterNS.Action(QuickFilterAction.SET_FILTERS)
  protected setQuickFilters: (name: FiltersMap[]) => void;

  @quickFilterNS.State('filters')
  protected quickFilters: FiltersMap[];

  @shiftPresetsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAll: FetchAllShiftPresetsFunction;

  @shiftPresetsNS.Getter('sortedByStartsAt')
  protected items: ShiftPreset[];

  @shiftPresetsFilterNS.Action(Action.SET_SELECTION)
  protected setSelection: SetSelectionFunction;

  @shiftPresetsFilterNS.State
  protected selection: FilterStoreState['selection'];

  protected get itemsWithCustomPreset() {
    const items = [...this.items];

    if (items.length > 0) {
      const presetCustom: ShiftPreset = {
        id: -1,
        color: '#000000',
        name: this.$t('shiftSchedule.filter.shiftPresets.labelCustomPreset'),
        shortName: null,
        startsAt: '1970-01-01T00:00:00.000Z',
        startsAtTime: '00:00',
        endsAtTime: '00:00',
        shiftEvaluationTagIds: [],
        tagIds: [],
        breakTime: 0,
      };

      items.unshift(presetCustom);
    }

    return items;
  }

  protected async onOpenClose({ payload: state }: EventPayload<boolean>) {
    this.isOpen = state;

    if (state) {
      this.isLoading = this.items.length === 0;

      await this.fetchAll({});

      this.isLoading = false;
    }
  }

  protected onSelect({ payload }) {
    const isWithoutPresetFilterEnabled = this.quickFilters.includes(
      FiltersMap.SHIFTS_WITHOUT_PRESET,
    );

    if (payload.includes(-1) && !isWithoutPresetFilterEnabled) {
      this.setQuickFilters([...this.quickFilters, FiltersMap.SHIFTS_WITHOUT_PRESET]);
    } else if (!payload.includes(-1) && isWithoutPresetFilterEnabled) {
      this.setQuickFilters(
        this.quickFilters.filter(filter => filter !== FiltersMap.SHIFTS_WITHOUT_PRESET),
      );
    }

    this.setSelection(payload);
  }

  public render() {
    return (
      <FilterBox isOpen={this.isOpen} onOpenCloseClick={this.onOpenClose}>
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('shiftSchedule.filter.shiftPresets.title')}
        </template>

        {
          this.isLoading
            ? (
              <Loader />
            ) : (
              <CheckboxListWithPagingAndFiltering
                items={this.itemsWithCustomPreset}
                filterKey="name"
                perPage={FILTER_PAGE_SIZE}
                scopedSlots={{
                  item: ({ item, isSelected, onChange }) => (
                    <CheckboxListItem
                      isSelected={isSelected}
                      onChange={onChange}
                      style={{ color: item.color }}
                    >
                      {item.name}
                    </CheckboxListItem>
                  ),
                }}
                selection={this.selection}
                throttleTimeout={FILTER_THROTTLE_TIMEOUT}
                onSelect={this.onSelect}
              />
            )
        }
      </FilterBox>
    );
  }
}
