import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';
import Tooltip from 'components/tooltip/Tooltip';
import Icon from 'components/icons/Icon';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import { AvailabilityAggregation } from '../../types';
import styles from './cell-contract-hours.css';

export interface Props {
  contractHours: AvailabilityAggregation['contractHours'];
}

@Component
export default class CellContractHours extends TsxComponent<Props> {
  @Prop()
  public contractHours: Props['contractHours'];

  public render() {
    return (
      <td
        class={[
          tableStyles.tableBodyCell,
          styles.cellContractHours,
        ]}
      >
        {
          this.contractHours === null
            ? (
              <Tooltip
                class={styles.cellContractHoursTooltip}
                text={this.$t('availabilityAggregations.tooltipContractedMonthly')}
              >
                <Icon
                  name={IconName.INFO}
                  size={Size.SMALL}
                  title={this.$t('availabilityAggregations.tooltipContractedMonthly')}
                />
              </Tooltip>
            )
            : this.contractHours
        }
      </td>
    );
  }
}
