import moment from 'moment';
import { Module } from 'vuex';
import DateItem from 'components/calendar-common/common/DateItem';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import type { SelectedTimeframe } from 'components/datepicker/types';
import {
  endOf, getCurrentDateInTimeZone, getDateInTimeZone, startOf, Unit,
} from 'src/utils/date-related';
import VueTimeZoneProvider from 'services/vue-time-zone-provider/VueTimeZoneProvider';
import { eachDayOfInterval, isSameDay } from 'date-fns';
import {
  SET_TIMEFRAME,
  SET_IS_LOADING,
} from './Actions';
import Mutations from './Mutations';
import { Mode, TimeframeKind } from '../../../calendar-common/Enums';

export const calendarAbsencesCommonNS = namespace('calendarAbsences/common');

export interface StoreState {
  isLoading: boolean;
  timeframe: SelectedTimeframe;
  timeframeKind: TimeframeKind;
}

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    timeframe: {
      startsAt: startOf(new Date(), Unit.MONTH, VueTimeZoneProvider.getTimeZone()),
      endsAt: endOf(new Date(), Unit.MONTH, VueTimeZoneProvider.getTimeZone()),
    },
    isLoading: true,
    timeframeKind: TimeframeKind.MONTH,
  },
  mutations: {
    [Mutations.SET_TIMEFRAME](state, timeframe: SelectedTimeframe) {
      state.timeframe = timeframe;
    },
    [Mutations.SET_IS_LOADING](state, value) {
      state.isLoading = value;
    },
  },
  actions: {
    [SET_TIMEFRAME]({ commit }, value: SelectedTimeframe) {
      commit(Mutations.SET_TIMEFRAME, value);
    },
    [SET_IS_LOADING]({ commit }, value: boolean) {
      commit(Mutations.SET_IS_LOADING, value);
    },
  },
  getters: {
    timeframe() {
      return TimeframeKind.MONTH;
    },
    // enforce interval to always use start of day/end of day
    currentInterval(state) {
      return {
        start:
            startOf(state.timeframe.startsAt, Unit.DAY, VueTimeZoneProvider.getTimeZone()),
        end:
            endOf(state.timeframe.endsAt, Unit.DAY, VueTimeZoneProvider.getTimeZone()),
      };
    },
    dates(state, getters, rootState, rootGetters) {
      const { start, end } = getters.currentInterval;

      const shiftedInterval = {
        start: getDateInTimeZone(
          start,
          VueTimeZoneProvider.getTimeZone(),
        ),
        end: getDateInTimeZone(
          end,
          VueTimeZoneProvider.getTimeZone(),
        ),
      };

      const isSpecialDate = date => rootGetters['calendarAbsences/notes/isSpecialDate'](moment(date));

      const dates = eachDayOfInterval(shiftedInterval);
      return dates.map(date => new DateItem(
        moment(date),
        true,
        isSameDay(date, getCurrentDateInTimeZone(VueTimeZoneProvider.getTimeZone())),
        isSpecialDate(date),
      ));
    },
    dateKeys(state, getters) {
      return getters.dates.map(date => date.dateKey);
    },
    mode(state, getters, rootState, rootGetters) {
      return rootGetters['auth/isSuperAdmin']
        || rootGetters['auth/hasAnyLocationRight'](
          rootState.auth.currentLocationId,
          'shift_manage_right',
        )
        ? Mode.STAKEHOLDER : Mode.EMPLOYEE;
    },
  },
});
