import { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './conflicts.css';

export interface Props {
  conflict: Extract<GQLShiftConflictsFragmentFragment, { __typename: 'MinimumBreakShiftConflict' }>;
}

@Component
export default class MinimumBreakShiftConflict extends TsxComponent<Props> {
  @Prop()
  public conflict: Props['conflict'];

  public render() {
    return (
      <section>
        <h3 class={styles.conflictsItemTitle}>
          {this.$t('conflicts.minimumBreak.title')}
        </h3>
        <p class={styles.conflictsItemText}>
          {
            this.$t(
              'conflicts.minimumBreak.text',
              {
                count: this.conflict.minBreakTime,
              },
            )
          }
        </p>
      </section>
    );
  }
}
