import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Icon from 'components/icons/Icon';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import BaseButton from 'components/form/base-button/BaseButton';
import { ButtonHTMLAttributes, EventsOn, SyntheticEvent } from 'vue-tsx-support/types/dom';
import { Size } from 'components/types';
import { ButtonProps, IconPosition, ICON_SIZE_MAP } from './types';
import styles from './button.css';

interface Events {
  onClick: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
}

@Component({
  inheritAttrs: false,
})
export default class Button extends
  TsxComponent<ButtonHTMLAttributes & ButtonProps, Events & Omit<EventsOn<HTMLButtonElement>, 'onClick'>> {
  @Prop({ default: ButtonColor.PRIMARY })
  public color: NonNullable<ButtonProps['color']>;

  @Prop()
  public icon?: ButtonProps['icon'];

  @Prop({ default: ButtonKind.FILL })
  public kind: NonNullable<ButtonProps['kind']>;

  @Prop({ default: Size.MEDIUM })
  public size: NonNullable<ButtonProps['size']>;

  @Prop({ default: IconPosition.BEFORE })
  public iconPosition: NonNullable<ButtonProps['iconPosition']>;

  public render() {
    return (
      <BaseButton
        kind={this.kind}
        size={this.size}
        color={this.color}
        {...{
          attrs: this.$attrs,
          on: { ...this.$listeners },
        }}
      >
        {this.icon
        && (this.iconPosition === IconPosition.BEFORE
            || this.iconPosition === IconPosition.ALONE
            || this.iconPosition === IconPosition.BOTH)
          && <Icon name={this.icon} size={ICON_SIZE_MAP[this.size]}
            class={{
              [styles.buttonIcon]: true,
              [styles.buttonIconAlone]: this.iconPosition === IconPosition.ALONE,
              [styles.buttonIconLeft]: this.iconPosition !== IconPosition.ALONE,
            }} />}
        {this.$slots.default}
        {this.icon && (this.iconPosition === IconPosition.AFTER
        || this.iconPosition === IconPosition.BOTH)
          && <Icon name={this.icon} size={ICON_SIZE_MAP[this.size]}
            class={{ [styles.buttonIconRight]: true, [styles.buttonIcon]: true }} />}
      </BaseButton>
    );
  }
}
