import { TFunction } from 'i18next';
import { RouteConfig, Route as RouteType } from 'vue-router';
import RotationWizard from './RotationWizard';
import GeneralSetup from './general-setup/GeneralSetup';
import RotationPatternSetup from './rotation-pattern-setup/RotationPatternSetup';
import EmploymentAssignment from './employment-assignment/EmploymentAssignment';
import RotationWizardEditContainer from './RotationWizardEditContainer';
import GeneralSetupIndividual from './general-setup/GeneralSetupIndividual';
import RotationPatternSetupIndividual from './rotation-pattern-setup/RotationPatternSetupIndividual';

export enum Route {
  ROOT = 'rotationWizardIndex',
  STEP_1 = 'rotationWizardGeneralSetup',
  STEP_2 = 'rotationWizardRotationPatternSetup',
  STEP_3 = 'rotationWizardEmploymentAssignment',
  EDIT_ROOT = 'rotationWizardEditIndex',
  EDIT_STEP_1 = 'rotationWizardEditGeneralSetup',
  EDIT_STEP_2 = 'rotationWizardEditRotationPatternSetup',
  EDIT_STEP_3 = 'rotationWizardEditEmploymentAssignment',

  INDIVIDUAL_ROOT = 'rotationWizardIndividualIndex',
  INDIVIDUAL_STEP_1 = 'rotationWizardIndividualGeneralSetup',
  INDIVIDUAL_STEP_2 = 'rotationWizardIndividualRotationPatternSetup',
}

const employmentIdInjector = (route: RouteType) => ({
  employmentId: parseInt(route.params.id, 10),
});

const getRoutes = (): RouteConfig[] => [
  {
    component: RotationWizard,
    name: Route.ROOT,
    path: '/rotation-wizard',
    redirect: { name: Route.STEP_1 },
    meta: {
      title: (t: TFunction) => t('rotationWizard.pageTitle'),
    },
    children: [
      {
        component: GeneralSetup,
        name: Route.STEP_1,
        path: 'general-setup',
      },
      {
        component: RotationPatternSetup,
        name: Route.STEP_2,
        path: 'rotation-pattern-setup',
      },
      {
        component: EmploymentAssignment,
        name: Route.STEP_3,
        path: 'employment-assignment',
      },
    ],
  },
  {
    path: '/rotation-wizard/edit/:id',
    name: Route.EDIT_ROOT,
    meta: {
      title: (t: TFunction) => t('rotationWizard.pageTitle'),
    },
    component: RotationWizardEditContainer,
    redirect: { name: Route.EDIT_STEP_3 },
    props: (route: RouteType) => ({
      rotationId: parseInt(route.params.id, 10),
    }),
    children: [
      {
        component: GeneralSetup,
        name: Route.EDIT_STEP_1,
        path: 'general-setup',
      },
      {
        component: RotationPatternSetup,
        name: Route.EDIT_STEP_2,
        path: 'rotation-pattern-setup',
      },
      {
        component: EmploymentAssignment,
        name: Route.EDIT_STEP_3,
        path: 'employment-assignment',
      },
    ],
  },
  {
    path: '/rotation-wizard/employment/:id',
    name: Route.INDIVIDUAL_ROOT,
    meta: {
      title: (t: TFunction) => t('rotationWizard.pageTitle'),
    },
    component: RotationWizard,
    redirect: { name: Route.INDIVIDUAL_STEP_1 },
    props: employmentIdInjector,
    children: [
      {
        component: GeneralSetupIndividual,
        name: Route.INDIVIDUAL_STEP_1,
        path: 'general-setup',
        props: employmentIdInjector,
      },
      {
        component: RotationPatternSetupIndividual,
        name: Route.INDIVIDUAL_STEP_2,
        path: 'rotation-pattern-setup',
        props: employmentIdInjector,
      },
    ],
  },
];

export default getRoutes;
