/* eslint-disable import/prefer-default-export */
import { TimeframeKind } from 'components/calendar-common/Enums';
import type { SelectedTimeframe } from 'components/datepicker/types';
import { addDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import type { Shiftplan } from 'src/store/shiftplans/Store';
import { endOf, startOf, Unit } from 'src/utils/date-related';

const DEFAULT_CUSTOM_TIMEFRAME_DURATION = 10;

export const getTimeframeFromDates = (
  startsAt: Date,
  endsAt: Date,
  timeframeKind: TimeframeKind,
  timeZone: string,
): SelectedTimeframe => {
  let resultTimeframe: SelectedTimeframe;

  switch (timeframeKind) {
    case TimeframeKind.FREE:
      resultTimeframe = {
        startsAt,
        endsAt,
      };
      break;

    case TimeframeKind.DAY:
      resultTimeframe = {
        startsAt,
        endsAt: startsAt,
      };
      break;

    case TimeframeKind.WEEK:
      resultTimeframe = {
        startsAt: startOf(startsAt, Unit.WEEK, timeZone, { weekStartsOn: 1 }),
        endsAt: endOf(startsAt, Unit.WEEK, timeZone, { weekStartsOn: 1 }),
      };
      break;

    case TimeframeKind.MONTH:
      resultTimeframe = {
        startsAt: startOf(startsAt, Unit.MONTH, timeZone),
        endsAt: endOf(startsAt, Unit.MONTH, timeZone),
      };
      break;

    default:
      resultTimeframe = {
        startsAt,
        endsAt: startsAt,
      };
  }

  return resultTimeframe;
};

const getDefaultTimeframe = (
  startsAt: string,
  timeframeKind: TimeframeKind,
  timeZone: string,
  timeframeCustomDuration = DEFAULT_CUSTOM_TIMEFRAME_DURATION,
): SelectedTimeframe => {
  // FAQ: startsAt may not contain offset information. It will therefore be parsed as local time
  // So, we will have to convert it to the company's time zone
  const startsAtDate = zonedTimeToUtc(new Date(startsAt), timeZone);
  const endsAtDate = timeframeKind === TimeframeKind.FREE
    ? addDays(startsAtDate, timeframeCustomDuration)
    : startsAtDate;

  return getTimeframeFromDates(
    startsAtDate,
    endsAtDate,
    timeframeKind,
    timeZone,
  );
};

export const getComputedTimeframe = ({
  shiftplan,
  timeframe,
  timeframeCustom,
  timeframeCustomDuration = DEFAULT_CUSTOM_TIMEFRAME_DURATION,
  timeframeKind,
  timeZone,
}: {
  shiftplan: Pick<Shiftplan, 'startsAt' | 'endsAt'>;
  timeframe?: SelectedTimeframe;
  timeframeCustom?: SelectedTimeframe;
  timeframeCustomDuration?: number;
  timeframeKind: TimeframeKind;
  timeZone: string;
}): SelectedTimeframe => {
  if (timeframeKind === TimeframeKind.FREE) {
    if (timeframeCustom) {
      return getTimeframeFromDates(
        new Date(timeframeCustom.startsAt),
        new Date(timeframeCustom.endsAt),
        timeframeKind,
        timeZone,
      );
    }

    return getDefaultTimeframe(
      shiftplan.startsAt,
      timeframeKind,
      timeZone,
      timeframeCustomDuration,
    );
  }

  if (timeframe) {
    return getTimeframeFromDates(
      new Date(timeframe.startsAt),
      new Date(timeframe.endsAt),
      timeframeKind,
      timeZone,
    );
  }

  return getDefaultTimeframe(
    shiftplan.startsAt,
    timeframeKind,
    timeZone,
  );
};
