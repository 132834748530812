import Absence from 'components/calendar-common/absences/Absence';
import AbsenceMonth from 'components/calendar-common/absences/absence-month/AbsenceMonth';
import DateItem from 'components/calendar-common/common/DateItem';
import DndParams from 'components/calendar-common/grid/DndParams';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import {
  GRID_COLUMN_MIN_WIDTH_MONTH,
  GRID_LABEL_COLUMN_SMALL_WIDTH,
  GRID_SUMMARY_COLUMN_WIDTH,
} from 'components/calendar-common/grid/GridVariables';
import DayNotesCompactContainer from
  'components/calendar-common/notes/day-notes-compact-container/DayNotesCompactContainer';
import SpecialDatesCompactContainer from
  'components/calendar-common/notes/special-dates-compact-container/SpecialDatesCompactContainer';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftMonth from 'components/calendar-common/shifts/shift-month/ShiftMonth';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { shiftsNS } from 'components/calendar-common/shifts/store/Store';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { Component, Mixins } from 'vue-property-decorator';
import { absencesNS } from 'components/calendar/absences/Store';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import HeaderMonth from 'components/calendar/grid/header-month/HeaderMonth';

@Component
class ListMonth extends Mixins(ShiftViewActionsMixin) {
  @calendarCommonNS.Getter
  private dates!: DateItem[];

  @shiftsNS.Getter('shiftsByDates')
  private shifts!: Record<string, Shift[]>;

  @absencesNS.Getter('absencesByDatesUnsorted')
  private absences!: Record<string, Absence[]>;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  private get gridStyle() {
    return {
      gridTemplateColumns: `${GRID_LABEL_COLUMN_SMALL_WIDTH} repeat(${this.dates.length}, minmax(${GRID_COLUMN_MIN_WIDTH_MONTH}, 1fr)) ${
        this.filters.showSummary
          ? GRID_SUMMARY_COLUMN_WIDTH : ''}`,
    };
  }

  public render() {
    return (<div class={gridStyles.gridTable}
      style={this.gridStyle}>
      <HeaderMonth
        dates={this.dates}
        onClick={(e, { dateItem }) => this.onShiftCellClick(dateItem)}
        shiftsByDates={this.shifts}
      />
      <SpecialDatesCompactContainer dates={this.dates}/>
      <DayNotesCompactContainer dates={this.dates}/>
      <GridRow
        cellClass={gridStyles.gridTableContentCellPaddingBottom}
        isLastRow={true}
        dates={this.dates}
        dndParams={ { ...DndParams, isDroppable: true } }
        onCellClick={dateItem => this.onShiftCellClick(dateItem)}
        scopedSlots= {{
          cell: scope => [
            this.absences[scope.dateItem.dateKey]
              .map(absence => <AbsenceMonth absence={absence}
                key={absence.uniqId}/>),
            this.shifts[scope.dateItem.dateKey]
              .map(shift => <ShiftMonth
                onDrop={(...args) => this.$emit('drop', ...args)}
                shift={shift}
                key={shift.id}/>)],
        }}>
        <GridCell slot="label"></GridCell>
      </GridRow>
    </div >);
  }
}

export default ListMonth as any;
