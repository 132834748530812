import type { ShiftAvailableEmployment } from 'store/shift-available-employments/Store';
import { formatNumberWithPrecision } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './payment-balance.css';

@Component
export default class PaymentBalance extends TsxComponent<{
  currency: string;
  employment: ShiftAvailableEmployment;
}> {
  @Prop()
  public currency: string;

  @Prop()
  public employment: ShiftAvailableEmployment;

  protected get scheduledPayment() {
    return this.formatWithTwoFractionDigits(this.employment.scheduledMoney || 0);
  }

  protected get maxPayment() {
    if (!this.employment.employment.maximumMoneyEnabled) {
      return null;
    }

    return `/${this.formatWithTwoFractionDigits(this.employment.employment.maximumMoney || 0)}`;
  }

  protected formatWithTwoFractionDigits(value: number) {
    return formatNumberWithPrecision(this.$i18n.i18next.language, value, 2, 2);
  }

  public render() {
    return (
      <span class={styles.paymentBalance}>
        {this.currency} {this.scheduledPayment}{this.maxPayment}
      </span>
    );
  }
}
