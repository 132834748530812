import InputButtonSelect from 'components/form/input-button-select/InputButtonSelect';
import InputTextArea from 'components/form/input-text-area/InputTextArea';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import FormDialog from 'components/form/form-dialog/FormDialog';
import { GQLShiftplanCreateNotificationInput, GQLShiftplanNotificationType } from 'codegen/gql-types';
import Alert, { AlertKind } from 'components/alert/Alert';
import styles from './notification.css';

interface Props {
  formState: GQLShiftplanCreateNotificationInput;
}

@Component
export default class Notification extends TsxComponent<Props, {
  onSubmitForm: (
    payload: EventPayload<{ formState: GQLShiftplanCreateNotificationInput }, HTMLElement, UIEvent>
  ) => void;
  onFormInput: <T extends keyof GQLShiftplanCreateNotificationInput>(
    payload: EventPayload<{ field: T; value: GQLShiftplanCreateNotificationInput[T] }>,
  ) => void;
}> {
  @Prop()
  public formState: Props['formState'];

  protected get formId() {
    return `form-${this.$route?.name}`;
  }

  private onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    e.preventDefault();

    this.$emit('submitForm', createEventPayload(
      e as SyntheticEvent,
      { formState: this.formState },
    ));
  }

  public render() {
    return (
      <FormDialog
        class={styles.notification}
        onSubmit={this.onSubmit}
        id={this.formId}
      >
        <div class={styles.notificationType}>
          {this.$t('messageEmployees.notification.labelType')}
          <InputButtonSelect
            options={[
              {
                label: this.$t('messageEmployees.notification.labelTypeUpdates'),
                value: GQLShiftplanNotificationType.UPDATES,
              },
              {
                label: this.$t('messageEmployees.notification.labelTypeOpenShifts'),
                value: GQLShiftplanNotificationType.OPEN,
              },
            ]}
            name="notificationType"
            value={this.formState.notificationType}
            onChange={e => this.$emit('formInput', createEventPayload(e.event, {
              value: e.payload,
              field: 'notificationType',
            }))}
            class={styles.notificationSelectButton}
          />
        </div>

        <Alert
          kind={AlertKind.INFO}
          title={
            this.formState.notificationType === GQLShiftplanNotificationType.UPDATES
              ? this.$t('messageEmployees.notification.infoUpdatesMessage')
              : this.$t('messageEmployees.notification.infoOpenShiftsMessage')
          }
        />

        <InputTextArea
          name="message"
          onInput={e => this.$emit('formInput', createEventPayload(e, {
            field: e.target.name,
            value: e.target.value,
          }))}
          value={this.formState.message!}
          placeholder={this.$t('messageEmployees.notification.labelMessagePlaceholder')}
          class={styles.notificationMessageInput}
        />
      </FormDialog >
    );
  }
}
