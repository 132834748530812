import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import SpIcon from 'components/sp-icon/SpIcon';
import { FiltersMap } from 'components/calendar-common/filters/Store';
import FilterBox, { Slot } from '../filter-box/FilterBox';
import Action from './store/Action';
import { quickFilterNS } from './store/Store';
import type { QuickFilterItem } from './types';
import CheckboxList from '../filter-box/checkbox-list/CheckboxList';
import Item, { Slot as ItemSlot } from '../filter-box/checkbox-list/item/Item';
import styles from './filter-box-quick.css';
import { FILTER_THROTTLE_TIMEOUT } from '../constants';

export const QUICK_FILTERS_MAP = {
  [FiltersMap.STAFF_REQUEST]: {
    label: 'shiftSchedule.filter.quick.joinRequest',
    icon: 'application',
  },
  [FiltersMap.LEAVE_REQUEST]: {
    label: 'shiftSchedule.filter.quick.leaveRequest',
    icon: 'swap',
  },
  [FiltersMap.REPLACE_REQUEST]: {
    label: 'shiftSchedule.filter.quick.replaceRequest',
    icon: 'direct-swap',
  },
  [FiltersMap.UNDERASSIGNED_SHIFTS]: {
    label: 'shiftSchedule.filter.quick.unassignedShift',
    icon: 'shift-open',
  },
  [FiltersMap.FULL_SHIFTS]: {
    label: 'shiftSchedule.filter.quick.assignedShift',
    icon: 'shift-full',
  },
  [FiltersMap.OVERASSIGNED_SHIFTS]: {
    label: 'shiftSchedule.filter.quick.overassignedShift',
    icon: 'overassigned-shifts',
  },
  [FiltersMap.NEW_ABSENCES]: {
    label: 'shiftSchedule.filter.quick.newAbsence',
    icon: 'absence',
  },
  [FiltersMap.ACCEPTED_ABSENCES]: {
    label: 'shiftSchedule.filter.quick.acceptedAbsence',
    icon: 'absences-state-approved',
  },
  [FiltersMap.SPECIAL_DATES]: {
    label: 'shiftSchedule.filter.quick.specialDay',
    icon: 'event',
  },
  [FiltersMap.DAY_NOTES]: {
    label: 'shiftSchedule.filter.quick.dayNote',
    icon: 'note',
  },
  [FiltersMap.SHIFTS_WITHOUT_TAGS]: {
    label: 'shiftSchedule.filter.quick.shiftWithoutTag',
    icon: 'tag',
  },
  [FiltersMap.SHIFT_ROTATION]: {
    label: 'shiftSchedule.filter.quick.shiftRotation',
    icon: 'personal',
  },
  [FiltersMap.SUMMARY]: {
    label: 'shiftSchedule.filter.quick.summary',
    icon: 'rotation',
  },
  [FiltersMap.NOTES]: {
    label: 'shiftSchedule.filter.quick.notes',
    icon: 'event',
  },
  [FiltersMap.POSITION_NOTES]: {
    label: 'shiftSchedule.filter.quick.positionNotes',
    icon: 'note',
  },
};
@Component
export default class FilterBoxQuick extends TsxComponent<{ items: QuickFilterItem[]}> {
  protected isOpen = false;

  @Prop()
  public items: QuickFilterItem[];

  @quickFilterNS.Action(Action.SET_FILTERS)
  protected setFilters: (name: FiltersMap[]) => void;

  @quickFilterNS.State
  protected filters: FiltersMap[];

  protected onOpenCloseClick() {
    this.isOpen = !this.isOpen;
  }

  protected get availableFiltersNames(): string[] {
    return this.items.map(it => it.name);
  }

  // Only change filters that can be changed by this filter box
  // and preserve other filters that can be set on other views
  protected onFiltersChange({ payload }) {
    const unchangedFilters = this.filters.filter(it => !this.availableFiltersNames.includes(it));
    this.setFilters([...unchangedFilters, ...payload as FiltersMap[]]);
  }

  public render() {
    return (
      <FilterBox isOpen={this.isOpen} onOpenCloseClick={this.onOpenCloseClick}>
        <template slot={Slot.TITLE}>{this.$t('shiftSchedule.filter.quick.title')}</template>

        <CheckboxList
          throttleTimeout={FILTER_THROTTLE_TIMEOUT}
          items={this.items}
          onChange={this.onFiltersChange}
          scopedSlots={{
            item: ({ item, isSelected, onChange }) => (
              <Item
                class={styles.filterBoxQuickItem}
                isSelected={isSelected}
                onChange={onChange}
              >
                {item.label}

                <SpIcon
                  class={styles.filterBoxQuickIcon}
                  name={item.icon}
                  slot={ItemSlot.ICON}
                />
              </Item>
            ),
          }}
          selection={this.filters}
        />
      </FilterBox>
    );
  }
}
