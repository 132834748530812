import type { ShiftAvailableEmployment } from 'store/shift-available-employments/Store';
import { getEmploymentString } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from 'components/popover/popover-content.css';
import AbsencesCollision from './conflicts/AbsencesCollision';
import MaximumConsecutiveWorkingDays from './conflicts/MaximumConsecutiveWorkingDays';
import MaximumEarning from './conflicts/MaximumEarning';
import MinimumRest from './conflicts/MinimumRest';
import MaximumWorkingHours from './conflicts/MaximumWorkingHours';
import OutsideWorkPeriod from './conflicts/OutsideWorkPeriod';
import RestrictedShiftPresets from './conflicts/RestrictedShiftPresets';
import ShiftsCollision from './conflicts/ShiftsCollision';

const componentMap: Record<string, any> = {
  AbsencesCollisionShiftConflict: AbsencesCollision,
  MaximumConsecutiveWorkingDaysShiftConflict: MaximumConsecutiveWorkingDays,
  MaximumEarningShiftConflict: MaximumEarning,
  MaximumWorkingHoursShiftConflict: MaximumWorkingHours,
  MinimumRestShiftConflict: MinimumRest,
  OutsideWorkPeriodShiftConflict: OutsideWorkPeriod,
  RestrictedShiftPresetsConflict: RestrictedShiftPresets,
  ShiftsCollisionShiftConflict: ShiftsCollision,
};

const translationMap: Record<string, string> = {
  AbsencesCollisionShiftConflict: 'conflicts.absenceCollisions.title',
  MaximumConsecutiveWorkingDaysShiftConflict: 'conflicts.maximumConsecutiveDays.title',
  MaximumEarningShiftConflict: 'conflicts.maximumEarning.title',
  MaximumWorkingHoursShiftConflict: 'conflicts.maximumWorkingHours.title',
  MinimumRestShiftConflict: 'conflicts.minimumRest.title',
  OutsideWorkPeriodShiftConflict: 'conflicts.outsideWorkPeriod.title',
  RestrictedShiftPresetsConflict: 'conflicts.restrictedShiftPresets.title',
  ShiftsCollisionShiftConflict: 'conflicts.shiftCollisions.title',
};

@Component
export default class PopoverContent extends TsxComponent<{
  employment: ShiftAvailableEmployment;
}> {
  @Prop()
  public employment: ShiftAvailableEmployment;

  public render() {
    return (
      <div class={styles.popoverContent}>
        <h3 class={styles.popoverContentHeading}>
          {getEmploymentString(this.employment.employment, true)}
        </h3>

        <dl class={styles.popoverContentList}>
          {
            this.employment.conflicts?.map((conflict) => {
              const ConflictComponent = componentMap[conflict.__typename];

              if (!(conflict.__typename in componentMap)) {
                return [
                  <dt class={styles.popoverContentItemHeader}>{this.$t('conflicts.unknown')}</dt>,
                  <dd class={styles.popoverContentItem}>
                    {conflict.__typename}
                  </dd>,
                ];
              }

              return [
                <dt class={styles.popoverContentItemHeader}>
                  {this.$t(translationMap[conflict.__typename])}
                </dt>,
                <dd class={styles.popoverContentItem}>
                  <ConflictComponent conflict={conflict} />
                </dd>,
              ];
            })
          }
        </dl>
      </div>
    );
  }
}
