import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconPosition } from 'components/form/button/types';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import styles from './header-cell.css';
import { SortDirection } from '../types';
import FilterButton from '../filter-button/FilterButton';

interface Sort {
  direction: SortDirection;
}

interface Props {
  filter?: any;
  isStretch?: boolean;
  sort?: Sort;
}

interface Events {
  onSortDirectionChange: (
    payload: EventPayload<{ direction: SortDirection }, HTMLButtonElement, MouseEvent>) => void;
}

interface ScopedSlots {
  popup: { close: () => void };
}

@Component
class HeaderCell extends TsxComponent<Props, Events, ScopedSlots> {
  public $refs: {
    popup: HTMLDivElement;
  };

  @Prop({ default: false })
  private isStretch: Props['isStretch'];

  @Prop()
  private filter: Props['filter'];

  @Prop()
  private sort: Props['sort'];

  private toggleSortOrder(event: SyntheticEvent<HTMLButtonElement, Event>) {
    if (!this.sort) {
      return;
    }

    this.$emit(
      'sortDirectionChange',
      createEventPayload(
        event,
        {
          direction: this.sort.direction === SortDirection.ASC
            ? SortDirection.DESC : SortDirection.ASC,
        },
      ),
    );
  }

  private get sortDirectionIcon() {
    if (!this.sort) {
      return IconName.SORT;
    }

    switch (this.sort.direction) {
      case SortDirection.ASC:
        return IconName.CHEVRON_UP;
      case SortDirection.DESC:
        return IconName.CHEVRON_DOWN;
      default:
        return IconName.SORT;
    }
  }

  public render() {
    return (
      <th class={styles.headerCell}>
        <div class={{
          [styles.headerCellContent]: true,
          [styles.headerCellContentStretch]: this.isStretch,
        }}>
          {
            this.filter && (
              <FilterButton
                class={styles.headerCellFilterIcon}
                icon={this.filter.icon || 'filter'}
                isActive={this.filter.isActive}
                scopedSlots={{
                  popup: this.$scopedSlots.popup,
                }}
              />
            )
          }
          {
            this.$slots.label && <span class={styles.headerCellLabel}>
              {this.$slots.label}
            </span>
          }
          {this.$slots.default}
          {
            this.sort && (
              <Button
                class={styles.headerCellSortIcon}
                color={ButtonColor.SECONDARY}
                iconPosition={IconPosition.ALONE}
                icon={this.sortDirectionIcon}
                onClick={this.toggleSortOrder}
                size={Size.SMALL}
                kind={ButtonKind.GHOST}
              />
            )
          }
        </div>
      </th>
    );
  }
}

export default HeaderCell;
