import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLFetchAllShiftRotationsQuery,
  GQLFetchAllShiftRotationsQueryVariables,
  GQLShiftRotationFragmentFragment,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import ShiftRotationsGql from './queries/ShiftRotations.gql';

export const shiftRotationsNS = namespace('shiftRotations');

export type StoreState = ById<ShiftRotation>;

export type ShiftRotation = GQLShiftRotationFragmentFragment;

export type FetchAllShiftRotationsFunction = (
  payload: Omit<GQLFetchAllShiftRotationsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getShiftRotationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({ namespaced: true });

  const fetchAll: ActionProvider<
  GQLFetchAllShiftRotationsQuery,
  GQLFetchAllShiftRotationsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllShiftRotationsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: ShiftRotationsGql,
      resultKey: 'shiftRotations',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  return createNormalizedStore<ShiftRotation, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getShiftRotationsStore;
