import DateItem from 'components/calendar-common/common/DateItem';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import SummaryContainer from 'components/calendar/summary/SummaryContainer';
import Shift from 'components/calendar-common/shifts/Shift';
import GridHeaderDays from 'components/calendar-common/grid/grid-header-days/GridHeaderDays';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import gridHeaderDaysStyles from 'components/calendar-common/grid/grid-header-days/grid-header-days.css';

@Component
class HeaderDays extends Vue {
  @Prop({
    default: () => [],
  })
  public dates!: DateItem[];

  @Prop()
  public shiftsByDates: Record<string, Shift[]>;

  @Prop({
    default: '',
  })
  public cellClass!: string;

  @Prop({
    default: true,
  })
  public isSticky!: boolean;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  public render() {
    return (<GridHeaderDays dates={this.dates}
      onClick={(e, scope) => this.$emit('click', e, scope)}
      scopedSlots={{
        rowAction: (date: DateItem) => this.$scopedSlots.rowAction
        && this.$scopedSlots.rowAction(date),
      }}>
      <template slot="label">
        {this.$slots.label}
      </template>
      <template slot="total">
        {
          this.filters.showSummary && <GridCell
            slot="total"
            isHeader={true}
            class={gridHeaderDaysStyles.gridHeaderDaysTotal}>
            {this.$t('calendar.summary.total')}
          </GridCell>
        }
      </template>
      {this.filters.showSummary && <SummaryContainer
        dates={this.dates}
        shiftsByDates={this.shiftsByDates}/>}
    </GridHeaderDays>);
  }
}

export default HeaderDays as any;
