import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { EventPayload } from 'src/utils/events';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import Prompt, { Slot as PromptSlot } from 'components/prompt/Prompt';

interface Props {
  isOpen: boolean;
  isDestructive?: boolean;
}

interface Events {
  onConfirmSingleShift: (e: EventPayload<void>) => void;
  onConfirmConnectedShifts: (e: EventPayload<void>) => void;
}

@Component
export default class DialogShiftConnected extends TsxComponent<Props, Events> {
  @Prop()
  public isOpen: Props['isOpen'];

  @Prop({ default: false })
  public isDestructive?: NonNullable<Props['isDestructive']>;

  private get translationKey() {
    return this.isDestructive ? 'shifts.modalDeleteConnectedShifts' : 'shifts.modalUpdateConnectedShifts';
  }

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        isDialogNotClosable={true}
        isDestructive={this.isDestructive}
        title={this.$t(`${this.translationKey}.title`)}
      >
        {this.$t(`${this.translationKey}.message`)}
        <template slot={PromptSlot.ACTION_BUTTONS}>
          <Button
            color={ButtonColor.SECONDARY}
            size={Size.SMALL}
            type="button"
            kind={ButtonKind.FILL}
            onClick={() => this.$emit('confirmSingleShift')}
            aria-label={this.$t('modal.buttonNo')}>
            {this.$t('modal.buttonNo')}
          </Button>
          <Button
            color={ButtonColor.PRIMARY}
            onClick={() => this.$emit('confirmConnectedShifts')}
            size={Size.SMALL}
            type="button"
            kind={ButtonKind.FILL}
            aria-label={this.$t('modal.buttonYes')}>
            {this.$t('modal.buttonYes')}
          </Button>
        </template>
      </Prompt>
    );
  }
}
