/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { RESTING_DAY } from 'components/rotation-wizard/store/types';
import type { RotationDayPreset } from 'components/rotation-wizard/store/types';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import styles from './day-cell.css';
import PresetSelect from '../preset-select/PresetSelect';

interface Props {
  preset: RotationDayPreset;
  isDisabled?: boolean;
}

interface Events {
  onPresetSelect: (
    e: SyntheticEvent<HTMLDivElement, MouseEvent>,
    preset: RotationDayPreset,
  ) => void;
}

@Component
class DayCell extends TSXComponent<Props, Events> {
  public $refs: {
    ref: HTMLDivElement;
  };

  protected isPresetSelectOpen = false;

  @Prop()
  public preset: Props['preset'];

  @Prop({ default: false })
  public isDisabled: Props['isDisabled'];

  @Emit('presetSelect')
  public onPresetSelect(e: SyntheticEvent<HTMLDivElement, MouseEvent>, preset: RotationDayPreset) {
    this.isPresetSelectOpen = false;
  }

  public render() {
    const isNoPresetSet = !this.preset;
    const isRestingDay = this.preset === RESTING_DAY;
    const isPresetSet = !(isNoPresetSet || isRestingDay);

    const presetStyle = typeof this.preset !== 'string' && isPresetSet ? {
      color: this.preset?.color,
      backgroundColor: `${this.preset?.color}4C`,
    } : {};

    return (
      <div
        class={[
          styles.dayCell,
          !isNoPresetSet && styles.dayCellWithPreset,
          isRestingDay && styles.dayCellRestingDay,
          this.isPresetSelectOpen && styles.dayCellPresetSelectOpen,
        ]}
        ref="ref"
        style={!isRestingDay && presetStyle}
      >
        {this.isPresetSelectOpen && (
          <OutsideClickHandler
            insideRef={this.$refs.ref}
            onOutsideClick={() => { this.isPresetSelectOpen = false; }}
          />
        )}
        <button
          disabled={this.isDisabled}
          class={styles.dayCellButton}
          onClick={() => { this.isPresetSelectOpen = !this.isPresetSelectOpen; }}
          type="button"
        >
          {isNoPresetSet && (
            <Icon name={IconName.PLUS} size={Size.MEDIUM} />
          )}

          {isRestingDay && this.$t('rotationWizard.rotationPatternSetup.restingDay')}

          {typeof this.preset !== 'string' && isPresetSet && this.preset?.name}
        </button>

        {this.isPresetSelectOpen && <PresetSelect onSelect={this.onPresetSelect} />}
      </div>
    );
  }
}

export default DayCell;
