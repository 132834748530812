import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import Action from 'components/snackbar/store/Action';
import { AlertKind } from 'components/alert/Alert';
import RotationWizardAction from './store/Action';
import { rotationWizardNS } from './store/Store';
import RotationWizard from './RotationWizard';
import { Route } from './routes';
import styles from './rotation-wizard-edit-container.css';

interface Props {
  rotationId: number;
}

@Component
export default class RotationWizardEditContainer extends TsxComponent<Props> {
  private isLoading = true;

  @Prop()
  private rotationId: number;

  @rotationWizardNS.Action(RotationWizardAction.FETCH_ROTATION)
  protected fetchRotation: (rotationId: number) => Promise<number | undefined>;

  @snackbarNS.Action(Action.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  public async created() {
    this.isLoading = true;
    const id = await this.fetchRotation(this.rotationId);
    if (!id) {
      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: 'Shift rotation not found',
        timeout: 5000,
      });
      this.$router.replace({ name: Route.ROOT });
    }
    this.isLoading = false;
  }

  public render() {
    return (
      this.isLoading
        ? <div class={styles.rotationWizardEditContainerMessage}>
          <span>
            {this.$t('rotationWizard.loading')}
          </span>
        </div>
        : <RotationWizard isEdit={true}/>

    );
  }
}
