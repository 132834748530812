import Absence from 'components/calendar-common/absences/Absence';
import DateItem from 'components/calendar-common/common/DateItem';
import Employment from 'components/calendar-common/employments/Employment';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridPrintRow from 'components/calendar-common/grid/grid-print-row/GridPrintRow';
import { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import Shift from 'components/calendar-common/shifts/Shift';
import { Component, Vue } from 'vue-property-decorator';
import { calendarPrintShiftsNS } from 'components/calendar-print/shifts/store/Store';
import { calendarPrintAbsencesNS } from 'components/calendar-print/absences/store/Store';
import styles from './employments-week.css';
import { calendarPrintCommonNS } from '../../common/Store';
import { calendarPrintEmploymentsNS } from '../store/Store';

@Component
export default class EmployementsWeek extends Vue {
  @calendarPrintCommonNS.Getter('dates')
  private dates: DateItem[];

  @calendarPrintShiftsNS.Getter('shiftsByEmployments')
  private shifts: Record<number, Record<string, Shift[]>>;

  @calendarPrintAbsencesNS.Getter('absencesByEmployments')
  private absences: Record<number, Record<string, Absence[]>>;

  @calendarPrintEmploymentsNS.Getter('filteredEmployments')
  private employments: Employment[];

  private renderAbsence(absence: Absence) {
    return (<div class={styles.employmentWeekAbsence}>
      {this.$t(`calendar.print.${absence.state}Absence`)}
    </div>);
  }

  private renderShift(shift: Shift) {
    return (
      <div class={styles.employmentWeekShift}>
        <div class={styles.employmentWeekShiftTime}>
          {`${shift.getStartTime()} - ${shift.getEndTime()}`}
        </div>
        <div>
          {shift.position.name}
        </div>
      </div>
    );
  }

  public render() {
    return (
      <div class={[gridStyles.gridTable, gridStyles.gridTablePrint, styles.employmentWeek]}>
        <GridCell isHeader={true}
          class={[gridStyles.gridTablePrintHeaderCell]}>
          <span>
            {this.dates[0].date.format('MMMM YY')}
          </span>
        </GridCell>
        <div class={[gridStyles.gridTableSubgrid, styles.employmentWeekGrid]}>
          <GridPrintRow dates={this.dates}
            isHeader={true}
            cellClass={gridStyles.gridTablePrintHeaderCell}
            scopedSlots= {{
              cell: (scope) => {
                const { date } = scope.dateItem;
                return (<div>
                  {date.format('ddd DD/MM')}
                </div>);
              },
            }}>
            <GridCell slot="label"
              class={gridStyles.gridTablePrintHeaderCell}
              isHeader={true}>
              <span>
                {this.$t('calendar.titleEmployment')}
              </span>
            </GridCell>
          </GridPrintRow>
          {this.employments.map(employment => (<GridPrintRow dates={this.dates}
            scopedSlots= {{
              cell: ({ dateItem }: GridRowScope) => {
                const { dateKey } = dateItem;
                return (<div class={styles.employmentWeekCell}>
                  {
                    this.absences[employment.id][dateKey]
                  && this.absences[employment.id][dateKey]
                    .map(absence => this.renderAbsence(absence))
                  }
                  {
                    this.shifts[employment.id][dateKey]
                  && this.shifts[employment.id][dateKey]
                    .map(shift => this.renderShift(shift))}
                </div>);
              },
            }}>
            <GridCell slot="label"
              class={gridStyles.gridTablePrintLabelCell}
              isHeader={true}>
              <span>
                {employment.name}
              </span>
            </GridCell>
          </GridPrintRow>))}
        </div>
      </div>
    );
  }
}
