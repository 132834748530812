import { Logger, createLogger, transports } from 'winston';
import SentryTransport from './SentryTransport';

class ApplicationLogger {
  public instance: Logger;

  public constructor() {
    this.instance = createLogger();
    // in env comma separated line of values will be provided
    // for example: console,sentry
    const logTypesFromEnv = (process.env.LOG_TYPES || '')
      .split(',')
      .map(it => it.trim())
      .filter(it => it.length > 0);
    const logTypes = logTypesFromEnv.length ? logTypesFromEnv : ['console'];
    if (logTypes.includes('sentry') && process.env.SENTRY_DSN) {
      const sentryTransport = new SentryTransport({
        level: 'info',
        dsn: process.env.SENTRY_DSN,
      });
      this.instance.add(sentryTransport);
    }

    if (logTypes.includes('console')) {
      this.instance.add(new transports.Console());
    }
  }
}

export default ApplicationLogger;
