export enum SelectionMode {
  SINGLE,
  MULTIPLE,
  INTERVAL,
}

export interface SelectedTimeframe {
  startsAt: Date;
  endsAt: Date;
}

export enum SelectionState {
  NOT_SELECTED,
  SINGLE_SELECTION,
  INTERVAL,
}
