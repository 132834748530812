import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './form-dialog.css';

export enum SectionKind {
  ONE_COLUMN,
  TWO_COLUMN,
}

@Component
export default class Section extends TsxComponent<{
  kind?: SectionKind;
  label?: string;
}, {}> {
  @Prop({ default: SectionKind.ONE_COLUMN })
  public kind: SectionKind;

  @Prop()
  public label?: string;

  public render() {
    return (
      <section
        class={{
          [styles.formDialogSection]: true,
          [styles.formDialogSectionTwoColumn]: this.kind === SectionKind.TWO_COLUMN,
        }}
      >
        {
          this.label && (
            <div class={styles.formDialogSectionLabel}>
              {this.label}
            </div>
          )
        }

        {this.$slots.default}
      </section>
    );
  }
}
