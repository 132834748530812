/* eslint-disable @typescript-eslint/no-empty-function */
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError as createErrorLink, ErrorResponse } from 'apollo-link-error';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { HttpLink } from 'apollo-link-http';
import introspectionResult from '../../../codegen/introspection-result';

export type GraphqlClient = ApolloClient<NormalizedCacheObject>;

export default class GraphqlClientFactory {
  protected token?: string;

  protected email?: string;

  protected gqlEndpoint: string;

  private client: GraphqlClient;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  private errorHandler = (e: ErrorResponse) => { };

  public constructor(gqlEndpoint: string, token?: string, email?: string) {
    this.token = token;
    this.email = email;
    this.gqlEndpoint = gqlEndpoint;
    this.client = this.createClient();
  }

  public setToken(token?: string) {
    this.token = token;
  }

  public setEmail(email?: string) {
    this.email = email;
  }

  public getClient() {
    return this.client;
  }

  public setErrorHandler(errorHandler: (e: ErrorResponse) => void) {
    this.errorHandler = errorHandler;
  }

  public createClient(): ApolloClient<NormalizedCacheObject> {
    const link = ApolloLink.split(
      operation => !operation.getContext().useBatching,
      this.getLink(),
      this.getBatchLink(),
    );

    return new ApolloClient({
      link,
      cache: new InMemoryCache({
        fragmentMatcher: new IntrospectionFragmentMatcher({
          introspectionQueryResultData: introspectionResult,
        }),
        dataIdFromObject: (object) => {
          // eslint-disable-next-line no-underscore-dangle
          switch (object.__typename) {
            case 'Availability': return (object as any).uniqueId;
            default: return defaultDataIdFromObject(object);
          }
        },
      }),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        mutate: {
          fetchPolicy: 'no-cache',
          // 'all' so that we can retrieve those errors
          errorPolicy: 'all',
        },
      },
    });
  }

  private composeLinkChainWithErrorAndAuth(httpLink: ApolloLink) {
    const errorLink = createErrorLink(e => this.errorHandler(e));
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authemail: this.email,
        authtoken: this.token,
        skipApiRateLimit: 'YES',
      },
    }));

    return ApolloLink.from([
      errorLink,
      authLink,
      httpLink,
    ]);
  }

  private getLink(): ApolloLink {
    const httpLink = new HttpLink(
      { uri: this.gqlEndpoint },
    );

    return this.composeLinkChainWithErrorAndAuth(httpLink);
  }

  private getBatchLink(): ApolloLink {
    const httpLink = new BatchHttpLink(
      { uri: this.gqlEndpoint },
    );

    return this.composeLinkChainWithErrorAndAuth(httpLink);
  }
}
