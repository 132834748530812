import RootStoreState from 'store/RootStoreState';
import type { HasAnyLocationRightFunction, HasAnyLocationsPositionRightFunction, HasAnyRightFunction } from 'components/auth/store/Store';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEvaluationMutation,
  GQLCreateEvaluationMutationVariables,
  GQLEvaluationFragmentFragment,
  GQLEvaluationQuery,
  GQLEvaluationQueryVariables,
  GQLEvaluationsQuery,
  GQLEvaluationsQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateEvaluationGql from './queries/CreateEvaluation.gql';
import EvaluationsGql from './queries/Evaluations.gql';
import EvaluationGql from './queries/Evaluation.gql';

export const evaluationsNS = namespace('evaluations');

export type StoreState = ById<GQLEvaluation>;

export type GQLEvaluation = Omit<GQLEvaluationFragmentFragment, 'id'> & { id: number };

export type CreateEvaluationFunction = (
  payload: Omit<GQLCreateEvaluationMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchAllEvaluationsFunction = (
  payload: Omit<GQLEvaluationsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchEvaluationFunction = (
  payload: Omit<GQLEvaluationQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const transformEvaluation = (evaluation: GQLEvaluationFragmentFragment): GQLEvaluation => ({
  ...evaluation,
  id: evaluation.id === null ? -1 : evaluation.id,
});

const getEvaluationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
    getters: {
      getByStaffShiftId: (state: StoreState) => (staffShiftId: number) => (
        Object.values<GQLEvaluation>(state.byId).find(item => item.staffShiftId === staffShiftId)
      ),
      hasEvaluationPayShowRight: (state, getters, rootState, rootGetters) => (
        (evaluation: GQLEvaluation) => {
          const isSuperAdmin = rootGetters['auth/isSuperAdmin'];
          const hasAnyRight: HasAnyRightFunction = rootGetters['auth/hasAnyRight'];
          const hasAnyLocationRight: HasAnyLocationRightFunction = rootGetters['auth/hasAnyLocationRight'];
          const hasAnyLocationsPositionRight: HasAnyLocationsPositionRightFunction = rootGetters['auth/hasAnyLocationsPositionRight'];

          return isSuperAdmin
            || hasAnyRight('payments_show_all')
            || hasAnyLocationRight(evaluation.location.id, 'payment_show_right')
            || hasAnyLocationsPositionRight(evaluation.locationsPosition.id, 'payment_show_right');
        }
      ),
      hasEvaluationPayManageRight: (state, getters, rootState, rootGetters) => (
        (evaluation: GQLEvaluation) => {
          const isSuperAdmin = rootGetters['auth/isSuperAdmin'];
          const hasAnyRight: HasAnyRightFunction = rootGetters['auth/hasAnyRight'];
          const hasAnyLocationRight: HasAnyLocationRightFunction = rootGetters['auth/hasAnyLocationRight'];
          const hasAnyLocationsPositionRight: HasAnyLocationsPositionRightFunction = rootGetters['auth/hasAnyLocationsPositionRight'];

          return isSuperAdmin
            || hasAnyRight('payments_manage_all')
            || hasAnyLocationRight(evaluation.location.id, 'payment_manage_right')
            || hasAnyLocationsPositionRight(evaluation.locationsPosition.id, 'payment_manage_right');
        }
      ),
    },
  });

  const create: ActionProvider<
  GQLCreateEvaluationMutation,
  GQLCreateEvaluationMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: CreateEvaluationGql,
      resultKey: 'createEvaluation',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  const fetchAll: ActionProvider<
  GQLEvaluationsQuery, GQLEvaluationsQueryVariables, GQLEvaluation> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllEvaluationsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: EvaluationsGql,
      resultKey: 'evaluations',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: transformEvaluation,
    });
  };

  const fetch: ActionProvider<GQLEvaluationQuery, GQLEvaluationQueryVariables, GQLEvaluation> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: EvaluationGql,
      resultKey: 'evaluations',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: transformEvaluation,
    });
  };

  return createNormalizedStore<GQLEvaluation, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.FETCH_ALL]: fetchAll,
      [Action.FETCH]: fetch,
    },
    graphqlClient,
    logger,
  });
};

export default getEvaluationsStore;
