import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { store as getAbsencesStore, StoreState as BaseStoreState } from 'components/calendar-common/absences/Store';
import {
  absencesByEmployments, FILTER_CHECKS, getByDates, getByDatesUnsorted,
} from 'components/calendar-common/absences/util';
import { CalendarNamespace, Mode } from 'components/calendar-common/Enums';

export const calendarPrintAbsencesNS = namespace(`${CalendarNamespace.PRINT}/absences`);

export type StoreState = BaseStoreState;

export const store = (): Module<StoreState, RootStoreState> => {
  const baseStore = getAbsencesStore();
  return {
    ...baseStore,
    getters: {
      ...baseStore.getters,
      filteredAbsences(state, getters, rootState, rootGetters) {
        const { absenceReasonsIds, filters } = rootState.calendarPrint.filters;
        return getters.absences.filter(it => (
        // mode check is same in all calendars, no need to copy it
          (rootGetters['calendar/common/mode'] === Mode.EMPLOYEE)
            || (
              absenceReasonsIds.length === 0
              || absenceReasonsIds.includes(it.reason.id)
            )
        )
        && FILTER_CHECKS.some(({ key, check }) => (
          filters[key] ? check(it) : false)));
      },
      absencesByDatesUnsorted(state, getters, rootState, rootGetters) {
        return getByDatesUnsorted(rootGetters[`${CalendarNamespace.PRINT}/common/dateKeys`], getters.filteredAbsences);
      },
      absencesByDates(state, getters) {
        return getByDates(getters.absencesByDatesUnsorted);
      },
      absencesByEmployments(state, getters, rootState, rootGetters) {
        return absencesByEmployments(
          rootGetters[`${CalendarNamespace.PRINT}/employments/filteredEmployments`],
          getters.absencesByDatesUnsorted,
        );
      },
    },
  };
};
