import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Figure, { FigureItem } from './figure/Figure';

import styles from './figures-summary.css';

@Component
export default class FiguresSummary extends TsxComponent<{
  isPlaceholderShown?: boolean;
  items: FigureItem[];
}> {
  @Prop()
  public isPlaceholderShown?: boolean;

  @Prop()
  public items: FigureItem[];

  public render() {
    return (
      <div class={styles.figuresSummary}>
        {this.isPlaceholderShown
          ? (
            Array.from({ length: 5 }, (_, index) => (
              <Figure
                label=""
                number={Number.NaN}
                key={`placeholder#${index}`}
                isPlaceholderShown={true}
              />
            ))
          ) : (
            this.items.map((item, index) => (
              <Figure
                key={item.label + index}
                label={item.label}
                number={item.number}
                precision={item.precision}
                useGrouping={item.useGrouping}
              />
            ))
          )
        }
      </div>
    );
  }
}
