import SpIcon from 'components/sp-icon/SpIcon';
import Tooltip from 'components/tooltip/Tooltip';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './filtering-and-sorting.css';

export enum SectionKind {
  SEARCH = 'search',
  SORTING = 'sorting',
  FILTER_GENERAL = 'filterGeneral',
  FILTER_AVAILABILITY = 'filterAvailability',
  FILTER_QUALIFICATION = 'filterQualification',
}

export interface Props {
  isQualificationsFilterVisible: boolean;
}

@Component
export default class FilteringAndSorting extends TsxComponent<Props, {
  onButtonClick: (e: EventPayload<SectionKind>) => void;
}> {
  @Prop()
  public isQualificationsFilterVisible: Props['isQualificationsFilterVisible'];

  protected onClick(e, section: SectionKind) {
    this.$emit('buttonClick', createEventPayload(e, section));
  }

  public render() {
    return (
      <div class={styles.filteringAndSorting}>
        <Button
          color={ButtonColor.SECONDARY}
          onClick={e => this.onClick(e, SectionKind.SEARCH)}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
        >
          <Tooltip text={this.$t('shifts.employees.labelButtonSearch')}>
            <SpIcon label={this.$t('shifts.employees.labelButtonSearch')} name="search" />
          </Tooltip>
        </Button>

        <Button
          color={ButtonColor.SECONDARY}
          onClick={e => this.onClick(e, SectionKind.SORTING)}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
        >
          <Tooltip text={this.$t('shifts.employees.labelButtonSorting')}>
            <SpIcon label={this.$t('shifts.employees.labelButtonSorting')} name="sort" />
          </Tooltip>
        </Button>

        <Button
          color={ButtonColor.SECONDARY}
          onClick={e => this.onClick(e, SectionKind.FILTER_GENERAL)}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
        >
          <Tooltip text={this.$t('shifts.employees.labelButtonFilter')}>
            <SpIcon label={this.$t('shifts.employees.labelButtonFilter')} name="filter" />
          </Tooltip>
        </Button>

        <Button
          color={ButtonColor.SECONDARY}
          onClick={e => this.onClick(e, SectionKind.FILTER_AVAILABILITY)}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
        >
          <Tooltip text={this.$t('shifts.employees.labelButtonAvailability')}>
            <SpIcon label={this.$t('shifts.employees.labelButtonAvailability')} name="occupied" />
          </Tooltip>
        </Button>

        {
          this.isQualificationsFilterVisible
          && (
            <Button
              color={ButtonColor.SECONDARY}
              onClick={e => this.onClick(e, SectionKind.FILTER_QUALIFICATION)}
              size={Size.SMALL}
              kind={ButtonKind.GHOST}
            >
              <Tooltip text={this.$t('shifts.employees.labelButtonQualification')}>
                <SpIcon label={this.$t('shifts.employees.labelButtonQualification')} name="work" />
              </Tooltip>
            </Button>
          )
        }
      </div>
    );
  }
}
