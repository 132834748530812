import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';
import Avatar from 'components/avatar/Avatar';
import { Size } from 'components/types';
import type { Absence } from '../types';
import styles from './cell-employment.css';

@Component
export default class CellEmployment extends TsxComponent<{
  employment: Absence['employment'];
}> {
  @Prop()
  public employment: Absence['employment'];

  public render() {
    return (
      <td
        class={[tableStyles.tableBodyCell, styles.cellEmployment]}
        title={`${this.employment.firstName} ${this.employment.lastName}`}
      >
        <Avatar
          class={styles.cellEmploymentAvatar}
          size={Size.MEDIUM}
          firstName={this.employment.firstName}
          lastName={this.employment.lastName}
          imageUrl={this.employment.pictureData?.pictureSmall}
        />
        {`${this.employment.firstName} ${this.employment.lastName}`}
      </td>
    );
  }
}
