import { GQLRequestType } from 'codegen/gql-types';
import EmploymentWithAvatar, { Slot } from 'components/employment-with-avatar/EmploymentWithAvatar';
import Vacancy from 'components/employment-with-avatar/Vacancy';
import { shiftsNS } from 'store/shifts/Store';
import type { Shift } from 'store/shifts/Store';
import { filterFalsy, sortByLastName } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import DialogUnassignConnected from 'components/dialog-shift/dialog-unassign-connected/DialogUnassignConnected';
import Tooltip from 'components/tooltip/Tooltip';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'src/utils/store';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { assignmentGroupsNS } from 'src/store/assignment-groups/Store';
import type { UnassignEmploymentFromAssignmentGroupFunction } from 'src/store/assignment-groups/Store';
import AssignmentGroupAction from 'src/store/assignment-groups/Action';
import { Size } from 'components/types';
import { AlertKind } from 'components/alert/Alert';
import Button from 'components/form/button/Button';
import { IconPosition } from 'components/form/button/types';
import { IconName } from 'components/icons/types';
import { ButtonKind } from 'components/form/base-button/types';
import { staffShiftsNS } from 'src/store/staff-shifts/Store';
import type { DeleteStaffShiftFunction } from 'src/store/staff-shifts/Store';
import { Action } from 'src/store/normalized-store';
import CheckboxGeneral from '../filtering/CheckboxGeneral';
import styles from './column-assigned.css';
import IndicatorLeaveShiftRequest from '../indicator-leave-shift-request/IndicatorLeaveShiftRequest';
import DialogAssignmentGroupUnassign from './dialog-assignment-group-unassign/DialogAssignmentGroupUnassign';
import CollapsibleQualifications from './collapsible-qualifications/CollapsibleQualifications';

export enum AssignmentGroupUnassignmentKind {
  INDIVIDUAL = 'individual',
  FUTURE = 'future',
  ALL = 'all'
}

export enum ConfirmationDialogKind {
  CONNECTED_SHIFTS = 'connectedShifts',
  ASSIGNMENT_GROUP_SHIFTS = 'assignmentGroupShifts'
}

@Component
export default class ColumnAssigned extends TsxComponent<{
  shift: Shift;
}, {
  onRefetchShift: ({ useBackgroundFetch: boolean }) => void;
}> {
  @Prop()
  public shift: Shift;

  @staffShiftsNS.Action(Action.DELETE)
  protected deleteStaffShiftAction: DeleteStaffShiftFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @assignmentGroupsNS.Action(AssignmentGroupAction.UNASSIGN_EMPLOYMENT)
  protected unassignEmploymentFromAssignmentGroup: UnassignEmploymentFromAssignmentGroupFunction;

  @shiftsNS.Getter
  protected canManageShift: (locationId: number, locationsPositionId: number) => boolean;

  protected isLeaveRequestFilterEnabled = false;

  protected isLoading = false;

  protected isUnassignConnectedShiftConfirmDialogOpen = false;

  protected isUnassignAssignmentGroupConfirmDialogOpen = false;

  protected staffShiftIdToDelete: number | null;

  protected unassignEmploymentId: number | null;

  protected unassignAssignmentGroupId: number | null;

  protected get staffShifts() {
    if (!Array.isArray(this.shift.staffShifts)) {
      return [];
    }

    const employmentsWithLeaveRequest = this.isLeaveRequestFilterEnabled
      ? this.leaveRequests.map(o => o.employment?.id)
      : [];

    return this.shift.staffShifts
      .filter(filterFalsy)
      .filter((o): o is Omit<typeof o, 'employment'> & { employment: NonNullable<typeof o.employment> } => !!o.employment)
      .filter(o => !this.isLeaveRequestFilterEnabled
        || employmentsWithLeaveRequest.includes(o.employment?.id))
      .map(staffShift => ({
        ...staffShift,
        assignmentGroupId: this.shift.shiftAssignmentGroups?.find(
          sag => sag.id === staffShift.shiftAssignmentGroupId,
        )?.assignmentGroupId,
      }))
      .sort((s1, s2) => sortByLastName(s1.employment, s2.employment));
  }

  protected get vacancies() {
    if (this.isLeaveRequestFilterEnabled || !Array.isArray(this.shift.staffShifts)) {
      return [];
    }

    const length = this.shift.workers
      - (this.shift.staffShiftsCount || this.shift.staffShifts.length);

    return Array.from({ length }, (_, index) => ({
      id: Number.MIN_SAFE_INTEGER + index,
    }));
  }

  protected get leaveRequests() {
    return this.shift.requests.filter(o => o.type === GQLRequestType.CHANGEREQUEST);
  }

  protected get employmentIdsWithLeaveRequest() {
    return this.shift.requests
      .filter(o => o.type === GQLRequestType.CHANGEREQUEST)
      .map(o => o.employment?.id);
  }

  protected get isUnassignmentFromShiftAllowed(): boolean {
    return this.canManageShift(
      this.shift?.locationsPosition.location?.id || Number.NaN,
      this.shift?.locationsPosition.id || Number.NaN,
    );
  }

  protected get shiftQualificationsForAccordion() {
    const getAssignedEmploymentsToShiftQualification = (qualificationId: number) => this.shift
      .staffShifts?.filter(o => o.employment?.qualificationIds?.includes(qualificationId)) || [];

    return this.shift.shiftQualifications?.map(({ count, qualification, qualificationId }) => {
      const assignedEmployments = getAssignedEmploymentsToShiftQualification(qualificationId);

      return {
        label: `${qualification.name}: ${assignedEmployments.length}/${count}`,
        assignedEmployeeNames: assignedEmployments.map(o => `${o.employment?.firstName} ${o.employment?.lastName}`)
          .concat(Array.from({ length: count - assignedEmployments.length })
            .map(() => this.$t('shifts.qualifications.labelVacancy'))),
      };
    }) || [];
  }

  protected async deleteStaffShift(staffShiftId: number, unassignFromConnected: boolean) {
    this.isLoading = true;
    const response = await executeStoreActionWithFailureSnackbar(
      this,
      { id: staffShiftId, unassignFromConnected },
      this.deleteStaffShiftAction,
      '',
    );
    this.isLoading = false;

    if (response.state === StoreActionState.ERROR) {
      return false;
    }

    this.$emit('refetchShift', { useBackgroundFetch: true });
    return this.showSnackbar({
      message: this.$t('shifts.employees.shiftUnassigned'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected unassignIndividualConnectedShifts(staffShiftId: number) {
    if (this.shift.connectedGroupId) {
      this.staffShiftIdToDelete = staffShiftId;
      this.isUnassignConnectedShiftConfirmDialogOpen = true;
      return;
    }
    this.deleteStaffShift(staffShiftId, false);
    this.resetUnassignmentSavedState();
  }

  protected resetUnassignmentSavedState() {
    this.staffShiftIdToDelete = null;
    this.unassignEmploymentId = null;
    this.unassignAssignmentGroupId = null;
  }

  protected onCloseConfirmationDialog(type: ConfirmationDialogKind) {
    if (type === ConfirmationDialogKind.ASSIGNMENT_GROUP_SHIFTS) {
      this.isUnassignAssignmentGroupConfirmDialogOpen = false;
    }
    if (type === ConfirmationDialogKind.CONNECTED_SHIFTS) {
      this.isUnassignConnectedShiftConfirmDialogOpen = false;
    }
    this.resetUnassignmentSavedState();
  }

  protected async onUnassignAssignmentGroups(
    type: AssignmentGroupUnassignmentKind,
  ) {
    this.isUnassignAssignmentGroupConfirmDialogOpen = false;
    let futureOrAllAssignmentPayload: {
      payload: {
        onlyFuture: boolean | null;
        shiftId: number | null;
        assignmentGroupId: number;
        employmentId: number;
      };
      successfulMessage: string;
    };

    switch (type) {
      case AssignmentGroupUnassignmentKind.INDIVIDUAL: {
        const { staffShiftIdToDelete } = this;

        // resetting state related to assignment group as those are not needed anymore
        this.resetUnassignmentSavedState();

        return this.unassignIndividualConnectedShifts(staffShiftIdToDelete!);
      }
      case AssignmentGroupUnassignmentKind.FUTURE:
        futureOrAllAssignmentPayload = {
          payload: {
            onlyFuture: true,
            shiftId: this.shift.id,
            assignmentGroupId: this.unassignAssignmentGroupId!,
            employmentId: this.unassignEmploymentId!,
          },
          successfulMessage: 'shifts.employees.shiftUnassignedFromFutureShifts',
        };
        break;
      case AssignmentGroupUnassignmentKind.ALL:
        futureOrAllAssignmentPayload = {
          payload: {
            onlyFuture: null,
            shiftId: null,
            assignmentGroupId: this.unassignAssignmentGroupId!,
            employmentId: this.unassignEmploymentId!,
          },
          successfulMessage: 'shifts.employees.shiftUnassignedFromAllShifts',
        };
        break;
      default:
        break;
    }

    this.resetUnassignmentSavedState();

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      futureOrAllAssignmentPayload!.payload,
      this.unassignEmploymentFromAssignmentGroup,
      '',
    );

    if (response.state === StoreActionState.ERROR) {
      return false;
    }

    return this.showSnackbar({
      message: this.$t(futureOrAllAssignmentPayload!.successfulMessage),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onUnassignConnectedShifts(unassignFromConnected: boolean) {
    this.isUnassignConnectedShiftConfirmDialogOpen = false;
    this.deleteStaffShift(this.staffShiftIdToDelete!, unassignFromConnected);
    this.resetUnassignmentSavedState();
  }

  protected onUnassignClick(
    employmentId: number,
    staffShiftId: number,
    assignmentGroupId: number | undefined,
  ) {
    if (!assignmentGroupId) {
      this.unassignIndividualConnectedShifts(staffShiftId);
      return;
    }
    this.staffShiftIdToDelete = staffShiftId;
    this.unassignEmploymentId = employmentId;
    this.unassignAssignmentGroupId = assignmentGroupId;
    this.isUnassignAssignmentGroupConfirmDialogOpen = true;
  }

  public render() {
    return (
      <div>
        {
          !!this.shiftQualificationsForAccordion.length
          && this.shiftQualificationsForAccordion.map(({ label, assignedEmployeeNames }) => (
            <CollapsibleQualifications
              label={label}
              assignedEmployeeNames={assignedEmployeeNames}
            />
          ))
        }
        <header class={styles.columnAssignedHeader}>
          {
            this.$t(
              'shifts.employees.labelAssigned',
              {
                numAssigned: this.shift.staffShifts?.length || 0,
                numTotal: this.shift.workers || 0,
              },
            )
          }

          <CheckboxGeneral
            checked={this.isLeaveRequestFilterEnabled}
            class={styles.columnAssignedLeaveRequestFilter}
            icon="swap-circle"
            label={this.$t('shifts.employees.labelLeaveRequestFilter')}
            name="leave-request-filter"
            onChange={(e) => { this.isLeaveRequestFilterEnabled = e.target.checked; }}
          >
            {this.leaveRequests.length}
          </CheckboxGeneral>
        </header>

        <div class={styles.columnAssignedBody}>
          {
            (
              this.isLoading && (
                <LoadingIndicator class={styles.columnAssignedLoadingIndicator} />
              )
            )
          }
          {
            this.staffShifts.map(({ employment, id, assignmentGroupId }) => (
              <div
                class={styles.columnAssignedItemContainer}
                key={employment.id}
              >
                <div class={styles.columnAssignedItem}>
                  <EmploymentWithAvatar
                    class={styles.columnAssignedEmploymentAvatar}
                    employment={employment}
                    size={Size.SMALL}
                    withStaffNumber={true}
                  >
                    <template slot={Slot.INDICATOR}>
                      {
                        this.employmentIdsWithLeaveRequest.includes(employment.id) && (
                          <IndicatorLeaveShiftRequest
                            class={styles.columnAssignedLeaveShiftIndicator}
                          />
                        )
                      }
                    </template>
                  </EmploymentWithAvatar>
                </div>

                {this.isUnassignmentFromShiftAllowed && (
                  <Tooltip class={styles.columnAssignedTooltip} text={this.$t('shifts.employees.buttonUnassignEmployee')}>
                    <Button
                      class={styles.columnAssignedUnassignButton}
                      disabled={this.isLoading}
                      onClick={() => this.onUnassignClick(employment.id, id, assignmentGroupId)}
                      aria-label={this.$t('shifts.employees.buttonUnassignEmployee')}
                      iconPosition={IconPosition.ALONE}
                      icon={IconName.MINUS}
                      kind={ButtonKind.FILL}
                      size={Size.XXSMALL}
                    />
                  </Tooltip>
                )}
              </div>
            ))
          }

          {
            this.vacancies.map(({ id }) => (
              <div class={styles.columnAssignedItemContainer}>
                <Vacancy class={styles.columnAssignedItem} key={id} size={Size.SMALL} />
              </div>
            ))
          }
        </div>

        <DialogUnassignConnected
          isOpen={this.isUnassignConnectedShiftConfirmDialogOpen}
          onCloseClick={
            () => this.onCloseConfirmationDialog(ConfirmationDialogKind.CONNECTED_SHIFTS)
          }
          onUnassignConnectedShifts={
            unassignFromConnected => this.onUnassignConnectedShifts(unassignFromConnected)
          }
        />

        <DialogAssignmentGroupUnassign
          isDialogOpen={this.isUnassignAssignmentGroupConfirmDialogOpen}
          onCloseClick={
            () => this.onCloseConfirmationDialog(ConfirmationDialogKind.ASSIGNMENT_GROUP_SHIFTS)
          }
          onUnassignAssignmentGroups={type => this.onUnassignAssignmentGroups(type)}
        />
      </div>
    );
  }
}
