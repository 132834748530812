import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './subheader.css';

export enum Slot {
  /** A generic slot on the right side of the Subheader */
  RIGHT = 'right',
  /** Slot for the title (overriding the prop) for more complex scenarios */
  TITLE = 'title',
}

@Component
export default class Subheader extends TsxComponent<{
  title?: string;
}> {
  @Prop()
  public title?: string;

  public render() {
    return (
      <header class={styles.subheader}>
        <h1 class={styles.subheaderTitle}>
          {
            this.$slots[Slot.TITLE]
              ? this.$slots[Slot.TITLE]
              : this.title
          }
        </h1>

        {this.$slots[Slot.RIGHT] && (
          <div class={styles.subheaderRight}>
            {this.$slots[Slot.RIGHT]}
          </div>
        )}
      </header>
    );
  }
}
