import Avatar from 'components/avatar/Avatar';
import AvatarDefault from 'components/avatar/AvatarDefault';
import type { Employment } from 'components/employments/types';
import { Size } from 'components/types';
import { getEmploymentString } from 'utils/utils';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './employment-with-avatar.css';

export enum Slot {
  INDICATOR = 'indicator',
}

export const EMPLOYMENT_WITH_AVATAR_SIZES = [
  Size.SMALL,
  Size.MEDIUM,
  Size.LARGE,
] as const;

export type EmploymentWithAvatarSize = typeof EMPLOYMENT_WITH_AVATAR_SIZES[number];

export interface Props {
  employment?: Omit<Employment, 'locationsPositionIds'> | null;
  size?: EmploymentWithAvatarSize;
  withStaffNumber?: boolean;
  other?: string | JSX.Element | Vue;
}

@Component
export default class EmploymentWithAvatar extends TsxComponent<Props> {
  @Prop()
  public employment?: Props['employment'];

  @Prop({ default: Size.MEDIUM })
  public size: NonNullable<Props['size']>;

  @Prop()
  public withStaffNumber?: Props['withStaffNumber'];

  @Prop()
  public other?: Props['other'];

  protected get name() {
    return this.employment
      ? getEmploymentString(this.employment)
      : this.$t('employments.nameUnknown');
  }

  public render() {
    return (
      <div
        class={{
          [styles.employmentWithAvatar]: true,
        }}
      >
        <div class={styles.employmentWithAvatarAvatarWithIndicator}>
          {
            this.employment
              ? (
                <Avatar
                  size={this.size}
                  firstName={this.employment.firstName}
                  lastName={this.employment.lastName}
                  imageUrl={this.employment?.pictureData?.pictureSmall}
                />
              ) : (
                <AvatarDefault size={this.size} />
              )
          }

          { this.$slots.indicator }
        </div>

        <div class={styles.employmentWithAvatarContainer}>
          <span class={styles.employmentWithAvatarName} title={this.name}>
            {this.name}
          </span>

          {
            this.withStaffNumber && this.employment?.staffNumber && (
              <span
                class={styles.employmentWithAvatarStaffNumber}
                title={this.employment?.staffNumber}
              >
                {this.employment?.staffNumber}
              </span>
            )
          }

          {this.other}
        </div>
      </div>
    );
  }
}
