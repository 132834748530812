enum Action {
  UPDATE_SHIFT = 'updateShift',
  ADD_TAG = 'addTag',
  DELETE_TAG = 'deleteTag',
  ADD_ASSIGNMENT_GROUP = 'addAssignmentGroup',
  DELETE_SHIFT = 'deleteShift',
  DELETE_CONNECTED_SHIFTS = 'deleteConnectedShifts',
}

export default Action;
