import { Component, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';

interface Props {
  to: string | Location;
  isPressed?: boolean;
  icon: IconName;
}

@Component
export default class NavLink extends TsxComponent<Props> {
  @Prop()
  public to: Props['to'];

  @Prop({ default: false })
  public isPressed: Props['isPressed'];

  @Prop()
  public icon: Props['icon'];

  public render() {
    return (
      <router-link
        to={this.to}
      >
        <Button icon={this.icon} iconPosition={IconPosition.ALONE}>
          {this.$slots.default}
        </Button>
      </router-link>
    );
  }
}
