import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support/lib/api';
import Button from 'components/form/button/Button';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { DialogSpacingSize } from 'components/dialog/base-dialog/BaseDialog';
import styles from './dialog-title.css';

interface Events {
  onCloseClick: (e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>) => void;
}
export interface Props {
  title?: string;
  isCloseButtonDisabled?: boolean;
  isDialogNotClosable?: boolean;
  dialogSpacingSize?: DialogSpacingSize;
}

const DIALOG_SPACING_SIZE_MAP: Record<DialogSpacingSize, string> = {
  [Size.MEDIUM]: styles.spacingMedium,
  [Size.SMALL]: styles.spacingSmall,
};

@Component
export default class DialogTitle extends TsxComponent<Props, Events> {
  @Prop()
  public title: Props['title'];

  @Prop({ default: false })
  public isCloseButtonDisabled: NonNullable<Props['isCloseButtonDisabled']>;

  @Prop({ default: false })
  public isDialogNotClosable: NonNullable<Props['isDialogNotClosable']>;

  @Prop({ default: Size.SMALL })
  public dialogSpacingSize: NonNullable<Props['dialogSpacingSize']>;

  public render() {
    return (<header class={[styles.header, DIALOG_SPACING_SIZE_MAP[this.dialogSpacingSize]] }>
      <h2 class={styles.title}>
        {this.title}
      </h2>
      {
        !this.isDialogNotClosable && <Button
          name="close"
          icon={IconName.CLEAR}
          iconPosition={IconPosition.ALONE}
          color={ButtonColor.SECONDARY}
          onClick={() => this.$emit('closeClick')}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
          disabled={this.isCloseButtonDisabled}
        />
      }
    </header>);
  }
}
