import { isBreakInsideShiftTimeFrame, isBreakOverlappingWithOther } from 'components/dialog-shift/details/utils';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import InputText from 'components/form/input-text/InputText';
import { isStartBeforeEnd } from 'src/utils/date-related';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import AdditionalBreak from './additional-break/AdditionalBreak';
import styles from './breaks.css';

export interface Props {
  context?: string;
  isDisabled?: boolean;
  isMultiDay: boolean;
  isUnpaidBreakDisabled?: boolean;
  shiftEndsAt: Date;
  shiftStartsAt: Date;
  unpaidBreak: number;
  breaks: {
    startsAt: Date;
    endsAt: Date;
    id: number;
  }[];
}

interface Events {
  onAddBreakClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onBreakChange: (payload: OnBreakChangePayload) => void;
  onDeleteBreakClick: (payload: OnDeleteBreakClickPayload) => void;
  onUnpaidBreakChange: (payload: OnUnpaidBreakChangePayload) => void;
  onValidityChange: (payload: OnValidityChangePayload) => void;
}

export type OnDeleteBreakClickPayload = EventPayload<{ id: number }, HTMLElement, UIEvent>;
export type OnBreakChangePayload = EventPayload<
{
  id: number;
  value: Date;
  field: string;
},
HTMLElement,
UIEvent
>;
export type OnValidityChangePayload = EventPayload<{ isValid: boolean }, HTMLElement, UIEvent>;
export type OnUnpaidBreakChangePayload = EventPayload<{value: number}, HTMLElement, UIEvent>;

@Component
class Breaks extends TSXComponent<Props, Events> {
  @Prop()
  public context: Props['context'];

  @Prop()
  public unpaidBreak: Props['unpaidBreak'];

  @Prop()
  public isMultiDay: Props['isMultiDay'];

  @Prop()
  public breaks: Props['breaks'];

  @Prop()
  public shiftStartsAt: Props['shiftStartsAt'];

  @Prop()
  public shiftEndsAt: Props['shiftEndsAt'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public isUnpaidBreakDisabled: Props['isUnpaidBreakDisabled'];

  private getErrorMessageForBreak(breakToValidate: {
    startsAt: Date;
    endsAt: Date;
    id: number;
  }): string | undefined {
    if (!isStartBeforeEnd(breakToValidate.startsAt, breakToValidate.endsAt)) {
      return this.$t('shifts.breaks.error.startBeforeEnd');
    }

    if (!isBreakInsideShiftTimeFrame({
      breakToValidate,
      shiftStartsAt: this.shiftStartsAt,
      shiftEndsAt: this.shiftEndsAt,
    })) {
      return this.$t('shifts.breaks.error.outsideOfShiftTimeframe');
    }

    if (!isBreakOverlappingWithOther({
      breakToValidate,
      allBreaks: this.breaks,
      timeZone: this.$timeZone.value,
    })) {
      return this.$t('shifts.breaks.error.overlappingBreaks');
    }

    return undefined;
  }

  protected onUnpaidBreakInput(e: SyntheticEvent<HTMLInputElement>) {
    this.$emit(
      'unpaidBreakChange',
      createEventPayload(e, { value: Number.parseInt(e.target.value, 10) }),
    );
  }

  public render() {
    return (
      <div class={styles.breaks}>
        <InputText
          class={styles.breaksUnpaidBreakInput}
          disabled={this.isUnpaidBreakDisabled || this.isDisabled}
          label={this.$t('shifts.breaks.labelUnpaidBreak')}
          min="0"
          name="unpaidBreak"
          onInput={this.onUnpaidBreakInput}
          required={true}
          type="number"
          value={this.unpaidBreak.toString()}
        />

        {this.breaks?.map((item) => {
          const errorMessage = this.getErrorMessageForBreak(item);

          return (
            <AdditionalBreak
              additionalBreak={item}
              isDisabled={this.isDisabled}
              isMultiDay={this.isMultiDay}
              shiftEndsAt={this.shiftEndsAt}
              shiftStartsAt={this.shiftStartsAt}
              onChange={ e => this.$emit('breakChange', e) }
              onDeleteBreakClick={ e => this.$emit('deleteBreakClick', e) }
              isValid={!errorMessage}
              errorMessage={errorMessage}
            />
          );
        })}

        {!this.isDisabled && (
          <Button
            class={styles.breaksCenteredButton}
            color={ButtonColor.SUCCESS}
            onClick={e => this.$emit('addBreakClick', createEventPayload(e, undefined))}
            size={Size.SMALL}
            kind={ButtonKind.STROKE}
          >
            {this.$t('shifts.breaks.buttonAdd')}
          </Button>
        )}
      </div>
    );
  }
}

export default Breaks;
