import type { EmploymentsRotationGroup } from 'components/employments/rotation-groups/types';
import type { Employment } from 'components/employments/types';

export const wrapAroundMod = (index: number, length: number, step: number) => (
  (index + step + length) % length
);

/**
 * Reducer to compute the sum of each group.
 * @param keyExtractor Function to extract the key used for the group from `item`
 * @param valExtractor Function to extract the value making up the sum from each `item`
 */
/* eslint-disable indent */
export const groupSumReducer = <T>(
  keyExtractor: (item: T) => number | string,
  valExtractor: (item: T) => number,
) => (prev: Record<number | string, number>, cur: T) => {
  const key = keyExtractor(cur);

  if (!(key in prev)) {
    prev[key] = 0;
  }

  prev[key] += valExtractor(cur);

  return prev;
};
/* eslint-enable indent */

/**
 * Ensure that `array` is actually an array and has at least `length` size
 * @param array Array to test
 * @param length Minimum size of `array`
 */
export const hasMinLength = <T extends any[] | undefined>(
  array: T,
  length: number,
): array is NonNullable<T> => Array.isArray(array) && array.length >= length;

export const filterFalsy = <T>(value?: T): value is NonNullable<T> => Boolean(value);

export const filterUndefined = <T>(value?: T): value is NonNullable<T> => value !== undefined;

export const sortBySortOrder = (reverse?: boolean) => (
  (x: { sort?: number | null }, y: { sort?: number | null }) => {
    if (typeof x.sort !== 'number' || typeof y.sort !== 'number') {
      return 0;
    }

    return (reverse) ? y.sort - x.sort : x.sort - y.sort;
  }
);

// Used to ensure a minimum number of fraction digits.
// Always use "en-US" as inputs only support this format
export const formatNumberWithOneFractionMin = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 1,
  useGrouping: false,
}).format;

export const sortBySortPosition = (
  x: { sortPosition: number | null },
  y: { sortPosition: number | null },
) => {
  if (x.sortPosition === null || y.sortPosition === null) {
    return 0;
  }

  return x.sortPosition - y.sortPosition;
};

/* eslint-disable indent */
export const sortEmploymentsRotationGroupByStartsAtAndName = <T extends EmploymentsRotationGroup>({
  reverseName = false,
  reverseStartsAt = false,
} = {}) => (x: T, y: T) => {
  if (x.startsAt && y.startsAt) {
    const xStartsAt = new Date(x.startsAt).valueOf();
    const yStartsAt = new Date(y.startsAt).valueOf();

    if (xStartsAt !== yStartsAt) {
      return reverseStartsAt ? yStartsAt - xStartsAt : xStartsAt - yStartsAt;
    }
  }

  const xShiftRotationName = x.shiftRotationGroup.shiftRotation.name;
  const yShiftRotationName = y.shiftRotationGroup.shiftRotation.name;

  return reverseName
    ? yShiftRotationName.localeCompare(xShiftRotationName)
    : xShiftRotationName.localeCompare(yShiftRotationName);
};
/* eslint-enable indent */

export const getEmploymentString = (employment: Omit<Employment, 'pictureData' | 'locationsPositionIds'>, withStaffNumber = false) => {
  const output = [
    employment.firstName,
    employment.lastName,
  ];

  if (withStaffNumber && employment.staffNumber) {
    output.push(`(${employment.staffNumber})`);
  }

  return output.join(' ');
};

export const formatNumberWithPrecision = (
  locale: string,
  value: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
) => new Intl.NumberFormat(locale, {
  maximumFractionDigits,
  minimumFractionDigits,
}).format(value);

/* eslint-disable arrow-parens */
export const uniqBy = <T>(
  input: T[],
  predicate: ((value: T) => boolean) | keyof T,
  overwrite = false,
): T[] => {
  /* eslint-enable arrow-parens */
  const keyFn = typeof predicate === 'function' ? predicate : (o: T) => o[predicate];

  return [
    ...input.reduce(
      (prev, cur) => {
        const key = keyFn(cur);

        return prev.has(key) && !overwrite
          ? prev
          : prev.set(key, cur);
      },
      new Map(),
    ).values(),
  ];
};

export function sortByLastName<T extends { lastName: string }>(x: T, y: T) {
  return x.lastName.localeCompare(y.lastName);
}

export function sortByName<T extends { name: string }>(x: T, y: T) {
  return x.name.localeCompare(y.name);
}

export const range = (start: number, stop: number, step = 1) => Array.from(
  { length: (stop - start) / step + 1 },
  (_, index) => start + (index * step),
);

export const formatCurrency = (locale: string, value: number, currency: string) => (
  `${formatNumberWithPrecision(locale, value, 2, 2)}${currency}`
);

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
