import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import BaseTooltip, { TooltipType } from 'components/tooltip/BaseTooltip';

export interface PopoverProps {
  content?: string | JSX.Element | Vue;
}

@Component
export default class Popover extends TsxComponent<PopoverProps> {
  @Prop()
  public content: PopoverProps['content'];

  public render() {
    return (
      <BaseTooltip type={TooltipType.POPOVER} text={this.content} >
        {this.$slots.default}
      </BaseTooltip>
    );
  }
}
