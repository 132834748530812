import InputButtonSelect from 'components/form/input-button-select/InputButtonSelect';
import { EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './section-time-frame-kind.css';

export enum TimeFrameKind {
  CUSTOM = 'custom',
  MONTH = 'month',
  WEEK = 'week',
}

interface Props {
  timeFrameKind: TimeFrameKind;
}

@Component
export default class SectionTimeFrameKind extends TsxComponent<Props, {
  onChange: (payload: EventPayload<TimeFrameKind, HTMLInputElement>) => void;
}> {
  @Prop()
  public timeFrameKind: Props['timeFrameKind'];

  protected get options() {
    return [
      {
        label: this.$t('createShiftplan.modal.buttonWeek'),
        value: TimeFrameKind.WEEK,
      },
      {
        label: this.$t('createShiftplan.modal.buttonMonth'),
        value: TimeFrameKind.MONTH,
      },
      {
        label: this.$t('createShiftplan.modal.buttonCustom'),
        value: TimeFrameKind.CUSTOM,
      },
    ];
  }

  protected onChange(payload: EventPayload<TimeFrameKind>) {
    this.$emit('change', payload);
  }

  public render() {
    return (
      <section class={styles.sectionTimeFrameKind}>
        <div class={styles.sectionTimeFrameKindLabel}>
          {this.$t('createShiftplan.modal.labelTimeFrame')}
        </div>

        <InputButtonSelect
          name="timeFrameKind"
          options={this.options}
          value={this.timeFrameKind}
          onChange={this.onChange}
        />
      </section>
    );
  }
}
