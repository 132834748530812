import Absence from 'components/calendar-common/absences/Absence';
import DateItem from 'components/calendar-common/common/DateItem';
import Employee from 'components/calendar-common/employees/employee/Employee';
import DndParams from 'components/calendar-common/grid/DndParams';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import ToggleCell from 'components/calendar-common/grid/toggle-cell/ToggleCell';
import { getRotationPatternVisibilityMap, RotationPatternVisibilityState } from 'components/calendar-common/shift-rotation-group/helpers';
import Employment from 'components/calendar-common/employments/Employment';
import styles from 'components/calendar-common/employments/employments.css';
import SummaryCell from 'components/calendar-common/summary/SummaryCell';

@Component
class EmploymentsRow extends Mixins(ShiftViewActionsMixin) {
  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  @Prop()
  public isVisible: boolean;

  @Prop()
  public dates!: DateItem[];

  @Prop()
  public shifts!: Record<string, Shift[]>;

  @Prop()
  public absences!: Record<string, Absence[]>;

  @Prop()
  public employment!: Employment;

  @Prop({ default: false })
  public isLastRow!: boolean;

  @Prop({ default: false })
  public isOpen: boolean;

  private get employmentRotationPattern() {
    return this.employment.getShiftPresetIdsForTimeframe(this.dates);
  }

  private onEmploymentShiftCellClick(dateItem: DateItem) {
    if (!this.employment.isDeleted()) {
      this.onShiftCellClick(dateItem, this.employment.id);
    }
  }

  public render() {
    const absences = { ...this.absences };
    const shifts = { ...this.shifts };
    const isEmploymentRotationPatternVisible = getRotationPatternVisibilityMap(
      shifts,
      this.employmentRotationPattern,
    );
    const isToggleVisible = this.filters.showShiftRotation
      && Object.values(isEmploymentRotationPatternVisible)
        .some(it => it === RotationPatternVisibilityState.NOT_VISIBLE);
    return (
      <GridRow
        isVisible={this.isVisible}
        cellClass={gridStyles.gridTableContentCellPaddingBottom}
        isLastRow={this.isLastRow}
        dates={this.dates}
        dndParams={{
          ...DndParams,
          employmentId: this.employment.id,
          isDroppable: true,
          allowedPositionIds: this.employment.activeLocationsPositionIds,
        }}
        onCellClick={dateItem => this.onEmploymentShiftCellClick(dateItem)}
        scopedSlots= {{
          cell: ({ dateItem }: GridRowScope) => {
            const shiftPresetId = this.employmentRotationPattern[dateItem.dateKey];

            const shouldShowSetPreset = this.isOpen
              ? isEmploymentRotationPatternVisible[dateItem.dateKey]
                !== RotationPatternVisibilityState.NO_ROTATION_PRESENT
              : isEmploymentRotationPatternVisible[dateItem.dateKey]
                === RotationPatternVisibilityState.VISIBLE;
            return [
              this.filters.showShiftRotation
           && shouldShowSetPreset
           && this.$scopedSlots.rotationShiftPreset
            && this.$scopedSlots.rotationShiftPreset({
              shiftPresetId,
            }),
              absences[dateItem.dateKey]
                .map(absence => this.$scopedSlots.absence
              && this.$scopedSlots.absence({ absence })),
              shifts[dateItem.dateKey]
                .map(shift => this.$scopedSlots.shift
              && this.$scopedSlots.shift({ shift, employment: this.employment }))].filter(Boolean);
          },
        }}>
        <GridCell slot="label"
          isHeader={true}
          class={styles.employeeCell}>
          <ToggleCell
            disabled={!isToggleVisible}
            isOpen={this.isOpen}
            onToggle={() => this.$emit('toggleEmployment', this.employment.id)}>
            <Employee employee={this.employment} isLabel={true} />
          </ToggleCell>
        </GridCell>
        {this.filters.showSummary && <SummaryCell slot="total"/>}
      </GridRow>);
  }
}

export default EmploymentsRow as any;
