import { Pay } from 'components/dialog-shift/paygrades/Section';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import { PaygradeType, paygradeTypesNS } from 'store/paygrade-types/Store';
import { paymentsCalculationNS } from 'store/payments-calculation/Store';
import type { FetchPaymentsCalculationFunction, PaymentsCalculationActionResult } from 'store/payments-calculation/Store';
import { EventPayload } from 'utils/events';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'utils/store';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { EvaluationContext } from 'components/evaluation/types';
import type { Evaluation } from 'components/evaluation/types';
import SectionPay from './SectionPay';
import SectionPayAction from './store/Action';
import { sectionPayEmploymentNS } from './store/Store';
import {
  filterDeletedDeep,
  mergeCalculationsWithPay,
  transformToCalculationPaymentInput,
  transformToPay,
} from './store/utils';
import type { FormState } from '../form/Form';
import { Break } from '../section-breaks/store/Store';

const DEBOUNCE_TIMEOUT = 250;

@Component
export default class SectionPayContainerEmployment extends TsxComponent<{
  breaks: Break[];
  evaluation: Evaluation;
  formState: FormState;
  isDisabled?: boolean;
  isEmploymentEvaluationPresent?: boolean;
}> {
  protected timeoutId: number = Number.NaN;

  protected total = 0;

  @paygradeTypesNS.Getter('bonuses')
  protected typesBonus: PaygradeType[];

  @paygradeTypesNS.Getter('regular')
  protected typesRegular: PaygradeType[];

  @paymentsCalculationNS.Action
  protected fetchPaymentsCalculation: FetchPaymentsCalculationFunction;

  @sectionPayEmploymentNS.Action(SectionPayAction.SET_TREE)
  protected setTree: (tree: Pay[]) => void;

  @sectionPayEmploymentNS.Action(SectionPayAction.ADD_PAY)
  protected addPay: (payload?: Pay) => void;

  @sectionPayEmploymentNS.Action(SectionPayAction.UPDATE_PAY)
  protected updatePay: (payload: Pay) => void;

  @sectionPayEmploymentNS.Action(SectionPayAction.UPDATE_TOTAL)
  protected updateTotal: (payload: Pay) => void;

  @sectionPayEmploymentNS.Action(SectionPayAction.REMOVE_PAY)
  protected removePay: (payload: Pay) => void;

  @sectionPayEmploymentNS.Action(SectionPayAction.DISPATCH_REMOVE)
  protected dispatchRemove: (evaluationId: number) => Promise<void>;

  @sectionPayEmploymentNS.Action(SectionPayAction.DISPATCH_UPDATE)
  protected dispatchUpdate: (evaluationId: number) => Promise<void>;

  @sectionPayEmploymentNS.Action(SectionPayAction.DISPATCH_CREATE)
  protected dispatchCreate: (evaluationId: number) => Promise<void>;

  @sectionPayEmploymentNS.State
  protected tree: Pay[];

  @Prop()
  public breaks: Break[];

  @Prop()
  public evaluation: Evaluation;

  @Prop()
  public formState: FormState;

  @Prop()
  public isDisabled?: boolean;

  @Prop()
  public isEmploymentEvaluationPresent?: boolean;

  @Watch('breaks')
  @Watch('formState.endsAt')
  @Watch('formState.startsAt')
  @Watch('formState.unpaidBreak')
  protected onFormStateUpdate() {
    this.executePaymentsCalculation();
  }

  protected onPayAdd({ payload }: EventPayload<Pay | undefined>) {
    this.addPay(payload);

    this.executePaymentsCalculation();
  }

  protected onPayChange({ payload }: EventPayload<Pay>) {
    this.updatePay(payload);

    this.executePaymentsCalculation();
  }

  protected onPayRemove({ payload }: EventPayload<Pay>) {
    this.removePay(payload);

    this.executePaymentsCalculation();
  }

  protected async executePaymentsCalculation() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    if (Number.isNaN(this.formState.unpaidBreak) || this.formState.unpaidBreak === undefined) {
      return;
    }

    this.timeoutId = window.setTimeout(async () => {
      const treeWithNonDeleted = filterDeletedDeep(this.tree);
      const payments = transformToCalculationPaymentInput(treeWithNonDeleted);

      const response: PaymentsCalculationActionResult = await executeStoreActionWithFailureSnackbar(
        this,
        {
          // FAQ: the evaluation ID is actually the staffShift ID
          staffShiftId: this.evaluation.staffShiftId,
          input: deepTransformDates({
            payments,
            breaks: this.formState.unpaidBreak,
            endsAt: this.formState.endsAt,
            startsAt: this.formState.startsAt,
            evaluationBreaks: this.breaks.map(o => ({
              endsAt: o.endsAt,
              startsAt: o.startsAt,
            })),
          }),
        },
        this.fetchPaymentsCalculation,
        'shifts.paygrades.error',
      );

      if (response.state === StoreActionState.SUCCESS && response.result?.payments) {
        const merged = mergeCalculationsWithPay(response.result.payments, treeWithNonDeleted);

        merged.forEach(pay => this.updateTotal(pay));

        this.total = response.result.sum;
      }
    }, DEBOUNCE_TIMEOUT);
  }

  public mounted() {
    this.setTree((this.evaluation.payments || []).map(payment => transformToPay(
      payment,
      undefined,
      !this.isEmploymentEvaluationPresent,
    )));
    this.total = this.evaluation.totalPayment || 0;
  }

  public beforeDestroy() {
    window.clearTimeout(this.timeoutId);
  }

  public render() {
    return (
      <SectionPay
        context={EvaluationContext.EMPLOYMENT}
        isBonusDisabled={true}
        isDisabled={this.isDisabled}
        isPaymentAddDisabled={true}
        onChange={this.onPayChange}
        onPayAdd={this.onPayAdd}
        onPayRemove={this.onPayRemove}
        pay={this.tree}
        total={this.total}
        typesBonus={this.typesBonus}
        typesRegular={this.typesRegular}
      />
    );
  }
}
