import { MINUTES_IN_HOUR } from 'components/calendar-common/helpers/intervals/Intervals.js';
import DateItem from 'components/calendar-common/common/DateItem';
import gridCellStyles from 'components/calendar-common/grid/grid-cell/grid-cell.css';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GridCell from '../grid-cell/GridCell';
import styles from './time-grid.css';

export interface TimeGridScope {
  dateItem: DateItem;
}

export const DEFAULT_TIME_GRID_STEP = 30;
export const DEFAULT_TIME_GRID_CELL_HEIGHT = 20;
const MINUTES_IN_DAY = 1440; // 24h * 60m
@Component
class TimeGrid extends Vue {
  @Prop({
    default: DEFAULT_TIME_GRID_STEP,
    validator(value) {
      const numberValue = parseInt(value, 10);
      return (!Number.isNaN(numberValue)) && (MINUTES_IN_DAY % numberValue === 0);
    },
  })
  public timeGridStep!: number;

  @Prop()
  public dates: DateItem[];

  @Prop({ default: false })
  public isEdited: boolean;

  private get timeGridKeys() {
    return Array.from(
      new Array(MINUTES_IN_DAY / this.timeGridStep),
      (item, index) => moment()
        .startOf('day')
        .add(index * this.timeGridStep, 'minutes')
        .format('HH:mm'),
    );
  }

  private get rowsInOneHour() {
    return MINUTES_IN_HOUR / this.timeGridStep;
  }

  private onMouseOver(event: MouseEvent, dateItem: DateItem) {
    this.$emit('cellMouseover', event, dateItem);
  }

  private onMouseDown(event: MouseEvent, dateItem: DateItem) {
    this.$emit('cellMousedown', event, dateItem);
  }

  public render() {
    return (<div class={[gridStyles.gridTableSubgrid, styles.timeGrid, {
      [styles.timeGridEdited]: this.isEdited,
    }]}>
      <GridCell isHeader={true} class={[styles.timeGridLabelRow, gridCellStyles.gridCellNoGap]}>
        {this.timeGridKeys.map((it, index) => <div class={{
          [styles.timeGridLabelCell]: true,
          [styles.timeGridLabelCellBorderSolid]:
            index % this.rowsInOneHour === this.rowsInOneHour - 1,
        }}>
          {(index % this.rowsInOneHour === 0) && it}
        </div>)}
      </GridCell>
      {this.dates.map(dateItem => <GridCell
        date={dateItem}
        onFocus={e => this.onMouseOver(e, dateItem)}
        onMouseover={e => this.onMouseOver(e, dateItem)}
        onMousedown={e => this.onMouseDown(e, dateItem)}
        class={[styles.timeGridRow, gridCellStyles.gridCellNoGap]}>
        {this.$scopedSlots.column && this.$scopedSlots.column({ dateItem })}
      </GridCell>)}
    </div>);
  }
}

export default TimeGrid as any;
