import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import { IconSize } from 'components/icons/Icon';
import { ButtonColor, ButtonKind, ButtonSize } from '../base-button/types';

// eslint-disable-next-line import/prefer-default-export
export enum IconPosition {
  BEFORE= 'before',
  AFTER= 'after',
  BOTH = 'both',
  ALONE = 'alone',
}

export interface ButtonProps {
  color?: ButtonColor;
  kind?: ButtonKind;
  icon?: IconName;
  iconPosition?: IconPosition;
  size?: ButtonSize;
}

export const ICON_SIZE_MAP: Record<ButtonSize, IconSize> = {
  [Size.XLARGE]: Size.LARGE,
  [Size.LARGE]: Size.MEDIUM,
  [Size.MEDIUM]: Size.MEDIUM,
  [Size.SMALL]: Size.SMALL,
  [Size.XSMALL]: Size.SMALL,
  [Size.XXSMALL]: Size.SMALL,
};
