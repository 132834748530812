import {
  GQLBackgroundJobsQuery,
  GQLBackgroundJobsQueryVariables,
  GQLBackgroundJobFragmentFragment,
} from 'codegen/gql-types';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import RootStoreState from 'store/RootStoreState';
import {
  QueryProvider,
  WatchStoreState,
  createWatchStore,
} from 'store/watch-store';
import BackgroundJobsGql from './queries/BackgroundJobs.gql';

export const backgroundJobsNS = namespace('backgroundJobs');

export type StoreState = WatchStoreState<BackgroundJob[]>;

export type BackgroundJob = GQLBackgroundJobFragmentFragment;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getBackgroundJobsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = { namespaced: true };

  const query: QueryProvider<
  GQLBackgroundJobsQuery, GQLBackgroundJobsQueryVariables> = (
    { rootState }: StoreActionContext,
    payload: GQLBackgroundJobsQueryVariables,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: BackgroundJobsGql,
      resultKey: 'backgroundJobs',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: input => input.backgroundJobs.items || [],
    });
  };

  return createWatchStore<BackgroundJob, StoreState, RootStoreState>({
    store,
    graphqlClient,
    logger,
    pollInterval: 10000,
    query,
  });
};

export default getBackgroundJobsStore;
