import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Dialog, { Slot } from 'components/dialog/Dialog';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'src/utils/events';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';

interface Props {
  isOpen: boolean;
}

interface Events {
  onCloseClick: (e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>) => void;
  onConfirm: (e: EventPayload<boolean>) => void;
}

@Component
export default class DialogAssignConnected extends TsxComponent<Props, Events> {
  @Prop()
  public isOpen: Props['isOpen'];

  public render() {
    return (
      <Dialog
        isOpen={this.isOpen}
        onCloseClick={e => this.$emit('closeClick', e)}
        title={this.$t('shifts.employees.connectedShifts.title')}
      >
        {this.$t('shifts.employees.connectedShifts.explanationAssign')}

        <Button
          color={ButtonColor.SUCCESS}
          size={Size.SMALL}
          type="button"
          kind={ButtonKind.GHOST}
          slot={Slot.BUTTONS_RIGHT}
          onClick={e => this.$emit('confirm', createEventPayload(e, false))}
          aria-label={this.$t('shifts.employees.connectedShifts.buttonAssignSingle')}
        >
          {this.$t('shifts.employees.connectedShifts.buttonAssignSingle')}
        </Button>

        <Button
          color={ButtonColor.SUCCESS}
          onClick={e => this.$emit('confirm', createEventPayload(e, true))}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          type="button"
          kind={ButtonKind.GHOST}
          aria-label={this.$t('shifts.employees.connectedShifts.buttonAssignAll')}
        >
          {this.$t('shifts.employees.connectedShifts.buttonAssignAll')}
        </Button>
      </Dialog>
    );
  }
}
