import Absence from 'components/calendar-common/absences/Absence';
import DateItem from 'components/calendar-common/common/DateItem';
import Employment from 'components/calendar-common/employments/Employment';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridHeaderMonth from 'components/calendar-common/grid/grid-header-month/GridHeaderMonth';
import GridPrintRow from 'components/calendar-common/grid/grid-print-row/GridPrintRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import { Position } from 'components/calendar-common/positions/Position';
import { calendarPrintPositionsNS } from 'components/calendar-common/positions/Store';
import Shift from 'components/calendar-common/shifts/Shift';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import AbsenceMonth from '../../absences/absence-month/AbsenceMonth';
import ShiftMonth from '../../shifts/shift-month/ShiftMonth';
import { calendarPrintCommonNS } from '../../common/Store';
import { calendarPrintShiftsNS } from '../../shifts/store/Store';
import { calendarPrintEmploymentsNS } from '../../employments/store/Store';
import { calendarPrintAbsencesNS } from '../../absences/store/Store';
import { GRID_COLUMN_MIN_WIDTH_MONTH, GRID_LABEL_COLUMN_WIDTH } from '../../../calendar-common/grid/GridVariables';

@Component
export default class PositionsMonth extends TsxComponent<{}> {
  @calendarPrintCommonNS.Getter('dates')
  public dates!: DateItem[];

  @calendarPrintShiftsNS.Getter('shiftsByPositions')
  public shifts!: Record<number, Record<string, Shift[]>>;

  @calendarPrintShiftsNS.Getter('shiftsByPositionsEmployments')
  public employmentShifts!: Record<number, Record<number, Record<string, Shift[]>>>;

  @calendarPrintAbsencesNS.Getter('absencesByEmployments')
  public employmentAbsences!: Record<number, Record<string, Absence[]>>;

  @calendarPrintPositionsNS.Getter('filteredPositions')
  public positions!: Position[];

  @calendarPrintEmploymentsNS.Getter('employmentsByPositions')
  public positionEmployments: Record<number, Employment[]>;

  private get gridStyle() {
    return {
      gridTemplateColumns: `minmax(${GRID_LABEL_COLUMN_WIDTH}, 4fr) repeat(${this.dates.length}, minmax(${
        GRID_COLUMN_MIN_WIDTH_MONTH}, 1fr))`,
    };
  }

  private renderPositionUsers({ locationsPositionId }) {
    return this.positionEmployments[locationsPositionId]
      .filter((employment) => {
        const hasShifts: boolean = Object.values(
          this.employmentShifts[locationsPositionId][employment.id],
        )
          .some(shifts => shifts.length > 0);
        const hasAbsences: boolean = Object.values(this.employmentAbsences[employment.id])
          .some(absences => absences.length > 0);
        return hasShifts || hasAbsences;
      })
      .map(employment => <GridPrintRow
        dates={this.dates}
        scopedSlots= {{
          cell: scope => [
            this.employmentShifts[locationsPositionId][employment.id][scope.dateItem.dateKey]
              .map(shift => <ShiftMonth shift={shift} key={shift.id}/>),
            this.employmentAbsences[employment.id][scope.dateItem.dateKey]
              .map(absence => <AbsenceMonth absence={absence} key={absence.uniqId}/>)],
        }}>
        <GridCell slot="label"
          isHeader={true}
          class={gridStyles.gridTablePrintLabelCell}>
          <span>
            {employment.name}
          </span>
        </GridCell>
      </GridPrintRow>);
  }

  private renderPositionsRows() {
    const { shifts } = this;
    const lastRowIndex = this.positions.length - 1;
    return (<div class={gridStyles.gridTableSubgrid}>
      {this.positions.map((position, index) => [
        (<GridPrintRow
          isLastRow={index === lastRowIndex}
          dates={this.dates}
          scopedSlots= {{
            cell: scope => shifts[position.locationsPositionId][scope.dateItem.dateKey]
              .map(shift => <ShiftMonth shift={shift} key={shift.id}/>),
          }}>
          <GridCell slot="label"
            isHeader={true}
            class={gridStyles.gridTablePrintLabelCell}>
            <div>
              {position.name}
            </div>
          </GridCell>
        </GridPrintRow>),
        this.renderPositionUsers(position)])}
    </div>);
  }

  public render() {
    return (
      <div class={[gridStyles.gridTable, gridStyles.gridTablePrint]}
        style={this.gridStyle}>
        <GridHeaderMonth
          class={gridStyles.gridTablePrintHeader}
          cellClass={gridStyles.gridTablePrintHeaderCell}
          dates={this.dates}
          isSticky={false}>
          <div slot="label">
            {this.$t('calendar.titlePosition')}
          </div>
        </GridHeaderMonth>
        {this.renderPositionsRows()}
      </div>
    );
  }
}
