import DateItem from 'components/calendar-common/common/DateItem';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface GridRowScope {
  dateItem: DateItem;
  index: number;
  isLastRow: boolean;
}

@Component
class GridPrintRow extends Vue {
  @Prop()
  public dates!: DateItem[];

  @Prop()
  public isHeader!: boolean;

  @Prop({ default: '' })
  public cellClass!: string;

  private getCells() {
    return this.dates.map((dateItem, index) => (
      <GridCell class={[gridStyles.gridTablePrintContentCell, this.cellClass]}
        key={dateItem.dateKey}
        date={dateItem}
        isLastRow={false}
        isHeader={this.isHeader}>
        { this.$scopedSlots.cell && this.$scopedSlots.cell({ dateItem, index })}
      </GridCell>
    ));
  }

  public render() {
    return (<div class={[gridStyles.gridTableSubgrid]}>
      {this.$slots.label}
      {this.getCells()}
    </div>);
  }
}

export default GridPrintRow as any;
