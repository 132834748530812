import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import {
  getDurationSum,
  getHourMinuteDurationString,
  isSameDayInTimeZone,
  MSECS_IN_MINUTE,
  startOf,
  Unit,
} from 'src/utils/date-related';
import { formatISO } from 'date-fns';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'src/utils/events';
import Breaks from 'components/breaks/Breaks';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import type { CompleteBreakInput, FormState } from '../types';

import styles from './section-breaks.css';

export interface Props {
  additionalShiftBreaks: CompleteBreakInput[];
  isDisabled?: boolean;
  isUnpaidBreakDisabled?: boolean;
  shiftEndsAt: Date;
  shiftStartsAt: Date;
  unpaidBreak: number;
}

interface Events {
  onChange: <T extends keyof FormState>(e: EventPayload<{ field: T; value: FormState[T] }>) => void;
}

@Component
class SectionBreaks extends TSXComponent<Props, Events> {
  private unsubmittedBreakIds: number[] = [];

  @Prop()
  public additionalShiftBreaks: Props['additionalShiftBreaks'];

  @Prop()
  public unpaidBreak: Props['unpaidBreak'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public isUnpaidBreakDisabled: Props['isUnpaidBreakDisabled'];

  @Prop()
  public shiftStartsAt: Props['shiftStartsAt'];

  @Prop()
  public shiftEndsAt: Props['shiftEndsAt'];

  private get shiftBreaksWithDates() {
    return this.additionalShiftBreaks.map(shiftBreak => ({
      ...shiftBreak,
      startsAt: new Date(shiftBreak.startsAt),
      endsAt: new Date(shiftBreak.endsAt),
    }));
  }

  private get isMultiDayShift() {
    return !isSameDayInTimeZone(
      this.shiftStartsAt,
      this.shiftEndsAt,
      this.$timeZone.value,
    );
  }

  private get additionalBreaksSumInMinutes() {
    return getDurationSum(this.additionalShiftBreaks) / MSECS_IN_MINUTE;
  }

  private get formattedTotalBreakTimeInMiliseconds() {
    const value = (Number.isNaN(this.unpaidBreak) ? 0 : this.unpaidBreak
      + this.additionalBreaksSumInMinutes) * 60 * 1000;

    return getHourMinuteDurationString(value);
  }

  private onAddBreakClick() {
    const nextId = this.unsubmittedBreakIds.length;

    this.onInput('shiftBreaks', [
      ...this.additionalShiftBreaks,
      {
        startsAt: formatISO(
          startOf(this.shiftStartsAt, Unit.DAY, this.$timeZone.value),
        ),
        endsAt: formatISO(
          startOf(this.shiftStartsAt, Unit.DAY, this.$timeZone.value),
        ),
        id: nextId,
      },
    ]);

    this.unsubmittedBreakIds.push(nextId);
  }

  private onDeleteBreakClick({ payload: { id: idToDelete } }) {
    this.onInput('shiftBreaks', [
      ...this.additionalShiftBreaks.filter(({ id }) => id !== idToDelete),
    ]);
  }

  private onBreakChange({
    payload: {
      id: idToChange,
      field,
      value,
    },
  }) {
    this.onInput(
      'shiftBreaks',
      this.additionalShiftBreaks.map((shiftBreak) => {
        if (shiftBreak.id === idToChange) {
          return {
            ...shiftBreak,
            [field]: value,
          };
        }

        return shiftBreak;
      }),
    );
  }

  protected onInput<T extends keyof FormState>(field: T, value: FormState[T], e?: SyntheticEvent) {
    this.$emit('change', createEventPayload(e as SyntheticEvent, { field, value }));
  }

  public render() {
    return (
      <FormSection
        headingSummary={
          this.$t(
            'shifts.dialog.breakSummaryTotal',
            { total: this.formattedTotalBreakTimeInMiliseconds },
          )
        }
        heading={this.$t('shifts.dialog.headingBreaks')}
      >
        <Breaks
          class={styles.sectionBreaksRowFullWidth}
          breaks={this.shiftBreaksWithDates}
          shiftStartsAt={this.shiftStartsAt}
          shiftEndsAt={this.shiftEndsAt}
          isDisabled={this.isDisabled}
          isUnpaidBreakDisabled={this.isUnpaidBreakDisabled}
          isMultiDay={this.isMultiDayShift}
          unpaidBreak={this.unpaidBreak}
          onAddBreakClick={this.onAddBreakClick}
          onDeleteBreakClick={this.onDeleteBreakClick}
          onBreakChange={this.onBreakChange}
          onUnpaidBreakChange={({ payload: { value } }) => this.onInput('unpaidBreak', value)}
        />
      </FormSection>
    );
  }
}

export default SectionBreaks;
