import Brand from 'components/brand/Brand';
import { BrandKind } from 'components/brand/types';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './loader.css';

@Component
export default class Loader extends TsxComponent<{}> {
  public render() {
    return (
      <div class={styles.loader}>
        <Brand class={styles.loaderSvg} kind={BrandKind.ICON} />
      </div>
    );
  }
}
