import Absence from 'components/calendar-common/absences/Absence';
import AbsenceDay from 'components/calendar-common/absences/absence-day/AbsenceDay';
import DateItem from 'components/calendar-common/common/DateItem';
import Observable from 'components/calendar-common/common/observable/Observable';
import PaginationMixin from 'components/calendar-common/common/pagination-mixin/PaginationMixin';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import GridMixin, { GridType } from 'components/calendar-common/grid/GridMixin';
import DayNotesContainer from 'components/calendar-common/notes/day-notes-container/DayNotesContainer';
import SpecialDatesContainer from 'components/calendar-common/notes/special-dates-container/SpecialDatesContainer';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { shiftsEmployeeViewNS, shiftsNS } from 'components/calendar-common/shifts/store/Store';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { Component, Mixins } from 'vue-property-decorator';
import { absencesNS } from 'components/calendar/absences/Store';
import Employment from 'components/calendar-common/employments/Employment';
import DaysShift from 'components/calendar-common/employments/days-shift/DaysShift';
import { ShiftPreset, shiftPresetsNS } from 'src/store/shift-presets/Store';
import ShiftPresetDay from 'components/calendar-common/shift-presets/shift-preset-day/ShiftPresetDay';
import HeaderDays from 'components/calendar/grid/header-days/HeaderDays';
import { calendarEmploymentsNS } from 'components/calendar-common/employments/Store';
import Row from '../row/Row';
import OpenShifts from '../OpenShifts';

@Component({
  directives: {
    observable: Observable,
  },
})
class EmploymentsDays extends Mixins(ShiftViewActionsMixin, GridMixin, PaginationMixin) {
  private openEmploymentIds: number[] = [];

  protected gridType = GridType.WEEK;

  @shiftsNS.Getter('shiftsByEmployments')
  private shifts: Record<number, Record<string, Shift[]>>;

  @shiftsNS.Getter('openShifts')
  private openShifts: Record<string, Shift[]>;

  @shiftsEmployeeViewNS.Getter('shiftsByDates')
  public shiftsByDates: Record<string, Shift[]>;

  @absencesNS.Getter('absencesByEmployments')
  private absences: Record<number, Record<string, Absence[]>>;

  @calendarCommonNS.Getter
  protected dates: DateItem[];

  @calendarEmploymentsNS.Getter('filteredEmployments')
  private employments: Employment[];

  @shiftPresetsNS.Getter('getByIdWithFallback')
  private getShiftPresetById: (id: number) => ShiftPreset;

  protected get paginatedItems() {
    return this.employments;
  }

  private get rowScopedSlots() {
    return {
      shift: ({ shift, employment }) => (<DaysShift
        onDrop={(...args) => this.$emit('drop', ...args)}
        shift={shift}
        employmentId={employment.id}
        key={shift.id}/>),
      absence: ({ absence }) => <AbsenceDay absence={absence} key={absence.uniqId} />,
      rotationShiftPreset: ({ shiftPresetId }) => this.filters.showShiftRotation && <ShiftPresetDay
        shiftPreset={this.getShiftPresetById(shiftPresetId)}/>,
    };
  }

  private onToggleEmployment(employmentId: number) {
    if (this.openEmploymentIds.includes(employmentId)) {
      this.openEmploymentIds = this.openEmploymentIds
        .filter(it => it !== employmentId);
    } else {
      this.openEmploymentIds = [...this.openEmploymentIds, employmentId];
    }
  }

  public render() {
    return (<div
      class={gridStyles.gridTable}
      style={this.gridStyle}>
      <HeaderDays
        shiftsByDates={this.shiftsByDates}
        dates={this.dates}
        onClick={(e, { dateItem }) => this.onShiftCellClick(dateItem)}
      >
        {this.$t('calendar.titleEmployment')}
      </HeaderDays>
      <SpecialDatesContainer showLabelText={true} dates={this.dates}/>
      <DayNotesContainer showLabelText={true} dates={this.dates}/>
      <OpenShifts
        showSummary={this.filters.showSummary}
        dates={this.dates}
        shifts={this.openShifts}
        scopedSlots= {this.rowScopedSlots}/>
      {
        this.visibleItems.map((employment, index) => {
          const isLastRow = (this.employments.length - 1) === index;
          return (<Row
            showSummary={this.filters.showSummary}
            isVisible={this.visibleIds.includes(employment.id)}
            isOpen={this.openEmploymentIds.includes(employment.id)}
            onToggleEmployment={this.onToggleEmployment}
            data-id={employment.id}
            v-observable={this.observer}
            employment={employment}
            shifts={this.shifts[employment.id]}
            absences={this.absences[employment.id]}
            dates={this.dates}
            isLastRow={isLastRow}
            scopedSlots= {this.rowScopedSlots}/>);
        })
      }
      {
        this.renderShowMore()
      }
    </div>);
  }
}

export default EmploymentsDays as any;
