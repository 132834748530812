import TwoColumn from 'layouts/two-column/TwoColumn';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { Mode } from 'components/calendar-common/Enums';
import type { Shiftplan } from 'store/shiftplans/Store';
import { FiltersMap } from 'components/calendar-common/filters/Store';
import { getDefaultShiftplan } from 'src/utils/shift-schedule';
import styles from './shift-schedule.css';
import Header from './header/Header';
import FilterBoxQuick, { QUICK_FILTERS_MAP } from './filter-box-quick/FilterBoxQuick';
import FilterBoxLocationsPosition from './filter-box-locations-position/FilterBoxLocationsPosition';
import type { QuickFilterItem } from './filter-box-quick/types';
import CalendarPrintControls from './calendar-print-controls/CalendarPrintControls';
import CalendarPrintContainer from './calendar-print-container/CalendarPrintContainer';
import FilterBoxAbsenceReason from './filter-box-absence-reason/FilterBoxAbsenceReason';
import { Route } from './routes';

interface Props {
  shiftplan?: Shiftplan;
  shiftplans: Shiftplan[];
  printShiftScheduleQuickFilters: FiltersMap[];
  isShiftplanFiltersSidebarCollapsed: boolean;
}

interface Events {
  onFilterSidebarToggle: void;
}

@Component
export default class PrintShiftSchedule extends TsxComponent<Props, Events> {
  @Prop()
  public printShiftScheduleQuickFilters: Props['printShiftScheduleQuickFilters'];

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public shiftplans: Props['shiftplans'];

  @Prop()
  public isShiftplanFiltersSidebarCollapsed: Props['isShiftplanFiltersSidebarCollapsed'];

  @calendarCommonNS.Getter('mode')
  public mode: Mode;

  protected get isEmployeeView() {
    return this.mode === Mode.EMPLOYEE;
  }

  protected get quickFilterItems(): QuickFilterItem[] {
    return this.printShiftScheduleQuickFilters.map((filterKey) => {
      const filter = QUICK_FILTERS_MAP[filterKey];
      return {
        ...filter,
        id: filterKey,
        name: filterKey,
        label: this.$t(filter.label),
      };
    });
  }

  protected redirectToDefaultShiftplanIfRequired() {
    if (this.shiftplan) {
      return;
    }

    if (!this.shiftplans?.length) {
      this.$router.push({ name: Route.ROOT });
      return;
    }

    const shiftplan = getDefaultShiftplan(this.shiftplans, this.$timeZone.value);

    if (shiftplan) {
      this.$router.push({
        name: Route.SHIFTPLAN,
        params: { shiftplanId: shiftplan.id.toString() },
      });
    }
  }

  public mounted() {
    this.redirectToDefaultShiftplanIfRequired();
  }

  public render() {
    return (
      <div
        class={{
          [styles.shiftSchedule]: true,
          [styles.shiftScheduleEmployee]: this.isEmployeeView,
        }}
      >
        <Header
          selectedShiftplan={this.shiftplan}
          shiftplans={this.shiftplans}
          isApplyRotationActionAllowed={false}
          title={this.$t('shiftSchedule.pageTitle')}
        />

        {
          this.isEmployeeView
            ? (
              <main slot="two" class={styles.shiftScheduleMain}>
                <CalendarPrintControls
                  class={styles.shiftScheduleControls}
                  shiftplan={this.shiftplan}
                  isEmployeeView={this.isEmployeeView}
                  isShiftplanFiltersSidebarCollapsed={this.isShiftplanFiltersSidebarCollapsed}
                />
                <CalendarPrintContainer
                  class={styles.shiftScheduleCalendar}
                  shiftplanId={this.shiftplan?.id} />
              </main>)
            : (
              <TwoColumn class={{
                [styles.shiftScheduleContainer]: true,
                [styles.shiftScheduleContainerSidebarCollapsed]:
                  this.isShiftplanFiltersSidebarCollapsed,
              }}>
                <div slot="one" class={styles.shiftScheduleSidebar}>
                  <FilterBoxQuick items={this.quickFilterItems} />
                  <FilterBoxLocationsPosition />
                  <FilterBoxAbsenceReason />
                </div>

                <main slot="two" class={styles.shiftScheduleMain}>
                  <CalendarPrintControls
                    class={styles.shiftScheduleControls}
                    shiftplan={this.shiftplan}
                    isEmployeeView={this.isEmployeeView}
                    isShiftplanFiltersSidebarCollapsed={this.isShiftplanFiltersSidebarCollapsed}
                    onFilterSidebarToggle={() => this.$emit('filterSidebarToggle')}
                  />
                  <CalendarPrintContainer
                    class={styles.shiftScheduleCalendar}
                    shiftplanId={this.shiftplan?.id} />
                </main>
              </TwoColumn>
            )
        }
        <router-view />
      </div>
    );
  }
}
