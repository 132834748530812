import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import { IconName } from 'components/icons/types';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import styles from './input-radio.css';

interface Props {
  id?: string;
  label: string;
  name: string;
  value?: string;
  isValid?: boolean;
  icon?: IconName;
  description?: string;
}

@Component({
  inheritAttrs: false,
})
export default class InputRadio extends TsxComponent<
Partial<HTMLInputElement> & Props, EventsOn<HTMLInputElement>> {
  @Prop({ default: () => `inp-${getRandomString()}` })
  protected id: Props['id'];

  @Prop()
  public label: Props['label'];

  @Prop()
  public name: Props['name'];

  @Prop()
  public value: Props['value'];

  @Prop({ default: true })
  public isValid: Props['isValid'];

  @Prop()
  public icon: Props['icon'];

  @Prop()
  public description: Props['description'];

  public render() {
    return (
      <div class={{
        [styles.inputRadio]: true,
        [styles.inputRadioDisabled]: !!this.$attrs.disabled,
        [styles.inputRadioInvalid]: !this.isValid,
      }}>
        <div class={styles.inputRadioInputWrapper}>
          <input
            {...{
              attrs: { ...this.$attrs },
              on: { ...this.$listeners },
            }}
            class={styles.inputRadioInput}
            id={this.id}
            name={this.name}
            type="radio"
            value={this.value}
          />

          {(this.label || this.icon || this.description)
          && <label for={this.id} class={styles.inputRadioLabel}>
            {this.icon && <Icon
              class={styles.inputRadioIcon}
              name={this.icon}
              size={Size.MEDIUM} />}
            <div class={styles.inputRadioText} title={this.label}>
              {this.label}
              {this.description && (
                <div class={styles.inputRadioDescription}>{this.description}</div>
              )}
            </div>
          </label>}
        </div>
      </div>
    );
  }
}
