import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import { dayNotesNS } from 'store/day-notes/Store';
import type {
  CreateDayNoteFunction,
  DayNote,
  DeleteDayNoteFunction,
  FetchAllDayNotesFunction,
  UpdateDayNoteFunction,
} from 'store/day-notes/Store';
import { Action } from 'store/normalized-store';
import { EventPayload } from 'utils/events';
import { redirectToParentIf } from 'utils/route';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'utils/store';
import type { GetById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { AlertKind } from 'components/alert/Alert';
import DialogDayNote, { FormState } from './DialogDayNote';

export const getInitialFormState = (): FormState => {
  const usp = new URLSearchParams(window.location.search);

  return ({
    title: '',
    startsAt: new Date(usp.get('startsAt') || Date.now()),
    endsAt: new Date(usp.get('startsAt') || Date.now()),
    description: '',
  });
};

@Component
export default class DialogDayNoteContainer extends TsxComponent<{
  dayNoteId?: number;
  shiftplanId: number;
}, {
  onCloseClick: (e: EventPayload<void, HTMLElement, UIEvent>) => void;
}> {
  protected formState = getInitialFormState();

  protected hasError = false;

  protected isDeleteConfirmDialogOpen = false;

  protected isDeleting = false;

  protected isLoading = false;

  protected isSubmitting = false;

  protected lastStoreActionState = StoreActionState.SUCCESS;

  protected dayNote: DayNote | null = null;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @dayNotesNS.Action(Action.CREATE)
  protected createDayNote: CreateDayNoteFunction;

  @dayNotesNS.Action(Action.DELETE)
  protected deleteDayNote: DeleteDayNoteFunction;

  @dayNotesNS.Action(Action.FETCH_ALL)
  protected fetchAll: FetchAllDayNotesFunction;

  @dayNotesNS.Action(Action.UPDATE)
  protected updateDayNote: UpdateDayNoteFunction;

  @dayNotesNS.Getter
  protected getById: GetById<DayNote>;

  @Prop()
  public dayNoteId?: number;

  @Prop()
  public shiftplanId: number;

  protected get isUpdate() {
    return !!this.dayNoteId;
  }

  public async populateFormState() {
    if (!this.isUpdate || !this.dayNoteId || !this.shiftplanId) {
      return;
    }

    this.dayNote = this.getById(this.dayNoteId) || null;

    if (!this.dayNote) {
      this.isLoading = true;

      // FAQ: Second chance. Refetch every day note and try again.
      await this.fetchAll({ shiftplanId: this.shiftplanId });

      this.isLoading = false;

      this.dayNote = this.getById(this.dayNoteId) || null;
    }

    if (!this.dayNote) {
      this.hasError = true;
      return;
    }

    this.formState = {
      ...this.dayNote,
      startsAt: new Date(this.dayNote.startsAt),
      endsAt: new Date(this.dayNote.endsAt),
      description: this.dayNote.description || '',
    };
  }

  protected onDeleteClick() {
    this.isDeleteConfirmDialogOpen = true;
  }

  protected async onDeleteCancel() {
    this.isDeleteConfirmDialogOpen = false;
  }

  protected async onDeleteConfirm() {
    if (!this.dayNoteId || !this.dayNote) {
      this.isDeleteConfirmDialogOpen = false;
      return false;
    }

    this.isDeleting = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      { id: this.dayNoteId, shiftplanId: this.shiftplanId },
      this.deleteDayNote,
      'dayNotes.modal.error',
    );

    this.isDeleting = false;

    if (response.state !== StoreActionState.SUCCESS) {
      return false;
    }

    this.onCloseClick();
    this.isDeleteConfirmDialogOpen = false;

    return this.showSnackbar({
      message: this.$t('dayNotes.modal.deleted'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit({ event }: EventPayload<void, HTMLElement, UIEvent>) {
    event.preventDefault();

    this.isSubmitting = true;

    const dayNote = {
      description: this.formState.description || null,
      endsAt: this.formState.endsAt,
      startsAt: this.formState.startsAt,
      title: this.formState.title,
    };

    const response = this.isUpdate && this.dayNote
      ? await executeStoreActionWithFailureSnackbar(
        this,
        { id: this.dayNote.id, dayNote, shiftplanId: this.shiftplanId },
        this.updateDayNote,
        'dayNotes.modal.error',
      ) : await executeStoreActionWithFailureSnackbar(
        this,
        { dayNote, shiftplanId: this.shiftplanId },
        this.createDayNote,
        'dayNotes.modal.error',
      );

    this.isSubmitting = false;

    if (response.state !== StoreActionState.SUCCESS) {
      return false;
    }

    this.onCloseClick();

    return this.showSnackbar({
      message: this.isUpdate ? this.$t('dayNotes.modal.updated') : this.$t('dayNotes.modal.created'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onCloseClick() {
    redirectToParentIf(() => true)(this);
  }

  public mounted() {
    this.populateFormState();
  }

  public render() {
    if (this.isDeleteConfirmDialogOpen) {
      return (
        <DialogConfirmDelete
          isOpen={true}
          isSubmitting={this.isDeleting}
          onCancel={this.onDeleteCancel}
          onConfirm={this.onDeleteConfirm}
          title={this.$t('dayNotes.modal.titleDelete')}
        >
          {this.$t('dayNotes.modal.messageDelete')}
        </DialogConfirmDelete>
      );
    }

    return (
      <DialogDayNote
        formState={this.formState}
        hasError={this.hasError}
        isOpen={true}
        isLoading={this.isLoading}
        isSubmitting={this.isSubmitting}
        isUpdate={this.isUpdate}
        onCloseClick={this.onCloseClick}
        onDeleteClick={this.onDeleteClick}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
      />
    );
  }
}
