import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { Route as EmploymentsRoute } from 'components/employments/routes';
import { employmentsNS } from 'components/employments/store/Store';
import type { Employment } from 'components/employments/types';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import Spinner from 'components/spinner/Spinner';
import { SentryTag } from 'services/logger/SentryTransport';
import { Action as StoreAction } from 'store/normalized-store';
import { shiftPresetsNS } from 'store/shift-presets/Store';
import type { FetchAllShiftPresetsFunction } from 'store/shift-presets/Store';
import { DAYS_IN_WEEK } from 'utils/date-related';
import type { GetById, GQLResponseExtension } from 'src/utils/store';
import { getFirstErrorMessageFromResponse } from 'src/utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Size } from 'components/types';
import ActionButtonWrapper from '../action-button-wrapper/ActionButtonWrapper';
import { Route } from '../routes';
import WeeksGrid from '../shift-group-setup/weeks-grid/WeeksGrid';
import StepWrapper, { Slot as StepWrapperSlot } from '../step-wrapper/StepWrapper';
import Action from '../store/Action';
import { rotationWizardNS, StoreState as RotationWizardStoreState } from '../store/Store';
import {
  CreateRotationResponse,
  RotationDayPreset,
  RotationGroup,
} from '../store/types';

import styles from './rotation-pattern-setup.css';

@Component
export default class RotationPatternSetupIndividual extends TsxComponent<{}> {
  protected employment: Employment | null = null;

  protected isSubmitting = false;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @employmentsNS.Getter
  protected getById: GetById<Employment>;

  @rotationWizardNS.State
  protected rotationGroupsLength: RotationWizardStoreState['rotationGroupsLength'];

  @rotationWizardNS.State
  protected rotationInterval: RotationWizardStoreState['rotationInterval'];

  @rotationWizardNS.State
  protected rotationGroups: RotationGroup[];

  @rotationWizardNS.State
  protected anchorDate: RotationWizardStoreState['anchorDate'];

  @shiftPresetsNS.Action(StoreAction.FETCH_ALL)
  protected fetchShiftPresets: FetchAllShiftPresetsFunction;

  @rotationWizardNS.Action(Action.UPDATE_ROTATION_DAY)
  protected updateRotationDay: ({
    dayIndex,
    groupIndex,
    preset,
  }: {
    dayIndex: number;
    groupIndex: number;
    preset: RotationDayPreset;
  }) => void;

  @rotationWizardNS.Action(Action.CREATE_INDIVIDUAL_ROTATION)
  protected createIndividualRotation: (
    payload: { employmentId: number },
  ) => Promise<CreateRotationResponse>;

  @rotationWizardNS.Action(Action.RESET)
  protected resetStore: () => void;

  @rotationWizardNS.Getter
  protected isRotationPatternSetupValid: boolean;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public employmentId: number;

  private get weeksLength() {
    return Math.ceil(this.rotationInterval / DAYS_IN_WEEK);
  }

  private onPresetSelect(payload: {
    preset: RotationDayPreset;
    groupIndex: number;
    dayIndex: number;
  }) {
    this.updateRotationDay(payload);
  }

  protected getErrorMessage(errorResponse: GQLResponseExtension | undefined) {
    if (errorResponse?.status === 403) {
      return this.$t('rotationWizard.error.forbidden');
    }

    if (errorResponse?.status === 404) {
      return this.$t('rotationWizard.error.individualShiftRotation.notFound');
    }

    return getFirstErrorMessageFromResponse(
      errorResponse,
      this.$t.bind(this),
      'rotationWizard.error.individualShiftRotation',
    ) || this.$t('general.error.unknown');
  }

  private async create(): Promise<false | number> {
    if (!this.employment) {
      return false;
    }

    const response = await this.createIndividualRotation({ employmentId: this.employment.id });

    if (typeof response !== 'number') {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, RotationPatternSetupIndividual.name]],
        message: JSON.stringify(response),
      });

      this.isSubmitting = false;

      const message = this.getErrorMessage(response);

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });
      return false;
    }

    const rotationId = response;

    this.isSubmitting = false;

    this.showSnackbar({
      kind: AlertKind.SUCCESS,
      message: this.$t('rotationWizard.saved'),
      timeout: 5000,
    });
    return rotationId;
  }

  private async onNextStepClick() {
    this.isSubmitting = true;

    const requestResult = await this.create();

    // in case of error redirect to first page
    if (!requestResult) {
      return this.$router.push({
        name: Route.INDIVIDUAL_STEP_1,
        params: { id: this.employment?.id.toString() || '0' },
      });
    }

    this.resetStore();

    return this.$router.push({
      name: EmploymentsRoute.ROTATION_GROUPS,
      params: { id: this.employment?.id.toString() || '0' },
    });
  }

  public mounted() {
    this.employment = this.getById(this.employmentId) || null;

    if (!this.employment || this.rotationGroups.length === 0) {
      this.$router.replace({ name: Route.ROOT });
      return;
    }

    this.fetchShiftPresets({});
  }

  public render() {
    return (
      <StepWrapper heading={this.$t('rotationWizard.rotationPatternSetup.heading')}>
        <div class={[styles.rotationPatternSetupContent, styles.rotationPatternSetupContentCenter]}>
          <WeeksGrid
            class={styles.rotationPatternSetupWeeksGrid}
            days={this.rotationGroups[0].days}
            firstDay={new Date(this.anchorDate)}
            onPresetSelect={(e, dayIndex, preset) => (
              this.onPresetSelect({ groupIndex: 0, dayIndex, preset })
            )}
            weeksLength={this.weeksLength}
          />
        </div>

        <ActionButtonWrapper slot={StepWrapperSlot.FOOTER}>
          <Button
            color={ButtonColor.SECONDARY}
            kind={ButtonKind.FILL}
            size={Size.LARGE}
            onClick={this.onNextStepClick}
            type="button"
            disabled={!this.isRotationPatternSetupValid || this.isSubmitting}
          >
            {this.isSubmitting
              ? <Spinner />
              : this.$t('rotationWizard.rotationPatternSetup.buttonFinishWizard')
            }
          </Button>
        </ActionButtonWrapper>
      </StepWrapper>
    );
  }
}
