enum Action {
  APPROVE = 'approve',
  CREATE = 'create',
  DECLINE = 'decline',
  DELETE = 'delete',
  FETCH = 'fetch',
  GET_ABSENCE_DURATION = 'getAbsenceDuration',
  UPDATE = 'update',
  UPLOAD_ATTACHMENT = 'uploadAttachment',
}

export default Action;
