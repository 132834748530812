import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Screen from './Screen';

@Component
export default class ScreenNotFound extends TsxComponent<{}> {
  public render() {
    return (
      <Screen
        visual="🙈"
        title={this.$t('screens.notFound.title')}
        message={this.$t('screens.notFound.message')}
      />
    );
  }
}
