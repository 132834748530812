import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Qualification } from 'src/store/qualifications/Store';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { ShiftQualificationInput } from '../types';
import AdditionalQualification from './additional-qualification/AdditionalQualification';
import styles from './qualifications.css';

export interface QualificationsSelectItem extends Qualification {
  isDisabled?: boolean
}

export interface Props {
  selectedQualifications: ShiftQualificationInput[];
  selectableQualifications: QualificationsSelectItem[];
  workersCount: number;
  isDisabled: boolean | undefined;
}

interface Events {
  onAddQualificationClick: (
    payload: EventPayload<void, HTMLElement, UIEvent>
  ) => void;
  onDeleteQualificationClick: (
    payload: OnDeleteQualificationClickPayload
  ) => void;
  onQualificationChange: (payload: onQualificationChange) => void;
}

export type OnDeleteQualificationClickPayload = EventPayload<
{ id: number },
HTMLElement,
UIEvent
>;

export type onQualificationChange = EventPayload<
{
  id: number;
  value: string;
  field: string;
},
HTMLElement,
UIEvent
>;

@Component
class Qualifications extends TSXComponent<Props, Events> {
  @Prop()
  public selectedQualifications: Props['selectedQualifications'];

  @Prop()
  public selectableQualifications: Props['selectableQualifications'];

  @Prop()
  public workersCount: Props['workersCount'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  protected get canShowAddbutton() : boolean {
    return ((this.selectedQualifications?.length
    !== this.selectableQualifications?.length) && !this.isDisabled);
  }

  public render() {
    return (
      <div class={styles.qualifications}>
        {this.selectedQualifications?.map(item => (
          <AdditionalQualification
            qualification={item}
            isDisabled={this.isDisabled}
            selectableQualifications={this.selectableQualifications}
            onDeleteQualificationClick={e => this.$emit('deleteQualificationClick', e)
            }
            onChange={e => this.$emit('qualificationChange', e)}
            workersCount={this.workersCount}
          />
        ))}

        { this.canShowAddbutton && (
          <Button
            type="button"
            class={styles.qualificationsCenteredButton}
            color={ButtonColor.SUCCESS}
            disabled={this.isDisabled}
            onClick={e => this.$emit(
              'addQualificationClick',
              createEventPayload(e, undefined),
            )
            }
            size={Size.SMALL}
            kind={ButtonKind.STROKE}
          >
            {this.$t('shifts.qualifications.buttonAddQualification')}
          </Button>
        )}
      </div>
    );
  }
}

export default Qualifications;
