import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';

@Component
export default class CellDuration extends TsxComponent<{
  number: number;
}> {
  @Prop()
  public number: number;

  protected get formatNumber() {
    return new Intl.NumberFormat(
      this.$i18n.i18next.language,
      {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      },
    ).format;
  }

  public render() {
    return (
      <td class={tableStyles.tableBodyCell}>
        {
          this.formatNumber(this.number)
        }
      </td>
    );
  }
}
