import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import Brand from 'components/brand/Brand';
import Icon from 'components/icons/Icon';
import GlobalSnackbarContainer from 'components/snackbar/GlobalSnackbarContainer';
import { Size } from 'components/types';
import TwoColumn from 'layouts/two-column/TwoColumn';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'src/utils/url';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import Navigation from './navigation/Navigation';
import styles from './rotation-wizard.css';

interface Props {
  employmentId?: number;
  isEdit?: boolean;
}

@Component
export default class RotationWizard extends TsxComponent<Props> {
  @Prop({ default: false })
  public isEdit: boolean;

  @Prop()
  public employmentId?: number;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  protected get isIndividualRotation() {
    return this.employmentId !== undefined;
  }

  public render() {
    return (
      <TwoColumn>
        <div slot="one" class={styles.rotationWizardLeft}>
          <Brand class={styles.rotationWizardBrand} />

          <Navigation
            isEdit={this.isEdit}
            isIndividualRotation={this.isIndividualRotation}
          />

          <a
            href={getUrlWithApiPrefix(getUrlWithCompanyPrefix('', this.currentCompany))}
            class={styles.rotationWizardBack}
          >
            <Icon
              name={IconName.ARROW_BACK}
              size={Size.SMALL}
              class={styles.rotationWizardBackIcon}
            />
            {this.$t('rotationWizard.steps.backToCompanyProfile')}
          </a>

          <GlobalSnackbarContainer />
        </div>

        <main slot="two" class={styles.rotationWizardMain}>
          <router-view isEdit={this.isEdit} />
        </main>
      </TwoColumn>
    );
  }
}
