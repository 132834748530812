import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Dialog, { Slot } from 'components/dialog/Dialog';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { AssignmentGroupUnassignmentKind } from '../ColumnAssigned';
import styles from '../column-assigned.css';

interface Props {
  isDialogOpen: boolean;
}

interface Events {
  onCloseClick: (e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>) => void;
  onUnassignAssignmentGroups: (type: AssignmentGroupUnassignmentKind) => void;
}

@Component
export default class DialogAssignmentGroupUnassign extends TsxComponent<Props, Events> {
  @Prop()
  public isDialogOpen: Props['isDialogOpen'];

  public render() {
    return (
      <Dialog
        isOpen={this.isDialogOpen}
        onCloseClick={e => this.$emit('closeClick', e)}
        title={this.$t('shifts.dialog.headingAssignmentGroupUnassignConfirm')}
      >
        <div class={styles.columnAssignedAssignmentGroupUnassignDialogBody}>
          {this.$t('shifts.dialog.labelAssignmentGroupUnassignExplanation')}
        </div>

        <Button
          color={ButtonColor.SECONDARY}
          size={Size.SMALL}
          type="button"
          kind={ButtonKind.GHOST}
          slot={Slot.BUTTONS_RIGHT}
          onClick={() => this.$emit(
            'unassignAssignmentGroups',
            AssignmentGroupUnassignmentKind.INDIVIDUAL,
          )}
          aria-label={this.$t('shifts.dialog.labelAssignmentGroupUnassignThisButton')}
        >
          {this.$t('shifts.dialog.labelAssignmentGroupUnassignThisButton')}
        </Button>

        <Button
          color={ButtonColor.SECONDARY}
          size={Size.SMALL}
          type="button"
          kind={ButtonKind.GHOST}
          slot={Slot.BUTTONS_RIGHT}
          onClick={() => this.$emit(
            'unassignAssignmentGroups',
            AssignmentGroupUnassignmentKind.FUTURE,
          )}
          aria-label={this.$t('shifts.dialog.labelAssignmentGroupUnassignFutureButton')}
        >
          {this.$t('shifts.dialog.labelAssignmentGroupUnassignFutureButton')}
        </Button>

        <Button
          color={ButtonColor.SECONDARY}
          size={Size.SMALL}
          type="button"
          kind={ButtonKind.GHOST}
          slot={Slot.BUTTONS_RIGHT}
          onClick={() => this.$emit(
            'unassignAssignmentGroups',
            AssignmentGroupUnassignmentKind.ALL,
          )}
          aria-label={this.$t('shifts.dialog.labelAssignmentGroupUnassignAllButton')}
        >
          {this.$t('shifts.dialog.labelAssignmentGroupUnassignAllButton')}
        </Button>
      </Dialog>
    );
  }
}
