import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './notification-badge.css';

export interface Props {
  disabled?: boolean;
}

@Component
export default class NotificationBadge extends TsxComponent<Props> {
  @Prop()
  public disabled: Props['disabled'];

  public render() {
    return (
      <span class={[{
        [styles.notificationBadge]: true,
        [styles.notificationBadgeDisabled]: this.disabled,
      }]}>{this.$slots.default}</span>
    );
  }
}
