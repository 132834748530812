import root from './root.css';
import colors from './colors.css';
import typography from './typography.css';
import shadowns from './shadows.css';
import spacing from './spacing.css';

export default {
  ...root,
  ...colors,
  ...typography,
  ...shadowns,
  ...spacing,
};
