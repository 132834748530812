/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  Emit,
  Prop,
} from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import Icon from 'components/icons/Icon';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { IconPosition } from 'components/form/button/types';
import Card from 'components/card/Card';
import { IconName } from 'components/icons/types';
import styles from './rotation-group-card.css';
import type { RotationGroup } from '../store/types';
import RotationGroupPreview from '../rotation-group-preview/RotationGroupPreview';

@Component
export default class RotationGroupCard extends TsxComponent<{
  anchorDate: Date;
  group: RotationGroup;
  groupIndex: number;
  isAddSelectedShown?: boolean;
  isFilterForGroupActive?: boolean;
  isUnassignSelectedShown?: boolean;
  usersLength?: number;
}, {
  onAddSelectedClick: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
  onFilterClick: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
}> {
  public $refs: {
    card: Vue;
    preview: RotationGroupPreview;
  };

  private isRotationPatternPreviewOpen = false;

  private previewPosition = { x: 0, y: 0 };

  @Emit('addSelectedClick')
  protected onAddSelectedClick() { }

  @Emit('filterClick')
  protected onFilterClick() { }

  @Prop()
  public anchorDate: Date;

  @Prop()
  public group: RotationGroup;

  @Prop()
  public groupIndex: number;

  @Prop()
  public isAddSelectedShown?: boolean;

  @Prop()
  public isFilterForGroupActive?: boolean;

  @Prop()
  public isUnassignSelectedShown?: boolean;

  @Prop()
  public usersLength?: number;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  protected async onPreviewToggle(e: SyntheticEvent<HTMLButtonElement, Event>, state: boolean) {
    this.isRotationPatternPreviewOpen = state;

    if (state) {
      // wait for RotationGroupPreview to be rendered
      await this.$nextTick();

      this.setPreviewPosition();
    }
  }

  protected setPreviewPosition() {
    const {
      left: leftCard,
      top: topCard,
      width: widthCard,
    } = this.$refs.card.$el.getBoundingClientRect();

    const { height: heightPreview } = this.$refs.preview.$el.getBoundingClientRect();

    this.previewPosition.x = leftCard + widthCard;
    this.previewPosition.y = topCard + heightPreview < window.innerHeight
      ? topCard
      : window.innerHeight - heightPreview;
  }

  public render() {
    return (
      <Card class={styles.rotationGroupCard} ref="card">
        <header class={styles.rotationGroupCardHeader}>
          <h4 class={styles.rotationGroupCardTitle}>
            {this.group.name}
          </h4>

          <div class={styles.rotationGroupCardActions}>
            <Button
              class={styles.rotationGroupCardIconButton}
              color={this.isFilterForGroupActive ? ButtonColor.SUCCESS : ButtonColor.SECONDARY}
              icon={IconName.FILTER}
              iconPosition={IconPosition.ALONE}
              onClick={this.onFilterClick}
              size={Size.SMALL}
              kind={ButtonKind.GHOST}
            />

            <Button
              class={styles.rotationGroupCardIconButton}
              color={ButtonColor.SECONDARY}
              icon={IconName.EYE_ON}
              iconPosition={IconPosition.ALONE}
              size={Size.SMALL}
              kind={ButtonKind.GHOST}
              {...{
                nativeOn: {
                  mouseenter: (e) => { this.onPreviewToggle(e, true); },
                  mouseleave: (e) => { this.onPreviewToggle(e, false); },
                  wheel: (e) => { this.onPreviewToggle(e, false); },
                },
              }}
            />
          </div>
        </header>

        <div class={styles.rotationGroupCardMain}>
          <div class={styles.rotationGroupCardUsersNumber}>
            <Icon name={IconName.PERSONAL} class={styles.rotationGroupCardUserIcon} />
            {this.usersLength || 0}
          </div>

          <Button
            class={{
              [styles.rotationGroupCardButton]: true,
              [styles.rotationGroupCardButtonHidden]: (
                !this.isUnassignSelectedShown && !this.isAddSelectedShown
              ),
            }}
            disabled={!this.isAddSelectedShown || this.isUnassignSelectedShown}
            onClick={this.onAddSelectedClick}
            size={Size.SMALL}
          >
            {this.$t('rotationWizard.employmentAssignment.buttonAddSelected')}
          </Button>
        </div>

        {this.isRotationPatternPreviewOpen && (
          <RotationGroupPreview
            group={this.group}
            anchorDate={this.anchorDate}
            style={{
              left: `${this.previewPosition.x}px`,
              top: `${this.previewPosition.y}px`,
            }}
            ref="preview"
          />
        )}
      </Card>
    );
  }
}
