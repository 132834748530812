import DatepickerInterval, { IntervalSelectionMode } from 'components/datepicker/DatepickerInterval';
import { SelectedTimeframe } from 'components/datepicker/types';
import FilterBox, { Slot as FilterBoxSlot } from 'components/filter-box/FilterBox';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import { toLocaleDateString } from 'src/utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';

import styles from './filter-timeframe.css';

export interface Props {
  startsAt: Date;
  endsAt: Date;
}

interface Events {
  onChange: (payload: SelectedTimeframe) => void;
}

@Component
class FilterTimeframe extends TSXComponent<Props, Events> {
  public $refs: {
    filterBox: Vue;
  };

  private isOpened = false;

  @Prop()
  public startsAt: Props['startsAt'];

  @Prop()
  public endsAt: Props['endsAt'];

  private onChange(payload) {
    this.$emit('change', payload);
  }

  public render() {
    return (
      <FilterBox
        ref="filterBox"
        class={styles.filterTimeframe}
      >
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('availabilityAggregations.filterTimeframe.header')}
        </template>

        <button
          class={[
            styles.filterTimeframeToggle,
            this.isOpened && styles.filterTimeframeToggleIsOpened,
          ]}
          type="button"
          onClick={() => { this.isOpened = !this.isOpened; }}
        >
          {
            toLocaleDateString(
              new Date(this.startsAt),
              this.$i18n.i18next.language,
              this.$timeZone.value,
            )
          } - {
            toLocaleDateString(
              new Date(this.endsAt),
              this.$i18n.i18next.language,
              this.$timeZone.value,
            )
          }
        </button>

        {this.isOpened && (
          <OutsideClickHandler
            insideRef={this.$refs.filterBox.$el as HTMLElement}
            onOutsideClick={() => { this.isOpened = false; }}
          />
        )}

        <transition name="filter-timeframe__datepicker-transition">
          {this.isOpened && (
            <DatepickerInterval
              class={styles.filterTimeframeDatepicker}
              selection={{ startsAt: this.startsAt, endsAt: this.endsAt }}
              maxIntervalDuration={35}
              intervalSelectionMode={IntervalSelectionMode.CUSTOM}
              onChange={({ payload }) => {
                this.isOpened = false;
                this.onChange(payload);
              }}
            />
          )}
        </transition>
      </FilterBox>
    );
  }
}

export default FilterTimeframe;
