import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { getDurationStringInTimeZone, toLocaleDateString } from 'utils/date-related';
import { filterFalsy } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './collisions.css';

@Component
export default class AbsencesCollision extends TsxComponent<{
  conflict: GQLShiftConflictsFragmentFragment;
}> {
  @Prop()
  public conflict: GQLShiftConflictsFragmentFragment;

  public render() {
    if (this.conflict.__typename !== 'AbsencesCollisionShiftConflict' || !this.conflict.absences) {
      return null;
    }

    return (
      <span>
        <ul class={styles.collisions}>
          {
            this.conflict.absences
              .filter(filterFalsy)
              .map(absence => (
                <li>
                  {
                    absence.isFullDay
                      ? `${toLocaleDateString(
                        new Date(absence.startsAt),
                        this.$i18n.i18next.language,
                        this.$timeZone.value,
                      )} (${this.$t('conflicts.absenceCollisions.fullDay')})`
                      : getDurationStringInTimeZone(
                        this.$timeZone.value,
                        this.$i18n.i18next.language,
                        new Date(absence.startsAt),
                        new Date(absence.endsAt),
                        true,
                      )
                  }
                </li>
              ))
          }
        </ul>
      </span>
    );
  }
}
