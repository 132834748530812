import { Slot } from 'components/dialog/Dialog';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import FormDialog from 'components/form/form-dialog/FormDialog';
import InputText from 'components/form/input-text/InputText';
import InputToggle from 'components/form/input-toggle/InputToggle';
import { Size } from 'components/types';
import { isValid, parseISO } from 'date-fns';
import { isStartBeforeEnd } from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import { getRandomString } from 'utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';

// required to make browser form validation work
const FORM_ID = `dialog-${getRandomString()}`;

export interface FormState {
  startsAt: string;
  endsAt: string;
  name: string;
  locked: boolean;
}

export interface Props {
  formState: FormState;
  hasError?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  isSubmitting?: boolean;
  isUpdate?: boolean;
  isLockShiftplanVisible: boolean;
  isShiftScheduleLocked: boolean;
}

interface Events {
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onDeleteClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] }>,
  ) => void;
  onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}

@Component
export default class DialogShiftplan extends TsxComponent<Props, Events> {
  @Prop()
  public formState: Props['formState'];

  @Prop()
  public hasError?: Props['hasError'];

  @Prop()
  public isLoading?: Props['isLoading'];

  @Prop()
  public isOpen?: Props['isOpen'];

  @Prop()
  public isSubmitting?: Props['isSubmitting'];

  @Prop()
  public isUpdate?: Props['isUpdate'];

  @Prop()
  public isLockShiftplanVisible: Props['isLockShiftplanVisible'];

  @Prop()
  public isShiftScheduleLocked: Props['isShiftScheduleLocked'];

  protected get isStartsAtBeforeEndsAt() {
    if (!this.formState.startsAt || !this.formState.endsAt) {
      return true;
    }

    return isStartBeforeEnd(new Date(this.formState.startsAt), new Date(this.formState.endsAt));
  }

  protected get isNameValid() {
    return !!this.formState.name && this.formState.name.trim().length > 0;
  }

  protected get isStartsAtValid() {
    return isValid(parseISO(this.formState.startsAt));
  }

  protected get isEndsAtValid() {
    return isValid(parseISO(this.formState.endsAt));
  }

  protected get isFormStateValid() {
    return !this.isSubmitting
      && this.isNameValid
      && this.isStartsAtValid
      && this.isEndsAtValid
      && this.isStartsAtBeforeEndsAt;
  }

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    if (!this.isSubmitting) {
      this.$emit('closeClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
    }
  }

  protected onDeleteClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    if (!this.isSubmitting) {
      this.$emit('deleteClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
    }
  }

  protected onInput(e: SyntheticEvent<HTMLInputElement, Event>) {
    this.$emit('input', createEventPayload(e, { field: e.target.name, value: e.target.value }));
  }

  protected onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    this.$emit('submit', createEventPayload<void>(e, undefined));
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        error={this.hasError}
        isLoading={this.isLoading}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        title={
          this.isUpdate
            ? this.$t('shiftplans.modal.titleUpdate')
            : this.$t('shiftplans.modal.titleCreate')
        }
      >
        <FormDialog id={FORM_ID} onSubmit={this.onSubmit}>
          <InputText
            error={
              this.isStartsAtBeforeEndsAt
                ? undefined
                : this.$t('rotationWizard.error.individualShiftRotation.endsAt.greaterThanStartsAt')
            }
            id="startsAt"
            isValid={this.isStartsAtValid}
            label={this.$t('shiftplans.modal.labelStartsAt')}
            name="startsAt"
            onInput={this.onInput}
            // HACK: Require specific date format for Safari which doesn't support input type="date"
            // we can only safely parse ISO date in every browser
            pattern="[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}"
            placeholder={this.$t('rotationWizard.generalSetup.inputDatePlaceholder')}
            required={true}
            type="date"
            value={this.formState.startsAt.substr(0, 10)}
            disabled={this.isShiftScheduleLocked}
          />

          <InputText
            id="endsAt"
            isValid={this.isEndsAtValid}
            label={this.$t('shiftplans.modal.labelEndsAt')}
            name="endsAt"
            onInput={this.onInput}
            // HACK: Require specific date format for Safari which doesn't support input type="date"
            // we can only safely parse ISO date in every browser
            pattern="[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}"
            placeholder={this.$t('rotationWizard.generalSetup.inputDatePlaceholder')}
            required={true}
            type="date"
            value={this.formState.endsAt.substr(0, 10)}
            disabled={this.isShiftScheduleLocked}
          />

          <InputText
            label={this.$t('shiftplans.modal.labelName')}
            name="name"
            onInput={this.onInput}
            required={true}
            type="text"
            value={this.formState.name}
            disabled={this.isShiftScheduleLocked}
          />

          {
            this.isLockShiftplanVisible
            && (
              <InputToggle
                checked={this.formState.locked}
                label={this.$t('shiftplans.modal.labelLockShiftSchedule')}
                name="locked"
                onChange={(e) => {
                  this.$emit('input', createEventPayload(e, { field: 'locked', value: e.target.checked }));
                }}
                value={'locked'}
              />
            )
          }

        </FormDialog>

        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isSubmitting}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.GHOST}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        {
          !this.hasError && this.isUpdate && !this.isLoading && (
            <Button
              color={ButtonColor.ERROR}
              disabled={this.isSubmitting || this.isLoading}
              size={Size.SMALL}
              slot={Slot.BUTTONS_LEFT}
              type="button"
              kind={ButtonKind.GHOST}
              onClick={this.onDeleteClick}
            >
              {this.$t('shiftplans.modal.buttonDelete')}
            </Button>
          )
        }

        {
          !this.hasError && (
            <Button
              disabled={this.isSubmitting || !this.isFormStateValid || this.isLoading}
              form={FORM_ID}
              size={Size.SMALL}
              slot={Slot.BUTTONS_RIGHT}
              type="submit"
            >
              {
                this.isUpdate
                  ? this.$t('shiftplans.modal.buttonUpdate')
                  : this.$t('shiftplans.modal.buttonCreate')
              }
            </Button>
          )
        }
      </DialogWithSpinnerAndError>
    );
  }
}
