import SpIcon from 'components/sp-icon/SpIcon';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import styles from './button-toolbar.css';

export enum ButtonColor {
  OUTER_SPACE = 'outer-space',
  SHYFTPLAN_GREEN = 'shyftplan-green',
}

@Component
export default class ButtonToolbar extends TsxComponent<Partial<Omit<HTMLButtonElement, 'form'>> & {
  icon: string;
  color: ButtonColor;
  label?: string;
}, EventsOn<HTMLButtonElement>> {
  @Prop()
  public icon: string;

  @Prop()
  public type: string;

  @Prop()
  public label: string;

  @Prop()
  public color: ButtonColor;

  public render() {
    return (<button
      {...{
        attrs: this.$attrs,
        on: { ...this.$listeners },
      }}
      type={this.type}
      class={{
        [styles.buttonToolbar]: true,
        [styles.buttonToolbarOuterSpace]: this.color === ButtonColor.OUTER_SPACE,
        [styles.buttonToolbarShyftplanGreen]: this.color === ButtonColor.SHYFTPLAN_GREEN,
      }}
    >
      <SpIcon name={this.icon} label={this.label} class={styles.buttonToolbarIcon}/>
    </button>);
  }
}
