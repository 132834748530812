import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLPaygradeTypesQuery,
  GQLPaygradeTypesQueryVariables,
  GQLPaygradeTypeFragmentFragment,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import PaygradeTypesGql from './queries/PaygradeTypes.gql';

export const paygradeTypesNS = namespace('paygradeTypes');

export type StoreState = ById<PaygradeType>;

export type PaygradeType = GQLPaygradeTypeFragmentFragment;

export type FetchAllPaygradeTypesFunction = (
  payload: Omit<GQLPaygradeTypesQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getPaygradeTypesStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      bonuses: (state: StoreState, { items }) => (
        items.filter(({ payType }) => payType.toLowerCase().startsWith('bonus'))
      ),
      regular: (state: StoreState, { items }) => (
        items.filter(({ payType }) => !payType.toLowerCase().startsWith('bonus'))
      ),
    },
  };

  const fetchAll: ActionProvider<GQLPaygradeTypesQuery, GQLPaygradeTypesQueryVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllPaygradeTypesFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: PaygradeTypesGql,
      resultKey: 'paygradeTypes',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  return createNormalizedStore<PaygradeType, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getPaygradeTypesStore;
