import { isWithinInterval } from 'date-fns';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateDayNoteMutation,
  GQLCreateDayNoteMutationVariables,
  GQLRemoveDayNoteMutation,
  GQLRemoveDayNoteMutationVariables,
  GQLDayNotesQuery,
  GQLDayNotesQueryVariables,
  GQLUpdateDayNoteMutation,
  GQLUpdateDayNoteMutationVariables,
  GQLDayNoteCreateInput,
  GQLDayNoteFragmentFragment,
} from 'codegen/gql-types';
import { HasAnyLocationRightFunction, HasAnyRightFunction } from 'components/auth/store/Store';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import RootStoreState from 'store/RootStoreState';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateDayNoteGql from './queries/CreateDayNote.gql';
import DayNotesGql from './queries/DayNotes.gql';
import RemoveDayNoteGql from './queries/RemoveDayNote.gql';
import UpdateDayNoteGql from './queries/UpdateDayNote.gql';

export const dayNotesNS = namespace('dayNotes');

export type StoreState = ById<DayNote>;

export type DayNote = GQLDayNoteFragmentFragment;

export type CreateDayNoteFunction = (
  payload: {
    dayNote: Omit<GQLDayNoteCreateInput, 'companyId' | 'endsAt' | 'startsAt'> & { endsAt: Date; startsAt: Date };
    shiftplanId: number;
  },
) => Promise<StoreActionResult>;

export type DeleteDayNoteFunction = (
  payload: Omit<GQLRemoveDayNoteMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchAllDayNotesFunction = (
  payload: Omit<GQLDayNotesQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateDayNoteFunction = (payload: {
  id: number;
  dayNote: Omit<GQLDayNoteCreateInput, 'companyId' | 'endsAt' | 'startsAt'> & { endsAt: Date; startsAt: Date };
  shiftplanId: number;
}) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const groupByDate = (dateKeys: string[], items: DayNote[]) => (
  dateKeys.reduce((prev, cur) => {
    prev[cur] = items.filter(it => (
      isWithinInterval(new Date(cur), { start: new Date(it.startsAt), end: new Date(it.endsAt) })
    ));

    return prev;
  }, {})
);

const getDayNotesStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
    getters: {
      byDate(state, getters, rootState, rootGetters) {
        return groupByDate(rootGetters['calendar/common/dateKeys'], getters.items);
      },
      canManage(state, getters, rootState, rootGetters) {
        const hasAnyRight: HasAnyRightFunction = rootGetters['auth/hasAnyRight'];
        const hasAnyLocationRight: HasAnyLocationRightFunction = rootGetters['auth/hasAnyLocationRight'];

        const { currentLocationId } = rootState.auth;

        return hasAnyRight('payments_manage_all', 'shifts_manage_all')
          || hasAnyLocationRight(currentLocationId, 'payment_manage_right', 'shift_manage_right');
      },
    },
  });

  const create: ActionProvider<GQLCreateDayNoteMutation, GQLCreateDayNoteMutationVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateDayNoteFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = deepTransformDates({
      ...payload,
      companyId: rootState.auth.currentCompanyId,
    });

    return ({
      query: CreateDayNoteGql,
      resultKey: 'createDayNote',
      variables,
    });
  };

  const remove: ActionProvider<GQLRemoveDayNoteMutation, GQLRemoveDayNoteMutationVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteDayNoteFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: RemoveDayNoteGql,
      resultKey: 'removeDayNote',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  const fetchAll: ActionProvider<GQLDayNotesQuery, GQLDayNotesQueryVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllDayNotesFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: DayNotesGql,
      resultKey: 'dayNotes',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  const update: ActionProvider<GQLUpdateDayNoteMutation, GQLUpdateDayNoteMutationVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateDayNoteFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = deepTransformDates({
      ...payload,
      companyId: rootState.auth.currentCompanyId,
    });

    return ({
      query: UpdateDayNoteGql,
      resultKey: 'updateDayNote',
      variables,
    });
  };

  return createNormalizedStore<DayNote, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.FETCH_ALL]: fetchAll,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getDayNotesStore;
