import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import Loader from 'components/loader/Loader';
import Action from 'components/table/store/Action';
import { EventPayload } from 'utils/events';
import type { SetSelectionFunction } from 'store/filter-store';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type { FetchAllLocationsPositionsFunction, LocationsPosition } from 'store/locations-positions/Store';
import { Action as StoreAction } from 'store/normalized-store';
import type { GetMultipleById } from 'utils/store';
import { filterFalsy, sortBySortOrder } from 'utils/utils';
import { StoreState as FilterStoreState, locationsPositionsFilterNS } from './store/Store';
import CheckboxListWithPagingAndFiltering from '../checkbox-list-with-paging-and-filtering/CheckboxListWithPagingAndFiltering';
import FilterBox, { Slot as FilterBoxSlot } from '../filter-box/FilterBox';
import CheckboxListItem from '../filter-box/checkbox-list/item/Item';
import { FILTER_PAGE_SIZE, FILTER_THROTTLE_TIMEOUT } from '../constants';

@Component
export default class FilterBoxLocationsPosition extends TsxComponent<{}> {
  protected isLoading = false;

  protected isOpen = false;

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @locationsPositionsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAll: FetchAllLocationsPositionsFunction;

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;

  @locationsPositionsFilterNS.Action(Action.SET_SELECTION)
  protected setSelection: SetSelectionFunction;

  @locationsPositionsFilterNS.State
  protected selection: FilterStoreState['selection'];

  protected get locationsPositions() {
    return this.getLocationsPositionsByLocationAndPositionId(this.currentLocationId)
      .filter(({ position }) => !!position)
      .sort(sortBySortOrder());
  }

  protected get positionsWithLocationsPositionId() {
    return this.locationsPositions
      .map(({ id, position }) => (
        position
          ? ({ ...position, id })
          : undefined
      ))
      .filter(filterFalsy);
  }

  protected async onOpenClose({ payload: state }: EventPayload<boolean>) {
    this.isOpen = state;

    if (state) {
      // use SWR if locationsPositions have been populated before
      this.isLoading = !this.locationsPositions.length;

      await this.fetchAll({
        locationIds: [this.currentLocationId || Number.NaN],
        positionIds: null,
      });

      this.isLoading = false;
    }
  }

  public render() {
    return (
      <FilterBox isOpen={this.isOpen} onOpenCloseClick={this.onOpenClose}>
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('shiftSchedule.filter.locationsPositions.title')}
        </template>

        {this.isLoading
          ? (
            <Loader />
          ) : (
            <CheckboxListWithPagingAndFiltering
              items={this.positionsWithLocationsPositionId}
              filterKey="name"
              perPage={FILTER_PAGE_SIZE}
              scopedSlots={{
                item: ({ item, isSelected, onChange }) => (
                  <CheckboxListItem
                    isSelected={isSelected}
                    onChange={onChange}
                  >
                    <span style={{ color: item.color }}>
                      {item.name}
                    </span>
                  </CheckboxListItem>
                ),
              }}
              selection={this.selection}
              throttleTimeout={FILTER_THROTTLE_TIMEOUT}
              onSelect={({ payload }) => { this.setSelection(payload as number[]); }}
            />
          )
        }
      </FilterBox>

    );
  }
}
