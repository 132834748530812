import DateItem from 'components/calendar-common/common/DateItem';
import { Component, Vue } from 'vue-property-decorator';
import { calendarFiltersNS } from '../filters/Store';
import type { FiltersDictionary } from '../filters/Store';
import {
  GRID_COLUMN_MIN_WIDTH_DAY,
  GRID_COLUMN_MIN_WIDTH_MONTH, GRID_LABEL_COLUMN_WIDTH, GRID_SUMMARY_COLUMN_WIDTH,
} from './GridVariables';

export enum GridType {
  MONTH,
  WEEK,
}

// TODO: convert it into helper functions
@Component
export default class GridMixin extends Vue {
  protected dates: DateItem[];

  protected gridType: GridType;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  protected getWeekNumber(date: DateItem) {
    return `${this.$t('calendar.calendarWeekShort')}-${date.week}`;
  }

  protected get weekGrid() {
    return `repeat(${this.dates.length + 1}, minmax(${GRID_COLUMN_MIN_WIDTH_DAY}, 1fr)) ${
      this.filters.showSummary
        ? GRID_SUMMARY_COLUMN_WIDTH : ''}`;
  }

  protected get monthGrid() {
    return `minmax(${GRID_LABEL_COLUMN_WIDTH}, 4fr) repeat(${this.dates.length}, minmax(${
      GRID_COLUMN_MIN_WIDTH_MONTH}, 1fr)) ${
      this.filters.showSummary
        ? GRID_SUMMARY_COLUMN_WIDTH : ''}`;
  }

  protected get gridStyle() {
    const gridTemplateColumns = this.gridType === GridType.MONTH
      ? this.monthGrid : this.weekGrid;
    return { gridTemplateColumns };
  }
}
