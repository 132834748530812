import { Size } from 'components/types';
import type { Shiftplan } from 'store/shiftplans/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import Link from 'components/form/button/Link';
import { Route } from '../routes';
import ShiftplanSelector from '../shiftplan-selector/ShiftplanSelector';
import styles from './header.css';

interface Props {
  isApplyRotationActionAllowed?: boolean;
  isShiftplanCreationAllowed?: boolean;
  selectedShiftplan?: Shiftplan;
  shiftplans?: Shiftplan[];
  title: string;
}

@Component
export default class Header extends TsxComponent<Props> {
  @Prop()
  public isApplyRotationActionAllowed: Props['isApplyRotationActionAllowed'];

  @Prop()
  public isShiftplanCreationAllowed: Props['isShiftplanCreationAllowed'];

  @Prop()
  public selectedShiftplan: Props['selectedShiftplan'];

  @Prop()
  public shiftplans: Props['shiftplans'];

  @Prop()
  public title: Props['title'];

  protected onApplyRotationClick() {
    if (!this.selectedShiftplan) {
      return;
    }

    this.$router.push({
      name: Route.APPLY_ROTATION,
      params: {
        shiftplanId: this.selectedShiftplan.id.toString(),
      },
    });
  }

  protected onNewScheduleClick() {
    this.$router.push({ name: Route.CREATE_SHIFTPLAN });
  }

  protected get isAbsencePlanningView() {
    return this.$route.name === Route.ROOT_ABSENCES;
  }

  public render() {
    return (
      <header class={styles.header}>
        <h2 class={styles.headerTitle}>
          {this.title}
        </h2>

        <div class={styles.headerShiftplanSelectorContainer}>
          {
            !!this.shiftplans?.length && (
              <ShiftplanSelector
                class={styles.headerShiftplanSelector}
                selected={this.selectedShiftplan}
                shiftplans={this.shiftplans}
              />
            )
          }
          <Link
            to={{
              name: this.isAbsencePlanningView
                ? Route.ROOT : Route.ROOT_ABSENCES,
            }}>
            {this.$t('shiftSchedule.buttonAbsencePlanning')}
          </Link>
        </div>
        <div class={styles.headerActions}>
          {
            this.selectedShiftplan && this.isApplyRotationActionAllowed && (
              <Button onClick={this.onApplyRotationClick} size={Size.SMALL}>
                {this.$t('shiftSchedule.buttonApplyRotation')}
              </Button>
            )
          }
          {
            this.isShiftplanCreationAllowed && (
              <Button onClick={this.onNewScheduleClick} size={Size.SMALL}>
                {this.$t('shiftSchedule.buttonCreateSchedule')}
              </Button>
            )
          }
        </div>
      </header>
    );
  }
}
