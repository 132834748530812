import { getHSLCSSString, getSecondaryColor } from 'components/calendar-common/helpers/colors/Colors';

export interface TagData {
  color?: string | null;
  secondaryColor: string;
  id: number;
  name: string;
}

class Tag {
  public color: string | null;

  public id: number;

  public name: string;

  public secondaryColor: string;

  public constructor({ color = null, id, name }: TagData) {
    this.color = color;
    this.name = name;
    this.id = id;
    this.secondaryColor = color
      ? `hsl(${getHSLCSSString(getSecondaryColor(color))})`
      : '#ffffff';
  }
}

export default Tag;
