import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { GQLAvailabilityInfo } from 'codegen/gql-types';
import Popover from 'components/popover/Popover';
import Indicator, { AvailabilityState } from './Indicator';
import PopoverContent from './PopoverContent';

@Component
export default class Availability extends TsxComponent<{
  info: GQLAvailabilityInfo;
}> {
  @Prop()
  public info: GQLAvailabilityInfo;

  protected get state() {
    if (this.info.fullAvailable) {
      return AvailabilityState.AVAILABLE;
    }

    if (this.info.fullUnavailable) {
      return AvailabilityState.UNAVAILABLE;
    }

    if (this.info.partialAvailable && !this.info.partialUnavailable) {
      return AvailabilityState.PARTLY_AVAILABLE;
    }

    if (!this.info.partialAvailable && this.info.partialUnavailable) {
      return AvailabilityState.PARTLY_UNAVAILABLE;
    }

    if (this.info.partialAvailable && this.info.partialUnavailable) {
      return AvailabilityState.PARTLY_BOTH;
    }

    return AvailabilityState.UNKNOWN;
  }

  public render() {
    if (this.state === AvailabilityState.UNKNOWN) {
      return null;
    }

    return (
      <Popover content={<PopoverContent info={this.info} />}>
        <Indicator state={this.state} />
      </Popover>
    );
  }
}
