import { ObservableQuery } from 'apollo-client';
import DefaultActions from 'components/calendar-common/DefaultActions';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import RootStoreState from 'src/store/RootStoreState';
import { GraphqlClient } from 'services/graphql-client/GraphqlClientFactory';
import {
  GQLCalendarPrintEventsQueryVariables,
  GQLCalendarPrintEventsQuery,
  GQLCalendarPrintDataQueryVariables,
  GQLCalendarPrintDataQuery,
} from 'codegen/gql-types';
import { CalendarNamespace, LoadState } from 'components/calendar-common/Enums';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import CalendarPrintEventsGql from '../queries/CalendarPrintEvents.gql';
import CalendarPrintDataGql from '../queries/CalendarPrintData.gql';
import Mutations from './Mutations';
import * as Actions from './Actions';

export const calendarPrintDataNS = namespace(`${CalendarNamespace.PRINT}/data`);

interface RequestParams {
  shiftplanId: number;
}

export interface StoreState {
  loadState: LoadState;
  requestParams: RequestParams | null;
  dataQuery: ObservableQuery<GQLCalendarPrintDataQuery, GQLCalendarPrintDataQueryVariables> | null;
  eventsQueryRequestUid: string;
  dataQueryRequestUid: string;
}

const INVALIDATED_REQUEST_UID = NIL_UUID;

const getAllEmployments = (employments, shifts) => {
  const employmentsMap = new Map(employments.map(it => [it.id, it]));

  shifts.forEach(({ staffShifts }) => {
    staffShifts.forEach(({ employment }) => {
      if (employment !== null) {
        employmentsMap.set(employment.id, employment);
      }
    });
  });

  return [...employmentsMap.values()];
};

export const store = (
  client: GraphqlClient,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    loadState: LoadState.NOT_LOADED,
    requestParams: null,
    dataQuery: null,
    eventsQueryRequestUid: INVALIDATED_REQUEST_UID,
    dataQueryRequestUid: INVALIDATED_REQUEST_UID,
  },
  mutations: {
    [Mutations.SET_LOAD_STATE](state, loadState: LoadState) {
      state.loadState = loadState;
    },
    [Mutations.SET_DATA_QUERY](state, query) {
      state.dataQuery = query;
    },
    [Mutations.SET_EVENTS_QUERY_REQUEST_UID](state, uid: string) {
      state.eventsQueryRequestUid = uid;
    },
    [Mutations.SET_DATA_QUERY_REQUEST_UID](state, uid: string) {
      state.dataQueryRequestUid = uid;
    },
  },
  getters: {
    currentInterval(state, getters, rootState, rootGetters) {
      return rootGetters[`${CalendarNamespace.PRINT}/common/currentInterval`];
    },
    locationsPositionIds(state, getters, rootState, rootGetters) {
      const locationsPositionIds = rootGetters['calendarPrint/filters/locationsPositionIds'];
      return !Array.isArray(locationsPositionIds) || locationsPositionIds.length === 0
        ? undefined
        : locationsPositionIds;
    },
  },
  actions: {
    async [Actions.LOAD_DATA]({
      dispatch, commit, state, rootState,
    }) {
      const { currentCompany } = rootState.auth;

      if (!currentCompany) {
        return;
      }
      //  You should already have current interval and shiftplan set
      // up in common store
      const {
        shiftplan,
      } = rootState.calendarPrint.common;

      if (!shiftplan) {
        return;
      }

      const variables: GQLCalendarPrintDataQueryVariables = {
        companyId: shiftplan.companyId,
        locationId: shiftplan.locationId,
      };

      const requestUid: string = uuidv4();

      // mark ongoing requests as stale
      commit(Mutations.SET_EVENTS_QUERY_REQUEST_UID, INVALIDATED_REQUEST_UID);
      commit(Mutations.SET_DATA_QUERY_REQUEST_UID, requestUid);
      commit(Mutations.SET_LOAD_STATE, LoadState.LOADING);

      const result = await client.query<
      GQLCalendarPrintDataQuery, GQLCalendarPrintDataQueryVariables>(
        {
          variables,
          query: CalendarPrintDataGql,
        },
      );

      // check if request is stale
      if (requestUid !== state.dataQueryRequestUid) {
        return;
      }
      const {
        employments: { items: employments },
        locationsPositions: { items: locationsPositions },
      } = result.data;
      dispatch(
        `${CalendarNamespace.PRINT}/employments/${DefaultActions.SET}`,
        employments,
        { root: true },
      );
      dispatch(`${CalendarNamespace.PRINT}/positions/${DefaultActions.SET}`, locationsPositions, { root: true });
      dispatch(Actions.LOAD_EVENTS);
    },

    async [Actions.LOAD_EVENTS](
      {
        dispatch,
        commit,
        rootState,
        getters,
        state,
      },
    ) {
      const { currentCompany, currentEmploymentId } = rootState.auth;
      const { filters: { filters }, common: { shiftplan } } = rootState.calendarPrint;
      const {
        currentInterval,
        locationsPositionIds,
      } = getters;

      const { start, end }: { start: Date; end: Date } = currentInterval;

      if (!currentCompany || !currentEmploymentId || !shiftplan) {
        return;
      }

      const variables: GQLCalendarPrintEventsQueryVariables = deepTransformDates({
        companyId: shiftplan.companyId,
        locationId: shiftplan.locationId,
        shiftplanId: shiftplan.id,
        startsAt: start,
        endsAt: end,
        locationsPositionIds,
        withAbsences: filters.showAcceptedAbsences || filters.showNewAbsences,
      });

      const requestUid: string = uuidv4();
      commit(Mutations.SET_EVENTS_QUERY_REQUEST_UID, requestUid);
      commit(Mutations.SET_LOAD_STATE, LoadState.LOADING);
      const result = await client.query<
      GQLCalendarPrintEventsQuery, GQLCalendarPrintEventsQueryVariables>(
        {
          variables,
          query: CalendarPrintEventsGql,
        },
      );

      // check if request is stale
      if (requestUid !== state.eventsQueryRequestUid) {
        return;
      }

      const {
        shifts: { items: shifts = [] } = {},
        absences: { items: absences = [] } = {},
      } = result.data;
        // TODO: fix graphql types to be stricter
      const getMapTuple = <T>(item: T): [number, T] => [(item as any).id, item];

      const allShifts = [...new Map(shifts.map(getMapTuple)).values()];
      dispatch(`${CalendarNamespace.PRINT}/absences/${DefaultActions.SET}`, absences, { root: true });
      dispatch(`${CalendarNamespace.PRINT}/shifts/${DefaultActions.SET}`, allShifts, { root: true });

      /*
        for old shiftplans employments can change, as some
        employments used in the past can be unassigned from current positions
      */
      dispatch(
        `${CalendarNamespace.PRINT}/employments/${DefaultActions.SET}`,
        getAllEmployments(rootState.calendarPrint.employments.items, allShifts),
        { root: true },
      );
      // wait for render cycle to complete before removing placeholder
      Vue.nextTick(() => {
        commit(Mutations.SET_LOAD_STATE, LoadState.LOADED);
      });
    },
  },
});
