import {
  REMOVE_HOVERED_EVENT,
  SET_HOVERED_EVENT,
  START_EVENT_EDIT,
  STOP_EVENT_EDIT,
  UPDATE_EDITED_EVENT,
} from 'components/calendar-common/common/time-grid-events/Actions';
import TimeGridEvent from 'components/calendar-common/common/time-grid-events/time-grid-event/TimeGridEvent';
import { Component } from 'vue-property-decorator';
import ShiftPart from '../ShiftPart';
import { AGENDA_GRID_STEP, agendaNS } from '../Store';
import styles from './agenda-days-shift.css';
import AgendaDaysShiftContent from './AgendaDaysShiftContent';

@Component
class AgendaDaysShift extends TimeGridEvent<ShiftPart> {
  protected timeGridStep = AGENDA_GRID_STEP;

  // override base class methods with vuex getters
  @agendaNS.Getter('isEditingEvent')
  protected isEditingEvent: boolean;

  @agendaNS.Action(SET_HOVERED_EVENT)
  protected setHoveredEvent;

  @agendaNS.Action(REMOVE_HOVERED_EVENT)
  protected removeHoveredEvent;

  @agendaNS.Action(START_EVENT_EDIT)
  protected startEventEdit;

  @agendaNS.Action(UPDATE_EDITED_EVENT)
  protected updateEditedEvent;

  @agendaNS.Action(STOP_EVENT_EDIT)
  protected stopEventEdit;

  @agendaNS.State(state => state.hoveredEventId)
  protected hoveredEventId: number | null;

  protected get dragHandleStyles() {
    return {
      color: this.eventPart.event.style.color,
      borderLeftColor: this.eventPart.event.style.color,
      backgroundColor: this.eventPart.event.style.backgroundColor,
    };
  }

  protected getContent() {
    return (<AgendaDaysShiftContent
      onDrop={(...args) => this.$emit('drop', ...args)}
      shiftPart={this.eventPart}
      shift={this.eventPart.event}
      class={[styles.agendaShift]}/>);
  }

  protected onMouseMove(e: MouseEvent) {
    // ts has no idea how vue inheritance works, could do a pull request to fix vue types a bit
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.constructor.super.options.methods.onMouseMove.call(this, e);
  }
}

export default AgendaDaysShift as any;
