import InputCheckbox from 'components/form/input-checkbox/InputCheckbox';
import { LocationsPosition } from 'src/store/locations-positions/Store';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Component, Prop } from 'vue-property-decorator';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './section-location-positions.css';

export interface Selection {
  positionIds: number[]
}

interface Props {
  positions: NonNullable<LocationsPosition['position']>[];
  selectedPositions: number[];
}

@Component
export default class SectionLocationPositions extends TsxComponent<Props, {
  onChange: (payload: EventPayload<Selection>) => void;
}> {
  @Prop()
  protected readonly positions: Props['positions'];

  @Prop()
  protected readonly selectedPositions: Props['selectedPositions'];

  protected onChange(e: SyntheticEvent, value) {
    this.$emit('change', createEventPayload(e, {
      positionIds: value,
    }));
  }

  protected toggleAllPositions(value: boolean, e: SyntheticEvent) {
    this.onChange(e, value ? this.positions.map(pos => pos.id) : []);
  }

  protected onCheckboxChange(positionId: number, value, e: SyntheticEvent<HTMLInputElement>) {
    const positions = [...this.selectedPositions];
    if (value) {
      positions.push(positionId);
    } else {
      const index = positions.indexOf(positionId);
      if (index > -1) {
        positions.splice(index, 1);
      }
    }
    this.onChange(e, positions);
  }

  public render() {
    return (
      <section>
        <header>
          {this.$t('copyShiftplan.modal.headingCopyPositions')}
        </header>

        <div
          class={styles.sectionLocationPositionsToggleContainer}
        >
          <InputCheckbox
            class={styles.sectionLocationPositionsToggleAll}
            label={this.$t('copyShiftplan.modal.labelSelectAll')}
            name="selectAll"
            value="false"
            checked={this.positions.length === this.selectedPositions.length}
            onChange={e => this.toggleAllPositions(e.target.checked, e)}
          />
        </div>

        <ol class={styles.sectionLocationPositions}>
          {
            this.positions && this.positions.map(position => (
              <li key={position.id}
                class={styles.sectionLocationPositionsItem}
              >
                <InputCheckbox
                  label={position.name}
                  name="locationIdsSet"
                  value={position.id.toString()}
                  checked={this.selectedPositions?.includes(position.id)}
                  onChange={e => this.onCheckboxChange(position.id, e.target.checked, e)}
                />
              </li>
            ))
          }
        </ol>
      </section>
    );
  }
}
