import {
  GQLAbsenceReasonsQuery, GQLAbsenceFragmentFragment, GQLApproveAbsenceMutation,
} from 'codegen/gql-types';

export type Absence = GQLAbsenceFragmentFragment;

export type AbsenceReason = GQLAbsenceReasonsQuery['absenceReasons']['items'][0];

export type AbsenceApproveResult = NonNullable<GQLApproveAbsenceMutation['approveAbsence']>;

export interface Employment {
  id: number;
  firstName: string;
  lastName: string;
  userImage?: string | null;
  staffNumber: string | null;
}

export enum TimeframeFilterKind {
  PREVIOUS_YEAR,
  CURRENT_YEAR,
  NEXT_YEAR,
  CUSTOM,
}

export interface NewAbsenceInitialState {
  startsAt: Date;
  endsAt: Date;
  employmentId: string;
}
