import FilterPopup from 'components/table/filter-popup/FilterPopup';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

const enum IsSelected {
  FALSE,
  TRUE,
}

@Component
export default class FilterPopupTrueFalse extends TsxComponent<{
  close: () => void;
  selected: boolean | null;
  translationPrefix: string;
}, {
  onFilterChange: boolean | null;
}> {
  protected isFalseSelected = true;

  protected isTrueSelected = true;

  @Emit('filterChange')
  public onFilterChange({ payload: { id: state } }) {
    if (state === IsSelected.TRUE) {
      this.isTrueSelected = !this.isTrueSelected;
    } else {
      this.isFalseSelected = !this.isFalseSelected;
    }

    if (this.isTrueSelected === this.isFalseSelected) {
      return null;
    }

    return this.isTrueSelected && !this.isFalseSelected;
  }

  @Prop()
  public close: () => void;

  @Prop()
  public selected: boolean | null;

  @Prop()
  public translationPrefix: string;

  public mounted() {
    this.isFalseSelected = !this.selected;
    this.isTrueSelected = this.selected === null || this.selected;
  }

  public render() {
    return (
      <FilterPopup
        items={[
          {
            id: IsSelected.TRUE,
            isChecked: this.isTrueSelected,
            label: this.$t(`${this.translationPrefix}.true`),
          },
          {
            id: IsSelected.FALSE,
            isChecked: this.isFalseSelected,
            label: this.$t(`${this.translationPrefix}.false`),
          },
        ]}
        onFilterChange={this.onFilterChange}
        onClose={this.close}
      />
    );
  }
}
