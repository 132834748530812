import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { getRandomString } from 'src/utils/random';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { EventPayload } from 'src/utils/events';
import { Key } from 'src/utils/keyboard';
import SelectPanel from 'components/select-panel/SelectPanel';
import InputText from '../input-text/InputText';
import styles from './input-combobox.css';
import OutsideClickHandler from '../../outside-click-handler/OutsideClickHandler';

export interface Option {
  label: string;
  value: string;
  style?: Partial<CSSStyleDeclaration>;
}

export interface Props {
  isDisabled?: boolean;
  error?: string;
  id?: string;
  isValid?: boolean;
  label?: string;
  name?: string;
  options: Option[];
  value?: string;
  placeholder?: string;
  isError?: boolean;
  hintText?: string;
}

interface Events {
  onChange: (e: EventPayload<string, HTMLInputElement, UIEvent>) => void;
}

// TODO: use InputPillSelectBase when that is merged to dev branch

@Component
class InputCombobox
  extends TSXComponent<Props, Events> {
  public $refs: {
    container: HTMLDivElement;
    input: Vue;
    list: Vue;
  };

  protected isExpanded = false;

  protected isPanelFocused = false;

  protected searchString = '';

  protected focusedOptionIndex = -1;

  @Prop({ default: () => `input-combobox-${getRandomString()}` })
  protected id: NonNullable<Props['id']>;

  @Prop()
  protected label: Props['label'];

  @Prop({ default: false })
  protected isDisabled: NonNullable<Props['isDisabled']>;

  @Prop()
  public options: Props['options'];

  @Prop()
  public name: Props['name'];

  @Prop()
  public isValid: Props['isValid'];

  @Prop()
  public error: Props['error'];

  @Prop()
  public value: Props['value'];

  @Prop()
  public placeholder: Props['placeholder'];

  @Prop()
  public isError: Props['isError'];

  @Prop()
  public hintText: Props['hintText'];

  private get selectedOption() {
    return this.options.find(({ value }) => this.value === value);
  }

  private get filteredOptions() {
    const trimmedLowerCaseSearchString = this.searchString
      .trim()
      .toLocaleLowerCase(this.$i18n.i18next.language);

    if (trimmedLowerCaseSearchString.length === 0) {
      return this.options;
    }

    return this.options.filter(({ label }) => (
      label.toLocaleLowerCase(this.$i18n.i18next.language)
        .trim()
        .includes(trimmedLowerCaseSearchString)
    ));
  }

  private get isInvalid(): boolean {
    return this.isError
      || (this.$attrs.required !== undefined
        && (this.value === undefined || this.value.length === 0));
  }

  private get inputValue(): string {
    return this.isExpanded
      ? this.searchString
      : this.selectedOption?.label || '';
  }

  private open() {
    if (this.isDisabled) {
      return;
    }

    this.isExpanded = true;
  }

  private close() {
    this.isPanelFocused = false;
    this.isExpanded = false;
  }

  private onKeyup({ key, target: { value } }: SyntheticEvent<HTMLInputElement, KeyboardEvent>) {
    if (
      this.isDisabled
      || key === Key.ESCAPE
      || key === Key.ENTER
      || key === Key.TAB
      || key === Key.SHIFT
      || key === Key.CAPS_LOCK
    ) {
      return;
    }

    if (key === Key.ESCAPE) {
      this.close();
      return;
    }
    if (key === Key.ARROW_DOWN || key === Key.ARROW_UP) {
      this.isPanelFocused = true;
    }

    this.searchString = value;
    this.open();
  }

  private onSelect(
    eventPayload: EventPayload<string | number,
    HTMLElement,
    KeyboardEvent | MouseEvent>,
  ) {
    this.$emit('change', eventPayload);
    this.close();
  }

  private onClick() {
    if (this.isExpanded) {
      this.close();
    } else {
      this.open();
    }
  }

  private onKeydown(e: SyntheticEvent<HTMLElement, KeyboardEvent>) {
    // Prevent submit while selecting
    if (e.key === Key.ENTER && this.isExpanded) {
      e.preventDefault();
    }
  }

  private onOutsideClick(e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>) {
    e.stopPropagation();
    this.close();
  }

  public render() {
    return (
      <div class={styles.inputCombobox} ref="container">
        <div
          // the required props are there, just not in this element, but the way proposed by w3c
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          aria-expanded={this.isExpanded}
          aria-owns={`${this.id}-listbox`}
          aria-haspopup="listbox"
          id={`${this.id}-combobox`}
          class={styles.inputComboboxInputWrappper}
        >
          {this.isExpanded && <OutsideClickHandler
            insideRef={() => this.$refs.container}
            onOutsideClick={this.onOutsideClick}
          />}
          <InputText
            class={styles.inputComboboxInput}
            id={this.id}
            ref="input"
            label={this.label}
            type="text"
            isValid={this.isValid}
            disabled={this.isDisabled}
            aria-autocomplete="both"
            error={this.error}
            aria-controls={`${this.id}-listbox`}
            value={this.inputValue}
            onKeyup={e => this.onKeyup(e as SyntheticEvent<HTMLInputElement, KeyboardEvent>)}
            onKeydown={e => this.onKeydown(e as SyntheticEvent<HTMLInputElement, KeyboardEvent>)}
            onClick={this.onClick}
            placeholder={this.placeholder}
            {...{
              style: this.$vnode.data?.style as CSSStyleDeclaration,
            }}
          />
          {!this.isDisabled && this.isExpanded && (
            <SelectPanel
              id={`${this.id}-listbox`}
              value={this.value}
              onSelect={e => this.onSelect(e)}
              options={this.filteredOptions}
              isPanelFocused={this.isPanelFocused}
              ref="list"
            />
          )}
        </div>
        <span class={{
          [styles.inputComboboxHintText]: true,
          [styles.inputComboboxHintTextError]: this.isInvalid,
          [styles.inputComboboxHintTextSuccess]: this.isValid,
        }}
        >
          {this.hintText}
        </span>
      </div>
    );
  }
}

export default InputCombobox;
