import { differenceInCalendarDays } from 'date-fns';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Tooltip from 'components/tooltip/Tooltip';
import { toLocaleDateString } from 'utils/date-related';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import type { Absence } from '../types';
import styles from './cell-attachment.css';

@Component
export default class TooltipUploadedAt extends TsxComponent<{
  absence: Absence;
}> {
  @Prop()
  public absence: Absence;

  protected get tooltipText() {
    if (!this.absence.fileUploadedAt || !this.absence.startsAt) {
      return '';
    }

    const uploadedAt = new Date(this.absence.fileUploadedAt);
    const startsAt = new Date(this.absence.startsAt);

    // use differenceInCalendarDays so that time is not considered
    const differenceUploadedAtStartsAt = differenceInCalendarDays(uploadedAt, startsAt);

    let translationKey = 'absence.attachment.uploadedAtSameDay';

    if (differenceUploadedAtStartsAt < 0) {
      translationKey = 'absence.attachment.uploadedAtBefore';
    } else if (differenceUploadedAtStartsAt > 0) {
      translationKey = 'absence.attachment.uploadedAtAfter';
    }

    const uploadedAtString = this.$t(
      'absence.attachment.uploadedAt',
      { date: toLocaleDateString(uploadedAt, this.$i18n.i18next.language, this.$timeZone.value) },
    );
    const relativeUploadedAtString = this.$t(
      translationKey,
      { count: Math.abs(differenceUploadedAtStartsAt) },
    );

    return `${uploadedAtString}<br />${relativeUploadedAtString}`;
  }

  public render() {
    return (
      <Tooltip text={this.tooltipText} class={styles.cellAttachmentTooltip}>
        <Icon
          name={IconName.INFO}
          size={Size.SMALL}
          title={this.$t('absence.attachment.uploadedAtIconTitle')}
        />
      </Tooltip>
    );
  }
}
