import FormSection from 'components/dialog-shift/form-section/FormSection';
import InputDateTime, { Kind } from 'components/form/input-date-time/InputDateTime';
import InputPillSelect from 'components/form/input-pill-select/InputPillSelect';
import FormDialog from 'components/form/form-dialog/FormDialog';
import { Tag } from 'store/tags/Store';
import { getHourMinuteDurationString, isStartBeforeEnd } from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import { getRandomString } from 'utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import InputTextArea from 'components/form/input-text-area/InputTextArea';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { EvaluationContext } from '../types';
import type { Evaluation } from '../types';
import styles from './form.css';

export interface FormState {
  startsAt: Date;
  endsAt: Date;
  note: string;
  unpaidBreak: number;
  tags: string[];
}

export enum Slot {
  SECTION_BREAKS = 'sectionBreaks',
  SECTION_PAY = 'sectionPay',
}

interface Props {
  context: EvaluationContext;
  evaluation: Evaluation;
  formState: FormState;
  isBringShiftsEnabled?: boolean;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  isValid?: boolean;
  tags?: Tag[];
}

interface Events{
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] } >,
  ) => void;
  onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}

@Component
export default class Form extends TsxComponent<Props, Events> {
  @Prop()
  public evaluation: Props['evaluation'];

  @Prop()
  public context: Props['context'];

  @Prop()
  public isBringShiftsEnabled?: Props['isBringShiftsEnabled'];

  @Prop()
  public isDisabled?: Props['isDisabled'];

  @Prop()
  public isValid?: Props['isValid'];

  @Prop()
  public isSubmitting?: Props['isSubmitting'];

  @Prop()
  public formState: Props['formState'];

  @Prop()
  public tags?: Props['tags'];

  protected get tagsOptions() {
    return this.tags?.map(tag => ({
      color: tag.color,
      label: this.$t(`shifts.evaluations.tags.${tag.name}`),
      value: tag.id.toString(),
    })) || [];
  }

  protected get totalShiftDuration() {
    return getHourMinuteDurationString(
      this.formState.endsAt.valueOf() - this.formState.startsAt.valueOf(),
    );
  }

  protected onInput<T extends keyof FormState>(field: T, value: FormState[T], e: SyntheticEvent) {
    this.$emit('input', createEventPayload(e, { field, value }));
  }

  protected async onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    this.$emit('submit', createEventPayload<void>(e, undefined));
  }

  public render() {
    return (
      <FormDialog
        class={styles.form}
        id={`dialog-${getRandomString()}`}
        onSubmit={this.onSubmit}
      >
        {
          this.isBringShiftsEnabled && !!this.tags?.length && (
            <InputPillSelect
              class={styles.formPillSelect}
              isDisabled={this.isDisabled || this.isSubmitting}
              onChange={({ event, payload }) => this.onInput('tags', payload, event)}
              options={this.tagsOptions}
              placeholder={this.$t('shifts.evaluations.placeholderTags')}
              value={this.formState.tags}
            />
          )
        }

        <FormSection
          context={
            this.context === EvaluationContext.COMPANY
              ? this.$t('shifts.evaluations.labelCompany')
              : this.$t('shifts.evaluations.labelEmployee')
          }
          heading={this.$t('shifts.evaluations.headingTime')}
          headingSummary={
            this.$t(
              'shifts.evaluations.labelSummaryTotal',
              { total: this.totalShiftDuration },
            )
          }
        >
          <InputDateTime
            class={[styles.formSectionInput]}
            datepickerLabel={this.$t('general.form.labelStartsAtDate')}
            isDatepickerDisabled={this.isDisabled || this.isSubmitting}
            isTimepickerDisabled={this.isDisabled || this.isSubmitting}
            isValid={isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt)}
            kind={Kind.DATETIME}
            onInput={({ event, payload: { value } }) => this.onInput('startsAt', value, event)}
            required={true}
            timepickerLabel={this.$t('general.form.labelStartsAtTime')}
            timeZone={this.$timeZone.value}
            value={this.formState.startsAt}
          />

          <InputDateTime
            class={[styles.formSectionInput]}
            datepickerLabel={this.$t('general.form.labelEndsAtDate')}
            isDatepickerDisabled={this.isDisabled || this.isSubmitting}
            isTimepickerDisabled={this.isDisabled || this.isSubmitting}
            isValid={isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt)}
            kind={Kind.DATETIME}
            onInput={({ event, payload: { value } }) => this.onInput('endsAt', value, event)}
            required={true}
            timepickerLabel={this.$t('general.form.labelEndsAtTime')}
            timeZone={this.$timeZone.value}
            value={this.formState.endsAt}
          />
        </FormSection>

        {
          this.$slots[Slot.SECTION_BREAKS]
        }

        {
          this.$slots[Slot.SECTION_PAY]
        }

        <FormSection
          context={
            this.context === EvaluationContext.COMPANY
              ? this.$t('shifts.evaluations.labelCompany')
              : this.$t('shifts.evaluations.labelEmployee')
          }
          heading={this.$t('shifts.evaluations.labelNote')}
        >
          <InputTextArea
            class={[styles.formSectionInput, styles.formSectionInputFullWidth]}
            disabled={this.isDisabled || this.isSubmitting}
            onInput={e => this.onInput('note', e.target.value, e)}
            value={this.formState.note}
          />
        </FormSection>

        {
          !this.isDisabled && (
            <Button
              class={styles.formCenteredButton}
              disabled={this.isSubmitting || !this.isValid}
              size={Size.MEDIUM}
              type="submit"
            >
              {this.$t('shifts.evaluations.buttonEvaluate')}
            </Button>
          )
        }
      </FormDialog>
    );
  }
}
