import Shift from '../shifts/Shift';
import Employment from './Employment';

/* eslint-disable import/prefer-default-export */
export const locationPositionFilter = (locationsPositionIds: number[]) => (
  employment: Employment,
) => (
  locationsPositionIds.length === 0
  || employment.presentLocationsPositionIds.some(
    locationsPositionId => locationsPositionIds.includes(locationsPositionId),
  )
  || employment.activeLocationsPositionIds.some(
    locationsPositionId => locationsPositionIds.includes(locationsPositionId),
  )
);

export const myShiftsFilter = (isFilterActive: boolean, currentEmploymentId: number) => (
  (employment: Employment) => (
    isFilterActive
      ? employment.id === currentEmploymentId
      : true
  )
);

export const shiftRotationGroupFilter = (
  shiftRotationGroupIds: number[] | null,
  isShiftRotationsEnabled: boolean,
  shifts: Shift[],
  includeEmployeesWithRotationGroupShifts = false,
) => (
  (employment: Employment) => {
    if (shiftRotationGroupIds === null || !isShiftRotationsEnabled) {
      return true;
    }

    const isEmployeeAssignedToRotationGroup = () => employment.shiftRotationGroupIds.some(
      id => shiftRotationGroupIds?.includes(id),
    );

    const hasEmployeeShiftsInRotationGroup = () => shifts.some(
      (shift: Shift) => {
        const isShiftOfCurrentEmployment = () => shift.employmentIds.includes(employment.id);

        const isShiftInShiftRotationGroup = () => (
          shift.shiftRotationGroupIds.some(
            id => shiftRotationGroupIds?.includes(id),
          )
        );

        return (
          isShiftOfCurrentEmployment()
            && isShiftInShiftRotationGroup()
        );
      },
    );

    return (
      isEmployeeAssignedToRotationGroup()
      || (
        includeEmployeesWithRotationGroupShifts
        && hasEmployeeShiftsInRotationGroup()
      )
    );
  }
);
