import { createEventPayload, EventPayload } from 'src/utils/events';
import {
  Component,
  Prop,
} from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { getRandomString } from 'src/utils/random';
import { IconName } from 'components/icons/types';
import Icon from 'components/icons/Icon';
import styles from './input-button-select.css';

export interface Option<T> {
  value: T;
  icon?: IconName;
  label: string;
  iconOnly?: boolean;
}

export interface Props<T> {
  name?: string;
  options: Option<T>[];
  value?: T;
  disabled?: boolean;
}

interface Events<T> {
  onChange: (e: EventPayload<T, HTMLInputElement, MouseEvent>) => void;
}

@Component
class InputButtonSelect<T> extends TSXComponent<Props<T>, Events<T>> {
  @Prop({ default: () => `buttonselect-${getRandomString()}` })
  protected name: NonNullable<Props<T>['name']>;

  @Prop()
  public options: Props<T>['options'];

  @Prop()
  public value: Props<T>['value'];

  @Prop()
  public disabled: Props<T>['disabled'];

  private onChange(e: SyntheticEvent<HTMLInputElement, MouseEvent>, value: T) {
    this.$emit('change', createEventPayload(e, value));
  }

  private get optionElements() {
    return this.options.map((option) => {
      const key = option.icon || option.label;

      return (
        <label
          class={[
            styles.inputButtonSelectLabel,
            this.value === option.value && styles.inputButtonSelectLabelSelected,
            this.disabled && styles.inputButtonSelectLabelDisabled,
          ]}
          key={key}
          for={key}
          aria-label={option.iconOnly && option.label}
        >
          <input
            class={styles.inputButtonSelectInput}
            type="radio"
            name={this.name}
            id={key}
            disabled={this.disabled}
            checked={this.value === option.value}
            value={option.value}
            onClick={(e: SyntheticEvent<HTMLInputElement, MouseEvent>) => (
              this.onChange(e, option.value)
            )}
          >
          </input>
          {option.icon && <Icon class={styles.inputButtonSelectIcon} name={option.icon} />}
          {option.label && !option.iconOnly && (
            <span class={styles.inputButtonSelectLabelText}>{option.label}</span>
          )}
        </label>
      );
    });
  }

  public render() {
    return (
      <div class={styles.inputButtonSelect}>
        {this.optionElements}
      </div>
    );
  }
}

export default InputButtonSelect;
