import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { GQLAvailabilityInfo } from 'codegen/gql-types';
import { getDurationStringInTimeZone } from 'utils/date-related';
import styles from 'components/popover/popover-content.css';

@Component
export default class PopoverContent extends TsxComponent<{
  info: GQLAvailabilityInfo;
}> {
  @Prop()
  public info: GQLAvailabilityInfo;

  protected get hasAvailability() {
    return Array.isArray(this.info.available) && !!this.info.available.length;
  }

  protected get hasUnavailability() {
    return Array.isArray(this.info.unavailable) && !!this.info.unavailable.length;
  }

  protected get availabilities() {
    return (this.info.available || []).map(item => getDurationStringInTimeZone(
      this.$timeZone.value,
      this.$i18n.i18next.language,
      new Date(item.startsAt),
      new Date(item.endsAt),
      true,
    ));
  }

  protected get unavailabilities() {
    return (this.info.unavailable || []).map(item => getDurationStringInTimeZone(
      this.$timeZone.value,
      this.$i18n.i18next.language,
      new Date(item.startsAt),
      new Date(item.endsAt),
      true,
    ));
  }

  public render() {
    return (
      <div class={styles.popoverContent}>
        {
          this.hasAvailability && <h3 class={styles.popoverContentHeading}>
            {this.$t('shifts.employees.availability.labelAvailableFrom')}
          </h3>
        }

        {
          this.hasAvailability && (
            <dl class={styles.popoverContentList}>
              {
                this.availabilities.map(item => <dd class={styles.popoverContentItem}>{item}</dd>)
              }
            </dl>
          )
        }

        {
          this.hasUnavailability && <h3 class={styles.popoverContentHeading}>
            {this.$t('shifts.employees.availability.labelUnavailableFrom')}
          </h3>
        }

        {
          this.hasUnavailability && (
            <dl class={styles.popoverContentList}>
              {
                this.unavailabilities.map(item => <dd class={styles.popoverContentItem}>{item}</dd>)
              }
            </dl>
          )
        }
      </div>
    );
  }
}
