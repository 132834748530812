import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import type { GQLEmploymentsFilterQuery, GQLEmploymentsFilterQueryVariables } from 'codegen/gql-types';
import getTableStore, { StoreState as TableStoreState } from 'components/table/store/Store';
import EmploymentsGql from '../queries/Employments.gql';
import type { Employment } from '../types';

export const employmentFilterNS = namespace('shiftSchedule/employmentFilter');

export interface Filter {
  search: string | null;
}

export type StoreState = TableStoreState<Employment, Filter>;

const getStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
) => getTableStore<
/* eslint-disable @typescript-eslint/indent */
  Employment,
  Filter,
  GQLEmploymentsFilterQuery,
  GQLEmploymentsFilterQueryVariables,
  void
/* eslint-enable @typescript-eslint/indent */
>(
  graphqlClient,
  {
    query: EmploymentsGql,
    getVariables: rootState => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      companyId: rootState.auth.currentCompanyId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      locationId: rootState.auth.currentLocationId!,
      shiftRotationGroupIds: rootState.shiftSchedule.shiftRotationGroupsFilter.selection,
    }),
    transformResponse: (response) => {
      const { employments: { items: data, pagination: { count } } } = response.data;

      return {
        data,
        count,
      };
    },
    initialState: {
      perPage: 10,
      filters: {
        search: null,
      },
    },
  },
);

export default getStore;
