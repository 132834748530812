import type { GQLPosition } from 'codegen/gql-types';
import Tooltip from 'components/tooltip/Tooltip';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './position-dot.css';

@Component
export default class PositionDot extends TsxComponent<{
  position: Pick<GQLPosition, 'name' | 'color'>;
}> {
  @Prop()
  public position: Pick<GQLPosition, 'name' | 'color'>;

  public render() {
    return (
      <Tooltip text={this.position.name || ''}>
        <span
          class={styles.positionDot}
          style={{
            background: this.position.color,
          }}
          title={this.position.name}
        >
          {this.position.name}
        </span>
      </Tooltip>
    );
  }
}
