enum Action {
  ADD_PAY = 'addPay',
  UPDATE_PAY = 'updatePay',
  UPDATE_TOTAL = 'updateTotal',
  REMOVE_PAY = 'removePay',
  SET_TREE = 'setTree',
  DISPATCH_REMOVE = 'dispatchRemove',
  DISPATCH_UPDATE = 'dispatchUpdate',
  DISPATCH_CREATE = 'dispatchCreate',
}

export default Action;
