import Absence from 'components/calendar-common/absences/Absence';
import DateItem from 'components/calendar-common/common/DateItem';
import Employment from 'components/calendar-common/employments/Employment';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridHeaderMonth from 'components/calendar-common/grid/grid-header-month/GridHeaderMonth';
import GridPrintRow from 'components/calendar-common/grid/grid-print-row/GridPrintRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import { GridType } from 'components/calendar-common/grid/GridMixin';
import Shift from 'components/calendar-common/shifts/Shift';
import { Component } from 'vue-property-decorator';
import { calendarPrintShiftsNS } from 'components/calendar-print/shifts/store/Store';
import { calendarPrintAbsencesNS } from 'components/calendar-print/absences/store/Store';
import { calendarPrintCommonNS } from 'components/calendar-print/common/Store';
import { Component as TsxComponent } from 'vue-tsx-support';
import AbsenceMonth from '../../absences/absence-month/AbsenceMonth';
import ShiftMonth from '../../shifts/shift-month/ShiftMonth';
import { calendarPrintEmploymentsNS } from '../store/Store';
import { GRID_COLUMN_MIN_WIDTH_MONTH, GRID_LABEL_COLUMN_WIDTH } from '../../../calendar-common/grid/GridVariables';

@Component
export default class EmploymentsMonth extends TsxComponent<{}> {
  protected gridType = GridType.MONTH;

  @calendarPrintCommonNS.Getter('dates')
  public dates!: DateItem[];

  @calendarPrintShiftsNS.Getter('shiftsByEmployments')
  public shifts!: Record<number, Record<string, Shift[]>>;

  @calendarPrintAbsencesNS.Getter('absencesByEmployments')
  public absences!:
  Record<number, Record<string, Absence[]>>;

  @calendarPrintEmploymentsNS.Getter('filteredEmployments')
  public employments: Employment[];

  private get gridStyle() {
    return {
      gridTemplateColumns: `minmax(${GRID_LABEL_COLUMN_WIDTH}, 4fr) repeat(${this.dates.length}, minmax(${
        GRID_COLUMN_MIN_WIDTH_MONTH}, 1fr))`,
    };
  }

  private renderEmployees() {
    return this.employments.map(employment => <GridPrintRow
      dates={this.dates}
      scopedSlots= {{
        cell: scope => [
          this.shifts[employment.id][scope.dateItem.dateKey]
            .map(shift => <ShiftMonth shift={shift} key={shift.id}/>),
          this.absences[employment.id][scope.dateItem.dateKey]
            .map(absence => <AbsenceMonth absence={absence} key={absence.uniqId}/>)],
      }}>
      <GridCell slot="label"
        class={gridStyles.gridTablePrintLabelCell}
        isHeader={true}>
        <span>
          {employment.name}
        </span>
      </GridCell>
    </GridPrintRow>);
  }

  public render() {
    return (
      <div class={[gridStyles.gridTable, gridStyles.gridTablePrint]}
        style={this.gridStyle}>
        <GridHeaderMonth
          class={gridStyles.gridTablePrintHeader}
          cellClass={gridStyles.gridTablePrintHeaderCell}
          dates={this.dates}
          isSticky={false}>
          <div slot="label">
            {this.$t('calendar.titleEmployment')}
          </div>
        </GridHeaderMonth>
        <div class={gridStyles.gridTableSubgrid}>
          {this.renderEmployees()}
        </div>
      </div>
    );
  }
}
