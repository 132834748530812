import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { MSECS_IN_MINUTE, getHourMinuteDurationString } from 'utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class MaximumWorkingHours extends TsxComponent<{
  conflict: GQLShiftConflictsFragmentFragment;
}> {
  @Prop()
  public conflict: GQLShiftConflictsFragmentFragment;

  protected get total() {
    if (this.conflict.__typename !== 'MaximumWorkingHoursShiftConflict') {
      return undefined;
    }

    return getHourMinuteDurationString((this.conflict.total || 0) * MSECS_IN_MINUTE);
  }

  protected get cap() {
    if (this.conflict.__typename !== 'MaximumWorkingHoursShiftConflict') {
      return undefined;
    }

    return getHourMinuteDurationString(
      (this.conflict.weeklyMinutes || this.conflict.monthlyMinutes || 0) * MSECS_IN_MINUTE,
    );
  }

  public render() {
    if (this.conflict.__typename !== 'MaximumWorkingHoursShiftConflict') {
      return null;
    }

    return (
      <span>
        {this.total}&thinsp;h/{this.cap}&thinsp;h
      </span>
    );
  }
}
