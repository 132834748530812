import type { GQLShiftRotationsQuery } from 'codegen/gql-types';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import TableAction from 'components/table/store/Action';
import { EventPayload } from 'src/utils/events';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import SnackbarAction from 'components/snackbar/store/Action';
import { AlertKind } from 'components/alert/Alert';
import { SentryTag } from 'services/logger/SentryTransport';
import { GraphQLError } from 'graphql';
import { getFirstErrorMessageFromResponse } from 'utils/store';
import Action from '../rotation-groups/store/Action';
import {
  CreateEmploymentsRotationGroupPayload,
  UpdateEmploymentsRotationGroupPayload,
  ModuleState,
  rotationGroupsNS,
  rotationGroupsTableNS,
} from '../rotation-groups/store/Store';
import { Route } from '../routes';
import DialogRotationAssignment, { FormState } from './DialogRotationAssignment';
import type { EmploymentsRotationGroup } from '../rotation-groups/types';

@Component
export default class DialogRotationAssignmentContainer extends TsxComponent<{
  employmentId: number;
  isUpdate: false;
} | {
  isUpdate: true;
  employmentId: number;
  employmentsRotationGroupId: number;
}> {
  private isSubmitting = false;

  private isLoading = true;

  private shiftRotations: GQLShiftRotationsQuery['shiftRotations']['items'] = [];

  private formState: FormState = {
    shiftRotationId: undefined,
    shiftRotationGroupId: undefined,
    endsAt: null,
    startsAt: null,
    unassignmentStrategy: undefined,
  };

  @Prop()
  public isUpdate: boolean;

  @Prop()
  public employmentId: number;

  @Prop()
  public employmentsRotationGroupId: number;

  @rotationGroupsTableNS.State('data')
  private employmentsRotationGroups: ModuleState['table']['data'];

  @rotationGroupsNS.Action(Action.FETCH_SHIFT_ROTATIONS)
  private fetchShiftRotations: () => Promise<GQLShiftRotationsQuery>;

  @rotationGroupsNS.Action
  private createEmploymentRotationGroup: (
    payload: CreateEmploymentsRotationGroupPayload) => Promise<number | GraphQLError>;

  @rotationGroupsNS.Action
  private updateEmploymentRotationGroup: (
    payload: UpdateEmploymentsRotationGroupPayload) => Promise<number | GraphQLError>;

  @rotationGroupsTableNS.Action(TableAction.REFETCH)
  private refetchEmploymentsRotationGroups: () => void;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  // reset unassignment strategy if end date is cleared
  @Watch('formState.endsAt')
  protected onEndsAtChange() {
    if (!this.formState.endsAt) {
      this.formState.unassignmentStrategy = undefined;
    }
  }

  protected onCloseClick() {
    this.$router.go(-1);
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit({ event }: EventPayload<void, HTMLElement, UIEvent>) {
    event.preventDefault();
    this.isSubmitting = true;
    if (!this.formState.shiftRotationId || !this.formState.shiftRotationGroupId) {
      return;
    }

    let result;

    if (this.isUpdate) {
      result = await this.updateEmploymentRotationGroup({
        ...this.formState as Required<FormState>,
        employmentId: this.employmentId,
        id: this.employmentsRotationGroupId,
      });
    } else {
      result = await this.createEmploymentRotationGroup({
        ...this.formState as Required<FormState>,
        employmentId: this.employmentId,
      });
    }

    this.isSubmitting = false;

    if (typeof result !== 'number') {
      this.$logError({
        tags: [[SentryTag.COMPONENT, DialogRotationAssignmentContainer.name]],
        error: new Error(JSON.stringify(result)),
      });

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: getFirstErrorMessageFromResponse(
          result,
          this.$t.bind(this),
          'employments.dialogRotationAssignment.error',
        )
          || this.$t('employments.dialogRotationAssignment.error.unknown'),
        timeout: 5000,
      });
      return;
    }

    this.onCloseClick();
    this.refetchEmploymentsRotationGroups();
  }

  public async mounted() {
    const response = await this.fetchShiftRotations();

    if (this.isUpdate) {
      const editedEmploymentRotationGroup = this.employmentsRotationGroups
        .find((it): it is EmploymentsRotationGroup => it.id === this.employmentsRotationGroupId);

      if (editedEmploymentRotationGroup === undefined) {
        this.$router.replace({ name: Route.CREATE_ASSIGNMENT });
        return;
      }

      this.formState = {
        startsAt: editedEmploymentRotationGroup.startsAt
          ? new Date(editedEmploymentRotationGroup.startsAt) : null,
        endsAt: editedEmploymentRotationGroup.endsAt
          ? new Date(editedEmploymentRotationGroup.endsAt) : null,
        shiftRotationGroupId: editedEmploymentRotationGroup.shiftRotationGroup.id,
        shiftRotationId: editedEmploymentRotationGroup.shiftRotationGroup.shiftRotation.id,
        unassignmentStrategy: undefined,
      };
    }
    const { shiftRotations: { items: shiftRotations } } = response;
    this.shiftRotations = shiftRotations;

    this.isLoading = false;
  }

  public render() {
    return (!this.isLoading
      && <DialogRotationAssignment
        isUpdate={this.isUpdate}
        isOpen={true}
        isSubmitting={this.isSubmitting}
        // do not pass currently edited assignment as it should not be checked for overlay
        employmentsRotationGroups={
          this.employmentsRotationGroups
            .filter((it): it is EmploymentsRotationGroup => it.__typename === 'EmploymentsRotationGroup')
            .filter(it => it.id !== this.employmentsRotationGroupId)
        }
        shiftRotations={this.shiftRotations}
        formState={this.formState}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
        onCloseClick={this.onCloseClick}
      />
    );
  }
}
