import { EvaluationBreak } from 'store/evaluation-breaks/Store';
import {
  getDurationSum,
  getHourMinuteDurationString,
  getItemsOverlappingInterval,
  isSameDayInTimeZone,
  isStartBeforeEnd,
} from 'utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import Action from './store/Action';
import { Break, sectionBreaksNS } from './store/Store';
import Breaks, {
  OnBreakChangePayload,
  OnDeleteBreakClickPayload,
  OnUnpaidBreakChangePayload,
} from '../../breaks/Breaks';

import styles from './section-breaks.css';

@Component
export default class SectionBreaksContainerCompany extends TsxComponent<{
  evaluationBreaks: EvaluationBreak[];
  evaluationEndsAt: Date;
  evaluationStartsAt: Date;
  isDisabled?: boolean;
  unpaidBreak: number;
}, {
  onUnpaidBreakChange: (payload: OnUnpaidBreakChangePayload) => void;
}> {
  @Prop()
  public evaluationBreaks: Break[];

  @Prop()
  public evaluationEndsAt: Date;

  @Prop()
  public evaluationStartsAt: Date;

  @Prop()
  public isDisabled?: boolean;

  @Prop()
  public unpaidBreak: number;

  @sectionBreaksNS.State('breaks')
  protected breaks: Break[];

  @sectionBreaksNS.Action(Action.ADD_BREAK)
  protected addBreak: (payload: Omit<Break, 'id'>) => void;

  @sectionBreaksNS.Action(Action.UPDATE_BREAK)
  protected updateBreak: (payload: Break) => void;

  @sectionBreaksNS.Action(Action.DELETE_BREAK)
  protected deleteBreak: (breakId: number) => void;

  @sectionBreaksNS.Action(Action.SET_BREAKS)
  protected setBreaks: (breaks: Break[]) => void;

  protected get breaksWithValidity(): (Break & { isValid: boolean})[] {
    return this.breaks.map(item => ({
      ...item,
      isValid: this.validateBreak(item.id, item.startsAt, item.endsAt),
    }));
  }

  protected get isMultiDay() {
    return !isSameDayInTimeZone(
      this.evaluationStartsAt,
      this.evaluationEndsAt,
      this.$timeZone.value,
    );
  }

  protected get totalBreakDuration() {
    return (this.unpaidBreak || 0) * 60 * 1000 + getDurationSum(this.breaks);
  }

  protected onAddBreak() {
    this.addBreak({
      startsAt: this.evaluationStartsAt,
      endsAt: this.evaluationEndsAt,
    });
  }

  protected onDeleteBreak({ payload: { id } }: OnDeleteBreakClickPayload) {
    this.deleteBreak(id);
  }

  protected onChangeBreak({ payload }: OnBreakChangePayload) {
    const updatedBreak = this.breaks.find(item => item.id === payload.id);
    if (updatedBreak) {
      this.updateBreak({
        ...updatedBreak,
        [payload.field]: payload.value,
      });
    }
  }

  protected onChangeUnpaidBreak(e: OnUnpaidBreakChangePayload) {
    this.$emit('unpaidBreakChange', e);
  }

  protected validateBreak(id: number, startsAt: Date, endsAt: Date) {
    if (
      !isStartBeforeEnd(startsAt, endsAt)
      || !isStartBeforeEnd(this.evaluationStartsAt, startsAt, true)
      || !isStartBeforeEnd(endsAt, this.evaluationEndsAt, true)
    ) {
      return false;
    }
    // grab only valid breaks with different id from currently validated break
    const otherBreaks = this.breaks.filter(item => item.id !== id
      && isStartBeforeEnd(item.startsAt, item.endsAt));

    return getItemsOverlappingInterval(
      otherBreaks,
      startsAt,
      endsAt,
      this.$timeZone.value,
    ).length === 0;
  }

  public mounted() {
    this.setBreaks((this.evaluationBreaks || []).map(item => ({
      ...item,
      startsAt: new Date(item.startsAt),
      endsAt: new Date(item.endsAt),
    })));
  }

  public render() {
    return (
      <FormSection
        context={this.$t('shifts.evaluations.labelCompany')}
        heading={this.$t('shifts.evaluations.breaks.headingBreaks')}
        headingSummary={
          this.$t(
            'shifts.evaluations.labelSummaryTotal',
            { total: getHourMinuteDurationString(this.totalBreakDuration) },
          )
        }
      >
        <Breaks
          class={styles.sectionBreaksInputFullWidth}
          shiftEndsAt={this.evaluationEndsAt}
          shiftStartsAt={this.evaluationStartsAt}
          breaks={this.breaksWithValidity}
          isDisabled={this.isDisabled}
          isMultiDay={this.isMultiDay}
          onAddBreakClick={this.onAddBreak}
          onBreakChange={this.onChangeBreak}
          onDeleteBreakClick={this.onDeleteBreak}
          onUnpaidBreakChange={this.onChangeUnpaidBreak}
          unpaidBreak={this.unpaidBreak}
        />
      </FormSection>
    );
  }
}
