import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SelectedTimeframe } from 'components/datepicker/types';
import {
  UPDATE_THROTTLE_TIME,
} from 'components/calendar/data/Store';
import { calendarAbsencesCommonNS } from 'components/calendar-absences/common/store/Store';
import { SET_TIMEFRAME } from 'components/calendar/common/Actions';
import Loader from 'components/loader/Loader';
import EmploymentsMonth from './employments/month/Month';
import { LoadState } from '../calendar-common/Enums';
import { LOAD_DATA, LOAD_EVENTS } from './data/Actions';
import { FiltersMap, calendarAbsencesFiltersNS } from '../calendar-common/filters/Store';
import FilterAction from '../calendar-common/filters/Actions';
import { SetHighlightPayload, HighlightableEventTypes, calendarAbsencesHighlightsNS } from '../calendar-common/highlights/Store';
import HighlightAction from '../calendar-common/highlights/Actions';
import { calendarAbsencesDataNS, StoreState } from './data/Store';

interface Props {
  isInitialDataLoading: boolean;
  timeframe: SelectedTimeframe;
  locationsPositionIds: number[] | null;
  shiftRotationGroupIds: number[] | null;
  employmentIds: number[] | null;
  filters: FiltersMap[];
}

@Component
class CalendarAbsences extends TsxComponent<Props> {
  private loadEventsTimeoutId: number | null = null;

  @Prop()
  public isInitialDataLoading: Props['isInitialDataLoading'];

  @Prop()
  public timeframe: Props['timeframe'];

  @Prop()
  public locationsPositionIds: Props['locationsPositionIds'];

  @Prop()
  public shiftRotationGroupIds: Props['shiftRotationGroupIds'];

  @Prop()
  public employmentIds: Props['employmentIds'];

  @Prop()
  public filters: Props['filters'];

  @calendarAbsencesDataNS.State((state: StoreState) => state.loadState)
  private calendarLoadState: LoadState;

  @calendarAbsencesDataNS.Action(LOAD_DATA)
  public loadData;

  @calendarAbsencesDataNS.Action(LOAD_EVENTS)
  public loadEvents;

  @calendarAbsencesCommonNS.Action(SET_TIMEFRAME)
  public setTimeframe: (timeframe: SelectedTimeframe) => void;

  @calendarAbsencesFiltersNS.Action(FilterAction.SET_FILTERS)
  public setFilters: (filters: FiltersMap[]) => void;

  @calendarAbsencesFiltersNS.Action(FilterAction.SET_LOCATIONS_POSITION_IDS)
  public setLocationsPositionIds: (ids: number[] | null) => void;

  @calendarAbsencesFiltersNS.Action(FilterAction.SET_SHIFT_ROTATION_GROUP_IDS)
  public setShiftRotationGroupIds: (ids: number[] | null) => void;

  @calendarAbsencesHighlightsNS.Action(HighlightAction.SET)
  public setHighlight: (payload: SetHighlightPayload) => void;

  @calendarAbsencesHighlightsNS.Action(HighlightAction.CLEAR)
  public clearHighlight: () => void;

  protected throttledLoadEvents() {
    // do no try to load event till initial data is prepared
    // and data query is started
    if (this.isInitialDataLoading || this.calendarLoadState === LoadState.NOT_LOADED) {
      return;
    }
    if (this.loadEventsTimeoutId) {
      window.clearTimeout(this.loadEventsTimeoutId);
    }
    this.loadEventsTimeoutId = window.setTimeout(() => {
      this.loadEvents();
    }, UPDATE_THROTTLE_TIME);
  }

  @Watch('filters', { immediate: true })
  protected onFiltersChange(filters: FiltersMap[]) {
    this.setFilters(filters);
    this.throttledLoadEvents();
  }

  @Watch('locationsPositionIds', { immediate: true })
  protected onLocationPositionIdsChange(currentValue: number[] | null) {
    this.setLocationsPositionIds(currentValue);
    this.loadData();
  }

  @Watch('shiftRotationGroupIds', { immediate: true })
  protected onShiftRotationGroupIdsChange(ids: number[] | null) {
    if (ids === null || ids.length === 0) {
      this.setShiftRotationGroupIds(null);
      this.clearHighlight();
    } else {
      this.setShiftRotationGroupIds(ids);

      this.setHighlight(ids.map(shiftRotationGroupId => ({
        type: HighlightableEventTypes.ShiftRotationGroup,
        id: shiftRotationGroupId,
      })));
    }
  }

  @Watch('employmentIds', { immediate: true })
  protected onEmploymentIdsChange(ids: number[] | null) {
    if (ids === null || ids.length === 0) {
      this.clearHighlight();
    } else {
      this.setHighlight(ids.map(employmentId => ({
        type: HighlightableEventTypes.Employment,
        id: employmentId,
      })));
    }
  }

  @Watch('timeframe', { immediate: true })
  protected onTimeframeChange() {
    this.setTimeframe(this.timeframe);
    this.throttledLoadEvents();
  }

  @Watch('isInitialDataLoading', { immediate: true })
  protected onIsLoadingChange(currentValue) {
    if (!currentValue) {
      this.loadData();
    }
  }

  public beforeDestroy() {
    if (this.loadEventsTimeoutId) {
      window.clearTimeout(this.loadEventsTimeoutId);
    }
  }

  public render() {
    return (<div>
      {
        this.isInitialDataLoading || this.calendarLoadState !== LoadState.LOADED
          ? <Loader/>
          : (<div>
            <EmploymentsMonth />
          </div>)
      }
    </div>);
  }
}

export default CalendarAbsences;
