import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import InputCheckbox from 'components/form/input-checkbox/InputCheckbox';
import { Shift } from 'src/store/shifts/Store';
import styles from './filtering.css';

export interface Props {
  qualifications: Shift['shiftQualifications'];
  selected: number[];
}

@Component
export default class Qualifications extends TsxComponent<Props, {
  onChange: EventPayload<number[], HTMLInputElement>;
}> {
  @Prop()
  public qualifications: Props['qualifications'];

  @Prop()
  public selected: Props['selected'];

  protected onChange(e: SyntheticEvent<HTMLInputElement, Event>) {
    const { checked } = e.target;

    if (checked) {
      this.$emit('change', createEventPayload(e, [
        ...this.selected,
        +e.target.value,
      ]));
    } else {
      this.$emit('change', createEventPayload(e, this.selected.filter(o => o !== +e.target.value)));
    }
  }

  public render() {
    return (
      <ul class={styles.filteringQualifications}>
        {
          this.qualifications && this.qualifications.map(({ qualification }) => (
            <li key={qualification.id}>
              <InputCheckbox
                label={qualification.name}
                name="qualification"
                value={qualification.id.toString()}
                checked={this.selected.includes(qualification.id)}
                onChange={this.onChange}
              />
            </li>
          ))
        }
      </ul>
    );
  }
}
