import HighlightableEvent from 'components/calendar-common/highlights/highlightable-event/HighlightableEvent';
import { HighlightableEventTypes } from 'components/calendar-common/highlights/Store';
import { Component } from 'vue-property-decorator';
import Absence from './Absence';

@Component
class HighlightableAbsence extends HighlightableEvent {
  protected absence: Absence;

  protected isHighlighted(type: HighlightableEventTypes, id: number[] | null) {
    if (type === HighlightableEventTypes.Employment && Array.isArray(id)) {
      return id.includes(this.absence.employment.id);
    } if (type === HighlightableEventTypes.ShiftRotationGroup) {
      return true;
    }

    return false;
  }
}

export default HighlightableAbsence;
