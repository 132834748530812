import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import styles from './button-square.css';

@Component({
  inheritAttrs: false,
})
export default class ButtonSquare extends TsxComponent<Partial<Omit<HTMLButtonElement, 'form'>> & {
  type?: string;
  // typings seem broken for this one, so redefine it here
  form?: string;
}, EventsOn<HTMLButtonElement>> {
  @Prop({ default: 'button' })
  public type: string;

  public render() {
    return (
      <button
        {...{
          attrs: this.$attrs,
          on: { ...this.$listeners },
        }}
        class={styles.buttonSquare}
        type={this.type}
      >
        {this.$slots.default}
      </button>
    );
  }
}
