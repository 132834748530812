import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';
import Badge, { BadgePreset } from 'components/badge/Badge';
import { IconName } from 'components/icons/types';

@Component
export default class CellPaid extends TsxComponent<{
  paid: boolean;
}> {
  @Prop()
  public paid: boolean;

  public render() {
    return (
      <td class={tableStyles.tableBodyCell}>
        {/* Cannot use Tag from Chronos here as it has no icon and does not support default slot */}
        <Badge
          icon={this.paid ? IconName.DONE : IconName.CLEAR}
          preset={this.paid ? BadgePreset.SUCCESS : BadgePreset.ERROR}
        >
          {this.$t(`absence.paid.${this.paid}`)}
        </Badge>
      </td>
    );
  }
}
