import { minutesBetweenDates } from 'components/calendar-common/helpers/intervals/Intervals.js';
import EventPart from 'components/calendar-common/common/time-grid-events/EventPart';
import { EventEditTypes } from 'components/calendar-common/common/time-grid-events/StoreFactory';
import { GRID_COLUMN_MIN_WIDTH_DAY, GRID_GAP_SIZE } from 'components/calendar-common/grid/GridVariables';
import {
  DEFAULT_TIME_GRID_CELL_HEIGHT,
  DEFAULT_TIME_GRID_STEP,
} from 'components/calendar-common/grid/time-grid/TimeGrid';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import styles from './time-grid-event.css';

export enum TimeGridEventSizes {
  XXS = 30,
  XS = 90,
  S = 120,
  DEFAULT = 150,
}

@Component
class TimeGridEvent<T extends EventPart> extends Vue {
  protected timeGridCellHeight: number = DEFAULT_TIME_GRID_CELL_HEIGHT;

  protected isFullWidth = false;

  protected timeGridStep: number = DEFAULT_TIME_GRID_STEP;

  private onFixedEventMouseMoveBinded: EventHandlerNonNull;

  private fixedStyle: { top: string; left: string; width: string } = {
    top: '0px',
    left: '0px',
    width: GRID_COLUMN_MIN_WIDTH_DAY,
  };

  @Prop({ default: false })
  public isEdited: boolean;

  @Prop()
  public eventPart: T;

  protected get event(): T['event'] {
    return this.eventPart.event;
  }

  private get height() {
    return `${(minutesBetweenDates(
      this.eventPart.start,
      this.eventPart.end,
    ) * this.timeGridCellHeight) / this.timeGridStep
      - 2 * GRID_GAP_SIZE}px`;
  }

  private get left() {
    return `${this.eventPart.indentCoeff * 100}%`;
  }

  private get top() {
    return `${(minutesBetweenDates(
      this.eventPart.start.clone().startOf('day'),
      this.eventPart.getGridStartDate(),
    ) * this.timeGridCellHeight) / this.timeGridStep}px`;
  }

  private get containerClasses() {
    return {
      [styles.timeGridEventContainerHover]: this.eventPart.id === this.hoveredEventId,
      [styles.timeGridEventContainerEditedOriginal]: this.isEdited,
      [styles.timeGridEventContainerEdited]: this.eventPart.isEditedShiftCopy,
      [styles.timeGridEventContainerFullWidth]: this.isFullWidth,
    };
  }

  public mounted() {
    this.onFixedEventMouseMoveBinded = this.onFixedEventMouseMove.bind(this);
    this.onFixedChanged(this.event.isFixed, undefined);
  }

  @Watch('event.isFixed')
  private onFixedChanged(newVal, oldVal) {
    if (newVal !== oldVal) {
      if (newVal === true) {
        document.addEventListener('mousemove', this.onFixedEventMouseMoveBinded);
      } else {
        document.removeEventListener('mousemove', this.onFixedEventMouseMoveBinded);
      }
    }
  }

  private onFixedEventMouseMove(e: MouseEvent) {
    this.fixedStyle.top = `${e.clientY}px`;
    this.fixedStyle.left = `${e.clientX}px`;
  }

  private onDragHandleMouseDown(e: MouseEvent) {
    if (e.button !== 0) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    if (!this.event.isManageable) {
      return;
    }

    if (this.isEditingEvent) {
      return;
    }

    this.startEventEdit({
      event: this.eventPart.event,
      editType: EventEditTypes.RESIZE_EVENT,
    });
  }

  private getDragHaldle() {
    return this.eventPart.isLastPart()
      && this.event.isManageable
      && (<button
        onMousedown={e => this.onDragHandleMouseDown(e)}
        class={[styles.timeGridEventDragHandle, this.dragHandleClass, {
          [styles.timeGridEventDragHandleSmall]:
            (this.event.getDurationMinutes() < TimeGridEventSizes.S),
        }]}>
        =
      </button>);
  }

  protected onMouseMove(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.buttons !== 1) {
      return;
    }
    if (!e.target) {
      return;
    }

    if (!this.event.isManageable) {
      return;
    }

    this.startEventEdit({
      event: this.event,
      editType: EventEditTypes.DRAG_EVENT,
    });
  }

  public render() {
    return (<div class={[
      styles.timeGridEventContainer, this.containerClasses, {
        [styles.timeGridEventContainerFixed]: this.event.isFixed,
      }]}
    style={this.event.isFixed
      ? this.fixedStyle
      : {
        height: this.height,
        left: this.left,
        top: this.top,
      }}>
      <div class={{
        [styles.timeGridEvent]: true,
      }}
      role="button"
      tabIndex={0}
      onMousedown={e => e.stopPropagation()}
      onMousemove={e => this.onMouseMove(e)}
      onMouseenter={() => this.setHoveredEvent(this.eventPart.id)}
      onMouseleave={() => this.removeHoveredEvent(this.eventPart.id)}>
        {this.getContent()}
        {this.getDragHaldle()}
      </div>
    </div>);
  }

  // methods to be overwritten
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  protected setHoveredEvent(id: number) {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  protected removeHoveredEvent(id: number) {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  protected startEventEdit(params: Record<any, any>) {
    throw new Error('Not implemented');
  }

  protected getContent() {
    throw new Error('Not implemented');
  }

  protected get isEditingEvent() {
    return false;
  }

  protected get hoveredEventId(): number | null {
    return null;
  }

  protected get dragHandleStyles() {
    return {};
  }

  protected get dragHandleClass(): string {
    return '';
  }
}

export default TimeGridEvent;
