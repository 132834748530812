import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Shift } from 'src/store/shifts/Store';
import Pagination from 'components/pagination/Pagination';
import { EventPayload } from 'src/utils/events';
import { IconName } from 'components/icons/types';
import ShiftsList from '../shifts-list/ShiftsList';
import styles from './column.css';

const ITEMS_PER_PAGE = 10;

interface Props {
  shifts: Shift[];
  isShiftActionRunning: boolean;
  columnLabel: string;
  icon: IconName;
}

interface Events {
  onShiftClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}

@Component
export default class Column extends TsxComponent<Props, Events> {
  protected page = 1;

  @Prop()
  public shifts: Props['shifts'];

  @Prop()
  public isShiftActionRunning: Props['isShiftActionRunning'];

  @Prop()
  public columnLabel: Props['columnLabel'];

  @Prop()
  public icon: Props['icon'];

  protected get pagesLength() {
    return Math.ceil(this.shifts.length / ITEMS_PER_PAGE);
  }

  protected get paginatedShifts() {
    return this.shifts.slice(
      (this.page - 1) * ITEMS_PER_PAGE,
      ITEMS_PER_PAGE * this.page,
    );
  }

  public render() {
    return (
      <div>
        <header class={styles.columnHeader}>
          {this.columnLabel}
        </header>

        <div class={styles.columnBody}>
          <ShiftsList
            shifts={this.paginatedShifts}
            icon={this.icon}
            onActionClick={e => this.$emit('shiftClick', e)}
            isShiftActionRunning={this.isShiftActionRunning}
          />

          {
            this.pagesLength > 1 && (
              <Pagination
                isLongGapOmitted={true}
                length={this.pagesLength}
                onClick={({ payload }) => { this.page = payload; }}
                selected={this.page}
              />
            )
          }
        </div>
      </div>
    );
  }
}
