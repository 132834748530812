import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'utils/events';
import { wrapAroundMod } from 'utils/utils';
import { Key } from 'utils/keyboard';
import { IconName } from 'components/icons/types';
import Icon from 'components/icons/Icon';
import NotificationBadge from 'components/notification-badge/NotificationBadge';
import { Size } from 'components/types';
import styles from './tabs.css';

export interface Tab {
  id: string;
  class?: string;
  label?: string | JSX.Element | Vue;
  iconName?: IconName;
  iconSize?: Size.XSMALL | Size.SMALL | Size.MEDIUM | Size.LARGE;
  disabled?: boolean;
  notificationCount?: number;
}

interface Props {
  list: Tab[];
  selected?: string;
  passthrough?: boolean;
}

interface Events {
  onSelect: (payload: EventPayload<Tab, HTMLButtonElement, KeyboardEvent | MouseEvent>) => void;
}

@Component
export default class Tabs extends TsxComponent<Props, Events> {
  public $refs: {
    tabsRef: HTMLButtonElement[];
  };

  @Prop()
  public list: Props['list'];

  @Prop()
  public selected: Props['selected'];

  @Prop()
  public passthrough: Props['passthrough'];

  protected get selectedTab() {
    return this.list.find(tab => tab.id === this.selected);
  }

  protected onKeyDown(e: SyntheticEvent<HTMLButtonElement, KeyboardEvent>) {
    if (![Key.ARROW_RIGHT, Key.ARROW_LEFT, Key.HOME, Key.END].includes(e.key as Key)) {
      return;
    }

    e.preventDefault();

    let index = this.$refs.tabsRef.findIndex(o => o === e.target);

    if (e.key === Key.ARROW_RIGHT) {
      index = wrapAroundMod(index, this.$refs.tabsRef.length, 1);
    } else if (e.key === Key.ARROW_LEFT) {
      index = wrapAroundMod(index, this.$refs.tabsRef.length, -1);
    } else if (e.key === Key.HOME) {
      index = 0;
    } else if (e.key === Key.END) {
      index = this.$refs.tabsRef.length - 1;
    }

    this.$refs.tabsRef[index].focus();
  }

  public render() {
    if (!this.passthrough && this.$slots.default?.length !== this.list.length) {
      console.warn('number of tabs does not match the number of slots');
    }
    return (
      <div class={styles.tabs}>
        <div
          class={styles.tabsTablist}
          role="tablist"
          aria-label={this.$t('general.tabsAriaLabel')}
        >
          {
            this.list.map(tab => (
              <button
                aria-controls={`${tab.id}-tab`}
                aria-selected={this.selected === tab.id}
                class={[{
                  [styles.tabsTab]: true,
                  [styles.tabsTabSelected]: this.selected === tab.id,
                }, tab.class]}
                id={tab.id}
                disabled={tab.disabled}
                onClick={e => this.$emit('select', createEventPayload(e, tab))}
                onKeydown={this.onKeyDown}
                ref="tabsRef"
                refInFor={true}
                role="tab"
                tabindex={this.selected === tab.id ? 0 : -1}
              >
                <div class={styles.tabsTabContent}>
                  {tab.iconName && <Icon
                    name={tab.iconName}
                    size={tab.iconSize ? tab.iconSize : Size.SMALL}
                    class={{ [styles.tabsTabIcon]: tab.iconName && tab.label }}
                  />}
                  {tab.label && tab.label}
                </div>
                {tab.notificationCount
                && <NotificationBadge disabled={tab.disabled}>
                  {tab.notificationCount}
                </NotificationBadge>}
              </button>
            ))
          }
        </div>

        {
          this.passthrough
            ? (
              <div
                aria-labelledby={this.selectedTab?.id}
                class={styles.tabsPanel}
                id={`${this.selectedTab?.id}-tab`}
                role="tabpanel"
                tabindex="0"
              >
                {this.$slots.default}
              </div>
            ) : (
              this.$slots.default?.map((node, index) => (
                <div
                  aria-labelledby={this.list[index].id}
                  class={styles.tabsPanel}
                  id={`${this.list[index].id}-tab`}
                  role="tabpanel"
                  tabindex="0"
                  hidden={this.selected !== this.list[index].id ? 'true' : undefined}
                >
                  {node}
                </div>
              ))
            )
        }
      </div>
    );
  }
}
