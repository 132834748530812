import { authNS } from 'components/auth/store/Store';
import type { HasAnyRightFunction, StoreState } from 'components/auth/store/Store';
import type { Shift } from 'store/shifts/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import ColumnAssigned from './column-assigned/ColumnAssigned';
import ColumnAssignment from './column-assignment/ColumnAssignment';

import styles from './manager-view.css';

@Component
export default class ManagerView extends TsxComponent<{
  shift?: Shift;
}, {
  onRefetchShift: ({ useBackgroundFetch: boolean }) => void;
}> {
  @Prop()
  public shift?: Shift;

  @authNS.Getter
  protected hasAnyRight: HasAnyRightFunction;

  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  protected get isMoneyShown() {
    return this.isSuperAdmin || this.hasAnyRight('payments_show_all');
  }

  public render() {
    if (!this.shift) {
      return null;
    }

    return (
      <section class={styles.managerView}>
        <ColumnAssigned
          onRefetchShift={({ useBackgroundFetch }) => this.$emit('refetchShift', { useBackgroundFetch })}
          class={styles.managerViewColumn}
          shift={this.shift}
        />

        <ColumnAssignment
          class={styles.managerViewColumn}
          currency={this.currentCompany?.currency}
          isMoneyShown={this.isMoneyShown}
          onRefetchShift={({ useBackgroundFetch }) => this.$emit('refetchShift', { useBackgroundFetch })}
          shift={this.shift}
        />
      </section>
    );
  }
}
