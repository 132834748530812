import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import DndKind from 'components/calendar-common/common/dnd/DndKind';
import 'components/calendar-common/dnd.css';
import defaultDndParams, { DndParams } from 'components/calendar-common/grid/DndParams';
import Shift from 'components/calendar-common/shifts/Shift';
import {
  dragAndDropNS,
  StoreState as DndStoreState,
} from 'src/store/drag-and-drop/store';
import { DraggableEmploymentPayload, DraggableStaffShiftPayload } from 'src/store/drag-and-drop/types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload } from '../../../utils/events';

@Component
export default class ShiftMixin extends Vue {
  @dragAndDropNS.State('payload')
  protected draggableItem: DndStoreState['payload'];

  @Prop({ default: null })
  public employmentId: number | null;

  protected shift: Shift;

  protected onWindowDragStartHandler;

  protected onWindowDropHandler;

  protected dndParams: DndParams = { ...defaultDndParams };

  protected isDragAndDropActive = false;

  protected isDragOverActive = false;

  @authNS.State(
    (state: AuthStoreState) => state.currentCompany?.isOverassignmentAllowed || false,
  )
  protected isOverassignmentAllowed: boolean;

  public get isValidShift(): boolean {
    return !!this.shift && !!this.shift.isManageable;
  }

  protected isShift;

  protected get draggedItemOptions(): DraggableEmploymentPayload
  | DraggableStaffShiftPayload | null {
    if (this.draggableItem === null) {
      return null;
    }

    const { kind } = this.draggableItem;
    return this.draggableItem.kind
      && [DndKind.EMPLOYMENT, DndKind.STAFF_SHIFT]
        .includes(kind)
      ? this.draggableItem
      : null;
  }

  protected get isDropActive() {
    const sourcePayload = this.draggedItemOptions;

    if (!sourcePayload) {
      return false;
    }

    const isSlotsAvailable: boolean = this.isOverassignmentAllowed
      || this.shift.staffShiftsLength < this.shift.workers;
    const isEmploymentAlreadyInShift = this.shift.employmentIds
      .includes(sourcePayload.kind === DndKind.STAFF_SHIFT
        ? sourcePayload.employmentId
        : sourcePayload.id);
    const isAllowedPosition = sourcePayload
      .activeLocationsPositionIds
      .includes(this.shift.position.locationsPositionId);
    // check if employment is not deleted
    const isEmploymentActive = !sourcePayload.isDeleted;

    return isEmploymentActive && isSlotsAvailable
      && !isEmploymentAlreadyInShift && isAllowedPosition;
  }

  @Watch('isDropActive')
  protected onIsDropActiveChange() {
    if (!this.isDropActive) {
      this.isDragOverActive = false;
    }
  }

  protected onDragOver(e: DragEvent) {
    e.preventDefault();
  }

  protected onDrop(e: SyntheticEvent<Element, DragEvent>) {
    e.preventDefault();

    if (!this.isDropActive) {
      return;
    }

    this.$emit('drop', createEventPayload(
      e,
      {
        source: this.draggedItemOptions,
        target: {
          kind: DndKind.SHIFT,
          id: this.shift.id,
        },
      },
    ));
  }

  protected onDragEnter(e: DragEvent) {
    if (!this.isDropActive) {
      return;
    }

    e.stopPropagation();
    this.isDragOverActive = true;
  }

  protected onDragLeave(e: DragEvent) {
    if (!this.isDropActive) {
      return;
    }

    e.stopPropagation();
    this.isDragOverActive = false;
  }
}
