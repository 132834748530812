import FormSection from 'components/dialog-shift/form-section/FormSection';
import { PaygradeType } from 'store/paygrade-types/Store';
import { createEventPayload, EventPayload } from 'utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Section, { Pay } from 'components/dialog-shift/paygrades/Section';
import { isShiftPaygrade } from 'components/dialog-shift/paygrades/utils';
import { formatNumberWithPrecision } from 'utils/utils';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { Option } from 'components/select-panel/SelectPanel';
import styles from './section-pay.css';
import { EvaluationContext } from '../types';

interface Props {
  context: EvaluationContext;
  isBonusDisabled?: boolean;
  isDisabled?: boolean;
  isPaymentAddDisabled?: boolean;
  pay: Pay[];
  total: number;
  typesBonus: PaygradeType[];
  typesRegular: PaygradeType[];
}

interface Events {
  onPayAdd: (payload: EventPayload<Pay | undefined, HTMLElement, UIEvent>) => void;
  onPayRemove: (payload: EventPayload<Pay, HTMLElement, UIEvent>) => void;
  onChange: (payload: EventPayload<Pay, HTMLElement, UIEvent>) => void;
}

@Component
export default class SectionPay extends TsxComponent<Props, Events> {
  @Prop()
  public context: Props['context'];

  @Prop({ default: false })
  public isBonusDisabled: Props['isBonusDisabled'];

  @Prop({ default: false })
  public isDisabled: Props['isDisabled'];

  @Prop({ default: false })
  public isPaymentAddDisabled: Props['isPaymentAddDisabled'];

  @Prop()
  public pay: Props['pay'];

  @Prop()
  public total: Props['total'];

  @Prop()
  public typesBonus: Props['typesBonus'];

  @Prop()
  public typesRegular: Props['typesRegular'];

  protected get isAddPaymentShown() {
    return !this.isDisabled
      && !this.isPaymentAddDisabled
      // eslint-disable-next-line no-unsafe-optional-chaining
      && this.typesRegular.length !== this.inherited?.length + this.individual?.length;
  }

  protected get inherited(): Pay[] {
    return this.pay.filter(o => !o.isDeleted && !isShiftPaygrade(o));
  }

  protected get individual(): Pay[] {
    return this.pay.filter(o => !o.isDeleted && isShiftPaygrade(o));
  }

  protected get optionsBonuses(): Option<number>[] {
    return this.typesBonus.map(type => ({
      label: `${type.name}, ${this.$t(`shifts.paygrades.${type.payType}`)}`,
      value: type.id,
    }));
  }

  protected get optionsRegular(): Option<number>[] {
    // FAQ: inherited paygrade may be overridden in shift - only count individual paygrades as used
    const typesAlreadyUsed = new Set(this.individual.map(o => o.typeId));

    return this.typesRegular.map(type => ({
      isDisabled: typesAlreadyUsed.has(type.id),
      label: `${type.name}, ${this.$t(`shifts.paygrades.${type.payType}`)}`,
      value: type.id,
    }));
  }

  protected formatWithTwoFractionDigits(value: number) {
    return formatNumberWithPrecision(this.$i18n.i18next.language, value, 2, 2);
  }

  protected onPayAdd(e: EventPayload<Pay | undefined>) {
    this.$emit('payAdd', e);
  }

  protected onChange(e: EventPayload<Pay>) {
    this.$emit('change', e);
  }

  protected onPayRemove(e: EventPayload<Pay>) {
    this.$emit('payRemove', e);
  }

  public render() {
    return (
      <FormSection
        context={
          this.context === EvaluationContext.COMPANY
            ? this.$t('shifts.evaluations.labelCompany')
            : this.$t('shifts.evaluations.labelEmployee')
        }
        heading={this.$t('shifts.evaluations.headingPayment')}
        headingSummary={
          this.$t(
            'shifts.evaluations.labelSummaryTotal',
            { total: this.formatWithTwoFractionDigits(this.total) },
          )
        }
      >
        {
          this.inherited.map(pay => (
            <Section
              class={styles.sectionPaySection}
              bonuses={pay.children.filter(o => !o.isDeleted)}
              isBonusDisabled={this.isBonusDisabled}
              isDenseStyle={true}
              isDisabled={this.isDisabled}
              isInherited={true}
              key={pay.id}
              onAddBonusPaygrade={this.onPayAdd}
              onChange={this.onChange}
              onRemovePaygrade={this.onPayRemove}
              optionsBonuses={this.optionsBonuses}
              optionsRegular={this.optionsRegular}
              paygrade={pay}
            />
          ))
        }

        {
          this.individual.map(pay => (
            <Section
              class={styles.sectionPaySection}
              bonuses={pay.children.filter(o => !o.isDeleted)}
              isBonusDisabled={this.isBonusDisabled}
              isDenseStyle={true}
              isDisabled={this.isDisabled}
              key={pay.id}
              onAddBonusPaygrade={this.onPayAdd}
              onChange={this.onChange}
              onRemovePaygrade={this.onPayRemove}
              optionsBonuses={this.optionsBonuses}
              optionsRegular={this.optionsRegular}
              paygrade={pay}
            />
          ))
        }

        {
          this.isAddPaymentShown && (
            <Button
              class={styles.sectionPayButton}
              color={ButtonColor.SUCCESS}
              onClick={e => this.onPayAdd(createEventPayload(e, undefined))}
              size={Size.SMALL}
              kind={ButtonKind.STROKE}
            >
              {this.$t('shifts.paygrades.buttonAddPaygrade')}
            </Button>
          )
        }
      </FormSection>
    );
  }
}
