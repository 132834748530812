import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { AbsenceStates, store as getAbsencesStore, StoreState as BaseStoreState } from 'components/calendar-common/absences/Store';
import {
  absencesByEmployments, absencesByPositions, FILTER_CHECKS, getByDates, getByDatesUnsorted,
} from 'components/calendar-common/absences/util';
import Absence from 'components/calendar-common/absences/Absence';

export const absencesNS = namespace('calendar/absences');
export const absencesPositionsMonthViewNS = namespace('calendar/absences/positionsMonthView');

export type StoreState = BaseStoreState;

export const store = (): Module<StoreState, RootStoreState> => {
  const baseStore = getAbsencesStore();
  return {
    ...baseStore,
    getters: {
      ...baseStore.getters,
      filteredAbsences(state, getters, rootState, rootGetters) {
        return getters.absences.filter(it => FILTER_CHECKS
          .some(({ key, check }) => (rootGetters['calendar/filters/filters'][key]
            ? check(it) : false))
          && rootGetters['calendar/employments/filteredEmployments'].find(emp => emp.id === it.employment.id));
      },
      absencesByDatesUnsorted(state, getters, rootState, rootGetters) {
        return getByDatesUnsorted(rootGetters['calendar/common/dateKeys'], getters.filteredAbsences);
      },

      absencesByDates(state, getters) {
        return getByDates(getters.absencesByDatesUnsorted);
      },

      absencesByEmployments(state, getters, rootState, rootGetters) {
        return absencesByEmployments(
          rootGetters['calendar/employments/filteredEmployments'],
          getters.absencesByDatesUnsorted,
        );
      },

      absencesByPositions(state, getters, rootState, rootGetters) {
        return absencesByPositions(
          rootGetters['calendar/positions/filteredPositions'],
          rootGetters['calendar/employments/employments'],
          getters.absencesByDatesUnsorted,
        );
      },

      /*
        we need to only have accepted absences
        for every day for summary, independently from current state filters
      */
      absencesSummary(state, getters, rootState, rootGetters): Record<string, Absence[]> {
        const filteredAbsences = getters.absences.filter(it => it.state === AbsenceStates.ACCEPTED
            && rootGetters['calendar/employments/filteredEmployments'].find(emp => emp.id === it.employment.id));
        return getByDatesUnsorted(rootGetters['calendar/common/dateKeys'], filteredAbsences);
      },
    },
    modules: {
      positionsMonthView: {
        namespaced: true,
        getters: {
          filteredAbsences(state, getters, rootState, rootGetters) {
            return rootGetters['calendar/absences/absences'].filter(it => FILTER_CHECKS
              .some(({ key, check }) => (rootGetters['calendar/filters/filters'][key]
                ? check(it) : false))
              && rootGetters['calendar/employments/positionsMonthView/filteredEmployments'].find(emp => emp.id === it.employment.id));
          },

          absencesByDatesUnsorted(state, getters, rootState, rootGetters) {
            return getByDatesUnsorted(rootGetters['calendar/common/dateKeys'], getters.filteredAbsences);
          },

          absencesByEmployments(state, getters, rootState, rootGetters) {
            return absencesByEmployments(
              rootGetters['calendar/employments/positionsMonthView/filteredEmployments'],
              getters.absencesByDatesUnsorted,
            );
          },
        },
      },
    },
  };
};
