import Placeholder from 'components/placeholder/Placeholder';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './figure.css';

export interface FigureItem {
  label: string;
  number: number;
  precision?: number;
  useGrouping?: boolean;
}

@Component
export default class Figure extends TsxComponent<FigureItem & {
  isPlaceholderShown?: boolean;
}> {
  @Prop()
  public isPlaceholderShown?: boolean;

  @Prop()
  public label: FigureItem['label'];

  @Prop()
  public number: FigureItem['number'];

  @Prop()
  public precision?: FigureItem['precision'];

  @Prop({ default: true })
  public useGrouping: FigureItem['useGrouping'];

  protected get nf() {
    return new Intl.NumberFormat(
      this.$i18n.i18next.language,
      {
        style: 'decimal',
        minimumFractionDigits: this.precision || 0,
        maximumFractionDigits: this.precision || 0,
        useGrouping: this.useGrouping,
      },
    );
  }

  public render() {
    return (
      <div class={styles.figure}>
        <span class={styles.figureNumber}>
          <Placeholder isShown={this.isPlaceholderShown}>
            {this.nf.format(this.number)}
          </Placeholder>
        </span>
        <span class={styles.figureLabel} title={this.label}>
          <Placeholder isShown={this.isPlaceholderShown} class={styles.figureLabelPlaceholder}>
            {this.label}
          </Placeholder>
        </span>
      </div>
    );
  }
}
