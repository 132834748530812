import { IconName } from 'components/icons/types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import NavigationButton from '../navigation-button/NavigationButton';
import getRoutes, { Route } from '../routes';
import { rotationWizardNS } from '../store/Store';

import styles from './navigation.css';

@Component
export default class Navigation extends TsxComponent<{
  isEdit: boolean;
  isIndividualRotation?: boolean;
}> {
  @Prop({ default: false })
  private isEdit: boolean;

  @Prop()
  private isIndividualRotation?: boolean;

  @rotationWizardNS.State
  protected rotationId: number;

  @rotationWizardNS.Getter
  protected isGeneralSetupValid: boolean;

  @rotationWizardNS.Getter
  protected isGeneralSetupIndividualValid: boolean;

  @rotationWizardNS.Getter
  protected isRotationPatternSetupValid: boolean;

  @rotationWizardNS.Getter
  protected isEmployeeAssignmentValid: boolean;

  protected get step() {
    const routes = getRoutes();
    const [parentRoute] = this.$route.matched;

    const step = routes.find(route => route.name === parentRoute.name)
      ?.children?.findIndex(route => route.name === this.$route.name);

    if (step === undefined || step < 0) {
      return Number.NaN;
    }

    return step + 1;
  }

  protected get routeStep1() {
    if (this.isEdit) {
      return Route.EDIT_STEP_1;
    }

    return this.isIndividualRotation
      ? Route.INDIVIDUAL_STEP_1
      : Route.STEP_1;
  }

  protected get routeStep2() {
    if (this.isEdit) {
      return Route.EDIT_STEP_2;
    }

    return this.isIndividualRotation
      ? Route.INDIVIDUAL_STEP_2
      : Route.STEP_2;
  }

  protected get isStep1Valid() {
    return (!this.isIndividualRotation && this.isGeneralSetupValid)
      || (this.isIndividualRotation && this.isGeneralSetupIndividualValid);
  }

  public render() {
    return (
      <nav class={styles.navigation}>
        <ol class={styles.navigationList}>
          <li class={styles.navigationListItem}>
            <NavigationButton
              route={this.routeStep1}
              icon={this.isStep1Valid ? IconName.DONE : IconName.ARROW_NEXT}
              class={{
                [styles.navigationButtonEnabled]: this.step > 1 && this.isStep1Valid,
              }}
            >
              {this.$t('rotationWizard.steps.general')}
            </NavigationButton>
          </li>
          <li class={styles.navigationListItem}>
            <NavigationButton
              disabled={!this.isStep1Valid}
              icon={this.isRotationPatternSetupValid ? IconName.DONE : IconName.ARROW_NEXT}
              route={this.routeStep2}
              class={{
                [styles.navigationButtonEnabled]: this.step !== 2 && this.isStep1Valid,
              }}
            >
              {this.$t('rotationWizard.steps.rotationSetup')}
            </NavigationButton>
          </li>
          {
            !this.isIndividualRotation && (
              <li class={styles.navigationListItem}>
                <NavigationButton
                  disabled={
                    !this.isGeneralSetupValid
                    || !this.isRotationPatternSetupValid
                    || !this.rotationId
                  }
                  icon={this.isEmployeeAssignmentValid ? IconName.DONE : IconName.ARROW_NEXT}
                  route={this.isEdit ? Route.EDIT_STEP_3 : Route.STEP_3}
                  class={{
                    [styles.navigationButtonEnabled]: this.step !== 3
                      && this.isGeneralSetupValid
                      && this.isRotationPatternSetupValid,
                  }}
                >
                  {this.$t('rotationWizard.steps.assignEmployees')}
                </NavigationButton>
              </li>
            )
          }
        </ol>
      </nav>
    );
  }
}
