import type { GQLTag } from 'codegen/gql-types';
import { getUpdatedItems, deleteItems } from 'components/calendar-common/common/store/Helpers';
import DefaultActions from 'components/calendar-common/DefaultActions';
import { namespace } from 'vuex-class';
import { SET_SELECTED_TAG_IDS } from './Actions';
import Mutations from './Mutations';
import Tag from './Tag';

export const tagsNS = namespace('calendar/tags');

export interface StoreState {
  tags: GQLTag[];
  selectedTagIds: number[];
}
export const store = (selectedTagIds: number[] = []) => ({
  namespaced: true,
  state: {
    selectedTagIds,
    tags: [],
  },
  mutations: {
    [Mutations.SET_TAGS](state, items) {
      state.tags = items;
    },
    [Mutations.UPDATE_TAGS](state, items) {
      state.tags = getUpdatedItems(state.tags, items);
    },
    [Mutations.DELETE_TAGS](state, ids) {
      state.tags = deleteItems(state.tags, ids);
    },
    [Mutations.SET_SELECTED_TAG_IDS](state, tagIds) {
      state.selectedTagIds = tagIds;
    },
  },
  actions: {
    [DefaultActions.SET]({ commit }, items) {
      commit(Mutations.SET_TAGS, items);
    },

    [DefaultActions.UPDATE]({ commit }, items) {
      commit(Mutations.UPDATE_TAGS, items);
    },

    [DefaultActions.DELETE]({ commit }, ids) {
      commit(Mutations.DELETE_TAGS, ids);
    },

    [SET_SELECTED_TAG_IDS]({ commit }, itemIds) {
      commit(Mutations.SET_SELECTED_TAG_IDS, itemIds);
    },
  },
  getters: {
    tags(state) {
      return state.tags.map(tag => new Tag(tag));
    },
    filteredTags(state, getters) {
      return getters.tags.filter(it => state.selectedTagIds.includes(it.id));
    },
  },
});
