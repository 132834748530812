import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { ExportUrlPathName, ViewKind } from 'components/calendar-common/Enums';
import {
  calendarFiltersNS,
  StoreState as FiltersStoreState,
} from 'components/calendar-common/filters/Store';
import {
  tagsNS,
  StoreState as TagsStoreState,
} from 'components/calendar-common/tags/Store';
import Dialog from 'components/dialog/Dialog';
import { StoreState as ShiftPresetsFilterStoreState, shiftPresetsFilterNS } from 'components/shift-schedule/filter-box-shift-preset/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { redirectToParentIf } from 'utils/route';
import { Component, Prop } from 'vue-property-decorator';
import { SentryTag } from 'services/logger/SentryTransport';
import { buildSpreadSheetInit, downloadFile } from 'src/utils/file';
import { buildPayload } from 'src/utils/url';
import { Component as TsxComponent } from 'vue-tsx-support';
import { format } from 'date-fns';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import { shiftScheduleNS, StoreState as CalendarStoreState } from '../store/Store';
import styles from './dialog-calendar-export.css';

@Component
export default class DialogCalendarExport extends TsxComponent<{
  shiftplanId: number;
}> {
  protected isSubmitting = false;

  @Prop()
  public shiftplanId: number;

  @authNS.State
  protected email: AuthStoreState['email'];

  @authNS.State
  protected token: AuthStoreState['token'];

  // #region params for query string
  @authNS.State
  private currentCompanyId: AuthStoreState['currentCompanyId'];

  @authNS.State
  private currentLocationId: AuthStoreState['currentLocationId'];

  @shiftScheduleNS.State
  private viewKind: CalendarStoreState['viewKind'];

  @shiftScheduleNS.State
  private timeframe: CalendarStoreState['timeframe'];

  @calendarFiltersNS.State
  private baseFilters: FiltersStoreState['baseFilters'];

  @calendarFiltersNS.Getter
  private shiftRotationGroupIds: number[] | null;

  @calendarFiltersNS.Getter
  private locationsPositionIds: number[] | null;

  @tagsNS.State('selectedTagIds')
  private tagIds: TagsStoreState['selectedTagIds'];

  @shiftPresetsFilterNS.State('selection')
  private shiftPresetIds: ShiftPresetsFilterStoreState['selection'];
  // #endregion

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  protected onCloseClick() {
    if (this.isSubmitting) { return; }
    redirectToParentIf(() => true)(this);
  }

  public async initDownload() {
    try {
      if (!this.currentCompanyId) {
        throw new TypeError('currentCompanyId not provided');
      }
      if (!this.email || !this.token) {
        throw new TypeError('authentication not provided');
      }

      const locationsPositionIds = this.locationsPositionIds?.length
        ? this.locationsPositionIds
        : undefined;

      const params = {
        locationsPositionIds,
        companyId: this.currentCompanyId,
        endsAt: this.timeframe.endsAt,
        locationId: this.currentLocationId,
        shiftplanId: this.shiftplanId,
        shiftPresetIds: this.shiftPresetIds,
        shiftRotationGroupIds: this.shiftRotationGroupIds,
        startsAt: this.timeframe.startsAt,
        tagIds: this.tagIds,
        viewKind: this.viewKind,
        withoutShiftPresets: this.baseFilters.showShiftsWithoutPreset,
        withoutTags: this.baseFilters.showShiftsWithoutTags,
      };

      const requestInit = buildSpreadSheetInit(
        this.email,
        this.token,
        buildPayload(deepTransformDates(params)),
      );

      await downloadFile(this.fileName(params), this.url, requestInit);

      this.isSubmitting = false;
    } catch (e) {
      this.$logError({
        tags: [[SentryTag.COMPONENT, DialogCalendarExport.name]],
        error: new Error(JSON.stringify(e)),
      });

      this.showSnackbar({
        message: this.$t('general.error.unknown'),
        kind: AlertKind.ERROR,
        timeout: 20000,
      });
    }

    return this.onCloseClick();
  }

  protected get url(): string {
    const pathName = this.viewKind === ViewKind.EMPLOYMENTS
      ? ExportUrlPathName.EMPLOYMENTS
      : ExportUrlPathName.POSITIONS;
    return `${process.env.CALENDAR_EXPORT_ENDPOINT}/${pathName}`;
  }

  protected fileName(params): string {
    const startsAt = format(params.startsAt, 'yyyy-MM-dd');
    const endsAt = format(params.endsAt, 'yyyy-MM-dd');

    return `${this.viewKind}-export-${startsAt}-to-${endsAt}.xlsx`;
  }

  public async mounted() {
    this.isSubmitting = true;
    await this.initDownload();
  }

  public render() {
    return (
      <Dialog
        isOpen={true}
        onCloseClick={this.onCloseClick}
        title={this.$t('shiftSchedule.calendarExport.modal.title')}
      >
        <div class={styles.dialogCalendarExportDescription}>
          {this.$t('shiftSchedule.calendarExport.modal.description')}
        </div>
      </Dialog>
    );
  }
}
