import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient, { ApolloQueryResult, ObservableQuery, OperationVariables } from 'apollo-client';
import RootStoreState from 'src/store/RootStoreState';
import { ActionTree, Module, MutationTree } from 'vuex';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import Action from './Action';
import Mutation from './Mutation';
import { SortDirection } from '../types';
import type { SortOptions } from '../types';

export const shiftPresetsNS = namespace('shiftPresets');

export enum LoadingState {
  IDLE,
  INITIAL_LOADING,
  LOADING,
  ERROR,
  NOT_FOUND,
}

export interface StoreState<TData, TFilters = {}> {
  subscription?: ZenObservable.Subscription;
  watchQuery?: ObservableQuery<any, OperationVariables>;
  data: TData[];
  count: number;
  loadingState: LoadingState;
  page: number;
  perPage: number;
  filters: Partial<TFilters>;
  sort?: SortOptions<TData>;
  selection: number[];
  [key: string]: any;
}

export interface FilterPayload<TFilters extends {}, TKey extends keyof TFilters> {
  key: TKey;
  value: TFilters[TKey];
}

export type SetFilterPayload<TFilters extends {}, TKey extends keyof TFilters> = (
  FilterPayload<TFilters, TKey> | FilterPayload<TFilters, TKey>[]
);

export type SetSelectionFunction = (ids: number[]) => void;

export interface SetSortPayload<TData> {
  key: keyof TData;
  direction: SortDirection;
}

type StoreParams<TData, TFilters, TResponse, TQueryVariables, TOwnState> = {
  query: any;
  getVariables: (rootState: RootStoreState) => Partial<TQueryVariables>;
  transformResponse: (response: ApolloQueryResult<TResponse>) => {
    data: TData[];
    count: number;
  };
  initialState?: Partial<StoreState<TData, TFilters>>;
  actions?: ActionTree<StoreState<TData, TFilters>, RootStoreState>;
  mutations?: MutationTree<StoreState<TData, TFilters>>;
  store?: Module<TOwnState & StoreState<TData, TFilters>, RootStoreState>;
};

export const DEFAULT_PAGINATION_PER_PAGE = 20;

// FIXME: temporary until GQL server orderKey issue is fixed
// Currently, these are all known order keys
const sortKeyTranslation: Record<any, string> = {
  firstName: 'first_name',
  id: 'id',
  lastName: 'last_name',
  startsAt: 'starts_at',

  // order keys for AvailabiltyAggregations
  employment: 'employee_last_name',
  availableHours: 'available_hours',
  contractHours: 'contract_hours',
};

function getStore<TData, TFilters extends {}, TResponse, TQueryVariables, TOwnState>(
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  {
    query,
    getVariables,
    transformResponse,
    initialState,
    store = {},
  }: StoreParams<TData, TFilters, TResponse, TQueryVariables, TOwnState>,
): Module<TOwnState & StoreState<TData, TFilters>, RootStoreState> {
  const {
    state: originalState,
    actions: originalActions,
    mutations: originalMutations,
    getters: originalGetters,
  } = store;

  return {
    namespaced: true,
    state: {
      ...originalState as TOwnState,
      subscription: undefined,
      watchQuery: undefined,
      data: [],
      count: 0,
      loadingState: LoadingState.INITIAL_LOADING,
      perPage: DEFAULT_PAGINATION_PER_PAGE,
      page: 1,
      filters: {},
      selection: [],
      sort: undefined,
      ...initialState,
    },
    mutations: {
      [Mutation.SET_WATCH_QUERY](state, watchQuery) {
        state.watchQuery = watchQuery;
      },
      [Mutation.SET_SUBSCRIPTION](state, subscription) {
        state.subscription = subscription;
      },
      [Mutation.SET_LOADING_STATE](state, loadingState: LoadingState) {
        state.loadingState = loadingState;
      },
      [Mutation.SET_DATA](state, data: TData[]) {
        state.data = data;
      },
      [Mutation.SET_COUNT](state, count: number) {
        state.count = count;
      },
      [Mutation.SET_PAGE](state, page: number) {
        state.page = page;
      },
      [Mutation.SET_FILTER]<TKey extends keyof TFilters>(
        state,
        { key, value }: FilterPayload<TFilters, TKey>,
      ) {
        Vue.set(state.filters, key as string, value);
      },
      [Mutation.SET_SELECTION](state, payload) {
        state.selection = payload;
      },
      [Mutation.SET_SORT](
        state,
        { key, direction }: SetSortPayload<TData>,
      ) {
        state.sort = { key, direction };
      },
      ...originalMutations,
    },
    actions: {
      async [Action.SUBSCRIBE]({
        commit, dispatch, rootState, state,
      }, errorHandler?: (error) => void) {
        const createSubscription = (watchQuery: ObservableQuery<TResponse, OperationVariables>) => {
          const onSubscription = (response: ApolloQueryResult<TResponse>) => {
            const {
              page,
              perPage,
            } = state;
            const { data, count } = transformResponse(response);

            // if we are not on first page | > 1
            // and results are present in general | > 0
            // and results amount is lower than (page - 1) * perPage
            // reset page to 1 and refetch
            if (page > 1 && count > 0 && (count <= (page - 1) * perPage)) {
              commit(Mutation.SET_PAGE, 1);
              dispatch(Action.REFETCH);

              return;
            }

            commit(Mutation.SET_LOADING_STATE, LoadingState.IDLE);
            commit(Mutation.SET_DATA, data);
            commit(Mutation.SET_COUNT, count);
          };

          const subscription = watchQuery.subscribe(onSubscription, errorHandler);

          commit(Mutation.SET_SUBSCRIPTION, subscription);
        };

        if (state.watchQuery) {
          if (!state.subscription) {
            createSubscription(state.watchQuery);
          }

          return;
        }

        commit(Mutation.SET_LOADING_STATE, LoadingState.LOADING);

        const {
          filters,
          page,
          perPage,
          sort,
        } = state;

        const variables: OperationVariables = {
          ...getVariables(rootState),
          page,
          perPage,
          ...filters,
        };

        if (sort) {
          variables.orderDir = sort.direction;
          variables.orderKey = sortKeyTranslation[sort.key];
        }

        const watchQuery = graphqlClient.watchQuery<TResponse>({
          query,
          variables: deepTransformDates(variables),
        });

        commit(Mutation.SET_WATCH_QUERY, watchQuery);
        createSubscription(watchQuery);
      },
      async [Action.UNSUBSCRIBE]({ commit, state }) {
        if (!state.watchQuery || !state.subscription) {
          return;
        }

        state.subscription.unsubscribe();

        commit(Mutation.SET_SUBSCRIPTION, undefined);
      },
      async [Action.REFETCH]({ commit, state, rootState }, clearBeforeRefetch = true) {
        if (!state.watchQuery) {
          return;
        }

        commit(Mutation.SET_LOADING_STATE, LoadingState.LOADING);

        const {
          filters,
          page,
          perPage,
          sort,
        } = state;

        const variables: OperationVariables = {
          ...getVariables(rootState),
          page,
          perPage,
          ...filters,
        };

        if (sort) {
          variables.orderDir = sort.direction;
          variables.orderKey = sortKeyTranslation[sort.key];
        }

        // When the same data is returned, the subscriber callback function is not called
        // thus not resetting the loading state. Therefore, you can clear the last results before.
        // In 99% of the cases, we want that anyway but here's an option to disable it.
        if (clearBeforeRefetch) {
          state.watchQuery.resetLastResults();
        }

        await state.watchQuery.refetch(deepTransformDates(variables));
      },
      async [Action.SET_FILTER]<TKey extends keyof TFilters>(
        { commit, dispatch },
        payload: SetFilterPayload<TFilters, TKey>,
      ) {
        const filters = (!Array.isArray(payload))
          ? [payload]
          : payload;

        filters.forEach(({ key, value }) => commit(Mutation.SET_FILTER, { key, value }));
        commit(Mutation.SET_PAGE, 1);
        dispatch(Action.REFETCH);
      },
      async [Action.SET_PAGE](
        { commit, dispatch },
        page: number,
      ) {
        commit(Mutation.SET_PAGE, page);
        dispatch(Action.REFETCH);
      },
      async [Action.SET_SELECTION]({ commit }, payload) {
        commit(Mutation.SET_SELECTION, payload);
      },
      async [Action.SET_SORT](
        { commit, dispatch },
        payload: SetSortPayload<TData>,
      ) {
        commit(Mutation.SET_SORT, payload);
        dispatch(Action.REFETCH);
      },
      ...originalActions,
    },
    getters: {
      ...originalGetters,
    },
  };
}

export default getStore;
