import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Loader from 'components/loader/Loader';
import RouterLinkIcon, { Size } from 'components/router-link-icon/RouterLinkIcon';
import Action from 'components/table/store/Action';
import { assignmentGroupsNS } from 'store/assignment-groups/Store';
import type { FetchAllAssignmentGroupsFunction, GetByShiftplanId } from 'store/assignment-groups/Store';
import type { SetSelectionFunction } from 'store/filter-store';
import { Action as StoreAction } from 'store/normalized-store';
import { EventPayload } from 'utils/events';
import { IconName } from 'components/icons/types';
import FilterBox, { Slot as FilterBoxSlot } from '../filter-box/FilterBox';
import { StoreState as FilterStoreState, assignmentGroupsFilterNS } from './store/Store';
import CheckboxListItem, { Slot } from '../filter-box/checkbox-list/item/Item';
import CheckboxListWithPagingAndFiltering from '../checkbox-list-with-paging-and-filtering/CheckboxListWithPagingAndFiltering';
import { Route } from '../routes';

import styles from './filter-box-assignment-group.css';
import { FILTER_PAGE_SIZE, FILTER_THROTTLE_TIMEOUT } from '../constants';

@Component
export default class FilterBoxAssignmentGroup extends TsxComponent<{
  shiftplanId?: number;
}> {
  protected isLoading = false;

  protected isOpen = false;

  @assignmentGroupsFilterNS.Action(Action.SET_SELECTION)
  protected setSelection: SetSelectionFunction;

  @assignmentGroupsFilterNS.State
  protected selection: FilterStoreState['selection'];

  @assignmentGroupsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAll: FetchAllAssignmentGroupsFunction;

  @assignmentGroupsNS.Getter('getByShiftplanId')
  protected getByShiftplanId: GetByShiftplanId;

  @Prop()
  public shiftplanId?: number;

  @Watch('shiftplanId')
  protected async onShiftplanIdUpdate() {
    this.setSelection([]);

    if (this.isOpen) {
      this.refetch();
    }
  }

  protected get items() {
    return this.shiftplanId
      ? this.getByShiftplanId(this.shiftplanId)
      : [];
  }

  protected async onOpenClose({ payload: state }: EventPayload<boolean>) {
    this.isOpen = state;

    if (state) {
      this.isLoading = this.items.length === 0;

      await this.refetch();

      this.isLoading = false;
    }
  }

  protected async refetch() {
    return this.fetchAll({
      shiftplanIds: this.shiftplanId
        ? [this.shiftplanId]
        : null,
    });
  }

  public render() {
    return (
      <FilterBox isOpen={this.isOpen} onOpenCloseClick={this.onOpenClose}>
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('shiftSchedule.filter.assignmentGroups.title')}
        </template>

        {
          this.isLoading
            ? (
              <Loader />
            ) : (
              <CheckboxListWithPagingAndFiltering
                items={this.items}
                filterKey="name"
                perPage={FILTER_PAGE_SIZE}
                scopedSlots={{
                  item: ({ item, isSelected, onChange }) => (
                    <CheckboxListItem
                      isSelected={isSelected}
                      onChange={onChange}
                    >
                      <div class={styles.filterBoxAssignmentGroupGroup}>
                        {item.name}
                        <span class={styles.filterBoxAssignmentGroupStats}>
                          {this.$t('shiftSchedule.filter.assignmentGroups.labelShift', { count: item.shiftCount })}
                          &nbsp;·&nbsp;
                          {this.$t('shiftSchedule.filter.assignmentGroups.labelEmployee', { count: item.employmentCount })}
                        </span>
                      </div>

                      <RouterLinkIcon
                        location={{
                          name: Route.ASSIGNMENT_GROUP,
                          params: { shiftplanId: this.shiftplanId?.toString() || '', assignmentGroupId: item.id.toString() },
                        }}
                        slot={Slot.ICON}
                        label="Edit"
                        size={Size.XXSMALL}
                        icon={IconName.EDIT}
                      />
                    </CheckboxListItem>
                  ),
                }}
                selection={this.selection}
                throttleTimeout={FILTER_THROTTLE_TIMEOUT}
                onSelect={({ payload }) => { this.setSelection(payload as number[]); }}
              />
            )
        }
      </FilterBox>

    );
  }
}
