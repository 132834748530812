import {
  authNS,
  StoreState as AuthStoreState,
  StoreState,
} from 'components/auth/store/Store';
import { Slot } from 'components/dialog/Dialog';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import Tabs from 'components/tabs/Tabs';
import { shiftsNS } from 'store/shifts/Store';
import type {
  CanManageFunction,
  Shift,
} from 'store/shifts/Store';
import { getDurationStringInTimeZone, hasAlreadyStarted } from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import type { Shiftplan } from 'src/store/shiftplans/Store';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './dialog-shift.css';
import { Route } from './routes';
import DialogShiftConnected from './dialog-shift-connected/DialogShiftConnected';

interface Props {
  currentEmployment: AuthStoreState['currentEmployment'];
  hasError?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  isUpdate?: boolean;
  shift?: Shift | null;
  shiftplan?: Shiftplan | null;
  isDeleting: boolean;
  isDeleteConfirmDialogOpen: boolean;
  isConnectedShiftsDeleteConfirmDialogOpen: boolean;
}

interface Events {
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onRefetchShift: ({ useBackgroundFetch: boolean }) => void;
  onDeleteClick: (payload: EventPayload<void>) => void;
  onCancelDelete: (payload: EventPayload<void>) => void;
  onConfirmDelete: (payload: EventPayload<void>) => void;
  onConfirmShiftConnected: (payload: EventPayload<boolean>) => void;
  onCreateShiftSuccess: (shiftIds: number[]) => void;
  onUpdateShiftSuccess: (shiftIds: number[]) => void;
}

@Component
export default class DialogShift extends TsxComponent<Props, Events> {
  private isSubmitting = false;

  public $refs: {
    content: HTMLDivElement;
  };

  @Prop()
  public currentEmployment: Props['currentEmployment'];

  @Prop()
  public hasError?: Props['hasError'];

  @Prop()
  public isLoading: Props['isLoading'];

  @Prop()
  public isOpen: Props['isOpen'];

  @Prop()
  public isUpdate: Props['isUpdate'];

  @Prop()
  public shift: Props['shift'];

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public isDeleteConfirmDialogOpen: Props['isDeleteConfirmDialogOpen'];

  @Prop()
  public isConnectedShiftsDeleteConfirmDialogOpen: Props['isConnectedShiftsDeleteConfirmDialogOpen'];

  @Prop()
  public isDeleting: Props['isDeleting'];

  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  @shiftsNS.Getter
  protected canManageStaff: CanManageFunction;

  @shiftsNS.Getter
  protected canManagePaygrade: CanManageFunction;

  @shiftsNS.Getter
  protected canManageShift: CanManageFunction;

  protected get hasShiftAlreadyStarted() {
    return hasAlreadyStarted(this.shift);
  }

  protected get isCurrentlyAssigned() {
    if (!this.shift || !Array.isArray(this.shift.staffShifts)) {
      return false;
    }

    return this.shift.staffShifts.some(staffShift => (
      staffShift.employment && staffShift.employment.id === this.currentEmployment?.id
    ));
  }

  protected get title() {
    if (this.isLoading) {
      return this.$t('general.loading');
    }

    if (this.hasError) {
      return this.$t('general.error.title');
    }

    if (this.shift) {
      const positionName = this.shift.locationsPosition.position?.name;
      const durationInTimeZone = getDurationStringInTimeZone(
        this.$timeZone.value,
        this.$i18n.i18next.language,
        new Date(this.shift.startsAt),
        new Date(this.shift.endsAt),
        true,
      );

      return `${positionName}: ${durationInTimeZone}`;
    }

    return this.$t('shifts.dialog.titleCreate');
  }

  protected get tabsList() {
    const shiftLocationId = this.shift?.locationsPosition?.location?.id || 0;
    const shiftLocationsPositionId = this.shift?.locationsPosition?.id || 0;
    const list = [{ id: Route.ROOT, label: this.$t('shifts.tabDetails') }];

    if (
      !this.hasShiftAlreadyStarted
      && this.canManagePaygrade(shiftLocationId, shiftLocationsPositionId)
    ) {
      list.push({ id: Route.TAB_PAYGRADES, label: this.$t('shifts.tabPaygrades') });
    }

    const isEmployeesShown = this.currentCompany?.viewPersonal
      || this.shift?.canManage
      || this.canManageStaff(shiftLocationId, shiftLocationsPositionId);

    if (isEmployeesShown) {
      list.push({ id: Route.TAB_EMPLOYEES, label: this.$t('shifts.tabEmployees') });
    }

    const isJoinOrLeaveShiftShown = this.currentEmployment?.isEmployee
      && !this.shift?.canManage
      && !this.hasShiftAlreadyStarted;

    if (isJoinOrLeaveShiftShown && !this.isCurrentlyAssigned) {
      list.push({ id: Route.TAB_JOIN_SHIFT, label: this.$t('shifts.tabJoinShift') });
    }

    if (isJoinOrLeaveShiftShown && this.isCurrentlyAssigned) {
      list.push({ id: Route.TAB_LEAVE_SHIFT, label: this.$t('shifts.tabLeaveShift') });
    }

    list.push({ id: Route.TAB_REPLACE_REQUESTS, label: this.$t('shifts.tabReplaceRequest') });

    list.push({ id: Route.TAB_EVALUATION, label: this.$t('shifts.tabEvaluation') });

    return list;
  }

  protected get formId() {
    return `form-${this.$route?.name}`;
  }

  protected get isButtonsDisabled() {
    return this.isLoading || this.isSubmitting || this.isDeleting;
  }

  private get isManagingShiftsAllowed(): boolean {
    return this.canManageShift(
      this.shift?.locationsPosition.location?.id || Number.NaN,
      this.shift?.locationsPosition.id || Number.NaN,
    );
  }

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    if (!this.isSubmitting) {
      this.$emit('closeClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
    }
  }

  protected onSubmitStateChange(state: boolean) {
    this.isSubmitting = state;
  }

  private onDeleteCancel(payload: EventPayload<void>) {
    this.$emit('cancelDelete', payload);
  }

  private onDeleteConfirm(payload: EventPayload<void>) {
    this.$emit('confirmDelete', payload);
  }

  protected onUpdateConnectedShift(payload: boolean) {
    this.$emit('confirmShiftConnected', { payload });
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        error={this.hasError}
        isLoading={this.isLoading}
        isClosingPrevented={this.isSubmitting}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        title={this.title}
      >
        <div
          ref="content"
          class={styles.dialogShiftInner}
        >
          {this.$route?.meta?.hasTabsHidden
            ? (
              <router-view
                onRefetchShift={({ useBackgroundFetch }) => this.$emit('refetchShift', { useBackgroundFetch })}
                onSubmitStateChange={this.onSubmitStateChange}
                onCloseClick={this.onCloseClick}
                formId={this.formId}
                shiftplan={this.shiftplan}
                shift={this.shift}
                onCreateShiftSuccess={(payload) => { this.$emit('createShiftSuccess', payload); }}
                onUpdateShiftSuccess={(payload) => { this.$emit('updateShiftSuccess', payload); }}
              />
            ) : (
              <Tabs
                list={this.tabsList}
                onSelect={(e) => { this.$router.push({ name: e.payload.id }); }}
                selected={this.$route?.name}
                passthrough={true}
              >
                <router-view
                  class={styles.dialogShiftTabInner}
                  onRefetchShift={({ useBackgroundFetch }) => this.$emit('refetchShift', { useBackgroundFetch })}
                  onSubmitStateChange={this.onSubmitStateChange}
                  onCloseClick={this.onCloseClick}
                  formId={this.formId}
                  shiftplan={this.shiftplan}
                  shift={this.shift}
                  onCreateShiftSuccess={(payload) => { this.$emit('createShiftSuccess', payload); }}
                  onUpdateShiftSuccess={(payload) => { this.$emit('updateShiftSuccess', payload); }}
                />
              </Tabs>
            )
          }
        </div>

        <DialogConfirmDelete
          isOpen={this.isDeleteConfirmDialogOpen}
          isSubmitting={this.isDeleting}
          onCancel={this.onDeleteCancel}
          onConfirm={this.onDeleteConfirm}
          title={this.$t('shifts.modalDelete.title')}
        >
          {this.$t('shifts.modalDelete.message')}
        </DialogConfirmDelete>

        <DialogShiftConnected
          isOpen={this.isConnectedShiftsDeleteConfirmDialogOpen}
          isDestructive={true}
          onConfirmSingleShift={() => this.onUpdateConnectedShift(false)}
          onConfirmConnectedShifts={() => this.onUpdateConnectedShift(true)}
        />

        {this.$route?.meta?.hasDeleteButton
          && this.isManagingShiftsAllowed
          && !this.hasError
          && (
            <Button
              color={ButtonColor.ERROR}
              disabled={this.isButtonsDisabled}
              size={Size.SMALL}
              slot={Slot.BUTTONS_LEFT}
              type="button"
              kind={ButtonKind.GHOST}
              onClick={() => { this.$emit('deleteClick'); }}
            >
              {this.$t('general.buttonDelete')}
            </Button>
          )}

        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isButtonsDisabled}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.STROKE}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        {(this.$route?.meta?.hasSaveButton || this.$route?.meta?.hasEditButton)
          && this.isManagingShiftsAllowed
          && !this.hasError
          && (
            <Button
              color={ButtonColor.PRIMARY}
              form={this.formId}
              disabled={this.isSubmitting || this.isButtonsDisabled}
              size={Size.SMALL}
              slot={Slot.BUTTONS_RIGHT}
              type="submit"
            >
              {
                this.$route?.meta?.hasSaveButton
                  ? this.$t('general.buttonSave')
                  : this.$t('general.buttonUpdate')
              }
            </Button>
          )}
      </DialogWithSpinnerAndError>
    );
  }
}
