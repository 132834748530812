import { FiltersMap, StoreState as CalendarFilterStoreState } from 'components/calendar-common/filters/Store';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Action as FilterStoreAction } from 'store/filter-store';
import TableStoreAction from 'components/table/store/Action';
import type { SetFilterPayload, SetSelectionFunction } from 'components/table/store/Store';
import ButtonResetFilters from '../button-reset-filters/ButtonResetFilters';
import { locationsPositionsFilterNS, StoreState as LocationsPositionsFilterStoreState } from '../filter-box-locations-position/store/Store';
import { quickFilterNS, StoreState as QuickFilterFilterStoreState } from '../filter-box-quick/store/Store';
import { shiftPresetsFilterNS, StoreState as ShiftPresetsFilterStoreState } from '../filter-box-shift-preset/store/Store';
import { tagsFilterNS, StoreState as TagsFilterStoreState } from '../filter-box-tag/store/Store';
import QuickFilterAction from '../filter-box-quick/store/Action';
import { employmentFilterNS } from '../filter-box-employment/store/Store';
import type { Filter } from '../filter-box-employment/store/Store';
import { assignmentGroupsFilterNS } from '../filter-box-assignment-group/store/Store';
import { shiftScheduleNS } from '../store/Store';
import { shiftRotationGroupsFilterNS } from '../filter-box-shift-rotation-group/store/Store';

@Component
export default class CalendarResetFiltersContainer extends TsxComponent<{}> {
  @shiftScheduleNS.Getter
  public shiftScheduleQuickFilters: FiltersMap[];

  @quickFilterNS.State('filters')
  protected quickFilters: QuickFilterFilterStoreState['filters'];

  @quickFilterNS.Action(QuickFilterAction.SET_FILTERS)
  protected setQuickFilters: (quickFilters: FiltersMap[]) => Promise<void>;

  @shiftRotationGroupsFilterNS.State('selection')
  public shiftRotationGroupIds: CalendarFilterStoreState['selectedShiftRotationGroupIds'];

  @shiftRotationGroupsFilterNS.Action(FilterStoreAction.SET_SELECTION)
  protected setShiftRotationGroupsSelection: (tagIds: number[]) => Promise<void>;

  @tagsFilterNS.State('selection')
  protected tagIds: TagsFilterStoreState['selection'];

  @tagsFilterNS.Action(TableStoreAction.SET_SELECTION)
  protected setTagsSelection: (tagIds: number[]) => Promise<void>;

  @locationsPositionsFilterNS.State('selection')
  protected locationsPositionIds: LocationsPositionsFilterStoreState['selection'];

  @locationsPositionsFilterNS.Action(FilterStoreAction.SET_SELECTION)
  protected setLocationsPositionsSelection: (
    locationsPositionIds: number[]
  ) => Promise<void>;

  @shiftPresetsFilterNS.State('selection')
  protected shiftPresetIds: ShiftPresetsFilterStoreState['selection'];

  @shiftPresetsFilterNS.Action(FilterStoreAction.SET_SELECTION)
  protected setShiftPresetsSelection: (shiftPresetIds: number[]) => Promise<void>;

  @employmentFilterNS.State('filters')
  protected employmentFilters: Filter;

  @employmentFilterNS.State('selection')
  protected employmentIds: number[];

  @employmentFilterNS.Action(TableStoreAction.SET_FILTER)
  private setEmploymentsFilter: (
    payload: SetFilterPayload<Filter, keyof Filter>
  ) => Promise<void>;

  @employmentFilterNS.Action(TableStoreAction.SET_SELECTION)
  private setEmploymentsSelection: SetSelectionFunction;

  @assignmentGroupsFilterNS.State('filters')
  protected assignmentGroupsFilters: Filter;

  @assignmentGroupsFilterNS.State('selection')
  protected assignmentGroupsIds: number[];

  @assignmentGroupsFilterNS.Action(TableStoreAction.SET_SELECTION)
  private setAssignmentGroupsSelection: SetSelectionFunction;

  private get selectedFiltersCount() {
    return this.quickFilters
      /*
        custom shift presets are addressed in both quick filters
        and shiftPresets filter, so we filter one out
      */
      .filter(filter => this.shiftScheduleQuickFilters.includes(filter)
        && filter !== FiltersMap.SHIFTS_WITHOUT_PRESET).length
      + this.shiftPresetIds.length
      + this.locationsPositionIds.length
      + this.tagIds.length
      + (this.shiftRotationGroupIds ? this.shiftRotationGroupIds.length : 0)
      + (this.employmentFilters.search
        && this.employmentFilters.search?.length ? 1 : 0)
      + this.employmentIds.length
      + this.assignmentGroupsIds.length;
  }

  private onClick() {
    this.setQuickFilters([]);
    this.setShiftPresetsSelection([]);
    this.setLocationsPositionsSelection([]);
    this.setTagsSelection([]);
    this.setShiftRotationGroupsSelection([]);
    // employments filter needs to be reset as well
    this.setEmploymentsFilter({ key: 'search', value: '' });
    this.setEmploymentsSelection([]);
    this.setAssignmentGroupsSelection([]);
  }

  public render() {
    return (
      <ButtonResetFilters
        onClick={() => this.onClick()}
        selectedFiltersCount={this.selectedFiltersCount}
      />
    );
  }
}
