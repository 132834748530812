export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date object */
  Date: string;
  /** Json object */
  Json: any;
};

export type GQLQuery = {
  _empty: Maybe<Scalars['String']>;
  absenceReasons: GQLAbsenceReasonCollection;
  absenceReasonsStats: GQLAbsenceReasonsStatsResponse;
  absences: GQLAbsenceCollection;
  absenceDuration: Maybe<GQLAbsenceDuration>;
  absencesEmployments: GQLEmploymentCollection;
  assignmentGroups: GQLAssignmentGroupCollection;
  availabilities: GQLAvailabilityCollection;
  /** Gets statistics per employee about their availabilities */
  availabilityAggregations: GQLAvailabilityAggregationCollection;
  availabilityExceptions: GQLAvailabilityExceptionCollection;
  backgroundJobs: GQLBackgroundJobCollection;
  calendarAggregations: Array<GQLCalendarAggregation>;
  chatEmployments: GQLChatEmploymentCollection;
  chatMessages: GQLChatMessageCollection;
  chats: GQLChatCollection;
  companies: GQLCompanyCollection;
  customFields: GQLCustomFieldCollection;
  /** Get the DayNotes for a specific shiftplanId */
  dayNotes: GQLDayNoteCollection;
  employments: GQLEmploymentCollection;
  /** List of employments of current user */
  myEmployments: Array<GQLEmployment>;
  employmentLiveInfo: Maybe<GQLPunchTimingLiveInfo>;
  employmentsAvailabilities: Array<GQLAvailability>;
  employmentEvaluation: Maybe<GQLEmploymentEvaluation>;
  employmentEvaluationPayments: GQLPaymentCollection;
  employmentsPositions: GQLEmploymentsPositionCollection;
  employmentsRotationGroups: GQLEmploymentsRotationGroupsCollection;
  employmentsSurvey: Maybe<GQLEmploymentsSurvey>;
  /** Get the UiSetting for a specific employmentId */
  employmentsUiSetting: GQLEmploymentsUiSetting;
  evaluationBreaks: GQLEvaluationBreakCollection;
  evaluations: GQLEvaluationCollection;
  evaluation: GQLEvaluation;
  evaluationPayments: GQLPaymentCollection;
  individualShiftRotations: GQLIndividualShiftRotationCollection;
  /** Get the configured languages for a given Company */
  languages: GQLLanguageCollection;
  liveStaffShifts: GQLLiveStaffShiftCollection;
  locations: GQLLocationCollection;
  locationsPositions: GQLLocationsPositionCollection;
  newsfeeds: GQLNewsfeedCollection;
  notificationConfigurations: GQLNotificationConfigurationCollection;
  /** Get the Paygrades for a specific employmentId */
  paygrades: Maybe<GQLPaygradeCollection>;
  paygradeTypes: Maybe<GQLPaygradeTypeCollection>;
  paymentsCalculation: GQLPaymentsCalculation;
  payslips: GQLPayslipCollection;
  positions: GQLPositionCollection;
  punchState: Maybe<GQLPunchStateOrError>;
  punchLocationsPositions: Maybe<GQLPunchLocationsPositions>;
  qualifications: GQLQualificationCollection;
  /** Get the Rights of the Company with the given ID */
  rights: GQLRightCollection;
  /** Get the Rights of the User in the Company with the given ID */
  myRights: Maybe<Array<Maybe<GQLRight>>>;
  shiftAssignmentGroups: GQLShiftAssignmentGroupCollection;
  shiftAvailableEmployments: GQLShiftAvailableEmploymentCollection;
  shiftBreaks: GQLShiftBreakCollection;
  shiftEvaluationTags: GQLTagCollection;
  /** Get the Paygrades for a specific shiftId */
  shiftPaygrades: GQLShiftPaygradeCollection;
  shiftplans: GQLShiftplanCollection;
  /** Get NotificationCount for an existing shiftplan */
  shiftplanNotifications: GQLShiftplanNotifications;
  shiftPresets: GQLShiftPresetCollection;
  requests: GQLRequestCollection;
  shiftRotationGroups: GQLShiftRotationGroupCollection;
  shiftRotations: GQLShiftRotationCollection;
  shifts: GQLShiftCollection;
  shiftsTags: GQLShiftsTagCollection;
  specialDates: GQLSpecialDateCollection;
  staffShifts: GQLStaffShiftCollection;
  staffShiftsTags: GQLStaffShiftsTagCollection;
  tags: GQLTagCollection;
  translations: Maybe<Array<Maybe<GQLTranslation>>>;
  users: GQLUserCollection;
};

export type GQLQueryAbsenceReasonsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryAbsenceReasonsStatsArgs = {
  companyId: Scalars['Int'];
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  employmentId: Scalars['Int'];
  locationIds: Maybe<Array<Scalars['Int']>>;
  attachment: Maybe<Scalars['Boolean']>;
};

export type GQLQueryAbsencesArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  absenceReasonId: Maybe<Scalars['Int']>;
  absenceReasonIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  attachment: Maybe<Scalars['Boolean']>;
  locationId: Maybe<Scalars['Int']>;
  locationIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  employmentStatuses: Maybe<Array<GQLEmploymentStatus>>;
  state: Maybe<GQLAbsenceState>;
  states: Maybe<Array<Maybe<GQLAbsenceState>>>;
  paid: Maybe<Scalars['Boolean']>;
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  orderKey: Maybe<Scalars['String']>;
  orderDir: Maybe<GQLOrderDirection>;
};

export type GQLQueryAbsenceDurationArgs = {
  employmentId: Scalars['Int'];
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
  companyId: Scalars['Int'];
  absenceDays: Maybe<Scalars['Float']>;
  absenceReasonId: Scalars['Int'];
};

export type GQLQueryAbsencesEmploymentsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderKey: Maybe<Scalars['String']>;
  orderDir: Maybe<GQLOrderDirection>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type GQLQueryAssignmentGroupsArgs = {
  companyId: Scalars['Int'];
  shiftplanIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryAvailabilitiesArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  startsAt: Scalars['Date'];
  endsAt: Maybe<Scalars['Date']>;
};

export type GQLQueryAvailabilityAggregationsArgs = {
  companyId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  orderKey?: Maybe<GQLAvailabilityAggregationOrderKey>;
  orderDir?: Maybe<GQLOrderDirection>;
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
  locationIds: Maybe<Array<Scalars['Int']>>;
  positionIds: Maybe<Array<Scalars['Int']>>;
  employmentIds: Maybe<Array<Scalars['Int']>>;
};

export type GQLQueryAvailabilityExceptionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  availabilityId: Maybe<Scalars['Int']>;
  availabilityIds: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryBackgroundJobsArgs = {
  companyId: Scalars['Int'];
  ids: Maybe<Array<Scalars['String']>>;
};

export type GQLQueryCalendarAggregationsArgs = {
  companyId: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  locationIds: Maybe<Array<Scalars['Int']>>;
  locationsPositionIds: Maybe<Array<Scalars['Int']>>;
  employmentIds: Maybe<Array<Scalars['Int']>>;
};

export type GQLQueryChatEmploymentsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
};

export type GQLQueryChatMessagesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  recipientType: GQLChatType;
  recipientId: Scalars['Int'];
  lastMessageId: Maybe<Scalars['Int']>;
};

export type GQLQueryChatsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
};

export type GQLQueryCompaniesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  companyId: Maybe<Scalars['Int']>;
};

export type GQLQueryCustomFieldsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryDayNotesArgs = {
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type GQLQueryEmploymentsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  locationId: Maybe<Scalars['Int']>;
  locationIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  locationsPositionId: Maybe<Scalars['Int']>;
  locationsPositionIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftplanId: Maybe<Scalars['Int']>;
  shiftplanIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftRotationGroupId: Maybe<Scalars['Int']>;
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  isStakeholder: Maybe<Scalars['Boolean']>;
  isOwner: Maybe<Scalars['Boolean']>;
  isEmployee: Maybe<Scalars['Boolean']>;
  invitationState: Maybe<GQLEmploymentInvitationState>;
  invitationStates: Maybe<Array<Maybe<GQLEmploymentInvitationState>>>;
  includeLiveInfo?: Maybe<Scalars['Boolean']>;
  withDeleted: Maybe<Scalars['Boolean']>;
  notInShiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
};

export type GQLQueryMyEmploymentsArgs = {
  companyId: Maybe<Scalars['Int']>;
};

export type GQLQueryEmploymentLiveInfoArgs = {
  id: Scalars['Int'];
};

export type GQLQueryEmploymentsAvailabilitiesArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  startsAt: Scalars['Date'];
  endsAt: Maybe<Scalars['Date']>;
  availabilityIds: Maybe<Array<Scalars['Int']>>;
  employmentId: Scalars['Int'];
};

export type GQLQueryEmploymentEvaluationArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLQueryEmploymentEvaluationPaymentsArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLQueryEmploymentsPositionsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  locationsPositionId: Maybe<Scalars['Int']>;
  locationsPositionIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  responseFormat?: Maybe<GQLResponseFormat>;
};

export type GQLQueryEmploymentsRotationGroupsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Scalars['Int']>>;
  shiftRotationGroupId: Maybe<Scalars['Int']>;
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  shiftRotationId: Maybe<Scalars['Int']>;
  rotationType?: Maybe<Array<GQLRotationType>>;
};

export type GQLQueryEmploymentsSurveyArgs = {
  id: Scalars['Int'];
};

export type GQLQueryEmploymentsUiSettingArgs = {
  employmentId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type GQLQueryEvaluationBreaksArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Scalars['Int']>>;
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  staffShiftId: Maybe<Scalars['Int']>;
  staffShiftIds: Maybe<Array<Scalars['Int']>>;
  evaluationIds: Maybe<Array<Scalars['Int']>>;
  shiftId: Maybe<Scalars['Int']>;
  shiftIds: Maybe<Array<Scalars['Int']>>;
};

export type GQLQueryEvaluationsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  state: Maybe<GQLEvaluationState>;
  id: Maybe<Scalars['Int']>;
  locationId: Maybe<Scalars['Int']>;
  locationIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  positionId: Maybe<Scalars['Int']>;
  positionIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  locationsPositionId: Maybe<Scalars['Int']>;
  locationsPositionIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  includePayments: Maybe<Scalars['Boolean']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
};

export type GQLQueryEvaluationArgs = {
  staffShiftId: Scalars['Int'];
};

export type GQLQueryEvaluationPaymentsArgs = {
  staffShiftId: Scalars['Int'];
};

export type GQLQueryIndividualShiftRotationsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Scalars['Int']>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Scalars['Int']>>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Scalars['Int']>>;
};

export type GQLQueryLanguagesArgs = {
  companyId: Scalars['Int'];
};

export type GQLQueryLiveStaffShiftsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
};

export type GQLQueryLocationsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryLocationsPositionsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  locationId: Maybe<Scalars['Int']>;
  locationIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  positionId: Maybe<Scalars['Int']>;
  positionIds: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryNewsfeedsArgs = {
  companyId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryNotificationConfigurationsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryPaygradesArgs = {
  employmentId: Scalars['Int'];
};

export type GQLQueryPaygradeTypesArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
};

export type GQLQueryPaymentsCalculationArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  input: GQLPaymentsCalculationInput;
};

export type GQLQueryPayslipsArgs = {
  companyId: Maybe<Scalars['Int']>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type GQLQueryPositionsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryPunchStateArgs = {
  encryptedCompanyId: Maybe<Scalars['String']>;
  userToken: Maybe<Scalars['Int']>;
  companyId: Maybe<Scalars['Int']>;
};

export type GQLQueryPunchLocationsPositionsArgs = {
  shiftplanId: Scalars['Int'];
  encryptedCompanyId: Maybe<Scalars['String']>;
  userToken: Maybe<Scalars['Int']>;
  companyId: Maybe<Scalars['Int']>;
};

export type GQLQueryQualificationsArgs = {
  companyId: Scalars['Int'];
  ids: Maybe<Array<Scalars['Int']>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type GQLQueryRightsArgs = {
  companyId: Scalars['Int'];
};

export type GQLQueryMyRightsArgs = {
  companyId: Scalars['Int'];
};

export type GQLQueryShiftAssignmentGroupsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  assignmentGroupId: Maybe<Scalars['Int']>;
  assignmentGroupIds: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryShiftAvailableEmploymentsArgs = {
  companyId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  searchString: Maybe<Scalars['String']>;
  sortName?: Maybe<GQLShiftEmploymentSort>;
  sortDirection?: Maybe<GQLOrderDirection>;
  filters: Maybe<GQLShiftEmploymentFilter>;
  shiftId: Scalars['Int'];
};

export type GQLQueryShiftBreaksArgs = {
  companyId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Scalars['Int']>>;
  shiftId: Maybe<Scalars['Int']>;
  shiftIds: Maybe<Array<Scalars['Int']>>;
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
};

export type GQLQueryShiftEvaluationTagsArgs = {
  companyId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  shiftId: Maybe<Scalars['Int']>;
};

export type GQLQueryShiftPaygradesArgs = {
  shiftId: Scalars['Int'];
};

export type GQLQueryShiftplansArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  locationId: Maybe<Scalars['Int']>;
  locationIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderKey: Maybe<Scalars['String']>;
  orderDir: Maybe<GQLOrderDirection>;
};

export type GQLQueryShiftplanNotificationsArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLQueryShiftPresetsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type GQLQueryRequestsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftId: Maybe<Scalars['Int']>;
  shiftIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftplanId: Maybe<Scalars['Int']>;
  shiftplanIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  type: Maybe<GQLRequestType>;
  types: Maybe<Array<Maybe<GQLRequestType>>>;
};

export type GQLQueryShiftRotationGroupsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Scalars['Int']>>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Scalars['Int']>>;
  shiftRotationId: Maybe<Scalars['Int']>;
  shiftRotationIds: Maybe<Array<Scalars['Int']>>;
};

export type GQLQueryShiftRotationsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Scalars['Int']>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Scalars['Int']>>;
  shiftplanId: Maybe<Scalars['Int']>;
  shiftplanIds: Maybe<Array<Scalars['Int']>>;
};

export type GQLQueryShiftsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftplanId: Maybe<Scalars['Int']>;
  shiftplanIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftRotationGroupId: Maybe<Scalars['Int']>;
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  locationsPositionId: Maybe<Scalars['Int']>;
  locationsPositionIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftPresetIds: Maybe<Array<Scalars['Int']>>;
  tagId: Maybe<Scalars['Int']>;
  tagIds: Maybe<Array<Scalars['Int']>>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Scalars['Int']>>;
  connectedGroupId: Maybe<Scalars['Int']>;
  onlyOpen?: Maybe<Scalars['Boolean']>;
  withoutConflicts?: Maybe<Scalars['Boolean']>;
  withoutTags?: Maybe<Scalars['Boolean']>;
  orderKey?: Maybe<Scalars['String']>;
  orderDir?: Maybe<GQLOrderDirection>;
  extendedInfo?: Maybe<Scalars['Boolean']>;
  withoutShiftPresets?: Maybe<Scalars['Boolean']>;
};

export type GQLQueryShiftsTagsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftId: Maybe<Scalars['Int']>;
  shiftIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  tagId: Maybe<Scalars['Int']>;
  tagIds: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQuerySpecialDatesArgs = {
  companyId: Scalars['Int'];
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  locationIds: Maybe<Array<Scalars['Int']>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type GQLQueryStaffShiftsArgs = {
  companyId: Maybe<Scalars['Int']>;
  companyIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  createdAfter: Maybe<Scalars['Date']>;
  updatedAfter: Maybe<Scalars['Date']>;
  createdBefore: Maybe<Scalars['Date']>;
  updatedBefore: Maybe<Scalars['Date']>;
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  shiftId: Maybe<Scalars['Int']>;
  shiftIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  state: Maybe<GQLStaffShiftState>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftplanId: Maybe<Scalars['Int']>;
  shiftplanIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  onlyOpen?: Maybe<Scalars['Boolean']>;
};

export type GQLQueryStaffShiftsTagsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  staffShiftIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  tagIds: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GQLQueryTagsArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  locationId: Maybe<Scalars['Int']>;
  locationIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  context: Maybe<Array<GQLTagContext>>;
};

export type GQLQueryTranslationsArgs = {
  languageId: Scalars['Int'];
};

export type GQLQueryUsersArgs = {
  companyId: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type GQLAbsenceReasonCollection = GQLPaginatable & {
  items: Array<GQLAbsenceReason>;
  pagination: GQLPaginationInfo;
};

export type GQLPaginatable = {
  pagination: GQLPaginationInfo;
};

export type GQLPaginationInfo = {
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

/**
 * In order do distinguish between different kinds of Absences every Absence has an AbsenceReason.
 * Note that vacation as an AbsenceReason will automatically count off vacation days that have been
 * documented in an employee's payroll information.
 */
export type GQLAbsenceReason = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the AbsenceReason */
  id: Scalars['Int'];
  /** A human-readable representation of the AbsenceReason (e.g. vacation, sickness, overtime, other) */
  name: Scalars['String'];
  /** Does the AbsenceReason have a localized name? */
  hasLocalization: Scalars['Boolean'];
  /** ID of the associated Company */
  company: Maybe<GQLCompany>;
  /** Date when the Absence was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when the Absence was created */
  createdAt: Scalars['Date'];
  /** Date when the Absence was last updated */
  updatedAt: Scalars['Date'];
  /** Can users add attachments? */
  isAbsenceAttachmentsAllowed: Scalars['Boolean'];
  /** Allowed amount of days per calendar year for AbsenceReason */
  days: Scalars['Int'];
  /** Can vacation days be carried over to the next year? */
  carryOverDaysEnabled: Scalars['Boolean'];
  /** The deadline by which the carried over vacation days can be taken in format DD.MM */
  carryOverDaysDeadline: Maybe<Scalars['String']>;
};

export type GQLIdentifiable = {
  id: Scalars['Int'];
};

export type GQLTimestampable = {
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  deletedAt: Maybe<Scalars['Date']>;
};

/**
 * Every User is associated with at least one Company. It contains important settings about the use
 * of the shyftplan software.
 */
export type GQLCompany = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Company */
  id: Scalars['Int'];
  /** Date when the Company was created */
  createdAt: Scalars['Date'];
  /** Date when the Company was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Company was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Show assignment groups? */
  assignmentGroupEnabled: Maybe<Scalars['Boolean']>;
  /** Should requests be automatically accepted as long as there are open slots in the Shift? */
  autoAcceptShiftRequestEnabled: Maybe<Scalars['Boolean']>;
  /** End punch time automatically? */
  autoPunchout: Maybe<Scalars['Boolean']>;
  /** Billing method used by the Company (direct, corrected or not set) */
  billingMethod: Maybe<Scalars['String']>;
  /** Currency used by the Company */
  currency: Maybe<Scalars['String']>;
  /** Default language used by the Company */
  defaultLanguageId: Maybe<Scalars['Int']>;
  /** Users can submit a different shift payment */
  editShiftPayments: Maybe<Scalars['Boolean']>;
  /** Can an employee create a Shift by starting the punch time? */
  employeeShift: Maybe<Scalars['Boolean']>;
  /** Name of the Company image file */
  imageName: Maybe<Scalars['String']>;
  imageUid: Maybe<Scalars['String']>;
  /** Email used for sending invoices to the Company */
  invoiceEmail: Maybe<Scalars['String']>;
  /** Is attaching files to absences allowed? */
  isAbsenceAttachmentsAllowed: Maybe<Scalars['Boolean']>;
  /** Is editing absences enabled */
  isAbsenceEditEnabled: Maybe<Scalars['Boolean']>;
  /** Enable billing reminder */
  isBillingRemindersEnabled: Maybe<Scalars['Boolean']>;
  /** Enable employees to post messages to the dashboard */
  isDashboardMessagesAllowed: Maybe<Scalars['Boolean']>;
  /** Is billing method deactivated? */
  isDeactivated: Maybe<Scalars['Boolean']>;
  /** Allow employees to see their own hour account */
  isEmployeeAllowedToSeeOwnHourAccount: Maybe<Scalars['Boolean']>;
  /** Show monetary value on evaluation */
  isMoneyShownOnEvaluation: Maybe<Scalars['Boolean']>;
  /** Is overassignment allowed? */
  isOverassignmentAllowed: Maybe<Scalars['Boolean']>;
  /** Is paying via PayPal enabled? */
  isPaypalEnabled: Maybe<Scalars['Boolean']>;
  /** Are payslips enabled? */
  isPayslipsEnabled: Maybe<Scalars['Boolean']>;
  /** Are tags enabled? */
  isTagsAllowed: Scalars['Boolean'];
  /** Has the VAT number been validated? */
  isVatIdValidated: Maybe<Scalars['Boolean']>;
  /** Name of the Company */
  name: Scalars['String'];
  /** At which step of the company onboarding tutorial is this Company? */
  onboardingStep: Maybe<Scalars['Int']>;
  /** Information about the picture of the Company */
  pictureData: Maybe<GQLPictureData>;
  /** Estimated number of employees stated during registration */
  propableEmployees: Maybe<Scalars['Int']>;
  /** Punch out automatically after how many hours? */
  punchoutHour: Maybe<Scalars['Int']>;
  /** Round for employee’s benefit? */
  roundFair: Maybe<Scalars['Boolean']>;
  /** Round punch time to which granularity (0, 5, 15, 30)? */
  roundTo: Maybe<Scalars['Float']>;
  /** If punching in before the beginning of the Shift is stored as a punch-in at the Shift start */
  roundToShift: Maybe<Scalars['Boolean']>;
  /** ID of the Company's Salesforce account */
  salesforceId: Maybe<Scalars['Int']>;
  /** Show punchtime token on user modal? */
  showUserToken: Maybe<Scalars['Boolean']>;
  /** Has the Employee off from work if only one of their Locations has a special day */
  specialDateCountedOff: Maybe<Scalars['Boolean']>;
  /** Date when the survey was completed */
  surveyDoneAt: Maybe<Scalars['Date']>;
  /** Preferred time zone */
  timeZone: Maybe<Scalars['String']>;
  /** VAT number of this Company */
  vatId: Maybe<Scalars['String']>;
  /** Allow employees to see other employees in their Positions */
  viewPersonal: Maybe<Scalars['Boolean']>;
  /** Is shift swap enabled? */
  shiftSwapEnabled: Maybe<Scalars['Boolean']>;
  /** Can managers manage the availabilities of their employees */
  canManageAvailabilities: Maybe<Scalars['Boolean']>;
  /** Are shift rotations enabled? */
  shiftRotationEnabled: Maybe<Scalars['Boolean']>;
  swapRequestMinHour: Maybe<Scalars['Int']>;
  /** Are shift swaps automatically accepted? */
  autoAcceptSwapRequests: Maybe<Scalars['Boolean']>;
  /** Is creating absences by employees allowed? */
  canEmployeesCreateAbsences: Maybe<Scalars['Boolean']>;
  /** Are shift presets enabled? */
  canUseShiftPresets: Maybe<Scalars['Boolean']>;
  /** Are bring shifts enabled? */
  canUseBringShifts: Maybe<Scalars['Boolean']>;
  /** Is automation to fill shiftplans enabled? */
  canFillShiftplans: Maybe<Scalars['Boolean']>;
  /** Are qualifications enabled? */
  canUseQualifications: Maybe<Scalars['Boolean']>;
  /** Is locking of shiftplans enabled? */
  canLockShiftplans: Maybe<Scalars['Boolean']>;
  /** Chat-related settings */
  chatSettings: Maybe<GQLChatSettings>;
  /** The Locations associated with the Company */
  locations: Maybe<Array<GQLLocation>>;
  /** The Positions associated with the Company */
  positions: Maybe<Array<GQLPosition>>;
  /** The Newsfeeds associated with the Company */
  newsfeeds: Maybe<Array<GQLNewsfeed>>;
  /** The Employments associated with the Company */
  employments: Maybe<Array<GQLEmployment>>;
  /** The Default Languages set by the Company */
  defaultLanguage: GQLLanguage;
};

export type GQLPictureData = {
  pictureLarge: Maybe<Scalars['String']>;
  pictureSmall: Maybe<Scalars['String']>;
  pictureMedium: Maybe<Scalars['String']>;
  backgroundColor: Maybe<Scalars['String']>;
};

export type GQLChatSettings = {
  isChatAllowed: Maybe<Scalars['Boolean']>;
  employmentChats: Maybe<GQLEmploymentChats>;
  locationChatsEnabled: Maybe<Scalars['Boolean']>;
  locationsPositionChatsEnabled: Maybe<Scalars['Boolean']>;
};

export type GQLEmploymentChats = {
  enabled: Maybe<Scalars['Boolean']>;
  employeeCanChatTo: Maybe<GQLEmployeeCanChatTo>;
  stakeholderCanChatTo: Maybe<GQLStakeholderCanChatTo>;
};

export type GQLEmployeeCanChatTo = {
  anyone: Maybe<Scalars['Boolean']>;
  sameLocation: Maybe<Scalars['Boolean']>;
  sameLocationsPosition: Maybe<Scalars['Boolean']>;
};

export type GQLStakeholderCanChatTo = {
  anyone: Maybe<Scalars['Boolean']>;
  employeesInLocation: Maybe<Scalars['Boolean']>;
  employeesInLocationsPosition: Maybe<Scalars['Boolean']>;
  stakeholdersInLocation: Maybe<Scalars['Boolean']>;
  stakeholdersInLocationsPosition: Maybe<Scalars['Boolean']>;
};

/**
 * Every Company and Position has at least one Location. The join table LocationsPosition shows
 * which Position is associated with which Location.
 */
export type GQLLocation = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Location */
  id: Scalars['Int'];
  /** Date when the Location was created */
  createdAt: Scalars['Date'];
  /** Date when the Location was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Location was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Name of the Location */
  name: Scalars['String'];
  /** Sorting position of the Location */
  sort: Maybe<Scalars['Int']>;
  actionType: Maybe<Scalars['String']>;
  /** Company associated with the Location */
  company: Maybe<GQLCompany>;
  /** ID of the Company associated with the Location */
  companyId: Maybe<Scalars['Int']>;
  /** The Positions associated with the Location */
  positions: Maybe<Array<Maybe<GQLPosition>>>;
};

/**
 * Every Company can have multiple Positions. Positions can be associated with several
 * Locations of a Company.
 */
export type GQLPosition = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Position */
  id: Scalars['Int'];
  /** Date when the Position was created */
  createdAt: Scalars['Date'];
  /** Date when the Position was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Position was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Name of the Position */
  name: Scalars['String'];
  /** Description of the Position */
  description: Maybe<Scalars['String']>;
  /** Background color for the Position */
  color: Scalars['String'];
  /** Text color for the Position */
  textColor: Scalars['String'];
  /** Company associated with the Position */
  company: Maybe<GQLCompany>;
  /** ID of the Company associated with the Position */
  companyId: Maybe<Scalars['Int']>;
  /** Position comment (shown on Shift) */
  note: Maybe<Scalars['String']>;
  actionType: Maybe<Scalars['String']>;
  /** Sorting position of the Position */
  sort: Maybe<Scalars['Int']>;
};

/**
 * The newsfeed contains notifications about created Absences, Evaluations, PunchTimes, Requests and
 * Shiftplans. It is also possible to post messages via newsfeed.
 */
export type GQLNewsfeed = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Newsfeed */
  id: Scalars['Int'];
  /** Date when the Newsfeed was created */
  createdAt: Scalars['Date'];
  /** Date when the Newsfeed was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Newsfeed was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Newsfeed item type */
  key: Maybe<Scalars['String']>;
  /** Optional message posted to the Newsfeed */
  message: Maybe<Scalars['String']>;
  objekt: Maybe<Scalars['Json']>;
  objektType: Maybe<Scalars['String']>;
  objektId: Maybe<Scalars['String']>;
  subjekt: Maybe<Scalars['Json']>;
  subjektType: Maybe<Scalars['String']>;
  subjektId: Maybe<Scalars['String']>;
  context: Maybe<Scalars['Json']>;
  contextType: Maybe<Scalars['String']>;
  contextId: Maybe<Scalars['String']>;
  /** Additional metadata related to the Newsfeed item */
  metadata: Maybe<Scalars['Json']>;
  /** The User associated with the Newsfeed */
  user: Maybe<Scalars['Json']>;
  /** The Company associated with the Newsfeed */
  company: Maybe<GQLCompany>;
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmployment = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  /** Date when the Employment was created */
  createdAt: Scalars['Date'];
  /** Date when the Employment was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Employment was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** First name of the employee in the Employment */
  firstName: Scalars['String'];
  /** Last name of the employee in the Employment */
  lastName: Scalars['String'];
  /** Email address of the employee in the Employment */
  email: Maybe<Scalars['String']>;
  /** Phone number of the employee in the Employment */
  phoneNumber: Maybe<Scalars['String']>;
  /** Staff number of the employee in the Employment */
  staffNumber: Maybe<Scalars['String']>;
  /** Punchtime token of the employee in the Employment */
  userToken: Maybe<Scalars['Int']>;
  /** Leaving date of the employee in the Employment */
  exitDate: Maybe<Scalars['Date']>;
  /** ID of the associated payroll type */
  billingTypeId: Maybe<Scalars['Int']>;
  /** Maximum number of vacation days of the employee in the Employment */
  maxVacationDays: Maybe<Scalars['Float']>;
  /** Monthly pay of the employee in the Employment */
  monthlyPay: Maybe<Scalars['Float']>;
  /** Maximum earnings per month */
  maximumMoney: Maybe<Scalars['Float']>;
  /** Maximum earnings per month enabled? */
  maximumMoneyEnabled: Maybe<Scalars['Boolean']>;
  /** State of the invitation to use shyftplan sent to the employee in the Employment */
  invitationState: GQLEmploymentInvitationState;
  /** Comments */
  note: Maybe<Scalars['String']>;
  /** Is the employee in the role of employee in the Employment? */
  isEmployee: Maybe<Scalars['Boolean']>;
  /** Is the employee's hour account enabled in the Employment? */
  hourEnabled: Maybe<Scalars['Boolean']>;
  /** Is the employee paid monthly in the Employment? */
  isPaidMonthly: Maybe<Scalars['Boolean']>;
  /** Is the employee a stakeholder in the Employment? */
  isStakeholder: Maybe<Scalars['Boolean']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: Maybe<Scalars['Boolean']>;
  /** Information related to the working hours of the employee in the Employment */
  employmentWorkHour: Maybe<GQLEmploymentWorkHour>;
  /** Information related to the picture of the employee in the Employment */
  pictureData: Maybe<GQLPictureData>;
  /** Information related to the current punch timing of the employee in the Employment */
  punchTimingLiveInfo: Maybe<GQLPunchTimingLiveInfo>;
  /** ID of the Company associated with the Employment */
  companyId: Maybe<Scalars['Int']>;
  /** Number of vacation hours per working day */
  vacationHours: Maybe<Scalars['Float']>;
  /** The Language associated with the Employment */
  language: Maybe<GQLLanguage>;
  /** The Company associated with the Employment */
  company: Maybe<GQLCompany>;
  /** Information related to the Paygrades of the employee in the Employment */
  paygrades: Maybe<GQLPaygradeCollection>;
  /** The User associated with the Employment */
  user: Maybe<GQLUser>;
  /** User id associated with Employment */
  userId: Scalars['Int'];
  /** The Absences associated with the Employment */
  absences: Maybe<Array<Maybe<GQLAbsence>>>;
  /** Ids of ShiftRotationGroups associated with the Employment */
  shiftRotationGroupIds: Array<Scalars['Int']>;
  /** The EmploymentsRotationGroups associated with the Employment */
  employmentsShiftRotationGroups: Array<GQLEmploymentsRotationGroup>;
  /** The LocationsPositions associated with the Employment */
  locationsPositions: Maybe<Array<Maybe<GQLLocationsPosition>>>;
  /** IDs of LocationsPositions associated with the Employment */
  locationsPositionIds: Maybe<Array<Scalars['Int']>>;
  /** IDs of Qualifications associated with the Employment */
  qualificationIds: Maybe<Array<Scalars['Int']>>;
  /** Qualifications associated with the Employment */
  qualifications: Array<GQLQualification>;
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmploymentShiftRotationGroupIdsArgs = {
  rotationId: Maybe<Scalars['Int']>;
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmploymentEmploymentsShiftRotationGroupsArgs = {
  shiftRotationId: Maybe<Scalars['Int']>;
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmploymentLocationsPositionsArgs = {
  locationId: Maybe<Scalars['Int']>;
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmploymentLocationsPositionIdsArgs = {
  locationId: Maybe<Scalars['Int']>;
};

export enum GQLEmploymentInvitationState {
  NEW = 'new',
  REFUSED = 'refused',
  ACCEPTED = 'accepted'
}

export type GQLEmploymentWorkHour = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  /** Date when the EmploymentWorkHour was created */
  createdAt: Scalars['Date'];
  /** Date when the EmploymentWorkHour was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the EmploymentWorkHour was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  vacationMinutes: Maybe<Scalars['Int']>;
  monMinutes: Maybe<Scalars['Int']>;
  tueMinutes: Maybe<Scalars['Int']>;
  wedMinutes: Maybe<Scalars['Int']>;
  thuMinutes: Maybe<Scalars['Int']>;
  friMinutes: Maybe<Scalars['Int']>;
  satMinutes: Maybe<Scalars['Int']>;
  sunMinutes: Maybe<Scalars['Int']>;
  weeklyMinutes: Maybe<Scalars['Int']>;
  isMonthly: Maybe<Scalars['Boolean']>;
  monthlyMinutes: Maybe<Scalars['Int']>;
  /** Is the employee working on Mondays in the Employment? */
  isWorkingOnMon: Maybe<Scalars['Boolean']>;
  /** Is the employee working on Tuesdays in the Employment? */
  isWorkingOnTue: Maybe<Scalars['Boolean']>;
  /** Is the employee working on Wednesdays in the Employment? */
  isWorkingOnWed: Maybe<Scalars['Boolean']>;
  /** Is the employee working on Thursdays in the Employment? */
  isWorkingOnThu: Maybe<Scalars['Boolean']>;
  /** Is the employee working on Fridays in the Employment? */
  isWorkingOnFri: Maybe<Scalars['Boolean']>;
  /** Is the employee working on Saturdays in the Employment? */
  isWorkingOnSat: Maybe<Scalars['Boolean']>;
  /** Is the employee working on Sundays in the Employment? */
  isWorkingOnSun: Maybe<Scalars['Boolean']>;
  /** The Employment associated with the EmploymentWorkHour */
  employment: Maybe<GQLEmployment>;
};

export type GQLPunchTimingLiveInfo = {
  state: Maybe<Scalars['String']>;
  punchTiming: Maybe<GQLPunchTiming>;
  punchBreak: Maybe<GQLPunchBreak>;
  staffShift: Maybe<GQLStaffShift>;
};

/** PunchTiming defines punched start and end time of a Shift, as well as punched breaks. */
export type GQLPunchTiming = GQLTimestampable & GQLIdentifiable & {
  /** Unique identifier of the PunchTiming */
  id: Scalars['Int'];
  /** StaffShift associated with the PunchTiming */
  staffShift: Maybe<GQLStaffShift>;
  /** Start of the PunchTiming */
  startTime: Maybe<Scalars['Date']>;
  /** End of the PunchTiming */
  endTime: Maybe<Scalars['Date']>;
  /** Break time in minutes */
  breakTime: Maybe<Scalars['Float']>;
  /** Date when the PunchTiming was created */
  createdAt: Scalars['Date'];
  /** Date when the PunchTiming was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the PunchTiming was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Additional break time in minutes */
  additionalBreakTime: Maybe<Scalars['Float']>;
  /** Start of the overtime */
  overtimeStart: Maybe<Scalars['Date']>;
};

/** A StaffShift defines the assignment of an Employment to a Shift. */
export type GQLStaffShift = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the StaffShift */
  id: Scalars['Int'];
  /** Date when the StaffShift was created */
  createdAt: Scalars['Date'];
  /** Date when the StaffShift was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the StaffShift was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** State of the StaffShift */
  state: GQLStaffShiftState;
  /** Date when the StaffShift was accepted? */
  acceptedAt: Maybe<Scalars['Date']>;
  shiftAssignmentGroupId: Maybe<Scalars['Int']>;
  /** Accumulated working time (without breaks) */
  totalMinutes: Maybe<Scalars['Int']>;
  /** Accumulated Payments */
  totalPayments: Maybe<Scalars['Float']>;
  /** Employment associated with the StaffShift */
  employment: Maybe<GQLEmployment>;
  /** Shift associated with the StaffShift */
  shift: Maybe<GQLShift>;
  /** StaffShiftsTags assigned to the StaffShift */
  staffShiftsTags: Array<GQLStaffShiftsTag>;
};

export enum GQLStaffShiftState {
  NO_EVALUATION = 'no_evaluation',
  DONE_EVALUATION = 'done_evaluation',
  NEEDS_EVALUATION = 'needs_evaluation',
  PUNCHTIMED = 'punchtimed',
  NO_SHOW = 'no_show'
}

/**
 * A Shift defines a working slot for a defined amount of Employments at a Position on a Shiftplan.
 * Please note: The assigned position is set by the LocationsPosition where the Location needs to
 * match the the Shiftplan it is linked to.
 */
export type GQLShift = GQLIdentifiable & GQLTimestampable & GQLTimeframeable & {
  /** Unique identifier of the Shift */
  id: Scalars['Int'];
  /** Date when the Shift was created */
  createdAt: Scalars['Date'];
  /** Date when the Shift was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Shift was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when the Shift starts */
  startsAt: Scalars['Date'];
  /** Date when the Shift ends */
  endsAt: Scalars['Date'];
  /** Duration of breaks in minutes */
  breakTime: Scalars['Float'];
  /** Duration of unpaid breaks in minutes */
  untimedBreakTime: Scalars['Float'];
  /** Amount of employees assignable to the Shift */
  workers: Scalars['Int'];
  /** Can employees create Evaluations? */
  canEvaluate: Scalars['Boolean'];
  /** Note for the Shift */
  note: Maybe<Scalars['String']>;
  /** Manager note for the Shift (can only be seen by stakeholders) */
  managerNote: Maybe<Scalars['String']>;
  connectedGroupId: Maybe<Scalars['Int']>;
  /** Should the Shift be accounted? */
  untimed: Maybe<Scalars['Boolean']>;
  /** Can the Shift be managed by the user? */
  canManage: Maybe<Scalars['Boolean']>;
  /** Number of StaffShifts associated with this Shift. Provided only when extendedInfo is true */
  staffShiftsCount: Maybe<Scalars['Int']>;
  /** Number of pending Evaluations for this shift. Provided only when extendedInfo is true */
  staffShiftsNeedsEvaluationCount: Maybe<Scalars['Int']>;
  /** Requests associated with the Shift */
  requests: Array<GQLRequest>;
  /** StaffShifts associated with the Shift */
  staffShifts: Maybe<Array<GQLStaffShift>>;
  /** Evaluations associated with the Shift */
  evaluations: Maybe<Array<Maybe<GQLEvaluation>>>;
  /** Shiftplan associated with the Shift */
  shiftplan: GQLShiftplan;
  /** Id of the Shiftplan associated with the Shift */
  shiftplanId: Scalars['Int'];
  /** LocationsPosition associated with the Shift */
  locationsPosition: GQLLocationsPosition;
  /** Tags associated with the Shift */
  tags: Maybe<Array<GQLTag>>;
  /** EvaluationTags associated with the Shift */
  shiftEvaluationTags: Maybe<Array<GQLTag>>;
  /** AssignmentGroups associated with the Shift */
  shiftAssignmentGroups: Maybe<Array<GQLShiftAssignmentGroup>>;
  /** ShiftPreset associated with the Shift */
  shiftPreset: Maybe<GQLShiftPreset>;
  /** ShiftRotationGroups the shift is associated with */
  shiftRotationGroups: Maybe<Array<GQLShiftRotationGroup>>;
  /** The ids of the ShiftRotationGroups the shift is associated with */
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  paygrades: Maybe<Array<GQLPaygrade>>;
  /** The setting for whether requests will be automatically accepted and how conflicts will be handled */
  autoAccept: GQLAutoAcceptRequestSetting;
  /** The additional breaks the shift is associated with */
  shiftBreaks: Array<GQLShiftBreak>;
  /** The ids and count of ShiftQualification the shift is associated with */
  shiftQualifications: Maybe<Array<GQLShiftQualification>>;
};

/**
 * A Shift defines a working slot for a defined amount of Employments at a Position on a Shiftplan.
 * Please note: The assigned position is set by the LocationsPosition where the Location needs to
 * match the the Shiftplan it is linked to.
 */
export type GQLShiftShiftEvaluationTagsArgs = {
  companyId: Scalars['Int'];
};

export type GQLTimeframeable = {
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
};

/**
 * A Request defines an employee's wish to make a
 * change to a Shift. If an employee is not currently
 * assigned to a Shift they can request an application.
 * If an employee is currently assigned to a Shift they
 * can request a change.
 */
export type GQLRequest = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Request */
  id: Scalars['Int'];
  /** Date when the Request was created */
  createdAt: Scalars['Date'];
  /** Date when the Request was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Request was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Type of Request */
  type: Maybe<GQLRequestType>;
  /** The employment ids of employees that accepted this request */
  acceptedBy: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Shift associated with the Request */
  shift: Maybe<GQLShift>;
  /** Employment associated with the Request */
  employment: Maybe<GQLEmployment>;
  /** The state of the swap request (if it is one) */
  state: Maybe<GQLReplaceRequestState>;
  /** Date when the request was processed by automation */
  automationCheckedAt: Maybe<Scalars['Date']>;
};

export enum GQLRequestType {
  /** Request made by an employee not assigned to any Shift */
  STAFFREQUEST = 'StaffRequest',
  /** Request made by an employee already assigned to a Shift */
  CHANGEREQUEST = 'ChangeRequest',
  /** Request of an employee offering his/her shift to other employees */
  SWAPREQUEST = 'SwapRequest'
}

export enum GQLReplaceRequestState {
  NEW = 'new',
  ACCEPTED = 'accepted',
  CONFIRMED = 'confirmed',
  AUTO_CONFIRMED = 'auto_confirmed'
}

/**
 * When a Shift has been performed by an employee an Evaluation is automatically provided. This can be
 * manually adjusted by stakeholders or a proposal of changes can be made by the employee (if enabled).
 * Please note: payments can be included in the evaluation response. This decreases the response time
 * and requires owner rights for the API user.
 */
export type GQLEvaluation = GQLTimestampable & {
  /** Unique identifier of the Evaluation. Null if shift was not evaluated */
  id: Maybe<Scalars['Int']>;
  /** Date when the Evaluation was created */
  createdAt: Scalars['Date'];
  /** Date when the Evaluation was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Evaluation was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Id of staffShift evaluation belongs to */
  staffShiftId: Scalars['Int'];
  /** Evaluated date when the Shift was started */
  startsAt: Scalars['Date'];
  /** Evaluated date when the Shift has ended */
  endsAt: Scalars['Date'];
  /** Evaluated total break duration (untimed plus additional) in minutes */
  break: Scalars['Float'];
  /** Untimed/unpaid break duration in minutes */
  untimedBreak: Maybe<Scalars['Float']>;
  /** Evaluated duration of the Shift */
  duration: Scalars['Float'];
  /** State of the Evaluation */
  state: GQLEvaluationState;
  /** Note regarding the Shift */
  shiftNote: Scalars['String'];
  /** Evaluation note by stakeholder */
  adminEvaluationNote: Maybe<Scalars['String']>;
  /** Evaluation note by employee */
  employeeEvaluationNote: Maybe<Scalars['String']>;
  totalPayment: Maybe<Scalars['Float']>;
  /** Evaluation has pending employment response */
  pendingEmployeeEvaluation: Scalars['Boolean'];
  /** The Shift associated with the Evaluation */
  shift: GQLShift;
  /** The LocationsPosition associated with the Evaluation */
  locationsPosition: GQLLocationsPosition;
  /** The Shiftplan associated with the Evaluation */
  shiftplan: GQLShiftplan;
  /** The Location associated with the Evaluation */
  location: GQLLocation;
  /** The Position associated with the Evaluation */
  position: GQLPosition;
  /** The Employment associated with the Evaluation */
  employment: GQLEmployment;
  /** The Payments associated with the Evaluation */
  payments: Array<GQLPayment>;
  /** The EvaluationBreaks associated with the Evaluaton */
  breaks: Array<GQLEvaluationBreak>;
  staffShiftsTags: Array<GQLStaffShiftsTag>;
};

export enum GQLEvaluationState {
  AUTO_PUNCHTIMED = 'auto_punchtimed',
  DONE_EVALUATION = 'done_evaluation',
  NEEDS_EVALUATION = 'needs_evaluation',
  NO_EVALUATION = 'no_evaluation',
  PUNCHTIMED = 'punchtimed',
  NO_SHOW = 'no_show'
}

/**
 * LocationsPosition is the join table of Location and Position.
 * It connects a certain position to a certain location.
 */
export type GQLLocationsPosition = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the LocationsPosition */
  id: Scalars['Int'];
  /** The Location associated with the LocationsPosition */
  location: Maybe<GQLLocation>;
  /** The Position associated with the LocationsPosition */
  position: Maybe<GQLPosition>;
  /** Date when the LocationsPosition was created */
  createdAt: Scalars['Date'];
  /** Date when the LocationsPosition was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the LocationsPosition was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Sorting position of the LocationsPosition */
  sort: Maybe<Scalars['Int']>;
};

/**
 * A Shiftplan contains a collection of Shifts within a defined period of time and is linked
 * to a Location.
 */
export type GQLShiftplan = GQLIdentifiable & GQLTimeframeable & GQLTimestampable & {
  /** Unique identifier of the Shiftplan */
  id: Scalars['Int'];
  /** Name of the Shiftplan */
  name: Scalars['String'];
  /** Date when the Shiftplan will start or started (inclusive) */
  startsAt: Scalars['Date'];
  /** Date when the Shiftplan will end or ended (inclusive) */
  endsAt: Scalars['Date'];
  /** Date when the Shiftplan was created */
  createdAt: Scalars['Date'];
  /** Date when the Shiftplan was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Shiftplan was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  copying: Scalars['Boolean'];
  deleting: Scalars['Boolean'];
  /** Location associated with the Shiftplan */
  location: Maybe<GQLLocation>;
  /** ID of the Location associated with the Shiftplan */
  locationId: Maybe<Scalars['Int']>;
  state: GQLShiftplanState;
  /** Is Shiftplan locked? */
  locked: Maybe<Scalars['Boolean']>;
};

export enum GQLShiftplanState {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished'
}

/**
 * Payments define the total amount of money an employee earns from a shift.
 * Note that to be able to receive Payment information about an employee's performed shifts
 * the staff shift id is required.
 */
export type GQLPayment = GQLIdentifiable & {
  /** Unique identifier of the Payment */
  id: Scalars['Int'];
  displayName: Maybe<Scalars['String']>;
  /** On which level has the Payment been created (Company, Position, employee)? */
  inheritedFrom: Maybe<GQLPaygradeLevel>;
  /** Is the Payment editable */
  isEdited: Maybe<Scalars['Boolean']>;
  /** Length of Shift in minutes */
  sumWithShift: Maybe<Scalars['Float']>;
  /** Type of Payment */
  type: Maybe<Scalars['String']>;
  /** Value of PaygradeType */
  value: Scalars['Float'];
  /** PaygradeType associated with the Payment */
  paygradeType: GQLPaygradeType;
  /** Children of the Payment */
  childPayments: Maybe<Array<GQLPayment>>;
  /** ID of the staffShift this Payment belongs to */
  staffShiftId: Scalars['Int'];
};

export enum GQLPaygradeLevel {
  COMPANY = 'Company',
  MONTHLYPAYGRADE = 'MonthlyPaygrade',
  PAYGRADE = 'Paygrade',
  POSITION = 'Position',
  SHIFT = 'Shift'
}

/**
 * Paygrade types represent different salary and compensation models a Company can have for an
 * employee's payment. They are specified on Company level, Position level or employee level.
 * Paygrades set on Position level will override paygrades on Company level, paygrades set on
 * employee level will override paygrades on Company and Position level. Only same paygrades
 * and same bonus paygrades will be overwritten, therefore all paygrades set on Company level that
 * don't exist on employee level are not being overwritten and will still be applied to the
 * employee's payments.
 * Name and paygrade type can only be associated with one unique paygrade, therefore also keep in mind
 * that it is not possible to set more than one paygrade for an employee with same paygrade type and same position.
 */
export type GQLPaygradeType = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the PaygradeType */
  id: Scalars['Int'];
  /** Date when the PaygradeType was created */
  createdAt: Scalars['Date'];
  /** Date when the PaygradeType was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the PaygradeType was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when the PaygradeType started */
  startsAt: Maybe<Scalars['Date']>;
  /** Date when the PaygradeType ended */
  endsAt: Maybe<Scalars['Date']>;
  /** Can the PaygradeType be applied on Mondays? */
  monday: Maybe<Scalars['Boolean']>;
  /** Can the PaygradeType be applied on Tuesdays? */
  tuesday: Maybe<Scalars['Boolean']>;
  /** Can the PaygradeType be applied on Wednesdays? */
  wednesday: Maybe<Scalars['Boolean']>;
  /** Can the PaygradeType be applied on Thursdays? */
  thursday: Maybe<Scalars['Boolean']>;
  /** Can the PaygradeType be applied on Fridays? */
  friday: Maybe<Scalars['Boolean']>;
  /** Can the PaygradeType be applied on Saturdays? */
  saturday: Maybe<Scalars['Boolean']>;
  /** Can the PaygradeType be applied on Sundays? */
  sunday: Maybe<Scalars['Boolean']>;
  /** Can the PaygradeType be applied on special days (public holidays etc.)? */
  specialDay: Maybe<Scalars['Boolean']>;
  /** Information about Paygrade validation in minutes */
  minMinutes: Scalars['Int'];
  /** Name of the PaygradeType */
  name: Scalars['String'];
  paychexLa: Maybe<Scalars['String']>;
  /** Type of payment (fixed/bonus fixed/bonus percentage/hourly) */
  payType: Scalars['String'];
  selection: Maybe<Scalars['String']>;
  /** The Company associated with the PaygradeType */
  company: GQLCompany;
};

export type GQLEvaluationBreak = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Evaluation */
  id: Scalars['Int'];
  /** Date when the EvaluationBreak was created */
  createdAt: Scalars['Date'];
  /** Date when the EvaluationBreak was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the EvaluationBreak was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when EvaluationBreak started */
  startsAt: Scalars['Date'];
  /** Date when EvaluationBreak ended */
  endsAt: Scalars['Date'];
  /** ID of Evaluation the EvaluationBreak is associated with */
  evaluationId: Scalars['Int'];
};

/** A StaffShiftsTag */
export type GQLStaffShiftsTag = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the StaffShiftsTag */
  id: Scalars['Int'];
  /** ID of the StaffShift associated with the StaffShiftsTag */
  staffShiftId: Scalars['Int'];
  /** StaffShift associated with the StaffShiftsTag */
  staffShift: GQLStaffShift;
  /** ID of the Tag associated with the StaffShiftsTag */
  tagId: Scalars['Int'];
  /** Tag associated with the StaffShiftsTag */
  tag: GQLTag;
  /** Date when the StaffShiftsTag was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when the StaffShiftsTag was created */
  createdAt: Scalars['Date'];
  /** Date when the StaffShiftsTag was last updated */
  updatedAt: Scalars['Date'];
};

/** A Tag */
export type GQLTag = GQLIdentifiable & {
  /** Unique identifier of the Tag */
  id: Scalars['Int'];
  /** Location associated with the Tag (null for staff_shift_tag) */
  location: Maybe<GQLLocation>;
  /** Name of the Tag */
  name: Scalars['String'];
  /** Color of the Tag (hex) */
  color: Scalars['String'];
  /** Text color of the Tag(hex) */
  textColor: Maybe<Scalars['String']>;
  /** Kind of the Tag */
  context: GQLTagContext;
};

export enum GQLTagContext {
  SHIFT_TAG = 'shift_tag',
  STAFF_SHIFT_TAG = 'staff_shift_tag'
}

/** A ShiftAssignmentGroup */
export type GQLShiftAssignmentGroup = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the ShiftAssignmentGroup */
  id: Scalars['Int'];
  /** Date when the ShiftAssignmentGroup was created */
  createdAt: Scalars['Date'];
  /** Date when the ShiftAssignmentGroup was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the ShiftAssignmentGroup was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Shift associated with the ShiftAssignmentGroup */
  shift: GQLShift;
  /** AssignmentGroup associated with the ShiftAssignmentGroup */
  assignmentGroup: GQLAssignmentGroup;
  /** Id of AssignmentGroup */
  assignmentGroupId: Scalars['Int'];
  /** Id of Shift */
  shiftId: Scalars['Int'];
};

/** A AssignmentGroup */
export type GQLAssignmentGroup = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the AssignmentGroup */
  id: Scalars['Int'];
  /** Date when the AssignmentGroup was created */
  createdAt: Scalars['Date'];
  /** Date when the AssignmentGroup was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the ShiftAssignmentGroup was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Name of the AssignmentGroup */
  name: Scalars['String'];
  /** Shiftplan associated with the AssignmentGroup */
  shiftplan: GQLShiftplan;
  /** Additional metadata related to the AssignmentGroup */
  metadata: Maybe<Scalars['Json']>;
  /** Number of employees who are assigned to all shifts in the AssignmentGroup */
  employmentCount: Maybe<Scalars['Int']>;
  /** Number of shifts in the AssignmentGroup */
  shiftCount: Maybe<Scalars['Int']>;
};

/** A ShiftPreset */
export type GQLShiftPreset = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the ShiftPreset */
  id: Scalars['Int'];
  /** Date when the ShiftPreset was created */
  createdAt: Scalars['Date'];
  /** Date when the ShiftPreset was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the ShiftPreset was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Name of ShiftPreset */
  name: Scalars['String'];
  /** Short name of shiftPreset */
  shortName: Maybe<Scalars['String']>;
  /** Start time string for a Shift with this ShiftPreset */
  startsAtTime: Scalars['String'];
  /** End time string for a Shift with this ShiftPreset */
  endsAtTime: Scalars['String'];
  /** Date with the start time for a Shift with this ShiftPreset */
  startsAt: Scalars['Date'];
  /** Date with the end time for a Shift with this ShiftPreset */
  endsAt: Scalars['Date'];
  /** Break time for a Shift with this ShiftPreset */
  breakTime: Scalars['Int'];
  /** Color of ShiftPreset */
  color: Scalars['String'];
  /** Company associated with ShiftPreset */
  company: GQLCompany;
  /** Tags for a Shift with this ShiftPreset */
  tags: Array<GQLTag>;
  /** Ids of the Tags for a Shift with this ShiftPreset */
  tagIds: Array<Scalars['Int']>;
  /** ShiftEvaluationTags for a Shift with this ShiftPreset */
  shiftEvaluationTags: Array<GQLTag>;
  /** Ids of the ShiftEvaluationTags for a Shift with this ShiftPreset */
  shiftEvaluationTagIds: Array<Scalars['Int']>;
};

export type GQLShiftRotationGroup = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the ShiftRotationGroup */
  id: Scalars['Int'];
  /** Date when the ShiftRotationGroup was created */
  createdAt: Scalars['Date'];
  /** Date when the ShiftRotationGroup was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the ShiftRotationGroup was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Name of the ShiftRotationGroup */
  name: Scalars['String'];
  /** Used for ordering ShiftRotationGroups of the same ShiftRotation */
  sortPosition: Maybe<Scalars['Int']>;
  /** ShiftRotation associated with ShiftRotationGroup */
  shiftRotation: GQLShiftRotation;
  /** EmploymentsShiftRotationGroups associated with ShiftRotationGroup */
  employmentsShiftRotationGroups: Array<GQLEmploymentsRotationGroup>;
  /** Employment IDs associated with ShiftRotationGroup */
  employmentIds: Array<Scalars['Int']>;
  /** An array of ShiftPreset associated with the ShiftRotationGroup */
  shiftPresets: Array<GQLShiftPreset>;
  /** An array of ShiftPreset IDs associated with the ShiftRotationGroup */
  shiftPresetIds: Array<Scalars['Int']>;
};

export type GQLShiftRotation = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the ShiftRotation */
  id: Scalars['Int'];
  /** Date when the ShiftRotation was created */
  createdAt: Scalars['Date'];
  /** Date when the ShiftRotation was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the ShiftRotation was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Name of the ShiftRotation */
  name: Scalars['String'];
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Company associated with ShiftRotation */
  company: GQLCompany;
  /** Anchor date of the ShiftRotation */
  anchorDate: Scalars['Date'];
  /** When does the the ShiftRotation itself start */
  startsAt: Maybe<Scalars['Date']>;
  /** When does the the ShiftRotation itself end */
  endsAt: Maybe<Scalars['Date']>;
  /** After how many days does the ShiftRotation repeat? */
  rotationInterval: Scalars['Int'];
  shiftRotationGroups: Array<GQLShiftRotationGroup>;
};

/**
 * EmploymentsRotationGroup is the join table of Employment and ShiftRotationGroup.
 * This defines what rotation groups employee has at specific period of time.
 */
export type GQLEmploymentsRotationGroup = GQLIdentifiable & {
  /** Unique identifier of the EmploymentsRotationGroup */
  id: Scalars['Int'];
  /** Date when the EmploymentsRotationGroup was created */
  createdAt: Scalars['Date'];
  /** Date when the EmploymentsRotationGroup was last updated */
  updatedAt: Scalars['Date'];
  /** ID of Employment associated with the EmploymentsRotationGroup */
  employmentId: Scalars['Int'];
  /** ID of ShiftRotationGroup associated with the EmploymentsRotationGroup */
  shiftRotationGroupId: Scalars['Int'];
  /** The Employment associated with the EmploymentsRotationGroup */
  employment: GQLEmployment;
  /** Connected ShiftRotationGroup */
  shiftRotationGroup: GQLShiftRotationGroup;
  /** Date when the employment's assignment to the ShiftRotationGroup will start or started */
  startsAt: Maybe<Scalars['Date']>;
  /** Date when the employment's assignment to the ShiftRotationGroup will end or ended */
  endsAt: Maybe<Scalars['Date']>;
};

export type GQLPaygrade = GQLIdentifiable & {
  /** Unique identifier of the Paygrade */
  id: Scalars['Int'];
  /** PaygradeType associated with the Paygrade */
  paygradeType: GQLPaygradeType;
  /** Type of Paygrade */
  paygradeForType: GQLPaygradeLevel;
  paygradeForId: Scalars['Int'];
  /** Value of PaygradeType */
  value: Scalars['Float'];
  /** Child Paygrades */
  paygrades: Maybe<Array<GQLPaygrade>>;
};

export enum GQLAutoAcceptRequestSetting {
  DISABLED = 'disabled',
  WARNING = 'warning',
  ENFORCED = 'enforced'
}

/** Every Shift can have multiple shift breaks. */
export type GQLShiftBreak = GQLIdentifiable & {
  /** Unique identifier of the ShiftBreak */
  id: Scalars['Int'];
  /** ID of the shift to which this break relates */
  shiftId: Scalars['Int'];
  /** Shift to which this break relates */
  shift: GQLShift;
  /** Date when the ShiftBreak started */
  startsAt: Scalars['Date'];
  /** Date when the ShiftBreak ended */
  endsAt: Maybe<Scalars['Date']>;
};

export type GQLShiftQualification = {
  /** ID of the associated qualification */
  qualificationId: Scalars['Int'];
  /** Qualification associated to the Shift */
  qualification: GQLQualification;
  /** Required workers for qualification */
  count: Scalars['Int'];
};

/**
 * Qualifications are expertise assigned to employment. An employee
 * can have multiple qualifications. Availability of Qualification feature
 * for a company depends on the package and also it needs to be enabled
 * in company profile.
 */
export type GQLQualification = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the Qualification */
  id: Scalars['Int'];
  /** Date when the Qualfication was created */
  createdAt: Scalars['Date'];
  /** ID of company associated with Qualification */
  companyId: Scalars['Int'];
  /** Company associated with Qualification */
  company: GQLCompany;
  /** Date when the Qualfication was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Qualfication was last deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Name of the Qualification */
  name: Scalars['String'];
  /** Additional metadata related to the Qualification */
  metadata: Maybe<Scalars['Json']>;
};

export type GQLPunchBreak = GQLIdentifiable & {
  /** Unique identifier of the PunchBreak */
  id: Scalars['Int'];
  /** ID of the associated PunchTiming */
  punchTimingId: Maybe<Scalars['Int']>;
  /** Start of the PunchBreak */
  startTime: Maybe<Scalars['Date']>;
  /** Start of the PunchBreak */
  endTime: Maybe<Scalars['Date']>;
  /** Date when the PunchBreak was created */
  createdAt: Scalars['Date'];
  /** Date when the PunchBreak was last updated */
  updatedAt: Maybe<Scalars['Date']>;
  /** Date when the PunchBreak was deleted */
  deletedAt: Maybe<Scalars['Date']>;
};

/**
 * Every company can set their default language, as well as users individually.
 * Optionally it is possible to add custom languages and create translations.
 */
export type GQLLanguage = GQLIdentifiable & {
  /** Unique identifier of the Language */
  id: Scalars['Int'];
  /** Localized name of the language */
  name: Scalars['String'];
  /** Name of the related locale */
  locale: Maybe<Scalars['String']>;
  /** Fallback locale when translation for the chosen language is not set */
  fallback: Maybe<Scalars['String']>;
  companyId: Maybe<Scalars['Int']>;
};

/**
 * A collection of Paygrades for a given Employment grouped into Paygrades in general,
 * monthly Paygrades, and Paygrades for existing EmploymentsPositions.
 */
export type GQLPaygradeCollection = {
  /** Only monthly Paygrades */
  monthlyPaygrades: Maybe<Array<Maybe<GQLPaygrade>>>;
  /** All Paygrades */
  paygrades: Maybe<Array<Maybe<GQLPaygrade>>>;
  /** Paygrades for existing EmploymentsPositions */
  employmentsPositionsPaygrades: Maybe<Array<Maybe<GQLPaygrade>>>;
};

/**
 * A User can be an employee, a stakeholder and/or the Company owner. As soon as someone is invited
 * to shyftplan a new User will be created. Users can be associated with several Companies.
 */
export type GQLUser = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the User */
  id: Scalars['Int'];
  /** Date when the User was created */
  createdAt: Scalars['Date'];
  /** Date when the User was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the User was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  billingTypeId: Maybe<Scalars['Int']>;
  /** Email address of the User */
  email: Maybe<Scalars['String']>;
  /** Leaving date of the User */
  exitDate: Maybe<Scalars['Date']>;
  /** Date when the invitation was accepted */
  invitationAcceptedAt: Maybe<Scalars['Date']>;
  /** Date when the invitation was created */
  invitationCreatedAt: Maybe<Scalars['Date']>;
  /** Date when the invitation was sent */
  invitationSentAt: Maybe<Scalars['Date']>;
  /** Date when the invitation was accepted */
  invitedById: Maybe<Scalars['Int']>;
  /** Type of User that sent the invitation (stakeholder/owner) */
  invitedByType: Maybe<Scalars['String']>;
  /** Is the User's account inactive? */
  isInactive: Maybe<Scalars['Boolean']>;
  /** Locale (i.e. language code) of the User */
  locale: Maybe<Scalars['String']>;
  /** Phone number of the User */
  phoneNumber: Maybe<Scalars['String']>;
  /** ID of the Company associated with the User */
  currentCompanyId: Maybe<Scalars['Int']>;
  /** ID of the Location associated with the User */
  currentLocationId: Maybe<Scalars['Int']>;
  /** Company associated with the User */
  currentCompany: Maybe<GQLCompany>;
  /** Location associated with the User */
  currentLocation: Maybe<GQLLocation>;
};

/**
 * Users are able to inform their employer about their absences or stakeholders create them for
 * them. It contains the absence reason, time of absence and whether it is paid or not. Optionally
 * a note for further explanation can be added. The employer can then approve or decline the absence.
 */
export type GQLAbsence = GQLIdentifiable & GQLTimestampable & GQLTimeframeable & {
  /** Unique identifier of the Absence */
  id: Scalars['Int'];
  /** Date when the Absence was created */
  createdAt: Scalars['Date'];
  /** Date when the Absence was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Absence was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when the Absence will start or started */
  startsAt: Scalars['Date'];
  /** Optional message for a refused absence */
  refuseMessage: Maybe<Scalars['String']>;
  /** Date when the Absence will end or ended */
  endsAt: Scalars['Date'];
  /** Location of attachment */
  file: Maybe<Scalars['String']>;
  /** Name of the attachment */
  fileName: Maybe<Scalars['String']>;
  /** Unique identification key for attachment */
  fileUid: Maybe<Scalars['String']>;
  /** Date when the attachment was uploaded */
  fileUploadedAt: Maybe<Scalars['Date']>;
  /** State of the Absence */
  state: GQLAbsenceState;
  /** Note added by the employee or employer */
  notes: Maybe<Scalars['String']>;
  /** Duration of the Absence in working days */
  days: Scalars['Float'];
  /** Amount of vacations minutes for the Absence */
  vacationMinutes: Scalars['Int'];
  /** An Absence may vary between full day(s) or set by time of day */
  isFullDay: Scalars['Boolean'];
  /** Is or was this a paid or unpaid Absence? */
  paid: Scalars['Boolean'];
  /** Can the Absence be managed by the stakeholder? */
  canManage: Scalars['Boolean'];
  /** Reason for the Absence */
  absenceReason: GQLAbsenceReason;
  /** The Employment associated with the Absence */
  employment: GQLEmployment;
};

/** An Absence can have a specific state */
export enum GQLAbsenceState {
  /** Absence has been created but no decision has been made yet */
  NEW = 'new',
  /** Absence has been rejected */
  REFUSED = 'refused',
  /** Absence has been granted */
  ACCEPTED = 'accepted'
}

export type GQLAbsenceReasonsStatsResponse = {
  absenceReasonsStats: Array<GQLAbsenceReasonStats>;
  totalDays: Scalars['Int'];
};

export type GQLAbsenceReasonStats = {
  /** Unique identifier of the AbsenceReason */
  id: Scalars['Int'];
  absenceReason: GQLAbsenceReason;
  /** A human-readable representation of the AbsenceReason (e.g. vacation, sickness, overtime, other) */
  name: Scalars['String'];
  /** The amount of taken days of this AbsenceReason for the given employment in the given timespan, including the taken carryover days */
  used: Scalars['Float'];
  /** The amount of days of the AbsenceReason the given employment is allowed to take in the given timespan */
  limit: Scalars['Float'];
  /** Information about carried over days, if it is enabled for the given AbsenceReason */
  carryOverInfo: GQLCarryOverInfo;
};

export type GQLCarryOverInfo = {
  /** The amount of days of the carried over days already have been taken */
  used: Maybe<Scalars['Float']>;
  /** How much days were carried over to the given timespan */
  limit: Maybe<Scalars['Float']>;
};

/** Status of the Employment (only in the context of Absence) */
export enum GQLEmploymentStatus {
  /** Employment is active */
  ACTIVE = 'active',
  /** Employment has been deleted */
  DESTROYED = 'destroyed',
  /** Employment is disabled */
  INACTIVE = 'inactive',
  /** Employment is not associated with an email */
  NOEMAIL = 'noemail'
}

export enum GQLOrderDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export type GQLAbsenceCollection = GQLPaginatable & {
  items: Array<GQLAbsence>;
  pagination: GQLPaginationInfo;
};

export type GQLAbsenceDuration = {
  absenceDays: Scalars['Float'];
  absenceHours: Scalars['Float'];
  absenceHoursPerWorkingDay: Scalars['Float'];
};

export type GQLEmploymentCollection = GQLPaginatable & {
  items: Array<GQLEmployment>;
  pagination: GQLPaginationInfo;
};

export type GQLAssignmentGroupCollection = GQLPaginatable & {
  items: Array<GQLAssignmentGroup>;
  pagination: GQLPaginationInfo;
};

export type GQLAvailabilityCollection = GQLPaginatable & {
  items: Array<GQLAvailability>;
  pagination: GQLPaginationInfo;
};

/**
 * Every employee can inform stakeholders about their availabilities. The employee is able to state
 * whether they are available or unavailable, either all day or within a certain time frame and if
 * these availabilities should be repeated weekly or appear only once.
 * When fetching availabilities for a certain time frame of repeated availabilities you need to search
 * for an exact date and check status ”repeat”: true in order to receive the information. In this case
 * searched and listed date can differ, because only the first created availability of a series of
 * repeated availability will be listed.
 */
export type GQLAvailability = GQLIdentifiable & GQLTimeframeable & GQLTimestampable & {
  /** Identifier of the Availability (is the same for repeating availabilities) */
  id: Scalars['Int'];
  /** Unique identifier of the Availability */
  uniqueId: Scalars['String'];
  /** Date when the Availability will start or started */
  startsAt: Scalars['Date'];
  /** Date when the Availability will end or ended */
  endsAt: Scalars['Date'];
  /** Date when the Availability was created */
  createdAt: Scalars['Date'];
  /** Date when the Availability was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the Availability was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Original start date with which availability was created */
  startsAtOriginal: Scalars['Date'];
  /** Original end date with which availability was created */
  endsAtOriginal: Scalars['Date'];
  /** endsAt in DD.MM.YYYY HH:mm format */
  endsAtFormatted: Maybe<Scalars['String']>;
  /** startsAt in DD.MM.YYYY HH:mm format */
  startsAtFormatted: Maybe<Scalars['String']>;
  /** Is this a full day-availability? */
  isFullDay: Scalars['Boolean'];
  /** Is the Availability repeated weekly? */
  repeat: Scalars['Boolean'];
  /** Is this Availability indicating that the Employee is available or unavailable? */
  available: Scalars['Boolean'];
  /** The Employment associated with the Availability */
  employment: GQLEmployment;
};

export enum GQLAvailabilityAggregationOrderKey {
  EMPLOYEE_LAST_NAME = 'employee_last_name',
  AVAILABLE_HOURS = 'available_hours',
  CONTRACT_HOURS = 'contract_hours'
}

export type GQLAvailabilityAggregationCollection = GQLPaginatable & {
  items: Array<GQLAvailabilityAggregation>;
  pagination: GQLPaginationInfo;
};

/** Statistics about availabilities for one employee */
export type GQLAvailabilityAggregation = {
  /** The id of the Employment associated with the AvailabilityAggregation */
  employmentId: Scalars['Int'];
  /** The Employment associated with the AvailabilityAggregation */
  employment: GQLEmployment;
  /** The amount of hours that the employee entered as available */
  availableHours: Scalars['Int'];
  /** The amount of hours that the employee is contracted to work. Null, employee only has monthly hours */
  contractHours: Maybe<Scalars['Int']>;
  /** Whether the requesting user is allowed to edit the availabilities of the associated employee */
  canManage: Scalars['Boolean'];
};

export type GQLAvailabilityExceptionCollection = GQLPaginatable & {
  items: Array<GQLAvailabilityException>;
  pagination: GQLPaginationInfo;
};

export type GQLAvailabilityException = GQLIdentifiable & GQLTimestampable & GQLTimeframeable & {
  /** Unique identifier of the AvailabilityException */
  id: Scalars['Int'];
  /** Date when the AvailabilityException will start or started */
  startsAt: Scalars['Date'];
  /** Date when the AvailabilityException will end or ended */
  endsAt: Scalars['Date'];
  /** Date when the AvailabilityException was created */
  createdAt: Scalars['Date'];
  /** Date when the AvailabilityException was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the AvailabilityException was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** The Availability associated with the AvailabilityException */
  availability: Maybe<GQLAvailability>;
};

export type GQLBackgroundJobCollection = GQLPaginatable & {
  items: Array<GQLBackgroundJob>;
  pagination: GQLPaginationInfo;
};

/**
 * In order do distinguish between different kinds of Absences every Absence has an AbsenceReason.
 * Note that vacation as an AbsenceReason will automatically count off vacation days that have been
 * documented in an employee's payroll information.
 */
export type GQLBackgroundJob = GQLTimestampable & {
  /** Unique identifier of the BackgroundJob */
  id: Scalars['String'];
  /** Unique identifier of the underlying job */
  jobIdentifier: Scalars['String'];
  /** Type of the BackgroundJob */
  klassName: Scalars['String'];
  /** State of the BackgroundJob */
  state: GQLBackgroundJobState;
  /** result of the BackgroundJob */
  result: Maybe<Scalars['Json']>;
  /** errors of the BackgroundJob */
  errors: Maybe<Array<Scalars['Json']>>;
  /** Date when the execution of the BackgroundJob was started */
  startedAt: Maybe<Scalars['Date']>;
  /** Date when the execution of the BackgroundJob finished */
  finishedAt: Maybe<Scalars['Date']>;
  /** Date when the BackgroundJob was created */
  createdAt: Scalars['Date'];
  /** Date when the BackgroundJob was deleted (not used, always null) */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when the BackgroundJob was last updated */
  updatedAt: Scalars['Date'];
};

export enum GQLBackgroundJobState {
  PENDING = 'pending',
  RUNNING = 'running',
  FAILED = 'failed',
  FINISHED = 'finished'
}

export type GQLCalendarAggregation = {
  date: Scalars['Date'];
  working: Scalars['Int'];
  demand: Scalars['Int'];
  absent: Scalars['Float'];
};

export type GQLChatEmploymentCollection = GQLPaginatable & {
  items: Array<GQLChatEmployment>;
  pagination: GQLPaginationInfo;
};

export type GQLChatEmployment = GQLIdentifiable & {
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  pictureData: GQLPictureData;
};

export enum GQLChatType {
  EMPLOYMENT = 'Employment',
  LOCATION = 'Location',
  LOCATIONSPOSITION = 'LocationsPosition'
}

export type GQLChatMessageCollection = GQLPaginatable & {
  items: Array<GQLChatMessage>;
  pagination: GQLPaginationInfo;
};

export type GQLChatMessage = GQLIdentifiable & {
  /** Unique identifier of the ChatMessage */
  id: Scalars['Int'];
  employmentId: Scalars['Int'];
  message: Scalars['String'];
  createdAt: Scalars['Date'];
  chatId: Scalars['Int'];
  sender: Maybe<GQLChatMessageEmployment>;
};

export type GQLChatMessageEmployment = GQLIdentifiable & {
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  userName: Scalars['String'];
  userImage: Scalars['String'];
};

export type GQLChatCollection = GQLPaginatable & {
  items: Array<GQLChat>;
  pagination: GQLPaginationInfo;
};

export type GQLChat = {
  /** Unique identifier of the Chat (can be integer or string like Location1234) */
  id: Scalars['String'];
  type: GQLChatType;
  lastChatMessage: Maybe<GQLChatMessage>;
  hasNewChatMessages: Scalars['Boolean'];
  member: Maybe<GQLChatUser>;
};

export type GQLChatUser = GQLChatMessageEmployment | GQLChatLocation | GQLChatLocationsPosition;

export type GQLChatLocation = GQLIdentifiable & {
  /** Unique identifier of the Location */
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type GQLChatLocationsPosition = GQLIdentifiable & {
  /** Unique identifier of the LocationsPosition */
  id: Scalars['Int'];
  positionName: Scalars['String'];
  locationName: Scalars['String'];
  positionColor: Scalars['String'];
};

export type GQLCompanyCollection = GQLPaginatable & {
  items: Array<GQLCompany>;
  pagination: GQLPaginationInfo;
};

export type GQLCustomFieldCollection = GQLPaginatable & {
  items: Array<GQLCustomField>;
  pagination: GQLPaginationInfo;
};

/**
 * A CustomField describes an additional information about a User. They will be added to the user's
 * employment information. Only stakeholders can add custom fields.
 */
export type GQLCustomField = GQLIdentifiable & {
  /** Unique identifier of the CustomField */
  id: Scalars['Int'];
  /** Name of the CustomField */
  name: Scalars['String'];
  /** The Company associated with the CustomField */
  company: Maybe<GQLCompany>;
  /** Data type used for the CustomField */
  customFieldType: Scalars['String'];
  /** Sort position of the CustomField */
  sort: Scalars['Int'];
  /** Date when the CustomField was created */
  createdAt: Scalars['Date'];
  /** Date when the CustomField was last updated */
  updatedAt: Scalars['Date'];
};

export type GQLDayNoteCollection = GQLPaginatable & {
  items: Array<GQLDayNote>;
  pagination: GQLPaginationInfo;
};

export type GQLDayNote = GQLIdentifiable & {
  /** Unique identifier of the DayNote */
  id: Scalars['Int'];
  /** Start date of the DayNote */
  startsAt: Scalars['Date'];
  /** End date for the DayNote */
  endsAt: Scalars['Date'];
  /** Title of the DayNote */
  title: Scalars['String'];
  /** Optional description for the DayNote */
  description: Maybe<Scalars['String']>;
  /** Shiftplan associated with the DayNote */
  shiftplan: GQLShiftplan;
};

/**
 * When a Shift has been performed by an employee an EmploymentEvaluation is automatically provided. This can be
 * manually adjusted by stakeholders or a proposal of changes can be made by the employee (if enabled).
 * Please note: payments can be included in the employment evaluation response. This decreases the response time
 * and requires owner rights for the API user.
 */
export type GQLEmploymentEvaluation = GQLIdentifiable & {
  /** Unique identifier of the Evaluation */
  id: Scalars['Int'];
  /** Evaluated date when the Shift was started */
  startsAt: Scalars['Date'];
  /** Evaluated date when the Shift has ended */
  endsAt: Scalars['Date'];
  /** Evaluated total break duration (untimed plus additional) in minutes */
  break: Scalars['Float'];
  /** Untimed/unpaid break duration in minutes */
  untimedBreak: Maybe<Scalars['Float']>;
  /** Note regarding the Shift */
  note: Scalars['String'];
  /** ID of StaffShift associated with Evaluation */
  staffShiftId: Scalars['Int'];
  /** The Payments associated with the Evaluation */
  payments: Array<GQLPayment>;
  /** The EvaluationBreaks associated with the Evaluaton */
  breaks: Array<GQLEvaluationBreak>;
  /** The EmploymentEvaluationTags associated with the Evaluation */
  evaluationTags: Array<GQLEmploymentEvaluationTag>;
};

export type GQLEmploymentEvaluationTag = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the EvaluationTag */
  id: Scalars['Int'];
  /** Date when the EvaluationTag was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when the EvaluationTag was created */
  createdAt: Scalars['Date'];
  /** Date when the EvaluationTag was last updated */
  updatedAt: Scalars['Date'];
  /** ID of the Tag associated with the EvaluationTag */
  tagId: Scalars['Int'];
  /** Tag associated with the EvaluationTag */
  tag: GQLTag;
};

export type GQLPaymentCollection = GQLPaginatable & {
  items: Array<GQLPayment>;
  pagination: GQLPaginationInfo;
};

export enum GQLResponseFormat {
  PLAIN = 'plain',
  NESTED = 'nested'
}

export type GQLEmploymentsPositionCollection = GQLPaginatable & {
  items: Array<GQLEmploymentsPosition>;
  pagination: GQLPaginationInfo;
};

/**
 * EmploymentsPosition is the join table of Employment and LocationsPosition.
 * This defines on which positions at what locations an employee is able to work.
 */
export type GQLEmploymentsPosition = GQLIdentifiable & {
  /** Unique identifier of the EmploymentsPosition */
  id: Scalars['Int'];
  /** The Employment associated with the EmploymentsPosition */
  employment: Maybe<GQLEmployment>;
  /** The LocationsPosition associated with the EmploymentsPosition */
  locationsPosition: Maybe<GQLLocationsPosition>;
  /** Date when the EmploymentsPosition was created */
  createdAt: Scalars['Date'];
  /** Date when the EmploymentsPosition was last updated */
  updatedAt: Scalars['Date'];
};

/** Type of shift rotation */
export enum GQLRotationType {
  /** Regular Shift Rotation */
  REGULAR = 'regular',
  /** Individual Shift Rotation */
  INDIVIDUAL = 'individual'
}

export type GQLEmploymentsRotationGroupsCollection = GQLPaginatable & {
  items: Array<GQLRotationGroupUnion>;
  pagination: GQLPaginationInfo;
};

export type GQLRotationGroupUnion = GQLEmploymentsRotationGroup | GQLIndividualShiftRotation;

export type GQLIndividualShiftRotation = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the IndividualShiftRotation */
  id: Scalars['Int'];
  /** Date when the IndividualShiftRotation was created */
  createdAt: Scalars['Date'];
  /** Date when the IndividualShiftRotation was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the IndividualShiftRotation was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Company associated with IndividualShiftRotation */
  company: GQLCompany;
  /** ID of associated Employment */
  employmentId: Scalars['Int'];
  /** Employment associated with IndividualShiftRotation */
  employment: GQLEmployment;
  /** When does the the IndividualShiftRotation itself start */
  startsAt: Maybe<Scalars['Date']>;
  /** When does the the IndividualShiftRotation itself end */
  endsAt: Maybe<Scalars['Date']>;
  /** Anchor date of the IndividualShiftRotation */
  anchorDate: Scalars['Date'];
  /** After how many days does the IndividualShiftRotation repeat? */
  rotationInterval: Scalars['Int'];
  /** An array of ShiftPreset associated with the ShiftRotationGroup */
  shiftPresets: Array<GQLShiftPreset>;
  /** An array of ShiftPreset IDs associated with the ShiftRotationGroup */
  shiftPresetIds: Array<Scalars['Int']>;
};

/**
 * Every Employment has an EmploymentSurvey. It contains all specified employee information.
 * To enable an employment survey the employment's billing type (payroll type) needs to be set
 * to billing. After the payroll type is set the employee is able to fill out the staff questionnaire
 * which will be added to the employment survey.
 */
export type GQLEmploymentsSurvey = GQLIdentifiable & {
  /** Unique identifier of the EmploymentsPosition */
  id: Scalars['Int'];
  /** First name of the employee */
  firstName: Maybe<Scalars['String']>;
  /** Middle name of the employee */
  middleName: Maybe<Scalars['String']>;
  /** Last name of the employee */
  lastName: Maybe<Scalars['String']>;
  /** true if an address other than the default adress of the User is used */
  otherAddress: Maybe<Scalars['Boolean']>;
  /** Date when the employee joined */
  dateOfEntry: Maybe<Scalars['Date']>;
  /** Birth date of the employee */
  birthDate: Maybe<Scalars['Date']>;
  /** Birth place of the employee */
  birthPlace: Maybe<Scalars['String']>;
  /** Nationality of the employee */
  nationality: Maybe<Scalars['String']>;
  /** Gender of the employee */
  gender: Maybe<Scalars['String']>;
  /** Relevant for the deduction of church tax from your gross pay. */
  denomination: Maybe<Scalars['String']>;
  /** Name of the bank */
  bankName: Maybe<Scalars['String']>;
  /** Name of the bank account holder */
  bankAccountHolder: Maybe<Scalars['String']>;
  /** IBAN */
  bankAccountNumber: Maybe<Scalars['String']>;
  /** BIC */
  bankIdentifierCode: Maybe<Scalars['String']>;
  /** Status of employment. As of now only German taxation options */
  employmentRelationship: Maybe<Scalars['String']>;
  /** Main or secondary employment? */
  employmentType: Maybe<Scalars['String']>;
  /** Whether the employee has one or more other mini jobs */
  furtherMinijobs: Maybe<Scalars['Boolean']>;
  /** Salary if the employment is a mini job */
  minijobSalary: Maybe<Scalars['Float']>;
  /** Type of exemption from pension insurance if the employment is a mini job */
  pensionInsuranceExemption: Maybe<Scalars['String']>;
  /** Description of main work activity */
  jobDescription: Maybe<Scalars['String']>;
  /** Highest school degree */
  highestSchoolDegree: Maybe<Scalars['String']>;
  /** Highest level of education */
  highestLevelOfEducation: Maybe<Scalars['String']>;
  /** Identification number of the health insurance provider */
  healthInsuranceIdentificationNumber: Maybe<Scalars['String']>;
  /** Name of the health insurance provider */
  healthInsuranceName: Maybe<Scalars['String']>;
  /** Social security number */
  socialSecurityNumber: Maybe<Scalars['String']>;
  /** Whether the employee has worked in Germany before or not */
  neverWorkedInGermany: Maybe<Scalars['Boolean']>;
  /** Tax identification number (also called personal identification number, IdNr. or tax ID) */
  taxIdentificationNumber: Maybe<Scalars['String']>;
  /** Marital status */
  familyStatus: Maybe<Scalars['String']>;
  /** Tax bracket */
  taxBracket: Maybe<Scalars['String']>;
  /** Only needed if tax bracket is 'IV with factor' */
  taxBracketFactor: Maybe<Scalars['String']>;
  /** Whether the employee has capital-forming benefits */
  hasCapitalFormingBenefits: Maybe<Scalars['Boolean']>;
  /** Bank that issued the capital-forming benefits */
  capitalFormingBenefitsBankName: Maybe<Scalars['String']>;
  /** Contract number of capital-forming benefits contract */
  capitalFormingBenefitsContractNumber: Maybe<Scalars['String']>;
  /** Commencement of the capital-forming benefits contract */
  capitalFormingBenefitsContractStart: Maybe<Scalars['Date']>;
  /** Amount per month going into the capital-forming benefits contract */
  capitalFormingBenefitsTotal: Maybe<Scalars['Float']>;
  /** Employer's contribution towards the capital-forming benefits contract */
  capitalFormingBenefitsEmployersContribution: Maybe<Scalars['Float']>;
  /** Whether the employee has children */
  hasChildren: Maybe<Scalars['Boolean']>;
  /** Number of children */
  childrenCount: Maybe<Scalars['Int']>;
  /** Whether a proof has been provided that the employee has children */
  childrenProof: Maybe<Scalars['String']>;
  /** Amount of allowance the employee is entitled to in case they have children */
  childrenAllowance: Maybe<Scalars['Float']>;
  /** Additional comments */
  comments: Maybe<Scalars['String']>;
  /** Leaving date of the employee (in case of fixed-term contract) */
  exitDate: Maybe<Scalars['Date']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: Maybe<Scalars['Boolean']>;
  /** Working hours per week */
  weeklyHours: Maybe<Scalars['Float']>;
  /** Staff number */
  staffNumber: Maybe<Scalars['String']>;
  /** Phone number */
  phoneNumber: Maybe<Scalars['String']>;
  /** Note */
  note: Maybe<Scalars['String']>;
  allStepCompleted: Maybe<Scalars['Boolean']>;
  /** Name of the street the employee is living in (only when otherAddress = true) */
  street: Maybe<Scalars['String']>;
  /** House number of the street the employee is living in (only when otherAddress = true) */
  streetNumber: Maybe<Scalars['String']>;
  /** Zip code of the city the employee is living in (only when otherAddress = true) */
  zip: Maybe<Scalars['String']>;
  /** Name of the city the employee is living in (only when otherAddress = true) */
  city: Maybe<Scalars['String']>;
  /** Name of the district etc. the employee is living in (only when otherAddress = true) */
  subcity: Maybe<Scalars['String']>;
  /** Name of the country's state the employee is living in (only when otherAddress = true) */
  state: Maybe<Scalars['String']>;
  /** Name of the country the employee is living in (only when otherAddress = true) */
  country: Maybe<Scalars['String']>;
  visibility: Maybe<Scalars['String']>;
  ownerId: Scalars['Int'];
  ownerType: Scalars['String'];
  /** Date when the EmploymentsSurvey was created */
  createdAt: Scalars['Date'];
  /** Date when the EmploymentsSurvey was last updated */
  updatedAt: Maybe<Scalars['Date']>;
};

/**
 * Every Employment has an EmploymentUiSetting. It is specific to Calendar.
 * It contains information on:
 * 1. Filters selected
 * 2. Timeframe selected for each shift schedule
 * 3. View that the user last used
 *
 * The information contained in there can specific to:
 * 1. Location
 * 2. Shiftplan
 */
export type GQLEmploymentsUiSetting = GQLIdentifiable & {
  /** Unique identifier of the EmploymentsUiSetting */
  id: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Data of saved setting */
  settings: Maybe<Scalars['Json']>;
  /** Version of saved setting */
  version: Maybe<Scalars['String']>;
  /** Date when the EmploymentsUiSetting was created */
  createdAt: Scalars['Date'];
  /** Date when the EmploymentsUiSetting was last updated */
  updatedAt: Maybe<Scalars['Date']>;
};

export type GQLEvaluationBreakCollection = GQLPaginatable & {
  items: Array<GQLEvaluationBreak>;
  pagination: GQLPaginationInfo;
};

export type GQLEvaluationCollection = GQLPaginatable & {
  items: Array<GQLEvaluation>;
  pagination: GQLPaginationInfo;
};

export type GQLIndividualShiftRotationCollection = GQLPaginatable & {
  items: Array<GQLIndividualShiftRotation>;
  pagination: GQLPaginationInfo;
};

export type GQLLanguageCollection = GQLPaginatable & {
  items: Array<GQLLanguage>;
  pagination: GQLPaginationInfo;
};

export type GQLLiveStaffShiftCollection = GQLPaginatable & {
  items: Array<GQLLiveStaffShift>;
  pagination: GQLPaginationInfo;
};

/**
 * A live staff shift contains all information about a performed Shift. Note that only company owners
 * or stakeholders with view permissions can access LiveStaffShift information.
 */
export type GQLLiveStaffShift = GQLIdentifiable & {
  /** Unique identifier of the LiveStaffShift */
  id: Scalars['Int'];
  /** ID of the PunchTiming associated with the LiveStaffShift */
  punchTimingId: Maybe<Scalars['Int']>;
  /** ID of the PunchBreak associated with the LiveStaffShift */
  punchBreakId: Maybe<Scalars['Int']>;
  /** State of the LiveStaffShift */
  liveStatus: Maybe<Scalars['String']>;
  /** The StaffShift associated with the LiveStaffShift */
  staffShift: Maybe<GQLStaffShift>;
  /** The Employment associated with the LiveStaffShift */
  employment: Maybe<GQLEmployment>;
  /** The Shift associated with the LiveStaffShift */
  shift: Maybe<GQLShift>;
  /** The LocationsPosition associated with the LiveStaffShift */
  locationsPosition: Maybe<GQLLocationsPosition>;
  /** The Location associated with the LiveStaffShift */
  location: Maybe<GQLLocation>;
  /** The Position associated with the LiveStaffShift */
  position: Maybe<GQLPosition>;
  /** The Company associated with the LiveStaffShift */
  company: Maybe<GQLCompany>;
  /** The User associated with the LiveStaffShift */
  user: Maybe<GQLUser>;
};

export type GQLLocationCollection = GQLPaginatable & {
  items: Array<GQLLocation>;
  pagination: GQLPaginationInfo;
};

export type GQLLocationsPositionCollection = GQLPaginatable & {
  items: Array<GQLLocationsPosition>;
  pagination: GQLPaginationInfo;
};

export type GQLNewsfeedCollection = GQLPaginatable & {
  items: Array<GQLNewsfeed>;
  pagination: GQLPaginationInfo;
};

export type GQLNotificationConfigurationCollection = GQLPaginatable & {
  items: Array<GQLNotificationConfiguration>;
  pagination: GQLPaginationInfo;
};

/**
 * NotificationConfiguration defines whether a User gets a notification via mobile or email
 * automatically when a notification appears on the Newsfeed.
 */
export type GQLNotificationConfiguration = {
  /** Unique identifier of the NotificationConfiguration */
  id: Maybe<Scalars['Int']>;
  /** Should Absence notifications be sent via email? */
  absencesMail: Maybe<Scalars['Boolean']>;
  /** Should Absence notifications be sent to the User's mobile device? */
  absencesMobile: Maybe<Scalars['Boolean']>;
  /** Should Request refusal notifications be sent via email? */
  applicationRequestRefusedMail: Maybe<Scalars['Boolean']>;
  /** Should Request refusal notifications be sent to the User's mobile device? */
  applicationRequestRefusedMobile: Maybe<Scalars['Boolean']>;
  /** Should auto punchout notifications be sent to the User's mobile device? */
  autoPunchoutMobile: Maybe<Scalars['Boolean']>;
  /** Date when the NotificationConfiguration was created */
  createdAt: Maybe<Scalars['Date']>;
  /** Date when the NotificationConfiguration was last updated */
  updatedAt: Maybe<Scalars['Date']>;
  /** Date when the NotificationConfiguration was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** ID of the associated Employment */
  employmentId: Maybe<Scalars['Int']>;
  /** Should Evaluation notifications be sent via email? */
  evaluationsMail: Maybe<Scalars['Boolean']>;
  /** Should Evaluation notifications be sent to the User's mobile device? */
  evaluationsMobile: Maybe<Scalars['Boolean']>;
  /** Should messages be sent to the User's mobile device? */
  messagesMobile: Maybe<Scalars['Boolean']>;
  /** Should Request notifications be sent to the User's mobile device? */
  requestsMobile: Maybe<Scalars['Boolean']>;
  /** Should Shiftplan notifications be sent to the User's mobile device? */
  shiftplansMobile: Maybe<Scalars['Boolean']>;
  /** Should staff messages be sent to the User's mobile device? */
  staffMessagesMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Absence notifications via email? */
  stakeholderAbsencesMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Absence notifications on their mobile devices? */
  stakeholderAbsencesMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto punchout notifications on their mobile devices? */
  stakeholderAutoPunchoutMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto change request notifications via email? */
  stakeholderAutoRequestChangeAcceptMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto change request notifications on their mobile devices? */
  stakeholderAutoRequestChangeAcceptMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Evaluation suggestions on their mobile devices? */
  stakeholderEvaluationsMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Request notifications via email? */
  stakeholderRequestsMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Request notifications on their mobile devices? */
  stakeholderRequestsMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive shift application notifications via email? */
  stakeholderShiftApplicationMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive shift application notifications on their mobile devices? */
  stakeholderShiftApplicationMobile: Maybe<Scalars['Boolean']>;
};

export type GQLPaygradeTypeCollection = GQLPaginatable & {
  items: Array<GQLPaygradeType>;
  pagination: GQLPaginationInfo;
};

export type GQLPaymentsCalculationInput = {
  /** Date when the evaluated Shift has started */
  startsAt: Scalars['Date'];
  /** Date when the evaluated Shift has ended */
  endsAt: Scalars['Date'];
  /** Evaluated total breaks duration in minutes */
  breaks: Scalars['Int'];
  /** Start and end date of individual breaks of the evaluated Shift */
  evaluationBreaks: Maybe<Array<GQLTimeframeableInput>>;
  /** Payments to be used for the calculation */
  payments: Array<GQLPaymentsCalculationPaymentInput>;
};

export type GQLTimeframeableInput = {
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
};

export type GQLPaymentsCalculationPaymentInput = {
  /** ID of the PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Value of the payment */
  value: Scalars['Float'];
  /** Children of the payment (e.g. bonuses) */
  childPayments: Maybe<Array<GQLPaymentsCalculationPaymentInput>>;
};

export type GQLPaymentsCalculation = {
  /** Calculated values for each provided payment */
  payments: Maybe<Array<GQLPaymentsCalculationPayment>>;
  /** Sum of calculated payment values for the evaluated Shift */
  sum: Scalars['Float'];
};

export type GQLPaymentsCalculationPayment = {
  /** ID of the PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Calculated value for the payment */
  totalValue: Scalars['Float'];
  /** Calculated children of the payment (e.g. bonuses) */
  childPayments: Maybe<Array<GQLPaymentsCalculationPayment>>;
};

export type GQLPayslipCollection = GQLPaginatable & {
  items: Array<GQLPayslip>;
  pagination: GQLPaginationInfo;
};

/** Payslips are payment documents attached to user. */
export type GQLPayslip = GQLIdentifiable & {
  /** Unique identifier of the Payslip */
  id: Scalars['Int'];
  /** Company associated with Payslip */
  company: GQLCompany;
  /** Employment id associated with Payslip */
  employmentId: Scalars['Int'];
  /** Upload date */
  date: Scalars['Date'];
  /** Uploaded file name */
  name: Scalars['String'];
  /** Uploaded file url */
  file: Scalars['String'];
};

export type GQLPositionCollection = GQLPaginatable & {
  items: Array<GQLPosition>;
  pagination: GQLPaginationInfo;
};

export type GQLPunchStateOrError = GQLPunchState | GQLPunchError;

export type GQLPunchState = {
  /** Was the punchtime session started successfully? */
  success: Maybe<Scalars['Boolean']>;
  /** StaffShift associated with the PunchState */
  staffShift: Maybe<GQLStaffShift>;
  /** Shift associated with the PunchState */
  shift: Maybe<GQLShift>;
  /** Location associated with the PunchState */
  location: Maybe<GQLLocation>;
  /** Shiftplans associated with the PunchState */
  shiftplans: Maybe<Array<Maybe<GQLShiftplan>>>;
  /** Position associated with the PunchState */
  position: Maybe<GQLPosition>;
  companyRoundFair: Maybe<Scalars['Boolean']>;
  companyRoundTo: Maybe<Scalars['Float']>;
  userToken: Maybe<Scalars['Int']>;
  userPresentation: Maybe<Scalars['String']>;
  userImage: Maybe<Scalars['String']>;
  startTime: Maybe<Scalars['Date']>;
  punchTimingUncomplete: Maybe<GQLPunchTiming>;
  incompletePunchBreak: Maybe<GQLPunchBreak>;
  punchBreaks: Maybe<Array<Maybe<GQLPunchBreak>>>;
};

export type GQLPunchError = {
  /** Was the punchtime session started successfully? */
  success: Maybe<Scalars['Boolean']>;
  /** A key indicating the reason why the action has failed */
  reason: Maybe<Scalars['String']>;
};

export type GQLPunchLocationsPositions = {
  success: Scalars['Boolean'];
  locationsPositions: Array<Maybe<GQLPunchingLocationsPosition>>;
};

export type GQLPunchingLocationsPosition = GQLIdentifiable & GQLTimestampable & {
  id: Scalars['Int'];
  /** Date when the PunchBreak was created */
  createdAt: Scalars['Date'];
  /** Date when the PunchBreak was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the PunchBreak was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Sorting position of the LocationsPosition */
  sort: Maybe<Scalars['Int']>;
  location: Maybe<GQLLocation>;
  position: Maybe<GQLPosition>;
};

export type GQLQualificationCollection = GQLPaginatable & {
  items: Array<GQLQualification>;
  pagination: GQLPaginationInfo;
};

export type GQLRightCollection = GQLPaginatable & {
  items: Array<GQLRight>;
  pagination: GQLPaginationInfo;
};

/**
 * Rights define the way a user is able to use shyftplan. Rights can be assigned to an
 * individual User. Only owners in any case and stakeholders with the same rights they
 * want to assign to a user can do so. Choosing the accurate rights for a stakeholder
 * is depending on Company-internal agreements about their operational area.
 */
export type GQLRight = {
  /** Unique identifier of the Right */
  id: Maybe<Scalars['Int']>;
  /** Context of the Right (Location, Position, PunchTime etc.) */
  contextType: Maybe<Scalars['String']>;
  /** ID of the associated context */
  contextId: Maybe<Scalars['Int']>;
  /** Name of the Right */
  name: Maybe<Scalars['String']>;
  /** Company associated with the Right */
  company: Maybe<GQLCompany>;
  /** Date when the Right was created */
  createdAt: Maybe<Scalars['Date']>;
  /** Date when the Right was last updated */
  updatedAt: Maybe<Scalars['Date']>;
  /** Date when the Right was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Can this Right be set? */
  isPossibleToSet: Maybe<Scalars['Boolean']>;
};

export type GQLShiftAssignmentGroupCollection = GQLPaginatable & {
  items: Array<GQLShiftAssignmentGroup>;
  pagination: GQLPaginationInfo;
};

export enum GQLShiftEmploymentSort {
  NAME = 'name',
  WORKEDMINUTES = 'workedMinutes',
  REMAININGMINUTES = 'remainingMinutes',
  HOURACCOUNTHOURS = 'hourAccountHours',
  BRINGSHIFTS = 'bringShifts',
  EARNEDAMOUNT = 'earnedAmount',
  MAXIMUMMONEY = 'maximumMoney',
  REMAININGMONEY = 'remainingMoney'
}

export type GQLShiftEmploymentFilter = {
  /** Include only employees without any other conflicting shift */
  withoutShiftConflicts: Maybe<Scalars['Boolean']>;
  /** Include only employees without any conflicting absence */
  withoutAbsenceConflicts: Maybe<Scalars['Boolean']>;
  /** Include only employees with join requests for this shift */
  withJoinRequests: Maybe<Scalars['Boolean']>;
  /** Include only employees with passed qualification ID */
  withTheseQualificationIds: Maybe<Array<Scalars['Int']>>;
  /** Include only employees with matching availability status */
  availabilityFilters: Maybe<GQLShiftEmploymentAvailabilityFilter>;
};

export type GQLShiftEmploymentAvailabilityFilter = {
  /** Include only employees who are fully available */
  available: Maybe<Scalars['Boolean']>;
  /** Include only employees who are partially available */
  partialAvailable: Maybe<Scalars['Boolean']>;
  /** Include only employees who are partially available and partially unavailable */
  partialBoth: Maybe<Scalars['Boolean']>;
  /** Include only employees who are partially unavailable */
  partialUnavailable: Maybe<Scalars['Boolean']>;
  /** Include only employees who are fully unavailable */
  unavailable: Maybe<Scalars['Boolean']>;
  /** Include only employees who have no information about availabilities */
  neutral: Maybe<Scalars['Boolean']>;
};

export type GQLShiftAvailableEmploymentCollection = GQLPaginatable & {
  items: Array<GQLShiftAvailableEmployment>;
  pagination: GQLPaginationInfo;
};

/** A ShiftAvailableEmployment */
export type GQLShiftAvailableEmployment = GQLIdentifiable & {
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  employment: GQLEmployment;
  /** Unique identifier of the Shift */
  shiftId: Scalars['Int'];
  /** Is there a conflict with an existing absence or shift assignment? */
  hasConflicts: Scalars['Boolean'];
  /** Is there a join request already pending? */
  joinRequest: Maybe<GQLRequest>;
  /** Information about the employee's availabilities and unavailabilities during the course of the shift */
  availabilityInfo: GQLAvailabilityInfo;
  /** Number of contracted minutes */
  contractMinutes: Maybe<Scalars['Float']>;
  /** Number of scheduled minutes */
  scheduledMinutes: Maybe<Scalars['Float']>;
  /** Number of actually worked minutes */
  workedMinutes: Maybe<Scalars['Float']>;
  /** Hour account balance */
  hourAccountHours: Maybe<Scalars['Float']>;
  /** Expected payment for the scheduled shifts */
  scheduledMoney: Maybe<Scalars['Float']>;
  /** Maximum earnings per month */
  maximumMoney: Maybe<Scalars['Float']>;
  /** Number of annual bring shift hours */
  bringShiftHours: Maybe<Scalars['Float']>;
  /** Number of bring shift hours worked */
  bringShiftHoursUsed: Maybe<Scalars['Float']>;
  conflicts: Maybe<Array<GQLShiftConflict>>;
};

export type GQLAvailabilityInfo = {
  /** Availabilities during the course of the shift */
  available: Maybe<Array<GQLShiftEmploymentAvailability>>;
  /** Unavailabilities during the course of the shift */
  unavailable: Maybe<Array<GQLShiftEmploymentAvailability>>;
  /** Is the employee only partially available for the shift? */
  partialAvailable: Scalars['Boolean'];
  /** Is the employee only partially unavailable for the shift? */
  partialUnavailable: Scalars['Boolean'];
  /** Is the employee either available or unavailable for the entire shift? */
  complete: Scalars['Boolean'];
  /** Is the employee available for the entire shift? */
  fullAvailable: Scalars['Boolean'];
  /** Is the employee available for the entire shift? */
  fullUnavailable: Scalars['Boolean'];
};

export type GQLShiftEmploymentAvailability = {
  /** Date when the availability or unavailability will end or ended */
  endsAt: Scalars['Date'];
  /** Date when the availability or unavailability will start or started */
  startsAt: Scalars['Date'];
};

export type GQLShiftConflict = GQLShiftsCollisionShiftConflict | GQLAbsencesCollisionShiftConflict | GQLAlreadyAssignedShiftConflict | GQLMaximumWorkingHoursShiftConflict | GQLMaximumEarningShiftConflict | GQLMinimumRestShiftConflict | GQLOutsideWorkPeriodShiftConflict | GQLMaximumConsecutiveWorkingDaysShiftConflict | GQLRestrictedShiftPresetsConflict | GQLUnknownShiftConflict | GQLMinimumBreakShiftConflict | GQLLocationsPositionChangedShiftConflict;

export type GQLShiftsCollisionShiftConflict = {
  /** Shifts that cause the collision */
  shifts: Maybe<Array<Maybe<GQLShift>>>;
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLAbsencesCollisionShiftConflict = {
  /** Absences that cause the collision */
  absences: Maybe<Array<Maybe<GQLAbsence>>>;
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLAlreadyAssignedShiftConflict = {
  /** Message from conflict */
  message: Scalars['String'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLMaximumWorkingHoursShiftConflict = {
  /** Total minutes with the shift */
  total: Maybe<Scalars['Float']>;
  /** Assigned monthly value to Employee */
  monthlyMinutes: Maybe<Scalars['Int']>;
  /** Assigned weekly value to Employee */
  weeklyMinutes: Maybe<Scalars['Int']>;
  /** Month for which the exceedance would occur */
  month: Maybe<Scalars['Int']>;
  /** Week for which the exceedance would occur */
  week: Maybe<Scalars['Int']>;
  /** Year for which the exceedance would occur */
  year: Maybe<Scalars['Int']>;
  /** Full name of the Employee concerned */
  name: Maybe<Scalars['String']>;
  /** Total overwork in minutes for the Employee */
  difference: Scalars['Float'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLMaximumEarningShiftConflict = {
  /** Maximum allowed amount */
  employmentMaxPayment: Scalars['Float'];
  /** Total amount to be paid when shift assigned */
  totalPayment: Scalars['Float'];
  /** Currency used by the Company */
  currency: Scalars['String'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLMinimumRestShiftConflict = {
  /** Actual rest time in minutes */
  restMinutes: Scalars['Int'];
  /** Minimum rest time in minutes */
  minimumRestMinutes: Scalars['Int'];
  /** Previous shift end date */
  lastShiftEndsAt: Scalars['Date'];
  /** New shift start date */
  newShiftStartsAt: Scalars['Date'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLOutsideWorkPeriodShiftConflict = {
  /** Start of work period */
  startsAt: Maybe<Scalars['Date']>;
  /** End of work period */
  endsAt: Maybe<Scalars['Date']>;
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLMaximumConsecutiveWorkingDaysShiftConflict = {
  /** Allowed maximum set in company settings */
  maxDays: Scalars['Int'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Name of employment */
  employmentName: Maybe<Scalars['String']>;
};

export type GQLRestrictedShiftPresetsConflict = {
  restrictedShiftPresets: Array<Scalars['String']>;
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLUnknownShiftConflict = {
  /** Original conflict data */
  data: Scalars['Json'];
  /** Message from original conflict */
  message: Scalars['String'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Conflict key */
  conflictKey: Scalars['String'];
};

export type GQLMinimumBreakShiftConflict = {
  /** Shift duration in minutes */
  shiftDuration: Scalars['Int'];
  /** Minimum break time in minutes */
  minBreakTime: Scalars['Int'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLLocationsPositionChangedShiftConflict = {
  /** Message from conflict */
  message: Scalars['String'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
};

export type GQLShiftBreakCollection = GQLPaginatable & {
  items: Array<GQLShiftBreak>;
  pagination: GQLPaginationInfo;
};

export type GQLTagCollection = GQLPaginatable & {
  items: Array<GQLTag>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftPaygradeCollection = GQLPaginatable & {
  items: Array<GQLShiftPaygrade>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftPaygrade = GQLIdentifiable & {
  /** Unique identifier of the ShiftPaygrade */
  id: Scalars['Int'];
  /** PaygradeType associated with the ShiftPaygrade */
  paygradeType: GQLPaygradeType;
  /** Level for which the ShiftPaygrade has been defined (company, shift, or other paygrade, i.e. bonus) */
  paygradeForType: GQLPaygradeLevel;
  /** ID of the entity for which the ShiftPaygrade has been defined */
  paygradeForId: Scalars['Int'];
  shiftId: Scalars['Int'];
  /** Value of ShiftPaygrade */
  value: Scalars['Float'];
};

export type GQLShiftplanCollection = GQLPaginatable & {
  items: Array<GQLShiftplan>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftplanNotifications = {
  count: Scalars['Int'];
};

export type GQLShiftPresetCollection = GQLPaginatable & {
  items: Array<GQLShiftPreset>;
  pagination: GQLPaginationInfo;
};

export type GQLRequestCollection = {
  items: Array<GQLRequest>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftRotationGroupCollection = GQLPaginatable & {
  items: Array<GQLShiftRotationGroup>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftRotationCollection = GQLPaginatable & {
  items: Array<GQLShiftRotation>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftCollection = GQLPaginatable & {
  items: Array<GQLShift>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftsTagCollection = GQLPaginatable & {
  items: Array<GQLShiftsTag>;
  pagination: GQLPaginationInfo;
};

/** A ShiftsTag */
export type GQLShiftsTag = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the ShiftTag */
  id: Scalars['Int'];
  /** Shift associated with the ShiftsTag */
  shift: GQLShift;
  /** Tag associated with the ShiftsTag */
  tag: GQLTag;
  /** Date when the ShiftsTag was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Date when the ShiftsTag was created */
  createdAt: Scalars['Date'];
  /** Date when the ShiftsTag was last updated */
  updatedAt: Scalars['Date'];
};

export type GQLSpecialDateCollection = GQLPaginatable & {
  items: Array<GQLSpecialDate>;
  pagination: GQLPaginationInfo;
};

/** A SpecialDate */
export type GQLSpecialDate = GQLIdentifiable & GQLTimestampable & {
  /** Unique identifier of the SpecialDate */
  id: Scalars['Int'];
  /** Date when the SpecialDate was created */
  createdAt: Scalars['Date'];
  /** Date when the SpecialDate was last updated */
  updatedAt: Scalars['Date'];
  /** Date when the SpecialDate was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Company associated with SpecialDate */
  company: GQLCompany;
  /** Name of SpecialDate */
  name: Scalars['String'];
  /** Start time for a Shift with this ShiftPreset */
  specialDate: Scalars['Date'];
  /** Is this a full-day SpecialDate? */
  fullDay: Scalars['Boolean'];
  /** Does this SpecialDate apply to every location of the company? */
  allLocations: Scalars['Boolean'];
  /** Time when that SpecialDate starts or null if fullDay is true */
  startTime: Maybe<Scalars['String']>;
  /** Time when that SpecialDate ends or null if fullDay is true */
  endTime: Maybe<Scalars['String']>;
  /** Is this SpecialDate counted towards the employee’s working hours? */
  isIncludedInHourAccount: Scalars['Boolean'];
  /** Array of Location to which the SpecialDate is applies (empty if allLocations is true) */
  locations: Array<GQLLocation>;
};

export type GQLStaffShiftCollection = GQLPaginatable & {
  items: Array<GQLStaffShift>;
  pagination: GQLPaginationInfo;
};

export type GQLStaffShiftsTagCollection = GQLPaginatable & {
  items: Array<GQLStaffShiftsTag>;
  pagination: GQLPaginationInfo;
};

/**
 * Translations demonstrate key-value pairs that can be added to a custom Language. In order to use
 * translations a custom Language must be created.
 * While the key for the translation is a given value, the translation is language-specific. This way
 * it is possible to generate a new dictionary for the shyftplan software or customize terms and
 * definitions according to the company's internal structure.
 */
export type GQLTranslation = {
  /** ID of the associated Language */
  languageId: Scalars['Int'];
  /** Key of the translated string */
  key: Scalars['String'];
  /** Translation of the string */
  value: Scalars['String'];
};

export type GQLUserCollection = GQLPaginatable & {
  items: Array<GQLUser>;
  pagination: GQLPaginationInfo;
};

export type GQLMutation = {
  _empty: Maybe<Scalars['String']>;
  /** Create a new Absence */
  createAbsence: Maybe<GQLAbsenceCreateResult>;
  /** Update an existing Absence */
  updateAbsence: Maybe<GQLAbsenceCreateResult>;
  /** Remove an existing Absence */
  deleteAbsence: Maybe<GQLSuccessResponse>;
  /** Approve an Absence */
  approveAbsence: Maybe<GQLAbsenceApproveResult>;
  /** Decline an Absence */
  declineAbsence: Maybe<Scalars['Boolean']>;
  /** Create a new AssignmentGroup */
  createAssignmentGroup: GQLAssignmentGroup;
  /** Remove an existing AssignmentGroup */
  removeAssignmentGroup: GQLSuccessResponse;
  /** Update an existing AssignmentGroup */
  updateAssignmentGroup: GQLAssignmentGroup;
  /** Assign an Employment to the Shifts of an AssignmentGroup */
  assignEmploymentToAssignmentGroup: Array<GQLStaffShift>;
  /** Unassign an Employment from the Shifts of an AssignmentGroup */
  unassignEmploymentFromAssignmentGroup: GQLSuccessResponse;
  /** Create a new Availability */
  createAvailability: Maybe<GQLAvailabilityCreateResult>;
  /** Update an existing Availability */
  updateAvailability: Maybe<GQLAvailabilityCreateResult>;
  /** Remove an existing Availability */
  deleteAvailability: Maybe<Scalars['Boolean']>;
  /** Create a new AvailabilityException */
  createAvailabilityException: Maybe<GQLAvailabilityException>;
  /** Remove an existing AvailabilityException */
  deleteAvailabilityException: Maybe<Scalars['Boolean']>;
  /** Send a chat message */
  sendChatMessage: Maybe<GQLChatMessage>;
  /** Create a new CustomField */
  createCustomField: Maybe<GQLCustomField>;
  /** Update an existing CustomField */
  updateCustomField: Maybe<GQLCustomField>;
  /** Remove an existing CustomField */
  deleteCustomField: Maybe<Scalars['Boolean']>;
  /** Create a new DayNote */
  createDayNote: GQLDayNote;
  /** Remove an existing DayNote */
  removeDayNote: GQLSuccessResponse;
  /** Update an existing DayNote */
  updateDayNote: GQLDayNote;
  /** Remove an existing Employment (soft delete) */
  removeEmployment: Maybe<Scalars['Json']>;
  /** Restore a soft-deleted Employment */
  restoreEmployment: Maybe<GQLEmployment>;
  /** Permanently remove an existing Employment */
  destroyEmployment: Maybe<Scalars['Json']>;
  /** Create a new EmploymentEvaluation */
  createEmploymentEvaluation: GQLEmploymentEvaluation;
  /** Create a new EvaluationBreak */
  createEmploymentEvaluationBreak: GQLEvaluationBreak;
  /** Update an existing EvaluationBreak */
  updateEmploymentEvaluationBreak: GQLEvaluationBreak;
  /** Delete an existing EvaluationBreak */
  deleteEmploymentEvaluationBreak: GQLEvaluationBreak;
  /** Create a new Payment for an EmploymentEvaluation */
  createEmploymentEvaluationPayment: GQLPayment;
  /** Update an existing Payment of an EmploymentEvaluation */
  updateEmploymentEvaluationPayment: GQLPayment;
  /** Remove an existing Payment of an EmploymentEvaluation */
  deleteEmploymentEvaluationPayment: GQLSuccessResponse;
  /** Create a new EmploymentEvaluationTag */
  createEmploymentEvaluationTag: GQLEmploymentEvaluationTag;
  /** Remove an existing EmploymentEvaluationTag */
  deleteEmploymentEvaluationTag: GQLSuccessResponse;
  /** Create a new EmploymentsPosition */
  createEmploymentsPosition: Maybe<GQLEmploymentsPosition>;
  /** Remove an existing EmploymentsPosition */
  deleteEmploymentsPosition: Maybe<Scalars['Boolean']>;
  /** Create a new EmploymentsRotationGroup */
  createEmploymentsRotationGroup: GQLEmploymentsRotationGroup;
  /** Update a new EmploymentsRotationGroup */
  updateEmploymentsRotationGroup: GQLEmploymentsRotationGroup;
  /** Remove an existing EmploymentsRotationGroup */
  deleteEmploymentsRotationGroup: GQLSuccessResponse;
  /** Update an existing EmploymentsSurvey */
  updateEmploymentsSurvey: Maybe<GQLEmploymentsSurveyCollection>;
  /** Update an existing EmploymentsUiSetting */
  updateEmploymentsUiSetting: GQLEmploymentsUiSetting;
  /** Create an Evaluation */
  createEvaluation: GQLEvaluation;
  /** Update an existing Evaluation */
  updateEvaluation: GQLEvaluation;
  /** Create a new EvaluationBreak */
  createEvaluationBreak: GQLEvaluationBreak;
  /** Update an existing EvaluationBreak */
  updateEvaluationBreak: GQLEvaluationBreak;
  /** Delete an existing EvaluationBreak */
  deleteEvaluationBreak: GQLSuccessResponse;
  /** Create a new Payment for an Evaluation */
  createEvaluationPayment: GQLPayment;
  /** Update an existing Payment of an Evaluation */
  updateEvaluationPayment: GQLPayment;
  /** Remove an existing Payment of an Evaluation */
  deleteEvaluationPayment: GQLSuccessResponse;
  /** Create a new IndividualShiftRotation */
  createIndividualShiftRotation: GQLIndividualShiftRotation;
  /** Update an existing IndividualShiftRotation */
  updateIndividualShiftRotation: GQLIndividualShiftRotation;
  /** Remove an existing IndividualShiftRotation */
  deleteIndividualShiftRotation: GQLSuccessResponse;
  /** Create a new Location */
  createLocation: Maybe<GQLLocation>;
  /** Update an existing Location */
  updateLocation: Maybe<GQLLocation>;
  /** Remove an existing Location (soft delete) */
  deleteLocation: Maybe<Scalars['Boolean']>;
  /** Permanently remove an existing Location */
  destroyLocation: Maybe<Scalars['Boolean']>;
  /** Create a new LocationsPosition */
  createLocationsPosition: Maybe<GQLLocationsPosition>;
  /** Remove an existing LocationsPosition */
  deleteLocationsPosition: Maybe<Scalars['Boolean']>;
  /** Perform the login using the provided email and password */
  login: Maybe<GQLLogin>;
  /** Create a new Newsfeed */
  createNewsfeed: GQLCreateResponse;
  /** Update an existing NotificationConfiguration */
  updateNotificationConfiguration: Maybe<Scalars['Boolean']>;
  /** Create a new Paygrade */
  createPaygrade: Maybe<GQLPaygrade>;
  /** Remove an existing Paygrade */
  removePaygrade: Maybe<Scalars['Json']>;
  /** Update an existing Paygrade */
  updatePaygrade: Maybe<GQLPaygrade>;
  /** Create a new Position */
  createPosition: Maybe<GQLPosition>;
  /** Update an existing Position */
  updatePosition: Maybe<GQLPosition>;
  /** Remove an existing Position (soft delete) */
  deletePosition: Maybe<Scalars['Boolean']>;
  /** Permanently remove an existing Position */
  destroyPosition: Maybe<Scalars['Boolean']>;
  /** Add an Employment to a Position */
  addEmploymentToPosition: Maybe<Scalars['Json']>;
  /** Remove an Employment from a Position */
  removeEmploymentFromPosition: Maybe<Scalars['Json']>;
  /** Create a new PunchTiming session */
  startPunchTimeSession: Maybe<GQLPunchtimeSession>;
  /** Create a new Shift by punching in */
  createPunchingShift: Maybe<GQLCreatePunchingShiftResult>;
  /** Record punching in */
  punchIn: Maybe<Scalars['Boolean']>;
  /** Record punching out */
  punchOut: Maybe<GQLPunchOutResult>;
  /** Record the starting of a break */
  startBreak: Maybe<GQLPunchBreak>;
  /** Record the ending of a break */
  endBreak: Maybe<GQLPunchBreak>;
  /** Assign ShiftShowRight for Location to Employment */
  assignLocationShiftShowRight: Maybe<Scalars['Boolean']>;
  /** Remove ShiftShowRight for Location from Employment */
  removeLocationShiftShowRight: Maybe<Scalars['Boolean']>;
  /** Assign ShiftShowRight for LocationsPosition to Employment */
  assignLocationsPositionShiftShowRight: Maybe<Scalars['Boolean']>;
  /** Remove ShiftShowRight for LocationsPosition from Employment */
  removeLocationsPositionShiftShowRight: Maybe<Scalars['Boolean']>;
  /** Assign ShiftManageRight for Location to Employment */
  assignLocationShiftManageRight: Maybe<Scalars['Boolean']>;
  /** Remove ShiftManageRight for Location from Employment */
  removeLocationShiftManageRight: Maybe<Scalars['Boolean']>;
  /** Assign ShiftManageRight for LocationsPosition to Employment */
  assignLocationsPositionShiftManageRight: Maybe<Scalars['Boolean']>;
  /** Remove ShiftManageRight for LocationsPosition from Employment */
  removeLocationsPositionShiftManageRight: Maybe<Scalars['Boolean']>;
  /** Assign PaymentShowRight for Location to Employment */
  assignLocationPaymentShowRight: Maybe<Scalars['Boolean']>;
  /** Remove PaymentShowRight for Location from Employment */
  removeLocationPaymentShowRight: Maybe<Scalars['Boolean']>;
  /** Assign PaymentShowRight for LocationsPosition to Employment */
  assignLocationsPositionPaymentShowRight: Maybe<Scalars['Boolean']>;
  /** Remove PaymentShowRight for LocationsPosition from Employment */
  removeLocationsPositionPaymentShowRight: Maybe<Scalars['Boolean']>;
  /** Assign PaymentManageRight for Location to Employment */
  assignLocationPaymentManageRight: Maybe<Scalars['Boolean']>;
  /** Remove PaymentManageRight for Location from Employment */
  removeLocationPaymentManageRight: Maybe<Scalars['Boolean']>;
  /** Assign PaymentManageRight for LocationsPosition to Employment */
  assignLocationsPositionPaymentManageRight: Maybe<Scalars['Boolean']>;
  /** Remove PaymentManageRight for LocationsPosition from Employment */
  removeLocationsPositionPaymentManageRight: Maybe<Scalars['Boolean']>;
  /** Assign Right to Employment */
  assignRight: Maybe<Scalars['Boolean']>;
  /** Remove Right from Employment */
  removeRight: Maybe<Scalars['Boolean']>;
  /** Create a new ShiftAssignmentGroup (i.e. add a Shift to an AssignmentGroup) */
  createShiftAssignmentGroup: GQLShiftAssignmentGroup;
  /** Remove an existing ShiftAssignmentGroup (i.e. remove a Shift from an AssignmentGroup) */
  deleteShiftAssignmentGroup: GQLSuccessResponse;
  /** Create a new EvaluationTag */
  createShiftEvaluationTag: GQLShiftEvaluationTag;
  /** Remove an existing EvaluationTag */
  deleteShiftEvaluationTag: GQLSuccessResponse;
  /** Create a new ShiftPaygrade */
  createShiftPaygrade: GQLShiftPaygrade;
  /** Remove an existing ShiftPaygrade */
  removeShiftPaygrade: GQLSuccessResponse;
  /** Update an existing ShiftPaygrade */
  updateShiftPaygrade: GQLShiftPaygrade;
  /** Create a new Shiftplan */
  createShiftplan: GQLShiftplan;
  /** Publish an existing Shiftplan */
  publishShiftplan: GQLSuccessResponse;
  /** Remove an existing Shiftplan */
  deleteShiftplan: GQLSuccessResponse;
  /** Update an existing Shiftplan */
  updateShiftplan: GQLShiftplan;
  /** Apply a ShiftRotation to an existing Shiftplan */
  applyShiftplanRotation: GQLBackgroundJobResponse;
  /** Send message to employees from an existing Shiftplan */
  createShiftplanMessage: GQLSuccessResponse;
  /** Send notification to employees from an existing Shiftplan */
  createShiftplanNotification: GQLSuccessResponse;
  /** Start automation for existing Shiftplan to assign employees to open shifts */
  assignEmploymentsToOpenShifts: GQLBackgroundJob;
  /** Copy an existing Shiftplan */
  copyShiftplan: GQLBackgroundJob;
  /** Import shifts from Shiftplan to another existing Shiftplan */
  importShiftplan: GQLBackgroundJob;
  /** Create a new JoinRequest */
  createJoinRequest: Maybe<GQLRequestState>;
  /** Create a new LeaveRequest */
  createLeaveRequest: Maybe<GQLCreateResponse>;
  /** Create a new ReplaceRequest */
  createReplaceRequest: Maybe<GQLRequest>;
  /** Accept a ReplaceRequest */
  acceptReplaceRequest: Maybe<GQLAcceptReplaceRequestResult>;
  /** Accept a ReplaceRequest */
  withdrawAcceptedReplaceRequest: Maybe<GQLPendingReplaceRequest>;
  /** Confirm a ReplaceRequest */
  confirmReplaceRequest: Maybe<GQLConfirmReplaceRequestResult>;
  /** Remove an existing Request */
  deleteRequest: Maybe<GQLSuccessResponse>;
  /** Create a new ShiftRotationGroup */
  createShiftRotationGroup: GQLShiftRotationGroup;
  /** Update an existing ShiftRotationGroup */
  updateShiftRotationGroup: GQLShiftRotationGroup;
  /** Remove an existing ShiftRotationGroup */
  deleteShiftRotationGroup: GQLSuccessResponse;
  /** Assign an employee to ShiftRotationGroup */
  assignEmployeeToShiftRotationGroup: GQLBackgroundJob;
  /** Create a new ShiftRotation */
  createShiftRotation: GQLShiftRotation;
  /** Update an existing ShiftRotation */
  updateShiftRotation: GQLShiftRotation;
  /** Remove an existing ShiftRotation */
  deleteShiftRotation: GQLSuccessResponse;
  /** Create a new Shiftplan based on an existing ShiftRotation */
  createShiftplanFromShiftRotation: GQLBackgroundJobResponse;
  /** Create a new Shift */
  createShift: GQLShiftCreateResult;
  /** Update an existing Shift */
  updateShift: GQLShiftCreateResult;
  /** Remove an existing Shift */
  deleteShift: GQLSuccessResponse;
  /** Copy Shift to another date in the Shiftplan */
  copyShift: GQLShiftCreateResult;
  /** Connect a collection of Shifts */
  connectShifts: GQLSuccessResponse;
  /** Disconnect a connected Shift */
  disconnectShift: GQLSuccessResponse;
  /** Adds a break to a shift */
  createShiftBreak: GQLShiftBreak;
  /** Create a new SpecialDate */
  createSpecialDate: GQLSpecialDate;
  /** Remove an existing SpecialDate */
  removeSpecialDate: GQLSuccessResponse;
  /** Update an existing SpecialDate */
  updateSpecialDate: GQLSpecialDate;
  /** Create a new StaffShift */
  createStaffShift: GQLStaffShiftCreateResult;
  /** Remove an existing StaffShift */
  deleteStaffShift: GQLSuccessResponse;
  moveStaffShift: GQLStaffShiftCreateResult;
  /** Create a new StaffShiftsTag */
  createStaffShiftsTag: GQLStaffShiftsTag;
  /** Remove an existing StaffShiftsTag */
  deleteStaffShiftsTag: GQLSuccessResponse;
  /** Create a new Tag */
  createTag: GQLTag;
  /** Update an existing Tag */
  updateTag: GQLTag;
  /** Remove an existing Tag */
  deleteTag: GQLSuccessResponse;
  /** Add Tag to Shift */
  addTagToShift: GQLShiftTag;
  /** Remove Tag from Shift */
  removeTagFromShift: GQLShiftTag;
  /** Create a new Translation */
  createTranslation: Maybe<GQLTranslation>;
  /** Update an existing Translation */
  updateTranslation: Maybe<GQLTranslation>;
  /** Remove an existing Translation */
  deleteTranslation: Maybe<Scalars['Boolean']>;
  /** Create a new User */
  createUser: Maybe<GQLUser>;
  /** Update an existing User */
  updateUser: Maybe<GQLUser>;
};

export type GQLMutationCreateAbsenceArgs = {
  absence: GQLAbsenceInput;
  forceCollision?: Maybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: Maybe<Scalars['Boolean']>;
};

export type GQLMutationUpdateAbsenceArgs = {
  id: Scalars['Int'];
  absence: GQLAbsenceInput;
  forceCollision?: Maybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: Maybe<Scalars['Boolean']>;
};

export type GQLMutationDeleteAbsenceArgs = {
  id: Scalars['Int'];
};

export type GQLMutationApproveAbsenceArgs = {
  id: Scalars['Int'];
  params: Maybe<GQLAbsenceApproveInput>;
};

export type GQLMutationDeclineAbsenceArgs = {
  id: Scalars['Int'];
  params: Maybe<GQLAbsenceDeclineInput>;
};

export type GQLMutationCreateAssignmentGroupArgs = {
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  assignmentGroup: GQLAssignmentGroupCreateInput;
};

export type GQLMutationRemoveAssignmentGroupArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationUpdateAssignmentGroupArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  assignmentGroup: GQLAssignmentGroupUpdateInput;
};

export type GQLMutationAssignEmploymentToAssignmentGroupArgs = {
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  employmentId: Scalars['Int'];
  strategy: GQLAssignmentGroupEmploymentAssignStrategy;
};

export type GQLMutationUnassignEmploymentFromAssignmentGroupArgs = {
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  employmentId: Scalars['Int'];
  onlyFuture: Maybe<Scalars['Boolean']>;
  shiftId: Maybe<Scalars['Int']>;
};

export type GQLMutationCreateAvailabilityArgs = {
  availability: GQLAvailabilityCreateInput;
};

export type GQLMutationUpdateAvailabilityArgs = {
  id: Scalars['Int'];
  availability: GQLAvailabilityUpdateInput;
};

export type GQLMutationDeleteAvailabilityArgs = {
  id: Scalars['Int'];
};

export type GQLMutationCreateAvailabilityExceptionArgs = {
  availabilityException: Maybe<GQLAvailabilityExceptionInput>;
};

export type GQLMutationDeleteAvailabilityExceptionArgs = {
  id: Maybe<Scalars['Int']>;
};

export type GQLMutationSendChatMessageArgs = {
  companyId: Scalars['Int'];
  chat: GQLChatMessageInput;
};

export type GQLMutationCreateCustomFieldArgs = {
  customField: GQLCustomFieldCreateInput;
};

export type GQLMutationUpdateCustomFieldArgs = {
  id: Scalars['Int'];
  customField: GQLCustomFieldUpdateInput;
};

export type GQLMutationDeleteCustomFieldArgs = {
  id: Scalars['Int'];
};

export type GQLMutationCreateDayNoteArgs = {
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  dayNote: GQLDayNoteCreateInput;
};

export type GQLMutationRemoveDayNoteArgs = {
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationUpdateDayNoteArgs = {
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  id: Scalars['Int'];
  dayNote: GQLDayNoteCreateInput;
};

export type GQLMutationRemoveEmploymentArgs = {
  id: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type GQLMutationRestoreEmploymentArgs = {
  id: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type GQLMutationDestroyEmploymentArgs = {
  id: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type GQLMutationCreateEmploymentEvaluationArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  employmentEvaluation: GQLEmploymentEvaluationInput;
};

export type GQLMutationCreateEmploymentEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
};

export type GQLMutationUpdateEmploymentEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
};

export type GQLMutationDeleteEmploymentEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
};

export type GQLMutationCreateEmploymentEvaluationPaymentArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  payment: GQLPaymentCreateInput;
};

export type GQLMutationUpdateEmploymentEvaluationPaymentArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  payment: GQLPaymentUpdateInput;
};

export type GQLMutationDeleteEmploymentEvaluationPaymentArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  parentPaymentId: Maybe<Scalars['Int']>;
};

export type GQLMutationCreateEmploymentEvaluationTagArgs = {
  companyId: Scalars['Int'];
  evaluationId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationDeleteEmploymentEvaluationTagArgs = {
  companyId: Scalars['Int'];
  evaluationId: Scalars['Int'];
  evaluationTagId: Scalars['Int'];
};

export type GQLMutationCreateEmploymentsPositionArgs = {
  employmentsPosition: GQLEmploymentsPositionInput;
};

export type GQLMutationDeleteEmploymentsPositionArgs = {
  id: Scalars['Int'];
};

export type GQLMutationCreateEmploymentsRotationGroupArgs = {
  employmentsRotationGroup: GQLEmploymentsRotationGroupCreateInput;
};

export type GQLMutationUpdateEmploymentsRotationGroupArgs = {
  id: Scalars['Int'];
  employmentsRotationGroup: GQLEmploymentsRotationGroupUpdateInput;
  strategyToUnassignFromShifts: Maybe<GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy>;
};

export type GQLMutationDeleteEmploymentsRotationGroupArgs = {
  id: Scalars['Int'];
};

export type GQLMutationUpdateEmploymentsSurveyArgs = {
  id: Scalars['Int'];
  employmentsSurvey: GQLEmploymentsSurveyInput;
};

export type GQLMutationUpdateEmploymentsUiSettingArgs = {
  employmentId: Scalars['Int'];
  companyId: Scalars['Int'];
  version: Scalars['String'];
  settings: Scalars['String'];
};

export type GQLMutationCreateEvaluationArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluation: GQLEvaluationInput;
};

export type GQLMutationUpdateEvaluationArgs = {
  staffShiftId: Scalars['Int'];
  didShow: Scalars['Boolean'];
};

export type GQLMutationCreateEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
};

export type GQLMutationUpdateEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
};

export type GQLMutationDeleteEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
};

export type GQLMutationCreateEvaluationPaymentArgs = {
  staffShiftId: Scalars['Int'];
  payment: GQLPaymentCreateInput;
};

export type GQLMutationUpdateEvaluationPaymentArgs = {
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  payment: GQLPaymentUpdateInput;
};

export type GQLMutationDeleteEvaluationPaymentArgs = {
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  parentPaymentId: Maybe<Scalars['Int']>;
};

export type GQLMutationCreateIndividualShiftRotationArgs = {
  individualShiftRotation: GQLIndividualShiftRotationCreateInput;
};

export type GQLMutationUpdateIndividualShiftRotationArgs = {
  id: Scalars['Int'];
  individualShiftRotation: GQLIndividualShiftRotationCreateInput;
};

export type GQLMutationDeleteIndividualShiftRotationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationCreateLocationArgs = {
  location: GQLLocationCreateInput;
};

export type GQLMutationUpdateLocationArgs = {
  id: Scalars['Int'];
  location: GQLLocationUpdateInput;
};

export type GQLMutationDeleteLocationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDestroyLocationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationCreateLocationsPositionArgs = {
  locationsPosition: Maybe<GQLLocationsPositionCreateInput>;
};

export type GQLMutationDeleteLocationsPositionArgs = {
  id: Scalars['Int'];
};

export type GQLMutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type GQLMutationCreateNewsfeedArgs = {
  newsfeed: Maybe<GQLNewsfeedInput>;
};

export type GQLMutationUpdateNotificationConfigurationArgs = {
  employmentId: Scalars['Int'];
  companyId: Maybe<Scalars['Int']>;
  notificationConfiguration: GQLNotificationConfigurationUpdateInput;
};

export type GQLMutationCreatePaygradeArgs = {
  employmentId: Scalars['Int'];
  paygrade: GQLPaygradeCreateInput;
};

export type GQLMutationRemovePaygradeArgs = {
  employmentId: Scalars['Int'];
  paygradeId: Scalars['Int'];
  deletePaygradeConfig: Maybe<GQLPaygradeDeleteInput>;
};

export type GQLMutationUpdatePaygradeArgs = {
  employmentId: Scalars['Int'];
  paygradeId: Scalars['Int'];
  paygrade: Maybe<GQLPaygradeUpdateInput>;
};

export type GQLMutationCreatePositionArgs = {
  position: GQLPositionCreateInput;
};

export type GQLMutationUpdatePositionArgs = {
  id: Scalars['Int'];
  position: GQLPositionUpdateInput;
};

export type GQLMutationDeletePositionArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDestroyPositionArgs = {
  id: Scalars['Int'];
};

export type GQLMutationAddEmploymentToPositionArgs = {
  employmentId: Scalars['Int'];
  positionId: Scalars['Int'];
  locationId: Scalars['Int'];
};

export type GQLMutationRemoveEmploymentFromPositionArgs = {
  employmentId: Scalars['Int'];
  positionId: Scalars['Int'];
  locationId: Scalars['Int'];
  keepFutureStaffShifts: Maybe<Scalars['Boolean']>;
};

export type GQLMutationStartPunchTimeSessionArgs = {
  companyId: Maybe<Scalars['Int']>;
};

export type GQLMutationCreatePunchingShiftArgs = {
  punchingShift: Maybe<GQLCreatePunchingShiftInput>;
};

export type GQLMutationPunchInArgs = {
  encryptedCompanyId: Maybe<Scalars['String']>;
  userToken: Scalars['Int'];
  companyId: Maybe<Scalars['Int']>;
  staffShiftId: Scalars['Int'];
  startTime: Maybe<Scalars['Date']>;
};

export type GQLMutationPunchOutArgs = {
  encryptedCompanyId: Maybe<Scalars['String']>;
  punchTimingId: Scalars['Int'];
  companyId: Maybe<Scalars['Int']>;
  additionalBreakTime: Maybe<Scalars['Float']>;
  endTime: Maybe<Scalars['Date']>;
  ignoreWarning: Maybe<Scalars['Boolean']>;
};

export type GQLMutationStartBreakArgs = {
  encryptedCompanyId: Maybe<Scalars['String']>;
  punchTimingId: Scalars['Int'];
  companyId: Maybe<Scalars['Int']>;
  startTime: Maybe<Scalars['Date']>;
};

export type GQLMutationEndBreakArgs = {
  encryptedCompanyId: Maybe<Scalars['String']>;
  punchTimingId: Scalars['Int'];
  companyId: Maybe<Scalars['Int']>;
  punchBreakId: Scalars['Int'];
  endTime: Maybe<Scalars['Date']>;
};

export type GQLMutationAssignLocationShiftShowRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationShiftShowRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationsPositionShiftShowRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationsPositionShiftShowRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationShiftManageRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationShiftManageRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationsPositionShiftManageRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationsPositionShiftManageRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationPaymentShowRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationPaymentShowRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationsPositionPaymentShowRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationsPositionPaymentShowRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationPaymentManageRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationPaymentManageRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationsPositionPaymentManageRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationsPositionPaymentManageRightArgs = {
  right: Maybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignRightArgs = {
  right: Maybe<GQLRightChangeInput>;
};

export type GQLMutationRemoveRightArgs = {
  right: Maybe<GQLRightChangeInput>;
};

export type GQLMutationCreateShiftAssignmentGroupArgs = {
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  shiftId: Scalars['Int'];
};

export type GQLMutationDeleteShiftAssignmentGroupArgs = {
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  shiftId: Scalars['Int'];
};

export type GQLMutationCreateShiftEvaluationTagArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationDeleteShiftEvaluationTagArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationCreateShiftPaygradeArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  paygrade: GQLShiftPaygradeCreateInput;
};

export type GQLMutationRemoveShiftPaygradeArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationUpdateShiftPaygradeArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  id: Scalars['Int'];
  paygrade: GQLShiftPaygradeCreateInput;
};

export type GQLMutationCreateShiftplanArgs = {
  shiftplan: GQLShiftplanCreateInput;
};

export type GQLMutationPublishShiftplanArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanPublishInput;
};

export type GQLMutationDeleteShiftplanArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationUpdateShiftplanArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  shiftplan: GQLShiftplanUpdateInput;
};

export type GQLMutationApplyShiftplanRotationArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLApplyShiftplanRotationInput;
};

export type GQLMutationCreateShiftplanMessageArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanCreateMessageInput;
};

export type GQLMutationCreateShiftplanNotificationArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanCreateNotificationInput;
};

export type GQLMutationAssignEmploymentsToOpenShiftsArgs = {
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
};

export type GQLMutationCopyShiftplanArgs = {
  companyId: Scalars['Int'];
  copyShiftplanParams: GQLShiftplanCopyInput;
};

export type GQLMutationImportShiftplanArgs = {
  companyId: Scalars['Int'];
  importShiftplanParams: GQLShiftplanImportInput;
};

export type GQLMutationCreateJoinRequestArgs = {
  request: GQLOverridableRequestInput;
};

export type GQLMutationCreateLeaveRequestArgs = {
  request: GQLRequestInput;
};

export type GQLMutationCreateReplaceRequestArgs = {
  request: GQLRequestInput;
};

export type GQLMutationAcceptReplaceRequestArgs = {
  request: GQLAcceptReplaceRequestInput;
};

export type GQLMutationWithdrawAcceptedReplaceRequestArgs = {
  request: GQLWithdrawAcceptedReplaceRequestInput;
};

export type GQLMutationConfirmReplaceRequestArgs = {
  request: GQLConfirmReplaceRequestInput;
};

export type GQLMutationDeleteRequestArgs = {
  id: Scalars['Int'];
  request: GQLRequestInput;
};

export type GQLMutationCreateShiftRotationGroupArgs = {
  shiftRotationGroup: GQLShiftRotationGroupCreateInput;
};

export type GQLMutationUpdateShiftRotationGroupArgs = {
  id: Scalars['Int'];
  shiftRotationGroup: GQLShiftRotationGroupCreateInput;
};

export type GQLMutationDeleteShiftRotationGroupArgs = {
  id: Scalars['Int'];
};

export type GQLMutationAssignEmployeeToShiftRotationGroupArgs = {
  companyId: Scalars['Int'];
  shiftRotationGroupAssignInput: GQLShiftRotationGroupAssignInput;
};

export type GQLMutationCreateShiftRotationArgs = {
  shiftRotation: GQLShiftRotationCreateInput;
};

export type GQLMutationUpdateShiftRotationArgs = {
  id: Scalars['Int'];
  shiftRotation: GQLShiftRotationCreateInput;
};

export type GQLMutationDeleteShiftRotationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationCreateShiftplanFromShiftRotationArgs = {
  companyId: Scalars['Int'];
  shiftRotationId: Scalars['Int'];
  input: GQLShiftRotationShiftplanCreateInput;
};

export type GQLMutationCreateShiftArgs = {
  shift: GQLShiftCreateInput;
};

export type GQLMutationUpdateShiftArgs = {
  id: Scalars['Int'];
  shift: GQLShiftUpdateInput;
};

export type GQLMutationDeleteShiftArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  deleteParams: GQLShiftDeleteInput;
};

export type GQLMutationCopyShiftArgs = {
  companyId: Scalars['Int'];
  copyParams: GQLShiftCopyInput;
};

export type GQLMutationConnectShiftsArgs = {
  shiftIds: Array<Scalars['Int']>;
  shiftplanId: Scalars['Int'];
};

export type GQLMutationDisconnectShiftArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
};

export type GQLMutationCreateShiftBreakArgs = {
  shiftId: Scalars['Int'];
  shiftBreak: GQLShiftBreakCreateInput;
};

export type GQLMutationCreateSpecialDateArgs = {
  companyId: Scalars['Int'];
  specialDate: GQLSpecialDateCreateInput;
};

export type GQLMutationRemoveSpecialDateArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationUpdateSpecialDateArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  specialDate: GQLSpecialDateCreateInput;
};

export type GQLMutationCreateStaffShiftArgs = {
  companyId: Scalars['Int'];
  staffShift: GQLStaffShiftCreateInput;
};

export type GQLMutationDeleteStaffShiftArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  unassignFromConnected?: Scalars['Boolean'];
};

export type GQLMutationMoveStaffShiftArgs = {
  staffShift: GQLStaffShiftMoveInput;
};

export type GQLMutationCreateStaffShiftsTagArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationDeleteStaffShiftsTagArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationCreateTagArgs = {
  tag: GQLTagCreateInput;
};

export type GQLMutationUpdateTagArgs = {
  id: Scalars['Int'];
  tag: GQLTagUpdateInput;
};

export type GQLMutationDeleteTagArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
};

export type GQLMutationAddTagToShiftArgs = {
  id: Maybe<Scalars['Int']>;
  params: GQLShiftTagInput;
};

export type GQLMutationRemoveTagFromShiftArgs = {
  id: Maybe<Scalars['Int']>;
  params: GQLShiftTagInput;
};

export type GQLMutationCreateTranslationArgs = {
  translation: Maybe<GQLTranslationCreateInput>;
};

export type GQLMutationUpdateTranslationArgs = {
  id: Scalars['Int'];
  translation: Maybe<GQLTranslationUpdateInput>;
};

export type GQLMutationDeleteTranslationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationCreateUserArgs = {
  user: GQLUserCreateInput;
};

export type GQLMutationUpdateUserArgs = {
  id: Scalars['Int'];
  user: GQLUserUpdateInput;
};

export type GQLAbsenceInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Date when the Absence will start */
  startsAt: Scalars['Date'];
  /** Date when the Absence will end */
  endsAt: Scalars['Date'];
  /** Initial state of the Absence */
  state: GQLAbsenceState;
  /** ID of the AbsenceReason */
  absenceReasonId: Scalars['Int'];
  /** Is the Absence for the full day(s)? */
  isFullDay: Scalars['Boolean'];
  /** Is this a paid or unpaid Absence? */
  paid: Scalars['Boolean'];
  /** Number of days from Absence */
  days: Scalars['Float'];
  /** Note of Absence */
  notes: Maybe<Scalars['String']>;
};

export type GQLAbsenceCreateResult = GQLAbsence | GQLConflictsResponse;

export type GQLConflictsResponse = {
  success: Scalars['Boolean'];
  canManage: Scalars['Boolean'];
  conflicts: Array<GQLConflict>;
};

export type GQLConflict = GQLCollisionsConflict | GQLMaxDaysPerYearConflict | GQLCarryOverDeadlineErrorConflict | GQLCarryOverMultipleYearsConflict | GQLUnknownConflict;

export type GQLCollisionsConflict = {
  ignorable: Scalars['Boolean'];
  shifts: Maybe<Array<GQLShift>>;
  absences: Maybe<Array<GQLAbsence>>;
};

export type GQLMaxDaysPerYearConflict = {
  ignorable: Scalars['Boolean'];
  before: Scalars['Float'];
  after: Scalars['Float'];
  limit: Scalars['Float'];
};

export type GQLCarryOverDeadlineErrorConflict = {
  ignorable: Scalars['Boolean'];
  deadline: Scalars['Date'];
};

export type GQLCarryOverMultipleYearsConflict = {
  ignorable: Scalars['Boolean'];
};

export type GQLUnknownConflict = {
  ignorable: Scalars['Boolean'];
  data: Maybe<Scalars['Json']>;
};

export type GQLSuccessResponse = {
  success: Scalars['Boolean'];
  error: Maybe<Scalars['String']>;
};

export type GQLAbsenceApproveInput = {
  /** Create Absence even when conflicts exist? */
  forceCollision: Maybe<Scalars['Boolean']>;
  /** Remove staff from conflicting Shifts? */
  forceCollisionAndRemove: Maybe<Scalars['Boolean']>;
  /** The company in which the absence should be approved */
  companyId: Scalars['Int'];
};

export type GQLAbsenceApproveResult = GQLCreateResponse | GQLConflictsResponse;

export type GQLCreateResponse = {
  success: Scalars['Boolean'];
};

export type GQLAbsenceDeclineInput = {
  /** Optional message for a refused absence */
  refuseMessage: Maybe<Scalars['String']>;
  /** The company in which the absence should be approved */
  companyId: Scalars['Int'];
};

export type GQLAssignmentGroupCreateInput = {
  /** Additional metadata related to the AssignmentGroup */
  metadata: Maybe<Scalars['Json']>;
  /** Name of the AssignmentGroup */
  name: Scalars['String'];
};

export type GQLAssignmentGroupUpdateInput = {
  /** Additional metadata related to the AssignmentGroup */
  metadata: Maybe<Scalars['Json']>;
  /** Name of the AssignmentGroup */
  name: Maybe<Scalars['String']>;
};

export enum GQLAssignmentGroupEmploymentAssignStrategy {
  IGNORESHIFTCONFLICTS = 'ignoreShiftConflicts',
  SKIPSHIFTSWITHCONFLICTS = 'skipShiftsWithConflicts'
}

export type GQLAvailabilityCreateInput = {
  /** Date when the Availability will start (rounded to steps of 15 minutes) */
  startsAt: Scalars['Date'];
  /** Date when the Availability will start (rounded to steps of 15 minutes) */
  endsAt: Scalars['Date'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Is the Availability repeated weekly? */
  repeat: Maybe<Scalars['Boolean']>;
  /** Is this Availability indicating that the Employee is available or unavailable? */
  available: Maybe<Scalars['Boolean']>;
  /** Override conflicting availabilities */
  override: Maybe<Scalars['Boolean']>;
};

export type GQLAvailabilityCreateResult = GQLAvailability | GQLAvailabilityConflictResponse;

export type GQLAvailabilityConflictResponse = {
  success: Maybe<Scalars['Boolean']>;
  conflicts: Maybe<Array<Maybe<GQLAvailabilityConflicts>>>;
  errorKey: Maybe<Scalars['String']>;
};

export type GQLAvailabilityConflicts = {
  availabilities: Maybe<Array<Maybe<GQLAvailability>>>;
};

export type GQLAvailabilityUpdateInput = {
  /** Date when the Availability will start (rounded to steps of 15 minutes) */
  startsAt: Scalars['Date'];
  /** Date when the Availability will start (rounded to steps of 15 minutes) */
  endsAt: Scalars['Date'];
  /** Update all records of a repeating Availability */
  updateAll: Scalars['Boolean'];
  /** Is this Availability indicating that the Employee is available or unavailable? */
  available: Maybe<Scalars['Boolean']>;
  /** Override conflicting availabilities */
  override: Maybe<Scalars['Boolean']>;
};

export type GQLAvailabilityExceptionInput = {
  /** ID of the associated Availability */
  availabilityId: Scalars['Int'];
  /**
   * Start of the selected Availability out of a repeated Availability which should be counted as an
   * exception (must fit the exact Availability start)
   */
  startsAt: Scalars['Date'];
  /**
   * End of the selected Availability out of a repeated Availability which should be counted as an
   * exception (must fit the exact Availability start)
   */
  endsAt: Scalars['Date'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
};

export type GQLChatMessageInput = {
  message: Scalars['String'];
  recipientId: Scalars['String'];
  recipientType: GQLChatType;
};

export type GQLCustomFieldCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Name of the CustomField */
  name: Scalars['String'];
};

export type GQLCustomFieldUpdateInput = {
  /** Name of the CustomField */
  name: Scalars['String'];
};

export type GQLDayNoteCreateInput = {
  /** Start date of the DayNote */
  startsAt: Scalars['Date'];
  /** End date of the DayNote */
  endsAt: Scalars['Date'];
  /** Title of the DayNote */
  title: Scalars['String'];
  /** Optional description for the DayNote */
  description: Maybe<Scalars['String']>;
};

export type GQLEmploymentEvaluationInput = {
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
  break: Maybe<Scalars['Float']>;
  untimedBreak: Maybe<Scalars['Float']>;
  note: Maybe<Scalars['String']>;
};

export type GQLEvaluationBreakInput = {
  /** Start date of the Evaluation break */
  startsAt: Maybe<Scalars['Date']>;
  /** End date of the Evaluation break */
  endsAt: Maybe<Scalars['Date']>;
};

export type GQLPaymentCreateInput = {
  /** Value of the Payment */
  value: Maybe<Scalars['Float']>;
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** ID of the parent Payment (or null if no parent) */
  parentPaymentId: Maybe<Scalars['Int']>;
};

export type GQLPaymentUpdateInput = {
  /** Value of PaygradeType */
  value: Maybe<Scalars['Float']>;
  /** ID of the parent Payment (or null if no parent) */
  parentPaymentId: Maybe<Scalars['Int']>;
};

export type GQLEmploymentsPositionInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** ID of the associated LocationsPosition */
  locationsPositionId: Scalars['Int'];
};

export type GQLEmploymentsRotationGroupCreateInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** ID of the associated ShiftRotationGroup */
  shiftRotationGroupId: Scalars['Int'];
  /** Start date for association */
  startsAt: Maybe<Scalars['Date']>;
  /** End date for association */
  endsAt: Maybe<Scalars['Date']>;
};

export type GQLEmploymentsRotationGroupUpdateInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** Start date for association */
  startsAt: Maybe<Scalars['Date']>;
  /** End date for association */
  endsAt: Maybe<Scalars['Date']>;
};

export enum GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy {
  ALL = 'all',
  ROTATION_GROUP = 'rotation_group'
}

export type GQLEmploymentsSurveyInput = {
  /** First name of the employee */
  firstName: Maybe<Scalars['String']>;
  /** Middle name of the employee */
  middleName: Maybe<Scalars['String']>;
  /** Last name of the employee */
  lastName: Maybe<Scalars['String']>;
  /** Set to true if an address other than the default adress of the User should be used */
  otherAddress: Maybe<Scalars['Boolean']>;
  /** Date when the employee joined */
  dateOfEntry: Maybe<Scalars['Date']>;
  /** Birth date of the employee */
  birthDate: Maybe<Scalars['Date']>;
  /** Birth place of the employee */
  birthPlace: Maybe<Scalars['String']>;
  /** Nationality of the employee */
  nationality: Maybe<Scalars['String']>;
  /** Gender of the employee */
  gender: Maybe<Scalars['String']>;
  /** Relevant for the deduction of church tax from your gross pay. */
  denomination: Maybe<Scalars['String']>;
  /** Name of the bank */
  bankName: Maybe<Scalars['String']>;
  /** Name of the bank account holder */
  bankAccountHolder: Maybe<Scalars['String']>;
  /** IBAN */
  bankAccountNumber: Maybe<Scalars['String']>;
  /** BIC */
  bankIdentifierCode: Maybe<Scalars['String']>;
  /** Status of employment. As of now only German taxation options */
  employmentRelationship: Maybe<Scalars['String']>;
  /** Main or secondary employment? */
  employmentType: Maybe<Scalars['String']>;
  /** Whether the employee has one or more other mini jobs */
  furtherMinijobs: Maybe<Scalars['Boolean']>;
  /** Salary if the employment is a mini job */
  minijobSalary: Maybe<Scalars['Float']>;
  /** Type of exemption from pension insurance if the employment is a mini job */
  pensionInsuranceExemption: Maybe<Scalars['String']>;
  /** Description of main work activity */
  jobDescription: Maybe<Scalars['String']>;
  /** Highest school degree */
  highestSchoolDegree: Maybe<Scalars['String']>;
  /** Highest level of education */
  highestLevelOfEducation: Maybe<Scalars['String']>;
  /** Identification number of the health insurance provider */
  healthInsuranceIdentificationNumber: Maybe<Scalars['String']>;
  /** Name of the health insurance provider */
  healthInsuranceName: Maybe<Scalars['String']>;
  /** Social security number */
  socialSecurityNumber: Maybe<Scalars['String']>;
  /** Whether the employee has worked in Germany before or not */
  neverWorkedInGermany: Maybe<Scalars['Boolean']>;
  /** Tax identification number (also called personal identification number, IdNr. or tax ID) */
  taxIdentificationNumber: Maybe<Scalars['String']>;
  /** Marital status */
  familyStatus: Maybe<Scalars['String']>;
  /** Tax bracket */
  taxBracket: Maybe<Scalars['String']>;
  /** Only needed if tax bracket is 'IV with factor' */
  taxBracketFactor: Maybe<Scalars['String']>;
  /** Whether the employee has capital-forming benefits */
  hasCapitalFormingBenefits: Maybe<Scalars['Boolean']>;
  /** Bank that issued the capital-forming benefits */
  capitalFormingBenefitsBankName: Maybe<Scalars['String']>;
  /** Contract number of capital-forming benefits contract */
  capitalFormingBenefitsContractNumber: Maybe<Scalars['String']>;
  /** Commencement of the capital-forming benefits contract */
  capitalFormingBenefitsContractStart: Maybe<Scalars['Date']>;
  /** Amount per month going into the capital-forming benefits contract */
  capitalFormingBenefitsTotal: Maybe<Scalars['Float']>;
  /** Employer's contribution towards the capital-forming benefits contract */
  capitalFormingBenefitsEmployersContribution: Maybe<Scalars['Float']>;
  /** Whether the employee has children */
  hasChildren: Maybe<Scalars['Boolean']>;
  /** Number of children */
  childrenCount: Maybe<Scalars['Int']>;
  /** Whether a proof has been provided that the employee has children */
  childrenProof: Maybe<Scalars['String']>;
  /** Amount of allowance the employee is entitled to in case they have children */
  childrenAllowance: Maybe<Scalars['Float']>;
  /** Additional comments */
  comments: Maybe<Scalars['String']>;
  /** Leaving date of the employee (in case of fixed-term contract) */
  exitDate: Maybe<Scalars['Date']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: Maybe<Scalars['Boolean']>;
  /** Working hours per week */
  weeklyHours: Maybe<Scalars['Float']>;
  /** Staff number */
  staffNumber: Maybe<Scalars['String']>;
  /** Phone number */
  phoneNumber: Maybe<Scalars['String']>;
  /** Note */
  note: Maybe<Scalars['String']>;
  /** Name of the street the employee is living in (only when otherAddress = true) */
  street: Maybe<Scalars['String']>;
  /** House number of the street the employee is living in (only when otherAddress = true) */
  streetNumber: Maybe<Scalars['String']>;
  /** Zip code of the city the employee is living in (only when otherAddress = true) */
  zip: Maybe<Scalars['String']>;
  /** Name of the city the employee is living in (only when otherAddress = true) */
  city: Maybe<Scalars['String']>;
  /** Name of the district etc. the employee is living in (only when otherAddress = true) */
  subcity: Maybe<Scalars['String']>;
  /** Name of the country's state the employee is living in (only when otherAddress = true) */
  state: Maybe<Scalars['String']>;
  /** Name of the country the employee is living in (only when otherAddress = true) */
  country: Maybe<Scalars['String']>;
  visibility: Maybe<Scalars['String']>;
};

export type GQLEmploymentsSurveyCollection = GQLPaginatable & {
  items: Array<GQLEmploymentsSurvey>;
  pagination: GQLPaginationInfo;
};

export type GQLEvaluationInput = {
  /** Evaluated duration of the Shift */
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
  break: Maybe<Scalars['Float']>;
  untimedBreak: Maybe<Scalars['Float']>;
  note: Maybe<Scalars['String']>;
};

export type GQLIndividualShiftRotationCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** When does the the rotation itself start */
  startsAt: Maybe<Scalars['Date']>;
  /** When does the the rotation itself end */
  endsAt: Maybe<Scalars['Date']>;
  /** Anchor date of the rotation */
  anchorDate: Scalars['Date'];
  /** After how many days does the rotation repeat? */
  rotationInterval: Scalars['Int'];
  /** An array of shift preset IDs associated with the ShiftRotationGroup */
  shiftPresetIds: Array<Scalars['Int']>;
};

export type GQLLocationCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Name of the Location */
  name: Scalars['String'];
  /** Sorting position of the Location */
  sort: Maybe<Scalars['Int']>;
};

export type GQLLocationUpdateInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** Name of the Location */
  name: Maybe<Scalars['String']>;
  /** Sorting position of the Location */
  sort: Maybe<Scalars['Int']>;
};

export type GQLLocationsPositionCreateInput = {
  /** ID of the associated Location */
  locationId: Scalars['Int'];
  /** ID of the associated Position */
  positionId: Scalars['Int'];
  /** Sorting position of the LocationsPosition */
  sort: Maybe<Scalars['Int']>;
};

/**
 * Email and password are the credentials for the login. To get an authentication token for using
 * the API a successful login is required.
 */
export type GQLLogin = {
  /** An automatically generated token upon login */
  authenticationToken: Scalars['String'];
  /** The User associated with the Login */
  users: Maybe<GQLUser>;
  /** The Companies associated with the Login */
  companies: Maybe<Array<Maybe<GQLCompany>>>;
  /** The Locations associated with the Login */
  locations: Maybe<Array<Maybe<GQLLocation>>>;
  /** The Positions associated with the Login */
  positions: Maybe<Array<Maybe<GQLPosition>>>;
  /** The Employments associated with the Login */
  employments: Maybe<Array<Maybe<GQLEmployment>>>;
  /** The Rights associated with the Login */
  rights: Maybe<Array<Maybe<GQLRight>>>;
  /** The Employment currently associated with the Login */
  currentEmploymentId: Maybe<Scalars['Int']>;
};

export type GQLNewsfeedInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** Message posted to the Newsfeed */
  message: Maybe<Scalars['String']>;
};

export type GQLNotificationConfigurationUpdateInput = {
  /** Should Absence notifications be sent via email? */
  absencesMail: Maybe<Scalars['Boolean']>;
  /** Should Absence notifications be sent to the User's mobile device? */
  absencesMobile: Maybe<Scalars['Boolean']>;
  /** Should Request refusal notifications be sent via email? */
  applicationRequestRefusedMail: Maybe<Scalars['Boolean']>;
  /** Should Request refusal notifications be sent to the User's mobile device? */
  applicationRequestRefusedMobile: Maybe<Scalars['Boolean']>;
  /** Should auto punchout notifications be sent to the User's mobile device? */
  autoPunchoutMobile: Maybe<Scalars['Boolean']>;
  /** Should Evaluation notifications be sent via email? */
  evaluationsMail: Maybe<Scalars['Boolean']>;
  /** Should Evaluation notifications be sent to the User's mobile device? */
  evaluationsMobile: Maybe<Scalars['Boolean']>;
  /** Should messages be sent to the User's mobile device? */
  messagesMobile: Maybe<Scalars['Boolean']>;
  /** Should Request notifications be sent to the User's mobile device? */
  requestsMobile: Maybe<Scalars['Boolean']>;
  /** Should Shiftplan notifications be sent to the User's mobile device? */
  shiftplansMobile: Maybe<Scalars['Boolean']>;
  /** Should staff messages be sent to the User's mobile device? */
  staffMessagesMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Absence notifications via email? */
  stakeholderAbsencesMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Absence notifications on their mobile devices? */
  stakeholderAbsencesMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto punchout notifications on their mobile devices? */
  stakeholderAutoPunchoutMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto change request notifications via email? */
  stakeholderAutoRequestChangeAcceptMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto change request notifications on their mobile devices? */
  stakeholderAutoRequestChangeAcceptMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Evaluation suggestions on their mobile devices? */
  stakeholderEvaluationsMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Request notifications via email? */
  stakeholderRequestsMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Request notifications on their mobile devices? */
  stakeholderRequestsMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive shift application notifications via email? */
  stakeholderShiftApplicationMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive shift application notifications on their mobile devices? */
  stakeholderShiftApplicationMobile: Maybe<Scalars['Boolean']>;
};

export type GQLPaygradeCreateInput = {
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Value of Paygrade */
  value: Scalars['Float'];
  /** ID of the Paygrade */
  paygradeId: Scalars['Int'];
  /** Update Paygrades on Shifts */
  updatePaygrades: Maybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts from */
  updateStartDate: Maybe<Scalars['Date']>;
  /** Update Paygrade on Shifts until */
  updateEndDate: Maybe<Scalars['Date']>;
  /** Force the Paygrade update */
  updateForce: Maybe<Scalars['Boolean']>;
  /** Update Paygrade on all Shifts */
  updateAll: Maybe<Scalars['Boolean']>;
};

export type GQLPaygradeDeleteInput = {
  /** Update Paygrades on Shifts */
  updatePaygrades: Maybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts from */
  updateStartDate: Maybe<Scalars['Date']>;
  /** Update Paygrade on Shifts until */
  updateEndDate: Maybe<Scalars['Date']>;
  /** Force the Paygrade update */
  updateForce: Maybe<Scalars['Boolean']>;
  /** Update Paygrade on all Shifts */
  updateAll: Maybe<Scalars['Boolean']>;
};

export type GQLPaygradeUpdateInput = {
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Value of PaygradeType */
  value: Scalars['Float'];
  /** ID of the Paygrade */
  paygradeId: Scalars['Int'];
  /** Update Paygrades on Shifts */
  updatePaygrades: Maybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts from */
  updateStartDate: Maybe<Scalars['Date']>;
  /** Update Paygrade on Shifts until */
  updateEndDate: Maybe<Scalars['Date']>;
  /** Force the Paygrade update */
  updateForce: Maybe<Scalars['Boolean']>;
  /** Update Paygrade on all Shifts */
  updateAll: Maybe<Scalars['Boolean']>;
};

export type GQLPositionCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Name of the Position */
  name: Scalars['String'];
  /** Description of the Position */
  description: Maybe<Scalars['String']>;
  /** Background color for the Position */
  color: Maybe<Scalars['String']>;
  /** Position comment (shown on Shift) */
  note: Maybe<Scalars['String']>;
  /** Sorting position of the Position */
  sort: Maybe<Scalars['Int']>;
};

export type GQLPositionUpdateInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** Name of the Position */
  name: Maybe<Scalars['String']>;
  /** Description of the Position */
  description: Maybe<Scalars['String']>;
  /** Background color for the Position */
  color: Maybe<Scalars['String']>;
  /** Position comment (shown on Shift) */
  note: Maybe<Scalars['String']>;
  /** Sorting position of the Position */
  sort: Maybe<Scalars['Int']>;
};

export type GQLPunchtimeSession = {
  /** Was the punchtime session started successfully? */
  success: Scalars['Boolean'];
  /** Name of the Company */
  companyName: Maybe<Scalars['String']>;
  /** Encrypted Company ID */
  encryptedCompanyId: Scalars['String'];
};

export type GQLCreatePunchingShiftInput = {
  /** Encrypted Company ID */
  encryptedCompanyId: Maybe<Scalars['String']>;
  /** Information required to create the shift */
  shift: GQLPunchingShiftInput;
  /** Company ID for which to create the shift */
  companyId: Scalars['Int'];
  /** User token for punch clock */
  userToken: Maybe<Scalars['Int']>;
};

export type GQLPunchingShiftInput = {
  /** ID of the associated Shiftplan */
  shiftplanId: Scalars['Int'];
  /** ID of the associated LocationsPosition */
  locationsPositionId: Scalars['Int'];
  /** Break time in minutes */
  breakTime: Maybe<Scalars['Float']>;
  /** Date when the Shift was started */
  startsAt: Scalars['Date'];
  /** Time when the Shift is planned to end */
  endsAtTime: Scalars['String'];
  /** Ignore any warning regarding break rules if possible */
  enforceBreakWarning: Maybe<Scalars['Boolean']>;
  /** Ignore any warning regarding rest periods if possible */
  overrideRestRules: Maybe<Scalars['Boolean']>;
  /** Ignore any warning regarding payment restrictions if possible */
  overridePayment: Maybe<Scalars['Boolean']>;
};

export type GQLCreatePunchingShiftResult = {
  /** Indicator whether shift creation was successful */
  success: Scalars['Boolean'];
  /** Conflicts which prevented shift creation */
  conflicts: Array<Maybe<GQLCreatePunchingShiftConflict>>;
};

export type GQLCreatePunchingShiftConflict = GQLWarningStaffOverpaid | GQLErrorStaffOverpaid | GQLWarningRestPeriodBelowMinimum | GQLErrorRestPeriodBelowMinimum;

export type GQLWarningStaffOverpaid = {
  /** Maximum allowed payment */
  employmentMaxPayment: Scalars['Float'];
  /** Total payment if shift was started */
  totalPayment: Scalars['Float'];
  /** Currency used by the Company */
  currency: Scalars['String'];
};

export type GQLErrorStaffOverpaid = GQLCreatePunchingShiftConflictWithStringValue & {
  /** A value with details about the error which prevented shift creation */
  value: Scalars['String'];
};

export type GQLCreatePunchingShiftConflictWithStringValue = {
  value: Scalars['String'];
};

export type GQLWarningRestPeriodBelowMinimum = GQLCreatePunchingShiftConflictWithIntValue & {
  /** A value with details about the error which prevented shift creation */
  value: Scalars['Int'];
};

export type GQLCreatePunchingShiftConflictWithIntValue = {
  value: Scalars['Int'];
};

export type GQLErrorRestPeriodBelowMinimum = GQLCreatePunchingShiftConflictWithStringValue & {
  /** A value with details about the error which prevented shift creation */
  value: Scalars['String'];
};

export type GQLPunchOutResult = {
  /** Type of overridable warning that prevents from punching out */
  warning: Maybe<Scalars['String']>;
  /** Type of non-overridable error that prevents from punching out */
  errorKey: Maybe<Scalars['String']>;
  /** A value that serves as a detail to the error which prevented from punching out */
  value: Maybe<Scalars['Int']>;
  /** Indicator whether punching out was successful */
  success: Scalars['Boolean'];
};

export type GQLRightSpecificChangeInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** ID of the associated Location */
  locationId: Scalars['Int'];
};

export type GQLRightChangeInput = {
  /** Unique identifier of the Right */
  id: Scalars['Int'];
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** ID of the associated Location */
  locationId: Scalars['Int'];
};

export type GQLShiftEvaluationTag = {
  tagId: Scalars['Int'];
  tag: GQLTag;
};

export type GQLShiftPaygradeCreateInput = {
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** ID of this paygrade's parent */
  parentPaygradeId: Maybe<Scalars['Int']>;
  /** Value of Paygrade */
  value: Scalars['Float'];
};

export type GQLShiftplanCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Date when the Shiftplan will start (inclusive) */
  startsAt: Scalars['Date'];
  /** Date when the Shiftplan will end (inclusive) */
  endsAt: Scalars['Date'];
  /** ID of the associated Location */
  locationId: Scalars['Int'];
  /** Name of the Shiftplan */
  name: Scalars['String'];
};

export type GQLShiftplanPublishInput = {
  /** Notify employees by email */
  publishWithEmail: Maybe<Scalars['Boolean']>;
  /** Send information about shifts assigned to the employee in email notification. Requires publishWithEmail to be true for notification to be sent */
  sendAssignedShifts: Maybe<Scalars['Boolean']>;
  /** Send information about open shifts in email notification. Requires publishWithEmail to be true for notification to be sent */
  sendOpenShifts: Maybe<Scalars['Boolean']>;
  /** Send additional text message to employees. Provided in message field. Requires publishWithEmail to be true for notification to be sent */
  sendMessage: Maybe<Scalars['Boolean']>;
  /** Additional text message included in email notification to employees. Requires sendMessage to be true for message to be included */
  message: Maybe<Scalars['String']>;
};

export type GQLShiftplanUpdateInput = {
  /** Date when the Shiftplan will start or started (inclusive) */
  startsAt: Maybe<Scalars['Date']>;
  /** Date when the Shiftplan will end or ended (inclusive) */
  endsAt: Maybe<Scalars['Date']>;
  /** Name of the Shiftplan */
  name: Maybe<Scalars['String']>;
  /** Should shiftplan be locked? */
  locked: Maybe<Scalars['Boolean']>;
};

export type GQLApplyShiftplanRotationInput = {
  /** ID of the ShiftRotation which should be assigned to the Shiftplan */
  shiftRotationId: Scalars['Int'];
  /** Array of number of employees which should be assigned to each Position of the ShiftRotation */
  workersPerPositionsList: Array<Maybe<GQLWorkersPerPosition>>;
};

export type GQLWorkersPerPosition = {
  /** ID of the Position */
  id: Scalars['Int'];
  /** Number of employees for the Position */
  workers: Scalars['Int'];
};

export type GQLBackgroundJobResponse = {
  id: Scalars['Int'];
  jobId: Scalars['String'];
};

export type GQLShiftplanCreateMessageInput = {
  /** Message content */
  message: Scalars['String'];
  /** Only send to employees that have this position */
  positionIds: Maybe<Array<Scalars['Int']>>;
  /** Only send to employees assigned to a shift that is part of the Shiftplan */
  onlyAssigned: Maybe<Scalars['Boolean']>;
};

export type GQLShiftplanCreateNotificationInput = {
  notificationType: GQLShiftplanNotificationType;
  message: Maybe<Scalars['String']>;
};

export enum GQLShiftplanNotificationType {
  UPDATES = 'updates',
  OPEN = 'open'
}

export type GQLShiftplanCopyInput = {
  /** Name of the Shiftplan */
  name: Scalars['String'];
  /** Date when the Shiftplan will start (inclusive) */
  startsAt: Scalars['Date'];
  /** Date when the Shiftplan will end (inclusive) */
  endsAt: Scalars['Date'];
  /** Copy employment assignments */
  copyEmployees: Maybe<Scalars['Boolean']>;
  /** Only copies assignments that wouldn’t trigger a warning */
  withoutConflicts: Maybe<Scalars['Boolean']>;
  /** Copy full shiftplan */
  copyFullShiftplan: Maybe<Scalars['Boolean']>;
  /** Ids Positions */
  positionIds: Maybe<Array<Scalars['Int']>>;
  /** Copy timeframe start at */
  copyTimeframeStartsAt: Maybe<Scalars['Date']>;
  /** Copy timeframe end at */
  copyTimeframeEndsAt: Maybe<Scalars['Date']>;
  /** ID of the Shiftplan to copy */
  sourceShiftplanId: Scalars['Int'];
};

export type GQLShiftplanImportInput = {
  /** ID of the Shiftplan to be imported to */
  targetShiftplanId: Scalars['Int'];
  /** Date of the start of imported period(first day of imported timeframe will be on this day) */
  startsAt: Scalars['Date'];
  /** Date of the end of imported period */
  endsAt: Scalars['Date'];
  /** Import employment assignments */
  copyEmployees: Maybe<Scalars['Boolean']>;
  /** Only imports assignments that wouldn’t trigger a warning */
  withoutConflicts: Maybe<Scalars['Boolean']>;
  /** Import full shiftplan */
  copyFullShiftplan: Maybe<Scalars['Boolean']>;
  /** Ids of Positions to be imported */
  positionIds: Maybe<Array<Scalars['Int']>>;
  /** Imported timeframe starts at */
  importTimeframeStartsAt: Maybe<Scalars['Date']>;
  /** Imported timeframe ends at */
  importTimeframeEndsAt: Maybe<Scalars['Date']>;
  /** ID of the shiftplan to import data from */
  sourceShiftplanId: Scalars['Int'];
};

export type GQLOverridableRequestInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the associated Shift */
  shiftId: Scalars['Int'];
  /** Ignore warnings */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
};

export type GQLRequestState = {
  /** Was request successful? */
  success: Scalars['Boolean'];
  /** Type of overridable collision */
  warning: Maybe<Scalars['String']>;
  /** Type of non-overridable collision */
  errorKey: Maybe<Scalars['String']>;
  /** Request conflicts */
  conflicts: Maybe<Array<Maybe<GQLShiftConflict>>>;
};

export type GQLRequestInput = {
  /** ID of the associated Shift */
  shiftId: Scalars['Int'];
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Ignore warnings */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
};

export type GQLAcceptReplaceRequestInput = {
  /** ID of the associated Shift */
  shiftId: Scalars['Int'];
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Replace Request Request id */
  id: Scalars['Int'];
  /** Accept replace requests even when warnings are present */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
};

export type GQLAcceptReplaceRequestResult = GQLPendingReplaceRequest | GQLRequestState;

export type GQLPendingReplaceRequest = {
  /** The id of the request. Is null when a replace request was auto accepted */
  id: Maybe<Scalars['Int']>;
  /** The state of the change request */
  state: Maybe<GQLReplaceRequestState>;
  /** The referring request */
  request: Maybe<GQLRequest>;
  /** The employment ids of employees that accepted this request */
  acceptedBy: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The employment that offered their shift */
  employment: Maybe<GQLEmployment>;
  /** The staff shift that was created by applying the replace request */
  staffShift: Maybe<GQLStaffShift>;
  /** The moment the change request was applied */
  createdAt: Maybe<Scalars['Date']>;
  /** The moment the change request was last changed */
  updatedAt: Maybe<Scalars['Date']>;
  /** The moment the change request was deleted */
  deletedAt: Maybe<Scalars['Date']>;
};

export type GQLWithdrawAcceptedReplaceRequestInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the shift offer */
  id: Scalars['Int'];
  /** ID of the employment that accepted the offer. If this is empty, currently logged in user is assumed to authorize the action. */
  acceptedBy: Maybe<Scalars['Int']>;
};

export type GQLConfirmReplaceRequestInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Replace Request Request id */
  id: Scalars['Int'];
  /** ID of Employment that accepted the shift offer */
  employmentId: Scalars['Int'];
  /** Accept replace requests even when warnings are present */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
};

export type GQLConfirmReplaceRequestResult = GQLPendingReplaceRequest | GQLRequestState;

export type GQLShiftRotationGroupCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Name of the ShiftRotationGroup */
  name: Scalars['String'];
  /** ID of the associated ShiftRotation */
  shiftRotationId: Scalars['Int'];
  /** An array of shift preset IDs associated with the ShiftRotationGroup */
  shiftPresetIds: Array<Scalars['Int']>;
  /** Used for ordering ShiftRotationGroups of the same ShiftRotation */
  sortPosition: Maybe<Scalars['Int']>;
};

export type GQLShiftRotationGroupAssignInput = {
  /** ID of the ShiftRotationGroup to assign */
  shiftRotationGroupId: Scalars['Int'];
  /** ID of employment to assign */
  employmentId: Scalars['Int'];
  /** Location Position ID */
  locationsPositionId: Scalars['Int'];
  /** Date to select the Shifts from */
  startDate: Scalars['Date'];
  /** Date to select the Shifts to */
  endDate: Scalars['Date'];
};

export type GQLShiftRotationCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Name of the rotation */
  name: Scalars['String'];
  /** When does the the rotation itself start */
  startsAt: Maybe<Scalars['Date']>;
  /** When does the the rotation itself end */
  endsAt: Maybe<Scalars['Date']>;
  /** Anchor date of the rotation */
  anchorDate: Scalars['Date'];
  /** After how many days does the rotation repeat? */
  rotationInterval: Scalars['Int'];
};

export type GQLShiftRotationShiftplanCreateInput = {
  /** Date when the Shiftplan will start (inclusive) */
  startsAt: Scalars['Date'];
  /** Date when the Shiftplan will end (inclusive) */
  endsAt: Scalars['Date'];
  /** ID of the associated Location */
  locationId: Scalars['Int'];
  /** Name of the Shiftplan */
  name: Scalars['String'];
  /** Array of number of employees which should be assigned to each Position of the ShiftRotation */
  workersPerPositionsList: Array<GQLWorkersPerPosition>;
  /** Shiftplan metadata as JSON */
  metadata: Maybe<Scalars['String']>;
};

export type GQLShiftCreateInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** Date when the Shift starts */
  startsAt: Scalars['Date'];
  /** Date when the Shift ends */
  endsAt: Scalars['Date'];
  /** Amount of employees assignable to the Shift */
  workers: Scalars['Int'];
  /** Duration of unpaid breaks in minutes */
  untimedBreakTime: Maybe<Scalars['Float']>;
  /** Note for the Shift */
  note: Maybe<Scalars['String']>;
  /** Can employees create Evaluations? */
  canEvaluate: Maybe<Scalars['Boolean']>;
  /** The setting for whether requests will be automatically accepted and how conflicts will be handled */
  autoAccept: Maybe<GQLAutoAcceptRequestSetting>;
  /** ID of the associated Shiftplan */
  shiftplanId: Scalars['Int'];
  /** ID of the associated LocationsPosition */
  locationsPositionId: Scalars['Int'];
  /** ID of the shift preset used for setting the time and unpaid break of the shift */
  shiftPresetId: Maybe<Scalars['Int']>;
  /** Defines whether this shift will be a stand by shift */
  untimed: Maybe<Scalars['Boolean']>;
  /** Ids of the associated Tags */
  tagIds: Maybe<Array<Scalars['Int']>>;
  /** Ids of the associated EvaluationTags */
  evaluationTagIds: Maybe<Array<Scalars['Int']>>;
  /** Start and end times of ShiftBreaks */
  shiftBreaks: Maybe<Array<GQLShiftBreakTimeframe>>;
  /** Id and count for shift qualification */
  shiftQualifications: Maybe<Array<GQLShiftQualificationInput>>;
  /** Whether to ignore conflicts */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
};

export type GQLShiftBreakTimeframe = {
  /** Date when the ShiftBreak started */
  startsAt: Scalars['Date'];
  /** Date when the ShiftBreak ended */
  endsAt: Scalars['Date'];
};

export type GQLShiftQualificationInput = {
  /** ID of the associated qualification */
  qualificationId: Scalars['Int'];
  /** Required workers for qualification */
  count: Scalars['Int'];
};

export type GQLShiftCreateResult = GQLShift | GQLShiftConflictsResponse;

export type GQLShiftConflictsResponse = {
  success: Scalars['Boolean'];
  conflicts: Array<GQLShiftConflict>;
};

export type GQLShiftUpdateInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** Date when the Shift starts */
  startsAt: Scalars['Date'];
  /** Date when the Shift ends */
  endsAt: Scalars['Date'];
  /** Amount of employees assignable to the Shift */
  workers: Scalars['Int'];
  /** Duration of unpaid breaks in minutes */
  untimedBreakTime: Maybe<Scalars['Float']>;
  /** Note for the Shift */
  note: Maybe<Scalars['String']>;
  /** ID of the associated LocationsPosition. Updating locations_position_id of a shift will unassign all employees from the shift and their evaluations will be deleted */
  locationsPositionId: Scalars['Int'];
  /** Can employees create Evaluations? */
  canEvaluate: Maybe<Scalars['Boolean']>;
  /** The setting for whether requests will be automatically accepted and how conflicts will be handled */
  autoAccept: Maybe<GQLAutoAcceptRequestSetting>;
  /** Defines whether this shift will be a stand by shift */
  untimed: Maybe<Scalars['Boolean']>;
  /** Manager note for the Shift */
  managerNote: Maybe<Scalars['String']>;
  /** ID of the shift preset used for setting the time and unpaid break of the shift */
  shiftPresetId: Maybe<Scalars['Int']>;
  /** Ids of the associated Tags */
  tagIds: Maybe<Array<Scalars['Int']>>;
  /** Ids of the associated EvaluationTags */
  evaluationTagIds: Maybe<Array<Scalars['Int']>>;
  /** Ids of the associated ShiftRotationGroups */
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  /** Start and end times of ShiftBreaks */
  shiftBreaks: Maybe<Array<GQLShiftBreakTimeframe>>;
  /** Id and count for shift qualification */
  shiftQualifications: Maybe<Array<GQLShiftQualificationInput>>;
  /** Ignore conflicts? */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
  /** Whether all connected Shift should be updated */
  updateConnected: Maybe<Scalars['Boolean']>;
};

export type GQLShiftDeleteInput = {
  /** Whether all connected Shift should be deleted */
  deleteConnected: Maybe<Scalars['Boolean']>;
};

export type GQLShiftCopyInput = {
  /** ID of Shift to be copied */
  sourceShiftId: Scalars['Int'];
  /** Date to which the Shift should be copied (start and end times are the same as for the original Shift) */
  date: Scalars['Date'];
  /** Whether the new Shift should be connected to the original */
  connect: Maybe<Scalars['Boolean']>;
  /** Whether to ignore conflicts */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
};

export type GQLShiftBreakCreateInput = {
  /** The id of the company in which you're acting */
  companyId: Scalars['Int'];
  /** The start of the break */
  startsAt: Scalars['Date'];
  /** The end of the break */
  endsAt: Scalars['Date'];
};

export type GQLSpecialDateCreateInput = {
  /** Name of the SpecialDate */
  name: Scalars['String'];
  /** Date for SpecialDate */
  specialDate: Scalars['Date'];
  /** Is this a full-day SpecialDate? */
  fullDay: Maybe<Scalars['Boolean']>;
  /** Does this SpecialDate apply to every location of the company? */
  allLocations: Maybe<Scalars['Boolean']>;
  /** Time when that SpecialDate starts or null if fullDay is true */
  startTime: Maybe<Scalars['String']>;
  /** Time when that SpecialDate ends or null if fullDay is true */
  endTime: Maybe<Scalars['String']>;
  /** Is this SpecialDate counted towards the employee’s working hours? */
  isIncludedInHourAccount: Maybe<Scalars['Boolean']>;
  /** Array of Location to which the SpecialDate is applied (empty if allLocations is true) */
  locationIds: Array<Scalars['Int']>;
};

export type GQLStaffShiftCreateInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** ID of the associated Shift */
  shiftId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Whether to ignore conflicts */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
  /** Whether to create StaffShifts for the Shifts connected to this Shift */
  assignToConnected: Maybe<Scalars['Boolean']>;
};

export type GQLStaffShiftCreateResult = GQLStaffShift | GQLShiftConflictsResponse;

export type GQLStaffShiftMoveInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** ID of the Shift to move to */
  shiftId: Scalars['Int'];
  /** ID of the Shift to move from */
  sourceShiftId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Whether to ignore conflicts caused by assignment */
  ignoreConflicts: Maybe<Scalars['Boolean']>;
};

export type GQLTagCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Location associated with the Tag */
  locationId: Scalars['Int'];
  /** Name of tag */
  name: Scalars['String'];
  /** Color of tag (hex) */
  color: Maybe<Scalars['String']>;
};

export type GQLTagUpdateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Location associated with the Tag */
  locationId: Scalars['Int'];
  /** Name of tag */
  name: Maybe<Scalars['String']>;
  /** Color of tag (hex) */
  color: Maybe<Scalars['String']>;
};

export type GQLShiftTagInput = {
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
  /** ID of shift */
  shiftId: Scalars['Int'];
  /** ID of location */
  locationId: Scalars['Int'];
};

/** Type describing 1 to 1 relation between tag and shift */
export type GQLShiftTag = {
  shift: Maybe<GQLShift>;
  tag: Maybe<GQLTag>;
  id: Maybe<Scalars['Int']>;
};

export type GQLTranslationCreateInput = {
  /** ID of the associated Language */
  languageId: Scalars['Int'];
  /** Key of the string to be translated */
  key: Scalars['String'];
  /** Translation of the string */
  value: Scalars['String'];
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
};

export type GQLTranslationUpdateInput = {
  /** ID of the associated Language */
  languageId: Maybe<Scalars['Int']>;
  /** Key of the string to be translated */
  key: Maybe<Scalars['String']>;
  /** Translation of the string */
  value: Maybe<Scalars['String']>;
  /** ID of the associated Company */
  companyId: Maybe<Scalars['Int']>;
};

export type GQLUserCreateInput = {
  /** ID of the billing type */
  billingTypeId: Maybe<Scalars['Int']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Email address of the User */
  email: Maybe<Scalars['String']>;
  /** Leaving date of the User */
  exitDate: Maybe<Scalars['Date']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: Maybe<Scalars['Boolean']>;
  /** First name of the User */
  firstName: Scalars['String'];
  /** Is the User's account inactive? */
  isInactive: Maybe<Scalars['Boolean']>;
  /** Is the User paid monthly? */
  isPaidMonthly: Maybe<Scalars['Boolean']>;
  /** Is the User a stakeholder? */
  isStakeholder: Maybe<Scalars['Boolean']>;
  /** Is the User an employee? */
  isEmployee: Maybe<Scalars['Boolean']>;
  /** Last name of the User */
  lastName: Scalars['String'];
  /** Locale (i.e. language code) of the User */
  locale: Maybe<Scalars['String']>;
  /** Maximum earnings per month */
  maximumMoney: Maybe<Scalars['Float']>;
  /** Maximum earnings per month enabled? */
  maximumMoneyEnabled: Maybe<Scalars['Boolean']>;
  /** Maximum number of vacation days of the User */
  maxVacationDays: Maybe<Scalars['Float']>;
  /** Note */
  note: Maybe<Scalars['String']>;
  /** Password of the User */
  password: Maybe<Scalars['String']>;
  /** Phone number of the User */
  phoneNumber: Maybe<Scalars['String']>;
  /** Staff number of the User */
  staffNumber: Maybe<Scalars['String']>;
  /** A unique token for the User */
  userToken: Maybe<Scalars['String']>;
};

export type GQLUserUpdateInput = {
  /** ID of the billing type */
  billingTypeId: Maybe<Scalars['Int']>;
  /** Email address of the User */
  email: Maybe<Scalars['String']>;
  /** Leaving date of the User */
  exitDate: Maybe<Scalars['Date']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: Maybe<Scalars['Boolean']>;
  /** First name of the User */
  firstName: Maybe<Scalars['String']>;
  /** Is the User's account inactive? */
  isInactive: Maybe<Scalars['Boolean']>;
  /** Is the User paid monthly? */
  isPaidMonthly: Maybe<Scalars['Boolean']>;
  /** Last name of the User */
  lastName: Maybe<Scalars['String']>;
  /** Locale (i.e. language code) of the User */
  locale: Maybe<Scalars['String']>;
  /** Maximum earnings per month */
  maximumMoney: Maybe<Scalars['Float']>;
  /** Maximum earnings per month enabled? */
  maximumMoneyEnabled: Maybe<Scalars['Boolean']>;
  /** Maximum number of vacation days of the User */
  maxVacationDays: Maybe<Scalars['Float']>;
  /** Note */
  note: Maybe<Scalars['String']>;
  /** Phone number of the User */
  phoneNumber: Maybe<Scalars['String']>;
  /** Staff number of the User */
  staffNumber: Maybe<Scalars['String']>;
  /** A unique token for the User */
  userToken: Maybe<Scalars['String']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
};

export type GQLCollisionRulesInput = {
  forceCollision: Scalars['Boolean'];
  forceCollisionAndRemove: Scalars['Boolean'];
};

export type GQLEmploymentEvaluationCollection = GQLPaginatable & {
  items: Array<GQLEvaluation>;
  pagination: GQLPaginationInfo;
};

export type GQLAbsenceConflictsFragmentFragment = (
  Pick<GQLConflictsResponse, 'success' | 'canManage'>
  & { conflicts: Array<(
    { __typename: 'CollisionsConflict' }
    & Pick<GQLCollisionsConflict, 'ignorable'>
    & { shifts: Maybe<Array<GQLShiftCollisionFragment>>, absences: Maybe<Array<GQLAbsenceCollisionFragment>> }
  ) | (
    { __typename: 'MaxDaysPerYearConflict' }
    & Pick<GQLMaxDaysPerYearConflict, 'before' | 'after' | 'limit' | 'ignorable'>
  ) | (
    { __typename: 'CarryOverDeadlineErrorConflict' }
    & Pick<GQLCarryOverDeadlineErrorConflict, 'ignorable' | 'deadline'>
  ) | (
    { __typename: 'CarryOverMultipleYearsConflict' }
    & Pick<GQLCarryOverMultipleYearsConflict, 'ignorable'>
  ) | (
    { __typename: 'UnknownConflict' }
    & Pick<GQLUnknownConflict, 'data' | 'ignorable'>
  )> }
);

export type GQLAbsenceDurationQueryVariables = Exact<{
  companyId: Scalars['Int'];
  days: Maybe<Scalars['Float']>;
  employmentId: Scalars['Int'];
  endsAt: Scalars['Date'];
  reasonId: Scalars['Int'];
  startsAt: Scalars['Date'];
}>;

export type GQLAbsenceDurationQuery = { absenceDuration: Maybe<Pick<GQLAbsenceDuration, 'absenceHoursPerWorkingDay' | 'absenceDays' | 'absenceHours'>> };

export type GQLAbsenceFragmentFragment = (
  Pick<GQLAbsence, 'id' | 'startsAt' | 'endsAt' | 'state' | 'days' | 'isFullDay' | 'file' | 'fileName' | 'fileUploadedAt' | 'notes' | 'paid' | 'vacationMinutes'>
  & { absenceReason: Pick<GQLAbsenceReason, 'id' | 'name' | 'hasLocalization'>, employment: (
    Pick<GQLEmployment, 'id' | 'firstName' | 'lastName' | 'staffNumber'>
    & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>> }
  ) }
);

export type GQLAbsencesQueryVariables = Exact<{
  absenceReasonIds: Maybe<Array<Scalars['Int']>>;
  companyId: Scalars['Int'];
  orderDir: Maybe<GQLOrderDirection>;
  orderKey: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  paid: Maybe<Scalars['Boolean']>;
  perPage: Scalars['Int'];
  states: Maybe<Array<GQLAbsenceState>>;
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  employmentIds: Maybe<Array<Scalars['Int']>>;
  employmentStatuses: Maybe<Array<GQLEmploymentStatus>>;
  locationIds: Maybe<Array<Scalars['Int']>>;
  withAttachment: Maybe<Scalars['Boolean']>;
}>;

export type GQLAbsencesQuery = { absences: { items: Array<GQLAbsenceFragmentFragment>, pagination: Pick<GQLPaginationInfo, 'count'> } };

export type GQLApproveAbsenceMutationVariables = Exact<{
  absenceId: Scalars['Int'];
  companyId: Scalars['Int'];
  forceCollision?: Maybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: Maybe<Scalars['Boolean']>;
}>;

export type GQLApproveAbsenceMutation = { approveAbsence: Maybe<Pick<GQLCreateResponse, 'success'> | GQLAbsenceConflictsFragmentFragment> };

export type GQLCreateAbsenceMutationVariables = Exact<{
  absence: GQLAbsenceInput;
  forceCollision?: Maybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCreateAbsenceMutation = { createAbsence: Maybe<GQLAbsenceFragmentFragment | GQLAbsenceConflictsFragmentFragment> };

export type GQLDeclineAbsenceMutationVariables = Exact<{
  absenceId: Scalars['Int'];
  companyId: Scalars['Int'];
  refuseMessage: Maybe<Scalars['String']>;
}>;

export type GQLDeclineAbsenceMutation = Pick<GQLMutation, 'declineAbsence'>;

export type GQLDeleteAbsenceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GQLDeleteAbsenceMutation = { deleteAbsence: Maybe<Pick<GQLSuccessResponse, 'success' | 'error'>> };

export type GQLFetchAbsenceQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLFetchAbsenceQuery = { absences: { items: Array<GQLAbsenceFragmentFragment> } };

export type GQLUpdateAbsenceMutationVariables = Exact<{
  id: Scalars['Int'];
  absence: GQLAbsenceInput;
  forceCollision?: Maybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: Maybe<Scalars['Boolean']>;
}>;

export type GQLUpdateAbsenceMutation = { updateAbsence: Maybe<GQLAbsenceFragmentFragment | GQLAbsenceConflictsFragmentFragment> };

export type GQLGetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;

export type GQLGetCompaniesQuery = { companies: { items: Array<(
  Pick<GQLCompany, 'id' | 'autoAcceptShiftRequestEnabled' | 'autoAcceptSwapRequests' | 'canEmployeesCreateAbsences' | 'canUseBringShifts' | 'canUseShiftPresets' | 'canFillShiftplans' | 'canLockShiftplans' | 'canUseQualifications' | 'canManageAvailabilities' | 'currency' | 'editShiftPayments' | 'isAbsenceAttachmentsAllowed' | 'isAbsenceEditEnabled' | 'isDashboardMessagesAllowed' | 'isEmployeeAllowedToSeeOwnHourAccount' | 'isOverassignmentAllowed' | 'isPayslipsEnabled' | 'shiftRotationEnabled' | 'assignmentGroupEnabled' | 'isTagsAllowed' | 'name' | 'shiftSwapEnabled' | 'swapRequestMinHour' | 'timeZone' | 'viewPersonal'>
  & { chatSettings: Maybe<(
    Pick<GQLChatSettings, 'isChatAllowed' | 'locationChatsEnabled' | 'locationsPositionChatsEnabled'>
    & { employmentChats: Maybe<(
      Pick<GQLEmploymentChats, 'enabled'>
      & { employeeCanChatTo: Maybe<Pick<GQLEmployeeCanChatTo, 'anyone' | 'sameLocation' | 'sameLocationsPosition'>>, stakeholderCanChatTo: Maybe<Pick<GQLStakeholderCanChatTo, 'anyone' | 'employeesInLocation' | 'employeesInLocationsPosition' | 'stakeholdersInLocation' | 'stakeholdersInLocationsPosition'>> }
    )> }
  )>, locations: Maybe<Array<Pick<GQLLocation, 'id' | 'name' | 'sort'>>>, pictureData: Maybe<Pick<GQLPictureData, 'pictureLarge' | 'pictureSmall' | 'pictureMedium'>> }
)> } };

export type GQLGetCurrentEmploymentQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLGetCurrentEmploymentQuery = { myEmployments: Array<(
  Pick<GQLEmployment, 'id' | 'userId' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'staffNumber' | 'userToken' | 'exitDate' | 'billingTypeId' | 'maxVacationDays' | 'monthlyPay' | 'maximumMoney' | 'maximumMoneyEnabled' | 'note' | 'isEmployee' | 'hourEnabled' | 'isPaidMonthly' | 'isStakeholder' | 'exitMonthPayedPartially' | 'vacationHours'>
  & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>>, language: Maybe<Pick<GQLLanguage, 'locale'>>, locationsPositions: Maybe<Array<Maybe<{ location: Maybe<Pick<GQLLocation, 'id' | 'name'>> }>>> }
)> };

export type GQLGetMyRightsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLGetMyRightsQuery = { myRights: Maybe<Array<Maybe<Pick<GQLRight, 'id' | 'contextType' | 'contextId' | 'name'>>>> };

export type GQLAvailabilityAggregationFragmentFragment = (
  Pick<GQLAvailabilityAggregation, 'employmentId' | 'canManage' | 'availableHours' | 'contractHours'>
  & { employment: (
    Pick<GQLEmployment, 'id' | 'firstName' | 'lastName' | 'staffNumber'>
    & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>> }
  ) }
);

export type GQLAvailabilityAggregationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
  perPage: Scalars['Int'];
  page: Scalars['Int'];
  orderKey: Maybe<GQLAvailabilityAggregationOrderKey>;
  orderDir: Maybe<GQLOrderDirection>;
  employmentIds: Maybe<Array<Scalars['Int']>>;
  locationIds: Maybe<Array<Scalars['Int']>>;
  positionIds: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLAvailabilityAggregationsQuery = { availabilityAggregations: { items: Array<GQLAvailabilityAggregationFragmentFragment>, pagination: Pick<GQLPaginationInfo, 'count'> } };

export type GQLAbsencesDataQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
  search: Maybe<Scalars['String']>;
  locationsPositionIds: Maybe<Array<Scalars['Int']>>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
}>;

export type GQLAbsencesDataQuery = { employments: { items: Array<GQLEmploymentFieldsFragment> } };

export type GQLAbsencesEventsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
  employmentIds: Maybe<Array<Scalars['Int']>>;
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  locationsPositionIds: Maybe<Array<Scalars['Int']>>;
  withSummary?: Maybe<Scalars['Boolean']>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
}>;

export type GQLAbsencesEventsQuery = { absences: { items: Array<GQLAbsenceFieldsFragment> }, calendarAggregations: Array<Pick<GQLCalendarAggregation, 'date' | 'working' | 'demand' | 'absent'>> };

export type GQLAbsenceFieldsFragment = (
  Pick<GQLAbsence, 'id' | 'startsAt' | 'endsAt' | 'state' | 'canManage' | 'notes'>
  & { absenceReason: Pick<GQLAbsenceReason, 'id' | 'name' | 'hasLocalization'>, employment: GQLEmploymentFieldsFragment }
);

export type GQLCalendarAbsencesQueryVariables = Exact<{
  companyId: Maybe<Scalars['Int']>;
  locationId: Maybe<Scalars['Int']>;
  ids: Array<Scalars['Int']>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCalendarAbsencesQuery = { absences: { items: Array<GQLAbsenceFieldsFragment> } };

export type GQLCalendarEmploymentsQueryVariables = Exact<{
  companyId: Maybe<Scalars['Int']>;
  locationId: Maybe<Scalars['Int']>;
  ids: Array<Scalars['Int']>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCalendarEmploymentsQuery = { employments: { items: Array<GQLEmploymentFieldsFragment> } };

export type GQLCalendarLocationsPositionsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  locationId: Scalars['Int'];
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
}>;

export type GQLCalendarLocationsPositionsQuery = { locationsPositions: { items: Array<GQLLocationsPositionFieldsFragment> } };

export type GQLCalendarShiftsQueryVariables = Exact<{
  companyId: Maybe<Scalars['Int']>;
  shiftplanId: Maybe<Scalars['Int']>;
  locationId: Maybe<Scalars['Int']>;
  ids: Maybe<Array<Scalars['Int']>>;
  connectedGroupId: Maybe<Scalars['Int']>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
  shouldFetchShiftTags?: Maybe<Scalars['Boolean']>;
  shouldFetchShiftAssignmentGroups?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCalendarShiftsQuery = { shifts: { items: Array<GQLShiftFieldsFragment> } };

export type GQLEmploymentFieldsFragment = (
  Pick<GQLEmployment, 'id' | 'firstName' | 'lastName' | 'isEmployee' | 'userId' | 'deletedAt' | 'locationsPositionIds'>
  & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>>, employmentsShiftRotationGroups: Array<(
    Pick<GQLEmploymentsRotationGroup, 'startsAt' | 'endsAt'>
    & { shiftRotationGroup: (
      Pick<GQLShiftRotationGroup, 'id' | 'shiftPresetIds'>
      & { shiftRotation: Pick<GQLShiftRotation, 'anchorDate' | 'rotationInterval'> }
    ) }
  )> }
);

export type GQLLocationsPositionFieldsFragment = (
  Pick<GQLLocationsPosition, 'id' | 'sort'>
  & { position: Maybe<Pick<GQLPosition, 'id' | 'name' | 'color' | 'note'>> }
);

export type GQLShiftFieldsFragment = (
  Pick<GQLShift, 'id' | 'startsAt' | 'endsAt' | 'workers' | 'untimed' | 'canManage' | 'note' | 'managerNote' | 'untimedBreakTime' | 'connectedGroupId' | 'staffShiftsCount' | 'staffShiftsNeedsEvaluationCount' | 'shiftRotationGroupIds'>
  & { shiftplan: Pick<GQLShiftplan, 'id'>, locationsPosition: GQLLocationsPositionFieldsFragment, staffShifts: Maybe<Array<(
    Pick<GQLStaffShift, 'id'>
    & { employment: Maybe<GQLEmploymentFieldsFragment> }
  )>>, requests: Array<Pick<GQLRequest, 'id' | 'type'>>, tags: Maybe<Array<Pick<GQLTag, 'id'>>>, shiftAssignmentGroups: Maybe<Array<Pick<GQLShiftAssignmentGroup, 'assignmentGroupId'>>>, shiftPreset: Maybe<Pick<GQLShiftPreset, 'id' | 'name' | 'shortName' | 'color'>> }
);

export type GQLCalendarAddTagToShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  params: GQLShiftTagInput;
}>;

export type GQLCalendarAddTagToShiftMutation = { addTagToShift: Pick<GQLShiftTag, 'id'> };

export type GQLCalendarCreateStaffShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShift: GQLStaffShiftCreateInput;
}>;

export type GQLCalendarCreateStaffShiftMutation = { createStaffShift: GQLStaffShiftFragmentFragment | { conflicts: Array<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment> } };

export type GQLCalendarDeleteShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  deleteParams: GQLShiftDeleteInput;
}>;

export type GQLCalendarDeleteShiftMutation = { deleteShift: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLCalendarDeleteStaffShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLCalendarDeleteStaffShiftMutation = { deleteStaffShift: Pick<GQLSuccessResponse, 'success'> };

export type GQLCalendarRemoveTagFromShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  params: GQLShiftTagInput;
}>;

export type GQLCalendarRemoveTagFromShiftMutation = { removeTagFromShift: Pick<GQLShiftTag, 'id'> };

export type GQLCalendarUpdateShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  shift: GQLShiftUpdateInput;
}>;

export type GQLCalendarUpdateShiftMutation = { updateShift: Pick<GQLShift, 'id'> | { conflicts: Array<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment> } };

export type GQLCalendarPrintDataQueryVariables = Exact<{
  companyId: Maybe<Scalars['Int']>;
  locationId: Maybe<Scalars['Int']>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCalendarPrintDataQuery = { locationsPositions: { items: Array<GQLLocationsPositionFieldsFragment> }, employments: { items: Array<GQLEmploymentFieldsFragment> } };

export type GQLCalendarPrintEventsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  withAbsences: Scalars['Boolean'];
  locationsPositionIds: Maybe<Array<Scalars['Int']>>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
  shouldFetchShiftTags?: Maybe<Scalars['Boolean']>;
  shouldFetchShiftAssignmentGroups?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCalendarPrintEventsQuery = { shifts: { items: Array<GQLShiftFieldsFragment> }, absences: { items: Array<GQLAbsenceFieldsFragment> } };

export type GQLCalendarDataQueryVariables = Exact<{
  companyId: Maybe<Scalars['Int']>;
  locationId: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
  shouldFetchShiftPresets?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCalendarDataQuery = { locationsPositions: { items: Array<GQLLocationsPositionFieldsFragment> }, employments: { items: Array<GQLEmploymentFieldsFragment> }, shiftPresets: { items: Array<Pick<GQLShiftPreset, 'id' | 'name' | 'color' | 'shortName'>> } };

export type GQLCalendarEventsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  startsAt: Maybe<Scalars['Date']>;
  endsAt: Maybe<Scalars['Date']>;
  shiftPresetIds: Maybe<Array<Scalars['Int']>>;
  tagIds: Maybe<Array<Scalars['Int']>>;
  withoutShiftPresets: Maybe<Scalars['Boolean']>;
  withoutTags: Maybe<Scalars['Boolean']>;
  withoutConflicts?: Scalars['Boolean'];
  withOpenShifts?: Scalars['Boolean'];
  withShifts: Scalars['Boolean'];
  withAbsences: Scalars['Boolean'];
  withSpecialDates?: Scalars['Boolean'];
  locationsPositionIds: Maybe<Array<Scalars['Int']>>;
  employmentId: Maybe<Scalars['Int']>;
  employmentIds: Maybe<Array<Scalars['Int']>>;
  shouldFetchShiftTags?: Maybe<Scalars['Boolean']>;
  shouldFetchShiftRotationGroupIds?: Maybe<Scalars['Boolean']>;
  shouldFetchShiftAssignmentGroups?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCalendarEventsQuery = { shifts: { items: Array<GQLShiftFieldsFragment> }, absences: { items: Array<GQLAbsenceFieldsFragment> }, openShifts: { items: Array<GQLShiftFieldsFragment> }, specialDates: { items: Array<Pick<GQLSpecialDate, 'id' | 'name' | 'specialDate' | 'fullDay' | 'allLocations' | 'startTime' | 'endTime' | 'isIncludedInHourAccount'>> } };

export type GQLEmploymentsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  employmentId?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  locationsPositionIds?: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLEmploymentsQuery = { employments: { items: Array<(
  Pick<GQLEmployment, 'id' | 'staffNumber' | 'firstName' | 'lastName' | 'locationsPositionIds' | 'qualificationIds'>
  & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>> }
)>, pagination: Pick<GQLPaginationInfo, 'count'> } };

export type GQLEmploymentsRotationGroupsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  employmentIds: Array<Scalars['Int']>;
}>;

export type GQLEmploymentsRotationGroupsQuery = { employmentsRotationGroups: { items: Array<(
  { __typename: 'EmploymentsRotationGroup' }
  & Pick<GQLEmploymentsRotationGroup, 'endsAt' | 'id' | 'startsAt'>
  & { shiftRotationGroup: (
    Pick<GQLShiftRotationGroup, 'id' | 'name' | 'shiftPresetIds'>
    & { shiftPresets: Array<Pick<GQLShiftPreset, 'color' | 'id' | 'name' | 'shortName' | 'startsAt'>>, shiftRotation: Pick<GQLShiftRotation, 'anchorDate' | 'id' | 'name' | 'rotationInterval'> }
  ) }
) | (
  { __typename: 'IndividualShiftRotation' }
  & Pick<GQLIndividualShiftRotation, 'anchorDate' | 'endsAt' | 'id' | 'rotationInterval' | 'shiftPresetIds' | 'startsAt'>
  & { shiftPresets: Array<Pick<GQLShiftPreset, 'color' | 'id' | 'name' | 'shortName' | 'startsAt'>> }
)> } };

export type GQLShiftRotationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLShiftRotationsQuery = { shiftRotations: { items: Array<(
  Pick<GQLShiftRotation, 'id' | 'name'>
  & { shiftRotationGroups: Array<Pick<GQLShiftRotationGroup, 'id' | 'name'>> }
)> } };

export type GQLEmploymentsTableDataQueryVariables = Exact<{
  companyId: Scalars['Int'];
  notInShiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  search: Maybe<Scalars['String']>;
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
  shiftRotationId: Scalars['Int'];
}>;

export type GQLEmploymentsTableDataQuery = { employments: { items: Array<(
  Pick<GQLEmployment, 'id' | 'staffNumber' | 'firstName' | 'lastName'>
  & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>>, employmentsShiftRotationGroups: Array<(
    Pick<GQLEmploymentsRotationGroup, 'id'>
    & { shiftRotationGroup: Pick<GQLShiftRotationGroup, 'id' | 'name'> }
  )> }
)>, pagination: Pick<GQLPaginationInfo, 'count'> } };

export type GQLSidebarDataQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftRotationId: Scalars['Int'];
  notInShiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLSidebarDataQuery = { shiftRotationGroups: { items: Array<(
  Pick<GQLShiftRotationGroup, 'id' | 'name' | 'shiftPresetIds' | 'sortPosition'>
  & { employmentsShiftRotationGroups: Array<Pick<GQLEmploymentsRotationGroup, 'id'>> }
)> }, employments: { pagination: Pick<GQLPaginationInfo, 'count'> } };

export type GQLCreateEmploymentsRotationGroupMutationVariables = Exact<{
  employmentsRotationGroup: GQLEmploymentsRotationGroupCreateInput;
}>;

export type GQLCreateEmploymentsRotationGroupMutation = { createEmploymentsRotationGroup: Pick<GQLEmploymentsRotationGroup, 'id'> };

export type GQLCreateIndividualShiftRotationMutationVariables = Exact<{
  individualShiftRotation: GQLIndividualShiftRotationCreateInput;
}>;

export type GQLCreateIndividualShiftRotationMutation = { createIndividualShiftRotation: Pick<GQLIndividualShiftRotation, 'id'> };

export type GQLCreateShiftRotationMutationVariables = Exact<{
  shiftRotation: GQLShiftRotationCreateInput;
}>;

export type GQLCreateShiftRotationMutation = { createShiftRotation: Pick<GQLShiftRotation, 'id'> };

export type GQLCreateShiftRotationGroupMutationVariables = Exact<{
  shiftRotationGroup: GQLShiftRotationGroupCreateInput;
}>;

export type GQLCreateShiftRotationGroupMutation = { createShiftRotationGroup: Pick<GQLShiftRotationGroup, 'id'> };

export type GQLDeleteEmploymentsRotationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GQLDeleteEmploymentsRotationGroupMutation = { deleteEmploymentsRotationGroup: Pick<GQLSuccessResponse, 'success'> };

export type GQLFetchShiftRotationQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLFetchShiftRotationQuery = { shiftRotations: { items: Array<(
  Pick<GQLShiftRotation, 'id' | 'rotationInterval' | 'startsAt' | 'endsAt' | 'anchorDate' | 'name'>
  & { shiftRotationGroups: Array<(
    Pick<GQLShiftRotationGroup, 'id' | 'name' | 'shiftPresetIds'>
    & { shiftPresets: Array<Pick<GQLShiftPreset, 'id' | 'color' | 'name' | 'shortName' | 'startsAt'>> }
  )> }
)> } };

export type GQLUpdateEmploymentsRotationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  employmentsRotationGroup: GQLEmploymentsRotationGroupUpdateInput;
  unassignmentStrategy: Maybe<GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy>;
}>;

export type GQLUpdateEmploymentsRotationGroupMutation = { updateEmploymentsRotationGroup: Pick<GQLEmploymentsRotationGroup, 'id'> };

export type GQLUpdateShiftRotationMutationVariables = Exact<{
  shiftRotation: GQLShiftRotationCreateInput;
  id: Scalars['Int'];
}>;

export type GQLUpdateShiftRotationMutation = { updateShiftRotation: Pick<GQLShiftRotation, 'id'> };

export type GQLUpdateShiftRotationGroupMutationVariables = Exact<{
  shiftRotationGroup: GQLShiftRotationGroupCreateInput;
  id: Scalars['Int'];
}>;

export type GQLUpdateShiftRotationGroupMutation = { updateShiftRotationGroup: Pick<GQLShiftRotationGroup, 'id'> };

export type GQLEmploymentsFilterQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  search: Maybe<Scalars['String']>;
  shiftRotationGroupIds: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLEmploymentsFilterQuery = { employments: { items: Array<(
  Pick<GQLEmployment, 'id' | 'firstName' | 'lastName' | 'staffNumber' | 'deletedAt'>
  & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>>, locationsPositions: Maybe<Array<Maybe<(
    Pick<GQLLocationsPosition, 'id'>
    & { position: Maybe<Pick<GQLPosition, 'id' | 'name' | 'color' | 'deletedAt'>> }
  )>>>, employmentWorkHour: Maybe<Pick<GQLEmploymentWorkHour, 'monthlyMinutes' | 'weeklyMinutes'>> }
)>, pagination: Pick<GQLPaginationInfo, 'count'> } };

export type GQLAbsenceCollisionFragment = (
  Pick<GQLAbsence, 'id' | 'startsAt' | 'endsAt' | 'isFullDay'>
  & { absenceReason: Pick<GQLAbsenceReason, 'id' | 'name' | 'hasLocalization'> }
);

export type GQLShiftCollisionFragment = (
  Pick<GQLShift, 'id' | 'startsAt' | 'endsAt'>
  & { locationsPosition: (
    Pick<GQLLocationsPosition, 'id'>
    & { location: Maybe<Pick<GQLLocation, 'id' | 'name'>>, position: Maybe<Pick<GQLPosition, 'id' | 'color' | 'name' | 'note'>> }
  ) }
);

export type GQLAbsenceReasonsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLAbsenceReasonsQuery = { absenceReasons: { items: Array<Pick<GQLAbsenceReason, 'deletedAt' | 'hasLocalization' | 'id' | 'isAbsenceAttachmentsAllowed' | 'name' | 'carryOverDaysEnabled' | 'carryOverDaysDeadline'>> } };

export type GQLAssignmentGroupFragmentFragment = (
  Pick<GQLAssignmentGroup, 'id' | 'createdAt' | 'deletedAt' | 'employmentCount' | 'metadata' | 'name' | 'shiftCount' | 'updatedAt'>
  & { shiftplan: Pick<GQLShiftplan, 'id'> }
);

export type GQLAssignmentGroupsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanIds: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLAssignmentGroupsQuery = { assignmentGroups: { items: Array<GQLAssignmentGroupFragmentFragment> } };

export type GQLCreateAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  assignmentGroup: GQLAssignmentGroupCreateInput;
}>;

export type GQLCreateAssignmentGroupMutation = { createAssignmentGroup: GQLAssignmentGroupFragmentFragment };

export type GQLFetchAssignmentGroupQueryVariables = Exact<{
  companyId: Scalars['Int'];
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  shiftplanIds: Maybe<Array<Maybe<Scalars['Int']>>>;
}>;

export type GQLFetchAssignmentGroupQuery = { assignmentGroups: { items: Array<GQLAssignmentGroupFragmentFragment> } };

export type GQLRemoveAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLRemoveAssignmentGroupMutation = { removeAssignmentGroup: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLUnassignEmploymentFromAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  employmentId: Scalars['Int'];
  onlyFuture: Maybe<Scalars['Boolean']>;
  shiftId: Maybe<Scalars['Int']>;
}>;

export type GQLUnassignEmploymentFromAssignmentGroupMutation = { unassignEmploymentFromAssignmentGroup: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLUpdateAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  assignmentGroup: GQLAssignmentGroupUpdateInput;
}>;

export type GQLUpdateAssignmentGroupMutation = { updateAssignmentGroup: GQLAssignmentGroupFragmentFragment };

export type GQLBackgroundJobFragmentFragment = Pick<GQLBackgroundJob, 'id' | 'jobIdentifier' | 'state' | 'startedAt' | 'createdAt'>;

export type GQLBackgroundJobsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  ids: Maybe<Array<Scalars['String']>>;
}>;

export type GQLBackgroundJobsQuery = { backgroundJobs: { items: Array<GQLBackgroundJobFragmentFragment> } };

export type GQLCreateDayNoteMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  dayNote: GQLDayNoteCreateInput;
}>;

export type GQLCreateDayNoteMutation = { createDayNote: GQLDayNoteFragmentFragment };

export type GQLDayNoteFragmentFragment = (
  Pick<GQLDayNote, 'id' | 'description' | 'endsAt' | 'startsAt' | 'title'>
  & { shiftplan: Pick<GQLShiftplan, 'id'> }
);

export type GQLDayNotesQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
}>;

export type GQLDayNotesQuery = { dayNotes: { items: Array<GQLDayNoteFragmentFragment> } };

export type GQLRemoveDayNoteMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLRemoveDayNoteMutation = { removeDayNote: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLUpdateDayNoteMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  id: Scalars['Int'];
  dayNote: GQLDayNoteCreateInput;
}>;

export type GQLUpdateDayNoteMutation = { updateDayNote: GQLDayNoteFragmentFragment };

export type GQLCreateEmploymentEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
}>;

export type GQLCreateEmploymentEvaluationBreakMutation = { createEmploymentEvaluationBreak: GQLEvaluationBreakFragmentFragment };

export type GQLDeleteEmploymentEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
}>;

export type GQLDeleteEmploymentEvaluationBreakMutation = { deleteEmploymentEvaluationBreak: GQLEvaluationBreakFragmentFragment };

export type GQLUpdateEmploymentEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
}>;

export type GQLUpdateEmploymentEvaluationBreakMutation = { updateEmploymentEvaluationBreak: GQLEvaluationBreakFragmentFragment };

export type GQLCreateEmploymentEvaluationPaymentMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  payment: GQLPaymentCreateInput;
}>;

export type GQLCreateEmploymentEvaluationPaymentMutation = { createEmploymentEvaluationPayment: GQLPaymentFragmentFragment };

export type GQLDeleteEmploymentEvaluationPaymentMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  parentPaymentId: Maybe<Scalars['Int']>;
}>;

export type GQLDeleteEmploymentEvaluationPaymentMutation = { deleteEmploymentEvaluationPayment: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLUpdateEmploymentEvaluationPaymentMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  payment: GQLPaymentUpdateInput;
}>;

export type GQLUpdateEmploymentEvaluationPaymentMutation = { updateEmploymentEvaluationPayment: GQLPaymentFragmentFragment };

export type GQLCreateEmploymentEvaluationTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  evaluationId: Scalars['Int'];
  tagId: Scalars['Int'];
}>;

export type GQLCreateEmploymentEvaluationTagMutation = { createEmploymentEvaluationTag: GQLEmploymentEvaluationTagFragmentFragment };

export type GQLDeleteEmploymentEvaluationTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  evaluationId: Scalars['Int'];
  evaluationTagId: Scalars['Int'];
}>;

export type GQLDeleteEmploymentEvaluationTagMutation = { deleteEmploymentEvaluationTag: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLEmploymentEvaluationTagFragmentFragment = (
  Pick<GQLEmploymentEvaluationTag, 'id'>
  & { tag: GQLTagFragmentFragment }
);

export type GQLCreateEmploymentEvaluationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  employmentEvaluation: GQLEmploymentEvaluationInput;
}>;

export type GQLCreateEmploymentEvaluationMutation = { createEmploymentEvaluation: GQLEmploymentEvaluationFragmentFragment };

export type GQLEmploymentEvaluationQueryVariables = Exact<{
  staffShiftId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GQLEmploymentEvaluationQuery = { employmentEvaluation: Maybe<GQLEmploymentEvaluationFragmentFragment> };

export type GQLEmploymentEvaluationFragmentFragment = (
  Pick<GQLEmploymentEvaluation, 'id' | 'break' | 'endsAt' | 'note' | 'startsAt' | 'staffShiftId' | 'untimedBreak'>
  & { breaks: Array<GQLEvaluationBreakFragmentFragment>, evaluationTags: Array<GQLEmploymentEvaluationTagFragmentFragment>, payments: Array<GQLPaymentFragmentFragment> }
);

export type GQLCreateEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
}>;

export type GQLCreateEvaluationBreakMutation = { createEvaluationBreak: GQLEvaluationBreakFragmentFragment };

export type GQLDeleteEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
}>;

export type GQLDeleteEvaluationBreakMutation = { deleteEvaluationBreak: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLEvaluationBreakFragmentFragment = Pick<GQLEvaluationBreak, 'id' | 'endsAt' | 'startsAt'>;

export type GQLUpdateEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
}>;

export type GQLUpdateEvaluationBreakMutation = { updateEvaluationBreak: GQLEvaluationBreakFragmentFragment };

export type GQLCreateEvaluationPaymentMutationVariables = Exact<{
  staffShiftId: Scalars['Int'];
  payment: GQLPaymentCreateInput;
}>;

export type GQLCreateEvaluationPaymentMutation = { createEvaluationPayment: GQLPaymentFragmentFragment };

export type GQLDeleteEvaluationPaymentMutationVariables = Exact<{
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  parentPaymentId: Maybe<Scalars['Int']>;
}>;

export type GQLDeleteEvaluationPaymentMutation = { deleteEvaluationPayment: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLPaymentFragmentFragment = (
  Pick<GQLPayment, 'displayName' | 'id' | 'inheritedFrom' | 'sumWithShift' | 'staffShiftId' | 'type' | 'value'>
  & { childPayments: Maybe<Array<(
    Pick<GQLPayment, 'displayName' | 'id' | 'inheritedFrom' | 'sumWithShift' | 'type' | 'value'>
    & { paygradeType: Pick<GQLPaygradeType, 'id' | 'name' | 'payType'> }
  )>>, paygradeType: Pick<GQLPaygradeType, 'id' | 'name' | 'payType'> }
);

export type GQLUpdateEvaluationPaymentMutationVariables = Exact<{
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  payment: GQLPaymentUpdateInput;
}>;

export type GQLUpdateEvaluationPaymentMutation = { updateEvaluationPayment: GQLPaymentFragmentFragment };

export type GQLCreateEvaluationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluation: GQLEvaluationInput;
}>;

export type GQLCreateEvaluationMutation = { createEvaluation: GQLEvaluationFragmentFragment };

export type GQLEvaluationQueryVariables = Exact<{
  staffShiftId: Scalars['Int'];
}>;

export type GQLEvaluationQuery = { evaluations: { items: Array<GQLEvaluationFragmentFragment> } };

export type GQLEvaluationFragmentFragment = (
  Pick<GQLEvaluation, 'id' | 'break' | 'untimedBreak' | 'endsAt' | 'startsAt' | 'totalPayment' | 'staffShiftId' | 'state'>
  & { note: GQLEvaluation['adminEvaluationNote'] }
  & { employment: (
    Pick<GQLEmployment, 'id' | 'firstName' | 'lastName' | 'staffNumber'>
    & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>> }
  ), location: Pick<GQLLocation, 'id'>, locationsPosition: Pick<GQLLocationsPosition, 'id'>, payments: Array<GQLPaymentFragmentFragment>, breaks: Array<GQLEvaluationBreakFragmentFragment>, shift: Pick<GQLShift, 'id'>, staffShiftsTags: Array<(
    Pick<GQLStaffShiftsTag, 'id'>
    & { tag: Pick<GQLTag, 'id' | 'color' | 'name'> }
  )> }
);

export type GQLEvaluationsQueryVariables = Exact<{
  shiftId: Scalars['Int'];
}>;

export type GQLEvaluationsQuery = { evaluations: { items: Array<GQLEvaluationFragmentFragment> } };

export type GQLCreateLocationsPositionMutationVariables = Exact<{
  locationsPosition: GQLLocationsPositionCreateInput;
}>;

export type GQLCreateLocationsPositionMutation = { createLocationsPosition: Maybe<GQLLocationsPositionFragmentFragment> };

export type GQLLocationsPositionFragmentFragment = (
  Pick<GQLLocationsPosition, 'id' | 'sort'>
  & { location: Maybe<Pick<GQLLocation, 'id' | 'name'>>, position: Maybe<Pick<GQLPosition, 'id' | 'color' | 'name' | 'note'>> }
);

export type GQLLocationsPositionsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationIds: Maybe<Array<Scalars['Int']>>;
  positionIds: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLLocationsPositionsQuery = { locationsPositions: { items: Array<GQLLocationsPositionFragmentFragment> } };

export type GQLLocationFragmentFragment = Pick<GQLLocation, 'id' | 'name'>;

export type GQLLocationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLLocationsQuery = { locations: { items: Array<GQLLocationFragmentFragment> } };

export type GQLPaygradeTypeFragmentFragment = Pick<GQLPaygradeType, 'id' | 'name' | 'payType'>;

export type GQLPaygradeTypesQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLPaygradeTypesQuery = { paygradeTypes: Maybe<{ items: Array<GQLPaygradeTypeFragmentFragment> }> };

export type GQLPaymentsCalculationQueryVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  input: GQLPaymentsCalculationInput;
}>;

export type GQLPaymentsCalculationQuery = { paymentsCalculation: GQLPaymentsCalculationFragmentFragment };

export type GQLPaymentsCalculationFragmentFragment = (
  Pick<GQLPaymentsCalculation, 'sum'>
  & { payments: Maybe<Array<(
    Pick<GQLPaymentsCalculationPayment, 'paygradeTypeId' | 'totalValue'>
    & { childPayments: Maybe<Array<Pick<GQLPaymentsCalculationPayment, 'paygradeTypeId' | 'totalValue'>>> }
  )>> }
);

export type GQLCreatePositionMutationVariables = Exact<{
  position: GQLPositionCreateInput;
}>;

export type GQLCreatePositionMutation = { createPosition: Maybe<GQLPositionFragmentFragment> };

export type GQLPositionFragmentFragment = Pick<GQLPosition, 'color' | 'description' | 'id' | 'name' | 'note' | 'sort'>;

export type GQLPositionsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLPositionsQuery = { positions: { items: Array<GQLPositionFragmentFragment> } };

export type GQLQualificationFragmentFragment = Pick<GQLQualification, 'id' | 'name'>;

export type GQLQualificationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLQualificationsQuery = { qualifications: { items: Array<GQLQualificationFragmentFragment> } };

export type GQLCreateShiftAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLCreateShiftAssignmentGroupMutation = { createShiftAssignmentGroup: GQLShiftAssignmentGroupFragmentFragment };

export type GQLDeleteShiftAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLDeleteShiftAssignmentGroupMutation = { deleteShiftAssignmentGroup: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLShiftAssignmentGroupFragmentFragment = Pick<GQLShiftAssignmentGroup, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'assignmentGroupId' | 'shiftId'>;

export type GQLShiftAvailableEmploymentFragmentFragment = (
  Pick<GQLShiftAvailableEmployment, 'id' | 'contractMinutes' | 'hasConflicts' | 'hourAccountHours' | 'maximumMoney' | 'scheduledMinutes' | 'scheduledMoney' | 'shiftId' | 'workedMinutes' | 'bringShiftHours' | 'bringShiftHoursUsed'>
  & { availabilityInfo: (
    Pick<GQLAvailabilityInfo, 'partialAvailable' | 'partialUnavailable' | 'complete' | 'fullAvailable' | 'fullUnavailable'>
    & { available: Maybe<Array<Pick<GQLShiftEmploymentAvailability, 'endsAt' | 'startsAt'>>>, unavailable: Maybe<Array<Pick<GQLShiftEmploymentAvailability, 'endsAt' | 'startsAt'>>> }
  ), conflicts: Maybe<Array<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment>>, employment: (
    Pick<GQLEmployment, 'id' | 'firstName' | 'hourEnabled' | 'lastName' | 'maximumMoney' | 'maximumMoneyEnabled' | 'staffNumber' | 'qualificationIds'>
    & { employmentWorkHour: Maybe<Pick<GQLEmploymentWorkHour, 'isMonthly' | 'monthlyMinutes' | 'weeklyMinutes'>>, pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>>, employmentsShiftRotationGroups: Array<Pick<GQLEmploymentsRotationGroup, 'id' | 'shiftRotationGroupId' | 'startsAt' | 'endsAt'>> }
  ), joinRequest: Maybe<Pick<GQLRequest, 'id' | 'createdAt'>> }
);

export type GQLShiftAvailableEmploymentsQueryVariables = Exact<{
  availability: Maybe<GQLShiftEmploymentAvailabilityFilter>;
  companyId: Scalars['Int'];
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  searchString: Maybe<Scalars['String']>;
  shiftId: Scalars['Int'];
  withJoinRequests: Maybe<Scalars['Boolean']>;
  withoutAbsenceConflicts: Maybe<Scalars['Boolean']>;
  withoutShiftConflicts: Maybe<Scalars['Boolean']>;
  sortCriterion: Maybe<GQLShiftEmploymentSort>;
  sortOrder: Maybe<GQLOrderDirection>;
  withTheseQualificationIds: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLShiftAvailableEmploymentsQuery = { shiftAvailableEmployments: { items: Array<GQLShiftAvailableEmploymentFragmentFragment>, pagination: Pick<GQLPaginationInfo, 'count'> } };

export type GQLCreateShiftEvaluationTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  tagId: Scalars['Int'];
}>;

export type GQLCreateShiftEvaluationTagMutation = { createShiftEvaluationTag: GQLShiftEvaluationTagFragmentFragment };

export type GQLDeleteShiftEvaluationTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLDeleteShiftEvaluationTagMutation = { deleteShiftEvaluationTag: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLShiftEvaluationTagFragmentFragment = { tag: GQLTagFragmentFragment };

export type GQLShiftEvaluationTagsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLShiftEvaluationTagsQuery = { shiftEvaluationTags: { items: Array<GQLTagFragmentFragment> } };

export type GQLCreateShiftPaygradeMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  paygrade: GQLShiftPaygradeCreateInput;
}>;

export type GQLCreateShiftPaygradeMutation = { createShiftPaygrade: GQLShiftPaygradeFragmentFragment };

export type GQLDeleteShiftPaygradeMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLDeleteShiftPaygradeMutation = { removeShiftPaygrade: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLShiftPaygradeFragmentFragment = (
  Pick<GQLShiftPaygrade, 'id' | 'paygradeForType' | 'paygradeForId' | 'shiftId' | 'value'>
  & { paygradeType: Pick<GQLPaygradeType, 'id' | 'name' | 'payType'> }
);

export type GQLShiftPaygradesQueryVariables = Exact<{
  shiftId: Scalars['Int'];
}>;

export type GQLShiftPaygradesQuery = { shiftPaygrades: { items: Array<GQLShiftPaygradeFragmentFragment> } };

export type GQLUpdateShiftPaygradeMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  id: Scalars['Int'];
  paygrade: GQLShiftPaygradeCreateInput;
}>;

export type GQLUpdateShiftPaygradeMutation = { updateShiftPaygrade: GQLShiftPaygradeFragmentFragment };

export type GQLShiftPresetFragmentFragment = Pick<GQLShiftPreset, 'id' | 'color' | 'name' | 'shortName' | 'startsAt' | 'startsAtTime' | 'endsAtTime' | 'tagIds' | 'shiftEvaluationTagIds' | 'breakTime'>;

export type GQLShiftPresetsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLShiftPresetsQuery = { shiftPresets: { items: Array<GQLShiftPresetFragmentFragment> } };

export type GQLShiftRotationFragmentFragment = (
  Pick<GQLShiftRotation, 'id' | 'rotationInterval' | 'startsAt' | 'endsAt' | 'anchorDate' | 'name'>
  & { shiftRotationGroups: Array<GQLShiftRotationGroupFragmentFragment> }
);

export type GQLShiftRotationGroupFragmentFragment = Pick<GQLShiftRotationGroup, 'id' | 'name' | 'sortPosition' | 'shiftPresetIds'>;

export type GQLFetchAllShiftRotationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanIds?: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLFetchAllShiftRotationsQuery = { shiftRotations: { items: Array<GQLShiftRotationFragmentFragment> } };

export type GQLCreateShiftplanNotificationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanCreateNotificationInput;
}>;

export type GQLCreateShiftplanNotificationMutation = { createShiftplanNotification: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLCreateShiftplanMessageMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanCreateMessageInput;
}>;

export type GQLCreateShiftplanMessageMutation = { createShiftplanMessage: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLShiftplanNotificationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
}>;

export type GQLShiftplanNotificationsQuery = { shiftplanNotifications: Pick<GQLShiftplanNotifications, 'count'> };

export type GQLApplyRotationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLApplyShiftplanRotationInput;
}>;

export type GQLApplyRotationMutation = { applyShiftplanRotation: Pick<GQLBackgroundJobResponse, 'id' | 'jobId'> };

export type GQLAssignEmploymentsToOpenShiftsMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
}>;

export type GQLAssignEmploymentsToOpenShiftsMutation = { assignEmploymentsToOpenShifts: Pick<GQLBackgroundJob, 'id' | 'jobIdentifier' | 'result' | 'errors'> };

export type GQLCopyShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  copyShiftplanParams: GQLShiftplanCopyInput;
}>;

export type GQLCopyShiftplanMutation = { copyShiftplan: Pick<GQLBackgroundJob, 'id' | 'jobIdentifier' | 'result' | 'errors'> };

export type GQLCreateShiftplanMutationVariables = Exact<{
  input: GQLShiftplanCreateInput;
}>;

export type GQLCreateShiftplanMutation = { createShiftplan: GQLShiftplanFragmentFragment };

export type GQLCreateShiftplanFromShiftRotationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftRotationId: Scalars['Int'];
  input: GQLShiftRotationShiftplanCreateInput;
}>;

export type GQLCreateShiftplanFromShiftRotationMutation = { createShiftplanFromShiftRotation: Pick<GQLBackgroundJobResponse, 'id' | 'jobId'> };

export type GQLDeleteShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLDeleteShiftplanMutation = { deleteShiftplan: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLFetchShiftplanQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLFetchShiftplanQuery = { shiftplans: { items: Array<GQLShiftplanFragmentFragment> } };

export type GQLImportShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  importShiftplanParams: GQLShiftplanImportInput;
}>;

export type GQLImportShiftplanMutation = { importShiftplan: Pick<GQLBackgroundJob, 'id' | 'jobIdentifier' | 'result' | 'errors'> };

export type GQLPublishShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanPublishInput;
}>;

export type GQLPublishShiftplanMutation = { publishShiftplan: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLShiftplanFragmentFragment = Pick<GQLShiftplan, 'endsAt' | 'id' | 'name' | 'startsAt' | 'state' | 'locationId' | 'locked'>;

export type GQLShiftplansQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Maybe<Scalars['Int']>;
}>;

export type GQLShiftplansQuery = { shiftplans: { items: Array<GQLShiftplanFragmentFragment> } };

export type GQLUpdateShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  shiftplan: GQLShiftplanUpdateInput;
}>;

export type GQLUpdateShiftplanMutation = { updateShiftplan: GQLShiftplanFragmentFragment };

export type GQLAcceptReplaceOfferMutationVariables = Exact<{
  companyId: Scalars['Int'];
  ignoreConflicts: Maybe<Scalars['Boolean']>;
  requestId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLAcceptReplaceOfferMutation = { acceptReplaceOffer: Maybe<Pick<GQLPendingReplaceRequest, 'id' | 'state'> | { conflicts: Maybe<Array<Maybe<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment>>> }> };

export type GQLAddTagToShiftMutationVariables = Exact<{
  tagId: Scalars['Int'];
  shiftId: Scalars['Int'];
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
}>;

export type GQLAddTagToShiftMutation = { addTagToShift: Pick<GQLShiftTag, 'id'> };

export type GQLAssignEmployeeToShiftRotationGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftRotationGroupAssignInput: GQLShiftRotationGroupAssignInput;
}>;

export type GQLAssignEmployeeToShiftRotationGroupMutation = { assignEmployeeToShiftRotationGroup: { jobId: GQLBackgroundJob['id'] } };

export type GQLConfirmReplaceRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  employmentId: Scalars['Int'];
  id: Scalars['Int'];
  ignoreConflicts: Maybe<Scalars['Boolean']>;
}>;

export type GQLConfirmReplaceRequestMutation = { confirmReplaceRequest: Maybe<Pick<GQLPendingReplaceRequest, 'id' | 'state'> | { conflicts: Maybe<Array<Maybe<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment>>> }> };

export type GQLConnectShiftsMutationVariables = Exact<{
  shiftIds: Array<Scalars['Int']>;
  shiftplanId: Scalars['Int'];
}>;

export type GQLConnectShiftsMutation = { connectShifts: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLCopyShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  copyParams: GQLShiftCopyInput;
  isShiftEvaluationTagsIncluded?: Maybe<Scalars['Boolean']>;
}>;

export type GQLCopyShiftMutation = { copyShift: GQLShiftFragmentFragment | { conflicts: Array<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment> } };

export type GQLCreateJoinRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  ignoreConflicts?: Maybe<Scalars['Boolean']>;
  shiftId: Scalars['Int'];
}>;

export type GQLCreateJoinRequestMutation = { createJoinRequest: Maybe<(
  Pick<GQLRequestState, 'success' | 'errorKey' | 'warning'>
  & { conflicts: Maybe<Array<Maybe<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment>>> }
)> };

export type GQLCreateLeaveRequestMutationVariables = Exact<{
  shiftId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GQLCreateLeaveRequestMutation = { createLeaveRequest: Maybe<Pick<GQLCreateResponse, 'success'>> };

export type GQLCreateReplaceRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLCreateReplaceRequestMutation = { createReplaceRequest: Maybe<Pick<GQLRequest, 'id'>> };

export type GQLCreateShiftMutationVariables = Exact<{
  shift: GQLShiftCreateInput;
}>;

export type GQLCreateShiftMutation = { createShift: Pick<GQLShift, 'id'> | { conflicts: Array<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment> } };

export type GQLCreateShiftBreakMutationVariables = Exact<{
  shiftId: Scalars['Int'];
  companyId: Scalars['Int'];
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
}>;

export type GQLCreateShiftBreakMutation = { createShiftBreak: Pick<GQLShiftBreak, 'id' | 'startsAt' | 'endsAt' | 'shiftId'> };

export type GQLDeleteRequestMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLDeleteRequestMutation = { deleteRequest: Maybe<Pick<GQLSuccessResponse, 'success' | 'error'>> };

export type GQLDeleteShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  deleteParams: GQLShiftDeleteInput;
}>;

export type GQLDeleteShiftMutation = { deleteShift: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLDisconnectShiftMutationVariables = Exact<{
  shiftId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GQLDisconnectShiftMutation = { disconnectShift: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLFetchAllShiftsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  perPage: Maybe<Scalars['Int']>;
  shiftplanId: Maybe<Scalars['Int']>;
  isShiftEvaluationTagsIncluded?: Maybe<Scalars['Boolean']>;
}>;

export type GQLFetchAllShiftsQuery = { shifts: { items: Array<GQLShiftFragmentFragment> } };

export type GQLFetchShiftQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  isShiftEvaluationTagsIncluded?: Maybe<Scalars['Boolean']>;
}>;

export type GQLFetchShiftQuery = { shifts: { items: Array<GQLShiftFragmentFragment> } };

type GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment = (
  { __typename: 'ShiftsCollisionShiftConflict' }
  & Pick<GQLShiftsCollisionShiftConflict, 'ignorable'>
  & { shifts: Maybe<Array<Maybe<GQLShiftCollisionFragment>>> }
);

type GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment = (
  { __typename: 'AbsencesCollisionShiftConflict' }
  & Pick<GQLAbsencesCollisionShiftConflict, 'ignorable'>
  & { absences: Maybe<Array<Maybe<GQLAbsenceCollisionFragment>>> }
);

type GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment = (
  { __typename: 'AlreadyAssignedShiftConflict' }
  & Pick<GQLAlreadyAssignedShiftConflict, 'message' | 'ignorable'>
);

type GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment = (
  { __typename: 'MaximumWorkingHoursShiftConflict' }
  & Pick<GQLMaximumWorkingHoursShiftConflict, 'total' | 'monthlyMinutes' | 'weeklyMinutes' | 'month' | 'week' | 'year' | 'name' | 'difference' | 'ignorable'>
);

type GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment = (
  { __typename: 'MaximumEarningShiftConflict' }
  & Pick<GQLMaximumEarningShiftConflict, 'employmentMaxPayment' | 'totalPayment' | 'currency' | 'ignorable'>
);

type GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment = (
  { __typename: 'MinimumRestShiftConflict' }
  & Pick<GQLMinimumRestShiftConflict, 'restMinutes' | 'minimumRestMinutes' | 'lastShiftEndsAt' | 'newShiftStartsAt' | 'ignorable'>
);

type GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment = (
  { __typename: 'OutsideWorkPeriodShiftConflict' }
  & Pick<GQLOutsideWorkPeriodShiftConflict, 'startsAt' | 'endsAt' | 'ignorable'>
);

type GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment = (
  { __typename: 'MaximumConsecutiveWorkingDaysShiftConflict' }
  & Pick<GQLMaximumConsecutiveWorkingDaysShiftConflict, 'employmentName' | 'maxDays' | 'ignorable'>
);

type GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment = (
  { __typename: 'RestrictedShiftPresetsConflict' }
  & Pick<GQLRestrictedShiftPresetsConflict, 'restrictedShiftPresets' | 'ignorable'>
);

type GQLShiftConflictsFragment_UnknownShiftConflict_Fragment = (
  { __typename: 'UnknownShiftConflict' }
  & Pick<GQLUnknownShiftConflict, 'data' | 'message' | 'ignorable'>
);

type GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment = (
  { __typename: 'MinimumBreakShiftConflict' }
  & Pick<GQLMinimumBreakShiftConflict, 'minBreakTime' | 'shiftDuration' | 'ignorable'>
);

type GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment = (
  { __typename: 'LocationsPositionChangedShiftConflict' }
  & Pick<GQLLocationsPositionChangedShiftConflict, 'message' | 'ignorable'>
);

export type GQLShiftConflictsFragmentFragment = GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment;

export type GQLShiftFragmentFragment = (
  Pick<GQLShift, 'id' | 'canEvaluate' | 'canManage' | 'endsAt' | 'breakTime' | 'untimedBreakTime' | 'note' | 'connectedGroupId' | 'workers' | 'shiftRotationGroupIds' | 'autoAccept' | 'untimed' | 'managerNote' | 'staffShiftsCount' | 'startsAt'>
  & { tags: Maybe<Array<Pick<GQLTag, 'id' | 'name' | 'color'>>>, shiftEvaluationTags: Maybe<Array<Pick<GQLTag, 'id' | 'name' | 'color'>>>, shiftAssignmentGroups: Maybe<Array<Pick<GQLShiftAssignmentGroup, 'assignmentGroupId' | 'id' | 'shiftId'>>>, shiftRotationGroups: Maybe<Array<(
    Pick<GQLShiftRotationGroup, 'id' | 'name'>
    & { shiftRotation: Pick<GQLShiftRotation, 'id' | 'name'> }
  )>>, shiftPreset: Maybe<Pick<GQLShiftPreset, 'id' | 'name' | 'color' | 'shortName' | 'startsAtTime' | 'endsAtTime'>>, locationsPosition: (
    Pick<GQLLocationsPosition, 'id'>
    & { location: Maybe<Pick<GQLLocation, 'id' | 'name'>>, position: Maybe<Pick<GQLPosition, 'id' | 'color' | 'name' | 'note'>> }
  ), requests: Array<(
    Pick<GQLRequest, 'id' | 'acceptedBy' | 'automationCheckedAt' | 'createdAt' | 'state' | 'type'>
    & { employment: Maybe<(
      Pick<GQLEmployment, 'id' | 'firstName' | 'lastName' | 'staffNumber' | 'qualificationIds'>
      & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>> }
    )> }
  )>, shiftBreaks: Array<Pick<GQLShiftBreak, 'id' | 'startsAt' | 'endsAt'>>, staffShifts: Maybe<Array<(
    Pick<GQLStaffShift, 'id' | 'shiftAssignmentGroupId' | 'state'>
    & { employment: Maybe<(
      Pick<GQLEmployment, 'id' | 'firstName' | 'lastName' | 'staffNumber' | 'qualificationIds'>
      & { pictureData: Maybe<Pick<GQLPictureData, 'pictureSmall'>> }
    )> }
  )>>, shiftplan: GQLShiftplanFragmentFragment, shiftQualifications: Maybe<Array<(
    Pick<GQLShiftQualification, 'count' | 'qualificationId'>
    & { qualification: Pick<GQLQualification, 'name' | 'id'> }
  )>> }
);

export type GQLUpdateShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  shift: GQLShiftUpdateInput;
  isShiftEvaluationTagsIncluded?: Maybe<Scalars['Boolean']>;
}>;

export type GQLUpdateShiftMutation = { updateShift: GQLShiftFragmentFragment | { conflicts: Array<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment> } };

export type GQLWithdrawAcceptedReplaceRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  acceptedBy: Maybe<Scalars['Int']>;
}>;

export type GQLWithdrawAcceptedReplaceRequestMutation = { withdrawAcceptedReplaceRequest: Maybe<Pick<GQLPendingReplaceRequest, 'id' | 'state'>> };

export type GQLCreateStaffShiftsTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  tagId: Scalars['Int'];
}>;

export type GQLCreateStaffShiftsTagMutation = { createStaffShiftsTag: GQLStaffShiftsTagFragmentFragment };

export type GQLDeleteStaffShiftsTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  tagId: Scalars['Int'];
}>;

export type GQLDeleteStaffShiftsTagMutation = { deleteStaffShiftsTag: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLStaffShiftsTagFragmentFragment = Pick<GQLStaffShiftsTag, 'id' | 'staffShiftId' | 'tagId'>;

export type GQLCreateStaffShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShift: GQLStaffShiftCreateInput;
}>;

export type GQLCreateStaffShiftMutation = { createStaffShift: GQLStaffShiftFragmentFragment | { conflicts: Array<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment> } };

export type GQLDeleteStaffShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  unassignFromConnected: Scalars['Boolean'];
}>;

export type GQLDeleteStaffShiftMutation = { deleteStaffShift: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLMoveStaffShiftMutationVariables = Exact<{
  staffShift: GQLStaffShiftMoveInput;
}>;

export type GQLMoveStaffShiftMutation = { moveStaffShift: GQLStaffShiftFragmentFragment | { conflicts: Array<GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment> } };

export type GQLStaffShiftFragmentFragment = Pick<GQLStaffShift, 'id'>;

export type GQLCreateTagMutationVariables = Exact<{
  tag: GQLTagCreateInput;
}>;

export type GQLCreateTagMutation = { createTag: GQLTagFragmentFragment };

export type GQLDeleteTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
}>;

export type GQLDeleteTagMutation = { deleteTag: Pick<GQLSuccessResponse, 'success' | 'error'> };

export type GQLFetchTagQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLFetchTagQuery = { tags: { items: Array<GQLTagFragmentFragment> } };

export type GQLTagFragmentFragment = (
  Pick<GQLTag, 'color' | 'id' | 'name' | 'context' | 'textColor'>
  & { location: Maybe<Pick<GQLLocation, 'id'>> }
);

export type GQLTagsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  context: Maybe<Array<GQLTagContext>>;
  locationIds: Maybe<Array<Scalars['Int']>>;
}>;

export type GQLTagsQuery = { tags: { items: Array<GQLTagFragmentFragment> } };

export type GQLUpdateTagMutationVariables = Exact<{
  id: Scalars['Int'];
  tag: GQLTagUpdateInput;
}>;

export type GQLUpdateTagMutation = { updateTag: GQLTagFragmentFragment };

export type GQLGetUiSettingQueryVariables = Exact<{
  employmentId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GQLGetUiSettingQuery = { employmentsUiSetting: Pick<GQLEmploymentsUiSetting, 'id' | 'createdAt' | 'updatedAt' | 'employmentId' | 'settings' | 'version'> };

export type GQLUpdateEmploymentsUiSettingMutationVariables = Exact<{
  employmentId: Scalars['Int'];
  companyId: Scalars['Int'];
  version: Scalars['String'];
  settings: Scalars['String'];
}>;

export type GQLUpdateEmploymentsUiSettingMutation = { updateEmploymentsUiSetting: Pick<GQLEmploymentsUiSetting, 'id' | 'createdAt' | 'updatedAt' | 'employmentId' | 'settings' | 'version'> };
