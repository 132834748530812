import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { StoreState as AuthStoreState } from 'components/auth/store/Store';
import { GQLShiftplanCopyInput } from 'codegen/gql-types';
import { LocationsPosition } from 'src/store/locations-positions/Store';
import { Shiftplan, shiftplansNS } from 'src/store/shiftplans/Store';
import type { FetchShiftplanFunction, CopyShiftplanFunction } from 'src/store/shiftplans/Store';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'src/utils/store';
import type { GetMultipleById } from 'src/utils/store';
import ShiftplanAction from 'store/shiftplans/Action';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import { shiftScheduleBackgroundJobsMapNS } from 'components/shift-schedule/store/Store';
import type { SetJobFunction } from 'components/shift-schedule/store/Store';
import { backgroundJobsNS } from 'src/store/background-jobs/Store';
import { Action as WatchStoreAction } from 'store/watch-store';
import { Action } from 'store/normalized-store';
import { AlertKind } from 'components/alert/Alert';
import ShiftScheduleAction from '../../store/Action';
import { Route } from '../../routes';
import CopyShiftplan, { TargetShiftplanKind } from './CopyShiftplan';
import { CreateShiftplanKind } from '../section-create-kind/SectionCreateKind';

export type FormState = Omit<GQLShiftplanCopyInput, 'positionIds' | 'copyTimeframeStartsAt' | 'copyTimeframeEndsAt'> & {
  positionIds: number[],
  sourceTimeframeStartsAt: string | null,
  sourceTimeframeEndsAt: string | null,
};

export interface Props {
  createShiftplanKind: CreateShiftplanKind,
  formId: string;
  currentCompany: AuthStoreState['currentCompany'];
  currentLocationId: AuthStoreState['currentLocationId'];
  positions: NonNullable<LocationsPosition['position']>[];
}

@Component
export default class CopyShiftplanContainer extends TsxComponent<Props, {
  onInput: <T extends keyof GQLShiftplanCopyInput>(
    payload: EventPayload<{ field: T; value: GQLShiftplanCopyInput[T] }>,
  ) => void;
  onSubmit: (payload: EventPayload<void>) => void;
}> {
  protected formState: FormState = {
    copyFullShiftplan: true,
    copyEmployees: false,
    name: '',
    withoutConflicts: true,
    startsAt: '',
    endsAt: '',
    positionIds: [],
    sourceShiftplanId: 0,
    sourceTimeframeStartsAt: '',
    sourceTimeframeEndsAt: '',
  };

  protected isSubmitting = false;

  @Prop()
  public readonly formId: Props['formId'];

  @Prop()
  public readonly positions: Props['positions'];

  @Prop()
  public readonly currentCompany: Props['currentCompany'];

  @Prop()
  public readonly currentLocationId: Props['currentLocationId'];

  @Prop()
  public readonly createShiftplanKind: Props['createShiftplanKind'];

  @shiftplansNS.Getter('getByLocationId')
  protected getShiftplansByLocationId: GetMultipleById<Shiftplan>;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @shiftplansNS.Action(ShiftplanAction.COPY_SHIFTPLAN)
  protected copyShiftplan: CopyShiftplanFunction;

  @shiftScheduleBackgroundJobsMapNS.Action(ShiftScheduleAction.SET_JOB)
  protected setJobForShiftplan: SetJobFunction;

  @backgroundJobsNS.Action(WatchStoreAction.REFETCH)
  private backgroundJobsRefetch: () => Promise<void>;

  @shiftplansNS.Action(Action.FETCH)
  protected fetchShiftplan: FetchShiftplanFunction;

  @Watch('formState.sourceShiftplanId')
  protected setDateOffset() {
    const shiftplan = this.shiftplans.find(s => s.id === this.formState.sourceShiftplanId);
    this.formState.sourceTimeframeStartsAt = shiftplan?.startsAt || '';
    this.formState.sourceTimeframeEndsAt = shiftplan?.endsAt || '';
    this.formState.startsAt = shiftplan?.startsAt || '';
    this.formState.endsAt = shiftplan?.endsAt || '';
  }

  protected get shiftplans() {
    return this.getShiftplansByLocationId(this.currentLocationId || Number.NaN);
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit({ event }: EventPayload<void>) {
    event.preventDefault();

    this.isSubmitting = true;
    this.$emit('submit', createEventPayload(event, true));
    const {
      sourceTimeframeEndsAt,
      sourceTimeframeStartsAt,
      ...otherParams
    } = this.formState;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        copyShiftplanParams: {
          ...otherParams,
          copyTimeframeEndsAt: sourceTimeframeEndsAt,
          copyTimeframeStartsAt: sourceTimeframeStartsAt,
        },
      },
      this.copyShiftplan,
      '',
    );

    this.isSubmitting = false;
    this.$emit('submit', createEventPayload(event, false));

    if (response.state !== StoreActionState.SUCCESS || !response.entityId) {
      return;
    }

    const shiftplanId = response.entityId;
    const { jobId } = response.meta;

    this.setJobForShiftplan({
      jobId,
      shiftplanId,
    });

    await Promise.all([
      this.backgroundJobsRefetch(),
      this.fetchShiftplan({ id: shiftplanId }),
    ]);

    this.showSnackbar({
      message: this.$t('createShiftplan.modal.created'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });

    this.$router.push({
      name: Route.SHIFTPLAN,
      params: {
        shiftplanId: response.entityId.toString(),
      },
    });
  }

  public render() {
    return (
      <CopyShiftplan<FormState>
        formId={this.formId}
        formState={this.formState}
        positions={this.positions}
        shiftplans={this.shiftplans}
        onSubmit={this.onSubmit}
        onInput={this.onInput}
        targetShiftplanKind={TargetShiftplanKind.NEW}
      />
    );
  }
}
