import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Prompt from 'components/prompt/Prompt';

export interface Props {
  isOpen?: boolean;
  isSubmitting?: boolean;
  title: string;
}

@Component
export default class DialogConfirmDelete extends TsxComponent<Props, {
  onCancel: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onConfirm: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}> {
  @Prop()
  public isOpen?: Props['isOpen'];

  @Prop()
  public isSubmitting?: Props['isSubmitting'];

  @Prop()
  public title: Props['title'];

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        onCloseClick={e => this.$emit('cancel', createEventPayload(e, undefined))}
        onConfirmClick={e => this.$emit('confirm', createEventPayload(e, undefined))}
        title={this.title}
        isDestructive={true}
        isConfirmButtonDisabled={this.isSubmitting}
        isCloseButtonDisabled={this.isSubmitting}
      >
        {this.$slots.default}
      </Prompt>
    );
  }
}
