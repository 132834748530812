import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';

import styles from './input-color.css';

@Component({
  inheritAttrs: false,
})
export default class InputColor extends TsxComponent<Partial<HTMLInputElement> & {
  id?: string;
  label?: string;
  name?: string;
  value?: string | number | readonly string[];
}, EventsOn<HTMLInputElement>> {
  public $refs: {
    inputRef: HTMLInputElement;
  };

  @Prop({ default: () => `inp-${getRandomString()}` })
  protected id: string;

  @Prop()
  public label?: string;

  @Prop()
  public name?: string;

  @Prop()
  public value?: string | number | readonly string[];

  public render() {
    return (
      <div class={{
        [styles.inputColor]: true,
        [styles.inputColorDisabled]: !!this.$attrs.disabled,
      }}>
        {this.label && (
          <label class={styles.inputColorLabel} for={this.id}>
            {this.label}
          </label>
        )}
        <div class={styles.inputColorInner}>
          <input
            {...{
              attrs: { ...this.$attrs },
              on: { ...this.$listeners },
            }}
            class={styles.inputColorInput}
            id={this.id}
            name={this.name}
            ref="inputRef"
            type="color"
            value={this.value}
          />
          <button
            aria-label={this.$t('buttonColorPicker')}
            class={styles.inputColorButton}
            onClick={() => { this.$refs.inputRef.click(); }}
            style={{
              color: this.value,
            }}
            type="button"
          >
            <span>{this.value}</span>
          </button>
        </div>
      </div>
    );
  }
}
