import { calendarDataNS } from 'components/calendar/data/Store';
import { LOAD_ABSENCES } from 'components/calendar/data/Actions';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { redirectToNamedParent } from 'src/utils/route';
import DefaultActions from 'components/calendar-common/DefaultActions';
import DialogAbsenceContainer from 'components/absences/dialog-absence/DialogAbsenceContainer';
import { NewAbsenceInitialState } from 'components/absences/types';
import { absencesNS } from 'components/calendar/absences/Store';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import { calendarAbsencesAbsencesNS } from 'components/calendar-absences/absences/Store';
import { calendarAbsencesDataNS } from 'components/calendar-absences/data/Store';

export interface Props {
  absenceId?: number;
  initialState?: NewAbsenceInitialState;
  namespace: CalendarNamespace.ABSENCES | CalendarNamespace.CALENDAR;
}

@Component
export default class CalendarDialogAbsence extends TsxComponent<Props, {}> {
  @Prop()
  private absenceId?: Props['absenceId'];

  @Prop()
  private initialState: Props['initialState'];

  @Prop()
  private namespace: Props['namespace'];

  @calendarDataNS.Action(LOAD_ABSENCES)
  private loadAbsencesAction;

  @calendarAbsencesDataNS.Action(LOAD_ABSENCES)
  private calendarAbsencesLoadAbsencesAction;

  @absencesNS.Action(DefaultActions.DELETE)
  private deleteAbsence;

  @calendarAbsencesAbsencesNS.Action(DefaultActions.DELETE)
  private calendarAbsencesDeleteAbsence;

  private onDeleteAbsence(absenceId: number) {
    if (this.namespace === CalendarNamespace.ABSENCES) {
      this.calendarAbsencesDeleteAbsence([absenceId]);
    } else {
      this.deleteAbsence([absenceId]);
    }
  }

  private onLoadAbsence(absenceId: number) {
    if (this.namespace === CalendarNamespace.ABSENCES) {
      this.calendarAbsencesLoadAbsencesAction([absenceId]);
    } else {
      this.loadAbsencesAction([absenceId]);
    }
  }

  private onCloseClick() {
    redirectToNamedParent(this);
  }

  public render() {
    return (
      <DialogAbsenceContainer
        absenceId={this.absenceId}
        initialState={this.initialState}
        onCloseClick={this.onCloseClick}
        onCreateAbsenceSuccess={this.onLoadAbsence}
        onUpdateAbsenceSuccess={this.onLoadAbsence}
        onDeleteAbsenceSuccess={this.onDeleteAbsence}
      />
    );
  }
}
