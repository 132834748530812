import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { dragAndDropNS, StoreState as DndStoreState } from 'src/store/drag-and-drop/store';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Icon from 'components/icons/Icon';
import { IconName } from 'components/icons/types';
import { DraggableSourcePayload, DropTargetPayload } from 'src/store/drag-and-drop/types';
import styles from './delete-box.css';
import DndKind from '../common/dnd/DndKind';

interface Events {
  onDrop: EventPayload<{
    source: DraggableSourcePayload,
    target: DropTargetPayload,
  }>
}

@Component
export default class DeleteBox extends TsxComponent<{}, Events> {
  private isDragOverActive = false;

  @dragAndDropNS.State('payload')
  protected draggableItem: DndStoreState['payload'];

  private get isStaffShiftDragged() {
    return this.draggableItem?.kind === DndKind.STAFF_SHIFT;
  }

  private get isActive() {
    return this.draggableItem
      && [DndKind.STAFF_SHIFT, DndKind.SHIFT].includes(this.draggableItem.kind);
  }

  private onDrop(e: SyntheticEvent<HTMLElement, DragEvent>) {
    if (!this.isActive) {
      return;
    }

    this.$emit(
      'drop',
      createEventPayload(e, {
        target: { kind: DndKind.DELETE_BOX },
        source: this.draggableItem,
      }),
    );
  }

  protected onDragOver(e: DragEvent) {
    e.preventDefault();
  }

  protected onDragEnter(e: DragEvent) {
    if (!this.isActive) {
      return;
    }

    e.stopPropagation();
    this.isDragOverActive = true;
  }

  protected onDragLeave(e: DragEvent) {
    if (!this.isActive) {
      return;
    }

    e.stopPropagation();
    this.isDragOverActive = false;
  }

  public render() {
    return (
      <div
        class={{
          [styles.deleteBox]: true,
          [styles.deleteBoxDropActive]: this.isActive,
          [styles.deleteBoxDragOverActive]: this.isDragOverActive,
        }}
        onDragover={this.onDragOver}
        onDragenter={this.onDragEnter}
        onDragleave={this.onDragLeave}
        onDrop={this.onDrop}>
        <Icon name={IconName.DELETE}/>
        {' '}
        <span>
          {
            this.isStaffShiftDragged
              ? this.$t('calendar.deleteBox.labelUnassign')
              : this.$t('calendar.deleteBox.labelDelete')
          }
        </span>
      </div>
    );
  }
}
