import { isSameSecond } from 'date-fns';
import { differenceBy, differenceWith } from 'lodash';
import RootStoreState from 'store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { Action as StoreAction } from 'store/normalized-store';
import { getItemsOverlappingInterval, isStartBeforeEnd } from 'utils/date-related';
import { StoreActionResult, StoreActionState } from 'utils/store';
import Action from './Action';
import Mutation from './Mutation';

export const sectionBreaksNS = namespace('evaluationsForm/sectionBreaks');

export const sectionBreaksEmploymentNS = namespace('evaluationsForm/sectionBreaksEmployment');

export type IsValidFunction = (
  evaluationStartsAt: Date,
  evaluationEndsAt: Date,
  timeZone: string,
) => boolean;

export interface Break {
  id: number;
  endsAt: Date;
  startsAt: Date;
}

export interface StoreState {
  createIdCounter: number;
  breaks: Break[];
  initial: Break[];
}

const getSectionBreaksStore = (
  isStakeholder?: boolean,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    createIdCounter: Number.MIN_SAFE_INTEGER,
    breaks: [],
    initial: [],
  },
  mutations: {
    [Mutation.SET_BREAKS]: (state, breaks: Break[]) => {
      state.breaks = breaks;
    },
    [Mutation.SET_CREATE_ID_COUNTER]: (state, number: number) => {
      state.createIdCounter = number;
    },
    [Mutation.SET_INITIAL]: (state, initial: Break[]) => {
      state.initial = initial;
    },
  },
  getters: {
    isValid: state => (evaluationStartsAt: Date, evaluationEndsAt: Date, timeZone: string) => {
      const filtered = state.breaks.filter(item => (
        isStartBeforeEnd(item.startsAt, item.endsAt)
          && isStartBeforeEnd(evaluationStartsAt, item.startsAt, true)
          && isStartBeforeEnd(item.endsAt, evaluationEndsAt, true)
      ));

      if (state.breaks.length !== filtered.length) {
        return false;
      }

      return filtered.every((item, index) => {
        const otherBreaks = [...filtered];
        otherBreaks.splice(index, 1);

        return getItemsOverlappingInterval(
          otherBreaks,
          item.startsAt,
          item.endsAt,
          timeZone,
        ).length === 0;
      });
    },
  },
  actions: {
    [Action.SET_BREAKS]({ commit }, breaks: Break[]) {
      commit(Mutation.SET_BREAKS, breaks);
      commit(Mutation.SET_INITIAL, breaks);
    },
    [Action.ADD_BREAK]({ commit, state }, newBreak: Omit<Break, 'id'>) {
      commit(Mutation.SET_BREAKS, [
        ...state.breaks,
        {
          ...newBreak,
          id: state.createIdCounter,
        },
      ]);
      commit(Mutation.SET_CREATE_ID_COUNTER, state.createIdCounter + 1);
    },
    [Action.DELETE_BREAK]({ commit, state }, breakId: number) {
      commit(Mutation.SET_BREAKS, state.breaks.filter(it => it.id !== breakId));
    },
    [Action.UPDATE_BREAK]({ commit, state }, updatedBreak: Break) {
      commit(
        Mutation.SET_BREAKS,
        state.breaks
          .map((it) => {
            if (it.id === updatedBreak.id) {
              return updatedBreak;
            }

            return it;
          }),
      );
    },
    async [Action.DISPATCH_REMOVE](
      { dispatch, state },
      staffShiftId: number,
    ): Promise<StoreActionResult> {
      const toRemove = differenceBy(state.initial, state.breaks, 'id');

      if (!toRemove.length) {
        return { state: StoreActionState.SUCCESS };
      }

      const results: StoreActionResult[] = await Promise.all(
        toRemove.map(item => dispatch(
          isStakeholder
            ? `evaluationBreaks/${StoreAction.DELETE}`
            : `employmentEvaluationBreaks/${StoreAction.DELETE}`,
          {
            id: item.id,
            staffShiftId,
            evaluationBreakId: item.id,
          },
          { root: true },
        )),
      );

      return results.some(result => result.state !== StoreActionState.SUCCESS)
        ? { state: StoreActionState.ERROR }
        : { state: StoreActionState.SUCCESS };
    },

    async [Action.DISPATCH_CREATE](
      { dispatch, state },
      staffShiftId: number,
    ): Promise<StoreActionResult> {
      const toCreate = state.breaks.filter(it => it.id < 0);

      if (!toCreate.length) {
        return { state: StoreActionState.SUCCESS };
      }

      const results: StoreActionResult[] = await Promise.all(
        toCreate.map(item => dispatch(
          isStakeholder
            ? `evaluationBreaks/${StoreAction.CREATE}`
            : `employmentEvaluationBreaks/${StoreAction.CREATE}`,
          {
            staffShiftId,
            evaluationBreak: {
              startsAt: item.startsAt,
              endsAt: item.endsAt,
            },
          },
          { root: true },
        )),
      );

      return results.some(result => result.state !== StoreActionState.SUCCESS)
        ? { state: StoreActionState.ERROR }
        : { state: StoreActionState.SUCCESS };
    },

    async [Action.DISPATCH_UPDATE](
      { dispatch, state },
      staffShiftId: number,
    ): Promise<StoreActionResult> {
      const toUpdate = state.initial.length
        ? differenceWith(state.breaks, state.initial, (x, y) => {
          const isSameId = x.id === y.id;
          const isSamePeriod = isSameSecond(x.startsAt, y.startsAt)
            && isSameSecond(x.endsAt, y.endsAt);

          return (isSameId && isSamePeriod) || x.id < 0;
        })
        : [];

      if (!toUpdate.length) {
        return { state: StoreActionState.SUCCESS };
      }

      const results: StoreActionResult[] = await Promise.all(
        toUpdate.map(item => dispatch(
          isStakeholder
            ? `evaluationBreaks/${StoreAction.UPDATE}`
            : `employmentEvaluationBreaks/${StoreAction.UPDATE}`,
          {
            staffShiftId,
            evaluationBreakId: item.id,
            evaluationBreak: {
              startsAt: item.startsAt,
              endsAt: item.endsAt,
            },
          },
          { root: true },
        )),
      );

      return results.some(result => result.state !== StoreActionState.SUCCESS)
        ? { state: StoreActionState.ERROR }
        : { state: StoreActionState.SUCCESS };
    },
  },
});

export default getSectionBreaksStore;
