import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { shiftPresetsNS } from 'components/table/store/Store';
import type { SetSelectionFunction } from 'components/table/store/Store';
import TableAction from 'components/table/store/Action';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import Calendar from 'components/calendar/Calendar';
import { FiltersMap } from 'components/calendar-common/filters/Store';
import { dayNotesNS } from 'store/day-notes/Store';
import type { FetchAllDayNotesFunction } from 'store/day-notes/Store';
import { Action as StoreAction } from 'store/normalized-store';
import { Shiftplan, shiftplansNS } from 'store/shiftplans/Store';
import type { GetById } from 'utils/store';
import type { FetchAllShiftPresetsFunction } from 'src/store/shift-presets/Store';
import { shiftScheduleNS, StoreState as CalendarStoreState } from '../store/Store';
import { tagsFilterNS, StoreState as TagsFilterStoreState } from '../filter-box-tag/store/Store';
import { StoreState as LocationsPositionsFilterStoreState, locationsPositionsFilterNS } from '../filter-box-locations-position/store/Store';
import { employmentFilterNS, StoreState as EmploymentsFilterStoreState } from '../filter-box-employment/store/Store';
import { StoreState as AssignmentGroupsFilterStoreState, assignmentGroupsFilterNS } from '../filter-box-assignment-group/store/Store';
import { quickFilterNS, StoreState as QuickFilterFilterStoreState } from '../filter-box-quick/store/Store';
import { StoreState as ShiftPresetsFilterStoreState, shiftPresetsFilterNS } from '../filter-box-shift-preset/store/Store';
import { HighlightableEventTypes } from '../../calendar-common/highlights/Store';
import { shiftRotationGroupsFilterNS, StoreState as ShiftRotationGroupsStoreState } from '../filter-box-shift-rotation-group/store/Store';

import styles from './calendar-container.css';

@Component
export default class CalendarContainer extends TsxComponent<{
  shiftplanId: number;
}> {
  @authNS.State
  private currentEmployment: AuthStoreState['currentEmployment'];

  @shiftScheduleNS.State
  private slotDisplayStyle: CalendarStoreState['slotDisplayStyle'];

  @authNS.State
  private currentCompanyId: AuthStoreState['currentCompanyId'];

  @authNS.State
  private currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  private currentLocationId: AuthStoreState['currentLocationId'];

  @shiftPresetsNS.Action(StoreAction.FETCH_ALL)
  private fetchAllShiftPresets: FetchAllShiftPresetsFunction;

  @shiftScheduleNS.State
  private timeframe: CalendarStoreState['timeframe'];

  @shiftScheduleNS.State
  private timeframeKind: CalendarStoreState['timeframeKind'];

  @shiftScheduleNS.State
  private viewKind: CalendarStoreState['viewKind'];

  @shiftplansNS.Getter('items')
  protected shiftplans: Shiftplan[];

  @shiftplansNS.Getter('getById')
  protected getShiftplanById: GetById<Shiftplan>;

  @tagsFilterNS.State('selection')
  protected tagsSelection: TagsFilterStoreState['selection'];

  @locationsPositionsFilterNS.State('selection')
  protected locationsPositionsSelection: LocationsPositionsFilterStoreState['selection'];

  @assignmentGroupsFilterNS.State('selection')
  protected assignmentGroupsSelection: AssignmentGroupsFilterStoreState['selection'];

  @assignmentGroupsFilterNS.Action(TableAction.SET_SELECTION)
  protected setAssignmentGroupsSelection: SetSelectionFunction;

  @employmentFilterNS.State('selection')
  protected employmentsSelection: EmploymentsFilterStoreState['selection'];

  @employmentFilterNS.Action(TableAction.SET_SELECTION)
  protected setEmploymentsSelection: SetSelectionFunction;

  @quickFilterNS.State('filters')
  protected quickFilters: QuickFilterFilterStoreState['filters'];

  @shiftPresetsFilterNS.State('selection')
  protected shiftPresetsSelection: ShiftPresetsFilterStoreState['selection'];

  @shiftRotationGroupsFilterNS.State('selection')
  protected shiftRotationGroupsSelection: ShiftRotationGroupsStoreState['selection'];

  @dayNotesNS.Action(StoreAction.CLEAR)
  protected clearDayNotes: Function;

  @dayNotesNS.Action(StoreAction.FETCH_ALL)
  protected fetchAllDayNotes: FetchAllDayNotesFunction;

  @Prop()
  public shiftplanId: number;

  /*
    We need to load some of required data before initialising calendar
  */
  private get isInitialLoadComplete() {
    return this.shiftplan
      && this.shiftplans.length > 0
      && this.currentEmployment !== undefined
      && this.currentCompany !== undefined;
  }

  @Watch('quickFilters', { immediate: true })
  protected onQuickFiltersChange(now: FiltersMap, prev?: FiltersMap[]) {
    if (
      this.shiftplan && now.includes(FiltersMap.DAY_NOTES) && !prev?.includes(FiltersMap.DAY_NOTES)
    ) {
      this.fetchAllDayNotes({ shiftplanId: this.shiftplan.id });
    }
  }

  @Watch('shiftplan')
  protected onShiftplanChange() {
    if (!this.shiftplan) {
      return;
    }

    this.clearDayNotes();

    if (this.quickFilters.includes(FiltersMap.DAY_NOTES)) {
      this.fetchAllDayNotes({ shiftplanId: this.shiftplan.id });
    }
  }

  public get shiftplan() {
    if (!this.currentCompanyId || !this.currentLocationId) {
      return null;
    }

    const shiftplan = this.getShiftplanById(this.shiftplanId);

    if (!shiftplan) {
      return null;
    }

    return {
      ...shiftplan,
      locationId: this.currentLocationId,
      companyId: this.currentCompanyId,
    };
  }

  private onClearHighlight() {
    this.setAssignmentGroupsSelection([]);
    this.setEmploymentsSelection([]);
  }

  private onSetHighlightType({ payload: type }: { payload: HighlightableEventTypes }) {
    if (type !== HighlightableEventTypes.AssignmentGroup) {
      this.setAssignmentGroupsSelection([]);
    }

    if (type !== HighlightableEventTypes.Employment) {
      this.setEmploymentsSelection([]);
    }

    // TODO: add rotationGroupsSelection
  }

  public mounted() {
    this.fetchAllShiftPresets({
      companyId: this.currentCompanyId,
    });
  }

  public render() {
    return (
      <div class={[styles.calendarContainer]}>
        <Calendar
          assignmentGroupIds={this.assignmentGroupsSelection}
          employmentIds={this.employmentsSelection}
          filters={this.quickFilters}
          isInitialDataLoading={!this.isInitialLoadComplete}
          locationsPositionIds={this.locationsPositionsSelection}
          shiftplan={this.shiftplan}
          shiftPresetIds={this.shiftPresetsSelection}
          shiftRotationGroupIds={this.shiftRotationGroupsSelection}
          slotDisplayStyle={this.slotDisplayStyle}
          tagIds={this.tagsSelection}
          timeframe={this.timeframe}
          timeframeKind={this.timeframeKind}
          viewKind={this.viewKind}
          onClearHightlight={this.onClearHighlight}
          onSetHighlightType={this.onSetHighlightType}
        />
      </div>
    );
  }
}
