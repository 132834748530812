import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Tooltip from 'components/tooltip/Tooltip';
import { ButtonKind } from 'components/form/base-button/types';
import Button from 'components/form/button/Button';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import styles from './button-calendar-export.css';
import { Route } from '../routes';

interface Props {
  shiftplanId: number;
}

@Component
class ButtonCalendarExport extends TsxComponent<Props> {
  @Prop()
  public shiftplanId: number;

  protected onExportButtonClick() {
    if (!this.shiftplanId) {
      return;
    }

    this.$router.push({
      name: Route.EXPORT_CALENDAR,
      params: {
        shiftplanId: this.shiftplanId.toString(),
      },
    });
  }

  public render() {
    return (
      <Tooltip
        class={styles.buttonTooltip}
        text={this.$t('shiftSchedule.calendarExport.exportTooltip')}
      >
        <Button
          kind={ButtonKind.GHOST}
          icon={IconName.DOWNLOAD}
          aria-label={this.$t('shiftSchedule.calendarExport.exportTooltip')}
          onClick={() => this.onExportButtonClick()}
          iconPosition={IconPosition.ALONE}
        />
      </Tooltip>
    );
  }
}

export default ButtonCalendarExport;
