import { Size } from 'components/types';
import styles from './base-button.css';

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum ButtonKind {
  FILL = 'fill',
  GHOST = 'ghost',
  STROKE = 'stroke',
}

export const BUTTON_SIZES = [
  Size.XXSMALL,
  Size.XSMALL,
  Size.SMALL,
  Size.MEDIUM,
  Size.LARGE,
  Size.XLARGE,
] as const;

export type ButtonSize = typeof BUTTON_SIZES[number]

export interface BaseButtonProps {
  color?: ButtonColor;
  kind?: ButtonKind;
  size?: ButtonSize;
}

export const BUTTON_SIZE_MAP: Record<ButtonSize, string> = {
  [Size.XLARGE]: styles.buttonXLarge,
  [Size.LARGE]: styles.buttonLarge,
  [Size.MEDIUM]: styles.buttonMedium,
  [Size.SMALL]: styles.buttonSmall,
  [Size.XSMALL]: styles.buttonXSmall,
  [Size.XXSMALL]: styles.buttonXxSmall, // Only used for icon only buttons
};

export const BUTTON_KIND_MAP: Record<ButtonKind, string> = {
  [ButtonKind.FILL]: styles.buttonFill,
  [ButtonKind.STROKE]: styles.buttonStroke,
  [ButtonKind.GHOST]: styles.buttonGhost,
};

export const BUTTON_COLOR_MAP: Record<ButtonColor, string> = {
  [ButtonColor.PRIMARY]: styles.buttonPrimary,
  [ButtonColor.SECONDARY]: styles.buttonSecondary,
  [ButtonColor.INFO]: styles.buttonInfo,
  [ButtonColor.WARNING]: styles.buttonWarning,
  [ButtonColor.ERROR]: styles.buttonError,
  [ButtonColor.SUCCESS]: styles.buttonSuccess,
};
