import DateItem from 'components/calendar-common/common/DateItem';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { Route } from 'components/dialog-shift/routes';
import { addHours } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { Component, Vue } from 'vue-property-decorator';
import { Mode } from '../Enums';

@Component
export default class ShiftViewActionsMixin extends Vue {
  @calendarCommonNS.Getter
  public mode: Mode;

  protected get isStakeholder() {
    return this.mode === Mode.STAKEHOLDER;
  }

  public onShiftCellClick(dateItem: DateItem, employmentId?: number, locationsPositionId?: number) {
    if (this.isStakeholder && dateItem.isWithinShiftplan) {
      const startsAt = addHours(toDate(dateItem.dateKey, { timeZone: this.$timeZone.value }), 9);
      const endsAt = addHours(toDate(dateItem.dateKey, { timeZone: this.$timeZone.value }), 17);

      const query = {
        endsAt: endsAt.toISOString(),
        startsAt: startsAt.toISOString(),
        employmentId: employmentId?.toString(),
        locationsPositionId: locationsPositionId?.toString(),
      };

      this.$router.push({
        name: Route.DIALOG_NEW,
        params: {
          shiftplanId: this.$route.params.shiftplanId,
        },
        query,
      });
    }
  }
}
