import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { IconName } from 'components/icons/types';

export enum DropdownItemKind {
  BUTTON = 'button',
  LINK = 'link',
  ROUTER_LINK = 'routerLink',
  RULER = 'ruler',
  TEXT = 'text',
  SUBTITLE = 'subtitle'
}

export interface DropdownItemLink {
  class?: string | Record<string, boolean>;
  href?: string;
  label: string | JSX.Element;
  type: DropdownItemKind.LINK;
  icon?: IconName;
}

export interface DropdownItemRouterLink {
  class?: string | Record<string, boolean>;
  route: string;
  params?: Record<string, string | undefined>;
  label: string | JSX.Element;
  type: DropdownItemKind.ROUTER_LINK;
  icon?: IconName;
}

export interface DropdownItemButton {
  class?: string;
  disabled?: boolean;
  label: string | JSX.Element;
  onClick: (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => void | Promise<void>;
  type: DropdownItemKind.BUTTON;
  icon?: IconName;
}

export interface DropdownItemRuler {
  type: DropdownItemKind.RULER;
  label?: string | JSX.Element;
}

export interface DropdownItemText {
  type: DropdownItemKind.TEXT;
  label?: string | JSX.Element;
}
export interface DropdownItemSubtitle {
  type: DropdownItemKind.SUBTITLE;
  label?: string | JSX.Element;
}

export type DropdownItem = DropdownItemLink
| DropdownItemRouterLink
| DropdownItemButton
| DropdownItemRuler
| DropdownItemText
| DropdownItemSubtitle;
