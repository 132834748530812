import {
  GQLPaygradeLevel,
  GQLPaymentsCalculationPaymentInput,
} from 'codegen/gql-types';
import { WithId } from 'src/store/normalized-store';
import { PaymentsCalculation } from 'src/store/payments-calculation/Store';
import { Payment } from 'src/store/evaluation-payments/Store';
import { PartialBy } from 'utils/utils';
import { Pay } from '../../../dialog-shift/paygrades/Section';

export const transformToPay = (payment: PartialBy<Payment, 'staffShiftId' | 'childPayments'>, parentPaymentId?: number, isCreated?: boolean): Pay => ({
  id: payment.id,
  isCreated,
  isLocked: true,
  level: parentPaymentId
    ? GQLPaygradeLevel.PAYGRADE
    : (payment.inheritedFrom || GQLPaygradeLevel.SHIFT),
  parentId: parentPaymentId,
  typeId: payment.paygradeType.id,
  total: payment.sumWithShift || 0,
  value: payment.value,
  children: payment.childPayments?.map(o => transformToPay(o, payment.id, isCreated)) || [],
});

export const transformToCalculationPaymentInput = (pay: Pay[]) => (
  pay.map<GQLPaymentsCalculationPaymentInput>(payment => ({
    childPayments: transformToCalculationPaymentInput(payment.children),
    paygradeTypeId: payment.typeId,
    value: payment.value || 0,
  }))
);

export const mergeCalculationsWithPay = (
  calculations: PartialBy<NonNullable<PaymentsCalculation['payments']>[0], 'childPayments'>[],
  pay: Pay[],
) => (calculations || []).reduce((prev, cur, index) => {
  prev[index].total = cur.totalValue;

  if (cur.childPayments) {
    prev[index].children = mergeCalculationsWithPay(cur.childPayments, prev[index].children);
  }

  return prev;
}, pay);

interface WithIdAndChildren extends WithId {
  children: WithIdAndChildren[];
}

export function mergeSourceWithTargetTree<T extends WithIdAndChildren>(source: T[], target: T[]) {
  return source.reduce((prev, cur) => {
    const index = prev.findIndex(({ id }) => cur.id === id);

    if (index < 0) {
      return prev;
    }

    const { children } = prev[index];

    prev[index] = {
      ...prev[index],
      ...cur,
      children: mergeSourceWithTargetTree(cur.children, children),
    };

    return prev;
  }, [...target]);
}

export function filterDeletedDeep<T extends WithIdAndChildren & { isDeleted?: boolean }>(
  tree: T[],
): T[] {
  return tree
    .filter(node => !node.isDeleted)
    .map(node => ({ ...node, children: filterDeletedDeep(node.children) }));
}
