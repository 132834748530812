import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Tag } from 'src/store/tags/Store';
import InputPillSelect from 'components/form/input-pill-select/InputPillSelect';
import { EventPayload } from 'src/utils/events';
import FormSection from 'components/dialog-shift/form-section/FormSection';

import styles from './section-shift-evaluation-tags.css';

export interface Props {
  selectedTagIds: number[];
  isDisabled?: boolean;
  availableTags: Tag[];
}

interface Events {
  onChange: (
    e: EventPayload<string[], HTMLButtonElement | HTMLInputElement, MouseEvent | InputEvent>
  ) => void;
}

@Component
class SectionShiftEvaluationTags extends TsxComponent<Props, Events> {
  @Prop()
  public selectedTagIds: Props['selectedTagIds'];

  @Prop()
  public isDisabled?: Props['isDisabled'];

  @Prop()
  public availableTags: Props['availableTags'];

  private get options() {
    return this.availableTags.map(
      ({ id, name, color }) => ({
        value: id.toString(),
        label: this.$t(`shifts.evaluations.tags.${name}`),
        color,
      }),
    );
  }

  private get placeholder() {
    return this.$t(
      this.isDisabled
        ? 'shifts.dialog.placeholderNoTags'
        : 'shifts.dialog.placeholderAddTags',
    );
  }

  protected onChange(
    e: EventPayload<string[], HTMLButtonElement | HTMLInputElement, MouseEvent | InputEvent>,
  ) {
    this.$emit('change', e);
  }

  public render() {
    return (
      <FormSection
        class={styles.sectionShiftEvaluationTagsRow}
        heading={this.$t('shifts.dialog.headingShiftEvaluationTags')}
      >
        <InputPillSelect
          label={this.$t('shifts.dialog.labelShiftEvaluationTags')}
          class={styles.sectionShiftEvaluationTagsRowFullWidth}
          isDisabled={this.isDisabled}
          onChange={this.onChange}
          options={this.options}
          placeholder={this.placeholder}
          value={this.selectedTagIds.map(id => id.toString())}
        />
      </FormSection>
    );
  }
}

export default SectionShiftEvaluationTags;
