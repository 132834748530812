import { GQLStaffShiftState } from 'codegen/gql-types';
import type { GQLStaffShift } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import styles from './icon-state.css';

const styleMap: Record<GQLStaffShiftState, string> = {
  [GQLStaffShiftState.DONE_EVALUATION]: styles.iconStateGreen,
  [GQLStaffShiftState.NO_EVALUATION]: styles.iconStateGreen,
  [GQLStaffShiftState.PUNCHTIMED]: styles.iconStateGreen,
  [GQLStaffShiftState.NEEDS_EVALUATION]: styles.iconStateOrange,
  [GQLStaffShiftState.NO_SHOW]: styles.iconStateGreen,
};

const iconMap: Record<GQLStaffShiftState, IconName> = {
  [GQLStaffShiftState.DONE_EVALUATION]: IconName.DONE,
  [GQLStaffShiftState.NO_EVALUATION]: IconName.DONE,
  [GQLStaffShiftState.PUNCHTIMED]: IconName.CLOCK,
  [GQLStaffShiftState.NEEDS_EVALUATION]: IconName.INFO,
  [GQLStaffShiftState.NO_SHOW]: IconName.DONE,
};

@Component
export default class IconState extends TsxComponent<{
  staffShift: Pick<GQLStaffShift, 'state'>;
}> {
  @Prop()
  public staffShift: Pick<GQLStaffShift, 'state'>;

  public render() {
    if (this.staffShift.state in styleMap && this.staffShift.state in iconMap) {
      return (
        <Icon
          class={[styles.iconState, styleMap[this.staffShift.state]]}
          name={iconMap[this.staffShift.state]}
          size={Size.SMALL}
        />
      );
    }

    // FAQ: should not happen, just a safety net
    return null;
  }
}
