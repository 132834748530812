import {
  Component, Watch,
} from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { AlertKind } from 'components/alert/Alert';
import Action from './store/Action';
import { snackbarNS } from './store/Store';
import type { SnackbarContent } from './types';
import Snackbar from './Snackbar';

export const BUFFERTIME_BETWEEN_NOTIFICATIONS = 300;

@Component
class GlobalSnackbarContainer extends TsxComponent<{}> {
  private isChanging = false;

  private timer: number | null = null;

  @snackbarNS.State
  private content: SnackbarContent;

  @snackbarNS.State
  private isVisible: boolean;

  @Watch('content')
  protected onContentChange() {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }

    this.isChanging = true;
    this.timer = window.setTimeout(() => {
      this.isChanging = false;
      this.initTimeout();
    }, BUFFERTIME_BETWEEN_NOTIFICATIONS);
  }

  @snackbarNS.Action(Action.HIDE)
  private hide: () => void;

  public beforeDestroy() {
    this.hide();
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
  }

  public mounted() {
    this.initTimeout();
  }

  private initTimeout() {
    if (this.content.timeout) {
      this.timer = window.setTimeout(() => {
        this.hide();
        this.timer = null;
      }, this.content.timeout);
    } else {
      this.timer = null;
    }
  }

  private get isSnackbarShown() {
    return this.isVisible && !this.isChanging;
  }

  public render() {
    return (
      <Snackbar
        kind={this.content.kind || AlertKind.SUCCESS}
        title={this.content.message}
        isOpen={this.isSnackbarShown}
        onClose={this.content.onCloseAction}
      />
    );
  }
}

export default GlobalSnackbarContainer;
