import { ObservableQuery } from 'apollo-client';
import {
  GQLCalendarAbsencesQuery,
  GQLCalendarAbsencesQueryVariables,
  GQLEmploymentsQuery,
  GQLAbsencesEventsQueryVariables,
  GQLAbsencesEventsQuery,
  GQLAbsencesDataQuery,
  GQLAbsencesDataQueryVariables,
} from 'codegen/gql-types';
import DefaultActions from 'components/calendar-common/DefaultActions';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import AbsencesGql from 'components/calendar-common/data/queries/CalendarAbsences.gql';
import EmploymentsGql from 'components/calendar-common/data/queries/CalendarEmployments.gql';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { SentryTag } from 'services/logger/SentryTransport';
import DataGql from './queries/AbsencesData.gql';
import EventsGql from './queries/AbsencesEvents.gql';
import Mutations from './Mutations';
import * as Actions from './Actions';
import { CalendarNamespace, LoadState, Mode } from '../../calendar-common/Enums';
import { GraphqlClient } from '../../../services/graphql-client/GraphqlClientFactory';
import RootStoreState from '../../../store/RootStoreState';
import { deepTransformDates } from '../../../services/graphql-client/DatesTransformLink';

export const calendarAbsencesDataNS = namespace('calendarAbsences/data');

export interface StoreState {
  loadState: LoadState;
  dataQuery: ObservableQuery<GQLAbsencesDataQuery, GQLAbsencesDataQueryVariables> | null;
  eventsQueryRequestUid: string;
  dataQueryRequestUid: string;
}

const INVALIDATED_REQUEST_UID = NIL_UUID;

export const store = (
  client: GraphqlClient,
  logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    loadState: LoadState.NOT_LOADED,
    dataQuery: null,
    eventsQueryRequestUid: INVALIDATED_REQUEST_UID,
    dataQueryRequestUid: INVALIDATED_REQUEST_UID,
  },
  mutations: {
    [Mutations.SET_LOAD_STATE](state, loadState: LoadState) {
      state.loadState = loadState;
    },
    [Mutations.SET_DATA_QUERY](state, query) {
      state.dataQuery = query;
    },
    [Mutations.SET_EVENTS_QUERY_REQUEST_UID](state, uid: string) {
      state.eventsQueryRequestUid = uid;
    },
    [Mutations.SET_DATA_QUERY_REQUEST_UID](state, uid: string) {
      state.dataQueryRequestUid = uid;
    },
  },
  getters: {
    currentInterval(state, getters, rootState, rootGetters) {
      return rootGetters[`${CalendarNamespace.ABSENCES}/common/currentInterval`];
    },
    locationsPositionIds(state, getters, rootState, rootGetters) {
      const locationsPositionIds = rootGetters[
        `${CalendarNamespace.ABSENCES}/filters/locationsPositionIds`
      ];
      return !Array.isArray(locationsPositionIds) || locationsPositionIds.length === 0
        ? undefined
        : locationsPositionIds;
    },
    shiftRotationGroupIds(state, getters, rootState, rootGetters) {
      const shiftRotationGroupIds = rootGetters[
        `${CalendarNamespace.ABSENCES}/filters/shiftRotationGroupIds`
      ];
      return shiftRotationGroupIds === null
        ? undefined
        : shiftRotationGroupIds;
    },
    employmentsSearchQuery(state, getters, rootState) {
      const { searchQuery } = rootState.calendarAbsences.employments;
      const trimmedQuery = searchQuery.trim();
      return trimmedQuery.length === 0 ? undefined : trimmedQuery;
    },
  },
  actions: {
    async [Actions.LOAD_DATA]({
      dispatch, commit, state, rootState, getters,
    }) {
      if (!rootState.auth.currentCompany
        || !rootState.auth.currentLocationId) {
        return;
      }

      const {
        currentLocationId,
        currentCompany: {
          id: currentCompanyId,
          shiftRotationEnabled: isShiftRotationsEnabled,
        },
      } = rootState.auth;
      const { employmentsSearchQuery, locationsPositionIds } = getters;

      const variables: GQLAbsencesDataQueryVariables = {
        companyId: currentCompanyId,
        locationId: currentLocationId,
        search: employmentsSearchQuery,
        locationsPositionIds,
        shouldFetchShiftRotationGroupIds: !!isShiftRotationsEnabled,
      };

      const requestUid: string = uuidv4();

      // mark ongoing requests as stale
      commit(Mutations.SET_EVENTS_QUERY_REQUEST_UID, INVALIDATED_REQUEST_UID);
      commit(Mutations.SET_DATA_QUERY_REQUEST_UID, requestUid);
      commit(Mutations.SET_LOAD_STATE, LoadState.LOADING);

      const result = await client.query<GQLAbsencesDataQuery, GQLAbsencesDataQueryVariables>(
        {
          variables,
          query: DataGql,
        },
      );

      // check if request is stale
      if (requestUid !== state.dataQueryRequestUid) {
        return;
      }
      const {
        employments: { items: employments },
      } = result.data;
      dispatch(
        `calendarAbsences/employments/${DefaultActions.SET}`,
        employments,
        { root: true },
      );

      dispatch(Actions.LOAD_EVENTS);
    },

    async [Actions.LOAD_EVENTS](
      {
        dispatch,
        commit,
        rootState,
        rootGetters,
        getters,
        state,
      },
    ) {
      if (!rootState.auth.currentCompany
        || !rootState.auth.currentLocationId) {
        return;
      }

      const {
        currentLocationId,
        currentCompany: {
          id: currentCompanyId,
          shiftRotationEnabled: isShiftRotationsEnabled,
        },
      } = rootState.auth;

      const mode = rootGetters['calendarAbsences/common/mode'];

      const {
        currentInterval,
        employmentsSearchQuery,
        locationsPositionIds,
        shiftRotationGroupIds,
      } = getters;

      const { showSummary } = rootGetters['calendarAbsences/filters/filters'];
      const employments = rootGetters['calendarAbsences/employments/employments'];
      const { start, end }: { start: Date; end: Date } = currentInterval;

      const employmentIds = employmentsSearchQuery
        ? employments.map(it => it.id)
        : undefined;

      if ((employmentIds && !employmentIds.length)) {
        return;
      }

      const variables: GQLAbsencesEventsQueryVariables = deepTransformDates({
        companyId: currentCompanyId,
        locationId: currentLocationId,
        startsAt: start,
        endsAt: end,
        employmentIds,
        locationsPositionIds,
        shiftRotationGroupIds,
        shouldFetchShiftRotationGroupIds: !!isShiftRotationsEnabled,
        withSummary: (mode === Mode.STAKEHOLDER) && showSummary,
      });

      const requestUid: string = uuidv4();
      commit(Mutations.SET_EVENTS_QUERY_REQUEST_UID, requestUid);
      commit(Mutations.SET_LOAD_STATE, LoadState.LOADING);
      const result = await client.query<GQLAbsencesEventsQuery, GQLAbsencesEventsQueryVariables>(
        {
          variables,
          query: EventsGql,
        },
      );

      // check if request is stale
      if (requestUid !== state.eventsQueryRequestUid) {
        return;
      }

      const {
        absences: { items: absences = [] } = {},
        calendarAggregations,
      } = result.data;
      dispatch(`calendarAbsences/absences/${DefaultActions.SET}`, absences, { root: true });

      if (showSummary) {
        dispatch(`calendarAbsences/aggregations/${DefaultActions.SET}`, calendarAggregations, { root: true });
      }

      // wait for render cycle to complete before removing placeholder
      Vue.nextTick(() => {
        commit(Mutations.SET_LOAD_STATE, LoadState.LOADED);
      });
    },

    async [Actions.LOAD_EMPLOYMENTS](
      { dispatch, rootState },
      employmentsIds: number[],
    ) {
      if (!rootState.auth.currentCompany
        || !rootState.auth.currentLocationId) {
        return;
      }

      const {
        currentLocationId,
        currentCompany: {
          id: currentCompanyId,
          shiftRotationEnabled: isShiftRotationsEnabled,
        },
      } = rootState.auth;

      try {
        const response = await client.query<GQLEmploymentsQuery>(
          {
            variables: {
              companyId: currentCompanyId,
              locationId: currentLocationId,
              ids: employmentsIds,
              shouldFetchShiftRotationGroupIds: isShiftRotationsEnabled,
            },
            query: EmploymentsGql,
          },
        );
        const {
          employments: { items: employments },
        } = response.data;
        dispatch(
          `calendarAbsences/employments/${DefaultActions.UPDATE}`,
          employments,
          { root: true },
        );
      } catch (e) {
        logger.instance.error({
          message: 'failed to update locations employments',
          tags: [[SentryTag.ACTION, Actions.LOAD_EMPLOYMENTS]],
          error: e,
        });
      }
    },
    async [Actions.LOAD_ABSENCES](
      { dispatch, rootState },
      absenceIds: number[],
    ) {
      if (!rootState.auth.currentCompany
        || !rootState.auth.currentLocationId) {
        return;
      }

      const {
        currentLocationId,
        currentCompany: {
          id: currentCompanyId,
          shiftRotationEnabled: isShiftRotationsEnabled,
        },
      } = rootState.auth;

      try {
        const response = await client.query<GQLCalendarAbsencesQuery,
        GQLCalendarAbsencesQueryVariables>(
          {
            variables: {
              companyId: currentCompanyId,
              locationId: currentLocationId,
              ids: absenceIds,
              shouldFetchShiftRotationGroupIds: isShiftRotationsEnabled,
            },
            query: AbsencesGql,
          },
        );
        const {
          absences: { items: absences },
        } = response.data;
        dispatch(
          `calendarAbsences/absences/${DefaultActions.UPDATE}`,
          absences,
          { root: true },
        );
      } catch (e) {
        logger.instance.error({
          message: 'failed to update absences',
          tags: [[SentryTag.ACTION, Actions.LOAD_ABSENCES]],
          error: e,
        });
      }
    },
  },
});
