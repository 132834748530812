import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { store as getAbsencesStore, StoreState as BaseStoreState } from 'components/calendar-common/absences/Store';
import {
  absencesByEmployments, getByDatesUnsorted,
} from 'components/calendar-common/absences/util';

export const calendarAbsencesAbsencesNS = namespace('calendarAbsences/absences');

export type StoreState = BaseStoreState;

export const store = (): Module<StoreState, RootStoreState> => {
  const baseStore = getAbsencesStore();
  return {
    ...baseStore,
    getters: {
      ...baseStore.getters,
      filteredAbsences(state, getters, rootState, rootGetters) {
        return getters.absences.filter(it => rootGetters['calendarAbsences/employments/filteredEmployments'].find(emp => emp.id === it.employment.id));
      },
      absencesByDatesUnsorted(state, getters, rootState, rootGetters) {
        return getByDatesUnsorted(rootGetters['calendarAbsences/common/dateKeys'], getters.filteredAbsences);
      },

      absencesByEmployments(state, getters, rootState, rootGetters) {
        return absencesByEmployments(
          rootGetters['calendarAbsences/employments/filteredEmployments'],
          getters.absencesByDatesUnsorted,
        );
      },
    },
  };
};
