import {
  GQLShiftFieldsFragment,
  GQLCalendarRemoveTagFromShiftMutation,
  GQLCalendarRemoveTagFromShiftMutationVariables,
  GQLCalendarAddTagToShiftMutation,
  GQLCalendarAddTagToShiftMutationVariables,
  GQLCalendarUpdateShiftMutation,
  GQLCalendarUpdateShiftMutationVariables,
  GQLCalendarDeleteShiftMutationVariables,
  GQLCalendarDeleteShiftMutation,
} from 'codegen/gql-types';
import { deleteItems, getUpdatedItems } from 'components/calendar-common/common/store/Helpers';
import DefaultActions from 'components/calendar-common/DefaultActions';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import moment from 'moment';
import RootStoreState from 'src/store/RootStoreState';
import { GraphqlClient } from 'services/graphql-client/GraphqlClientFactory';
import { PayloadParameter, StoreActionResult, StoreActionState } from 'src/utils/store';
import { isSuccessResult } from 'store/normalized-store';
import { LOAD_SHIFTS } from 'components/calendar/data/Actions';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import { FiltersDictionary } from 'components/calendar-common/filters/Store';
import { DateKey } from 'components/calendar-common/common/DateItem';
import Action from './Action';
import Mutation from './Mutation';
import Shift from '../Shift';
import StaffShift from '../StaffShift';
import CalendarAddTagToShift from '../queries/CalendarAddTagToShift.gql';
import CalendarRemoveTagFromShift from '../queries/CalendarRemoveTagFromShift.gql';
import CalendarDeleteShift from '../queries/CalendarDeleteShift.gql';
import CalendarUpdateShift from '../queries/CalendarUpdateShift.gql';
import { ShiftUpdateParams } from '../shift-params-helper/ShiftParamsHelper';
import {
  filterByBaseFilters,
  filterByLocationsPositions,
  filterShowOnlyMyShifts,
  getByDates,
  shiftsByEmployments,
  shiftsByPositions,
} from './util';
import { CalendarNamespace } from '../../Enums';

export const shiftsNS = namespace('calendar/shifts');
export const shiftsEmployeeViewNS = namespace('calendar/shifts/employeeView');
export const shiftsPositionsMonthViewNS = namespace('calendar/shifts/positionsMonthView');

export type AddTagFunction = (
  payload: {
    tagId: number;
    shiftId: number;
    refetch?: boolean;
  }
) => Promise<StoreActionResult>;

export type updateShiftFunction = (
  payload: {
    id: number;
    params: ShiftUpdateParams;
    refetch?: boolean;
  }
) => Promise<StoreActionResult>;

export type DeleteTagFunction = (
  payload: {
    tagId: number;
    shiftId: number;
    refetch?: boolean;
  }
) => Promise<StoreActionResult>;

export type DeleteShiftFunction = (
  payload: {
    id: number;
  }
) => Promise<StoreActionResult>;

export type MoveStaffShiftFunction = (
  payload: {
    employmentId: number;
    shiftId: number;
    sourceShiftId: number;
    refetch?: boolean;
  }
) => Promise<StoreActionResult>;

export interface StoreState {
  items: GQLShiftFieldsFragment[];
}

export const store = (
  client: GraphqlClient,
  // logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    [Mutation.SET_SHIFTS](state, items) {
      state.items = items;
    },
    [Mutation.DELETE_SHIFTS](state, ids: number[]) {
      state.items = deleteItems(state.items, ids);
    },
    [Mutation.UPDATE_SHIFTS](state, items) {
      state.items = getUpdatedItems(state.items, items);
    },
  },
  actions: {
    [DefaultActions.SET]({ commit }, items) {
      commit(Mutation.SET_SHIFTS, items);
    },
    [DefaultActions.UPDATE]({ commit }, items) {
      commit(Mutation.UPDATE_SHIFTS, items);
    },
    [DefaultActions.DELETE]({ commit }, ids) {
      const idsArray = Array.isArray(ids) ? ids : [ids];
      commit(Mutation.DELETE_SHIFTS, idsArray);
    },
    async [Action.DELETE_CONNECTED_SHIFTS]({ state, commit }, connectedGroupId) {
      const idsArray = state.items
        .filter(item => item.connectedGroupId === connectedGroupId)
        .map(item => item.id);
      commit(Mutation.DELETE_SHIFTS, idsArray);
    },
    async [Action.UPDATE_SHIFT](
      { getters, dispatch, rootState },
      { id, params, refetch = true }: PayloadParameter<updateShiftFunction>,
    ): Promise<StoreActionResult> {
      if (!rootState.auth.currentCompanyId) {
        return { state: StoreActionState.ERROR };
      }

      const shift: Shift = getters.shiftById(id);
      if (!shift) {
        return { state: StoreActionState.ERROR };
      }

      if (params.startsAt || params.endsAt) {
        const variables: GQLCalendarUpdateShiftMutationVariables = {
          id,
          shift: deepTransformDates({
            startsAt: params.startsAt ? params.startsAt.toDate() : shift.startsAt,
            endsAt: params.endsAt ? params.endsAt.toDate() : shift.endsAt,
            workers: shift.workers,
            companyId: rootState.auth.currentCompanyId,
            note: shift.note,
            canEvaluate: null,
            autoAccept: null,
            shiftplanId: shift.shiftplanId,
            locationsPositionId: params.locationsPositionId || 0,
            untimedBreakTime: 0,
            shiftPresetId: null,
            untimed: shift.untimed,
            managerNote: shift.managerNote,
            // FIXME at https://shyftplan.atlassian.net/browse/SP-622
            shiftRotationGroupIds: [],
            shiftQualifications: [],
            tagIds: [],
            evaluationTagIds: [],
            shiftBreaks: [],
            ignoreConflicts: true,
            updateConnected: false,
          }),
        };
        const result = await client.mutate<
        GQLCalendarUpdateShiftMutation,
        GQLCalendarUpdateShiftMutationVariables>({
          mutation: CalendarUpdateShift,
          variables,
        });

        if (result.errors?.length) {
          return {
            state: StoreActionState.ERROR,
            error: result.errors[0].extensions?.response,
          };
        }

        if (!isSuccessResult(result, 'updateShift')) {
          return { state: StoreActionState.ERROR };
        }
      }

      if (refetch) {
        dispatch(`calendar/data/${LOAD_SHIFTS}`, { shiftIds: [id] }, { root: true });
      }

      return {
        state: StoreActionState.SUCCESS,
        entityId: id,
      };
    },
    async [Action.DELETE_SHIFT](
      { commit, rootState },
      { id }: PayloadParameter<DeleteShiftFunction>,
    ) {
      if (!rootState.auth.currentCompanyId) {
        return { state: StoreActionState.ERROR };
      }

      const variables: GQLCalendarDeleteShiftMutationVariables = {
        id,
        companyId: rootState.auth.currentCompanyId,
        // TODO: add proper value when
        // implementing connected shifts logic to d'n'd
        deleteParams: {
          deleteConnected: false,
        },
      };

      const result = await client.mutate<
      GQLCalendarDeleteShiftMutation, GQLCalendarDeleteShiftMutationVariables>({
        mutation: CalendarDeleteShift,
        variables,
      });

      if (result.errors?.length) {
        return {
          state: StoreActionState.ERROR,
          error: result.errors[0].extensions?.response,
        };
      }

      if (!result.data?.deleteShift || !result.data?.deleteShift.success) {
        return { state: StoreActionState.ERROR };
      }

      commit(Mutation.DELETE_SHIFTS, [id]);

      return {
        state: StoreActionState.SUCCESS,
      };
    },
    async [Action.ADD_TAG](
      { rootState, dispatch },
      { shiftId, tagId, refetch = true }: PayloadParameter<AddTagFunction>,
    ): ReturnType<AddTagFunction> {
      const { currentCompanyId } = rootState.auth;
      if (!rootState.calendar.common.shiftplan || !currentCompanyId) {
        return {
          state: StoreActionState.ERROR,
        };
      }
      const {
        shiftplan: { locationId },
      } = rootState.calendar.common;

      const variables = {
        id: tagId,
        params: {
          shiftId,
          locationId,
          companyId: currentCompanyId,
        },
      };
      const result = await client.mutate<
      GQLCalendarAddTagToShiftMutation, GQLCalendarAddTagToShiftMutationVariables>({
        mutation: CalendarAddTagToShift,
        variables,
      });

      if (result.errors?.length) {
        return {
          state: StoreActionState.ERROR,
          error: result.errors[0].extensions?.response,
        };
      }

      if (!isSuccessResult(result, 'addTagToShift')) {
        return { state: StoreActionState.ERROR };
      }

      if (refetch) {
        dispatch(`calendar/data/${LOAD_SHIFTS}`, { shiftIds: [shiftId] }, { root: true });
      }

      return {
        state: StoreActionState.SUCCESS,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        entityId: result.data?.addTagToShift.id!,
      };
    },
    async [Action.DELETE_TAG](
      { rootState, dispatch },
      { shiftId, tagId, refetch = true }: PayloadParameter<DeleteTagFunction>,
    ) {
      const { currentCompanyId } = rootState.auth;
      if (!rootState.calendar.common.shiftplan || !currentCompanyId) {
        return {
          state: StoreActionState.ERROR,
        };
      }
      const {
        shiftplan: { locationId },
      } = rootState.calendar.common;

      const variables = {
        id: tagId,
        params: {
          shiftId,
          locationId,
          companyId: currentCompanyId,
        },
      };
      const result = await client.mutate<
      GQLCalendarRemoveTagFromShiftMutation, GQLCalendarRemoveTagFromShiftMutationVariables>({
        mutation: CalendarRemoveTagFromShift,
        variables,
      });

      if (result.errors?.length) {
        return {
          state: StoreActionState.ERROR,
          error: result.errors[0].extensions?.response,
        };
      }

      if (!isSuccessResult(result, 'removeTagFromShift')) {
        return { state: StoreActionState.ERROR };
      }

      if (refetch) {
        dispatch(`calendar/data/${LOAD_SHIFTS}`, { shiftIds: [shiftId] }, { root: true });
      }

      return {
        state: StoreActionState.SUCCESS,
        entityId: result.data?.removeTagFromShift.id,
      };
    },
  },
  getters: {
    shiftsIds(state, getters) {
      return getters.shifts.map(it => it.id);
    },
    shiftsEmployments(state) {
      return state.items
        .reduce(
          (
            acc: Record<number, Set<number>>,
            { locationsPosition, staffShifts },
          ) => {
            (staffShifts || []).forEach((staffShift) => {
              if (staffShift && staffShift.employment) {
                if (acc[staffShift.employment.id]) {
                  acc[staffShift.employment.id].add(locationsPosition.id);
                } else {
                  acc[staffShift.employment.id] = new Set([locationsPosition.id]);
                }
              }
            });
            return acc;
          },
          {},
        );
    },
    shift(state, getters, rootState, rootGetters) {
      return (shiftData): Shift => {
        const allTags = rootGetters['tags/items'];
        const isShiftPresetsEnabled = rootState.auth.currentCompany?.canUseShiftPresets || false;
        const {
          locationsPosition,
          staffShifts,
          shiftplan,
          shiftAssignmentGroups,
          tags,
        } = shiftData;
        const tagIds = (tags || []).map(it => it.id);
        const visibleStaffShifts = staffShifts
          .filter(staffShift => staffShift.employment !== null);
        return new Shift({
          ...shiftData,
          isMyShift: visibleStaffShifts.find(
            staffShift => staffShift.employment
              .userId === rootState.auth.currentUserId,
          ) !== undefined,
          staffShifts: visibleStaffShifts
            .map(staffShift => new StaffShift({
              ...staffShift.employment,
              staffShiftId: staffShift.id,
            })),
          tags: allTags.filter(it => tagIds.includes(it.id)),
          position: {
            ...locationsPosition.position,
            locationsPositionId: locationsPosition.id,
          },
          color: locationsPosition.position.color,
          shiftplanId: shiftplan.id,
          shiftAssignmentGroups: shiftAssignmentGroups || [],
        }, isShiftPresetsEnabled);
      };
    },
    shifts(state, getters) {
      return state.items
        .map(it => getters.shift(it))
        .sort((shift1: Shift, shift2: Shift) => {
          const shift1StartsAt = moment(shift1.startsAt);
          const shift2StartsAt = moment(shift2.startsAt);
          if (shift1StartsAt.isAfter(shift2StartsAt, 'minute')) {
            return 1;
          }
          if (shift1StartsAt.isBefore(shift2StartsAt, 'minute')) {
            return -1;
          }
          // shifts without shift presets go to the back
          if (shift1.shiftPreset && !shift2.shiftPreset) {
            return -1;
          }

          if (!shift1.shiftPreset && shift2.shiftPreset) {
            return 1;
          }

          // compare shift presets name if both shifts have shift preset
          if (shift1.shiftPreset && shift2.shiftPreset) {
            if (shift1.shiftPreset.id !== shift2.shiftPreset.id) {
              return shift1.shiftPreset
                .name.localeCompare(shift2.shiftPreset.name);
            }
          }

          return shift1.id - shift2.id;
        });
    },
    // all filters except employments filter
    filteredShifts(state, getters, rootState, rootGetters) {
      if (!rootState.auth.currentCompany) {
        return [];
      }
      const filters: FiltersDictionary = rootGetters['calendar/filters/filters'];
      const {
        shiftRotationEnabled: isShiftRotationsEnabled,
      } = rootState.auth.currentCompany;
      const locationsPositionIds = rootGetters['calendar/filters/locationsPositionIds'];
      const shiftRotationGroupIds = rootGetters['calendar/filters/shiftRotationGroupIds'];

      const isLocationsPositionsFilterDisabled = !Array.isArray(locationsPositionIds)
        || locationsPositionIds.length === 0;
      const isShiftRotationGroupsFilterDisabled = !isShiftRotationsEnabled
        || shiftRotationGroupIds === null;

      const filterByShiftRotationGroups = (shift: Shift) => {
        const isShiftInFilteredShiftRotationGroups = () => shiftRotationGroupIds?.some(
          rotationGroupId => shift.shiftRotationGroupIds.includes(rotationGroupId),
        );

        return (
          isShiftRotationGroupsFilterDisabled
          || isShiftInFilteredShiftRotationGroups()
        );
      };

      return (
        getters.shifts
          .filter(filterShowOnlyMyShifts(
            filters.showOnlyMineShifts,
            filters.showShiftsWithoutConflicts,
          ))
          .filter(filterByBaseFilters(filters))
          .filter(filterByShiftRotationGroups)
          .filter(filterByLocationsPositions(
            locationsPositionIds,
            isLocationsPositionsFilterDisabled,
          ))
      );
    },
    shiftsByDates(state, getters, rootState, rootGetters) {
      return getByDates(rootGetters['calendar/common/dateKeys'], getters.filteredShifts);
    },
    shiftsByPositions(state, getters, rootState, rootGetters) {
      return shiftsByPositions(
        rootGetters['calendar/positions/filteredPositions'],
        getters.shiftsByDates,
      );
    },
    shiftsByEmployments(state, getters, rootState, rootGetters) {
      return shiftsByEmployments(rootGetters['calendar/employments/filteredEmployments'], getters.shiftsByDates);
    },
    openShifts(state, getters, rootState, rootGetters) {
      // if it is "filtered" by shiftRotationGroup, open shifts should only be shown,
      // if they belong to a filtered group
      // -> other shifts would still be shown, if the employment the shift is assigned to
      // has other shifts that belong to a filtered group.
      const shiftRotationGroupFilter = (shift: Shift) => {
        const shiftRotationGroupIds = rootGetters['calendar/filters/shiftRotationGroupIds'];
        const isFilterInactive = shiftRotationGroupIds === null;
        const isShiftBelongingToFilteredGroup = shift.shiftRotationGroupIds.some(
          id => shiftRotationGroupIds?.includes(id),
        );

        return isFilterInactive || isShiftBelongingToFilteredGroup;
      };

      // adding open shifts
      return getByDates(
        rootGetters['calendar/common/dateKeys'],
        getters.filteredShifts
          .filter(it => it.isOpen)
          .filter(shiftRotationGroupFilter),
      );
    },
    shiftsByPositionsEmployments(state, getters, rootState, rootGetters) {
      return Object.entries(
        getters.shiftsByPositions as Record<number, Record<DateKey, Shift[]>>,
      ).reduce(
        (acc, [positionId, items]) => {
          acc[positionId] = shiftsByEmployments(
            rootGetters['calendar/employments/employmentsByPositions'][positionId] || [],
            items,
          );
          return acc;
        },
        {},
      );
    },
    shiftById(state, getters) {
      return id => getters.filteredShifts.find(it => it.id === id);
    },
  },
  modules: {
    employeeView: {
      namespaced: true,
      getters: {
        filteredShifts(state, getters, rootState, rootGetters) {
          if (!rootState.auth.currentCompany) {
            return [];
          }

          const filters: FiltersDictionary = rootGetters['calendar/filters/filters'];
          const {
            shiftRotationEnabled: isShiftRotationsEnabled,
          } = rootState.auth.currentCompany;
          const locationsPositionIds = rootGetters[`${CalendarNamespace.CALENDAR}/filters/locationsPositionIds`];
          const shiftRotationGroupIds = rootGetters[`${CalendarNamespace.CALENDAR}/filters/shiftRotationGroupIds`];
          const isLocationsPositionsFilterDisabled = !Array.isArray(locationsPositionIds)
            || locationsPositionIds.length === 0;
          const isShiftRotationGroupsFilterDisabled = !isShiftRotationsEnabled
          || shiftRotationGroupIds === null;

          const filterByShiftRotationGroups = (shift: Shift) => {
            const isOpenShiftInRotationGroup = () => (
              shift.isOpen
              && shift.shiftRotationGroupIds.some(id => shiftRotationGroupIds?.includes(id))
            );
            const isEmploymentInFilteredShiftRotationGroups = () => rootGetters['calendar/employments/filteredEmployments']
              .map(({ id }) => id)
              .some(id => shift.employmentIds.includes(id));

            return (
              isShiftRotationGroupsFilterDisabled
              || isOpenShiftInRotationGroup()
              || isEmploymentInFilteredShiftRotationGroups()
            );
          };

          return (
            rootGetters['calendar/shifts/shifts']
              .filter(filterShowOnlyMyShifts(
                filters.showOnlyMineShifts,
                filters.showShiftsWithoutConflicts,
              ))
              .filter(filterByBaseFilters(rootGetters['calendar/filters/filters']))
              .filter(filterByShiftRotationGroups)
              .filter(filterByLocationsPositions(
                locationsPositionIds,
                isLocationsPositionsFilterDisabled,
              ))
          );
        },
        shiftsByDates(state, getters, rootState, rootGetters) {
          return getByDates(rootGetters['calendar/common/dateKeys'], getters.filteredShifts);
        },
        shiftsByEmployments(state, getters, rootState, rootGetters) {
          return shiftsByEmployments(rootGetters['calendar/employments/filteredEmployments'], getters.shiftsByDates);
        },
        shiftById(state, getters) {
          return id => getters.filteredShifts.find(it => it.id === id);
        },
        openShifts(state, getters, rootState, rootGetters) {
          return getByDates(
            rootGetters['calendar/common/dateKeys'],
            getters.filteredShifts
              .filter(it => it.isOpen),
          );
        },
      },
    },
    positionsMonthView: {
      namespaced: true,
      getters: {
        shiftsByPositionsEmployments(state, getters, rootState, rootGetters) {
          return Object.entries(
            rootGetters['calendar/shifts/shiftsByPositions'] as Record<number, Record<string, Shift[]>>,
          )
            .reduce(
              (acc, [positionId, items]) => {
                acc[positionId] = shiftsByEmployments(
                  rootGetters['calendar/employments/positionsMonthView/employmentsByPositions'][positionId] || [],
                  items,
                );
                return acc;
              },
              {},
            );
        },
      },
    },
  },
});
