import { employmentsNS } from 'components/employments/store/Store';
import { Employment } from 'components/employments/types';
import FilterBox, { Slot as FilterBoxSlot } from 'components/filter-box/FilterBox';
import InputPillSelect from 'components/form/input-pill-select/InputPillSelect';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';

import styles from './filter-employments.css';

export interface Props {
  value: number[];
}

interface Events {
  onChange: (value: number[]) => void;
  onReset: () => void;
}

@Component
class FilterEmployments extends TSXComponent<Props, Events> {
  @Prop()
  public value: Props['value'];

  @employmentsNS.Getter('items')
  private employments: Employment[];

  private get employmentOptions() {
    return this.employments.map(employment => ({
      value: `${employment.id}`,
      label: `${employment.firstName} ${employment.lastName}`,
      color: '#1c1c1c', // cod-gray
      textColor: '#FFFFFF',
    }));
  }

  private onReset() {
    this.$emit('reset');
  }

  private onChange(value) {
    this.$emit('change', value);
  }

  public render() {
    return (
      <FilterBox
        class={styles.filterEmployments}
        isResetButtonShown={this.value.length > 0}
        resetButtonText={`${this.value.length}`}
        onResetClick={this.onReset}
      >
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('availabilityAggregations.filterEmployees.header')}
        </template>

        <InputPillSelect
          class={styles.filterEmploymentsInput}
          placeholder={this.$t('availabilityAggregations.filterEmployees.placeholder')}
          options={this.employmentOptions}
          name="EMPLOYMENTS"
          value={this.value.map(item => `${item}`)}
          onChange={({ payload }) => this.onChange(payload.map(val => parseInt(val, 10)))}
        />
      </FilterBox>
    );
  }
}

export default FilterEmployments;
