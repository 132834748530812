enum Action {
  SET_DAY_NOTES = 'setDayNotes',
  SET_SPECIAL_DATES = 'setSpecialDates',
  OPEN_DAY_NOTE_POPUP = 'openDayNotePopup',
  CLOSE_DAY_NOTE_POPUP = 'closeDayNotePopup',
  OPEN_DAY_NOTES = 'openDayNotes',
  CLOSE_DAY_NOTES = 'closeDayNotes',
}

export default Action;
