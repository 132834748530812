import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { EventPayload } from 'utils/events';
import {
  executeStoreActionWithFailureSnackbar,
  StoreActionState,
} from 'utils/store';
import type {
  GetById,
  GetMultipleById,
  StoreActionResult,
} from 'utils/store';
import { assignmentGroupsNS } from 'store/assignment-groups/Store';
import type {
  AssignmentGroup,
  CreateAssignmentGroupFunction,
  FetchAssignmentGroupFunction,
  RemoveAssignmentGroupFunction,
  UpdateAssignmentGroupFunction,
} from 'store/assignment-groups/Store';
import { Action as NormalizedStoreAction } from 'store/normalized-store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { redirectToParentIf } from 'src/utils/route';
import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import { shiftsNS } from 'src/store/shifts/Store';
import type {
  FetchAllShiftsFunction, FetchShiftFunction, Shift,
} from 'src/store/shifts/Store';
import { shiftAssignmentGroupsNS } from 'src/store/shift-assignment-groups/Store';
import type { CreateShiftAssignmentGroupFunction, DeleteShiftAssignmentGroupFunction } from 'src/store/shift-assignment-groups/Store';
import { AlertKind } from 'components/alert/Alert';
import DialogAssignmentGroup, { FormState } from './DialogAssignmentGroup';

export const getInitialFormState = (): FormState => ({
  name: '',
});

@Component
export default class DialogAssignmentGroupContainer extends TsxComponent<{
  shiftplanId: number;
  assignmentGroupId?: number;
}> {
  protected formState = getInitialFormState();

  protected isDeleteConfirmDialogOpen = false;

  protected isDeleting = false;

  protected isSubmitting = false;

  protected lastStoreActionState = StoreActionState.SUCCESS;

  protected isLoading = true;

  protected isLoadingShifts = true;

  protected isAssigningShift = false;

  protected isUnassigningShift = false;

  protected assignmentGroup: AssignmentGroup | undefined;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @assignmentGroupsNS.Action(NormalizedStoreAction.CREATE)
  protected createAssignmentGroup: CreateAssignmentGroupFunction;

  @assignmentGroupsNS.Action(NormalizedStoreAction.UPDATE)
  protected updateAssignmentGroup: UpdateAssignmentGroupFunction;

  @assignmentGroupsNS.Action(NormalizedStoreAction.FETCH)
  protected fetchAssignmentGroup: FetchAssignmentGroupFunction;

  @assignmentGroupsNS.Action(NormalizedStoreAction.DELETE)
  protected deleteAssignmentGroup: RemoveAssignmentGroupFunction;

  @assignmentGroupsNS.Getter
  protected getById: GetById<AssignmentGroup>;

  @shiftsNS.Action(NormalizedStoreAction.FETCH_ALL)
  protected fetchAllShifts: FetchAllShiftsFunction;

  @shiftsNS.Action(NormalizedStoreAction.FETCH)
  protected fetchShift: FetchShiftFunction;

  @shiftsNS.Getter('getByShiftplanId')
  protected getShiftsByShiftplanId: GetMultipleById<Shift>;

  @shiftAssignmentGroupsNS.Action(NormalizedStoreAction.CREATE)
  protected createShiftAssignmentGroup: CreateShiftAssignmentGroupFunction;

  @shiftAssignmentGroupsNS.Action(NormalizedStoreAction.DELETE)
  protected deleteShiftAssignmentGroup: DeleteShiftAssignmentGroupFunction;

  @Prop()
  protected shiftplanId: number;

  @Prop()
  protected assignmentGroupId?: number;

  protected get shifts() {
    return this.getShiftsByShiftplanId(this.shiftplanId);
  }

  protected onCloseClick() {
    redirectToParentIf(() => true)(this);
  }

  protected onDeleteToggle(state: boolean) {
    this.isDeleteConfirmDialogOpen = state;
  }

  protected async onDeleteConfirm() {
    if (!this.assignmentGroupId || !this.assignmentGroup) {
      this.isDeleteConfirmDialogOpen = false;
      return;
    }

    this.isDeleting = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        id: this.assignmentGroupId,
      },
      this.deleteAssignmentGroup,
      '',
    );

    this.isDeleting = false;
    this.isDeleteConfirmDialogOpen = false;

    if (response.state !== StoreActionState.SUCCESS) {
      return;
    }

    this.onCloseClick();

    this.showSnackbar({
      message: this.$t('assignmentGroups.modal.deleted'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit({ event }: EventPayload<void, HTMLElement, UIEvent>) {
    event.preventDefault();

    this.isSubmitting = true;

    let response: StoreActionResult;

    if (this.assignmentGroupId) {
      response = await executeStoreActionWithFailureSnackbar(
        this,
        {
          assignmentGroup: {
            name: this.formState.name,
            metadata: null,
          },
          id: this.assignmentGroupId,
        },
        this.updateAssignmentGroup,
        '',
      );
    } else {
      response = await executeStoreActionWithFailureSnackbar(
        this,
        {
          assignmentGroup: {
            name: this.formState.name,
            metadata: null,
          },
          shiftplanId: this.shiftplanId,
        },
        this.createAssignmentGroup,
        '',
      );
    }

    this.isSubmitting = false;

    if (response.state !== StoreActionState.SUCCESS) {
      return;
    }

    this.onCloseClick();

    this.showSnackbar({
      message: this.assignmentGroupId
        ? this.$t('assignmentGroups.modal.updated')
        : this.$t('assignmentGroups.modal.created'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected async refetchShift(shiftId: number) {
    this.isLoadingShifts = true;
    await this.fetchShift({ id: shiftId });
    this.isLoadingShifts = false;
  }

  protected async refetchAssignmentGroup(assignmentGroupId: number) {
    this.fetchAssignmentGroup({
      ids: [assignmentGroupId],
      shiftplanIds: [this.shiftplanId],
    });
  }

  protected async populateAssignmentGroup(assignmentGroupId: number) {
    this.lastStoreActionState = StoreActionState.PENDING;

    const assignmentGroupsResponse = await this.fetchAssignmentGroup({
      ids: [assignmentGroupId],
      shiftplanIds: [this.shiftplanId],
    });

    this.lastStoreActionState = assignmentGroupsResponse.state;

    if (this.lastStoreActionState !== StoreActionState.SUCCESS) {
      return;
    }

    this.assignmentGroup = this.getById(assignmentGroupId);

    if (this.assignmentGroup) {
      this.formState = {
        name: this.assignmentGroup.name,
      };
    }

    await this.fetchAllShifts({ perPage: 1000, shiftplanId: this.shiftplanId });
    this.isLoadingShifts = false;
  }

  protected async assignShift(shiftId: number) {
    if (!this.assignmentGroupId) {
      return;
    }

    this.isAssigningShift = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        shiftId,
        assignmentGroupId: this.assignmentGroupId,
      },
      this.createShiftAssignmentGroup,
      '',
    );

    if (response.state === StoreActionState.SUCCESS) {
      this.refetchShift(shiftId);
      // refetch assignment group to update aggregations data
      this.refetchAssignmentGroup(this.assignmentGroupId);
    }

    this.isAssigningShift = false;
  }

  protected async unassignShift(shiftId: number) {
    if (!this.assignmentGroupId) {
      return;
    }

    this.isUnassigningShift = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        shiftId,
        assignmentGroupId: this.assignmentGroupId,
      },
      this.deleteShiftAssignmentGroup,
      '',
    );

    if (response.state === StoreActionState.SUCCESS) {
      this.refetchShift(shiftId);
      // refetch assignment group to update aggregations data
      this.refetchAssignmentGroup(this.assignmentGroupId);
    }

    this.isUnassigningShift = false;
  }

  protected async mounted() {
    if (!this.assignmentGroupId) {
      this.lastStoreActionState = StoreActionState.SUCCESS;
      return;
    }

    await this.populateAssignmentGroup(this.assignmentGroupId);
  }

  public render() {
    if (this.isDeleteConfirmDialogOpen) {
      return (
        <DialogConfirmDelete
          isOpen={true}
          isSubmitting={this.isDeleting}
          onCancel={() => this.onDeleteToggle(false)}
          onConfirm={this.onDeleteConfirm}
          title={this.$t('assignmentGroups.modal.titleDelete')}
        >
          {this.$t('assignmentGroups.modal.messageDelete')}
        </DialogConfirmDelete>
      );
    }

    return (
      <DialogAssignmentGroup
        formState={this.formState}
        hasError={
          [StoreActionState.NOT_FOUND, StoreActionState.ERROR].includes(this.lastStoreActionState)
        }
        isOpen={true}
        isSubmitting={this.isSubmitting}
        isUpdate={!!this.assignmentGroupId}
        isDeleting={this.isDeleting}
        isLoading={this.lastStoreActionState === StoreActionState.PENDING}
        onCloseClick={this.onCloseClick}
        onDeleteClick={() => this.onDeleteToggle(true)}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
        assignmentGroupId={this.assignmentGroupId}
        shifts={this.shifts}
        onAssignClick={({ payload }) => this.assignShift(payload)}
        onUnassignClick={({ payload }) => this.unassignShift(payload)}
        isAssigningShift={this.isAssigningShift}
        isUnassigningShift={this.isUnassigningShift}
        isLoadingShifts={this.isLoadingShifts}
      />
    );
  }
}
