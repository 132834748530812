import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { createEventPayload, EventPayload } from 'utils/events';
import Prompt, { Slot as PromptSlot } from 'components/prompt/Prompt';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import Conflicts from '../conflicts/Conflicts';

export interface Props {
  conflicts: GQLShiftConflictsFragmentFragment[];
  isOpen?: boolean;
  isSelf?: boolean;
  isSubmitting?: boolean;
}

@Component
export default class DialogShiftConflicts extends TsxComponent<Props, {
  onCancel: (payload: EventPayload<void>) => void;
  onIgnore: (payload: EventPayload<void>) => void;
}> {
  protected onCancelClick(e) {
    this.$emit('cancel', createEventPayload(e, undefined));
  }

  protected onApproveAndIgnoreClick(e) {
    this.$emit('ignore', createEventPayload(e, undefined));
  }

  @Prop({ default: () => [] })
  protected conflicts: Props['conflicts'];

  @Prop()
  public isOpen?: Props['isOpen'];

  @Prop()
  public isSelf?: Props['isSelf'];

  @Prop()
  public isSubmitting?: Props['isSubmitting'];

  protected get hasIgnorableConflictsOnly() {
    return this.conflicts.every(conflict => conflict.ignorable);
  }

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        onCloseClick={this.onCancelClick}
        title={this.$t('conflicts.title')}
      >
        <Conflicts
          conflicts={this.conflicts}
          isSelf={this.isSelf}
        />

        <template slot={PromptSlot.ACTION_BUTTONS}>
          <Button type="button"
            color={ButtonColor.SECONDARY}
            kind={ButtonKind.STROKE}
            onClick={this.onCancelClick}
          >{this.$t('modal.buttonCancel')}</Button>
          { this.hasIgnorableConflictsOnly
            && <Button type="button"
              color={ButtonColor.PRIMARY}
              kind={ButtonKind.FILL}
              disabled={this.isSubmitting}
              onClick={this.onApproveAndIgnoreClick}>
              {this.$t('conflicts.buttonApproveAndIgnore')}
            </Button>
          }
        </template>
      </Prompt>
    );
  }
}
