import Screen from 'components/screen/Screen';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import imgBilling from './illustrations/billing.png';
import imgChat from './illustrations/chat.png';
import imgEvaluation from './illustrations/evaluation.png';
import imgHours from './illustrations/hours.png';
import imgOops from './illustrations/oops.png';
import imgPayroll from './illustrations/payroll.png';
import imgPayslips from './illustrations/payslips.png';
import imgPosition from './illustrations/position.png';
import imgSickCertificate from './illustrations/sick-certificate.png';
import imgUser from './illustrations/user.png';

export enum Illustration {
  BILLING,
  CHAT,
  EVALUATION,
  HOURS,
  OOPS,
  PAYROLL,
  PAYSLIPS,
  POSITION,
  SICK_CERTIFICATE,
  USER,
}

const map: Record<Illustration, any> = {
  [Illustration.BILLING]: imgBilling,
  [Illustration.CHAT]: imgChat,
  [Illustration.EVALUATION]: imgEvaluation,
  [Illustration.HOURS]: imgHours,
  [Illustration.OOPS]: imgOops,
  [Illustration.PAYROLL]: imgPayroll,
  [Illustration.PAYSLIPS]: imgPayslips,
  [Illustration.POSITION]: imgPosition,
  [Illustration.SICK_CERTIFICATE]: imgSickCertificate,
  [Illustration.USER]: imgUser,
};

@Component
export default class ScreenIllustration extends TsxComponent<{
  illustration: Illustration;
  title: string;
  message: string;
}> {
  @Prop()
  public illustration: Illustration;

  @Prop()
  public title: string;

  @Prop()
  public message: string;

  public render() {
    return (
      <Screen
        title={this.title}
        message={this.message}
        visual={<img src={map[this.illustration]} alt="" aria-hidden="true" />}
      />
    );
  }
}
