import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';

@Component
export default class CellReason extends TsxComponent<{
  reason: {
    name: string;
    hasLocalization: boolean;
  };
}> {
  @Prop()
  public reason: {
    name: string;
    hasLocalization: boolean;
  };

  public render() {
    return (
      <td class={tableStyles.tableBodyCell}>
        {
          this.reason.hasLocalization
            ? this.$t(`absence.reason.${this.reason.name}`)
            : this.reason.name
        }
      </td>
    );
  }
}
