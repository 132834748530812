import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import SpIcon from 'components/sp-icon/SpIcon';
import { FREE_DAY_PRESET_ID } from 'src/store/shift-presets/Store';
import styles from './shift-preset-day.css';
import type { ShiftPreset } from '../Store';

@Component
class ShiftPresetDay extends Vue {
  protected maxDisplayedShifts = 3;

  @Prop({ default: () => ({ color: '#333' }) })
  public shiftPreset: ShiftPreset;

  private get isFreeDayPreset() {
    return this.shiftPreset.id === FREE_DAY_PRESET_ID;
  }

  private get style(): Pick<CSSStyleDeclaration, 'borderColor'> {
    return {
      borderColor: this.isFreeDayPreset
        ? 'var(--shift-preset-free-color)'
        : this.shiftPreset.color,
    };
  }

  public render() {
    return (
    // disable clicks on the element and prevent clicks from bubbling
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div class={styles.shiftPresetDay}
        style={this.style}
        onClick={(e: Event) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        {
          this.isFreeDayPreset
            ? <SpIcon name="clear"/>
            : this.shiftPreset.name
        }
      </div>
    );
  }
}

export default ShiftPresetDay as any;
