import { Component, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Loader from 'components/loader/Loader';
import Action from 'components/table/store/Action';
import DndKind from 'components/calendar-common/common/dnd/DndKind';
import SpptDnd, { DRAGGABLE_DATA_KEY } from '@shyftplan/drag-and-drop';
import type { TagDraggable } from 'components/calendar-common/tags/dnd/types';
import { EventPayload } from 'utils/events';
import RouterLinkIcon, { Size } from 'components/router-link-icon/RouterLinkIcon';
import type { SetSelectionFunction } from 'src/store/filter-store';
import type { FetchAllTagsFunction, Tag } from 'src/store/tags/Store';
import { tagsNS } from 'src/store/tags/Store';
import { Action as StoreAction } from 'store/normalized-store';
import type { GetMultipleById } from 'src/utils/store';
import {
  authNS,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import { sortByName } from 'src/utils/utils';
import Icon from 'components/icons/Icon';
import { IconName } from 'components/icons/types';
import type {
  HasAnyCurrentLocationRightFunction,
  HasAnyRightFunction,
} from '../../auth/store/Store';
import { FILTER_PAGE_SIZE, FILTER_THROTTLE_TIMEOUT } from '../constants';
import styles from './filter-box-tag.css';
import CheckboxListWithPagingAndFiltering from '../checkbox-list-with-paging-and-filtering/CheckboxListWithPagingAndFiltering';
import CheckboxListItem, { Slot } from '../filter-box/checkbox-list/item/Item';
import { tagsFilterNS, StoreState as FilterStoreState } from './store/Store';
import FilterBox, { Slot as FilterBoxSlot } from '../filter-box/FilterBox';
import { Route } from '../routes';
import filterBoxStyles from '../filter-box/filter-box.css';

@Component
export default class FilterBoxTag extends TsxComponent<{}> {
  protected isLoading = false;

  protected isOpen = false;

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.Getter
  protected hasAnyCurrentLocationRight: HasAnyCurrentLocationRightFunction;

  @authNS.Getter
  protected hasAnyRight: HasAnyRightFunction;

  @tagsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAll: FetchAllTagsFunction;

  @tagsNS.Getter('getByLocationId')
  protected getTagsByLocationId: GetMultipleById<Tag>;

  @tagsFilterNS.Action(Action.SET_SELECTION)
  protected setSelection: SetSelectionFunction;

  @tagsFilterNS.State
  protected selection: FilterStoreState['selection'];

  @Watch('tags')
  protected updateSelection() {
    const tagIdSet = new Set(this.tags.map(o => o.id));
    const selection = this.selection.filter(o => tagIdSet.has(o));

    if (selection.length !== this.selection.length) {
      this.setSelection(selection);
    }
  }

  protected get tags() {
    return this.getTagsByLocationId(this.currentLocationId)
      .sort(sortByName);
  }

  protected get isManagingTagsAllowed(): boolean {
    return this.isSuperAdmin
      || (
        !!this.currentEmployment?.isStakeholder
        && (
          this.hasAnyCurrentLocationRight('shift_manage_right')
          || this.hasAnyRight('shifts_manage_all')
        )
      );
  }

  protected async onOpenClose({ payload: state }: EventPayload<boolean>) {
    this.isOpen = state;

    if (state) {
      this.isLoading = !this.tags.length;

      await this.fetchAll({
        locationIds: this.currentLocationId ? [this.currentLocationId] : [],
        context: null,
      });

      this.isLoading = false;
    }
  }

  public mounted() {
    SpptDnd.getInstance().initDraggableContainer(
      this.$el as HTMLElement,
      [{
        kind: DndKind.TAG,
        selector: `.${styles.filterBoxTagDraggable}`,
      }],
    );
  }

  public render() {
    return (
      <FilterBox isOpen={this.isOpen} onOpenCloseClick={this.onOpenClose}>
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('shiftSchedule.filter.tags.title')}
        </template>

        {
          this.isManagingTagsAllowed && (
            <template slot={FilterBoxSlot.ACTION_BUTTON}>
              <router-link
                to={{
                  name: Route.TAG,
                }}
                class={filterBoxStyles.filterBoxButton}
              >
                <Icon
                  aria-label={this.$t('shiftSchedule.filter.tags.buttonCreate')}
                  class={filterBoxStyles.filterBoxIcon}
                  name={IconName.PLUS}
                />
              </router-link>
            </template>
          )
        }

        {this.isLoading
          ? (
            <Loader />
          ) : (
            <CheckboxListWithPagingAndFiltering
              items={this.tags}
              filterKey="name"
              perPage={FILTER_PAGE_SIZE}
              scopedSlots={{
                item: ({ item, isSelected, onChange }) => (
                  <CheckboxListItem
                    class={styles.filterBoxTagDraggable}
                    isSelected={isSelected}
                    onChange={onChange}
                    {
                      ...{
                        attrs: {
                          draggable: true,
                        },
                      }
                    }
                    v-data-attrs={{
                      [`${DRAGGABLE_DATA_KEY}`]: JSON.stringify({
                        kind: DndKind.TAG,
                        data: {
                          id: item.id,
                        },
                      } as TagDraggable),
                    }}
                  >
                    <span style={{ color: item.color }}>
                      {item.name}
                    </span>

                    {
                      this.isManagingTagsAllowed && (
                        <RouterLinkIcon
                          location={{
                            name: Route.TAG,
                            params: { tagId: item.id.toString() },
                          }}
                          slot={Slot.ICON}
                          label="Edit"
                          size={Size.XXSMALL}
                          icon={IconName.EDIT}
                        />
                      )
                    }
                  </CheckboxListItem>
                ),
              }}
              selection={this.selection}
              throttleTimeout={FILTER_THROTTLE_TIMEOUT}
              onSelect={({ payload }) => { this.setSelection(payload as number[]); }}
            />
          )
        }
      </FilterBox>

    );
  }
}
