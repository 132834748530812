import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './sub-view.css';

export enum Slot {
  DEFAULT = 'default',
  SUBHEADER = 'subheader',
  SUBNAVIGATION = 'subnavigation',
}

@Component
export default class SubView extends TsxComponent<{}> {
  public render() {
    return (
      <div class={styles.subView}>
        {
          this.$slots[Slot.SUBHEADER] && (
            <div class={styles.subViewSubheader}>{this.$slots[Slot.SUBHEADER]}</div>
          )
        }

        {
          this.$slots[Slot.SUBNAVIGATION] && (
            <div class={styles.subViewSubnavigation}>{this.$slots[Slot.SUBNAVIGATION]}</div>
          )
        }

        {
          this.$slots[Slot.DEFAULT] && (
            <div class={styles.subViewMain}>{this.$slots[Slot.DEFAULT]}</div>
          )
        }
      </div>
    );
  }
}
