import type { GQLAbsenceConflictsFragmentFragment } from 'codegen/gql-types';
import { getDateInTimeZone, toLocaleDateString } from 'src/utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Collisions from '../../conflicts/collisions/Collisions';
import type { Absence, Employment } from '../types';

import styles from './conflicts.css';

@Component
export default class Conflicts extends TsxComponent<{
  absence: Pick<Absence, 'absenceReason'> & { employment: Employment; startsAt: Date };
  conflicts?: GQLAbsenceConflictsFragmentFragment['conflicts'];
  isCurrentEmployment?: boolean;
}> {
  @Prop()
  public absence: Pick<Absence, 'absenceReason'> & { employment: Employment; startsAt: Date };

  @Prop({ default: () => [] })
  public conflicts: GQLAbsenceConflictsFragmentFragment['conflicts'];

  @Prop()
  public isCurrentEmployment?: boolean;

  public render() {
    return (
      <div class={styles.conflicts}>
        {
          this.conflicts.map((conflict, index) => {
            if (conflict.__typename === 'CollisionsConflict') {
              return (
                <Collisions
                  absences={conflict.absences}
                  shifts={conflict.shifts}
                  key={conflict.toString() + index}
                />
              );
            }

            if (conflict.__typename === 'MaxDaysPerYearConflict') {
              const translationKey = `absence.conflicts.maxDaysPerYear.${this.isCurrentEmployment
                ? 'messageSelf'
                : 'messageEmployment'
              }`;

              const exceedance = conflict.after - conflict.limit;
              const reason = this.absence.absenceReason.hasLocalization
                ? this.$t(`absence.reason.${this.absence.absenceReason.name}`)
                : this.absence.absenceReason.name;

              const translation = this.$t(translationKey, {
                ...conflict,
                employment: `${this.absence.employment.firstName} ${this.absence.employment.lastName}`,
                exceedance,
                reason,
                year: getDateInTimeZone(this.absence.startsAt, this.$timeZone.value).getFullYear(),
              });

              return (
                <div class={styles.conflictsItem}>{translation}</div>
              );
            }

            if (conflict.__typename === 'CarryOverMultipleYearsConflict') {
              return (
                <div class={styles.conflictsItem}>
                  {this.$t('absence.conflicts.carryOverMultipleYears.message')}
                </div>
              );
            }

            if (conflict.__typename === 'CarryOverDeadlineErrorConflict') {
              return (
                <div
                  class={styles.conflictsItem}
                  domPropsInnerHTML={
                    this.$t(
                      'absence.conflicts.carryOverDeadlineError.message',
                      {
                        deadline: toLocaleDateString(
                          new Date(conflict.deadline),
                          this.$i18n.i18next.language,
                          this.$timeZone.value,
                        ),
                      },
                    )
                  }
                >
                </div>
              );
            }

            return (
              <div class={styles.conflictsItem}>
                { this.$t('absence.conflicts.unknown.message') }
              </div>
            );
          })
        }
      </div>
    );
  }
}
