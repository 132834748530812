import Dialog, { Slot } from 'components/dialog/Dialog';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { getEmploymentString } from 'src/utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { EmploymentsTableData } from '../store/Store';

import styles from './dialog-overlap.css';

@Component
export default class DialogOverlap extends TsxComponent<{
  employments: EmploymentsTableData[];
  isOpen?: boolean;
}, {
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}> {
  @Prop()
  public employments: EmploymentsTableData[];

  @Prop()
  public isOpen?: boolean;

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    this.$emit('closeClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
  }

  public render() {
    return (
      <Dialog
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        title={this.$t('rotationWizard.error.employmentAssignment.overlap.title')}
      >
        <div class={styles.dialogOverlap}>
          <p class={styles.dialogOverlapText} domPropsInnerHTML={this.$t('rotationWizard.error.employmentAssignment.overlap.message')} />
          <p class={styles.dialogOverlapText} domPropsInnerHTML={this.$t('rotationWizard.error.employmentAssignment.overlap.instruction')} />

          <ul class={styles.dialogOverlapList}>
            {
              this.employments.map(({ employment, staffNumber }) => (
                <li class={styles.dialogOverlapListItem} key={employment.id}>
                  {getEmploymentString({ ...employment, staffNumber }, true)}
                </li>
              ))
            }
          </ul>
        </div>

        <Button
          color={ButtonColor.PRIMARY}
          onClick={e => this.onCloseClick(e as SyntheticEvent<HTMLButtonElement, MouseEvent>)}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.GHOST}
        >
          {this.$t('rotationWizard.error.employmentAssignment.overlap.buttonConfirm')}
        </Button>
      </Dialog>
    );
  }
}
