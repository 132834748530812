import { GQLOrderDirection, GQLShiftEmploymentSort } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { createEventPayload, EventPayload } from 'src/utils/events';
import RadioButton from './RadioButton';

import styles from './sorting.css';

@Component
export default class Chrono extends TsxComponent<{
  criterion: GQLShiftEmploymentSort;
  isBringShiftsShown?: boolean;
  isHourAccountShown?: boolean;
  name: string;
  order: GQLOrderDirection;
}, {
  onClick: (payload: EventPayload<GQLShiftEmploymentSort>) => void;
}> {
  @Prop()
  public criterion: GQLShiftEmploymentSort;

  @Prop()
  public isBringShiftsShown?: boolean;

  @Prop()
  public isHourAccountShown?: boolean;

  @Prop()
  public name: string;

  @Prop({ default: GQLOrderDirection.ASC })
  public order: GQLOrderDirection;

  protected onClick(e) {
    this.$emit('click', createEventPayload(e, e.target.value));
  }

  public render() {
    return (
      <div class={styles.sorting}>
        <RadioButton
          checked={this.criterion === GQLShiftEmploymentSort.NAME}
          icon="person"
          label={this.$t('shifts.employees.sorting.chrono.labelName')}
          name={this.name}
          onClick={this.onClick}
          order={this.order}
          value={GQLShiftEmploymentSort.NAME}
        />

        <RadioButton
          checked={this.criterion === GQLShiftEmploymentSort.WORKEDMINUTES}
          icon="punch"
          label={this.$t('shifts.employees.sorting.chrono.labelPlanned')}
          name={this.name}
          onClick={this.onClick}
          order={this.order}
          value={GQLShiftEmploymentSort.WORKEDMINUTES}
        />

        {
          this.isHourAccountShown && (
            <RadioButton
              checked={this.criterion === GQLShiftEmploymentSort.REMAININGMINUTES}
              icon="flag"
              label={this.$t('shifts.employees.sorting.chrono.labelRemaining')}
              name={this.name}
              onClick={this.onClick}
              order={this.order}
              value={GQLShiftEmploymentSort.REMAININGMINUTES}
            />
          )
        }

        {
          this.isHourAccountShown && (
            <RadioButton
              checked={this.criterion === GQLShiftEmploymentSort.HOURACCOUNTHOURS}
              icon="time"
              label={this.$t('shifts.employees.sorting.chrono.labelHourAccount')}
              name={this.name}
              onClick={this.onClick}
              order={this.order}
              value={GQLShiftEmploymentSort.HOURACCOUNTHOURS}
            />
          )
        }

        {
          this.isBringShiftsShown && (
            <RadioButton
              checked={this.criterion === GQLShiftEmploymentSort.BRINGSHIFTS}
              icon="calendar-open-shifts"
              label={this.$t('shifts.employees.sorting.chrono.labelBringShifts')}
              name={this.name}
              onClick={this.onClick}
              order={this.order}
              value={GQLShiftEmploymentSort.BRINGSHIFTS}
            />
          )
        }
      </div>
    );
  }
}
