import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import styles from './attachment.css';

export enum Slot {
  /** Slot for any further preview content */
  PREVIEW = 'preview',
}

@Component
export default class Attachment extends TsxComponent<{
  isPreviewShown?: boolean;
  name: string | JSX.Element;
  url?: string;
}> {
  @Prop()
  public isPreviewShown?: boolean;

  @Prop()
  public name: string;

  @Prop()
  public url?: string;

  public render() {
    return (
      <div class={styles.attachment}>
        <div class={styles.attachmentPreview}>
          {
            this.isPreviewShown && this.url && (
              <img
                alt={this.$t('attachment.preview')}
                class={styles.attachmentPreviewImage}
                src={this.url}
              />
            )
          }

          {
            this.isPreviewShown && !this.url && (
              <div class={styles.attachmentPreviewIcon}>
                <Icon name={IconName.LINK} size={Size.LARGE} />
              </div>
            )
          }

          {
            this.$slots[Slot.PREVIEW]
          }
        </div>
        <div class={styles.attachmentName}>
          {this.name}
        </div>
      </div>
    );
  }
}
