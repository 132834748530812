import AbsencesRow from 'components/calendar-common/absences/absences-row/AbsencesRow';
import { AbsenceStates } from 'components/calendar-common/absences/Store';
import DateItem from 'components/calendar-common/common/DateItem';
import {
  START_EVENT_EDIT,
  STOP_EVENT_EDIT,
  UPDATE_EDITED_EVENT,
} from 'components/calendar-common/common/time-grid-events/Actions';
import { EventEditTypes } from 'components/calendar-common/common/time-grid-events/StoreFactory';
import TimeGridView from 'components/calendar-common/common/time-grid-events/time-grid-view/TimeGridView';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { Component, Mixins } from 'vue-property-decorator';

import DayNotesContainer from 'components/calendar-common/notes/day-notes-container/DayNotesContainer';
import SpecialDatesContainer from 'components/calendar-common/notes/special-dates-container/SpecialDatesContainer';
import { calendarCommonNS, StoreState } from 'components/calendar/common/Store';
import HeaderDays from 'components/calendar/grid/header-days/HeaderDays';
import { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import { shiftsNS } from 'components/calendar-common/shifts/store/Store';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import { GRID_COLUMN_MIN_WIDTH_DAY, GRID_LABEL_COLUMN_SMALL_WIDTH, GRID_SUMMARY_COLUMN_WIDTH } from 'components/calendar-common/grid/GridVariables';
import AgendaDaysShift from '../agenda-days-shift/AgendaDaysShift';
import ShiftPart from '../ShiftPart';
import { AGENDA_GRID_STEP, agendaNS } from '../Store';

@Component
class AgendaDays extends Mixins(TimeGridView, ShiftViewActionsMixin) {
  public timeGridStep = AGENDA_GRID_STEP;

  public eventComponent = AgendaDaysShift;

  @agendaNS.State(state => state.editedEvent)
  protected editedEvent: Shift;

  @agendaNS.State(state => state.eventEditType)
  protected eventEditType: EventEditTypes;

  @agendaNS.Getter('eventParts')
  protected eventParts: Record<string, ShiftPart[]>;

  @agendaNS.Getter('isEditingEvent')
  protected isEditingEvent: boolean;

  @agendaNS.Action(START_EVENT_EDIT)
  protected startEventEdit;

  @agendaNS.Action(UPDATE_EDITED_EVENT)
  protected updateEditedEvent;

  @agendaNS.Action(STOP_EVENT_EDIT)
  protected stopEventEdit;

  @calendarCommonNS.State((state: StoreState) => state.shiftplan?.id)
  protected shiftplanId: number;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  @shiftsNS.Getter('shiftsByDates')
  protected shiftsByDates: Record<string, Shift[]>;

  protected get showSummary() {
    return this.filters.showSummary;
  }

  protected get gridStyle() {
    return {
      gridTemplateColumns: `${GRID_LABEL_COLUMN_SMALL_WIDTH} repeat(${
        this.dates.length}, minmax(${
        GRID_COLUMN_MIN_WIDTH_DAY}, 1fr)) ${
        this.filters.showSummary
          ? GRID_SUMMARY_COLUMN_WIDTH : ''}`,
    };
  }

  public getEvent(startDate, endDate): Shift {
    return new Shift({
      untimedBreakTime: 15,
      shiftplanId: this.shiftplanId,
      isMyShift: false,
      id: -1,
      startsAt: startDate,
      endsAt: endDate,
      staffShifts: [],
      workers: 0,
      // as it's a fake position other fields do not matter for us
      position: { name: 'New shift' } as Shift['position'],
      color: '#c2ebff',
      canManage: true,
      tags: [],
      requests: [],
      staffShiftsNeedsEvaluationCount: 0,
      staffShiftsCount: 0,
      shiftAssignmentGroups: [],
      shiftRotationGroupIds: [],
      note: null,
      managerNote: null,
      untimed: false,
      shiftPreset: null,
      connectedGroupId: null,
    });
  }

  public renderSlots() {
    return [
      <SpecialDatesContainer dates={this.dates}/>,
      <DayNotesContainer dates={this.dates}/>,
      <AbsencesRow absenceState={AbsenceStates.NEW} />,
      <AbsencesRow absenceState={AbsenceStates.ACCEPTED} />,
    ];
  }

  public renderHeader() {
    return (<HeaderDays
      shiftsByDates={this.shiftsByDates}
      onClick={(e, { dateItem }: GridRowScope) => this.onHeaderClick(dateItem)}
      dates={this.dates}>
      <template slot="label">
        {this.getWeekNumber(this.dates[0])}
      </template>
    </HeaderDays>);
  }

  public onHeaderClick(dateItem: DateItem) {
    this.onShiftCellClick(dateItem);
  }
}

export default AgendaDays as any;
