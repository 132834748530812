import { minutesBetweenDates, minutesFromStartOfDay } from 'components/calendar-common/helpers/intervals/Intervals.js';
import moment, { Moment } from 'moment';
import Shift from '../Shift';

// value for "open slot" employment
export const OPEN_SHIFT_EMPLOYMENT_ID = 0;

/* eslint-disable camelcase */
interface DateParams {
  startsAt?: Moment;
  endsAt?: Moment;
}

interface PositionParams {
  locationsPositionId?: number;
}

interface StaffShiftParams {
  addedEmploymentId?: number;
  removedStaffShiftId?: number;
}

export type ShiftUpdateParams = StaffShiftParams & DateParams & PositionParams;
/* eslint-enable camelcase */
class ShiftParamsHelper {
  public getDateParams(slot, shift: Shift): DateParams {
    const date = slot.date || slot.datetime;
    const hasTime = slot.datetime !== undefined;
    const shiftStartDate = shift.getStartDateTime();
    const shiftEndDate = shift.getEndDateTime();
    const shiftDurationMinutes = minutesBetweenDates(shiftStartDate, shiftEndDate);
    let newStartDate;
    // if time was provided just use it
    if (hasTime) {
      if (moment(date).isSame(shiftStartDate, 'minute')) {
        return {};
      }
      newStartDate = moment(date);
    // otherwise use time from original date
    } else {
      if (moment(date).isSame(moment(shiftStartDate), 'day')) {
        return {};
      }
      const newDate = moment(date);
      newStartDate = newDate.clone().add(minutesFromStartOfDay(shiftStartDate), 'minutes');
    }
    const newEndDate = newStartDate.clone().add(shiftDurationMinutes, 'minutes');

    return {
      startsAt: newStartDate,
      endsAt: newEndDate,
    };
  }

  public getPositionParams(slot, shift: Shift): PositionParams {
    const params: PositionParams = {};
    const { positionId } = slot;
    const shiftPositionId = shift.position.locationsPositionId;

    if (positionId !== undefined && positionId !== shiftPositionId) {
      params.locationsPositionId = positionId;
    }
    return params;
  }

  public getStaffShiftParams(slot, shift: Shift): StaffShiftParams {
    const params: StaffShiftParams = {};
    const currentEmploymentId = shift.employmentId;
    const { employmentId } = slot;
    if (employmentId !== undefined && employmentId !== currentEmploymentId) {
      if (!!currentEmploymentId && currentEmploymentId !== OPEN_SHIFT_EMPLOYMENT_ID) {
        const staffShift = shift.staffShifts.find(it => it.id === currentEmploymentId);
        if (staffShift !== undefined) {
          params.removedStaffShiftId = staffShift.staffShiftId;
        }
      }

      if (employmentId > 0) {
        params.addedEmploymentId = employmentId;
      }
    }

    return params;
  }

  public getUpdatedParams(slot, shift): ShiftUpdateParams {
    return {
      ...this.getStaffShiftParams(slot, shift),
      ...this.getDateParams(slot, shift),
      ...this.getPositionParams(slot, shift),
    };
  }
}

export default new ShiftParamsHelper();
