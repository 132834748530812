import Table, { Pagination } from 'components/table/Table';
import tableStyles from 'components/table/table.css';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SortDirection } from 'components/table/types';
import type { TableColumns, TableColumn } from 'components/table/types';
import HeaderCell from 'components/table/header-cell/HeaderCell';
import FilterPopup from 'components/table/filter-popup/FilterPopup';
import { LoadingState } from 'components/table/store/Store';
import Avatar from 'components/avatar/Avatar';
import { Size } from 'components/types';
import styles from './employments-table.css';
import { EmploymentsTableData, Filters } from '../store/Store';

type SortableFields = 'employment' | 'shiftRotationGroup';
enum TableFields {
  STAFF_NUMBER = 'staffNumber',
  EMPLOYMENT_NAME = 'employmentName',
  ROTATION_GROUP = 'rotationGroup',
}

export interface Sort {
  key: SortableFields;
  direction: SortDirection;
}

interface Props {
  rotationGroups: {id: number; label: string; isChecked: boolean }[];
  rows: EmploymentsTableData[];
  pagination: Pagination;
  sort: Sort;
  filters: Partial<Filters>;
  selectedRowIds: number[];
  loadingState: LoadingState;
}

interface Events {
  onSetPage: {
    page: number;
  };
  onSetSort: {
    key: SortableFields;
    direction: SortDirection;
  };
  onSetFilter: {
    key: keyof Filters;
    value: any;
  };
  onSetSelection: {
    ids: number[];
  };
}

@Component
export default class EmploymentsTable extends TsxComponent<Props, Events> {
  private searchValue = '';

  private searchThrottleTimeout: number | null = null;

  @Prop()
  public rotationGroups: Props['rotationGroups'];

  @Prop()
  public rows: Props['rows'];

  @Prop()
  public pagination: Props['pagination'];

  @Prop()
  public sort: Props['sort'];

  @Prop()
  public filters: Props['filters'];

  @Prop()
  public selectedRowIds: Props['selectedRowIds'];

  @Prop()
  public loadingState: Props['loadingState'];

  private fields: TableColumns<EmploymentsTableData, Filters> = [
    {
      label: TableFields.STAFF_NUMBER,
      rowIdKey: 'staffNumber',
      width: '200px',
      isSortable: false,
    },
    {
      label: TableFields.EMPLOYMENT_NAME,
      rowIdKey: 'employment',
      isSortable: true,
      isFilterable: false,
    },
    {
      label: TableFields.ROTATION_GROUP,
      rowIdKey: 'shiftRotationGroup',
      width: '220px',
      isFilterable: true,
      isSortable: false,
    },
  ];

  private get search() {
    return {
      placeholder: this.$t('rotationWizard.employmentAssignment.searchPlaceholder'),
      value: this.searchValue,
    };
  }

  private clearThrottleTimeout() {
    if (this.searchThrottleTimeout) {
      window.clearTimeout(this.searchThrottleTimeout);
      this.searchThrottleTimeout = null;
    }
  }

  private onSeachChange(value: string) {
    this.searchValue = value;
    this.clearThrottleTimeout();

    this.searchThrottleTimeout = window.setTimeout(() => {
      this.$emit('setFilter', {
        key: 'search',
        value,
      });
    }, 500);
  }

  public mounted() {
    this.searchValue = this.filters.search || '';
  }

  public beforeDestroy() {
    this.clearThrottleTimeout();
  }

  public renderHeaderCell(field: TableColumn<EmploymentsTableData, Filters>) {
    const translatedLabel = this.$t(`rotationWizard.employmentAssignment.table.${field.label}`);
    const sort = field.isSortable
      ? {
        direction: field.rowIdKey === this.sort?.key
          ? this.sort?.direction
          : SortDirection.UNDEFINED,
      }
      : undefined;

    switch (field.label) {
      case (TableFields.ROTATION_GROUP):
        return (<HeaderCell
          style={{ width: field.width }}
          sort={sort}
          filter={this.filters}
          scopedSlots={{
            popup: ({ close }) => (<FilterPopup
              items={this.rotationGroups}
              onClose={close}
              onFilterChange={({ payload }) => {
                let rotationGroupIds = [...this.filters.shiftRotationGroupIds || []];
                const { id } = payload;
                if (rotationGroupIds.includes(payload.id)) {
                  rotationGroupIds = rotationGroupIds
                    .filter(groupId => groupId !== id);
                } else {
                  rotationGroupIds = [...rotationGroupIds, id];
                }

                this.$emit('setFilter', [{
                  key: 'shiftRotationGroupIds',
                  value: rotationGroupIds,
                }, {
                  key: 'notInShiftRotationGroupIds',
                  value: [],
                }]);
              }}
            >
              <template slot="header">
                {this.$t('rotationWizard.employmentAssignment.table.rotationGroupFilter.title')}
              </template>
            </FilterPopup>),
          }}>
          <template slot="label">
            {translatedLabel}
          </template>
        </HeaderCell>);
      case (TableFields.EMPLOYMENT_NAME):
        return (<HeaderCell
          sort={sort}
          isStretch={true}
          onSortDirectionChange={({ payload }) => {
            this.$emit('setSort', {
              key: field.rowIdKey,
              direction: payload.direction,
            });
          }}>
          <template slot="label">
            {translatedLabel}
          </template>
        </HeaderCell>);
      default:
        return (<HeaderCell
          sort={sort}
          style={{ width: field.width }}
          onSortDirectionChange={({ payload }) => {
            this.$emit('setSort', {
              key: field.rowIdKey,
              direction: payload.direction,
            });
          }}>
          <template slot="label">
            {translatedLabel}
          </template>
        </HeaderCell>);
    }
  }

  public render() {
    return (
      <Table rows={this.rows}
        fields={this.fields}
        search={this.search}
        pagination={this.pagination}
        rowIdKey="id"
        selection={this.selectedRowIds}
        loadingState={this.loadingState}

        onPageChange={({ payload }) => this.$emit('setPage', payload)}
        onSearchInput={({ payload }) => this.onSeachChange(payload.value)}
        onSelectionChange={({ payload }) => {
          this.$emit('setSelection', { ids: payload.value });
        }}
        scopedSlots={{
          header: ({ fields }) => fields.map(field => this.renderHeaderCell(field)),
          row: ({ rowData }) => [
            <td class={tableStyles.tableBodyCell}>
              {rowData.staffNumber}
            </td>,
            <td class={
              [tableStyles.tableBodyCell, tableStyles.tableBodyCellStretch]
            }>
              <div class={styles.tableEmploymentCell}>
                <Avatar
                  class={styles.tableEmploymentCellAvatar}
                  firstName={rowData.employment.firstName}
                  lastName={rowData.employment.lastName}
                  imageUrl={rowData.employment.userImage ?? undefined}
                  size={Size.MEDIUM}/>
                {
                  `${rowData.employment.firstName} ${rowData.employment.lastName}`
                }
              </div>
            </td>,
            <td class={tableStyles.tableBodyCell}>
              {rowData.shiftRotationGroup?.name || this.$t('rotationWizard.employmentAssignment.table.notAssigned')}
            </td>],
        }}>
      </Table>);
  }
}
