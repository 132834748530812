import { EvaluationBreak } from 'store/evaluation-breaks/Store';
import {
  getDurationSum,
  getHourMinuteDurationString,
  isSameDayInTimeZone,
} from 'utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import Action from './store/Action';
import { Break, sectionBreaksEmploymentNS } from './store/Store';
import Breaks, {
  OnBreakChangePayload,
  OnDeleteBreakClickPayload,
  OnUnpaidBreakChangePayload,
} from '../../breaks/Breaks';

import styles from './section-breaks.css';

@Component
export default class SectionBreaksContainerEmployment extends TsxComponent<{
  evaluationBreaks: EvaluationBreak[];
  evaluationEndsAt: Date;
  evaluationStartsAt: Date;
  isDisabled?: boolean;
  isEmploymentEvaluationPresent?: boolean;
  unpaidBreak: number;
}, {
  onUnpaidBreakChange: (payload: OnUnpaidBreakChangePayload) => void;
}> {
  @Prop()
  public evaluationBreaks: Break[];

  @Prop()
  public evaluationEndsAt: Date;

  @Prop()
  public evaluationStartsAt: Date;

  @Prop()
  public isDisabled?: boolean;

  @Prop()
  public isEmploymentEvaluationPresent?: boolean;

  @Prop()
  public unpaidBreak: number;

  @sectionBreaksEmploymentNS.State('breaks')
  protected breaks: Break[];

  @sectionBreaksEmploymentNS.Action(Action.ADD_BREAK)
  protected addBreak: (payload: Omit<Break, 'id'>) => void;

  @sectionBreaksEmploymentNS.Action(Action.UPDATE_BREAK)
  protected updateBreak: (payload: Break) => void;

  @sectionBreaksEmploymentNS.Action(Action.DELETE_BREAK)
  protected deleteBreak: (breakId: number) => void;

  @sectionBreaksEmploymentNS.Action(Action.SET_BREAKS)
  protected setBreaks: (breaks: Break[]) => void;

  protected get isMultiDay() {
    return !isSameDayInTimeZone(
      this.evaluationStartsAt,
      this.evaluationEndsAt,
      this.$timeZone.value,
    );
  }

  protected get totalBreakDuration() {
    return (this.unpaidBreak || 0) * 60 * 1000 + getDurationSum(this.breaks);
  }

  protected onAddBreak() {
    this.addBreak({
      startsAt: this.evaluationStartsAt,
      endsAt: this.evaluationEndsAt,
    });
  }

  protected onDeleteBreak({ payload: { id } }: OnDeleteBreakClickPayload) {
    this.deleteBreak(id);
  }

  protected onChangeBreak({ payload }: OnBreakChangePayload) {
    const updatedBreak = this.breaks.find(item => item.id === payload.id);
    if (updatedBreak) {
      this.updateBreak({
        ...updatedBreak,
        [payload.field]: payload.value,
      });
    }
  }

  protected onChangeUnpaidBreak(e: OnUnpaidBreakChangePayload) {
    this.$emit('unpaidBreakChange', e);
  }

  public mounted() {
    this.setBreaks((this.evaluationBreaks || []).map(item => ({
      ...item,
      id: this.isEmploymentEvaluationPresent ? item.id : -item.id,
      startsAt: new Date(item.startsAt),
      endsAt: new Date(item.endsAt),
    })));
  }

  public render() {
    return (
      <FormSection
        context={this.$t('shifts.evaluations.labelEmployee')}
        heading={this.$t('shifts.evaluations.breaks.headingBreaks')}
        headingSummary={
          this.$t(
            'shifts.evaluations.labelSummaryTotal',
            { total: getHourMinuteDurationString(this.totalBreakDuration) },
          )
        }
      >
        <Breaks
          class={styles.sectionBreaksInputFullWidth}
          shiftStartsAt={this.evaluationStartsAt}
          shiftEndsAt={this.evaluationEndsAt}
          breaks={this.breaks}
          isDisabled={this.isDisabled}
          isMultiDay={this.isMultiDay}
          onAddBreakClick={this.onAddBreak}
          onBreakChange={this.onChangeBreak}
          onDeleteBreakClick={this.onDeleteBreak}
          onUnpaidBreakChange={this.onChangeUnpaidBreak}
          unpaidBreak={this.unpaidBreak}
        />
      </FormSection>
    );
  }
}
