// if you want to understand calculations - check this:
// https://medium.com/@donatbalipapp/colours-maths-90346fb5abda
// or any other article about RGB -> HUE conversion
type RGBColorChannels = [number, number, number];
export interface HSLColor {
  hue: number;
  saturation: number;
  lightness: number;
}

export interface RGBColor {
  red: number;
  green: number;
  blue: number;
}

const NUM_CHANNELS = 3;
const EPSION = 0.0001;

// returns array of 0 - 1 values
const getRGBColorChannels = (hexColor: string): RGBColorChannels => {
  const result: RGBColorChannels = [0, 0, 0];
  for (let i = 0; i < NUM_CHANNELS; i += 1) {
    const index = 1 + i * 2;
    result[i] = parseInt(hexColor.slice(index, index + 2), 16) / 255;
  }
  return result;
};

// returns hue angle (0 - 360)
const getHue = (
  colorChannels: RGBColorChannels,
  maxIndex: number,
  diff: number,
): number => (diff > EPSION
  ? (360 + 60 * (
    maxIndex * 2 + (
      colorChannels[(maxIndex + 1) % NUM_CHANNELS]
        - colorChannels[(maxIndex + 2) % NUM_CHANNELS]
    ) / diff)) % 360
  : 0);

// returns lightness percentage (0 - 100)
const getLightness = (min: number, max: number): number => ((max + min) / 2) * 100;

// returns saturation persentage (0 - 100)
const getSaturation = (
  min: number,
  max: number,
  diff: number,
): number => ((1 - min > EPSION) ? (diff / (1 - Math.abs(max + min - 1))) * 100 : 0);

// returns W3C Luma value (0 - 100)
// https://thoughtbot.com/blog/closer-look-color-lightness
const getLuma = (colorChannels: RGBColorChannels): number => {
  const COLOR_WEIGHTS = [0.299, 0.587, 0.117];
  return colorChannels.reduce(
    (acc, current, index) => acc + COLOR_WEIGHTS[index] * current,
    0,
  ) * 100;
};

const clamp = (
  value: number,
  min = 0,
  max = 100,
): number => Math.min(max, Math.max(min, value));

export const hexRGBToHSL = (colorChannels: RGBColorChannels): HSLColor => {
  const min = Math.min(...colorChannels);
  const max = Math.max(...colorChannels);
  const diff = max - min;
  const hue = getHue(colorChannels, colorChannels.indexOf(max), diff);
  const lightness = getLightness(min, max);
  const saturation = getSaturation(min, max, diff);
  return {
    hue,
    lightness,
    saturation,
  };
};

// gets a high contrast color by adjusting saturation and lightness
export const getSecondaryColor = (hexColor = '#000000'): HSLColor => {
  const LIGHTNESS_OFFSET = 55;
  const LUMA_TRESHOLD = 50;
  const SATURATION_OFFSET = 25;
  const colorChannels: RGBColorChannels = getRGBColorChannels(hexColor);
  const hslColor: HSLColor = hexRGBToHSL(colorChannels);
  const luma: number = getLuma(colorChannels);

  const lightness = clamp(
    luma < LUMA_TRESHOLD
      ? hslColor.lightness + LIGHTNESS_OFFSET
      : hslColor.lightness - LIGHTNESS_OFFSET,
  );
  const saturation = clamp(hslColor.saturation - SATURATION_OFFSET);
  return { lightness, saturation, hue: hslColor.hue };
};

export const getHSLCSSString = ({ hue, saturation, lightness }: HSLColor): string => `${Math.floor(hue)}, ${Math.floor(saturation)}%, ${Math.floor(lightness)}%`;
