import Absence from 'components/calendar-common/absences/Absence';
import { Component, Prop, Vue } from 'vue-property-decorator';
import styles from './absence-month.css';

@Component
class AbsenceMonth extends Vue {
  @Prop()
  protected absence: Absence;

  private get localizedReason() {
    return this.absence.reason.hasLocalization
      ? this.$t(`absence.reason.${this.absence.reason.name}`)
      : this.absence.reason.name;
  }

  public render() {
    return (<div class={styles.absenceMonth}>
      <span> {this.localizedReason.substring(0, 1).toUpperCase()} </span>
    </div >);
  }
}

export default AbsenceMonth as any;
