import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import Icon from 'components/icons/Icon';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconPosition } from 'components/form/button/types';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { createEventPayload, EventPayload } from '../../utils/events';
import HeaderCell from './header-cell/HeaderCell';
import { LoadingState } from './store/Store';
import styles from './table.css';
import type { TableColumns } from './types';

export enum TableSlot {
  AUX_CONTROLS = 'AUX_CONTROLS',
}

interface Search {
  value: string;
  placeholder: string;
}

export interface Pagination {
  count: number;
  perPage: number;
  page: number;
}

interface Props<TRowData, TIdentifierKey extends keyof TRowData, TFilters> {
  fields: TableColumns<TRowData, TFilters>;
  loadingState?: LoadingState;
  pagination?: Pagination;
  rowIdKey: TIdentifierKey;
  rows: TRowData[];
  search?: Search;
  selection?: TRowData[TIdentifierKey][];
}

interface Slots<TRowData, TFilters> {
  header: { fields: TableColumns<TRowData, TFilters> };
  row: {
    rowData: TRowData;
    fields: TableColumns<TRowData, TFilters>;
  };
}

interface Events<TRowData, TSelectionKey extends keyof TRowData> {
  onSearchInput: (payload: EventPayload<{ value: string }, HTMLInputElement, InputEvent>) => void;
  onPageChange: (payload: EventPayload<{ page: number }, HTMLButtonElement, MouseEvent>) => void;
  onSelectionChange: (payload: EventPayload<
  { value: TRowData[TSelectionKey][] }, HTMLInputElement, InputEvent>) => void;
}

@Component
class Table<TRowData, TSelectionKey extends keyof TRowData, TFilters> extends
  TsxComponent<
  Props<TRowData, TSelectionKey, TFilters>,
  Events<TRowData, TSelectionKey>,
  Slots<TRowData, TFilters>
  > {
  @Prop()
  private search: Search | undefined;

  @Prop()
  private pagination: Pagination | undefined;

  @Prop()
  public rows: Props<TRowData, TSelectionKey, TFilters>['rows'];

  @Prop()
  public fields: Props<TRowData, TSelectionKey, TFilters>['fields'];

  @Prop({ default: LoadingState.IDLE })
  public loadingState: Props<TRowData, TSelectionKey, TFilters>['loadingState'];

  @Prop()
  public selection: Props<TRowData, TSelectionKey, TFilters>['selection'] | undefined;

  @Prop()
  public rowIdKey: Props<TRowData, TSelectionKey, TFilters>['rowIdKey'];

  private get paginationInfoText() {
    if (!this.pagination) {
      return '';
    }
    const { count, perPage, page } = this.pagination;
    return this.$t('table.paginationInfo', {
      pageStart: Math.min(count, (page - 1) * perPage + 1),
      pageEnd: Math.min(page * perPage, count),
      count,
    });
  }

  private get maxPages() {
    return this.pagination
    // always have at least one page even if there's no items
      ? Math.max(1, Math.ceil(this.pagination.count / this.pagination.perPage))
      : 1;
  }

  private get isAllSelected() {
    if (!this.selection) {
      return false;
    }

    return this.rows.length > 0 && this.selection.length === this.rows.length;
  }

  private onSearchInput(event: SyntheticEvent<HTMLInputElement, InputEvent>) {
    this.$emit('searchInput', createEventPayload(event, { value: event.target.value }));
  }

  private onPageChange(event: SyntheticEvent<HTMLButtonElement, MouseEvent>, pageDelta: number) {
    if (!this.pagination) {
      return;
    }
    this.$emit(
      'pageChange',
      createEventPayload(event, { page: this.pagination.page + pageDelta }),
    );
  }

  private setSelected(event: SyntheticEvent<HTMLInputElement, InputEvent>, row: TRowData) {
    // this should not happen normally
    if (!this.selection) {
      return;
    }

    const key = row[this.rowIdKey];
    let selectedKeys = [...this.selection];
    if (selectedKeys.includes(key)) {
      selectedKeys = selectedKeys.filter(selectedKey => selectedKey !== key);
    } else {
      selectedKeys = selectedKeys.concat([key]);
    }

    this.$emit('selectionChange', createEventPayload(event, {
      value: selectedKeys,
    }));
  }

  private setAllSelected(event: SyntheticEvent<HTMLInputElement, InputEvent>) {
    // this should not happen normally
    if (!this.selection) {
      return;
    }

    let selectedKeys: TRowData[TSelectionKey][] = [];
    if (!this.isAllSelected) {
      selectedKeys = this.rows.map(row => row[this.rowIdKey]);
    }

    this.$emit('selectionChange', createEventPayload(event, {
      value: selectedKeys,
    }));
  }

  public render() {
    return (
      <section class={styles.tableWrapper}>
        <div class={styles.tableScrollContainer}>
          <table class={{
            [styles.table]: true,
            [styles.tableLoading]: this.loadingState !== LoadingState.IDLE,
          }}>
            <thead class={styles.tableHeader}>
              <tr>
                {
                  this.selection
                  && <HeaderCell class={styles.tableHeaderSelectCell}>
                    <input type="checkbox"
                      aria-label={this.$t(`table.${this.isAllSelected ? 'labelDeselectAll' : 'labelDeselectAll'}`)}
                      value={true}
                      indeterminate={!this.isAllSelected && this.selection.length > 0}
                      checked={this.isAllSelected}
                      onChange={this.setAllSelected}/>
                  </HeaderCell>
                }
                {this.$scopedSlots.header({ fields: this.fields })}
              </tr>
            </thead>
            <tbody class={styles.tableBody}>
              {
                this.loadingState === LoadingState.IDLE && this.rows.length === 0 && (
                  <tr>
                    <td
                      class={[styles.tableBodyCell, styles.tableBodyCellNothingFound]}
                      colspan={this.selection ? this.fields.length + 1 : this.fields.length}
                    >
                      {this.$t('table.filterNothingFound')}
                    </td>
                  </tr>
                )
              }
              {
                this.rows.map((row) => {
                  const isSelected = this.selection
                    ? this.selection.includes(row[this.rowIdKey])
                    : undefined;

                  return (
                    <tr
                      // vue doesn't handle passing boolean correctly
                      // as aria-selected has 3 states: true/false/undefined
                      // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Row_Role
                      // eslint-disable-next-line no-nested-ternary
                      aria-selected={isSelected ? 'true' : (isSelected === undefined ? 'undefined' : 'false')}
                      key={row[this.rowIdKey]}
                    >
                      {
                        this.selection && (
                          <td class={styles.tableBodyCell}>
                            <input type="checkbox"
                              aria-label={this.$t(`table.${isSelected ? 'labelDeselect' : 'labelSelect'}`)}
                              checked={isSelected}
                              onChange={e => this.setSelected(e, row)}
                              value={row[this.rowIdKey]}
                            />
                          </td>
                        )
                      }
                      {
                        this.$scopedSlots.row(
                          {
                            rowData: row,
                            fields: this.fields,
                          },
                        )
                      }
                    </tr>
                  );
                })
              }
            </tbody>
            {this.loadingState !== LoadingState.IDLE && (
              <div aria-hidden="true" class={styles.tableLoadingIndicator} />
            )}
          </table>
        </div>
        <div class={styles.tableControls}>
          {
            this.search
            && <div class={styles.tableSearch}>
              <Icon name={IconName.SEARCH}
                class={styles.tableSearchIcon}></Icon>
              <input type="text"
                onInput={this.onSearchInput}
                value={this.search.value}
                placeholder={this.search.placeholder}
                class={styles.tableSearchInput}/>
            </div>
          }

          <div class={{
            [styles.tablePagination]: true,
            [styles.tablePaginationHidden]: this.rows.length === 0,
          }}>
            <span
              class={styles.tablePaginationInfo}
              domProps-innerHTML={this.paginationInfoText}
            />
            <div class={styles.tablePaginationControls}>
              <Button
                color={ButtonColor.SECONDARY}
                disabled={!this.pagination || this.pagination.page === 1}
                name="arrow-left"
                onClick={e => this.onPageChange(e, -1)}
                size={Size.MEDIUM}
                kind={ButtonKind.GHOST}
                iconPosition={IconPosition.ALONE
                }/>
              <Button
                color={ButtonColor.SECONDARY}
                disabled={!this.pagination || this.pagination.page === this.maxPages}
                name="arrow-right"
                onClick={e => this.onPageChange(e, 1)}
                size={Size.MEDIUM}
                kind={ButtonKind.GHOST}
                iconPosition={IconPosition.ALONE
                }/>
            </div>
          </div>
          {
            this.$slots[TableSlot.AUX_CONTROLS] && (
              <div class={styles.tableAuxiliaryControls}>
                {this.$slots[TableSlot.AUX_CONTROLS]}
              </div>
            )
          }
        </div>
      </section>
    );
  }
}

export default Table;
