import DateItem, { DateKey } from 'components/calendar-common/common/DateItem';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import type { GQLCalendarAggregation } from 'codegen/gql-types';
import SummaryCell, { SummaryCellColor } from './SummaryCell';
import styles from './summary.css';

@Component
class Summary extends Vue {
  @Prop()
  public dates: DateItem[];

  @Prop()
  public aggregationsByDates: Record<DateKey, GQLCalendarAggregation>;

  private get aggregationsTotal() {
    return Object.values(this.aggregationsByDates).reduce((acc, current) => {
      acc.absent += current.absent;
      acc.demand += current.demand;
      acc.working += current.working;
      return acc;
    }, {
      working: 0,
      absent: 0,
      demand: 0,
    });
  }

  public render() {
    return (
      <div class={[gridStyles.gridTableSubgrid]}>
        <GridRow
          isHeader={true}
          dates={this.dates}
          scopedSlots={{
            cell: ({ dateItem }: GridRowScope) => <SummaryCell
              value={this.aggregationsByDates[dateItem.dateKey].demand}
              date={dateItem}
              color={SummaryCellColor.GREY}/>,
          }}>
          <GridCell slot="label"
            class={gridStyles.gridTableLabelCell}
            isHeader={true}>
            {this.$t('calendar.summary.demand')}
          </GridCell>
          <GridCell slot="total"
            class={[gridStyles.gridTableLabelCell, styles.summaryTotalCell]}
            isHeader={true}>
            <SummaryCell
              value={this.aggregationsTotal.demand}/>
          </GridCell>
        </GridRow>
        <GridRow
          isHeader={true}
          dates={this.dates}
          scopedSlots={{
            cell: ({ dateItem }: GridRowScope) => {
              const { working, demand } = this.aggregationsByDates[dateItem.dateKey];
              let color = SummaryCellColor.GREEN;
              if (working < demand) {
                color = SummaryCellColor.RED;
              } else if (working > demand) {
                color = SummaryCellColor.YELLOW;
              }
              return <SummaryCell
                value={working}
                date={dateItem}
                color={color}/>;
            },
          }}>
          <GridCell slot="label"
            class={gridStyles.gridTableLabelCell}
            isHeader={true}>
            {this.$t('calendar.summary.working')}
          </GridCell>
          <GridCell slot="total"
            class={[gridStyles.gridTableLabelCell, styles.summaryTotalCell]}
            isHeader={true}>
            <SummaryCell
              color={
                this.aggregationsTotal.demand > this.aggregationsTotal.working
                  ? SummaryCellColor.RED
                  : SummaryCellColor.GREEN}
              value={this.aggregationsTotal.working}/>
          </GridCell>
        </GridRow>
        <GridRow
          isHeader={true}
          dates={this.dates}
          scopedSlots={{
            cell: ({ dateItem }: GridRowScope) => <SummaryCell
              date={dateItem}
              value={this.aggregationsByDates[dateItem.dateKey].absent}/>,
          }}>
          <GridCell slot="label"
            class={gridStyles.gridTableLabelCell}
            isHeader={true}>
            {this.$t('calendar.summary.absent')}
          </GridCell>
          <GridCell slot="total"
            class={[gridStyles.gridTableLabelCell, styles.summaryTotalCell]}
            isHeader={true}>
            <SummaryCell
              value={this.aggregationsTotal.absent}/>
          </GridCell>
        </GridRow>
      </div>);
  }
}

export default Summary as any;
