import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import styles from './tag.css';

// TODO: maybe it makes sense to make a Tag component
// and move this display logic to shift slot
// would make it cleaner
@Component
class Tag extends Vue {
  @Prop({ default: '' })
  public name!: string;

  @Prop({ default: '' })
  public tooltip!: string;

  @Prop({ default: '#000' })
  public bgColor!: string;

  @Prop({ default: '#fff' })
  public textColor!: string;

  // @Inject('bootstrapHovers')
  // bootstrapHovers;

  protected get style(): Record<string, string> {
    return {
      backgroundColor: this.bgColor,
      color: this.textColor,
    };
  }

  protected getTagName(name: string): string {
    const MAX_STR_SYMBOLS = 25;
    if (name.length < MAX_STR_SYMBOLS) {
      return name;
    }
    return `${name.substring(0, MAX_STR_SYMBOLS - 3)}...`;
  }

  // mounted(): void {
  //   this.bootstrapHovers.tooltip(this.$el);
  // }

  // updated(): void {
  //   this.bootstrapHovers.tooltip(this.$el);
  // }

  public render() {
    /* show one chip with all tags for default and compact views
    * show all tags in seperate chips for fullnames
    */
    return (<div class={styles.tags}
      style={this.style}
      data-tooltip="true"
      data-html="true"
      title={this.tooltip}>
      <span class={styles.tagsContent}>
        {this.getTagName(this.name)}
      </span>
    </div>);
  }
}

export default Tag as any;
