import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import type { ShiftAvailableEmployment } from 'src/store/shift-available-employments/Store';
import { getDateInTimeZone, getDateTimeFormatter } from 'src/utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import Tooltip from 'components/tooltip/Tooltip';
import styles from './indicator-join-request.css';

@Component
export default class IndicatorJoinRequest extends TsxComponent<{
  employment: ShiftAvailableEmployment;
}> {
  @Prop()
  public employment: ShiftAvailableEmployment;

  protected get formatDate() {
    return getDateTimeFormatter(this.$i18n.i18next.language);
  }

  public render() {
    return (
      <Tooltip
        class={styles.indicatorJoinRequest}
        text={
          this.$t(
            'shifts.employees.labelHasJoinRequest',
            { when: this.formatDate(getDateInTimeZone(new Date(this.employment.joinRequest?.createdAt || ''), this.$timeZone.value)) },
          )
        }
      >
        <Icon
          class={styles.indicatorJoinRequestIcon}
          name={IconName.APPLICATION}
          size={Size.SMALL}
        />
      </Tooltip>
    );
  }
}
