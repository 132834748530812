import {
  Component,
  Prop,
  Mixins,
} from 'vue-property-decorator';
import { State } from 'vuex-class';
import SummaryCell, { SummaryCellColor } from 'components/calendar-common/summary/SummaryCell';
import { shiftPresetsNS, ShiftPreset } from 'components/calendar-common/shift-presets/Store';
import ToggleCell from 'components/calendar-common/grid/toggle-cell/ToggleCell';
import ShiftPresetMonth from 'components/calendar-common/shift-presets/shift-preset-month/ShiftPresetMonth';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import { getRotationPatternVisibilityMap, RotationPatternVisibilityState } from 'components/calendar-common/shift-rotation-group/helpers';
import { positionsNS } from 'components/calendar-common/positions/Store';
import { Position } from 'components/calendar-common/positions/Position';
import { HAS_SHIFTS_KEY } from 'components/calendar-common/shifts/store/util';
import RootStoreState from 'src/store/RootStoreState';
import { TsxComponentAttrs } from 'vue-tsx-support';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import Employment from 'components/calendar-common/employments/Employment';
import Shift from 'components/calendar-common/shifts/Shift';
import Absence from 'components/calendar-common/absences/Absence';
import DateItem from 'components/calendar-common/common/DateItem';
import ShiftMonth from 'components/calendar-common/shifts/shift-month/ShiftMonth';
import { TOGGLE_POSITION } from 'components/calendar-common/positions/Actions';
import GridRow from 'components/calendar-common/grid/grid-row/GridRow';
import AbsenceMonth from 'components/calendar-common/absences/absence-month/AbsenceMonth';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import Employee from 'components/calendar-common/employees/employee/Employee';
import { GridRowScope } from 'components/calendar-common/grid/grid-print-row/GridPrintRow';
import DndParams from 'components/calendar-common/grid/DndParams';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import styles from '../positions.css';

interface Props {
  isVisible: boolean;
  showSummary: boolean;
  employments: Employment[];
  position: Position;
  isLastRow: boolean;
  employmentsAbsences: Record<number, Record<string, Absence[]>>;
  employmentsShifts: Record<number, Record<string, Shift[]> & { [HAS_SHIFTS_KEY]: boolean }>;
  dates: DateItem[];
  shiftsByDates: Record<string, Shift[]>;
  absencesByDates: Record<string, Absence[]>;
}

@Component
class Row extends Mixins(ShiftViewActionsMixin) {
  protected _tsxattrs: TsxComponentAttrs<Props>;

  private openEmploymentIds: number[] = [];

  @Prop()
  public isVisible: Props['isVisible'];

  @Prop()
  public showSummary: Props['showSummary'];

  @Prop()
  public employments: Props['employments'];

  @Prop()
  public position: Props['position'];

  @Prop()
  public isLastRow: Props['isLastRow'];

  @Prop()
  public employmentsAbsences: Props['employmentsAbsences'];

  @Prop()
  public employmentsShifts: Props['employmentsShifts'];

  @Prop()
  public dates: Props['dates'];

  @Prop()
  public shiftsByDates: Props['shiftsByDates'];

  @Prop()
  public absencesByDates: Props['absencesByDates'];

  @State((state: RootStoreState) => state.calendar.positions.monthPositions.openPositionIds)
  public openPositionIds: number[];

  @positionsNS.Action(`monthPositions/${TOGGLE_POSITION}`)
  public togglePosition;

  @shiftPresetsNS.Getter('getByIdWithFallback')
  private getShiftPresetById: (id: number) => ShiftPreset;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  private get cellScopedSlots() {
    return ({
      cell: scope => this.shiftsByDates[scope.dateItem.dateKey]
        .map(shift => <ShiftMonth
          shift={shift}
          employmentId={0}
          onDrop={(...args) => this.$emit('drop', ...args)}
          key={shift.id}/>),
    });
  }

  private get isPositionEmpty(): boolean {
    const hasShifts = Object.values(this.employmentsShifts)
      .some(it => it[HAS_SHIFTS_KEY]);
    const hasAbsences = Object.values(this.absencesByDates)
      .some(it => it.length > 0);
    return !(hasShifts || hasAbsences);
  }

  private get isPositionOpen(): boolean {
    return this.openPositionIds.includes(this.position.locationsPositionId);
  }

  private get positionWorkersCount() {
    return Object.values(this.shiftsByDates)
      .flatMap(shifts => shifts)
      .reduce((acc, shift) => acc + shift.workers, 0);
  }

  private toggleEmploymentRow(employmentId) {
    if (this.openEmploymentIds.includes(employmentId)) {
      this.openEmploymentIds = this.openEmploymentIds
        .filter(it => it !== employmentId);
    } else {
      this.openEmploymentIds = [...this.openEmploymentIds, employmentId];
    }
  }

  private renderPositionUsers() {
    const { locationsPositionId, color } = this.position;

    return this.employments
      .filter((employment) => {
        const hasShifts: boolean = this.employmentsShifts[employment.id][HAS_SHIFTS_KEY];
        const hasAbsences: boolean = Object.values(this.employmentsAbsences[employment.id])
          .some(absences => absences.length > 0);
        return hasShifts || hasAbsences;
      })
      .map((employment, index) => {
        const isOpen = this.openEmploymentIds.includes(employment.id);
        const absences = this.employmentsAbsences[employment.id];
        const shifts = this.employmentsShifts[employment.id];
        const employmentRotationPattern = employment.getShiftPresetIdsForTimeframe(this.dates);
        const isEmploymentRotationPatternVisible = getRotationPatternVisibilityMap(
          shifts,
          employmentRotationPattern,
        );

        const isToggleVisible = this.filters.showShiftRotation
          && Object.values(isEmploymentRotationPatternVisible)
            .some(it => it === RotationPatternVisibilityState.NOT_VISIBLE);
        const aggregationValue = (Object.values(shifts) as (Shift[] | boolean)[])
          // there can be non-array keys that we want to remove before aggregating
          .filter((it): it is Shift[] => Array.isArray(it))
          .reduce((acc, dayShifts) => acc + dayShifts.length, 0);
        const scopedSlots = {
          cell: ({ dateItem }: GridRowScope) => {
            const shiftPresetId = employmentRotationPattern[dateItem.dateKey];
            const shouldShowSetPreset = isOpen
              ? isEmploymentRotationPatternVisible[dateItem.dateKey]
                !== RotationPatternVisibilityState.NO_ROTATION_PRESENT
              : isEmploymentRotationPatternVisible[dateItem.dateKey]
                === RotationPatternVisibilityState.VISIBLE;

            return [
              this.filters.showShiftRotation
              && shouldShowSetPreset
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              && <ShiftPresetMonth shiftPreset={this.getShiftPresetById(shiftPresetId!)} />,
              absences[dateItem.dateKey]
                .map(absence => <AbsenceMonth absence={absence}
                  key={absence.uniqId}/>),
              shifts[dateItem.dateKey]
                .map(shift => <ShiftMonth shift={shift}
                  employmentId={employment.id}
                  onDrop={(...args) => this.$emit('drop', ...args)}
                  key={shift.id}/>),
            ].filter(Boolean);
          },
        };
        const lastRowIndex = this.employments.length - 1;
        return (<GridRow
          isVisible={this.isVisible}
          cellClass={gridStyles.gridTableContentCellPaddingBottom}
          dates={this.dates}
          dndParams={{
            ...DndParams,
            positionId: locationsPositionId,
            isDroppable: true,
            employmentId: employment.id,
            allowedPositionIds: employment.activeLocationsPositionIds,
          }}
          onCellClick={dateItem => this.onShiftCellClick(
            dateItem,
            employment.id,
            locationsPositionId,
          )}
          isLastRow={this.isLastRow && (index === lastRowIndex)}
          scopedSlots= {scopedSlots}>
          <GridCell slot="label"
            isHeader={true}
            style={{ borderColor: color }}
            class={styles.positionCell}>
            <ToggleCell
              disabled={!isToggleVisible}
              isOpen={isOpen}
              onToggle={() => this.toggleEmploymentRow(employment.id)}>
              <Employee employee={employment} isLabel={true} />
            </ToggleCell>
          </GridCell>
          {
            this.showSummary
            && <SummaryCell slot="total"
              value={aggregationValue} color={SummaryCellColor.GREY}/>
          }
        </GridRow>);
      });
  }

  public render() {
    const { isPositionEmpty } = this;

    return (<div class={gridStyles.gridTableSubgrid}>
      <GridRow
        cellClass={gridStyles.gridTableContentCellPaddingBottom}
        // if last row is open then this is not a lost row
        // but last row of inner grid
        isLastRow={this.isLastRow
          && !this.openPositionIds.includes(this.position.locationsPositionId)}
        dates={this.dates}
        dndParams={{
          ...DndParams,
          positionId: this.position.locationsPositionId,
          isDroppable: true,
          employmentId: 0,
        }}
        onCellClick={dateItem => this.onShiftCellClick(
          dateItem,
          undefined,
          this.position.locationsPositionId,
        )}
        scopedSlots= {this.cellScopedSlots}>
        <GridCell slot="label"
          isHeader={true}
          style={{ borderColor: this.position.color }}
          class={styles.positionCell}>
          <ToggleCell
            disabled={isPositionEmpty}
            title={this.position.name}
            isOpen={this.isPositionOpen}
            onToggle={() => this.togglePosition(this.position.locationsPositionId)}>
            {this.position.name}
          </ToggleCell>
        </GridCell>
        {
          this.showSummary
        && <SummaryCell slot="total" value={this.positionWorkersCount} color={SummaryCellColor.GREY}/>}
      </GridRow>
      {this.isPositionOpen
        && this.renderPositionUsers()}
    </div>);
  }
}

export default Row;
