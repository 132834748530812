/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */

import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent as SE } from 'vue-tsx-support/types/dom';
import { GQLAbsenceConflictsFragmentFragment } from 'codegen/gql-types';
import Prompt, { Slot } from 'components/prompt/Prompt';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { ButtonKind, ButtonColor } from 'components/form/base-button/types';
import Conflicts from '../conflicts/Conflicts';
import type { Absence, Employment } from '../types';

interface Props {
  absence: Pick<Absence, 'absenceReason'> & { employment: Employment; startsAt: Date };
  canManage?: boolean;
  conflicts: GQLAbsenceConflictsFragmentFragment['conflicts'];
  isCurrentEmployment?: boolean;
  isOpen?: boolean;
  isSubmitting?: boolean;
}

@Component
export default class DialogConflicts extends TsxComponent<Props, {
  onCancelClick: (e: SE<HTMLButtonElement, KeyboardEvent | MouseEvent>) => void;
  onApproveAndIgnoreClick: (e: SE<HTMLButtonElement, KeyboardEvent | MouseEvent>) => void;
  onApproveAndRemoveClick: (e: SE<HTMLButtonElement, KeyboardEvent | MouseEvent>) => void;
}> {
  @Prop()
  protected absence: Props['absence'];

  @Prop()
  protected canManage: Props['canManage'];

  @Prop({ default: () => [] })
  protected conflicts: Props['conflicts'];

  @Prop()
  public isCurrentEmployment: Props['isCurrentEmployment'];

  @Prop()
  public isOpen: Props['isOpen'];

  @Prop()
  public isSubmitting: Props['isSubmitting'];

  protected get canIgnore(): boolean {
    return this.conflicts.every(conflict => conflict.ignorable);
  }

  protected get hasAnyShiftCollision(): boolean {
    return this.conflicts.some(conflict => (
      conflict.__typename === 'CollisionsConflict' && conflict.shifts && conflict.shifts.length > 0
    ));
  }

  protected onCancelClick() {
    this.$emit('cancelClick');
  }

  protected onApproveAndIgnoreClick() {
    this.$emit('approveAndIgnoreClick');
  }

  protected onApproveAndRemoveClick() {
    this.$emit('approveAndRemoveClick');
  }

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        onCloseClick={this.onCancelClick}
        title={this.$t('conflicts.title')}
      >
        <Conflicts
          absence={this.absence}
          conflicts={this.conflicts}
          isCurrentEmployment={this.isCurrentEmployment}
        />

        <template slot={Slot.ACTION_BUTTONS}>
          <Button type="button"
            color={ButtonColor.SECONDARY}
            kind={ButtonKind.STROKE}
            onClick={this.onCancelClick}
          >{this.$t('modal.buttonCancel')}</Button>
          { this.canIgnore
            && <Button type="button"
              color={ButtonColor.PRIMARY}
              kind={ButtonKind.FILL}
              disabled={this.isSubmitting}
              onClick={this.onApproveAndIgnoreClick}>
              {this.$t('conflicts.buttonApproveAndIgnore')}
            </Button>
          }
        </template>

        {this.canIgnore && this.hasAnyShiftCollision && this.canManage
        && <Button
          disabled={this.isSubmitting}
          onClick={this.onApproveAndRemoveClick}
          kind={ButtonKind.FILL}
          size={Size.SMALL}
          slot={Slot.OTHER_ACTIONS}
        >
          {this.$t('conflicts.buttonApproveAndRemove')}
        </Button>}
      </Prompt>
    );
  }
}
