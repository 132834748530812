import Loader from 'components/loader/Loader';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Screen from './Screen';

@Component
export default class ScreenLoading extends TsxComponent<{}> {
  public render() {
    return (
      <Screen
        visual={<Loader />}
        message={this.$t('screens.loading.message')}
      />
    );
  }
}
