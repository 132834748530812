import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import SpIcon from 'components/sp-icon/SpIcon';
import { Route } from 'components/shift-schedule/routes';
import DayNote from '../day-note/DayNote';
import styles from './day-notes-popup.css';

export default Vue.component('DayNotesPopup', {
  data() {
    return {
      popupStyle: { left: null, top: null },
    };
  },
  computed: {
    ...mapState({
      date: state => state.calendar.notes.dayNotesPopup.date,
      isOpen: state => state.calendar.notes.dayNotesPopup.isOpen,
      shiftplanId: state => state.calendar.common.shiftplan.id,
      position: state => state.calendar.notes.dayNotesPopup.position,
      element: state => state.calendar.notes.dayNotesPopup.element,
    }),
    ...mapGetters('dayNotes', {
      canManageNotes: 'canManage',
    }),
    ...mapGetters('calendar/notes/dayNotesPopup', ['notes', 'specialDates', 'dateKey']),
    isSpecialDate() {
      return this.specialDates.length > 0;
    },
    isWithinShiftplan() {
      return this.date.isWithinShiftplan;
    },
  },
  watch: {
    date() {
      if (this.isOpen) {
        this.$nextTick(() => this.updatePopupPosition());
      }
    },
  },
  methods: {
    ...mapActions('calendar/notes/dayNotesPopup', ['closeDayNotePopup']),
    updatePopupPosition() {
      // accessing root element to get relative position
      const { left: rootElemLeft, width: rootElemWidth } = this.$root.$el.getBoundingClientRect();
      const {
        left: parentLeft,
        top: parentTop,
        height: parentHeight,
      } = this.element.getBoundingClientRect();
      const { width } = this.$el.getBoundingClientRect();
      // set default values
      this.popupStyle.left = `${parentLeft}px`;
      // shift one row up
      this.popupStyle.top = `${document.scrollingElement.scrollTop + parentTop - parentHeight}px`;

      if (parentLeft - rootElemLeft < 0) {
        this.popupStyle.left = 0;
      }
      if (parentLeft + width > rootElemWidth + rootElemLeft) {
        this.popupStyle.left = `${rootElemWidth - width + rootElemLeft}px`;
      }
    },
    addNote() {
      this.$router.push({
        name: Route.DAY_NOTE,
        params: {
          shiftplanId: this.shiftplanId.toString(),
        },
        query: {
          startsAt: this.date.dateKey,
        },
      });
    },
    showNote(noteId) {
      this.$router.push({
        name: Route.DAY_NOTE,
        params: {
          id: noteId.toString(),
          shiftplanId: this.shiftplan.id.toString(),
        },
      });
    },
  },
  render() {
    return ((this.date !== null)
    && <div class={[styles.dayNotesPopup, {
      [styles.dayNotesPopupIsOpen]: this.isOpen,
    }]}
    style={this.popupStyle}>
      <header class={[styles.dayNotesPopupHeaderWrapper,
        { [styles.dayNotesPopupHeaderWrapperIsSpecial]: this.isSpecialDate }]}>
        <header class={styles.dayNotesPopupHeader}>
          <div>
            {this.isSpecialDate
                && this.specialDates.map(it => (<div class={styles.dayNotesPopupSpecialDateTitle}>
                  {it.name}
                </div>))}
            <div class={styles.dayNotesPopupHeaderTitle}>
              {this.date.date.format('dddd')}
            </div>
          </div>
          <button class={styles.dayNotesPopupCloseButton}
            onClick={(e) => {
              e.stopPropagation();
              this.closeDayNotePopup();
            }}>
            <SpIcon name="clear" />
          </button>
        </header>
        <div class={styles.dayNotesPopupSubheader}>
          {this.date.date.format('DD')}
        </div>
      </header>
      <div class={styles.dayNotesPopupContainer}>
        {this.notes.map(note => <DayNote note={note}/>)}
      </div>
      {this.isWithinShiftplan && this.canManageNotes && <button class={styles.dayNotesPopupAddNote}
        onClick={(e) => {
          e.stopPropagation();
          this.addNote();
          this.closeDayNotePopup();
        }}>
        <SpIcon name="plus" />
      </button>}
    </div>);
  },
});
