import { GQLRequestType } from 'codegen/gql-types';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { SentryTag } from 'services/logger/SentryTransport';
import { hasAlreadyStarted } from 'src/utils/date-related';
import Action from 'store/shifts/Action';
import { shiftsNS } from 'store/shifts/Store';
import type {
  CreateLeaveRequestFunction,
  DeleteRequestFunction,
  Shift,
} from 'store/shifts/Store';
import { StoreActionState } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './leave-shift.css';

interface Props{
  shift?: Shift;
}

interface Events{
  onRefetchShift: () => void;
}

@Component
export default class LeaveShift extends TsxComponent<Props, Events> {
  protected isDeleteConfirmDialogOpen = false;

  protected isSubmitting = false;

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @shiftsNS.Action(Action.CREATE_LEAVE_REQUEST)
  protected createLeaveRequest: CreateLeaveRequestFunction;

  @shiftsNS.Action(Action.DELETE_REQUEST)
  protected deleteRequest: DeleteRequestFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public shift?: Props['shift'];

  protected get hasShiftAlreadyStarted() {
    return hasAlreadyStarted(this.shift);
  }

  protected get isCurrentlyAssigned() {
    if (!this.shift || !Array.isArray(this.shift.staffShifts)) {
      return false;
    }

    return this.shift.staffShifts.some(staffShift => (
      staffShift.employment && staffShift.employment.id === this.currentEmployment?.id
    ));
  }

  protected get ownRequest() {
    const { currentEmployment } = this;

    if (!this.shift || !currentEmployment) {
      return undefined;
    }

    return this.shift.requests.find(request => (
      request.type === GQLRequestType.CHANGEREQUEST
        && request.employment
        && request.employment.id === currentEmployment.id
    ));
  }

  protected async onCancelLeaveRequest() {
    this.isDeleteConfirmDialogOpen = true;
  }

  protected onCancelLeaveRequestCancel() {
    this.isDeleteConfirmDialogOpen = false;
  }

  protected async onCancelLeaveRequestConfirm() {
    if (!this.shift || !this.ownRequest) {
      return undefined;
    }

    this.isSubmitting = true;

    const response = await this.deleteRequest({
      id: this.ownRequest.id,
      shiftId: this.shift.id,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, LeaveShift.name]],
        message: JSON.stringify(response),
      });

      this.isDeleteConfirmDialogOpen = false;
      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return false;
    }

    this.$emit('refetchShift');

    this.isDeleteConfirmDialogOpen = false;
    this.isSubmitting = false;

    return this.showSnackbar({
      message: this.$t('shifts.leaveShift.requestCanceled'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected async onCreateLeaveRequest() {
    if (!this.shift) {
      return undefined;
    }

    this.isSubmitting = true;

    const response = await this.createLeaveRequest({
      shiftId: this.shift.id,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, LeaveShift.name]],
        message: JSON.stringify(response),
      });

      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return undefined;
    }

    this.$emit('refetchShift');

    this.isSubmitting = false;

    return this.showSnackbar({
      message: this.$t('shifts.leaveShift.requestCreated'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  public beforeRouteEnter(_to, _from, next) {
    next((vm: this) => {
      const isLeaveShiftPossible = vm.currentEmployment?.isEmployee
        && !vm.shift?.canManage
        && !vm.hasShiftAlreadyStarted
        && vm.isCurrentlyAssigned;

      if (!isLeaveShiftPossible) {
        const { matched } = vm.$route;
        // FAQ: last element is the route itself, so the one before is the route's parent
        const routeParent = matched[matched.length - 2] || matched[0];
        vm.$router.push({ name: routeParent.name, replace: true });
      }
    });
  }

  public render() {
    if (this.ownRequest) {
      return (
        <div class={styles.leaveShift}>
          <p class={styles.leaveShiftMessage}>
            {this.$t('shifts.leaveShift.messageCancel')}
          </p>

          <p class={styles.leaveShiftMessageState}>
            {this.$t('shifts.leaveShift.messageManagerApprovalRequired')}
          </p>

          <Button
            class={styles.leaveShiftButton}
            color={ButtonColor.ERROR}
            disabled={this.isSubmitting}
            onClick={this.onCancelLeaveRequest}
            size={Size.SMALL}
            kind={ButtonKind.GHOST}
          >
            {this.$t('shifts.leaveShift.buttonCancel')}
          </Button>

          <DialogConfirmDelete
            isOpen={this.isDeleteConfirmDialogOpen}
            isSubmitting={this.isSubmitting}
            onCancel={this.onCancelLeaveRequestCancel}
            onConfirm={this.onCancelLeaveRequestConfirm}
            title={this.$t('shifts.leaveShift.titleConfirmCancel')}
          >
            {this.$t('shifts.leaveShift.messageConfirmCancel')}
          </DialogConfirmDelete>
        </div>
      );
    }

    return (
      <div class={styles.leaveShift}>
        <p class={styles.leaveShiftMessage}>
          {this.$t('shifts.leaveShift.messageCreate')}
        </p>

        <Button
          class={styles.leaveShiftButton}
          color={ButtonColor.SUCCESS}
          disabled={this.isSubmitting}
          onClick={this.onCreateLeaveRequest}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
        >
          {this.$t('shifts.leaveShift.buttonCreate')}
        </Button>
      </div>
    );
  }
}
