import { GQLTagContext } from 'codegen/gql-types';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import Spinner from 'components/spinner/Spinner';
import { employmentEvaluationsNS } from 'store/employment-evaluations/Store';
import type { FetchEmploymentEvaluationFunction } from 'store/employment-evaluations/Store';
import { evaluationsNS } from 'store/evaluations/Store';
import type { FetchEvaluationFunction } from 'store/evaluations/Store';
import { Action } from 'store/normalized-store';
import { paygradeTypesNS } from 'store/paygrade-types/Store';
import type { FetchAllPaygradeTypesFunction } from 'store/paygrade-types/Store';
import { shiftsNS } from 'store/shifts/Store';
import type { FetchShiftFunction, Shift } from 'store/shifts/Store';
import { tagsNS } from 'store/tags/Store';
import type { FetchAllTagsFunction, Tag } from 'store/tags/Store';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'utils/store';
import type { GetById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import FormContainerCompany from './form/FormContainerCompany';
import FormContainerEmployment from './form/FormContainerEmployment';
import type { Evaluation } from './types';

import styles from './evaluation-container.css';

interface Props {
  isDisabled?: boolean;
  staffShiftId: number;
}

@Component
export default class EvaluationContainer extends TsxComponent<Props> {
  protected isLoading = true;

  @Prop()
  public isDisabled?: Props['staffShiftId'];

  @Prop()
  public staffShiftId: Props['staffShiftId'];

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @evaluationsNS.Action(Action.FETCH)
  protected fetchEvaluation: FetchEvaluationFunction;

  @employmentEvaluationsNS.Action(Action.FETCH)
  protected fetchEmploymentEvaluation: FetchEmploymentEvaluationFunction;

  @evaluationsNS.Getter('getByStaffShiftId')
  protected getEvaluationByStaffShiftId: GetById<Evaluation>;

  @employmentEvaluationsNS.Getter('getByStaffShiftId')
  protected getEmploymentEvaluationByStaffShiftId: GetById<Evaluation>;

  @paygradeTypesNS.Action(Action.FETCH_ALL)
  protected fetchPaygradeTypesAll: FetchAllPaygradeTypesFunction;

  @shiftsNS.Action(Action.FETCH)
  protected fetchShift: FetchShiftFunction;

  @shiftsNS.Getter
  protected canManageShift: (locationId: number, locationsPositionId: number) => boolean;

  @shiftsNS.Getter('getById')
  protected getShiftById: GetById<Shift>;

  @tagsNS.Action(Action.FETCH_ALL)
  protected fetchTagsAll: FetchAllTagsFunction;

  @tagsNS.Getter('items')
  protected tags: Tag[];

  protected get evaluation(): Evaluation | undefined {
    const evaluation = this.getEvaluationByStaffShiftId(this.staffShiftId);

    if (!evaluation) {
      return undefined;
    }

    return {
      ...evaluation,
      note: evaluation.note || '',
      totalPayment: evaluation.totalPayment || 0,
    };
  }

  protected get employmentEvaluation(): Evaluation | undefined {
    const employmentEvaluation = this.getEmploymentEvaluationByStaffShiftId(this.staffShiftId);

    if (!this.evaluation || (!employmentEvaluation && this.isManager)) {
      return undefined;
    }

    return {
      ...this.evaluation,
      ...employmentEvaluation,
      note: employmentEvaluation?.note || '',
      totalPayment: employmentEvaluation?.totalPayment || 0,
    };
  }

  protected get isEmploymentEvaluationPresent() {
    return !!this.getEmploymentEvaluationByStaffShiftId(this.staffShiftId);
  }

  protected get isManager() {
    return this.canManageShift(
      this.evaluation?.location.id || Number.NaN,
      this.evaluation?.locationsPosition.id || Number.NaN,
    );
  }

  protected get isSelfEvaluation() {
    return this.evaluation?.employment.id === this.currentEmploymentId;
  }

  protected get shift() {
    return this.getShiftById(this.evaluation?.shift.id || Number.NaN);
  }

  protected get staffShiftsTags() {
    return this.tags.filter(tag => tag.context === GQLTagContext.STAFF_SHIFT_TAG);
  }

  protected async populate() {
    this.isLoading = true;

    const result = await executeStoreActionWithFailureSnackbar(
      this,
      { staffShiftId: this.staffShiftId },
      this.fetchEvaluation,
      '',
    );

    if (result.state !== StoreActionState.SUCCESS) {
      this.isLoading = false;
      return;
    }

    await this.fetchEmploymentEvaluation({ staffShiftId: this.staffShiftId });

    if (this.evaluation) {
      await this.fetchShift({ id: this.evaluation.shift.id });
      await this.fetchPaygradeTypesAll({});
      await this.fetchTagsAll({
        context: [GQLTagContext.SHIFT_TAG, GQLTagContext.STAFF_SHIFT_TAG],
        locationIds: null,
      });
    }

    this.isLoading = false;
  }

  protected onSubmit() {
    this.populate();
  }

  public mounted() {
    this.populate();
  }

  public render() {
    if (this.isLoading) {
      return (
        <Spinner class={styles.evaluationContainerSpinner} />
      );
    }

    if (!this.evaluation || (!this.isManager && !this.isSelfEvaluation)) {
      return null;
    }

    return (
      <div class={{
        [styles.evaluationContainer]: true,
        [styles.evaluationContainerFlipped]: !this.isManager,
      }}>
        <FormContainerCompany
          evaluation={this.evaluation}
          isBringShiftsEnabled={!!this.currentCompany?.canUseBringShifts}
          isDisabled={!this.isManager}
          onSubmit={this.onSubmit}
          staffShiftsTags={this.staffShiftsTags}
        />

        {
          (this.isManager || this.shift?.canEvaluate) && this.employmentEvaluation && (
            <FormContainerEmployment
              evaluation={this.employmentEvaluation}
              isBringShiftsEnabled={!!this.currentCompany?.canUseBringShifts}
              isDisabled={this.isManager}
              isEmploymentEvaluationPresent={this.isEmploymentEvaluationPresent}
              onSubmit={this.onSubmit}
              staffShiftsTags={this.staffShiftsTags}
            />
          )
        }
      </div>
    );
  }
}
