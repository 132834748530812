import type { GQLAbsence } from 'codegen/gql-types';
import { deleteItems, getUpdatedItems } from 'components/calendar-common/common/store/Helpers';
import DefaultActions from 'components/calendar-common/DefaultActions';
import Employment from 'components/calendar-common/employments/Employment';
import moment from 'moment';
import { Module } from 'vuex';
import RootStoreState from 'src/store/RootStoreState';
import Absence from './Absence';
import Mutations from './Mutations';

export enum AbsenceStates {
  NEW = 'new',
  ACCEPTED = 'accepted',
}

// tslint:disable-next-line:variable-name
export const AbsenceIcons = {
  [AbsenceStates.NEW]: 'absence',
  [AbsenceStates.ACCEPTED]: 'absences-state-approved',
};

export interface StoreState {
  items: GQLAbsence[];
}

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    // absences data comes as events with startsAt and endsAt
    // we want to make one day events from those
    [Mutations.SET](state, items) {
      state.items = items;
    },
    [Mutations.UPDATE](state, updatedItems: GQLAbsence[]) {
      state.items = getUpdatedItems(state.items, updatedItems);
    },
    [Mutations.DELETE](state, ids: number[]) {
      state.items = deleteItems(state.items, ids);
    },
  },
  actions: {
    [DefaultActions.SET]({ commit }, items) {
      commit(Mutations.SET, items);
    },
    [DefaultActions.UPDATE]({ commit }, items) {
      commit(Mutations.UPDATE, items);
    },
    [DefaultActions.DELETE]({ commit }, ids) {
      commit(Mutations.DELETE, ids);
    },
  },
  getters: {
    absences(state) {
      // unroll absence into a separate event for each absence day
      return state.items.reduce(
        (acc, absence) => {
          const momentStart = moment(absence.startsAt);
          const momentEnd = moment(absence.endsAt);
          while (momentStart.isSameOrBefore(momentEnd, 'day')) {
            acc.push(new Absence({
              ...absence,
              reason: absence.absenceReason,
              date: momentStart.clone(),
              employment: new Employment(absence.employment),
            }));
            momentStart.add(1, 'day');
          }
          return acc;
        },
        [] as Absence[],
      );
    },
  },
});
