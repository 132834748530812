import { TFunction } from 'i18next';
import { RouteConfig, Route as RouteType } from 'vue-router';
import Absences from './Absences';
import DialogAbsenceContainer from './dialog-absence/DialogAbsenceContainer';

export enum Route {
  ROOT = 'absencesIndex',
  DIALOG_EDIT = 'absencesDialogEdit',
  DIALOG_NEW = 'absencesDialogNew',
}

const routes: RouteConfig[] = [
  {
    component: Absences,
    name: Route.ROOT,
    path: '/absences',
    meta: {
      title: (t: TFunction) => t('absences.pageTitle'),
    },
    children: [{
      component: DialogAbsenceContainer,
      name: Route.DIALOG_NEW,
      path: 'new',
    }, {
      component: DialogAbsenceContainer,
      name: Route.DIALOG_EDIT,
      path: ':id',
      props: (route: RouteType) => ({
        absenceId: Number.parseInt(route.params.id, 10),
      }),
    }],
  },
];

export default routes;
