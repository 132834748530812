enum Action {
  ACCEPT_REPLACE_OFFER = 'acceptReplaceOffer',
  CONFIRM_REPLACE_REQUEST = 'confirmReplaceRequest',
  CREATE_JOIN_REQUEST = 'createJoinRequest',
  CREATE_LEAVE_REQUEST = 'createLeaveRequest',
  CREATE_REPLACE_REQUEST = 'createReplaceRequest',
  DELETE_REQUEST = 'deleteRequest',
  WITHDRAW_ACCEPTED_REPLACE_REQUEST = 'withdrawAcceptedReplaceRequest',
  CONNECT_SHIFTS = 'connectShifts',
  DISCONNECT_SHIFT = 'disconnectShift',
  ADD_TAG_TO_SHIFT = 'addTagToShift',
  CREATE_SHIFT_BREAK = 'createShiftBreak',
  COPY_SHIFT = 'copyShift',
  ASSIGN_EMPLOYEE_TO_SHIFT_ROTATION_GROUP = 'assignEmployeeToShiftRotationGroup',
}

export default Action;
