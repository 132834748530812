import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import type { SelectedTimeframe } from 'components/datepicker/types';
import getEmploymentFilterStore, { StoreState as EmploymentFilterStoreState } from 'components/shift-schedule/filter-box-employment/store/Store';
import getQuickFilterStore, { StoreState as QuickFilterStoreState } from 'components/shift-schedule/filter-box-quick/store/Store';
import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import getShiftPresetsFilterStore from 'components/shift-schedule/filter-box-shift-preset/store/Store';
import getShiftRotationGroupsFilterStore, { StoreState as ShiftRotationGroupFilterStoreState } from 'components/shift-schedule/filter-box-shift-rotation-group/store/Store';
import getTagsFilterStore, { StoreState as TagFilterStoreState } from 'components/shift-schedule/filter-box-tag/store/Store';
import getAssignmentGroupsFilterStore from 'components/shift-schedule/filter-box-assignment-group/store/Store';
import getLocationsPositionsFilterStore from 'components/shift-schedule/filter-box-locations-position/store/Store';
import { StoreState as FilterStoreState } from 'store/filter-store';
import { isSameDay } from 'date-fns';
import getAbsenceReasonsFilterStore from 'components/shift-schedule/filter-box-absence-reason/store/Store';
import { PayloadParameter } from 'utils/store';
import {
  ViewKind, SlotDisplayStyle, TimeframeKind, PrintViewKind, PrintTimeframeKind,
} from '../../calendar-common/Enums';
import Action from './Action';
import Mutation from './Mutation';
import { FiltersMap } from '../../calendar-common/filters/Store';

export const PrintTypeIntervals: Record<PrintViewKind, PrintTimeframeKind> = {
  [PrintViewKind.LIST]: TimeframeKind.DAY,
  [PrintViewKind.POSITIONS_WEEK]: TimeframeKind.WEEK,
  [PrintViewKind.POSITIONS_MONTH]: TimeframeKind.MONTH,
  [PrintViewKind.EMPLOYMENTS_WEEK]: TimeframeKind.WEEK,
  [PrintViewKind.EMPLOYMENTS_MONTH]: TimeframeKind.MONTH,
};

export const shiftScheduleNS = namespace('shiftSchedule');

export const shiftScheduleBackgroundJobsMapNS = namespace('shiftSchedule/backgroundJobsMap');

export interface StoreState {
  timeframe: SelectedTimeframe;
  timeframeKind: TimeframeKind;
  viewKind: ViewKind;
  printViewKind: PrintViewKind;
  slotDisplayStyle: SlotDisplayStyle;
}

export interface BackgroundJobsMapStoreState {
  byShiftplanId: Record<number, string>;
}

export interface SetTimeframePayload {
  timeframeKind?: TimeframeKind;
  timeframe?: SelectedTimeframe;
}

export interface ModuleState extends StoreState {
  absenceReasonsFilter: FilterStoreState;
  assignmentGroupsFilter: FilterStoreState;
  employmentFilter: EmploymentFilterStoreState;
  locationsPositionsFilter: FilterStoreState;
  quickFilter: QuickFilterStoreState;
  shiftPresetsFilter: FilterStoreState;
  tagsFilter: TagFilterStoreState;
  shiftRotationGroupsFilter: ShiftRotationGroupFilterStoreState;
  backgroundJobsMap: BackgroundJobsMapStoreState;
}

export type RemoveJobFunction = (shiftplanId: number) => void;

export type SetJobFunction = (payload: { jobId: string; shiftplanId: number }) => void;

const getBackgroundJobsMapStore = (): Module<BackgroundJobsMapStoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    byShiftplanId: {},
  },
  mutations: {
    [Mutation.SET_JOB](state, { jobId, shiftplanId }) {
      state.byShiftplanId = {
        ...state.byShiftplanId,
        [shiftplanId]: jobId,
      };
    },
    [Mutation.REMOVE_JOB](state, shiftplanId: number) {
      const tmp = { ...state.byShiftplanId };
      delete tmp[shiftplanId];

      state.byShiftplanId = tmp;
    },
  },
  actions: {
    [Action.SET_JOB]({ commit }, payload: PayloadParameter<SetJobFunction>) {
      commit(Mutation.SET_JOB, { jobId: payload.jobId, shiftplanId: payload.shiftplanId });
    },
    [Action.REMOVE_JOB]({ commit }, payload: PayloadParameter<RemoveJobFunction>) {
      commit(Mutation.REMOVE_JOB, payload);
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const getStore = (graphqlClient: ApolloClient<NormalizedCacheObject>):
Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    timeframe: {
      startsAt: new Date(),
      endsAt: new Date(),
    },
    timeframeKind: TimeframeKind.WEEK,
    viewKind: ViewKind.AGENDA,
    printViewKind: PrintViewKind.LIST,
    slotDisplayStyle: SlotDisplayStyle.FULLNAMES,
  },
  mutations: {
    [Mutation.SET_SLOT_DISPLAY_STYLE](state, slotDisplayStyle: SlotDisplayStyle) {
      state.slotDisplayStyle = slotDisplayStyle;
    },
    [Mutation.SET_TIMEFRAME](state, timeframe: SelectedTimeframe) {
      state.timeframe = timeframe;
    },
    [Mutation.SET_TIMEFRAME_KIND](state, timeframeKind: TimeframeKind) {
      state.timeframeKind = timeframeKind;
    },
    [Mutation.SET_VIEW_KIND](state, viewKind: ViewKind) {
      state.viewKind = viewKind;
    },
    [Mutation.SET_PRINT_VIEW_KIND](state, printViewKind: PrintViewKind) {
      state.printViewKind = printViewKind;
    },
  },
  getters: {
    printTimeframeKind(state) {
      return PrintTypeIntervals[state.printViewKind];
    },
    shiftScheduleQuickFilters(state, getters, rootState): FiltersMap[] {
      const { currentCompany } = rootState.auth;

      return [
        FiltersMap.STAFF_REQUEST,
        FiltersMap.LEAVE_REQUEST,
        currentCompany?.shiftSwapEnabled && FiltersMap.REPLACE_REQUEST,
        FiltersMap.UNDERASSIGNED_SHIFTS,
        FiltersMap.FULL_SHIFTS,
        currentCompany?.isOverassignmentAllowed && FiltersMap.OVERASSIGNED_SHIFTS,
        FiltersMap.NEW_ABSENCES,
        FiltersMap.ACCEPTED_ABSENCES,
        FiltersMap.SPECIAL_DATES,
        FiltersMap.DAY_NOTES,
        currentCompany?.isTagsAllowed && FiltersMap.SHIFTS_WITHOUT_TAGS,
        currentCompany?.shiftRotationEnabled && FiltersMap.SHIFT_ROTATION,
        FiltersMap.SUMMARY,
      ].filter((filter): filter is FiltersMap => !!filter);
    },
    absencePlanningQuickFilters(state, getters, rootState): FiltersMap[] {
      const { currentCompany } = rootState.auth;

      return [
        FiltersMap.SPECIAL_DATES,
        currentCompany?.shiftRotationEnabled && FiltersMap.SHIFT_ROTATION,
        FiltersMap.SUMMARY,
      ].filter((filter): filter is FiltersMap => !!filter);
    },
    printShiftScheduleQuickFilters(): FiltersMap[] {
      return [
        FiltersMap.NEW_ABSENCES,
        FiltersMap.ACCEPTED_ABSENCES,
        FiltersMap.NOTES,
        FiltersMap.POSITION_NOTES,
      ];
    },
  },
  actions: {
    [Action.SET_SLOT_DISPLAY_STYLE]({ commit }, slotDisplayStyle: SlotDisplayStyle) {
      commit(Mutation.SET_SLOT_DISPLAY_STYLE, slotDisplayStyle);
    },
    [Action.SET_VIEW_KIND]({ commit }, viewKind: ViewKind) {
      commit(Mutation.SET_VIEW_KIND, viewKind);
    },
    [Action.SET_PRINT_VIEW_KIND]({ commit }, printViewKind: PrintViewKind) {
      commit(Mutation.SET_PRINT_VIEW_KIND, printViewKind);
    },
    [Action.SET_TIMEFRAME]({ commit, state }, payload: SetTimeframePayload) {
      const extendedPayload = {
        timeframeKind: state.timeframeKind,
        timeframe: state.timeframe,
        ...payload,
      };

      if (!(isSameDay(extendedPayload.timeframe.endsAt, state.timeframe.endsAt)
      && isSameDay(extendedPayload.timeframe.startsAt, state.timeframe.startsAt))) {
        commit(Mutation.SET_TIMEFRAME, extendedPayload.timeframe);
      }
      commit(Mutation.SET_TIMEFRAME_KIND, extendedPayload.timeframeKind);
    },
  },
  modules: {
    absenceReasonsFilter: getAbsenceReasonsFilterStore(),
    assignmentGroupsFilter: getAssignmentGroupsFilterStore(),
    employmentFilter: getEmploymentFilterStore(graphqlClient),
    locationsPositionsFilter: getLocationsPositionsFilterStore(),
    quickFilter: getQuickFilterStore(),
    shiftPresetsFilter: getShiftPresetsFilterStore(),
    shiftRotationGroupsFilter: getShiftRotationGroupsFilterStore(),
    tagsFilter: getTagsFilterStore(),
    backgroundJobsMap: getBackgroundJobsMapStore(),
  },
});

export default getStore;
