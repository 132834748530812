import {
  addDays,
  isAfter,
  isBefore,
  subDays,
} from 'date-fns';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import {
  getDateInTimeZone,
  LOCALE_TIMEZONE,
  eachDayOfTimeframe,
} from 'src/utils/date-related';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { getTimeframeFromDates } from 'utils/timeframe-helpers';
import { TimeframeKind } from 'components/calendar-common/Enums';
import Datepicker from './Datepicker';
import { SelectedTimeframe, SelectionMode, SelectionState } from './types';

interface Props {
  max?: Date;
  min?: Date;
  maxIntervalDuration?: number;
  selection: SelectedTimeframe;
  intervalSelectionMode?: IntervalSelectionMode;
  timeZone?: string;
}
interface Events {
  onChange: EventPayload<SelectedTimeframe, HTMLButtonElement, MouseEvent>;
  onWindowResize: void;
}

export enum IntervalSelectionMode {
  WEEK,
  CUSTOM
}

@Component
export default class DatepickerInterval extends TsxComponent<Props, Events> {
  /*
    it is storing correct moment of times,
    so conversion is needed when comparing with values
    that are used for UI representation
  */
  private currentSelection: Date[] = [];

  protected currentHoveredDates: SelectedTimeframe | null = null;

  private firstDate: Date | null = null;

  @Prop()
  public max: Props['max'];

  @Prop()
  public min: Props['min'];

  @Prop()
  public maxIntervalDuration: Props['maxIntervalDuration'];

  @Prop()
  public selection: Props['selection'];

  @Prop({ default: SelectionMode.INTERVAL })
  public intervalSelectionMode: Props['intervalSelectionMode'];

  @Prop()
  public timeZone?: Props['timeZone'];

  @Watch('selection', { immediate: true })
  public onSelectionChange() {
    const { startsAt, endsAt } = this.selection;

    this.updateCurrentSelectionFromTimeframe(startsAt, endsAt);
  }

  private get maxDate() {
    if (!this.maxIntervalDuration || !this.firstDate) {
      return this.max;
    }

    const maxByRange = addDays(this.firstDate, this.maxIntervalDuration - 1);

    if (!this.max) {
      return maxByRange;
    }

    return isBefore(this.max, maxByRange) ? this.max : maxByRange;
  }

  private get minDate() {
    if (!this.maxIntervalDuration || !this.firstDate) {
      return this.min;
    }

    const minByRange = subDays(this.firstDate, this.maxIntervalDuration - 1);

    if (!this.min) {
      return minByRange;
    }

    return isBefore(this.min, minByRange) ? minByRange : this.min;
  }

  private get timeZoneValue() {
    return this.timeZone || LOCALE_TIMEZONE;
  }

  private updateCurrentSelectionFromTimeframe(startsAt: Date, endsAt: Date) {
    this.currentSelection = eachDayOfTimeframe({ startsAt, endsAt }, this.timeZoneValue);
  }

  private handleFirstSelection(
    { event, payload }: EventPayload<Date[], HTMLButtonElement, MouseEvent>,
  ) {
    if (this.intervalSelectionMode !== IntervalSelectionMode.WEEK) {
      const [day] = payload;

      this.firstDate = day;

      return;
    }

    // week mode completes selection after first click
    this.handleWeekModeChange({ event, payload });
  }

  private handleWeekModeChange(
    { event, payload: days }: EventPayload<Date[], HTMLButtonElement, MouseEvent>,
  ) {
    const [day] = days;
    const { startsAt, endsAt } = getTimeframeFromDates(
      day,
      day,
      TimeframeKind.WEEK,
      this.timeZoneValue,
    );

    this.updateCurrentSelectionFromTimeframe(startsAt, endsAt);

    this.$emit('change', createEventPayload(event, {
      startsAt,
      endsAt,
    }));
  }

  private handleIntervalModeChange(
    { event, payload: days }: EventPayload<Date[], HTMLButtonElement, MouseEvent>,
  ) {
    if (this.intervalSelectionMode !== IntervalSelectionMode.CUSTOM) {
      return;
    }

    this.firstDate = null;

    const startsAt = days[0];
    const endsAt = days[days.length - 1];

    this.updateCurrentSelectionFromTimeframe(startsAt, endsAt);

    this.$emit('change', createEventPayload(event, {
      startsAt,
      endsAt,
    }));
  }

  private onWindowResize() {
    this.$emit('windowResize');
  }

  private onMouseEnter({ day, selectionState, currentSelection }:
  { day: Date; selectionState: SelectionState; currentSelection: Date[] }) {
    const momentOfTime = day;

    if (this.intervalSelectionMode === IntervalSelectionMode.CUSTOM) {
      if (selectionState === SelectionState.SINGLE_SELECTION) {
        /* we are guaranteed to have one selected element
          in current selection at this state
          Note that current selection is not matching this.currentSelection
          as interval selection is in progress
        */
        const [currentlySelectedDate] = currentSelection;
        const interval = isAfter(currentlySelectedDate, momentOfTime)
          ? {
            startsAt: momentOfTime,
            endsAt: currentlySelectedDate,
          }
          : {
            startsAt: currentlySelectedDate,
            endsAt: momentOfTime,
          };

        this.currentHoveredDates = {
          startsAt: getDateInTimeZone(interval.startsAt, this.timeZoneValue),
          endsAt: getDateInTimeZone(interval.endsAt, this.timeZoneValue),
        };
      }

      return;
    }

    const { startsAt, endsAt } = getTimeframeFromDates(
      momentOfTime,
      momentOfTime,
      TimeframeKind.WEEK,
      this.timeZoneValue,
    );

    this.currentHoveredDates = {
      startsAt: getDateInTimeZone(startsAt, this.timeZoneValue),
      endsAt: getDateInTimeZone(endsAt, this.timeZoneValue),
    };
  }

  public render() {
    return (
      <Datepicker
        selection={this.currentSelection}
        selectionMode={SelectionMode.INTERVAL}
        hoveredDates={this.currentHoveredDates}
        max={this.maxDate}
        min={this.minDate}
        timeZone={this.timeZoneValue}
        // interval mode uses normal start + end selection
        onChange={this.handleIntervalModeChange}
        onIntervalSingleSelection={this.handleFirstSelection}
        onWindowResize={this.onWindowResize}
        onMouseEnter={({ payload }) => this.onMouseEnter(payload)}
        onMouseLeave={() => {
          this.currentHoveredDates = null;
        }}
      />
    );
  }
}
