import { createEventPayload, EventPayload } from 'src/utils/events';
import { v4 as uuid } from 'uuid';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './item.css';

export enum Slot {
  ICON = 'icon',
}

interface Props {
  isSelected: boolean;
}

interface Events {
  onChange: EventPayload<boolean, HTMLInputElement, MouseEvent | KeyboardEvent>;
}

@Component
class Item extends TSXComponent<Props, Events> {
  private id = uuid();

  @Prop()
  public isSelected: Props['isSelected'];

  private onChange(e: SyntheticEvent<HTMLInputElement, MouseEvent | KeyboardEvent>) {
    this.$emit('change', createEventPayload(e, e.target.checked));
  }

  public render() {
    return (
      <li class={styles.item}>
        <input
          id={this.id}
          type="checkbox"
          checked={this.isSelected ? 'checked' : undefined}
          onChange={e => this.onChange(e)}
        />

        <label class={styles.itemLabel} for={this.id}>
          {this.$slots.default}
        </label>

        {this.$slots[Slot.ICON] && (
          <div>
            {this.$slots[Slot.ICON]}
          </div>
        )}
      </li>
    );
  }
}

export default Item;
