import { calendarDataNS } from 'components/calendar/data/Store';
import type { LoadShiftsFunction } from 'components//calendar/data/Store';
import { LOAD_SHIFTS } from 'components/calendar/data/Actions';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { shiftsNS } from 'components/calendar-common/shifts/store/Store';
import Action from 'components/calendar-common/shifts/store/Action';
import { redirectToNamedParent } from 'src/utils/route';
import DialogShiftContainer from 'components/dialog-shift/DialogShiftContainer';
import DefaultActions from 'components/calendar-common/DefaultActions';

export interface Props {
  shiftId?: number;
  shiftplanId: number;
}

export interface OnDeleteShiftPayload {
  shiftId: number;
  withConnected: boolean;
  connectedGroupId: number | undefined;
}

export interface OnUpdateShiftPayload {
  shiftId: number;
  withConnected: boolean;
  connectedGroupId: number | undefined;
}

@Component
export default class CalendarDialogShiftContainer extends TsxComponent<Props, {}> {
  @Prop()
  private shiftId?: Props['shiftId'];

  @Prop()
  private shiftplanId: Props['shiftplanId'];

  @calendarDataNS.Action(LOAD_SHIFTS)
  private loadShiftsAction: LoadShiftsFunction;

  @shiftsNS.Action(DefaultActions.DELETE)
  private deleteShift;

  @shiftsNS.Action(Action.DELETE_CONNECTED_SHIFTS)
  private deleteConnectedShifts;

  private onDeleteShift({ shiftId, withConnected, connectedGroupId }: OnDeleteShiftPayload) {
    if (withConnected) {
      this.deleteConnectedShifts(connectedGroupId);
    }

    this.deleteShift([shiftId]);
  }

  private onUpdateShift({ shiftId, withConnected, connectedGroupId }: OnUpdateShiftPayload) {
    if (withConnected) {
      this.loadShiftsAction({ connectedGroupId });
    }

    this.onLoadShift([shiftId]);
  }

  private onLoadShift(shiftIds: number[]) {
    this.loadShiftsAction({ shiftIds });
  }

  private onCloseClick() {
    redirectToNamedParent(this);
  }

  public render() {
    return (
      <DialogShiftContainer
        shiftId={this.shiftId}
        shiftplanId={this.shiftplanId}
        onCloseClick={this.onCloseClick}
        onCreateShiftSuccess={this.onLoadShift}
        onUpdateShiftSuccess={this.onUpdateShift}
        onDeleteShiftSuccess={this.onDeleteShift}
      />
    );
  }
}
