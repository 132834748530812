import { getRandomString } from 'src/utils/random';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import { IconName } from 'components/icons/types';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import styles from './input-checkbox.css';

export interface Props {
  id?: string;
  label: string;
  name: string;
  value?: string;
  isValid?: boolean;
  icon?: IconName;
  description?: string;
  isIndeterminate?: boolean;
  checked: boolean;
}

@Component({
  inheritAttrs: false,
})
export default class InputCheckbox extends TsxComponent<
Partial<HTMLInputElement> & Props, EventsOn<HTMLInputElement>> {
  public $refs: {
    checkboxed: HTMLInputElement;
  };

  @Prop({ default: () => `inp-${getRandomString()}` })
  protected id: Props['id'];

  @Prop()
  public label: Props['label'];

  @Prop()
  public name: Props['name'];

  @Prop()
  public checked: Props['checked'];

  @Prop()
  public value: Props['value'];

  @Prop({ default: true })
  public isValid: Props['isValid'];

  @Prop()
  public icon: Props['icon'];

  @Prop()
  public description: Props['description'];

  @Prop({ default: false })
  public isIndeterminate: Props['isIndeterminate'];

  @Watch('isIndeterminate')
  private onIsIndeterminateChange() {
    this.$refs.checkboxed.indeterminate = !!this.isIndeterminate;
  }

  public mounted() {
    this.onIsIndeterminateChange();
  }

  public render() {
    return (
      <div class={{
        [styles.inputCheckbox]: true,
        [styles.inputCheckboxDisabled]: !!this.$attrs.disabled,
        [styles.inputCheckboxInvalid]: !this.isValid,
      }}>
        <div class={styles.inputCheckboxInputWrapper}>
          <input
            {...{
              attrs: { ...this.$attrs },
              on: { ...this.$listeners },
            }}
            class={styles.inputCheckboxInput}
            id={this.id}
            name={this.name}
            type="checkbox"
            value={this.value}
            ref="checkboxed"
            checked={this.checked}
          />
          <label for={this.id} class={styles.inputCheckboxLabel}>
            {this.icon
            && <Icon class={styles.inputCheckboxIcon}
              name={this.icon}
              size={Size.MEDIUM} />}
            <div title={this.label} class={styles.inputCheckboxText}>
              {this.label}
              {this.description && (
                <div class={styles.inputCheckboxDescription}>{this.description}</div>
              )}
            </div>
          </label>
        </div>
      </div>
    );
  }
}
