import DropdownLegacy from 'components/dropdown-legacy/DropdownLegacy';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import ChatButton from 'components/main-view/chat-button/ChatButton';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'utils/url';
import Avatar from 'components/avatar/Avatar';
import { Size } from 'components/types';
import AvatarDefault from 'components/avatar/AvatarDefault';
import LocationDropdown from '../location-dropdown/LocationDropdown';
import { mainViewNS } from '../store/Store';
import styles from './header.css';

@Component
export default class Header extends TsxComponent<{}> {
  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.State
  protected currentUserId: AuthStoreState['currentUserId'];

  @mainViewNS.Getter
  protected isChatAllowed: boolean;

  public render() {
    return (
      <header class={styles.header}>
        <LocationDropdown class={styles.headerDropdown} />

        <DropdownLegacy
          class={styles.headerDropdown}
          id="header-dropdown"
          items={[
            {
              label: this.$t('header.profile'),
              href: getUrlWithApiPrefix('users', this.currentUserId?.toString() || '0'),
            },
            this.isSuperAdmin ? {
              label: this.$t('header.companyProfile'),
              href: getUrlWithApiPrefix(getUrlWithCompanyPrefix('', this.currentCompany)),
            } : undefined,
            'ruler',
            {
              label: this.$t('header.logout'),
              href: getUrlWithApiPrefix('logout'),
            },
          ]}
        >
          <div class={styles.headerDropdownInner}>
            {
              this.currentEmployment
                ? <Avatar
                  size={Size.SMALL}
                  firstName={this.currentEmployment?.firstName}
                  lastName={this.currentEmployment?.lastName}
                  imageUrl={this.currentEmployment?.pictureData?.pictureSmall}
                />
                : <AvatarDefault size={Size.SMALL}/>
            }
            <span class={styles.headerUser}>
              {this.currentEmployment?.firstName} {this.currentEmployment?.lastName}
            </span>
          </div>
        </DropdownLegacy>

        {this.isChatAllowed && <ChatButton />}
      </header>
    );
  }
}
