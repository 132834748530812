import { GQLPaygradeLevel } from 'codegen/gql-types';
import InputSelect from 'components/form/input-select/InputSelect';
import InputText from 'components/form/input-text/InputText';
import Icon from 'components/icons/Icon';
import Tooltip from 'components/tooltip/Tooltip';
import { Size } from 'components/types';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconPosition } from 'components/form/button/types';
import { IconName } from 'components/icons/types';
import { Option } from 'components/select-panel/SelectPanel';
import styles from './section.css';

export interface Pay {
  id: number;
  children: Pay[];
  isLocked?: boolean;
  level: GQLPaygradeLevel;
  parentId?: number;
  total?: number;
  typeId: number;
  value?: number;
  isCreated?: boolean;
  isUpdated?: boolean;
  isDeleted?: boolean;
}

interface Props {
  isBonusDisabled?: boolean;
  isDenseStyle?: boolean;
  isDisabled?: boolean;
  isInherited?: boolean;
  isRemoveDisabled?: boolean;
  paygrade: Pay;
  bonuses?: Pay[];
  optionsBonuses: Option<number>[];
  optionsRegular: Option<number>[];
}

interface Events {
  onAddBonusPaygrade: (payload: EventPayload<Pay, HTMLElement, UIEvent>) => void;
  onRemovePaygrade: (payload: EventPayload<Pay, HTMLElement, UIEvent>) => void;
  onChange: (payload: EventPayload<Pay, HTMLElement, UIEvent>) => void;
}

@Component
export default class Section extends TsxComponent<Props, Events> {
  @Prop({ default: false })
  public isBonusDisabled: Props['isBonusDisabled'];

  @Prop()
  public isDenseStyle?: Props['isDenseStyle'];

  @Prop({ default: false })
  public isDisabled: Props['isDisabled'];

  @Prop()
  public isInherited?: Props['isInherited'];

  @Prop({ default: false })
  public isRemoveDisabled: Props['isRemoveDisabled'];

  @Prop()
  public paygrade: Props['paygrade'];

  @Prop()
  public bonuses?: Props['bonuses'];

  @Prop()
  public optionsBonuses: Props['optionsBonuses'];

  @Prop()
  public optionsRegular: Props['optionsRegular'];

  protected get isBonusAddible() {
    return !this.isDisabled
      && (!this.isInherited || !this.isRemoveDisabled)
      && this.optionsBonuses.length !== this.bonuses?.length;
  }

  protected get optionsBonusesUnused() {
    const typesAlreadyUsed = new Set(this.bonuses?.map(o => o.typeId));

    return this.optionsBonuses
      .map(option => ({
        ...option,
        isDisabled: typeof option.value !== 'number' || typesAlreadyUsed.has(option.value),
      }));
  }

  protected onInput(
    e: SyntheticEvent<HTMLInputElement | HTMLButtonElement>,
    paygrade: Pay,
  ) {
    this.$emit('change', createEventPayload<Pay>(e, {
      ...paygrade,
      [e.target.name]: e.target.value,
    }));
  }

  public render() {
    return (
      <section class={{
        [styles.section]: true,
        [styles.sectionInherited]: this.isInherited && this.isRemoveDisabled,
        [styles.sectionDense]: this.isDenseStyle,
      }}>
        <div class={styles.sectionSub}>
          <InputSelect
            class={styles.sectionSelect}
            isDisabled={this.isDisabled || this.isInherited || this.paygrade.isLocked}
            label={this.$t('shifts.paygrades.labelPaygradeType')}
            name="typeId"
            onChange={e => this.onInput(e.event, this.paygrade)}
            options={this.optionsRegular}
            required={true}
            value={this.paygrade.typeId}
          />

          <div class={styles.sectionInput}>
            <InputText
              class={styles.sectionInputValue}
              disabled={this.isDisabled || (this.isInherited && this.isRemoveDisabled)}
              label={this.$t('shifts.paygrades.labelPaygradeValue')}
              min="0"
              name="value"
              onInput={e => this.onInput(e, this.paygrade)}
              required={true}
              step="0.01"
              type="number"
              value={this.paygrade.value?.toFixed(2)}
            />

            {
              this.isInherited && (
                <Tooltip
                  class={styles.sectionTooltip}
                  text={this.$t('shifts.paygrades.tooltipInheritedFrom.text', { name: this.$t(`shifts.paygrades.tooltipInheritedFrom.${this.paygrade.level.toLowerCase()}`) })}
                >
                  <Icon
                    class={styles.sectionIconInherited}
                    name={IconName.INFO}
                    title={this.$t('shifts.paygrades.tooltipInheritedFrom.text', { name: this.$t(`shifts.paygrades.tooltipInheritedFrom.${this.paygrade.level.toLowerCase()}`) })}
                    size={Size.SMALL}
                  />
                </Tooltip>
              )
            }
          </div>

          {
            this.paygrade.total !== undefined && (
              <InputText
                class={styles.sectionInput}
                disabled={true}
                label="Total"
                min="0"
                name="total"
                required={true}
                step="0.01"
                type="number"
                value={this.paygrade.total.toFixed(2)}
              />
            )
          }

          {
            !this.isDisabled && !this.isRemoveDisabled && (
              <Button
                color={ButtonColor.ERROR}
                icon={IconName.CLEAR}
                onClick={e => this.$emit('removePaygrade', createEventPayload(e, this.paygrade))}
                size={Size.SMALL}
                title={this.$t('shifts.paygrades.buttonRemovePaygrade')}
                kind={ButtonKind.GHOST}
                iconPosition={IconPosition.ALONE}
              />
            )
          }
        </div>

        {
          this.bonuses?.map(bonus => (
            <div class={styles.sectionSub}>
              <InputSelect
                class={styles.sectionSelect}
                isDisabled={this.isDisabled || this.isBonusDisabled || bonus.isLocked}
                key={`type#${bonus.id}`}
                label={this.$t('shifts.paygrades.labelBonusPaygradeType')}
                name="typeId"
                onChange={e => this.onInput(e.event, bonus)}
                options={this.optionsBonusesUnused}
                required={true}
                value={bonus.typeId}
              />

              <InputText
                class={styles.sectionInput}
                disabled={this.isDisabled || this.isBonusDisabled}
                key={`value#${bonus.id}`}
                label={this.$t('shifts.paygrades.labelPaygradeValue')}
                min="0"
                name="value"
                onInput={e => this.onInput(e, bonus)}
                required={true}
                step="0.01"
                type="number"
                value={bonus.value?.toFixed(2)}
              />

              {
                bonus.total !== undefined && (
                  <InputText
                    class={styles.sectionInput}
                    disabled={true}
                    label="Total"
                    min="0"
                    name="total"
                    required={true}
                    step="0.01"
                    type="number"
                    value={bonus.total.toFixed(2)}
                  />
                )
              }

              {
                !this.isDisabled && (
                  <Button
                    color={ButtonColor.ERROR}
                    key={`button#${bonus.id}`}
                    icon={IconName.CLEAR}
                    onClick={e => this.$emit('removePaygrade', createEventPayload(e, bonus))}
                    size={Size.SMALL}
                    title={this.$t('shifts.paygrades.buttonRemoveBonusPaygrade')}
                    kind={ButtonKind.GHOST}
                  />
                )
              }
            </div>
          ))
        }

        {
          this.isBonusAddible && (
            <Button
              class={styles.sectionButton}
              color={ButtonColor.SUCCESS}
              onClick={e => this.$emit('addBonusPaygrade', createEventPayload(e, this.paygrade))}
              size={Size.SMALL}
              kind={ButtonKind.GHOST}
            >
              {this.$t('shifts.paygrades.buttonAddBonusPaygrade')}
            </Button>
          )
        }
      </section>
    );
  }
}
