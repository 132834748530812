import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './step-wrapper.css';

export enum Slot {
  FOOTER = 'footer',
}

@Component
export default class StepWrapper extends TsxComponent<{
  heading: string;
}> {
  @Prop()
  public heading: string;

  public render() {
    return (
      <div class={styles.stepWrapper}>
        <h1 class={styles.stepWrapperHeading}>{this.heading}</h1>

        {this.$slots.default}

        {this.$slots.footer && (
          <div class={styles.stepWrapperFooter}>
            {this.$slots.footer}
          </div>
        )}
      </div>
    );
  }
}
