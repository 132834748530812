import FormDialog from 'components/form/form-dialog/FormDialog';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'src/utils/events';
import InputToggle from 'components/form/input-toggle/InputToggle';
import InputSelect from 'components/form/input-select/InputSelect';
import InputText from 'components/form/input-text/InputText';
import { LocationsPosition } from 'src/store/locations-positions/Store';
import InputDateTime, { Kind } from 'components/form/input-date-time/InputDateTime';
import { SelectedTimeframe } from 'components/datepicker/types';
import DatepickerInterval, { IntervalSelectionMode } from 'components/datepicker/DatepickerInterval';
import { Shiftplan } from 'src/store/shiftplans/Store';
import { getTimeframeFromDates } from 'src/utils/timeframe-helpers';
import { TimeframeKind } from 'components/calendar-common/Enums';
import {
  getDateString,
} from 'src/utils/date-related';
import { toDate } from 'date-fns-tz';
import { endOfDay } from 'date-fns';
import { FormState as ImportFormState } from 'components/dialog-shiftplan-import/DialogShiftplanImportContainer';
import styles from './copy-shiftplan.css';
import SectionLocationPositions from '../section-location-positions/SectionLocationPositions';
import { FormState as CopyFormState } from './CopyShiftplanContainer';

export interface Props<TFormState extends ImportFormState | CopyFormState> {
  formId: string;
  formState: TFormState;
  positions: NonNullable<LocationsPosition['position']>[];
  shiftplans: Shiftplan[];
  targetShiftplanKind: TargetShiftplanKind
}

export enum TargetShiftplanKind {
  NEW = 'new',
  EXISTING = 'existing'
}

@Component
export default class CopyShiftplan<
  TFormState extends (CopyFormState | ImportFormState)> extends TsxComponent<Props<TFormState>, {
    onInput: <T extends keyof Props<TFormState>['formState']>(
      payload: EventPayload<{ field: T; value: Props<TFormState>['formState'][T] }>,
    ) => void;
    onSubmit: (payload: EventPayload<void>) => void;
  }> {
  @Prop()
  public readonly formId: Props<TFormState>['formId'];

  @Prop()
  public readonly formState: TFormState;

  @Prop()
  public readonly shiftplans: Props<TFormState>['shiftplans'];

  @Prop()
  public readonly positions: Props<TFormState>['positions'];

  @Prop()
  public readonly targetShiftplanKind: Props<TFormState>['targetShiftplanKind'];

  private isImportFormState(
    formState: ImportFormState | CopyFormState,
  ): formState is ImportFormState {
    return Object.hasOwnProperty.call(formState, 'targetShiftplanId');
  }

  private get selectableShiftplans() {
    return this.shiftplans.map(shift => ({
      label: `${shift.name} (${shift.startsAt} - ${shift.endsAt})`,
      value: shift.id,
    }));
  }

  protected get sourceShiftplan(): Shiftplan | undefined {
    return this.shiftplans.find(it => it.id === this.formState.sourceShiftplanId);
  }

  protected get targetShiftplan(): Shiftplan | undefined {
    if (this.isImportFormState(this.formState)) {
      return this.shiftplans
        .find(it => it.id === (this.formState as ImportFormState).targetShiftplanId);
    }

    return undefined;
  }

  protected get startsAtMaxDate() {
    if (!this.targetShiftplan?.endsAt) {
      return undefined;
    }

    // eslint-disable-next-line no-restricted-syntax
    return endOfDay(
      toDate(this.targetShiftplan.endsAt, { timeZone: this.$timeZone.value }),
    );
  }

  protected get startsAtMinDate() {
    if (!this.targetShiftplan?.endsAt) {
      return undefined;
    }

    return toDate(this.targetShiftplan.startsAt, { timeZone: this.$timeZone.value });
  }

  protected get sourceTimeframeStartsAtMinDate() {
    if (!this.sourceShiftplan) {
      return undefined;
    }

    return toDate(this.sourceShiftplan.startsAt, { timeZone: this.$timeZone.value });
  }

  protected get sourceTimeframeStartsAtMaxDate() {
    if (!this.formState.sourceTimeframeEndsAt) {
      return undefined;
    }

    // eslint-disable-next-line no-restricted-syntax
    return endOfDay(
      toDate(this.formState.sourceTimeframeEndsAt, { timeZone: this.$timeZone.value }),
    );
  }

  protected get sourceTimeframeEndsAtMinDate() {
    if (!this.formState.sourceTimeframeStartsAt) {
      return undefined;
    }

    return toDate(this.formState.sourceTimeframeStartsAt, { timeZone: this.$timeZone.value });
  }

  protected get sourceTimeframeEndsAtMaxDate() {
    if (!this.sourceShiftplan) {
      return undefined;
    }

    // eslint-disable-next-line no-restricted-syntax
    return endOfDay(
      toDate(this.sourceShiftplan.endsAt, { timeZone: this.$timeZone.value }),
    );
  }

  protected onInput<T extends keyof ImportFormState>(
    field: T,
    value: ImportFormState[T],
    e?: SyntheticEvent,
  ): void;

  protected onInput<T extends keyof CopyFormState>(
    field: T,
    value: CopyFormState[T],
    e?: SyntheticEvent,
  ): void;

  protected onInput<T extends keyof Props<TFormState>['formState']>(
    field: T,
    value: Props<TFormState>['formState'][T],
    e?: SyntheticEvent,
  ) {
    this.$emit(
      'input',
      createEventPayload(e as SyntheticEvent, { field, value }),
    );
  }

  protected onTimeFrameChange({ event, payload }: EventPayload<SelectedTimeframe>) {
    const { startsAt, endsAt } = getTimeframeFromDates(
      payload.startsAt,
      payload.endsAt,
      TimeframeKind.FREE,
      this.$timeZone.value,
    );

    this.onInput('startsAt', getDateString(startsAt, this.$timeZone.value), event);
    this.onInput('endsAt', getDateString(endsAt, this.$timeZone.value), event);
  }

  protected onSubmit(e: SyntheticEvent<HTMLFormElement>) {
    this.$emit('submit', createEventPayload(e, undefined));
  }

  public render() {
    return (
      <FormDialog
        id={this.formId}
        onSubmit={this.onSubmit}
      >
        <InputSelect
          label={this.$t('copyShiftplan.modal.labelSelectShiftplan')}
          name="shiftplanId"
          onChange={e => this.onInput('sourceShiftplanId', e.payload, e.event)}
          options={this.selectableShiftplans}
          required={true}
          value={this.formState.sourceShiftplanId}
        />

        {!!this.formState.sourceShiftplanId
          && this.targetShiftplanKind === TargetShiftplanKind.NEW
          && <DatepickerInterval
            class={styles.copyShiftplanDatepicker}
            onChange={this.onTimeFrameChange}
            selection={{
              startsAt: new Date(this.formState.startsAt || ''),
              endsAt: new Date(this.formState.endsAt || ''),
            }}
            intervalSelectionMode={IntervalSelectionMode.CUSTOM}
            timeZone={this.$timeZone.value}
          />}

        {!!this.formState.sourceShiftplanId
          && this.targetShiftplanKind === TargetShiftplanKind.EXISTING
          && <InputDateTime
            kind={Kind.DATE}
            datepickerLabel={this.$t('absence.modal.labelStartDate')}
            name="startsAt"
            onInput={e => this.onInput(
              'startsAt',
              getDateString(e.payload.value, this.$timeZone.value),
              e.event,
            )}
            min={this.startsAtMinDate}
            max={this.startsAtMaxDate}
            required={true}
            value={new Date(this.formState.startsAt || '')}
            timeZone={this.$timeZone.value}
          />}

        {!!this.formState.sourceShiftplanId
          && this.targetShiftplanKind === TargetShiftplanKind.NEW
          && <InputText
            type="text"
            name="name"
            required={true}
            label={this.$t('copyShiftplan.modal.labelShiftName')}
            onChange={e => this.onInput('name', e.target.value, e)}
          />}

        {!!this.formState.sourceShiftplanId && <InputToggle
          checked={!!this.formState.copyFullShiftplan}
          label={this.$t('copyShiftplan.modal.labelCopyFullSchedule')}
          name="copyFullShiftplan"
          onChange={e => this.onInput('copyFullShiftplan', e.target.checked, e)}
        />}

        {!this.formState.copyFullShiftplan
          && <SectionLocationPositions
            positions={this.positions}
            selectedPositions={this.formState.positionIds || []}
            onChange={e => this.onInput('positionIds', e.payload.positionIds)}
          />}

        {!this.formState.copyFullShiftplan && <div class={styles.copyShiftplanDateFrame}>
          <InputDateTime
            class={styles.copyShiftplanSideBySide}
            kind={Kind.DATE}
            datepickerLabel={this.$t('absence.modal.labelStartDate')}
            name="sourceTimeframeStartsAt"
            onInput={e => this.onInput('sourceTimeframeStartsAt', getDateString(e.payload.value, this.$timeZone.value))}
            min={this.sourceTimeframeStartsAtMinDate}
            max={this.sourceTimeframeStartsAtMaxDate}
            required={true}
            value={new Date(this.formState.sourceTimeframeStartsAt || '')}
            timeZone={this.$timeZone.value}
          />
          <InputDateTime
            class={styles.copyShiftplanSideBySide}
            kind={Kind.DATE}
            datepickerLabel={this.$t('absence.modal.labelEndDate')}
            name="sourceTimeframeEndsAt"
            min={this.sourceTimeframeEndsAtMinDate}
            max={this.sourceTimeframeEndsAtMaxDate}
            onInput={e => this.onInput('sourceTimeframeEndsAt', getDateString(e.payload.value, this.$timeZone.value))}
            required={true}
            value={new Date(this.formState.sourceTimeframeEndsAt || '')}
            timeZone={this.$timeZone.value}
          />
        </div>}

        {!!this.formState.sourceShiftplanId && <InputToggle
          checked={!!this.formState.copyEmployees}
          label={this.$t('copyShiftplan.modal.labelCopyEmployees')}
          name="copyEmployees"
          onChange={e => this.onInput('copyEmployees', e.target.checked, e)}
        />}

        {this.formState.copyEmployees && <InputToggle
          checked={!!this.formState.withoutConflicts}
          label={this.$t('copyShiftplan.modal.labelIgnoreConflicts')}
          name="withoutConflicts"
          onChange={e => this.onInput('withoutConflicts', !e.target.checked, e)}
        />}

      </FormDialog>
    );
  }
}
