import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { createEventPayload, EventPayload } from 'src/utils/events';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { Qualification } from 'src/store/qualifications/Store';
import Qualifications, { QualificationsSelectItem } from '../qualifications/Qualifications';
import type { FormState, ShiftQualificationInput } from '../types';

import styles from './section-qualifications.css';

export interface Props {
  selectableQualifications: Qualification[];
  selectedQualifications: ShiftQualificationInput[];
  workersCount: number;
  isDisabled: boolean | undefined;
}

interface Events {
  onChange: <T extends keyof FormState>(
    e: EventPayload<{ field: T; value: FormState[T] }>
  ) => void;
}

@Component
class SectionQualifications extends TSXComponent<Props, Events> {
  @Prop()
  public selectableQualifications: Props['selectableQualifications'];

  @Prop()
  public selectedQualifications: Props['selectedQualifications'];

  @Prop()
  public workersCount: Props['workersCount'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  protected onInput<T extends keyof FormState>(
    field: T,
    value: FormState[T],
    e?: SyntheticEvent,
  ) {
    this.$emit(
      'change',
      createEventPayload(e as SyntheticEvent, { field, value }),
    );
  }

  private onAddQualificationClick() {
    const qualification = this.getNextAvailableQualification();
    if (qualification) {
      this.onInput('shiftQualifications', [
        ...this.selectedQualifications,
        {
          qualificationId: qualification.id,
          count: 1,
        },
      ]);
    }
  }

  private getNextAvailableQualification() {
    return this.qualificationsSelectItems.find(qualification => !qualification.isDisabled);
  }

  private onDeleteQualificationClick({ payload: { id } }) {
    this.onInput(
      'shiftQualifications',
      this.selectedQualifications.filter(q => q.qualificationId !== id),
    );
  }

  private get qualificationsSelectItems(): QualificationsSelectItem[] {
    return this.selectableQualifications?.map((q) => {
      const index = this.selectedQualifications?.findIndex(
        qualification => qualification.qualificationId === q.id,
      );
      return {
        ...q,
        isDisabled: index > -1,
      };
    });
  }

  private onQualificationChange(e) {
    const { payload } = e;
    this.onInput(
      'shiftQualifications',
      this.selectedQualifications.map((qualification) => {
        if (qualification.qualificationId === payload.id) {
          return {
            ...qualification,
            [payload.field]: payload.value,
          };
        }
        return qualification;
      }),
    );
  }

  public render() {
    return (
      <FormSection heading={this.$t('shifts.dialog.headingQualifications')}>
        <Qualifications
          class={styles.sectionQualificationsRowFullWidth}
          onAddQualificationClick={this.onAddQualificationClick}
          selectedQualifications={this.selectedQualifications}
          selectableQualifications={this.qualificationsSelectItems}
          onDeleteQualificationClick={this.onDeleteQualificationClick}
          onQualificationChange={this.onQualificationChange}
          workersCount={this.workersCount}
          isDisabled={this.isDisabled}
        />
      </FormSection>
    );
  }
}

export default SectionQualifications;
