import VueRouter from 'vue-router';
import Vuex from 'vuex';
import Vue from 'vue';
import VueI18Next from '@panter/vue-i18next';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import VueLogger from 'services/logger/VueLogger';
import GraphqlClient from 'services/graphql-client/GraphqlClientFactory';
import getStore, { ApplicationStore } from 'src/store/Store';
import { SentryTag } from 'services/logger/SentryTransport';
import RestClient from 'services/rest-client/RestClient';
import ApplicationRouter from './ApplicationRouter';
import bootstrapI18n from './config.i18n';
import Root from './Root';
import VueTimeZoneProvider from './services/vue-time-zone-provider/VueTimeZoneProvider';

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueI18Next);
Vue.use(VueLogger);
Vue.use(VueTimeZoneProvider);

const i18next = bootstrapI18n();

class Application {
  public vueInstance: Vue;

  private graphqlClient: GraphqlClient;

  private restClient: RestClient;

  private store: ApplicationStore;

  private router: ApplicationRouter;

  private logger: ApplicationLogger;

  public constructor() {
    const apiEndPoint = process.env.API_ENDPOINT;
    const gqlEndPoint = process.env.GRAPHQL_ENDPOINT;

    const i18n = new VueI18Next(i18next);

    this.logger = new ApplicationLogger();

    VueLogger.setLogger(this.logger);

    if (!gqlEndPoint) {
      this.logger.instance.error({
        message: 'GRAPHQL_ENDPOINT not provided',
      });
      return;
    }

    this.graphqlClient = this.createGraphqlClient(gqlEndPoint);

    this.restClient = new RestClient(`${apiEndPoint}/api/v1`);

    this.store = getStore(this.graphqlClient, this.restClient, this.logger);

    this.router = new ApplicationRouter(this.store, i18next);

    this.vueInstance = new Vue({
      i18n,
      router: this.router.getInstance(),
      store: this.store,
      components: {
        Root,
      },
    });
  }

  public async mount(element: Element) {
    await this.store.dispatch('auth/tryLoginFromLocalStorage');

    this.vueInstance.$mount(element);
  }

  private createGraphqlClient(endpoint: string) {
    const client = new GraphqlClient(endpoint);
    client.setErrorHandler((e) => {
      // if (e.networkError) {
      //   this.store.dispatch(`snackbar/${SnackbarAction.SHOW}`, {
      //     onCloseAction: () => { window.location.reload(); },
      //     buttonCloseLabel: this.vueInstance.$t('feedback.buttonRetry'),
      //     message: this.vueInstance.$t('feedback.errorGeneric'),
      //     kind: AlertKind.ERROR,
      //     timeout: 5000,
      //   });
      // }
      this.logger.instance.info({
        message: JSON.stringify(e),
        tags: [[SentryTag.GRAPHQL, e.operation.operationName]],
      });
    });

    return client;
  }
}

export default Application;
