import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { SentryTag } from 'services/logger/SentryTransport';
import { StoreActionState } from 'src/utils/store';
import ShiftAction from 'store/shifts/Action';
import { shiftsNS } from 'store/shifts/Store';
import type {
  DeleteRequestFunction,
  Shift,
} from 'store/shifts/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './cancel-offer.css';

interface Props {
  offer: Shift['requests'][0];
  shift?: Shift;
}

interface Events {
  onRefetchShift: () => void;
}

@Component
export default class CancelOffer extends TsxComponent<Props, Events> {
  protected isDeleteConfirmDialogOpen = false;

  protected isSubmitting = false;

  @shiftsNS.Action(ShiftAction.DELETE_REQUEST)
  protected deleteRequest: DeleteRequestFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public offer: Props['offer'];

  @Prop()
  public shift?: Props['shift'];

  protected onSubmit() {
    this.isDeleteConfirmDialogOpen = true;
  }

  protected onSubmitCancel() {
    this.isDeleteConfirmDialogOpen = false;
  }

  protected async onSubmitConfirm() {
    this.isSubmitting = true;

    const response = await this.deleteRequest({
      id: this.offer.id,
      shiftId: this.shift?.id || 0,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, CancelOffer.name]],
        message: JSON.stringify(response),
      });

      this.isDeleteConfirmDialogOpen = false;
      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return false;
    }

    this.$emit('refetchShift');

    this.isDeleteConfirmDialogOpen = false;
    this.isSubmitting = false;

    return this.showSnackbar({
      message: this.$t('shifts.replaceRequests.offerDeleted'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  public render() {
    return (
      <div class={styles.cancelOffer}>
        <p class={styles.cancelOfferMessage}>
          {this.$t('shifts.replaceRequests.offerMessageCancel')}
        </p>

        <Button
          class={styles.cancelOfferButton}
          color={ButtonColor.ERROR}
          disabled={this.isSubmitting}
          onClick={this.onSubmit}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
        >
          {this.$t('shifts.replaceRequests.buttonCancelOffer')}
        </Button>

        <DialogConfirmDelete
          isOpen={this.isDeleteConfirmDialogOpen}
          isSubmitting={this.isSubmitting}
          onCancel={this.onSubmitCancel}
          onConfirm={this.onSubmitConfirm}
          title={this.$t('shifts.replaceRequests.offerTitleDelete')}
        >
          {this.$t('shifts.replaceRequests.offerMessageDelete')}
        </DialogConfirmDelete>
      </div>
    );
  }
}
