import Absence from 'components/calendar-common/absences/Absence';
import Observable from 'components/calendar-common/common/observable/Observable';
import PaginationMixin from 'components/calendar-common/common/pagination-mixin/PaginationMixin';
import Employment from 'components/calendar-common/employments/Employment';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import GridMixin, { GridType } from 'components/calendar-common/grid/GridMixin';
import DayNotesCompactContainer
  from 'components/calendar-common/notes/day-notes-compact-container/DayNotesCompactContainer';
import SpecialDatesCompactContainer
  from 'components/calendar-common/notes/special-dates-compact-container/SpecialDatesCompactContainer';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { shiftsNS, shiftsPositionsMonthViewNS } from 'components/calendar-common/shifts/store/Store';
import { Component, Mixins } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Position } from 'components/calendar-common/positions/Position';
import { positionsNS } from 'components/calendar-common/positions/Store';
import { absencesNS, absencesPositionsMonthViewNS } from 'components/calendar/absences/Store';
import HeaderMonth from 'components/calendar/grid/header-month/HeaderMonth';
import { calendarEmploymentsPositionsMonthViewNS } from 'components/calendar-common/employments/Store';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import RootStoreState from 'store/RootStoreState';
import { calendarCommonNS } from 'components/calendar/common/Store';
import DateItem from 'components/calendar-common/common/DateItem';
import Row from './Row';

@Component({
  directives: {
    observable: Observable,
  },
})
class PositionsMonth extends Mixins(ShiftViewActionsMixin, GridMixin, PaginationMixin) {
  protected gridType = GridType.MONTH;

  @calendarCommonNS.Getter
  public dates: DateItem[];

  @positionsNS.Getter('filteredPositions')
  public positions: Position[];

  @calendarEmploymentsPositionsMonthViewNS.Getter('employmentsByPositions')
  public positionsEmployments: Record<string, Employment[]>;

  @absencesPositionsMonthViewNS.Getter('absencesByEmployments')
  public employmentsAbsences: Record<number, Record<string, Absence[]>>;

  @absencesNS.Getter('absencesByPositions')
  public positionsAbsences: Record<number, Record<string, Absence[]>>;

  @shiftsNS.Getter('shiftsByPositions')
  public positionsShifts: Record<number, Record<string, Shift[]>>;

  @shiftsNS.Getter('shiftsByDates')
  public shiftsByDates: Record<string, Shift[]>;

  @shiftsPositionsMonthViewNS.Getter('shiftsByPositionsEmployments')
  public positionsEmploymentsShifts: Record<number,
  Record<number, Record<string, Shift[]> & { hasShifts: boolean }>>;

  @calendarFiltersNS.Getter('filters')
  protected filters: FiltersDictionary;

  @State((state: RootStoreState) => state.calendar.positions.monthPositions.openPositionIds)
  public openPositionIds: number[];

  protected get paginatedItems() {
    return this.positions;
  }

  public render() {
    return <div
      class={gridStyles.gridTable}
      style={this.gridStyle}>
      <HeaderMonth
        shiftsByDates={this.shiftsByDates}
        dates={this.dates}
        onClick={(e, { dateItem }) => this.onShiftCellClick(dateItem)}
      >
        <span slot="label">{this.$t('calendar.titlePosition')}</span>
      </HeaderMonth>
      <SpecialDatesCompactContainer showLabelText={true} dates={this.dates}/>
      <DayNotesCompactContainer showLabelText={true} dates={this.dates}/>
      {
        this.visibleItems.map((position, index) => {
          const isLastRow = (this.positions.length - 1 === index);
          const employments = this.positionsEmployments[position.locationsPositionId];
          const { employmentsAbsences } = this;
          const employmentsShifts = this.positionsEmploymentsShifts[position.locationsPositionId];
          const shiftsByDates = this.positionsShifts[position.locationsPositionId];
          const absencesByDates = this.positionsAbsences[position.locationsPositionId];

          return <Row
            key={position.id}
            isVisible={this.visibleIds.includes(position.id)}
            showSummary={this.filters.showSummary}
            data-id={position.id}
            v-observable={this.observer}
            employments={employments}
            position={position}
            employmentsAbsences={employmentsAbsences}
            employmentsShifts={employmentsShifts}
            shiftsByDates={shiftsByDates}
            absencesByDates={absencesByDates}
            dates={this.dates}
            isLastRow={isLastRow}
          />;
        })
      }
      {
        this.renderShowMore()
      }
    </div>;
  }
}

export default PositionsMonth as any;
