import SpIcon from 'components/sp-icon/SpIcon';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './collapsible-qualifications.css';

export interface Props {
  label: string;
  assignedEmployeeNames: string[];
}

@Component
export default class CollapsibleQualifications extends TsxComponent<Props, {}> {
  @Prop()
  public label: Props['label'];

  @Prop()
  public assignedEmployeeNames: Props['assignedEmployeeNames'];

  private isOpen = false;

  public render() {
    return (
      <div class={styles.collapsibleQualifications}>
        <header class={styles.collapsibleQualificationsHeader}>
          <span>
            {this.label}
          </span>

          <button
            aria-controls={`${this.label}-control-button`}
            aria-expanded={this.isOpen}
            class={styles.collapsibleQualificationsButton}
            onClick={() => { this.isOpen = !this.isOpen; }}
            title={
              this.isOpen
                ? this.$t('shifts.qualifications.labelCollapse')
                : this.$t('shifts.qualifications.labelExpand')
            }
            type="button"
          >
            <SpIcon
              aria-label={
                this.isOpen
                  ? this.$t('shifts.qualifications.labelCollapse')
                  : this.$t('shifts.qualifications.labelExpand')
              }
              class={styles.collapsibleQualificationsIcon}
              name={this.isOpen ? 'chevron-up' : 'chevron-down'}
            />
          </button>
        </header>

        <transition
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {
            this.isOpen && (
              <div
                class={styles.collapsibleQualificationsBody}
              >
                {
                  this.assignedEmployeeNames.map(o => (
                    <div>{o}</div>
                  ))
                }
              </div>
            )
          }
        </transition>
      </div>
    );
  }
}
