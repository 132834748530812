import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './action-button-wrapper.css';

@Component
export default class ActionButtonWrapper extends TsxComponent<{}> {
  public render() {
    return (
      <div class={styles.actionButtonWrapper}>
        {this.$slots.default}
      </div>
    );
  }
}
