/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Prompt, { Slot as PromptSlot } from 'components/prompt/Prompt';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import styles from './dialog-decline.css';

@Component
export default class DialogDecline extends TsxComponent <{
  isOpen?: boolean;
}, {
  onCancelClick: (e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>) => void;
  onDeclineClick: (
    message: string,
    e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>
  ) => void;
}> {
  protected message = '';

  protected onCancelClick() {
    this.$emit('cancelClick');
  }

  protected onDeclineClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    this.$emit('declineClick', this.message, e);
  }

  @Prop()
  public isOpen?: boolean;

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        onCloseClick={this.onCancelClick}
        title={this.$t('absence.decline.title')}
      >
        <textarea
          class={styles.dialogDeclineMessage}
          onInput={(e: SyntheticEvent<HTMLTextAreaElement, InputEvent>) => {
            this.message = e.target.value;
          }}
          placeholder={this.$t('absence.decline.placeholder')}
          value={this.message}
        />

        <template slot={PromptSlot.ACTION_BUTTONS}>
          <Button type="button"
            color={ButtonColor.SECONDARY}
            kind={ButtonKind.STROKE}
            onClick={this.onCancelClick}
          >{this.$t('modal.buttonCancel')}</Button>
          <Button type="button"
            color={ButtonColor.PRIMARY}
            kind={ButtonKind.FILL}
            onClick={this.onDeclineClick}>
            {this.$t('absence.buttonDecline')}
          </Button>
        </template>
      </Prompt>
    );
  }
}
