import { CalendarNamespace } from 'components/calendar-common/Enums';
import { HighlightableEventProps } from 'components/calendar-common/highlights/highlightable-event/HighlightableEvent';
import { Route } from 'components/shift-schedule/routes';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { TsxComponentAttrs } from 'vue-tsx-support';
import Absence from '../Absence';
import HighlightableAbsence from '../HighlightableAbsence';
import { AbsenceStates } from '../Store';
import styles from './absence-month.css';

interface OwnProps {
  absence: Absence;
}

type Props = OwnProps & HighlightableEventProps;

@Component
class AbsenceMonth extends Mixins(HighlightableAbsence) {
  protected _tsxattrs: TsxComponentAttrs<Props>;

  @Prop()
  public absence: Props['absence'];

  private get absenceClass() {
    return {
      [styles.absenceMonthNew]: this.absence.state === AbsenceStates.NEW,
      [styles.absenceMonthAccepted]: this.absence.state === AbsenceStates.ACCEPTED,
    };
  }

  private get localizedReason() {
    return this.absence.reason.hasLocalization
      ? this.$t(`absence.reason.${this.absence.reason.name}`)
      : this.absence.reason.name;
  }

  private get dialogRouteName() {
    return this.calendarNamespace === CalendarNamespace.CALENDAR
      ? Route.ABSENCE
      : Route.ABSENCES_ABSENCE;
  }

  private onClick(e: MouseEvent) {
    e.stopPropagation();

    if (this.absence.isManageable) {
      this.$router.push({
        name: this.dialogRouteName,
        params:
        { id: this.absence.id.toString() },
      });
    }
  }

  public render() {
    return (
      <button class={[styles.absenceMonth, this.absenceClass, this.highlightClass]}
        disabled={!this.absence.isManageable}
        onClick={e => this.onClick(e)}
        key={this.absence.uniqId}>
        <span>{this.localizedReason.substring(0, 1)}</span>
      </button>
    );
  }
}

export default AbsenceMonth;
