import Shift from 'components/calendar-common/shifts/Shift';
import { FREE_DAY_PRESET_ID } from 'src/store/shift-presets/Store';
import { DateKey } from '../common/DateItem';

export enum RotationPatternVisibilityState {
  NOT_VISIBLE,
  VISIBLE,
  NO_ROTATION_PRESENT,
}

// eslint-disable-next-line import/prefer-default-export
export const shouldShowShiftRotationForDate = (shifts: Shift[], shiftPresetId: number) => (
// case when employee should be resting
  shiftPresetId === FREE_DAY_PRESET_ID
    && shifts.length > 0
)
  || (
    !shifts
      .map(it => it.shiftPreset?.id).includes(shiftPresetId)
  );

export const getRotationPatternVisibilityMap = (
  shifts: Record<DateKey, Shift[]>,
  rotationPattern: Record<DateKey, number | null>,
): Record<DateKey, RotationPatternVisibilityState> => Object.entries(rotationPattern)
  .reduce((acc, [dateKey, shiftPresetId]) => {
    if (shiftPresetId === null) {
      acc[dateKey] = RotationPatternVisibilityState.NO_ROTATION_PRESENT;
    } else {
      acc[dateKey] = shouldShowShiftRotationForDate(shifts[dateKey], shiftPresetId)
        ? RotationPatternVisibilityState.VISIBLE
        : RotationPatternVisibilityState.NOT_VISIBLE;
    }
    return acc;
  }, {} as Record<DateKey, RotationPatternVisibilityState>);
