/* eslint-disable no-alert */
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import DialogPositionContainer from './dialog-position/DialogPositionContainer';

@Component
class Testing extends TsxComponent<{}> {
  protected isDialogPositionContainerOpen = false;

  protected tagId?: number = undefined;

  public render() {
    return (
      <div>
        <h1>Development Testing Page</h1>

        <ul>
          <li>
            <button
              onClick={() => {
                this.isDialogPositionContainerOpen = true;
              }}
              type="button"
            >
              Open Create Position Dialog
            </button>
          </li>

          <li>
            <button
              onClick={() => {
                const shiftplanId = Number.parseInt(window.prompt('Enter ID of shiftplan to edit') || '0', 10);
                this.$router.push({ name: 'shiftplans', params: { id: shiftplanId.toString() } });
              }}
              type="button"
            >
              Open Edit Shiftplan Dialog
            </button>
          </li>

          <li>
            <button
              onClick={() => {
                const shiftplanId = Number.parseInt(window.prompt('Enter ID of shiftplan to create assignment group') || '0', 10);
                this.$router.push({ name: 'assignmentGroups', params: { shiftplanId: shiftplanId.toString() } });
              }}
              type="button"
            >
              Open Create Assignment Group Dialog
            </button>
          </li>

          <li>
            <button
              onClick={() => {
                const shiftplanId = Number.parseInt(window.prompt('Enter ID of shiftplan to edit assignment group') || '0', 10);
                const assignmentGroupId = Number.parseInt(window.prompt('Enter ID of assignment group to edit') || '0', 10);
                this.$router.push({
                  name: 'assignmentGroups',
                  params: {
                    shiftplanId: shiftplanId.toString(),
                    assignmentGroupId: assignmentGroupId.toString(),
                  },
                });
              }}
              type="button"
            >
              Open Edit Assignment Group Dialog
            </button>
          </li>
        </ul>

        <DialogPositionContainer
          isOpen={this.isDialogPositionContainerOpen}
          onCloseClick={() => { this.isDialogPositionContainerOpen = false; }}
        />

        <router-view />
      </div>
    );
  }
}

export default Testing;
