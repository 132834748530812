import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import EmploymentWithAvatar from 'components/employment-with-avatar/EmploymentWithAvatar';
import EvaluationContainer from 'components/evaluation/EvaluationContainer';
import { shiftsNS } from 'store/shifts/Store';
import type { Shift } from 'store/shifts/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './evaluations.css';
import IconState from './icon-state/IconState';

@Component
export default class Evaluations extends TsxComponent<{
  shift: Shift;
}, {
  onRefetchShift: () => void;
}> {
  protected expandedStaffShiftId = Number.NaN;

  protected isLoading = false;

  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @shiftsNS.Getter
  protected canManageShift: (locationId: number, locationsPositionId: number) => boolean;

  @Prop()
  public shift: Shift;

  protected get isManager() {
    return this.canManageShift(
      this.shift?.locationsPosition.location?.id || Number.NaN,
      this.shift?.locationsPosition.id || Number.NaN,
    );
  }

  protected get ownEvaluation() {
    return this.shift?.staffShifts?.find(
      staffShift => staffShift.employment?.id === this.currentEmploymentId,
    );
  }

  // FIXME: only temporary to have something that can be tested
  protected onClick(staffShiftId: number) {
    this.expandedStaffShiftId = this.expandedStaffShiftId === staffShiftId
      ? Number.NaN
      : staffShiftId;
  }

  public render() {
    if (this.ownEvaluation && !this.isManager) {
      return (
        <div class={styles.evaluations}>
          <EvaluationContainer
            class={styles.evaluationsEvaluation}
            staffShiftId={this.ownEvaluation.id}
          />
        </div>
      );
    }

    if (!this.isManager) {
      return null;
    }

    return (
      <div class={styles.evaluations}>
        <ul class={styles.evaluationsList}>
          {
            this.shift.staffShifts?.map(staffShift => (
              <li class={styles.evaluationsListItem}>
                <button
                  class={styles.evaluationsListItemButton}
                  onClick={() => this.onClick(staffShift.id)}
                >
                  <EmploymentWithAvatar employment={staffShift.employment} withStaffNumber={true} />

                  <IconState staffShift={staffShift} />
                </button>

                <transition name="accordion-header-transition">
                  {
                    this.expandedStaffShiftId === staffShift.id && (
                      <EvaluationContainer
                        class={styles.evaluationsEvaluation}
                        staffShiftId={staffShift.id}
                      />
                    )
                  }
                </transition>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }
}
