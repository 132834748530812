import { store as absences, StoreState as AbsencesStoreState } from 'components/calendar-print/absences/store/Store';
import { store as data, StoreState as DataStoreState } from 'components/calendar-print/data/store/Store';
import { store as employments, StoreState as EmploymentsStoreState } from 'components/calendar-print/employments/store/Store';
import { store as positions, ModuleState as PositionsModuleState } from 'components/calendar-common/positions/Store';
import { store as shifts, StoreState as ShiftsStoreState } from 'components/calendar-print/shifts/store/Store';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import {
  store as common,
  StoreState as CommonStoreState,
} from './common/Store';
import { store as filters, StoreState as FiltersStoreState } from './filters/store/Store';

export interface ModuleState {
  shifts: ShiftsStoreState;
  absences: AbsencesStoreState;
  positions: PositionsModuleState;
  employments: EmploymentsStoreState;
  common: CommonStoreState;
  data: DataStoreState;
  filters: FiltersStoreState;
}

const getCalendarPrintStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
) => ({
  namespaced: true,
  modules: {
    shifts: shifts(),
    absences: absences(),
    positions: positions(CalendarNamespace.PRINT),
    employments: employments(),
    common: common(),
    filters: filters(),
    data: data(graphqlClient),
  },
});

export default getCalendarPrintStore;
