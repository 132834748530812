import InputButtonSelect from 'components/form/input-button-select/InputButtonSelect';
import InputPillSelect from 'components/form/input-pill-select/InputPillSelect';
import InputTextArea from 'components/form/input-text-area/InputTextArea';
import { LocationsPosition } from 'src/store/locations-positions/Store';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import FormDialog from 'components/form/form-dialog/FormDialog';
import { GQLLocationsPositionFragmentFragment, GQLPosition } from 'codegen/gql-types';
import styles from './message.css';
import { FormState, SendMessageGroupKind } from './MessageContainer';

interface Props {
  locationsPositions: LocationsPosition[];
  formState: FormState;
}

@Component
export default class Message extends TsxComponent<Props, {
  onSubmitForm: (
    payload: EventPayload<{ formState: FormState }, HTMLElement, UIEvent>
  ) => void;
  onFormInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] }>,
  ) => void;
}> {
  @Prop()
  public locationsPositions: Props['locationsPositions'];

  @Prop()
  public formState: Props['formState'];

  private onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    e.preventDefault();

    this.$emit('submitForm', createEventPayload(
      e as SyntheticEvent,
      { formState: this.formState },
    ));
  }

  protected get formId() {
    return `form-${this.$route?.name}`;
  }

  private get options() {
    return this.locationsPositions
      .filter((o): o is (
      Omit<GQLLocationsPositionFragmentFragment, 'position'>
      & {
        position: Pick<GQLPosition, 'id' | 'color' | 'name' | 'note'>;
      }) => !!o.position)
      .map(
        ({ position }) => ({
          value: position.id.toString(),
          label: position.name,
          color: position.color,
        }),
      );
  }

  public render() {
    return (
      <FormDialog
        onSubmit={this.onSubmit}
        id={this.formId}
        class={styles.message}
      >
        <div class={styles.messageType}>
          <span>{this.$t('messageEmployees.message.labelType')}</span>
          <InputButtonSelect
            options={[
              {
                label: this.$t('messageEmployees.message.labelTypeAll'),
                value: SendMessageGroupKind.ALL,
              },
              {
                label: this.$t('messageEmployees.message.labelTypePosition'),
                value: SendMessageGroupKind.POSITION,
              },
              {
                label: this.$t('messageEmployees.message.labelTypeAssigned'),
                value: SendMessageGroupKind.ASSIGNED,
              },
            ]}
            name="kind"
            value={this.formState.kind}
            onChange={e => this.$emit('formInput', createEventPayload(e.event, {
              value: e.payload,
              field: 'kind',
            }))}
            class={styles.messageSelectButton}
          />
        </div>

        {
          this.formState.kind === SendMessageGroupKind.POSITION && (
            <div class={styles.messagePositionsSelectContainer}>
              <span>{this.$t('messageEmployees.message.labelPositionsPlaceholder')}</span>
              <InputPillSelect
                options={this.options}
                required={true}
                name="positionIds"
                class={styles.messagePositionsSelect}
                value={this.formState.positionIds.map(id => id.toString())}
                onChange={e => this.$emit('formInput', createEventPayload(e.event, {
                  value: e.payload,
                  field: 'positionIds',
                }))}
              />
            </div>
          )
        }

        <InputTextArea
          name="message"
          onInput={e => this.$emit('formInput', createEventPayload(e, {
            field: e.target.name,
            value: e.target.value,
          }))}
          required={true}
          type="text"
          value={this.formState.message}
          placeholder={this.$t('messageEmployees.message.labelMessagePlaceholder')}
          class={styles.messageMessageInput}
        />
      </FormDialog>
    );
  }
}
