import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import CheckboxAvailability from './CheckboxAvailability';
import { AvailabilityState } from '../../availability/Indicator';

import styles from './filtering.css';

@Component
export default class Availability extends TsxComponent<{
  selected: Record<AvailabilityState, boolean>;
}, {
  onChange: EventPayload<AvailabilityState, HTMLInputElement>;
}> {
  @Prop()
  public selected: Record<AvailabilityState, boolean>;

  protected onChange(e: SyntheticEvent<HTMLInputElement, Event>) {
    this.$emit('change', createEventPayload(e, e.target.value));
  }

  public render() {
    return (
      <section class={styles.filtering}>
        <CheckboxAvailability
          checked={this.selected[AvailabilityState.AVAILABLE]}
          class={styles.filteringCheckbox}
          label={this.$t('shifts.employees.availability.labelAvailable')}
          name={AvailabilityState.AVAILABLE.toString()}
          onChange={this.onChange}
          state={AvailabilityState.AVAILABLE}
        />
        <CheckboxAvailability
          checked={this.selected[AvailabilityState.PARTLY_AVAILABLE]}
          class={styles.filteringCheckbox}
          label={this.$t('shifts.employees.availability.labelPartlyAvailable')}
          name={AvailabilityState.PARTLY_AVAILABLE.toString()}
          onChange={this.onChange}
          state={AvailabilityState.PARTLY_AVAILABLE}
        />
        <CheckboxAvailability
          checked={this.selected[AvailabilityState.PARTLY_BOTH]}
          class={styles.filteringCheckbox}
          label={this.$t('shifts.employees.availability.labelPartlyBoth')}
          name={AvailabilityState.PARTLY_BOTH.toString()}
          onChange={this.onChange}
          state={AvailabilityState.PARTLY_BOTH}
        />
        <CheckboxAvailability
          checked={this.selected[AvailabilityState.PARTLY_UNAVAILABLE]}
          class={styles.filteringCheckbox}
          label={this.$t('shifts.employees.availability.labelPartlyUnavailable')}
          name={AvailabilityState.PARTLY_UNAVAILABLE.toString()}
          onChange={this.onChange}
          state={AvailabilityState.PARTLY_UNAVAILABLE}
        />
        <CheckboxAvailability
          checked={this.selected[AvailabilityState.UNAVAILABLE]}
          class={styles.filteringCheckbox}
          label={this.$t('shifts.employees.availability.labelUnavailable')}
          name={AvailabilityState.UNAVAILABLE.toString()}
          onChange={this.onChange}
          state={AvailabilityState.UNAVAILABLE}
        />
        <CheckboxAvailability
          checked={this.selected[AvailabilityState.UNKNOWN]}
          class={styles.filteringCheckbox}
          label={this.$t('shifts.employees.availability.labelUnknown')}
          name={AvailabilityState.UNKNOWN.toString()}
          onChange={this.onChange}
          state={AvailabilityState.UNKNOWN}
        />
      </section>
    );
  }
}
