import Avatar from 'components/avatar/Avatar';
import { Size } from 'components/types';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Key } from 'src/utils/keyboard';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import HoursReport from '../hours-report/HoursReport';
import PositionDot from '../position-dot/PositionDot';
import type { Employment } from '../types';

import styles from './employment-item.css';

@Component
export default class EmploymentItem extends TsxComponent<{
  employment: Employment;
  isSelected?: boolean;
}, {
  onSelect: EventPayload<number, HTMLElement, KeyboardEvent | MouseEvent>;
  onDragStart: void;
  onDragEnd: void;
}> {
  @Prop()
  public employment: Employment;

  @Prop()
  public isSelected?: boolean;

  public render() {
    return (
      <li
        draggable="true"
        onDragstart={() => this.$emit('dragStart')}
        onDragend={() => this.$emit('dragEnd')}
        aria-checked={this.isSelected}
        onClick={e => this.$emit('select', createEventPayload(e, this.employment.id))}
        onKeydown={(e) => {
          if (e.key === Key.ENTER || e.key === Key.SPACE) {
            this.$emit('select', createEventPayload(e, this.employment.id));
          }
        }}
        class={{
          [styles.employmentItem]: true,
          [styles.employmentItemSelected]: this.isSelected,
        }}
        // Should be valid according to MDN
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="radio"
        tabIndex={0}
        key={this.employment.id}
      >
        <Avatar
          size={Size.MEDIUM}
          firstName={this.employment.firstName}
          lastName={this.employment.lastName}
          imageUrl={this.employment.pictureData?.pictureSmall}
        />

        <div class={styles.employmentItemInfo}>
          <span class={styles.employmentItemName}>
            {this.employment.firstName} {this.employment.lastName}
          </span>
          <HoursReport
            class={styles.employmentItemHoursReport}
            employment={this.employment}
          />
        </div>

        <div class={styles.employmentItemPositions}>
          {(this.employment.locationsPositions || []).slice(0, 10).map(lp => (
            lp?.position && (
              <PositionDot
                class={styles.employmentItemPositionDot}
                key={lp.position.id}
                position={lp.position}
              />
            )
          ))}
        </div>
      </li>
    );
  }
}
