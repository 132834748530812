import Dialog, { Slot } from 'components/dialog/Dialog';
import InputDateTime, { Kind } from 'components/form/input-date-time/InputDateTime';
import InputSelect from 'components/form/input-select/InputSelect';
import FormDialog from 'components/form/form-dialog/FormDialog';
import Section, { SectionKind } from 'components/form/form-dialog/Section';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import {
  getItemsOverlappingInterval,
  isStartBeforeEnd,
  startOf,
  Unit,
} from 'src/utils/date-related';
import InputRadio from 'components/form/input-radio/InputRadio';
import { GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy } from 'codegen/gql-types';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import Alert, { AlertKind } from 'components/alert/Alert';
import styles from './dialog-rotation-assignment.css';
import type { EmploymentsRotationGroup } from '../rotation-groups/types';

// required to make browser form validation work
const FORM_ID = 'rotation-assignment-modal';

export interface FormState {
  endsAt: Date | null;
  startsAt: Date | null;
  shiftRotationId?: number;
  shiftRotationGroupId?: number;
  unassignmentStrategy: GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy | undefined;
}

export interface CollisionOverrideOption {
  forceCollision?: boolean;
  forceCollisionAndRemove?: boolean;
}

interface Props {
  isOpen: boolean;
  isUpdate: boolean;
  isSubmitting: boolean;
  employmentsRotationGroups: EmploymentsRotationGroup[];
  shiftRotations: {
    id: number;
    name: string;
    shiftRotationGroups: {
      id: number;
      name: string;
    }[];
  }[];
  formState: FormState;

}

@Component
export default class DialogRotationAssignment extends TsxComponent<Props, {
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] } >,
  ) => void;
  onCloseClick: (payload: EventPayload<void, HTMLElement, MouseEvent>) => void;
  onSubmit: (payload: EventPayload<void, HTMLElement, MouseEvent>) => void;
}> {
  public $refs: { endsAtRef: HTMLElement };

  private unassignmentOptions = [
    {
      label: 'all',
      value: GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy.ALL,
    },
    {
      label: 'rotationGroup',
      value: GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy
        .ROTATION_GROUP,
    },
    {
      label: 'none',
      value: undefined,
    },
  ];

  @Prop()
  public formState: FormState;

  @Prop()
  public isOpen?: boolean;

  @Prop()
  public isSubmitting?: boolean;

  @Prop()
  public shiftRotations: Props['shiftRotations'];

  @Prop()
  public isUpdate: boolean;

  @Prop()
  public employmentsRotationGroups: Props['employmentsRotationGroups'];

  private get shiftRotationGroups(): Props['shiftRotations'][0]['shiftRotationGroups'] {
    if (this.formState.shiftRotationId === undefined) {
      return [];
    }

    const shiftRotation = this.shiftRotations
      .find(it => it.id === this.formState.shiftRotationId);
    if (shiftRotation) {
      return shiftRotation.shiftRotationGroups;
    }

    return [];
  }

  private get overlappingAssignments(): Props['employmentsRotationGroups'] {
    // do not run checks if dates are invalid
    if (!this.isDatesValid) {
      return [];
    }

    return getItemsOverlappingInterval(
      this.employmentsRotationGroups,
      this.formState.startsAt,
      this.formState.endsAt,
      this.$timeZone.value,
    );
  }

  private get isDatesValid() {
    // check dates order if both dates are filled
    return this.formState.startsAt === null
      || this.formState.endsAt === null
      || isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt);
  }

  private get isValid() {
    return this.isDatesValid
      && this.formState.shiftRotationGroupId
      && this.formState.shiftRotationId
      && (this.overlappingAssignments.length === 0);
  }

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    this.$emit('closeClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
  }

  protected onInput<T extends keyof FormState>(field: T, value: FormState[T], e?: SyntheticEvent) {
    this.$emit('input', createEventPayload(e as SyntheticEvent, { field, value }));
  }

  protected onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    this.$emit('submit', createEventPayload(e, undefined));
  }

  public render() {
    return (
      <Dialog
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        title={
          this.$t('employments.dialogRotationAssignment.title')
        }
      >
        <FormDialog id={FORM_ID} onSubmit={this.onSubmit}>
          <InputSelect
            label={this.$t('employments.dialogRotationAssignment.labelShiftRotation')}
            name="shiftRotation"
            onChange={(e) => {
              this.onInput('shiftRotationId', e.payload, e.event);
            }}
            options={this.shiftRotations.map(rotation => ({
              isSelected: false,
              label: rotation.name,
              value: rotation.id,
            }))}
            placeholder={this.$t('employments.dialogRotationAssignment.placeholderShiftRotation')}
            required={true}
            isDisabled={this.isUpdate}
            value={this.formState.shiftRotationId?.toString()}
          />
          <InputSelect
            label={this.$t('employments.dialogRotationAssignment.labelShiftRotationGroup')}
            name="shiftRotationGroup"
            onChange={(e) => {
              this.onInput('shiftRotationGroupId', e.payload, e.event);
            }}
            isDisabled={this.formState.shiftRotationId === undefined || this.isUpdate}
            options={this.shiftRotationGroups.map(shiftRotationGroup => ({
              isSelected: false,
              label: shiftRotationGroup.name,
              value: shiftRotationGroup.id,
            }))}
            placeholder={this.$t('employments.dialogRotationAssignment.placeholderShiftRotationGroup')}
            required={true}
            value={this.formState.shiftRotationGroupId?.toString()}
          />

          <Section kind={SectionKind.TWO_COLUMN}>
            <InputDateTime
              kind={Kind.DATE}
              datepickerLabel={this.$t('employments.dialogRotationAssignment.labelInterval')}
              name="startsAt"
              onInput={({ event, payload: { value } }) => {
                this.onInput('startsAt', startOf(value, Unit.DAY, this.$timeZone.value), event);
              }}
              value={this.formState.startsAt}
              isValid={this.isDatesValid}
              timeZone={this.$timeZone.value}
            />

            <InputDateTime
              kind={Kind.DATE}
              datepickerLabel={' '}
              name="endsAt"
              onInput={({ event, payload: { value } }) => {
                this.onInput('endsAt', startOf(value, Unit.DAY, this.$timeZone.value), event);
              }}
              ref={'endsAtRef'}
              value={this.formState.endsAt}
              isValid={this.isDatesValid}
              timeZone={this.$timeZone.value}
            />
          </Section>

          {
            this.overlappingAssignments.length > 0 && (
              <Alert kind={AlertKind.ERROR} title={this.$t('employments.dialogRotationAssignment.conflict.title')}>
                {this.$t('employments.dialogRotationAssignment.conflict.description')}

                <ul class={styles.dialogRotationAssignmentConflictList}>
                  {
                    this.overlappingAssignments.map(it => (
                      <li class={styles.dialogRotationAssignmentConflictListItem}>
                        {it.shiftRotationGroup.shiftRotation.name}
                      </li>
                    ))
                  }
                </ul>
              </Alert>
            )
          }

          {
            this.isUpdate && this.formState.endsAt && (
              <section class={styles.dialogRotationAssignmentUnassignment}>
                {
                  this.unassignmentOptions.map(({ value, label }) => <InputRadio
                    name="unassignmentStrategy"
                    checked={this.formState.unassignmentStrategy === value}
                    onChange={() => this.onInput('unassignmentStrategy', value)}
                    label={this.$t(`employments.dialogRotationAssignment.unassignmentStrategy.${label}`)}
                    value={value}
                    class={styles.dialogRotationAssignmentUnassignmentRadioInput}/>)
                }
              </section>
            )
          }
        </FormDialog>

        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isSubmitting}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.STROKE}
        >
          {this.$t('employments.dialogRotationAssignment.buttonCancel')}
        </Button>
        <Button
          form={FORM_ID}
          disabled={this.isSubmitting || !this.isValid}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          type="submit"
        >
          {
            this.isUpdate
              ? this.$t('employments.dialogRotationAssignment.buttonUpdate')
              : this.$t('employments.dialogRotationAssignment.buttonCreate')
          }
        </Button>
      </Dialog>
    );
  }
}
