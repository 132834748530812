import { Shift } from 'src/store/shifts/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { getDurationStringInTimeZone } from 'src/utils/date-related';
import { IconPosition } from 'components/form/button/types';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import styles from './shifts-list.css';

interface Props {
  shifts: Shift[];
  icon: IconName;
  isShiftActionRunning: boolean;
}

interface Events {
  onActionClick: (payload: EventPayload<number, HTMLElement, UIEvent>) => void;
}

@Component
export default class ShiftsList extends TsxComponent<Props, Events> {
  @Prop()
  public shifts: Props['shifts'];

  @Prop()
  public icon: Props['icon'];

  @Prop()
  public isShiftActionRunning: Props['isShiftActionRunning'];

  public render() {
    return <div>
      {this.shifts.map(shift => (
        <div class={styles.shiftsList}>
          <div class={styles.shiftsListShiftDetails}>
            <div class={styles.shiftsListDatetime}>
              {
                getDurationStringInTimeZone(
                  this.$timeZone.value,
                  this.$i18n.i18next.language,
                  new Date(shift.startsAt),
                  new Date(shift.endsAt),
                  true,
                )
              }
            </div>
            <div class={styles.shiftsListPositionEmployment}>
              <span
                class={styles.shiftsListPosition}
                style={{ color: shift.locationsPosition.position?.color }}
              >
                {shift.locationsPosition.position?.name}
              </span>
              <span class={styles.shiftsListEmployment}>
                {shift.staffShifts?.length}/{shift.staffShiftsCount} {this.$t('assignmentGroups.employees')}
              </span>
            </div>
          </div>

          <Button
            color={ButtonColor.SECONDARY}
            icon={this.icon}
            size={Size.SMALL}
            kind={ButtonKind.GHOST}
            onClick={e => this.$emit('actionClick', createEventPayload(e, shift.id))}
            iconPosition={IconPosition.ALONE}
            disabled={this.isShiftActionRunning}
          />
        </div>
      ))}
    </div>;
  }
}
