import { Action as StoreAction } from 'store/normalized-store';
import type { StoreState as AuthStoreState, HasAnyRightFunction } from 'components/auth/store/Store';
import { authNS } from 'components/auth/store/Store';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { FetchAllEmploymentsFunction } from 'components/employments/store/Store';
import { employmentsNS } from 'components/employments/store/Store';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'src/utils/url';
import { GQLAvailabilityAggregationsQuery } from 'codegen/gql-types';
import {
  LoadingState,
  SetFilterPayload,
  SetSortPayload,
} from 'components/table/store/Store';
import { SnackbarContent } from 'components/snackbar/types';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { SortOptions } from 'components/table/types';
import {
  executeStoreActionWithFailureSnackbar,
  PayloadParameter,
  StoreActionResult,
} from 'src/utils/store';
import LinkButton from 'components/link-button/LinkButton';
import SnackbarAction from 'components/snackbar/store/Action';
import TableAction from 'components/table/store/Action';
import { positionsNS } from 'src/store/positions/Store';
import type { FetchAllPositionsFunction } from 'src/store/positions/Store';
import { locationsNS } from 'src/store/locations/Store';
import type { FetchAllLocationsFunction } from 'src/store/locations/Store';
import { addWeeks, formatISO } from 'date-fns';
import {
  LOCALE_TIMEZONE,
  startOf,
  Unit,
} from 'src/utils/date-related';
import type { AvailabilityAggregation } from './types';
import type { Filters } from './store/Store';
import { availabilityAggregationsNS } from './store/Store';
import AvailabilityAggregationsTable from './table/Table';
import FilterEmployments from './filter-employments/FilterEmployments';
import FilterPositions from './filter-positions/FilterPositions';
import FilterLocations from './filter-locations/FilterLocations';
import FilterTimeframe from './filter-timeframe/FilterTimeframe';
import styles from './availability-aggregations.css';
import { AlertKind } from '../alert/Alert';

@Component
export default class AvailabilityAggregations extends TsxComponent<{}> {
  @employmentsNS.Action(StoreAction.FETCH_ALL)
  private fetchEmployments: FetchAllEmploymentsFunction;

  @positionsNS.Action(StoreAction.FETCH_ALL)
  private fetchPositions: FetchAllPositionsFunction;

  @locationsNS.Action(StoreAction.FETCH_ALL)
  private fetchLocations: FetchAllLocationsFunction;

  // #region availabilityAggregationsNS
  @availabilityAggregationsNS.State
  private data: GQLAvailabilityAggregationsQuery['availabilityAggregations']['items'];

  @availabilityAggregationsNS.State
  private page: number;

  @availabilityAggregationsNS.State
  private perPage: number;

  @availabilityAggregationsNS.State
  private count: number;

  @availabilityAggregationsNS.State
  private loadingState: LoadingState;

  @availabilityAggregationsNS.State
  private filters: Filters;

  @availabilityAggregationsNS.State
  private sort: SortOptions<AvailabilityAggregation>;

  @availabilityAggregationsNS.Action(TableAction.SUBSCRIBE)
  private subscribe: (errorHandler?: (error) => void) => Promise<StoreActionResult>;

  @availabilityAggregationsNS.Action(TableAction.UNSUBSCRIBE)
  private unsubscribe: () => Promise<void>;

  @availabilityAggregationsNS.Action(TableAction.SET_PAGE)
  private setPage: (page: number) => Promise<void>;

  @availabilityAggregationsNS.Action(TableAction.SET_SORT)
  private setSort: (payload: SetSortPayload<AvailabilityAggregation>) => Promise<void>;

  @availabilityAggregationsNS.Action(TableAction.SET_FILTER)
  private setFilter: (payload: SetFilterPayload<Filters, keyof Filters>) => Promise<void>;
  // #endregion availabilityAggregationsNS

  // #region authNS
  @authNS.State
  private currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  private currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.Getter
  private hasAnyRight: HasAnyRightFunction;

  @authNS.Getter
  private isSuperAdmin: boolean;

  @authNS.Getter
  private manageableLocationPositions: number[];
  // #endregion authNS

  @snackbarNS.Action(SnackbarAction.SHOW)
  private show: (content: SnackbarContent) => void;

  private get pagination() {
    return {
      page: this.page,
      perPage: this.perPage,
      count: this.count,
    };
  }

  private onLocationChange(value) {
    this.setFilter({ key: 'locationIds', value });
    this.setFilter({ key: 'positionIds', value: [] });
  }

  public beforeMount() {
    // has to be done before mount, as otherwise the timeframe filter will get invalid values
    const currentDate = new Date(Date.now());
    const timeZone = this.currentCompany?.timeZone || LOCALE_TIMEZONE;

    const startsAt = formatISO(startOf(currentDate, Unit.DAY, timeZone));
    const endsAt = formatISO(startOf(addWeeks(currentDate, 1), Unit.DAY, timeZone));

    this.setFilter({ key: 'startsAt', value: startsAt });
    this.setFilter({ key: 'endsAt', value: endsAt });
  }

  public mounted() {
    const employmentParams: PayloadParameter<FetchAllEmploymentsFunction> = {};

    if (!this.isSuperAdmin && !this.hasAnyRight('users_show')) {
      employmentParams.locationsPositionIds = this.manageableLocationPositions;
    }

    executeStoreActionWithFailureSnackbar(
      this,
      employmentParams,
      this.fetchEmployments,
      'availabilityAggregations.errors',
      'availabilityAggregations.errors.genericLoadEmployments',
    );

    executeStoreActionWithFailureSnackbar(
      this,
      {},
      this.fetchLocations,
      'availabilityAggregations.errors',
      'availabilityAggregations.errors.genericLoadLocations',
    );

    executeStoreActionWithFailureSnackbar(
      this,
      {},
      this.fetchPositions,
      'availabilityAggregations.errors',
      'availabilityAggregations.errors.genericLoadPositions',
    );

    this.subscribe(
      // error handler
      () => this.show({
        message: this.$t('availabilityAggregations.errors.genericLoadOverview'),
        kind: AlertKind.ERROR,
      }),
    );
  }

  public beforeDestroy() {
    this.unsubscribe();
  }

  public render() {
    return (
      <div class={styles.availabilityAggregations}>
        <header class={styles.availabilityAggregationsHeader}>
          <h1 class={styles.availabilityAggregationsTitle}>
            {this.$t('availabilityAggregations.header')}
          </h1>

          {this.currentEmployment?.isEmployee && (
            <LinkButton
              isExternal
              to={
                getUrlWithApiPrefix(
                  getUrlWithCompanyPrefix('/availabilities', this.currentCompany),
                )
              }
            >
              {this.$t('availabilityAggregations.buttonShowMyAvailabilities')}
            </LinkButton>
          )}
        </header>

        <div class={styles.availabiltiyAggregationsFilters}>
          <FilterEmployments
            value={this.filters.employmentIds}
            onChange={value => this.setFilter({ key: 'employmentIds', value })}
            onReset={() => this.setFilter({ key: 'employmentIds', value: [] })}
          />

          <FilterTimeframe
            startsAt={new Date(this.filters.startsAt)}
            endsAt={new Date(this.filters.endsAt)}
            onChange={(value) => {
              this.setFilter({ key: 'startsAt', value: formatISO(value.startsAt) });
              this.setFilter({ key: 'endsAt', value: formatISO(value.endsAt) });
            }}
          />

          <FilterLocations
            value={this.filters.locationIds}
            onChange={this.onLocationChange}
            onReset={() => this.onLocationChange([])}
          />

          <FilterPositions
            value={this.filters.positionIds}
            onChange={value => this.setFilter({ key: 'positionIds', value })}
            onReset={() => this.setFilter({ key: 'positionIds', value: [] })}
          />
        </div>

        <AvailabilityAggregationsTable
          data={this.data}
          loadingState={this.loadingState}
          pagination={this.pagination}
          sort={this.sort}
          onSetPage={page => this.setPage(page)}
          onSetSort={sortPayload => this.setSort(sortPayload)}
        />

        <router-view />
      </div>
    );
  }
}
