import moment, { Moment } from 'moment';

const DATE_KEY_FORMAT = 'YYYY-MM-DD';

export type DateKey = string;

export const getDateKey = (date: string | Moment): string => moment(date).format(DATE_KEY_FORMAT);

class DateItem {
  public dateKey: string;

  public isEmpty: boolean;

  private momentDate: Moment;

  public isWithinShiftplan: boolean;

  public isToday: boolean;

  public isSpecialDate: boolean;

  public constructor(
    momentDate: Moment,
    isWithinShiftplan: boolean,
    isToday: boolean,
    isSpecialDate: boolean,
  ) {
    this.momentDate = momentDate;
    this.isWithinShiftplan = isWithinShiftplan;
    this.isToday = isToday;
    this.isSpecialDate = isSpecialDate;
    this.dateKey = this.date.format(DATE_KEY_FORMAT);
  }

  // make it immutable
  public get date() {
    return this.momentDate.clone();
  }

  public get week() {
    return this.momentDate.format('W');
  }
}

export default DateItem;
