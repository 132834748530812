import { EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { LocationsPosition, locationsPositionsNS } from 'src/store/locations-positions/Store';
import { shiftplanNotificationsNS } from 'src/store/shiftplan-notifications/Store';
import type { CreateShiftplanMessageFunction } from 'src/store/shiftplan-notifications/Store';
import shiftplanNotificationsAction from 'src/store/shiftplan-notifications/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'src/utils/store';
import type { GetMultipleById } from 'src/utils/store';
import { AlertKind } from 'components/alert/Alert';
import { redirectToNamedParent } from 'src/utils/route';
import Message from './Message';

export enum SendMessageGroupKind {
  ALL = 'all',
  POSITION = 'position',
  ASSIGNED = 'assigned'
}

export type FormState = {
  positionIds: string[];
  message: string;
  kind: SendMessageGroupKind;
}

export const getInitialFormState = (): FormState => ({
  positionIds: [],
  message: '',
  kind: SendMessageGroupKind.ALL,
});

@Component
export default class MessageContainer extends TsxComponent<{
  shiftplanId: number;
}, {
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onSubmitStateChange: (state: boolean) => void;
}> {
  @Prop()
  public shiftplanId: number;

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;

  @shiftplanNotificationsNS.Action(shiftplanNotificationsAction.CREATE_SHIFTPLAN_MESSAGE)
  protected createShiftplanMessage: CreateShiftplanMessageFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  protected formState = getInitialFormState();

  protected get locationsPositions() {
    return this.getLocationsPositionsByLocationAndPositionId(this.currentLocationId)
      .filter(({ position }) => !!position)
      .sort((x, y) => x.position!.name.localeCompare(y.position!.name));
  }

  protected async onCreateMessage({ kind, message, positionIds }: FormState) {
    this.$emit('submitStateChange', true);
    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        input: {
          positionIds: kind === SendMessageGroupKind.POSITION
            ? positionIds.map(o => parseInt(o, 10))
            : null,
          onlyAssigned: kind === SendMessageGroupKind.ASSIGNED,
          message,
        },
        id: this.shiftplanId,
      },
      this.createShiftplanMessage,
      '',
    );

    this.$emit('submitStateChange', false);

    if (response.state === StoreActionState.ERROR) {
      return;
    }

    redirectToNamedParent(this);

    this.showSnackbar({
      message: this.$t('messageEmployees.message.messageSent'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onFormInput({ payload }) {
    this.formState[payload.field] = payload.value;
  }

  public render() {
    return (
      <Message
        locationsPositions={this.locationsPositions}
        formState={this.formState}
        onSubmitForm={e => this.onCreateMessage(e.payload.formState)}
        onFormInput={this.onFormInput}
      />
    );
  }
}
