import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { shiftPresetsNS } from 'components/table/store/Store';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { Action as StoreAction } from 'store/normalized-store';
import type { FetchAllShiftPresetsFunction } from 'src/store/shift-presets/Store';
import { shiftScheduleNS, StoreState as CalendarStoreState } from '../store/Store';
import { tagsFilterNS, StoreState as TagsFilterStoreState } from '../filter-box-tag/store/Store';
import { StoreState as LocationsPositionsFilterStoreState, locationsPositionsFilterNS } from '../filter-box-locations-position/store/Store';
import { employmentFilterNS, StoreState as EmploymentsFilterStoreState } from '../filter-box-employment/store/Store';
import { StoreState as AssignmentGroupsFilterStoreState, assignmentGroupsFilterNS } from '../filter-box-assignment-group/store/Store';
import { quickFilterNS, StoreState as QuickFilterFilterStoreState } from '../filter-box-quick/store/Store';
import { StoreState as ShiftPresetsFilterStoreState, shiftPresetsFilterNS } from '../filter-box-shift-preset/store/Store';
import CalendarAbsences from '../../calendar-absences/CalendarAbsences';
import { shiftRotationGroupsFilterNS, StoreState as ShiftRotationGroupsFilterStoreState } from '../filter-box-shift-rotation-group/store/Store';

import styles from './calendar-absences-container.css';

@Component
export default class CalendarAbsencesContainer extends TsxComponent<{}> {
  @authNS.State
  private currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.State
  private currentCompanyId: AuthStoreState['currentCompanyId'];

  @authNS.State
  private currentCompany: AuthStoreState['currentCompany'];

  @shiftPresetsNS.Action(StoreAction.FETCH_ALL)
  private fetchAllShiftPresets: FetchAllShiftPresetsFunction;

  @shiftScheduleNS.State
  private timeframe: CalendarStoreState['timeframe'];

  @tagsFilterNS.State('selection')
  protected tagsSelection: TagsFilterStoreState['selection'];

  @locationsPositionsFilterNS.State('selection')
  protected locationsPositionsSelection: LocationsPositionsFilterStoreState['selection'];

  @assignmentGroupsFilterNS.State('selection')
  protected assignmentGroupsSelection: AssignmentGroupsFilterStoreState['selection'];

  @employmentFilterNS.State('selection')
  protected employmentsSelection: EmploymentsFilterStoreState['selection'];

  @quickFilterNS.State('filters')
  protected quickFilters: QuickFilterFilterStoreState['filters'];

  @shiftPresetsFilterNS.State('selection')
  protected shiftPresetsSelection: ShiftPresetsFilterStoreState['selection'];

  @shiftRotationGroupsFilterNS.State('selection')
  protected shiftRotationGroupsSelection: ShiftRotationGroupsFilterStoreState['selection'];

  /*
    We need to load some of required data before initialising calendar
  */
  private get isInitialLoadComplete() {
    return this.currentEmployment !== undefined
    && this.currentCompany !== undefined;
  }

  public mounted() {
    this.fetchAllShiftPresets({
      companyId: this.currentCompanyId,
    });
  }

  public render() {
    return (
      <div class={[styles.calendarAbsencesContainer]}>
        <CalendarAbsences
          employmentIds={this.employmentsSelection}
          filters={this.quickFilters}
          isInitialDataLoading={!this.isInitialLoadComplete}
          locationsPositionIds={this.locationsPositionsSelection}
          shiftRotationGroupIds={this.shiftRotationGroupsSelection}
          timeframe={this.timeframe}
        />
      </div>
    );
  }
}
