import Alert, { AlertKind } from 'components/alert/Alert';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import InputCheckbox from 'components/form/input-checkbox/InputCheckbox';
import InputText from 'components/form/input-text/InputText';
import { LocationsPosition } from 'src/store/locations-positions/Store';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './section-positions.css';

export type Selection = Record<number, {
  isDisabled?: boolean;
  workers: number;
}>;

interface Props {
  positions: NonNullable<LocationsPosition['position']>[];
  selection: Selection;
}

@Component
export default class SectionPositions extends TsxComponent<Props, {
  onChange: (payload: EventPayload<Selection>) => void;
}> {
  @Prop()
  protected readonly positions: Props['positions'];

  @Prop()
  protected readonly selection: Props['selection'];

  protected onNumberInput(e: SyntheticEvent<HTMLInputElement>, positionId: number) {
    this.$emit('change', createEventPayload(e, {
      ...this.selection,
      [positionId]: {
        ...this.selection[positionId],
        workers: Number.parseInt(e.target.value, 10),
      },
    }));
  }

  protected onCheckboxChange(e: SyntheticEvent<HTMLInputElement>, positionId: number) {
    this.$emit('change', createEventPayload(e, {
      ...this.selection,
      [positionId]: {
        ...this.selection[positionId],
        isDisabled: !e.target.checked,
      },
    }));
  }

  public render() {
    return (
      <FormSection heading={this.$t('createShiftplan.modal.headingPositions')}>
        {
          this.positions.length === 0 && (
            <Alert
              class={styles.sectionPositionsAlert}
              kind={AlertKind.ERROR}
              title={this.$t('createShiftplan.modal.errorNoPosition.title')}
            >
              {this.$t('createShiftplan.modal.errorNoPosition.message')}
            </Alert>
          )
        }

        <ul class={styles.sectionPositionsList}>
          {
            this.positions.map(position => (
              <li class={styles.sectionPositionsItem}>
                <div class={styles.sectionPositionsCheckbox}>
                  <InputCheckbox
                    checked={!this.selection[position.id]?.isDisabled}
                    label={position.name}
                    name="positionId"
                    onChange={e => this.onCheckboxChange(e, position.id)}
                  />
                </div>
                <InputText
                  class={styles.sectionPositionsInput}
                  disabled={this.selection[position.id]?.isDisabled}
                  isValid={this.selection[position.id]?.workers > 0}
                  label={this.$t('createShiftplan.modal.labelWorkers')}
                  min="1"
                  onInput={e => this.onNumberInput(e, position.id)}
                  pattern="\\d+"
                  step="1"
                  type="number"
                  value={this.selection[position.id]?.workers.toString() || '0'}
                />
              </li>
            ))
          }
        </ul>
      </FormSection>
    );
  }
}
