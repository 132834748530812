import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './two-column.css';

@Component
export default class TwoColumn extends TsxComponent<{}> {
  public render() {
    return (
      <div class={styles.twoColumn}>
        <div class={styles.twoColumnOne}>
          {this.$slots.one}
        </div>

        <div class={styles.twoColumnTwo}>
          {this.$slots.two}
        </div>
      </div>
    );
  }
}
