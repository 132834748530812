import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Size } from 'components/types';
import AvatarDefault from 'components/avatar/AvatarDefault';
import styles from './employment-with-avatar.css';
import { EmploymentWithAvatarSize } from './EmploymentWithAvatar';

interface Props {
  size?: EmploymentWithAvatarSize;
}

@Component
export default class Vacancy extends TsxComponent<Props> {
  @Prop({ default: Size.MEDIUM })
  public size: NonNullable<Props['size']>;

  public render() {
    return (
      <div class={styles.employmentWithAvatar}>
        <div class={styles.employmentWithAvatarVacancy}>
          <AvatarDefault size={this.size} />
        </div>

        <div class={styles.employmentWithAvatarContainer}>
          <span class={styles.employmentWithAvatarName} title={this.$t('shifts.employees.vacancy')}>
            {this.$t('shifts.employees.vacancy')}
          </span>
        </div>
      </div>
    );
  }
}
