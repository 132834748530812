import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SelectedTimeframe } from 'components/datepicker/types';
import {
  UPDATE_THROTTLE_TIME,
} from 'components/calendar/data/Store';
import Action from 'components/calendar-print/common/Action';
import PrintRoot from 'components/calendar-print/PrintRoot';
import { calendarPrintDataNS, StoreState } from 'components/calendar-print/data/store/Store';
import { calendarPrintCommonNS } from 'components/calendar-print/common/Store';
import { calendarPrintFiltersNS } from 'components/calendar-print/filters/store/Store';
import type { PrintFilters } from 'components/calendar-print/filters/store/Store';
import Loader from 'components/loader/Loader';
import {
  LoadState, PrintTimeframeKind, PrintViewKind, TimeframeKind,
} from '../calendar-common/Enums';
import { LOAD_DATA, LOAD_EVENTS } from './data/Actions';
import { FiltersMap } from '../calendar-common/filters/Store';
import FilterAction from '../calendar-print/filters/store/Action';
import initDnd from '../calendar-common/common/dnd/initDnd';
import type { Shiftplan } from '../calendar-common/types';

interface Props {
  isInitialDataLoading: boolean;
  printViewKind: PrintViewKind;
  timeframeKind: TimeframeKind;
  timeframe: SelectedTimeframe;
  shiftplan: Shiftplan | null;
  locationsPositionIds: number[] | null;
  absenceReasonIds: number[];
  filters: FiltersMap[];
}

@Component
class CalendarPrint extends TsxComponent<Props> {
  private loadEventsTimeoutId: number | null = null;

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public isInitialDataLoading: Props['isInitialDataLoading'];

  @Prop()
  public printViewKind: Props['printViewKind'];

  @Prop()
  public timeframeKind: Props['timeframeKind'];

  @Prop()
  public timeframe: Props['timeframe'];

  @Prop()
  public locationsPositionIds: Props['locationsPositionIds'];

  @Prop()
  public absenceReasonIds: Props['absenceReasonIds'];

  @Prop()
  public filters: Props['filters'];

  @calendarPrintDataNS.State((state: StoreState) => state.loadState)
  private calendarLoadState: LoadState;

  @calendarPrintDataNS.Action(LOAD_DATA)
  public loadData;

  @calendarPrintDataNS.Action(LOAD_EVENTS)
  public loadEvents;

  @calendarPrintCommonNS.Action(Action.SET_TIMEFRAME)
  public setTimeframe: (timeframe: SelectedTimeframe) => void;

  @calendarPrintCommonNS.Action(Action.SET_TIMEFRAME_KIND)
  public setTimeframeKind: (timeframeKind: PrintTimeframeKind) => void;

  @calendarPrintCommonNS.Action(Action.SET_PRINT_VIEW_KIND)
  public setPrintViewKind: (printViewKind: PrintViewKind) => void;

  @calendarPrintCommonNS.Action(Action.SET_SHIFTPLAN)
  public setShiftplan: (timeframe: Shiftplan) => void;

  @calendarPrintFiltersNS.Action(FilterAction.SET_FILTERS)
  public setFilters: (filters: PrintFilters) => void;

  @calendarPrintFiltersNS.Action(FilterAction.SET_ABSENCE_REASONS_IDS)
  public setAbsenceReasonIds: (ids: number[]) => void;

  @calendarPrintFiltersNS.Action(FilterAction.SET_LOCATIONS_POSITION_IDS)
  public setLocationsPositionIds: (ids: number[] | null) => void;

  protected throttledLoadEvents() {
    // do no try to load event till initial data is prepared
    // and data query is started
    if (this.isInitialDataLoading || this.calendarLoadState === LoadState.NOT_LOADED) {
      return;
    }
    if (this.loadEventsTimeoutId) {
      window.clearTimeout(this.loadEventsTimeoutId);
    }
    this.loadEventsTimeoutId = window.setTimeout(() => {
      this.loadEvents();
    }, UPDATE_THROTTLE_TIME);
  }

  @Watch('shiftplan', { immediate: true })
  protected onShiftplanChange(currentValue: Shiftplan | null) {
    if (currentValue) {
      this.setShiftplan(currentValue);
      this.throttledLoadEvents();
    }
  }

  @Watch('filters', { immediate: true })
  protected onFiltersChange(filters: FiltersMap[]) {
    this.setFilters({
      showNewAbsences: filters.includes(FiltersMap.NEW_ABSENCES),
      showAcceptedAbsences: filters.includes(FiltersMap.ACCEPTED_ABSENCES),
      showNotes: filters.includes(FiltersMap.NOTES),
      showPositionNotes: filters.includes(FiltersMap.POSITION_NOTES),
    });
    this.throttledLoadEvents();
  }

  @Watch('locationsPositionIds', { immediate: true })
  protected onLocationPositionIdsChange(currentValue: number[] | null) {
    this.setLocationsPositionIds(currentValue);
    this.throttledLoadEvents();
  }

  @Watch('absenceReasonIds', { immediate: true })
  protected onAbsenceReasonidsChange(currentValue: number[]) {
    this.setAbsenceReasonIds(currentValue);
  }

  @Watch('timeframe', { immediate: true })
  protected onTimeframeChange() {
    this.setTimeframe(this.timeframe);
    this.throttledLoadEvents();
  }

  @Watch('printViewKind', { immediate: true })
  protected onPrintViewKindChange() {
    this.setPrintViewKind(this.printViewKind);
  }

  @Watch('isInitialDataLoading', { immediate: true })
  protected onIsLoadingChange(currentValue) {
    if (!currentValue) {
      this.loadData();
    }
  }

  public mounted() {
    initDnd(this.$el, this.$store);
  }

  public beforeDestroy() {
    if (this.loadEventsTimeoutId) {
      window.clearTimeout(this.loadEventsTimeoutId);
    }
  }

  public render() {
    return (<div>
      {
        this.isInitialDataLoading || this.calendarLoadState !== LoadState.LOADED
          ? <Loader/>
          : <PrintRoot />
      }
    </div>);
  }
}

export default CalendarPrint;
