import { createEventPayload, EventPayload } from 'src/utils/events';
import {
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './checkbox-list.css';

interface Props<T extends {id: number | string}> {
  items: T[];
  selection?: T['id'][];
  throttleTimeout?: number;
}

interface Events {
  onChange: EventPayload<(number | string)[], HTMLInputElement, KeyboardEvent | MouseEvent>;
}

interface ScopedSlots<T> {
  item: {
    item: T;
    isSelected: boolean;
    onChange: (
      e: EventPayload<boolean, HTMLInputElement, KeyboardEvent | MouseEvent>,
    ) => void;
  };
}

@Component
class CheckboxList<T extends {id: number | string}>
  extends TSXComponent<Props<T>, Events, ScopedSlots<T>> {
  private timeoutId: number = Number.NaN;

  private throttledSelection: T['id'][] = [];

  @Prop()
  public items: Props<T>['items'];

  @Prop({ default: () => [] })
  public selection: T['id'][];

  @Prop({ default: 0 })
  public throttleTimeout: number;

  @Watch('selection', { immediate: true })
  protected syncThrottledItems() {
    window.clearTimeout(this.timeoutId);
    this.throttledSelection = [...this.selection];
  }

  private onChange(e: SyntheticEvent<HTMLInputElement, KeyboardEvent | MouseEvent>) {
    if (this.hasSelectionChanged) {
      this.$emit('change', createEventPayload(e, this.throttledSelection));
    }
  }

  private queueChange(
    id: number|string,
    event: SyntheticEvent<HTMLInputElement, KeyboardEvent | MouseEvent>,
  ) {
    if (event.target.checked) {
      this.throttledSelection = [...this.throttledSelection, id];
    } else {
      this.throttledSelection = this.throttledSelection.filter(
        selectedItemId => selectedItemId !== id,
      );
    }

    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    this.timeoutId = window.setTimeout(() => {
      this.onChange(event);
    }, this.throttleTimeout);
  }

  private get hasSelectionChanged() {
    return [...this.throttledSelection].sort().join() !== [...this.selection].sort().join();
  }

  public beforeDestroy() {
    window.clearTimeout(this.timeoutId);
  }

  public render() {
    return (
      <ul class={styles.checkboxList}>
        {this.items.map((item) => {
          const isSelected = this.throttledSelection.includes(item.id);
          const onChange = (
            { event }: EventPayload<boolean, HTMLInputElement, KeyboardEvent | MouseEvent>,
          ) => { this.queueChange(item.id, event); };

          return this.$scopedSlots.item?.({ item, isSelected, onChange });
        })}
      </ul>
    );
  }
}

export default CheckboxList;
