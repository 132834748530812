import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Loader from 'components/loader/Loader';
import Action from 'components/table/store/Action';
import { EventPayload } from 'utils/events';
import type { SetSelectionFunction } from 'store/filter-store';
import { absenceReasonsNS } from 'store/absence-reasons/Store';
import type { FetchAllFunction, AbsenceReason } from 'store/absence-reasons/Store';
import { Action as StoreAction } from 'store/normalized-store';
import { StoreState as FilterStoreState, absenceReasonsFilterNS } from './store/Store';
import CheckboxListWithPagingAndFiltering from '../checkbox-list-with-paging-and-filtering/CheckboxListWithPagingAndFiltering';
import FilterBox, { Slot as FilterBoxSlot } from '../filter-box/FilterBox';
import CheckboxListItem from '../filter-box/checkbox-list/item/Item';
import { FILTER_PAGE_SIZE, FILTER_THROTTLE_TIMEOUT } from '../constants';

@Component
export default class FilterBoxAbsenceReason extends TsxComponent<{}> {
  protected isLoading = false;

  protected isOpen = false;

  @absenceReasonsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAll: FetchAllFunction;

  @absenceReasonsNS.Getter('ordered')
  protected absenceReasons: AbsenceReason[];

  @absenceReasonsFilterNS.Action(Action.SET_SELECTION)
  protected setSelection: SetSelectionFunction;

  @absenceReasonsFilterNS.State
  protected selection: FilterStoreState['selection'];

  protected async onOpenClose({ payload: state }: EventPayload<boolean>) {
    this.isOpen = state;

    if (state) {
      // use SWR if AbsenceReason have been populated before
      this.isLoading = !this.absenceReasons.length;

      await this.fetchAll();

      this.isLoading = false;
    }
  }

  public render() {
    return (
      <FilterBox isOpen={this.isOpen} onOpenCloseClick={this.onOpenClose}>
        <template slot={FilterBoxSlot.TITLE}>
          {this.$t('shiftSchedule.filter.absenceReasons.title')}
        </template>

        {this.isLoading
          ? (
            <Loader />
          ) : (
            <CheckboxListWithPagingAndFiltering
              items={this.absenceReasons}
              filterKey="name"
              perPage={FILTER_PAGE_SIZE}
              scopedSlots={{
                item: ({ item, isSelected, onChange }) => (
                  <CheckboxListItem
                    isSelected={isSelected}
                    onChange={onChange}
                  >
                    <span>
                      {
                        item.hasLocalization
                          ? this.$t(`absence.reason.${item.name}`)
                          : item.name
                      }
                    </span>
                  </CheckboxListItem>
                ),
              }}
              selection={this.selection}
              throttleTimeout={FILTER_THROTTLE_TIMEOUT}
              onSelect={({ payload }) => { this.setSelection(payload as number[]); }}
            />
          )
        }
      </FilterBox>

    );
  }
}
