import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

export interface EventPayload<TPayload, TElement = Element, TEvent = Event> {
  payload: TPayload;
  event: SyntheticEvent<TElement, TEvent>;
}

export function createEventPayload<TPayload, TElement = Element, TEvent = Event>(
  event: SyntheticEvent<TElement, TEvent>,
  payload: TPayload,
): EventPayload<TPayload, TElement, TEvent> {
  return {
    payload,
    event,
  };
}
