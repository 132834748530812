import SpIcon from 'components/sp-icon/SpIcon';
import Employee from 'components/calendar-common/employees/employee/Employee';
import {
  Component, Mixins, Prop,
} from 'vue-property-decorator';
import { State } from 'vuex-class';
import { SlotDisplayStyle } from 'components/calendar-common/Enums';
import { Route } from 'components/shift-schedule/routes';
import RootStoreState from 'src/store/RootStoreState';
import Absence from '../Absence';
import HighlightableAbsence from '../HighlightableAbsence';
import { AbsenceIcons, AbsenceStates } from '../Store';
import styles from './absence-day.css';

@Component
class AbsenceDay extends Mixins(HighlightableAbsence) {
  @Prop()
  public absence!: Absence;

  @State((state: RootStoreState) => state.calendar.common.slotDisplayStyle)
  private slotDisplayStyle: SlotDisplayStyle;

  // @Inject('bootstrapHovers')
  // private bootstrapHovers;

  private get absenceClass() {
    return {
      [styles.absenceDayNew]: this.absence.state === AbsenceStates.NEW,
      [styles.absenceDayAccepted]: this.absence.state === AbsenceStates.ACCEPTED,
    };
  }

  private get absenceIcon() {
    return AbsenceIcons[this.absence.state];
  }

  private get hasNote() {
    return this.absence.note && this.absence.note.length > 0;
  }

  private get localizedReason() {
    return this.absence.reason.hasLocalization
      ? this.$t(`absence.reason.${this.absence.reason.name}`)
      : this.absence.reason.name;
  }

  private onClick(e: MouseEvent) {
    e.stopPropagation();

    if (this.absence.isManageable) {
      this.$router.push({ name: Route.ABSENCE, params: { id: this.absence.id.toString() } });
    }
  }

  public mounted() {
    // this.bootstrapHovers.tooltip(this.$el);
  }

  public updated() {
    // this.bootstrapHovers.tooltip(this.$el);
  }

  public render() {
    return (
      <button class={[styles.absenceDay, this.absenceClass, this.highlightClass]}
        disabled={!this.absence.isManageable}
        onClick={e => this.onClick(e)}>
        <header class={styles.absenceDayHeader}>
          <span class={styles.absenceDayReason}>
            {this.localizedReason}
          </span>
          {
            this.hasNote && <SpIcon
              class={styles.absenceDayNote}
              name={'note'}
              data-tooltip={true}
              label={this.absence.note}/>
          }
          <SpIcon name={this.absenceIcon}/>
        </header>
        {this.slotDisplayStyle !== SlotDisplayStyle.COMPACT
            && <section class={styles.absenceDayEmployment}>
              <Employee employee={this.absence.employment}
                slotDisplayStyle={this.slotDisplayStyle} />
            </section>}
      </button>
    );
  }
}

export default AbsenceDay as any;
