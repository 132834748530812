import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Action from 'components/table/store/Action';
import type { SetSelectionFunction } from 'store/filter-store';
import { EventPayload } from 'utils/events';
import { ShiftRotation } from 'src/store/shift-rotations/Store';
import { sortBySortPosition } from 'src/utils/utils';
import FilterBox, { Slot as FilterBoxSlot } from '../filter-box/FilterBox';
import { StoreState as FilterStoreState, shiftRotationGroupsFilterNS } from './store/Store';
import CheckboxListItem from '../filter-box/checkbox-list/item/Item';
import CheckboxListWithPagingAndFiltering from '../checkbox-list-with-paging-and-filtering/CheckboxListWithPagingAndFiltering';
import { FILTER_PAGE_SIZE, FILTER_THROTTLE_TIMEOUT } from '../constants';

export interface Props {
  shiftRotation: ShiftRotation;
}

@Component
export default class FilterBoxShiftRotationGroup extends TsxComponent<Props> {
  protected isOpen = false;

  @Prop()
  public shiftRotation: Props['shiftRotation'];

  @shiftRotationGroupsFilterNS.Action(Action.SET_SELECTION)
  protected setSelection: SetSelectionFunction;

  @shiftRotationGroupsFilterNS.State
  protected selection: FilterStoreState['selection'];

  private get sortedShiftRotationGroups() {
    return [...this.shiftRotation.shiftRotationGroups].sort(sortBySortPosition);
  }

  private get selectionOfCurrentGroup() {
    const shiftRotationGroupsSet = new Set(
      this.shiftRotation.shiftRotationGroups.map(({ id }) => id),
    );

    return this.selection.filter(
      selectedId => shiftRotationGroupsSet.has(selectedId),
    );
  }

  private get selectionOfOtherGroups() {
    const currentSelectionSet = new Set(this.selectionOfCurrentGroup);

    return this.selection.filter(
      id => !currentSelectionSet.has(id),
    );
  }

  protected async onOpenClose({ payload: state }: EventPayload<boolean>) {
    this.isOpen = state;
  }

  protected onSelect({ payload }) {
    this.setSelection([...payload, ...this.selectionOfOtherGroups]);
  }

  public render() {
    return (
      <FilterBox isOpen={this.isOpen} onOpenCloseClick={this.onOpenClose}>
        <template slot={FilterBoxSlot.TITLE}>
          {this.shiftRotation.name}
        </template>

        <CheckboxListWithPagingAndFiltering
          filterKey="name"
          items={this.sortedShiftRotationGroups}
          perPage={FILTER_PAGE_SIZE}
          onSelect={this.onSelect}
          selection={this.selectionOfCurrentGroup}
          throttleTimeout={FILTER_THROTTLE_TIMEOUT}
          scopedSlots={{
            item: ({ item, isSelected, onChange }) => (
              <CheckboxListItem
                isSelected={isSelected}
                onChange={onChange}
              >
                {item.name}
              </CheckboxListItem>
            ),
          }}
        />
      </FilterBox>
    );
  }
}
