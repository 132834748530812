/* eslint-disable import/prefer-default-export */
import { SentryTag } from 'services/logger/SentryTransport';
import Vue from 'vue';
import { NavigationGuard } from 'vue-router';

export function redirectToNamedParent<T extends Vue>(vm: T) {
  const { matched } = vm.$route;
  // FAQ: last element is the route itself, so the one before is the route's parent
  let matchedIndex = matched.length - 2;
  let routeParent = matched[matchedIndex];

  if (!routeParent) {
    vm.$logInfo({
      tags: [[SentryTag.COMPONENT, vm.$options.name || 'unknown component']],
      message: 'Parent route not found. Did you try to include the child routes as non-children?',
    });
  }

  while (!routeParent?.name && (matchedIndex > 0)) {
    matchedIndex -= 1;
    routeParent = matched[matchedIndex];
  }

  vm.$router.push({ name: (routeParent || matched[0]).name, replace: true });
}

export function redirectToParentIf<T extends Vue>(predicate: (vm: T) => boolean) {
  return (vm: T) => {
    if (predicate(vm)) {
      redirectToNamedParent(vm);
    }
  };
}

/**
 * Never call this function directly. Always use `confirmLeaveIf.bind` or
 * `confirmLeaveIf.call` to bind your required `this` value to it.
 * @param predicate A predicate function to determine if the confirm dialog should be shown
 * @param next A reference to the `next` function of any Vue Router `NavigationGuard`
 */
export function confirmLeaveIf(predicate: () => boolean, next: Parameters<NavigationGuard>[2]) {
  if (predicate()) {
    // eslint-disable-next-line no-alert
    const hasConfirmedLeave = window.confirm(this.$t('general.confirmDiscardUnsaved'));

    if (!hasConfirmedLeave) {
      return next(false);
    }
  }

  return next();
}
