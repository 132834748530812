import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import type { EventPayload } from 'src/utils/events';
import Datepicker from 'components/datepicker/Datepicker';
import { SelectionMode } from 'components/datepicker/types';
import styles from './input-repeat-dates.css';

export interface Props {
  dates: Date[];
  min: Date;
  max: Date;
  disabledDates: Date[];
}

interface Events {
  onChange: (payload: EventPayload<Date[], HTMLInputElement, UIEvent>) => void;
  onConnectedShiftChange: (payload: EventPayload<boolean, HTMLInputElement, UIEvent>) => void;
}

@Component
class InputRepeatDates extends TSXComponent<Props, Events> {
  @Prop()
  public min: Props['min'];

  @Prop()
  public max: Props['max'];

  @Prop()
  public dates: Props['dates'];

  @Prop()
  public disabledDates: Props['disabledDates'];

  protected onRepeatDatesChange(eventPayload: EventPayload<Date[], HTMLButtonElement, MouseEvent>) {
    this.$emit('change', eventPayload);
  }

  public render() {
    return (
      <section class={styles.inputRepeatDates}>
        <div class={styles.inputRepeatDatesOptions}>
          <Datepicker
            selection={this.dates}
            selectionMode={SelectionMode.MULTIPLE}
            disabledDates={this.disabledDates}
            min={this.min}
            max={this.max}
            onChange={this.onRepeatDatesChange}
            timeZone={this.$timeZone.value}
          />
        </div>
      </section>
    );
  }
}

export default InputRepeatDates;
