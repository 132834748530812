import { GQLEmploymentStatus } from 'codegen/gql-types';
import FilterPopup from 'components/table/filter-popup/FilterPopup';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class FilterPopupEmploymentStatus extends TsxComponent<{
  close: () => void;
  selected: string[] | null;
}, {
  onFilterChange: string[];
}> {
  @Emit('filterChange')
  public onFilterChange({ payload }) {
    if (this.selected && this.selected.includes(payload.id)) {
      return this.selected.filter(o => o !== payload.id);
    }

    return [...(this.selected || []), payload.id];
  }

  @Emit('filterChange')
  public onToggleAll({ payload }) {
    if (payload.isSelected) {
      return Object.values(GQLEmploymentStatus);
    }

    return null;
  }

  @Prop()
  public close: () => void;

  @Prop()
  public selected: string[] | null;

  public render() {
    return (
      <FilterPopup
        hasSelectAll={true}
        items={
          Object.values(GQLEmploymentStatus).map(val => ({
            id: val,
            isChecked: !!this.selected && this.selected.indexOf(val) !== -1,
            label: this.$t(`absence.employmentStatus.${val}`),
          }))
        }
        onClose={this.close}
        onFilterChange={this.onFilterChange}
        onToggleAll={this.onToggleAll}
      >
        <template slot="header">{this.$t('absence.employmentStatus.title')}</template>
      </FilterPopup>
    );
  }
}
