import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import { Shift } from 'src/store/shifts/Store';
import { EventPayload } from 'src/utils/events';
import Spinner from 'components/spinner/Spinner';
import { IconName } from 'components/icons/types';
import styles from './shifts.css';
import Column from './column/Column';

interface Props {
  shifts: Shift[];
  isAssigningShift: boolean;
  isUnassigningShift: boolean;
  isLoadingShifts: boolean;
  assignmentGroupId: number;
}

interface Events {
  onAssignClick: (payload: EventPayload<number, HTMLElement, UIEvent>) => void;
  onUnassignClick: (payload: EventPayload<number, HTMLElement, UIEvent>) => void;
}

@Component
export default class Shifts extends TsxComponent<Props, Events> {
  @Prop()
  public shifts: Shift[];

  @Prop()
  public isAssigningShift: Props['isAssigningShift'];

  @Prop()
  public isUnassigningShift: Props['isUnassigningShift'];

  @Prop()
  public isLoadingShifts: Props['isLoadingShifts'];

  @Prop()
  public assignmentGroupId: Props['assignmentGroupId'];

  protected get assignedShifts(): Shift[] {
    if (this.shifts) {
      return this.shifts.filter(shift => !!shift.shiftAssignmentGroups?.some(
        o => o.assignmentGroupId === this.assignmentGroupId,
      ));
    }
    return [];
  }

  protected get availableShifts(): Shift[] {
    if (this.shifts) {
      return this.shifts.filter(shift => !shift.shiftAssignmentGroups?.find(
        o => o.assignmentGroupId === this.assignmentGroupId,
      ));
    }
    return [];
  }

  public render() {
    if (this.isLoadingShifts) {
      return (
        <section class={styles.shiftsSpinnerContainer}>
          <Spinner class={styles.shiftsSpinner} />
        </section>
      );
    }

    return (
      <section class={styles.shifts}>
        {/* Column assigned */}
        <Column
          class={styles.shiftsColumn}
          shifts={this.assignedShifts}
          columnLabel={this.$t('assignmentGroups.shifts.labelAssigned')}
          onShiftClick={e => this.$emit('unassignClick', e)}
          isShiftActionRunning={this.isUnassigningShift}
          icon={IconName.MINUS}
        />

        {/* Column available */}
        <Column
          class={styles.shiftsColumn}
          shifts={this.availableShifts}
          columnLabel={this.$t('assignmentGroups.shifts.labelAvailable')}
          onShiftClick={e => this.$emit('assignClick', e)}
          isShiftActionRunning={this.isAssigningShift}
          icon={IconName.PLUS}
        />
      </section>
    );
  }
}
