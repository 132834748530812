import Shift from 'components/calendar-common/shifts/Shift';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import styles from './shift-month.css';

@Component
class ShiftMonth extends Vue {
  @Prop()
  public shift: Shift;

  public render() {
    const date = moment(this.shift.getStartDateTime()).format('HH');

    return (<div class={styles.shiftMonth}>
      <span> {date} </span>
    </div >);
  }
}

export default ShiftMonth as any;
