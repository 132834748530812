import { TimeGridEventSizes } from 'components/calendar-common/common/time-grid-events/time-grid-event/TimeGridEvent';
import { GRID_GAP_SIZE } from 'components/calendar-common/grid/GridVariables';
import { DEFAULT_TIME_GRID_CELL_HEIGHT } from 'components/calendar-common/grid/time-grid/TimeGrid';
import { minutesBetweenDates } from 'components/calendar-common/helpers/intervals/Intervals.js';
import Shift from 'components/calendar-common/shifts/Shift';
import shiftStyles from 'components/calendar-common/shifts/shift-day-base/shift-day.css';
import ShiftDayBase from 'components/calendar-common/shifts/shift-day-base/ShiftDayBase';
import ShiftDaySlots from 'components/calendar-common/shifts/shift-day-base/ShiftDaySlots';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import ShiftPart from '../ShiftPart';
import { AGENDA_GRID_STEP } from '../Store';
import styles from './agenda-days-shift.css';

@Component
class AgendaDaysShiftContent extends Mixins(ShiftDaySlots) {
  private shownEmployments = 0;

  @Prop()
  public shift!: Shift;

  @Prop()
  public shiftPart!: ShiftPart;

  public mounted() {
    this.updateShownEmployments();
  }

  public updated() {
    this.updateShownEmployments();
  }

  private updateShownEmployments() {
    this.$nextTick(() => {
      const EMPLOYEE_SIZE = 35; // 25 * 1.5 approx
      if (this.shiftPart.isEditedShiftCopy) {
        return;
      }
      const { height: infoContainerHeight } = (this.$refs.infoContainer as HTMLElement)
        .getBoundingClientRect();
      const shiftHeight = (minutesBetweenDates(
        this.shiftPart.start,
        this.shiftPart.end,
      ) * DEFAULT_TIME_GRID_CELL_HEIGHT) / AGENDA_GRID_STEP
        - 2 * GRID_GAP_SIZE;
      this.shownEmployments = Math.max(
        Math.floor((shiftHeight - infoContainerHeight) / EMPLOYEE_SIZE) - 1,
        0,
      );
    });
  }

  public render() {
    return (<ShiftDayBase shift={this.shift}
      onDrop={(...args) => this.$emit('drop', ...args)}
      allowDrag={false}
      class={shiftStyles.shiftDayAgenda}>
      <div class={styles.agendaShiftInfoWrapper}
        ref="infoContainer">
        <div class={shiftStyles.shiftDayHorizontalGroup}>
          {this.renderTimeSlot()}
          {this.renderIcons()}
        </div>
        {(this.shiftPart.duration() >= TimeGridEventSizes.S)
          && <div class={shiftStyles.shiftDayHorizontalGroup}>
            {this.renderPositionName()}
          </div>}
        {this.shiftPart.duration() >= TimeGridEventSizes.XS
        && <div class={[
          shiftStyles.shiftDayHorizontalGroup]}>
          {this.renderEmployeesChip()}
        </div>}
        {this.shiftPart.duration() >= TimeGridEventSizes.XS
        && <div class={[
          shiftStyles.shiftDayHorizontalGroup, shiftStyles.shiftDayHorizontalGroupJustifyLeft]}>
          {this.renderTags()}
        </div>}
      </div>
      {this.shiftPart.duration() >= TimeGridEventSizes.DEFAULT
        && <div
          class={[shiftStyles.shiftDayHorizontalGroup,
            shiftStyles.shiftDayHorizontalGroupAlignBottom,
            styles.agendaShiftEmploymentsContainer]}>
          {this.renderEmployees(this.shownEmployments)}
        </div>}
    </ShiftDayBase>);
  }
}

export default AgendaDaysShiftContent as any;
