import Dropdown from 'components/dropdown/Dropdown';
import RotationGroupPreview from 'components/rotation-wizard/rotation-group-preview/RotationGroupPreview';
import { RESTING_DAY } from 'components/rotation-wizard/store/types';
import { getDurationStringInTimeZone, toLocaleDateString } from 'src/utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { DropdownItemKind } from 'components/dropdown/types';
import Button from 'components/form/button/Button';
import Card from 'components/card/Card';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { IconPosition } from 'components/form/button/types';
import { IconName } from 'components/icons/types';
import styles from './rotation-group-card.css';
import { Route } from '../routes';
import { Employment } from '../types';
import { RotationGroupUnion } from '../rotation-groups/types';

@Component
export default class RotationGroupCard extends TsxComponent<{
  employment?: Employment;
  employmentsRotationGroup: RotationGroupUnion;
}> {
  public $refs: {
    previewButton: Vue;
  };

  private isRotationPatternPreviewOpen = false;

  private previewButtonRect: DOMRect;

  @Prop()
  public employment?: Employment;

  @Prop()
  public employmentsRotationGroup: RotationGroupUnion;

  protected get anchorDate() {
    return new Date(
      this.employmentsRotationGroup.__typename === 'EmploymentsRotationGroup'
        ? this.employmentsRotationGroup.shiftRotationGroup.shiftRotation.anchorDate
        : this.employmentsRotationGroup.anchorDate,
    );
  }

  protected get duration() {
    if (!this.employmentsRotationGroup.startsAt) {
      return null;
    }

    const startsAt = new Date(this.employmentsRotationGroup.startsAt);

    if (this.employmentsRotationGroup.endsAt) {
      const endsAt = new Date(this.employmentsRotationGroup.endsAt);

      return getDurationStringInTimeZone(
        this.$timeZone.value,
        this.$i18n.i18next.language,
        startsAt,
        endsAt,
      );
    }

    return toLocaleDateString(startsAt, this.$i18n.i18next.language, this.$timeZone.value);
  }

  protected get isIndividualRotation() {
    return this.employmentsRotationGroup.__typename === 'IndividualShiftRotation';
  }

  protected get previewGroup() {
    const group = this.employmentsRotationGroup;

    return {
      id: group.id,
      name: group.__typename === 'EmploymentsRotationGroup' ? group.shiftRotationGroup.name : '',
      employmentsLength: 0,
      days: (group.__typename === 'EmploymentsRotationGroup' ? group.shiftRotationGroup : group).shiftPresetIds.map(id => (
        id === 0
          ? RESTING_DAY
          : (group.__typename === 'EmploymentsRotationGroup' ? group.shiftRotationGroup : group).shiftPresets.find(preset => preset.id === id)
      )),
    };
  }

  protected get rotationInterval() {
    return this.employmentsRotationGroup.__typename === 'EmploymentsRotationGroup'
      ? this.employmentsRotationGroup.shiftRotationGroup.shiftRotation.rotationInterval
      : this.employmentsRotationGroup.rotationInterval;
  }

  protected onPreviewToggle(e: SyntheticEvent<HTMLButtonElement, MouseEvent>, state: boolean) {
    if (state) {
      this.previewButtonRect = this.$refs.previewButton.$el.getBoundingClientRect();
    }

    this.isRotationPatternPreviewOpen = state;
  }

  public render() {
    return (
      <Card class={styles.rotationGroupCard}>
        <div class={styles.rotationGroupCardActions}>
          <Button
            color={ButtonColor.SECONDARY}
            icon={IconName.EYE_ON}
            ref="previewButton"
            size={Size.SMALL}
            kind={ButtonKind.GHOST}
            iconPosition={IconPosition.ALONE}
            {...{
              nativeOn: {
                mouseenter: (e) => { this.onPreviewToggle(e, true); },
                mouseleave: (e) => { this.onPreviewToggle(e, false); },
                wheel: (e) => { this.onPreviewToggle(e, false); },
              },
            }}
          />

          <Dropdown
            class={styles.rotationGroupCardButton}
            hideChevron={true}
            id={`rotation-group-${this.employmentsRotationGroup.id}-dropdown`}
            items={[
              /*
              hidden for now
              {
                label: this.$t('employments.rotationGroups.buttonUnassignFromGroup'),
                type: DropdownItemKind.BUTTON,
                onClick: () => undefined,
              },
              */
              {
                label: this.$t('employments.rotationGroups.buttonEditGroupAssigment'),
                type: DropdownItemKind.BUTTON,
                onClick: () => {
                  this.$router.push({
                    name: Route.UPDATE_ASSIGNMENT,
                    params: {
                      assignmentId: this.employmentsRotationGroup.id.toString(),
                    },
                  });
                },
              },
            ]}
            openToLeft={true}
          >
            <Button
              color={ButtonColor.PRIMARY}
              icon={IconName.DOTS_HORIZONTAL}
              iconPosition={IconPosition.ALONE}
              size={Size.SMALL}
              kind={ButtonKind.GHOST}
            />
          </Dropdown>
        </div>

        <header>
          <h3 class={styles.rotationGroupCardTitle}>
            {
              this.employmentsRotationGroup.__typename === 'EmploymentsRotationGroup'
                ? this.employmentsRotationGroup.shiftRotationGroup.shiftRotation.name
                : `${this.employment?.firstName} ${this.employment?.lastName}`
            }
          </h3>

          <p class={styles.rotationGroupCardSubtitle}>
            {
              this.employmentsRotationGroup.__typename === 'EmploymentsRotationGroup'
                ? this.employmentsRotationGroup.shiftRotationGroup.name
                : this.$t('employments.rotationGroups.labelIndividualRotation')
            }
          </p>
        </header>

        <ul class={styles.rotationGroupCardList}>
          {
            this.duration && (
              <li>
                <Icon name={IconName.EVENT} class={styles.rotationGroupCardIcon} />
                {this.duration}
              </li>
            )
          }
          <li>
            <Icon name={IconName.RANGE} class={styles.rotationGroupCardIcon} />
            {this.$t('employments.rotationGroups.labelRotationInterval', { length: this.rotationInterval })}
          </li>
          {
            this.anchorDate && (
              <li>
                <Icon name={IconName.DAY} class={styles.rotationGroupCardIcon} />
                <span domPropsInnerHTML={this.$t('employments.rotationGroups.labelAnchorDate', { value: toLocaleDateString(this.anchorDate, this.$i18n.i18next.language, this.$timeZone.value) })} />
              </li>
            )
          }
        </ul>

        {this.isRotationPatternPreviewOpen && (
          <RotationGroupPreview
            anchorDate={this.anchorDate}
            group={this.previewGroup}
            style={{
              right: `${window.innerWidth - this.previewButtonRect.right}px`,
              top: `${this.previewButtonRect.top + this.previewButtonRect.height}px`,
            }}
          />
        )}
      </Card>
    );
  }
}
