import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import type { Shift } from 'store/shifts/Store';
import { createEventPayload, EventPayload } from 'utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Offerer from '../offerer/Offerer';

import styles from './offerer-list.css';

type Request = Shift['requests'][0];

@Component
export default class OffererList extends TsxComponent<{
  isSubmitting?: boolean;
  requests: Request[];
}, {
  onAcceptOffer: (payload: EventPayload<{ employmentId: number; requestId: number }>) => void;
  onCancelRequest: (payload: EventPayload<{ employmentId: number; requestId: number }>) => void;
}> {
  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @Prop()
  public requests?: Request[];

  @Prop()
  public isSubmitting?: boolean;

  protected onAcceptOffer({ event }: EventPayload<void>, offer: Request) {
    this.$emit('acceptOffer', createEventPayload(
      event,
      { employmentId: this.currentEmploymentId, requestId: offer.id },
    ));
  }

  protected onCancelRequest({ event }: EventPayload<void>, offer: Request) {
    this.$emit('cancelRequest', createEventPayload(
      event,
      { employmentId: this.currentEmploymentId, requestId: offer.id },
    ));
  }

  public render() {
    return (
      <div class={styles.offererList}>
        {
          this.requests?.map((request) => {
            const isPending = !!request.acceptedBy?.includes(
              this.currentEmploymentId || Number.NaN,
            );

            return (
              <Offerer
                class={styles.offererListOfferer}
                isManagerView={false}
                isPending={isPending}
                isSubmitting={this.isSubmitting}
                onCancelRequest={e => this.onCancelRequest(e, request)}
                onAcceptOffer={e => this.onAcceptOffer(e, request)}
                request={request}
              />
            );
          })
        }
      </div>
    );
  }
}
