import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLQualificationsQueryVariables,
  GQLQualificationsQuery,
  GQLQualificationFragmentFragment,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import QualificationsGql from './queries/Qualifications.gql';

export const qualificationsNS = namespace('qualifications');

export type StoreState = ById<Qualification>;

export type Qualification = GQLQualificationFragmentFragment;

export type FetchAllQualificationsFunction = (
  payload: Omit<GQLQualificationsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getQualificationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
  });

  const fetchAll: ActionProvider<
  GQLQualificationsQuery, GQLQualificationsQueryVariables, Qualification> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllQualificationsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: QualificationsGql,
      resultKey: 'qualifications',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  return createNormalizedStore<Qualification, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getQualificationsStore;
