import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { Employment } from '../types';

enum Period {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
}

@Component
export default class HoursReport extends TsxComponent<{
  employment: Employment;
}> {
  @Prop()
  public employment: Employment;

  protected get contractedMinutesDuration() {
    return this.employment.employmentWorkHour?.monthlyMinutes
      || this.employment.employmentWorkHour?.weeklyMinutes
      || 0;
  }

  protected get period(): Period | undefined {
    if (this.employment.employmentWorkHour?.monthlyMinutes) {
      return Period.MONTHLY;
    }

    if (this.employment.employmentWorkHour?.weeklyMinutes) {
      return Period.WEEKLY;
    }

    return undefined;
  }

  protected get formatNumber() {
    return new Intl.NumberFormat(this.$i18n.i18next.language, {
      maximumFractionDigits: 1,
    }).format;
  }

  public render() {
    return (
      <span>
        {!!this.contractedMinutesDuration && ` (${this.formatNumber(this.contractedMinutesDuration / 60)})`}
      </span>
    );
  }
}
