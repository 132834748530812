import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Dialog, { Slot } from 'components/dialog/Dialog';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'src/utils/events';
import InputDateTime, { Kind } from 'components/form/input-date-time/InputDateTime';
import FormDialog from 'components/form/form-dialog/FormDialog';
import Section, { SectionKind } from 'components/form/form-dialog/Section';
import Alert, { AlertKind } from 'components/alert/Alert';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import InputRadio from 'components/form/input-radio/InputRadio';
import styles from './dialog-assign-rotation-group.css';
import { RotationGroupAssignmentKind } from '../employees/manager-view/column-assignment/ColumnAssignment';

const FORM_ID = 'rotation-group-assignment-dialog';

interface Props {
  isOpen: boolean;
  minDate: Date;
  maxDate: Date;
}

interface Events {
  onCloseClick: (e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>) => void;
  onConfirm: (e: EventPayload<{
    startDate: Date;
    endDate: Date;
    type: RotationGroupAssignmentKind;
  }>) => void;
}

interface FormState {
  startDate: Date | null;
  endDate: Date | null;
  type: RotationGroupAssignmentKind;
}

@Component
export default class DialogAssignRotationGroup extends TsxComponent<Props, Events> {
  @Prop({ default: false })
  public isOpen: NonNullable<Props['isOpen']>;

  @Prop()
  public minDate: Props['minDate'];

  @Prop()
  public maxDate: Props['maxDate'];

  private formState: FormState = {
    startDate: null,
    endDate: null,
    type: RotationGroupAssignmentKind.SINGLE_SHIFT,
  };

  private radioButtons = [
    {
      value: RotationGroupAssignmentKind.SINGLE_SHIFT,
      label: 'shifts.employees.rotationGroupShifts.single',
    },
    {
      value: RotationGroupAssignmentKind.ALL_SHIFTS,
      label: 'shifts.employees.rotationGroupShifts.all',
    },
    {
      value: RotationGroupAssignmentKind.CUSTOM,
      label: 'shifts.employees.rotationGroupShifts.custom',
    },
  ];

  private onSubmit(event: SyntheticEvent<HTMLFormElement, UIEvent>) {
    event.preventDefault();
    this.$emit('confirm', createEventPayload(event, {
      startDate: this.formState.startDate,
      endDate: this.formState.endDate,
      type: this.formState.type,
    }));
  }

  private get isValid(): boolean {
    if (this.formState.type === RotationGroupAssignmentKind.CUSTOM) {
      return !!(this.formState.startDate && this.formState.endDate);
    }

    return !!this.formState.type;
  }

  private onInput({ field, value }: { field: 'startDate' | 'endDate'; value: Date; }) {
    this.formState[field] = value;
  }

  public render() {
    return (
      <Dialog
        isOpen={this.isOpen}
        onCloseClick={e => this.$emit('closeClick', e)}
        title={this.$t('shifts.employees.rotationGroupShifts.title')}
      >
        <FormDialog id={FORM_ID} onSubmit={this.onSubmit}>
          <ul class={styles.rotationGroupOptions}>
            {
              this.radioButtons.map(({ label, value }) => (
                <li
                  class={[styles.rotationGroupOption]}
                  key={value}
                >
                  <InputRadio
                    value={value}
                    name="type"
                    label={this.$t(label)}
                    checked={value === this.formState.type}
                    onChange={() => { this.formState.type = value; }}
                  />
                </li>
              ))
            }
          </ul>

          {
            this.formState.type === RotationGroupAssignmentKind.CUSTOM && (
              <Section kind={SectionKind.TWO_COLUMN}>
                <InputDateTime
                  kind={Kind.DATE}
                  datepickerLabel={this.$t('shifts.employees.rotationGroupShifts.labelStartDate')}
                  name="startDate"
                  required={true}
                  value={this.formState.startDate}
                  onInput={({ payload }) => { this.onInput({ field: 'startDate', value: payload.value }); }}
                  min={this.minDate}
                  max={this.maxDate}
                />
                <InputDateTime
                  kind={Kind.DATE}
                  datepickerLabel={this.$t('shifts.employees.rotationGroupShifts.labelEndDate')}
                  name="endDate"
                  required={true}
                  value={this.formState.endDate}
                  onInput={({ payload }) => { this.onInput({ field: 'endDate', value: payload.value }); }}
                  min={this.formState.startDate || this.minDate}
                  max={this.maxDate}
                />
              </Section>
            )
          }

          {
            this.formState.type !== RotationGroupAssignmentKind.SINGLE_SHIFT && (
              <Alert
                class={styles.rotationGroupInfoPanel}
                kind={AlertKind.INFO}
                title={this.$t('shifts.employees.rotationGroupShifts.infoMessage')}
              />
            )
          }
        </FormDialog>
        <Button
          color={ButtonColor.SECONDARY}
          size={Size.SMALL}
          type="button"
          kind={ButtonKind.FILL}
          slot={Slot.BUTTONS_LEFT}
          onClick={e => this.$emit('closeClick', e)}
          aria-label={this.$t('shifts.employees.rotationGroupShifts.buttonCancel')}
        >
          {this.$t('shifts.employees.rotationGroupShifts.buttonCancel')}
        </Button>
        <Button
          color={ButtonColor.PRIMARY}
          size={Size.SMALL}
          kind={ButtonKind.FILL}
          slot={Slot.BUTTONS_RIGHT}
          type="submit"
          aria-label={this.$t('shifts.employees.rotationGroupShifts.buttonAssign')}
          form={FORM_ID}
          disabled={!this.isValid}
        >
          {this.$t('shifts.employees.rotationGroupShifts.buttonAssign')}
        </Button>
      </Dialog>
    );
  }
}
