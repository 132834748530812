import Avatar from 'components/avatar/Avatar';
import { employmentsNS } from 'components/employments/store/Store';
import type { Employment } from 'components/employments/types';
import FilterPopup from 'components/table/filter-popup/FilterPopup';
import { Size } from 'components/types';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './filter-popup-employments.css';

interface Props {
  selected: number[] | null;
  close: () => void;
}

@Component
export default class FilterPopupEmployments extends TsxComponent<Props, {
  onFilterChange: string[];
}> {
  @Prop()
  public close: Props['close'];

  @Prop()
  public selected: Props['selected'];

  protected employmentsSearchString = '';

  @employmentsNS.Getter('items')
  protected employments: Employment[];

  @Emit('filterChange')
  public onFilterChange({ payload }) {
    if (Array.isArray(payload.id) || payload.id === null) {
      return payload.id;
    }

    if (this.selected && this.selected.includes(payload.id)) {
      return this.selected.filter(o => o !== payload.id);
    }

    return [...(this.selected || []), payload.id];
  }

  public onSearchInput({ payload }) {
    this.employmentsSearchString = payload.search;
  }

  @Emit('filterChange')
  public onToggleAll({ payload }) {
    if (payload.isSelected) {
      return this.filteredItems.map(it => it.id);
    }
    return null;
  }

  private get items() {
    return this.employments
      .map(employment => ({
        id: employment.id,
        isChecked: !!this.selected && this.selected.indexOf(employment.id) !== -1,
        label: `${employment.firstName} ${employment.lastName}`,
        data: employment,
      }));
  }

  private get filteredItems() {
    const lowerCaseString = this.employmentsSearchString.toLocaleLowerCase();
    return this.items
      .filter(item => this.employmentsSearchString.length === 0
        || item.isChecked
        || item.data.staffNumber?.toLocaleLowerCase().includes(lowerCaseString)
        || item.label.toLocaleLowerCase().includes(lowerCaseString))
      .sort((a, b) => {
        // move checked to the top
        if (a.isChecked !== b.isChecked) {
          return Number(b.isChecked) - Number(a.isChecked);
        }
        return a.data.lastName.localeCompare(b.data.lastName);
      });
  }

  public render() {
    return (
      <FilterPopup
        class={styles.filterPopupEmployments}
        items={this.filteredItems}
        searchString={this.employmentsSearchString}
        hasSearch={true}
        hasSelectAll={true}
        itemClass={styles.filterPopupEmploymentsItem}
        onSearchInput={this.onSearchInput}
        onFilterChange={this.onFilterChange}
        onToggleAll={this.onToggleAll}
        onClose={this.close}
        scopedSlots={{
          item: ({ item: { data: employment, label } }) => [
            <Avatar
              class={styles.filterPopupEmploymentsItemAvatar}
              firstName={employment.firstName}
              lastName={employment.lastName}
              imageUrl={employment.pictureData?.pictureSmall}
              size={Size.SMALL}
            />,
            <span title={label}>
              {label}
            </span>,
          ],
        }}
      />
    );
  }
}
