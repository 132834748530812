import { Component, Prop, Mixins } from 'vue-property-decorator';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import { GridRowScope } from 'components/calendar-common/grid/grid-print-row/GridPrintRow';
import GridRow from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import GridMixin from 'components/calendar-common/grid/GridMixin';
import DateItem from '../../common/DateItem';
import styles from './grid-header-month.css';

interface DateInterval {
  value: string;
  length: number;
  start: number;
}

interface GridColumnStyle {
  gridColumn: string;
}

const countSame = (acc, current, index) => {
  if (acc[current] !== undefined) {
    acc[current].value += 1;
  } else {
    acc[current] = { index, value: 1 };
  }
  return acc;
};

@Component
class GridHeaderMonth extends Mixins(GridMixin) {
  @Prop({
    default: () => [],
  })
  public dates!: DateItem[];

  @Prop({
    default: '',
  })
  public cellClass!: string;

  @Prop({
    default: true,
  })
  public isSticky!: boolean;

  private get months(): DateInterval[] {
    const items = this.dates
      .map(({ date }) => date.format('MMMM YYYY'))
      .reduce(countSame, {});
    return Object.keys(items)
      .map(key => ({
        value: key,
        length: items[key].value,
        start: items[key].index,
      }));
  }

  private get weeks(): DateInterval[] {
    const items = this.dates
      .map(date => this.getWeekNumber(date))
      .reduce(countSame, {});
    return Object.keys(items)
      .map(key => ({
        value: key,
        length: items[key].value,
        start: items[key].index,
      }))
      .sort((a, b) => a.start - b.start);
  }

  private get labelClasses() {
    return [gridStyles.gridTableHeaderCell,
      styles.gridHeaderMonthLabel,
      this.cellClass, {
        [styles.gridHeaderMonthLabelSticky]: this.isSticky,
      }];
  }

  private getGridStyle(gridElements: DateInterval[], index: number): GridColumnStyle {
    const start = gridElements
      .slice(0, index)
      .map(({ length }) => length)
      .reduce((acc, cur) => acc + cur, 0);
    return {
      gridColumn: `${start + 2} / ${start + gridElements[index].length + 2}`,
    };
  }

  public render() {
    return (<div class={{
      [gridStyles.gridTableSubgrid]: true,
      [gridStyles.gridTableHeader]: true,
      [gridStyles.gridTableHeaderSticky]: this.isSticky,
    }}>
      <GridCell
        isHeader={true}
        class={this.labelClasses}>
        {this.$slots.label}
      </GridCell>
      {this.$slots.total}
      {this.months.map((month, index) => (<GridCell
        isHeader={true}
        class={[
          gridStyles.gridTableHeaderCell,
          styles.gridHeaderMonthMonth,
          this.cellClass]}
        style={this.getGridStyle(this.months, index)}
      >
        <span>{month.value}</span>
      </GridCell>))}
      {this.weeks.map((week, index) => (<GridCell
        isHeader={true}
        class={[gridStyles.gridTableHeaderCell,
          styles.gridHeaderMonthWeek,
          this.cellClass]}
        style={this.getGridStyle(this.weeks, index)}
      >
        <span>{week.value}</span>
      </GridCell>))}
      <GridRow dates={this.dates}
        isHeader={true}
        cellClass={this.cellClass}
        style={{
          gridTemplateColumns: `repeat(${this.dates.length}, 1fr)`,
          gridColumn: `2 / ${this.dates.length + 2}`,
        }}
        scopedSlots= {{
          cell: (scope: GridRowScope) => {
            const { date, isWithinShiftplan } = scope.dateItem;
            return (<button class={[
              gridStyles.gridTableHeaderCell,
              styles.gridHeaderMonthDay,
              this.cellClass]}
            disabled={!isWithinShiftplan}
            onClick={e => this.$emit('click', e, scope)}>
              <div>
                {date.format('dd')}
              </div>
              <div>
                {date.format('DD')}
              </div>
            </button>);
          },
        }}>
      </GridRow>
      {this.$slots.default}
    </div >);
  }
}

export default GridHeaderMonth as any;
