export interface DndParams {
  positionId: number | undefined;
  employmentId: number | undefined;
  allowedPositionIds: number[] | undefined;
}

const defaultParams: DndParams = {
  positionId: undefined,
  employmentId: undefined,
  allowedPositionIds: undefined,
};

export default defaultParams;
