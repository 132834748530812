/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { RESTING_DAY } from 'components/rotation-wizard/store/types';
import type { RotationDayPreset } from 'components/rotation-wizard/store/types';
import { ShiftPreset, shiftPresetsNS } from 'store/shift-presets/Store';
import { Component, Emit } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './preset-select.css';

interface Events {
  onSelect: (e: SyntheticEvent<HTMLDivElement, MouseEvent>, preset: RotationDayPreset) => void;
}

@Component
class PresetSelect extends TSXComponent<{}, Events> {
  @shiftPresetsNS.Getter('sortedByStartsAt')
  protected presets: ShiftPreset[];

  @Emit('select')
  public onSelect(e: SyntheticEvent<HTMLDivElement, MouseEvent>, preset: RotationDayPreset) { }

  private get presetsWithRestingDay() {
    return [
      ...this.presets,
      RESTING_DAY,
    ];
  }

  public render() {
    return (
      <div class={styles.presetSelect}>
        <div class={styles.presetSelectHeader}>
          {this.$t('rotationWizard.rotationPatternSetup.presetSelectHeader')}
        </div>
        <div class={styles.presetSelectList}>
          {this.presetsWithRestingDay.map(preset => (
            <button
              type="button"
              class={[
                styles.presetSelectListItem,
                preset === RESTING_DAY && styles.presetSelectListItemRestingDay,
              ]}
              style={preset !== RESTING_DAY && {
                color: preset?.color,
                backgroundColor: `${preset?.color}4C`,
              }}
              onClick={e => this.onSelect(e, preset)}
              title={preset === RESTING_DAY
                ? this.$t('rotationWizard.rotationPatternSetup.restingDay')
                : preset.name
              }
            >
              <span class={styles.presetSelectListItemLabel}>
                {preset === RESTING_DAY
                  ? this.$t('rotationWizard.rotationPatternSetup.restingDay')
                  : preset.name
                }
              </span>
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default PresetSelect;
