import { GQLOrderDirection, GQLShiftEmploymentSort } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import SpIcon from 'components/sp-icon/SpIcon';
import Tooltip from 'components/tooltip/Tooltip';
import type { EventsOn } from 'vue-tsx-support/types/dom';

import styles from './sorting.css';

@Component
export default class RadioButton extends TsxComponent<
Partial<HTMLInputElement> & {
  icon: string;
  order: GQLOrderDirection;
  label: string;
  name: string;
  value?: GQLShiftEmploymentSort;
}, EventsOn<HTMLInputElement>> {
  @Prop()
  public icon: string;

  @Prop()
  public label: string;

  @Prop()
  public name: string;

  @Prop({ default: GQLOrderDirection.ASC })
  public order: GQLOrderDirection;

  @Prop()
  public value: GQLShiftEmploymentSort;

  public render() {
    return (
      <label
        class={{
          [styles.sortingRadioButton]: true,
          [styles.sortingRadioButtonSelected]: !!this.$attrs.checked,
        }}
      >
        <input
          {...{
            attrs: { ...this.$attrs },
            on: { ...this.$listeners },
          }}
          class={styles.sortingInput}
          name={this.name}
          type="radio"
          value={this.value}
        />
        <Tooltip text={this.label}>
          <SpIcon label={this.label} name={this.icon} />
          {
            !!this.$attrs.checked && (
              <input
                {...{
                  on: { ...this.$listeners },
                }}
                aria-label={this.$t('general.sortDescending')}
                checked={this.order === GQLOrderDirection.DESC}
                class={styles.sortingOrderIndicator}
                type="checkbox"
                value={this.value}
              />
            )
          }
        </Tooltip>
      </label>
    );
  }
}
