import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './card.css';

@Component
export class Card extends TsxComponent<{}> {
  public render() {
    return (
      <div class={ styles.card }>
        { this.$slots.default }
      </div>
    );
  }
}

export default Card;
