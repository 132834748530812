import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Icon from 'components/icons/Icon';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import {
  EventsOn, LinkHTMLAttributes,
} from 'vue-tsx-support/types/dom';
import { Size } from 'components/types';
import { Location } from 'vue-router';
import { ButtonProps, IconPosition, ICON_SIZE_MAP } from './types';
import styles from './button.css';
import BaseLink from '../base-button/BaseLink';

export interface Props extends ButtonProps {
  to: string | Location;
}

@Component({
  inheritAttrs: false,
})
export default class Link extends
  TsxComponent<LinkHTMLAttributes & Props, EventsOn<HTMLLinkElement>> {
  @Prop()
  public to: NonNullable<Props['to']>;

  @Prop({ default: ButtonColor.PRIMARY })
  public color: NonNullable<Props['color']>;

  @Prop()
  public icon?: Props['icon'];

  @Prop({ default: ButtonKind.FILL })
  public kind: NonNullable<Props['kind']>;

  @Prop({ default: Size.MEDIUM })
  public size: NonNullable<Props['size']>;

  @Prop({ default: IconPosition.BEFORE })
  public iconPosition: NonNullable<Props['iconPosition']>;

  public render() {
    return (
      <BaseLink
        to={this.to}
        kind={this.kind}
        size={this.size}
        color={this.color}
        {...{
          attrs: this.$attrs,
          on: { ...this.$listeners },
        }}
      >
        {this.icon
        && (this.iconPosition === IconPosition.BEFORE
            || this.iconPosition === IconPosition.ALONE
            || this.iconPosition === IconPosition.BOTH)
          && <Icon name={this.icon} size={ICON_SIZE_MAP[this.size]}
            class={{
              [styles.buttonIcon]: true,
              [styles.buttonIconAlone]: this.iconPosition === IconPosition.ALONE,
              [styles.buttonIconLeft]: this.iconPosition !== IconPosition.ALONE,
            }} />}
        {this.$slots.default}
        {this.icon && (this.iconPosition === IconPosition.AFTER
        || this.iconPosition === IconPosition.BOTH)
          && <Icon name={this.icon} size={ICON_SIZE_MAP[this.size]}
            class={{ [styles.buttonIconRight]: true, [styles.buttonIcon]: true }} />}
      </BaseLink>
    );
  }
}
