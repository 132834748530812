import { DAYS_IN_WEEK, getLocalizedWeekday } from 'src/utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { RESTING_DAY } from '../store/types';
import type { RotationGroup } from '../store/types';

import styles from './rotation-group-preview.css';

interface Props {
  group: RotationGroup;
  anchorDate: Date;
}

@Component
class RotationGroupPreview extends TSXComponent<Props> {
  @Prop()
  public group: Props['group'];

  @Prop()
  public anchorDate: Props['anchorDate'];

  private get weeksLength() {
    return Math.ceil(this.group.days.length / DAYS_IN_WEEK);
  }

  public render() {
    return (
      <div class={styles.rotationGroupPreview}>
        {Array.from({ length: DAYS_IN_WEEK }, (_, index) => {
          const curDate = new Date(this.anchorDate);

          curDate.setDate(this.anchorDate.getDate() + index);

          return (
            <div class={styles.rotationGroupPreviewHeader}>
              {getLocalizedWeekday(curDate, this.$i18n.i18next.language, { weekday: 'narrow' })}
            </div>
          );
        })}

        {Array.from({ length: this.weeksLength }, (_row, weekIndex) => {
          // last week can be partial
          const weekLength = weekIndex === this.weeksLength - 1
            ? DAYS_IN_WEEK - (this.weeksLength * DAYS_IN_WEEK - this.group.days.length)
            : DAYS_IN_WEEK;
          return ([
            Array.from({ length: weekLength }, (_, dayIndex) => {
              const preset = this.group.days[(weekIndex * 7) + dayIndex] || RESTING_DAY;

              return (
                <div
                  title={
                    preset === RESTING_DAY
                      ? this.$t('rotationWizard.rotationGroupPreview.restingDay')
                      : preset?.name
                  }
                  class={{
                    [styles.rotationGroupPreviewItem]: true,
                    [styles.rotationGroupPreviewItemRestingDay]: preset === RESTING_DAY,
                  }}
                  style={
                    preset !== RESTING_DAY && {
                      backgroundColor: `${preset?.color}4C`,
                      color: preset?.color,
                    }
                  }
                >
                  <span class={styles.rotationGroupPreviewItemLabel}>
                    {
                      preset === RESTING_DAY
                        ? this.$t('rotationWizard.rotationGroupPreview.restingDayAbbreviation')
                        : preset?.shortName
                    }
                  </span>
                </div>
              );
            }),
          ]);
        })}
      </div>
    );
  }
}

export default RotationGroupPreview;
