import { createEventPayload, EventPayload } from 'utils/events';
import { range } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Icon from 'components/icons/Icon';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';

import styles from './pagination.css';

@Component
export default class Pagination extends TsxComponent<{
  isLongGapOmitted?: boolean;
  length: number;
  selected?: number;
}, {
  onClick: (payload: EventPayload<number>) => void;
}> {
  @Prop()
  public isLongGapOmitted?: boolean;

  @Prop()
  public length: number;

  @Prop()
  public selected: number;

  protected get pageAndEllipsisSequence() {
    if (!this.isLongGapOmitted || this.length <= 5) {
      return Array.from({ length: this.length }, (_, index) => index + 1);
    }

    const items: (number | string)[] = [];

    if (this.selected - 1 > 2) {
      items.push(1, '…');
    } else {
      items.push(...range(1, 3));
    }

    if (this.selected - 1 > 2 && this.length - this.selected > 2) {
      items.push(this.selected);
    }

    if (this.length - this.selected > 2) {
      items.push('…', this.length);
    } else {
      items.push(...range(this.length - 2, this.length));
    }

    return items;
  }

  protected onClick(e: SyntheticEvent<HTMLButtonElement, MouseEvent>, page: number) {
    this.$emit('click', createEventPayload(e, page));
  }

  public render() {
    return (
      <nav
        aria-label="pagination"
        class={styles.pagination}
        role="navigation"
      >
        <ul class={styles.paginationList}>
          <li>
            <button
              aria-disabled={this.selected < 2}
              class={[styles.paginationButton, styles.paginationButtonArrow]}
              disabled={this.selected < 2}
              onClick={e => this.onClick(e, this.selected - 1)}
              type="button"
            >
              <Icon
                name={IconName.ARROW_BACK}
                size={Size.LARGE}
              />
            </button>
          </li>

          {this.pageAndEllipsisSequence.map(item => (
            <li>
              {
                typeof item === 'number'
                  ? (
                    <button
                      aria-current={this.selected === item}
                      class={{
                        [styles.paginationButton]: true,
                        [styles.paginationButtonSelected]: this.selected === item,
                      }}
                      onClick={e => this.onClick(e, item)}
                      type="button"
                    >
                      {item}
                    </button>
                  ) : (
                    <span aria-hidden={true} class={styles.paginationEllipsis}>{item}</span>
                  )
              }
            </li>
          ))}

          <li>
            <button
              aria-disabled={this.selected >= this.length}
              class={[styles.paginationButton, styles.paginationButtonArrow]}
              disabled={this.selected >= this.length}
              onClick={e => this.onClick(e, this.selected + 1)}
              type="button"
            >
              <Icon
                name={IconName.ARROW_NEXT}
                size={Size.LARGE}
              />
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}
