import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { SentryTag } from 'services/logger/SentryTransport';
import { StoreActionState } from 'src/utils/store';
import ShiftAction from 'store/shifts/Action';
import { shiftsNS } from 'store/shifts/Store';
import type {
  CreateReplaceRequestFunction,
  Shift,
} from 'store/shifts/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './create-offer.css';

interface Props {
  shift?: Shift;
}

interface Events {
  onRefetchShift: () => void;
}

@Component
export default class CreateOffer extends TsxComponent<Props, Events> {
  protected isSubmitting = false;

  @shiftsNS.Action(ShiftAction.CREATE_REPLACE_REQUEST)
  protected createReplaceRequest: CreateReplaceRequestFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public shift?: Props['shift'];

  protected async onSubmit() {
    this.isSubmitting = true;

    const response = await this.createReplaceRequest({
      shiftId: this.shift?.id || 0,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, CreateOffer.name]],
        message: JSON.stringify(response),
      });

      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return false;
    }

    this.$emit('refetchShift');

    this.isSubmitting = false;

    return this.showSnackbar({
      message: this.$t('shifts.replaceRequests.offerCreated'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  public render() {
    return (
      <div class={styles.createOffer}>
        <p class={styles.createOfferMessage}>
          {this.$t('shifts.replaceRequests.offerMessageCreate')}
        </p>

        <Button
          class={styles.createOfferButton}
          color={ButtonColor.SUCCESS}
          disabled={this.isSubmitting}
          onClick={this.onSubmit}
          size={Size.SMALL}
          kind={ButtonKind.GHOST}
        >
          {this.$t('shifts.replaceRequests.buttonCreateOffer')}
        </Button>
      </div>
    );
  }
}
