import SpIcon from 'components/sp-icon/SpIcon';
import DateItem from 'components/calendar-common/common/DateItem';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import type { FiltersDictionary } from 'components/calendar-common/filters/Store';
import gridCellStyles from 'components/calendar-common/grid/grid-cell/grid-cell.css';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { Action } from 'vuex-class';
import styles from './special-dates-compact-container.css';

@Component
class SpecialDatesCompactContainer extends Vue {
  @Prop()
  public dates!: DateItem[];

  @Prop()
  public showLabelText!: string;

  @calendarFiltersNS.Getter('filters')
  private filters: FiltersDictionary;

  @Action('calendar/notes/dayNotesPopup/openDayNotePopup')
  private openDayNotePopup:
  (params: { event: MouseEvent; dateItem: DateItem }) => void;

  public render() {
    return (
      this.filters.showSpecialDates && <GridRow dates={this.dates}
        cellClass={gridCellStyles.gridCellNoGap}
        scopedSlots={{
          cell: ({ dateItem }: GridRowScope) => (dateItem.isSpecialDate
            ? (<button class={styles.specialDateCompactContainerDate}
              onClick={event => this.openDayNotePopup({
                dateItem,
                event,
              })}>
            </button>)
            : undefined),
        }}>
        <GridCell class={gridStyles.gridTableLabelCell}
          slot="label"
          isHeader={true}>
          {this.showLabelText
            ? (<span class={styles.specialDatesCompactContainerLabelText}>
              {this.$t('calendar.specialDates.title')}
            </span>)
            : <SpIcon name="event" class={styles.specialDatesCompactContainerIcon}/>}
        </GridCell>
      </GridRow>);
  }
}

export default SpecialDatesCompactContainer as any;
