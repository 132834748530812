import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Badge from 'components/badge/Badge';
import styles from './filter-box.css';

export enum Slot {
  TITLE = 'title',
}

export interface Props {
  resetButtonText?: string;
  isResetButtonShown?: boolean;
}

interface Events {
  onOpenCloseClick: (e: EventPayload<boolean>) => void;
  onResetClick: (e: EventPayload<void>) => void;
}

@Component
export default class FilterBox extends TsxComponent<Props, Events> {
  @Prop()
  public resetButtonText: Props['resetButtonText'];

  @Prop()
  public isResetButtonShown: Props['isResetButtonShown'];

  protected onResetClick(e: SyntheticEvent<HTMLButtonElement, MouseEvent>) {
    this.$emit('resetClick', createEventPayload(e, undefined));
  }

  public render() {
    return (
      <div class={styles.filterBox}>
        <header class={styles.filterBoxHeader}>
          {this.$slots[Slot.TITLE]}
          {this.isResetButtonShown && (
            <Badge class={styles.filterBoxSummaryBadge}
              isClosable={true}
              onCloseClick={e => this.onResetClick(e)}>
              {this.resetButtonText}
            </Badge>
          )}
        </header>

        <div class={styles.filterBoxInner}>
          {this.$slots.default}
        </div>
      </div>
    );
  }
}
