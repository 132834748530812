import { StoreState as AuthStoreState } from 'components/auth/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { formatISO, getISOWeek } from 'date-fns';
import type { FetchAllLocationsPositionsFunction, LocationsPosition } from 'src/store/locations-positions/Store';
import type { ShiftRotation } from 'src/store/shift-rotations/Store';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { TimeframeKind } from 'components/calendar-common/Enums';
import { getTimeframeFromDates } from 'utils/timeframe-helpers';
import { IntervalSelectionMode } from 'components/datepicker/DatepickerInterval';
import { Action } from 'store/normalized-store';
import SnackbarAction from 'components/snackbar/store/Action';

import {
  executeStoreActionWithFailureSnackbar,
  StoreActionState,
} from 'utils/store';

import {
  getDateInTimeZone, getItemsCoveringInterval, isStartBeforeEnd, toLocaleString,
} from 'src/utils/date-related';
import type { CreateShiftplanFromShiftRotationFunction, CreateShiftplanFunction, FetchShiftplanFunction } from 'store/shiftplans/Store';
import { shiftplansNS } from 'store/shiftplans/Store';
import { snackbarNS } from 'components/snackbar/store/Store';
import { locationsPositionsNS } from 'store/locations-positions/Store';

import ShiftplanAction from 'store/shiftplans/Action';
import type { SetJobFunction } from 'components/shift-schedule/store/Store';
import { backgroundJobsNS } from 'src/store/background-jobs/Store';
import { Action as WatchStoreAction } from 'store/watch-store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import ShiftScheduleAction from '../../store/Action';
import { Route } from '../../routes';
import { shiftScheduleBackgroundJobsMapNS } from '../../store/Store';
import { TimeFrameKind as ShiftplanTimeFrameKind, TimeFrameKind } from '../section-time-frame-kind/SectionTimeFrameKind';
import { CreateShiftplanKind } from '../section-create-kind/SectionCreateKind';
import CreateShiftplan from './CreateShiftplan';

// required to make browser form validation work

export const timeFrameKindToTimeframeKindMap: Record<ShiftplanTimeFrameKind, TimeframeKind> = {
  [ShiftplanTimeFrameKind.WEEK]: TimeframeKind.WEEK,
  [ShiftplanTimeFrameKind.MONTH]: TimeframeKind.MONTH,
  [ShiftplanTimeFrameKind.CUSTOM]: TimeframeKind.FREE,
};

export interface FormState {
  createShiftplanKind: CreateShiftplanKind;
  endsAt: Date;
  name: string;
  positions: Record<number, {
    isDisabled?: boolean;
    workers: number;
  }>;
  rotationId: string;
  startsAt: Date;
  timeFrameKind: ShiftplanTimeFrameKind;
}

export interface Props {
  createShiftplanKind: CreateShiftplanKind,
  currentCompany: AuthStoreState['currentCompany'];
  currentLocationId: AuthStoreState['currentLocationId'];
  isShiftRotationEnabled?: boolean;
  positions: NonNullable<LocationsPosition['position']>[];
  shiftRotations: ShiftRotation[];
  formId: string;
}

@Component
export default class CreateShiftplanContainer extends TsxComponent<Props, {
  onCloseClick: (payload: EventPayload<void>) => void;
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] }>,
  ) => void;
  onSubmit: (payload: EventPayload<void>) => void;
}> {
  protected formState: FormState = {
    createShiftplanKind: CreateShiftplanKind.NEW,
    endsAt: new Date(),
    name: '',
    positions: {},
    rotationId: '',
    startsAt: new Date(),
    timeFrameKind: TimeFrameKind.WEEK,
  };

  protected isSubmitting = false;

  @Prop()
  public readonly formId: Props['formId'];

  @Prop()
  public readonly isShiftRotationEnabled: Props['isShiftRotationEnabled'];

  @Prop()
  public readonly positions: Props['positions'];

  @Prop()
  public readonly currentCompany: Props['currentCompany'];

  @Prop()
  public readonly currentLocationId: Props['currentLocationId'];

  @Prop()
  public readonly shiftRotations: Props['shiftRotations'];

  @Prop()
  public readonly createShiftplanKind: Props['createShiftplanKind'];

  @shiftplansNS.Action(Action.CREATE)
  protected createShiftplan: CreateShiftplanFunction;

  @shiftplansNS.Action(ShiftplanAction.CREATE_SHIFTPLAN_FROM_SHIFT_ROTATION)
  protected createShiftplanFromShiftRotation: CreateShiftplanFromShiftRotationFunction;

  @shiftScheduleBackgroundJobsMapNS.Action(ShiftScheduleAction.SET_JOB)
  protected setJobForShiftplan: SetJobFunction;

  @backgroundJobsNS.Action(WatchStoreAction.REFETCH)
  private backgroundJobsRefetch: () => Promise<void>;

  @shiftplansNS.Action(Action.FETCH)
  protected fetchShiftplan: FetchShiftplanFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @locationsPositionsNS.Action(Action.FETCH_ALL)
  protected fetchLocationsPositionsAll: FetchAllLocationsPositionsFunction;

  @Watch('formState.timeFrameKind')
  protected onTimeFrameKindUpdate() {
    this.formState.name = '';

    // recalculate startsAt and endsAt for convenience
    const { startsAt, endsAt } = getTimeframeFromDates(
      this.formState.startsAt,
      this.formState.endsAt,
      timeFrameKindToTimeframeKindMap[this.formState.timeFrameKind],
      this.$timeZone.value,
    );

    this.formState.startsAt = startsAt;
    this.formState.endsAt = endsAt;
  }

  @Watch('formState.startsAt')
  @Watch('formState.endsAt')
  protected onTimeFrameUpdate() {
    if (this.formState.timeFrameKind === TimeFrameKind.MONTH) {
      this.formState.name = toLocaleString(
        this.formState.startsAt,
        this.$i18n.i18next.language,
        this.$timeZone.value,
        {
          month: 'short',
          year: '2-digit',
        },
      );
    } else if (this.formState.timeFrameKind === TimeFrameKind.WEEK) {
      this.formState.name = this.$t(
        'createShiftplan.modal.templateLabelCalendarWeek',
        { value: getISOWeek(getDateInTimeZone(this.formState.startsAt, this.$timeZone.value)) },
      );
    }

    // FAQ: reset rotationId so that you don't accidentally use a rotation that is not covering
    // the shiftplan period
    this.formState.rotationId = '';
  }

  protected get calendarSelectionMode(): IntervalSelectionMode {
    if (this.formState.timeFrameKind === ShiftplanTimeFrameKind.WEEK) {
      return IntervalSelectionMode.WEEK;
    }

    return IntervalSelectionMode.CUSTOM;
  }

  protected get isValid() {
    let isRotationValid = true;

    if (this.createShiftplanKind === CreateShiftplanKind.ROTATION) {
      const positions = Object.values(this.formState.positions);

      isRotationValid = !!this.formState.rotationId
                && positions.length > 0
                && positions.every(position => position.workers > 0)
                && positions.some(position => !position.isDisabled);
    }

    return !!this.formState.name
      && isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt, true)
      && isRotationValid;
  }

  protected get shiftRotationsCoveringShiftplanPeriod() {
    return getItemsCoveringInterval(
      this.shiftRotations,
      this.formState.startsAt,
      this.formState.endsAt,
      this.$timeZone.value,
    );
  }

  protected onCloseClick(e) {
    this.$emit('closeClick', createEventPayload(e, undefined));
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async createNewShiftplan() {
    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        input: {
          companyId: this.currentCompany?.id || 0,
          // FIXME: workaround for API ignoring supplied offset
          endsAt: formatISO(getDateInTimeZone(this.formState.endsAt, this.$timeZone.value), { representation: 'date' }),
          locationId: this.currentLocationId || 0,
          name: this.formState.name,
          // FIXME: workaround for API ignoring supplied offset
          startsAt: formatISO(getDateInTimeZone(this.formState.startsAt, this.$timeZone.value), { representation: 'date' }),
        },
      },
      this.createShiftplan,
      '',
    );

    return response;
  }

  protected async createRotationShiftplan() {
    const workersPerPositionsList = Object.entries(this.formState.positions)
      .filter(([, item]) => !item.isDisabled)
      .map(([id, item]) => ({
        id: Number.parseInt(id, 10),
        workers: item.workers,
      }));

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        input: {
          // FIXME: workaround for API ignoring supplied offset
          endsAt: formatISO(getDateInTimeZone(this.formState.endsAt, this.$timeZone.value), { representation: 'date' }),
          locationId: this.currentLocationId || 0,
          metadata: null,
          name: this.formState.name,
          // FIXME: workaround for API ignoring supplied offset
          startsAt: formatISO(getDateInTimeZone(this.formState.startsAt, this.$timeZone.value), { representation: 'date' }),
          workersPerPositionsList,
        },
        shiftRotationId: Number.parseInt(this.formState.rotationId, 10),
      },
      this.createShiftplanFromShiftRotation,
      '',
    );

    if (response.state !== StoreActionState.SUCCESS || !response.entityId) {
      return response;
    }

    const shiftplanId = response.entityId;
    const { jobId } = response.meta;

    this.setJobForShiftplan({
      jobId,
      shiftplanId,
    });

    await Promise.all([
      this.backgroundJobsRefetch(),
      this.fetchShiftplan({ id: shiftplanId }),
    ]);

    return response;
  }

  protected async onSubmit({ event }: EventPayload<void>) {
    event.preventDefault();

    if (!this.isValid) {
      return;
    }

    this.isSubmitting = true;
    this.$emit('submit', createEventPayload(event, true));

    const response = this.createShiftplanKind === CreateShiftplanKind.NEW
      ? await this.createNewShiftplan()
      : await this.createRotationShiftplan();

    this.isSubmitting = false;
    this.$emit('submit', createEventPayload(event, false));
    if (response.state !== StoreActionState.SUCCESS || !response.entityId) {
      return;
    }
    this.showSnackbar({
      message: this.$t('createShiftplan.modal.created'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });

    this.$router.push({
      name: Route.SHIFTPLAN,
      params: {
        shiftplanId: response.entityId.toString(),
      },
    });
  }

  protected initialPopulate() {
    this.formState.positions = this.positions.reduce((prev, cur) => {
      prev[cur.id] = { workers: 1 };
      return prev;
    }, {});
  }

  public mounted() {
    this.initialPopulate();
  }

  public render() {
    return (
      <CreateShiftplan
        formState={this.formState}
        isShiftRotationEnabled={this.isShiftRotationEnabled}
        positions={this.positions}
        shiftRotations={this.shiftRotations}
        formId={this.formId}
        onSubmit={this.onSubmit}
        onInput={this.onInput}
        createShiftplanKind={this.createShiftplanKind}
      />
    );
  }
}
