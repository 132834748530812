import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import styles from './button-reset-filters.css';

const FILTERS_COUNT_LIMIT = 99;

interface Props {
  selectedFiltersCount: number;
}

interface Events {
  onClick: EventPayload<void, HTMLElement, UIEvent>;
}

@Component
export default class ButtonResetFilters extends TsxComponent<Props, Events> {
  @Prop()
  public selectedFiltersCount: number;

  private get filtersCountString(): string {
    return this.selectedFiltersCount > FILTERS_COUNT_LIMIT
      ? `${FILTERS_COUNT_LIMIT}+` : this.selectedFiltersCount.toString();
  }

  public render() {
    return (
      <div class={styles.buttonResetFiltersWrapper}>
        {
          this.selectedFiltersCount > 0
          && <Button class={styles.buttonResetFilters}
            size={Size.SMALL}
            onClick={e => this.$emit('click', createEventPayload(e, undefined))}>
            {this.$t('shiftSchedule.filter.buttonResetFilters', { value: this.filtersCountString })}
          </Button>
        }
      </div>
    );
  }
}
