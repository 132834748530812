import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';
import type { GQLAbsenceConflictsFragmentFragment } from 'codegen/gql-types';
import { authNS, StoreState } from 'components/auth/store/Store';
import Spinner from 'components/spinner/Spinner';
import { GQLResponseExtension } from 'utils/store';
import { absencesNS } from '../store/Store';
import Action from '../store/Action';
import type { Absence, AbsenceApproveResult } from '../types';
import DialogConflicts from '../dialog-conflicts/DialogConflicts';
import DropdownState from '../dropdown-state/DropdownState';

import styles from './cell-state.css';
import DialogDecline from '../dialog-decline/DialogDecline';

@Component
export default class CellState extends TsxComponent<{
  absence: Absence;
}> {
  protected isWorking = false;

  protected isConflictsDialogOpen = false;

  protected isDeclineDialogOpen = false;

  protected conflicts: GQLAbsenceConflictsFragmentFragment['conflicts'] = [];

  protected canManage = false;

  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  @authNS.State
  protected currentEmploymentId: StoreState['currentEmploymentId'];

  @absencesNS.Action(Action.APPROVE)
  protected approveAbsence: (
    payload: {
      absenceId: number;
      forceCollision?: boolean;
      forceCollisionAndRemove?: boolean;
    }
  ) => Promise<AbsenceApproveResult | GQLResponseExtension>;

  @absencesNS.Action(Action.DECLINE)
  protected declineAbsence: (
    payload: { absenceId: number; refuseMessage?: string }
  ) => Promise<boolean | GQLResponseExtension>;

  @Prop()
  public absence: Absence;

  protected async tryApproveAbsence(payload: {
    forceCollision?: boolean;
    forceCollisionAndRemove?: boolean;
  } = {}) {
    this.conflicts = [];
    this.canManage = false;
    this.isWorking = true;

    const result = await this.approveAbsence({
      absenceId: this.absence.id,
      forceCollision: payload.forceCollision,
      forceCollisionAndRemove: payload.forceCollisionAndRemove,
    });

    this.isWorking = false;

    if (!('body' in result) && !result.success && 'canManage' in result) {
      this.isConflictsDialogOpen = true;
      this.conflicts = result.conflicts;
      this.canManage = result.canManage;
    }
  }

  protected closeAnyDialog() {
    this.isConflictsDialogOpen = false;
    this.isDeclineDialogOpen = false;
  }

  public render() {
    return (
      <td class={[tableStyles.tableBodyCell, styles.cellState]}>
        <DialogConflicts
          absence={this.absence as any}
          canManage={this.canManage}
          conflicts={this.conflicts}
          isCurrentEmployment={this.absence.employment.id === this.currentEmploymentId}
          isOpen={this.isConflictsDialogOpen}
          onCancelClick={this.closeAnyDialog}
          onApproveAndIgnoreClick={async () => {
            this.closeAnyDialog();
            await this.tryApproveAbsence({ forceCollision: true });
          }}
          onApproveAndRemoveClick={async () => {
            this.closeAnyDialog();
            await this.tryApproveAbsence({ forceCollisionAndRemove: true });
          }}
        />

        <DialogDecline
          onCancelClick={this.closeAnyDialog}
          onDeclineClick={async (refuseMessage) => {
            this.closeAnyDialog();
            this.isWorking = true;
            await this.declineAbsence({ absenceId: this.absence.id, refuseMessage });
            this.isWorking = false;
          }}
          isOpen={this.isDeclineDialogOpen}
        />

        {
          this.isWorking
            ? (
              <Spinner class={styles.cellStateSpinner} />
            ) : (
              <DropdownState
                absence={this.absence}
                onApproveClick={() => this.tryApproveAbsence()}
                onDeclineClick={() => { this.isDeclineDialogOpen = true; }}
              />
            )
        }
      </td>
    );
  }
}
