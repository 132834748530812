import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import styles from './button.css';

interface Props {
  disabled?: boolean;
  route: string;
  icon?: IconName.ARROW_NEXT | IconName.DONE;
  title?: string;
}

@Component
export default class Button extends TsxComponent<Props> {
  @Prop()
  public disabled?: Props['disabled'];

  @Prop()
  public route: Props['route'];

  @Prop()
  public icon?: Props['icon'];

  @Prop()
  public title?: Props['title'];

  public render() {
    return (
      <router-link
        activeClass={styles.buttonActive}
        aria-disabled={this.disabled}
        class={[styles.button, this.disabled && styles.buttonDisabled]}
        event={this.disabled ? [] : undefined}
        to={this.disabled ? '#' : { name: this.route }}
      >
        <span class={styles.buttonLabel} title={this.title}>{this.$slots.default}</span>
        {this.icon && (
          <Icon name={this.icon} size={Size.SMALL} class={styles.buttonIcon} />
        )}
      </router-link>
    );
  }
}
