import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import CalendarPrint from 'components/calendar/CalendarPrint';
import { FiltersMap } from 'components/calendar-common/filters/Store';
import { dayNotesNS } from 'store/day-notes/Store';
import type { FetchAllDayNotesFunction } from 'store/day-notes/Store';
import { Action as StoreAction } from 'store/normalized-store';
import { Shiftplan, shiftplansNS } from 'store/shiftplans/Store';
import type { GetById } from 'utils/store';
import type { PrintTimeframeKind } from 'components/calendar-common/Enums';
import styles from './calendar-print-container.css';
import { shiftScheduleNS, StoreState as CalendarStoreState } from '../store/Store';
import { StoreState as LocationsPositionsFilterStoreState, locationsPositionsFilterNS } from '../filter-box-locations-position/store/Store';
import { employmentFilterNS, StoreState as EmploymentsFilterStoreState } from '../filter-box-employment/store/Store';
import { quickFilterNS, StoreState as QuickFilterFilterStoreState } from '../filter-box-quick/store/Store';
import { StoreState as AbsenceReasonsFilterStoreState, absenceReasonsFilterNS } from '../filter-box-absence-reason/store/Store';

@Component
export default class CalendarPrintContainer extends TsxComponent<{
  shiftplanId?: number;
}> {
  @Prop()
  public shiftplanId: number;

  @authNS.State
  private currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.State
  private currentCompanyId: AuthStoreState['currentCompanyId'];

  @authNS.State
  private currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  private currentLocationId: AuthStoreState['currentLocationId'];

  @shiftScheduleNS.State
  private timeframe: CalendarStoreState['timeframe'];

  @shiftScheduleNS.State
  private printViewKind: CalendarStoreState['printViewKind'];

  @shiftScheduleNS.Getter
  private printTimeframeKind: PrintTimeframeKind;

  @shiftplansNS.Getter('items')
  protected shiftplans: Shiftplan[];

  @shiftplansNS.Getter('getById')
  protected getShiftplanById: GetById<Shiftplan>;

  @locationsPositionsFilterNS.State('selection')
  protected locationsPositionsSelection: LocationsPositionsFilterStoreState['selection'];

  @absenceReasonsFilterNS.State('selection')
  protected absenceReasonsSelection: AbsenceReasonsFilterStoreState['selection'];

  @employmentFilterNS.State('selection')
  protected employmentsSelection: EmploymentsFilterStoreState['selection'];

  @quickFilterNS.State('filters')
  protected quickFilters: QuickFilterFilterStoreState['filters'];

  @dayNotesNS.Action(StoreAction.FETCH_ALL)
  protected fetchAllDayNotes: FetchAllDayNotesFunction;

  /*
    We need to load some of required data before initialising calendar
  */
  private get isInitialLoadComplete() {
    return this.shiftplan
      && this.shiftplans.length > 0
      && this.currentEmployment !== undefined
      && this.currentCompany !== undefined;
  }

  @Watch('shiftplan', { immediate: true })
  protected onShiftplanChange() {
    if (!this.shiftplan) {
      return;
    }

    if (this.quickFilters.includes(FiltersMap.DAY_NOTES)) {
      this.fetchAllDayNotes({ shiftplanId: this.shiftplan.id });
    }
  }

  public get shiftplan() {
    if (!this.currentCompanyId || !this.currentLocationId) {
      return null;
    }

    const shiftplan = this.getShiftplanById(this.shiftplanId);

    if (!shiftplan) {
      return null;
    }

    return {
      ...shiftplan,
      locationId: this.currentLocationId,
      companyId: this.currentCompanyId,
    };
  }

  public render() {
    return (
      <div class={[styles.calendarContainer]}>
        <CalendarPrint
          filters={this.quickFilters}
          isInitialDataLoading={!this.isInitialLoadComplete}
          locationsPositionIds={this.locationsPositionsSelection}
          absenceReasonIds={this.absenceReasonsSelection}
          shiftplan={this.shiftplan}
          timeframe={this.timeframe}
          printViewKind={this.printViewKind}
          timeframeKind={this.printTimeframeKind}
        />
      </div>
    );
  }
}
