import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import DialogConfirmDelete from 'components/dialog-confirm-delete/DialogConfirmDelete';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { AlertKind } from 'components/alert/Alert';
import { SentryTag } from 'services/logger/SentryTransport';
import { redirectToNamedParent } from 'src/utils/route';
import { Action } from 'store/normalized-store';
import { tagsNS } from 'store/tags/Store';
import type {
  CreateTagFunction,
  DeleteTagFunction,
  FetchTagFunction,
  Tag,
  UpdateTagFunction,
} from 'store/tags/Store';
import { getRandomPastelColor } from 'utils/color';
import { EventPayload } from 'utils/events';
import { getFirstErrorFromResponse, StoreActionState } from 'utils/store';
import type { GetById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import DialogTag, { FormState } from './DialogTag';

export const getInitialFormState = (): FormState => ({
  color: getRandomPastelColor(),
  name: '',
});

export interface Props {
  tagId?: number;
}

@Component
export default class DialogTagContainer extends TsxComponent<Props, {}> {
  protected formState = getInitialFormState();

  protected isDeleteConfirmDialogOpen = false;

  protected isDeleting = false;

  protected isSubmitting = false;

  protected lastStoreActionState = StoreActionState.SUCCESS;

  protected tag: Tag | null = null;

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @tagsNS.Action(Action.CREATE)
  protected createTag: CreateTagFunction;

  @tagsNS.Action(Action.DELETE)
  protected deleteTag: DeleteTagFunction;

  @tagsNS.Action(Action.FETCH)
  protected fetch: FetchTagFunction;

  @tagsNS.Action(Action.UPDATE)
  protected updateTag: UpdateTagFunction;

  @tagsNS.Getter
  protected getById: GetById<Tag>;

  @Prop()
  public tagId?: Props['tagId'];

  protected get isUpdate() {
    return !!this.tagId;
  }

  protected onCloseClick() {
    redirectToNamedParent(this);
  }

  protected onDeleteClick() {
    this.isDeleteConfirmDialogOpen = true;
  }

  protected async onDeleteCancel() {
    this.isDeleteConfirmDialogOpen = false;
  }

  protected async onDeleteConfirm() {
    if (!this.tagId || !this.tag || !this.tag.location) {
      this.isDeleteConfirmDialogOpen = false;
      return false;
    }

    this.isDeleting = true;

    const response = await this.deleteTag({
      id: this.tagId,
      locationId: this.tag.location?.id || Number.NaN,
    });

    if (response.state === StoreActionState.ERROR) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, DialogTagContainer.name]],
        message: JSON.stringify(response),
      });

      this.isDeleting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: (response.error || this.$t('general.error.unknown')).toString(),
        timeout: 5000,
      });

      return false;
    }

    this.onCloseClick();

    this.isDeleting = false;
    this.isDeleteConfirmDialogOpen = false;

    return this.showSnackbar({
      message: this.$t('tags.modal.deleted'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit({ event }: EventPayload<void, HTMLElement, UIEvent>) {
    event.preventDefault();

    const tag = {
      color: this.formState.color,
      name: this.formState.name,
      locationId: this.isUpdate && this.tag && this.tag.location
        ? this.tag.location.id
        : (this.currentLocationId || 0),
    };

    this.isSubmitting = true;

    const response = this.isUpdate && this.tag
      ? await this.updateTag({ id: this.tag.id, tag })
      : await this.createTag({ tag });

    this.isSubmitting = false;

    if (response.state === StoreActionState.ERROR && typeof response.error !== 'string') {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, DialogTagContainer.name]],
        message: JSON.stringify(response),
      });

      const error = getFirstErrorFromResponse(response.error);

      const message = error
        ? this.$t(`tags.modal.error.${error.key}`, { ...error.val })
        : this.$t('general.error.unknown');

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });

      return false;
    }

    this.onCloseClick();

    return this.showSnackbar({
      message: this.isUpdate ? this.$t('tags.modal.updated') : this.$t('tags.modal.created'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  public async mounted() {
    if (this.isUpdate && this.tagId) {
      this.lastStoreActionState = StoreActionState.PENDING;

      const response = await this.fetch({ id: this.tagId });

      this.lastStoreActionState = response.state;

      if (this.lastStoreActionState !== StoreActionState.SUCCESS) {
        return;
      }

      this.tag = this.getById(this.tagId) || null;

      if (this.tag) {
        this.formState = {
          color: this.tag.color,
          name: this.tag.name,
        };
      }
    }
  }

  public render() {
    if (this.isDeleteConfirmDialogOpen) {
      return (
        <DialogConfirmDelete
          isOpen={true}
          isSubmitting={this.isDeleting}
          onCancel={this.onDeleteCancel}
          onConfirm={this.onDeleteConfirm}
          title={this.$t('tags.modal.titleDelete')}
        >
          {this.$t('tags.modal.messageDelete')}
        </DialogConfirmDelete>
      );
    }

    return (
      <DialogTag
        formState={this.formState}
        hasError={
          [StoreActionState.ERROR, StoreActionState.NOT_FOUND].includes(this.lastStoreActionState)
        }
        isLoading={this.lastStoreActionState === StoreActionState.PENDING}
        isOpen={true}
        isSubmitting={this.isSubmitting}
        isUpdate={this.isUpdate}
        onCloseClick={this.onCloseClick}
        onDeleteClick={this.onDeleteClick}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
      />
    );
  }
}
