import DateItem from 'components/calendar-common/common/DateItem';
import DndParams from 'components/calendar-common/grid/DndParams';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import {
  Component, Prop, Mixins,
} from 'vue-property-decorator';
import { OPEN_SHIFT_EMPLOYMENT_ID } from 'components/calendar-common/shifts/shift-params-helper/ShiftParamsHelper';
import styles from 'components/calendar-common/employments/employments.css';

@Component
class OpenShifts extends Mixins(ShiftViewActionsMixin) {
  @Prop()
  public dates: DateItem[];

  @Prop()
  public shifts: Record<string, Shift[]>;

  public render() {
    return (
      <GridRow dates={this.dates}
        cellClass={gridStyles.gridTableContentCellPaddingBottom}
        dndParams={{ ...DndParams, employmentId: OPEN_SHIFT_EMPLOYMENT_ID, isDroppable: true }}
        onCellClick={dateItem => this.onShiftCellClick(dateItem)}
        scopedSlots={{
          cell: ({ dateItem }: GridRowScope) => this.shifts[dateItem.dateKey]
            .map(shift => this.$scopedSlots.shift
            && this.$scopedSlots.shift({ shift, employment: { id: OPEN_SHIFT_EMPLOYMENT_ID } })),
        }}>
        <GridCell slot="label"
          isHeader={true}
          class={styles.employeeCell}>
          <span class={styles.employeeCellText}>
            {this.$t('calendar.openShifts')}
          </span>
        </GridCell>
      </GridRow>);
  }
}

export default OpenShifts as any;
