import DateItem from 'components/calendar-common/common/DateItem';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { absencesNS } from 'components/calendar/absences/Store';
import Absence from 'components/calendar-common/absences/Absence';
import Shift from 'components/calendar-common/shifts/Shift';
import Summary from 'components/calendar-common/summary/Summary';

@Component
class SummaryContainer extends Vue {
  @Prop()
  public dates: DateItem[];

  @Prop()
  public shiftsByDates: Record<string, Shift[]>;

  @absencesNS.Getter('absencesSummary')
  public absences:
  Record<string, Absence[]>;

  private get aggregationsByDates() {
    return Object.fromEntries(
      this.dates.map(date => ([date.dateKey, {
        demand: this.shiftsByDates[date.dateKey].reduce((acc, shift) => acc + shift.workers, 0),
        working: this.shiftsByDates[date.dateKey]
          .reduce((acc, shift) => acc + shift.staffShiftsLength, 0),
        absent: this.absences[date.dateKey]
          .reduce((acc, absence) => {
            acc.add(absence.employment.id);
            return acc;
          }, new Set()).size,
      }])),
    );
  }

  public render() {
    return (<Summary
      dates={this.dates}
      aggregationsByDates={this.aggregationsByDates}/>);
  }
}

export default SummaryContainer as any;
