import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SelectedTimeframe } from 'components/datepicker/types';
import {
  calendarDataNS, StoreState, UPDATE_THROTTLE_TIME,
} from 'components/calendar/data/Store';
import { calendarCommonNS } from 'components/calendar/common/Store';
import DayNotesPopup from 'components/calendar-common/notes/day-notes-popup/DayNotesPopup.jsx';
import AgendaDays from 'components/calendar/agenda/agenda-days/AgendaDays';
import AgendaMonth from 'components/calendar/agenda/agenda-month/AgendaMonth';
import { SET_SHIFTPLAN, SET_SLOT_DISPLAY_STYLE, SET_TIMEFRAME } from 'components/calendar/common/Actions';
import { highlightsNS, SetHighlightPayload, HighlightableEventTypes } from 'components/calendar-common/highlights/Store';
import Loader from 'components/loader/Loader';
import EmploymentsDays from './employments/days/Days';
import EmploymentsMonth from './employments/month/Month';
import ListDays from './list/list-days/ListDays';
import ListMonth from './list/list-month/ListMonth';
import PositionsDays from './positions/days/Days';
import PositionsMonth from './positions/month/Month';
import {
  LoadState, SlotDisplayStyle, TimeframeKind, ViewKind,
} from '../calendar-common/Enums';
import { LOAD_DATA, LOAD_EVENTS } from './data/Actions';
import { FiltersMap, calendarFiltersNS } from '../calendar-common/filters/Store';
import FilterAction from '../calendar-common/filters/Actions';
import { tagsNS } from '../calendar-common/tags/Store';
import { SET_SELECTED_TAG_IDS } from '../calendar-common/tags/Actions';
import HighlightAction from '../calendar-common/highlights/Actions';
import type { Shiftplan } from '../calendar-common/types';
import DragAndDropEventsContainer from './drag-and-drop-events-container/DragAndDropEventsContainer';

const monthComponents = {
  [ViewKind.LIST]: ListMonth,
  [ViewKind.AGENDA]: AgendaMonth,
  [ViewKind.POSITIONS]: PositionsMonth,
  [ViewKind.EMPLOYMENTS]: EmploymentsMonth,
};

const dayComponents = {
  [ViewKind.LIST]: ListDays,
  [ViewKind.AGENDA]: AgendaDays,
  [ViewKind.POSITIONS]: PositionsDays,
  [ViewKind.EMPLOYMENTS]: EmploymentsDays,
};

interface Props {
  isInitialDataLoading: boolean;
  viewKind: ViewKind;
  timeframeKind: TimeframeKind;
  timeframe: SelectedTimeframe;
  shiftplan: Shiftplan | null;
  locationsPositionIds: number[] | null;
  shiftRotationGroupIds: number[] | null;
  tagIds: number[] | null;
  employmentIds: number[] | null;
  filters: FiltersMap[];
  slotDisplayStyle: SlotDisplayStyle;
  shiftPresetIds: number[];
  assignmentGroupIds: number[];
}

interface Events {
  onClearHightlight: void;
  onSetHighlightType: { payload: HighlightableEventTypes };
}

@Component
class Calendar extends TsxComponent<Props, Events> {
  private loadEventsTimeoutId: number | null = null;

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public isInitialDataLoading: Props['isInitialDataLoading'];

  @Prop()
  public viewKind: Props['viewKind'];

  @Prop()
  public timeframeKind: Props['timeframeKind'];

  @Prop()
  public timeframe: Props['timeframe'];

  @Prop()
  public locationsPositionIds: Props['locationsPositionIds'];

  @Prop()
  public shiftPresetIds: Props['shiftPresetIds'];

  @Prop()
  public shiftRotationGroupIds: Props['shiftRotationGroupIds'];

  @Prop()
  public assignmentGroupIds: Props['assignmentGroupIds'];

  @Prop()
  public tagIds: Props['tagIds'];

  @Prop()
  public employmentIds: Props['employmentIds'];

  @Prop()
  public filters: Props['filters'];

  @Prop()
  public slotDisplayStyle: SlotDisplayStyle;

  @calendarDataNS.State((state: StoreState) => state.loadState)
  private calendarLoadState: LoadState;

  @highlightsNS.State('type')
  private highlightedEventType: HighlightableEventTypes | null;

  @calendarDataNS.Action(LOAD_DATA)
  public loadData;

  @calendarDataNS.Action(LOAD_EVENTS)
  public loadEvents;

  @calendarCommonNS.Action(SET_TIMEFRAME)
  public setTimeframe: (timeframe: SelectedTimeframe) => void;

  @calendarCommonNS.Action(SET_SHIFTPLAN)
  public setShiftplan: (timeframe: Shiftplan) => void;

  @calendarCommonNS.Action(SET_SLOT_DISPLAY_STYLE)
  public setSlotDisplayStyle: (slotDisplayStyle: SlotDisplayStyle) => void;

  @calendarFiltersNS.Action(FilterAction.SET_FILTERS)
  public setFilters: (filters: FiltersMap[]) => void;

  @calendarFiltersNS.Action(FilterAction.SET_LOCATIONS_POSITION_IDS)
  public setLocationsPositionIds: (ids: number[] | null) => void;

  @calendarFiltersNS.Action(FilterAction.SET_SHIFT_ROTATION_GROUP_IDS)
  public setShiftRotationGroupIds: (ids: number[] | null) => void;

  @tagsNS.Action(SET_SELECTED_TAG_IDS)
  public setSelectedTagIds: (ids: number[] | null) => void;

  @highlightsNS.Action(HighlightAction.SET)
  public setHighlight: (payload: SetHighlightPayload) => void;

  @highlightsNS.Action(HighlightAction.CLEAR)
  public clearHighlight: () => void;

  protected throttledLoadEvents() {
    // do no try to load event till initial data is prepared
    // and data query is started
    if (this.isInitialDataLoading || this.calendarLoadState === LoadState.NOT_LOADED) {
      return;
    }
    if (this.loadEventsTimeoutId) {
      window.clearTimeout(this.loadEventsTimeoutId);
    }

    this.loadEventsTimeoutId = window.setTimeout(() => {
      this.loadEvents();
    }, UPDATE_THROTTLE_TIME);
  }

  @Watch('shiftplan', { immediate: true })
  protected onShiftplanChange(currentValue: Shiftplan | null) {
    if (currentValue) {
      this.setShiftplan(currentValue);
      this.throttledLoadEvents();
    }
  }

  @Watch('filters', { immediate: true })
  protected onFiltersChange(filters: FiltersMap[]) {
    this.setFilters(filters);
    this.throttledLoadEvents();
  }

  @Watch('locationsPositionIds', { immediate: true })
  protected onLocationPositionIdsChange(currentValue: number[] | null) {
    this.setLocationsPositionIds(currentValue);
    this.throttledLoadEvents();
  }

  @Watch('tagIds', { immediate: true })
  protected onTagIdsChange(currentValue: number[] | null) {
    this.setSelectedTagIds(currentValue);
    this.throttledLoadEvents();
  }

  @Watch('shiftPresetIds', { immediate: true })
  protected onShiftPresetIdsChange() {
    this.throttledLoadEvents();
  }

  @Watch('shiftRotationGroupIds', { immediate: true })
  protected onShiftRotationGroupIdsChange(ids: number[] | null) {
    if (ids === null || ids.length === 0) {
      this.setShiftRotationGroupIds(null);
      this.clearHighlightIfActive(HighlightableEventTypes.ShiftRotationGroup);
    } else {
      this.setShiftRotationGroupIds(ids);
      this.setHighlight(ids.map(shiftRotationGroupId => ({
        type: HighlightableEventTypes.ShiftRotationGroup,
        id: shiftRotationGroupId,
      })));
      this.$emit('setHighlightType', {
        payload: HighlightableEventTypes.ShiftRotationGroup,
      });
    }
  }

  @Watch('assignmentGroupIds', { immediate: true })
  protected onAssignmentGroupIdsChange(ids: number[] | null) {
    if ((ids === null || ids.length === 0)) {
      this.clearHighlightIfActive(HighlightableEventTypes.AssignmentGroup);
    } else {
      this.setHighlight(ids.map(assignmentGroupId => ({
        type: HighlightableEventTypes.AssignmentGroup,
        id: assignmentGroupId,
      })));
      this.$emit('setHighlightType', {
        payload: HighlightableEventTypes.AssignmentGroup,
      });
    }
  }

  @Watch('employmentIds', { immediate: true })
  protected onEmploymentIdsChange(ids: number[] | null) {
    if (ids === null || ids.length === 0) {
      this.clearHighlightIfActive(HighlightableEventTypes.Employment);
    } else {
      this.setHighlight(ids.map(employmentId => ({
        type: HighlightableEventTypes.Employment,
        id: employmentId,
      })));
      this.$emit('setHighlightType', {
        payload: HighlightableEventTypes.Employment,
      });
    }
  }

  @Watch('timeframe', { immediate: true })
  protected onTimeframeChange() {
    this.setTimeframe(this.timeframe);
    this.throttledLoadEvents();
  }

  @Watch('slotDisplayStyle', { immediate: true })
  protected onSlotDisplayStyleChange() {
    this.setSlotDisplayStyle(this.slotDisplayStyle);
  }

  @Watch('isInitialDataLoading', { immediate: true })
  protected onIsLoadingChange(currentValue) {
    if (!currentValue) {
      this.loadData();
    }
  }

  private clearHighlightIfActive(type: HighlightableEventTypes) {
    if (type === this.highlightedEventType) {
      this.clearHighlight();
      this.$emit('clearHighlight');
    }
  }

  public beforeDestroy() {
    if (this.loadEventsTimeoutId) {
      window.clearTimeout(this.loadEventsTimeoutId);
    }
  }

  public render() {
    const ComponentName = (this.timeframeKind === TimeframeKind.MONTH)
      ? monthComponents[this.viewKind]
      : dayComponents[this.viewKind];
    return (<div>
      {
        this.isInitialDataLoading || this.calendarLoadState !== LoadState.LOADED
          ? <Loader/>
          : (<div>
            <DragAndDropEventsContainer viewComponent={ComponentName} />
            <DayNotesPopup />
          </div>)
      }
    </div>);
  }
}

export default Calendar;
