import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

export const OVERLAY_PORTAL_NAME = 'overlay';

@Component
class OverlayPortalTarget extends TsxComponent<{}> {
  public render() {
    return (
      <portal-target name={OVERLAY_PORTAL_NAME} multiple>
      </portal-target>
    );
  }
}

export default OverlayPortalTarget;
