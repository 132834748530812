import TwoColumn from 'layouts/two-column/TwoColumn';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS } from 'components/auth/store/Store';
import type {
  HasAnyRightFunction,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import ScreenIllustration, { Illustration } from 'components/screen/ScreenIllustration';
import { shiftplansNS } from 'store/shiftplans/Store';
import type { Shiftplan } from 'store/shiftplans/Store';
import { ShiftplanScopedSettings } from 'store/ui-settings/Store';
import { getDefaultShiftplan } from 'utils/shift-schedule';
import { Mode } from 'components/calendar-common/Enums';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { ShiftRotation } from 'src/store/shift-rotations/Store';
import FilterBoxAssignmentGroup from './filter-box-assignment-group/FilterBoxAssignmentGroup';
import CalendarContainer from './calendar-container/CalendarContainer';
import FilterBoxEmployment from './filter-box-employment/FilterBoxEmployment';
import Header from './header/Header';
import FilterBoxQuick, { QUICK_FILTERS_MAP } from './filter-box-quick/FilterBoxQuick';
import FilterBoxLocationsPosition from './filter-box-locations-position/FilterBoxLocationsPosition';
import FilterBoxTag from './filter-box-tag/FilterBoxTag';
import FilterBoxShiftPreset from './filter-box-shift-preset/FilterBoxShiftPreset';
import { Route } from './routes';
import styles from './shift-schedule.css';
import type { QuickFilterItem } from './filter-box-quick/types';
import CalendarControlsContainer from './calendar-controls/CalendarControlsContainer';
import FilterBoxShiftRotationGroup from './filter-box-shift-rotation-group/FilterBoxShiftRotationGroup';
import CalendarResetFiltersContainer from './calendar-reset-filters-container/CalendarResetFiltersContainer';
import { FiltersMap } from '../calendar-common/filters/Store';
import { shiftScheduleNS } from './store/Store';

interface Props {
  hasUnfinishedBackgroundJob?: boolean;
  settings?: ShiftplanScopedSettings;
  shiftplan?: Shiftplan;
  shiftplans?: Shiftplan[];
  shiftRotations?: ShiftRotation[];
  isShiftplanFiltersSidebarCollapsed: boolean;
}

interface Events {
  onFilterSidebarToggle: void;
}

@Component
export default class ShiftSchedule extends TsxComponent<Props, Events> {
  @Prop()
  public hasUnfinishedBackgroundJob: Props['hasUnfinishedBackgroundJob'];

  @Prop()
  public settings: Props['settings'];

  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public shiftplans: Props['shiftplans'];

  @Prop()
  public shiftRotations: Props['shiftRotations'];

  @Prop()
  public isShiftplanFiltersSidebarCollapsed: Props['isShiftplanFiltersSidebarCollapsed'];

  @authNS.Getter
  public hasAnyRight: HasAnyRightFunction;

  @authNS.Getter
  public hasAnyCurrentLocationRight: HasAnyRightFunction;

  @authNS.Getter
  public isSuperAdmin: boolean;

  @authNS.State
  public currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  public currentLocationId: AuthStoreState['currentLocationId'];

  @authNS.State
  public currentEmployment: AuthStoreState['currentEmployment'];

  @shiftScheduleNS.Getter
  protected shiftScheduleQuickFilters: FiltersMap[];

  @calendarCommonNS.Getter('mode')
  protected mode: Mode;

  @shiftplansNS.Getter('canCreateShiftplan')
  public canCreateShiftplan: boolean;

  private get isEmployeeView() {
    return this.mode === Mode.EMPLOYEE;
  }

  protected get isApplyRotationActionAllowed() {
    return !!this.currentCompany?.shiftRotationEnabled && this.isShiftplanCreationAllowed;
  }

  protected get isShiftplanCreationAllowed() {
    return this.isSuperAdmin
      || (
        !!this.currentEmployment?.isStakeholder && (
          this.hasAnyRight('payments_manage_all', 'shifts_manage_all')
          || this.hasAnyCurrentLocationRight('payment_manage_right', 'shift_manage_right')
        )
      );
  }

  protected get quickFilterItems(): QuickFilterItem[] {
    return this.shiftScheduleQuickFilters.map((filterKey) => {
      const filter = QUICK_FILTERS_MAP[filterKey];
      return {
        ...filter,
        id: filterKey,
        name: filterKey,
        label: this.$t(filter.label),
      };
    });
  }

  private redirectToDefaultShiftplanIfRequired() {
    if (this.shiftplan) {
      return;
    }

    if (!this.shiftplans?.length) {
      this.$router.push({ name: Route.ROOT });
      return;
    }

    const shiftplan = getDefaultShiftplan(this.shiftplans, this.$timeZone.value);

    if (shiftplan) {
      this.$router.push({
        name: Route.SHIFTPLAN,
        params: { shiftplanId: shiftplan.id.toString() },
      });
    }
  }

  public mounted() {
    this.redirectToDefaultShiftplanIfRequired();
  }

  public render() {
    if (!this.shiftplans?.length) {
      return (
        <div
          class={{
            [styles.shiftSchedule]: true,
            [styles.shiftScheduleEmployee]: this.isEmployeeView,
          }}
        >
          <Header
            selectedShiftplan={this.shiftplan}
            shiftplans={this.shiftplans}
            isApplyRotationActionAllowed={this.isApplyRotationActionAllowed}
            isShiftplanCreationAllowed={this.isShiftplanCreationAllowed}
            title={this.$t('shiftSchedule.pageTitle')}
          />

          <ScreenIllustration
            title={this.$t('shiftSchedule.none.title')}
            message={this.$t('shiftSchedule.none.message')}
            illustration={Illustration.OOPS}
          />

          <router-view />
        </div>
      );
    }

    if (this.hasUnfinishedBackgroundJob) {
      return (
        <div
          class={{
            [styles.shiftSchedule]: true,
            [styles.shiftScheduleEmployee]: this.isEmployeeView,
          }}
        >
          <Header
            selectedShiftplan={this.shiftplan}
            shiftplans={this.shiftplans}
            isApplyRotationActionAllowed={false}
            isShiftplanCreationAllowed={this.isShiftplanCreationAllowed}
            title={this.$t('shiftSchedule.pageTitle')}
          />
          <ScreenIllustration
            title={this.$t('shiftSchedule.backgroundJobUnfinished.title')}
            message={this.$t('shiftSchedule.backgroundJobUnfinished.message')}
            illustration={Illustration.POSITION}
          />

          <router-view />
        </div>
      );
    }

    if (this.hasUnfinishedBackgroundJob) {
      return (
        <div
          class={{
            [styles.shiftSchedule]: true,
            [styles.shiftScheduleEmployee]: this.isEmployeeView,
          }}
        >
          <Header
            selectedShiftplan={this.shiftplan}
            shiftplans={this.shiftplans}
            isShiftplanCreationAllowed={this.isShiftplanCreationAllowed}
            title={this.$t('shiftSchedule.pageTitle')}
          />
          <ScreenIllustration
            title={this.$t('shiftSchedule.backgroundJobUnfinished.title')}
            message={this.$t('shiftSchedule.backgroundJobUnfinished.message')}
            illustration={Illustration.POSITION}
          />

          <router-view />
        </div>
      );
    }

    return (
      <div
        class={{
          [styles.shiftSchedule]: true,
          [styles.shiftScheduleEmployee]: this.isEmployeeView,
        }}
      >
        <Header
          selectedShiftplan={this.shiftplan}
          shiftplans={this.shiftplans}
          isApplyRotationActionAllowed={this.isApplyRotationActionAllowed}
          isShiftplanCreationAllowed={this.isShiftplanCreationAllowed}
          title={this.$t('shiftSchedule.pageTitle')}
        />
        {
          this.isEmployeeView
            ? (
              <main slot="two" class={styles.shiftScheduleMain}>
                <CalendarControlsContainer
                  class={styles.shiftScheduleControls}
                  settings={this.settings}
                  shiftplan={this.shiftplan}
                />
                <CalendarContainer shiftplanId={this.shiftplan?.id || 0} />
              </main>)
            : (
              <TwoColumn class={{
                [styles.shiftScheduleContainer]: true,
                [styles.shiftScheduleContainerSidebarCollapsed]:
                  this.isShiftplanFiltersSidebarCollapsed,
              }}>
                <div slot="one" class={styles.shiftScheduleSidebarWrapper}>
                  <div class={styles.shiftScheduleSidebar}>
                    <FilterBoxQuick items={this.quickFilterItems} />
                    <FilterBoxLocationsPosition />
                    <FilterBoxTag />

                    {
                      this.currentCompany?.canUseShiftPresets && (
                        <FilterBoxShiftPreset />
                      )
                    }

                    {this.currentCompany?.shiftRotationEnabled && (
                      this.shiftRotations?.map(shiftRotation => (
                        <FilterBoxShiftRotationGroup
                          shiftRotation={shiftRotation}
                        />
                      ))
                    )}

                    <FilterBoxAssignmentGroup shiftplanId={this.shiftplan?.id} />
                    <FilterBoxEmployment />
                    <CalendarResetFiltersContainer />
                  </div>
                </div>

                <main slot="two" class={styles.shiftScheduleMain}>
                  <CalendarControlsContainer
                    class={styles.shiftScheduleControls}
                    settings={this.settings}
                    shiftplan={this.shiftplan}
                    onFilterSidebarToggle={() => this.$emit('filterSidebarToggle')}
                  />
                  <CalendarContainer
                    class={styles.shiftScheduleCalendar} shiftplanId={this.shiftplan?.id || 0} />
                </main>
              </TwoColumn>
            )
        }

        <router-view />
      </div>
    );
  }
}
