import { Key } from 'src/utils/keyboard';
import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import { Component as TsxComponent } from 'vue-tsx-support';
import { DropdownItem, DropdownItemKind } from 'components/dropdown/types';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import styles from './dropdown.css';

export interface Props {
  id?: string;
  items: DropdownItem[];
  hideChevron?: boolean;
  isBorderless?: boolean;
  openToLeft?: boolean;
  stayOpenOnItemClick?: boolean;
  disabled?: boolean;
  icon?: IconName;
  chevronSize?: Size.SMALL | Size.LARGE;
  hideFocusBorder?: boolean;
}

@Component
export default class Dropdown extends TsxComponent<Props> {
  public $refs: {
    buttonRef: HTMLButtonElement;
    dropdownRef: HTMLUListElement;
    itemsRef: HTMLAnchorElement[];
  };

  @Prop({ default: () => `dropdown-${getRandomString()}` })
  public id: Props['id'];

  @Prop()
  public items: Props['items'];

  @Prop({ default: false })
  public hideChevron: NonNullable<Props['hideChevron']>;

  @Prop()
  public isBorderless: NonNullable<Props['isBorderless']>;

  @Prop({ default: false })
  public openToLeft: NonNullable<Props['openToLeft']>;

  @Prop({ default: false })
  public stayOpenOnItemClick: NonNullable<Props['stayOpenOnItemClick']>;

  @Prop({ default: false })
  public disabled: NonNullable<Props['disabled']>;

  @Prop()
  public icon: Props['icon'];

  @Prop()
  public chevronSize?: Props['chevronSize'];

  @Prop()
  public hideFocusBorder?: Props['hideFocusBorder'];

  protected isExpanded = false;

  protected onButtonClick() {
    this.isExpanded = !this.isExpanded;
  }

  protected async onButtonKeyDown(e: KeyboardEvent) {
    if (e.key === Key.ENTER || e.key === Key.SPACE || e.key === Key.ARROW_DOWN) {
      this.isExpanded = true;
      await this.$nextTick();
      const [firstItem] = this.$refs.itemsRef;
      firstItem.focus();
    } else if (e.key === Key.ARROW_UP) {
      this.isExpanded = true;
      await this.$nextTick();
      const [lastItem] = this.$refs.itemsRef.slice(-1);
      lastItem.focus();
    }
  }

  protected onMenuItemKeyDown(e: KeyboardEvent) {
    if (e.key === Key.ENTER) {
      this.isExpanded = false;
      this.$refs.buttonRef.focus();
      return;
    }

    if (e.key === Key.ESCAPE) {
      return;
    }

    const index = this.$refs.itemsRef.findIndex(o => o === e.target);

    if (index < 0) {
      return;
    }

    let newIndex = index;

    if (e.key === Key.ARROW_DOWN) {
      newIndex = ((index + 1) + this.$refs.itemsRef.length) % this.$refs.itemsRef.length;
    } else if (e.key === Key.ARROW_UP) {
      newIndex = ((index - 1) + this.$refs.itemsRef.length) % this.$refs.itemsRef.length;
    }

    this.$refs.itemsRef[newIndex].focus();
  }

  public render() {
    return (
      <div
        class={[
          styles.dropdown,
          this.isBorderless && styles.dropdownWithoutBorder,
          this.disabled && styles.dropdownDisabled,
          this.hideFocusBorder && styles.hideFocusBorder]}
        ref="dropdownRef"
      >
        {this.isExpanded && (
          <OutsideClickHandler
            insideRef={this.$refs.dropdownRef}
            onOutsideClick={(e) => {
              this.isExpanded = false;

              if (e instanceof KeyboardEvent) {
                this.$refs.buttonRef.focus();
              }
            }}
          />
        )}
        <button
          disabled={this.disabled}
          aria-controls={`${this.id}-menu`}
          aria-expanded={this.isExpanded}
          aria-haspopup="true"
          class={styles.dropdownButton}
          id={`${this.id}-button`}
          onClick={this.onButtonClick}
          onKeydown={this.onButtonKeyDown}
          ref="buttonRef"
          type="button"
        >
          {this.icon && <Icon
            aria-hidden="true"
            class={styles.dropdownPrefixIcon}
            name={this.icon}
            size={this.chevronSize}
          ></Icon>}
          {this.$slots.default}
          {!this.hideChevron && (
            <Icon
              aria-hidden="true"
              class={[
                styles.dropdownChevron,
                this.isExpanded && styles.dropdownChevronMenuHidden,
              ]}
              name={IconName.CHEVRON_DOWN}
              size={this.chevronSize}
            />
          )}
        </button>
        <ul
          aria-labelledby={`${this.id}-button`}
          class={[
            styles.dropdownMenu,
            this.openToLeft && styles.dropdownMenuLeft,
            !this.isExpanded && styles.dropdownMenuHidden,
          ]}
          id={`${this.id}-menu`}
          role="menu"
          tabindex={-1}
        >
          {this.items?.filter((item): item is NonNullable<DropdownItem> => !!item)
            .map((item, index) => {
              if (item.type === DropdownItemKind.TEXT
                || item.type === DropdownItemKind.SUBTITLE) {
                return (
                  <li
                    class={[styles.dropdownMenuItem,
                      item.type === DropdownItemKind.TEXT && styles.dropdownMenuItemText,
                      item.type
                      === DropdownItemKind.SUBTITLE && styles.dropdownMenuItemSubtitle,
                    ]}
                    key={`text${index}`}
                  >{item.label}
                  </li>
                );
              }
              if (item.type === DropdownItemKind.RULER) {
                return (
                  <li
                    class={styles.dropdownMenuItemSeparator}
                    key={`ruler${index}`}
                    role="separator"
                  />
                );
              }

              if (item.type === DropdownItemKind.LINK) {
                return (
                  <li
                    key={item.label.toString() + index}
                    role="none"
                  >
                    <a
                      class={[styles.dropdownMenuItem, item.class]}
                      href={item.href}
                      onClick={() => {
                        if (!this.stayOpenOnItemClick) {
                          this.isExpanded = false;
                        }
                      }}
                      onKeydown={this.onMenuItemKeyDown}
                      ref="itemsRef"
                      refInFor={true}
                      role="menuitem"
                    >
                      {item.icon
                        && <Icon class={styles.dropdownMenuItemIcon}
                          name={item.icon}
                          size={Size.SMALL}/>}
                      {item.label}
                    </a>
                  </li>
                );
              }

              if (item.type === DropdownItemKind.ROUTER_LINK) {
                return (
                  <li
                    key={item.label.toString() + index}
                    role="none"
                  >
                    <router-link
                      class={[styles.dropdownMenuItem, item.class]}
                      to={{ name: item.route, params: item.params }}
                      nativeOnClick={() => {
                        if (!this.stayOpenOnItemClick) {
                          this.isExpanded = false;
                        }
                      }}
                      onKeydown={this.onMenuItemKeyDown}
                      ref="itemsRef"
                      refInFor={true}
                      role="menuitem"
                    >
                      {item.icon
                      && <Icon class={styles.dropdownMenuItemIcon}
                        name={item.icon}
                        size={Size.SMALL}/>}
                      {item.label}
                    </router-link>
                  </li>
                );
              }

              if (item.type === DropdownItemKind.BUTTON) {
                return (
                  <li
                    key={item.label.toString() + index}
                    role="none"
                  >
                    <button
                      class={{
                        [styles.dropdownMenuItem]: true,
                        [item.class as string]: item.class !== undefined,
                      }}
                      disabled={item.disabled}
                      onClick={(e) => {
                        item.onClick(e);

                        if (!this.stayOpenOnItemClick) {
                          this.isExpanded = false;
                        }
                      }}
                      onKeydown={this.onMenuItemKeyDown}
                      ref="itemsRef"
                      refInFor={true}
                      role="menuitem"
                      type="button"
                    >
                      {item.icon
                      && <Icon class={styles.dropdownMenuItemIcon}
                        name={item.icon}
                        size={Size.SMALL}/>}
                      {item.label}
                    </button>
                  </li>
                );
              }
              return null;
            })
          }
        </ul>
      </div>
    );
  }
}
