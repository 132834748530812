export type TableColumns<TData, TFilters = {}> = TableColumn<TData, TFilters>[];

export interface TableColumn<TData, TFilters = {}> {
  rowIdKey?: keyof TData;
  /**
   * @deprecated Use filter instead
   */
  isFilterable?: boolean;
  isSortable?: boolean;
  isStretch?: boolean;
  label: string;
  width?: string;
  filter?: {
    key: keyof TFilters;
    icon?: string;
    isActive?: boolean;
    component: any;
    translationPrefix?: string;
  };
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  UNDEFINED = 'undefined',
}

export interface SortOptions<T> {
  key: keyof T;
  direction: SortDirection;
}
