import { GQLAbsenceState } from 'codegen/gql-types';
import Attachment from 'components/attachment/Attachment';
import AttachmentUpload from 'components/attachment-upload/AttachmentUpload';
import { Slot } from 'components/dialog/Dialog';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import FormDialog from 'components/form/form-dialog/FormDialog';
import Section, { SectionKind } from 'components/form/form-dialog/Section';
import InputDateTime, { Kind } from 'components/form/input-date-time/InputDateTime';
import InputSelect from 'components/form/input-select/InputSelect';
import InputText from 'components/form/input-text/InputText';
import InputTextArea from 'components/form/input-text-area/InputTextArea';
import InputToggle from 'components/form/input-toggle/InputToggle';
import {
  addYears,
  differenceInBusinessDays,
  isBefore,
} from 'date-fns';
import { isEqual } from 'lodash';
import {
  endOf,
  startOf,
  toLocaleDateString,
  Unit,
} from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import Action from '../store/Action';
import { absencesNS } from '../store/Store';
import type { AbsenceReason, Employment } from '../types';
import styles from './dialog-absence.css';

// For any date that is 2 year prior to current
// date is going to get an error from API.
const MAXIMUM_ALLOWED_YEARS_IN_FUTURE = 2;

// required to make browser form validation work
const FORM_ID = 'create-modal';

const DEBOUNCE_TIMEOUT = 500;

export interface FormState {
  attachment?: File;
  daysDuration: string;
  employmentId: string;
  endsAt: Date;
  existingAttachment?: {
    name: string;
    url: string;
  };
  hoursDuration: string;
  hoursDurationNumber: number;
  isFullDay: boolean;
  isPaid: boolean;
  notes: string;
  reasonId: string;
  startsAt: Date;
  state: string;
}

export interface CollisionOverrideOption {
  forceCollision?: boolean;
  forceCollisionAndRemove?: boolean;
}

@Component
export default class DialogAbsence extends TsxComponent<{
  hasError?: boolean;
  isLoading?: boolean;
  employments?: Employment[];
  formState: FormState;
  isAttachmentUploadShown?: boolean;
  isManager?: boolean;
  isOpen?: boolean;
  isSubmitting?: boolean;
  isUpdate?: boolean;
  reasons?: AbsenceReason[];
  states?: GQLAbsenceState[];
}, {
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] } >,
  ) => void;
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onDeleteClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onSubmit: (payload: EventPayload<CollisionOverrideOption, HTMLElement, UIEvent>) => void;
}> {
  protected timeout = Number.NaN;

  protected hasGetAbsenceDurationFailed = false;

  // just initialize to something to enable reactivity
  protected maxEndsAtDate = new Date();

  @Prop({ default: () => [] })
  public employments: Employment[];

  @Prop()
  public formState: FormState;

  @Prop()
  public hasError?: boolean;

  @Prop()
  public isLoading?: boolean;

  @Prop()
  public isAttachmentUploadShown?: boolean;

  @Prop()
  public isManager?: boolean;

  @Prop()
  public isOpen?: boolean;

  @Prop()
  public isSubmitting?: boolean;

  @Prop()
  public isUpdate?: boolean;

  @Prop({ default: () => [] })
  public reasons: AbsenceReason[];

  @Prop({ default: () => [] })
  public states: GQLAbsenceState[];

  @absencesNS.Action(Action.GET_ABSENCE_DURATION)
  protected getAbsenceDuration: (payload: any) => Promise<Record<string, any> | null>;

  protected get estimatedDuration() {
    return (this.formState.startsAt && this.formState.endsAt)
      ? differenceInBusinessDays(this.formState.endsAt, this.formState.startsAt) + 1
      : 0;
  }

  protected get isEndsAtBeforeStartsAt() {
    if (!(this.formState.startsAt instanceof Date && this.formState.endsAt instanceof Date)) {
      return false;
    }

    return isBefore(this.formState.endsAt, this.formState.startsAt);
  }

  protected get isEndsAtTooFarInFuture() {
    if (!(this.formState.endsAt instanceof Date)) {
      return false;
    }

    const toValidate = endOf(this.formState.endsAt, Unit.DAY, this.$timeZone.value);

    return !isBefore(toValidate, this.maxEndsAtDate);
  }

  protected get currentAbsenceReason() {
    return this.reasons.find(({ id }) => id === parseInt(this.formState.reasonId, 10));
  }

  protected get carryoverDeadline() {
    if (
      !this.currentAbsenceReason?.carryOverDaysEnabled
      || !this.currentAbsenceReason.carryOverDaysDeadline
    ) {
      return undefined;
    }

    const [day, month] = this.currentAbsenceReason.carryOverDaysDeadline
      .split('.')
      .map(value => parseInt(value, 10));
    const deadlineDate = startOf(this.formState.startsAt, Unit.YEAR, this.$timeZone.value);

    deadlineDate.setDate(day);
    deadlineDate.setMonth(month - 1);

    return deadlineDate;
  }

  protected get isEntirelyBeforeOrAfterDeadline() {
    return (
      this.carryoverDeadline === undefined
      || (
        isBefore(this.carryoverDeadline, this.formState.startsAt)
        === isBefore(this.carryoverDeadline, this.formState.endsAt)
      )
    );
  }

  protected get isStartsAtAndEndsAtYearDifferent() {
    return (
      this.formState.startsAt.getFullYear()
      !== this.formState.endsAt.getFullYear()
    );
  }

  protected get isTimespanValid() {
    return (
      !this.isEndsAtBeforeStartsAt
      && !this.isEndsAtTooFarInFuture
      && this.isEntirelyBeforeOrAfterDeadline
      && !this.isStartsAtAndEndsAtYearDifferent
    );
  }

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    this.$emit('closeClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
  }

  protected onDeleteClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    this.$emit('deleteClick', createEventPayload<void, HTMLElement, UIEvent>(e, undefined));
  }

  protected onInput<T extends keyof FormState>(field: T, value: FormState[T], e?: SyntheticEvent) {
    this.$emit('input', createEventPayload(e as SyntheticEvent, { field, value }));
  }

  protected onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    this.$emit('submit', createEventPayload<CollisionOverrideOption>(e, {}));
  }

  protected queryAbsenceDuration() {
    if (!Number.isNaN(this.timeout)) {
      window.clearTimeout(this.timeout);
    }

    if (
      !this.formState.employmentId
        || !this.formState.endsAt
        || !this.formState.reasonId
        || !this.formState.startsAt
        || this.isEndsAtBeforeStartsAt
    ) {
      return;
    }

    const paramsAtStart = {
      employmentId: this.formState.employmentId,
      endsAt: this.formState.endsAt,
      reasonId: this.formState.reasonId,
      startsAt: this.formState.startsAt,
    };

    const timeout = window.setTimeout(async () => {
      const days = Number.parseFloat(this.formState.daysDuration);

      const result = await this.getAbsenceDuration({
        days: Number.isNaN(days) ? undefined : days,
        employmentId: Number.parseInt(this.formState.employmentId, 10),
        endsAt: this.formState.endsAt,
        reasonId: Number.parseInt(this.formState.reasonId, 10),
        startsAt: this.formState.startsAt,
      });

      if (timeout !== this.timeout) {
        return; // Stop processing if another timeout has already started
      }

      this.timeout = Number.NaN;

      const paramsAtFinish = {
        employmentId: this.formState.employmentId,
        endsAt: this.formState.endsAt,
        reasonId: this.formState.reasonId,
        startsAt: this.formState.startsAt,
      };

      // params have changed since the request was started (slow request), ignore response
      if (!isEqual(paramsAtStart, paramsAtFinish)) {
        return;
      }

      this.hasGetAbsenceDurationFailed = result === null;

      if (result) {
        this.onInput('daysDuration', result.days.toString());
        this.onInput('hoursDurationNumber', result.hours || Number.NaN);
      } else {
        this.onInput('daysDuration', this.estimatedDuration.toString());
      }
    }, DEBOUNCE_TIMEOUT);

    this.timeout = timeout;
  }

  public mounted() {
    this.maxEndsAtDate = endOf(
      addYears(new Date(), MAXIMUM_ALLOWED_YEARS_IN_FUTURE),
      Unit.DAY,
      this.$timeZone.value,
    );
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        error={this.hasError}
        isLoading={this.isLoading}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        title={
          this.isUpdate
            ? this.$t('absence.modal.titleUpdate')
            : this.$t('absence.modal.titleCreate')
        }
      >
        <FormDialog id={FORM_ID} onSubmit={this.onSubmit}>
          {
            this.isManager && (
              <InputSelect
                isDisabled={this.isUpdate}
                label={this.$t('absence.modal.labelEmployee')}
                name="employmentId"
                onChange={(e) => {
                  this.onInput('employmentId', e.payload.toString(), e.event);
                  this.queryAbsenceDuration();
                }}
                options={this.employments.map(employment => ({
                  value: employment.id,
                  label: `${employment.firstName} ${employment.lastName}`,
                }))}
                placeholder={this.$t('absence.modal.placeholderEmployee')}
                required={true}
                value={this.formState.employmentId}
              />
            )
          }

          <InputSelect
            label={this.$t('absence.modal.labelReason')}
            name="reasonId"
            onChange={(e) => {
              this.onInput('reasonId', e.payload.toString(), e.event);
              this.queryAbsenceDuration();
            }}
            options={this.reasons.map(reason => ({
              isSelected: false,
              label: reason.hasLocalization
                ? this.$t(`absence.reason.${reason.name}`)
                : reason.name,
              value: reason.id,
            }))}
            placeholder={this.$t('absence.modal.placeholderReason')}
            required={true}
            value={this.formState.reasonId}
          />

          {
            this.formState.existingAttachment && (
              <a
                href={this.formState.existingAttachment.url}
                class={styles.dialogAbsenceAttachmentLink}
              >
                <Attachment
                  isPreviewShown={true}
                  name={this.formState.existingAttachment.name}
                />
              </a>
            )
          }

          {
            this.isAttachmentUploadShown && (
              <AttachmentUpload
                file={this.formState.attachment}
                onChange={
                  ({ event, payload: { value } }) => this.onInput('attachment', value, event)
                }
              />
            )
          }

          {
            this.isManager && (
              <InputSelect
                label={this.$t('absence.modal.labelState')}
                name="state"
                onChange={e => this.onInput('state', e.payload, e.event)}
                options={this.states.map(state => ({
                  label: this.$t(`absence.state.${state}`),
                  value: state,
                }))}
                required={true}
                value={this.formState.state}
              />
            )
          }

          <InputToggle
            checked={this.formState.isPaid}
            class={styles.dialogAbsenceInputToggle}
            id="toggle-is-paid"
            label={this.$t('absence.modal.labelPaid')}
            name="isPaid"
            onChange={e => this.onInput('isPaid', e.target.checked, e)}
            value="isPaid"
          />

          <InputToggle
            checked={this.formState.isFullDay}
            class={styles.dialogAbsenceInputToggle}
            id="toggle-is-full-day"
            label={this.$t('absence.modal.labelFullDay')}
            name="isFullDay"
            onChange={e => this.onInput('isFullDay', e.target.checked, e)}
            value="isFullDay"
          />

          <InputDateTime
            class={styles.dialogAbsenceSideBySide}
            kind={this.formState.isFullDay ? Kind.DATE : Kind.DATETIME}
            datepickerLabel={this.$t('absence.modal.labelStartDate')}
            name="startsAt"
            onInput={({ event, payload: { value } }) => {
              this.onInput('startsAt', value, event);
              this.onInput('daysDuration', '', event);
              this.queryAbsenceDuration();
            }}
            required={true}
            value={this.formState.startsAt}
            isValid={this.isTimespanValid}
            timeZone={this.$timeZone.value}
          />

          <InputDateTime
            class={styles.dialogAbsenceSideBySide}
            kind={this.formState.isFullDay ? Kind.DATE : Kind.DATETIME}
            datepickerLabel={this.$t('absence.modal.labelEndDate')}
            name="endsAt"
            onInput={({ event, payload: { value } }) => {
              this.onInput('endsAt', value, event);
              this.onInput('daysDuration', '', event);
              this.queryAbsenceDuration();
            }}
            required={true}
            value={this.formState.endsAt}
            isValid={(this.isTimespanValid)}
            timeZone={this.$timeZone.value}
          />

          {!this.isTimespanValid && (
            <ul class={styles.dialogAbsenceErrorList}>
              {this.isEndsAtBeforeStartsAt && (
                <li>{this.$t('absence.modal.error.endsAtBeforeStartsAt')}</li>
              )}

              {this.isEndsAtTooFarInFuture && (
                <li domPropsInnerHTML={
                  this.$t(
                    'absence.modal.error.endsAtTooFarInFuture',
                    {
                      max: toLocaleDateString(
                        this.maxEndsAtDate,
                        this.$i18n.i18next.language,
                        this.$timeZone.value,
                      ),
                    },
                  )
                } />
              )}

              {this.isStartsAtAndEndsAtYearDifferent && (
                <li>{this.$t('absence.modal.error.carryOverMultipleYears')}</li>
              )}

              {!this.isEntirelyBeforeOrAfterDeadline && (
                <li domPropsInnerHTML={
                  this.$t(
                    'absence.modal.error.carryOverDeadlineError',
                    {
                      deadline: this.carryoverDeadline && toLocaleDateString(
                        this.carryoverDeadline,
                        this.$i18n.i18next.language,
                        this.$timeZone.value,
                      ),
                    },
                  )
                } />
              )}
            </ul>
          )}

          <Section kind={SectionKind.TWO_COLUMN}>
            <InputText
              id="days"
              label={this.$t('absence.modal.labelDays')}
              min="0"
              name="daysDuration"
              onInput={(e) => {
                this.onInput('daysDuration', e.target.value, e);
                this.queryAbsenceDuration();
              }}
              placeholder="0"
              required={true}
              step="0.5"
              type="number"
              value={this.formState.daysDuration}
            />

            <InputText
              disabled={true}
              id="hours"
              label={this.$t('absence.modal.labelHours')}
              type="number"
              value={this.formState.hoursDuration}
            />
          </Section>

          {
            this.hasGetAbsenceDurationFailed && (
              <ul class={styles.dialogAbsenceErrorList}>
                <li>{this.$t('absence.modal.error.getDurationFailed')}</li>
              </ul>
            )
          }

          <InputTextArea
            label={this.$t('absence.modal.labelNote')}
            name="notes"
            onChange={e => this.onInput('notes', e.target.value, e)}
            placeholder={this.$t('absence.modal.placeholderNote')}
            value={this.formState.notes}
          />
        </FormDialog>

        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isSubmitting}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_LEFT}
          kind={ButtonKind.STROKE}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        {
          !this.hasError && this.isUpdate && !this.isLoading && (
            <Button
              color={ButtonColor.ERROR}
              disabled={this.isSubmitting || this.isLoading}
              onClick={this.onDeleteClick}
              size={Size.SMALL}
              slot={Slot.BUTTONS_LEFT}
              type="button"
              kind={ButtonKind.GHOST}
            >
              {this.$t('absence.modal.buttonDelete')}
            </Button>
          )
        }

        {
          !this.hasError && (
            <Button
              disabled={this.isSubmitting || this.isLoading || !this.isTimespanValid}
              form={FORM_ID}
              size={Size.SMALL}
              slot={Slot.BUTTONS_RIGHT}
              type="submit"
            >
              {
                this.isUpdate
                  ? this.$t('absence.modal.buttonUpdate')
                  : this.$t('absence.modal.buttonCreate')
              }
            </Button>
          )
        }
      </DialogWithSpinnerAndError>
    );
  }
}
