import DialogAssignmentGroupContainer from 'components/dialog-assignment-group/DialogAssignmentGroupContainer';
import DialogDayNoteContainer from 'components/dialog-day-note/DialogDayNoteContainer';
import { TFunction } from 'i18next';
import { RouteConfig, Route as RouteType } from 'vue-router';
import DialogShiftplanNotificationsRoutes from 'components/dialog-shiftplan-notifications/routes';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import DialogTagContainer from 'components/dialog-tag/DialogTagContainer';
import DialogShiftplanPublishContainer from 'components/dialog-shiftplan-publish/DialogShiftplanPublishContainer';
import DialogShiftplanImportContainer from 'components/dialog-shiftplan-import/DialogShiftplanImportContainer';
import AbsencePlanningContainer from './AbsencePlanningContainer';
import DialogApplyRotation from './dialog-apply-rotation/DialogApplyRotation';
import DialogCalendarExport from './dialog-calendar-export/DialogCalendarExport';
import ShiftScheduleContainer from './ShiftScheduleContainer';
import PrintShiftScheduleContainer from './PrintShiftScheduleContainer';
import DialogShiftRoutes from '../dialog-shift/routes';
import CalendarDialogAbsence from './calendar-dialog-absence/CalendarDialogAbsence';
import DialogShiftplanContainer from '../dialog-shiftplan/DialogShiftplanContainer';
import DialogAddShiftplanContainer from './dialog-create-shiftplan/DialogAddShiftplanContainer';

export enum Route {
  ROOT = 'calendarIndex',
  ABSENCE = 'calendarAbsence',
  ABSENCES_ABSENCE = 'calendarAbsencesAbsence',
  ABSENCES_CREATE_ABSENCE = 'calendarAbsencesAbsenceCreate',
  APPLY_ROTATION = 'applyRotation',
  ASSIGNMENT_GROUP = 'calendarAssignmentGroup',
  CREATE_SHIFTPLAN = 'calendarCreateShiftplan',
  EDIT_SHIFTPLAN = 'calendarEditShiftplan',
  DAY_NOTE = 'calendarDayNote',
  EXPORT_CALENDAR = 'exportCalendar',
  MESSAGE_EMPLOYEE = 'calendarMessageEmployee',
  PRINT_SHIFTPLAN = 'calendarPrintShiftplan',
  ROOT_ABSENCES = 'calendarAbsencesIndex',
  SHIFTPLAN = 'calendarShiftplan',
  TAG = 'calendarTag',
  PUBLISH = 'calendarPublishShiftplan',
  IMPORT_SHIFT_SCHEDULE = 'importShiftSchedule'
}

export const ROOT_ROUTE: RouteConfig = {
  component: ShiftScheduleContainer,
  name: Route.ROOT,
  path: '/calendar',
  meta: {
    title: (t: TFunction) => t('shiftSchedule.pageTitle'),
  },
};

const routes: RouteConfig[] = [
  ROOT_ROUTE,
  {
    component: ShiftScheduleContainer,
    name: Route.SHIFTPLAN,
    path: '/calendar/shiftplans/:shiftplanId(\\d+)',
    meta: {
      title: (t: TFunction) => t('shiftSchedule.pageTitle'),
    },
    props: (route: RouteType) => ({
      shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
    }),
    children: [{
      component: CalendarDialogAbsence,
      name: Route.ABSENCE,
      path: 'absences/:id(\\d+)',
      props: (route: RouteType) => ({
        absenceId: Number.parseInt(route.params.id, 10),
        namespace: CalendarNamespace.CALENDAR,
      }),
    }, {
      component: DialogShiftplanPublishContainer,
      name: Route.PUBLISH,
      path: 'publish',
      props: (route: RouteType) => ({
        shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
      }),
    }, {
      component: DialogDayNoteContainer,
      name: Route.DAY_NOTE,
      path: 'notes/:id(\\d+)?',
      props: (route: RouteType) => ({
        dayNoteId: Number.parseInt(route.params.id, 10),
        shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
      }),
    }, {
      component: DialogApplyRotation,
      name: Route.APPLY_ROTATION,
      path: 'apply-rotation',
      props: (route: RouteType) => ({
        shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
      }),
    }, {
      component: DialogCalendarExport,
      name: Route.EXPORT_CALENDAR,
      path: 'export',
      props: (route: RouteType) => ({
        shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
      }),
    }, {
      component: DialogAssignmentGroupContainer,
      name: Route.ASSIGNMENT_GROUP,
      path: 'assignment-groups/:assignmentGroupId(\\d+)?',
      props: (route: RouteType) => ({
        shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        assignmentGroupId: route.params.assignmentGroupId
          && Number.parseInt(route.params.assignmentGroupId, 10),
      }),
    }, {
      component: DialogAddShiftplanContainer,
      name: Route.CREATE_SHIFTPLAN,
      path: 'new',
    }, {
      component: DialogShiftplanContainer,
      name: Route.EDIT_SHIFTPLAN,
      path: 'edit',
      props: (route: RouteType) => ({
        shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
      }),
    }, {
      component: DialogTagContainer,
      name: Route.TAG,
      path: 'tags/:tagId(\\d+)?',
      props: (route: RouteType) => ({
        tagId: route.params.tagId && Number.parseInt(route.params.tagId, 10),
      }),
    }, {
      component: DialogShiftplanImportContainer,
      name: Route.IMPORT_SHIFT_SCHEDULE,
      path: 'import',
      props: (route: RouteType) => ({
        shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
      }),
    },
    ...DialogShiftRoutes,
    ...DialogShiftplanNotificationsRoutes,
    ],
  },
  {
    component: AbsencePlanningContainer,
    name: Route.ROOT_ABSENCES,
    path: '/calendar-absences',
    meta: {
      title: (t: TFunction) => t('shiftSchedule.absencesPlanning.pageTitle'),
    },
    children: [{
      component: CalendarDialogAbsence,
      name: Route.ABSENCES_ABSENCE,
      path: 'absences/:id(\\d+)',
      props: (route: RouteType) => ({
        namespace: CalendarNamespace.ABSENCES,
        absenceId: Number.parseInt(route.params.id, 10),
      }),
    },
    {
      component: CalendarDialogAbsence,
      name: Route.ABSENCES_CREATE_ABSENCE,
      path: 'absences/new',
      props: (route: RouteType) => ({
        namespace: CalendarNamespace.ABSENCES,
        initialState: route.params.initialState,
      }),
    }],
  },
  {
    component: PrintShiftScheduleContainer,
    name: Route.PRINT_SHIFTPLAN,
    path: '/calendar/:shiftplanId(\\d+)/print',
    meta: {
      title: (t: TFunction) => t('shiftSchedule.calendarPrint.pageTitle'),
    },
    props: (route: RouteType) => ({
      shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
    }),
  },
];

export default routes;
