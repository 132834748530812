import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import DateItem from '../../common/DateItem';
import GridCell from '../grid-cell/GridCell';
import GridRow, { GridRowScope } from '../grid-row/GridRow';

import gridStyles from '../grid-table.css';
import styles from './grid-header-days.css';

@Component
class GridHeaderDays extends Vue {
  @Prop({
    default: () => [],
  })
  private dates!: DateItem[];

  public render() {
    return (
      <div class={{
        [gridStyles.gridTableSubgrid]: true,
        [gridStyles.gridTableHeader]: true,
        [gridStyles.gridTableHeaderSticky]: true,
      }}>
        <GridRow dates={this.dates}
          isHeader={true}
          cellClass={gridStyles.gridTableHeaderCell}
          scopedSlots= {{
            cell: (scope: GridRowScope) => {
              const { date, isWithinShiftplan } = scope.dateItem;
              return (<button
                disabled={!isWithinShiftplan}
                class={styles.gridHeaderDaysDay}
                onClick={e => this.$emit('click', e, scope)}>
                <div>
                  {/* TODO: use localized date when moving from momentjs */}
                  {date.format('dd DD.MM.YY')}
                </div >
                {this.$scopedSlots.rowAction
            && this.$scopedSlots.rowAction(scope.dateItem)}
              </button>);
            },
          }}>
          <GridCell slot="label"
            isHeader={true}
            class={gridStyles.gridTableHeaderCell}>
            {this.$slots.label}
          </GridCell>
          <template slot="total">
            {this.$slots.total}
          </template>
        </GridRow>
        {this.$slots.default}
      </div>
    );
  }
}

export default GridHeaderDays as any;
