import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import BaseDialog, { Slot as BaseDialogSlot } from 'components/dialog/base-dialog/BaseDialog';
import { Size } from 'components/types';
import styles from './dialog.css';

export enum Slot {
  BUTTONS_LEFT = 'buttonsLeft',
  BUTTONS_RIGHT = 'buttonsRight',
}

export interface Props {
  id?: string;
  isOpen?: boolean;
  title?: string;
  isCloseButtonDisabled?: boolean;
}

interface Events {
  onCloseClick: (e: SyntheticEvent<HTMLElement
  | HTMLButtonElement, KeyboardEvent
  | MouseEvent | UIEvent>) => void;
}

@Component
export default class Dialog extends TsxComponent<Props, Events> {
  public $refs: {
    container: HTMLDivElement;
  };

  @Prop({ default: () => `dialog-${getRandomString()}` })
  public id: string;

  @Prop({ default: false })
  public isOpen: boolean;

  @Prop({ default: false })
  public isCloseButtonDisabled: boolean;

  @Prop()
  public title?: string;

  protected onCloseClick() {
    this.$emit('closeClick');
  }

  public render() {
    return (
      <BaseDialog
        dialogClasses={[styles.dialog]}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        isCloseButtonDisabled={this.isCloseButtonDisabled}
        title={this.title}
        dialogSpacingSize={Size.SMALL}
        showTitleBodySeparator={true}>
        <div id={`${this.id}-body`} class={styles.dialogBody}>

          {this.$slots.default}
        </div>
        {
          (this.$slots[Slot.BUTTONS_LEFT]?.length
              || this.$slots[Slot.BUTTONS_RIGHT]?.length) && (
            <footer class={styles.dialogFooter} slot={BaseDialogSlot.FOOTER}>
              {this.$slots[Slot.BUTTONS_LEFT]?.length && (
                <div class={styles.dialogFooterStart}>
                  {
                    this.$slots[Slot.BUTTONS_LEFT]
                  }
                </div>
              )}

              <div class={styles.dialogFooterEnd}>
                {
                  this.$slots[Slot.BUTTONS_RIGHT]
                }
              </div>
            </footer>
          )
        }
      </BaseDialog>

    );
  }
}
