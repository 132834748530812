/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import Accordion, { Slot as AccordionSlot } from 'components/accordion/Accordion';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'src/utils/events';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import styles from './shift-group-setup.css';
import type { RotationDayPreset, RotationGroupInput } from '../store/types';
import NameEditForm from './name-edit-form/NameEditForm';
import WeeksGrid from './weeks-grid/WeeksGrid';

interface Props {
  firstDay: Date;
  weeksLength: number;
  isInitiallyOpened?: boolean;
  groupIndex: number;
  groupsLength: number;
  group: RotationGroupInput;
}

interface Events {
  onPresetSelect: (
    e: SyntheticEvent<HTMLDivElement, MouseEvent>,
    dayIndex: number,
    preset: RotationDayPreset,
  ) => void;
  onGroupNameUpdate: (value: string) => void;
  onAccordionKeyDown: (e: SyntheticEvent<HTMLDivElement, KeyboardEvent>) => void;
  onMove: (e: EventPayload<{
    groupIndex: number;
    direction: number;
  }, HTMLButtonElement, MouseEvent>) => void;
  onDelete: (e: EventPayload<{
    groupIndex: number;
  }, HTMLButtonElement, MouseEvent>) => void;
}

@Component
class ShiftGroupSetup extends TSXComponent<Props, Events> {
  public $refs: {
    accordionRef: Accordion;
  };

  private isEditingName = false;

  private isOpen = false;

  @Prop()
  public firstDay: Props['firstDay'];

  @Prop()
  public weeksLength: Props['weeksLength'];

  @Prop()
  public isInitiallyOpened: Props['isInitiallyOpened'];

  @Prop()
  public group: Props['group'];

  @Prop()
  public groupIndex: Props['groupIndex'];

  @Prop()
  public groupsLength: Props['groupsLength'];

  private get isDeletePossible() {
    return this.group.id === undefined && this.groupsLength > 1;
  }

  private get isEditable() {
    return this.group.id === undefined;
  }

  @Emit('presetSelect')
  public onPresetSelect(
    e: SyntheticEvent<HTMLDivElement, MouseEvent>,
    dayIndex: number,
    preset: RotationDayPreset,
  ) {}

  @Emit('groupNameUpdate')
  public onGroupNameUpdate(value: string) {
    this.isEditingName = false;
    return value;
  }

  @Emit('accordionKeyDown')
  public onAccordionKeyDown() {}

  public mounted() {
    this.isOpen = !!this.isInitiallyOpened;
  }

  public render() {
    return (
      <Accordion
        isOpen={this.isOpen}
        label={this.group.name}
        onOpenCloseClick={() => { this.isOpen = !this.isOpen; }}
        onKeyDown={this.onAccordionKeyDown}
        ref="accordionRef"
      >
        {this.isEditingName && (
          <NameEditForm
            groupIndex={this.groupIndex}
            value={this.group.name}
            slot={AccordionSlot.LID_CONTENT_OVERLAY}
            onSave={this.onGroupNameUpdate}
            onCancel={() => {
              this.isEditingName = false;
            }}
          />
        )}
        <div class={styles.shiftGroupSetupButtonWrapper} slot={AccordionSlot.LID}>
          <Button
            kind={ButtonKind.GHOST}
            color={ButtonColor.PRIMARY}
            size={Size.XXSMALL}
            icon={IconName.EDIT}
            iconPosition={IconPosition.ALONE}
            onClick={() => { this.isEditingName = true; }}
          />
          <Button
            kind={ButtonKind.GHOST}
            color={ButtonColor.SECONDARY}
            size={Size.XXSMALL}
            icon={IconName.CHEVRON_DOWN}
            iconPosition={IconPosition.ALONE}
            onClick={e => this.$emit('move', createEventPayload(e, { direction: 1, groupIndex: this.groupIndex }))}
            disabled={this.groupIndex === this.groupsLength - 1}
          />
          <Button
            kind={ButtonKind.GHOST}
            color={ButtonColor.SECONDARY}
            size={Size.XXSMALL}
            icon={IconName.CHEVRON_UP}
            iconPosition={IconPosition.ALONE}
            onClick={e => this.$emit('move', createEventPayload(e, { direction: -1, groupIndex: this.groupIndex }))}
            disabled={this.groupIndex === 0}
          />
          {this.isDeletePossible && (
            <Button
              kind={ButtonKind.GHOST}
              color={ButtonColor.ERROR}
              size={Size.XXSMALL}
              icon={IconName.DELETE}
              iconPosition={IconPosition.ALONE}
              onClick={e => this.$emit('delete', createEventPayload(e, {
                groupIndex: this.groupIndex,
              })) }
            />
          )}
        </div>
        <WeeksGrid
          days={this.group.days}
          firstDay={this.firstDay}
          onPresetSelect={this.onPresetSelect}
          weeksLength={this.weeksLength}
          isDisabled={!this.isEditable}
        />
      </Accordion>
    );
  }
}

export default ShiftGroupSetup;
