import HeaderCell from 'components/table/header-cell/HeaderCell';
import type { SetSortPayload } from 'components/table/store/Store';
import { LoadingState } from 'components/table/store/Store';
import { SortDirection, SortOptions, TableColumns } from 'components/table/types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';
import { GQLAvailabilityAggregationsQuery } from 'codegen/gql-types';
import Table from 'components/table/Table';
import { AvailabilityAggregation } from '../types';
import CellActions from './cell-actions/CellActions';
import CellEmployment from './cell-employment/CellEmployment';
import styles from './table.css';
import CellContractHours from './cell-contract-hours/CellContractHours';

export interface Props {
  loadingState: LoadingState;
  data: GQLAvailabilityAggregationsQuery['availabilityAggregations']['items'];
  sort: SortOptions<AvailabilityAggregation>;
  pagination: {
    page: number;
    perPage: number;
    count: number;
  };
}

interface Events {
  onSetSort: (payload: SetSortPayload<AvailabilityAggregation>) => void;
  onSetPage: (page: number) => void;
}

@Component
class AvailabilityAggregationsTable extends TSXComponent<Props, Events> {
  @Prop()
  public loadingState: Props['loadingState'];

  @Prop()
  public data: Props['data'];

  @Prop()
  public pagination: Props['pagination'];

  @Prop()
  public sort: Props['sort'];

  private get fields() {
    const fields: TableColumns<AvailabilityAggregation> = [
      {
        rowIdKey: 'employment',
        label: this.$t('availabilityAggregations.table.headerEmployee'),
        isStretch: true,
        isSortable: true,
      },
      {
        rowIdKey: 'availableHours',
        label: this.$t('availabilityAggregations.table.headerAvailableHours'),
        width: '200px',
        isSortable: true,
      },
      {
        rowIdKey: 'contractHours',
        label: this.$t('availabilityAggregations.table.headerContractedHours'),
        isSortable: true,
        width: '200px',
      },
      {
        label: this.$t('availabilityAggregations.table.headerActions'),
        width: '200px',
      },
    ];

    return fields;
  }

  private onSetPage(page: number) {
    this.$emit('setPage', page);
  }

  private onSetSort(rowIdKey: string | undefined, direction: SortDirection) {
    if (!rowIdKey) {
      return;
    }

    this.$emit('setSort', {
      key: rowIdKey,
      direction,
    });
  }

  public render() {
    return (
      <Table
        class={styles.table}
        loadingState={this.loadingState}
        rows={this.data}
        rowIdKey="employmentId"
        pagination={this.pagination}
        fields={this.fields}
        onPageChange={({ payload }) => this.onSetPage(payload.page)}
        scopedSlots={{
          header: ({ fields }) => fields.map((field) => {
            const sort = field.isSortable
              ? {
                direction: field.rowIdKey === this.sort?.key
                  ? this.sort?.direction
                  : SortDirection.UNDEFINED,
              }
              : undefined;

            return (
              <HeaderCell
                filter={field.filter}
                isStretch={field.isStretch}
                sort={sort}
                style={{ width: field.width }}
                onSortDirectionChange={({ payload }) => (
                  this.onSetSort(field.rowIdKey, payload.direction)
                )}
              >
                {field.label}
              </HeaderCell>
            );
          }),
          row: ({ rowData }) => [
            <CellEmployment employment={rowData.employment} />,

            <td class={tableStyles.tableBodyCell}>
              {rowData.availableHours}
            </td>,

            <CellContractHours contractHours={rowData.contractHours} />,

            <CellActions availabilityAggregation={rowData} />,
          ],
        }}
      />
    );
  }
}

export default AvailabilityAggregationsTable;
