import SpIcon from 'components/sp-icon/SpIcon';
import DndKind from 'components/calendar-common/common/dnd/DndKind';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { SlotDisplayStyle } from 'components/calendar-common/Enums';
import StaffShift from 'components/calendar-common/shifts/StaffShift';
import Employment from 'components/calendar-common/employments/Employment';
import type { DndProperties } from 'components/calendar-common/common/dnd/types';
import { dragAndDropNS } from 'src/store/drag-and-drop/store';
import type { SetItemAction } from 'src/store/drag-and-drop/store';
import Action from 'src/store/drag-and-drop/Action';
import { DraggableEmploymentPayload, DraggableStaffShiftPayload } from 'src/store/drag-and-drop/types';
import styles from './employee.css';

interface TEmployeeStyle {
  zIndex: number;
  left: string;
  position: string;
}

export interface StaffShiftDraggableData {
  id: number;
  parentEventId: number;
  staffShiftId: number;
}

export interface EmploymentDraggableData {
  id: number;
}

export type EmploymentDraggable = DndProperties<DndKind.EMPLOYMENT, EmploymentDraggableData>;
export type StaffShiftDraggable = DndProperties<DndKind.STAFF_SHIFT, StaffShiftDraggableData>;

export const EMPLOYEE_SIZE = {
  SHIFT: 0.60,
  ICON: 1.5,
};

@Component
class Employee extends Vue {
  @Prop({ default: () => ({ id: null }) })
  public employee!: StaffShift | Employment;

  @Prop({ default: 0 })
  public index!: number;

  @Prop({ default: false })
  public isDraggable!: boolean;

  @Prop({ default: false })
  public isHidden!: boolean;

  @Prop({ default: false })
  public isOpen!: boolean;

  @Prop({ default: false })
  public isExtra!: boolean;

  @Prop({ default: false })
  public isLabel!: boolean;

  @Prop({ default: 0 })
  public value!: number;

  @Prop({ default: undefined })
  public eventId: number;

  @Prop({ default: SlotDisplayStyle.FULLNAMES })
  public slotDisplayStyle: SlotDisplayStyle;

  @dragAndDropNS.Action(Action.SET_ITEM)
  protected setDraggableItem: SetItemAction;

  @dragAndDropNS.Action(Action.CLEAR_ITEM)
  protected clearDndItem;

  private get employeeStyle(): TEmployeeStyle {
    return {
      zIndex: this.index + 1,
      // for extra element we want a full element shift
      left: this.isExtra
        ? `${(this.index - 1) * EMPLOYEE_SIZE.SHIFT * EMPLOYEE_SIZE.ICON + EMPLOYEE_SIZE.ICON}rem`
        : `${this.index * EMPLOYEE_SIZE.SHIFT * EMPLOYEE_SIZE.ICON}rem`,
      position: 'absolute',
    };
  }

  private get src(): string {
    return this.employee.image || '';
  }

  private get name(): string {
    return this.employee.name;
  }

  private get classes() {
    return {
      [styles.employee]: true,
      [styles.employeeFullnames]: this.showFullnames,
    };
  }

  private get isShiftViewModeDefault(): boolean {
    return !this.isLabel
      && !!this.slotDisplayStyle
      && (this.slotDisplayStyle === SlotDisplayStyle.DEFAULT);
  }

  private get showFullnames() {
    return (this.slotDisplayStyle === SlotDisplayStyle.FULLNAMES) || this.isLabel;
  }

  private isStaffShift(entity: Employment | StaffShift): entity is StaffShift {
    return 'staffShiftId' in entity;
  }

  private onDragStart() {
    if (!this.isDraggable) {
      return;
    }

    const payload: DraggableEmploymentPayload
    | DraggableStaffShiftPayload = this.isStaffShift(this.employee)
      ? {
        kind: DndKind.STAFF_SHIFT,
        id: this.employee.staffShiftId,
        shiftId: this.eventId,
        employmentId: this.employee.id,
        activeLocationsPositionIds: this.employee.activeLocationsPositionIds,
        isDeleted: this.employee.isDeleted(),
      }
      : {
        kind: DndKind.EMPLOYMENT,
        id: this.employee.id,
        activeLocationsPositionIds: this.employee.activeLocationsPositionIds,
        isDeleted: this.employee.isDeleted(),
      };

    this.setDraggableItem(payload);
  }

  private onDragEnd() {
    this.clearDndItem();
  }

  private renderEmployeeContents() {
    if (this.isOpen) {
      return [(<div class={this.classes}>
        <SpIcon name="person"/>
      </div>),
      <span class={styles.employeeName}>
        {this.$t('calendar.employmentSlotEmpty')}
      </span>];
    }
    if (this.isHidden) {
      return [(<div class={this.classes}>
        <span>?</span>
      </div>),
      <span class={styles.employeeName}>
        {this.$t('calendar.employmentSlotHidden')}
      </span>];
    }

    if (this.isExtra) {
      return ([<div class={styles.employeeExtra}>
        <span>+{this.value}</span>
      </div>,
      <span class={styles.employeeName}>
        {this.$t('calendar.showAllEmployments')}
      </span>]);
    }

    return [<img class={this.classes}
      draggable="false"
      data-tooltip={true}
      title={this.name}
      src={this.src}
      alt={this.name} />,
    <span class={styles.employeeName}>
      {this.name}
    </span>];
  }

  public render() {
    return (<div class={{
      [styles.employeeContainer]: true,
      [styles.employeeContainerDraggable]: this.isDraggable,
      [styles.employeeContainerFullnames]: this.showFullnames,
      [styles.employeeContainerHasHover]: this.employee.id !== null,
    }}
    style={this.isShiftViewModeDefault ? this.employeeStyle : {}}
    draggable={this.isDraggable}
    onDragstart={this.onDragStart}
    onDragend={this.onDragEnd}>
      {this.renderEmployeeContents()}
    </div>);
  }
}

export default Employee as any;
