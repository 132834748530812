import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import tableStyles from 'components/table/table.css';
import { getDurationStringInTimeZone } from 'src/utils/date-related';

@Component
export default class CellTimeframe extends TsxComponent<{
  endsAt: Date;
  startsAt: Date;
}> {
  @Prop()
  public endsAt: Date;

  @Prop()
  public startsAt: Date;

  protected get timeframe() {
    return getDurationStringInTimeZone(
      this.$timeZone.value,
      this.$i18n.i18next.language,
      this.startsAt,
      this.endsAt,
    );
  }

  public render() {
    return (
      <td class={tableStyles.tableBodyCell} title={this.timeframe}>
        {this.timeframe}
      </td>
    );
  }
}
