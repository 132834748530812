import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { Tag } from 'src/store/tags/Store';
import InputPillSelect from 'components/form/input-pill-select/InputPillSelect';
import { EventPayload } from 'src/utils/events';
import FormSection from 'components/dialog-shift/form-section/FormSection';

import styles from './section-tags.css';

export interface Props {
  selectedTagIds: number[];
  isDisabled?: boolean;
  availableTags: Tag[];
}

interface Events {
  onChange: (
    eventPayload: (
      EventPayload<string[], HTMLButtonElement | HTMLInputElement, MouseEvent | InputEvent>
    )
  ) => void;
}

@Component
class SectionTags extends TSXComponent<Props, Events> {
  @Prop()
  public selectedTagIds: Props['selectedTagIds'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public availableTags: Props['availableTags'];

  private get options() {
    return this.availableTags.map(
      ({ id, name, color }) => ({ value: id.toString(), label: name, color }),
    );
  }

  private get placeholder() {
    return this.$t(
      this.isDisabled
        ? 'shifts.dialog.placeholderNoTags'
        : 'shifts.dialog.placeholderAddTags',
    );
  }

  protected onChange(
    eventPayload: (
      EventPayload<string[], HTMLButtonElement | HTMLInputElement, MouseEvent | InputEvent>),
  ) {
    this.$emit('change', eventPayload);
  }

  public render() {
    return (
      <FormSection class={styles.sectionTagsRow} heading={this.$t('shifts.dialog.headingTags')}>
        <InputPillSelect
          label={this.$t('shifts.dialog.labelTags')}
          isDisabled={this.isDisabled}
          class={styles.sectionTagsRowFullWidth}
          placeholder={this.placeholder}
          options={this.options}
          value={this.selectedTagIds.map(id => id.toString())}
          onChange={this.onChange}
        />
      </FormSection>
    );
  }
}

export default SectionTags;
