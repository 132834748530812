import Vue from 'vue';

function onUpdate(el, binding) {
  const { dataset } = el;
  const { value: values } = binding;
  if (values instanceof Object) {
    Object.keys(values).forEach((key) => {
      dataset[key] = values[key];
    });
  }
}

export default Vue.directive('data-attrs', {
  bind: onUpdate,
  update: onUpdate,
});
