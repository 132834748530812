import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateLocationsPositionMutation,
  GQLCreateLocationsPositionMutationVariables,
  GQLLocationsPositionCreateInput,
  GQLLocationsPositionFragmentFragment,
  GQLLocationsPositionsQuery,
  GQLLocationsPositionsQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { StoreActionResult, PayloadParameter } from 'utils/store';
import CreateLocationsPositionGql from './queries/CreateLocationsPosition.gql';
import LocationsPositionGql from './queries/LocationsPositions.gql';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from '../normalized-store';

export const locationsPositionsNS = namespace('locationsPositions');

export type StoreState = ById<LocationsPosition>;

export type LocationsPosition = GQLLocationsPositionFragmentFragment;

export type CreateLocationsPositionFunction = (
  payload: { locationsPosition: Omit<GQLLocationsPositionCreateInput, 'companyId'> },
) => Promise<StoreActionResult>;

export type FetchAllLocationsPositionsFunction = (
  payload: Omit<GQLLocationsPositionsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getLocationsPositionsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = ({
    namespaced: true,
    getters: {
      getByLocationAndPositionId: (state: StoreState) => (
        locationId?: number,
        positionId?: number,
      ) => (
        Object.values(state.byId).filter(
          item => (
            locationId ? item.location?.id === locationId : true
          ) && (
            positionId ? item.position?.id === positionId : true
          ),
        )
      ),
    },
  });

  const create: ActionProvider<
  GQLCreateLocationsPositionMutation,
  GQLCreateLocationsPositionMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateLocationsPositionFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: CreateLocationsPositionGql,
      resultKey: 'createLocationsPosition',
      variables: {
        locationsPosition: {
          ...payload.locationsPosition,
        },
      },
      useBatching: true,
    });
  };

  const fetchAll: ActionProvider<
  GQLLocationsPositionsQuery,
  GQLLocationsPositionsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllLocationsPositionsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return ({
      query: LocationsPositionGql,
      resultKey: 'locationsPositions',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    });
  };

  return createNormalizedStore<LocationsPosition, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getLocationsPositionsStore;
