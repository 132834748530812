import { StoreState as FilterStoreState, createFilterStore } from 'store/filter-store';
import { namespace } from 'vuex-class';

export const shiftRotationGroupsFilterNS = namespace('shiftSchedule/shiftRotationGroupsFilter');

export type StoreState = FilterStoreState;

const getStore = () => createFilterStore({ namespaced: true });

export default getStore;
