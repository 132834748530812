enum DndKind {
  SHIFT = 'shift',
  EMPLOYMENT = 'employment',
  STAFF_SHIFT = 'staff_shift',
  TAG = 'tag',
  ASSIGNMENT_GROUP = 'assignment_group',
  CELL = 'cell',
  DELETE_BOX = 'delete_box',
}

export default DndKind;
