import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { difference, differenceWith } from 'lodash';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import { evaluationsNS } from 'store/evaluations/Store';
import type { CreateEvaluationFunction, GQLEvaluation } from 'store/evaluations/Store';
import { Action } from 'store/normalized-store';
import { PaygradeType, paygradeTypesNS } from 'store/paygrade-types/Store';
import { staffShiftsTagsNS } from 'store/staff-shifts-tags/Store';
import type { CreateStaffShiftsTagFunction, DeleteStaffShiftsTagFunction } from 'store/staff-shifts-tags/Store';
import { Tag } from 'store/tags/Store';
import { isStartBeforeEnd } from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import { executeStoreActionWithFailureSnackbar, StoreActionResult } from 'utils/store';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Form, { FormState, Slot } from './Form';
import SectionBreaksAction from '../section-breaks/store/Action';
import { sectionBreaksNS } from '../section-breaks/store/Store';
import type { Break, IsValidFunction } from '../section-breaks/store/Store';
import SectionPayAction from '../section-pay/store/Action';
import { sectionPayNS } from '../section-pay/store/Store';
import { EvaluationContext } from '../types';
import type { Evaluation } from '../types';
import SectionBreaksContainerCompany from '../section-breaks/SectionBreaksContainerCompany';
import SectionPayContainerCompany from '../section-pay/SectionPayContainerCompany';

@Component
export default class FormContainerCompany extends TsxComponent<{
  evaluation: Evaluation;
  isBringShiftsEnabled?: boolean;
  isDisabled?: boolean;
  staffShiftsTags: Tag[];
}, {
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] } >,
  ) => void;
  onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
}> {
  protected formState: FormState = {} as FormState;

  protected isSubmitting = false;

  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @evaluationsNS.Action(Action.CREATE)
  protected createEvaluation: CreateEvaluationFunction;

  @evaluationsNS.Getter
  protected hasEvaluationPayShowRight: (evaluation: GQLEvaluation) => boolean;

  @evaluationsNS.Getter
  protected hasEvaluationPayManageRight: (evaluation: GQLEvaluation) => boolean;

  @paygradeTypesNS.Getter('items')
  protected paygradeTypes: PaygradeType[];

  @sectionPayNS.Action(SectionPayAction.DISPATCH_REMOVE)
  protected dispatchRemovePayments: (evaluationId: number) => Promise<StoreActionResult>;

  @sectionPayNS.Action(SectionPayAction.DISPATCH_UPDATE)
  protected dispatchUpdatePayments: (evaluationId: number) => Promise<StoreActionResult>;

  @sectionPayNS.Action(SectionPayAction.DISPATCH_CREATE)
  protected dispatchCreatePayments: (evaluationId: number) => Promise<StoreActionResult>;

  @sectionBreaksNS.Action(SectionBreaksAction.DISPATCH_REMOVE)
  protected dispatchRemoveBreaks: (evaluationId: number) => Promise<StoreActionResult>;

  @sectionBreaksNS.Action(SectionBreaksAction.DISPATCH_UPDATE)
  protected dispatchUpdateBreaks: (evaluationId: number) => Promise<StoreActionResult>;

  @sectionBreaksNS.Action(SectionBreaksAction.DISPATCH_CREATE)
  protected dispatchCreateBreaks: (evaluationId: number) => Promise<StoreActionResult>;

  @sectionBreaksNS.Action(SectionBreaksAction.SET_BREAKS)
  protected setBreaks: (breaks: Break[]) => void;

  @sectionBreaksNS.Getter('isValid')
  protected isBreaksValid: IsValidFunction;

  @sectionBreaksNS.State('breaks')
  protected breaks: Break[];

  @staffShiftsTagsNS.Action(Action.CREATE)
  protected createStaffShiftsTag: CreateStaffShiftsTagFunction;

  @staffShiftsTagsNS.Action(Action.DELETE)
  protected deleteStaffShiftsTag: DeleteStaffShiftsTagFunction;

  @Prop()
  public evaluation: Evaluation;

  @Prop()
  public isBringShiftsEnabled?: boolean;

  @Prop()
  public isDisabled?: boolean;

  @Prop()
  public staffShiftsTags: Tag[];

  @Watch('evaluation', { immediate: true })
  protected onEvaluationChange() {
    this.formState = {
      startsAt: new Date(this.evaluation.startsAt),
      endsAt: new Date(this.evaluation.endsAt),
      note: this.evaluation.note,
      unpaidBreak: this.evaluation.untimedBreak ?? this.evaluation.break,
      tags: this.evaluation.staffShiftsTags.map(({ tag }) => tag.id.toString()),
    };
  }

  protected onInput<T extends keyof FormState>(
    { payload: { field, value } }: EventPayload<{ field: T; value: FormState[T] } >,
  ) {
    this.formState[field] = value;
  }

  protected async updatePayments() {
    await executeStoreActionWithFailureSnackbar(
      this,
      this.evaluation.staffShiftId,
      this.dispatchRemovePayments,
      '',
    );
    await executeStoreActionWithFailureSnackbar(
      this,
      this.evaluation.staffShiftId,
      this.dispatchUpdatePayments,
      '',
    );
    await executeStoreActionWithFailureSnackbar(
      this,
      this.evaluation.staffShiftId,
      this.dispatchCreatePayments,
      '',
    );
  }

  protected async updateBreaks() {
    await executeStoreActionWithFailureSnackbar(
      this,
      this.evaluation.staffShiftId,
      this.dispatchRemoveBreaks,
      '',
    );
    await executeStoreActionWithFailureSnackbar(
      this,
      this.evaluation.staffShiftId,
      this.dispatchUpdateBreaks,
      '',
    );
    await executeStoreActionWithFailureSnackbar(
      this,
      this.evaluation.staffShiftId,
      this.dispatchCreateBreaks,
      '',
    );
  }

  protected async updateStaffShiftTags(updatedTagIds: string[]) {
    const tagsUpdated = updatedTagIds.map(id => Number.parseInt(id, 10));
    const tagsExisting = this.evaluation.staffShiftsTags.map(o => o.tag.id);

    const toRemove = differenceWith(
      this.evaluation.staffShiftsTags,
      tagsUpdated,
      (x, y) => x.tag.id === y,
    );
    const toCreate = difference(tagsUpdated, tagsExisting);

    await Promise.all([
      ...toRemove.map(staffShiftTag => executeStoreActionWithFailureSnackbar(
        this,
        {
          id: staffShiftTag.id,
          staffShiftId: this.evaluation.staffShiftId,
          tagId: staffShiftTag.tag.id,
        },
        this.deleteStaffShiftsTag,
        '',
      )),
      ...toCreate.map(tagId => executeStoreActionWithFailureSnackbar(
        this,
        { staffShiftId: this.evaluation.staffShiftId, tagId },
        this.createStaffShiftsTag,
        '',
      )),
    ]);
  }

  protected async onSubmit({ event }: EventPayload<void>) {
    event.preventDefault();

    this.isSubmitting = true;

    const { tags, unpaidBreak, ...evaluation } = this.formState;

    await executeStoreActionWithFailureSnackbar(
      this,
      {
        staffShiftId: this.evaluation.staffShiftId,
        evaluation: deepTransformDates({ ...evaluation, break: null, untimedBreak: unpaidBreak }),
      },
      this.createEvaluation,
      '',
    );

    await Promise.all([
      this.updatePayments(),
      this.updateBreaks(),
      this.updateStaffShiftTags(tags),
    ]);

    this.isSubmitting = false;

    this.$emit('submit', createEventPayload<void>(event, undefined));
  }

  protected get isSelfEvaluation() {
    return this.evaluation.employment.id === this.currentEmploymentId;
  }

  protected get isSectionPayShown() {
    return !!this.paygradeTypes.length && (this.hasEvaluationPayManageRight(this.evaluation) || (
      (this.hasEvaluationPayShowRight(this.evaluation) || this.isSelfEvaluation)
        && !!this.evaluation.payments.length
    ));
  }

  protected get isValid() {
    return this.formState.unpaidBreak !== undefined
      && !Number.isNaN(this.formState.unpaidBreak)
      && isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt, true)
      && this.isBreaksValid(this.formState.startsAt, this.formState.endsAt, this.$timeZone.value);
  }

  public render() {
    return (
      <Form
        context={EvaluationContext.COMPANY}
        evaluation={this.evaluation}
        formState={this.formState}
        isBringShiftsEnabled={this.isBringShiftsEnabled}
        isDisabled={this.isDisabled}
        isSubmitting={this.isSubmitting}
        isValid={this.isValid}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
        tags={this.staffShiftsTags}
      >
        <SectionBreaksContainerCompany
          evaluationBreaks={this.evaluation.breaks}
          evaluationEndsAt={this.formState.endsAt}
          evaluationStartsAt={this.formState.startsAt}
          isDisabled={this.isDisabled || this.isSubmitting}
          onUnpaidBreakChange={
            ({ event, payload: { value } }) => this.onInput({
              event,
              payload: { field: 'unpaidBreak', value },
            })
          }
          slot={Slot.SECTION_BREAKS}
          unpaidBreak={this.formState.unpaidBreak}
        />

        {
          this.isSectionPayShown && (
            <SectionPayContainerCompany
              breaks={this.breaks}
              evaluation={this.evaluation}
              formState={this.formState}
              isDisabled={
                this.isDisabled
                  || this.isSubmitting
                  || !this.hasEvaluationPayManageRight(this.evaluation)
              }
              slot={Slot.SECTION_PAY}
            />
          )
        }
      </Form>
    );
  }
}
