import { GQLEmploymentStatus } from 'codegen/gql-types';
import { FigureItem } from 'components/figures-summary/figure/Figure';
import FiguresSummary from 'components/figures-summary/FiguresSummary';
import { groupSumReducer } from 'src/utils/utils';
import { Component, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { absencesTableNS } from '../store/Store';
import type { Filters } from '../store/Store';
import type { AbsenceReason } from '../types';
import { absencesSummaryNS, StoreState } from './store/Store';
import Action from './store/Action';

import styles from './summary.css';

@Component
export default class Summary extends TsxComponent<{}> {
  protected isLoading = true;

  protected items: FigureItem[] = [];

  @absencesSummaryNS.Getter
  protected filteredAbsenceReasons: AbsenceReason[];

  @absencesTableNS.State
  private filters: Filters;

  @absencesSummaryNS.Action(Action.FETCH_SUMMARY_DATA)
  private fetchSummaryData: (payload: {
    employmentId: number;
    employmentStatuses?: GQLEmploymentStatus[];
    endsAt?: Date;
    locationIds?: number[];
    startsAt?: Date;
    withAttachment?: boolean;
  }) => Promise<boolean>;

  @absencesSummaryNS.State
  private absences: StoreState['absences'];

  @Watch('filters.employmentStatuses')
  @Watch('filters.employmentIds')
  @Watch('filters.endsAt')
  @Watch('filters.locationIds')
  @Watch('filters.startsAt')
  protected async onFiltersChange() {
    if (!this.isSingleEmployment) {
      return;
    }

    this.isLoading = true;

    const employmentId = this.filters.employmentIds?.[0] as number;

    const result = await this.fetchSummaryData({
      employmentId,
      employmentStatuses: this.filters.employmentStatuses,
      endsAt: this.filters.endsAt,
      locationIds: this.filters.locationIds,
      startsAt: this.filters.startsAt,
      withAttachment: this.filters.withAttachment,
    });

    if (!result) {
      this.isLoading = false;
      this.items = [];
      return;
    }

    const sumsByReason = this.absences.reduce(
      groupSumReducer(item => item.absenceReason.id, item => item.days),
      {} as Record<number, number>,
    );

    this.items = (this.filteredAbsenceReasons || []).map(reason => ({
      label: reason.hasLocalization
        ? this.$t(`absence.reason.${reason.name}`)
        : reason.name,
      number: sumsByReason[reason.id] ?? 0,
      useGrouping: true,
      precision: 1,
    }));

    this.items.push({
      label: this.$t('absence.reason.total'),
      number: this.absences.reduce((prev, cur) => prev + cur.days, 0),
      useGrouping: true,
      precision: 1,
    });

    // reset here so that loading is shown while doing above calculations
    this.isLoading = false;
  }

  protected get isSingleEmployment() {
    return Array.isArray(this.filters.employmentIds) && this.filters.employmentIds.length === 1;
  }

  public render() {
    return (
      <div class={[
        styles.summary,
        !this.isSingleEmployment && styles.summaryHidden,
      ]}>
        <h2 class={styles.summaryTitle}>
          {this.$t('absence.summary.title')}
        </h2>

        <FiguresSummary
          aria-hidden={!this.isSingleEmployment}
          isPlaceholderShown={this.isLoading}
          items={this.items}
        />
      </div>
    );
  }
}
