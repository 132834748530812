enum Action {
  TRY_LOGIN_FROM_LOCAL_STORAGE = 'tryLoginFromLocalStorage',
  GET_COMPANIES = 'getCompanies',
  GET_CURRENT_EMPLOYMENT = 'getCurrentEmployment',
  GET_RIGHTS = 'getRights',
  GET_USER_DATA = 'getUserData',
  SET_REQUEST_CREDENTIALS = 'setRequestCredentials',
}

export default Action;
