import {
  Component, Emit, Prop, Watch,
} from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import styles from './name-edit-form.css';
import { rotationWizardNS } from '../../store/Store';

interface Props {
  value: string;
  groupIndex: number;
}

interface Events {
  onSave: (value: string) => void;
  onCancel: () => void;
}

@Component
class NameEditForm extends TSXComponent<Props, Events> {
  private currentValue = '';

  @Prop()
  public groupIndex: number;

  @Prop({ default: '' })
  public value: string;

  @rotationWizardNS.Getter
  private rotationGroupNames: string[];

  private onSubmit(e: Event) {
    e.preventDefault();
    this.$emit('save', this.currentValue);
  }

  @Emit('cancel')
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars, no-unused-vars
  private onCancel(e) {}

  private onEscape(e) {
    if (e.key === 'Escape') {
      this.onCancel(e);
    }
  }

  @Watch('value')
  protected onValueChange() {
    this.currentValue = this.value;
  }

  private get isNameUnique() {
    return !this.rotationGroupNames
      .find((groupName, index) => (index !== this.groupIndex) && (this.currentValue === groupName));
  }

  private onInput({ target: { value } }) {
    this.currentValue = value;
  }

  public mounted() {
    this.currentValue = this.value;
    document.addEventListener('keyup', this.onEscape);
  }

  public beforeDestroy() {
    document.removeEventListener('keyup', this.onEscape);
  }

  public render() {
    return (
      <div class={styles.nameEditForm}>
        <form
          class={[
            styles.nameEditFormForm,
          ]}
          onSubmit={this.onSubmit}
        >
          <input
            class={{
              [styles.nameEditFormInput]: true,
              [styles.nameEditFormInputInvalid]: !this.isNameUnique,
            }}
            type="text"
            value={this.currentValue}
            onInput={this.onInput}
            onFocus={e => e.target.select()}
          />

          <Button
            disabled={!this.isNameUnique}
            type="submit"
            kind={ButtonKind.GHOST}
            color={ButtonColor.SUCCESS}
            size={Size.XSMALL}
            icon={IconName.DONE}
            iconPosition={IconPosition.ALONE}
          />

          <Button
            type="submit"
            kind={ButtonKind.GHOST}
            color={ButtonColor.ERROR}
            size={Size.XSMALL}
            onClick={this.onCancel}
            icon={IconName.CLEAR}
            iconPosition={IconPosition.ALONE}
          />
        </form>
        {
          !this.isNameUnique && <label class={styles.nameEditFormError}>
            {this.$t('rotationWizard.rotationPatternSetup.nameEditForm.error')}
          </label>
        }
      </div>
    );
  }
}

export default NameEditForm;
