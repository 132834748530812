import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Size } from 'components/types';
import { HTMLAttributes } from 'vue-tsx-support/types/dom';
import styles from './icon.css';
import { IconName } from './types';
import generalIconsSVG from './assets/general-icons.svg';

export const ICON_SIZES = [Size.XSMALL, Size.SMALL, Size.MEDIUM, Size.LARGE] as const;

export type IconSize = typeof ICON_SIZES[number]

export interface IconProps {
  name: IconName;
  size?: IconSize;
}

@Component
export class Icon extends TsxComponent<HTMLAttributes & IconProps> {
  @Prop()
  public name: IconProps['name'];

  @Prop({ default: Size.SMALL })
  public size: IconProps['size'];

  public render() {
    return (
      <svg
        role="img"
        class={{
          [styles.icon]: true,
          [styles.iconItemSize12]: this.size === Size.XSMALL,
          [styles.iconItemSize16]: this.size === Size.SMALL,
          [styles.iconItemSize20]: this.size === Size.MEDIUM,
          [styles.iconItemSize24]: this.size === Size.LARGE,
        }}
        {...this.$attrs}
      >
        <use href={`${generalIconsSVG}#.icon__item--${this.name}`} />
      </svg>
    );
  }
}

export default Icon;
