import Absence from 'components/calendar-common/absences/Absence';
import DateItem from 'components/calendar-common/common/DateItem';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridPrintRow from 'components/calendar-common/grid/grid-print-row/GridPrintRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import { Position } from 'components/calendar-common/positions/Position';
import Shift from 'components/calendar-common/shifts/Shift';
import { Component, Vue } from 'vue-property-decorator';
import { AbsenceStates } from 'components/calendar-common/absences/Store';
import { calendarPrintPositionsNS } from 'components/calendar-common/positions/Store';
import { calendarPrintFiltersNS } from 'components/calendar-print/filters/store/Store';
import type { PrintFilters } from 'components/calendar-print/filters/store/Store';
import styles from './positions-week.css';
import { calendarPrintCommonNS } from '../../common/Store';
import { calendarPrintShiftsNS } from '../../shifts/store/Store';
import { calendarPrintAbsencesNS } from '../../absences/store/Store';

@Component
export default class PositionsWeek extends Vue {
  @calendarPrintCommonNS.Getter('dates')
  public dates: DateItem[];

  @calendarPrintShiftsNS.Getter('shiftsByPositions')
  public shifts!: Record<number, Record<string, Shift[]>>;

  @calendarPrintAbsencesNS.Getter('absencesByDates')
  public absences!: Record<string, { new: Absence[]; accepted: Absence[] }>;

  @calendarPrintFiltersNS.Getter('filters')
  public filters!: PrintFilters;

  @calendarPrintPositionsNS.Getter('filteredPositions')
  public positions!: Position[];

  private renderAbsenceRow(absenceState: AbsenceStates) {
    const isVisible = (absenceState === AbsenceStates.NEW && this.filters.showNewAbsences)
    || (absenceState === AbsenceStates.ACCEPTED && this.filters.showAcceptedAbsences);
    return (isVisible && <GridPrintRow dates={this.dates}
      scopedSlots= {{
        cell: scope => this.absences[scope.dateItem.dateKey]
          && this.absences[scope.dateItem.dateKey][absenceState]
            .map(absence => <div>{absence.employment.name}</div>),
      }}>
      <GridCell slot="label"
        class={gridStyles.gridTableHeaderCell}
        isHeader={true}>
        <span>
          {this.$t(`calendar.${absenceState}Absence`)}
        </span>
      </GridCell>
    </GridPrintRow>);
  }

  private renderShift(shift: Shift) {
    return (
      <div class={styles.positionWeekShift}>
        <div class={styles.positionWeekShiftHeader}>
          <div class={styles.positionWeekShiftTime}>
            {`${shift.getStartTime()} - ${shift.getEndTime()}`}
          </div>
          <div>
            {`${shift.staffShiftsLength} / ${shift.workers}`}
          </div>
        </div>
        { shift.staffShifts
          .filter(staffShift => staffShift.id !== undefined)
          .map(staffShift => staffShift.name).join('\n') }
      </div>
    );
  }

  public render() {
    return (
      <div class={[gridStyles.gridTable, gridStyles.gridTablePrint, styles.positionWeek]}>
        <GridCell isHeader={true}
          class={gridStyles.gridTablePrintHeaderCell}>
          <span>
            {this.dates[0].date.format('MMMM YY')}
          </span>
        </GridCell>
        <div class={[gridStyles.gridTableSubgrid, styles.positionWeekGrid]}>
          <GridPrintRow dates={this.dates}
            isHeader={true}
            cellClass={gridStyles.gridTablePrintHeaderCell}
            scopedSlots= {{
              cell: (scope) => {
                const { date } = scope.dateItem;
                return (<div>
                  {date.format('ddd DD/MM')}
                </div>);
              },
            }}>
            <GridCell slot="label"
              class={gridStyles.gridTablePrintHeaderCell}
              isHeader={true}>
              <span>{this.$t('calendar.titlePosition')}</span>
            </GridCell>
          </GridPrintRow>
          {this.renderAbsenceRow(AbsenceStates.NEW)}
          {this.renderAbsenceRow(AbsenceStates.ACCEPTED)}
          {this.positions.map(({
            locationsPositionId: positionId,
            name: positionName,
          }) => (<GridPrintRow dates={this.dates}
            scopedSlots= {{
              cell: scope => (<div>
                {
                  this.shifts[positionId]
                && this.shifts[positionId][scope.dateItem.dateKey]
                && this.shifts[positionId][scope.dateItem.dateKey]
                  .map(shift => this.renderShift(shift))}
              </div>),
            }}>
            <GridCell slot="label"
              class={gridStyles.gridTablePrintLabelCell}
              isHeader={true}>
              <span>{positionName}</span>
            </GridCell>
          </GridPrintRow>))}
        </div>
      </div>
    );
  }
}
