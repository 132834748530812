import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import brandBlackIconAndTextSvg from './assets/brand-black-icon-and-text.svg';
import brandBlackIconSvg from './assets/brand-black-icon.svg';
import brandBlackTextSvg from './assets/brand-black-text.svg';
import brandWhiteIconAndTextSvg from './assets/brand-white-icon-and-text.svg';
import brandWhiteIconSvg from './assets/brand-white-icon.svg';
import brandWhiteTextSvg from './assets/brand-white-text.svg';
import { BrandColor, BrandKind } from './types';

export interface Props {
  color?: BrandColor
  kind?: BrandKind
}

const LOGO_MAP: Record<BrandColor, Record<BrandKind, string>> = {
  [BrandColor.BLACK]: {
    [BrandKind.ICON_AND_TEXT]: brandBlackIconAndTextSvg,
    [BrandKind.ICON]: brandBlackIconSvg,
    [BrandKind.TEXT]: brandBlackTextSvg,
  },
  [BrandColor.WHITE]: {
    [BrandKind.ICON_AND_TEXT]: brandWhiteIconAndTextSvg,
    [BrandKind.ICON]: brandWhiteIconSvg,
    [BrandKind.TEXT]: brandWhiteTextSvg,
  },
};

@Component
export default class Brand extends TsxComponent<Props> {
  @Prop({ default: BrandColor.BLACK })
  public color: NonNullable<Props['color']>;

  @Prop({ default: BrandKind.ICON_AND_TEXT })
  public kind: NonNullable<Props['kind']>;

  public render() {
    return (
      <img src={LOGO_MAP[this.color][this.kind]} alt="shyftplan" />
    );
  }
}
