import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type {
  FetchAllLocationsPositionsFunction,
  LocationsPosition,
} from 'store/locations-positions/Store';
import { Action, Action as StoreAction } from 'store/normalized-store';
import { shiftplansNS } from 'store/shiftplans/Store';
import type {
  FetchAllShiftplansFunction,
  Shiftplan,
} from 'store/shiftplans/Store';
import { Mode } from 'components/calendar-common/Enums';
import { calendarAbsencesCommonNS } from 'components/calendar-absences/common/store/Store';
import type { GetMultipleById, StoreActionResult } from 'utils/store';
import { shiftRotationsNS } from 'src/store/shift-rotations/Store';
import type { FetchAllShiftRotationsFunction, ShiftRotation } from 'src/store/shift-rotations/Store';
import { uiSettingsNS } from 'src/store/ui-settings/Store';
import type { StoreState as UiSettingsStoreState } from 'store/ui-settings/Store';
import AbsencePlanning from './AbsencePlanning';
import ScreenLoading from '../screen/ScreenLoading';
import UiSettingsContainer, { SettingsScope } from './ui-settings-container/UiSettingsContainer';
import ShiftScheduleAction from './store/Action';
import styles from './container.css';
import { SetTimeframePayload, shiftScheduleNS, StoreState as ShiftScheduleStoreState } from './store/Store';
import { FiltersMap } from '../calendar-common/filters/Store';

@Component
export default class AbsencePlanningContainer extends TsxComponent<{}> {
  private isLoading = true;

  protected isShiftplanFiltersSidebarCollapsed = false;

  @authNS.State
  public currentLocationId: AuthStoreState['currentLocationId'];

  @authNS.State
  public currentCompany: AuthStoreState['currentCompany'];

  @calendarAbsencesCommonNS.Getter('mode')
  public mode: Mode;

  @shiftRotationsNS.Action(Action.FETCH_ALL)
  protected fetchAllShiftRotations: FetchAllShiftRotationsFunction;

  @shiftRotationsNS.Getter('ordered')
  protected shiftRotations: ShiftRotation[];

  @shiftScheduleNS.Getter
  protected absencePlanningQuickFilters: FiltersMap[];

  @shiftScheduleNS.Action(ShiftScheduleAction.SET_TIMEFRAME)
  protected setTimeframe: (payload: SetTimeframePayload) => void;

  @shiftScheduleNS.State('timeframe')
  protected timeframe: ShiftScheduleStoreState['timeframe'];

  @shiftplansNS.Action(StoreAction.FETCH_ALL)
  protected fetchAllShiftplans: FetchAllShiftplansFunction;

  @shiftplansNS.Getter('getByLocationId')
  protected getShiftplansByLocationId: GetMultipleById<Shiftplan>;

  @locationsPositionsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAllLocationsPositions: FetchAllLocationsPositionsFunction;

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;

  @uiSettingsNS.State('shared')
  protected uiSharedSettings: UiSettingsStoreState['shared'];

  protected get shiftplans() {
    return this.getShiftplansByLocationId(this.currentLocationId || Number.NaN);
  }

  protected get orderedLocationsPositions() {
    return this.getLocationsPositionsByLocationAndPositionId(this.currentLocationId)
      .filter(({ position }) => !!position)
      .sort((x, y) => x.position!.name.localeCompare(y.position!.name));
  }

  public async mounted() {
    this.isLoading = true;

    if (!this.shiftplans.length) {
      this.fetchAllShiftplans({
        locationId: this.currentLocationId || Number.NaN,
      });
    }

    const promises: Promise<StoreActionResult>[] = [];

    if (!this.orderedLocationsPositions.length) {
      promises.push(this.fetchAllLocationsPositions({
        locationIds: [this.currentLocationId || Number.NaN],
        positionIds: null,
      }));
    }

    if (!this.orderedLocationsPositions.length) {
      promises.push(this.fetchAllLocationsPositions({
        locationIds: [this.currentLocationId || Number.NaN],
        positionIds: null,
      }));
    }

    if (this.currentCompany?.shiftRotationEnabled) {
      promises.push(this.fetchAllShiftRotations({}));
    }

    await Promise.all(promises);

    this.isLoading = false;
  }

  public render() {
    if (this.isLoading) {
      return (
        <ScreenLoading />
      );
    }

    return (
      <div class={styles.containerWrapper}>
        <UiSettingsContainer
          scope={SettingsScope.ABSENCES}
          isShiftplanFiltersSidebarCollapsed={this.isShiftplanFiltersSidebarCollapsed}
        />

        <AbsencePlanning
          absencePlanningQuickFilters={this.absencePlanningQuickFilters}
          timeframe={this.timeframe}
          mode={this.mode}
          isShiftRotationsEnabled={this.currentCompany?.shiftRotationEnabled || false}
          shiftRotations={this.shiftRotations}
          shiftplans={this.shiftplans}
          onTimeframeChange={({ payload }) => this.setTimeframe(payload)}
          onFilterSidebarToggle={() => {
            this.isShiftplanFiltersSidebarCollapsed = !this.isShiftplanFiltersSidebarCollapsed;
          }}
          isShiftplanFiltersSidebarCollapsed={
            !!this.uiSharedSettings.isShiftplanFiltersSidebarCollapsed
          }
        />
      </div>
    );
  }
}
