import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import Alert, { AlertKind } from 'components/alert/Alert';
import Spinner from 'components/spinner/Spinner';
import { SentryTag } from 'services/logger/SentryTransport';
import { Action as StoreAction } from 'store/normalized-store';
import { shiftPresetsNS } from 'store/shift-presets/Store';
import type { FetchAllShiftPresetsFunction } from 'store/shift-presets/Store';
import { DAYS_IN_WEEK } from 'utils/date-related';
import { getFirstErrorMessageFromResponse, GQLResponseExtension } from 'utils/store';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'utils/url';
import { wrapAroundMod } from 'utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { IconName } from 'components/icons/types';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { IconPosition } from 'components/form/button/types';
import ActionButtonWrapper from '../action-button-wrapper/ActionButtonWrapper';
import { Route } from '../routes';
import ShiftGroupSetup from '../shift-group-setup/ShiftGroupSetup';
import StepWrapper, { Slot as StepWrapperSlot } from '../step-wrapper/StepWrapper';
import Action from '../store/Action';
import { rotationWizardNS, StoreState as RotationWizardStoreState } from '../store/Store';
import {
  CreateRotationResponse,
  RotationDayPreset,
  RotationGroup,
  UpdateRotationResponse,
} from '../store/types';

import styles from './rotation-pattern-setup.css';

@Component
export default class RotationPatternSetup extends TsxComponent<{ isEdit: boolean }> {
  public $refs: {
    shiftGroupsRef: ShiftGroupSetup[];
  };

  private isSubmitting = false;

  @Prop()
  protected isEdit: boolean;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @rotationWizardNS.State
  protected rotationGroupsLength: RotationWizardStoreState['rotationGroupsLength'];

  @rotationWizardNS.State
  protected rotationInterval: RotationWizardStoreState['rotationInterval'];

  @rotationWizardNS.State
  protected rotationGroups: RotationGroup[];

  @rotationWizardNS.State
  protected anchorDate: RotationWizardStoreState['anchorDate'];

  @shiftPresetsNS.Action(StoreAction.FETCH_ALL)
  protected fetchShiftPresets: FetchAllShiftPresetsFunction;

  @rotationWizardNS.Action(Action.UPDATE_ROTATION_DAY)
  protected updateRotationDay: ({
    dayIndex,
    groupIndex,
    preset,
  }: {
    dayIndex: number;
    groupIndex: number;
    preset: RotationDayPreset;
  }) => void;

  @rotationWizardNS.Action(Action.UPDATE_ROTATION_GROUP_NAME)
  protected updateRotationGroupName: ({
    groupIndex,
    name,
  }: {
    groupIndex: number;
    name: string;
  }) => void;

  @rotationWizardNS.Action(Action.MOVE_ROTATION_GROUP)
  protected moveRotationGroup: ({
    groupIndex,
    direction,
  }: {
    groupIndex: number;
    direction: number;
  }) => void;

  @rotationWizardNS.Action(Action.DELETE_ROTATION_GROUP)
  protected deleteRotationGroup: ({
    groupIndex,
  }: {
    groupIndex: number;
  }) => void;

  @rotationWizardNS.Action(Action.CREATE_ROTATION)
  protected createRotation: () => Promise<CreateRotationResponse>;

  @rotationWizardNS.Action(Action.UPDATE_ROTATION)
  protected updateRotation: () => Promise<UpdateRotationResponse>;

  @rotationWizardNS.Action(Action.CREATE_ROTATION_GROUPS)
  protected createRotationGroups: (shiftRotationId: number) => Promise<CreateRotationResponse[]>;

  @rotationWizardNS.Action(Action.UPDATE_ROTATION_GROUPS)
  protected updateRotationGroups: (shiftRotationId: number) => Promise<UpdateRotationResponse[]>;

  @rotationWizardNS.Action(Action.RESET)
  protected resetStore: () => void;

  @rotationWizardNS.Getter
  protected isRotationPatternSetupValid: boolean;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  private get weeksLength() {
    return Math.ceil(this.rotationInterval / DAYS_IN_WEEK);
  }

  private onPresetSelect(payload: {
    preset: RotationDayPreset;
    groupIndex: number;
    dayIndex: number;
  }) {
    this.updateRotationDay(payload);
  }

  protected getErrorMessage(
    errorResponse: GQLResponseExtension | undefined,
    type: 'shiftRotation' | 'shiftRotationGroup',
  ) {
    if (errorResponse?.status === 403) {
      return this.$t('rotationWizard.error.forbidden');
    }

    return getFirstErrorMessageFromResponse(
      errorResponse,
      this.$t.bind(this),
      `rotationWizard.error.${type}`,
    ) || this.$t('general.error.unknown');
  }

  private async create(): Promise<false | number> {
    const createRotationResponse = await this.createRotation();

    if (typeof createRotationResponse !== 'number') {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, RotationPatternSetup.name]],
        message: JSON.stringify(createRotationResponse),
      });

      this.isSubmitting = false;

      const message = this.getErrorMessage(createRotationResponse, 'shiftRotation');

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });
      return false;
    }

    const rotationId = createRotationResponse;
    const createRotationGroupResponse = await this.createRotationGroups(rotationId);

    const firstErrorResponse = createRotationGroupResponse.find(
      (response): response is GQLResponseExtension | undefined => typeof response !== 'number',
    );

    if (firstErrorResponse) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, RotationPatternSetup.name]],
        message: JSON.stringify(createRotationGroupResponse),
      });

      this.isSubmitting = false;

      const message = this.getErrorMessage(firstErrorResponse, 'shiftRotationGroup');

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });
      return false;
    }

    this.isSubmitting = false;

    this.showSnackbar({
      kind: AlertKind.SUCCESS,
      message: this.$t('rotationWizard.saved'),
      timeout: 5000,
    });
    return rotationId;
  }

  private async update(): Promise<false | number> {
    const updateRotationResponse = await this.updateRotation();

    if (typeof updateRotationResponse !== 'number') {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, RotationPatternSetup.name]],
        message: JSON.stringify(updateRotationResponse),
      });

      this.isSubmitting = false;

      const message = this.getErrorMessage(updateRotationResponse, 'shiftRotation');

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });
      return false;
    }

    const rotationId = updateRotationResponse;
    const createRotationGroupResponse = await this.updateRotationGroups(rotationId);

    const firstErrorResponse = createRotationGroupResponse.find(
      (response): response is GQLResponseExtension | undefined => typeof response !== 'number',
    );

    if (firstErrorResponse) {
      this.$logInfo({
        tags: [[SentryTag.COMPONENT, RotationPatternSetup.name]],
        message: JSON.stringify(createRotationGroupResponse),
      });

      this.isSubmitting = false;

      const message = this.getErrorMessage(firstErrorResponse, 'shiftRotationGroup');

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });
      return false;
    }

    this.isSubmitting = false;

    this.showSnackbar({
      kind: AlertKind.SUCCESS,
      message: this.$t('rotationWizard.saved'),
      timeout: 5000,
    });

    return rotationId;
  }

  private async onNextStepClick(isFinish = false) {
    this.isSubmitting = true;

    const requestResult = this.isEdit
      ? await this.update()
      : await this.create();

    // in case of error redirect to first page
    if (!requestResult) {
      return this.$router.replace({
        name: this.isEdit
          ? Route.EDIT_STEP_1 : Route.STEP_1,
      });
    }

    if (isFinish) {
      this.resetStore();

      const companyProfileUrl = getUrlWithApiPrefix(getUrlWithCompanyPrefix('', this.currentCompany));
      window.location.assign(companyProfileUrl);

      return undefined;
    }

    return this.$router.push({
      name: Route.EDIT_ROOT,
      params: { id: requestResult.toString() },
    });
  }

  public mounted() {
    this.fetchShiftPresets({});

    if (this.rotationGroups.length === 0) {
      this.$router.replace({ name: Route.ROOT });
    }
  }

  public render() {
    return (
      <StepWrapper heading={this.$t('rotationWizard.rotationPatternSetup.heading')}>
        {
          this.isEdit && (
            <Alert
              class={styles.rotationPatternSetupAlert}
              kind={AlertKind.INFO}
              title={this.$t('rotationWizard.rotationPatternSetup.warning.title')}
              message={this.$t('rotationWizard.rotationPatternSetup.warning.message')}
              isDismissible={false}
            />
          )
        }

        <div class={styles.rotationPatternSetupContent}>
          {this.rotationGroups.map((group, groupIndex) => (
            <ShiftGroupSetup
              key={group.id}
              firstDay={new Date(this.anchorDate)}
              isInitiallyOpened={groupIndex === 0}
              groupsLength={this.rotationGroups.length}
              weeksLength={this.weeksLength}
              groupIndex={groupIndex}
              group={group}
              onPresetSelect={(e, dayIndex, preset) => (
                this.onPresetSelect({ groupIndex, dayIndex, preset })
              )}
              onGroupNameUpdate={(value) => {
                this.updateRotationGroupName({
                  groupIndex,
                  name: value,
                });
              }}
              onDelete={() => this.deleteRotationGroup({ groupIndex })}
              onMove={({
                payload:
                { direction },
              }) => this.moveRotationGroup({ groupIndex, direction })}
              onAccordionKeyDown={(e: SyntheticEvent<HTMLDivElement, KeyboardEvent>) => {
                const curIndex = this.$refs.shiftGroupsRef.findIndex(grp => (
                  grp.$el.contains(e.target)
                ));

                if (curIndex < 0) {
                  return undefined;
                }

                let newIndex: number;

                if (e.key === 'ArrowDown') {
                  newIndex = wrapAroundMod(curIndex, this.$refs.shiftGroupsRef.length, 1);
                } else if (e.key === 'ArrowUp') {
                  newIndex = wrapAroundMod(curIndex, this.$refs.shiftGroupsRef.length, -1);
                } else {
                  return undefined;
                }

                // FIXME: Find a better way to focus grandchild component's elements
                return this.$refs.shiftGroupsRef[newIndex]
                  .$refs.accordionRef?.$refs.buttonRef?.focus();
              }}
              ref="shiftGroupsRef"
              refInFor={true}
            />
          ))}
        </div>

        <ActionButtonWrapper slot={StepWrapperSlot.FOOTER}>
          <Button
            type="button"
            color={ButtonColor.SECONDARY}
            size={Size.LARGE}
            kind={ButtonKind.FILL}
            onClick={() => this.onNextStepClick(true)}
            disabled={!this.isRotationPatternSetupValid || this.isSubmitting}
          >
            {this.isSubmitting
              ? <Spinner />
              : this.$t('rotationWizard.rotationPatternSetup.buttonFinishWizard')
            }
          </Button>

          <Button
            type="submit"
            color={ButtonColor.PRIMARY}
            size={Size.LARGE}
            kind={ButtonKind.FILL}
            icon={IconName.ARROW_NEXT}
            iconPosition={IconPosition.AFTER}
            onClick={() => this.onNextStepClick(false)}
            disabled={!this.isRotationPatternSetupValid || this.isSubmitting}
          >
            {this.isSubmitting
              ? <Spinner />
              : this.$t('rotationWizard.rotationPatternSetup.buttonAssignEmployees')
            }
          </Button>
        </ActionButtonWrapper>
      </StepWrapper>
    );
  }
}
