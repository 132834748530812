import { Route } from 'components/dialog-shift/routes';
import SpIcon from 'components/sp-icon/SpIcon';
import moment from 'moment';
import RootStoreState from 'src/store/RootStoreState';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import HighlightableShift from '../HighlightableShift';
import Shift from '../Shift';
import ShiftMixin from '../ShiftMixin';
import styles from './shift-month.css';

@Component
class ShiftMonth extends Mixins(ShiftMixin, HighlightableShift) {
  @Prop()
  public shift: Shift;

  @Prop()
  public classes: string[];

  @Prop({ default: () => ({}) })
  public dataAttrs: Record<string, string>;

  @State((state: RootStoreState) => state.auth.currentCompany?.canUseShiftPresets || false)
  public isShiftPresetsEnabled: boolean;

  private get label() {
    return this.shift.shiftPreset && this.isShiftPresetsEnabled
      // slice full name if shortName is not provided
      ? this.shift.shiftPreset.shortName
        || this.shift.shiftPreset.name.slice(0, 3)
      : moment(this.shift.getStartDateTime()).format('HH');
  }

  private onClick(e: MouseEvent) {
    e.stopPropagation();

    this.$router.push({
      name: Route.ROOT,
      params: { id: this.shift.id.toString(), shiftplanId: this.shift.shiftplanId.toString() },
    });
  }

  private renderIcons() {
    return (<div class={styles.shiftMonthIcons}>
      {this.shift.hasNote() && <SpIcon name="note"
        class={styles.shiftMonthIcon}
        data-tooltip="true"
        data-html="true"
        label={this.shift.note || ''}/>}
    </div>);
  }

  public render() {
    return (<button class={[
      this.classes,
      styles.shiftMonth,
      this.highlightClass,
      {
        [styles.shiftMonthIsDropActive]: this.isDropActive,
        [styles.shiftMonthIsDragOverActive]: this.isDragOverActive,
      },
    ]}
    style={this.shift.style}
    draggable={this.shift.isManageable}
    onDrop={this.onDrop}
    onDragover={this.onDragOver}
    onDragenter={this.onDragEnter}
    onDragleave={this.onDragLeave}
    onClick={e => this.onClick(e)}>
      <div class={styles.shiftMonthIconsRow}>
        <span class={{
          [styles.shiftMonthEmploymentsIcon]: true,
          [styles.shiftMonthEmploymentsIconOverassigned]: this.shift.isOverassigned,
          [styles.shiftMonthEmploymentsIconUnderassigned]: this.shift.isOpen,
        }}></span>
        {this.renderIcons()}
      </div>
      <span>
        {this.label}
      </span>
    </button>);
  }
}

export default ShiftMonth as any;
