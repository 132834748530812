import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { StoreState as AuthStoreState } from 'components/auth/store/Store';
import { authNS } from 'components/auth/store/Store';
import tableStyles from 'components/table/table.css';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'src/utils/url';
import LinkButton, { LinkButtonColor } from 'components/link-button/LinkButton';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { AvailabilityAggregation } from '../../types';
import styles from './cell-actions.css';

export interface Props {
  availabilityAggregation: AvailabilityAggregation;
}

@Component
export default class CellActions extends TsxComponent<Props> {
  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @Prop()
  public availabilityAggregation: Props['availabilityAggregation'];

  public render() {
    return (
      <td class={[tableStyles.tableBodyCell, styles.cellActions]}>
        <LinkButton
          isExternal
          class={styles.cellActionsLink}
          color={
            this.availabilityAggregation.canManage
              ? LinkButtonColor.SHYFTPLAN_GREEN
              : LinkButtonColor.OUTER_SPACE
          }
          title={this.$t(
            this.availabilityAggregation.canManage
              ? 'availabilityAggregations.table.buttonEditTitle'
              : 'availabilityAggregations.table.buttonViewTitle',
          )}
          to={
            getUrlWithApiPrefix(
              getUrlWithCompanyPrefix(
                `/employments/${this.availabilityAggregation.employment.id}/availabilities`,
                this.currentCompany,
              ),
            )
          }
        >
          <Icon
            name={this.availabilityAggregation.canManage ? IconName.EDIT : IconName.EYE_ON }
            size={Size.SMALL}
          />
        </LinkButton>
      </td>
    );
  }
}
