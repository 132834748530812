import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS, StoreState } from 'components/auth/store/Store';
import tableStyles from 'components/table/table.css';
import { EventPayload, createEventPayload } from 'src/utils/events';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import { IconPosition } from 'components/form/button/types';
import type { Absence } from '../types';

@Component
export default class CellActions extends TsxComponent<{
  absence: Absence;
}, {
  onEditClick: (payload: EventPayload<number>) => void;
}> {
  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: StoreState['currentEmployment'];

  @Prop()
  public absence: Absence;

  public render() {
    return (
      <td class={tableStyles.tableBodyCell}>
        {this.currentCompany?.isAbsenceEditEnabled && this.currentEmployment?.isStakeholder && (
          <Button
            color={ButtonColor.SECONDARY}
            icon={IconName.EDIT}
            onClick={e => this.$emit('editClick', createEventPayload(e, this.absence.id))}
            size={Size.MEDIUM}
            kind={ButtonKind.GHOST}
            iconPosition={IconPosition.ALONE}
          />
        )}
      </td>
    );
  }
}
