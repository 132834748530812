import Absence from 'components/calendar-common/absences/Absence';
import AbsenceDay from 'components/calendar-common/absences/absence-day/AbsenceDay';
import DateItem from 'components/calendar-common/common/DateItem';
import DndParams from 'components/calendar-common/grid/DndParams';
import GridCell from 'components/calendar-common/grid/grid-cell/GridCell';
import GridRow, { GridRowScope } from 'components/calendar-common/grid/grid-row/GridRow';
import gridStyles from 'components/calendar-common/grid/grid-table.css';
import {
  GRID_COLUMN_MIN_WIDTH_DAY,
  GRID_LABEL_COLUMN_SMALL_WIDTH,
} from 'components/calendar-common/grid/GridVariables';
import DayNotes from 'components/calendar-common/notes/day-notes/DayNotes';
import SpecialDates from 'components/calendar-common/notes/special-dates/SpecialDates.jsx';
import { notesNS, OpenStrategy } from 'components/calendar-common/notes/Store';
import Shift from 'components/calendar-common/shifts/Shift';
import ShiftDay from 'components/calendar-common/shifts/shift-day/ShiftDay';
import ShiftViewActionsMixin from 'components/calendar-common/shifts/ShiftViewActionsMixin';
import { calendarCommonNS } from 'components/calendar/common/Store';
import { calendarFiltersNS } from 'components/calendar-common/filters/Store';
import { shiftsNS } from 'components/calendar-common/shifts/store/Store';
import { DayNote, dayNotesNS } from 'src/store/day-notes/Store';
import { Component, Mixins } from 'vue-property-decorator';
import { absencesNS } from 'components/calendar/absences/Store';
import styles from './agenda-month.css';

interface GridTemplate {
  gridTemplateColumns: string;
}

@Component
class AgendaMonth extends Mixins(ShiftViewActionsMixin) {
  @calendarCommonNS.Getter('dates')
  private shiftplanDates: DateItem[];

  @shiftsNS.Getter('shiftsByDates')
  private shifts: Record<number, Shift[]>;

  @calendarFiltersNS.Getter('filters')
  private filters: Record<string, boolean>;

  @absencesNS.Getter('absencesByDatesUnsorted')
  private absences!: Record<string, Absence[]>;

  @dayNotesNS.Getter('byDate')
  private notes: Record<string, DayNote[]>;

  @dayNotesNS.Getter('canManage')
  private canManageNotes: boolean;

  @notesNS.Getter('specialDates')
  private specialDates: Record<string, object[]>;

  protected get gridStyle(): GridTemplate {
    return {
      gridTemplateColumns: `${GRID_LABEL_COLUMN_SMALL_WIDTH} repeat(7, minmax(${
        GRID_COLUMN_MIN_WIDTH_DAY}, 1fr))`,
    };
  }

  private get weeks(): DateItem[][] {
    const DAYS_IN_WEEK = 7;
    const extraDays: DateItem[] = [];
    const { date } = this.shiftplanDates[0];
    const startOfWeek = date.clone().startOf('week');
    while (!date.isSame(startOfWeek, 'day')) {
      date.subtract(1, 'day');
      extraDays.unshift(new DateItem(date.clone(), false, false, false));
    }
    const dates = [...extraDays, ...this.shiftplanDates];
    const weeks: DateItem[][] = [];
    let i = 0;
    while (i < dates.length) {
      weeks.push(dates.slice(i, i + DAYS_IN_WEEK));
      i += DAYS_IN_WEEK;
    }
    return weeks;
  }

  private showDayNotes(date: DateItem): boolean {
    return this.filters.showDayNotes
    && (
      (this.notes[date.dateKey] && this.notes[date.dateKey].length > 0)
      || (this.canManageNotes && date.isWithinShiftplan));
  }

  private renderDay(scope: GridRowScope) {
    const { dateKey, date } = scope.dateItem;
    return [
      this.filters.showSpecialDates && <SpecialDates
        specialDates={this.specialDates[dateKey]}/>,
      this.showDayNotes(scope.dateItem)
      && [<DayNotes
        notes={this.notes[dateKey]}
        class={styles.agendaMonthNotes}
        date={scope.dateItem}
        openStrategy={OpenStrategy.INDEPENDENT}
      />,
      <div class={styles.agendaMonthSeparator}/>],
      <div class={styles.agendaMonthCellDate}>
        {date.format('DD')}
      </div>,
      (<div class={styles.agendaMonthCellEvents}>
        {this.absences[dateKey]
          && this.absences[dateKey]
            .map(absence => <AbsenceDay
              absence={absence} key={absence.uniqId}/>)}
        {this.shifts[dateKey]
          && this.shifts[dateKey].map(shift => <ShiftDay
            onDrop={(...args) => this.$emit('drop', ...args)}
            shift={shift} key={shift.id}/>)}
      </div>),
    ];
  }

  public render() {
    return (<div class={[gridStyles.gridTable, styles.agendaMonth]}
      style={this.gridStyle}>
      <GridRow
        class={[gridStyles.gridTableHeader, gridStyles.gridTableHeaderSticky]}
        isHeader={true}
        cellClass={gridStyles.gridTableHeaderCell}
        dates={this.weeks[0]}
        scopedSlots= {{ cell: (scope: GridRowScope) => scope.dateItem.date.format('ddd') }}>
        <GridCell
          slot="label"
          isHeader={true}>
          {this.$t('calendar.calendarWeekShort')}
        </GridCell>
      </GridRow>

      {this.weeks.map(weekDates => <GridRow
        isLastRow={true}
        dates={weekDates}
        dndParams={ { ...DndParams, isDroppable: true } }
        cellClass={styles.agendaMonthCell}
        onCellClick={dateItem => this.onShiftCellClick(dateItem)}
        scopedSlots= {{
          cell: (scope: GridRowScope) => this.renderDay(scope),
        }}>

        <GridCell slot="label" class={[
          gridStyles.gridTableLabelCell,
          styles.agendaMonthWeekNumber]}
        isHeader={true}>
          {weekDates[0].week}
        </GridCell>
      </GridRow>)}

    </div>);
  }
}

export default AgendaMonth as any;
