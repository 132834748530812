import Vue from 'vue';
import DateItem from 'components/calendar-common/common/DateItem';
import SpIcon from 'components/sp-icon/SpIcon';
import { mapActions, mapGetters } from 'vuex';
import styles from './day-notes-compact.css';

export default Vue.component('DayNotesCompact', {
  props: {
    notes: {
      default() {
        return [];
      },
      type: Array,
    },
    date: {
      default: () => ({}),
      type: DateItem,
    },
  },
  computed: {
    isEmpty() {
      return this.notes.length === 0;
    },
    ...mapGetters('dayNotes', {
      canManageNotes: 'canManage',
    }),
  },
  methods: {
    ...mapActions('calendar/notes/dayNotesPopup', ['openDayNotePopup']),
  },
  render() {
    return (
      <button class={styles.dayNotesCompact}
        disabled={this.isEmpty && !this.date.isWithinShiftplan}
        onClick={e => this.openDayNotePopup({ event: e, dateItem: this.date })}>
        {this.isEmpty
          ? (this.canManageNotes && <SpIcon name="plus" class={styles.dayNotesCompactAddIcon} />)
          : (<div class={styles.dayNotesCompactNotesCount}>
            {this.notes.length}
          </div>)}
      </button>);
  },
});
