import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Badge, { BadgePreset } from 'components/badge/Badge';
import { IconName } from 'components/icons/types';
import { GQLAbsenceState } from 'codegen/gql-types';
import Dropdown from 'components/dropdown/Dropdown';
import { DropdownItemButton, DropdownItemKind } from 'components/dropdown/types';
import type { Absence } from '../types';
import styles from './dropdown-state.css';

const statePresetMap: Record<GQLAbsenceState, BadgePreset> = {
  [GQLAbsenceState.ACCEPTED]: BadgePreset.INFO,
  [GQLAbsenceState.REFUSED]: BadgePreset.ERROR,
  [GQLAbsenceState.NEW]: BadgePreset.WARNING,
};

const stateIconMap: Record<GQLAbsenceState, IconName> = {
  [GQLAbsenceState.ACCEPTED]: IconName.DONE,
  [GQLAbsenceState.NEW]: IconName.ARROW_BACK,
  [GQLAbsenceState.REFUSED]: IconName.CLEAR,
};

@Component
export default class DropdownState extends TsxComponent<{
  absence: Absence;
}, {
  onApproveClick: (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => void;
  onDeclineClick: (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => void;
}> {
  /* eslint-disable @typescript-eslint/no-empty-function */
  /* eslint-disable no-unused-vars */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  @Emit('approveClick')
  protected onApproveClick(e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) { }

  @Emit('declineClick')
  protected onDeclineClick(e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) { }
  /* eslint-enable @typescript-eslint/no-empty-function */
  /* eslint-enable no-unused-vars */
  /* eslint-enable @typescript-eslint/no-unused-vars */

  @Prop()
  public absence: Absence;

  protected get stateDropdown(): DropdownItemButton[] {
    return [{
      disabled: this.absence.state === GQLAbsenceState.ACCEPTED,
      type: DropdownItemKind.BUTTON,
      label: (
        <Badge
          icon={IconName.DONE}
          preset={BadgePreset.INFO}
        >
          {this.$t('absence.buttonApprove')}
        </Badge>
      ),
      onClick: (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
        this.onApproveClick(e);
        e.stopPropagation();
      },
    }, {
      disabled: this.absence.state === GQLAbsenceState.REFUSED,
      type: DropdownItemKind.BUTTON,
      label: (
        <Badge
          icon={IconName.CLEAR}
          preset={BadgePreset.ERROR}
        >
          {this.$t('absence.buttonDecline')}
        </Badge>
      ),
      onClick: (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
        this.onDeclineClick(e);
        e.stopPropagation();
      },
    }];
  }

  public render() {
    return (
      <Dropdown
        class={styles.dropdownState}
        hideChevron={true}
        items={this.stateDropdown}
      >
        <Badge
          icon={stateIconMap[this.absence.state]}
          preset={statePresetMap[this.absence.state]}
        >
          {this.$t(`absence.state.${this.absence.state}`)}
        </Badge>
      </Dropdown>
    );
  }
}
