import {
  GQLEmploymentsRotationGroup,
  GQLOrderDirection,
  GQLRequestType,
  GQLShiftAvailableEmploymentFragmentFragment,
  GQLShiftConflictsFragmentFragment,
  GQLShiftEmploymentSort,
} from 'codegen/gql-types';
import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import DialogShiftConflicts from 'components/dialog-shift/DialogShiftConflicts';
import EmploymentWithAvatar, { Slot } from 'components/employment-with-avatar/EmploymentWithAvatar';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import Pagination from 'components/pagination/Pagination';
import Tooltip from 'components/tooltip/Tooltip';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { Action } from 'store/normalized-store';
import { shiftAvailableEmploymentsNS } from 'store/shift-available-employments/Store';
import type { FetchAllShiftAvailableEmploymentsFunction, ShiftAvailableEmployment } from 'store/shift-available-employments/Store';
import type { DeleteRequestFunction, Shift, AssignEmployeeToShiftRotationGroupFunction } from 'store/shifts/Store';
import { shiftsNS } from 'store/shifts/Store';
import { staffShiftsNS } from 'store/staff-shifts/Store';
import type { CreateStaffShiftFunction } from 'store/staff-shifts/Store';
import { EventPayload } from 'utils/events';
import { executeStoreActionWithFailureSnackbar, StoreActionState } from 'utils/store';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { AlertKind } from 'components/alert/Alert';
import DialogAssignConnected from 'components/dialog-shift/dialog-assign-connected/DialogAssignConnected';
import ShiftAction from 'src/store/shifts/Action';
import DialogAssignRotationGroup from 'components/dialog-shift/dialog-assign-rotation-group/DialogAssignRotationGroup';
import { isWithinInterval } from 'date-fns';
import { toDate } from 'date-fns-tz';
import {
  endOf,
  MAX_DATE,
  MIN_DATE,
  startOf,
  Unit,
} from 'src/utils/date-related';
import { Size } from 'components/types';
import Button from 'components/form/button/Button';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import { ButtonKind } from 'components/form/base-button/types';
import AvailabilityFilter from '../filtering/Availability';
import ShiftQualificationsFilter from '../filtering/Qualifications';
import GeneralFilter, { FilterGeneral } from '../filtering/General';
import HourBalance from '../hour-balance/HourBalance';
import IndicatorConflict from '../indicator-conflict/IndicatorConflict';
import IndicatorJoinRequest from '../indicator-join-request/IndicatorJoinRequest';
import PaymentBalance from '../payment-balance/PaymentBalance';
import Search from '../search/Search';
import Sorting from '../sorting/Sorting';
import Availability from '../../availability/Availability';
import { AvailabilityState } from '../../availability/Indicator';
import FilteringAndSorting, { SectionKind } from '../filtering-and-sorting/FilteringAndSorting';
import styles from './column-assignment.css';

export enum RotationGroupAssignmentKind {
  ALL_SHIFTS = 'allShifts',
  SINGLE_SHIFT = 'singleShift',
  CUSTOM = 'custom',
}

const DEBOUNCE_TIMEOUT = 250;

const ITEMS_PER_PAGE = 10;

@Component
export default class ColumnAssignment extends TsxComponent<{
  currency?: string | null;
  isMoneyShown?: boolean;
  shift: Shift;
}, {
  onRefetchShift: ({ useBackgroundFetch: boolean }) => void;
}> {
  protected isAssignConnected = false;

  protected conflicts: GQLShiftConflictsFragmentFragment[] = [];

  protected shiftAvailableEmploymentId = Number.NaN;

  protected isChronoOpen = true;

  protected isMoneyOpen = false;

  protected isDialogAssignConnectedOpen = false;

  protected isDialogAssignRotationGroupOpen = false;

  protected searchString = '';

  protected isLoading = false;

  protected page = 1;

  protected timeoutId: number = Number.NaN;

  protected commonShiftRotationGroupId: number | undefined;

  protected sectionsOpeningState = {
    [SectionKind.SEARCH]: false,
    [SectionKind.SORTING]: false,
    [SectionKind.FILTER_GENERAL]: false,
    [SectionKind.FILTER_AVAILABILITY]: false,
    [SectionKind.FILTER_QUALIFICATION]: false,
  };

  protected sorting = {
    criterion: GQLShiftEmploymentSort.NAME,
    order: GQLOrderDirection.ASC,
  };

  protected filterGeneral: Record<FilterGeneral, boolean> = {
    [FilterGeneral.WITH_JOIN_REQUESTS]: false,
    [FilterGeneral.WITHOUT_ABSENCE_CONFLICTS]: false,
    [FilterGeneral.WITHOUT_SHIFT_CONFLICTS]: false,
  };

  protected filterAvailability: Record<AvailabilityState, boolean> = {
    [AvailabilityState.AVAILABLE]: false,
    [AvailabilityState.PARTLY_AVAILABLE]: false,
    [AvailabilityState.UNAVAILABLE]: false,
    [AvailabilityState.PARTLY_UNAVAILABLE]: false,
    [AvailabilityState.PARTLY_BOTH]: false,
    [AvailabilityState.UNKNOWN]: false,
  };

  protected filterByQualificationIds: number[] = [];

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @shiftAvailableEmploymentsNS.Action(Action.FETCH_ALL)
  protected fetchAll: FetchAllShiftAvailableEmploymentsFunction;

  @shiftAvailableEmploymentsNS.Getter
  protected ordered: ShiftAvailableEmployment[];

  @shiftAvailableEmploymentsNS.State
  protected total: number;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @staffShiftsNS.Action(Action.CREATE)
  protected createStaffShift: CreateStaffShiftFunction;

  @shiftsNS.Action(ShiftAction.DELETE_REQUEST)
  protected deleteRequest: DeleteRequestFunction;

  @shiftsNS.Action(ShiftAction.ASSIGN_EMPLOYEE_TO_SHIFT_ROTATION_GROUP)
  protected assignEmployeeToShiftRotationGroup: AssignEmployeeToShiftRotationGroupFunction;

  @shiftsNS.Getter
  protected canManageShift: (locationId: number, locationsPositionId: number) => boolean;

  @Prop({ default: '€' })
  public currency: string;

  @Prop()
  public isMoneyShown?: boolean;

  @Prop()
  public shift: Shift;

  protected get pagesLength() {
    return Math.ceil(this.total / ITEMS_PER_PAGE);
  }

  protected get isManageableShift() {
    return this.canManageShift(
      this.shift?.locationsPosition.location?.id || Number.NaN,
      this.shift?.locationsPosition.id || Number.NaN,
    );
  }

  /*
    this hash is used to refetch list when shift assignments change
    this is a list of comma separated staffShift ids
  */
  protected get staffShiftsHash(): string {
    return this.shift.staffShifts?.map(it => it.id).join(',') || '';
  }

  protected get isAssignmentToShiftAllowed(): boolean {
    return this.isManageableShift && ((this.shift.workers > (this.shift.staffShiftsCount || 0))
      || !!this.currentCompany?.isOverassignmentAllowed);
  }

  protected get isQualificationsFilterVisible(): boolean {
    return !!(this.currentCompany?.canUseQualifications
      && this.shift.shiftQualifications?.length);
  }

  protected async refetch() {
    this.isLoading = true;

    await this.fetchAll({
      shiftId: this.shift.id,
      availability: {
        available: this.filterAvailability[AvailabilityState.AVAILABLE],
        neutral: this.filterAvailability[AvailabilityState.UNKNOWN],
        partialAvailable: this.filterAvailability[AvailabilityState.PARTLY_AVAILABLE],
        partialBoth: this.filterAvailability[AvailabilityState.PARTLY_BOTH],
        partialUnavailable: this.filterAvailability[AvailabilityState.PARTLY_UNAVAILABLE],
        unavailable: this.filterAvailability[AvailabilityState.UNAVAILABLE],
      },
      page: this.page,
      perPage: ITEMS_PER_PAGE,
      searchString: this.searchString || null,
      sortCriterion: this.sorting.criterion,
      sortOrder: this.sorting.order,
      withJoinRequests: this.filterGeneral[FilterGeneral.WITH_JOIN_REQUESTS],
      withoutAbsenceConflicts: this.filterGeneral[FilterGeneral.WITHOUT_ABSENCE_CONFLICTS],
      withoutShiftConflicts: this.filterGeneral[FilterGeneral.WITHOUT_SHIFT_CONFLICTS],
      withTheseQualificationIds: this.filterByQualificationIds,
    });

    this.isLoading = false;
  }

  protected async tryCreateStaffShift(ignoreConflicts = false) {
    this.conflicts = [];

    this.isLoading = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        staffShift: {
          ignoreConflicts,
          assignToConnected: this.isAssignConnected,
          employmentId: this.shiftAvailableEmploymentId,
          shiftId: this.shift.id,
        },
      },
      this.createStaffShift,
      'shifts.dialog.error',
    );

    this.isLoading = false;

    if (response.state === StoreActionState.CONFLICT) {
      this.conflicts = response.conflicts;
      return;
    }

    if (response.state === StoreActionState.SUCCESS) {
      this.$emit('refetchShift', { useBackgroundFetch: true });

      this.showSnackbar({
        message: this.$t('shifts.employees.snackShiftAssigned'),
        kind: AlertKind.SUCCESS,
        timeout: 5000,
      });
    }
  }

  protected async tryRejectJoinRequest(requestId: number) {
    this.conflicts = [];

    this.isLoading = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        id: requestId,
        shiftId: this.shift.id,
      },
      this.deleteRequest,
      'shifts.dialog.error',
    );

    this.isLoading = false;

    if (response.state === StoreActionState.SUCCESS) {
      await this.refetch();

      this.showSnackbar({
        message: this.$t('shifts.employees.snackJoinRequestRejected'),
        kind: AlertKind.SUCCESS,
        timeout: 5000,
      });
    }
  }

  protected async tryAssignShiftToRotationGroup({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) {
    const { locationsPosition, shiftRotationGroups } = this.shift;
    if (!shiftRotationGroups?.length || !this.commonShiftRotationGroupId) {
      return;
    }
    this.isLoading = true;

    const response = await executeStoreActionWithFailureSnackbar(
      this,
      {
        shiftRotationGroupAssignInput: {
          employmentId: this.shiftAvailableEmploymentId,
          locationsPositionId: locationsPosition.id,
          startDate,
          endDate,
          shiftRotationGroupId: this.commonShiftRotationGroupId,
        },
      },
      this.assignEmployeeToShiftRotationGroup,
      'shifts.dialog.error',
    );

    this.isLoading = false;

    if (response.state === StoreActionState.SUCCESS) {
      this.$emit('refetchShift', { useBackgroundFetch: true });

      await this.refetch();

      this.showSnackbar({
        message: this.$t('shifts.employees.rotationGroupShifts.backgroundJobSuccess'),
        kind: AlertKind.SUCCESS,
        timeout: 5000,
      });
    }
  }

  // we do not want to debounce this call
  @Watch('staffShiftsHash', { immediate: true })
  protected async onStaffShiftsUpdate() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    this.page = 1;
    this.refetch();
  }

  @Watch('filterGeneral')
  @Watch('filterAvailability')
  @Watch('searchString')
  @Watch('sorting')
  @Watch('filterByQualificationIds')
  protected async onFilterUpdate() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    this.timeoutId = window.setTimeout(() => {
      this.page = 1;
      this.refetch();
    }, DEBOUNCE_TIMEOUT);
  }

  @Watch('page')
  protected async onPageUpdate() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    this.timeoutId = window.setTimeout(this.refetch, DEBOUNCE_TIMEOUT);
  }

  protected onAssignClick(shiftAvailableEmployment: GQLShiftAvailableEmploymentFragmentFragment) {
    this.shiftAvailableEmploymentId = shiftAvailableEmployment.id;
    this.isAssignConnected = false;

    if (this.currentCompany?.shiftRotationEnabled
      && this.shift.shiftRotationGroups?.length) {
      const isShiftWithinEmploymentRotationGroupInterval = (
        o: Pick<GQLEmploymentsRotationGroup, 'id' | 'endsAt' | 'startsAt' | 'shiftRotationGroupId'>,
      ) => isWithinInterval(
        new Date(this.shift.startsAt),
        {
          start: o.startsAt ? new Date(o.startsAt) : MIN_DATE,
          end: o.endsAt ? new Date(o.endsAt) : MAX_DATE,
        },
      );

      const employmentsShiftRotationGroup = this.shift.shiftRotationGroupIds?.length
        ? shiftAvailableEmployment.employment
          .employmentsShiftRotationGroups
          .find(o => this.shift.shiftRotationGroupIds?.includes(o.shiftRotationGroupId)
            && isShiftWithinEmploymentRotationGroupInterval(o))
        : null;

      this.commonShiftRotationGroupId = employmentsShiftRotationGroup?.shiftRotationGroupId;

      if (this.commonShiftRotationGroupId) {
        this.isDialogAssignRotationGroupOpen = true;
        return;
      }
    }

    if (this.shift.connectedGroupId) {
      this.isDialogAssignConnectedOpen = true;
      return;
    }

    this.tryCreateStaffShift(false);
  }

  protected onRejectJoinRequestClick(requestId: number) {
    this.tryRejectJoinRequest(requestId);
  }

  protected onAssignConnectedConfirm({ payload }: EventPayload<boolean>) {
    this.isDialogAssignConnectedOpen = false;
    this.isAssignConnected = payload;
    this.tryCreateStaffShift(false);
  }

  protected onAssignRotationGroupConfirm({
    payload,
  }: EventPayload<{
    startDate: Date;
    endDate: Date;
    type: RotationGroupAssignmentKind;
  }>) {
    this.isDialogAssignRotationGroupOpen = false;

    switch (payload.type) {
      case RotationGroupAssignmentKind.ALL_SHIFTS:
        this.tryAssignShiftToRotationGroup({
          startDate: startOf(
            new Date(this.shift.startsAt),
            Unit.DAY,
            this.$timeZone.value,
          ).toISOString(),
          endDate: endOf(
            toDate(this.shift.shiftplan.endsAt, { timeZone: this.$timeZone.value }),
            Unit.DAY,
            this.$timeZone.value,
          ).toISOString(),
        });
        break;
      case RotationGroupAssignmentKind.CUSTOM:
        this.tryAssignShiftToRotationGroup({
          startDate: startOf(payload.startDate, Unit.DAY, this.$timeZone.value).toISOString(),
          endDate: endOf(payload.endDate, Unit.DAY, this.$timeZone.value).toISOString(),
        });
        break;
      default:
        this.tryCreateStaffShift();
    }
  }

  protected onCancelConflicts() {
    this.conflicts = [];
  }

  protected onIgnoreConflicts() {
    this.tryCreateStaffShift(true);
  }

  protected onSectionToggle({ payload }: EventPayload<SectionKind>) {
    this.sectionsOpeningState[payload] = !this.sectionsOpeningState[payload];
  }

  protected onFilterChange(e: SyntheticEvent<HTMLInputElement, Event>, filterKind: string) {
    this[filterKind] = {
      ...this[filterKind],
      [e.target.name]: e.target.checked,
    };
  }

  protected onSearchInput({ payload }: EventPayload<string>) {
    this.searchString = payload;
  }

  protected onSortingChange({ payload }: EventPayload<GQLShiftEmploymentSort>) {
    if (payload === this.sorting.criterion) {
      this.sorting = {
        ...this.sorting,
        order: this.sorting.order === GQLOrderDirection.ASC
          ? GQLOrderDirection.DESC
          : GQLOrderDirection.ASC,
      };
    } else {
      this.sorting = {
        criterion: payload,
        order: GQLOrderDirection.ASC,
      };
    }
  }

  protected onSortingTabSelect({ payload }: EventPayload<string>) {
    this.isChronoOpen = payload === 'chrono';
    this.isMoneyOpen = payload !== 'chrono';
  }

  protected onFilterQualification({ payload }: EventPayload<number[]>) {
    this.filterByQualificationIds = payload;
  }

  public beforeDestroy() {
    window.clearTimeout(this.timeoutId);
  }

  public render() {
    return (
      <div>
        <FilteringAndSorting
          onButtonClick={this.onSectionToggle}
          isQualificationsFilterVisible={this.isQualificationsFilterVisible}
        />

        <transition
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {
            this.sectionsOpeningState[SectionKind.SEARCH] && (
              <Search
                class={styles.columnAssignmentFilterSection}
                onInput={this.onSearchInput}
                value={this.searchString}
              />
            )
          }
        </transition>

        <transition
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {
            this.sectionsOpeningState[SectionKind.SORTING] && (
              <Sorting
                class={styles.columnAssignmentFilterSection}
                criterion={this.sorting.criterion}
                isBringShiftsShown={!!this.currentCompany?.canUseBringShifts}
                isChronoOpen={this.isChronoOpen}
                isHourAccountShown={true}
                isMoneyOpen={this.isMoneyOpen}
                isMoneyShown={this.isMoneyShown}
                onChange={this.onSortingChange}
                onTabSelect={this.onSortingTabSelect}
                order={this.sorting.order}
              />
            )
          }
        </transition>

        <transition
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {
            this.sectionsOpeningState[SectionKind.FILTER_GENERAL] && (
              <GeneralFilter
                class={styles.columnAssignmentFilterSection}
                onChange={({ event }) => this.onFilterChange(event, 'filterGeneral')}
                selected={this.filterGeneral}
                joinRequestLength={
                  this.shift.requests.filter(o => o.type === GQLRequestType.STAFFREQUEST).length
                }
              />
            )
          }
        </transition>

        <transition
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {
            this.sectionsOpeningState[SectionKind.FILTER_AVAILABILITY] && (
              <AvailabilityFilter
                class={styles.columnAssignmentFilterSection}
                onChange={({ event }) => this.onFilterChange(event, 'filterAvailability')}
                selected={this.filterAvailability}
              />
            )
          }
        </transition>

        <transition
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
        >
          {
            this.sectionsOpeningState[SectionKind.FILTER_QUALIFICATION] && (
              <ShiftQualificationsFilter
                class={styles.columnAssignmentFilterSection}
                onChange={(e) => { this.onFilterQualification(e); }}
                selected={this.filterByQualificationIds}
                qualifications={this.shift.shiftQualifications}
              />
            )
          }
        </transition>

        <div class={{
          [styles.columnAssignmentList]: true,
          [styles.columnAssignmentListLoading]: this.isLoading,
        }}>
          {
            (
              this.isLoading && (
                <LoadingIndicator class={styles.columnAssignmentLoadingIndicator} />
              )
            )
          }

          {
            this.ordered.map(shiftAvailableEmployment => (
              <div class={styles.columnAssignmentItem} key={shiftAvailableEmployment.id}>
                <EmploymentWithAvatar
                  class={styles.columnAssignmentEmployment}
                  employment={shiftAvailableEmployment.employment}
                  size={Size.SMALL}
                  other={
                    this.isChronoOpen
                      ? <HourBalance employment={shiftAvailableEmployment} />
                      : (
                        <PaymentBalance
                          currency={this.currency}
                          employment={shiftAvailableEmployment}
                        />
                      )
                  }
                >
                  <template slot={Slot.INDICATOR}>
                    {
                      shiftAvailableEmployment.hasConflicts && (
                        <IndicatorConflict
                          class={styles.columnAssignmentConflictIndicator}
                          employment={shiftAvailableEmployment}
                        />
                      )
                    }

                    <Availability
                      class={styles.columnAssignmentAvailabilityIndicator}
                      info={shiftAvailableEmployment.availabilityInfo}
                    />

                    {
                      shiftAvailableEmployment.joinRequest && (
                        <IndicatorJoinRequest
                          class={styles.columnAssignmentJoinRequestIndicator}
                          employment={shiftAvailableEmployment}
                        />
                      )
                    }
                  </template>
                </EmploymentWithAvatar>

                {this.isAssignmentToShiftAllowed && (
                  <Tooltip class={styles.columnAssignmentTooltip} text={this.$t('shifts.employees.buttonAssignEmployee')}>
                    <Button
                      class={styles.columnAssignmentButton}
                      disabled={this.isLoading}
                      onClick={() => this.onAssignClick(shiftAvailableEmployment)}
                      aria-label={this.$t('shifts.employees.buttonAssignEmployee')}
                      iconPosition={IconPosition.ALONE}
                      icon={IconName.PLUS}
                      kind={ButtonKind.FILL}
                      size={Size.XXSMALL}
                    />
                  </Tooltip>
                )}
                {shiftAvailableEmployment.joinRequest && this.isManageableShift && (
                  <Tooltip class={styles.columnAssignmentTooltip} text={this.$t('shifts.employees.buttonRejectRequest')}>
                    <Button
                      class={[styles.columnAssignmentButton, styles.columnAssignmentButtonUnassign]}
                      disabled={this.isLoading}
                      onClick={() => shiftAvailableEmployment.joinRequest
                        && this.onRejectJoinRequestClick(shiftAvailableEmployment.joinRequest?.id)}
                      aria-label={this.$t('shifts.employees.buttonRejectRequest')}
                      iconPosition={IconPosition.ALONE}
                      icon={IconName.MINUS}
                      kind={ButtonKind.FILL}
                      size={Size.XXSMALL}
                    />
                  </Tooltip>
                )}
              </div>
            ))
          }
        </div>

        {
          this.pagesLength > 1 && (
            <Pagination
              isLongGapOmitted={true}
              length={this.pagesLength}
              onClick={({ payload }) => { this.page = payload; }}
              selected={this.page}
            />
          )
        }

        <DialogShiftConflicts
          isOpen={!!this.conflicts.length}
          conflicts={this.conflicts}
          onCancel={this.onCancelConflicts}
          onIgnore={this.onIgnoreConflicts}
        />

        <DialogAssignConnected
          isOpen={this.isDialogAssignConnectedOpen}
          onCloseClick={() => {
            this.isDialogAssignConnectedOpen = false;
          }}
          onConfirm={this.onAssignConnectedConfirm}
        />

        <DialogAssignRotationGroup
          isOpen={this.isDialogAssignRotationGroupOpen}
          minDate={startOf(
            toDate(this.shift.shiftplan.startsAt, { timeZone: this.$timeZone.value }),
            Unit.DAY,
            this.$timeZone.value,
          )}
          maxDate={endOf(
            toDate(this.shift.shiftplan.endsAt, { timeZone: this.$timeZone.value }),
            Unit.DAY,
            this.$timeZone.value,
          )}
          onCloseClick={() => {
            this.isDialogAssignRotationGroupOpen = false;
          }}
          onConfirm={this.onAssignRotationGroupConfirm}
        />
      </div>
    );
  }
}
