import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import {
  StoreActionResult,
} from 'src/utils/store';
import {
  PrintViewKind, SlotDisplayStyle, TimeframeKind, ViewKind,
} from 'components/calendar-common/Enums';
import TableStoreAction from 'components/table/store/Action';
import { Action as FilterStoreAction } from 'store/filter-store';
import type { Shiftplan } from 'store/shiftplans/Store';
import UiSettingsAction from 'store/ui-settings/Action';
import { StoreState, UiSettings, uiSettingsNS } from 'store/ui-settings/Store';

import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { FiltersMap } from 'components/calendar-common/filters/Store';
import { ShiftRotation, shiftRotationsNS } from 'src/store/shift-rotations/Store';
import { getComputedTimeframe, getTimeframeFromDates } from 'utils/timeframe-helpers';
import QuickFilterAction from '../filter-box-quick/store/Action';
import {
  quickFilterNS, StoreState as QuickFilterFilterStoreState,
} from '../filter-box-quick/store/Store';
import { StoreState as ShiftPresetsFilterStoreState, shiftPresetsFilterNS } from '../filter-box-shift-preset/store/Store';
import {
  tagsFilterNS, StoreState as TagsFilterStoreState,
} from '../filter-box-tag/store/Store';
import ShiftScheduleAction from '../store/Action';
import {
  PrintTypeIntervals, SetTimeframePayload, shiftScheduleNS, StoreState as ShiftScheduleState,
} from '../store/Store';
import { StoreState as LocationsPositionsFilterStoreState, locationsPositionsFilterNS } from '../filter-box-locations-position/store/Store';
import { buildCalendarSettings, getApplicableShiftRotationGroupIds } from './util';
import { shiftRotationGroupsFilterNS } from '../filter-box-shift-rotation-group/store/Store';

export enum SettingsScope {
  CALENDAR = 'calendar',
  ABSENCES = 'absences',
  CALENDAR_PRINT = 'calendarPrint',
}

interface Props {
  scope: SettingsScope;
  shiftplan?: Shiftplan;
  isShiftplanFiltersSidebarCollapsed: boolean;
}

@Component
export default class UiSettingsContainer extends TsxComponent<Props> {
  @Prop()
  public shiftplan: Props['shiftplan'];

  @Prop()
  public scope: Props['scope'];

  @Prop()
  public isShiftplanFiltersSidebarCollapsed: Props['isShiftplanFiltersSidebarCollapsed'];

  // #region authNS
  @authNS.State
  public currentCompany: AuthStoreState['currentCompany'];
  // #endregion authNS

  // #region uiSettingsNS
  @uiSettingsNS.State('locationScoped')
  protected uiLocationScopedSettings: StoreState['locationScoped'];

  @uiSettingsNS.State('shiftplanScoped')
  protected uiShiftplanScopedSettings: StoreState['shiftplanScoped'];

  @uiSettingsNS.State('shared')
  protected uiSharedSettings: StoreState['shared'];

  @uiSettingsNS.State('calendarAbsencesScoped')
  protected uiCalendarAbsencesScopedSettings: StoreState['calendarAbsencesScoped'];

  @uiSettingsNS.State('employmentId')
  protected uiSettingsEmploymentId: StoreState['employmentId'];

  @uiSettingsNS.Action(UiSettingsAction.UPDATE_SETTINGS_STATE)
  protected updateCurrentSettingsState: (settings: UiSettings) => Promise<void>;

  @uiSettingsNS.Action(UiSettingsAction.SAVE_SETTINGS)
  protected saveAllSettings: () => Promise<void>;

  @uiSettingsNS.Action(UiSettingsAction.GET_SETTINGS)
  protected getSettings: () => Promise<StoreActionResult>;
  // #endregion

  // #region shiftScheduleNS
  @shiftScheduleNS.State
  private viewKind: ShiftScheduleState['viewKind'];

  @shiftScheduleNS.State
  private printViewKind: ShiftScheduleState['printViewKind'];

  @shiftScheduleNS.Action(ShiftScheduleAction.SET_PRINT_VIEW_KIND)
  private setPrintViewKind: (payload: PrintViewKind) => Promise<void>;

  @shiftScheduleNS.Action(ShiftScheduleAction.SET_VIEW_KIND)
  private setViewKind: (payload: ViewKind) => Promise<void>;

  @shiftScheduleNS.State
  private timeframe: ShiftScheduleState['timeframe'];

  @shiftScheduleNS.Action(ShiftScheduleAction.SET_TIMEFRAME)
  private setTimeframe: (payload: SetTimeframePayload) => Promise<void>;

  @shiftScheduleNS.State
  private timeframeKind: ShiftScheduleState['timeframeKind'];

  @shiftScheduleNS.State
  private slotDisplayStyle: ShiftScheduleState['slotDisplayStyle'];

  @shiftScheduleNS.Action(ShiftScheduleAction.SET_SLOT_DISPLAY_STYLE)
  private setSlotDisplayStyle: (slotDisplayStyle: SlotDisplayStyle) => Promise<void>;
  // #endregion

  // #region shiftRotationsNS
  @shiftRotationsNS.Getter('ordered')
  protected shiftRotations: ShiftRotation[];
  // #endregion shiftRotationsNS

  // #region quickFilterNS
  @quickFilterNS.State('filters')
  protected quickFilters: QuickFilterFilterStoreState['filters'];

  @quickFilterNS.Action(QuickFilterAction.SET_FILTERS)
  protected setQuickFilters: (quickFilters: FiltersMap[]) => Promise<void>;
  // #endregion

  // #region tagsFilterNS
  @tagsFilterNS.State('selection')
  protected tagIds: TagsFilterStoreState['selection'];

  @tagsFilterNS.Action(TableStoreAction.SET_SELECTION)
  protected setTagsSelection: (tagIds: number[]) => Promise<void>;
  // #endregion

  // #region locationsPositionsFilterNS
  @locationsPositionsFilterNS.State('selection')
  protected locationsPositionIds: LocationsPositionsFilterStoreState['selection'];

  @locationsPositionsFilterNS.Action(FilterStoreAction.SET_SELECTION)
  protected setLocationsPositionsSelection: (
    locationsPositionIds: number[]
  ) => Promise<void>;
  // #endregion

  // #region shiftPresetsFilterNS
  @shiftPresetsFilterNS.State('selection')
  protected shiftPresetIds: ShiftPresetsFilterStoreState['selection'];

  @shiftPresetsFilterNS.Action(FilterStoreAction.SET_SELECTION)
  protected setShiftPresetsSelection: (shiftPresetIds: number[]) => Promise<void>;
  // #endregion

  // #region shiftRotationGroupsFilterNS
  @shiftRotationGroupsFilterNS.State('selection')
  protected shiftRotationGroupIds: ShiftPresetsFilterStoreState['selection'];

  @shiftRotationGroupsFilterNS.Action(FilterStoreAction.SET_SELECTION)
  protected setShiftRotationGroupSelection: (shiftRotationGroupIds: number[]) => Promise<void>;
  // #endregion

  // #region Auth
  @authNS.State
  private currentLocationId: AuthStoreState['currentLocationId'];

  @authNS.State
  private currentEmploymentId: AuthStoreState['currentEmploymentId'];
  // #endregion

  private saveSettingsTimeoutId: number | null = null;

  private initialSettingsLoadComplete = false;

  private get isShiftplanMissing() {
    return [
      SettingsScope.CALENDAR,
      SettingsScope.CALENDAR_PRINT,
    ].includes(this.scope) && !this.shiftplan;
  }

  @Watch('shiftplan')
  @Watch('currentLocationId')
  protected setUiFilters() {
    if (this.isShiftplanMissing) {
      return Promise.resolve();
    }

    const {
      viewKind = ViewKind.AGENDA,
      printViewKind = PrintViewKind.LIST,
      timeframeKind = TimeframeKind.WEEK,
      filters = [],
      slotDisplayStyle = SlotDisplayStyle.DEFAULT,
      timeframeCustomDuration,
    } = this.uiSharedSettings;

    const storePromises: Promise<void>[] = [];

    storePromises.push(this.setViewKind(viewKind));
    storePromises.push(this.setPrintViewKind(printViewKind));
    storePromises.push(this.setQuickFilters(filters));
    storePromises.push(this.setSlotDisplayStyle(slotDisplayStyle));

    // for Calendar/CalendarPrint we should use data stored for shiftplan
    if (this.scope === SettingsScope.CALENDAR) {
      const {
        timeframeCustom,
        timeframe,
        shiftRotationGroupIds,
        // shift is always defined here(check on top)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      } = this.uiShiftplanScopedSettings[this.shiftplan!.id] || {};

      storePromises.push(this.setTimeframe({
        timeframeKind,
        timeframe: getComputedTimeframe({
          timeframe,
          timeframeCustom,
          timeframeCustomDuration,
          timeframeKind,
          // shift is always defined here(check on top)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shiftplan: this.shiftplan!,
          timeZone: this.$timeZone.value,
        }),
      }));

      if (this.currentCompany?.shiftRotationEnabled) {
        const applicableShiftRotationGroupIds = getApplicableShiftRotationGroupIds(
          shiftRotationGroupIds || [],
          this.shiftRotations,
        );

        storePromises.push(this.setShiftRotationGroupSelection(
          applicableShiftRotationGroupIds || [],
        ));
      }
    }

    // For print timeframeKind is determined by PrintViewKind
    if (this.scope === SettingsScope.CALENDAR_PRINT) {
      const {
        timeframe,
        timeframeCustom,
        shiftRotationGroupIds,
        // shift is always defined here(check on top)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      } = this.uiShiftplanScopedSettings[this.shiftplan!.id] || {};

      /*
        Print view should use currently selected timeframe in calendar
        for current shiftplan, and use customTimeframe
        if it is set and current timeFrameKind is FREE
      */
      const printViewTimeframe = (
        timeframeKind === TimeframeKind.FREE && timeframeCustom
      )
        ? timeframeCustom
        : timeframe;
      storePromises.push(this.setTimeframe({
        timeframeKind,
        timeframe: getComputedTimeframe({
          timeframe: printViewTimeframe,
          // it is not possible to select custom timeframe for print views
          timeframeCustom: undefined,
          timeframeCustomDuration,
          timeframeKind: PrintTypeIntervals[printViewKind],
          // shift is always defined here(check on top)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shiftplan: this.shiftplan!,
          timeZone: this.$timeZone.value,
        }),
      }));

      if (this.currentCompany?.shiftRotationEnabled) {
        const applicableShiftRotationGroupIds = getApplicableShiftRotationGroupIds(
          shiftRotationGroupIds || [],
          this.shiftRotations,
        );

        storePromises.push(this.setShiftRotationGroupSelection(
          applicableShiftRotationGroupIds || [],
        ));
      }
    }

    // for CalendarAbsences we should use calendarAbsences scoped settings
    if (this.scope === SettingsScope.ABSENCES) {
      const {
        // use current moment of time as a fallback
        timeframe = {
          startsAt: new Date(),
          endsAt: new Date(),
        },
        shiftRotationGroupIds = [],
      } = this.uiCalendarAbsencesScopedSettings || {};

      storePromises.push(this.setTimeframe({
        timeframe: getTimeframeFromDates(
          timeframe.startsAt,
          timeframe.endsAt,
          TimeframeKind.MONTH,
          this.$timeZone.value,
        ),
      }));

      if (this.currentCompany?.shiftRotationEnabled) {
        storePromises.push(this.setShiftRotationGroupSelection(shiftRotationGroupIds || []));
      }
    }

    const {
      locationsPositionIds = [],
      tagIds = [],
      shiftPresetIds = [],
    } = (this.currentLocationId && this.uiLocationScopedSettings[this.currentLocationId]) || {};
    storePromises.push(this.setLocationsPositionsSelection(locationsPositionIds));
    storePromises.push(this.setTagsSelection(tagIds));
    storePromises.push(this.setShiftPresetsSelection(shiftPresetIds));

    return Promise.all(storePromises);
  }

  @Watch('viewKind')
  @Watch('printViewKind')
  @Watch('timeframeKind')
  @Watch('quickFilters')
  @Watch('locationsPositionIds')
  @Watch('tagIds')
  @Watch('shiftPresetIds')
  @Watch('shiftRotationGroupIds')
  @Watch('timeframe')
  @Watch('slotDisplayStyle')
  @Watch('isShiftplanFiltersSidebarCollapsed')
  protected throttledSaveSettings() {
    if (!this.initialSettingsLoadComplete || this.isShiftplanMissing) {
      return;
    }
    const settings: UiSettings = buildCalendarSettings({
      // shiftplan will be present for calendar settings updates
      shiftplanId: this.shiftplan?.id,
      locationid: this.currentLocationId,
      props: {
        printViewKind: this.printViewKind,
        viewKind: this.viewKind,
        filters: this.quickFilters,
        timeframeKind: this.timeframeKind,
        slotDisplayStyle: this.slotDisplayStyle,
        timeframe: this.timeframe,
        shiftRotationGroupIds: this.shiftRotationGroupIds,
        tagIds: this.tagIds,
        locationsPositionIds: this.locationsPositionIds,
        shiftPresetIds: this.shiftPresetIds,
        isShiftplanFiltersSidebarCollapsed: this.isShiftplanFiltersSidebarCollapsed,
      },
      currentState: {
        shared: this.uiSharedSettings,
        locationScoped: this.uiLocationScopedSettings,
        shiftplanScoped: this.uiShiftplanScopedSettings,
        calendarAbsencesScoped: this.uiCalendarAbsencesScopedSettings,
      },
    });
    this.updateCurrentSettingsState(settings);

    // save settings to DB after some time to club multiple changes in one API call
    if (this.saveSettingsTimeoutId) {
      window.clearTimeout(this.saveSettingsTimeoutId);
    }

    this.saveSettingsTimeoutId = window.setTimeout(() => {
      this.saveAllSettings();
    }, 10000);
  }

  @Watch('currentEmploymentId', { immediate: true })
  protected async onCurrentEmploymentIdUpdate() {
    if (!this.currentEmploymentId) {
      return;
    }
    /*
      only refetch settings if current employment id is different from
      employment id used in ui-store request
    */
    if (this.currentEmploymentId !== this.uiSettingsEmploymentId) {
      await this.getSettings();
    }

    await this.setUiFilters();
    this.initialSettingsLoadComplete = true;
  }

  public beforeDestroy() {
    if (this.saveSettingsTimeoutId) {
      window.clearTimeout(this.saveSettingsTimeoutId);
    }
  }

  public render() {
    return null;
  }
}
