import { StoreItem } from 'components/calendar-common/common/store/Helpers';
import Vue from 'vue';
import Vuex from 'vuex';
import { store as absences, StoreState as AbsencesStoreState } from 'components/calendar-absences/absences/Store';
import {
  store as common,
  StoreState as CommonStoreState,
} from 'components/calendar-absences/common/store/Store';
import { store as data, StoreState as AbsencesDataStoreState } from 'components/calendar-absences/data/Store';
import { store as employments, StoreState as EmploymentsStoreState } from 'components/calendar-common/employments/Store';
import {
  store as filters,
  StoreState as FiltersStoreState,
} from 'components/calendar-common/filters/Store';
import { store as highlights, StoreState as HighlightsStoreState } from 'components/calendar-common/highlights/Store';
import { store as aggregations, StoreState as AggregationsStoreState } from 'components/calendar-absences/summary/Store';
import { store as notes, StoreState as NotesStoreState } from 'components/calendar-common/notes/Store';
import { store as pagination, StoreState as PaginationStoreState } from 'components/calendar-common/common/pagination-mixin/Store';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import ApplicationLogger from 'services/logger/ApplicationLogger';

Vue.use(Vuex);

export type EventsArray = StoreItem[];

export interface EventsData {
  specialDates: EventsArray;
  dayNotes: EventsArray;
}

export interface CalendarAbsencesModuleState {
  highlights: HighlightsStoreState;
  absences: AbsencesStoreState;
  filters: FiltersStoreState;
  employments: EmploymentsStoreState;
  common: CommonStoreState;
  data: AbsencesDataStoreState;
  aggregations: AggregationsStoreState;
  notes: NotesStoreState;
  pagination: PaginationStoreState;
}

const appStore = (
  graphqlClientProvider: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => ({
  namespaced: true,
  modules: {
    highlights: highlights(CalendarNamespace.ABSENCES),
    absences: absences(),
    filters: filters(CalendarNamespace.ABSENCES),
    employments: employments(CalendarNamespace.ABSENCES),
    common: common(),
    data: data(graphqlClientProvider, logger),
    notes: notes(CalendarNamespace.ABSENCES),
    aggregations: aggregations(),
    pagination: pagination(),
  },
});

export default appStore;
