enum Mutation {
  SET_DAY_NOTES = 'setDayNotes',
  SET_SPECIAL_DATES = 'setSpecialDates',
  SET_ELEMENT = 'setElement',
  SET_EVENT_HANDLER = 'setHandler',
  TOGGLE_IS_OPEN = 'toggleIsOpen',
  SET_IS_OPEN = 'setIsOpen',
  SET_DATE = 'setDate',
}

export default Mutation;
