import { Component as TsxComponent } from 'vue-tsx-support/lib/api';
import {
  BaseButtonProps, ButtonColor, ButtonKind, BUTTON_COLOR_MAP, BUTTON_KIND_MAP, BUTTON_SIZE_MAP,
} from 'components/form/base-button/types';
import { Component, Prop } from 'vue-property-decorator';
import { ButtonHTMLAttributes, EventsOn, SyntheticEvent } from 'vue-tsx-support/types/dom';
import { Size } from 'components/types';
import styles from './base-button.css';

interface Events {
  onClick: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
}

@Component({
  inheritAttrs: false,
})
export default class BaseButton extends TsxComponent<
ButtonHTMLAttributes & BaseButtonProps, Events & Omit<EventsOn<HTMLButtonElement>, 'onClick'>
> {
  @Prop({ default: ButtonColor.PRIMARY })
  public color: NonNullable<BaseButtonProps['color']>;

  @Prop({ default: ButtonKind.FILL })
  public kind: NonNullable<BaseButtonProps['kind']>;

  @Prop({ default: Size.MEDIUM })
  public size: NonNullable<BaseButtonProps['size']>;

  public render() {
    return (
      <button
        {...{
          attrs: this.$attrs,
          on: { ...this.$listeners },
        }}
        class={[
          styles.button,
          BUTTON_SIZE_MAP[this.size],
          BUTTON_KIND_MAP[this.kind],
          BUTTON_COLOR_MAP[this.color],
        ]}
      >
        {this.$slots.default}
      </button>
    );
  }
}
