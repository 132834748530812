import { authNS, StoreState as AuthStoreState } from 'components/auth/store/Store';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type { FetchAllLocationsPositionsFunction, LocationsPosition } from 'store/locations-positions/Store';
import { Action } from 'store/normalized-store';
import { shiftRotationsNS } from 'store/shift-rotations/Store';
import type { FetchAllShiftRotationsFunction, ShiftRotation } from 'store/shift-rotations/Store';
import { shiftplansNS } from 'store/shiftplans/Store';
import type { GetMultipleById } from 'utils/store';
import { Size } from 'components/types';
import { executeStoreActionWithFailureSnackbar } from 'utils/store';
import { filterFalsy, sortBySortOrder } from 'utils/utils';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import { Slot } from 'components/dialog/Dialog';
import { redirectToParentIf } from 'src/utils/route';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import SectionCreateKind, { CreateShiftplanKind } from './section-create-kind/SectionCreateKind';
import CreateShiftplanContainer from './create-shiftplan/CreateShiftplanContainer';
import CopyShiftplanContainer from './copy-shiftplan/CopyShiftplanContainer';

const FORM_ID = 'dialog-create-shiftplan';

@Component
export default class DialogAddShiftplanContainer extends TsxComponent<{}> {
  protected isSubmitting = false;

  protected isLoading = true;

  protected createShiftplanKind = CreateShiftplanKind.NEW;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @locationsPositionsNS.Action(Action.FETCH_ALL)
  protected fetchLocationsPositionsAll: FetchAllLocationsPositionsFunction;

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;

  @shiftRotationsNS.Action(Action.FETCH_ALL)
  protected fetchRotationsAll: FetchAllShiftRotationsFunction;

  @shiftRotationsNS.Getter('items')
  protected shiftRotations: ShiftRotation[];

  @shiftplansNS.Getter('canCreateShiftplan')
  protected canCreateShiftplan: boolean;

  protected get isShiftRotationEnabled() {
    return !!this.currentCompany?.shiftRotationEnabled && this.shiftRotations.length > 0;
  }

  protected get locationsPositions() {
    return this.getLocationsPositionsByLocationAndPositionId(this.currentLocationId)
      .filter(({ position }) => !!position)
      .sort(sortBySortOrder());
  }

  protected get positions() {
    return this.locationsPositions.map(o => o.position).filter(filterFalsy);
  }

  protected onCloseClick() {
    redirectToParentIf(() => !this.isSubmitting)(this);
  }

  public beforeRouteEnter(_to, _from, next) {
    next(redirectToParentIf((vm: this) => !vm.canCreateShiftplan));
  }

  protected async initialPopulate() {
    this.isLoading = true;

    const responses = await Promise.all([
      executeStoreActionWithFailureSnackbar(
        this,
        {},
        this.fetchRotationsAll,
        'applyRotation.modal.error',
      ),
      executeStoreActionWithFailureSnackbar(
        this,
        {
          locationIds: [this.currentLocationId || Number.NaN],
          positionIds: null,
        },
        this.fetchLocationsPositionsAll,
        'applyRotation.modal.error',
      ),
    ]);

    this.isLoading = false;
    return responses;
  }

  protected onSubmit({ payload }) {
    this.isSubmitting = payload;
  }

  public mounted() {
    this.initialPopulate();
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        isLoading={this.isLoading}
        isOpen={true}
        onCloseClick={this.onCloseClick}
        title={this.$t('createShiftplan.modal.title')}
      >
        <SectionCreateKind
          createShiftplanKind={this.createShiftplanKind}
          isShiftRotationEnabled={this.isShiftRotationEnabled}
          onChange={({ payload }) => {
            this.createShiftplanKind = payload;
          }}
        />

        {(this.createShiftplanKind === CreateShiftplanKind.NEW
          || this.createShiftplanKind === CreateShiftplanKind.ROTATION) && (
          <CreateShiftplanContainer
            formId={FORM_ID}
            currentCompany={this.currentCompany}
            currentLocationId={this.currentLocationId}
            isShiftRotationEnabled={this.isShiftRotationEnabled}
            onCloseClick={this.onCloseClick}
            positions={this.positions}
            shiftRotations={this.shiftRotations}
            createShiftplanKind={this.createShiftplanKind}
            onSubmit={this.onSubmit}
          />
        )}

        {this.createShiftplanKind === CreateShiftplanKind.COPY
          && <CopyShiftplanContainer
            formId={FORM_ID}
            currentCompany={this.currentCompany}
            currentLocationId={this.currentLocationId}
            createShiftplanKind={this.createShiftplanKind}
            onSubmit={this.onSubmit}
            positions={this.positions}
          />}

        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isSubmitting}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_LEFT}
          kind={ButtonKind.GHOST}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        {
          <Button
            form={FORM_ID}
            disabled={this.isSubmitting}
            slot={Slot.BUTTONS_RIGHT}
            type="submit"
          >
            {this.$t('createShiftplan.modal.buttonCreate')}
          </Button>
        }
      </DialogWithSpinnerAndError>
    );
  }
}
