import moment from 'moment';

const MSC_TO_MIN_CONSTANT = 60 * 1000;
export const DATE_TIME_STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const MINUTES_IN_HOUR = 60;

function diffMinutes(date1, date2) {
  return date2.diff(date1) / MSC_TO_MIN_CONSTANT;
}

function getStartOfDay(momentDate) {
  return momentDate.clone().startOf('day');
}

function toMomentObject(data) {
  return moment.isMoment(data) ? data.clone() : moment(data);
}

function getEndOfDay(momentDate) {
  return momentDate.clone().endOf('day');
}

function minutesFromStartOfDay(date) {
  // moment.js mutates objects, so we need to do copy
  const d = toMomentObject(date);
  const startOfDay = getStartOfDay(d);

  return diffMinutes(startOfDay, d);
}

function minutesBetweenDates(start, end) {
  return diffMinutes(moment(start), moment(end));
}

function splitIntervalIntoDays(start, end) {
  const dStart = toMomentObject(start);
  const dEnd = toMomentObject(end);
  // don't get into infinite loop
  if (dEnd.isSame(dEnd.clone().startOf('day'))) {
    dEnd.subtract(1, 'second');
  }
  if (dStart.isAfter(dEnd)) {
    return [];
  }
  const startOfDay = getStartOfDay(dStart);
  const endOfDay = getEndOfDay(dStart);
  const days = [];
  while (!startOfDay.isSame(dEnd, 'day')) {
    days.push({
      start: moment.max(dStart, startOfDay).clone(),
      end: endOfDay.clone(),
    });
    // bump days
    startOfDay.add(1, 'days');
    endOfDay.add(1, 'days');
  }
  days.push({
    start: moment.max(dStart, startOfDay).clone(),
    end: dEnd,
  });
  return days;
}

function incrementByType(date, type) {
  return toMomentObject(date).clone().add(1, `${type}s`);
}

function decrementByType(date, type) {
  return toMomentObject(date).clone().subtract(1, `${type}s`);
}

function getIntervalDates(startDate, endDate) {
  let start = toMomentObject(startDate).clone().startOf('day');
  const end = toMomentObject(endDate).clone().startOf('day');
  const result = [start];
  while (start.isBefore(end)) {
    start = start.clone().add(1, 'days');
    result.push(start);
  }

  return result;
}

function isToday(date) {
  return date.isSame(moment(), 'day');
}

export {
  minutesBetweenDates,
  minutesFromStartOfDay,
  splitIntervalIntoDays,
  incrementByType,
  decrementByType,
  getIntervalDates,
  isToday,
};
