import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import Action from './Action';
import Mutation from './Mutation';

export const mainViewNS = namespace('mainView');

export interface StoreState {
  hasNewChatMessage: boolean;
  isChatOpen: boolean;
}

const getInitialState = (): StoreState => ({
  hasNewChatMessage: false,
  isChatOpen: false,
});

const getMainViewStore = (): Module<StoreState, RootStoreState> => {
  const store: Module<StoreState, RootStoreState> = {
    namespaced: true,
    state: {
      ...getInitialState(),
    },
    mutations: {
      [Mutation.SET_IS_CHAT_OPEN](state: StoreState, isOpen: boolean) {
        state.isChatOpen = isOpen;
      },
      [Mutation.SET_HAS_NEW_CHAT_MESSAGE](state: StoreState, hasNewChatMessage: boolean) {
        state.hasNewChatMessage = hasNewChatMessage;
      },
    },
    actions: {
      [Action.OPEN_CHAT]({ commit }) {
        commit(Mutation.SET_IS_CHAT_OPEN, true);
      },
      [Action.CLOSE_CHAT]({ commit }) {
        commit(Mutation.SET_IS_CHAT_OPEN, false);
      },
      [Action.SET_HAS_NEW_CHAT_MESSAGE]({ commit }, state: boolean) {
        commit(Mutation.SET_HAS_NEW_CHAT_MESSAGE, state);
      },
    },
    getters: {
      isChatAllowed: (state, getters, rootState, rootGetters) => (
        rootGetters['auth/isAuthorized'] && rootState.auth.currentCompany?.chatSettings?.isChatAllowed
      ),
    },
  };

  return store;
};

export default getMainViewStore;
