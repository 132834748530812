import {
  GQLAutoAcceptRequestSetting,
  GQLShiftCreateInput,
  GQLShiftRotationFragmentFragment,
  GQLShiftUpdateInput,
} from 'codegen/gql-types';
import {
  addHours,
  differenceInCalendarDays,
  formatISO,
  isAfter,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import type { Shiftplan } from 'store/shiftplans/Store';
import type { Shift } from 'store/shifts/Store';
import {
  endOf,
  getDateInTimeZone,
  getItemsOverlappingInterval,
  isStartBeforeEnd,
  MAX_DATE,
  MIN_DATE,
  parseDateString,
  startOf,
  Unit,
} from 'utils/date-related';
import { CUSTOM_PRESET_ID } from './section-general/SectionGeneral';
import {
  CompleteBreakInput,
  FormState,
} from './types';

export const getInitialFormState = (): FormState => ({
  shiftplanId: Number.NaN,
  startsAt: new Date(),
  isConnectedShift: false,
  endsAt: new Date(),
  workers: 1,
  shiftPresetId: null,
  locationsPositionId: null,
  shiftRotationGroupIds: [],
  shiftBreaks: [],
  autoAccept: GQLAutoAcceptRequestSetting.DISABLED,
  connectedGroupId: Number.NaN,
  shiftEvaluationTagIds: [],
  shiftQualifications: [],
  tagIds: [],
  unpaidBreak: 0,
  publicNote: '',
  repeatDates: [],
  managerNote: '',
  isStandBy: false,
  canEvaluate: false,
  ignoreConflicts: false,
  updateConnected: false,
});

export const getFormStateByShift = (shift: Shift, shiftplanId: number) => ({
  autoAccept: shift.autoAccept,
  unpaidBreak: shift.untimedBreakTime ?? shift.breakTime,
  connectedGroupId: shift.connectedGroupId,
  shiftEvaluationTagIds: shift.shiftEvaluationTags?.map(({ id }) => id) || [],
  locationsPositionId: shift.locationsPosition.id,
  publicNote: shift.note || '',
  shiftBreaks: shift.shiftBreaks.filter(
    (shiftBreak): shiftBreak is CompleteBreakInput => (
      typeof shiftBreak.endsAt === 'string'
    ),
  ),
  shiftplanId,
  shiftPresetId: shift.shiftPreset?.id || CUSTOM_PRESET_ID,
  shiftRotationGroupIds: shift.shiftRotationGroups?.map(({ id }) => id) || [],
  startsAt: new Date(shift.startsAt),
  endsAt: new Date(shift.endsAt),
  tagIds: shift.tags?.map(({ id }) => id) || [],
  workers: shift.workers,
  isStandBy: shift.untimed,
  canEvaluate: shift.canEvaluate,
  managerNote: shift.managerNote || '',
  ignoreConflicts: false,
  updateConnected: false,
  isConnectedShift: shift.connectedGroupId !== null,
  repeatDates: [],
  shiftQualifications: shift.shiftQualifications?.map(
    ({ qualificationId, count }) => ({ qualificationId, count }),
  ) || [],
});

export const getNewFormState = (payload: {
  shiftplan: Pick<Shiftplan, 'startsAt' | 'endsAt' | 'id'>;
  timeZone: string;
  startsAt?: Date;
  endsAt?: Date;
  locationsPositionId?: number;
}) => {
  const shiftplanStartsAt = startOf(
    new Date(payload.shiftplan.startsAt),
    Unit.DAY,
    payload.timeZone,
  );
  const shiftplanEndsAt = endOf(
    new Date(payload.shiftplan.endsAt),
    Unit.DAY,
    payload.timeZone,
  );

  let startsAt = addHours(shiftplanStartsAt, 9);

  const shiftplanDuration = { start: shiftplanStartsAt, end: shiftplanEndsAt };

  if (payload.startsAt && isWithinInterval(payload.startsAt, shiftplanDuration)) {
    startsAt = payload.startsAt;
  }

  let endsAt = addHours(startsAt, 8);

  if (
    payload.endsAt
    && isWithinInterval(payload.endsAt, shiftplanDuration)
    && isAfter(payload.endsAt, startsAt)
  ) {
    endsAt = payload.endsAt;
  }

  return {
    ...getInitialFormState(),
    startsAt,
    endsAt,
    shiftplanId: payload.shiftplan.id,
    locationsPositionId: payload.locationsPositionId || null,
  };
};

export const transformFormStateToCreateInput = (
  formState: Omit<FormState, 'locationsPositionId'> & {
    locationsPositionId: number;
  },
): Omit<GQLShiftCreateInput, 'companyId'> => ({
  startsAt: formatISO(formState.startsAt),
  endsAt: formatISO(formState.endsAt),
  workers: formState.workers,
  untimedBreakTime: formState.unpaidBreak,
  note: formState.publicNote,
  canEvaluate: formState.canEvaluate,
  autoAccept: formState.autoAccept,
  shiftplanId: formState.shiftplanId,
  locationsPositionId: formState.locationsPositionId,
  shiftPresetId: formState.shiftPresetId && formState.shiftPresetId !== CUSTOM_PRESET_ID
    ? formState.shiftPresetId
    : null,
  untimed: !!formState.isStandBy,
  tagIds: formState.tagIds,
  evaluationTagIds: formState.shiftEvaluationTagIds,
  shiftBreaks: formState.shiftBreaks.map(o => ({
    startsAt: o.startsAt,
    endsAt: o.endsAt,
  })),
  shiftQualifications: formState.shiftQualifications || [],
  ignoreConflicts: formState.ignoreConflicts,
});

export const transformFormStateToUpdateInput = (
  formState: Omit<FormState, 'locationsPositionId'> & {
    locationsPositionId: number;
  },
): Omit<GQLShiftUpdateInput, 'companyId'> => ({
  startsAt: formatISO(formState.startsAt),
  endsAt: formatISO(formState.endsAt),
  workers: formState.workers,
  untimedBreakTime: formState.unpaidBreak,
  note: formState.publicNote,
  canEvaluate: formState.canEvaluate,
  autoAccept: formState.autoAccept,
  locationsPositionId: formState.locationsPositionId,
  shiftPresetId: formState.shiftPresetId && formState.shiftPresetId !== CUSTOM_PRESET_ID
    ? formState.shiftPresetId
    : null,
  untimed: !!formState.isStandBy,
  managerNote: formState?.managerNote || '',
  tagIds: formState.tagIds,
  evaluationTagIds: formState.shiftEvaluationTagIds,
  shiftBreaks: formState.shiftBreaks.map(o => ({
    startsAt: o.startsAt,
    endsAt: o.endsAt,
  })),
  shiftQualifications: formState.shiftQualifications || [],
  shiftRotationGroupIds: formState.shiftRotationGroupIds || [],
  ignoreConflicts: formState.ignoreConflicts,
  updateConnected: formState.updateConnected,
});

export const isBreakInsideShiftTimeFrame = ({
  breakToValidate: { startsAt, endsAt },
  shiftStartsAt,
  shiftEndsAt,
}: {
  breakToValidate: { startsAt: Date; endsAt: Date; id: number };
  shiftStartsAt: Date;
  shiftEndsAt: Date;
}) => (
  isStartBeforeEnd(shiftStartsAt, startsAt, true)
  && isStartBeforeEnd(endsAt, shiftEndsAt, true)
);

export const isBreakOverlappingWithOther = ({
  allBreaks,
  breakToValidate: { startsAt, endsAt, id },
  timeZone,
}: {
  allBreaks: { startsAt: Date; endsAt: Date; id: number }[];
  breakToValidate: { startsAt: Date; endsAt: Date; id: number };
  timeZone: string;
}) => {
  // grab only valid breaks with different id from currently validated break
  const otherBreaks = allBreaks.filter((item) => {
    const startsAtDate = item.startsAt;
    const endsAtDate = item.endsAt;

    return (
      item.id !== id
      && isStartBeforeEnd(startsAtDate, endsAtDate)
    );
  });

  return getItemsOverlappingInterval(
    otherBreaks,
    startsAt,
    endsAt,
    timeZone,
  ).length === 0;
};

export const validateBreak = (
  {
    allBreaks,
    breakToValidate,
    shiftEndsAt,
    shiftStartsAt,
    timeZone,
  }: {
    allBreaks: { startsAt: Date; endsAt: Date; id: number }[];
    breakToValidate: { startsAt: Date; endsAt: Date; id: number };
    shiftEndsAt: Date;
    shiftStartsAt: Date;
    timeZone: string;
  },
) => (
  isStartBeforeEnd(breakToValidate.startsAt, breakToValidate.endsAt)
  && isBreakInsideShiftTimeFrame({
    breakToValidate,
    shiftEndsAt,
    shiftStartsAt,
  })
  && isBreakOverlappingWithOther({
    breakToValidate,
    allBreaks,
    timeZone,
  })
);

export const shiftPresetOfRotationGroupForCurrentDate = (
  startsAt: Date,
  shiftRotations: GQLShiftRotationFragmentFragment[],
  timeZone: string,
): {
  shiftRotationGroupId: number;
  shiftRotationGroupName: string;
  shiftPresetId: number;
  shiftRotationName: string;
  shiftRotationId: number;
}[] => {
  const shiftStartsAt = getDateInTimeZone(startsAt, timeZone);

  if (!shiftRotations) {
    return [];
  }

  const shiftRotationsWithinTimeframe = shiftRotations
    .filter(({ startsAt: rotationStartsAt, endsAt: rotationEndsAt }) => {
      const start = rotationStartsAt ? parseDateString(rotationStartsAt, timeZone) : MIN_DATE;
      const end = rotationEndsAt ? parseDateString(rotationEndsAt, timeZone) : MAX_DATE;

      return isWithinInterval(shiftStartsAt, { start, end });
    });

  return shiftRotationsWithinTimeframe.flatMap((shiftRotation) => {
    const {
      anchorDate: anchorDateString, rotationInterval, shiftRotationGroups, name, id,
    } = shiftRotation;

    // eslint-disable-next-line no-restricted-syntax
    const anchorDate = startOfDay(parseDateString(anchorDateString, timeZone));

    const rotationPadding = differenceInCalendarDays(shiftStartsAt, anchorDate) % rotationInterval;
    const patternIndex = (rotationPadding < 0
      // make sure that index is positive
      ? rotationInterval + rotationPadding
      : rotationPadding) % rotationInterval;

    return shiftRotationGroups.map((shiftRotationGroup) => {
      // [1, 2, 1, 3, 5]
      const {
        shiftPresetIds,
        id: shiftRotationGroupId,
        name: shiftRotationGroupName,
      } = shiftRotationGroup;

      return {
        shiftPresetId: shiftPresetIds[patternIndex],
        shiftRotationGroupId,
        shiftRotationName: name,
        shiftRotationGroupName,
        shiftRotationId: id,
      };
    });
  });
};

export const filteredShiftRotationGroupByShiftPreset = (
  startsAt: Date,
  shiftRotations: GQLShiftRotationFragmentFragment[],
  formShiftPresetId: number,
  timeZone: string,
): {
  shiftRotationGroupId: number;
  shiftRotationGroupName: string;
  shiftPresetId: number;
  shiftRotationName: string;
  shiftRotationId: number;
}[] => shiftPresetOfRotationGroupForCurrentDate(
  startsAt,
  shiftRotations,
  timeZone,
).filter(({ shiftPresetId }) => shiftPresetId === formShiftPresetId);
