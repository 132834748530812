import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import Tooltip from 'components/tooltip/Tooltip';
import styles from './indicator-leave-shift-request.css';

@Component
export default class IndicatorLeaveShiftRequest extends TsxComponent<{}> {
  public render() {
    return (
      <Tooltip text={this.$t('shifts.employees.labelHasLeaveRequest')}>
        <Icon
          class={styles.indicatorLeaveShiftRequestIcon}
          name={IconName.CHANGE}
          size={Size.SMALL}
        />
      </Tooltip>
    );
  }
}
