import MainView from 'components/main-view/MainView';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class Employments extends TsxComponent<{}> {
  public render() {
    return <MainView />;
  }
}
