import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SelectedTimeframe } from 'components/datepicker/types';
import {
  SlotDisplayStyle, TimeframeKind, ViewKind,
} from 'components/calendar-common/Enums';
import { createEventPayload, EventPayload } from 'src/utils/events';
import type { Shiftplan } from 'src/store/shiftplans/Store';
import NotificationBadge from 'components/notification-badge/NotificationBadge';
import Tooltip from 'components/tooltip/Tooltip';
import { Route as ShiftplanNotificationActionRoute } from 'components/dialog-shiftplan-notifications/routes';
import { ShiftplanScopedSettings } from 'src/store/ui-settings/Store';
import { getTimeframeFromDates } from 'utils/timeframe-helpers';
import { GQLShiftplanState } from 'codegen/gql-types';
import { IconName } from 'components/icons/types';
import Button from 'components/form/button/Button';
import Link from 'components/form/button/Link';
import { IconPosition } from 'components/form/button/types';
import RadioButtonGroup from '../radio-button-group/RadioButtonGroup';
import { SetTimeframePayload } from '../store/Store';
import Controls, { Slot } from '../controls/Controls';
import ButtonCalendarExport from '../button-calendar-export/ButtonCalendarExport';
import FilterPopupQuick from '../filter-box-quick/filter-popup-quick/FilterPopupQuick';
import NavLink from '../nav-link/NavLink';
import { Route } from '../routes';
import styles from './calendar-controls.css';
import ButtonAssignEmploymentsToOpenShifts from '../button-assign-employments-to-open-shifts/ButtonAssignEmploymentsToOpenShifts';

const TimeframeKindIconMap: Record<TimeframeKind, string> = {
  [TimeframeKind.DAY]: 'day',
  [TimeframeKind.WEEK]: 'week',
  [TimeframeKind.MONTH]: 'month',
  [TimeframeKind.FREE]: 'range',
};

const ViewKindIconMap: Record<ViewKind, string> = {
  [ViewKind.AGENDA]: 'shift-vertical',
  [ViewKind.LIST]: 'shift-horizontal',
  [ViewKind.EMPLOYMENTS]: 'personal',
  [ViewKind.POSITIONS]: 'position',
};

const SlotDisplayStyleIconMap: Record<SlotDisplayStyle, string> = {
  [SlotDisplayStyle.FULLNAMES]: 'shift-horizontal',
  [SlotDisplayStyle.COMPACT]: 'minimized',
  [SlotDisplayStyle.DEFAULT]: 'maximized',

};

interface Props {
  isAuthorisedToFillShiftplans: boolean;
  isAuthorisedToManageShiftplans: boolean;
  isCalendarExportEnabled: boolean;
  isEmployeeView: boolean;
  pendingNotifications?: number;
  settings?: ShiftplanScopedSettings;
  shiftplan?: Shiftplan;
  slotDisplayStyle: SlotDisplayStyle;
  timeframe: SelectedTimeframe;
  timeframeKind: TimeframeKind;
  viewKind: ViewKind;
  isShiftplanFiltersSidebarCollapsed: boolean;
}

interface Events {
  onTimeframeChange: EventPayload<SetTimeframePayload, HTMLButtonElement, MouseEvent>;
  onViewKindChange: EventPayload<ViewKind, HTMLButtonElement, MouseEvent>;
  onSlotDisplayStyleChange: EventPayload<SlotDisplayStyle, HTMLButtonElement, MouseEvent>;
  onFilterSidebarToggle: void;
}

@Component
export default class CalendarControls extends TsxComponent<Props, Events> {
  @Prop()
  private timeframeKind: Props['timeframeKind'];

  @Prop()
  private viewKind: Props['viewKind'];

  @Prop()
  private slotDisplayStyle: Props['slotDisplayStyle'];

  @Prop()
  private timeframe: Props['timeframe'];

  @Prop()
  private shiftplan: Props['shiftplan'];

  @Prop()
  private settings: Props['settings'];

  @Prop()
  public isCalendarExportEnabled: Props['isCalendarExportEnabled'];

  @Prop()
  public isEmployeeView: Props['isEmployeeView'];

  @Prop()
  public isAuthorisedToFillShiftplans: Props['isAuthorisedToFillShiftplans'];

  @Prop()
  public isAuthorisedToManageShiftplans: Props['isAuthorisedToManageShiftplans'];

  @Prop()
  public pendingNotifications: Props['pendingNotifications'];

  @Prop()
  public isShiftplanFiltersSidebarCollapsed: Props['isShiftplanFiltersSidebarCollapsed'];

  private isDatepickerOpen = false;

  private isIntervalSelectPending = false;

  // datepicker returns correct timeframe so we don't need to cast it anymore
  private onTimeframeUpdate(e, {
    timeframe = this.timeframe,
    timeframeKind = this.timeframeKind,
  }: SetTimeframePayload) {
    let resultTimeframe = timeframe;
    if (this.timeframeKind !== timeframeKind) {
      resultTimeframe = getTimeframeFromDates(
        timeframe.startsAt,
        timeframe.endsAt,
        timeframeKind,
        this.$timeZone.value,
      );
    }
    this.$emit(
      'timeframeChange',
      createEventPayload(
        e,
        {
          timeframe: resultTimeframe,
          timeframeKind: this.isIntervalSelectPending
            ? TimeframeKind.FREE
            : timeframeKind,
        },
      ),
    );
  }

  private onCustomTimeframe(e) {
    // switch to saved custom timeframe directly if present and not already in custom timeframe mode
    if (this.settings?.timeframeCustom && this.timeframeKind !== TimeframeKind.FREE) {
      const resultTimeframe = {
        startsAt: new Date(this.settings.timeframeCustom.startsAt),
        endsAt: new Date(this.settings.timeframeCustom.endsAt),
      };

      this.$emit('timeframeChange', createEventPayload(e, {
        timeframe: resultTimeframe,
        timeframeKind: TimeframeKind.FREE,
      }));
      return;
    }

    this.isIntervalSelectPending = true;
    this.isDatepickerOpen = true;
  }

  public render() {
    return (
      <Controls
        timeframe={this.timeframe}
        timeframeKind={this.timeframeKind}
        isDatepickerOpen={this.isDatepickerOpen}
        shiftplan={this.shiftplan}
        isIntervalSelectPending={this.isIntervalSelectPending}
        onDatepickerCancel={() => {
          this.isIntervalSelectPending = false;
          this.isDatepickerOpen = false;
        }}
        onDatepickerChange={({ event, payload }) => {
          this.onTimeframeUpdate(event, {
            timeframe: payload,
          });
          this.isIntervalSelectPending = false;
        }}
        onDatepickerToggle={({ payload }) => { this.isDatepickerOpen = payload; }}
      >
        <template slot={Slot.LEFT_CONTROLS_GROUP}>
          {!this.isEmployeeView && (
            <Tooltip
              text={
                <span
                  class={styles.calendarControlsTooltipText}>
                  {this.$t(
                    this.isShiftplanFiltersSidebarCollapsed
                      ? 'calendar.controls.buttonFilterSidebarExpand'
                      : 'calendar.controls.buttonFilterSidebarCollapse',
                  )}
                </span>
              }
            >
              <Button
                aria-label={this.$t(
                  this.isShiftplanFiltersSidebarCollapsed
                    ? 'calendar.controls.buttonFilterSidebarExpand'
                    : 'calendar.controls.buttonFilterSidebarCollapse',
                )}
                icon={IconName.SIDEBAR_COLLAPSE}
                iconPosition={IconPosition.ALONE}
                onClick={() => this.$emit('filterSidebarToggle')}
              />
            </Tooltip>
          )}
          {this.isEmployeeView && <FilterPopupQuick />}
          {this.shiftplan && this.isCalendarExportEnabled
            && <ButtonCalendarExport shiftplanId={this.shiftplan.id} />
          }
          {
            this.shiftplan
            && <Tooltip
              text={
                <span
                  class={styles.calendarControlsTooltipText}>
                  {this.$t('calendar.controls.buttonShowPrintView')}
                </span>
              }
            >
              <NavLink icon={IconName.PRINT} to={{
                name: Route.PRINT_SHIFTPLAN,
                params: {
                  shiftplanId: this.shiftplan?.id.toString(),
                },
              }} />
            </Tooltip>
          }
          {
            this.isAuthorisedToManageShiftplans
            && (
              <Tooltip text={
                <span
                  class={[styles.calendarControlsTooltipText]}>
                  {this.$t('shiftplans.modal.titleUpdate')}
                </span>
              }>
                <NavLink
                  icon={IconName.EDIT}
                  to={{
                    name: Route.EDIT_SHIFTPLAN,
                  }} />
              </Tooltip>
            )
          }
          {
            this.shiftplan && this.isAuthorisedToManageShiftplans && (
              <Tooltip text={
                <span
                  class={styles.calendarControlsTooltipText}>
                  {this.$t('calendar.controls.buttonMessageToEmployeesTooltip')}
                </span>
              }
              >
                <NavLink to={{
                  name: ShiftplanNotificationActionRoute.NOTIFICATION,
                  params: {
                    shiftplanId: this.shiftplan?.id.toString(),
                  },
                }}
                icon={IconName.SEND_MESSAGE}
                />
                {
                  !!this.pendingNotifications && (
                    <NotificationBadge>{this.pendingNotifications}</NotificationBadge>
                  )
                }
              </Tooltip>
            )
          }
          {
            this.shiftplan?.state === GQLShiftplanState.UNPUBLISHED
            && this.isAuthorisedToManageShiftplans
            && (
              <Tooltip text={
                <span
                  class={[styles.calendarControlsTooltipText]}>
                  {this.$t('publishShift.modal.buttonPublish')}
                </span>
              }>
                <NavLink to={{
                  name: Route.PUBLISH,
                }}
                icon={IconName.EYE_OFF} />
              </Tooltip>
            )
          }
          {
            this.isAuthorisedToManageShiftplans
             && (
               <Tooltip text={
                 <span
                   class={[styles.calendarControlsTooltipText]}>
                   {this.$t('importShiftSchedule.modal.title')}
                 </span>
               }>
                 <Link to={{
                   name: Route.IMPORT_SHIFT_SCHEDULE,
                 }}
                 iconPosition={IconPosition.ALONE}
                 icon={IconName.IMPORT_SHIFT_SCHEDULE}/>
               </Tooltip>
             )
          }
          {
            this.shiftplan && this.isAuthorisedToFillShiftplans && (
              <ButtonAssignEmploymentsToOpenShifts shiftplanId={this.shiftplan.id} />
            )
          }
        </template>
        <template slot={Slot.RIGHT_CONTROLS_GROUP}>
          <RadioButtonGroup
            options={Object.values(TimeframeKind).map(kind => ({
              label: this.$t(`calendar.controls.timeframeKind.${kind}`),
              value: kind,
              icon: TimeframeKindIconMap[kind],
            }))}
            value={this.timeframeKind}
            onChange={({ event, payload }) => {
              if (payload.value === TimeframeKind.FREE) {
                return this.onCustomTimeframe(event);
              }

              return this.onTimeframeUpdate(event, {
                timeframeKind: payload.value as TimeframeKind,
              });
            }} />
          <RadioButtonGroup
            options={Object.values(ViewKind).map(kind => ({
              label: this.$t(`calendar.controls.viewKind.${kind}`),
              value: kind,
              icon: ViewKindIconMap[kind],
            }))}
            value={this.viewKind}
            onChange={({ event, payload }) => {
              this.$emit('viewKindChange', createEventPayload(event, payload.value as ViewKind));
            }} />
          <RadioButtonGroup
            options={Object.values(SlotDisplayStyle).map(kind => ({
              label: this.$t(`calendar.controls.slotDisplayStyle.${kind}`),
              value: kind,
              icon: SlotDisplayStyleIconMap[kind],
            }))}
            value={this.slotDisplayStyle}
            onChange={({ event, payload }) => {
              this.$emit('slotDisplayStyleChange', createEventPayload(event, payload.value as SlotDisplayStyle));
            }} />
        </template>
      </Controls>
    );
  }
}
