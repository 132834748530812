import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

@Component
export default class MaximumConsecutiveWorkingDays extends TsxComponent<{
  conflict: GQLShiftConflictsFragmentFragment;
}> {
  @Prop()
  public conflict: GQLShiftConflictsFragmentFragment;

  public render() {
    if (this.conflict.__typename !== 'MaximumConsecutiveWorkingDaysShiftConflict') {
      return null;
    }

    return (
      <span>
        {
          this.$t('conflicts.maximumConsecutiveDays.textShort', { num: this.conflict.maxDays })
        }
      </span>
    );
  }
}
