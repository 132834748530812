import type { Shift } from 'store/shifts/Store';
import { createEventPayload, EventPayload } from 'utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Row from '../row/Row';

import styles from './offerer.css';

@Component
export default class Offerer extends TsxComponent<{
  isManagerView?: boolean;
  isPending?: boolean;
  isSubmitting?: boolean;
  request: Shift['requests'][0];
}, {
  onAcceptOffer: (payload: EventPayload<void>) => void;
  onCancelRequest: (payload: EventPayload<void>) => void;
  onDeleteOffer: (payload: EventPayload<void>) => void;
}> {
  @Prop()
  public isManagerView?: boolean;

  @Prop()
  public isPending?: boolean;

  @Prop()
  public isSubmitting?: boolean;

  @Prop()
  public request: Shift['requests'][0];

  public render() {
    if (!this.request.employment) {
      return null;
    }

    return (
      <section class={styles.offerer}>
        <div class={styles.offererTitle}>
          {this.$t('shifts.replaceRequests.titleOfferedBy')}
        </div>

        <Row
          isManagerView={this.isManagerView}
          isOffer={true}
          isPending={this.isPending}
          isSubmitting={this.isSubmitting}
          onCancelRequest={({ event }) => this.$emit('cancelRequest', createEventPayload(event, undefined))}
          onConfirmRequest={({ event }) => this.$emit('acceptOffer', createEventPayload(event, undefined))}
          onDeleteOffer={({ event }) => this.$emit('deleteOffer', createEventPayload(event, undefined))}
          request={this.request}
        />
      </section>
    );
  }
}
